import clsx from "clsx"
import React, { ReactNode } from "react"

import {
  CardActions,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core"
import useGlobalStyles from "styles/globalStyles"
import { ShopArticleFragment } from "../../lib/graphql/operations"
import StopPropagation from "../StopPropagation/StopPropagation"
import { StyledProps } from "types/type"
import placeholderImage from "./image-placeholder.png"
import { getGermanyMoneyFormat } from "lib/getGermanyMoneyFormat"
import { AppCard } from "../AppCard/AppCard"

export { PriceDescription } from "./components/PriceDescription/PriceDescription"

export type ShopArticleProps = {
  article: ShopArticleFragment
  actionsButtons?: React.ReactNode | null
  onClick?: () => void
  renderContent?: (props: {
    defaultComponent: React.ReactNode
    components: {
      price: React.ReactNode
      name: React.ReactNode
      id: React.ReactNode
      brandName: React.ReactNode
    }
  }) => React.ReactNode
} & StyledProps

export default function ShopArticleCard({
  article,
  actionsButtons: buttons = null,
  onClick = undefined,
  renderContent,
  className,
  style,
}: ShopArticleProps) {
  const c = useStyles({ isClickable: !!onClick })
  const a = article
  const gs = useGlobalStyles()

  const articleID = (article || {}).id

  if (!articleID) return null

  return (
    <AppCard
      onClick={onClick}
      className={clsx(c.root, "shop-article-card", className)}
      style={style}
      header={
        <CardMedia
          image={a.thumbnail || placeholderImage}
          title={a.name || ""}
        />
      }
      body={
        <div className={c.body}>
          <div style={{ padding: 0 }}>
            {(() => {
              const id = (
                <Typography variant="overline" color="textSecondary">
                  {a.sku}
                </Typography>
              )

              const name = (
                <Typography
                  variant="body1"
                  color="textPrimary"
                  gutterBottom
                  className={gs.clipText}
                  {...(a.name ? { title: a.name } : {})}
                  // ...({})
                >
                  {a.name + ""}
                </Typography>
              )

              const brandName = (
                <Typography
                  variant="caption"
                  color="textPrimary"
                  gutterBottom
                  className={gs.clipText}
                  {...(a.brand?.name ? { title: a.brand?.name } : {})}
                >
                  {a.brand?.name || (
                    <span style={{ visibility: "hidden" }}>placeholder</span>
                  )}
                </Typography>
              )

              const price = !!a.price?.value && (
                <Typography variant="body1" color="textSecondary">
                  {`${getGermanyMoneyFormat(a.price.value || 0)} ${
                    a.price.currency
                  }`}
                </Typography>
              )

              const defaultComponent = (
                <>
                  {id}
                  {name}
                  {brandName}
                  {price}
                </>
              )

              if (typeof renderContent === "function")
                return renderContent({
                  defaultComponent,
                  components: { id, name, brandName, price },
                })

              return defaultComponent
            })()}
          </div>

          {!!buttons && (
            <CardActions
              className={c.actions}
              disableSpacing
              style={{ padding: 0, marginTop: 16 }}
            >
              <StopPropagation style={{ width: "100%" }}>
                {buttons}
              </StopPropagation>
            </CardActions>
          )}
        </div>
      }
    ></AppCard>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    body: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },

    // media: {
    //   backgroundPosition: "center",
    //   paddingTop: 0.5056 * 100 + "%",
    // },
    actions: {
      display: "flex",
    },
  }
})
