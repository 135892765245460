import React from "react"
import moment from "moment"
import CalendarEventContext from "../../contexts/CalendarEventContext"
import { Variant } from "@material-ui/core/styles/createTypography"
import CalendarDateTimeInput from "components/CalendarDateTimeInput/CalendarDateTimeInput"
import { makeStyles, TextFieldProps } from "@material-ui/core"
import clsx from "clsx"

export default function DateTimeIntervalInput(props: {
  textFieldVariant?: Variant
  className?: string
  style?: React.CSSProperties
  TextFieldProps?: TextFieldProps
}) {
  const ctx = React.useContext(CalendarEventContext)

  const start = ctx.form.getFieldProps("startDateTime")
  const end = ctx.form.getFieldProps("endDateTime")

  React.useEffect(() => {
    const endSmallerThanStart = end.value < start.value

    if (!endSmallerThanStart) return

    start.onChange({
      target: {
        name: start.name,
        value: beginningOfDay(end.value),
      },
    })
  }, [end.value])

  React.useEffect(() => {
    const endSmallerThanStart = end.value < start.value

    if (!endSmallerThanStart) return

    end.onChange({
      target: {
        name: end.name,
        value: endOfDay(start.value),
      },
    })
  }, [start.value])

  const c = useStyles()

  return (
    <div
      className={clsx(c.root, "date-time-interval-input", props.className)}
      style={props.style}
    >
      <CalendarDateTimeInput
        textFieldVariant={props.textFieldVariant}
        {...start}
        classes={{ calendar: c.calendar }}
        hideClearButton
        onChange={(e: { target: { name: string; value: string } }) => {
          end.onChange({
            target: {
              name: e.target.name,
              value: beginningOfDay(e.target.value),
            },
          })
        }}
      ></CalendarDateTimeInput>

      <CalendarDateTimeInput
        textFieldVariant={props.textFieldVariant}
        {...end}
        hideClearButton
        classes={{ calendar: c.calendar }}
        onChange={(e: { target: { name: string; value: string } }) => {
          end.onChange({
            target: {
              name: e.target.name,
              value: endOfDay(e.target.value),
            },
          })
        }}
      ></CalendarDateTimeInput>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    calendar: {
      // marginBottom: theme.spacing(2),
    },
    root: {
      // display: "flex",
      // justifyContent: "space-between",
      // "&>*": {
      //   width: `calc(50% - 4px) !important`,
      // },
    },
  }
})

const beginningOfDay = (date: string) => {
  return moment(date)
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      milliseconds: 0,
    })
    .toISOString()
}

const endOfDay = (date: string) => {
  return moment(date)
    .set({
      hour: 23,
      minute: 59,
      second: 59,
      milliseconds: 999,
    })
    .toISOString()
}
