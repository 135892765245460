import { makeStyles, Paper } from "@material-ui/core"
import clsx from "clsx"
import { List, ListProps } from "components/List/List"
import PaperList from "components/PaperList"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import React from "react"
import { StyledProps } from "types/type"
import { ItemsListContext } from "../../context/ItemsListContext"
import { ItemsListTwoPages } from "../ItemsListTwoPages/ItemsListTwoPages"
import ListItemEntry from "../ListItemEntry/ListItemEntry"

export function ListPage(props: StyledProps) {
  return (
    <ItemsListTwoPages
      style={props.style}
      className={clsx("list-page-two-pages-layout", props.className)}
      mainPage={<MainPage style={{ height: "100%" }}></MainPage>}
    ></ItemsListTwoPages>
  )
}

function MainPage(props: StyledProps) {
  const api = React.useContext(ItemsListContext)

  const indexedItemsApi = api.indexedItemsApi

  const itemKey = React.useCallback(
    (index: number, a: NewPageBoardItemFragment) => {
      return a?.id || index + ""
    },
    []
  )

  const c = useStyles()
  return (
    <div
      className={clsx("list-page", c.root, props.className)}
      key={indexedItemsApi.rootKey}
      style={props.style}
      ref={api.rootContainer.containerRef}
    >
      {(() => {
        const basicProps: ListProps = {
          ...(api.ListProps || {}),
          itemKey: itemKey,
        }

        if (api.variant === "grid") {
          return (
            <List.Provider variant="grid" {...basicProps}>
              <List.Root
                itemContent={(i, b) => {
                  if (!b) return null
                  return (
                    <ListItemEntry
                      entry={b as NewPageBoardItemFragment}
                    ></ListItemEntry>
                  )
                }}
              ></List.Root>
            </List.Provider>
          )
        }

        return (
          <Paper elevation={5}>
            <PaperList.Provider
              {...basicProps}
              ListItem={ListItemEntry as any}
              ItemPaperProps={{ elevation: 0 }}
            >
              <Paper style={{ height: "100%" }}>
                <PaperList.Root marginZero className="list"></PaperList.Root>
              </Paper>
            </PaperList.Provider>
          </Paper>
        )
      })()}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "&>*": {
        height: "100%",
      },
    },
  }
})
