import React from "react"
import {
  ButtonBase,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core"
import { Modal } from "../Modal/Modal"
import clsx from "clsx"
import { useI18n } from "contexts/i18nContext/i18nContext"
import LexicalEditor, {
  LexicalEditorAPIInput,
} from "components/LexicalEditor/LexicalEditor"
import { LexicalEditorAPI } from "components/LexicalEditor/contexts/LexicalEditorContext"
import { Icon } from "components/Icon/Icon"
import LightTooltip from "components/LightTooltip/LightTooltip"

export type EditorModalProps = {
  onClose: () => any
  isOpen: boolean
  onSave: (text: string) => any
  saveButtonText?: string
  className?: string
  style?: React.CSSProperties
  getEditorKey?: () => string
  allowToSaveEmptyValue?: boolean
  namespace: LexicalEditorAPIInput["namespace"]
  initialValue: LexicalEditorAPIInput["value"]
  markdownMode: LexicalEditorAPIInput["markdownMode"]
  editable: LexicalEditorAPIInput["editable"]
  variant?: React.ComponentProps<typeof Modal.Default>["variant"]
  children?: (args: {
    lexicalEditorAPI: LexicalEditorAPI
    defaultComponent: React.ReactNode
  }) => React.ReactNode
}

export default function EditorModal(props: EditorModalProps) {
  const c = useStyles()
  const t = useI18n()

  return (
    <Modal.Default
      isOpen={props.isOpen}
      onClose={props.onClose}
      className={clsx(c.root, "editor-modal", props.className)}
      variant={props.variant}
      style={props.style}
      render={(p) => {
        return (
          <LexicalEditor.Provider
            editable={props.editable}
            namespace={props.namespace}
            markdownMode={props.markdownMode}
            allowToSaveEmptyValue={props.allowToSaveEmptyValue}
            onSave={props.onSave}
            value={props?.initialValue || ""}
            {...(props.getEditorKey ? { key: props.getEditorKey() } : {})}
          >
            <LexicalEditor.Context.Consumer>
              {(api) => {
                const defaultComponent = (
                  <>
                    {p.components.top}
                    {p.components.body}
                  </>
                )

                if (props.children)
                  return (
                    <>
                      {props.children({
                        lexicalEditorAPI: api,
                        defaultComponent,
                      })}
                    </>
                  )

                return defaultComponent
              }}
            </LexicalEditor.Context.Consumer>
          </LexicalEditor.Provider>
        )
      }}
      renderTop={({ components }) => {
        return (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <React.Fragment>
                {(() => {
                  return (
                    <LexicalEditor.Context.Consumer>
                      {(api) => {
                        return (
                          <ButtonBase
                            className={c.saveButton}
                            disabled={api.saveAPI.isDisabled}
                            onClick={() => {
                              api.saveAPI.save({ clearEditorOnSave: true })
                              props.onClose()
                            }}
                          >
                            <Typography
                              style={{
                                ...(api.saveAPI.isDisabled
                                  ? { opacity: 0.1 }
                                  : {}),
                              }}
                              variant="body2"
                            >
                              {props.saveButtonText || t["save"]}
                            </Typography>
                          </ButtonBase>
                        )
                      }}
                    </LexicalEditor.Context.Consumer>
                  )
                })()}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LexicalEditor.Context.Consumer>
                    {(api) => {
                      return (
                        <LightTooltip
                          title={(() => {
                            if (api.markdownMode.isTrue)
                              return t["disableMarkdown"]

                            return t["enableMarkdown"]
                          })()}
                        >
                          <IconButton
                            size="small"
                            style={{ marginRight: 8 }}
                            onClick={() => api.markdownMode.toggle()}
                          >
                            <Icon
                              style={{
                                fontSize: 19,
                                ...(api.markdownMode.isTrue
                                  ? {}
                                  : { opacity: 0.2 }),
                              }}
                              name="markdown"
                            ></Icon>
                          </IconButton>
                        </LightTooltip>
                      )
                    }}
                  </LexicalEditor.Context.Consumer>

                  {components.closeButton}
                </div>
              </React.Fragment>
            </div>
            <LexicalEditor.Context.Consumer>
              {(api) => {
                return (
                  !api.markdownMode.isTrue && (
                    <LexicalEditor.Toolbar
                      className={c.toolbar}
                    ></LexicalEditor.Toolbar>
                  )
                )
              }}
            </LexicalEditor.Context.Consumer>
            {}
          </div>
        )
      }}
    >
      {(() => {
        if (!props.isOpen) return null

        return (
          <div className={c.body}>
            <LexicalEditor.Root
              className={c.textField}
              classes={{ inside: c.inside }}
            ></LexicalEditor.Root>
          </div>
        )
      })()}
    </Modal.Default>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    toolbar: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    root: {},
    body: {
      padding: theme.spacing(0, 2, 2),
      height: "100%",
      marginTop: theme.spacing(0.5),

      // "&>*": { height: "100%" },
    },
    saveButton: { textAlign: "left" },

    inside: {
      padding: theme.spacing(2),
      background: "rgba(248,248,248)",
    },
    textField: {
      display: "flex",

      "&>*": {
        width: "100%",
      },
      // padding: theme.spacing(2),
      minHeight: "100%",
      // height: "100%",
      // background: "rgba(248,248,248)",
    },
  }
})
