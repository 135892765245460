import { UserType } from "lib/graphql/types"
import { green } from "constants/colors"
import { userTypesColor } from "lib/constants"
import { RoomMemberFragment, UserFragment } from "lib/graphql/operations"

export default function getRoomMemberColor(roomMember?: RoomMemberFragment) {
  const colors = {
    Account: green[3],
    User: userTypesColor[
      (roomMember as UserFragment).type === UserType.User
        ? UserType.User
        : UserType.Guest
    ],
    ContactMember: userTypesColor[UserType.Guest],
    "": "black",
  }

  // const color = roomMember?.__typename === "Account" ? green[3] : purple[2]
  let color = colors[roomMember?.__typename || ""]

  return color
}
