import RoomBase from "components/Room/Room"
import _ from "lodash"
import { useContext } from "react"
import ChatContext from "components/Chat/contexts/ChatContext"
import ChatRoomAppBar from "../ChatRoomAppBar/ChatRoomAppBar"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"

export default function Room(props: { className?: string }) {
  const { goToPrivateRoom, roomIdentifier } = useContext(ChatContext)

  const c = useStyles({})

  if (!roomIdentifier) return null

  return (
    <div className={clsx(c.root, "room", props.className || "")}>
      <RoomBase.Provider
        identifier={roomIdentifier}
        goToPrivateRoom={goToPrivateRoom}
      >
        <RoomBase.Template.Default
          className={props.className || ""}
          appBar={<ChatRoomAppBar />}
        />
      </RoomBase.Provider>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "&>*": {
        height: "100%",
      },
    },
  }
})

//
