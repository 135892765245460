import AutoComplete from "components/OldDocumentForm/components/AutoComplete/AutoComplete"
import CountriesAutoComplete from "components/Input/components/CountriesAutoComplete/CountriesAutoComplete"
import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { PageTextField as TextField } from "components/Page/Page"
import { I18nAPI } from "contexts/i18nContext/i18nContext"
import { ContactFormContext } from "../components/ProviderAndContext/ProviderAndContext"
import Switch from "../../../../../../../../Switch/Switch"
import Typography from "@material-ui/core/Typography"
import SalutationAutoComplete from "../components/SalutationAutoComplete/SalutationAutoComplete"

export function getPersonalDataFieldProps(props: { translations: I18nAPI }) {
  const { translations } = props

  const fieldProps: FieldsetIndentationProps = {
    icon: "person",
    children: (
      <ContactFormContext.Consumer>
        {(api) => {
          return (
            <>
              {/* <TextField
                {...api.form.getFieldProps("name1")}
                InputProps={{
                  endAdornment: (
                    <Switch {...api.form.getFieldProps("active")}></Switch>
                  ),
                }}
                label={translations.name}
              ></TextField>
              <TextField
                {...api.form.getFieldProps("name2")}
                label={translations.name2}
              ></TextField>
              <TextField
                {...api.form.getFieldProps("street")}
                label={translations.street}
              ></TextField>
              <TextField
                {...api.form.getFieldProps("zip")}
                label={translations.zip}
              ></TextField>
              <TextField
                {...api.form.getFieldProps("city")}
                label={translations.city}
              ></TextField>
              <CountriesAutoComplete
                {...api.form.getFieldProps("country")}
                TextFieldProps={{ label: translations.country }}
                AutoComplete={AutoComplete}
                renderSuggestionsItem={(item) => {
                  return <Typography>{item}</Typography>
                }}
              ></CountriesAutoComplete> */}
              <SalutationAutoComplete
                {...api.form.getFieldProps("salutation")}
                TextFieldProps={{
                  label: translations.salutation,
                  InputProps: {
                    endAdornment: (
                      <Switch {...api.form.getFieldProps("active")}></Switch>
                    ),
                  },
                }}
                AutoComplete={AutoComplete}
                renderSuggestionsItem={(item) => {
                  return <Typography>{item}</Typography>
                }}
              ></SalutationAutoComplete>
              <TextField
                {...api.form.getFieldProps("title")}
                label={translations.title}
              ></TextField>
              <TextField
                {...api.form.getFieldProps("firstName")}
                label={translations.firstName}
              ></TextField>
              <TextField
                {...api.form.getFieldProps("lastName")}
                label={translations.lastName}
              ></TextField>

              <TextField
                {...api.form.getFieldProps("position")}
                // InputProps={{
                //   endAdornment: (
                //     <Switch {...api.form.getFieldProps("active")}></Switch>
                //   ),
                // }}
                label={translations.position}
              ></TextField>
            </>
          )
        }}
      </ContactFormContext.Consumer>
    ),
  }

  return fieldProps
}
