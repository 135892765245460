import useWindowSize from "hooks/useWindowSize"
import React from "react"

const WindowSizeContext = React.createContext(
  {} as ReturnType<typeof useWindowSize>
)

WindowSizeContext.displayName = "WindowSizeContext"

export default WindowSizeContext
