import moment from "moment"
import React, { useEffect } from "react"
import { ArrayElement, Period } from "types/type"
// import { Period } from "../CalendarViews/StackedMonths"
import useOnChange from "hooks/useOnChange"
import useMemoAPI from "hooks/useMemoAPI"

export const periodOptions = [
  {
    period: "yesterday" as const,
    description: "gestern",
    unit: "day",
    addition: -1,
  },
  {
    period: "today" as const,
    description: "heute",
    unit: "day",
    addition: 0,
  },
  {
    period: "tomorrow" as const,
    description: "morgen",
    unit: "day",
    addition: 1,
  },
  {
    period: "last-week" as const,
    description: "letzte Woche",
    unit: "week",
    addition: -1, // start of last week
  },
  {
    period: "this-week" as const,
    description: "diese Woche",
    unit: "week",
    addition: 0, // start of this week
  },
  {
    period: "next-week" as const,
    description: "nächste Woche",
    unit: "week",
    addition: 1, // start of next week
  },
  {
    period: "last-month" as const,
    description: "letzten Monat",
    unit: "month",
    addition: -1, // start of last month
  },
  {
    period: "this-month" as const,
    description: "diesen Monat",
    unit: "month",
    addition: 0, // start of this month
  },
  {
    period: "next-month" as const,
    description: "nächsten Monat",
    unit: "month",
    addition: 1, // start of next month
  },
  {
    period: "last-year" as const,
    description: "letztes Jahr",
    unit: "year",
    addition: -1, // start of last year
  },
  {
    period: "this-year" as const,
    description: "dieses Jahr",
    unit: "year",
    addition: 0, // start of this year
  },
  {
    period: "next-year" as const,
    description: "nächstes Jahr",
    unit: "year",
    addition: 1, // start of next year
  },
]

export function usePeriodSelector(props: PeriodSelectorProps) {
  const externalPeriodState = resolvePeriodState(props?.periodState || null)

  const validPeriod = externalPeriodState?.period?.start?.isValid()

  const [periodType, setPeriodType] = React.useState<PeriodType | null>(
    externalPeriodState?.periodType || (validPeriod ? null : "all")
  )

  const [period, setPeriod] = React.useState<Period | null>(
    externalPeriodState.period || null
  )

  React.useEffect(() => {
    const currentPeriodState = getPeriodState({ period, periodType })

    if (periodStatesAreEqual(props.periodState || null, currentPeriodState))
      return

    setPeriod(period)
    setPeriodType(periodType)
  }, [JSON.stringify(props?.periodState)])

  const currentPeriodState = getPeriodState({ period, periodType })

  useOnChange({
    value: JSON.stringify(currentPeriodState),
    disabled: !props?.onChangePeriodState,
    onChange: () => {
      const statesAreEqual = periodStatesAreEqual(
        currentPeriodState,
        props?.periodState || null
      )

      if (statesAreEqual) return

      props?.onChangePeriodState?.(currentPeriodState)
    },
  })

  const periodTypeState = periodType

  const resolvePeriod = React.useCallback(
    (periodType: PeriodType | null = periodTypeState) => {
      if (!periodType) return period

      if (periodType === "all") return null

      const option = periodOptions.find((a) => a.period === periodType)

      if (!option) return period

      const start = moment()
        .add(option.addition, option.unit as any)
        .startOf(option.unit as any)

      return {
        start: start,
        end: start.clone().endOf(option.unit as any),
      }
    },
    [period, periodTypeState]
  )

  useEffect(() => {
    const period = resolvePeriod(periodType || undefined)
    setPeriod(period)
  }, [periodType])

  const setPeriodExternally = React.useCallback((period: Period | null) => {
    setPeriodType(period ? null : "all")
    setPeriod(period)
  }, [])

  return useMemoAPI({
    periodType,
    setPeriod: setPeriodExternally,
    setPeriodType,
    period: resolvePeriod(),
    resolvePeriod,
    getPeriodTypeDescription,
    clear: React.useCallback(() => {
      setPeriod(null)
      setPeriodType("all")
    }, []),
  })
}

type PeriodType = ArrayElement<typeof periodOptions>["period"] | "all"

const periodString = (period: Period | null | undefined) => {
  if (!period) return ""
  return [period.start, period.end].map((a) => a?.toISOString()).join("-")
}

function isEqual(
  date1: moment.Moment | undefined | null,
  date2: moment.Moment | undefined | null
) {
  if (!date1 || !date2) return
  return date1.isSame(date2, "day")
}

const getPeriodTypeDescription = (periodType: PeriodType) => {
  const option = periodOptions.find((a) => a.period === periodType)

  if (!option) return ""

  return option.description
}

export type PeriodState = Period | PeriodType

function resolvePeriodState(periodState: PeriodState | undefined | null) {
  if (!periodState) return { period: null, periodType: null }

  if (typeof periodState === "string")
    return { period: null, periodType: periodState }

  return { period: periodState, periodType: null }
}

function periodStatesAreEqual(
  periodState1: PeriodState | null,
  periodState2: PeriodState | null
) {
  const { period: period1, periodType: periodType1 } =
    resolvePeriodState(periodState1)
  const { period: period2, periodType: periodType2 } =
    resolvePeriodState(periodState2)

  if (periodType1 !== periodType2) return false

  return (
    isEqual(period1?.start, period2?.start) &&
    isEqual(period1?.end, period2?.end)
  )
}

const getPeriodState = ({
  period,
  periodType,
}: {
  period: Period | null
  periodType: PeriodType | null
}) => {
  return periodType ? periodType : period || null
}

export type PeriodSelectorProps = {
  periodState?: PeriodState | null
  onChangePeriodState?: (period: PeriodState | null) => any
}
