import _ from "lodash"
import React, { useState, useEffect, useMemo } from "react"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import Views from "components/Views/Views"
import { filter, map, defaultTo, flow, find, get } from "lodash/fp"
import ComposeProviders from "components/ComposeProviders/ComposeProviders"
import { useInputController } from "hooks/useInputController"
import { CollectionItemType, Nullable, Tabs as TabsType } from "types/type"
import Tabs from "components/Tabs/Tabs"

type TabsWithBodyProps<TabID extends string | number | symbol> = {
  tabs: TabsType<TabID>
  tabID?: TabID
  className?: string
  smallTabsIcon?: boolean
  noScroll?: boolean
  tabLinkFromID?: (tabID: string) => string
  classes?: { body?: string; bodyContent?: string; bodyRoot?: string }
  lazyRendering?: boolean // render the component when the respective tab is active
  contentZeroPadding?: boolean
  onChange?: (tabID: TabID) => any
  selectedTabColor?: string
  style?: React.CSSProperties
  mountAllProvidersAtOnce?: string
  backgroundColor?: string
  hideTabSelector?: boolean
  hideTabIndicator?: boolean
  children?:
    | React.ReactChild
    | ((props: {
        components: React.ReactNode
        defaultComponent: React.ReactNode
        tabID: string
        setTabID: (id: string) => any
      }) => React.ReactNode)
}

export default function TabsWithBody<T extends string>(
  props: TabsWithBodyProps<T>
) {
  const {
    tabs: propsTabs, //it accepts the structure {[id]: tabsProps} and [{id: 'ID', ....tabsProps}]
    tabID: propsTabID = undefined,
    className = "",
    smallTabsIcon = false,
    noScroll = false,
    tabLinkFromID = (tabID) => "",
    children = undefined,
    classes: { body = "", bodyContent = "" } = {},
    lazyRendering = true, // render the component when the respective tab is active
    contentZeroPadding = false,
    onChange,
    selectedTabColor = undefined,
    mountAllProvidersAtOnce = true,
    backgroundColor = undefined,
    hideTabSelector = false,
    hideTabIndicator = false,
  } = props

  const c = useStyles({
    contentZeroPadding,
    backgroundColor,
    selectedTabColor,
    hideIndicator: hideTabIndicator,
  })

  const tabsArray = useMemo(() => {
    if (!propsTabs) return []

    if (Array.isArray(propsTabs)) return propsTabs

    if (typeof propsTabs === "object") {
      return Object.entries(propsTabs).map(([id, tabProps]) => {
        return { id, ...(tabProps || {}) }
      })
    }

    return []
  }, [propsTabs])

  const tabs = useTabs({ propsTabs: tabsArray, tabLinkFromID })

  const { inputValue: tabID, setInputValue: setTabID } = useInputController({
    value: propsTabID || (tabs || [])[0]?.id,
    onChange,
  })

  const components = {
    tabs: !hideTabSelector && (
      <Tabs
        tabs={tabs}
        classes={{
          muiTabs: {
            root: c.muiTabsRoot,
            indicator: c.muiTabIndicator,
          },
          muiTab: {
            root: clsx(c.muiTabRoot, smallTabsIcon && c.smallMuiTabRoot),
            selected: c.muiTabSelected,
          },
        }}
        elevation={0}
        currentTabID={tabID}
        onChange={setTabID}
      />
    ),
    body: (
      <Views
        lazyRendering={lazyRendering}
        currentViewID={tabID}
        noScroll={noScroll}
        views={tabs}
        className={clsx(c.body, props.classes?.bodyRoot)}
        classes={{ view: body, viewContent: clsx(c.bodyContent, bodyContent) }}
      />
    ),
  }

  const getProvider = (tab) => get("Provider")(tab) || get("provider")(tab)

  const Providers = flow(map(getProvider), filter(Boolean), defaultTo([]))(tabs)

  const defaultComponent = (
    <>
      {components.tabs}
      {components.body}
    </>
  )

  return (
    <div className={clsx(c.tabsWithBody, className)} style={props.style || {}}>
      {(function Content() {
        const content =
          typeof children === "function"
            ? children({ components, defaultComponent, setTabID, tabID })
            : defaultComponent

        if (!_.get(Providers, "length")) return content

        if (mountAllProvidersAtOnce)
          return (
            <ComposeProviders Providers={Providers}>{content}</ComposeProviders>
          )

        const CurrentProvider = flow(
          find((tab) => (tab || {}).id === tabID),
          getProvider
        )(tabs)

        if (React.isValidElement(CurrentProvider))
          return React.cloneElement<{ children: React.ReactNode }>(
            CurrentProvider,
            { children: content }
          )

        if (_.isFunction(CurrentProvider))
          return <CurrentProvider>{content}</CurrentProvider>

        return content
      })()}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    tabsWithBody: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    bodyContent: {
      "&>*": {
        // padding: theme.spacing(3, 2, 0)
        height: "100%",
        padding: ({ contentZeroPadding }) =>
          contentZeroPadding ? 0 : theme.spacing(3, 2, 0),
      },
    },
    body: {
      flex: 1,
      overflowY: "auto",
    },

    muiTabsRoot: {
      backgroundColor: ({ backgroundColor }) => backgroundColor,
      // backgroundColor: "red",
    },
    muiTabRoot: {
      // minWidth: ({ smallTabsIcon }) => {
      //   return smallTabsIcon &&
      // },
    },

    muiTabIndicator: {
      backgroundColor: ({ selectedTabColor }) => selectedTabColor,
      display: ({ hideIndicator }) => (hideIndicator ? "none" : undefined),
    },

    muiTabSelected: {
      color: ({ selectedTabColor }) => `${selectedTabColor} !important`,
    },

    smallMuiTabRoot: {
      minWidth: `84px !important`,
    },
  }
})

function useTabs({ propsTabs, tabLinkFromID }) {
  return React.useMemo(() => {
    return (propsTabs || []).map((t) => ({
      ...t,
      link: tabLinkFromID(t.id) || t.link,
    }))
  }, [propsTabs, tabLinkFromID])
}

function useTabID({ initialTabID, tabs }) {
  const [tabID, setTabID] = useState()
  const firstTabID = _.get(tabs, "0.id")

  useEffect(() => {
    if (!initialTabID) return
    setTabID(initialTabID)
  }, [initialTabID])

  useEffect(() => {
    //set default tabID
    setTabID((oldTabID) => oldTabID || firstTabID)
  }, [firstTabID])

  return [tabID, setTabID]
}
