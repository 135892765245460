import { Freezer } from "components/Freezer/Freezer"
import React from "react"
import { StyledProps } from "types/type"
import { ShopOrderFormContext } from "../../ShopOrderFormContext"

export function FormFreezer(
  props: { children: React.ReactNode; freezed?: boolean } & StyledProps
) {
  const api = React.useContext(ShopOrderFormContext)

  return (
    <Freezer
      freezed={props.freezed ?? !!api.readOnly}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </Freezer>
  )
}
