import { makeStyles } from "@material-ui/styles"
import { Typography, Box, Button } from "@material-ui/core"
import useOnChange from "hooks/useOnChange"
import SaveButtons from "../SaveButtons/SaveButtons"
import React, { useEffect, useState } from "react"
import BasicForm from "components/BasicForm/BasicForm"

const GRID = { xs: 12 }

export default function AddNew({
  onClose,
  fieldset,
  onSubmit,
  title,
  onChangeLoadingState,
  renderButtons = undefined,
}) {
  const c = useStyles({})

  const [isLoading, setIsLoading] = useState(false)

  onChangeLoadingState = (() => {
    if (typeof onChangeLoadingState === "function") return onChangeLoadingState
    return () => {}
  })()

  useOnChange({ value: isLoading, onChange: onChangeLoadingState })

  useEffect(() => {
    return () => onChangeLoadingState(false)
  }, [])

  return (
    <div className={c.addNew}>
      <Typography variant="h6" className={c.title}>
        {title}
      </Typography>
      <BasicForm
        fieldset={fieldset}
        grid={GRID}
        spacing={3}
        onSubmit={onSubmit}
      >
        {({ formik, components: { fieldsets } }) => {
          const submit = async () => {
            setIsLoading(true)
            await formik.submitForm()
            setIsLoading(false)
          }

          return (
            <>
              {fieldsets}
              <SaveButtons form={formik} submit={submit} onClose={onClose}>
                {renderButtons}
              </SaveButtons>
            </>
          )
        }}
      </BasicForm>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    addNew: {},
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(8),
      "&>*": {
        marginLeft: theme.spacing(1),
      },
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    submitButton: {
      marginTop: theme.spacing(3),
    },
  }
})
