import { Button, ButtonProps, Icon } from "@material-ui/core"
import React from "react"
import clsx from "clsx"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useActiveResources from "hooks/useActiveResources"
import { AudibleNotificationsContext } from "../../AudibleNotification"

export function AudibleNotificationToggleButton(props: {
  buttonProps?: ButtonProps
  className?: string
}) {
  const t = useI18n()

  const { workspaceID } = useActiveResources()

  const audioAPI = React.useContext(AudibleNotificationsContext)

  const { muteAPI } = audioAPI

  const icon = {
    SOUND_IS_ON: { label: t.soundIsOn, iconName: "volume_up" },
    SOUND_IS_OFF: { label: t.soundIsOff, iconName: "volume_off" },
  }

  type Actions = keyof typeof icon

  const s: Actions = muteAPI.isWorkspaceMuted({ workspaceID })
    ? "SOUND_IS_OFF"
    : "SOUND_IS_ON"

  return (
    <>
      <Button
        variant="outlined"
        className={clsx(props.className, "install-button")}
        color={props.buttonProps?.color || "inherit"}
        {...props.buttonProps}
        onClick={() => muteAPI.toggleWorkspaceMute({ workspaceID })}
      >
        <Icon className="icon">{icon[s].iconName}</Icon>
        {icon[s].label}
      </Button>
    </>
  )
}
