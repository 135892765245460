import React from "react"
import { ObjectWithID } from "types/type"
import EntryStatus from "../components/EntryStatus/EntryStatus"
import EntryListItem from "../components/EntryListItem/EntryListItem"

export interface DocumentsDashboardParams {
  EntryListItem?: typeof EntryListItem
  EntryStatus?: typeof EntryStatus
  onSaveEdition?: (
    newObject: Object | null,
    o?: { activeID: String | null }
  ) => any
  selectedEntryID: string | null
  detailedSelectedEntry?: ObjectWithID | null | undefined
  detailsTabsRoot: React.ReactNode
  entriesAreLoaded?: boolean
  entries?: Array<ObjectWithID> | null
  fetchMore: () => any
  renderRightOnOverlay?: boolean
  selectedEntry?: ObjectWithID | null
  selectedEntryPartner?: ObjectWithID | null
  detailsOverlayAnchor?: Element | null
  selectedEntryDocument?: ObjectWithID | null
}

export interface DocumentsDashboardInternalProps {
  setActiveID: (id: string | null) => any
  resetSelection: () => any
  onSaveEdition(object: Object, props?: { activeID: string | null }): any
  headersMinHeight: number | undefined
  selectedEntryColor: string | undefined
}

export interface IDocumentsDashboardContext
  extends DocumentsDashboardParams,
    DocumentsDashboardInternalProps {}

const DocumentsDashboardContext =
  React.createContext<IDocumentsDashboardContext>({
    setActiveID(id: string) {},
    selectedEntryID: null,
    detailsTabsRoot: null,
    resetSelection() {},
    selectedEntry: null,
    selectedEntryPartner: null,
    selectedEntryDocument: null,
    entries: null,
    fetchMore() {},
    selectedEntryColor: undefined,
    headersMinHeight: undefined,
    renderRightOnOverlay: false,
    onSaveEdition(object) {
      return Promise.resolve()
    },
  })

DocumentsDashboardContext.displayName = "DocumentsDashboardContext"

export default DocumentsDashboardContext
