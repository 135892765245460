// import { ItemTemplate } from "components/PageBoard/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import UseMemo from "components/UseMemo/UseMemo"
import { ShopOrderAdditionalInfoInput } from "lib/graphql/types"
import React from "react"
import { ItemTemplate } from "../ItemTemplate/ItemTemplate"
import { PageBoardItemContext } from "../PageBoardItemContext"
import { ShopOrderForm } from "../ShopOrderForm/ShopOrderForm"
import { Body } from "./components/Body/Body"
import { Title } from "./components/Title/Title"
import {
  ShopOrderPageBoardItemContext,
  ShopOrderPageBoardItemProps,
  ShopOrderPageBoardItemProvider,
} from "./ShopOrderPageBoardItemContext"

export type { ShopOrderPageBoardItemProps } from "./ShopOrderPageBoardItemContext"

export function ShopOrderPageBoardItem(props: ShopOrderPageBoardItemProps) {
  const api = React.useContext(PageBoardItemContext)

  const item = api.item

  if (item?.__typename !== "ShopOrderPageBoardItem") return null

  return (
    <ItemTemplate.Provider onClose={props.onClose}>
      <ShopOrderPageBoardItemProvider {...props} item={item}>
        <ShopOrderPageBoardItemContext.Consumer>
          {(api) => {
            return (
              <>
                <UseMemo
                  factory={() => {
                    const order = api.order

                    const a: ShopOrderAdditionalInfoInput = {
                      partnerParams: { partnerID: order?.partnerID || "" },
                      address: {
                        street: order?.address?.street || "",
                        country: order?.address?.country || "",
                        name1: order?.address?.name1 || "",
                        name2: order?.address?.name2 || "",
                        city: order?.address?.city || "",
                        zip: order?.address?.zip || "",
                      },
                      commission: order?.commission || "",
                      deliveryDate: order?.deliveryDate || "",
                      companyOrderId: order?.companyOrderId || "",
                    }

                    return a
                  }}
                  deps={[JSON.stringify(api.order)]}
                >
                  {(a) => {
                    return (
                      <ShopOrderForm.Provider
                        initialFormValues={a}
                        articles={api.articles}
                        getQuantity={api.getQuantity}
                        readOnly={!!api.order?.closedAt}
                        onSubmit={() => {}}
                      >
                        <ItemTemplate.Root
                          EnhancedPageProps={{
                            titleComponent: <Title></Title>,
                            body: <Body></Body>,
                          }}
                        ></ItemTemplate.Root>
                      </ShopOrderForm.Provider>
                    )
                  }}
                </UseMemo>
              </>
            )
          }}
        </ShopOrderPageBoardItemContext.Consumer>
      </ShopOrderPageBoardItemProvider>
    </ItemTemplate.Provider>
  )
}
