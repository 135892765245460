export enum PartnersTabsIDs {
  DOCUMENTS = "documents",
  UPLOAD = "upload",
  PARTNER = "partner",
  ORDER_TEMPLATES = "order-template",
  CONTACTS = "contacts",
  CONTACT = "contact",
}

export enum PartnersModalIDs {
  NEW_PARTNER = "new-partner",
  NEW_CONTACT = "new-contact",
}

export interface PartnersParams {
  rootContainer?: Element | null
  collectionID: string | null
  documentID: string | null
  searchedTerm: string | null
  types: Array<string> | null
}
