import InviteRoomMetaCard from "./components/InviteMembersMetaCard/InviteMembersMetaCard"
import RoomWall from "components/Room/components/RoomWall/RoomWall"

export default function InviteMembersWall() {
  return (
    <RoomWall
      className="invite-members-wall"
      bottomRight={<InviteRoomMetaCard></InviteRoomMetaCard>}
    ></RoomWall>
  )
}
