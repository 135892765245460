import * as Yup from "yup"
import { useI18n } from "contexts/i18nContext/i18nContext"
import BasicForm from "components/BasicForm/BasicForm"
import React, { useMemo } from "react"
import { Box, useTheme } from "@material-ui/core"
import { fieldsetWithDefaultValue } from "components/Form/util"
import useUpdateDetailedContact from "hooks/useUpdateDetailedContact/useUpdateDetailedContact"
import useOnChange from "hooks/useOnChange"
import SaveAndOpenContactButton from "../SaveAndOpenContactButton/SaveAndOpenContactButton"

export default function EditSelectedContactForm({
  selectedContact,
  onUpdate,
  onChangeLoadingState
}) {
  const theme = useTheme()
  const fieldset = useFieldset({ selectedContact })
  const {
    updateDetailedContact,
    isLoading,
    cacheUpdateDone
  } = useUpdateDetailedContact({
    contactID: selectedContact.id
  })

  useOnChange({ value: isLoading, onChange: onChangeLoadingState })

  const onSubmit = React.useCallback(
    async data => {
      await updateDetailedContact({ dataUpdate: data })
      onUpdate()
    },
    [onUpdate, updateDetailedContact]
  )

  return (
    <BasicForm fieldset={fieldset} onSubmit={onSubmit}>
      {({ components: { fieldsets, submitButton }, readyToSubmit, formik }) => {
        return (
          <>
            {fieldsets}
            <Box display="flex" justifyContent="flex-end" marginTop={6}>
              <SaveAndOpenContactButton
                contactID={selectedContact.id}
                style={{ marginRight: theme.spacing(1) }}
                cacheUpdateDone={cacheUpdateDone}
                readyToSubmit={readyToSubmit}
                submitForm={formik.submitForm.bind(formik)}
              ></SaveAndOpenContactButton>
              {submitButton}
            </Box>
          </>
        )
      }}
    </BasicForm>
  )
}

function useFieldset({ selectedContact }) {
  const t = useI18n()

  const fieldset = [
    { name: "firstName", label: t.firstName },
    {
      name: "lastName",
      label: t.lastName,
      schema: Yup.string().required(t.required)
    },
    { name: "position", label: t.position },
    { name: "email", label: t.email, schema: Yup.string().email(t.wrongEmail) },
    { name: "landline", label: t.landline },
    { name: "mobile", label: t.mobile }
  ]

  return useMemo(
    () =>
      fieldsetWithDefaultValue({
        data: selectedContact,
        fieldset
      }),
    [fieldset, selectedContact]
  )
}
