import { useAddNewPartnerAPI } from "components/AddNewKontakt/components/AddNewPartner/AddNewPartnerProvider"
import contextAndProviderFactory from "lib/contextAndProviderFactory"

const Factory = contextAndProviderFactory({
  hookApi: useAddNewPartnerAPI,
  contextName: "AddNewPartnerFormContext",
})

export const AddNewPartnerFormContext = Factory.Context
export const AddNewPartnerFormProvider = Factory.Provider
export const AddNewPartnerFormConsumer = Factory.Consumer
