import KeyListeners from "components/KeyListeners/KeyListeners"
import useMemoAPI from "hooks/useMemoAPI"
import useTrueFalse from "hooks/useTrueFalse"
import { useSubscription } from "observable-hooks"
import React from "react"
import { Subject } from "rxjs"

export type PageComponentProps = {
  onClose: () => any
  toggleVisibility?: () => any
  hide?: () => any
}

export type PageComponent = React.FC<PageComponentProps>

export type OpenPageProps = { Page: PageComponent; id?: string }

export type RenderView = (api: { closePage: () => any }) => View | null

export type SecondPageAPIInput = {
  enableEscapeKey?: boolean
  view?: View | null | RenderView
}

type View = {
  id?: string
  component: React.ReactNode
}

export default function useSecondPage(props?: SecondPageAPIInput) {
  const closePage$ = React.useRef(new Subject<void>()).current

  const closeView = React.useCallback(() => closePage$.next(), [])

  const [view, setView] = React.useState<View | null>(() => {
    if (!props?.view) return null
    if (typeof props.view === "function")
      return props.view({ closePage: closeView })
    return props.view
  })

  const hidden = useTrueFalse(true)

  useSubscription(
    closePage$,
    React.useCallback(() => setView(null), [setView])
  )

  KeyListeners.useEscapeListener(closeView, {
    enabled: !!view && !!props?.enableEscapeKey,
  })

  // React.useEffect(() => {
  //   if (!view) return

  //   hidden.setFalse()
  // }, [!view])

  const openPage = React.useCallback(
    function openPage(p: OpenPageProps) {
      hidden.setFalse()

      setTimeout(() => {
        setView({
          id: p.id || "",
          component: (
            <p.Page
              onClose={closeView}
              hide={() => hidden.setTrue()}
              toggleVisibility={() => hidden.toggle()}
            ></p.Page>
          ),
        })
      }, 0)
    },

    [closeView]
  )

  return useMemoAPI({
    openPage,
    closePage: closeView,
    view: view?.component,
    viewID: view?.id,
    isOpen: !!view,
    hidden,
    closePage$,
  })
}
