import tldEnum from "tld-enum"

export default function formatTextURLs(text) {
  // const [domainInPotential] = text.match(/(?<=\.)\w{2,}(?=$|\/|\s|\?|#)/) || []
  const [domainInPotential] = [""]

  //tldEnum.list -> list of all top-level domains available
  const allDomains = tldEnum.list
  if (!allDomains.includes(domainInPotential)) return text

  // const t = text.replace(
  //   /(?<=(\>|\s|^))(?<!(https?:\/\/))(([0-9a-z\-_]+\.)+\w{2,})/gi,
  //   "https://$3"
  // )

  // return t
  return ""
}
