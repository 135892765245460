import ClippedText from "components/ClippedText/ClippedText"
import { EnhancedPageProps } from "components/EnhancedPage/EnhancedPage"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import { DocumentFormPage } from "../DocumentFormPage/DocumentFormPage"
// import DocumentFormContext from "components/OldDocumentForm/contexts/DocumentFormContext"
import { getSecondPage } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/SecondPage/getSecondPage"
import Pdf from "components/Pdf/Pdf"
import React from "react"
import { HeaderIconButton } from "../../../HeaderIconButton"
import { RelatedItemsIconButtons } from "../../../RelatedItemsIconButtons/RelatedItemsIconButtons"
import { DocumentPageBoardItemContext } from "../../DocumentPageBoardItemContext"

export function usePagePdfProps(props: {
  onClose: () => any
  ControlProps?: React.ComponentProps<typeof Pdf.Controls>
  showInfoButton?: boolean
}) {
  const api = React.useContext(DocumentPageBoardItemContext)

  const renderTitleLine = React.useCallback<
    Exclude<EnhancedPageProps["renderTitleLine"], undefined>
  >(
    function render({ titleComponent }) {
      return (
        <>
          <ItemTemplate.Context.Consumer>
            {(template) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ClippedText maxWidth={180}>{titleComponent}</ClippedText>
                  {props.showInfoButton && (
                    <HeaderIconButton
                      name="info_outlined"
                      onClick={() =>
                        template.twoPagesAPI?.openFullPage(DocumentFormPage)
                      }
                    ></HeaderIconButton>
                  )}
                  {api.item && (
                    <RelatedItemsIconButtons
                      item={api.item}
                      onClick={(itemParams) => {
                        template.twoPagesAPI?.openFullPage(
                          getSecondPage({ itemParams })
                        )
                      }}
                    ></RelatedItemsIconButtons>
                  )}
                </div>
              )
            }}
          </ItemTemplate.Context.Consumer>
          <div>
            <ItemTemplate.Context.Consumer>
              {(templatePageApi) => {
                const hideIcons = templatePageApi.rootContainer.width < 600

                return (
                  <>
                    <Pdf.Controls
                      {...(props.ControlProps || {})}
                      {...(hideIcons
                        ? {
                            renderIcons() {
                              return []
                            },
                          }
                        : {})}
                      IconButtonProps={{
                        size: "small",
                        ...(props.ControlProps?.IconButtonProps || {}),
                      }}
                    ></Pdf.Controls>
                  </>
                )
              }}
            </ItemTemplate.Context.Consumer>
          </div>
        </>
      )
    },
    [props.onClose, props.ControlProps, props.showInfoButton]
  )

  const a: EnhancedPageProps = {
    onClose: props.onClose,
    title: api.document?.title || "",
    className: "pdf-page",
    // style: { height: "100%" },
    renderTitleLine,
    body: React.useMemo(
      () => (
        <div style={{ marginBottom: 20 }}>
          <Pdf.Body style={{ padding: 20, marginBottom: 24 }}></Pdf.Body>
        </div>
      ),
      [api.document?.pdf]
    ),
  }

  return a
}
