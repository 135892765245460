import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import arePropsEqual from "util/arePropsEqual"
import PaperListApiContext from "../../contexts/PaperListContext"
import { List } from "../../../List/List"
import { PaperListRootItem } from "./PaperListRootItem"

interface PaperListRootProps {
  className?: string
  classes?: { itemPaper?: string }
  marginZero?: boolean
  style?: React.CSSProperties
}

export default React.memo(
  PaperListRoot,
  arePropsEqual<PaperListRootProps>([
    "className",
    (a) => a.classes?.itemPaper,
    "marginZero",
    (a) => JSON.stringify(a.style),
  ])
)

function PaperListRoot(props: PaperListRootProps) {
  const { className = "" } = props
  const ctx = React.useContext(PaperListApiContext)

  const { containerRef: rootContainerRef } = React.useContext(
    ctx.RootContainer.Context
  )

  const c = useStyles({})

  const itemContent = React.useCallback((index: number) => {
    return <MemoizedItem index={index} key={index}></MemoizedItem>
  }, [])

  return (
    <div
      className={clsx("paper-list-root", c.root, className)}
      ref={rootContainerRef}
      style={props.style}
    >
      <List.Root itemContent={itemContent}></List.Root>
    </div>
  )
}

const MemoizedItem = React.memo(PaperListRootItem, arePropsEqual(["index"]))

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      maxHeight: "100%",
      height: "100%",
      "&>*": {
        height: "100%",
        maxHeight: "100%",
      },
    },
    list: {
      padding: 0,
    },

    itemPaper: {
      borderRadius: 0,
      // margin: theme.spacing(0, 2),
    },

    firstItemPaper: {
      borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    },

    space: {
      margin: 0,
      height: theme.spacing(2),
    },
    lastItemPaper: {
      borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    },
    marginZero: {
      margin: "0 !important",
    },
  }
})
