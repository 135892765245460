import useMemoAPI from "hooks/useMemoAPI"
import { CartFragment } from "lib/graphql/operations"
import React from "react"

export function useCartQuantity({
  cart,
}: {
  cart: CartFragment | null | undefined
}) {
  const getQuantity = React.useCallback(
    function getQuantity({ articleID = "" }: { articleID: string }) {
      return (
        cart?.items?.find((item) => item.article?.id === articleID)?.quantity ||
        0
      )
    },
    [cart?.items]
  )

  const quantity = React.useMemo(() => {
    return cart?.items?.reduce((acc, item) => {
      return acc + item.quantity
    }, 0)
  }, [cart])

  return useMemoAPI({ quantity, getQuantity })
}
