import React from "react"
import SuccessFeedback from "./components/SuccessFeedback/SuccessFeedback"
import { LibProvider } from "./context/libContext"
import { SelectedFileProvider } from "./context/SelectedFileContext"
import { UploadProvider } from "./context/UploadContext"
import transformOnDropFile from "./util/transformOnDropFile"
import { Alert } from "@material-ui/lab"
import clsx from "clsx"
import LeftSnackbar from "components/LeftSnackbar/LeftSnackbar"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useDocumentsTypesTranslations from "hooks/useDocumentsTypesTranslations/useDocumentsTypesTranslations"
import usePublicAppConfig from "hooks/usePublicAppConfig"
import Dropzone from "../Dropzone/Dropzone"
import UploadSteps from "./components/UploadSteps/UploadSteps"
import useAddFilesToContext from "./hooks/useAddFilesToContext"
import useUploadFiles from "./hooks/useUploadFiles"

function Upload({
  className: propsClassName = "",
  defaultUploadType = "",
  partnerID = "",
  collectionsIDs = [],
  onChangeLoadingState,
}: {
  className?: string
  defaultUploadType?: string
  partnerID?: string
  collectionsIDs?: string[]
  onChangeLoadingState?: (isLoading: boolean) => void
}) {
  const { uploadFiles, uploadDone, resetUpload, amountUploaded, loading } =
    useUploadFiles({
      partnerID,
      collectionsIDs,
      onChangeLoadingState,
    })

  const { documentsTypesTranslations: fileTypesAvailable } =
    useDocumentsTypesTranslations()

  const className = clsx("upload", propsClassName)

  const utils = React.useMemo(() => ({ uploadFiles }), [uploadFiles])

  const params = React.useMemo(
    () => ({ fileTypesAvailable, defaultUploadType, amountUploaded, loading }),
    [
      //TODO: move to UploadContext (see: https://trello.com/c/yWpwMUBZ/493-15-hour-replace-the-name-of-uploadcontext-by-filescontext-and-create-an-uploadcontext)
      amountUploaded,
      loading,

      //params
      fileTypesAvailable,
      defaultUploadType,
    ]
  )

  const [showFileLimitMessage, setShowFileLimitMessage] = React.useState(false)

  const t = useI18n()

  const { fileMaxSizeInMB } = usePublicAppConfig() || {}

  if (typeof fileMaxSizeInMB === "undefined") return null

  if (uploadDone) {
    return (
      <SuccessFeedback onClickComeback={resetUpload} className={className} />
    )
  }

  return (
    <LibProvider utils={utils} params={params}>
      <UploadProvider transformOnDropFile={transformOnDropFile}>
        <SelectedFileProvider>
          <>
            <DropzoneProvider
              accept="application/pdf"
              multiple
              maxSize={fileMaxSizeInMB * Math.pow(10, 6)}
              onDropFileOverLimit={() => setShowFileLimitMessage(true)}
            >
              <Dropzone.Root className={className}>
                <UploadSteps />
              </Dropzone.Root>
            </DropzoneProvider>
            <LeftSnackbar
              open={showFileLimitMessage}
              onClose={() => setShowFileLimitMessage(false)}
              autoHideDuration={15000}
            >
              <Alert
                severity="error"
                variant="filled"
                onClose={() => setShowFileLimitMessage(false)}
              >
                {t.fileLimitMessage(fileMaxSizeInMB)}
              </Alert>
            </LeftSnackbar>
          </>
        </SelectedFileProvider>
      </UploadProvider>
    </LibProvider>
  )
}

export default Upload

function DropzoneProvider({
  children,
  multiple,
  accept,
  maxSize,
  onDropFileOverLimit,
}) {
  const addFilesToContext = useAddFilesToContext()

  return (
    <Dropzone.Provider
      onDrop={(files) => {
        const passedSizeLimit = () => files.some((file) => file.size > maxSize)

        if (typeof maxSize !== "undefined" && passedSizeLimit()) {
          return onDropFileOverLimit()
        }

        addFilesToContext(files)
      }}
      multiple={multiple}
      accept={accept}
    >
      {children}
    </Dropzone.Provider>
  )
}
