import PaperList from "components/PaperList"
import { ItemPaperProps } from "components/PaperList/types"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { DocumentFragment } from "lib/graphql/operations"
import React from "react"

export type DocumentsListApiInput = {
  documents?: DocumentFragment[]
  fetchMore?: () => void
  ItemPaperProps?: ItemPaperProps
}
const ContextAndProvider = contextAndProviderFactory({
  contextName: "DocumentsListContext",
  hookApi: function useDocumentsListApi(props?: DocumentsListApiInput) {
    const [activeID, setActiveID] = React.useState<string | null>(null)
    return { ...(props || {}), activeID, setActiveID }
  },
})
export const DocumentsListProvider = ContextAndProvider.Provider
export const DocumentsListContext = ContextAndProvider.Context
