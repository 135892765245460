import { fromEvent } from "rxjs"
import { Popover } from "@material-ui/core"
import React, { useEffect, useState, useRef, useImperativeHandle } from "react"
import popoverPositioning from "util/popoverPositioning/popoverPositioning"
import clsx from "clsx"
import KeyListeners from "components/KeyListeners/KeyListeners"
// import { KeyListener } from '../KeyListeners/hooks/useKeyListenersAPI';

function ContentPopover(
  { children, content, showEvent, className = "" },
  externalRef
) {
  const [anchorPosition, setAnchorPosition] = useState(null)
  const [anchor, setAnchor] = useState(null)
  const isOpen = !!anchorPosition

  const childRef = useRef(null)

  const closePopover = React.useCallback(() => {
    // console.log("bingo")
    setAnchorPosition(null)
    setAnchor(null)
  }, [])

  // console.log(isOpen)
  // KeyListeners.useEscapeListener(closePopover, { enabled: isOpen })

  useImperativeHandle(externalRef, () => ({
    closePopover,
    openPopover({ anchorAbsoluteX, anchorAbsoluteY }) {
      setAnchorPosition({ x: anchorAbsoluteX, y: anchorAbsoluteY })
    },
  }))

  useEffect(() => {
    const getEventPosition = {
      contextmenu(e) {
        return { x: e.clientX, y: e.clientY }
      },
      "long-press"(e) {
        return { x: e.detail.clientX, y: e.detail.clientY }
      },
    }

    const sub = fromEvent(childRef.current, showEvent).subscribe((e) => {
      e.preventDefault()
      const position = getEventPosition[showEvent](e)
      setAnchorPosition(position)
    })

    const windowSub = fromEvent(window, "click").subscribe(() => {
      setAnchorPosition(null)
      setAnchor(null)
    })

    sub.add(windowSub)

    return sub.unsubscribe.bind(sub)
  }, [showEvent])

  return (
    // </ClickAwayListener>
    <div className={clsx("content-popover", className)}>
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ position: "relative" }}
      >
        <div ref={childRef}>{children}</div>
        {anchorPosition && (
          <div
            ref={setAnchor}
            style={{
              position: "absolute",
              ...(() => {
                const { x: childX, y: childY } =
                  childRef.current.getBoundingClientRect()

                return {
                  left: anchorPosition.x - childX,
                  top: anchorPosition.y - childY,
                }
              })(),
            }}
          />
        )}
      </div>
      <Popover
        {...popoverPositioning({ anchorEl: anchor })}
        anchorEl={anchor}
        open={isOpen}
      >
        {content}
      </Popover>
    </div>
  )
}

export default React.forwardRef(ContentPopover)
