import { useApolloClient, gql } from "@apollo/client";
import { MessageFragment, MessageFragmentDoc } from "lib/graphql/operations"
import { useCallback } from "react"
import getApolloInterfaceTypename from "lib/getApolloInterfaceTypename"

//REMOVE
export default function useGetMessage() {
  const client = useApolloClient()

  return useCallback(({ messageID }) => {
    if (!messageID) throw new Error("missing messageID")

    const __typename = getApolloInterfaceTypename(
      {
        interfaceName: "Message",
        id: messageID,
      },
      client
    )

    let message

    try {
      message = client.readFragment<MessageFragment>({
        fragment: MessageFragmentDoc,
        fragmentName: "Message",
        id: `${__typename}:${messageID}`,
      })
    } catch (e) {
      console.error(e)
    }

    return (message as MessageFragment) || null
  }, [])
}
