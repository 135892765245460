import useDocumentSelection from "hooks/useDocumentSelection"
import contextAndProviderFactory from "lib/contextAndProviderFactory"

const Factory = contextAndProviderFactory({
  hookApi: useDocumentSelection,
})

const Context = Factory.Context

export default {
  Provider: Factory.Provider,
  Context,
}
