import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { useContactFormAPI } from "./useContactFormAPI"

const { Context, Provider } = contextAndProviderFactory({
  hookApi: useContactFormAPI,
  contextName: "ContactForm",
})

export const ContactFormContext = Context
export const ContactFormProvider = Provider
