import React from "react"

import useMessagesProviderValue from "../hooks/useMessagesProviderValue"

type MessageAPI = ReturnType<typeof useMessagesProviderValue>

const MessagesContext = React.createContext({} as MessageAPI)

MessagesContext.displayName = "MessagesContext"

export default MessagesContext
