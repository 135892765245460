import _ from "lodash"
import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Typography } from "@material-ui/core"
import useGlobalStyles, { alignmentUnits } from "styles/globalStyles"
import clsx from "clsx"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"

function HeaderDescription({
  avatar,
  firstLine: propsFirstLine,
  secondLine,
  thirdLine,
  renderFirstLine,
}) {
  const { isSmallMobile } = React.useContext(DeviceObserver.Context)
  const c = useStyles({ isSmallMobile })
  const gs = useGlobalStyles()

  const firstLine = propsFirstLine && (
    <Typography variant="body1" className={clsx(gs.clipText, c.maxWidth)}>
      {propsFirstLine}
    </Typography>
  )

  return (
    <div className={c.headerDescription}>
      {avatar}

      <div className={c.info}>
        {_.isFunction(renderFirstLine)
          ? renderFirstLine({ defaultComponent: firstLine })
          : firstLine}
        {secondLine && (
          <Typography variant="body2" color="textSecondary">
            {secondLine}
          </Typography>
        )}
        {thirdLine && (
          <Typography variant="body2" color="textSecondary">
            {thirdLine}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default HeaderDescription

const useStyles = makeStyles((theme) => {
  return {
    maxWidth: {
      maxWidth: ({ isSmallMobile }) => isSmallMobile && 78,
    },
    headerDescription: {
      display: "flex",
      alignItems: "center",
      "&>*:first-child": {
        marginRight: theme.spacing(2),
      },
    },
    alignment: {
      maxWidth: theme.spacing(112),
      margin: "0 auto",
      paddingLeft: theme.spacing(alignmentUnits),
      paddingRight: theme.spacing(alignmentUnits),
    },
  }
})
