import { PageBoardItemParams } from "lib/graphql/types"
import { IndexedItems } from "./components/IndexedItems/IndexedItems"
import { IndexedItemsApiInput } from "./components/IndexedItems/types"
import PageBoardRoot, {
  PageBoardRootProps,
} from "./components/PageBoardRoot/PageBoardRoot"
import PageBoardContext, {
  PageBoardProvider,
} from "./contexts/PageBoardContext"
import { PageBoardAPIProps } from "./hooks/usePageBoardAPI"

export type PageBoardProps<
  Type extends PageBoardItemParams["type"] = PageBoardItemParams["type"]
> = {
  RootProps?: PageBoardRootProps
  IndexedItemsApiProps?: IndexedItemsApiInput<Type>
} & PageBoardAPIProps

export default function PageBoard<Type extends PageBoardItemParams["type"]>(
  props: PageBoardProps<Type>
) {
  const { IndexedItemsApiProps, RootProps, ...ProviderProps } = props

  return (
    <IndexedItems.Provider {...props.IndexedItemsApiProps}>
      <PageBoardProvider {...ProviderProps}>
        <PageBoardContext.Consumer>
          {(pageBoardAPI) => {
            return (
              <IndexedItems.ItemsList.Provider
                variant={
                  pageBoardAPI.views.selectedID === "cards" ? "grid" : "list"
                }
                ListProps={{
                  fetchMore: pageBoardAPI.fetchMore,
                  totalCount: pageBoardAPI.pagination?.total || 0,
                  items: pageBoardAPI.pageBoardItems || [],
                }}
              >
                <PageBoardRoot {...(props.RootProps || {})}></PageBoardRoot>
                <IndexedItems.DateIntervalModal></IndexedItems.DateIntervalModal>
              </IndexedItems.ItemsList.Provider>
            )
          }}
        </PageBoardContext.Consumer>
      </PageBoardProvider>
    </IndexedItems.Provider>
  )
}
