import React from "react"
import useMemoAPI from "hooks/useMemoAPI"

export default function MemoAPI<T extends Object>(props: {
  api: T
  children: (props: T) => React.ReactNode
}) {
  const api = useMemoAPI(props.api)

  return <>{props.children(api)}</>
}
