import { ChipProps } from "@material-ui/core"
import { InlinedChips } from "components/InlinedChips/InlinedChips"
import { IndexedItemsContext } from "components/PageBoard/components/IndexedItems/IndexedItems"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { StyledProps } from "types/type"
import useTypesOptions from "../../../../hooks/useTypesOptions"

export function TypesChips(
  props: {
    children?: (p: { typesChipsProps: ChipProps[] }) => React.ReactNode
    types: PageBoardItemType[]
  } & StyledProps
) {
  const api = React.useContext(IndexedItemsContext)

  const types = props.types

  const options = useTypesOptions()

  const chipProps = (types || []).map((type) => {
    const onDelete = () => {
      api.types.delete(type)
    }

    const chipProps: ChipProps = {
      size: "small",
      key: type,
      label: (options[type]?.label || "").toUpperCase(),
      onClick: onDelete,
      onDelete: onDelete,
    }

    return chipProps
  })

  if (props.children) {
    return <>{props.children({ typesChipsProps: chipProps })}</>
  }

  if (!chipProps.length) return null

  return (
    <InlinedChips
      wrap
      chips={chipProps}
      className={props.className}
      style={props.style}
    ></InlinedChips>
  )
}
