import contextAndProviderFactory from "lib/contextAndProviderFactory"
import usePageBoardAPI, { PageBoardAPIProps } from "../hooks/usePageBoardAPI"

const Factory = contextAndProviderFactory({
  hookApi: usePageBoardAPI,
  contextName: "PageBoardContext",
})

const PageBoardContext = Factory.Context

export const PageBoardProvider = Factory.Provider

export type PageBoardProps = PageBoardAPIProps

export default PageBoardContext
