import { Button, Dialog, makeStyles, Typography } from "@material-ui/core"
import Input from "components/Input/Input"
import React from "react"
import clsx from "clsx"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function DeleteRoomModal(props: {
  visible: boolean
  deleteRoom: () => void
  onClose?: () => void
}) {
  const c = useStyles({})
  const deleteRoom = props.deleteRoom
  const [value, setValue] = React.useState<string>("")

  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (!!props.visible) return inputRef.current?.focus()
    setValue("")
  }, [props.visible])

  const t = useI18n()

  const word = "delete"

  return (
    <>
      <Dialog
        onClose={() => props.onClose?.call(false)}
        open={props.visible}
        onClick={(e) => e.stopPropagation()}
        classes={{
          paper: c.dialogPaper,
        }}
      >
        <Typography variant="h6" style={{ marginBottom: 16 }}>
          Warning
        </Typography>

        <Typography variant="body1" style={{ marginBottom: 8 }}>
          This is an irreversible operation.
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 8 }}>
          When completed, this room will be permanently deleted.
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 16 }}>
          <>
            If you are sure type <b>"{word}"</b> bellow:
          </>
        </Typography>

        <form
          style={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault()
            deleteRoom()
          }}
        >
          <Input.text
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{ width: "100%" }}
            // ref={}
            // style={{}}
            inputProps={{
              style: {
                width: "100%",
              },
            }}
            inputRef={inputRef}
          ></Input.text>
        </form>

        <div className={c.dialogButtons}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => props.onClose?.call(undefined)}
          >
            {t.cancel}
          </Button>
          <Button
            variant="outlined"
            size="small"
            disabled={value !== word}
            onClick={() => {
              props.onClose?.call(undefined)
              deleteRoom()
            }}
          >
            {t["delete"]}
          </Button>
        </div>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    // root: {
    //   width: "100%",
    // },
    // footer: {
    //   width: "100%",
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "flex-end",
    // },
    dialogPaper: {
      padding: theme.spacing(3),
      // maxWidth: 300,
    },
    dialogButtons: {
      width: "100%",
      marginTop: theme.spacing(4),

      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",

      "&>*": {
        marginLeft: theme.spacing(0.5),
      },
    },
  }
})
