import { useI18n } from "contexts/i18nContext/i18nContext"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import useForm, { UseFormProps } from "hooks/useForm"
import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import {
  GuestWorkspaceFragment,
  ShopArticleFragment,
  ShopOrderAddressFragment,
} from "lib/graphql/operations"
import {
  ShopOrderAdditionalInfoInput,
  ShopOrderAddress,
} from "lib/graphql/types"
import _ from "lodash"
import React from "react"
import { MakePropsOptionalAndNullable } from "types/type"
import * as Yup from "yup"

const Factory = contextAndProviderFactory({
  hookApi: useShopOrderForm,
})
export const ShopOrderFormContext = Factory.Context

export type FormType = Omit<
  ShopOrderAdditionalInfoInput,
  "address" | "partnerParams"
> & {
  address: ShopOrderAddressFragment | null
  partnerParams?: MakePropsOptionalAndNullable<PartnerParams>
}

export type FormProps = UseFormProps<FormType>

export type PartnerParams =
  | { itemID: string | null }
  | { partnerID: string | null }

export type ShopOrderFormProps = {
  readOnly?: boolean
  initialFormValues?: Partial<FormType>
  articles?: ShopArticleFragment[]
  getQuantity?: (props: { articleID: string }) => number
  onChange?: FormProps["onChange"]
  onSubmit?: Exclude<FormProps["onSubmit"], undefined>
  partnerIsEditable?: boolean
}

export const ShopOrderFormProvider = Factory.Provider

function useShopOrderForm(props: ShopOrderFormProps) {
  const { workspace } = useCurrentWorkspace()
  const guestPartnerID = (workspace as GuestWorkspaceFragment)?.partner?.id

  const initialValue = React.useMemo<FormType>(() => {
    return {
      partnerParams: undefined,
      address: props.initialFormValues?.address || {
        street: "",
        country: "GERMANY",
        name1: "",
        name2: "",
        city: "",
      },
      commission: "",
      deliveryDate: null,
      companyOrderId: "",
      ...(_.omit(props.initialFormValues, "address") || {}),
      ...(guestPartnerID
        ? { partnerParams: { partnerID: guestPartnerID } }
        : {}),
    }
  }, [JSON.stringify(props.initialFormValues), guestPartnerID])

  const t = useI18n()

  const onSubmit = React.useCallback<Exclude<FormProps["onSubmit"], undefined>>(
    async (a) => {
      if (!props.onSubmit) return

      if (!a.values.deliveryDate) {
        let cloned = JSON.parse(JSON.stringify(a)) as typeof a
        cloned.values.deliveryDate = null
        return props.onSubmit(cloned)
      }

      return props.onSubmit(a)
    },
    [props.onSubmit]
  )

  const form = useForm<FormType>({
    initialValues: initialValue,
    onSubmit,
    enableReinitialize: true,
    onChange: props.onChange,
    validationSchema: Yup.object().shape({
      partnerParams: Yup.mixed().test(
        "valid-shape",
        "partnerParams must have either partnerID or itemID",
        function (value) {
          if (value.hasOwnProperty("partnerID") && value.partnerID) {
            // Validate partnerID when it exists
            return Yup.object()
              .shape({ partnerID: Yup.string().required() })
              .isValidSync(value)
          }

          if (value.hasOwnProperty("itemID") && value.itemID) {
            // Validate itemID when it exists
            return Yup.object()
              .shape({ itemID: Yup.string().required() })
              .isValidSync(value)
          }

          return false // Neither partnerID nor itemID present
        }
      ),
      address: Yup.object()
        .shape({
          name1: Yup.string().required(t.required),
          street: Yup.string().required(t.required),
          city: Yup.string().required(t.required),
          zip: Yup.string().required(t.required),
          country: Yup.string().required(t.required),
        })
        .required(t.required),
    }),
  })

  return useMemoAPI({
    ...props,
    partnerIsEditable: (props.partnerIsEditable ?? true) && !props.readOnly,
    form,
  })
}
