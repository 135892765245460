import TwoPages from "components/TwoPages/TwoPages"
import React from "react"
import { ItemsListContext } from "../../context/ItemsListContext"
import { Subscriber } from "components/Observer"

export function ItemsListTwoPages(
  props: React.ComponentProps<typeof TwoPages.Root>
) {
  const api = React.useContext(ItemsListContext)

  return (
    <TwoPages.Provider>
      <TwoPages.Root
        style={{ height: "100%", ...props.style }}
        mainPage={<>{props.mainPage}</>}
      ></TwoPages.Root>
      <TwoPages.Context.Consumer>
        {(twoPagesApi) => {
          return (
            <Subscriber
              observable={api.itemsListTwoPagesApi.openPage$}
              subscription={(a) => {
                twoPagesApi[a.action].call(twoPagesApi, a.Page)
              }}
            ></Subscriber>
          )
        }}
      </TwoPages.Context.Consumer>
    </TwoPages.Provider>
  )
}
