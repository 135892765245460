export default function readWrite({ proxy, ...args }) {
  return {
    read() {
      try {
        if (args.fragment) {
          return proxy.readFragment(args)
        }

        return proxy.readQuery(args)
      } catch (e) {
        console.error(e)
        return
      }
    },
    write(data) {
      if (args.fragment) {
        return proxy.writeFragment({ ...args, data })
      }

      return proxy.writeQuery({ ...args, data })
    },
  }
}
