import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import { ConferenceContext } from "../../../../ConferenceContext"
import { FabControlButton } from "../FabControlButton/FabControlButton"

export function LeaveButton() {
  const api = React.useContext(ConferenceContext)

  const t = useI18n()

  const tooltipText = t.conference.leaveConference

  return (
    <FabControlButton
      toolTipTitle={tooltipText}
      icon="logout"
      onClick={api.leave}
    />
  )
}
