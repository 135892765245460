import { makeStyles } from "@material-ui/styles"
import React from "react"
import { Grid } from "@material-ui/core"
import SinglePagePDF from "../SinglePagePDF/SinglePagePDF"
import IconButton from "components/IconButton/IconButton"
import useFileSelectionManger from "../../hooks/useFileSelectionManager"
import clsx from "clsx"
import { selectionBorder } from "styles/globalStyles"
import { useUploadContext } from "../../context/UploadContext"

export default function UploadedGrid({ enableSelection, className }) {
  const { uploadedFiles } = useUploadContext()
  const {
    selectedFile: { id: selectedID } = {},
    selectFile
  } = useFileSelectionManger()

  const amount = (uploadedFiles || []).length

  const c = useStyles()

  return (
    <Grid container spacing={2} className={clsx("uploaded-grid", className)}>
      {(uploadedFiles || []).map(({ base64URL, id }, index) => {
        const isSelected = enableSelection && selectedID === id
        return (
          <Grid
            item
            xs={12 / (amount > 2 ? 3 : 2)}
            key={id}
            onClick={() => selectFile({ id })}
          >
            <DeleteWrapper
              fileID={id}
              className={clsx(enableSelection && c.cursorPointer)}
            >
              <SinglePagePDF
                elevation={2}
                url={base64URL}
                className={isSelected && c.pdfSelected}
              ></SinglePagePDF>
            </DeleteWrapper>
          </Grid>
        )
      })}
    </Grid>
  )
}

const useStyles = makeStyles(theme => {
  return {
    cursorPointer: {
      cursor: "pointer"
    },
    pdfSelected: {
      "& canvas": selectionBorder(theme)
    }
  }
})

function DeleteWrapper({ fileID, children, className }) {
  const c = useDeleteWrapperStyles()
  const { removeUploadedFile } = useUploadContext()

  return (
    <div className={clsx(c.deleteWrapper, className)}>
      <IconButton
        iconName="remove_circle"
        className={c.deleteButton}
        onClick={() => removeUploadedFile(fileID)}
        size="small"
      ></IconButton>
      {children}
    </div>
  )
}

const useDeleteWrapperStyles = makeStyles(theme => {
  return {
    deleteWrapper: {
      position: "relative"
    },
    deleteButton: {
      position: "absolute",
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
      zIndex: 1
    }
  }
})
