import { ConferenceActivity } from "components/ConferenceActivity/ConferenceActivityContext"
import { ZoomApiInput } from "components/Zoom/components/ZoomProvider/ZoomApiInputContext"
import { ZoomVideoMeeting } from "components/Zoom/components/ZoomVideoMeeting/ZoomVideoMeeting"
import { MeetingArgs } from "components/Zoom/context/MeetingArgsContext"
import { useAppHttpGet } from "hooks/useAppHttpGet/useAppHttpGet"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { StyledProps } from "types/type"

function useRoomZoomMeetingArgs(props: { roomID: string }) {
  const httpGet = useAppHttpGet()

  // const [signature, setSignature] = React.useState<string | null>(null)
  const [meetingArgs, setMeetingArgs] = React.useState<{
    signature: string
    topic: string
    userIdentity: string
  } | null>(null)

  React.useEffect(() => {
    httpGet(`/zoom/meeting-args/room?roomID=${props.roomID}`)
      .then((res) => res.json())
      .then((data) => {
        setMeetingArgs(data)
      })
  }, [props.roomID])

  return meetingArgs
}

export function RoomVideoConference(
  props: {
    roomID: string
    onLeave?: () => void
    children?: (props: {
      meetingArgs: MeetingArgs
      onLeave: Exclude<ZoomApiInput["manageLeaving"], undefined>
    }) => React.ReactNode
  } & StyledProps
) {
  const meetingArgs = useRoomZoomMeetingArgs({ roomID: props.roomID })

  // conferenceApi.handleSessionExit(leave)
  const conferenceApi = React.useContext(ConferenceActivity.Context)

  const onLeave = React.useCallback<
    Exclude<ZoomApiInput["manageLeaving"], undefined>
  >(
    (callback) => {
      if (!props.onLeave) return

      conferenceApi.handleSessionExit(() => {
        callback()
        props.onLeave?.()
      })
    },
    [props.onLeave, conferenceApi.handleSessionExit]
  )

  const m: MeetingArgs | undefined = React.useMemo(() => {
    // return { signature: signature || undefined }
    if (!meetingArgs) return undefined

    return {
      signature: meetingArgs.signature,
      topic: meetingArgs.topic,
      userIdentity: meetingArgs.userIdentity,
    }
  }, [meetingArgs])

  if (!m) return <div>loading</div>

  if (props.children) return <>{props.children({ meetingArgs: m, onLeave })}</>

  return (
    <ZoomVideoMeeting
      className={props.className}
      style={{ ...props.style, height: "100%" }}
      meetingArgs={m}
      manageLeaving={onLeave}
    ></ZoomVideoMeeting>
  )
}
