import useMemoAPI from "hooks/useMemoAPI"
import { useCommentSelectionUI } from "./useCommentSelectionUI"

export default function useUsersReactionsModal() {
  const commentModalAPI = useCommentSelectionUI()

  const ref = commentModalAPI.popoverAnchorRef

  return useMemoAPI({
    close: commentModalAPI.close,
    open: commentModalAPI.open,
    isOpen: commentModalAPI.isOpen,
    comment: commentModalAPI.selectedComment,
    popoverAnchorRef: ref,
  })
}
