import { ErrorResponse } from "@apollo/client/link/error"
import * as config from "lib/config"
import { GraphQLError } from "graphql"
import useEnvironmentVariables from "hooks/useEnvironmentVariables/useEnvironmentVariables"
import useMemoAPI from "hooks/useMemoAPI"
import { enableSentry } from "lib/config"
import {
  PingDocument,
  PingQuery,
  PingQueryVariables,
} from "lib/graphql/operations"
import { AppError } from "lib/graphql/types"
import reportApolloErrorToSentry from "lib/reportApolloErrorToSentry"
import setupApollo from "lib/setupApollo/setupApollo"
import trackEnvironmentVariables from "lib/trackEnvironmentVariables"
import React from "react"
import { Subject, merge } from "rxjs"
import { filter, mapTo, mergeMap } from "rxjs/operators"
import Authentication from "../components/Authentication/Authentication"
import useConnectionStatusController from "./useConnectionStatusController"

export default function useApolloSetupAPI() {
  const httpNetworkError$ = React.useRef(new Subject()).current
  const httpData$ = React.useRef(new Subject()).current
  const wsDisconnected$ = React.useRef(new Subject()).current
  const graphQLError$ = React.useRef(new Subject<GraphQLError>()).current
  const wsConnected$ = React.useRef(new Subject<boolean>()).current
  const wsReconnected$ = React.useRef(new Subject<boolean>()).current

  const apolloError$ = React.useRef(
    new Subject<
      Pick<
        ErrorResponse,
        "graphQLErrors" | "networkError" | "response" | "operation"
      >
    >()
  ).current

  const client = React.useRef(
    (() => {
      return setupApollo({
        ...(() => {
          if (config.graphqlUrl)
            return { apolloURL: new URL(config.graphqlUrl) }
          return {}
        })(),

        onError({ networkError, graphQLErrors, operation, response }) {
          apolloError$.next({
            networkError,
            graphQLErrors,
            operation,
            response,
          })
        },
        onHttpNetworkError({ networkError }) {
          if (!networkError) return
          httpNetworkError$.next()
        },
        onWsReconnected() {
          console.log("reconnected")
          wsReconnected$.next()
        },
        onWsConnected() {
          console.log("connected")
          wsConnected$.next()
        },
        onWsDisconnected() {
          console.log("disconnected")
          wsDisconnected$.next()
        },
        onGraphqlError({ graphQLErrors, operation, response }) {
          if (process.env.NODE_ENV === "development") {
            console.log({ graphQLErrors, operation, response })
          }
        },
      })
    })()
  ).current

  const isConnectionAlive = React.useCallback(async () => {
    const ping =
      (await client
        .query<PingQuery, PingQueryVariables>({
          query: PingDocument,
          variables: {},
          fetchPolicy: "network-only",
        })
        .catch(console.error)) || undefined

    return ping?.data?.ping === "pong"
  }, [client])

  useConnectionStatusController({
    httpNetworkError$,
    httpData$,
    wsDisconnected$,
    wsConnected$,
    wsReconnected$,
    isConnectionAlive,
  })

  const environmentVariables = useEnvironmentVariables()

  React.useEffect(() => {
    const sub = merge(wsConnected$, wsReconnected$).subscribe(() => {
      trackEnvironmentVariables(environmentVariables, client)
    })

    return () => {
      return sub.unsubscribe()
    }
  }, [environmentVariables, client])

  React.useEffect(() => {
    const unauthenticated$ = apolloError$.pipe(
      filter(({ graphQLErrors }) => {
        return (graphQLErrors || []).some(
          (error) => error?.extensions?.code === AppError.InvalidAuthToken
        )
      }),
      mapTo(true)
    )

    apolloError$
      .pipe(
        mergeMap((a) => a.graphQLErrors || []),
        filter((a): a is GraphQLError => !!a)
      )
      .subscribe(graphQLError$)

    const unauthenticatedSub = unauthenticated$.subscribe(
      Authentication.unauthenticated$
    )

    const sub = apolloError$.subscribe(
      ({ networkError, graphQLErrors, operation, response }) => {
        enableSentry &&
          reportApolloErrorToSentry({
            networkError,
            graphQLErrors,
            operation,
            response,
          })
      }
    )
    return () => {
      unauthenticatedSub.unsubscribe()
      sub.unsubscribe()
    }
  }, [client])

  return useMemoAPI({
    graphQLError$,
    client,
    httpNetworkError$,
    httpData$,
    wsDisconnected$,
    wsConnected$,
    wsReconnected$,
  })
}
