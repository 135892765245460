import _ from "lodash"
import { makeStyles, Paper, Typography } from "@material-ui/core"
import clsx from "clsx"
import React, { useContext } from "react"
import Fieldset from "components/Fieldset/Fieldset"
import DocumentFormContext from "components/DeprecatedDocumentForm/contexts/DocumentFormContext"
import DocumentForm from "components/OldDocumentForm/OldDocumentForm"

export default function FormFieldset({
  className = "",
  fields,
  fieldsMap,
  fullWidth = false,
  twoColumns = false,
}) {
  const c = useStyles({})
  const { document } = React.useContext(DocumentForm.Context)

  if (!fields) return null

  return (
    <Paper
      className={clsx(
        "activity-fieldset",
        c.paper,
        c.fieldsContainer,
        className
      )}
    >
      <Fieldset
        fields={fields}
        getField={fieldsMap}
        object={document}
        fullWidth={fullWidth}
        twoColumns={twoColumns}
      ></Fieldset>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      // flexWrap: "wrap",
      padding: theme.spacing(2),
      // display: "flex",
    },

    fieldsContainer: {},
  }
})
