import _ from "lodash"
import useMemoAPI from "hooks/useMemoAPI"
import useRendererAPI from "./useRendererAPI"
import useMessagesDataAPI from "./useMessagesDataAPI"

export default function useMessagesProviderValue() {
  const messagesData = useMessagesDataAPI()
  const listRenderer = useRendererAPI({ messagesData })

  const value = useMemoAPI({
    messagesData,
    listRenderer,
  })

  return value
}
