import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import Dropzone from "components/Dropzone/Dropzone"
import _ from "lodash"
import React, { useCallback } from "react"
// import Messages from "components/Room/components/Messages/Messages"
import Footer from "components/Room/components/Footer/Footer"
import useSendMessage from "components/Room/components/Footer/hooks/useSendMessage"
import CreateRoomWall from "components/Room/components/Messages/components/CreateRoomWall/CreateRoomWall"
import InviteMembersWall from "components/Room/components/Messages/components/InviteMembersWall/InviteMembersWall"
import Messages from "components/Room/components/Messages/Messages"
import { RoomContext } from "components/Room/contexts/room"
import { useI18n } from "contexts/i18nContext/i18nContext"
import RoomCreationSnackbar from "../RoomTemplate/components/RoomBody/components/RoomCreationSnackbar/RoomCreationSnackbar"
import { DeleteMessageDialog } from "./DeleteMessageDialog"

export default function Chat(props: {
  className?: string
  style?: React.CSSProperties
  MessagesProps?: React.ComponentProps<typeof Messages.Root>
  renderMain?: (props: {
    components: { body: React.ReactNode; footer: React.ReactNode }
    defaultComponent: React.ReactNode
  }) => React.ReactNode
  renderBody?: (props: {
    defaultComponent: React.ReactElement
    roomContext: React.ContextType<typeof RoomContext>
  }) => React.ReactElement
}) {
  const { className = "" } = props
  const ctx = React.useContext(RoomContext)
  const { isCreating, roomIsInexistent } = ctx
  // const templateCtx = React.useContext(RoomTemplateContext)

  const template = React.useContext(RoomContext).template

  const c = useStyles({})
  const t = useI18n()
  const { sendFiles } = useSendMessage()

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: File[]) => {
      if (!_.get(acceptedFiles, "length")) return
      return sendFiles({ files: acceptedFiles })
    },
    [sendFiles]
  )

  return (
    <Dropzone.Default
      multiple={false}
      onDrop={onDrop}
      disable={roomIsInexistent}
      accept="image/*"
      acceptFileMessage={t.chatImageUploadDropzone.acceptFileMessage}
      rejectFileMessage={t.chatImageUploadDropzone.rejectFileMessage}
      className={clsx(c.root, "chat", props.className)}
      style={props.style}
    >
      <div className={clsx(c.main)} ref={template.rootContainer.containerRef}>
        {(() => {
          const body = (
            <div className={c.body}>
              {(() => {
                if (!!ctx.messageList?.data?.totalItems)
                  return <Messages.Root {...(props.MessagesProps || {})} />
                if (ctx.roomIsInexistent && template.showRoomCreationButton)
                  return <CreateRoomWall />

                if (ctx.roomWasFetched) {
                  if (
                    !ctx?.peerMembers?.length &&
                    !!ctx.searchMembersAPI.allowedMembersToBeAdded?.length
                  )
                    return <InviteMembersWall></InviteMembersWall>
                }

                return null
              })()}
            </div>
          )

          const footer = <Footer />

          const defaultComponent = (
            <>
              {body}
              {footer}
            </>
          )

          if (props.renderMain)
            return props.renderMain({
              components: { body, footer },
              defaultComponent,
            })

          return defaultComponent
        })()}

        <RoomCreationSnackbar isCreating={isCreating} />
        <DeleteMessageDialog />
      </div>
    </Dropzone.Default>
  )
  // return null
}

const useStyles = makeStyles((theme) => {
  return {
    body: {
      flex: 1,
      "&>*": {
        height: "100%",
      },
    },
    root: {
      // height: "100%",
    },
    main: {
      height: "100%",
      // display: "flex",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      // flexDirection: "column",
      // flexGrow: 1,
    },
  }
})
