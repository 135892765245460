import { useKey, useKeys } from "rooks"
import { IconButton, makeStyles, Icon } from "@material-ui/core"
import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import Img from "./components/Img/Img"
import Positioner from "./components/Positioner/Positioner"
import OpacityAnimation from "./components/OpacityAnimation/OpacityAnimation"
import ImageViewerAPIProvider from "./components/ImageViewerAPIProvider/ImageViewerAPIProvider"
import Zoom from "./components/Zoom/Zoom"
import ImageViewerContext from "./contexts/ImageViewerContext"
import clsx from "clsx"
import HighestZIndexLayer from "components/HighestZIndexLayer/HighestZIndexLayer"
import { KeyListener } from "../KeyListeners/hooks/useKeyListenersAPI"
import KeyListeners from "components/KeyListeners/KeyListeners"

export default function ImageViewer(props: {
  imageURL: string
  className?: string
  isOpen: boolean
  onClose: () => void
  animateOpenness?: boolean
  imageRef: React.RefObject<HTMLElement>
}) {
  const c = useStyles()
  const duration = props.animateOpenness ? 200 : 0

  const [active, setActive] = React.useState(false)

  useEffect(() => {
    if (!props.isOpen) return
    setActive(true)
  }, [props.isOpen])

  const onClose = React.useCallback(() => {
    setActive(false)
    setTimeout(() => {
      props.onClose()
    }, duration)
  }, [props.onClose])

  KeyListeners.useEscapeListener(onClose, { enabled: active })

  const component = (
    <ImageViewerAPIProvider
      imageURL={props.imageURL}
      active={active}
      onClose={onClose}
      duration={duration}
    >
      {(api) => {
        if (!props.isOpen) return null

        return (
          <ImageViewerContext.Provider value={api}>
            <>
              <OpacityAnimation animation={api.animation}>
                <div
                  className={clsx("viewer-background", c.viewerBackground)}
                  onClick={onClose}
                ></div>
              </OpacityAnimation>
              <Img
                imageURL={props.imageURL}
                imageRef={props.imageRef}
                className={clsx("img", props.className)}
                isAnimated={props.animateOpenness}
                // isAnimation={false}
              ></Img>

              <OpacityAnimation animation={api.animation}>
                <>
                  <Positioner position="top-left">
                    <IconButton
                      onClick={api.closeViewer}
                      style={{ color: "white" }}
                      size="medium"
                    >
                      <Icon fontSize="large" style={{ fontSize: 39 }}>
                        close
                      </Icon>
                    </IconButton>
                  </Positioner>
                  <Positioner position="bottom-center">
                    <Zoom></Zoom>
                  </Positioner>
                </>
              </OpacityAnimation>
            </>
          </ImageViewerContext.Provider>
        )
      }}
    </ImageViewerAPIProvider>
  )

  return <HighestZIndexLayer.Portal>{component}</HighestZIndexLayer.Portal>
}

const useStyles = makeStyles((theme) => {
  return {
    viewerBackground: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: "fixed",
      backgroundColor: "rgba(0,0,0,.8)",
    },
  }
})
