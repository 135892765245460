import _ from "lodash"
import { useParamsContext } from "pages/Partners/contexts/libContext"
import PaperList from "components/PaperList"
import CollectionItemAvatar from "components/CollectionItemAvatar/CollectionItemAvatar"
import { makeStyles } from "@material-ui/core"
import { CollectionItem } from "types/type"
import { useContext } from "react"
import PartnersContext from "pages/Partners/contexts/PartnersContext"

export function CollectionsListItem(props: { entry: CollectionItem }) {
  const c = useStyles({})

  const { entry: collection } = props

  const { setCollectionID, collectionID, collectionTypeColor } =
    useContext(PartnersContext)

  const isSelected = collectionID === collection?.id

  return (
    <PaperList.ListItem
      isSelected={isSelected}
      classes={{
        listItemLeft: c.listItemLeft,
      }}
      left={
        <div className={c.left}>
          <CollectionItemAvatar
            type={collection?.type}
            alt={collection?.title}
          />
          <PaperList.ListItemText
            primary={
              <span style={isSelected ? { color: collectionTypeColor } : {}}>
                {collection?.title}
              </span>
            }
            secondary={collection?.subtitle}
          ></PaperList.ListItemText>
        </div>
      }
      right={
        <PaperList.ListItemText
          className={c.right}
          secondary={<>{collection?.number}</>}
        />
      }
      onClick={() => collection?.id && setCollectionID(collection?.id)}
    ></PaperList.ListItem>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    left: {
      display: "flex",
      alignItems: "center",
      "&>*:first-child": {
        marginRight: theme.spacing(1),
      },
    },
    listItemLeft: {
      flex: 6,
    },
    right: {
      display: "flex",
      justifyContent: "flex-end",
      flex: 1,
    },
  }
})
