import { Typography } from "@material-ui/core"
import { FetchingParamsProps } from "components/PageBoard/components/IndexedItems/hooks/useFetchPageBoardItemFromParams"
import Room from "components/Room/Room"
import { ShopArticleText } from "components/Room/components/Messages/components/MessageRow/components/Message/components/ParagraphsMessage/ShopArticleText/ShopArticleText"
import { TableParagraph } from "components/Room/components/Messages/components/MessageRow/components/Message/components/ParagraphsMessage/TableParagraph/TableParagraph"
import { RenderMessageProps } from "components/Room/hooks/useRoomAPI/useRoomAPI"
import { Shop } from "components/Shop/Shop"
import StopPropagation from "components/StopPropagation/StopPropagation"
import { Paragraph_PageBoardItemSummaryParagraph_Fragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { SecondPage } from "../../../SecondPage/SecondPage"
import { RoomPageBoardItemApiContext } from "../../contexts/RoomPageBoardItemContext"
import { RoomPageBoardItemAPI } from "../../hooks/useRoomPageBoardItemAPI"
import { ZoomSessionRegistrationMessage } from "components/Room/components/Messages/components/MessageRow/components/Message/components/ZoomSessionRegistrationMessage/ZoomSessionRegistrationMessage"

const getOpenShopArticle = (api: RoomPageBoardItemAPI) =>
  function openShopArticlePage(
    params?: FetchingParamsProps<PageBoardItemType.ShopArticle> | undefined
  ) {
    api.twoPagesAPI?.openHalfPage(function ShopArticlePage(args) {
      return (
        <SecondPage
          onClose={args.onClose}
          getItemParams={{
            fetchItemProps: {
              params,
              type: PageBoardItemType.ShopArticle,
            },
          }}
        ></SecondPage>
      )
    })
  }

export function Message(props: RenderMessageProps) {
  const api = React.useContext(RoomPageBoardItemApiContext)

  const openShopArticlePage = getOpenShopArticle(api)

  if (props.message?.__typename === "ZoomSessionRegistrationMessage") {
    return (
      <ZoomSessionRegistrationMessage
        MessageBalloonProps={props.MessageBalloonProps}
        message={props.message}
        deactivateJoinButton={api.isVideoCallPanelOpen}
        onClickJoinButton={api.toggleVideoCallPage}
      ></ZoomSessionRegistrationMessage>
    )
  }

  if (props.message?.__typename === "MessageInParagraphs") {
    return (
      <Room.Paragraphs
        message={props.message}
        MessageBalloonProps={props.MessageBalloonProps}
        renderParagraph={(args) => {
          if (args.paragraph.__typename === "TextParagraph") {
            return (
              <Typography variant="body2">
                <ShopArticleText
                  text={args.paragraph.text}
                  openArticlePage={openShopArticlePage}
                ></ShopArticleText>
              </Typography>
            )
          }

          if (args.paragraph.__typename === "PageBoardItemSummaryParagraph") {
            return (
              <PageBoardItemSummaryParagraph
                paragraph={args.paragraph}
                containerWidth={args.containerWidth}
                openShopArticlePage={openShopArticlePage}
              ></PageBoardItemSummaryParagraph>
            )
          }

          if (args.paragraph.__typename === "TableParagraph") {
            return (
              <TableParagraph
                paragraph={args.paragraph}
                openArticlePage={openShopArticlePage}
              ></TableParagraph>
            )
          }

          return args.defaultComponent
        }}
      ></Room.Paragraphs>
    )
  }

  return <>{props.defaultComponent}</>
}
function PageBoardItemSummaryParagraph(props: {
  paragraph: Paragraph_PageBoardItemSummaryParagraph_Fragment
  containerWidth?: number
  openShopArticlePage: (
    params?: FetchingParamsProps<PageBoardItemType.ShopArticle> | undefined
  ) => void
}) {
  if (!(props.paragraph.item.__typename === "ShopArticlePageBoardItem")) {
    return null
  }

  const article = props.paragraph.item.article || null
  const isLayoutVertical = (props.containerWidth || 0) <= 390

  return (
    <Room.ShopArticlePageBoardItemSummaryParagraph
      onClick={() => {
        if (!article?.id) return
        props.openShopArticlePage({ articleID: article.id })
      }}
      summaryLimitOfLines={isLayoutVertical ? 3 : 5}
      isLayoutVertical={isLayoutVertical}
      paragraph={props.paragraph}
      renderDetails={(details) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div>
              <div style={{ marginBottom: 4 }}>
                {details.components.sku}
                {details.components.title}
              </div>
              {details.components.summary}
              {details.components.brand}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 12,
              }}
            >
              {details.components.price}
              {/* {args.components.price} */}
              {!!article && (
                <StopPropagation>
                  <Shop.CardCartButtons
                    article={article}
                  ></Shop.CardCartButtons>
                </StopPropagation>
              )}
            </div>
          </div>
        )
      }}
    ></Room.ShopArticlePageBoardItemSummaryParagraph>
  )
}
