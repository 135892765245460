import React from "react"
import { Avatar } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import { COLLECTIONS_TYPES_COLOR, COLLECTIONS_TYPES_ICONS } from "lib/constants"
import { CollectionItemType } from "types/type"

export default function CollectionItemAvatar(props: {
  type: CollectionItemType
  alt: string
  className: string
  classes: { icon: string }
}) {
  const {
    type,
    alt = "",
    className = "",
    classes: { icon: iconClassName = "" } = {},
  } = props

  return (
    <Avatar
      alt={alt}
      className={className}
      style={{
        backgroundColor: COLLECTIONS_TYPES_COLOR[type],
      }}
    >
      {COLLECTIONS_TYPES_ICONS[type]
        ? React.cloneElement(COLLECTIONS_TYPES_ICONS[type], {
            className: clsx(iconClassName),
          })
        : null}
    </Avatar>
  )
}
