// import "./notificationCount.scss"
import React from "react"
import _ from "lodash"
import { red } from "constants/colors"
import { makeStyles } from "@material-ui/core"

export default function NotificationCount(props: { count: number }) {
  const c = useStyles()

  if (typeof props.count !== "number") return null

  return (
    <div className={c.notificationCount}>
      <span>{props.count}</span>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    notificationCount: {
      background: red[1],
      color: "white",
      borderRadius: "15px",
      padding: "3px 8px",
      fontWeight: 600,
      lineHeight: 1,

      "& > *": {
        position: "relative",
        fontSize: "0.85rem",
      },
    },
  }
})
