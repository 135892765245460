import React, { useContext, useMemo } from "react"
import DocumentsViewButtons from "./components/DocumentsViewButtons/DocumentsViewButtons"
import _ from "lodash"
import ExpansionPanel from "components/ExpansionPanel/ExpansionPanel"
import CollectionDocumentsFilter from "./components/CollectionDocumentsFilter/index"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import CollectionDashboardContext from "components/CollectionDocumentsDashboard/contexts/CollectionDashboardContext"

export default function CollectionDashboardHeader({}) {
  const c = useStyles({})
  const { DocumentsFilter } = useContext(CollectionDashboardContext)
  const {
    inputs: filterInputs,
    selectedRelatedCollectionsIDs,
    selectedCollections,
  } = useContext(DocumentsFilter.Context)

  return (
    <CollectionDocumentsFilter.Provider DocumentsFilter={DocumentsFilter}>
      <CollectionDocumentsFilter.Context.Consumer>
        {({ isFilterOpen }) => {
          const showSelectedCollections =
            !isFilterOpen && selectedCollections?.length

          return (
            <>
              <ExpansionPanel
                classes={{
                  expanded: c.expanded,
                  root: clsx(
                    c.expansionPanel,
                    !!showSelectedCollections && c.boxShadowNone
                  ),
                }}
                expandIcon={null}
                isExpanded={isFilterOpen}
                summary={
                  <>
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className={clsx(
                        "collection-dashboard-summary",
                        c.dashboardSummary,
                        c.fullWidth,
                        c.flex
                      )}
                    >
                      <DocumentsViewButtons></DocumentsViewButtons>
                      {!!filterInputs?.length && (
                        <CollectionDocumentsFilter.Button
                          edge="end"
                          count={selectedRelatedCollectionsIDs?.length}
                        >
                          {({ components: { button }, onClickButton }) => {
                            return (
                              <div
                                className={clsx("button-wrapper")}
                                onClick={onClickButton}
                              >
                                {button}
                              </div>
                            )
                          }}
                        </CollectionDocumentsFilter.Button>
                      )}
                    </div>
                  </>
                }
                DetailsProps={{ className: c.detailsPanel }}
                SummaryProps={{
                  className: c.summaryPanel,
                  classes: { content: c.expansionPanelSummaryContent },
                }}
                details={<div className={c.details}>{filterInputs}</div>}
              ></ExpansionPanel>

              {!!showSelectedCollections && (
                <CollectionDocumentsFilter.ChipsPaper></CollectionDocumentsFilter.ChipsPaper>
              )}
            </>
          )
        }}
      </CollectionDocumentsFilter.Context.Consumer>
    </CollectionDocumentsFilter.Provider>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    // expansionPanel: {
    //   padding: 0
    // },
    flex: {
      display: "flex",
      justifyContent: "space-between",
    },
    expansionPanelSummaryContent: {
      margin: 0,
    },
    summaryPanel: {
      padding: 0,
      // margin: 0,
    },
    boxShadowNone: {
      boxShadow: "none",
    },
    dashboardSummary: {
      padding: theme.spacing(0, 2),
      margin: theme.spacing("20px", 0),
      "& .button-wrapper": {
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    details: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      "&>*": {
        width: "calc(50% - 5px)",
      },
    },
    detailsPanel: {
      width: "100%",
    },
    expanded: {
      margin: "0 !important",
    },

    fullWidth: {
      width: "100%",
    },
  }
})
