import CollectionDocumentsDashboard from "components/CollectionDocumentsDashboard/index"
// import ApolloClient from "apollo-client"
import useActiveResources from "hooks/useActiveResources"
import useCollectionItem from "hooks/useCollectionItem"
import { useInputController } from "hooks/useInputController"
import useMemoAPI from "hooks/useMemoAPI"
import { COLLECTIONS_TYPES_COLOR } from "lib/constants"
import {
  DetailedContactDocument,
  PartnerDocument,
} from "lib/graphql/operations"
import { useState, useCallback, useEffect } from "react"
import { CollectionItemType } from "types/type"
import { IPartnersContext } from "../contexts/PartnersContext"
import {
  PartnersParams,
  PartnersTabsIDs,
  PartnersModalIDs as PartnersModalIDs,
} from "../types"
import { ApolloClient, useApolloClient } from "@apollo/client"

interface PartnersProviderProps extends PartnersParams {
  onChangeCollectionID?(collectionID: string | null): any
  onChangeModal?(modal: string | null): any
  onChangeDocumentID?(documentID: string | null): any
  onChangeSearchedTerm?(searchedTerm: string | null): any
  onChangeTypes?(types: Array<string> | null): any
}

export default function usePartnersProviderValue(
  props: PartnersProviderProps
): IPartnersContext {
  const {
    collectionID,
    setCollectionID,
    isLoading: isLoadingCollectionID,
    setPartnerID,
    setContactID,
  } = useCollectionID({
    collectionID: props.collectionID,
    onChange: props.onChangeCollectionID,
  })

  const [tabID, setTabID] = useState(<PartnersTabsIDs | null>null)

  useEffect(() => {
    setTabID(() => null)
  }, [collectionID])

  const [modalID, setModalID] = useState(<PartnersModalIDs | null>null)

  const { inputValue: documentID, setInputValue: setDocumentID } =
    useInputController({
      onChange: (value) => props.onChangeDocumentID?.call(null, value || null),
      value: props.documentID || null,
    })

  const { inputValue: searchedTerm, setInputValue: setSearchedTerm } =
    useInputController({
      onChange: (value) =>
        props.onChangeSearchedTerm?.call(null, value || null),
      value: props.searchedTerm || null,
    })

  const { inputValue: types, setInputValue: setTypes } = useInputController({
    onChange: (value) => props.onChangeTypes?.call(null, value || null),
    value: props.types || null,
  })

  const { collectionItem: collection } = useCollectionItem({ collectionID })

  const openNewContactModal = useCallback(() => {
    setModalID(PartnersModalIDs.NEW_CONTACT)
  }, [])

  const openNewPartnerModal = useCallback(() => {
    setModalID(PartnersModalIDs.NEW_PARTNER)
  }, [])

  const closeModal = useCallback(() => {
    setModalID(null)
  }, [])

  const closeDetails = useCallback(() => {
    setCollectionID(null)
  }, [])

  const goToDocumentsTab = useCallback(() => {
    setTabID(PartnersTabsIDs.DOCUMENTS)
  }, [])

  return useMemoAPI({
    tabID,
    CollectionDashboard: CollectionDocumentsDashboard,
    closeDetails,
    openNewContactModal,
    openNewPartnerModal,
    closeModal,
    goToDocumentsTab,
    modalID,
    collectionID,
    documentID,
    searchedTerm,
    setTabID,
    types,
    collection,
    loadingData: isLoadingCollectionID,
    collectionTypeColor:
      COLLECTIONS_TYPES_COLOR[collection?.type as CollectionItemType],
    setCollectionID,
    setPartnerID,
    setContactID,
    setDocumentID,
    setSearchedTerm,
    setTypes,
  })
}

// const setPartnerID =
function useCollectionID(props: {
  collectionID: string | null
  onChange?: (collectionID: string | null) => any
}) {
  const [loading, setLoading] = useState(false)

  const { inputValue: collectionID, setInputValue: setCollectionID } =
    useInputController({
      onChange: (value) => props.onChange?.call(null, value || null),
      value: props.collectionID || null,
    })

  const client = useApolloClient()
  const { workspaceID } = useActiveResources()

  const setPartnerID = useCallback(
    async function setPartnerID(partnerID) {
      setLoading(true)

      const partnerCollectionID = await getPartnerCollectionID(
        { partnerID, workspaceID },
        client
      ).finally(() => setLoading(false))

      setCollectionID(partnerCollectionID)
    },
    [workspaceID, client, setCollectionID]
  )

  const setContactID = useCallback(
    async function setContactID(contactID) {
      setLoading(true)

      const partnerCollectionID = await getContactCollectionID(
        { contactID, workspaceID },
        client
      ).finally(() => setLoading(false))

      setCollectionID(partnerCollectionID)
    },
    [workspaceID, client, setCollectionID]
  )

  return useMemoAPI({
    collectionID,
    setCollectionID,
    setContactID,
    setPartnerID,
    isLoading: loading,
  })
}
async function getPartnerCollectionID(
  params: { partnerID: string; workspaceID: string },
  client: ApolloClient<{}>
) {
  const result = await client.query({
    query: PartnerDocument,
    variables: { id: params.partnerID, workspaceID: params.workspaceID },
    fetchPolicy: "cache-first",
  })
  // .finally(() => setLoading(false))
  const partnerCollectionID = result?.data?.partner?.collection?.id
  return partnerCollectionID
}

async function getContactCollectionID(
  params: { contactID: string; workspaceID: string },
  client: ApolloClient<{}>
) {
  const result = await client.query({
    query: DetailedContactDocument,
    variables: { id: params.contactID, workspaceID: params.workspaceID },
    fetchPolicy: "cache-first",
  })
  // .finally(() => setLoading(false))
  const contactCollectionID = result?.data?.detailedContact?.collectionID
  return contactCollectionID
}
