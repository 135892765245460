import React from "react"
import { Button, makeStyles, Paper } from "@material-ui/core"
import { useI18n } from "contexts/i18nContext/i18nContext"
import ActivityUserAvatar from "../ActivityEntry/components/AddCommentActivity/ActivityUserAvatar"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import LexicalEditor from "components/LexicalEditor/LexicalEditor"
import { LexicalEditorAPIInput } from "components/LexicalEditor/hooks/useLexicalEditorAPI"

export type CommentInputProps = {
  onSave: Exclude<LexicalEditorAPIInput["onSave"], undefined>
  namespace: LexicalEditorAPIInput["namespace"]
  value?: LexicalEditorAPIInput["value"]
  clearEditorOnSave: boolean
  renderSubmitButton?: (props: {
    defaultComponent?: React.ReactNode
  }) => JSX.Element
}

export default function CommentInput(props: CommentInputProps) {
  const t = useI18n()
  const w = useCurrentWorkspace()

  const c = useStyles()

  if (!w.user) return null

  return (
    <LexicalEditor.Provider
      editable={true}
      namespace={props.namespace}
      onSave={props.onSave}
      value={props.value}
    >
      <Paper className={c.wrapper}>
        <LexicalEditor.Root
          PlaceholderProps={{
            className: c.placeholder,
          }}
        ></LexicalEditor.Root>

        <div className={c.controls}>
          <LexicalEditor.Context.Consumer>
            {(api) => {
              const defaultComponent = (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={api.saveAPI.isDisabled}
                  onClick={api.saveAPI.save.bind(api.saveAPI, {
                    clearEditorOnSave: props.clearEditorOnSave,
                  })}
                >
                  {t["send"]}
                </Button>
              )

              if (props.renderSubmitButton)
                return props.renderSubmitButton({ defaultComponent })

              return defaultComponent
            }}
          </LexicalEditor.Context.Consumer>

          <LexicalEditor.Toolbar fontSize={21}></LexicalEditor.Toolbar>
        </div>
      </Paper>
    </LexicalEditor.Provider>
  )
}
const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  wrapper: { padding: theme.spacing(1, 1.5) },

  controls: {
    marginTop: theme.spacing(1),

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  placeholder: {
    left: "0px !important",
    top: "0px !important",
  },
}))
