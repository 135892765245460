import useMemoAPI from "hooks/useMemoAPI"
import useOpenClose from "hooks/useOpenClose"
import { KanbanCommentFragment } from "lib/graphql/operations"
import React from "react"

export function useCommentSelectionUI() {
  const [comment, setComment] = React.useState<KanbanCommentFragment | null>(
    null
  )

  const [popoverAnchorRef, setPopoverAnchorRef] =
    React.useState<HTMLElement | null>(null)

  const openClose = useOpenClose()

  const close = React.useCallback(() => {
    setComment(null)
    openClose.close()
    setPopoverAnchorRef(null)
  }, [openClose.close])

  const open = React.useCallback(
    (props: {
      comment: KanbanCommentFragment
      popoverAnchorRef?: React.RefObject<HTMLElement>
    }) => {
      // debugger
      setComment(props.comment)

      if (!!props.popoverAnchorRef?.current)
        setPopoverAnchorRef(props.popoverAnchorRef?.current)

      openClose.open()
    },
    [openClose.open]
  )

  return useMemoAPI({
    close,
    open,
    popoverAnchorRef,
    isOpen: !!comment && openClose.isOpen,
    selectedComment: comment,
  })
}
