import React from "react"
import _ from "lodash"
import useCalendarEventAPI from "./hooks/useCalendarEventAPI"
import { ApiProps } from "./types"
import CalendarEventContext from "./contexts/CalendarEventContext"
import CalendarEventBody from "./components/CalendarEventBody/CalendarEventBody"

const CalendarEvent = {
  Body: CalendarEventBody,
  Provider: CalendarEventProvider,
  Default: function CalendarEventDefault(
    props: ApiProps & {
      BodyProps?: React.ComponentProps<typeof CalendarEventBody>
    }
  ) {
    return (
      <CalendarEventProvider {..._.omit(props, "BodyProps")}>
        <CalendarEventBody {...(props.BodyProps || {})}></CalendarEventBody>
      </CalendarEventProvider>
    )
  },
}

export default CalendarEvent

function CalendarEventProvider(
  props: ApiProps & {
    children: React.ReactNode
  }
) {
  const { children, ...apiProps } = props

  const api = useCalendarEventAPI(apiProps)

  return (
    <CalendarEventContext.Provider value={api}>
      {props.children}
    </CalendarEventContext.Provider>
  )
}
