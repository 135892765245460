import {
  RoomSilencedDocument,
  RoomSilencedQueryResult,
  RoomSilencedQueryVariables,
  useRoomSilencedQuery,
} from "lib/graphql/operations"
import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { ApolloClient, useApolloClient, gql } from "@apollo/client";

export function useIsRoomSilenced(props: {
  roomID: string
  workspaceID: string
}) {
  const { data } = useRoomSilencedQuery({
    variables: { roomID: props.roomID, workspaceID: props.roomID },
    fetchPolicy: "cache-only",
  })

  const apolloClient = useApolloClient()

  const setIsSilenced = React.useCallback(
    (isSilenced: boolean, proxy: DataProxy = apolloClient) => {
      proxy.writeFragment<{ isSilenced: boolean; __typename: "Room" }>({
        fragment: gql`
          fragment RoomSilenced on Room {
            id
            isSilenced
          }
        `,
        data: { __typename: "Room", isSilenced, id: props.roomID },
        id: `Room:${props.roomID}`,
      })
    },
    [apolloClient, props.roomID]
  )

  const isSilenced = data?.room?.isSilenced

  return useMemoAPI({ isSilenced, setIsSilenced })
}
