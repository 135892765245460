import { RoomQueryVariables } from "types/type"
import React from "react"
import { TransformKanbanBoard } from "components/Kanban/@types"
import useChangeRoomKanbanBoard from "./useChangeRoomKanbanBoard"
import { KanbanAPIInput } from "components/Kanban/hooks/useKanbanAPI"

export default function useSetBoard(
  props: RoomQueryVariables
): KanbanAPIInput["setBoard"] {
  const { roomID, workspaceID } = props

  // const currentBoard = useBoard({
  //   roomID,
  //   workspaceID,
  //   fetchPolicy: "cache-only",
  // })

  const { changeRoomKanbanBoard } = useChangeRoomKanbanBoard({
    workspaceID,
    roomID,
  })

  return React.useCallback(
    function setBoard(transform: TransformKanbanBoard) {
      // const newBoard = transform(currentBoard)
      // changeRoomKanbanBoard(newBoard)
    },
    [changeRoomKanbanBoard]
  )
}
