import { RoomsQuery } from "lib/graphql/operations"
import { ArrayElement } from "types/type"

export function sortRooms(rooms: RoomsQuery["rooms"]) {
  type Room = ArrayElement<typeof rooms>

  const relevantDateTime = (r: Room) =>
    [r.lastMessage?.sentAt, r.createdAt]
      .filter((a): a is string => !!a)
      .sort(sortMostRecentDates)[0] || ""

  return (rooms || []).sort((a, b) => {
    return sortMostRecentDates(relevantDateTime(a), relevantDateTime(b))
  })
}
function sortMostRecentDates(a: string, b: string) {
  if (a > b) return -1
  return 1
}
