import _ from "lodash"

export function getName({ fieldsetName, fieldName }) {
  return [fieldsetName, fieldName].filter(Boolean).join(".")
}

export function fieldsetWithDefaultValue({ data, fieldset }) {
  return fieldset.map(field => ({
    ...field,
    defaultValue: (function getDefaultValue() {
      const dataValue = _.get(data, field.name)

      const defaultValue = _.isFunction(field.getDefaultValue)
        ? field.getDefaultValue(data)
        : dataValue

      return _.isUndefined(defaultValue) ? "" : defaultValue
    })()
  }))
}

export function spreadObjectOfType({ type, fieldset, object }) {
  const fieldsOfType = (fieldset || []).filter(f => f.type === type)
  const namesWithType = _.map(fieldsOfType, "name")

  const formatted = _.reduce(
    object,
    function(acc, value, key) {
      if (namesWithType.includes(key)) acc = { ...acc, ...value }
      else acc = { ...acc, [key]: value }
      return acc
    },
    {}
  )

  return _.omit(formatted, namesWithType)
}
