import _ from "lodash"
import React from "react"
import { DocumentStatusType } from "./contexts/DocumentFormContext"
import getFieldsMap from "./lib/getFieldsMap"
import DocumentForm from "components/OldDocumentForm/OldDocumentForm"
import { DocumentStatus } from "components/OldDocumentForm/types"
import DocumentFormRoot from "./components/DocumentFormRoot/DocumentFormRoot"

type ProviderProps = React.ComponentProps<typeof DocumentForm.Provider>

const DeprecatedDocumentForm = {
  getFieldsMap: getFieldsMap,
  Provider: function DocumentFormProvider(props: ProviderProps) {
    const status = React.useMemo(() => {
      if (props.status) return props.status

      const s: DocumentStatus = {
        type: DocumentStatusType.workStatus,
        value: props.document?.workStatus || "",
      }

      return s
    }, [props.status, props.document?.workStatus])

    return (
      <DocumentForm.Provider
        document={props.document}
        onSaveDocument={props.onSaveDocument}
        status={status}
        partner={props.partner}
      >
        {props.children}
      </DocumentForm.Provider>
    )
  },

  Root: DocumentFormRoot,
}

export default DeprecatedDocumentForm
