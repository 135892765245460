import React, { useCallback } from "react"
import moment from "moment"
import getter from "util/getter"
import { KeyboardDatePicker } from "@material-ui/pickers"

export default function KeyBoardDateInput(props) {
  const g = getter(props)

  const onChange = useCallback(
    (date) => {
      if (typeof props.onChange !== "function") return
      const d = (() => {
        const j = date?.format("YYYY-MM-DD")

        if (!j) return null

        return j
      })()

      // console.log(date.format("HH:mm"))
      props.onChange({
        target: { value: d || null, name: props.name },
      })
    },
    [props.onChange, props.name]
  )

  return (
    <KeyboardDatePicker
      label={g("label")}
      clearable
      value={props.value ? moment.utc(props.value).format("YYYY-MM-DD") : null}
      onChange={onChange}
      format="DD.MM.YYYY"
      invalidDateMessage="Ungültiges Datumsformat"
      className={g("className")}
      inputProps={{
        readOnly: g("readOnly"),
      }}
    />
  )
}
