import { FetchResult } from "@apollo/client";
import _ from "lodash"
import { DataProxy } from "apollo-cache"
import { useMemo } from "react"
import useActiveResources from "hooks/useActiveResources"
import {
  AddPartnerMutation,
  CollectionsItemsDocument,
  CollectionsItemsQuery,
  CollectionsItemsQueryVariables,
  useAddPartnerMutation,
  CollectionItemFragment,
  AddPartnerMutationVariables,
} from "lib/graphql/operations"
import { CONTACT_TYPES } from "lib/constants"

export default function useAddPartner() {
  const [mutate, { loading }] = useAddPartnerMutation()
  const { workspaceID } = useActiveResources()

  return useMemo(() => {
    return {
      isLoading: loading,
      async addPartner(
        props: Omit<AddPartnerMutationVariables, "workspaceID">
      ) {
        const variables = {
          remarks: props.remarks,
          name1: props.name1,
          type: props.type,
          no: props.no,
          taxID: props.taxID,
          workspaceID,
        }

        const result = await mutate({
          variables,
          update: updateCache({ workspaceID }),
        })

        return result?.data?.addPartner
      },
    }
  }, [loading, workspaceID])
}

function updateCache(props: { workspaceID: string }) {
  return function update(
    cache: DataProxy,
    result: FetchResult<AddPartnerMutation>
  ) {
    const args = {
      query: CollectionsItemsDocument,
      variables: {
        search: null,
        types: CONTACT_TYPES,
        workspaceID: props.workspaceID,
      },
    }

    const { collectionsItems } =
      cache.readQuery<CollectionsItemsQuery, CollectionsItemsQueryVariables>(
        args
      ) || {}

    if (!collectionsItems) return

    const r = result?.data?.addPartner

    const newCollectionItem: CollectionItemFragment = {
      // id: p("collection.id") || null,
      id: r?.collection?.id || "",
      number: null,
      // type: p("collection.type") || null,
      type: r?.collection?.type || null,
      // title: p("name1") || null,
      title: r?.name1 || null,
      subtitle: null,
      bilateralID: null,
      deleted: null,
      __typename: "CollectionItem",
    }

    cache.writeQuery<CollectionsItemsQuery, CollectionsItemsQueryVariables>({
      ...args,
      data: {
        collectionsItems: [newCollectionItem, ...(collectionsItems || [])],
      },
    })
  }
}
