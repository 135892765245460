import React from "react"
import _ from "lodash"
import { useI18n } from "contexts/i18nContext/i18nContext"
import SelectWithAutocomplete from "components/SelectWithAutocomplete/SelectWithAutocomplete"
import { makeStyles, TextFieldProps } from "@material-ui/core"
import DocumentCollectionsContext from "../../contexts/DocumentCollectionsContext"

export default function TypeSelection(props: {
  type?: string
  onChangeType?: (type: string) => void
  TextField?: React.FC<TextFieldProps>

  classes?: React.ComponentProps<typeof SelectWithAutocomplete>["classes"]
  // classes?: { suggestionsItem?: string }
}) {
  const {
    form,
    collection,
    resetSelectedCollectionID: resetCollectionID,
  } = React.useContext(DocumentCollectionsContext)

  const { setType } = form

  const type = "type" in props ? props.type : form.type

  const onChangeType = React.useCallback(
    (newType: string) => {
      if (newType !== collection?.type) {
        resetCollectionID()
        form.setCollectionText("")
      }

      setType(newType)
      props.onChangeType?.call(undefined, newType)
    },
    [setType, resetCollectionID, props.onChangeType]
  )

  const t = useI18n()

  const { collectionsOverviews } = React.useContext(DocumentCollectionsContext)
  const options = React.useMemo(() => {
    return (collectionsOverviews || [])?.map((a) => {
      return {
        value: a.type,
        label: [a.title, a.count && `(${a.count})`]
          .filter((a): a is string => !!a)
          .join(" "),
      }
    })
  }, [collectionsOverviews])

  const c = useStyles()

  const selected = options.find((o) => o.value === type)

  return (
    <SelectWithAutocomplete
      options={options}
      TextField={props.TextField}
      textField={(selected || {}).label || ""}
      label={t.category}
      classes={{
        // suggestionsItem: props.classes?.suggestionsItem,
        suggestionsPaper: c.suggestionsPaper,
        ...(props.classes || {}),
      }}
      onChangeTextField={(field) => {
        if (!(collectionsOverviews || []).map((a) => a.title).includes(field)) {
          onChangeType("")
        }
      }}
      onSelect={(selected) => {
        if (!selected) return
        const type = (selected || {}).value
        onChangeType(type)
      }}
    />
  )
}

const useStyles = makeStyles((theme) => {
  return {
    suggestionsPaper: {
      maxHeight: "200px",
    },
  }
})
