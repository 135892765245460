import { CollectionItemType } from "types/type"
import { green, red, purple, yellow } from "../constants/colors"
import { RoomType, UserType } from "./graphql/types"

export const PARTNER_TYPES = ["CUSTOMER", "LEAD", "VENDOR"]

export const COLLECTIONS_TYPES = ["CONTACT", "DOCUMENT", ...PARTNER_TYPES]

export const COLLECTIONS_TYPES_COLOR: { [key in CollectionItemType]?: string } =
  {
    [CollectionItemType.VENDOR]: green[2],
    [CollectionItemType.CONTACT]: red[1],
    [CollectionItemType.CUSTOMER]: purple[0],
    [CollectionItemType.LEAD]: purple[1],
    [CollectionItemType.DOCUMENT]: yellow[3],
  }

export const userTypesColor: { [key in UserType]: string } = {
  [UserType.Guest]: COLLECTIONS_TYPES_COLOR[CollectionItemType.CONTACT] || "",
  [UserType.User]: purple[2],
  // [RoomType.Company]: "rgba(33, 47, 0, 1)",
  // [RoomType.CompanyCompany]: "rgba(33, 47, 0, 0.7)",
  // [RoomType.CompanyGuest]: "rgba(33, 47, 0, 0.5)",
  // [RoomType.UserUser]: purple[2],
  // [RoomType.UserGuest]: COLLECTIONS_TYPES_COLOR[CollectionItemType.CONTACT],
  // [RoomType.Document]: purple[0],
  // [RoomType.Collection]: purple[2],
  // [RoomType.Free]: yellow[2],
  // [RoomType.System]: red[3],
}

export const roomTypesColors: { [key in RoomType]?: string } = {
  [RoomType.Company]: "rgba(33, 47, 0, 1)",
  [RoomType.CompanyCompany]: "rgba(33, 47, 0, 0.7)",
  [RoomType.CompanyGuest]: "rgba(33, 47, 0, 0.5)",
  [RoomType.UserUser]: purple[2],
  [RoomType.UserGuest]: COLLECTIONS_TYPES_COLOR[CollectionItemType.CONTACT],
  [RoomType.Document]: purple[0],
  [RoomType.Collection]: purple[2],
  // [RoomType.Free]: yellow[2],
  [RoomType.Free]: yellow[2],
  [RoomType.System]: red[3],
}

export { default as COLLECTIONS_TYPES_ICONS } from "./collectionsTypesIcons"

export const CONTACT_TYPES = COLLECTIONS_TYPES.filter(
  (t): t is string => t !== "DOCUMENT"
)

export const DEFAULT_POLLING = 1000 * 60 * 3

const getComposedRegex = (...regexes) =>
  new RegExp(regexes.map((regex) => regex.source).join("|"))

export const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)\b\/?/g

// export const urlRegex =
//   /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)\b\/?/g

export const localhostRegex =
  /https?:\/\/localhost:[0-9]{1,5}\/([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g
