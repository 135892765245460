import React, { useContext } from "react"
import { DropzoneContext } from "../../contexts/dropzone"
import clsx from "clsx"
import { makeStyles } from "@material-ui/styles"

export default function DropzoneRoot({ children, className = "" }) {
  const { getRootProps, getInputProps } = useContext(DropzoneContext)
  const c = useStyles({})

  return (
    <div {...getRootProps()} className={clsx(c.dropzoneRoot, className)}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    dropzoneRoot: {
      height: "100%",
      padding: 0,
    },
  }
})
