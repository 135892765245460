import {
  type VideoPlayer,
  type VideoPlayerContainer,
  VideoQuality,
} from "@zoom/videosdk"
import React, {
  DetailedHTMLProps,
  DOMAttributes,
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { RouteComponentProps } from "react-router-dom"
import ZoomMediaContext from "../../context/MediaContext"
import ZoomClientContext from "../../context/ZoomClientContext"
// import {} from "csstype
import ShareView from "./components/share-view"
import VideoFooter from "./components/video-footer"
import AvatarActionContext from "./context/avatar-context"
// import ReportBtn from "./components/report-btn"
import { Radio } from "antd"
import clsx from "clsx"
import { StyledProps } from "types/type"
import { useOrientation, usePrevious } from "../../hooks"
import { Participant } from "../../index-types"
import Avatar from "./components/avatar"
import { useAvatarAction } from "./hooks/useAvatarAction"
import { useActiveVideo } from "./hooks/useAvtiveVideo"
import { useNetworkQuality } from "./hooks/useNetworkQuality"
import { useParticipantsChange } from "./hooks/useParticipantsChange"
import { useVideoAspect } from "./hooks/useVideoAspectRatio"
import { useVideoStyles } from "./useVideoStyles"
type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ["video-player"]: DetailedHTMLProps<
        HTMLAttributes<VideoPlayer>,
        VideoPlayer
      > & { class?: string }
      ["video-player-container"]: CustomElement<VideoPlayerContainer> & {
        class?: string
      }
      ["zoom-video"]: DetailedHTMLProps<
        HTMLAttributes<VideoPlayer>,
        VideoPlayer
      > & { class?: string }
      ["zoom-video-container"]: CustomElement<VideoPlayerContainer> & {
        class?: string
      }
    }
  }
}
export function VideoAttach(props: StyledProps) {
  const zmClient = useContext(ZoomClientContext)
  const { mediaStream } = useContext(ZoomMediaContext)
  const [participants, setParticipants] = useState(zmClient.getAllUser())
  const [subscribers, setSubscribers] = useState<number[]>([])
  const previousSubscribers = usePrevious(subscribers)

  const shareViewRef = useRef<{
    selfShareRef: HTMLCanvasElement | HTMLVideoElement | null
  }>(null)

  const videoPlayerListRef = useRef<Record<string, VideoPlayer>>({})
  const [isRecieveSharing, setIsRecieveSharing] = useState(false)
  const activeVideo = useActiveVideo(zmClient)
  const avatarActionState = useAvatarAction(zmClient, participants, true)
  const networkQuality = useNetworkQuality(zmClient)

  const c = useVideoStyles()

  const aspectRatio = useVideoAspect(zmClient)

  const optionsOfVideoResolution = [
    { label: "720P", value: VideoQuality.Video_720P },
    { label: "360P", value: VideoQuality.Video_360P },
    { label: "180P", value: VideoQuality.Video_180P },
    { label: "90P", value: VideoQuality.Video_90P },
  ]

  const orientation = useOrientation()

  const maxVideoCellWidth =
    orientation === "portrait"
      ? "none"
      : `calc(100vw/${Math.min(participants.length, 4)})`

  useParticipantsChange(zmClient, (participants) => {
    let pageParticipants: Participant[] = []
    if (participants.length > 0) {
      if (participants.length === 1) {
        pageParticipants = participants
      } else {
        pageParticipants = participants
          .filter((user) => user.userId !== zmClient.getSessionInfo().userId)
          .sort(
            (user1, user2) => Number(user2.bVideoOn) - Number(user1.bVideoOn)
          )
        const currentUser = zmClient.getCurrentUserInfo()
        if (currentUser) {
          pageParticipants.splice(1, 0, currentUser)
        }
      }
    }
    setParticipants(pageParticipants)
    setSubscribers(
      pageParticipants.filter((user) => user.bVideoOn).map((u) => u.userId)
    )
  })
  const setVideoPlayerRef = (userId: number, element: VideoPlayer | null) => {
    if (element) {
      videoPlayerListRef.current[`${userId}`] = element
    }
  }
  useEffect(() => {
    const addedUsers = subscribers.filter(
      (user) => !(previousSubscribers || []).includes(user)
    )
    const removedUsers = (previousSubscribers || []).filter(
      (user) => !subscribers.includes(user)
    )
    if (removedUsers.length > 0) {
      removedUsers.forEach((userId) => {
        mediaStream?.detachVideo(userId)
      })
    }
    if (addedUsers.length > 0) {
      addedUsers.forEach((userId) => {
        const attachment = videoPlayerListRef.current[`${userId}`]
        if (attachment) {
          mediaStream?.attachVideo(userId, VideoQuality.Video_720P, attachment)
        }
      })
    }
  }, [subscribers, previousSubscribers, mediaStream])

  const onVideoResolutionChange = useCallback(
    ({ target: { value } }: any, userId: number) => {
      const attachment = videoPlayerListRef.current[`${userId}`]
      mediaStream?.attachVideo(userId, value, attachment)
    },
    [videoPlayerListRef, mediaStream]
  )

  return (
    <div
      className={clsx(props.className, c.root, "video-attach")}
      style={props.style}
    >
      <ShareView
        ref={shareViewRef}
        onRecieveSharingChange={setIsRecieveSharing}
      />
      <div
        className={clsx(c.videoContainer, c.videoContainerAttech, {
          [c.videoContainerInSharing]: isRecieveSharing,
        })}
      >
        <div className={c.videoContainerWrap}>
          <AvatarActionContext.Provider value={avatarActionState}>
            <video-player-container className={c.userList}>
              {participants.map((user) => {
                return (
                  <div
                    className={c.videoCell}
                    key={user.userId}
                    style={
                      aspectRatio[`${user.userId}`]
                        ? {
                            aspectRatio: aspectRatio[`${user.userId}`],
                            maxWidth: maxVideoCellWidth,
                          }
                        : { maxWidth: maxVideoCellWidth }
                    }
                  >
                    {avatarActionState?.avatarActionState[user?.userId]
                      ?.videoResolutionAdjust?.toggled && (
                      <div className={c.changeVideoResolution}>
                        <Radio.Group
                          options={optionsOfVideoResolution}
                          onChange={(value) => {
                            onVideoResolutionChange(value, user.userId)
                          }}
                          defaultValue={VideoQuality.Video_720P}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </div>
                    )}
                    {user.bVideoOn && (
                      <div>
                        <video-player
                          // className={c.videoPlayer}
                          ref={(element) => {
                            setVideoPlayerRef(user.userId, element)
                          }}
                        />
                      </div>
                    )}
                    <Avatar
                      participant={user}
                      key={user.userId}
                      isActive={activeVideo === user.userId}
                      networkQuality={networkQuality[`${user.userId}`]}
                    />
                  </div>
                )
              })}
            </video-player-container>
          </AvatarActionContext.Provider>
        </div>
      </div>
      <VideoFooter
        className={c.videoOperations}
        sharing
        selfShareCanvas={shareViewRef.current?.selfShareRef}
      />
    </div>
  )
}
const VideoContainer: React.FunctionComponent<RouteComponentProps> = () => {
  return <VideoAttach />
}

/** @deprecated */
export default VideoContainer
