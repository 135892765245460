import React from "react"
import useToolbarPluginAPI from "../hooks/useToolbarPluginAPI"

const ToolbarPluginContext = React.createContext(
  {} as ReturnType<typeof useToolbarPluginAPI>
)

ToolbarPluginContext.displayName = "ToolbarPluginContext"

export default ToolbarPluginContext
