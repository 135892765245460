import { makeStyles } from "@material-ui/core"
import React from "react"

export function MessageLine(props: { authorName: string; text: string }) {
  const { authorName, text } = props

  const c = useStyles()

  if (!authorName || !text) return null

  return (
    <React.Fragment>
      <span className={c.authorName}>{authorName}: </span>{" "}
      <span>
        {text
          .split(/\s/)
          .map((a) => (a.length > 20 ? a.slice(0, 20) + "..." : a))
          .join(" ")}
      </span>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  authorName: {
    color: "rgba(0, 0, 0, 0.87)",
  },
}))
