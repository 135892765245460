import { LiveQueryInput } from "hooks/useLiveQuery"
import { CartSubscriptionDocument, useCartQuery } from "lib/graphql/operations"
import React from "react"
import { useLiveQuery } from "../../../hooks/useLiveQuery"

export function useLiveCartQuery(props: {
  cartID: string
  workspaceID: string
}) {
  type Input = LiveQueryInput<typeof useCartQuery>

  const queryProps: Input = {
    useQuery: useCartQuery,
    options: {
      variables: { workspaceID: props.workspaceID, cartID: props.cartID || "" },
      skip: !props.cartID || !props.workspaceID,
      fetchPolicy: "cache-and-network",
    },
    subscriptionDocument: CartSubscriptionDocument,
  }

  const query = useLiveQuery(queryProps)

  return query
}
