import { CollectionItem } from "lib/graphql/types"
import React from "react"
interface DocumentsFilterAPI {
  inputs: React.ReactNode[]
  selectedRelatedCollectionsIDs: string[]
  selectedCollections: CollectionItem[] | null
  unselectCollection(id: string): void
  getLabel(collection?: CollectionItem | null): React.ReactNode
  getTypeCollections(type: string): CollectionItem[] | null | undefined
  selectCollection(collection: CollectionItem): void
}

const DocumentsFilterContext = React.createContext<DocumentsFilterAPI>(
  {} as DocumentsFilterAPI
)

DocumentsFilterContext.displayName = "DocumentsFilterContext"

export default DocumentsFilterContext
