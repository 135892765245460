import { Picker } from "emoji-mart"
import React from "react"

export default function EmojiPicker(props: EmojiPickerProps) {
  return (
    <Picker
      i18n={translate}
      {...props}
      {...(() => {
        const normalizeEmoji = (emoji: Emoji) => {
          return {
            ...emoji,
            unified: emoji.unified.replace(/\-/g, "_"),
          }
        }

        if (typeof props.onSelect === "function") {
          return {
            onClick(emoji: Emoji, event: React.MouseEvent<HTMLButtonElement>) {
              return props.onClick?.call(
                undefined,
                normalizeEmoji(emoji),
                event
              )
            },
            onSelect(emoji: Emoji) {
              return props.onSelect?.call(undefined, normalizeEmoji(emoji))
            },
          }
        }

        return {}
      })()}
    />
  )
}

type Emoji = {
  id: string
  name: string
  colons: string
  unified: string
  text: string
  emoticons: string[]
  skin: string | null
  native: string
}

const translate: I18n = {
  search: "Suche",
  clear: "Löschen",
  notfound: "Kein Emoji gefunden",
  skintext: "Wähle deinen Standard-Hautton",
  categories: {
    search: "Suchergebnisse",
    recent: "Häufig verwendet",
    smileys: "Smileys & Emotionen",
    people: "Menschen & Körper",
    nature: "Tiere & Natur",
    foods: "Essen & Trinken",
    activity: "Aktivität",
    places: "Reisen & Orte",
    objects: "Objekte",
    symbols: "Symbole",
    flags: "Flaggen",
    custom: "Benutzerdefiniert",
  },
  categorieslabel: "Emoji-Kategorien",
  skintones: {
    "1": "Standard-Hautfarbe",
    "2": "Helle Hautfarbe",
    "3": "Mittelhelle Hautfarbe",
    "4": "Mittlere Hautfarbe",
    "5": "Mitteldunkle Hautfarbe",
    "6": "Dunkle Hautfarbe",
  },
}

type I18n = {
  search?: string
  clear?: string
  notfound?: string
  categories?: {
    search?: string
    recent?: string
    smileys?: string
    people?: string
    nature?: string
    foods?: string
    activity?: string
    places?: string
    objects?: string
    symbols?: string
    flags?: string
    custom?: string
  }
  categorieslabel?: string // Accessible title for the list of categories
  skintones?: {
    1?: string
    2?: string
    3?: string
    4?: string
    5?: string
    6?: string
  }
  skintext?: string // Choose your default skin tone
}

type EmojiPickerProps = {
  /** Auto focus the search input when mounted **/
  autoFocus?: boolean
  color?: string // The top bar anchors select and hover color
  emoji?: string // The emoji shown when no emojis are hovered, set to an empty string to show nothing
  include?: string[] // Only load included categories. Accepts I18n categories keys. Order will be respected, except for the recent category which will always be the first.
  exclude?: string[] // Don't load excluded categories. Accepts I18n categories keys.
  custom?: Emoji[] // Custom emojis
  recent?: string[] // Pass your own frequently used emojis as array of string IDs
  enableFrequentEmojiSort?: boolean // Instantly sort “Frequently Used” category
  emojiSize?: number // The emoji width and height
  onClick?: (emoji: Emoji, event: React.MouseEvent<HTMLButtonElement>) => void // Not called when emoji is selected with enter
  onSelect?: (emoji: Emoji) => void
  onSkinChange?: (skin: number) => void
  perLine?: number // Number of emojis per line. While there’s no minimum or maximum, this will affect the picker’s width. This will set Frequently Used length as well (perLine * 4)
  i18n?: I18n // An object containing localized strings
  native?: boolean // Renders the native unicode emoji
  set?: "apple" | "google" | "twitter" | "facebook" // The emoji set
  theme?: "auto" | "light" | "dark" // The picker theme
  sheetSize?: 16 | 20 | 32 | 64 // The emoji sheet size
  backgroundImageFn?: (set: string, sheetSize: number) => string // A Fn that returns that image sheet to use for emojis. Useful for avoiding a request if you have the sheet locally.
  emojisToShowFilter?: (emoji: Emoji) => boolean // A Fn to choose whether an emoji should be displayed or not
  showPreview?: boolean // Display preview section
  showSkinTones?: boolean // Display skin tones picker. Disable both this and showPreview to remove the footer entirely.
  emojiTooltip?: boolean // Show emojis short name when hovering (title)
  useButton?: boolean // When clickable, render emojis with a <button>. Some browsers have issues rendering certain emojis on a button, so you might want to disable this. It is better for accessibility to use buttons.
  skin?: number // Forces skin color
  defaultSkin?: number // Default skin color
  skinEmoji?: string // The emoji used to pick a skin tone. Uses an emoji-less skin tone picker by default
  style?: React.CSSProperties // Inline styles applied to the root element. Useful for positioning
  title?: string // The title shown when no emojis are hovered
  notFoundEmoji?: string // The emoji shown when there are no search results
  notFound?: string
  icons?: {
    [key: string]: React.ReactNode
  } // Custom icons
}
