import { Button, IconButton, Typography } from "@material-ui/core"
import clsx from "clsx"
import ActionsPanel from "components/ActionsPanel/ActionsPanel"
import Dropzone from "components/Dropzone/Dropzone"
import { Icon } from "components/Icon/Icon"
import { useI18n } from "contexts/i18nContext/i18nContext"
import _ from "lodash"
import React from "react"
import BackwardButton from "../BackwardButton/BackwardButton"
import { UploadDocumentsContext } from "../ContextAndProvider"
import EditFilesMetadata from "../EditFilesMetadata/EditFilesMetadata"
import UploadButton from "../UploadButton/UploadButton"

export const steps = [
  {
    id: "dropzone-area",
    FirstColumn() {
      const t = useI18n()
      return (
        <div style={{ padding: 12 }} className={clsx("dropzone-area")}>
          <Dropzone.Area
            idleMessage={t.documentUploadDropzone.idleMessage}
            acceptFileMessage={t.documentUploadDropzone.acceptFileMessage}
            rejectFileMessage={t.documentUploadDropzone.rejectFileMessage}
          ></Dropzone.Area>
        </div>
      )
    },
    Header() {
      const api = React.useContext(UploadDocumentsContext)

      const t = useI18n()

      return (
        <StepsHeader
          right={
            !!api.uploadedFiles?.length && (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={api.stepsController.goForward}
                style={{ paddingRight: 0 }}
              >
                {t.continue} <Icon name="chevron_right"></Icon>
              </Button>
            )
          }
        ></StepsHeader>
      )
    },
  },
  {
    id: "categorize",
    FirstColumn: function FirstColumn() {
      return (
        <div style={{ padding: 12 }}>
          <EditFilesMetadata></EditFilesMetadata>
        </div>
      )
    },
    enableFileSelection: true,

    Header: function Header() {
      const api = React.useContext(UploadDocumentsContext)

      return (
        <StepsHeader
          right={
            <UploadButton
              onClick={() => api.uploadFiles?.({ files: api.uploadedFiles })}
              disabled={api.loading}
              size="small"
              variant="outlined"
            ></UploadButton>
          }
        ></StepsHeader>
      )
    },
    HeaderOld: function Header() {
      const api = React.useContext(UploadDocumentsContext)

      return (
        <ActionsPanel
          actionButtons={[
            <BackwardButton
              onClick={api.stepsController.goBackward}
              disabled={api.loading}
            />,
            <UploadButton
              onClick={() => api.uploadFiles?.({ files: api.uploadedFiles })}
              disabled={api.loading}
            />,
          ]}
        >
          {({ defaultComponent }: { defaultComponent: React.ReactNode }) => (
            <>
              {api.loading && _.isFinite(api.amountUploaded) ? (
                <Typography variant="body1">{`${api.amountUploaded}/${api.uploadedFiles.length} uploaded`}</Typography>
              ) : (
                <span />
              )}
              {defaultComponent}
            </>
          )}
        </ActionsPanel>
      )
    },
  },
]

function StepsHeader(props: { right?: React.ReactNode | null }) {
  return (
    // <ActionsPanel actionButtons={[<ForwardButton onClick={goForward} />]} />
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 12,
        paddingBottom: 6,
        width: "100%",
      }}
    >
      <UploadDocumentsContext.Consumer>
        {(api) => {
          return (
            <>
              <IconButton size="small" onClick={api.stepsController.goBackward}>
                <Icon name="chevron_left"></Icon>
              </IconButton>
              {props.right}
            </>
          )
        }}
      </UploadDocumentsContext.Consumer>
    </div>
  )
}

function Wrapper(props: {
  children: React.ReactNode
  className?: string
  style?: style
}) {
  return <div>{props.children}</div>
}
