import React from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import RichText from "components/RichText/RichText"
import useTypographyClass from "hooks/useTypographyClass"
import { CalendarEventFragment } from "lib/graphql/operations"
import doesHaveNoText from "util/draftJS/doesHaveNoText"

const Description = {
  isVisible(props: { event?: CalendarEventFragment }) {
    const description = props.event?.description

    if (!description) return false

    const isVisible = !doesHaveNoText(description)

    return isVisible
  },
  Component: function Description(props: { event: CalendarEventFragment }) {
    const e = props.event
    const tClasses = useTypographyClass()

    const c = useDescriptionStyles()
    return (
      <RichText
        key={e?.description || ""}
        value={e?.description}
        className={clsx(tClasses["body1"], c.root)}
        EditorProps={{ readOnly: true }}
      ></RichText>
    )
  },
}

export default Description

const useDescriptionStyles = makeStyles((theme) => {
  return {
    root: {
      color: theme.palette.text.secondary,
    },
  }
})
