import React from "react"
import arePropsEqual from "util/arePropsEqual"
import { ListEntryProps } from "../../types"
import { ContactItem } from "./ContactItem"

export default function ContactListItem(props: ListEntryProps) {
  if (props.entry?.__typename !== "ContactPageBoardItem") return null

  return (
    <MemoizedItem
      entry={props.entry}
      className={props.className}
      style={props.style}
    />
  )
}

const MemoizedItem = React.memo(
  ContactItem,
  arePropsEqual([
    // (a) => a.entry?.id,
    (a) => a.entry?.lastUpdate,
    (a) => a.entry?.name,
    (a) => a.entry?.additionalText,
  ])
)
