import moment from "moment"
import useMemoAPI from "hooks/useMemoAPI"
import useOnChange from "hooks/useOnChange"
import React from "react"
import { Month, MonthCalendarProviderProps } from "../types/index"
import getMonth from "../lib/getMonth"

export default function useMonthCalendarAPI(props: MonthCalendarProviderProps) {
  const [selectedDay, setSelectedDay] = React.useState(
    (() => {
      if (!props.selectedDay || !moment(props.selectedDay).isValid())
        return null
      return moment(props.selectedDay)
    })()
  )

  const [month, setMonth] = React.useState<Month>(
    (() => getMonth({ month: props.month, day: props.selectedDay }))()
  )

  useOnChange({
    value: JSON.stringify(props.month),
    onChange() {
      if (
        JSON.stringify(getMonth({ month: props.month })) !==
        JSON.stringify({ month })
      ) {
        setMonth(getMonth({ month: props.month }))
      }
    },
  })

  const format = (d: moment.MomentInput) => moment(d).format("YYYY-MM-DD")

  useOnChange({
    value: format(props.selectedDay),
    onChange(value) {
      if (value !== format(selectedDay)) {
        setSelectedDay(moment(props.selectedDay))

        if (props.selectedDay) {
          setMonth(getMonth({ day: props.selectedDay }))
        }
      }
    },
  })

  const addMonth = React.useCallback(
    (amount: number) => {
      setMonth((month) => {
        const m = moment()
          .year(month.year)
          .month(month.index)
          .startOf("month")
          .add(amount, "month")

        return { index: m.month(), year: m.year() }
      })
    },
    [setMonth]
  )

  //
  useOnChange({
    value: selectedDay,
    onChange(value) {
      props?.onChangeSelectedDay?.call(undefined, { selectedDay: value, month })
    },
  })

  useOnChange({
    value: month,
    onChange(value) {
      props?.onChangeMonth?.call(undefined, { month: value, selectedDay })
    },
  })

  const clearSelection = React.useCallback(
    () => setSelectedDay(null),
    [setSelectedDay]
  )

  return useMemoAPI({
    selectedDay,
    setSelectedDay,
    clearSelection,
    month,
    addMonth,
  })
}
