import { useI18n } from "contexts/i18nContext/i18nContext"
import _ from "lodash"
import { useMemo } from "react"
import jobStatuses, { jobStatusesColors } from "./jobStatuses"
import workStatuses, {
  workStatusesColors,
  workStatusTranslation,
} from "./workStatuses"
import buildMap from "./buildMap"

export default function useActivityStatus() {
  const t = useI18n()
  const marketPlaceCollectionsTypes =
    t.collectionsTypes?.["title_marketplace"] || {}

  const colorMap = useMemo(
    () => ({
      "job.status": jobStatusesColors,
      workStatus: workStatusesColors,
    }),
    []
  )

  const translationMap = useMemo(
    () => ({
      "job.status": {
        ...buildMap("translation", jobStatuses),
        ...Object.entries(marketPlaceCollectionsTypes).reduce(
          (acc, [key, value]) => {
            acc[key.toUpperCase()] = value
            return acc
          },
          {}
        ),
      },

      workStatus: workStatusTranslation,
    }),
    [JSON.stringify(marketPlaceCollectionsTypes)]
  )

  const activitiesStatus = useMemo(
    () => getActivityStatus({ colorMap, translationMap }),
    [colorMap, translationMap]
  )

  return activitiesStatus
}

export function getActivityStatus({
  colorMap = undefined,
  translationMap = undefined,
}) {
  return {
    colorMap,
    translationMap,

    getStatusKey(activity) {
      const jobStatus = activity?.job?.status
      const workStatus = activity?.workStatus

      // return "job.status"

      if (jobStatus && jobStatus !== "FINISHED") return "job.status"
      if (workStatus) return "workStatus"
      else return "job.status"
    },

    getTranslatedValue(activity) {
      const key = this.getStatusKey(activity)
      const value = _.get(activity, key)
      const translationMap = this.translationMap[key] || {}
      const v = translationMap[value] || value
      return v
    },

    getValue(activity) {
      if (!activity) return null
      const key = this.getStatusKey(activity)
      return _.get(activity, key)
    },

    getColor(activity) {
      if (!activity) return null
      const value = this.getValue(activity)
      const colorMap = this.getColorMap(activity)

      if (!colorMap) return null

      const color = colorMap[value]

      return color
    },
    getColorMap(activity) {
      if (!activity) return null

      const key = this.getStatusKey(activity)
      return this.colorMap[key]
    },
    getTranslationMap(activity) {
      if (!activity) return null

      const key = this.getStatusKey(activity)

      return this.translationMap[key]
    },
  }
}
