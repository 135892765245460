import { useApolloClient } from "@apollo/client";
import React, { useContext } from "react"
import _ from "lodash"
import { makeStyles } from "@material-ui/styles"
import initials from "pages/util/initials"
import GoToPrivateRoomButton from "components/GoToPrivateRoomButton/GoToPrivateRoomButton"
import { Typography, useTheme } from "@material-ui/core"
import getter from "util/getter"
import DetailsForPopover from "components/DetailsForPopover/DetailsForPopover"
import InvitationEmailButton from "../InvitationEmailButton/InvitationEmailButton"
import privateRoomColor from "util/privateRoomColor"
import UserPopoverContext from "components/UserPopover/contexts/UserPopoverContext"
// import CallButton from "../CallButton/CallButton"

export default function UserDetails({ user }) {
  const c = useStyles({})
  const g = getter(user)
  const client = useApolloClient()
  const theme = useTheme()

  const { goToPrivateRoom } = useContext(UserPopoverContext)

  const headerBackgroundColor = (() => {
    const defaultColor = _.get(theme, "palette.primary.main")
    return privateRoomColor({ userID: g("id") }, client) || defaultColor
  })()

  return (
    <DetailsForPopover
      className={c.profile}
      headerBackground={
        <div
          className={c.squareAvatar}
          style={{ backgroundColor: headerBackgroundColor }}
        >
          <Typography variant="h5">{initials(user.name)}</Typography>
        </div>
      }
      body={
        <>
          <Typography variant="body1">{user.name}</Typography>
          {g("company") && (
            <Typography variant="body2" color="textSecondary">
              {g("company")}
            </Typography>
          )}
        </>
      }
      actionsButtons={[
        <GoToPrivateRoomButton
          userID={user.id}
          MuiButtonProps={{ variant: "outlined", size: "small" }}
          goToPrivateRoom={goToPrivateRoom}
        />,
        // <InvitationEmailButton userID={user.id} />,
        // <CallButton userID={user.id} />
      ]}
    />
  )
}

const useStyles = makeStyles((theme) => {
  return {
    profile: {},
    squareAvatar: {
      display: "flex",
      alignItems: "center",
      color: "white",
      justifyContent: "center",
    },
  }
})
