import { makeStyles } from "@material-ui/core"
import { useSecondPage } from "components/TwoPages/TwoPages"
import useMemoAPI from "hooks/useMemoAPI"
import _ from "lodash"
import PDFPage from "../components/PDFPage/PDFPage"
import { APIProps } from "../types"
import useDocumentForm from "./useDocumentForm"

export default function useDocumentFormAPI(props: APIProps) {
  const documentForm = useDocumentForm(
    _.pick(props, ["document", "onSaveDocument"])
  )

  const twoPages = props.twoPagesAPI

  const pdfPage = useSecondPage({
    twoPagesAPI: twoPages,
    page: { Page: PDFPage, id: "pdf" },
    isOpen: twoPages.layout.id === "side-by-side",
  })

  const classes = useStyles()

  return useMemoAPI({
    ...props,
    documentForm,
    pdfPage,
    classes,
    twoPagesApi: twoPages,
  })
}

const useStyles = makeStyles((theme) => {
  return {
    indentation: {
      paddingRight: [theme.spacing(2) + "px", "!important"].join(" "),
    },
  }
})
