import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { useFooterApi } from "../hooks/useFooterApi/useFooterApi"

const Factory = contextAndProviderFactory({
  hookApi: useFooterApi,
  contextName: "FooterContext",
})

export const FooterProvider = Factory.Provider
export const FooterContext = Factory.Context

export default FooterContext

FooterContext.displayName = "FooterContext"
