import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { EnhancedPageProps } from "components/EnhancedPage/EnhancedPage"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import Room from "components/Room/Room"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { HeaderWithAssistantStatus } from "./HeaderWithAssistantStatus"
import Title from "./components/Title/Title"

export default function RoomPageBoardItemRoot() {
  const c = useStyles()

  const EnhancedPageProps = useMemoAPI<EnhancedPageProps>({
    titleComponent: React.useRef(<Title></Title>).current,
    body: <Body></Body>,
    HeaderProps: {
      render(args) {
        return (
          <HeaderWithAssistantStatus>
            {args.defaultComponent}
          </HeaderWithAssistantStatus>
        )
      },
    },
  })

  return (
    <>
      <ItemTemplate.Root
        className={clsx(c.root, "room-page-board-item-root")}
        EnhancedPageProps={EnhancedPageProps}
      ></ItemTemplate.Root>
      {/* <RoomPageBoardItemApiContext.Consumer>
        {(api) => {
          return (
            <>
              {(() => {
                if (!api.roomAPI?.roomID) return <div>loading</div>

                return (
                  <RoomVideoConference
                    roomID={api.roomAPI?.roomID}
                    className={clsx(c.root, "room-video-conference")}
                  ></RoomVideoConference>
                )
              })()}
            </>
          )
        }}
      </RoomPageBoardItemApiContext.Consumer> */}
    </>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
}))

function Body() {
  const api = React.useContext(ItemTemplate.Context)
  const size = api.rootContainer.width
  const isSmall = size < 500

  const c = useBodyStyles({ isSmall })

  return (
    <Room.Chat
      className={c.fullHeight}
      MessagesProps={{ className: c.messages }}
    ></Room.Chat>
  )
}

const useBodyStyles = makeStyles(() => ({
  fullHeight: {
    height: "100%",
  },
  main: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: 1,
    overflow: "hidden",
    display: "flex",
    position: "relative",
  },

  panel: {
    position: "absolute",
    padding: ({ isSmall }: Style) => (isSmall ? 8 : 16),
    fontSize: ({ isSmall }: Style) => (isSmall ? 10 : 14),
    left: ({ isSmall }: Style) => (isSmall ? 4 : 16),
    bottom: ({ isSmall }: Style) => (isSmall ? 4 : 16),
  },

  // icon: { marginRight: 8 },

  messages: {
    background: "none",
  },
}))

type Style = {
  isSmall: boolean
}
