import _ from "lodash"
import useParams from "hooks/useParams"
import useSectionsRoutes from "components/SectionsRoutes/hooks/useSectionsRoutes"
import { useCallback, useMemo } from "react"

export default function useSectionsRoutesAPI() {
  const { sectionsRoutes, getSectionRoute } = useSectionsRoutes()

  const { sectionID } = useParams("/account/:accountID/:sectionID")

  const currentSectionRoute = (sectionsRoutes || []).find(
    (a) => sectionID === a?.id
  )
  const api = {
    sectionsRoutes,
    getSectionRoute,
    currentSectionRouteID: _.get(currentSectionRoute, "id"),
    currentSectionRoute,
  }

  return useMemo(() => api, Object.values(api))
}
