import React from "react"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import useMemoAPI from "hooks/useMemoAPI"

export type ItemsFn<T extends any = any> = (args: { isStacked: boolean }) => T[]

export type GroupInTwoInLargeDevicesInput<T extends any = any> = {
  items: T[] | ItemsFn<T>
}

export function useGroupInTwoInLargeDevices<T extends any>(
  props: GroupInTwoInLargeDevicesInput<T>
) {
  const containerApi = useRootContainerAPI()
  const isStacked = containerApi.isSmall

  return useMemoAPI({
    containerRef: containerApi.containerRef,
    isStacked,
    grouped: React.useMemo(() => {
      const items =
        typeof props.items === "function"
          ? props.items({ isStacked })
          : props.items

      if (isStacked) return items.map((a) => [a])

      return group(items, 2)
    }, [props.items, containerApi.isSmall]),
  })
}

function group<T extends any>(items: T[], amountPerGroup: number) {
  if (items.length < amountPerGroup) return items.map((a) => [a])

  const length = Math.ceil(items.length / amountPerGroup)

  const groups = Array.from({ length }).map((_, i) => {
    const group = [...items].slice(i * amountPerGroup, (i + 1) * amountPerGroup)

    return Array.from({ length: amountPerGroup }).map((_, j) => {
      return group[j] || null
    })
  })

  return groups
}
