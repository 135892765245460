import { makeStyles } from "@material-ui/core"
import { ConferenceActivity } from "components/ConferenceActivity/ConferenceActivityContext"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import Room from "components/Room/Room"
import { Shop } from "components/Shop/Shop"
import { ToggleableMenuItem } from "components/ToggleableMenu/ToggleableMenu"
import I18nContext from "contexts/i18nContext/i18nContext"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import React from "react"
import {
  Action,
  ControlButtons,
} from "../../../../../ControlButtons/ControlButtons"
import { RoomPageBoardItemApiContext } from "../../../../contexts/RoomPageBoardItemContext"
import { RoomShoppingPage } from "../../../RoomShoppingPage/RoomShoppingPage"
import { CopyURL } from "./CopyURL"
import { openedKanbanBoardInput } from "./openedKanbanBoardInput"
import { ConferenceButton } from "./ConferenceButton"

export default function RoomControlButtons(props: { collapsed: boolean }) {
  const c = useStyles()

  const api = React.useContext(RoomPageBoardItemApiContext)
  const hasShopping = useCurrentWorkspace().hasShopping
  const templateApi = React.useContext(ItemTemplate.Context)

  const shopApi = React.useContext(Shop.Context)

  const conferenceApi = React.useContext(ConferenceActivity.Context)

  const actions: Action[] = React.useMemo(() => {
    const b: (Action | null)[] = [
      hasShopping
        ? {
            icon: "shopping_cart",
            text: (
              <I18nContext.Consumer>{(t) => t["market"]}</I18nContext.Consumer>
            ),
            BadgeProps: (() => {
              const count = shopApi.cartApi.cart?.items?.length

              return {
                badgeContent: (() => {
                  return count ? count + "" : ""
                })(),
                variant: "standard",
                invisible: !count,
              }
            })(),
            onClick: () => {
              conferenceApi.handleSessionExit(() => {
                templateApi.twoPagesAPI.openHalfPage(RoomShoppingPage)
              })
            },
          }
        : null,
      //       {
      //         icon: "video_call",
      //         onClick: () => api.toggleVideoCallPage(),
      //         text: (
      //           <Conference.Context.Consumer>{
      //           (conferenceApi) => {

      //             const isActive = conferenceApi.isActive.isTrue

      // return <I18nContext.Consumer>
      //             {(t) => {
      //               return <>{isActive ? t.conference.getBack: t.conference.start}</>
      //             }}
      //           </I18nContext.Consumer>
      //           }
      //           }</Conference.Context.Consumer>

      //         ),
      //       },
      { Component: ConferenceButton },
      {
        icon: "group",
        onClick: () => {
          conferenceApi.handleSessionExit(() => {
            api.openMembersList()
          })
        },
        text: "Members",
      },
      {
        icon: "view_kanban",
        text: "Kanban",
        onClick: () => {
          conferenceApi.handleSessionExit(() => {
            templateApi.twoPagesAPI.openSecondPage(openedKanbanBoardInput)
          })
        },
      },

      {
        icon: "search",
        onClick: () => api.searchField.toggle(),
        text: "Search",
      },

      {
        Component: CopyURL,
      },
    ]

    const l = b.filter((a): a is Action => !!a)

    return l
  }, [
    api,
    templateApi.twoPagesAPI.openSecondPage,
    hasShopping,
    shopApi.cartApi.cart?.items?.length,
    conferenceApi.handleSessionExit,
  ])

  return (
    <ControlButtons
      actions={actions}
      collapsed={props.collapsed}
      renderCollapsedSettings={(props: { menuItems: ToggleableMenuItem[] }) => {
        return (
          <Room.SettingsMenu
            className={c.settings}
            closeOnSelect
            options={props.menuItems}
            IconButtonProps={{
              size: "small",
            }}
          ></Room.SettingsMenu>
        )
      }}
    ></ControlButtons>
  )
}
const useStyles = makeStyles(() => ({
  settings: { display: "inline", marginLeft: -8 },
  fullHeight: {
    height: "100%",
  },
}))
