import { useApolloClient } from "@apollo/client";
import React from "react"
import { ID } from "types/type"
import { GetKanbanCard, KanbanImagesAPI } from "components/Kanban/@types"
import useMemoAPI from "hooks/useMemoAPI"
import useAddImages from "./useAddImages"
import useRemoveImage from "./useRemoveImage"
import useSetCoverImage from "./useSetCoverImage"
import useRemoveCoverImage from "./useRemoveCoverImage"
import useAddImagesAndThenCoverWhenUploadingFirstImage from "./useAddImagesAndThenCoverWhenUploadingFirstImage"
import useGetCard from "../useGetCard"
import useSelectionAPI from "../useSelectionAPI"

export default function useImagesAPI(props: {
  workspaceID: ID
  getCard: ReturnType<typeof useGetCard>
}): KanbanImagesAPI {
  const [isAddingImage, setIsLoadingImage] = React.useState<boolean>(false)

  const cover = React.useCallback(
    function cover(cardID: ID) {
      const card = props.getCard(cardID)
      return card?.images?.cover || null
    },
    [props.getCard]
  )

  const list = React.useCallback(
    function list(cardID: ID) {
      const card = props.getCard(cardID)
      return card?.images?.list || null
    },
    [props.getCard]
  )

  const setCover = useSetCoverImage({ workspaceID: props.workspaceID })

  const removeCover = useRemoveCoverImage({ workspaceID: props.workspaceID })

  const removeImage = useRemoveImage({
    workspaceID: props.workspaceID,
  })

  const addImages = useAddImages({
    onChangeLoadingState: setIsLoadingImage,
    workspaceID: props.workspaceID,
  })

  const addImagesAndThenCoverWhenUploadingFirstImage =
    useAddImagesAndThenCoverWhenUploadingFirstImage({
      workspaceID: props.workspaceID,
      addImages,
      setCover,
    })

  const api: KanbanImagesAPI = {
    list,
    cover,
    setCover,
    removeCover,
    removeImage,
    addImages: addImagesAndThenCoverWhenUploadingFirstImage,
    isAddingImages: isAddingImage,
  }

  return useMemoAPI(api)
}
