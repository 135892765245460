import React from "react"
import CartButtons from "../../../CartButtons/CartButtons"
import { ShopArticleProps } from "../../../ShopArticleCard/ShopArticleCard"
import { ShopContext } from "../../ShopContext"

export function CardCartButtons(
  props: Omit<ShopArticleProps, "actionsButtons"> & {
    renderActions?: (props: { cartButtons: React.ReactNode }) => React.ReactNode
  }
) {
  const cart = React.useContext(ShopContext).cartApi

  const cartButtons = (
    <CartButtons
      addMoreOne={() => cart.addMoreOne.action({ articleID: props.article.id })}
      remove={() => {
        cart.remove.action({ articleID: props.article.id })
      }}
      IconButtonProps={{ size: "small" }}
      quantity={cart.getItemQuantity({ articleID: props.article.id })}
    ></CartButtons>
  )

  return cartButtons
}
