import React from "react"
import {
  useCreateKanbanCardMutation,
  useCreateOneCardForEachReturnLineFromTextMutation,
} from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"

export default function useCreateTasksFromLines() {
  const [createTasksFromReturnMutate, { loading: createMultipleTasksLoading }] =
    useCreateOneCardForEachReturnLineFromTextMutation()

  const [createTaskMutate, { loading: createTaskLoading }] =
    useCreateKanbanCardMutation()

  const r = useActiveResources()

  type Input = { text: string; columnID: string }

  const createTasksFromLines = React.useCallback(
    (props: Input) => {
      if (!r.workspaceID) return

      return createTasksFromReturnMutate({
        variables: {
          workspaceID: r.workspaceID,
          text: props.text,
          columnID: props.columnID,
        },
      })
    },
    [r.workspaceID, createTasksFromReturnMutate]
  )

  const createASingleTask = React.useCallback(
    (props: Input) => {
      if (!r.workspaceID) return

      return createTaskMutate({
        variables: {
          workspaceID: r.workspaceID,
          card: { title: props.text },
          columnID: props.columnID,
        },
      })
    },
    [r.workspaceID, createTaskMutate]
  )

  return useMemoAPI({
    createTasksFromLines,
    isCreating: createMultipleTasksLoading || createTaskLoading,
    createASingleTask,
  })
}
