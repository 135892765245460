import React, { useContext, useMemo } from "react"
import Profile from "../Profile/Profile"
import Partner from "components/Partner/index"
// import Modal from "components/Partn"
import PartnerViewContext from "pages/Partners/contexts/PartnerViewContext"
import usePartnerViewProvider from "../../hooks/usePartnerViewProvider"
import Dialog from "components/Dialog/Dialog"
import { AddNewContact } from "components/AddNewKontakt"
import PartnersContext from "pages/Partners/contexts/PartnersContext"
import { PartnersModalIDs } from "pages/Partners/types"
import { IPartnerContext } from "components/Partner/contexts/PartnerContext"

export default function PartnerProfile() {
  const { partner, isPartnerLoading, tabs } = usePartnerViewProvider()
  const { modalID, closeDetails, goToDocumentsTab } =
    useContext(PartnersContext)

  const value: IPartnerContext = useMemo(
    () => ({
      partner,
      isLoading: isPartnerLoading,
      onClickDocumentsButton: goToDocumentsTab,
    }),
    [partner, isPartnerLoading, goToDocumentsTab]
  )

  return (
    <Partner.Context.Provider value={value}>
      <Profile tabs={tabs}></Profile>
      <Dialog
        Content={PartnerModalContact}
        open={modalID === PartnersModalIDs.NEW_CONTACT}
        onClose={closeDetails}
      ></Dialog>
    </Partner.Context.Provider>
  )
}

function PartnerModalContact({
  onChangeLoadingState = (boolean: boolean) => null,
}) {
  const { partner } = useContext(Partner.Context)
  const { modalID, closeModal } = useContext(PartnersContext)

  return (
    <AddNewContact
      partnerID={partner?.id}
      onChangeLoadingState={onChangeLoadingState}
      onClose={closeModal}
    ></AddNewContact>
  )
}
