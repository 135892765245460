import React from "react"
import Room from "components/Room/Room"
import { ButtonBase, Typography } from "@material-ui/core"
import { MessageListItemFragment } from "lib/graphql/operations"
import Avatar from "components/Avatar/Avatar"
import KeyListeners from "components/KeyListeners/KeyListeners"
import formatDate from "components/PageBoard/components/IndexedItems/components/ItemsList/lib/formatDate"
import useListSelection from "hooks/useListSelection"
import { KeyListener } from "components/KeyListeners/hooks/useKeyListenersAPI"
import RoomPageBoardItemApiContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/RoomPageBoardItem/contexts/RoomPageBoardItemContext"

export default function SearchOptions(props: {
  options: MessageListItemFragment[]
}) {
  const api = React.useContext(RoomPageBoardItemApiContext)

  const searchField = api.searchField

  KeyListeners.useEscapeListener(searchField.close, {
    enabled: !!searchField.isOpen,
  })

  const altFKeyListener = React.useMemo(() => {
    const a: KeyListener = { altKey: true, fn: searchField.open, code: "KeyF" }
    return a
  }, [searchField.open])

  const selection = useListSelection({ list: props.options })

  KeyListeners.useListener(altFKeyListener, { enabled: !searchField.isOpen })

  const arrowDown = React.useMemo(() => {
    const a: KeyListener = {
      altKey: false,
      fn: selection.next,
      code: "ArrowDown",
    }
    return a
  }, [selection.next])

  KeyListeners.useListener(arrowDown, { enabled: !!searchField.isOpen })

  const messagesAPI = React.useContext(Room.Messages.Context)

  const select = React.useCallback(() => {
    const m = selection.selected

    if (!m) return

    messagesAPI.listRenderer.scrolling.scrollToItem({
      index: m.index,
    })
  }, [
    selection.selected?.message,
    messagesAPI.listRenderer.scrolling.scrollToItem,
  ])

  const selectKey = React.useMemo(() => {
    const a: KeyListener = {
      altKey: false,
      fn: select,
      code: "Enter",
    }
    return a
  }, [select])

  KeyListeners.useListener(selectKey, {
    enabled: !!searchField.isOpen && !!selection.selected,
  })

  const arrowUp = React.useMemo(() => {
    const a: KeyListener = {
      altKey: false,
      fn: selection.previous,
      code: "ArrowUp",
    }
    return a
  }, [selection.previous])

  KeyListeners.useListener(arrowUp, { enabled: !!searchField.isOpen })

  const searchInputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (!searchField.isOpen) return
    searchInputRef.current?.focus()
  }, [!!searchField.isOpen])

  const b = props.options.map((m, index) => {
    const message = m.message

    if (message.__typename !== "TextMessage") return null

    const isSelected = m.message?.id === selection.selected?.message?.id

    return (
      <ButtonBase
        key={index}
        onClick={() =>
          messagesAPI.listRenderer.scrolling.scrollToItem({
            index: m.index,
          })
        }
        style={{
          width: "100%",
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          borderBottom: "1px solid rgba(0,0,0,0.04)",

          alignItems: "center",
          padding: 8,
          paddingBottom: 4,
          paddingTop: 4,
          ...(() => {
            if (isSelected) return { background: "#ededed" }
            return {}
          })(),
        }}
      >
        <Avatar
          style={{
            height: 30,
            width: 30,
            marginRight: 8,
          }}
          content={message.author.name}
        ></Avatar>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          <div style={{ flex: 1 }}>
            <Typography variant="body2">{message.author.name}</Typography>

            <Typography variant="body2" color="textSecondary">
              {message.content}
            </Typography>
          </div>

          {/* <div></div> */}
          <Typography variant="body2" color="textSecondary">
            {formatDate(message.sentAt)}
          </Typography>
        </div>
      </ButtonBase>
    )
  })

  if (!b?.length) return null

  return <>{b}</>
}
