import { makeStyles, Typography } from "@material-ui/core"
import ClippedText from "components/ClippedText/ClippedText"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import PaperList from "components/PaperList"
import Messages from "components/Room/components/Messages/Messages"
import ShortMessageLine from "components/ShortMessageLine/ShortMessageLine"
import { MessageListItemFragment } from "lib/graphql/operations"
import formatMessageDate from "pages/util/formatMessageDate"
import React from "react"

export default function MessageListItem(props: {
  entry?: MessageListItemFragment
}) {
  const { entry: listItem } = props

  // const { RootContainer } = React.useContext(PaperList.Context)
  const api = React.useContext(ItemsListContext)

  const rootContainer = api.rootContainer
  const { listRenderer } = React.useContext(Messages.Context)

  const c = useStyles({})

  if (!listItem) return null

  return (
    <PaperList.ListItem
      onClick={() =>
        listRenderer.scrolling.scrollToItem({ index: listItem.index })
      }
      left={
        <React.Fragment>
          <PaperList.CenteredListItemText
            className={c.left}
            primary={
              <div className={c.primary}>
                <React.Fragment>
                  {/* <RootContainer.ClippedText
                    maxPercentageMap={{ small: "50%" }}
                  >
                  </RootContainer.ClippedText> */}
                  <ClippedText maxWidth={rootContainer.width / 2}>
                    <>{listItem.message?.author.name}</>
                  </ClippedText>
                  {listItem.message?.sentAt ? (
                    <Typography variant="caption" style={{ opacity: 0.8 }}>
                      {formatMessageDate(listItem.message?.sentAt, {
                        day: "HH:mm",
                        year: "DD.MM",
                        oldPast: "DD.MM.YY",
                      })}
                    </Typography>
                  ) : null}
                </React.Fragment>
              </div>
            }
            secondary={
              <>
                {listItem.message && (
                  <ShortMessageLine
                    message={listItem.message}
                    RootContainer={RootContainer}
                    hideAuthorName
                    maxPercentageMap={{ small: "95%" }}
                  ></ShortMessageLine>
                )}
              </>
            }
          ></PaperList.CenteredListItemText>
        </React.Fragment>
      }
      right={null}
    ></PaperList.ListItem>
  )
}

// const c = useStyl
const useStyles = makeStyles(() => {
  return {
    left: {
      width: "100%",
    },
    primary: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
  }
})
