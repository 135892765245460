import { IconButton, makeStyles, Paper } from "@material-ui/core"
import clsx from "clsx"
import { useBottomModalProps } from "components/BottomAndPopoverModal/useBottomModalProps"
import { Icon, IconProps } from "components/Icon/Icon"
import BasicModal from "components/Modal/Modal"
import _ from "lodash"
import React from "react"

export const Modal = {
  Default: ModalDefault,
  Content: ModalContent,
}

function ModalDefault(props: ModalWrapperProps & ModalContentProps) {
  return (
    <ModalWrapper {...props}>
      <ModalContent
        {..._.omit(props, ["isOpen", "style", "className"])}
      ></ModalContent>
    </ModalWrapper>
  )
}

export type ModalContentProps = {
  onClose: () => any
  className?: string
  style?: React.CSSProperties

  children: React.ReactNode

  render?: (props: {
    defaultComponent: React.ReactNode
    components: { top: React.ReactNode; body: React.ReactNode }
  }) => React.ReactNode

  topButtonsFontSize?: number

  closeButtonIcon?: IconProps["name"]
  renderTop?: (props: {
    defaultComponent: React.ReactNode
    components: { closeButton: React.ReactNode }
    rootComponentProps: ModalContentProps
  }) => React.ReactNode
}
function ModalContent(props: ModalContentProps) {
  const c = useStyles({ topButtonsFontSize: props.topButtonsFontSize })

  return (
    <Paper
      className={clsx("modal-content", c.contentContainer, props.className)}
      style={props.style}
    >
      {(() => {
        const top = (
          <div className={c.topBar}>
            {(() => {
              const components = {
                closeButton: (
                  <IconButton
                    onClick={props.onClose}
                    size="small"
                    className={c.closeButton}
                  >
                    <Icon
                      className="icon"
                      name={props.closeButtonIcon || "close"}
                    ></Icon>
                  </IconButton>
                ),
              }

              const defaultComponent = (
                <React.Fragment>
                  <div
                    className="placeholder"
                    style={{ visibility: "hidden" }}
                  ></div>
                  {components.closeButton}
                </React.Fragment>
              )

              if (props.renderTop)
                return props.renderTop({
                  components,
                  defaultComponent,
                  rootComponentProps: props,
                })

              return defaultComponent
            })()}
          </div>
        )

        const body = <div className={c.body}>{props.children}</div>

        const defaultComponent = (
          <React.Fragment>
            {top}
            {body}
          </React.Fragment>
        )

        if (props.render)
          return props.render({ defaultComponent, components: { top, body } })

        return defaultComponent
      })()}
    </Paper>
  )
}

type ModalWrapperProps = {
  isOpen: boolean
  onClose: () => any
  children: React.ReactNode

  className?: string
  style?: React.CSSProperties
  /** 'no-modal' is used for test purposes */
  variant?: React.ComponentProps<typeof BasicModal>["variant"] | "no-modal"
}

function ModalWrapper(props: ModalWrapperProps) {
  const bottomModalProps = useBottomModalProps()

  if (props.variant === "no-modal") {
    return <>{props.children}</>
  }

  return (
    <BasicModal
      className={clsx("modal-wrapper", props.className)}
      isOpen={props.isOpen}
      onClose={props.onClose}
      {...(() => {
        const variant = props.variant || "bottom-modal"

        if (variant === "bottom-modal") return bottomModalProps

        return { variant }
      })()}
    >
      {props.children}
    </BasicModal>
  )
}

type Style = { topButtonsFontSize?: number }

const useStyles = makeStyles((theme) => {
  return {
    topBar: {
      padding: theme.spacing(2, 2, 0.5, 2),
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    body: {
      flex: 1,
      overflow: "hidden",
      "&>*": {
        maxHeight: "100%",
        overflow: "auto",
      },
    },
    closeButton: {
      padding: 0,
      "& .icon": { fontSize: (props: Style) => props.topButtonsFontSize || 15 },
    },
    contentContainer: {
      width: "100%",
      height: "100%",
      borderTop: "1px solid #d9d9d9",
      background: "white",
      borderRadius: "5px 5px 0",
      display: "flex",
      flexDirection: "column",
    },
  }
})
