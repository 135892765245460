import useForm from "hooks/useForm"
import _ from "lodash"
import React from "react"
import { APIProps } from "../types"
import { DocumentUpdateInput } from "lib/graphql/types"
import useUpdateDocument from "hooks/useUpdateDocument/useUpdateDocument"
import { DocumentFragment } from "lib/graphql/operations"

export default function useDocumentForm(props: {
  document?: DocumentFragment | null
  onSaveDocument?: (document: DocumentUpdateInput) => void
}) {
  const initialValues = React.useMemo(() => {
    const keys: (keyof DocumentUpdateInput)[] = [
      "date",
      "netValueGoods",
      "paymentDate",
      "postDate",
      "text",
      "title",
      "totalAmount",
      "totalTax",
      "type",
    ]

    const v: DocumentUpdateInput = {
      ...(_.pick(props.document, keys) || {}),
    }

    return v
  }, [props.document])

  const a = useUpdateDocument({ documentID: props.document?.id || "" })

  const onSubmit = React.useCallback(
    (p: { values: DocumentUpdateInput }) => {
      return a.updateDocument(p.values).then(() => {
        props.onSaveDocument?.call(undefined, p.values)
      })
    },
    [a.updateDocument]
  )

  const documentForm = useForm({
    initialValues,
    enableReinitialize: true,
    onSubmit,
  })

  return documentForm
}
