import useMemoAPI from "hooks/useMemoAPI"
import useActiveResources from "hooks/useActiveResources"
import {
  useCalendarEventQuery,
  CalendarEventQueryVariables,
} from "lib/graphql/operations"
import useEventForm from "./useEventForm"
import { ApiProps, EventViewID } from "../types"
import useStateController from "hooks/useStateController"
import useOnChange from "hooks/useOnChange"
import useEditState from "hooks/useEditState"
import { makeStyles } from "@material-ui/core"

export default function useCalendarEventAPI(props?: ApiProps) {
  const r = useActiveResources()

  const classes = useStyles()

  const variables: CalendarEventQueryVariables = {
    workspaceID: r.workspaceID,
    eventID: props?.eventID || "",
  }

  const query = useCalendarEventQuery({
    variables,
    skip: !variables.eventID || !variables.workspaceID,
    fetchPolicy: "cache-first",
  })

  const calendarEvent = query.data?.calendarEvent

  const form = useEventForm({ ...props, event: calendarEvent })

  const [viewID, setViewID] = useStateController<EventViewID>({
    value: props?.eventID ? "visualization" : "creation",
  })

  useOnChange({ value: viewID, onChange: props?.onChangeViewID || (() => {}) })

  const edit = useEditState()

  const api = {
    form,
    viewID,
    classes,
    setViewID,
    edit,
    event: calendarEvent,
    ...(props || {}),
  }

  return useMemoAPI(api)
}

const useStyles = makeStyles((theme) => {
  return {
    smallIcon: {
      fontSize: "17px !important",
    },
  }
})
