import "@asseinfo/react-kanban/dist/styles.css"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"
import React from "react"
import { makeStyles, Button, Icon } from "@material-ui/core"
import Board, { BoardProps } from "../Board/Board"
import KanbanContext from "components/Kanban/contexts/KanbanContext"
import clsx from "clsx"
import useOnChange from "hooks/useOnChange"

export default function KanbanRoot(props: {
  style?: React.CSSProperties
  className?: string
}) {
  const context = React.useContext(KanbanContext)
  const c = useStyles({})
  const rootRef = React.useRef<HTMLDivElement>(null)

  useScrollToRightOnNewColumn(rootRef)

  const renderCard = React.useCallback<
    Exclude<BoardProps["renderCard"], undefined>
  >((args) => {
    return args.defaultComponent
  }, [])

  return (
    <>
      <div
        className={clsx(c.root, "kanban-root", props.className)}
        style={props.style}
        ref={rootRef}
      >
        <Board className={c.kanbanBoard} renderCard={renderCard}></Board>
      </div>

      {/* <CardModal></CardModal> */}
    </>
  )
}
export const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
      maxHeight: "100%",
      // overflow: "hidden",
      overflow: "auto",
      alignItems: "flex-start",
      display: "flex",
    },

    button: { marginTop: 10, width: 250, minWidth: 250, marginRight: 12 },
    kanbanBoard: {
      // flex: 1,
      // overflowY: 'scroll'
    },
  }
})

function useScrollToRightOnNewColumn(rootRef: React.RefObject<HTMLDivElement>) {
  const context = React.useContext(KanbanContext)
  const columnsCount = context.board?.columns?.length
  const oldColumnCountRef = React.useRef<number>(columnsCount || 0)

  useOnChange({
    value: columnsCount || 0,
    onChange(newColumnsCount) {
      if (!rootRef.current) return

      if (newColumnsCount > oldColumnCountRef.current) {
        const scrollWidth = rootRef.current?.scrollWidth || 0
        const clientWidth = rootRef.current?.clientWidth || 0

        if (scrollWidth > clientWidth) {
          rootRef.current.scrollLeft = scrollWidth - clientWidth
        }
      }

      oldColumnCountRef.current = newColumnsCount
    },
  })
}
// useScrollToRightOnNewColumn
