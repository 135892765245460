import { ButtonProps } from "@material-ui/core"
import SaveButton from "components/SaveButton/SaveButton"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { FormApi } from "hooks/useForm"

export type FormSaveButtonProps<T extends Object = Object> = {
  form: FormApi<T>
  resetOnSubmit?: boolean
  ButtonProps?: ButtonProps
  label?: string
}

export default function FormSaveButton<T extends Object = Object>(
  props: FormSaveButtonProps<T>
) {
  const translations = useI18n()

  return (
    <SaveButton
      disabled={!props.form.readyToSubmit}
      onClick={async () => {
        await props.form.submit()
        if (!props.resetOnSubmit) return
        return props.form.formik.resetForm()
      }}
      {...(props.resetOnSubmit
        ? { children: translations["save-and-new"] }
        : {})}
      {...props.ButtonProps}
    ></SaveButton>
  )
}
