import { useI18n } from "contexts/i18nContext/i18nContext"
import TextField from "components/TextField/TextField"
import React from "react"
import { StyledProps } from "types/type"
import clsx from "clsx"
import { makeStyles, TextFieldProps } from "@material-ui/core"
import { AddNewPartnerFormContext } from "./ContextAndProvider"
import GroupSelection from "components/GroupSelection/GroupSelection"

export function AddNewPartnerFormRoot(props: StyledProps) {
  const api = React.useContext(AddNewPartnerFormContext)

  const t = useI18n()

  const c = useStyles()

  const form = api.form

  return (
    <div
      className={clsx(c.root, "add-new-contact-form-root", props.className)}
      style={props.style}
    >
      {(() => {
        const field = form.getFieldProps("type")

        return (
          <GroupSelection
            onChange={(type) => {
              field.onChange({
                target: { value: type, name: "type" },
              })
            }}
            type={field.value}
            filter={(group) => !!group.hasPartnerAssociated}
            TextField={TextField}
            TextFieldProps={{
              helperText: form.formik.errors["type"],
              error: !!form.formik.errors["type"],
            }}
          />
        )
      })()}
      <TextField
        {...form.getFieldProps("name1")}
        label={t.name}
        helperText={form.formik.errors["name1"]}
        error={!!form.formik.errors["name1"]}
      />
      <TextField {...form.getFieldProps("taxID")} label={t.taxID} />
      <TextField {...form.getFieldProps("no")} label={t.partnerNumber} />
      <TextField
        {...api.form.getFieldProps("remarks")}
        label={t["remarks"]}
      ></TextField>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    "&>*": {
      paddingBottom: theme.spacing(1),
    },
  },
}))
