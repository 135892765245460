import { Icon, IconButton } from "@material-ui/core"
import LightTooltip from "components/LightTooltip/LightTooltip"
import SelfContainedBall from "components/SelfContainedBall/SelfContainedBall"
import { red } from "constants/colors"
import { PageBoardItemType } from "lib/graphql/types"
import useTypesOptions from "../../hooks/useTypesOptions"

export function IconType({
  type,
  isSelected,
  onClick,
}: {
  type: PageBoardItemType
  isSelected?: boolean
  onClick?: () => void
}) {
  const options = useTypesOptions()

  const icon = options[type]?.icon

  if (!icon) return null

  return (
    <SelfContainedBall
      defaultHeight={30}
      key={type}
      style={{
        ...(() => {
          if (!isSelected)
            return {
              color: "rgba(0,0,0,.6)",
            }

          return {
            background: red[1],
            color: "white",
          }
        })(),

        marginLeft: 4,
      }}
    >
      <LightTooltip title={options[type]?.label || ""}>
        <IconButton
          size="small"
          onClick={() => onClick?.()}
          style={{ color: "inherit", padding: 4 }}
        >
          <Icon color="inherit" style={{ fontSize: 22 }}>
            {icon}
          </Icon>
        </IconButton>
      </LightTooltip>
    </SelfContainedBall>
  )
}
