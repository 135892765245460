import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import Reactions from "components/Reactions/Reactions"
import { RoomContext } from "components/Room/contexts/room"
import UserAvatarWithPopover from "components/UserAvatarWithPopover/UserAvatarWithPopover"
import { red } from "constants/colors"
import { useIsCurrentUserMessage } from "hooks/useIsCurrentUserMessage"
import { MessageFragment } from "lib/graphql/operations"
import _ from "lodash"
import moment from "moment"
import React from "react"
import arePropsEqual from "util/arePropsEqual"
import MessagesContext from "../../contexts/MessagesContext"
import DayIndicator from "../DayIndicator/DayIndicator"
import Message from "./components/Message/Message"
import { MessageActions } from "./components/MessageActions/MessageActions"
import TextSelectionKanbanCardCreation from "./components/TextSelectionKanbanCardCreation/TextSelectionKanbanCardCreation"

function MessageRow(props: {
  index: number
  isHighlighted?: boolean
  firstUnread?: boolean
}) {
  const { index, isHighlighted = false, firstUnread = false } = props

  const { messagesData: listAPI } = React.useContext(MessagesContext)
  const { getMessage, getNextMessage, getPreviousMessage } = listAPI

  const message = getMessage(index)
  const previous = getPreviousMessage(index)
  const next = getNextMessage(index)

  const isCurrentUserMessage = useIsCurrentUserMessage(message)

  const { isSmallMobile, isMobile } = React.useContext(DeviceObserver.Context)

  const roomAPI = React.useContext(RoomContext)

  const c = useStyles({
    isCurrentUserMessage,
    isSmallMobile,
    isMobile,
    isLast: false,
  })
  //

  return (
    <div className={c.row}>
      <div className={clsx(c.background, isHighlighted && c.highlighted)} />
      {!!message && dayChanged({ message, previous }) ? (
        <DayIndicator
          isoDate={_.get(message, "sentAt")}
          className={c.dayChip}
        />
      ) : null}

      {firstUnread && <div className={c.newMessageLine} />}

      <div className={c.messageRow}>
        <Reactions
          message={message}
          position={isCurrentUserMessage ? "right" : "left"}
        >
          <div>
            <div className={c.message}>
              {!!message &&
                (function avatar() {
                  const nextAuthor = _.get(next, "author")
                  const author = _.get(message, "author")
                  const isLastMessage = !next

                  if (!author) return null
                  if (isCurrentUserMessage) return null

                  if (isLastMessage || nextAuthor?.id !== author?.id)
                    return (
                      <UserAvatarWithPopover
                        userID={author.id}
                        className={c.avatar}
                      />
                    )

                  return null
                })()}

              {message && (
                <TextSelectionKanbanCardCreation
                  actionsPosition={isCurrentUserMessage ? "left" : "right"}
                  disabled={roomAPI.disableTasksCreationFromTextSelection}
                >
                  <Message
                    showAuthorName={!isCurrentUserMessage}
                    message={message}
                    isCurrentUserMessage={isCurrentUserMessage}
                  />
                </TextSelectionKanbanCardCreation>
              )}
              {/* {!message && "loading..."} */}
            </div>
          </div>
        </Reactions>
        {message?.actions?.length ? (
          <MessageActions message={message} className={c.messageActions} />
        ) : null}
      </div>
    </div>
  )
}

export default React.memo(
  MessageRow,
  arePropsEqual(["index", "firstUnread", "isHighlighted"])
)

function dayChanged(props: {
  message?: MessageFragment | null
  previous?: MessageFragment | null
}) {
  const { message, previous } = props

  if (!previous) return true

  const day = (d: string) => moment(d).format("DD/MM/YYYY")
  const messageDate = message?.sentAt
  const previousMessageDate = previous?.sentAt

  return day(messageDate) !== day(previousMessageDate)
}

const useStyles = makeStyles((theme) => {
  const rowLeftPadding = theme.spacing(6)

  type StyleProps = {
    isSmallMobile: boolean
    isLast: boolean
    isCurrentUserMessage: boolean
    isMobile: boolean
  }

  return {
    messageActions: {
      marginTop: theme.spacing(1),
    },
    row: {
      position: "relative",
      paddingTop: theme.spacing(0.5),
      minHeight: 2,
    },
    highlighted: {
      // animation: `$myEffect ${theme.transitions.easing.easeInOut}`,
      background: "black",
      opacity: "0.08 !important",
      transition: "background opacity 300ms ease-in-out",
      zIndex: 1,
    },
    background: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      // transition: "opacity 300ms ease-in-out",

      transition: "background opacity 300ms ease-in-out",
      opacity: 0,
    },
    newMessageLine: {
      borderTop: "1px solid " + red[0],
      paddingBottom: theme.spacing(0.25),
    },
    messageRow: {
      // backgroundColor: ({ isHighlighted }) =>
      //   isHighlighted ? "#e4e2e2" : "transparent",
      // transition: "1s background-color",

      paddingBottom: (props: StyleProps) => {
        if (props.isLast) return theme.spacing(4)
        return theme.spacing(0.5)
      },
      paddingLeft: (props: StyleProps) =>
        props.isCurrentUserMessage ? 0 : rowLeftPadding,
      margin: theme.spacing(0, 1),
    },
    dayChip: {
      padding: theme.spacing(1.5, 0),
    },
    message: {
      display: "flex",
      position: "relative",
      justifyContent: (props: StyleProps) =>
        props.isCurrentUserMessage ? "flex-end" : "flex-start",

      "&>*": {
        maxWidth: (props: StyleProps) => (props.isSmallMobile ? "95%" : "75%"),
      },
    },
    avatar: {
      position: "absolute",
      left: -rowLeftPadding,
      bottom: 0,
    },
    dateChip: {
      color: theme.palette.text.secondary,
    },
    dayRow: {
      display: "flex",
      justifyContent: "center",
    },
  }
})
