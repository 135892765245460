import _ from "lodash"
import Room from "components/Room/Room"
import { Icon } from "@material-ui/core"
import React from "react"
import DetailsTabsContext from "../contexts/DetailsTabsContext"
import DetailsTabsIDs from "./detailsTabsIDs"
import DocumentView from "components/DocumentView/index"
import PartnerTab from "../components/PartnerTab/PartnerTab"
import Memo from "components/Memo/Memo"
import * as ChatOverlay from "components/ChatOverlay/index"
import Pdf from "components/Pdf/Pdf"

let basicTabs

export default basicTabs = {
  [DetailsTabsIDs.INFO]: {
    label: <Icon>info</Icon>,
    component: null,
  },
  [DetailsTabsIDs.PARTNER]: {
    label: <Icon>person</Icon>,
    component: <PartnerTab></PartnerTab>,
  },
  [DetailsTabsIDs.DOCUMENT]: {
    label: <Icon>description</Icon>,
    component: (
      <DetailsTabsContext.Consumer>
        {({ document }) => {
          return (
            <Memo factory={() => ({ document })} deps={[document?.id]}>
              {(providerValue) => {
                return (
                  // <DocumentView.Context.Provider value={providerValue}>
                  //   <DocumentView.Root
                  //     disabledSideSheet
                  //     style={{ padding: 0 }}
                  //   ></DocumentView.Root>
                  // </DocumentView.Context.Provider>

                  <Pdf.Provider url={document?.pdf}>
                    <Pdf.Root></Pdf.Root>
                  </Pdf.Provider>
                )
              }}
            </Memo>
          )
        }}
      </DetailsTabsContext.Consumer>
    ),
  },

  [DetailsTabsIDs.ROOM]: {
    label: <Icon>chat</Icon>,
    component: (
      <DetailsTabsContext.Consumer>
        {({ document, statusColor }) => {
          if (!document?.id) return null

          const identifier = { documentID: document?.id }

          return (
            <Memo factory={() => identifier} deps={[identifier.documentID]}>
              {(identifier) => {
                return (
                  <ChatOverlay.Context.Consumer>
                    {({ setRoomIdentifier }) => {
                      if (!identifier.documentID) return null

                      return (
                        <Room.Provider
                          identifier={identifier}
                          tabs={Room.basicTabs}
                          goToPrivateRoom={({ userID }) =>
                            setRoomIdentifier({ userID })
                          }
                        >
                          <Room.Body
                            selectedTabColor={statusColor || "transparent"}
                            hideTabIndicator
                            smallTabsIcon
                          ></Room.Body>
                        </Room.Provider>
                      )
                    }}
                  </ChatOverlay.Context.Consumer>
                )
              }}
            </Memo>
          )
        }}
      </DetailsTabsContext.Consumer>
    ),
  },
}
