import useActiveResources from "hooks/useActiveResources"
import {
  LiveQueryInput,
  useLiveQuery,
  ArrayOptions,
  QueryArrayElement,
} from "hooks/useLiveQuery"
import useMemoAPI from "hooks/useMemoAPI"
import {
  KanbanCardActivitiesQuery,
  KanbanCardActivitiesSubscriptionDocument,
  useKanbanCardActivitiesQuery,
} from "lib/graphql/operations"
import React from "react"
// import { KanbanCardActivitiesSubscriptionDocument } from 'lib/graphql/operations';

export default function useKanbanCardActivities(props: { cardID: string }) {
  type Input = LiveQueryInput<typeof useKanbanCardActivitiesQuery>
  const workspaceID = useActiveResources().workspaceID

  const options = React.useMemo<Input["options"]>(() => {
    return {
      fetchPolicy: "cache-and-network",
      variables: {
        cardID: props.cardID,
        workspaceID,
      },
    }
  }, [workspaceID, props.cardID])

  const arrayOptions = React.useMemo<Input["arrayOptions"]>(() => {
    return {
      isUnique: true,
      sortFn(a, b) {
        return a.when > b.when ? -1 : 1
      },
    }
  }, [])

  const query = useLiveQuery({
    useQuery: useKanbanCardActivitiesQuery,
    options,
    subscriptionDocument: KanbanCardActivitiesSubscriptionDocument,
    arrayOptions,
  })

  return useMemoAPI({
    data: query.data?.kanbanCardActivities?.filter(
      // (a) => a.action === KanbanAction.AddCard
      // (a) => [KanbanAction.AddCard, KanbanAction.AddImage].includes(a.action)
      (a) => Boolean(a)
    ),
  })
}
