import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { getIcon } from "components/PageBoard/hooks/useTypesOptions"
import I18nContext from "contexts/i18nContext/i18nContext"
import { PageBoardItemType } from "lib/graphql/types"
import {
  RelatedItemsChips,
  RelatedItemsChipsProps,
} from "../components/RelatedItemsChips/RelatedItemsChips"
import TemplatePage from "components/PageBoard/components/TemplatePage/contexts/TemplatePageContext"
import { SecondPage } from "../components/SecondPage/SecondPage"

export function getRelatedRoomsFieldProps(args?: {
  RelatedItemsChipsProps?: RelatedItemsChipsProps
}) {
  const fieldProps: FieldsetIndentationProps = {
    icon: getIcon(PageBoardItemType.Room),
    title: <I18nContext.Consumer>{(api) => api.rooms}</I18nContext.Consumer>,
    children: (
      <TemplatePage.Consumer>
        {(api) => {
          return (
            <RelatedItemsChips
              {...args?.RelatedItemsChipsProps}
              onClickItem={(item) => {
                api.twoPagesAPI.openHalfPage(function Page(args) {
                  return (
                    <SecondPage
                      onClose={args.onClose}
                      getItemParams={{
                        item: item,
                      }}
                    ></SecondPage>
                  )
                })
              }}
            ></RelatedItemsChips>
          )
        }}
      </TemplatePage.Consumer>
    ),
  }

  return fieldProps
}
