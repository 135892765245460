import _ from "lodash"
import React from "react"
import { useBoard } from "./useBoard"

export default function useGetCard(props: {
  board: ReturnType<typeof useBoard>
}) {
  // TODO: receive selected cardId to fetch a detailed card from the Query.card

  return React.useCallback(
    (cardID) => {
      if (!props.board) return null
      if (!cardID) return null

      const allCards = (props.board?.columns || []).map((a) => a.cards).flat()

      // allCards[0] = { ...allCards[0], images: { cover: list[0], list } }

      const card = _.keyBy(allCards, "id")[cardID]

      return card
    },
    [props.board]
  )
}
