import React, { useContext } from "react"
import _ from "lodash"
import { Button, Icon, makeStyles, IconButton } from "@material-ui/core"
import MembersContext from "../../contexts/MembersContext"
import { RoomContext } from "components/Room/contexts/room"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import LightTooltip from "components/LightTooltip/LightTooltip"
import clsx from "clsx"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function EditButton() {
  const { modeAPI } = useContext(MembersContext)
  const { room, allowMembersEdition } = useContext(RoomContext)
  const { user } = useCurrentWorkspace()

  const t = useI18n()

  const members = _.get(room, "members", []) || []
  const differentMembers = members.filter((m) => m.id !== _.get(user, "id"))

  const c = useStyles({})

  // const button = (
  //   <Button
  //     variant="contained"
  //     color="primary"
  //     className={clsx("edit-button", c.editButton)}
  //     disabled={!workspaceUserIsOwner}
  //     onClick={modeAPI.edit}
  //   >
  //     {(() => {
  //       if (!differentMembers.length)
  //         return (
  //           <>
  //             <Icon>group_add</Icon>
  //             {"  "} {t["add"]}
  //           </>
  //         )

  //       return (
  //         <>
  //           <Icon>edit</Icon> {t["edit"]}
  //         </>
  //       )
  //     })()}
  //   </Button>
  // )

  const button = (
    <IconButton
      disabled={!allowMembersEdition}
      className={clsx("edit-button", c.editButton)}
      size="small"
      edge="end"
      onClick={modeAPI.edit}
    >
      {(() => {
        return (
          <Icon fontSize="small" color="primary">
            {/* {!differentMembers.length ? "group_add" : "edit"}{" "} */}
            group_add
          </Icon>
        )

        // if (!differentMembers.length)
        //         return (
        //           <>
        //             <Icon>group_add</Icon>
        //             {/* {"  "} {t["add"]} */}
        //           </>
        //         )

        //       return (
        //         <>
        //           <Icon>edit</Icon>
        //           {/* {t["edit"]} */}
        //         </>
        //       )
      })()}
      {/* <Icon fontSize="small" color="primary">
          edit
        </Icon> */}
    </IconButton>
  )

  // <LightTooltip title="cancel edition">

  // </LightTooltip>

  if (!allowMembersEdition) return null
  // return (
  //   <LightTooltip
  //     title={"Only owners can edit the list of members"}
  //     placement="top"
  //   >
  //     {button}
  //   </LightTooltip>
  // )

  return (
    <LightTooltip title={t["add"]} placement="top">
      {button}
    </LightTooltip>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    editButton: {
      // width: "100%",
    },
  }
})
