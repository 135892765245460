import React, { useContext } from "react"
import PresenceList from "components/PresenceList/index"
import InclusionButton from "../../../InclusionButton/InclusionButton"
import MembersContext from "components/Room/components/Members/contexts/MembersContext"
import { RoomMemberFragment } from "lib/graphql/operations"

export default function EditPresenceListItem(props: {
  entry: RoomMemberFragment
}) {
  const member = props.entry
  const { members } = useContext(MembersContext)

  const roomMembersIDs = members
    .map((m) => m?.id)
    .filter((m): m is string => !!m)

  const added = roomMembersIDs.includes(member?.id)

  return (
    <PresenceList.ListItem
      entry={member}
      right={
        <InclusionButton
          member={member}
          edge="end"
          added={added}
        ></InclusionButton>
      }
      isSelected={added}
    ></PresenceList.ListItem>
  )
}
