import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import { useEffect, useMemo, useState } from "react"
import React from "react"

export const GRID_VIEWS = {
  multiColumn: "multi-column",
  singleColumn: "single-column",
}

export default function useGridView() {
  const { isMobile } = React.useContext(DeviceObserver.Context)

  const [view, setView] = useState(GRID_VIEWS.multiColumn)

  useEffect(() => {
    if (!isMobile) setView(GRID_VIEWS.multiColumn)
  }, [isMobile])

  return useMemo(() => {
    return {
      view,
      toggleView() {
        setView((v) => {
          if (v === GRID_VIEWS.multiColumn) return GRID_VIEWS.singleColumn
          return GRID_VIEWS.multiColumn
        })
      },
      setMultiColumnView() {
        setView(GRID_VIEWS.multiColumn)
      },
      setSingleColumnView() {
        setView(GRID_VIEWS.singleColumn)
      },
    }
  }, [view])
}
