import { UnionOmit } from "types/type"
import KanbanCardPageBoardItemProvider, {
  KanbanCardPageBoardItemApiInput,
} from "./components/KanbanCardPageBoardItemProvider/KanbanCardPageBoardItemProvider"
import KanbanCardPageBoardItemRoot from "./components/KanbanCardPageBoardItemRoot/KanbanCardPageBoardItemRoot"
import KanbanCardPageBoardItemContext from "./contexts/KanbanCardPageBoardItemContext"

export default {
  Context: KanbanCardPageBoardItemContext,
  Provider: KanbanCardPageBoardItemProvider,
  Root: KanbanCardPageBoardItemRoot,
  Default: function KanbanCardPageBoardItemDefault(
    props: UnionOmit<
      KanbanCardPageBoardItemApiInput & {
        RootProps?: React.ComponentProps<typeof KanbanCardPageBoardItemRoot>
      },
      "children"
    >
  ) {
    const { RootProps, ...apiProps } = props

    return (
      <KanbanCardPageBoardItemProvider {...(apiProps || {})}>
        <KanbanCardPageBoardItemRoot
          {...(RootProps || {})}
        ></KanbanCardPageBoardItemRoot>
      </KanbanCardPageBoardItemProvider>
    )
  },
}
