import { Icon, makeStyles, Typography } from "@material-ui/core"
import ClippedText from "components/ClippedText/ClippedText"
import {
  NewPageBoardItem_RoomPageBoardItem_Fragment,
  NewPageBoardItemFragment,
} from "lib/graphql/operations"
import React from "react"
import { StyledProps } from "types/type"
import { ItemGridCell } from "../ItemGridCell/ItemGridCell"
import { MessageLine } from "../MessageLine/MessageLine"
import { isTypeEntry } from "../../../../../../../../lib/isTypeEntry"
import clsx from "clsx"

export function RoomItemGridCell(
  props: {
    entry: NewPageBoardItemFragment
    renderIcon?: React.ComponentProps<typeof ItemGridCell>["renderIcon"]
    IconBadgeProps?: React.ComponentProps<typeof ItemGridCell>["IconBadgeProps"]
  } & StyledProps
) {
  const c = useStyles()

  if (
    !isTypeEntry<NewPageBoardItem_RoomPageBoardItem_Fragment>(
      "RoomPageBoardItem",
      props.entry
    )
  ) {
    return null
  }

  const roomName = props.entry.room?.name || props.entry.name

  const silenceIcon = props.entry.room?.isSilenced && (
    <Icon
      className={c.notificationsOffIcon}
      style={{ fontSize: 19, marginLeft: 4 }}
    >
      notifications_off
    </Icon>
  )

  const description = (
    <MessageLine
      text={props.entry.room?.lastMessage?.text || ""}
      authorName={props.entry.room?.lastMessage?.author?.shortName || ""}
    ></MessageLine>
  )

  if (!props.entry) return null

  return (
    <ItemGridCell
      entry={props.entry}
      IconBadgeProps={{
        ...(props.IconBadgeProps || {}),
        classes: { badge: clsx(c.badge, props.IconBadgeProps?.classes?.badge) },
      }}
      title={roomName}
      renderTitle={({ defaultComponent, typographyProps }) => {
        return (
          <Typography {...typographyProps}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ClippedText maxWidth="85%">{roomName}</ClippedText>
              <div
                style={{
                  position: "relative",
                  top: 3,
                  marginLeft: 4,
                }}
              >
                {silenceIcon}
              </div>
            </div>
          </Typography>
        )
      }}
      descriptionText={description}
      timestamp={
        props.entry.room?.lastMessage?.sentAt || props.entry.lastUpdate
      }
    ></ItemGridCell>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    badge: {
      transform: "scale(.85) translate(-50%, -50%);",
    },
    notificationsOffIcon: {
      color: theme.palette.text.secondary,
    },
  }
})
