import * as ApolloReactHooks from "@apollo/client";
import {
  NewPageBoardItemFragment,
  PageBoardItemsInCommonWithUsersQuery,
  PageBoardItemsQuery,
  PageBoardItemsResponseFragment,
  PaginationFragment,
  usePageBoardItemsQuery,
} from "lib/graphql/operations"
import _ from "lodash"
import React from "react"

export function useFetchNextPage<
  T extends PageBoardItemsQuery | PageBoardItemsInCommonWithUsersQuery
>(props: {
  queryResult: ReturnType<typeof ApolloReactHooks.useQuery<T, any>>
  getResponse: (
    p: T | undefined
  ) => PageBoardItemsResponseFragment | undefined | null
  updateQuery: (p: {
    items: NewPageBoardItemFragment[]
    pagination: PaginationFragment | null
  }) => T
}) {
  function fetchMore() {
    if (!props.queryResult?.fetchMore) return

    const response = props.getResponse(props.queryResult?.data)

    const to = response?.pagination?.to || 0

    const lastIndex = response?.pagination?.lastItemIndex || 0

    if (to >= lastIndex) return

    props.queryResult.fetchMore({
      variables: {
        ...props.queryResult.variables,
        pagination: {
          from: (to || 0) + 1,
          size: props.queryResult?.variables?.pagination?.size || 50,
        },
      },
      updateQuery(previous, options) {
        const items = _.uniqBy(
          [
            ...(props.getResponse(previous)?.items || []),
            // ...(options?.fetchMoreResult?.pageBoardItems?.items || []),
            ...(props.getResponse(options?.fetchMoreResult)?.items || []),
          ],
          (a) => a.id
        )

        const pagination =
          props.getResponse(options?.fetchMoreResult)?.pagination || null

        return props.updateQuery({ items, pagination })
      },
    })
  }

  return React.useCallback(fetchMore, [props])
}

// export default function use(props: ReturnType<typeof usePageBoardItemsQuery>) {
//   // function fetchMore() {
//   //   if (!props?.fetchMore) return

//   //   const to = props.data?.pageBoardItems?.pagination?.to || 0
//   //   const lastIndex = props.data?.pageBoardItems?.pagination?.lastItemIndex || 0

//   //   if (!(to < lastIndex)) return

//   //   props.fetchMore({
//   //     variables: {
//   //       ...props.variables,
//   //       pagination: {
//   //         from: (to || 0) + 1,
//   //         size: props.variables?.pagination?.size || 50,
//   //       },
//   //     },
//   //     updateQuery(previous, options) {
//   //       const items = _.uniqBy(
//   //         [
//   //           ...(previous?.pageBoardItems?.items || []),
//   //           ...(options?.fetchMoreResult?.pageBoardItems?.items || []),
//   //         ],
//   //         (a) => a.id
//   //       )

//   //       return {
//   //         __typename: "Query",
//   //         pageBoardItems: {
//   //           __typename: "PageBoardItemsResponse",
//   //           pagination:
//   //             options?.fetchMoreResult?.pageBoardItems?.pagination || null,
//   //           items,
//   //         },
//   //       }
//   //     },
//   //   })
//   // }

//   // return React.useCallback(fetchMore, [props])

//   const p = usePageBoardItemsQuery(props)

//   return useFetchNextPage({
//     queryResult: p,
//     getResponse: (p: PageBoardItemsQuery | undefined) => p?.pageBoardItems,
//     updateQuery(p) {
//       const l: PageBoardItemsQuery = {
//         __typename: "Query",
//         pageBoardItems: {
//           __typename: "PageBoardItemsResponse",
//           pagination: p.pagination,
//           items: p.items || [],
//         },
//       }
//       return l
//     },
//     // updateQuery: p=>{

//     // }
//     // ...props,
//     // getResponse: (p) => p?.
//     // updateQuery: (p) => {

//     // }
//   })
// }
