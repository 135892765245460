import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import DocumentCollections from "components/DocumentCollections/DocumentCollections"
import useTypographyClass from "hooks/useTypographyClass"
import SecondPage from "../SecondPage/SecondPage"
import { EnhancedPageProps } from "components/EnhancedPage/EnhancedPage"

import { PageTextField as TextField } from "components/Page/Page"
// import { SecondPageProps } from "../CollectionsInputPage"

export function MainForm(
  props: Omit<EnhancedPageProps, "body"> & {
    openAddNewPartnerForm: () => any
  }
) {
  const c = useMainFormStyles()

  const renderActions = React.useCallback(
    (() => {
      const renderActions: React.ComponentProps<
        typeof DocumentCollections.CollectionForm
      >["renderActions"] = function renderActions({
        defaultComponent,
        components,
      }) {
        return (
          <React.Fragment>
            {components.addPartnerButton}
            {components.saveButton}
          </React.Fragment>
        )
      }

      return renderActions
    })(),
    []
  )

  const tClass = useTypographyClass()

  const body = (
    <React.Fragment>
      <DocumentCollections.CollectionForm
        TextField={TextField}
        renderActions={renderActions}
        classes={{ suggestionsItem: tClass.caption }}
        AddPartnerButtonProps={{
          className: c.formButton,
          // size: "small",
          variant: "outlined",
          classes: {
            label: clsx(c.formButtonLabel, c.addPartnerButton),
          },
          onClick: props.openAddNewPartnerForm,
        }}
        SaveButtonProps={{
          className: c.formButton,
          size: "small",
          classes: {
            label: c.formButtonLabel,
          },
        }}
        className={c.collectionForm}
      ></DocumentCollections.CollectionForm>
      <DocumentCollections.Chips
        hideAddButton
        // ChipProps={{
        //   size: "small",
        //   // classes: { label: tClass.body1, root: c.chipRoot },
        // }}
      ></DocumentCollections.Chips>
    </React.Fragment>
  )

  const p: EnhancedPageProps = {
    className: clsx("collections-input-page", c.root, props.className),
    body,

    ...props,
  }

  return <SecondPage {...p}></SecondPage>
}
const useMainFormStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
    },
    chipRoot: {
      marginBottom: theme.spacing(0.5),
    },

    addPartnerButton: {
      display: "flex",
    },
    collectionForm: {
      marginBottom: theme.spacing(4),
    },

    formButton: {
      marginTop: theme.spacing(1),
      display: "block",
      width: "100%",
    },

    formButtonLabel: {
      // fontSize: "0.71rem",
    },

    content: {},
  }
})
