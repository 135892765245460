import _ from "lodash"
import PresenceListItem from "../PresenceListItem/PresenceListItem"
import React from "react"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import clsx from "clsx"
import PaperList from "components/PaperList"
import { makeStyles } from "@material-ui/core"
import { RoomMember } from "lib/graphql/types"

export default function PresenceListRoot(props: {
  members?: RoomMember[]
  className?: string
  ListItem: typeof PaperList.ListItem
  fetchMore?: () => Promise<any>
}) {
  const {
    members: members = [],
    className = "",
    ListItem: PropsListItem,
  } = props

  const { workspace } = useCurrentWorkspace()
  const c = useStyles({})
  const workspaceOwnerID = workspace?.user?.id

  const orderedMembers = React.useMemo(() => {
    const members = (props.members || [])
      // .sort((a, b) => {
      //   const name = (a: RoomMember) => a?.name?.toLowerCase()
      //   if (name(a) < name(b)) return -1
      //   return 1
      // })
      .filter((m) => m.id !== workspaceOwnerID)

    return members
  }, [members, workspaceOwnerID])

  const items = orderedMembers

  const itemKey = React.useCallback(
    (index: number) => {
      const key = [items?.[index]?.id, items?.[index]?.__typename].join("-")
      return key
    },
    [items]
  )

  return (
    <div className={clsx("presence-list", c.root, className)}>
      <PaperList.Provider
        items={items}
        ListItem={PropsListItem || PresenceListItem}
        itemKey={itemKey}
        fetchMore={props.fetchMore}
      >
        <PaperList.OldRoot
          classes={{ itemPaper: c.itemPaper }}
        ></PaperList.OldRoot>
      </PaperList.Provider>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
    },
    itemPaper: {
      margin: theme.spacing(0, 2),
    },
  }
})
