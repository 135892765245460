import { Typography, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import WindowSize from "components/WindowSize/WindowSize"
import React, { useEffect } from "react"
import { StyledProps } from "types/type"
import { computeTextElementNumberOfLines } from "./computeTextElementNumberOfLines"
import useTrueFalse from "hooks/useTrueFalse"
import I18nContext from "contexts/i18nContext/i18nContext"
import ButtonBase from "components/ButtonBase/ButtonBase"
import StopPropagation from "components/StopPropagation/StopPropagation"

export function TypographyWithLineCounter(
  props: {
    enableLimit: boolean
    linesLimit?: number
    children: string
    TypographyProps?: React.ComponentProps<typeof Typography>
  } & StyledProps
) {
  const [containerRef, textContainerRef] =
    React.useState<HTMLDivElement | null>(null)
  const [lineCount, setLineCount] = React.useState(0)

  const windowSizeApi = React.useContext(WindowSize.Context)

  const moreButtonIsPressed = useTrueFalse(false)

  // console.log({ lineCount, linesLimit: props.linesLimit })

  const c = useStyles({
    linesLimit: moreButtonIsPressed.isTrue ? lineCount : props.linesLimit,
  })

  useEffect(() => {
    process.nextTick(() => {
      const realAmountOfLines = computeTextElementNumberOfLines(containerRef)

      if (typeof realAmountOfLines !== "number") return

      setLineCount(realAmountOfLines)
    })
  }, [windowSizeApi.width, windowSizeApi.height, !!containerRef])

  // const className = clsx(
  //   "typography-with-line-counter",
  //   props.className,
  //   props.TypographyProps?.className
  // )

  const renderTextComponent = (p?: {
    onClick?: () => void
    content?: React.ReactNode
    className?: string
    ref?: (e: HTMLDivElement) => void
  }) => (
    <>
      <Typography
        {...props.TypographyProps}
        className={p?.className}
        ref={p?.ref}
        onClick={p?.onClick}
      >
        {props.children}
      </Typography>
      {p?.content ? (
        <>
          <div style={{ marginTop: 8 }}></div>
          <Typography {...props.TypographyProps}>
            {p?.content || null}
          </Typography>
        </>
      ) : null}
    </>
  )

  const textComponent = renderTextComponent({ ref: (e) => textContainerRef(e) })

  if (!props.enableLimit) return textComponent

  const showMoreButton = lineCount > (props.linesLimit ?? 4)

  const renderedText = renderTextComponent({
    className: props.enableLimit ? c.clamp : "",
    // onClick=

    onClick: () => moreButtonIsPressed.toggle(),
    content: showMoreButton ? (
      <ButtonBase onClick={() => moreButtonIsPressed.toggle()}>
        <span style={{ textDecoration: "underline" }}>
          <I18nContext.Consumer>
            {(t) => {
              return moreButtonIsPressed.isTrue
                ? t["less"].toLowerCase()
                : t["more"].toLowerCase()
            }}
          </I18nContext.Consumer>
        </span>
      </ButtonBase>
    ) : null,
  })

  return (
    <StopPropagation
      className="typography-with-line-counter"
      disabled={!showMoreButton}
    >
      {renderedText}
      {/** rendering 'ghost' element to infer the real amount of lines */}
      <div style={{ overflow: "hidden", height: 0 }}>{textComponent}</div>
    </StopPropagation>
  )
}

type Styles = {
  linesLimit?: number
}

const useStyles = makeStyles((theme) => {
  return {
    clamp: {
      display: "-webkit-box",
      // WebkitLineClamp: (styles: Styles) => styles.numberOfLines,
      WebkitLineClamp: ({ linesLimit: summaryLimitOfLines }: Styles) =>
        summaryLimitOfLines ?? 4,
      // WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
  }
})
