import _ from "lodash"
import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import React, { useContext, useMemo } from "react"
import DetailsTabsContext from "../../../DocumentsDashboard/components/Details/components/DetailsTabs/contexts/DetailsTabsContext"
import useActivityStatus from "hooks/useActivityStatus/useActivityStatus"
import isPaymentType from "lib/isPaymentType"

//REMOVE
export default function BasicFieldsGroups({
  children = ({
    basicInfoFields = [],
    textFields = [],
    collectionsFields = [],
    paymentFields = [],
  }) => null,
}) {
  const { entry: activity, EditControl } = useContext(DetailsTabsContext)
  const { newObjectValue: newActivityValue, isEditing } = useContext(
    EditControl.Context
  )

  const activitiesStatus = useActivityStatus()
  const statusField = activitiesStatus.getStatusKey(activity)

  const basicInfoFields = useMemo(
    () => ["title", "date", "type", "workStatus"],
    [statusField]
  )

  const collectionsFields = useMemo(
    () => ["job.partner.name", "collections"],
    []
  )
  const textFields = useMemo(() => ["text"], [])

  const type = (isEditing ? newActivityValue : activity)?.type
  const isAPaymentActivity = isPaymentType(type)

  const paymentFields = useMemo(() => {
    if (!isAPaymentActivity) return undefined
    return [
      "netValueGoods",
      "paymentDate",
      "totalTax",
      "postDate",
      "totalAmount",
    ]
  }, [isAPaymentActivity])

  return children({
    paymentFields,
    collectionsFields,
    textFields,
    basicInfoFields,
  })
}
