import useMemoAPI from "hooks/useMemoAPI"
import { usePaginatedDocuments } from "hooks/usePaginatedDocuments"
import contextAndProviderFactory from "lib/contextAndProviderFactory"

export type DocumentsChipApiInput = {
  collectionId?: string
  onClickDocumentChip?: (documentID: string) => void
  onClickOpenAllDocuments?: () => void
}

function useDocumentsChipsApi(props?: DocumentsChipApiInput) {
  const paginatedDocuments = usePaginatedDocuments({
    collectionId: props?.collectionId || "",
  })

  return useMemoAPI({ documentsApi: paginatedDocuments, ...(props || {}) })
}
export const {
  Context: DocumentsChipsContext,
  Provider: DocumentsChipsProvider,
} = contextAndProviderFactory({
  hookApi: useDocumentsChipsApi,
  contextName: "DocumentsChipsContext",
})
