import "moment/locale/de"
import ReactDOM from "react-dom"
import "./dev.css"
// import RoomDraft from "components/Drafts/RoomDraft"
// import KanbanDraft from "components/Drafts/KanbanDraft"
// import RichTextDraft from "components/Drafts/RichTextDraft"
// import ShareableDraft from "components/Drafts/ShareableDraft"
// import ChatDraft from "components/Drafts/ChatDraft"
import RoomMessagesDraft from "components/DevDraft/components/RoomMessagesDraft"
import NotificationsDraft from "components/DevDraft/components/NotificationsDraft"
import { app } from "root"

// const initialBoard = Kanban.fakeBoard

// const oxana = {
//   id: "oxana",
//   name: "Oxana Schimidt",
// }

// const ulli = {
//   id: "ulli",
//   name: "Ulli Frotscher",
// }

// const eva = {
//   id: "eva",
//   name: "Eva Meyer",
// }

// const hellon = {
//   id: "hellon",
//   name: "Hellon Canella",
// }

// const

ReactDOM.render(
  // <h1>Helo</h1>,
  <NotificationsDraft></NotificationsDraft>,
  document.getElementById("root")
)
