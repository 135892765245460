import _ from "lodash"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { useContext } from "react"
import ScrollableContentWithFixedTopAndBottom from "components/ScrollableContentWithFixedTopAndBottom/ScrollableContentWithFixedTopAndBottom"
import DocumentFormContext from "components/DeprecatedDocumentForm/contexts/DocumentFormContext"
import FieldsetGroups from "../FieldsetGroups/FieldsetGroups"
import DocumentForm from "components/OldDocumentForm/OldDocumentForm"
import SaveButtons from "components/DeprecatedDocumentForm/components/SaveButtons/SaveButtons"

export default function FormRoot({
  className = "",
  fieldsMap,
  fieldsGroups = undefined,
}) {
  const c = useStyles({})

  const { document: documentObj } = useContext(DocumentForm.Context)

  if (!documentObj?.id) return null

  return (
    <ScrollableContentWithFixedTopAndBottom
      className={clsx("activity-info-tab", c.root, className)}
      content={<FieldsetGroups {...arguments[0]}></FieldsetGroups>}
      bottom={<SaveButtons></SaveButtons>}
    ></ScrollableContentWithFixedTopAndBottom>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(0),
    },
  }
})
