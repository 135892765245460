import { makeStyles } from "@material-ui/core"
// import Avatar from "components/Avatar/Avatar"
import clsx from "clsx"
import { RoomVideoConference } from "components/RoomVideoConference/RoomVideoConference"
import { ZoomProvider } from "components/Zoom/components/ZoomProvider/ZoomProvider"
import ZoomClientContext from "components/Zoom/context/ZoomClientContext"
import { MeetingStateContext } from "components/Zoom/useMeetingState"
import { StyledProps } from "types/type"
import { ConferenceParticipants } from "./components/ConferenceParticipants/ConferenceParticipants"
import { ConferenceControls } from "./components/Controls/Controls"
import { ConferenceProvider } from "./ConferenceContext"
import { ConferenceApiInput } from "./hooks/useConference"
import React from "react"
import { ConferenceActivity } from "components/ConferenceActivity/ConferenceActivityContext"
import UseEffect from "components/UseEffect/UseEffect"

export function RoomConference(
  props: { roomID: string; onLeave?: () => void } & StyledProps
) {
  const c = useStyles()

  const conferenceApi = React.useContext(ConferenceActivity.Context)

  const onLeave = React.useCallback<
    Exclude<ConferenceApiInput["manageLeaving"], undefined>
  >(
    (callback) => {
      if (!props.onLeave) return

      conferenceApi.handleSessionExit(() => {
        callback()
        props.onLeave?.()
      })
    },
    [props.onLeave, conferenceApi.handleSessionExit]
  )

  return (
    <RoomVideoConference roomID={props.roomID}>
      {(p) => {
        return (
          <ZoomProvider meetingArgs={p.meetingArgs}>
            <ZoomClientContext.Consumer>
              {(zoomClient) => {
                return (
                  <MeetingStateContext.Consumer>
                    {(media) => {
                      return (
                        <>
                          <ConferenceProvider
                            zoomClient={zoomClient}
                            mediaStream={media.mediaContext?.mediaStream}
                            manageLeaving={onLeave}
                            sessionID={
                              media.userJoined
                                ? zoomClient.getSessionInfo().sessionId
                                : null
                            }
                          >
                            {(() => {
                              if (!media.userJoined) return null

                              return (
                                <div
                                  className={clsx(
                                    "conference",
                                    props.className
                                  )}
                                  style={props.style}
                                >
                                  <ConferenceParticipants></ConferenceParticipants>
                                  <ConferenceControls
                                    className={clsx(c.conferenceControl, "")}
                                  ></ConferenceControls>
                                </div>
                              )
                            })()}
                          </ConferenceProvider>

                          <ConferenceActivity.Context.Consumer>
                            {(conferenceAPI) => {
                              return (
                                <UseEffect
                                  deps={[]}
                                  effect={() => {
                                    conferenceAPI.isActive.setTrue()

                                    return () => {
                                      conferenceAPI.isActive.setFalse()
                                    }
                                  }}
                                ></UseEffect>
                              )
                            }}
                          </ConferenceActivity.Context.Consumer>
                        </>
                      )
                    }}
                  </MeetingStateContext.Consumer>
                )

                // return (
                //   <ConferenceProvider
                //     zoomClient={zoomClient}
                //     mediaStream={p.mediaStream}
                //     userJoined={p.userJoined}
                //   >
                //     <ConferenceParticipants></ConferenceParticipants>
                //     <ConferenceControls
                //       className={clsx(c.conferenceControl, "")}
                //     ></ConferenceControls>
                //   </ConferenceProvider>
                // )
              }}
            </ZoomClientContext.Consumer>
          </ZoomProvider>
        )
      }}
    </RoomVideoConference>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },

  conferenceControl: {
    position: "absolute",
    bottom: 20,
    left: "50%",
    transform: "translateX(-50%)",
  },
}))
