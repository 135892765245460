//TODO: document
function getCache() {
  const cache = JSON.parse(localStorage.getItem("apollo-cache-persist"))
  return cache
}

function matchApollo(regex, deepestTreeLevel) {
  const treeLevel = deepestTreeLevel
  const cache = getCache()

  const filtered = Object.keys(cache).filter((key) => key.match(regex))
  const mapped = filtered.reduce((acc, key) => {
    return {
      ...acc,
      [key]: mountTree(
        cache[key],
        cache,
        typeof treeLevel === "number" ? treeLevel : 1
      ),
    }
  }, {})

  return mapped
}

function mountTree(node, cache, level = 1) {
  const keys = Object.keys(node)

  const getTree = (key) => {
    const nodeID = (node[key] || {}).id

    if (nodeID) {
      if (level <= 0) return cache[nodeID]
      return mountTree(cache[nodeID], cache, level - 1)
    }

    if (Array.isArray(node[key])) {
      const mapped = node[key].filter(Boolean).map((n) => {
        if (level <= 0) return cache[n.id]
        return mountTree(cache[n.id], cache, level - 1)
      })

      return mapped
    }

    return node[key] || null
  }

  const tree = keys.reduce((acc, key) => {
    return { ...acc, [key]: getTree(key) }
  }, {})

  return tree
}

function searchApollo(regex) {
  const cache = getCache()

  const cacheWithStringValues = Object.keys(cache).reduce((acc, key) => {
    const values = Object.values(cache[key]).map((v) => {
      const isNode = (v || {}).id
      if (isNode) return v.id

      if (Array.isArray(v))
        return v
          .filter(Boolean)
          .map((v) => v.id)
          .join(" ")

      return v
    })

    const stringValue = [key, ...values].join(" ")
    return { ...acc, [key]: stringValue }
  }, {})
  // console.log(cacheWithStringValues)
  const idsThatMatch = Object.entries(cacheWithStringValues)
    .map(([key, stringValue]) => {
      if (stringValue.match(regex)) {
        const id = key
        return id
      }

      return
    })
    .filter(Boolean)

  // console.log(idsThatMatch)
  const nodesThatContainTheIDs = Object.entries(cacheWithStringValues)
    .map(([key, stringValue]) => {
      const matches = idsThatMatch.some((id) => stringValue.includes(id))

      if (matches) return key

      return
    })
    .filter(Boolean)

  // console.log(nodesThatContainTheIDs)
  const obj = nodesThatContainTheIDs.reduce((acc, id) => {
    const tree = mountTree(cache[id], cache)
    return { ...acc, [id]: tree }
  }, {})

  return obj

  // valuesStringified

  // const idsThatMatch // const id = ()=>_.get(cache[key], "id") if(values.match(regex) && !!id()) return id()
}

window.keySearch = matchApollo
window.getCache = getCache
window.valueSearch = searchApollo

// window.searchApollo = {
//   byKey: matchApollo,
//   byValue: searchApollo,
// }
