import { gql } from "@apollo/client";
import _f, { defaultTo, flow, forEach, get, map } from "lodash/fp"
import _ from "lodash"
import readWrite from "util/readWrite"
import getter from "util/getter"
import updateRoomMessageList from "./updateRoomMessageList"
import updateMessage from "./updateMessage"
import {
  RoomMemberAdditionFragment,
  RoomMemberFragment,
  RoomNewMessageFragment,
} from "./graphql/operations"
import { DataProxy } from "apollo-cache"
// import { RoomMemberFragment } from "./graphql/operations"

export default function updateRoom({ update }, client) {
  const updateFn = updateAction[update.__typename]

  if (typeof updateFn !== "function") return

  updateAction[update.__typename]({ update }, client)
}

const updateAction = {
  RoomMessageDeletion({ update }, client) {
    this.RoomNotificationUpdate({ update }, client)

    flow(
      get("messagesUpdates"),
      defaultTo([]),
      forEach((messageUpdate) => {
        updateMessage({ update: messageUpdate }, client)
      })
    )(update)
  },
  RoomNotificationUpdate({ update }, client) {
    const r = getter(update)

    const args = {
      id: `Room:${r("roomID")}`,
      fragment: gql`
        fragment RoomUpdateTwo on Room {
          unread
        }
      `,
    }

    const { read, write } = readWrite({ proxy: client, ...args })

    write({
      __typename: "Room",
      unread: r("unread"),
    })
  },
  RoomNewMessage(props: { update: RoomNewMessageFragment }, client) {
    const { update } = props
    const r = getter(update)

    const roomID = r("roomID")

    const args = {
      id: `Room:${roomID}`,
      fragment: gql`
        fragment RoomUpdateOne on Room {
          id
          unread
          lastMessage {
            id
          }
        }
      `,
    }

    const { read, write } = readWrite({ proxy: client, ...args })

    write({
      __typename: "Room",
      id: roomID,
      unread: r("unread"),
      unread: update.unread,
      lastMessage: (function lastMessage() {
        const message = r("newMessageListItems.0.message")
        return _.pick(message, ["id", "__typename"])
      })(),
    })

    updateRoomMessageList(
      {
        roomID: r("roomID"),
        totalItems: r("totalMessages"),
        newListItems: r("newMessageListItems"),
        workspaceID: update.workspaceID,
      },
      client
    )
  },
  RoomMemberAddition(
    props: { update: RoomMemberAdditionFragment },
    client: DataProxy
  ) {
    // debugger

    userInclusionUpdate(
      {
        roomUpdate: props.update,
        updateRoomMembers: (oldMemberList) => {
          // const toReturn = uniqBy("id")([
          //   ...oldMemberList,
          //   ...(props.update.addedMembers || []),
          // ]).filter(Boolean)

          // _.uniqBy(oldM)

          // return toReturn
          const ok: RoomMemberFragment[] = [
            ...oldMemberList,
            ...(props.update.addedMembers || []),
          ]

          return ok
        },
      },
      client
    )
  },
  RoomMemberRemoval({ update }, client) {
    userInclusionUpdate(
      {
        roomUpdate: update,
        updateRoomMembers: (oldMemberList) => {
          const removedIDs = map("id")(update.removedMembers || [])

          const toReturn = (oldMemberList || []).filter(
            (m) => !removedIDs.includes(m.id)
          )

          return toReturn
        },
      },
      client
    )
  },
}

function userInclusionUpdate(
  props: {
    updateRoomMembers: (a: RoomMemberFragment[]) => RoomMemberFragment[]
    roomUpdate: RoomMemberAdditionFragment
  },
  proxy: DataProxy
) {
  // const r = getter(props.roomUpdate)

  // const args = {
  //   id: `Room:${r("roomID")}`,
  //   fragment: gql`
  //     fragment RoomMemberAddition on Room {
  //       members {
  //         id
  //       }
  //     }
  //   `,
  // }

  // const { read, write } = readWrite({ proxy, ...args })

  // const oldRoomMembers = (read() || {}).members || []
  // const newRoomMembers = props.updateRoomMembers(oldRoomMembers)

  // write({
  //   __typename: "Room",
  //   members: (newRoomMembers || [])
  //     .filter(Boolean)
  //     .map((m) => ({ __typename: "User", id: m.id })),
  // })

  updateRoomMessageList(
    {
      roomID: props.roomUpdate.roomID,
      totalItems: props.roomUpdate.totalMessages,
      newListItems: props.roomUpdate.newMessageListItems,
    },
    proxy
  )
}
