import _ from "lodash"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { useContext } from "react"
import PaperFieldset from "../PaperFieldset/PaperFieldset"
import TimeLog from "../TimeLog/TimeLog"
import DocumentFormContext from "components/DeprecatedDocumentForm/contexts/DocumentFormContext"
import DocumentForm from "components/OldDocumentForm/OldDocumentForm"

export default function FieldsetGroups({
  className = "",
  fieldsMap,
  fieldsets = [],
}) {
  const c = useStyles({})

  const { document: documentObj } = useContext(DocumentForm.Context)

  return (
    <div className={clsx("info-form", c.root, className)}>
      {fieldsets.map((fieldGroup, index) => {
        if (React.isValidElement(fieldGroup))
          return React.cloneElement(fieldGroup, { key: index })

        return (
          <PaperFieldset
            key={index}
            twoColumns
            fieldsMap={fieldsMap}
            fields={fieldGroup}
          ></PaperFieldset>
        )
      })}
      <TimeLog
        created={documentObj?.created}
        createdBy={documentObj?.createdBy}
        updated={documentObj?.updated}
        updatedBy={documentObj?.updatedBy}
      ></TimeLog>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(2),
      "&>*:not(:last-child)": {
        marginBottom: theme.spacing(1),
      },
    },
  }
})
