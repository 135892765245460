import _f from "lodash/fp"
import React from "react"
import _ from "lodash"
import { makeStyles, Paper } from "@material-ui/core"
import clsx from "clsx"
import { useContext } from "react"
import PaperListApiContext from "../../contexts/PaperListContext"
import VirtualizedList from "components/VirtualizedList/VirtualizedList"
import TodoOnceVisible from "components/TodoOnceVisible/TodoOnceVisible"
import arePropsEqual from "util/arePropsEqual"
import { white } from "constants/colors"
import { VariableSizeList } from "react-window"

interface PaperListRootProps {
  className?: string
  classes?: { itemPaper?: string }
  marginZero?: boolean
  style?: React.CSSProperties
}

export default React.memo(
  OldPaperListRoot,
  arePropsEqual<PaperListRootProps>([
    "className",
    (a) => a.classes?.itemPaper,
    "marginZero",
    (a) => JSON.stringify(a.style),
  ])
)

function OldPaperListRoot(props: PaperListRootProps) {
  const { className = "" } = props
  const ctx = React.useContext(PaperListApiContext)

  // const  = context
  const { containerRef: rootContainerRef } = React.useContext(
    ctx.RootContainer.Context
  )

  const ref = React.useRef<VariableSizeList>(null)
  const c = useStyles({})

  const itemCount = ctx.items?.length

  React.useEffect(() => {
    if (!itemCount) return

    ref.current?.resetAfterIndex?.call(undefined, 0)
  }, [itemCount])

  return (
    <div
      className={clsx("paper-list-root", c.root, className)}
      ref={rootContainerRef}
      style={props.style}
    >
      {typeof ctx.items?.length === "number" && (
        <PaperListRootContext.Provider value={props}>
          <VirtualizedList
            itemCount={ctx.items?.length}
            overscanCount={10}
            ref={ref}
            estimatedItemSize={70}
            {...(ctx?.itemKey ? { itemKey: ctx?.itemKey } : {})}
            // itemKey={}
          >
            {MemoizedItem}
          </VirtualizedList>
        </PaperListRootContext.Provider>
      )}
    </div>
  )
}

const MemoizedItem = React.memo(PaperListRootItem, arePropsEqual(["index"]))

function PaperListRootItem(props: { index: number }) {
  const c = useStyles({})

  const rootProps = React.useContext(PaperListRootContext)

  const api = useContext(PaperListApiContext)

  const { index } = props

  const entry = api.items ? api.items[index] : null

  // React.useEffect(() => {
  //   return () => {
  //     console.log("bingo", api.items?.map((a) => a.id).join("-"))
  //   }
  // }, [])

  if (!api.ListItem) return null

  const isFirst = index === 0

  const lastIndex = (api.items?.length || 0) - 1
  const isLast = index === lastIndex

  return (
    <>
      {isFirst && !rootProps.marginZero && <div className={c.space} />}
      <Paper
        className={clsx(
          c.itemPaper,
          rootProps.classes?.itemPaper,
          isFirst && c.firstItemPaper,
          isLast && c.lastItemPaper,
          rootProps.marginZero && c.marginZero
        )}
      >
        <api.ListItem entry={entry} key={entry?.id || index}></api.ListItem>
      </Paper>
      {(Math.max(lastIndex - 10, 0) || lastIndex) === index && (
        <TodoOnceVisible todoOnceVisible={api.fetchMore}></TodoOnceVisible>
      )}
      {isLast && !rootProps.marginZero && <div className={c.space} />}
    </>
  )
}

const PaperListRootContext = React.createContext<PaperListRootProps>({})
PaperListRootContext.displayName = "PaperListRootContext"

const useStyles = makeStyles((theme) => {
  return {
    root: {
      maxHeight: "100%",
      height: "100%",
      // background: white[1],
    },
    list: {
      padding: 0,
    },

    itemPaper: {
      borderRadius: 0,
      margin: theme.spacing(0, 2),
    },

    firstItemPaper: {
      borderRadius: theme.spacing(0.5, 0.5, 0, 0),
    },

    space: {
      margin: 0,
      height: theme.spacing(2),
    },
    lastItemPaper: {
      borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    },
    marginZero: {
      margin: "0 !important",
    },
  }
})
