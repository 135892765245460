import { Link } from "react-router-dom"
import _ from "lodash"
import clsx from "clsx"
import LinkButtonWithIcon, {
  LinkButtonProps,
} from "../LinkButtonWithIcon/LinkButtonWithIcon"

export type SameOriginLinkButtonProps = {
  url?: URL | null
  className?: string
} & LinkButtonProps

export default function SameOriginLinkButton(props: SameOriginLinkButtonProps) {
  if (props.url && props.url.origin !== window.location.origin) {
    console.error(new Error("It is not same origin"))
    return <>{props.url.href}</>
  }

  const to = props.url
    ? (props.url?.pathname || "") + (props.url?.search || "")
    : ""

  return (
    <Link
      to={props.disabled ? "#" : to || "#"}
      className={clsx("link", props.className)}
    >
      <LinkButtonWithIcon
        {..._.omit(props, ["url", "className"])}
      ></LinkButtonWithIcon>
    </Link>
  )
}
