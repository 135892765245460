import { useSubscription, gql } from "@apollo/client";
import { RoomsKanbanUpdatesDocument } from "lib/graphql/operations"
import { RoomKanbanUpdate } from "types/type"
import bindCardImageToCardOnCache from "components/RoomKanban/lib/bindCardImageToCardOnCache"

export default function useRoomsKanbanUpdates() {
  useSubscription(RoomsKanbanUpdatesDocument, {
    onSubscriptionData({ client, subscriptionData }) {
      const roomsUpdates = subscriptionData?.data?.roomsKanbanUpdates

      roomsUpdates?.forEach((roomUpdate: RoomKanbanUpdate) => {
        client.writeFragment({
          fragment: gql`
            fragment RoomWithKanbanNotification on Room {
              kanbanNotificationsCount
            }
          `,
          id: `Room:${roomUpdate?.roomID}`,
          data: {
            __typename: "Room",
            kanbanNotificationsCount: roomUpdate?.boardNotificationsCount,
          },
        })

        if (roomUpdate.update.__typename === "KanbanCardImages") {
          // debugger
          const { cardID } = roomUpdate.update

          if (!cardID) return

          bindCardImageToCardOnCache({ cardID }, client)
        }

        if (roomUpdate.update.__typename === "KanbanBoard") {
          const args = {
            query: gql`
              query RoomKanbanBoard($roomID: ID!, $workspaceID: ID!) {
                roomKanbanBoard(roomID: $roomID, workspaceID: $workspaceID) {
                  id
                }
              }
            `,

            variables: {
              workspaceID: roomUpdate.workspaceID,
              roomID: roomUpdate.roomID,
            },
          }

          client.writeQuery({
            ...args,
            data: {
              roomKanbanBoard: {
                id: roomUpdate.update.id,
                __typename: roomUpdate.update.__typename,
              },
            },
          })
        }
      })
    },
  })
}
