import { SHAREABLE, SHARER } from "constants/links"
import path from "path"

export default function getShareableLink({ type, sharerID, shareableID = "" }) {
  if (!shareableID || !sharerID) return null

  return path.join(
    SHAREABLE,
    ...[type, shareableID, SHARER, sharerID].filter(Boolean)
  )
}
