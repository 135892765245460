import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import _ from "lodash"
import React from "react"
import Helmet from "react-helmet"

import { NotificationsAPI } from "components/NotificationsAPI/NotificationsAPI"

export default function Title() {
  const { workspace } = useCurrentWorkspace()

  const notification = React.useContext(NotificationsAPI.Context).sumCount

  const count = notification || 0

  const t = [count ? `(${count})` : "", workspace?.name || ""]
    .filter(Boolean)
    .join(" ")

  return (
    /**
     * by setting defer=false, we allow the title to update when
     * the tab is inactive (https://github.com/nfl/react-helmet/issues/315#issuecomment-336751182)
     */
    <Helmet defer={false}>
      <title>{t}</title>
    </Helmet>
  )
}
