import React from "react"
import {
  Message_KanbanCardLogMessage_Fragment,
  KanbanActivityFragment,
} from "lib/graphql/operations"
import { MessageBalloonProps } from "../MessageBalloon/MessageBalloon"
import MessageBalloon from "../MessageBalloon/MessageBalloon"
import { Typography } from "@material-ui/core"
import { useI18n } from "contexts/i18nContext/i18nContext"
import RoomTemplateContext from "components/Room/components/RoomTemplate/contexts/RoomTemplateContext"
import { KanbanAction } from "lib/graphql/types"
import AddComment from "./components/AddComment/AddComment"
import AddImage from "./components/AddImage/AddImage"
import AddCard from "./components/AddCard/AddCard"
import DeleteCard, {
  MoveCardBetweenColumns,
} from "./components/DeleteCard/DeleteCard"
import LinkToTheCardButton from "../../../../../LinkToTheCardButton/LinkToTheCardButton"
import { MessageWrapper } from "../MessageWrapper"

export default function KanbanLogMessage(props: {
  message: Message_KanbanCardLogMessage_Fragment
  MessageBalloonProps: Omit<MessageBalloonProps, "message" | "children">
}) {
  const action = props.message.activity?.action

  if (!action) return null

  const Component = ComponentsByAction[action]

  const activity = props.message.activity

  if (!Component || !activity) return null

  return (
    <MessageWrapper {...props}>
      {({ components: { authorName, bottom } }) => {
        return (
          <>
            {authorName}
            <LogMessage message={props.message}>
              {(args) => {
                return React.createElement(Component, {
                  cardLink: args.components.cardLink,
                  activity,
                })
              }}
            </LogMessage>

            {bottom}
          </>
        )
      }}
    </MessageWrapper>
  )

  // return (
  //   <MessageBalloon message={props.message} {...props.MessageBalloonProps}>

  //   </MessageBalloon>
  // )
}

function LogMessage(props: {
  message: Message_KanbanCardLogMessage_Fragment
  children: (props: {
    components: { cardLink: React.ReactNode }
  }) => React.ReactNode
}) {
  const t = useI18n()

  const link = (
    <LinkToTheCardButton
      card={props.message.card || null}
    ></LinkToTheCardButton>
  )

  return (
    <Typography variant="body2">
      {props.children({ components: { cardLink: link } })}
    </Typography>
  )
}

const ComponentsByAction: {
  [i in KanbanAction]?: React.FC<{
    cardLink: React.ReactNode
    activity: KanbanActivityFragment
  }>
} = {
  [KanbanAction.AddCard]: AddCard,
  [KanbanAction.AddComment]: AddComment,
  [KanbanAction.AddImage]: AddImage,
  [KanbanAction.DeleteCard]: DeleteCard,
  [KanbanAction.MoveCardBetweenColumns]: MoveCardBetweenColumns,
}
