import { Icon, IconButton, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { purple } from "constants/colors"

export default function Button(props: {
  className?: string
  onClick: () => any
  iconName: string
  invisible: boolean
  isActive: boolean
}) {
  const c = useStyles()

  if (props.invisible) return null

  return (
    <IconButton
      size="small"
      className={clsx("page-button", props.className)}
      onClick={props.onClick}
    >
      <Icon className={clsx(props.isActive && c.isActive)}>
        {props.iconName}
      </Icon>
    </IconButton>
  )
}

export const useStyles = makeStyles((theme) => {
  return {
    isActive: {
      color: purple[2],
    },
  }
})
