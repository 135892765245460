import { WatchQueryFetchPolicy } from "@apollo/client"
import { DocumentNode } from "graphql"
import useActiveResources from "hooks/useActiveResources"
import useAppIsVisible from "hooks/useAppIsVisible"
import {
  DecoupledKanbanBoardQuery,
  DecoupledKanbanBoardQueryVariables,
  KanbanCardsSubscriptionDocument,
  KanbanCardsSubscriptionSubscription,
  KanbanColumnOrderingSubscriptionDocument,
  KanbanColumnOrderingSubscriptionSubscription,
  RoomKanbanColumnsSubscriptionDocument,
  RoomKanbanColumnsSubscriptionSubscription,
  useDecoupledKanbanBoardQuery,
} from "lib/graphql/operations"
import _ from "lodash"
import { useSubscription } from "observable-hooks"
import React from "react"

export function useBoard(props: {
  roomID: string
  workspaceID: string
  fetchPolicy: WatchQueryFetchPolicy
}) {
  const variables = {
    roomID: props.roomID,
    workspaceID: useActiveResources().workspaceID,
  }

  const query = useDecoupledKanbanBoardQuery({
    variables,
    fetchPolicy: props.fetchPolicy || "cache-first",
    skip: !variables.roomID || !variables.workspaceID,
  })

  React.useEffect(() => {
    const common = {
      query,
      variables,
    }

    const subscriptions = [
      subscribeToMore({
        ...common,
        document: KanbanColumnOrderingSubscriptionDocument,
        updateQuery: (s: KanbanColumnOrderingSubscriptionSubscription) => ({
          columnOrdering: s.kanbanColumnOrdering,
        }),
      }),

      subscribeToMore({
        ...common,
        document: RoomKanbanColumnsSubscriptionDocument,
        updateQuery: (s: RoomKanbanColumnsSubscriptionSubscription, prev) => ({
          columns: _.uniqBy(
            [...(s.roomKanbanColumns || []), ...(prev?.columns || [])],
            (a) => a.id
          ),
        }),
      }),

      subscribeToMore({
        ...common,
        document: KanbanCardsSubscriptionDocument,
        updateQuery: (s: KanbanCardsSubscriptionSubscription, prev) => ({
          cards: _.uniqBy(
            [...(s.kanbanCards || []), ...(prev?.cards || [])],
            (a) => a.id
          ),
        }),
      }),
    ]

    return () => {
      // Unsubscribe from all subscriptions
      subscriptions.forEach((sub) => sub())
    }
  }, [...Object.values(variables)])

  /**
   *  For some reason apollo cache stops updating when
   *  the app becomes visible again.
   *
   *  So with this workaround, we force apollo cache to update
   *  and to work again
   */
  useSubscription(
    useAppIsVisible().appIsVisible$,
    React.useCallback(
      (isVisible) =>
        isVisible &&
        !!variables.roomID &&
        !!variables.workspaceID &&
        query.refetch(),
      [query.refetch]
    )
  )

  const data = query.data?.decoupledKanbanBoard

  const cardsByID = React.useMemo(() => {
    return _.keyBy(data?.cards || [], (a) => a.id)
  }, [data?.cards])

  const columns = React.useMemo(() => {
    const c = (data?.columns || []).map((c) => {
      return {
        ...c,
        cards: (c.orderedCardsIDs || [])
          .map((id) => cardsByID[id] || null)
          .filter((a) => !!a && !a?.isDeleted),
      }
    })

    return c
  }, [data?.columns, cardsByID])

  const columnsByID = React.useMemo(() => {
    return _.keyBy(columns || [], (a) => a.id)
  }, [columns])

  const board = React.useMemo(() => {
    return {
      id: data?.id || "",
      columns: (data?.columnOrdering?.orderedColumnsIDs || [])
        .map((id) => {
          const c = columnsByID[id] || null

          if (!!c?.isDeleted) return null

          return c
        })
        .filter((a): a is Exclude<typeof a, null> => !!a),
    }
  }, [columns, columnsByID, data?.columnOrdering])

  return board
}

function subscribeToMore<Subscription>(args: {
  query: ReturnType<typeof useDecoupledKanbanBoardQuery>
  document: DocumentNode
  variables: DecoupledKanbanBoardQueryVariables
  updateQuery: (
    subData: Subscription,
    prev: DecoupledKanbanBoardQuery["decoupledKanbanBoard"]
  ) => Partial<DecoupledKanbanBoardQuery["decoupledKanbanBoard"]>
}) {
  return args.query.subscribeToMore({
    document: args.document,
    variables: args.variables,
    updateQuery(prev, { subscriptionData }) {
      const subData = subscriptionData.data as Subscription

      if (!subData || !prev.decoupledKanbanBoard) return prev

      const a: DecoupledKanbanBoardQuery = {
        __typename: "Query",
        decoupledKanbanBoard: {
          ...prev.decoupledKanbanBoard,
          ...args.updateQuery(subData, prev.decoupledKanbanBoard),
        },
      }

      return a
    },
  })
}
