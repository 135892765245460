import _ from "lodash"
import * as Sentry from "@sentry/react"
import { ApolloError } from "@apollo/client"
import { print } from "graphql"
import getter from "util/getter"
import { enableSentry } from "./config"
import { ErrorResponse } from "@apollo/client/link/error"

export default function reportApolloErrorToSentry(
  props: Pick<
    ErrorResponse,
    "networkError" | "graphQLErrors" | "operation" | "response"
  >
) {
  const { networkError, graphQLErrors, operation } = props
  if (!networkError && !(graphQLErrors || []).length) return

  enableSentry &&
    Sentry.withScope((scope) => {
      const o = getter(operation)
      scope.setExtra("query", print(o("query")))
      scope.setExtra("variables", o("variables"))

      scope.setTag("operationName", o("operationName"))

      scope.setTag(
        "operationType",
        (function getOperationType() {
          const definition = (o("query.definitions") || []).find(
            (d) => d.kind === "OperationDefinition"
          )
          return definition.operation
        })()
      )

      if (networkError && !(graphQLErrors || []).length) {
        return Sentry.captureException(new ApolloError({ networkError }))
      }

      for (let err of graphQLErrors) {
        const e = getter(err)

        const path = e("path")
        path && scope.setExtra("path", path.join(" > "))

        const stacktrace = e("extensions.exception.stacktrace")

        stacktrace && scope.setExtra("stacktrace", stacktrace.join("\n"))

        const message = e("message")
        message && scope.setExtra("message", message)

        if (!enableSentry) return

        const error = new ApolloError({ networkError, graphQLErrors: [err] })
        Sentry.captureException(error)
      }
    })
}
