import React from "react"
import useSearchMessagesAPI from "../hooks/useSearchMessagesAPI"

type SearchMessagesAPI = ReturnType<typeof useSearchMessagesAPI>

const SearchMessagesContext = React.createContext<SearchMessagesAPI>(
  {} as SearchMessagesAPI
)

SearchMessagesContext.displayName = "SearchMessagesContext"

export default SearchMessagesContext
