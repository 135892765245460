import React from "react"
import DocumentFormContext from "components/OldDocumentForm/contexts/DocumentFormContext"
import TextField from "../../../TextField/TextField"
import moment from "moment"
import { Icon, IconButton } from "@material-ui/core"
import { FieldInputProps } from "formik"
import DateInputPage from "./components/DateInputPage/DateInputPage"
import CalendarDateTimeInput from "components/CalendarDateTimeInput/CalendarDateTimeInput"

export default function DateTextField(
  props: {
    label: string
  } & FieldInputProps<moment.MomentInput | null>
) {
  const { label, ...fieldProps } = props

  // const api = React.useContext(DocumentFormContext)

  // const open = () => {
  //   api.secondPage.openInputPage({
  //     fieldProps,
  //     title: label,
  //     InputPage: DateInputPage,
  //   })
  // }

  // const d = moment(fieldProps.value)

  // const clear = React.useCallback(
  //   () => props.onChange({ target: { name: props.name, value: "" } }),
  //   [props.onChange, props.name]
  // )

  // const endAdornment = (() => {
  //   if (!props.value) return null

  //   return (
  //     <IconButton size="small" onClick={clear}>
  //       <Icon style={{ fontSize: "19px" }}>close</Icon>
  //     </IconButton>
  //   )
  // })()

  // return (
  //   <TextField
  //     label={label}
  //     onFocus={open}
  //     InputProps={{
  //       readOnly: true,
  //       endAdornment,
  //     }}
  //     {...fieldProps}
  //     value={d.isValid() ? d.format("DD.MM.YYYY") : "-"}
  //   ></TextField>
  // )
  return (
    <CalendarDateTimeInput
      {...fieldProps}
      TextFieldProps={{ label }}
      format="DD.MM.YYYY"
    ></CalendarDateTimeInput>
  )
}
