import _ from "lodash"
import { makeStyles, Paper, IconButton, Icon } from "@material-ui/core"
import React, { useContext } from "react"
import SmallContent from "./components/SmallContent/SmallContent"
import LargeContent from "./components/LargeContent/LargeContent"
import DetailsTabsContext from "../DetailsTabs/contexts/DetailsTabsContext"
import ColoredHeaderWithCloseButton from "components/ColoredHeaderWithCloseButton/ColoredHeaderWithCloseButton"
import { Nullable, ObjectWithID } from "types/type"
import DocumentsDashboardContext from "components/DocumentsDashboard/contexts/DocumentsDashboardContext"
import clsx from "clsx"

export default function DetailsHeader(props: {
  selectedEntry: Nullable<ObjectWithID>
  renderTitle: (props: { defaultComponent: React.ReactNode }) => React.ReactNode
  small?: boolean
  className?: string
  onClose?: () => any
  EntryStatus?: typeof EntryStatus
}) {
  const { renderTitle, small = false, className = "", onClose } = props

  const { selectedEntry, EntryStatus } = useContext(DocumentsDashboardContext)

  const { statusColor, hideBackButton } = useContext(DetailsTabsContext)

  const c = useStyles({ backgroundColor: statusColor })
  if (!selectedEntry?.id) return null

  return (
    <ColoredHeaderWithCloseButton
      className={clsx("details-header", className)}
      hideCloseButton={hideBackButton}
      onClose={onClose}
      backgroundColor={statusColor}
      renderContent={() => {
        return small ? (
          <SmallContent
            className={c.content}
            renderTitle={renderTitle}
            activity={selectedEntry}
            EntryStatus={EntryStatus}
          ></SmallContent>
        ) : (
          <LargeContent
            renderTitle={renderTitle}
            className={c.content}
            activity={selectedEntry}
            EntryStatus={EntryStatus}
          ></LargeContent>
        )
      }}
    ></ColoredHeaderWithCloseButton>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    content: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  }
})
