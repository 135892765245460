import ZoomVideo from "@zoom/videosdk"
import React from "react"
import MediaContext from "../../context/MediaContext"
import {
  MeetingArgs,
  MeetingArgsContext,
  MeetingArgsProvider,
} from "../../context/MeetingArgsContext"
import { ZoomClientContext } from "../../context/ZoomClientContext"
import {
  MeetingStateContext,
  MeetingStateProvider,
} from "../../useMeetingState"
import { ZoomApiInput, ZoomApiInputProvider } from "./ZoomApiInputContext"

ZoomVideo.preloadDependentAssets()

export function ZoomProvider(
  props: {
    children: React.ReactNode
    meetingArgs?: Partial<MeetingArgs>
  } & ZoomApiInput
) {
  const zmClient = React.useRef(ZoomVideo.createClient()).current

  return (
    <React.StrictMode>
      <ZoomApiInputProvider manageLeaving={props.manageLeaving}>
        <ZoomClientContext.Provider value={zmClient}>
          <MeetingArgsProvider meetingArgs={props.meetingArgs}>
            <MeetingArgsContext.Consumer>
              {(meetingArgs) => {
                return (
                  <MeetingStateProvider meetingArgs={meetingArgs}>
                    <MeetingStateContext.Consumer>
                      {(meetingState) => {
                        return (
                          <MediaContext.Provider
                            value={meetingState.mediaContext}
                          >
                            {props.children}
                          </MediaContext.Provider>
                        )
                      }}
                    </MeetingStateContext.Consumer>
                  </MeetingStateProvider>
                )
              }}
            </MeetingArgsContext.Consumer>
          </MeetingArgsProvider>
        </ZoomClientContext.Provider>
      </ZoomApiInputProvider>
    </React.StrictMode>
  )
}
