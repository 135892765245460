import { makeStyles, Paper } from "@material-ui/core"
import clsx from "clsx"
import Hook from "components/Hook/Hook"
import useMemoAPI from "hooks/useMemoAPI"
import isProduction from "lib/isProduction"
import React from "react"
import { useRegisterItemInTheHistory } from "../../hooks/useRegisterItemInTheHistory"
import { PageBoardItem } from "../IndexedItems/components/PageBoardItem/PageBoardItem"
import { InitialParams } from "../IndexedItems/components/PageBoardItem/types"
import { IndexedItems } from "../IndexedItems/IndexedItems"
import { ItemParams } from "../IndexedItems/types"
import useComponentSize from "@rehooks/component-size"
import WindowSizeContext from "components/WindowSize/contexts/WindowSizeContext"
// import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

export default function ItemDetails(props: { style?: React.CSSProperties }) {
  const api = React.useContext(IndexedItems.Context)

  const itemsParams = api.selected.params
  const onClose = api.selected.setNull
  const windowSize = React.useContext(WindowSizeContext)

  const initialParams = useDevelopmentInitialParams({
    params: itemsParams,
    desired: {},
  })

  const c = useStyles()

  const historyRef = React.useRef<HTMLDivElement>(null)

  const height = useComponentSize(historyRef).height

  if (!itemsParams) return null

  return (
    <div
      className={clsx(c.root, "item-details")}
      // style={{ marginTop: -height || -29 }}
    >
      <IndexedItems.HistoryNavigation
        goToHome={onClose}
        className={c.historyNavigation}
      >
        {(a) => {
          return (
            <div
              className={"item-detail-history-navigation"}
              style={{
                visibility:
                  a.amountOfRenderedItems < 3 && windowSize.width > 600
                    ? "hidden"
                    : "visible",
              }}
              ref={historyRef}
            >
              {a.defaultComponent}
            </div>
          )
        }}
      </IndexedItems.HistoryNavigation>
      <Paper style={props.style} elevation={2} className={clsx("paper")}>
        <PageBoardItem.Provider
          itemParams={itemsParams}
          {...(initialParams ? { initialParams } : {})}
        >
          <PageBoardItem.ItemPage onClose={onClose}></PageBoardItem.ItemPage>
          <PageBoardItem.Consumer>
            {(api) => {
              return (
                <Hook
                  hook={useRegisterItemInTheHistory}
                  hookProps={{ item: api.item }}
                ></Hook>
              )
            }}
          </PageBoardItem.Consumer>
        </PageBoardItem.Provider>
      </Paper>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    historyNavigation: {
      marginBottom: theme.spacing(1),
    },
    root: {
      "&>.paper": {
        "&>*": {
          height: "100%",
        },
      },
    },
  }
})

function useDevelopmentInitialParams<T extends keyof InitialParams>(props: {
  params: ItemParams | undefined | null
  desired?: {
    [key in T]: InitialParams[T]
  }
}) {
  const initialParams = props.desired?.[
    props.params?.type as keyof typeof props.desired
  ]
    ? props.desired?.[props.params?.type as keyof typeof props.desired]
    : {}

  return useMemoAPI(isProduction ? undefined : initialParams)
}
