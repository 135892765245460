import { FormCommonsContext } from "./FormCommonsContext"
import React from "react"
import FormSaveButton, { FormSaveButtonProps } from "./FormSaveButton"

export function SaveButton(props: Omit<FormSaveButtonProps, "form">) {
  const api = React.useContext(FormCommonsContext)

  if (!api.form) return null
  return <FormSaveButton form={api.form} {...props}></FormSaveButton>
}
