import { makeStyles, IconButton } from "@material-ui/core"
import clsx from "clsx"
import TextField from "components/TextField/TextField"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import PaperList from "components/PaperList"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import { ListItemProps } from "components/PaperList/types"
import UseCallback from "components/UseCallback/UseCallback"
import { Icon } from "components/Icon/Icon"
import { NewPageBoardItem_RoomPageBoardItem_Fragment } from "lib/graphql/operations"
import BoardAndColumnSelectionAPI from "../../../../BoardAndColumnSelectionAPI"
import ListItem from "../ListItem/ListItem"

export default function SelectRoom(props: {
  className?: string
  style?: React.CSSProperties
}) {
  const c = useStyles()
  const t = useI18n()

  const api = React.useContext(BoardAndColumnSelectionAPI.Context)

  const pageBoardItems =
    api.roomSelection?.rooms.itemsQuery.data?.pageBoardItems

  const items = React.useMemo(
    () =>
      (pageBoardItems?.items || [])
        .filter(
          (a): a is NewPageBoardItem_RoomPageBoardItem_Fragment =>
            a.__typename === "RoomPageBoardItem"
        )
        .filter((a) => {
          return !!a.room?.name
        }),
    [pageBoardItems?.items]
  )

  if (!api.roomSelection) return null

  return (
    <div className={clsx(c.root, props.className)} style={props.style}>
      <div>
        <TextField
          label={t["chatRoom"]}
          onChange={api.roomSelection.onChangeSearchText}
          value={api.roomSelection.searchText}
          InputProps={{
            endAdornment: (
              <IconButton
                size="small"
                onClick={() => api.roomSelection.reset()}
                edge="end"
              >
                <Icon name="close" style={{ fontSize: 19 }}></Icon>
              </IconButton>
            ),
          }}
          variant="filled"
        ></TextField>
      </div>
      <UseCallback callback={(index: number) => items[index].id} deps={[items]}>
        {(getItemKey) => {
          return (
            <PaperList.Provider
              items={items}
              ListItem={RoomListItem}
              itemKey={getItemKey}
              fetchMore={api.roomSelection.rooms.fetchNextPage}
            >
              <PaperList.Root></PaperList.Root>
            </PaperList.Provider>
          )
        }}
      </UseCallback>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",

      "&>*": {
        // alignItems: "stretch",
        "&:first-child": {
          padding: theme.spacing(1, 2),
        },

        "&:nth-child(2)": {
          flex: 1,
          overflow: "auto",
        },
      },
    },
  }
})

const RoomListItem: React.FunctionComponent<
  ListItemProps<NewPageBoardItemFragment>
> = function RoomListItem(props) {
  const api = React.useContext(BoardAndColumnSelectionAPI.Context)
  const entry = props.entry

  if (entry?.__typename !== "RoomPageBoardItem") return null

  const name = entry?.room?.name || ""
  const onSelect = () => {
    api.roomSelection.select(entry || null)
  }
  const isSelected = api.roomSelection.isSelected(entry)

  return (
    <ListItem
      name={name}
      onSelect={onSelect}
      isSelected={isSelected}
    ></ListItem>
  )
}
