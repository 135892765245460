import { makeStyles } from "@material-ui/core"
import { white } from "constants/colors"

export const useVideoStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    // backgroundColor: "#000",
    background: white[1],

    display: "flex",
    overflowX: "hidden",
  },
  videoContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    "&$videoContainerAttech": {
      display: "flex",
      alignItems: "center",
    },
    "&$videoContainerInSharing": {
      width: 264,
      flexShrink: 0,
    },
    "&$singleVideoContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  videoContainerAttech: {},
  videoContainerInSharing: {},
  singleVideoContainer: {},
  singleVideoWrap: {
    width: "100%",
    aspectRatio: "16/9",
    height: "auto",
    position: "relative",
    maxHeight: "100%",
  },
  videoCanvas: {
    width: "100%",
    height: "100%",
  },
  selfVideo: {
    position: "absolute",
    width: 254,
    height: 143,
    top: 50,
    right: 30,
    zIndex: 2,
    display: "none",
    "&$singleSelfVideo": {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
    },
    "&$selfVideoShow": {
      display: "block",
    },
  },
  singleSelfVideo: {},
  selfVideoShow: {},
  antRadioButtonWrapper: {
    zIndex: 1,
  },
  videoContainerWrap: {
    flex: 1,
    height: "100%",
  },
  userList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
  },
  videoCell: {
    minWidth: 256,
    aspectRatio: "16/9",
    position: "relative",
    flex: 1,
    margin: 12,
  },
  changeVideoResolution: {
    position: "absolute",
  },
  // videoPlayer: {
  //   display: "block",
  //   position: "absolute",
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   bottom: 0,
  // },
  avatarList: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    overflow: "hidden",
    padding: 0,
    margin: 0,
  },
  videoOperations: {
    position: "absolute",
    left: 0,
    bottom: 0,
  },
  avatarWrap: {
    position: "absolute",
    pointerEvents: "auto",
    listStyle: "none",
    top: 0,
    left: 0,
  },
  avatarWrapDragging: {
    zIndex: 10,
  },
  singleViewAvatar: {
    top: 0,
    left: 0,
  },
  selfVideoTag: {
    display: "none",
    position: "absolute",
  },
}))
