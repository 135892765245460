// import { PdfProvider } from "./PDFProvider"
import PdfBody from "./components/PdfBody/PdfBody"
import PdfControls from "./components/PdfControls/PdfControls"
import PdfPage from "./components/PdfPage/PdfPage"
import PdfRoot from "./components/PdfRoot/PdfRoot"
import PdfContext, { PdfProvider } from "./contexts/PdfContext"

const Pdf = {
  Body: PdfBody,
  Provider: PdfProvider,
  Context: PdfContext,
  Controls: PdfControls,
  Root: PdfRoot,
  Page: PdfPage,
}

export default Pdf
