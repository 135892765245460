import { TextFieldProps } from "@material-ui/core"
import { PageComponent } from "components/Page/hooks/useSecondPage"
import { useI18n } from "contexts/i18nContext/i18nContext"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { PartnerFragment } from "lib/graphql/operations"
import React from "react"
import PartnerFormPage from "../PartnerFormPage/PartnerFormPage"
import TextField from "../TextField/TextField"
import { TwoPagesAPI } from "../TwoPages/TwoPages"

export type PartnerFormProps = Omit<
  React.ComponentProps<typeof PartnerFormPage>,
  "partner" | "summarized" | "onClose"
>

function getPartner(
  args: {
    partner?: PartnerFragment | null
  } & PartnerFormProps
) {
  const PartnerPage: PageComponent = function Partner(props) {
    return (
      <PartnerFormPage
        {...props}
        {...args}
        partnerID={args.partner?.id || ""}
        summarized
      ></PartnerFormPage>
    )
  }

  return PartnerPage
}

type API = {
  partner?: PartnerFragment | null
  layout?: TwoPagesAPI
  PartnerFormProps?: PartnerFormProps
}

const PartnerTextFieldAPI = contextAndProviderFactory({
  hookApi: function usePartnerTextField(input: API) {
    return input
  },
})

export type PartnerTextFieldProps = API & {
  TextFieldProps?: Omit<
    TextFieldProps,
    "label" | "onClick" | "value" | "InputProps"
  >
}

export function PartnerTextField(props: PartnerTextFieldProps) {
  const open = React.useCallback(() => {
    props.layout?.openSecondPage({
      Page: getPartner({
        partner: props.partner,
        ...(props.PartnerFormProps || {}),
      }),
      // layout: { id: "side-by-side", minimized: true },
      ...(() => {
        if (props.layout?.rootContainer.width > 650) {
          return { preferentialLayout: { id: "side-by-side", minimized: true } }
        }

        return {}
      })(),
    })
  }, [props.layout, props.partner, props.PartnerFormProps])

  const t = useI18n()

  if (!props.partner) return null

  return (
    <PartnerTextFieldAPI.Provider layout={props.layout} partner={props.partner}>
      <TextField
        {...props.TextFieldProps}
        label={(() => {
          if (!props.partner) return "Partner"

          const key = (
            props.partner?.type || ""
          ).toLowerCase() as keyof typeof t.appGroups

          return t.appGroups?.[key] || "Partner"
        })()}
        value={props.partner?.name || ""}
        InputProps={{ readOnly: true }}
        onClick={open}
      ></TextField>
    </PartnerTextFieldAPI.Provider>
  )
}
