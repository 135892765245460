import React, { useState, useContext, useMemo } from "react"

export default function getStateContext(
  defaultValue,
  { contextDisplayName = "" } = {}
) {
  const Context = React.createContext([])

  if (contextDisplayName) {
    Context.displayName = contextDisplayName
  }

  function Provider(props) {
    const [state, setState] = useState(props.defaultValue || defaultValue)
    const api = useMemo(() => [state, setState], [JSON.stringify(state)])
    return <Context.Provider value={api}>{props.children}</Context.Provider>
  }

  function useStateContext() {
    return useContext(Context)
  }

  return { Context, Provider, useStateContext }
}
