import { useMemo } from "react"
import { useFooterContext } from "../../contexts/useFooterContext"

export function useDraftEditorProps({ keyBindingFn }) {
  const { onFiles: onFiles } = useFooterContext()

  const draftEditorProps = useMemo(() => {
    return {
      keyBindingFn,
      handlePastedFiles(files) {
        onFiles(files)
        return "handled"
      },
      handleDroppedFiles(__, files) {
        onFiles(files)
        return "handled"
      },
    }
  }, [keyBindingFn, onFiles])

  return draftEditorProps
}
