import { Chip, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import Avatar from "components/Avatar/Avatar"
import ClippedText from "components/ClippedText/ClippedText"
import Hook from "components/Hook/Hook"
import { Icon } from "components/Icon/Icon"
import { HistoryThread } from "components/PageBoard/components/HistoryThread/HistoryThread"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import { getSecondPage } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/SecondPage/getSecondPage"
import useTypesOptions from "components/PageBoard/hooks/useTypesOptions"
import PaperList from "components/PaperList"
import SelfContainedBall from "components/SelfContainedBall/SelfContainedBall"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import { NewPageBoardItem_ShopOrderPageBoardItem_Fragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import _ from "lodash"
import React from "react"
import { ShopOrderArticlesInlinedChips } from "../../../../../../../ShopOrderArticlesInlinedChips"
import EntryRight from "../EntryRight/EntryRight"
import { ItemGridCell } from "../ItemGridCell/ItemGridCell"
import ListItem from "../ListItem/ListItem"

export type ShopOrderItemProps = {
  entry: NewPageBoardItem_ShopOrderPageBoardItem_Fragment
  className?: string
  style?: React.CSSProperties
}

export function ShopOrderItem(props: ShopOrderItemProps) {
  const c = useStyles()
  const t = useI18n()

  const api = React.useContext(ItemsListContext)

  const rootContainer = api.rootContainer

  const isSmall = rootContainer.width < 700

  const maxSize = isSmall ? 150 : 500

  const options = useTypesOptions()

  const historyThread = React.useContext(HistoryThread.Context)

  const title = (() => {
    const id = props.entry.order?.companyOrderId || props.entry.order?.id || ""

    const title = id
      ? t["orderTitle"][true ? "shorter" : "large"](id)
      : props.entry.name || ""

    return title
  })()

  const iconsButtons: React.ComponentProps<typeof EntryRight>["iconsButtons"] =
    React.useMemo(() => {
      return props.entry.significativeRelations
        ?.filter((a) => !historyThread.alreadyVisited(a))
        .map((a) => {
          return {
            icon: options?.[a.type]?.icon,
            onClick: () => {
              api.itemsListTwoPagesApi.openHalfPage(
                getSecondPage({
                  itemParams: { type: a.type, itemID: a.itemID },
                })
              )
            },
          }
        })
    }, [
      props.entry.significativeRelations?.map((a) => a.itemID).join(","),
      options,
      api.itemsListTwoPagesApi.openHalfPage,
      historyThread.alreadyVisited,
    ])

  if (!props.entry) return null

  const chips = (
    <ShopOrderArticlesInlinedChips
      style={{ marginTop: 4 }}
      item={props.entry}
      onClickArticle={(args) => {
        api.itemsListTwoPagesApi.openHalfPage(
          getSecondPage({
            fetchingProps: {
              type: PageBoardItemType.ShopArticle,
              params: { articleID: args.article.id },
            },
          })
        )
      }}
    ></ShopOrderArticlesInlinedChips>
  )

  if (api.variant === "grid") {
    return (
      <ItemGridCell
        title={title}
        timestamp={props.entry.lastUpdate || null}
        renderDescription={({ defaultComponent }) => {
          const items = _.uniqBy(props.entry.order?.items, (a) => a.article.id)

          const sum = items.reduce((acc, a) => {
            return acc + a.quantity
          }, 0)

          return (
            <Chip
              size="small"
              icon={
                <SelfContainedBall style={{ background: "white" }}>
                  <div style={{ padding: 2 }}>{sum}</div>
                </SelfContainedBall>
              }
              label="Artikel"
            ></Chip>
          )

          // return chips
        }}
        entry={props.entry}
      ></ItemGridCell>
    )
  }

  return (
    <ListItem
      className={clsx(c.root, "shop-order-list-item", props.className)}
      style={props.style}
      entry={props.entry}
      right={
        <EntryRight
          // context={"Test Kunde"}
          iconsButtons={iconsButtons}
          lastUpdate={props.entry.lastUpdate}
        ></EntryRight>
      }
      left={
        <React.Fragment>
          {(() => {
            const defaultComponent = (
              <Avatar
                backgroundColor={options[PageBoardItemType.ShopOrder]?.color}
                content={(() => {
                  const icon = options[PageBoardItemType.ShopOrder]?.icon || ""

                  if (!icon) return null
                  return <Icon name={icon}></Icon>
                })()}
              />
            )

            return defaultComponent
          })()}
          <div style={{ marginLeft: 8 }}></div>
          <PaperList.ListItemText
            primary={(() => {
              return (
                <>
                  <Typography variant="caption" color="textPrimary">
                    <ClippedText
                      maxWidth={maxSize}
                      style={{ display: "inline" }}
                    >
                      Bestellung
                    </ClippedText>
                    <Hook hook={useCurrentWorkspace} hookProps={undefined}>
                      {(w) => {
                        const isGuest =
                          w.workspace?.__typename === "GuestWorkspace"

                        //when it is guest, the partner is always the same
                        if (isGuest) return null

                        const name = props.entry.partnerSummary?.name || ""

                        if (!name) return null

                        return (
                          <ClippedText
                            maxWidth={maxSize}
                            style={{ display: "inline" }}
                          >
                            {[" - ", name].join(" ")}
                          </ClippedText>
                        )
                      }}
                    </Hook>
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    <ClippedText maxWidth={maxSize}>{title}</ClippedText>
                  </Typography>
                </>
              )
            })()}
            secondary={(() => {
              return (
                <ItemsListContext.Consumer>
                  {(api) => {
                    return <>{chips}</>
                  }}
                </ItemsListContext.Consumer>
              )
            })()}
          ></PaperList.ListItemText>
        </React.Fragment>
      }
    ></ListItem>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {},
  }
})
