import "emoji-mart/css/emoji-mart.css"
import "./emoji.scss"

import React, { useState } from "react"
import MenuOnClick from "components/MenuOnClick/MenuOnClick"
import { Icon, Typography, makeStyles, Dialog } from "@material-ui/core"
import EmojiDialog from "../EmojiDialog/EmojiDialog"
// import { duplicateFieldDefinitionNameMessage } from "graphql/validation/rules/UniqueFieldDefinitionNames"
import useMenuItems from "./useMenuItems"
import { MessageFragment } from "lib/graphql/operations"

export default function MessageMenu(props: {
  message: MessageFragment
  children: React.ReactNode
}) {
  const [modalContent, setModalContent] = React.useState<JSX.Element | null>(
    null
  )

  const items = useMenuItems({
    closeModal: () => setModalContent(null),
    message: props.message,
  })

  const c = useStyles({})
  if (!items.length) return <>{props.children}</>

  return (
    <>
      <MenuOnClick
        itemsCount={items.length}
        onClickItem={({ index }) => {
          const { onClick, modalContent } = items[index] || {}
          modalContent && setModalContent(<>{modalContent}</>)
          onClick && onClick()
        }}
        renderItem={({ index }) => {
          const { label, icon } = items[index] || {}
          if (!label) return null

          return (
            <>
              <Icon className={c.icon} fontSize="small">
                {icon}
              </Icon>
              <Typography variant="body2">{label}</Typography>
            </>
          )
        }}
      >
        {props.children}
      </MenuOnClick>
      <Dialog
        onClose={() => setModalContent(null)}
        open={!!modalContent}
        // classes={{ paper: c.dialogPaper }}
      >
        {!!modalContent && modalContent}
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      marginRight: theme.spacing(1),
    },
    dialogPaper: {
      minHeight: 300,
      minWidth: 500,
      maxHeight: 700,
      height: "40vh",
    },
  }
})
