import React from "react"
import { PdfAttachmentContext } from "./PdfAttachmentContext"
import { PdfFirstPage } from "../PdfFirstPage/PdfFirstPage"

export function PdfAttachmentRoot() {
  const api = React.useContext(PdfAttachmentContext)

  if (api.attachment.file.mimetype !== "application/pdf") return null

  if (!api.attachment.file.src) return null

  return <PdfFirstPage url={api.attachment.file.src} height={api.height} />
}
