import _ from "lodash"
import React from "react"
import {
  MenuItem,
  MenuItemProps,
  TextField,
  TextFieldProps,
} from "@material-ui/core"
import { FieldInputProps } from "formik"

type Option = { label: string; value: string }

export type SelectInputProps = {
  options: Option[]
  TextField?: React.FC<TextFieldProps>
  MenuItemProps?: Omit<
    React.ComponentProps<typeof MenuItem>,
    "value" | "children"
  >
} & Omit<TextFieldProps, keyof FieldInputProps<string>> &
  FieldInputProps<string>

export default function SelectInput(props: SelectInputProps) {
  const p: TextFieldProps = {
    select: true,
    ..._.omit(props, "options"),
    helperText: props.helperText || props.error,

    children: (props.options || [])
      .filter((a): a is Option => !!a && !!a.value && !!a.label)
      .map(({ value, label }) => {
        return (
          <MenuItem key={value} value={value} {...(props.MenuItemProps || {})}>
            {label}
          </MenuItem>
        )
      }),
  }

  if (props.TextField) return <props.TextField {...p}></props.TextField>

  return <TextField {...p}></TextField>
}
