import React from "react"
import { Typography, makeStyles } from "@material-ui/core"
import { clipText } from "styles/globalStyles"
import Thumbnail from "components/Thumbnail/Thumbnail"

export default function PreviewTemplate(props: {
  thumbSrc?: string | null
  description?: React.ReactNode | null
  title?: React.ReactNode | null
  leftRibbonColor?: string
}) {
  const { thumbSrc, description, title } = props

  const c = useStyles({ leftRibbonColor: props.leftRibbonColor })

  return (
    <div className={c.previewTemplate}>
      {thumbSrc && <Thumbnail className={c.thumb} src={thumbSrc} />}
      <div>
        {title && (
          <Typography variant="body2" color="textSecondary">
            {title}
          </Typography>
        )}
        {description && (
          <div className={c.content}>
            <Typography variant="body2">{description}</Typography>
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    thumb: {
      marginRight: theme.spacing(1),
    },
    previewTemplate: {
      paddingLeft: theme.spacing(1),
      cursor: "pointer",
      borderLeft: (props: { leftRibbonColor: string }) =>
        "2px solid ".concat(
          props.leftRibbonColor || theme.palette.primary.main
        ),
      display: "flex",
      alignItems: "center",
      "&>*": {
        maxWidth: "100%",
      },
    },
    content: {
      "&>*": {
        ...clipText,
      },
    },
  }
})
