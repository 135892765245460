import { IntervalPeriodModal } from "./components/IntervalPeriodModal"
import { Months, MonthsAPIProps } from "./components/Months"
import {
  DateIntervalSelectorProps,
  DateIntervalApiContext,
  DateIntervalApiProvider,
} from "./DateIntervalSelectorContext"
import React from "react"

export const DateIntervalSelector = {
  Context: DateIntervalApiContext,
  Provider: function DateIntervalProvider(
    props: DateIntervalSelectorProps & {
      children: React.ReactNode
    }
  ) {
    return (
      <Months.Provider {...props.MonthsProps}>
        <Months.Context.Consumer>
          {(api) => (
            <DateIntervalApiProvider {...props} monthsApi={api}>
              {props.children}
            </DateIntervalApiProvider>
          )}
        </Months.Context.Consumer>
      </Months.Provider>
    )
  },
  Modal: IntervalPeriodModal,
}
