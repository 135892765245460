import _ from "lodash"
import React from "react"
import useMemoAPI from "hooks/useMemoAPI"

export default function useList<Item extends { id: string }>(props?: {
  list: Item[]
}) {
  const [list, setList] = React.useState<Item[]>(props?.list || [])

  const push = React.useCallback((item: Item) => {
    setList((list) => _.uniqBy([...list, item], "id"))
  }, [])

  const remove = React.useCallback((id: Item["id"]) => {
    setList((list) => list.filter((a) => a.id !== id))
  }, [])

  const update = React.useCallback(
    (id: Item["id"], update: Item | ((item: Item) => Item)) => {
      setList((oldList) => {
        const index = oldList.findIndex((a) => a.id === id)

        if (index !== -1) {
          const list = [...oldList]

          const oldItem = oldList[index]

          list[index] = typeof update === "function" ? update(oldItem) : update

          return list
        }

        return oldList
      })
    },
    []
  )

  return useMemoAPI({ items: list, push, remove, update })
}
