import _ from "lodash"

export function isSingleParagraphWithNoStyle(richText) {
  let blocks
  let singleParagraphWithNoStyle = false

  try {
    blocks = JSON.parse(richText).blocks
  } catch (e) {
    console.error(e)
    return false
  }

  if (blocks.length === 1) {
    const inlineStyleRanges = _.get(blocks, "0.inlineStyleRanges")
    if (_.isEmpty(inlineStyleRanges)) singleParagraphWithNoStyle = true
  }

  return singleParagraphWithNoStyle
}
