import { DataProxy } from "apollo-cache/lib/types/DataProxy"
import {
  CalendarEntriesDocument,
  CalendarEntriesQuery,
  CalendarEntriesQueryVariables,
  DecoupledKanbanBoardQuery,
  DecoupledKanbanBoardQueryVariables,
  KanbanCardImagesDocument,
  KanbanCardImagesQuery,
  KanbanCardImagesQueryVariables,
  PageBoardItemsDocument,
  PageBoardItemsNotificationsQuery,
  PageBoardItemsNotificationsQueryVariables,
  PageBoardItemsQuery,
  PageBoardItemsQueryVariables,
  RoomDocument,
  RoomKanbanBoardDocument,
  RoomKanbanBoardQuery,
  RoomKanbanBoardQueryVariables,
  RoomKanbanColumnsDocument,
  RoomKanbanColumnsQuery,
  RoomKanbanColumnsQueryVariables,
  RoomMembersDocument,
  RoomMembersQuery,
  RoomMembersQueryVariables,
  RoomMessageListDocument,
  RoomMessageListQueryVariables,
  RoomQuery,
  RoomQueryVariables,
  RoomsDocument,
  RoomsQuery,
  RoomsQueryVariables,
} from "lib/graphql/operations"
import { Query } from "lib/graphql/types"
import {
  DecoupledKanbanBoardDocument,
  PageBoardItemsNotificationsDocument,
  RoomMessageListQuery,
} from "../graphql/operations"

const queryMap = {
  pageBoardItems: {
    query: PageBoardItemsDocument,
    variables: {} as PageBoardItemsQueryVariables,
    updateFn: (a: PageBoardItemsQuery) => a,
  },
  room: {
    query: RoomDocument,
    variables: {} as RoomQueryVariables,
    updateFn: (a: RoomQuery) => a,
  },
  rooms: {
    query: RoomsDocument,
    variables: {} as RoomsQueryVariables,
    updateFn: (a: RoomsQuery) => a,
  },
  calendarEntries: {
    query: CalendarEntriesDocument,
    variables: {} as CalendarEntriesQueryVariables,
    updateFn: (a: CalendarEntriesQuery) => a,
  },
  roomMembers: {
    query: RoomMembersDocument,
    variables: {} as RoomMembersQueryVariables,
    updateFn: (a: RoomMembersQuery) => a,
  },
  roomMessageList: {
    query: RoomMessageListDocument,
    variables: {} as RoomMessageListQueryVariables,
    updateFn: (a: RoomMessageListQuery | null) => ({} as RoomMessageListQuery),
  },
  roomKanbanBoard: {
    query: RoomKanbanBoardDocument,
    variables: {} as RoomKanbanBoardQueryVariables,
    updateFn: (a: RoomKanbanBoardQuery) => ({} as RoomKanbanBoardQuery),
  },
  pageBoardItemsNotifications: {
    query: PageBoardItemsNotificationsDocument,
    variables: {} as PageBoardItemsNotificationsQueryVariables,
    updateFn: (a: PageBoardItemsNotificationsQuery) =>
      ({} as PageBoardItemsNotificationsQuery),
  },
  kanbanCardImages: {
    query: KanbanCardImagesDocument,
    variables: {} as KanbanCardImagesQueryVariables,
    updateFn: (a: KanbanCardImagesQuery) => ({} as KanbanCardImagesQuery),
  },

  roomKanbanColumns: {
    query: RoomKanbanColumnsDocument,
    variables: {} as RoomKanbanColumnsQueryVariables,
    updateFn: (a: RoomKanbanColumnsQuery) => a,
  },
  decoupledKanbanBoard: {
    query: DecoupledKanbanBoardDocument,
    variables: {} as DecoupledKanbanBoardQueryVariables,
    updateFn: (a: DecoupledKanbanBoardQuery) => a,
  },
}

type QueryMap = typeof queryMap

export default function updateQuery<
  QueryName extends Extract<keyof QueryMap, keyof Query>,
  Variables extends QueryMap[QueryName]["variables"]
>(
  props: {
    queryName: QueryName
    variables: Variables
    updateFn: QueryMap[QueryName]["updateFn"]
  },
  dataProxy: DataProxy
) {
  const queryOptions: DataProxy.Query<Variables> = {
    query: queryMap[props.queryName].query,
    variables: props.variables,
  }

  type QueryType = ReturnType<typeof props.updateFn>

  let data: QueryType | null = null

  try {
    data = dataProxy.readQuery<QueryType, Variables>(queryOptions)

    if (!data) {
      console.error(
        `No cached value for the query ${
          props.queryName
        } with variables ${JSON.stringify(props.variables)}.`
      )
    }
  } catch (e) {
    console.error(e)
  }

  const updatedData = props.updateFn(data) as QueryType

  dataProxy.writeQuery<QueryType, Variables>({
    ...queryOptions,
    data: updatedData,
  })
}

export function readQuery<
  QueryName extends Extract<keyof QueryMap, keyof Query>,
  Variables extends QueryMap[QueryName]["variables"],
  QueryType extends ReturnType<QueryMap[QueryName]["updateFn"]> = ReturnType<
    QueryMap[QueryName]["updateFn"]
  >
>(
  props: {
    queryName: QueryName
    variables: Variables
  },
  dataProxy: DataProxy
) {
  const queryOptions: DataProxy.Query<Variables> = {
    query: queryMap[props.queryName].query,
    variables: props.variables,
  }

  let data: QueryType | null = null

  try {
    data = dataProxy.readQuery<QueryType, Variables>(queryOptions)
    return data
  } catch (e) {
    console.error(e)
  }

  return data
}
