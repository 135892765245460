import React from "react"
import useMemoAPI from "hooks/useMemoAPI"

export default function useFileUI(props: {
  onFiles: (files: File[]) => any
  multiple?: boolean
}) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const open = React.useCallback(() => {
    inputRef.current?.click()
  }, [inputRef])

  const onChange = React.useCallback(
    (() => {
      type OnChange = React.ChangeEventHandler<HTMLInputElement>

      const fn: OnChange = function onChange(e) {
        if (!e.target.files) return
        props.onFiles(Array.from(e.target.files))
      }

      return fn
    })(),
    [props.onFiles]
  )

  return useMemoAPI({
    inputRef,
    open: open,
    accept: "image/*",
    onChange,
    multiple: !!props.multiple,
  })
}
