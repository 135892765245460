import { KanbanLogMessagePreview } from "components/Room/components/MessagePreview/KanbanMessagePreview"
import { TextMessagePreview } from "components/Room/components/MessagePreview/TextMessagePreview"
import { ZoomSessionPreview } from "components/Room/components/MessagePreview/ZoomSessionRegistrationMessage"
import { MessageFragment, RepliedMessageFragment } from "lib/graphql/operations"
import FooterContext from "../components/Footer/contexts/FooterContext"
import { modes } from "../components/Footer/hooks/useFooterApi/constants"
import AttachmentPreview from "../components/Footer/components/AttachmentPreview/AttachmentPreview"

export const getPreview = (props: {
  message: MessageFragment | RepliedMessageFragment
}): Partial<Record<MessageFragment["__typename"], React.ReactNode>> => {
  return {
    ZoomSessionRegistrationMessage: (
      <ZoomSessionPreview message={props.message}></ZoomSessionPreview>
    ),

    KanbanCardLogMessage: (
      <KanbanLogMessagePreview
        message={props.message}
      ></KanbanLogMessagePreview>
    ),
    TextMessage: (
      <FooterContext.Consumer>
        {(api) => {
          const { mode } = api

          const message = props.message

          if (message?.__typename !== "TextMessage") return null

          const showAttachment =
            mode === modes.EDIT_MESSAGE && !!message?.attachments?.length

          if (showAttachment) return <AttachmentPreview message={message} />

          return <TextMessagePreview message={message} />
        }}
      </FooterContext.Consumer>
    ),
    UserInclusionMessage: null,
  }
}
