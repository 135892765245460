import UseEffect from "components/UseEffect/UseEffect"
import { MakePropsOptionalAndNullable } from "types/type"
import uniqid from "uniqid"
import { isPageBoardItemParamsInput } from "../../lib/isPageBoardItemParamsInput"
import { HistoryThreadApi, HistoryThreadContext } from "./HistoryThreadContext"
import { PageBoardItemParamsInput } from "lib/graphql/types"

export function HistoryThreadTracker(props: {
  params: MakePropsOptionalAndNullable<PageBoardItemParamsInput>
  onCloseItem: () => any
}) {
  return (
    <HistoryThreadContext.Consumer>
      {(api) => {
        return (
          <HistoryTracker
            api={api}
            params={props.params}
            onCloseItem={props.onCloseItem}
          ></HistoryTracker>
        )
      }}
    </HistoryThreadContext.Consumer>
  )
}

export function HistoryTracker(props: {
  params: MakePropsOptionalAndNullable<PageBoardItemParamsInput>
  api: HistoryThreadApi
  onCloseItem: () => any
}) {
  const params = props.params
  const api = props.api

  return (
    <UseEffect
      key={(props.params?.itemID || "") + (props.params?.type || "")}
      effect={() => {
        if (!isPageBoardItemParamsInput(params)) return

        const id = uniqid()

        api.push({ ...params, id, onClose: props.onCloseItem })

        return () => {
          api.remove(id)
        }
      }}
      deps={[]}
    ></UseEffect>
  )
}
