import {
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  makeStyles,
} from "@material-ui/core"
import clsx from "clsx"
import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import TextField from "components/TextField/TextField"
import { I18nAPI } from "contexts/i18nContext/i18nContext"
import { FieldInputProps } from "formik"
import useTypographyClass from "hooks/useTypographyClass"
import PartnerTypeSelectInput from "../components/PartnerTypeSelectInput/PartnerTypeSelectInput"
import { PartnerFormContext } from "../components/ProviderAndContext/ProviderAndContext"

export default function getDetailsFieldProps(props: { translations: I18nAPI }) {
  const { translations } = props

  const fieldProps: FieldsetIndentationProps = {
    icon: "description",
    children: (
      <PartnerFormContext.Consumer>
        {(api) => {
          return (
            <>
              <PartnerTypeSelectInput
                {...api.form.getFieldProps("type")}
              ></PartnerTypeSelectInput>
              <TextField
                {...api.form.getFieldProps("no")}
                label={translations.partnerNumber}
              ></TextField>
              <TextField
                {...api.form.getFieldProps("taxID")}
                label={translations.taxID}
              ></TextField>
              {(() => {
                const fieldProps = api.form.getFieldProps("url")

                return (
                  <TextField
                    {...fieldProps}
                    label="Web"
                    InputProps={{
                      endAdornment: (() => {
                        if (!fieldProps.value) return null

                        return (
                          <IconButton
                            size="small"
                            onClick={() => {
                              const url = fieldProps.value

                              window.open(
                                "//" + url.replace(/https?\:\/\//, ""),
                                "__blank"
                              )
                            }}
                          >
                            <Icon style={{ fontSize: 19 }}>launch</Icon>
                          </IconButton>
                        )
                      })(),
                    }}
                  ></TextField>
                )
              })()}
              <OcrCheckbox
                {...api.form.getFieldProps("ocr")}
                label="OCR"
              ></OcrCheckbox>
            </>
          )
        }}
      </PartnerFormContext.Consumer>
    ),
  }

  return fieldProps
}

function OcrCheckbox(props: { label: string } & FieldInputProps<boolean>) {
  const tClass = useTypographyClass()

  const c = useOcrCheckBoxStyles()

  return (
    <FormControlLabel
      label={props.label}
      control={
        <Checkbox
          className={c.checkbox}
          checked={props.value}
          onChange={(e) =>
            props.onChange({
              target: { name: props.name, value: e.target.checked },
            })
          }
        ></Checkbox>
      }
      classes={{ label: clsx(tClass.body2, c.label) }}
    ></FormControlLabel>
  )
}

const useOcrCheckBoxStyles = makeStyles((theme) => ({
  label: {
    opacity: 0.7,
    userSelect: "none",
  },
  checkbox: {
    paddingRight: 8,
    "& .MuiSvgIcon-root": {
      fontSize: 24,
    },
  },
}))
