import _ from "lodash"
import React, { useCallback } from "react"
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText
} from "@material-ui/core"

import Checkbox from "@material-ui/core/Checkbox"
import useInputOnChange from "../../util/useInputOnChange"

function Checkboxes({
  error,
  value,
  onChange: propsOnChange,
  disabled,
  label,
  checkboxes,
  onBlur,
  name: propsName
}) {
  const onChange = useInputOnChange({ onChange: propsOnChange })

  return (
    <FormControl error={error}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      {!_.isEmpty(checkboxes) && (
        <FormGroup>
          {checkboxes.map(({ label, name: inputName }) => {
            const name = `${propsName}.${inputName}`

            return (
              <FormControlLabel
                key={name}
                label={label}
                disabled={disabled}
                control={
                  <Checkbox
                    checked={!!_.get(value, inputName)}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                }
              />
            )
          })}
        </FormGroup>
      )}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default Checkboxes
