import React from "react"
import { CollectionItem } from "types/type"
import { PartnersModalIDs, PartnersParams, PartnersTabsIDs } from "../types"
import CollectionDocumentsDashboard from "components/CollectionDocumentsDashboard/index"

interface PartnersInternalProps {
  tabID: null | PartnersTabsIDs
  modalID: null | PartnersModalIDs
  collection: CollectionItem | null | undefined
  CollectionDashboard: typeof CollectionDocumentsDashboard
  collectionTypeColor?: string
  loadingData?: boolean
  setCollectionID(collectionID: string | null): any
  setDocumentID(documentID: string | null): any
  setSearchedTerm(searchedTerm: string | null): any
  setTypes(types: Array<string> | null): any
  setPartnerID(partnerID: string | null): any
  goToDocumentsTab(): any
  setContactID(contactID: string | null): any
  setTabID(tabID: PartnersTabsIDs | null): any
  openNewContactModal(): any
  openNewPartnerModal(): any
  closeModal(): any
  closeDetails(): any
}

export interface IPartnersContext
  extends PartnersInternalProps,
    PartnersParams {}

const PartnersContext = React.createContext<IPartnersContext>({
  modalID: null,
  CollectionDashboard: CollectionDocumentsDashboard,
  openNewContactModal() {},
  openNewPartnerModal() {},
  closeModal() {},
  tabID: null,
  documentID: null,
  types: null,
  searchedTerm: null,
  goToDocumentsTab: () => {},
  collectionID: null,
  collection: undefined,
  setContactID() {},
  setCollectionID() {},
  setDocumentID() {},
  setTabID() {},
  setPartnerID() {},
  setSearchedTerm() {},
  setTypes() {},
  closeDetails() {},
})

PartnersContext.displayName = "PartnersContext"

export default PartnersContext
