import moment from "moment-timezone"
import { WebSocketLink } from "@apollo/client/link/ws"
import { ClientOptions, SubscriptionClient } from "subscriptions-transport-ws"
import { LinkProps } from "./link"
import graphqlURI from "./graphqlURI"
import sessionID from "./sessionID"

export type WsLinkProps = {
  onReconnected?: () => any
  onConnected?: () => any
  onDisconnected?: () => any
  apolloURL?: LinkProps["apolloURL"]
  username?: string
}

export default function wsLink(props: WsLinkProps) {
  const url = graphqlURI({
    url: {
      ...props.apolloURL,
      protocol: window.location.protocol === "https:" ? "wss:" : "ws:",
    },
  })

  const options: ClientOptions = {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      return {
        sessionID,
        timezone: moment.tz.guess(),
      }
    },
  }

  const client = new SubscriptionClient(url, {
    ...options,
  })

  props.onConnected && client.onConnected(props.onConnected)
  props.onReconnected && client.onReconnected(props.onReconnected)
  props.onDisconnected && client.onDisconnected(props.onDisconnected)

  return new WebSocketLink(client)
}
