import useWindowSize from "hooks/useWindowSize"
import React from "react"
import WindowSizeContext from "./contexts/WindowSizeContext"

const WindowSize = {
  Provider: function WindowSizeProvider(props: { children: React.ReactNode }) {
    const size = useWindowSize()

    return (
      <WindowSizeContext.Provider value={size}>
        {props.children}
      </WindowSizeContext.Provider>
    )
  },

  Context: WindowSizeContext,
}

export default WindowSize
