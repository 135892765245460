import React from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"

export type ChainProps = {
  children: React.ReactNode | React.ReactNode[]
  separator: React.ReactNode
  /** if the children should be displayed in a line or in a block (stacked) */
  display?: "line" | "block"
  // maxWidth?: number
}

/**
 * Displays a list of items (children) in a line or in a block (stacked).
 */
export default function Chain(props: ChainProps) {
  let children: React.ReactNode[] = []

  if (!Array.isArray(props.children)) {
    children = [props.children as React.ReactNode].filter((a) => !!a)
  } else {
    children = (props.children as React.ReactNode[]).filter((a) => !!a)
  }

  const display = props.display || "block"

  // const c = useChainStyles({ maxWidth: props.maxWidth })
  const c = useStyles()

  if (display === "line") {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <React.Fragment>
          {children.slice(0, -1).map((a, index, array) => (
            <span key={index} style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: 4 }}>{a}</span>
              {children.length > 1 ? <div>{props.separator || "-"}</div> : null}
            </span>
          ))}

          {(() => {
            const last = children.slice(-1)[0] || null

            if (!last) return null

            return (
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: children.length === 1 ? 0 : 4,
                }}
              >
                {last}
              </span>
            )
          })()}
        </React.Fragment>
      </div>
    )
  }

  return (
    <div
      className={clsx(
        c.block
        // !!props.maxWidth && c.clipText)}>
      )}
    >
      {children.map((a, index) => (
        <React.Fragment key={index}>{a}</React.Fragment>
      ))}
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    clipText: {
      // maxWidth: (props: { maxWidth?: number }) => props.maxWidth || "none",

      "&, &>*": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },

    block: {
      "&>*": {
        // lineHeight: 1,
        display: "block",
      },
    },
  }
})
