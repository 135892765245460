import { ChipProps, makeStyles } from "@material-ui/core"
import { Icon, IconProps } from "components/Icon/Icon"
import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { KanbanCommentFragment } from "lib/graphql/operations"
import React from "react"
import KanbanCardPageBoardItemContext from "../../../../../contexts/KanbanCardPageBoardItemContext"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"

function useActionsChips(props: { comment: KanbanCommentFragment }) {
  const reactionApi = useReactionApi({ comment: props.comment })
  const editionApi = useEditionApi({ comment: props.comment })

  const w = useCurrentWorkspace()

  const allowCommentManipulation = props.comment?.author?.id === w?.user?.id

  const getBasicChipProps = useGetBasicChipProps()

  return useMemoAPI({
    ...props,
    reactionApi,
    editionApi,
    allowCommentManipulation,
    getBasicChipProps,
  })
}
const F = contextAndProviderFactory({
  hookApi: useActionsChips,
})

export const ActionsChipsProvider = F.Provider
export const ActionsChipsContext = F.Context

function useGetBasicChipProps() {
  const c = useStyles()

  const getBasicChipProps = React.useCallback(
    function getBasicChipProps(props: {
      iconName: IconProps["name"]
      labelRef?: React.MutableRefObject<HTMLElement | null>
    }) {
      // label: <span style={{display: 'flex'}} ref={ref}>{props.label}</span>,

      const ok: Partial<ChipProps> = {
        clickable: true,
        classes: { label: c.iconLabel },
        className: c.chipRoot,
        size: "small",

        label: (() => {
          const icon = (
            <Icon className={c.chipIcon} name={props.iconName}></Icon>
          )
          if (!props.labelRef) return icon

          return (
            <span style={{ display: "flex" }} ref={props.labelRef}>
              {icon}
            </span>
          )
        })(),
        variant: "outlined",
      }

      return ok
    },
    []
  )

  return getBasicChipProps
}

function useEditionApi(props: { comment: KanbanCommentFragment }) {
  const cardApi = React.useContext(KanbanCardPageBoardItemContext)

  return useMemoAPI({
    openEdition: React.useCallback(
      function openEdition() {
        cardApi.comments.edition.ui.open({
          comment: props.comment,
        })
      },
      [cardApi.comments.edition.ui.open, props.comment]
    ),
  })
}
function useReactionApi(props: { comment: KanbanCommentFragment }) {
  const cardApi = React.useContext(KanbanCardPageBoardItemContext)

  const reactionApi = cardApi.comments.reaction

  type PopOverFunction = (p: {
    popoverAnchorRef: React.MutableRefObject<HTMLElement | null>
  }) => void

  return useMemoAPI({
    openUserReactions: React.useCallback<PopOverFunction>(
      (p) => {
        cardApi.comments.reaction.modal.userReactions.open({
          comment: props.comment,
          popoverAnchorRef: p.popoverAnchorRef,
        })
      },
      [cardApi.comments.reaction.modal.userReactions.open, props.comment]
    ),

    openEmojiPicker: React.useCallback<PopOverFunction>(
      (p) => {
        cardApi.comments.reaction.modal.emojiPicker.open({
          comment: props.comment,
          popoverAnchorRef: p.popoverAnchorRef,
        })
      },
      [props.comment, cardApi.comments.reaction.modal]
    ),
    toggle: React.useCallback(
      function toggleEmoji(args: { emojiUnicode: string }) {
        return reactionApi.toggle({
          comment: props.comment,
          emojiUnicode: args.emojiUnicode,
        })
      },
      [props.comment, reactionApi]
    ),
  })
}

const useStyles = makeStyles((theme) => {
  return {
    chipIcon: { fontSize: "16px !important" },
    chipRoot: {
      height: 19,

      marginRight: 4,
      // marginTop: 6,

      padding: "11px 2px",
    },
    iconLabel: {
      color: theme.palette.text.secondary,
      userSelect: "none",
      display: "flex",
      padding: theme.spacing(0, 0.5),
    },
  }
})
