import getRoomLink, { getPrivateRoomLink } from "./util/getRoomLink"

const ChatPageAPI = {
  getLink: {
    room: getRoomLink,
    privateRoom: getPrivateRoomLink,
    home() {
      return "/"
    },
  },
}

export default ChatPageAPI
