import _ from "lodash"
import matches from "util/matches"
import stringOrNumber from "util/stringOrNumber"

//TODO: include documentation
export default function filterSearched(searchedTerm, { fields = {} } = {}) {
  return function filter(entry) {
    if (!searchedTerm) return !!entry

    const stringifiedEntry = Object.entries(entry)
      .filter(([field, __]) => {
        const { unsearchable } = fields[field] || {}
        return !unsearchable
      })

      .map(([field, value]) => {
        const { getValueOnSearch } = fields[field] || {}
        const searchValue =
          typeof getValueOnSearch === "function"
            ? getValueOnSearch({ value, entry })
            : value

        return searchValue
      })
      .filter(stringOrNumber)
      .join(" ")

    return matches({ searchedTerm, value: stringifiedEntry })
  }
}
