/**
 *
 * Given the dom element used as anchor, look for the best position
 * for floating elements, like tooltips, modals, popovers....
 */

export default function bestQuadrantForFloatingElement(props: {
  nodeAnchor: HTMLElement
}) {
  const rect = props.nodeAnchor.getBoundingClientRect()
  const { left = 0, top = 0 } = rect

  const halfWidth = window.outerWidth / 2
  const halfHeight = window.outerHeight / 2

  if (top <= halfHeight) {
    if (left >= halfWidth) return "one"
    return "two"
  } else {
    if (left < halfWidth) return "three"
    return "four"
  }
}
