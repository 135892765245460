import { Item } from "./Item"
import { ListConsumer, ListContext, ListProvider } from "./ListContext"
import { ListRoot } from "./ListRoot"
import { ListApiInput } from "./useListApi"

export type { ListApiInput } from "./useListApi"

export type ListProps = ListApiInput

export const List = {
  Context: ListContext,
  Provider: ListProvider,
  Consumer: ListConsumer,
  Root: ListRoot,
  Item,
}
