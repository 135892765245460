import React from "react"
import { FlexColumn } from "components/Styled"
import clsx from "clsx"
import { makeStyles } from "@material-ui/styles"
import useGlobalStyles, { alignmentUnits } from "styles/globalStyles"
import FlexOneWithScrollbars from "components/FlexOneWithScrollbars/FlexOneWithScrollbars"

export default function ScrollableContentWithFixedTopAndBottom(props: {
  bottom?: React.ReactNode
  content?: React.ReactNode
  className?: string
  top?: React.ReactNode
}) {
  const { bottom = null, content, className = "", top = null } = props

  const c = useStyles({})
  const gs = useGlobalStyles()

  return (
    <FlexColumn
      className={clsx(
        "scrollable-content-with-fixed-top-and-bottom",
        gs.height100Percent,
        className
      )}
    >
      {top}
      <FlexOneWithScrollbars>
        <div className={c.scrollbarsContent}>{content}</div>
      </FlexOneWithScrollbars>
      {bottom}
    </FlexColumn>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    scrollbarsContent: {
      height: "100%",
      overflow: "auto",
    },
  }
})
