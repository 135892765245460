import { useMutation, gql } from "@apollo/client";
import _ from "lodash"
import useActiveResources from "../useActiveResources"
import moment from "moment"
import { useMemo } from "react"
import { ToggleKontaktDeletionDocument } from "lib/graphql/operations"
import useFragment from "hooks/apolloHooks/useFragment"

export default function useKontaktToggleDelete({ id, kontaktType }) {
  const { workspaceID } = useActiveResources()
  const [mutate, { loading }] = useMutation(ToggleKontaktDeletionDocument, {
    variables: { workspaceID, id, kontaktType },
  })

  const isDeleted = useIsDeleted({ kontaktType, id })

  return useMemo(() => {
    return {
      isDeleted,
      isLoading: loading,
      toggleDelete: mutate,
    }
  }, [isDeleted, id, loading])
}

function useIsDeleted({ kontaktType, id }) {
  const types = {
    PARTNER: "Partner",
    DETAILED_CONTACT: "DetailedContact",
  }

  const Type = types[kontaktType]

  const fragment = gql`
    fragment Deletable on ${Type}{
      deleted
    }
  `

  const obj = useFragment({ fragment, id: `${Type}:${id}` })

  if (!obj) return true

  const deletedDate = _.get(obj, "deleted") || null
  const isDeleted = deletedDate && moment(deletedDate).isValid()

  return isDeleted
}
