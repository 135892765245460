import { MessageFragment } from "lib/graphql/operations"
import { Preview } from "./Preview"

export function BasicMessagePreview(props: {
  message: MessageFragment
  text: React.ReactNode
  src?: string
}) {
  if (!props.message || !props.text) return null

  const previewBasicProps = {
    description: props.text,
    title: props.message?.author?.name || "",
    src: props.src || "",
  }

  return <Preview {...previewBasicProps} />
}
