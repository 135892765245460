import React from "react"
import MetaCardWithUserAvatar from "components/MetaCardWithUserAvatar/MetaCardWithUserAvatar"
import getter from "util/getter"
import CollectionItemAvatar from "components/CollectionItemAvatar/CollectionItemAvatar"
import usePartner from "hooks/usePartner"

function PartnerMetaCard({
  partnerID,
  link = "",
  onClick = undefined,
  fetchPolicy = "cache-and-network",
}) {
  const { partner } = usePartner({ id: partnerID, fetchPolicy })

  if (!partner) return null

  const g = getter(partner)
  const userName = [g("name1"), g("name2")].join(" ")

  return (
    <MetaCardWithUserAvatar
      userName={userName}
      onClick={onClick}
      // link={link}
      overline="Partner"
      title={userName}
      avatar={
        g("type") ? (
          <CollectionItemAvatar type={g("type")}></CollectionItemAvatar>
        ) : null
      }
    ></MetaCardWithUserAvatar>
  )
}

export default PartnerMetaCard
