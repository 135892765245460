import { ListProps } from "components/List/List"
import { ListVariants } from "components/List/useListApi"
import useVariousImagesWithAuth from "hooks/useImageWithAuth/useVariousImagesWithAuth"
import useMemoAPI from "hooks/useMemoAPI"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { excludeNullable } from "lib/excludeNullable"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import React from "react"
import { Subject } from "rxjs"
// import { IndexedItemsContext } from "../../IndexedItems/IndexedItems"
import { NewThumbnailImage } from "../components/ListItemEntry/components/ItemGridCell/ItemGridCellContext"
import { useItemsListTwoPagesApi } from "../hooks/useItemsListTwoPagesApi"
import useTemplate from "../hooks/useTemplate"
import { IndexedItemsContext } from "../../../IndexedItems"

export type ItemNewThumbnailImage = NewThumbnailImage & {
  item: NewPageBoardItemFragment
}

const Factory = contextAndProviderFactory({
  hookApi: function useItemsList(props: {
    variant: ListVariants
    openItemPage?: (a: NewPageBoardItemFragment | null) => void
    ListProps: {
      fetchMore: ListProps["fetchMore"]
      totalCount: ListProps["totalCount"]
      items: NewPageBoardItemFragment[]
    }
  }) {
    const rootContainer = useRootContainerAPI()

    const indexedItemsApi = React.useContext(IndexedItemsContext)

    const newThumbnailImage$ = React.useRef(
      new Subject<ItemNewThumbnailImage>()
    ).current

    const twoPagesApi = useItemsListTwoPagesApi()

    // const imagesApi = useImagesApi({
    //   entries: indexedItemsApi.pageBoardItems || [],
    // })

    const template = useTemplate({ rootContainer })

    const openItemPage = React.useCallback(
      (a: NewPageBoardItemFragment | null) => {
        const fn = props.openItemPage
          ? props.openItemPage
          : indexedItemsApi.selected.set.bind(indexedItemsApi.selected)

        return fn(a)
      },
      [indexedItemsApi.openItemPage, props.openItemPage]
    )

    return useMemoAPI({
      ...props,
      // imagesApi,
      template,
      rootContainer,
      indexedItemsApi,
      openItemPage,
      itemsListTwoPagesApi: twoPagesApi,
      newThumbnailImage$,
    })
  },
})

export const ItemsListProvider = Factory.Provider
export const ItemsListContext = Factory.Context

// function useImagesApi(props: { entries: NewPageBoardItemFragment[] }) {
//   const getEntryImageSrc = React.useCallback(
//     (item: NewPageBoardItemFragment) => {
//       return item.thumbnail?.src || item.thumbnail?.url || null
//     },
//     []
//   )

//   const authApi = useVariousImagesWithAuth({
//     sources: (() => {
//       const urls = props.entries
//         ?.map(getEntryImageSrc)
//         .filter(excludeNullable) as string[]
//       return urls
//     })(),
//   })

//   return useMemoAPI({
//     ...authApi,
//     getEntryImageSrc: React.useCallback(
//       (entry: NewPageBoardItemFragment) => {
//         const src = getEntryImageSrc(entry)
//         const url = authApi.getImageObjectURL(src)
//         return url
//       },
//       [authApi.getImageObjectURL, getEntryImageSrc]
//     ),
//   })
// }
