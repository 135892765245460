import React from "react"
import _ from "lodash"

import { useDrawerContext } from "contexts/drawerContext/drawerContext"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import Workspaces from "components/Workspaces/Workspaces"
import { NotificationsAPI } from "components/NotificationsAPI/NotificationsAPI"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"

export default function NotificationBadge(props: {
  children: React.ReactNode
}) {
  // const { workspaces } = useWorkspaces({ fetchPolicy: "cache-only" })
  // const { workspaces } = React.useContext(Workspaces.Context)

  const { workspace } = useCurrentWorkspace()

  const notification = React.useContext(
    NotificationsAPI.Context
  ).sumCountOtherThanCurrentWorkspace

  const hasNotification = !!notification

  const { isDrawerOpen } = useDrawerContext()

  return (
    <PurpleBadge variant="dot" invisible={isDrawerOpen || !hasNotification}>
      {props.children}
    </PurpleBadge>
  )
}
