import React from "react"
import clsx from "clsx"
import _ from "lodash"
import { Paper, PaperProps, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { clipText } from "styles/globalStyles"

export default function MetaCardPaper({
  className = "",
  children,
  ...other
}: PaperProps) {
  const c = useStyles({})

  return (
    <Paper className={clsx("meta-card-paper", c.padding, className)} {...other}>
      {children}
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    metaCard: {},
    padding: {
      padding: theme.spacing(1, 2),
    },
  }
})
