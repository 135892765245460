import React from "react"
import PaperList from "components/PaperList/index"
import UserAvatar from "components/UserAvatar/UserAvatar"
import lastSeen from "util/lastSeen"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import { ContactMember, RoomMember, User } from "lib/graphql/types"
import { green } from "constants/colors"
import arePropsEqual from "util/arePropsEqual"
import Avatar from "components/Avatar/Avatar"
import { RoomMemberFragment } from "lib/graphql/operations"
import getRoomMemberColor from "components/PresenceList/lib/getRoomMemberColor"

export default React.memo(
  ListItemLeft,
  arePropsEqual([(a) => (a ? a?.member?.id + a?.member?.__typename : true)])
)

function ListItemLeft(props: {
  member?: RoomMemberFragment
  color?: string
  primaryLine?: React.ReactNode
}) {
  const { member } = props
  const c = useStyles({})

  const color = props.color || getRoomMemberColor(member)

  if (!member) return null

  return (
    <div className={clsx("list-item-left", c.root)}>
      {(() => {
        const isUser = member.__typename === "User"

        if (isUser) {
          return (
            <UserAvatar
              name={member.name}
              status={member.status || undefined}
              backgroundColor={color}
            ></UserAvatar>
          )
        }

        return <Avatar name={member.name} backgroundColor={color}></Avatar>
      })()}

      <PaperList.ListItemText
        primary={props.primaryLine || member?.name}
        secondary={
          <>
            {(() => {
              const lines = (() => {
                let l: Record<
                  Exclude<RoomMember["__typename"], undefined>,
                  string[]
                > = {
                  Account: [],
                  User: (() => {
                    const user = member as User
                    return [user.company, user.account?.name, user.email, ""]
                      .filter((a): a is string => typeof a === "string")
                      .slice(0, 1)
                  })(),
                  ContactMember: (() => {
                    const contact = member as ContactMember
                    return [
                      contact?.collection?.subtitle,
                      contact.collection?.id,
                    ]
                      .filter((a): a is string => typeof a === "string")
                      .slice(0, 1)
                  })(),
                }

                return member.__typename ? l[member.__typename] : []
              })()

              return lines
                .filter((a): a is string => !!a)
                .map((a, index) => (
                  <React.Fragment key={index}>
                    {/* line break from the second item*/}
                    {index > 0 ? <br /> : null}
                    {a}
                  </React.Fragment>
                ))
            })()}
          </>
        }
      ></PaperList.ListItemText>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      "&>*:first-child": {
        marginRight: theme.spacing(1),
      },
    },
  }
})
