import { MobileHiddenXScrollbarRoot } from "./MobileHiddenXScrollbarRoot"
import {
  MobileHiddenXScrollbarProvider,
  MobileHiddenXScrollbarContext,
} from "./MobileHiddenXScrollbarContext"

export const MobileHiddenXScrollbar = {
  Provider: MobileHiddenXScrollbarProvider,
  Context: MobileHiddenXScrollbarContext,
  Root: MobileHiddenXScrollbarRoot,
}
