import React from "react"
import clsx from "clsx"
import _ from "lodash"

export default function cloneElWithClass(
  className: string,
  reactElement: React.ReactElement<{ className?: string }>
) {
  return React.cloneElement(reactElement, {
    className: clsx(reactElement?.props?.className, className),
  })
}
