import React from "react"
import { Grid } from "@material-ui/core"
import clsx from "clsx"

function TwoColumns({ firstColumn, secondColumn, className }) {
  return (
    <Grid container spacing={3} className={clsx("two-columns", className)}>
      <Grid item xs={secondColumn ? 6 : 12}>
        {firstColumn}
      </Grid>

      {secondColumn && (
        <Grid item xs={6}>
          {secondColumn}
        </Grid>
      )}
    </Grid>
  )
}

export default TwoColumns
