import React, { useImperativeHandle, useRef, ReactChild } from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import FullScreenModal, {
  FullScreeModalAPI,
} from "components/FullScreenModal/FullScreenModal"
import { useCallback } from "react"
import { useInputController } from "hooks/useInputController"
import useMemoAPI from "hooks/useMemoAPI"
import RootContainer from "components/RootContainer/RootContainer"

export default React.forwardRef(SideBySideLayout)

export type SideBySideLayoutAPI = {
  closeRight: () => void
  openRight: () => void
  getWindowOverlayZIndex: () => number
}

function SideBySideLayout(
  props: {
    renderLeft: (api: SideBySideLayoutAPI) => ReactChild
    renderRight: (api: SideBySideLayoutAPI) => ReactChild
    rightIsOpen?: boolean
    onClose?: () => any
    overlayAnchor?: Element | null
    renderRightOnOverlay?: boolean
    className?: string
    ratioRightLeft?: number
  },
  externalRef: React.Ref<SideBySideLayoutAPI>
) {
  const c = useStyles({ ratioRightLeft: props.ratioRightLeft || 2 })

  const { inputValue: rightIsOpen, setInputValue: setRightIsOpen } =
    useInputController({ value: props.rightIsOpen })

  const closeRight = useCallback(() => setRightIsOpen(false), [])
  const openRight = useCallback(() => setRightIsOpen(true), [])

  const windowOverlayRef = useRef<FullScreeModalAPI>(null)

  const getWindowOverlayZIndex = useCallback(
    function getZIndex() {
      if (!windowOverlayRef.current) return 0
      const zIndex = windowOverlayRef.current.getZIndex()
      return zIndex || 2
    },
    [windowOverlayRef]
  )

  const api: SideBySideLayoutAPI = {
    closeRight,
    openRight,
    getWindowOverlayZIndex,
  }
  const memoizedAPI = useMemoAPI(api)

  useImperativeHandle(
    externalRef,
    () => {
      return memoizedAPI
    },
    Object.values(memoizedAPI)
  )

  const right = props.renderRight(memoizedAPI)
  const left = props.renderLeft(memoizedAPI)
  const root = RootContainer.useAPI()

  const renderRightOnOverlay =
    props.renderRightOnOverlay || (!root.isCollapsed && !root.isLarge)

  const leftRef = useRef(null)

  return (
    <div
      className={clsx(c.root, "side-by-side-layout", props.className)}
      ref={root.containerRef}
    >
      <div className="left" ref={leftRef}>
        {left}
      </div>
      {renderRightOnOverlay ? (
        <FullScreenModal
          isOpen={!!rightIsOpen}
          onClose={props.onClose}
          ref={windowOverlayRef}
          anchor={props.overlayAnchor}
        >
          {right}
        </FullScreenModal>
      ) : (
        rightIsOpen && <div className="right">{right}</div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    "&>*": { maxWidth: "100%" },

    "&>.left": {
      flex: 1,
      overflow: "hidden",
    },

    "&>.right": {
      flex: 1.8,
      overflow: "hidden",
    },
  },
}))
