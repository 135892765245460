import React from "react"
import * as pdfjs from "pdfjs-dist"

export default function useRenderContext(props: {
  width?: number
  page: pdfjs.PDFPageProxy | null
  canvasRef: React.RefObject<HTMLCanvasElement>
}) {
  const { width, page, canvasRef } = props

  const renderContext = React.useMemo(() => {
    if (!page) return
    if (!width) return

    const desiredWidth = width
    let viewport = page.getViewport({ scale: 1 })
    const scale = desiredWidth / viewport.width
    const scaledViewport = page.getViewport({ scale: scale })

    viewport = scaledViewport
    const outputScale = window.devicePixelRatio || 1

    const canvas = canvasRef.current

    if (!canvas) return

    const context = canvas.getContext("2d")

    if (!context) return

    canvas.width = Math.floor(viewport.width * outputScale)
    canvas.height = Math.floor(viewport.height * outputScale)
    canvas.style.width = Math.floor(viewport.width) + "px"
    canvas.style.height = Math.floor(viewport.height) + "px"

    const transform =
      outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null

    const renderContext = {
      canvasContext: context,
      transform: transform || undefined,
      viewport: viewport,
    }

    return renderContext
  }, [page, width, canvasRef])

  return renderContext
}
