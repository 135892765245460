import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "../../lib/contextAndProviderFactory"
import { Attachment_FileAttachment_Fragment } from "../../lib/graphql/operations"

const Factory = contextAndProviderFactory({
  hookApi: function usePdfAttachmentApi(props: {
    attachment: Attachment_FileAttachment_Fragment
    height: number
  }) {
    return useMemoAPI({ ...props })
  },
})

export const PdfAttachmentContext = Factory.Context
export const PdfAttachmentProvider = Factory.Provider
