import React from "react"
import useCountries from "hooks/useCountries"
import AutoComplete, {
  AutoCompleteProps,
} from "components/Input/components/AutoComplete/AutoComplete"
import { useI18n } from "contexts/i18nContext/i18nContext"
// import AutoComplete, { AutoCompleteProps } from "../AutoComplete/AutoComplete"

export default function SalutationAutoComplete(
  props: Omit<AutoCompleteProps, "options"> & {
    AutoComplete?: React.FC<AutoCompleteProps>
  }
) {
  const t = useI18n()

  const options = React.useMemo(() => {
    const opts: AutoCompleteProps["options"] = [t.herr, t.frau, t.team].map(
      (a) => ({
        label: a,
        value: a,
      })
    )

    return opts
  }, [t])

  const p: AutoCompleteProps = {
    ...props,
    options,
    value: props.value,
  }

  if (props.AutoComplete)
    return <props.AutoComplete {...p}></props.AutoComplete>

  return <AutoComplete {...p}></AutoComplete>
}
