import { useState, useCallback, useEffect } from "react"
import { ZoomClient } from "../../../index-types"

import { Property } from "csstype"

export function useVideoAspect(zmClient: ZoomClient) {
  const [aspectRatio, setAspectRatio] = useState<
    Record<string, Property.AspectRatio>
  >({})

  const onVideoAspectRatioChange = useCallback((payload: any) => {
    const { userId, aspectRatio } = payload
    setAspectRatio((s) => {
      return { ...s, [`${userId}`]: aspectRatio }
    })
  }, [])
  useEffect(() => {
    zmClient.on("video-aspect-ratio-change", onVideoAspectRatioChange)
    return () => {
      zmClient.off("video-aspect-ratio-change", onVideoAspectRatioChange)
    }
  })
  return aspectRatio
}
