import { ChipProps } from "@material-ui/core"
import { InlinedChips } from "components/InlinedChips/InlinedChips"
import PageBoardContext from "components/PageBoard/contexts/PageBoardContext"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import React from "react"
import { StyledProps } from "types/type"
import { PageBoardItemContext } from "../PageBoardItemContext"

export type RelatedItemsChipsProps<
  T extends NewPageBoardItemFragment = NewPageBoardItemFragment
> = StyledProps & {
  filter?: (item: T) => boolean
  additionalChips?: ChipProps[]
  onClickItem?: (item: T) => any
}

export function RelatedItemsChips<T extends NewPageBoardItemFragment>(
  props: RelatedItemsChipsProps<T>
) {
  const itemApi = React.useContext(PageBoardItemContext)

  const chipsProps = [
    ...(itemApi.related || [])
      .filter((a) => (props.filter ? props.filter(a as T) : true))
      .map((item, index) => {
        const chipProps: ChipProps = {
          key: item.id,
          onClick: () => {
            props?.onClickItem?.(item as T)
          },
          label:
            (item.name || "").length <= 25
              ? item.name
              : item.name?.slice(0, 25) + "...",
        }

        return chipProps
      })

      .filter((a): a is ChipProps => !!a),

    ...(props.additionalChips || []),
  ]

  return <InlinedChips wrap chips={chipsProps}></InlinedChips>
}

// const t = useI18n()
// {
//   key: "add-room",
//   color: "primary",
//   variant: "outlined",
//   label: t["room"],
//   icon: <Icon name="add"></Icon>,
//   onClick: () => {
//     // api.onClickUploadButton?.()
//   },
// } as ChipProps,
