import useEscapeListener from "../../hooks/useEscapeListener"

export default function Escape(props: {
  callback: () => any
  enabled: boolean
  children: React.ReactNode
}) {
  useEscapeListener(props.callback, { enabled: props.enabled })

  return <>{props.children}</>

  // return <h1>Hello</h1>
}
