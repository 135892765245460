import cloneElWithClass from "util/cloneElWithClass"
import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

export default function ActiveSwitchAlignedToRight({ getInput }) {
  const c = useActiveSwitchStyle({})

  return (
    <Box
      className="active"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
    >
      {cloneElWithClass(c.activeSwitch, getInput("active"))}
    </Box>
  )
}

const useActiveSwitchStyle = makeStyles(theme => {
  return {
    activeSwitch: {
      marginRight: -12
    }
  }
})
