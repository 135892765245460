import _ from "lodash"
import useActiveResources from "hooks/useActiveResources"
import { NetworkStatus, WatchQueryFetchPolicy } from "@apollo/client"
import useMemoAPI from "hooks/useMemoAPI"
import { useCollectionsOverviewsQuery } from "lib/graphql/operations"
import { CollectionsOverviewsQueryVariables } from "lib/graphql/operations"

export default function useCollectionsOverviews(props?: {
  fetchPolicy?: WatchQueryFetchPolicy
  pollInterval?: number
  excludeSystemTypes?: boolean
  includeMissingTypes?: boolean
}) {
  const { workspaceID } = useActiveResources()
  const variables: CollectionsOverviewsQueryVariables = {
    workspaceID,
    excludeSystemTypes: !!props?.excludeSystemTypes,
    includeMissingTypes: !!props?.includeMissingTypes,
  }

  const query = useCollectionsOverviewsQuery({
    variables,
    skip: !variables.workspaceID,
    fetchPolicy: props?.fetchPolicy,
    pollInterval: (function pollInterval() {
      const fetchPolicyAllowed = ["cache-only", "cache-first"].every(
        (policy) => props?.fetchPolicy !== policy
      )

      if (fetchPolicyAllowed && variables.workspaceID && props?.pollInterval) {
        return props?.pollInterval
      }

      return 0
    })(),
  })

  return useMemoAPI({
    collectionsOverviews: query?.data?.collectionsOverviews || null,
    isReady: query.networkStatus === NetworkStatus.ready,
    isLoading: query.loading || false,
  })
}
