import React from "react"
import DynamicViews from "components/DynamicViews/DynamicViews"
import CalendarEventContext from "../../contexts/CalendarEventContext"
import EventCreation from "../EventCreation/EventCreation"
import EventVisualization from "../EventVisualization/EventVisualization"
import EventEdition from "../EventEdition/EventEdition"
import EventForm from "../EventForm/EventForm"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"

export default function CalendarEventBody(props: {className?: string, style?: React.CSSProperties}) {
  const api = React.useContext(CalendarEventContext)

  const c = useStyles()

  return (
    <div className={clsx(c.root, "calendar-event-body", props.className)} style={props.style}>
      {(() => {
        const viewID = api.viewID

        if (viewID === "creation") return <EventForm></EventForm>

        return <EventVisualization></EventVisualization>
      })()}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "&>*": {
        height: "100%",
      },
    },
  }
})
