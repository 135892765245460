import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
// import { ArticleDetailPanelContext } from "components/PageBoard/components/PageBoardItem/components/ShopArticlePageBoardItem/components/ArticleDetailPanel/ArticleDetailPanelContext"
import React from "react"
import { StyledProps } from "types/type"
import { ArticleDetailPanelContext } from "../../ArticleDetailPanelContext"
import { Attributes } from "../Attributes/Attributes"
import * as Carousel from "../Carousel/Carousel"
import { Details } from "../Details/Details"
import Price from "../Price/Price"

export default function ArticleMobileLayout(props: StyledProps) {
  const c = useStyles()
  const api = React.useContext(ArticleDetailPanelContext)

  const { article, onClose } = api

  if (!article?.id) return null

  return (
    <div
      className={clsx(c.root, "article-mobile-layout", props.className)}
      style={props.style}
    >
      <Carousel.Provider images={article.images}>
        <Carousel.Root></Carousel.Root>
      </Carousel.Provider>
      <div className={c.content}>
        <Typography variant="overline" gutterBottom>
          {article.sku}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {(article.name || "").replace(/(,|\.|,|-)/g, "$1 ")}
        </Typography>
        <Price article={article} className={c.price}></Price>
        <div className={c.actionsButtons}>
          {api.CartButtons && (
            <api.CartButtons articleID={article.id}></api.CartButtons>
          )}
          {api.ShareButton && (
            <api.ShareButton articleID={article.id}></api.ShareButton>
          )}
        </div>
        <Attributes></Attributes>
        <Details details={article.details} classes={{ tabs: c.tabs }}></Details>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    tabs: {
      maxWidth: "100%",
    },
    root: {
      position: "relative",
    },
    content: {
      padding: theme.spacing(2),
    },
    price: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    actionsButtons: {
      margin: theme.spacing(4, 0),

      "&>*": {
        width: "100%",
      },

      "&>*:not(:last-child)": {
        marginBottom: theme.spacing(1),
      },
    },
    img: {
      display: "block",
      maxHeight: 250,
      marginBottom: theme.spacing(3),
      marginLeft: "auto",
      marginRight: "auto",
    },
  }
})
