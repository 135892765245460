import { CartFragment } from "lib/graphql/operations"
import React from "react"
import { useRemoveFromCartMutation } from "../../lib/graphql/operations"
import { getUpdateCartQueryFn } from "./lib/getUpdateCartQueryFn"

export function useRemoveApi({
  cartID,
  cart,
  workspaceID,
}: {
  cartID: string
  cart: CartFragment | null | undefined
  workspaceID: string
}) {
  const [removeFromCartMutation, { loading: removing }] =
    useRemoveFromCartMutation()

  const removeApi = React.useMemo(() => {
    return {
      async action({ articleID = "" }: { articleID: string }) {
        if (!cartID) return
        if (!cart) return

        const result = await removeFromCartMutation({
          variables: { articleID, workspaceID, cartID },

          update: getUpdateCartQueryFn({
            cartID,
            workspaceID,
            getCart(b) {
              return b.data?.removeFromCart || null
            },
          }),
          optimisticResponse: {
            __typename: "Mutation",
            removeFromCart: {
              ...cart,
              items: cart.items?.filter(
                (item) => item.article.id !== articleID
              ),
            },
          },
        })

        return result
      },
      loading: removing,
    }
  }, [cart, removeFromCartMutation, removing, workspaceID, cartID])
  return removeApi
}
