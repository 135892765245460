import _f from "lodash/fp"
import useIsMounted from "hooks/useIsMounted"
import ActivitiesRight from "../RightSide/RightSide"
import DocumentsDashboardContext from "../../contexts/DocumentsDashboardContext"
import _ from "lodash"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { useRef, useContext } from "react"
import SideBySideLayout, {
  SideBySideLayoutAPI,
} from "components/SideBySideLayout/SideBySideLayout"
import ActivitiesList from "../List/List"
import { useEffect } from "react"

export default function DocumentsDashboardRoot({
  className = "",
  renderLeft: propsRenderLeft = ({
    defaultComponent,
    components: { list = null } = {},
  }) => defaultComponent,
}) {
  //
  const c = useStyles()

  const {
    selectedEntryID: activeID,
    renderRightOnOverlay,
    detailsOverlayAnchor,
    setActiveID,
  } = useContext(DocumentsDashboardContext)

  const sideBySideRef = useRef<SideBySideLayoutAPI>(null)

  useEffect(() => {
    activeID && sideBySideRef.current?.openRight()
  }, [activeID])

  return (
    <SideBySideLayout
      ref={sideBySideRef}
      overlayAnchor={detailsOverlayAnchor}
      renderRightOnOverlay={renderRightOnOverlay}
      onClose={() => setActiveID(null)}
      className={clsx("activities", c.root, className)}
      renderLeft={function renderLeft() {
        const list = <ActivitiesList></ActivitiesList>

        return (
          <div className={c.left}>
            {propsRenderLeft({
              defaultComponent: list,
              components: { list },
            })}
          </div>
        )
      }}
      renderRight={({ closeRight, getWindowOverlayZIndex }) => {
        return (
          <ActivitiesRight
            className={c.right}
            onClose={closeRight}
            getWindowOverlayZIndex={getWindowOverlayZIndex}
            isRenderedOnOverlay={renderRightOnOverlay}
          ></ActivitiesRight>
        )
      }}
    ></SideBySideLayout>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: 0,
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    right: {
      height: "100%",
    },
    left: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    ActivityDetails: {},
  }
})
