import { createHeadlessEditor } from "@lexical/headless"
import { $getRoot, SerializedEditorState, SerializedLexicalNode } from "lexical"
import nodes from "./nodes"
import $setEditorValue from "./$setEditorValue"

export default function extractText(props: {
  namespace: string
  value: string | SerializedEditorState<SerializedLexicalNode>
}) {
  const editor = createHeadlessEditor({
    nodes,
    namespace: props.namespace,
    onError: (e) => {
      console.log({ namespace: props.namespace, error: e })
    },
  })

  editor.update(() => {
    $setEditorValue({ editor, value: props.value || "" })
  })

  return editor.getEditorState().read(() => {
    return $getRoot().getTextContent()
  })
}
