// import Avatar from "components/Avatar/Avatar"
import React from "react"
import { ConferenceApiInput } from "./useConference"

export function useOnLeave(props: ConferenceApiInput) {
  const zoomClient = props.zoomClient

  const onLeave = React.useCallback(() => {
    const finish = async () => {
      const isHost = zoomClient.isHost()
      // await zoomClient.leave(isHost ? true : undefined)
      await zoomClient.leave()
    }

    if (props.manageLeaving) {
      return props.manageLeaving(finish)
    }

    finish()
  }, [zoomClient, props.manageLeaving])

  React.useEffect(() => {
    return () => {
      onLeave()
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener("beforeunload", onLeave)

    return () => {
      window.removeEventListener("beforeunload", onLeave)
    }
  }, [])

  // React.useEffect(() => {}, [onLeave])

  return onLeave
}
