import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/client"
import { SubscribeToMoreOptions } from "@apollo/client"
import _ from "lodash"
import React from "react"

/**
 * 
 * @additionalInfo
 * fetchPolicy: "cache-and-network" is the default
 *
 * @example
 * ```
    useLiveQuery({
      useQuery: useWorkspacesQuery,
      options: { variables: {} },
      arrayOptions: { isUnique: true },
      subscriptionDocument: WorkspacesAlertsCountSubscriptionDocument,
    })
 * ```
 */
export function useLiveQuery<UseQuery>(props: LiveQueryInput<UseQuery>) {
  type QueryData = UseQuery extends CustomQueryHook<infer U, any> ? U : never
  type QueryVariables = UseQuery extends CustomQueryHook<any, infer U>
    ? U
    : never

  const query = props.useQuery({
    ...props.options,
    fetchPolicy: props.options.fetchPolicy || "cache-and-network",
  }) as ApolloReactCommon.QueryResult<QueryData, QueryVariables>

  React.useEffect(() => {
    if (props.disableSubscription) return
    if (!query?.subscribeToMore) return

    // query.
    return query?.subscribeToMore({
      document: props.subscriptionDocument,
      variables: props.options.variables,
      updateQuery(prev, { subscriptionData }) {
        if (!subscriptionData.data) return prev

        if (subscriptionData.data) {
          props.onSubscriptionData?.(subscriptionData.data)
          if (props.refetchQueryOnSubscriptionData)
            setTimeout(() => query.refetch(), 0)
        }

        if (props.updateQuery)
          return props.updateQuery(prev, { subscriptionData }) as typeof prev

        const subscriptionsDataKey = Object.keys(subscriptionData.data).filter(
          (key) => key !== "__typename"
        )

        if (subscriptionsDataKey.length !== 1) {
          console.error(
            "useLiveQuery: subscriptionData.data should have only one key beyond __typename",
            subscriptionData.data
          )
          return prev
        }

        const subscriptionKey = subscriptionsDataKey[0]

        const prevData = prev as { [key: string]: any }
        const subData = subscriptionData.data as { [key: string]: any }

        if (!subData?.[subscriptionKey]) return prev

        if (!prevData?.[subscriptionKey]) {
          return {
            __typename: "Query",
            [subscriptionKey]: subData[subscriptionKey] || null,
          } as QueryData
        }

        const queryDataKeys = Object.keys(prevData).filter(
          (key) => key !== "__typename"
        )

        if (queryDataKeys.length !== 1) {
          console.error(
            "useLiveQuery: query data should have only one key beyond __typename",
            prev
          )
          // console.error("useLiveQuery: prev has more than one key", prev);
          return prev
        }

        const queryDataKey = queryDataKeys[0]

        if (queryDataKey !== subscriptionKey) {
          console.error(
            "useLiveQuery: queryDataKey !== subscriptionKey",
            queryDataKey,
            subscriptionKey
          )

          console.error(
            "useLiveQuery: subscription and query should be have the same interface"
          )

          return prev
        }

        if (
          Array.isArray(prevData?.[queryDataKey]) &&
          Array.isArray(subData?.[subscriptionKey])
        ) {
          const data = [
            ...(subData[subscriptionKey] || []),
            // ...(prev[queryDataKey] || []),
            ...(prevData?.[queryDataKey] || []),
          ].filter((a): a is Exclude<typeof a, null> => !!a)

          const query = {
            __typename: "Query",
            [queryDataKey]: (() => {
              const r =
                props.arrayOptions?.isUnique || props.arrayOptions?.unicityFn
                  ? _.uniqBy(data, (a) =>
                      props.arrayOptions?.unicityFn
                        ? props.arrayOptions.unicityFn(a)
                        : a.id
                    )
                  : data

              const result = props.arrayOptions?.sortFn
                ? [...(r || [])].sort(props.arrayOptions?.sortFn)
                : r

              return result
            })(),
          } as QueryData

          // console.log(query)

          return query
        } else if (
          !Array.isArray(prevData?.[queryDataKey]) &&
          !Array.isArray(subData?.[subscriptionKey])
        ) {
          return {
            __typename: "Query",
            [queryDataKey]: subData?.[subscriptionKey],
          } as QueryData
        } else {
          return prev
        }
      },
    })
  }, [
    query?.subscribeToMore,
    props.options,
    props.arrayOptions?.isUnique,
    props.arrayOptions?.sortFn,
    props.arrayOptions?.unicityFn,
    props.arrayOptions?.filter,
    props.subscriptionDocument,
    props.disableSubscription,
  ])

  return query
}

// Custom QueryHook function type that supports your useChatMessagesQuery
export type CustomQueryHook<QueryData, QueryVariables> = (
  baseOptions: ApolloReactHooks.QueryHookOptions<QueryData, QueryVariables>
) => ApolloReactCommon.QueryResult<QueryData, QueryVariables>

export type ArrayOptions<
  QueryData,
  Element = QueryArrayElement<QueryData>
> = Element extends never
  ? never
  : {
      sortFn?: (a: Element, b: Element) => number
      unicityFn?: (a: Element) => string
      isUnique?: boolean
      filter?: (a: Element) => boolean //implement.
    }

export type QueryArrayElement<T> = Omit<T, "__typename"> extends {
  [key: string]: Array<infer U> | null | undefined
}
  ? U
  : never
export type LiveQueryInput<UseQuery> = UseQuery extends CustomQueryHook<
  infer QueryData,
  infer QueryVariables
>
  ? {
      useQuery: UseQuery
      options: ApolloReactHooks.QueryHookOptions<QueryData, QueryVariables>
      subscriptionDocument: SubscribeToMoreOptions["document"]
      disableSubscription?: boolean
      arrayOptions?: ArrayOptions<QueryData>
      updateQuery?: UpdateQueryFn<QueryData, unknown, QueryData>
      onSubscriptionData?: (subData?: QueryData) => any
      refetchQueryOnSubscriptionData?: boolean
    }
  : never
