import _ from "lodash"
import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { Option } from "../types/types"

export type Config = { onlyOptionsMatchingText?: boolean }

export default function useAutoCompleteSuggestions<T extends Option>(
  props: {
    options: T[]
    textField?: string
  },
  config?: Config
) {
  const reducer = getReducer(config)

  const [state, dispatch] = React.useReducer(reducer, {
    isVisible: false,
    allOptions: props.options,
    options: props.options,
    text: props.textField,
  })

  React.useEffect(
    () => dispatch({ type: "newOptions", payload: { options: props.options } }),
    [props.options]
  )

  React.useEffect(
    () => dispatch({ type: "changeText", payload: { text: props.textField } }),
    [props.textField]
  )

  const changeTextFilteringOptions = React.useCallback(
    (text: string) => dispatch({ type: "type", payload: { text } }),
    []
  )

  const clearTextInput = React.useCallback(
    () => dispatch({ type: "changeText", payload: { text: "" } }),
    []
  )

  const hideSuggestions = React.useCallback(
    () => dispatch({ type: "hideSuggestions" }),
    []
  )

  return useMemoAPI({
    state,
    dispatch,
    isVisible: state.isVisible,
    options: state.options as T[],
    text: state.text,
    clearTextInput,
    changeTextFilteringOptions,
    hideSuggestions,
  })
}

type State = {
  isVisible?: boolean
  text?: string
  options?: Option[]
  allOptions?: Option[]
  selected?: Option | null
}

type EventType =
  | "hideSuggestions"
  | "type"
  | "toggleVisibilityAllOptions"
  | "startTyping"
  | "newOptions"
  | "select"
  | "changeText"

const getReducer = (config?: Config) =>
  (function reducer(state: State, action: { type: EventType; payload?: State }) {
    const filter = getFilter(config)

    const events: Record<EventType, () => State> = {
      hideSuggestions: () => {
        return {
          ...state,
          isVisible: false,
        }
      },
      type: () => {
        const text = action.payload?.text

        return {
          ...state,
          text,
          isVisible: true,
          options: filter({
            // allOptions: _.get(state, "allOptions", []),
            allOptions:
              typeof state.allOptions === "undefined" ? [] : state.allOptions,
            text,
          }),
        }
      },
      toggleVisibilityAllOptions: () => {
        return {
          ...state,
          // options: _.get(state, "allOptions", []),
          // options: state.allO_.get(state, "allOptions", []),
          options:
            typeof state.allOptions === "undefined" ? [] : state.allOptions,
          // allOptions:
          //   typeof state.allOptions === "undefined" ? [] : state.allOptions,
          isVisible: !state.isVisible,
        }
      },
      startTyping: () => {
        const text = state.text || ""

        return {
          ...state,
          isVisible: true,
          options: filter({
            // allOptions: _.get(state, "allOptions", []),
            // allOptions: , "allOptions", []),
            allOptions:
              typeof state.allOptions === "undefined" ? [] : state.allOptions,
            text: state.text,
          }),
        }
      },
      newOptions: () => {
        const allOptions =
          typeof action.payload?.options === "undefined"
            ? []
            : action.payload.options

        return {
          ...state,
          allOptions,
          options: filter({ allOptions, text: state.text }),
        }
      },
      select: () => {
        return {
          ...state,
          // text: _.get(action, "payload.selected.label"),
          text: action.payload?.selected?.label,
          isVisible: false,
        }
      },
      changeText: () => {
        const newState = {
          ...state,
          // text: _.get(action, "payload.text"),
          text: action.payload?.text,
        }

        return newState
      },
    }

    return events[action.type]()
  })

const getFilter = (config?: Config) =>
  (function filter(
    props: {
      allOptions?: State["allOptions"]
      text: State["text"]
    }
  ) {
    const { allOptions, text } = props
    const enableTextFilter = !!(config?.onlyOptionsMatchingText ?? true)

    if (!enableTextFilter) return allOptions

    return (allOptions || []).filter((o) => {
      const string =
        [o.label, ...(o.metadata ? _.values(o.metadata) : [])].join(" ") || ""

      return string.toLowerCase().includes((text || "").toLowerCase())
    })
  })
