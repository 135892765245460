import { makeStyles, IconButton, Icon } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import Indentation, { IndentationProps } from "../Indentation/Indentation"
import { IconProps } from "components/Icon/Icon"
// import Indentation from "../Indentation/Indentation"

export default function Header(props: {
  iconButton?: { iconName: IconProps["name"]; onClick?: () => void }
  content?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  IndentationProps?: Omit<IndentationProps, "children">
  render?(props: { defaultComponent: React.ReactNode }): React.ReactNode
}) {
  const c = useHeaderStyles()

  const header = (
    <Indentation
      style={props.style}
      className={clsx(
        c.root,
        "header",
        props.IndentationProps?.className,
        props.className
      )}
      left={
        props.iconButton ? (
          <IconButton
            size="small"
            onClick={props.iconButton.onClick}
            className={c.iconButton}
          >
            <Icon className={c.iconButton}>{props.iconButton.iconName}</Icon>
          </IconButton>
        ) : null
      }
      {...(props.IndentationProps || {})}
    >
      {props.content}
    </Indentation>
  )

  if (props.render) return <>{props.render({ defaultComponent: header })}</>

  return header
}
const useHeaderStyles = makeStyles((theme) => {
  return {
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1.5),
      paddingRight: theme.spacing(2),
      height: 60,
    },
    iconButton: {
      fontSize: "21px !important",
      padding: 0,
      lineHeight: 1,
    },
  }
})
