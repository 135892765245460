import getter from "util/getter"
import React from "react"
import ProfileAppBar from "../ProfileAppBar/ProfileAppBar"
import usePartner from "hooks/usePartner"
import { useParamsContext } from "../../contexts/libContext"
import useCollection from "hooks/useCollectionItem"

export default function PartnerAppBar({
  renderRight = ({
    defaultComponent,
    components: { search, settings },
    isMobileSearchActive,
  }) => defaultComponent,
}) {
  const { partnerID, collectionID } = useParamsContext()
  const collection = useCollection({ id: collectionID }) || {}

  const { partner } = usePartner({
    fetchPolicy: "cache-only",
    id: partnerID,
    collectionID,
    type: collection.type,
  })

  const g = getter(partner)

  return (
    <ProfileAppBar
      type={g("collection.type")}
      subtitle={g("url") || g("street") || g("city") || ""}
      title={[g("name1", ""), g("name2", "")].filter(Boolean).join(" ")}
      deleted={!!g("deleted")}
      renderRight={renderRight}
    ></ProfileAppBar>
  )
}
