import clsx from "clsx"
import Shareable from "components/Shareable/Shareable"
import { Link } from "react-router-dom"

import Hook from "components/Hook/Hook"
import ShareableLink from "./ShareableLink"

export default function SameOriginLink(props: {
  url: URL
  className?: string
  renderShareableLink?: (params: {
    shareableProps: ReturnType<typeof Shareable.useShareable>
    defaultComponent: React.ReactNode
  }) => React.ReactNode
}) {
  const url = props.url

  if (Shareable.isShareableURL({ url: props.url })) {
    return (
      <Hook hook={Shareable.useShareable} hookProps={{ url: props.url }}>
        {(shareable) => {
          const defaultComponent = (
            <ShareableLink
              className={props.className}
              shareableProps={shareable}
            ></ShareableLink>
          )

          if (props.renderShareableLink)
            return props.renderShareableLink({
              shareableProps: shareable,
              defaultComponent,
            })

          return defaultComponent
        }}
      </Hook>
    )
  }

  if (!url) return null

  return (
    <Link
      to={url.pathname + url.search}
      className={clsx("same-origin-link", props.className)}
    >
      {props.url.href}
    </Link>
  )
  // return (
  //   <a
  //     href={url.href}
  //     className={clsx("same-origin-link", props.className)}
  //     target="_blank"
  //   >
  //     {props.url.href}
  //   </a>
  // )
}
