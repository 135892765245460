import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { clipText } from "styles/globalStyles"

export type ClippedTextProps = {
  containerWidth?: number | undefined
  children: React.ReactNode
  maxPercentage?: string
  className?: string
  maxWidth?: number | string
  style?: React.CSSProperties
}

export default function ClippedText(props: ClippedTextProps) {
  const {
    maxPercentage = "50%",
    className = "",
    children,
    maxWidth = undefined,
    containerWidth,
  } = props

  const c = useStyles({
    maxWidth: (() => {
      if (maxWidth) return maxWidth
      if (!containerWidth) return 0
      const ratio = Number(maxPercentage.replace("%", "")) / 100
      return containerWidth * ratio
    })(),
  })

  return (
    <span
      style={props.style}
      className={clsx("clipped-text", c.root, className)}
    >
      {children}
    </span>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "block",
      maxWidth: (props: { maxWidth: number | string }) => props.maxWidth,

      "&, &>*": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
  }
})
