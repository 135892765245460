import { BottomModalVariantProps } from "components/Modal/Modal"
import useWindowSize from "hooks/useWindowSize"

export function useBottomModalProps() {
  const windowSize = useWindowSize()

  const props: BottomModalVariantProps = {
    variant: "bottom-modal",
    BottomModalProps: {
      TransitionProps: {
        timeout: 300,
      },
      containerTransitionStyles: {
        entered: {
          top: 60,
        },
        exiting: () => ({
          top: windowSize.height,
        }),
      },
    },
  }

  return props
}
