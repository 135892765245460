import useMemoAPI from "hooks/useMemoAPI"
import { useCartApi } from "./useCartApi"

export function useShop(props: {
  cartID: string
  // createOrder: { create: () => void; loading: boolean }
}) {
  const cartApi = useCartApi({ cartID: props.cartID })

  return useMemoAPI({ cartApi: cartApi, ...props })
}
