import { useCallback } from "react"

export default function useInputOnChange({ onChange: propsOnChange }) {
  return useCallback(
    function onChange(e) {
      const isFunction = typeof onChange === "function"
      if (!isFunction || !e) return

      const { checked, value, name } = e.target

      propsOnChange({
        target: {
          value: typeof checked === "undefined" ? value : checked,
          name,
        },
      })
    },
    [propsOnChange]
  )
}
