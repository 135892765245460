import React from "react"
import { makeStyles } from "@material-ui/styles"
import { alignmentUnits } from "styles/globalStyles"
import clsx from "clsx"

export default function DetailsForPopover({
  headerBackground,
  body,
  actionsButtons = [],
  className = ""
}) {
  const c = useStyles({})

  return (
    <div className={clsx(c.detailsForPopover, className)}>
      {headerBackground && (
        <div className={c.headerBackground}>{headerBackground}</div>
      )}

      <div className={c.body}>
        <div className="top">{body}</div>
        {(actionsButtons || []).length && (
          <div className={c.actionsButtons}>{actionsButtons}</div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => {
  return {
    actionsButtons: {
      display: "flex",
      flexWrap: "wrap",
      "&>*:not(:first-child)": {
        marginLeft: theme.spacing(0.5)
      }
    },
    detailsForPopover: {
      width: theme.spacing(25),
      display: "flex",
      flexDirection: "column"
    },

    headerBackground: {
      height: theme.spacing(20),
      "&>*": {
        height: "100%"
      }
    },

    body: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: theme.spacing(alignmentUnits),
      minHeight: theme.spacing(20),
      alignItems: "flex-start"
    }
  }
})
