import useForm, { UseFormProps } from "hooks/useForm"
import useUpdatePartner from "hooks/useUpdatePartner/useUpdatePartner"
import { PartnerFragment } from "lib/graphql/operations"
import { PartnerUpdateInput } from "lib/graphql/types"
import _ from "lodash"
import React from "react"

export function usePartnerForm(props?: { partner?: PartnerFragment | null }) {
  const initialValues = React.useMemo(() => {
    if (!props?.partner) return {} as PartnerUpdateInput

    const keys: (keyof PartnerUpdateInput)[] = [
      "active",
      "city",
      "country",
      "deleted",
      "name1",
      "name2",
      "no",
      "ocr",
      "remarks",
      "street",
      "taxID",
      "type",
      "url",
      "zip",
    ]

    const v: PartnerUpdateInput = {
      ...(_.pick(props.partner, keys) || {}),
    }

    return v
  }, [props?.partner])

  const { updatePartner } = useUpdatePartner()

  const onSubmit = React.useCallback(
    (() => {
      const onSubmit: UseFormProps<typeof initialValues>["onSubmit"] =
        function onSubmit(a) {
          const update = a.values

          const partnerID = props?.partner?.id

          if (!partnerID) return

          return updatePartner({ dataUpdate: update, partnerID })
        }
      return onSubmit
    })(),
    [props?.partner?.id, updatePartner]
  )

  const form = useForm({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    // isReadyToSubmit({ errors, dirty }) {
    //   return _.isEmpty(errors) && dirty
    // },
  })

  return form
}
