import _ from "lodash"
import { ApolloError } from "@apollo/client"

export default function apolloErrorMessage(e: ApolloError) {
  if (!e) return null

  const [error] = new ApolloError(e).graphQLErrors

  return {
    message: _.get(error, "message"),
    code: _.get(error, "extensions.code"),
  }
}
