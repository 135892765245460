import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { useDocumentFormAPI } from "../hooks/useDocumentFormAPI"

export type DocumentFormAPI = ReturnType<typeof useDocumentFormAPI>

const a = contextAndProviderFactory({
  hookApi: useDocumentFormAPI,
  contextName: "DocumentFormContext",
})

export const DocumentFormContext = a.Context
export const DocumentFormProvider = a.Provider
