import { useApolloClient } from "@apollo/client";
import React from "react"
import { fetchPageBoardItem } from "../lib/fetchPageBoardItem"
import { useSelectedAPI } from "./useSelectedAPI"
import useActiveResources from "../../../../../hooks/useActiveResources"

export function useOpenPageItem(props: {
  selectedAPI: ReturnType<typeof useSelectedAPI>
}) {
  const workspaceID = useActiveResources().workspaceID
  const client = useApolloClient()

  type Lib = typeof fetchPageBoardItem

  return React.useCallback(
    async function openPage<Key extends keyof Lib>(
      type: Key,
      params: Omit<Parameters<Lib[Key]>[0], "workspaceID">
    ) {
      try {
        const fn = fetchPageBoardItem[type]

        if (typeof fn !== "function") return

        const item = await (fn as any)({ ...params, workspaceID }, client)

        if (!item) return

        props.selectedAPI.set(item as any)
      } catch (e) {
        console.error(e)
      }
    },
    [props.selectedAPI, workspaceID]
  )
}
