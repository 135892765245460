import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { EnhancedPage } from "components/EnhancedPage/EnhancedPage"
import TwoPages from "components/TwoPages/TwoPages"
import React from "react"
import UserPageBoardItemContext from "../../contexts/UserPageBoardItemContext"
import { Body } from "./Body"
import { Title } from "./Title"

export default function UserPageBoardItemRoot() {
  const c = useStyles()
  const api = React.useContext(UserPageBoardItemContext)

  return (
    <TwoPages.Root
      className={clsx(c.root, "user-page-board-item-root")}
      mainPage={
        <EnhancedPage
          onClose={api.onClose}
          className={c.fullHeight}
          titleComponent={
            <>
              <Title className={c.title}></Title>
            </>
          }
          onCloseIconName="close"
          style={{ height: "100%" }}
          body={<Body></Body>}
        ></EnhancedPage>
      }
    ></TwoPages.Root>
  )
}
const useStyles = makeStyles(() => ({
  title: {
    width: "100%",
  },
  root: {
    height: "100%",
  },

  fullHeight: {
    height: "100%",
  },

  messages: {
    background: "none",
  },
}))
