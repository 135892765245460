import _ from "lodash"
import useActiveResources from "hooks/useActiveResources"
import { useDocumentQuery } from "lib/graphql/operations"
import { ApolloError, WatchQueryFetchPolicy } from "@apollo/client"
import useMemoAPI from "hooks/useMemoAPI"
// import { ApolloError } from 'apollo-server-errors';

export default function useDocument(props: {
  documentID: string
  fetchPolicy?: WatchQueryFetchPolicy
  pollInterval?: number
  onError?: (error: ApolloError) => void
}) {
  const {
    documentID: propsDocumentID = "",
    fetchPolicy = "cache-and-network",
  } = props

  const { workspaceID } = useActiveResources()

  let variables = { workspaceID, documentID: propsDocumentID }
  const ableToLoad = !!(variables.documentID && variables.workspaceID)

  const pollInterval = ableToLoad ? props.pollInterval : 0

  const query = useDocumentQuery({
    fetchPolicy,
    variables,
    skip: !ableToLoad,
    onError: props.onError,
    ...(pollInterval ? { pollInterval } : {}),
  })

  const api = {
    document: query?.data?.document,
    isLoading: query?.loading,
  }

  return useMemoAPI(api)
}
