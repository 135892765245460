import { isPageBoardItemParamsInput } from "components/PageBoard/lib/isPageBoardItemParamsInput"
import useMemoAPI from "hooks/useMemoAPI"
import {
  PageBoardItemParamsInput,
  PageBoardItemParamsWithRedirect,
} from "lib/graphql/types"
import { set } from "lodash"
import React from "react"
import { MakePropsOptionalAndNullable } from "types/type"

const getID = (
  a: MakePropsOptionalAndNullable<PageBoardItemParamsInput> | undefined | null
) => {
  if (!a) return ""

  return [a.itemID, a.type].join(":")
}

const parseID = (id: string) => {
  const [itemID, type] = id.split(":")
  return { itemID, type }
}

/**
 ** This hook is used to track the history of the selected item.
 ** It is used to determine whether the item is previously selected or not.
 */
export function useHistoryThread(props: {
  entryPoint?: MakePropsOptionalAndNullable<PageBoardItemParamsInput> | null
}) {
  type Registry = {
    [id: string]: number
  }

  // const [registry, setVisitationRegistry] = React.useState<Registry>(
  //   {}
  // )

  type RegistryEntry =
    MakePropsOptionalAndNullable<PageBoardItemParamsInput> & {
      onClose: () => any
      id: string
    }

  const [thread, setThread] = React.useState<RegistryEntry[]>([])

  const registry = React.useMemo<Registry>(() => {
    return thread.reduce((acc, item) => {
      acc[getID(item)] = (acc[getID(item)] || 0) + 1
      return acc
    }, {} as Registry)
  }, [thread])

  const push = React.useCallback(function push(
    // params: PageBoardItemParamsInput
    args: RegistryEntry
  ) {
    // setVisitationRegistry((prev) => ({
    //   ...prev,
    //   [getID(params)]: (prev[getID(params)] || 0) + 1,
    // }))

    setThread((prev) => {
      // const id = getID({ itemID: args.itemID, type: args.type })
      // const index = prev.findIndex((item) => getID(item) === id)

      // if (index !== -1) {
      //   prev.splice(index, 1)
      // }

      return [...prev, args]
    })
  }, [])

  const remove = React.useCallback((id: string) => {
    setThread((prev) =>
      (() => {
        const clone = [...prev]
        const index = clone.findIndex((item) => item.id === id)

        if (index !== -1) {
          const c = clone.slice(0, index)
          // clone[index].onClose()
          return c
        }

        return clone
      })()
    )
  }, [])

  const reset = React.useCallback(function reset() {
    // setVisitationRegistry(() => ({}))
    setThread(() => [])
  }, [])

  const alreadyVisited = React.useCallback(
    function isBehind(
      params: MakePropsOptionalAndNullable<PageBoardItemParamsInput>
    ) {
      const redirectWasVisited =
        !!registry[getID((params as PageBoardItemParamsWithRedirect).redirect)]

      return !!registry[getID(params)] || redirectWasVisited
    },
    [registry]
  )

  React.useEffect(() => {
    if (!isPageBoardItemParamsInput(props.entryPoint)) {
      return reset()
    }

    push({
      ...props.entryPoint,
      onClose: () => {},
      id: getID(props.entryPoint),
    })
  }, [getID(props.entryPoint)])

  const api = useMemoAPI({
    push,
    remove,
    reset,
    alreadyVisited,
    registry,
    thread,
    entryPoint: props.entryPoint,
  })

  return api
}
