import _ from "lodash"
import React, { BaseSyntheticEvent } from "react"
import { IconButton, PopoverOrigin, IconButtonProps } from "@material-ui/core"
import { MoreVert } from "@material-ui/icons"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import clsx from "clsx"
import { makeStyles } from "@material-ui/styles"
import useOnChange from "hooks/useOnChange"
import KeyListeners from "components/KeyListeners/KeyListeners"

export type ToggleableMenuItem = {
  onClick?: () => void
  isSelected?: boolean
} & ({ component: React.ReactNode } | { text: string })

export interface ToggleableMenuProps {
  icon?: React.ReactNode | null
  edge?: IconButtonProps["edge"]
  menuItems: ToggleableMenuItem[]
  closeOnSelect?: boolean
  anchor?: React.ReactNode
  disabled?: boolean
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  className?: string
  style?: React.CSSProperties
  IconButtonProps?: IconButtonProps
  MenuItemProps?: React.ComponentProps<typeof MenuItem>
  renderMenuItems?: (props: {
    defaultComponent: React.ReactNode
  }) => React.ReactNode
  onChangeOpenState?: (isOpen: boolean) => any
  open?: boolean
}

export default function ToggleableMenu(props: ToggleableMenuProps) {
  const { edge = "end", icon = <MoreVert></MoreVert> } = props

  const [anchorEl, setAnchorEl] = React.useState<
    BaseSyntheticEvent["currentTarget"] | null
  >(null)

  const isOpen = !!props.open || Boolean(anchorEl)

  useOnChange({ value: isOpen, onChange: props.onChangeOpenState })

  const handleClose = React.useCallback(() => setAnchorEl(null), [])
  const handleClick: React.MouseEventHandler = (e) =>
    setAnchorEl(e.currentTarget)

  KeyListeners.useEscapeListener(handleClose, { enabled: isOpen })

  const components = {
    menuItems: (props.menuItems || []).map(
      ({ onClick, isSelected, ...other }, index) => {
        return (
          <MenuItem
            key={`menu-item-${index}`}
            selected={isSelected}
            onClick={() => {
              typeof onClick === "function" && onClick()
              props.closeOnSelect && handleClose()
            }}
            {...(props.MenuItemProps || {})}
          >
            {"component" in other ? other.component : other.text}
          </MenuItem>
        )
      }
    ),
  }

  const c = useStyles()

  return (
    <div
      className={clsx("toggleable-menu", c.toggleableMenu, props.className)}
      style={props.style}
    >
      {props.anchor ? (
        <div onClick={!props.disabled ? handleClick : () => {}}>
          {props.anchor}
        </div>
      ) : icon ? (
        <IconButton
          {...(props.IconButtonProps || {})}
          edge={edge}
          onClick={handleClick}
        >
          {icon}
        </IconButton>
      ) : null}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
        open={isOpen}
        onClose={handleClose}
      >
        <>
          {_.isFunction(props.renderMenuItems)
            ? props.renderMenuItems({
                defaultComponent: <>{components.menuItems}</>,
              })
            : components.menuItems}
        </>
      </Menu>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    toggleableMenu: {
      "& *": {
        cursor: "pointer",
      },
    },
  }
})
