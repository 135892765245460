import React from "react"
import { RoomContext } from "../../contexts/room"
import { IconButton, Icon, makeStyles } from "@material-ui/core"
import { useChangeRoomNameMutation } from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import { Typename } from "types/type"
import updateFragment from "lib/updateFragment/updateFragment"
import TextField from "components/TextField/TextField"
import useTrueFalse from "hooks/useTrueFalse"
import useOnChange from "hooks/useOnChange"
import useInputAutoFocus from "hooks/useInputAutoFocus"
import KeyListeners from "components/KeyListeners/KeyListeners"
import { ClippedTextProps } from "components/ClippedText/ClippedText"
import clsx from "clsx"
import ClippedTypography from "../../../ClippedTypography/ClippedTypography"
import { Variant } from "@material-ui/core/styles/createTypography"

export type EditNameProps = {
  isEditing?: ReturnType<typeof useTrueFalse>
  ClippedTextProps?: Omit<ClippedTextProps, "children">
  style?: React.CSSProperties
  className?: string
  typographyVariant?: Variant
  renderIdle?: (props: {
    components: { editButton: React.ReactNode; name: React.ReactNode }
    defaultComponent: React.ReactNode
  }) => React.ReactNode
}

export default function EditRoomName(props: EditNameProps) {
  const api = React.useContext(RoomContext)
  const roomID = api.room?.id

  const innerIsEditing = useTrueFalse(false)
  const isEditing = props.isEditing || innerIsEditing

  const changeRoomName = useChangeRoomName({ roomID })

  const c = useStyles()

  KeyListeners.useEscapeListener(isEditing.setFalse, {
    enabled: isEditing.isTrue,
  })

  const externalName = api.room?.name || ""
  const [name, setName] = React.useState(externalName)

  const autoFocus = useInputAutoFocus({ enable: isEditing.isTrue })

  const onSubmit = React.useCallback(() => {
    changeRoomName(name)
    isEditing.setFalse()
  }, [changeRoomName, name])

  useOnChange({
    value: externalName,
    onChange() {
      if (externalName === name) return
      setName(externalName)
    },
  })

  if (!api.room) return null

  return (
    <div
      style={props.style}
      className={clsx("edit-room-name", c.root, props.className)}
    >
      {(() => {
        if (isEditing.isFalse) {
          const nameComponent = (
            <ClippedTypography
              variant={props.typographyVariant}
              ClippedTextProps={props.ClippedTextProps}
            >
              {name}
            </ClippedTypography>
          )

          const editButton = (
            <IconButton onClick={isEditing.setTrue} className={c.iconButton}>
              <Icon>edit</Icon>
            </IconButton>
          )

          const defaultComponent = (
            <>
              {nameComponent}
              {editButton}
            </>
          )

          if (props.renderIdle)
            return props.renderIdle({
              components: { editButton, name: nameComponent },
              defaultComponent,
            })

          return defaultComponent
        }

        return (
          <form
            className={c.form}
            onSubmit={(e) => {
              e.preventDefault()
              onSubmit()
            }}
          >
            <TextField
              typographyVariant={props.typographyVariant}
              value={name}
              onBlur={() => {
                if (isEditing.isFalse) return
                onSubmit()
              }}
              onChange={(e) => {
                const text = e.target.value
                setName(text)
              }}
              inputProps={{ ref: autoFocus.inputRef }}
              InputProps={{
                endAdornment: (
                  <IconButton size="small" onClick={isEditing.setFalse}>
                    <Icon>cancel</Icon>
                  </IconButton>
                ),
              }}
            ></TextField>
          </form>
        )
      })()}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    form: {
      width: "100%",
    },
    iconButton: {
      padding: theme.spacing(0, 2),
    },
    root: {
      display: "flex",
      alignItems: "center",

      // height: 48
    },
  }
})
function useChangeRoomName(props: { roomID: string | undefined }) {
  const { workspaceID } = useActiveResources()

  const roomID = props.roomID

  const [mutation] = useChangeRoomNameMutation({
    optimisticResponse: {
      __typename: "Mutation",
      changeRoomName: {
        __typename: "BasicResponse",
        success: true,
      },
    },
  })

  const changeRoomName = React.useCallback(
    (name: string) => {
      if (!roomID || !workspaceID) return
      mutation({
        variables: { workspaceID, name, roomID },

        update(dataProxy, mutationResult) {
          if (!mutationResult.data?.changeRoomName?.success) return

          if (!roomID) return

          updateFragment(
            {
              typename: Typename.Room,
              cacheIDParams: { id: roomID },
              updateFn: (a) => ({ ...a, name }),
            },
            dataProxy
          )
        },
      })
    },
    [workspaceID, roomID, mutation]
  )
  return changeRoomName
}
