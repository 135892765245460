import AutoComplete from "components/OldDocumentForm/components/AutoComplete/AutoComplete"
import CountriesAutoComplete from "components/Input/components/CountriesAutoComplete/CountriesAutoComplete"
import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { PageTextField as TextField } from "components/Page/Page"
import { I18nAPI } from "contexts/i18nContext/i18nContext"
import { ContactFormContext } from "../components/ProviderAndContext/ProviderAndContext"
import Switch from "../../../../../../../../Switch/Switch"
import Typography from "@material-ui/core/Typography"
import SalutationAutoComplete from "../components/SalutationAutoComplete/SalutationAutoComplete"
import CopyToClipboardInput from "components/Input/components/CopyToClipboardInput/CopyToClipboardInput"
import CopyAccessKeyToClipboardInput from "components/Input/components/CopyAccessKeyToClipboardInput/CopyAccessKeyToClipboardInput"

export function getBusinessFieldProps(props: { translations: I18nAPI }) {
  const { translations } = props

  const fieldProps: FieldsetIndentationProps = {
    icon: "business",
    children: (
      <ContactFormContext.Consumer>
        {(api) => {
          return (
            <>
              {/* <CopyToClipboardInput readOnly ></CopyToClipboardInput> */}
              <CopyAccessKeyToClipboardInput
                value={api.contact?.access?.key || ""}
                label={translations["access-key"]}
              ></CopyAccessKeyToClipboardInput>

              <TextField
                {...api.form.getFieldProps("email")}
                label={translations.email}
                helperText={api.form.formik.errors["email"]}
              ></TextField>

              <TextField
                {...api.form.getFieldProps("mobile")}
                label={translations.mobile}
              ></TextField>

              <TextField
                {...api.form.getFieldProps("landline")}
                label={translations.landline}
              ></TextField>

              {/* lla
              <TextField
                {...api.form.getFieldProps("name2")}
                label={translations.name2}
              ></TextField>
              <TextField
                {...api.form.getFieldProps("street")}
                label={translations.street}
              ></TextField>
              <TextField
                {...api.form.getFieldProps("zip")}
                label={translations.zip}
              ></TextField>
              <TextField
                {...api.form.getFieldProps("city")}
                label={translations.city}
              ></TextField>
              <CountriesAutoComplete
                {...api.form.getFieldProps("country")}
                TextFieldProps={{ label: translations.country }}
                AutoComplete={AutoComplete}
                renderSuggestionsItem={(item) => {
                  return <Typography>{item}</Typography>
                }}
              ></CountriesAutoComplete> */}
            </>
          )
        }}
      </ContactFormContext.Consumer>
    ),
  }

  return fieldProps
}
