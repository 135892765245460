import _ from "lodash"
import React from "react"
import { IconButton, Box } from "@material-ui/core"
import { Menu as MenuIcon, ArrowBack } from "@material-ui/icons"
import useAppRouter from "hooks/useAppRouter"
import NotificationBadge from "./components/NotificationBadge/NotificationBadge"
import { useDrawerContext } from "contexts/drawerContext/drawerContext"
import { Interface } from "components/Interface/Interface"

export function NavigationButton({
  type = "toggle-drawer",
}: {
  type?: keyof typeof Icon
}) {
  const { history } = useAppRouter()
  const { toggleDrawer } = useDrawerContext()

  const action = {
    "toggle-drawer": toggleDrawer,
    "go-back": history.goBack,
  }

  return (
    <IconButton edge="start" onClick={action[type]}>
      {React.createElement(Icon[type])}
    </IconButton>
  )
}

const Icon = {
  "toggle-drawer": () => (
    <Interface.Consumer>
      {(api) => {
        if (!api.showHamburgerMenu) return null
        return (
          <NotificationBadge>
            <MenuIcon />
          </NotificationBadge>
        )
      }}
    </Interface.Consumer>
  ),
  "go-back": ArrowBack,
}
