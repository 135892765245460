import RootContainerProvider from "./components/RootContainerProvider/RootContainerProvider"
import RootContainerDefault from "./components/RootContainerDefault/RootContainerDefault"
import ClippedText from "./components/ClippedText/ClippedText"
import RootContainerContext from "./contexts/RootContainerContext"
import useRootContainerAPI, { SizeEnums } from "hooks/useRootContainerAPI"

const RootContainer = {
  Context: RootContainerContext,
  SizeEnums: SizeEnums,
  ClippedText,
  Default: RootContainerDefault,
  useAPI: useRootContainerAPI,
  Provider: RootContainerProvider,
}

export default RootContainer
