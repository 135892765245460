import _ from "lodash"

import ChatDefault from "./ChatDefault"
import ChatProvider from "./ChatProvider"
import ChatContext from "./contexts/ChatContext"
import ChatList from "./components/ChatList/ChatList"
import ChatListTopBar from "./components/ChatRoot/components/ChatListTopBar/ChatListTopBar"

const Chat = {
  Default: ChatDefault,
  Provider: ChatProvider,
  Context: ChatContext,
  ListTopBar: ChatListTopBar,
  List: ChatList,
}

export default Chat
