import React from "react"
import DocumentsDashboard from "components/DocumentsDashboard"
import DocumentsGrid from "components/DocumentsGrid/DocumentsGrid"
import DocumentsFilter from "components/DocumentsFilter/index"
import documentsViewsEnums from "../constants/documentsViewsEnums"

const CollectionDashboardContext = React.createContext({
  DocumentsGrid,
  DocumentsDashboard,
  DocumentsFilter,
  setDocumentView(documentView = documentsViewsEnums.GRID) {},
  documentView: documentsViewsEnums.GRID,
})

CollectionDashboardContext.displayName = "CollectionDashboardContext"

export default CollectionDashboardContext
