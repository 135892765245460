import { AddNewPartnerFormRoot } from "./components/AddNewPartnerFormRoot"

import FormSaveButton from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/FormCommons/components/FormSaveButton"
import React from "react"
import {
  AddNewPartnerFormConsumer,
  AddNewPartnerFormContext,
  AddNewPartnerFormProvider,
} from "./components/ContextAndProvider"

export const AddNewPartnerForm = {
  Provider: AddNewPartnerFormProvider,
  Context: AddNewPartnerFormContext,
  Consumer: AddNewPartnerFormConsumer,
  Root: AddNewPartnerFormRoot,
  SaveButtons: function SaveButtons() {
    const form = React.useContext(AddNewPartnerFormContext).form
    return (
      <>
        <FormSaveButton form={form} resetOnSubmit></FormSaveButton>
      </>
    )
  },
}
