import React from "react"
import {
  KanbanColumnFragment,
  RoomKanbanColumnsQueryVariables,
  useRoomKanbanColumnsQuery,
} from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import useOnChange from "./useOnChange"
import useCreateKanbanColumn from "./useCreateKanbanColumn"
import useChangeKanbanColumnTitle from "./useChangeKanbanColumnTitle"

export default function useKanbanColumns(props: {
  roomID: string
  setFirstColumnAsDefault?: boolean
  filter?: (column: KanbanColumnFragment) => boolean
}) {
  const [selectedColumn, setSelectedColumn] =
    React.useState<KanbanColumnFragment | null>(null)

  const variables: RoomKanbanColumnsQueryVariables = {
    roomID: props.roomID || "",
    workspaceID: useActiveResources().workspaceID,
  }

  useOnChange({
    value: props.roomID || "",
    onChange() {
      setSelectedColumn(null)
    },
  })

  const query = useRoomKanbanColumnsQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip: !variables.roomID || !variables.workspaceID,
  })

  const defaultValue = React.useRef<KanbanColumnFragment[]>([]).current
  const columns = query.data?.roomKanbanColumns || defaultValue

  React.useEffect(() => {
    if (!columns.length) return

    if (!selectedColumn) {
      if (!props.setFirstColumnAsDefault) return

      setSelectedColumn(columns[0])
      return
    }
  }, [columns.length])

  const filteredColumns = React.useMemo(() => {
    if (!props.filter) return columns
    return columns.filter(props.filter)
  }, [columns, props.filter])

  const createColumn = useCreateKanbanColumn({
    roomID: props.roomID,
  }).createColumn

  const changeKanbanColumnTitle = useChangeKanbanColumnTitle({
    roomID: props.roomID,
  }).changeColumnTitle

  return useMemoAPI({
    columns: filteredColumns,
    selectedColumn,
    createColumn,
    setSelectedColumn,
    changeColumnTitle: changeKanbanColumnTitle,
  })
}
