import React from "react"
import ShopArticleCard, {
  ShopArticleProps,
} from "../../ShopArticleCard/ShopArticleCard"
import { CardCartButtons } from "./CardCartButtons/CardCartButtons"

export function ArticleCard(
  props: Omit<ShopArticleProps, "actionsButtons"> & {
    renderActions?: (props: { cartButtons: React.ReactNode }) => React.ReactNode
  }
) {
  const cartButtons = <CardCartButtons {...props} />

  return (
    <ShopArticleCard
      {...props}
      {...(props.renderActions
        ? { actionsButtons: props.renderActions({ cartButtons: cartButtons }) }
        : {
            actionsButtons: (
              <div
                className="actions-buttons"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {cartButtons}
              </div>
            ),
          })}
    />
  )
}
