import { useApolloClient } from "@apollo/client";
import useActiveResources from "hooks/useActiveResources"
import {
  ColumnOrderingFragment,
  DeleteKanbanColumnMutation,
  KanbanColumnFragment,
  MutatedBoardFragment,
  useDeleteKanbanColumnMutation,
} from "lib/graphql/operations"
import readFragment from "lib/updateFragment/readFragment"
import { readQuery } from "lib/updateQuery/updateQuery"
import React from "react"
import { Typename } from "types/type"

export function useDeleteKanbanColumn(props?: { roomID?: string }) {
  const [mutate] = useDeleteKanbanColumnMutation()

  const r = useActiveResources()
  const client = useApolloClient()

  return React.useCallback(
    function deleteColumn(args: { columnID: string }) {
      return mutate({
        variables: { columnID: args.columnID, workspaceID: r.workspaceID },

        optimisticResponse: () => {
          const column = readFragment(
            {
              typename: Typename.KanbanColumn,
              cacheIDParams: { id: args.columnID },
            },
            client
          )

          const board = readQuery(
            {
              queryName: "decoupledKanbanBoard",
              variables: {
                roomID: props?.roomID || "",
                workspaceID: r.workspaceID,
              },
            },
            client
          )?.decoupledKanbanBoard

          const c: KanbanColumnFragment = {
            ...(column || {}),
            __typename: "KanbanColumn",
            id: args.columnID,
            isDeleted: true,
          }

          const columnOrdering: ColumnOrderingFragment | null = (() => {
            const old = board?.columnOrdering

            if (!old) return null

            const a: ColumnOrderingFragment = {
              ...old,
              orderedColumnsIDs:
                old.orderedColumnsIDs?.filter((id) => id !== args.columnID) ||
                [],
            }

            return a
          })()

          const boardMutation: MutatedBoardFragment = {
            __typename: "MutatedBoard",
            upsertedColumns: [c],
            columnOrdering,
          }

          const b: DeleteKanbanColumnMutation = {
            __typename: "Mutation",
            deleteKanbanColumn: boardMutation,
          }

          return b
        },
      })
    },
    [mutate, r.workspaceID, client, props?.roomID]
  )
}
