import { useI18n } from "contexts/i18nContext/i18nContext"
import {
  Message_TextMessage_Fragment,
  RepliedMessage_TextMessage_Fragment,
} from "lib/graphql/operations"
import React from "react"
import { BasicMessagePreview } from "./BasicMessagePreview"
import getAttachmentImage from "./util/getAttachmentImage"
import getText from "./util/getText"

export function TextMessagePreview(props: {
  message: RepliedMessage_TextMessage_Fragment | Message_TextMessage_Fragment
}) {
  const message = props.message

  const text = React.useMemo(() => getText(message), [message])
  const thumb = React.useMemo(() => getAttachmentImage(message), [message])
  const t = useI18n()

  if (!message) return null

  if (!text && !thumb?.src) return null

  const description = text || t["image"] || ""

  return <BasicMessagePreview {...props} text={description} src={thumb?.src} />
}
