import { usePartnerForm } from "components/PageBoard/components/PartnerForm/lib/usePartnerForm"
import useMemoAPI from "hooks/useMemoAPI"
import usePartner from "hooks/usePartner"
import { usePaginatedDocuments } from "hooks/usePaginatedDocuments"
import React from "react"
import { useTogglePartnerDeletionMutation } from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"

export type PartnerFormApiInput = {
  partnerID?: string
  summarized?: boolean
  onClickOpenButton?: () => void
  onClickContactChip?: (contact: { id: string }) => void
}

export const usePartnerFormAPI = (props?: PartnerFormApiInput) => {
  const partnerId = props?.partnerID || ""
  const data = usePartner({ id: partnerId })
  const form = usePartnerForm({ partner: data.partner })

  const toggleDeletionMutation = useTogglePartnerDeletionMutation()[0]

  const workspaceId = useActiveResources().workspaceID

  const toggleDeletion = React.useCallback(() => {
    if (!partnerId || !workspaceId) return

    return toggleDeletionMutation({
      variables: { partnerID: partnerId, workspaceID: workspaceId },
    })
  }, [toggleDeletionMutation, partnerId, workspaceId])

  const paginatedDocuments = usePaginatedDocuments({
    collectionId: data.partner?.collection?.id || "",
  })

  return useMemoAPI({
    form,
    partner: data.partner || null,
    paginatedDocuments,
    toggleDeletion,
    ...(props || {}),
  })
}
