import { LinkProps } from "./link"

export default function graphqlURI(props: { url: LinkProps["apolloURL"] }) {
  const url = props.url

  const protocol = url?.protocol
  const hostname = url?.hostname || window.location.host
  const pathname = url?.pathname || "/graphql"
  const port = url?.port

  return protocol + "//" + hostname + (port ? `:${port}` : "") + pathname
}
