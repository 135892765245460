import React, { ReducerAction } from "react"
import clsx from "clsx"
import _f from "lodash/fp"
import { makeStyles } from "@material-ui/core"
import ImageViewer from "components/ImageViewer/ImageViewer"

export default function Image(props: {
  imageURL: string
  animateOpenness?: boolean
  disableViewer?: boolean
  children?: (props: {
    components: { img: React.ReactElement; viewer: React.ReactElement }
    defaultComponent: React.ReactElement
    viewerIsActive: boolean
    closeViewer: () => void
    openViewer: () => void
    ref: React.RefObject<HTMLElement>
  }) => React.ReactChild
  className?: string
  classes?: { image?: string }
  onLoad: () => void
}) {
  const { animateOpenness = true } = props

  const c = useStyles({})
  const ref = React.useRef<HTMLElement>(null)
  const [viewerIsActive, setViewerIsActive] = React.useState(false)

  const openViewer = React.useCallback(() => setViewerIsActive(true), [])
  const closeViewer = React.useCallback(() => setViewerIsActive(false), [])

  if (!props.imageURL) return null

  const viewer = (
    <ImageViewer
      isOpen={viewerIsActive}
      onClose={closeViewer}
      imageURL={props.imageURL}
      className={clsx(c.root, "attachment-root")}
      imageRef={ref}
      animateOpenness={animateOpenness}
    ></ImageViewer>
  )

  const image = (
    <img
      src={props.imageURL}
      className={props.classes?.image || ""}
      ref={ref as React.RefObject<HTMLImageElement>}
      onClick={openViewer}
      onLoad={props.onLoad}
    />
  )

  const defaultComponent = (
    <>
      {image}
      {!!props.disableViewer ? null : viewer}
    </>
  )

  return (
    <div className={clsx("image", c.root, props.className)}>
      {props.children
        ? props.children({
            defaultComponent,
            components: { img: image, viewer },
            closeViewer,
            openViewer,
            viewerIsActive,
            ref,
          })
        : defaultComponent}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      overflow: "hidden",
    },
  }
})
