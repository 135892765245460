import { gql } from "@apollo/client";
import _f from "lodash/fp"
import _ from "lodash"
import getter from "util/getter"
import updateRoom from "./updateRoom"

export default function updateWorkspace({ workspaceUpdate }, client) {
  const w = getter(workspaceUpdate)

  if (_.isFinite(w("unread"))) {
    client.writeFragment({
      id: `Workspace:${w("workspaceID")}`,
      fragment: gql`
        fragment WorkspaceUpdate on Workspace {
          unread
        }
      `,
      data: {
        __typename: "Workspace",
        unread: w("unread"),
      },
    })
  }

  ;(w("roomsUpdates") || []).forEach((update) => {
    updateRoom({ update }, client)
  })
}
