import React, { useContext } from "react"
import RoomBase from "components/Room/Room"
import ChatContext from "components/Chat/contexts/ChatContext"

export default function ChatRoomAppBar() {
  const context = useContext(ChatContext)
  const { renderRoomTopBar, getOutOfRoom } = context

  return (
    <RoomBase.Template.AppBar
      render={renderRoomTopBar}
      onClickBackButton={getOutOfRoom}
    ></RoomBase.Template.AppBar>
  )
}
