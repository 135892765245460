import CenteredOnContainer from "components/CenteredOnContainer/CenteredOnContainer"
import _ from "lodash"
import React, { useState, useCallback, useContext } from "react"
import Partner from "components/Partner/index"
import SelectedContactDialog from "./components/SelectedContactDialog/SelectedContactDialog"
import ContactsTable from "./components/ContactsTable/ContactsTable"
import { Button, Icon, makeStyles, Paper } from "@material-ui/core"
import { useI18n } from "contexts/i18nContext/i18nContext"
import PartnersContext from "pages/Partners/contexts/PartnersContext"
import clsx from "clsx"

export default function Contacts() {
  const { partner } = useContext(Partner.Context) || { partner: null }
  const { openNewContactModal } = useContext(PartnersContext)
  const contacts = _.get(partner, "contacts") || []
  const [selectedContact, setSelectedContact] = useState(null)
  const reset = useCallback(() => setSelectedContact(null), [])

  const t = useI18n()
  const c = useStyles({})

  const hasContacts = !!contacts.length
  const addButton = (
    <Button variant="outlined" color="primary" onClick={openNewContactModal}>
      <Icon className="icon">group_add</Icon>
      {t["add"]}
    </Button>
  )

  if (!hasContacts)
    return (
      <CenteredOnContainer
        className={clsx(c.emptyContactsList, "empty-contact-list")}
      >
        {addButton}
      </CenteredOnContainer>
    )

  return (
    <>
      <div className={clsx(c.root, "partner-contacts")}>
        <div className="table">
          <ContactsTable
            onClickEdit={setSelectedContact}
            contacts={contacts}
          ></ContactsTable>
        </div>
        <Paper square className="add-partner-contact">
          {React.cloneElement(addButton, {
            variant: "contained",
            size: "small",
          })}
        </Paper>
      </div>
      <SelectedContactDialog
        isOpen={!!_.get(selectedContact || {}, "id")}
        onClose={reset}
        selectedContact={selectedContact}
      ></SelectedContactDialog>
    </>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "&>.table": {
        flex: 1,
        padding: theme.spacing(1, 2),
      },
      "&>.add-partner-contact": {
        padding: theme.spacing(1, 2),
        // flex: 1,
      },
    },
    emptyContactsList: {
      "& .icon": {
        marginRight: theme.spacing(1),
      },
    },
  }
})
