import useAppRouter from "hooks/useAppRouter"
import PartnersContext from "../../../../../../contexts/PartnersContext"
import { useContext } from "react"
import { makeStyles } from "@material-ui/styles"
import _ from "lodash"
import React, { useCallback } from "react"
import useContactFields from "pages/Partners/hooks/useContactFields"
import Table from "components/Table/Table"
import clsx from "clsx"
import groupBy from "util/groupBy"
import { useUtilsContext } from "pages/Partners/contexts/libContext"
import IconButton from "components/IconButton/IconButton"

export default function ContactsTable({
  className = "",
  contacts,
  onClickEdit,
}) {
  const c = useStyles({})

  const { setContactID } = useContext(PartnersContext)

  const goToContactPage = useCallback(
    (row) => {
      console.log(row)
      setContactID(row?.id)
    },
    [setContactID]
  )

  const columnFields = useColumnFields()

  return (
    <Table
      showSearch={contacts.length > 5}
      columns={[
        {
          name: "action",
          label: "",
          size: "small",
          className: c.columnClassName,
        },
        ...columnFields,
      ]}
      rows={contacts.map((r) => ({
        action: (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              onClickEdit(r)
            }}
          >
            edit
          </IconButton>
        ),
        ...r,
      }))}
      hover
      onClickRow={goToContactPage}
      className={clsx("contacts-table", className)}
    ></Table>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    columnClassName: {
      maxWidth: theme.spacing(2),
    },
  }
})

function useColumnFields() {
  const contactFields = useContactFields()
  const tableColumns = ["firstName", "lastName", "landline", "mobile"]
  const columnsByName = groupBy({ collection: contactFields, key: "name" })
  return tableColumns.map((key) => columnsByName[key]).filter(Boolean)
}
