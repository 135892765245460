import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import useMuteAPI from "./useMuteAPI"
import { useSubscription } from "observable-hooks"
import GraphqlSubscriptions from "components/GraphqlSubscriptions/GraphqlSubscriptions"
import { Message } from "lib/graphql/types"
import useGetRoomWorkspacesIDs from "hooks/useGetRoomWorkspacesIDs"
import useAudioControl from "./useAudioControl"
import useIsRoomFocused from "./useIsRoomFocused"

export default function useAudioNotificationsControl() {
  const muteAPI = useMuteAPI()

  const { audioRef, ring } = useAudioControl()

  const { externalUsersNewMessage$ } = React.useContext(
    GraphqlSubscriptions.Context
  )
  const getRoomWorkspacesIDs = useGetRoomWorkspacesIDs()
  const isRoomFocused = useIsRoomFocused()

  const onNewMessage = React.useCallback(
    (m: Message) => {
      const roomID = m.roomID

      if (document.visibilityState === "visible") return

      const roomIsMuted = muteAPI.isRoomMuted({ roomID })

      if (roomIsMuted) return

      const workspacesAreMuted = getRoomWorkspacesIDs({ roomID }).some(
        (workspaceID) => muteAPI.isWorkspaceMuted({ workspaceID })
      )

      if (workspacesAreMuted) return

      ring()
    },
    [ring, muteAPI, isRoomFocused]
  )

  useSubscription(externalUsersNewMessage$, onNewMessage)

  return useMemoAPI({
    muteAPI,
    audioElementRef: audioRef,
  })
}
