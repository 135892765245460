import { Badge, TooltipProps } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"

export type LightTooltipProps = TooltipProps

export default function LightTooltip(props: LightTooltipProps) {
  const c = useStyles()
  return (
    <Tooltip {...props} classes={{ ...c, ...(props.classes || {}) }}></Tooltip>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }
})
