import { Typography, makeStyles } from "@material-ui/core"
import moment, { Moment } from "moment"
import React from "react"
// import { Months } from "../CalendarViews/Months"
import { MonthControls } from "./MonthControls"
import clsx from "clsx"
import { StyledProps } from "types/type"
import { Months } from "./components/Months"

export function CalendarView(
  props: {
    body: React.ReactNode
    renderTitle: (date: Moment) => React.ReactNode
    additionPeriod: moment.unitOfTime.DurationConstructor
  } & StyledProps
) {
  const c = useStyles()

  return (
    <div
      className={clsx(c.root, "calendar-view", props.className)}
      style={props.style}
    >
      <Months.Context.Consumer>
        {(api) => {
          return (
            <React.Fragment>
              <MonthControls additionPeriod={props.additionPeriod}>
                <Typography>
                  {props.renderTitle(
                    moment()
                      .month(api.list.activeMonth.month.index)
                      .year(api.list.activeMonth.month.year)
                  )}
                </Typography>
              </MonthControls>
              <div className={c.body}>{props.body}</div>
            </React.Fragment>
          )
        }}
      </Months.Context.Consumer>
    </div>
  )
}
export const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingTop: 8,
      paddingBottom: 8,
      display: "flex",
      flexDirection: "column",
    },

    body: {
      flex: 1,
    },
  }
})
