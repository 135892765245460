import { MobileHiddenXScrollbar } from "components/MobileHiddenXScrollbar/MobileHiddenXScrollbar"
import { ScrollChildTracker } from "../../ScrollChildTracker"
import {
  ApiProps,
  DateAndTypesFilterApiContext,
  DateAndTypesFilterApiProvider,
} from "./DateAndTypesFilterApiContext"

export function DateAndTypesFilterProvider(props: ApiProps) {
  return (
    <DateAndTypesFilterApiProvider {...props}>
      <MobileHiddenXScrollbar.Provider>
        <MobileHiddenXScrollbar.Context.Consumer>
          {(scrollApi) => {
            return (
              <ScrollChildTracker.Provider
                scroll$={scrollApi.scroll$}
                containerMounted$={scrollApi.containerMounted$}
                containerResized$={scrollApi.containerResized$}
              >
                {props.children}
              </ScrollChildTracker.Provider>
            )
          }}
        </MobileHiddenXScrollbar.Context.Consumer>
      </MobileHiddenXScrollbar.Provider>
    </DateAndTypesFilterApiProvider>
  )
}
