import clsx from "clsx"
import { alignmentUnits } from "styles/globalStyles"
import { TextField, IconButton, Icon, InputAdornment } from "@material-ui/core"
import React from "react"
import { makeStyles } from "@material-ui/styles"

export default function FilterInput({ onChange, className = "" }) {
  const c = useStyles({})

  return (
    <TextField
      variant="outlined"
      onChange={e => onChange(e.target.value)}
      className={clsx(c.searchInput, className)}
      inputProps={{
        className: c.input,
        placeholder: "Filter"
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment edge="start">
            <Icon fontSize="small" className={c.icon}>
              search
            </Icon>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" className={c.cleanButton}>
            <IconButton onClick={() => onChange("")} edge="end" size="small">
              <Icon fontSize="small" className={c.icon}>
                close
              </Icon>
            </IconButton>
          </InputAdornment>
        )
      }}
    ></TextField>
  )
}

const useStyles = makeStyles(theme => {
  return {
    cleanButton: {
      visibility: props => (props.searchedTerm ? "visible" : "hidden")
    },

    icon: {
      color: theme.palette.text.secondary
    },
    search: {
      padding: theme.spacing(0, alignmentUnits)
    },
    searchInput: {},
    input: {
      padding: "12px 14px"
    }
  }
})
