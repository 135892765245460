import { useApolloClient } from "@apollo/client";
import React from "react"
import { useEffect, useState } from "react"
import useMemoAPI from "hooks/useMemoAPI"

export default function useApolloCacheReset() {
  const [cacheWasReset, setCacheWasReset] = useState(false)

  const client = useApolloClient()

  useEffect(() => {
    client.onResetStore(async () => {
      setCacheWasReset(true)
    })
  }, [client])

  const resetCache = React.useCallback(async () => {
    await client.cache.reset()
    await client.resetStore()
    localStorage.setItem("apollo-cache-persist", "{}")
  }, [])

  return useMemoAPI({ resetCache, cacheWasReset })
}
