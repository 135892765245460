import React from "react"
import { ChipProps, makeStyles } from "@material-ui/core"
import { Icon, Chip } from "@material-ui/core"
import clsx from "clsx"
import useGlobalStyles from "styles/globalStyles"
import { COLLECTIONS_TYPES_ICONS } from "lib/constants"
import { useI18n } from "contexts/i18nContext/i18nContext"
import DocumentCollectionsContext from "../../contexts/DocumentCollectionsContext"

export default function Chips(props: {
  ChipProps?: ChipProps
  hideAddButton?: boolean
}) {
  const c = useStyles()
  const gs = useGlobalStyles()
  const t = useI18n()

  const api = React.useContext(DocumentCollectionsContext)

  const allowedTypes = api.collectionsOverviews?.map((a) => a.type)

  const chips = (api.collections || []).map((collection, index) => {
    if (!collection.type || !collection?.id) return null

    if (!allowedTypes?.includes(collection?.type)) return null

    return (
      <Chip
        key={collection.id}
        label={collection.title}
        variant="outlined"
        deleteIcon={<Icon>highlight_off</Icon>}
        clickable
        icon={
          COLLECTIONS_TYPES_ICONS[
            collection.type as keyof typeof COLLECTIONS_TYPES_ICONS
          ]
        }
        onClick={function select() {
          api.setSelectedCollectionID(collection.id)
          api.setIsDialogOpen(true)
        }}
        onDelete={async function onDelete() {
          api.dissociateCollection({
            collectionID: collection.id,
          })
        }}
        {...(props.ChipProps || {})}
        className={clsx(props.ChipProps?.className, c.chip)}
        classes={{
          ...props.ChipProps?.classes,
          label: clsx(
            gs.clipText,
            c.chipLabel,
            props.ChipProps?.classes?.label
          ),
        }}
      />
    )
  })

  if (typeof api.renderChips === "function")
    return (
      <>
        {api.renderChips({
          components: { chips },
          api: { openDialog: api.openDialog },
        })}
      </>
    )

  return (
    <div className={clsx("chip")}>
      {[
        ...chips.filter(Boolean).map(
          (chip) =>
            chip &&
            React.cloneElement(chip, {
              className: clsx(chip.props.className),
            })
        ),

        !props.hideAddButton && (
          <Chip
            key="add"
            label={t.add}
            clickable
            icon={<Icon fontSize="small">add_circle_outline_icon</Icon>}
            color="primary"
            // className={c.chip}
            onClick={api.openDialog}
            {...(props.ChipProps || {})}
          ></Chip>
        ),
      ]}
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {},

    chipLabel: {
      display: "block",
      maxWidth: "190px",
    },
    chip: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(0.5),
    },
  }
})
