import { CodeNode } from "@lexical/code"
import { $getRoot, LexicalEditor } from "lexical"
import { $enforceWYSIWYG } from "../components/EnforceWYSIWYGPlugin/EnforceWYSIWYGPlugin"
import { LexicalEditorAPIInput } from "../hooks/useLexicalEditorAPI"
import $setEditorValue from "./$setEditorValue"

type $setEditorValueInput = {
  value: Exclude<LexicalEditorAPIInput["value"], undefined>
  editor: LexicalEditor
  markdownMode?: LexicalEditorAPIInput["markdownMode"]
}

export default function $setEditorValueDealingWithMarkdown(
  args: $setEditorValueInput
) {
  const dealWithMarkdown = () => {
    if (!args.markdownMode) {
      const node = $getRoot().getFirstChild() as CodeNode

      const isMarkdownValue = node.getLanguage?.call(node) === "markdown"

      if (isMarkdownValue) {
        $enforceWYSIWYG({ codeNode: node })
      }
    }
  }

  $setEditorValue({
    value: args.value,
    editor: args.editor,
    updateFn: dealWithMarkdown,
  })
}
