import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import useThrottledValue from "hooks/useThrottledValue"
import { PageBoardItemsQueryVariables } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import { Moment } from "moment"
import React from "react"
import { Period } from "types/type"
import {
  NewPageBoardItemFragment,
  PageBoardItemsResponseFragment,
} from "../../lib/graphql/operations"
import useOnChange from "../useOnChange"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"

export type PageBoardItemsInput = {
  search: string
  period?: Period | null
  types:
    | PageBoardItemType[]
    | ((props: { throttledSearch: string }) => PageBoardItemType[])
}
// const usePageBoardItems = createUsePageBoardItems({useHookApi})

export type PageBoardItemsHookResult = {
  fetchingResponse: PageBoardItemsResponseFragment | null | undefined
  fetchNextPage(): void
  refetch(): void
}

export type HookAPIFn = (args: {
  variables: PageBoardItemsQueryVariables
}) => PageBoardItemsHookResult

export function createUsePageBoardItems<
  HookResult extends PageBoardItemsHookResult = PageBoardItemsHookResult
>(props: {
  useHookApi: HookAPIFn
}): (args: PageBoardItemsInput) => {
  throttledSearch: string
  newItems: NewPageBoardItemFragment[]
} & HookResult {
  return function usePageBoardItems(args: PageBoardItemsInput): {
    throttledSearch: string
    newItems: NewPageBoardItemFragment[]
  } & HookResult {
    const throttledSearch = useThrottledValue(args.search, 1000)
    const throttledPeriod = args.period

    const workspaceID = useActiveResources().workspaceID

    const [newItems, setNewItems] = React.useState<NewPageBoardItemFragment[]>(
      []
    )

    useOnChange({
      value: workspaceID,
      onChange: () => {
        setNewItems([])
      },
    })

    const types = React.useMemo(() => {
      if (typeof args.types === "function") {
        return args.types({ throttledSearch })
      }

      return args.types
    }, [args.types])

    const formatDate = (date: Moment | null | undefined) => {
      if (!date) return null
      return date.format("YYYY-MM-DD")
    }

    const formatPeriod = (period: Period | null | undefined) => {
      const a = {
        from: formatDate(period?.start),
        to: formatDate(period?.end),
      }

      return [a.from, a.to].filter((a) => !!a).join("-")
    }

    const variables = React.useMemo<PageBoardItemsQueryVariables>(() => {
      return {
        search: (throttledSearch || "").trim(),
        types,
        workspaceID,
        ...(() => {
          if (!throttledPeriod?.start) return {}

          return {
            timeInterval: {
              start: throttledPeriod.start?.toISOString(),
              end: (throttledPeriod.end || throttledPeriod.start)
                .endOf("day")
                .toISOString(),
            },
          }
        })(),
        pagination: {
          from: 0,
          size: 30,
        },
      }
    }, [
      throttledSearch,
      JSON.stringify(types),
      workspaceID,
      formatPeriod(throttledPeriod),
    ])

    const hookResult = props.useHookApi({ variables })

    const currentWorkspace = useCurrentWorkspace()

    const pageBoardItems = React.useMemo(() => {
      return hookResult.fetchingResponse?.items?.filter((a) => {
        if (a.__typename === "UserPageBoardItem") {
          return a.user?.id !== currentWorkspace.user?.id //excluding current workspace's user
        }

        return true
      })
    }, [currentWorkspace.user?.id, hookResult.fetchingResponse?.items])

    const pagination = hookResult.fetchingResponse?.pagination
    const refetch = hookResult.refetch

    return useMemoAPI({
      ...hookResult,
      throttledSearch,
      newItems,
      pageBoardItems,
      refetch,
      pagination,
    })
  }
}
