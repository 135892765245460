import _ from "lodash"
import React from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { DocumentCollectionsProviderProps } from "./hooks/useDocumentCollectionsAPI"
import Dialog from "./components/Dialog/Dialog"
import Chips from "./components/Chips/Chips"
import DocumentCollectionsProvider from "./components/DocumentCollectionsProvider/DocumentCollectionsProvider"
import CollectionForm from "./components/CollectionForm/CollectionForm"
import AddNewPartner from "./components/Forms/AddNewPartner"

const DocumentsCollection = {
  CollectionForm,
  Chips,
  AddNewPartner,
  Default: DocumentCollectionsDefault,
  Provider: DocumentCollectionsProvider,
}

export default DocumentsCollection

function DocumentCollectionsDefault(
  props: DocumentCollectionsProviderProps & {
    render?: (props: {
      components: { chips?: React.ReactNode; dialog?: React.ReactNode }
    }) => React.ReactNode
  }
) {
  const c = useStyles()

  return (
    <DocumentCollectionsProvider {...props}>
      <div className={clsx(c.root, "document-collections")}>
        {(() => {
          const components = {
            dialog: <Dialog></Dialog>,
            chips: <Chips></Chips>,
          }

          if (typeof props.render === "function")
            return props.render({ components })

          return (
            <>
              {components.dialog}
              {components.chips}
            </>
          )
        })()}
      </div>
    </DocumentCollectionsProvider>
  )
}

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
    },
  }
})
