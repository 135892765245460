import React from "react"
import BasicEuropeanMoneyInput from "components/Input/components/EuropeanMoneyInput/EuropeanMoneyInput"
import useTextFieldProps from "../../../TextField/hooks/useTextFieldProps"

export default function EuropeanMoneyInput(
  props: React.ComponentProps<typeof BasicEuropeanMoneyInput>
) {
  const inputProps = useTextFieldProps(props)

  return <BasicEuropeanMoneyInput {...inputProps}></BasicEuropeanMoneyInput>
}
