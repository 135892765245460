import { ShopContext, ShopProvider } from "./ShopContext"
import { ArticleCard } from "./components/ArticleCard"
import { ArticlesGrid } from "./components/ArticlesGrid/ArticlesGrid"
import { PageCartButtons } from "./components/PageCartButtons/PageCartButtons"
import { CartItemQuantity } from "./components/CartItemQuantity/CartItemQuantity"
import { CardCartButtons } from "./components/CardCartButtons/CardCartButtons"

export const Shop = {
  Context: ShopContext,
  Provider: ShopProvider,
  CardCartButtons,
  PageCartButtons: PageCartButtons,
  CartItemQuantity,
  ArticleCard,
  ArticlesGrid: ArticlesGrid,
}
