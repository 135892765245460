import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import _ from "lodash"
import getShareableLink from "lib/getShareableLink"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { ApolloClient, useApolloClient, gql } from "@apollo/client";
import { ID } from "types/type"

export function useGetRoomShareableLink() {
  const { workspace } = useCurrentWorkspace()

  const userID = _.get(workspace, "user.id")
  const client = useApolloClient()

  return React.useCallback(
    (roomID) => {
      if (!roomID) return null
      return getRoomShareableLink({ roomID, sharerID: userID }, client)
    },
    [userID, client]
  )
}

function getRoomShareableLink(
  props: { roomID: ID; sharerID: ID },
  client: ApolloClient<{}>
) {
  const fragment =
    client.readFragment({
      id: `Room:${props.roomID}`,
      fragment: gql`
        fragment RoomWithSharableID on Room {
          shareableID
        }
      `,
    }) || {}

  const shareableID = fragment.shareableID

  if (!shareableID) return null

  const pathname = getShareableLink({
    type: "room",
    shareableID: shareableID + "",
    sharerID: props.sharerID,
  })

  const url = new URL(window.location.origin)

  url.pathname = pathname + ""

  return url.href
}
