import _ from "lodash"
import React from "react"
import { InputBase } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { fade } from "@material-ui/core/styles"
import IconButton from "components/IconButton/IconButton"

export default function FilledSearchField({
  onSubmit,
  inputProps,
  suggestionsComponent,
}) {
  const c = useStyles()
  const hasSubmission = _.isFunction(onSubmit)

  return (
    <div className={c.filledSearchInput}>
      <div className={c.input}>
        <InputBase
          placeholder="Search…"
          classes={{
            root: c.inputRoot,
            input: c.inputInput,
          }}
          inputProps={{ "aria-label": "search", type: "search", ...inputProps }}
        />
        {suggestionsComponent && (
          <div className={c.suggestions}>{suggestionsComponent}</div>
        )}
      </div>
      {hasSubmission && (
        <IconButton
          className={c.submission}
          iconName="search"
          type="submit"
          disabled={!inputProps.value}
        ></IconButton>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    filledSearchInput: {
      display: "flex",
      alignItems: "center",
    },
    input: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      display: "flex",
      alignItems: "center",
      border: `1px solid ${theme.palette.text.secondary}`,
      color: theme.palette.text.secondary,
      width: "auto",
    },

    suggestions: {
      minWidth: "120%",
      position: "absolute",
      bottom: -theme.spacing(0.5),
      transform: "translateY(100%)",
      right: 0,
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 7, 1, 1),
      transition: theme.transitions.create("width"),
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  }
})
