import { ID } from "types/type"
import cachedIDGetters from "lib/getCacheID/getCacheID"
import { ApolloClient, gql } from "@apollo/client";
import { CardImages } from "../hooks/useImagesAPI/useAddImagesAndThenCoverWhenUploadingFirstImage"

export function getCardImages(
  cardID: ID,
  client: ApolloClient<{}>
): CardImages {
  const v = client.readFragment({
    id: cachedIDGetters.KanbanCardImages({ cardID: cardID + "" }),
    fragment: gql`
      fragment GetKanbanCardImages on KanbanCardImages {
        list {
          id
        }
        cover {
          id
        }
      }
    `,
  }) as CardImages

  return v
}
