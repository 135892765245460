import { useApolloClient } from "@apollo/client";
import {
  CartFragment,
  ChangeCartItemQuantityMutation,
  useChangeCartItemQuantityMutation,
} from "lib/graphql/operations"
import React from "react"
import { updateCart } from "../lib/updateCart"
import { getUpdateCartQueryFn } from "../lib/getUpdateCartQueryFn"

export function useChangeQuantityApi({
  cartID,
  cart,
  workspaceID,
}: {
  cartID: string
  cart: CartFragment | null | undefined
  workspaceID: string
}) {
  const [changeCartItemQuantityMutation, { loading: changingQuantity }] =
    useChangeCartItemQuantityMutation()

  const client = useApolloClient()

  const changeQuantityApi = React.useMemo(() => {
    return {
      async action({
        articleID,
        quantity,
      }: {
        articleID: string
        quantity: number
      }) {
        if (!cartID) return

        const updated = await updateCart(
          {
            cart,
            articleID,
            workspaceID,
            updateItemFn: (item) => ({
              ...item,
              quantity,
            }),
          },
          client
        )

        if (!updated) return

        const result = await changeCartItemQuantityMutation({
          variables: { articleID, workspaceID, quantity, cartID },
          update: getUpdateCartQueryFn({
            workspaceID,
            cartID,
            getCart: (result) => {
              return result.data?.changeCartItemQuantity || null
            },
          }),
          optimisticResponse: {
            __typename: "Mutation",
            changeCartItemQuantity: updated,
          },
        })

        return result
      },
      // loading: changeOrderItemQuantityAPI.isLoading,
      loading: changingQuantity,
    }
  }, [
    cart,
    changeCartItemQuantityMutation,
    changingQuantity,
    workspaceID,
    cartID,
    client,
  ])
  return changeQuantityApi
}
