import React from "react"
import useSectionsRoutesAPI from "../hooks/useSectionsRoutesAPI"

const defaultSection = {
  path: "/",
  exact: false,
  icon: "home",
  name: "Start",
  component: null,
  id: "",
}

const SectionsRoutesContext = React.createContext(
  {} as ReturnType<typeof useSectionsRoutesAPI>
)

SectionsRoutesContext.displayName = "SectionsRoutesContext"

export default SectionsRoutesContext
