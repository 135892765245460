import _ from "lodash"
import React from "react"
import { useContext } from "react"
import { useRoomAPI } from "../hooks/useRoomAPI/useRoomAPI"

export type RoomContextAPI = ReturnType<typeof useRoomAPI>

export const RoomContext = React.createContext({} as RoomContextAPI)

RoomContext.displayName = "RoomContext"

export function useContextRoom() {
  return useContext(RoomContext)
}
