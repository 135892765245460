let activeWorkspaceID: string | undefined

export function getActiveWorkspaceID() {
  return activeWorkspaceID
}

export function setActiveWorkspaceID(workspaceID: string) {
  activeWorkspaceID = workspaceID
  window.activeWorkspaceID = activeWorkspaceID
}
