import { IconButton } from "@material-ui/core"
import { Icon } from "components/Icon/Icon"
import Shareable from "components/Shareable/Shareable"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import { ShareableType } from "types/type"
import { RoomPageBoardItemApiContext } from "../../../../contexts/RoomPageBoardItemContext"

export function CopyURL(props: { variant: "icon-button" | "menu-item" }) {
  const api = React.useContext(RoomPageBoardItemApiContext)

  const t = useI18n()
  const roomID = api.roomAPI?.room?.id

  if (!roomID) return null

  return (
    <Shareable.CopyLink shareableType={ShareableType.Room} shareableID={roomID}>
      {({ copyShareableLink }) => {
        if (props.variant === "icon-button")
          return (
            <IconButton onClick={copyShareableLink} size="small">
              <Icon name="link"></Icon>
            </IconButton>
          )

        return (
          <div onClick={copyShareableLink} style={{ width: "100%" }}>
            {t["copy-link"]}
          </div>
        )
      }}
    </Shareable.CopyLink>
  )
}
