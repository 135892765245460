import React from "react"
import moment from "moment"
import { Typography, makeStyles } from "@material-ui/core"
import { render } from "react-dom"
import MonthCalendar, {
  WeekProps,
  Month,
} from "components/MonthCalendar/MonthCalendar"
import arePropsEqual from "util/arePropsEqual"

export const MonthEntity = React.memo(
  MonthEntityComponent,
  arePropsEqual([(a) => a.month.index && a.month.year])
)

/** @deprecated */
export default MonthEntity

function MonthEntityComponent(props: {
  month: Month
  WeekProps?: WeekProps
  showOnlyDaysOfSelectedMonth?: boolean
  renderMonthHeader?: boolean
  showWeekHeader?: boolean
}) {
  const month = props.month

  const showOnlyDaysOfSelectedMonth = props.showOnlyDaysOfSelectedMonth ?? true
  const renderMonthHeader = props.renderMonthHeader ?? true

  const c = useStyles()

  return (
    <MonthCalendar.Provider month={month}>
      <React.Fragment>
        {renderMonthHeader ? (
          <MonthCalendar.Context.Consumer>
            {({ month }) => {
              return (
                <Typography variant="body1" style={{ padding: 16 }}>
                  {moment()
                    .year(month.year)
                    .month(month.index)
                    .format("MMMM YYYY")}
                </Typography>
              )
            }}
          </MonthCalendar.Context.Consumer>
        ) : null}
        {(props.showWeekHeader && null) || null}

        <MonthCalendar.Root
          WeekProps={{
            showDayOfWeekName: false,
            ...(props.WeekProps || {}),
            // style: { marginBottom: 16, ...(props.WeekProps?.style || {}) },
            renderDay(p) {
              if (
                showOnlyDaysOfSelectedMonth &&
                p.day.month() !== props.month.index
              )
                return null

              if (props.WeekProps?.renderDay)
                return props.WeekProps.renderDay(p)

              return p.defaultComponent
            },
          }}
        ></MonthCalendar.Root>
      </React.Fragment>
    </MonthCalendar.Provider>
  )
}

const useStyles = makeStyles(() => {
  return {
    weeksWrapper: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: 0,
      paddingBottom: 8,
    },
    weekName: { flex: 1, textAlign: "center" },
  }
})
