import React from "react"
import useComponentSize from "@rehooks/component-size"
import { PdfContext } from "../../contexts/PdfContext"
import { makeStyles } from "@material-ui/core"
import PdfPage from "../PdfPage/PdfPage"
import useDebouncedValue from "hooks/useDebouncedValue"
import clsx from "clsx"

export default function PdfBody(props: {
  className?: string
  style?: React.CSSProperties
}) {
  const ctx = React.useContext(PdfContext)

  const contentRef = React.useRef<HTMLDivElement>(null)

  const size = useComponentSize(contentRef)

  const width = useDebouncedValue(size.width, 1000)

  const c = useStyles()

  return (
    <div
      className={clsx("pdf-body", c.root, props.className)}
      style={{
        // transform: `scale(${ctx.scale}) rotate(${ctx.angle}deg)`,
        ...(props.style || {}),
        transform: `rotate(${ctx.angle}deg)`,
        transition: "all 300ms",
      }}
    >
      <div ref={contentRef} className="page-content">
        {(() => {
          if (!ctx.documentProxy?.numPages) return null

          return (
            <div key={width}>
              {Array.from(
                { length: ctx.documentProxy.numPages },
                (__, index) => {
                  return (
                    <PdfPage
                      index={index}
                      key={`page-${index}`}
                      className={c.page}
                      width={width}
                    ></PdfPage>
                  )
                }
              )}
            </div>
          )
        })()}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
      padding: theme.spacing(8),
    },
    page: {
      marginBottom: theme.spacing(2),
    },
  }
})
