import { ShopOrderFormBody } from "./components/ShopOrderFormBody/ShopOrderFormBody"
import {
  ShopOrderFormContext,
  ShopOrderFormProvider,
} from "./ShopOrderFormContext"

export type { FormType } from "./ShopOrderFormContext"

export const ShopOrderForm = {
  Provider: ShopOrderFormProvider,
  Context: ShopOrderFormContext,
  Body: ShopOrderFormBody,
}
