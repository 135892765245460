import React from "react"
import isTouchDevice from "lib/isTouchDevice"
import useMemoAPI from "hooks/useMemoAPI"

export default function useInputAutoFocus(props?: { enable: boolean }) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (!props?.enable) return
    if (isTouchDevice) return
    inputRef.current?.focus()
  }, [props?.enable])

  return useMemoAPI({ inputRef })
}
