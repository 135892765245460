import { makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

export type Positions =
  | "top-left"
  // | "top-center"
  // | "top-right"
  // | "bottom-left"
  | "bottom-center"
// | "bottom-right"

export default function Positioner(props: {
  position: Positions
  children: React.ReactChild
  className?: string
}) {
  const c = useStyles({})

  return (
    <div
      className={clsx("positioner", c.root, c[props.position], props.className)}
    >
      {props.children}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "fixed",
    },
    "bottom-center": {
      bottom: theme.spacing(2),
      left: "50%",
      transform: "translateX(-50%)",
    },
    "top-left": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  }
})
// const
