import { useMutation } from "@apollo/client";
import _ from "lodash"
import _f from "lodash/fp"
import { useCallback, useMemo } from "react"
import { EditMessageDocument } from "lib/graphql/operations"
import { useContextRoom } from "../../../contexts/room"
import useActiveResources from "hooks/useActiveResources"
import useGetMessage from "hooks/useGetMessage"

export default function useEditMessage() {
  const { room } = useContextRoom() || {}
  const { workspaceID } = useActiveResources()
  const getMessage = useGetMessage()

  const roomID = _f.get("id")(room)

  const [mutate, { loading }] = useMutation(EditMessageDocument)

  const editMessage = useCallback(
    ({ message: content, messageID, attachments }) => {
      if (!content && !attachments) return
      const message = getMessage({ messageID })

      return mutate({
        variables: _.pickBy(
          { content, roomID, workspaceID, messageID, attachments },
          (v) => typeof v !== "undefined"
        ),

        optimisticResponse: {
          __typename: "Mutation",
          editMessage: {
            ...message,
            loading: true,
            content: typeof content === "undefined" ? message.content : content,
            editedAt: new Date().toISOString(),
            __typename: "TextMessage",
          },
        },
      })
    },
    [mutate, roomID, workspaceID, getMessage]
  )

  const toReturn = useMemo(() => {
    return { editMessage, loading }
  }, [editMessage, loading])

  return toReturn
}
