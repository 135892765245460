import _ from "lodash"
import { makeStyles, Typography } from "@material-ui/core"
import React from "react"
import clsx from "clsx"

export default function Text({ value, className = "", style = {} }) {
  const c = useStyles()

  const array = (() => {
    if (typeof value !== "string") return [value]
    return (value || "")
      .split("\n")
      .map((a) => a.trim())
      .filter(Boolean)
  })()

  return (
    <>
      {array.map((a, index) => (
        <Typography
          variant="body1"
          key={index}
          color="textPrimary"
          className={clsx(c.root, className)}
          style={style}
        >
          {React.isValidElement(a) ? a : a ? a + "" : "-"}
        </Typography>
      ))}
    </>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      textAlign: "left",
    },
  }
})
