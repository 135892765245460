import { EnhancedPage } from "components/EnhancedPage/EnhancedPage"
import TwoPages from "components/TwoPages/TwoPages"
import UseEffect from "components/UseEffect/UseEffect"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { DocumentFragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import { EnhancedPageProps } from "../../../../../EnhancedPage/EnhancedPage"
import { SecondPage } from "../PageBoardItem/components/SecondPage/SecondPage"
import { DocumentsList } from "./DocumentsList"

export function AllDocumentsListPage(props: {
  onClose: () => void
  documents?: DocumentFragment[]
  fetchMore?: () => void
  style?: React.CSSProperties
  EnhancedPageProps?: Omit<EnhancedPageProps, "title" | "style" | "body">
  onOpenDocument?: (documentID: string) => void
}) {
  const t = useI18n()

  return (
    <DocumentsList.Provider
      documents={props.documents}
      fetchMore={props.fetchMore}
      ItemPaperProps={{ elevation: 0 }}
    >
      <DocumentsList.Context.Consumer>
        {(api) => {
          return (
            <>
              <TwoPages.Provider>
                <TwoPages.Context.Consumer>
                  {(twoPagesAPI) => {
                    return (
                      <UseEffect
                        effect={() => {
                          if (!api.activeID) return

                          twoPagesAPI.openSecondPage({
                            Page(args) {
                              const onClose = () => {
                                api.setActiveID(null)
                                args.onClose()
                              }

                              return (
                                <SecondPage
                                  onClose={onClose}
                                  key={api.activeID}
                                  getItemParams={{
                                    fetchItemProps: {
                                      type: PageBoardItemType.Document,
                                      params: {
                                        documentID: api.activeID || "",
                                      },
                                    },
                                  }}
                                ></SecondPage>
                              )

                              // return (
                              //   <DocumentFormPage
                              //     onClose={onClose}
                              //     DocumentFormApiInput={{
                              //       documentID: api.activeID || "",
                              //       summarized: true,
                              //       hidePartnerField: true,
                              //       onClickOpenButton() {
                              //         props.onOpenDocument?.(api.activeID || "")
                              //       },
                              //     }}
                              //   ></DocumentFormPage>
                              // )
                            },
                          })
                        }}
                        deps={[api.activeID]}
                      ></UseEffect>
                    )
                  }}
                </TwoPages.Context.Consumer>

                <TwoPages.Root
                  style={{ height: "100%", ...(props.style || {}) }}
                  mainPage={
                    <EnhancedPage
                      onClose={props.onClose}
                      title={t.allDocuments}
                      body={
                        <div style={{ maxHeight: "100%", overflow: "hidden" }}>
                          <DocumentsList.Root
                            style={{ maxHeight: "100%", overflow: "auto" }}
                          ></DocumentsList.Root>
                        </div>
                      }
                      {...(props.EnhancedPageProps || {})}
                    ></EnhancedPage>
                  }
                ></TwoPages.Root>
              </TwoPages.Provider>
            </>
          )
        }}
      </DocumentsList.Context.Consumer>
    </DocumentsList.Provider>
  )
}
