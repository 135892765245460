import { makeStyles } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import clsx from "clsx"
import { Button } from "components/Button/Button"
import LeftSnackbar from "components/LeftSnackbar/LeftSnackbar"
import { Subscriber } from "components/Observer"
import { PageComponentProps } from "components/Page/Page"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import I18nContext, { useI18n } from "contexts/i18nContext/i18nContext"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { SecondPage } from "../../../../SecondPage/SecondPage"
import { ShopOrderForm } from "../../../../ShopOrderForm/ShopOrderForm"
import { ShoppingArticlePage } from "../ShoppingArticlePage/ShoppingArticlePage"
import { CartBody } from "./CartBody"
import { CartPageApiContext } from "./CartPageContext"

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
      display: "flex",

      flexDirection: "column",
      "&>.body": {
        flex: 1,
        overflow: "auto",
      },
    },
    templateRoot: {
      // paddingTop: 8,
    },
  }
})
export function CartPageBody(props: PageComponentProps) {
  const c = useStyles()
  const t = useI18n()

  const api = React.useContext(CartPageApiContext)

  return (
    <ItemTemplate.Provider onClose={props.onClose}>
      <div className={c.root} ref={api.rootContainer.containerRef}>
        <ItemTemplate.Root
          className={clsx(c.templateRoot, "body")}
          EnhancedPageProps={{
            title: t.cart,
            body: <CartBody {...(api?.CartBodyProps || {})}></CartBody>,
            renderTitleLine(props) {
              return (
                <>
                  {props.titleComponent}
                  <CartPageApiContext.Consumer>
                    {(api) => {
                      if (api.renderSaveButtonInTheBottom) return null

                      return (
                        <ShopOrderForm.Context.Consumer>
                          {(formApi) => {
                            return (
                              <Button
                                variant="outlined"
                                onClick={formApi.form.submit}
                                disabled={!formApi.form.readyToSubmit}
                              >
                                {t.createOrder}
                              </Button>
                            )
                          }}
                        </ShopOrderForm.Context.Consumer>
                      )
                    }}
                  </CartPageApiContext.Consumer>
                </>
              )
            },
          }}
        ></ItemTemplate.Root>

        <LeftSnackbar open={api.shop.cartApi.createOrder.loading}>
          <I18nContext.Consumer>
            {(t) => {
              return <Alert severity="info">{t.orderIsBeingCreated}</Alert>
            }}
          </I18nContext.Consumer>
        </LeftSnackbar>
      </div>
      <ItemTemplate.Context.Consumer>
        {(templateApi) => {
          return (
            <Subscriber
              doNotUnsubscribeWhenSubscriptionsChange
              observable={api.onClickArticle$}
              subscription={(article) => {
                templateApi.twoPagesAPI.openFullPage(({ onClose }) => {
                  return (
                    <SecondPage
                      onClose={onClose}
                      getItemParams={{
                        fetchItemProps: {
                          type: PageBoardItemType.ShopArticle,
                          params: { articleID: article.id },
                        },
                      }}
                    >
                      {({ api }) => {
                        return (
                          <ShoppingArticlePage
                            getItemParams={{ item: api.item }}
                            onClose={onClose}
                          ></ShoppingArticlePage>
                        )
                      }}
                    </SecondPage>
                  )
                  // return (
                  // )
                })
              }}
            ></Subscriber>
          )
        }}
      </ItemTemplate.Context.Consumer>
    </ItemTemplate.Provider>
  )
}
