import _ from "lodash"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { useCallback, useContext } from "react"
import getter from "util/getter"
import DetailsHeader from "./components/DetailsHeader/DetailsHeader"
import DetailsTabs from "./components/DetailsTabs/index"
import DocumentsDashboardContext from "../../contexts/DocumentsDashboardContext"
import { ShareableType } from "types/type"
import Shareable from "components/Shareable/Shareable"

export default function DocumentDetails({
  className = "",
  smallHeader,
  statusColor,
  hideBackButton = false,
  goToRoom,
  onClose,
  headerMinHeight = undefined,
}) {
  const {
    onSaveEdition,
    selectedEntry,
    detailsTabsRoot,
    selectedEntryPartner,
    selectedEntryDocument,
    EntryStatus,
  } = useContext(DocumentsDashboardContext)

  const c = useStyles({ smallHeader, headerMinHeight })

  const a = useCallback(getter(selectedEntry), [selectedEntry])

  if (!a("id")) return null

  return (
    <div className={clsx("document-details", c.root, className)}>
      <DetailsTabs.Provider
        key={selectedEntry?.id}
        entry={selectedEntry}
        partner={selectedEntryPartner}
        document={selectedEntryDocument}
        hideBackButton={hideBackButton}
        goToRoom={goToRoom}
        statusColor={statusColor}
        onSaveEdition={onSaveEdition}
      >
        <DetailsHeader
          selectedEntry={selectedEntry}
          EntryStatus={EntryStatus}
          onClose={onClose}
          small={smallHeader}
          // small={smallHeader}
          className={c.header}
          renderTitle={({ defaultComponent }) => (
            <div className={c.title}>
              <DetailsTabs.GoToDocumentTabButton
                classes={{ icon: c.goToDocumentIcon }}
              >
                {defaultComponent}
              </DetailsTabs.GoToDocumentTabButton>
              <div className={c.buttons}>
                {selectedEntryDocument?.id && (
                  <Shareable.CopyLink
                    shareableType={ShareableType.Document}
                    shareableID={selectedEntryDocument?.id}
                  ></Shareable.CopyLink>
                )}
              </div>
            </div>
          )}
        ></DetailsHeader>
        <div className={c.tabs}>
          {detailsTabsRoot || <DetailsTabs.Root></DetailsTabs.Root>}
        </div>
      </DetailsTabs.Provider>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    editButtons: {
      // marginLeft: theme.spacing(2),
    },
    goToRoomButton: {
      position: "relative",
      top: 0.5,
    },
    buttons: {
      marginLeft: theme.spacing(2),
      display: "flex",
      alignItems: "center",
    },
    root: {
      display: "flex",
      flexDirection: "column",
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    goToDocumentIcon: {
      fontSize: ({ smallHeader }) =>
        smallHeader ? `19px !important` : "initial",
      position: "relative",
      top: ({ smallHeader }) => (smallHeader ? 1.5 : -1),
      opacity: 0.3,
    },

    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(2, 3),
      minHeight: ({ headerMinHeight }) =>
        typeof headerMinHeight === "number" ? headerMinHeight : "auto",
      "& *": {
        color: "white",
      },

      "& .right": {
        textAlign: "right",
      },
    },

    tabs: {
      display: "flex",
      "&, &>*": {
        flex: 1,
        overflow: "hidden",
      },
    },
  }
})
