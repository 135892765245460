import React from "react"
import { makeStyles } from "@material-ui/core"
import Modal, { ModalProps } from "components/Modal/Modal"
import {
  EnhancedPageProps,
  EnhancedPage,
} from "components/EnhancedPage/EnhancedPage"
import clsx from "clsx"
import useMemoAPI from "hooks/useMemoAPI"

export default function EnhancedPageModal(
  props: Omit<ModalProps, "children"> & { EnhancedPageProps: EnhancedPageProps }
) {
  const c = useEnhancedPageModal()
  const { EnhancedPageProps, ...ModalProps } = props

  const Props = useMemoAPI(ModalProps)

  return (
    <Modal {...Props} className={clsx(Props.className)}>
      <EnhancedPage
        {...EnhancedPageProps}
        onClose={Props.onClose}
        className={clsx(
          c.enhancedPage,
          c.fullHeight,
          props.EnhancedPageProps.className
        )}
      ></EnhancedPage>
    </Modal>
  )
}
const useEnhancedPageModal = makeStyles((theme) => {
  return {
    enhancedPage: { background: "white" },
    fullHeight: {
      height: "100%",
    },
  }
})
