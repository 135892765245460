import { LexicalEditorContext } from "../../contexts/LexicalEditorContext"
import useLexicalEditorAPI, {
  LexicalEditorAPI,
  LexicalEditorAPIInput,
} from "../../hooks/useLexicalEditorAPI"
import useMemoAPI from "hooks/useMemoAPI"
import _ from "lodash"
import React from "react"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import EnforceMarkdownPlugin from "../EnforceMarkdownPlugin/EnforceMarkdownPlugin"
import useInitialConfig from "../../hooks/useInitialConfig"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import useOnChange from "hooks/useOnChange"
import $setEditorValueDealingWithMarkdown from "components/LexicalEditor/lib/setEditorValueDealingWithMarkdown"
import EnforceWYSIWYGPlugin from "../EnforceWYSIWYGPlugin/EnforceWYSIWYGPlugin"
import Hook from "components/Hook/Hook"

export default function LexicalEditorProvider(
  props: LexicalEditorAPIInput & {
    children: React.ReactNode
  }
) {
  const autoFocus = (() => {
    if (typeof props.autoFocus === "undefined") return true
    return props.autoFocus
  })()
  const apiProps = useMemoAPI(_.omit(props, "children"))

  return (
    <LexicalComposer
      initialConfig={useInitialConfig({
        value: props.value,
        editable: props.editable,
        namespace: props.namespace,
        markdownMode: props.markdownMode,
      })}
    >
      <Hook hook={useLexicalEditorAPI} hookProps={apiProps}>
        {(api) => {
          return (
            <LexicalEditorContext.Provider value={api}>
              <>
                {props.children}
                {autoFocus && <AutoFocusPlugin />}
                <ListPlugin />
                <LinkPlugin />
                <OnChangePlugin
                  onChange={api.editorState.onChange}
                ></OnChangePlugin>
                {(() => {
                  if (!!api.markdownMode.isTrue)
                    return <EnforceMarkdownPlugin></EnforceMarkdownPlugin>

                  return <EnforceWYSIWYGPlugin></EnforceWYSIWYGPlugin>
                })()}
                {(() => {
                  if (typeof props.value === "undefined") return null

                  return (
                    <WhenExternalValueChangePlugin
                      value={props.value}
                      markdown={api.markdownMode.isTrue}
                    ></WhenExternalValueChangePlugin>
                  )
                })()}
              </>
            </LexicalEditorContext.Provider>
          )
        }}
      </Hook>
    </LexicalComposer>
  )
}

function WhenExternalValueChangePlugin(props: {
  value: LexicalEditorAPIInput["value"]
  markdown: LexicalEditorAPIInput["markdownMode"]
}) {
  const [editor] = useLexicalComposerContext()

  useOnChange({
    value: props.value,
    onChange(value) {
      editor.update(() => {
        if (typeof value === "undefined") return
        $setEditorValueDealingWithMarkdown({
          value,
          editor,
          markdownMode: props.markdown,
        })
      })
    },
  })

  return null
}
