import React from "react"
import { LexicalEditor } from "lexical"
import FormatButton, { FormatButtonProps } from "../FormatButton/FormatButton"
import { BlockType } from "../../types"
import $wrapSelection from "../../lib/$wrapSelection"

export default function ToggleHeadButton(props: {
  blockType: Extract<BlockType, "h1" | "h2">
  isActive: boolean
  editor: LexicalEditor
}) {
  const icons: {
    [i in typeof props.blockType]: FormatButtonProps["iconName"]
  } = {
    h1: "h1",
    h2: "h2",
  }

  return (
    <FormatButton
      isActive={props.isActive}
      iconName={icons[props.blockType]}
      onClick={() => {
        if (props.isActive) {
          return $wrapSelection("paragraph", props.editor)
        }

        return $wrapSelection(props.blockType, props.editor)
      }}
    ></FormatButton>
  )
}
