import _ from "lodash"
import React from "react"
import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import DeleteModal from "../DeleteImageModal/DeleteModal"

export default function DeleteCardModal() {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const modal = api.card.deleteModal

  const t = useI18n()

  return (
    <DeleteModal
      isOpen={modal.isOpen}
      onClose={modal.close}
      warningText={t["deleteTaskIsAnIrreversibleOperation"]}
      onDelete={() => {
        modal.delete()
        modal.close()
      }}
    ></DeleteModal>
  )
}
