import React from "react"
import { Observable, Subject } from "rxjs"

export function Subscriber<T extends any>(props: {
  observable: Observable<T>
  subscription?: (a: T) => void
  doNotUnsubscribeWhenSubscriptionsChange?: boolean
}) {
  React.useEffect(() => {
    const sub = props.observable.subscribe(props.subscription)

    return () => {
      sub.unsubscribe()
    }
  }, [
    props.doNotUnsubscribeWhenSubscriptionsChange ? null : props.subscription,
  ])

  return null
}

export function Observer<T extends any>(props: {
  value: T
  subject: Subject<T>
  memoize?: (a: T) => T
}) {
  React.useEffect(() => {
    props.subject.next(props.value)
  }, [props.memoize ? props.memoize(props.value) : props.value])

  return null
}
