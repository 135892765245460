import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import { RoomPageBoardItemFragment } from "lib/graphql/operations"
import React from "react"
import { usePageBoardItems } from "hooks/usePageBoardItems/usePageBoardItems"
import { PageBoardItemType } from "lib/graphql/types"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import useMemoAPI from "hooks/useMemoAPI"
import useOnChange from "hooks/useOnChange"

export default function useRoomSelection() {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const roomName = api.card.data?.roomName || ""

  const [isDirty, setIsDirty] = React.useState(false)

  const [selected, setSelected] =
    React.useState<RoomPageBoardItemFragment | null>(null)

  const [search, setSearch] = React.useState(roomName)

  const isSelected = React.useCallback(
    (item: NewPageBoardItemFragment) => {
      if (item.__typename === "RoomPageBoardItem") {
        if (!selected) {
          return item.room?.id === api.card.data?.roomID
        }

        if (selected?.id === item.id) {
          return true
        }

        return false
      }

      return false
    },
    [api.card.data?.roomID, selected?.id]
  )

  useOnChange({
    value: roomName,
    onChange() {
      setSearch(roomName)
    },
  })

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsDirty(() => true)
      setSearch(() => (typeof e === "string" ? e : e.target.value))
    },
    []
  )

  const reset = React.useCallback(() => {
    setSelected(() => null)
    setSearch(() => "")
    setIsDirty(() => false)
  }, [])

  const pageBoardItems = usePageBoardItems({
    search: isDirty ? search : "",
    types: React.useRef([PageBoardItemType.Room]).current,
  })

  const select = React.useCallback(
    (item: typeof selected) => {
      if (item?.__typename !== "RoomPageBoardItem") return

      setSelected(() => item)
      setSearch(() => item.room?.name || "")
      setIsDirty(() => false)
    },
    [setSelected]
  )

  const roomSelection = useMemoAPI({
    rooms: pageBoardItems,
    onChangeSearchText: onChange,
    searchText: search,
    isSelected,
    select: select,
    selected,
    reset,
  })

  return roomSelection
}
