import React from "react"
import Form from "../Form/Form"
import Input from "../Input/Input"
import { useI18n } from "contexts/i18nContext/i18nContext"
import _ from "lodash"

const GRID = { xs: 12 }

function BasicForm(props) {
  const t = useI18n()

  const submitButtonProps = React.useMemo(() => {
    return { children: t.save, ...props.SubmitButtonProps }
  }, [t.save, props.SubmitButtonProps])

  return (
    <Form
      Inputs={Input}
      grid={GRID}
      SubmitButtonProps={submitButtonProps}
      spacing={2}
      {..._.omit(props, "SubmitButtonProps")}
    ></Form>
  )
}

export default BasicForm
