import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { useCallback } from "react"
import useGlobalStyles from "styles/globalStyles"
import cloneElWithClass from "util/cloneElWithClass"
import ButtonBase from "../ButtonBase/ButtonBase"
import MetaCard from "../MetaCard/MetaCard"
import { StyledProps } from "../../types/type"

export type MetaCardWithAvatarProps = {
  overline?: React.ReactNode | null
  title?: React.ReactNode | null
  subtitle?: React.ReactNode | null
  className?: string | null
  onClick?: () => void
  link?: string | null
  avatar?: React.ReactNode | null
  styles?: { title: React.CSSProperties }
} & StyledProps

export function MetaCardWithAvatar(props: MetaCardWithAvatarProps) {
  const {
    overline,
    title,
    subtitle,
    className,
    onClick,
    link,
    avatar: propsAvatar,
  } = props

  const c = useStyles()

  const render = useCallback(
    ({ defaultComponent, components }) => {
      const avatar = <>{propsAvatar}</>

      return (
        <div className={c.wrapper}>
          {avatar && cloneElWithClass(c.avatar, avatar)}
          <div className="right">
            <div className={c.text}>{components.overline}</div>
            <div className={c.text} style={{ ...(props.styles?.title || {}) }}>
              {components.title}
            </div>
            <div className={c.text}>{components.subtitle}</div>
          </div>
        </div>
      )
    },
    [propsAvatar, props.styles]
  )

  const gs = useGlobalStyles()

  const metaCard = (
    <MetaCard
      overline={overline}
      className={clsx("meta-card-with-user-avatar", className)}
      title={<div className={clsx(gs.clipText, c.title)}>{title}</div>}
      subtitle={subtitle}
      style={props.style}
    >
      {render}
    </MetaCard>
  )

  if ((typeof onClick !== "function" && !link) || !metaCard) return metaCard

  return (
    <ButtonBase onClick={() => onClick?.()} link={link || undefined}>
      {metaCard}
    </ButtonBase>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      position: "relative",
      display: "flex",
      width: "100%",
      "&>.right": {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
    },
    text: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      // maxWidth: "70%",
    },
    avatar: {
      // position: "absolute",
      // right: 0,
      // top: theme.spacing(1),
    },

    title: {},
  }
})
