import React from "react"
import { FormCommonsContext } from "../PageBoard/components/IndexedItems/components/PageBoardItem/components/FormCommons/components/FormCommonsContext"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { StyledProps } from "types/type"

export function Freezer(
  props: {
    freezed?: boolean
    children: React.ReactNode
  } & StyledProps
) {
  const c = useStyles({ freezed: !!props.freezed })

  return (
    <div
      className={clsx("freezer-wrapper", c.root, props.className)}
      style={props.style}
    >
      {props.children}
      {props.freezed && <div className={c.freezer}></div>}
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
      cursor: (props: Style) => (props.freezed ? "not-allowed" : "default"),
    },
    freezer: {
      position: "absolute",
      inset: 0,
      background: "white",
      opacity: 0.55,
    },
  }
})
type Style = {
  freezed?: boolean
}
