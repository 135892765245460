import PaperList from "components/PaperList"
import React from "react"
import { Icon, makeStyles } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import formatMessageDate from "pages/util/formatMessageDate"
import Avatar from "components/Avatar/Avatar"
import { yellow } from "constants/colors"
import RootContainer from "components/RootContainer/RootContainer"

export default function NotificationsItem(props: {
  name: string
  timestamp: any
  title: string
  text: string
  count?: number | null
}) {
  const c = useStyles()

  const isSelected = false

  return (
    <PaperList.ListItem
      className={c.root}
      isSelected={isSelected}
      style={{ width: 400 }}
      classes={{ listItemLeft: c.listItemLeft, listItemRight: c.listItemRight }}
      left={
        <React.Fragment>
          <Avatar
            backgroundColor={yellow[2]}
            content={(() => {
              return <Icon>chat</Icon>
            })()}
            BadgeProps={{
              overlap: "circular",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              badgeContent: props.count || 0,
              max: 99,
            }}
          />
          <div style={{ marginLeft: 16 }}></div>
          <PaperList.CenteredListItemText
            primary={
              <Typography variant="caption" color="textSecondary">
                {props.title || ""}
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Typography variant="body1" color="textPrimary">
                  {props.text || ""}
                </Typography>
              </React.Fragment>
            }
          ></PaperList.CenteredListItemText>
        </React.Fragment>
      }
      onClick={
        () => {}
        // api.selected.set({
        //   itemID: item.itemID,
        //   type: item.type,
        //   name: item.name,
        // })
      }
    ></PaperList.ListItem>
  )
}

export const useStyles = makeStyles((theme) => {
  return {
    root: {},

    listItemLeft: {
      flex: "3 !important",
    },

    listItemRight: {
      flex: 1,
      textAlign: "end",
    },
  }
})
