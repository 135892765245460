import { AddNewContactFormRoot } from "./components/AddNewContactFormRoot"

import {
  AddNewContactFormProvider,
  AddNewContactFormContext,
} from "./components/ContextAndProvider"
import FormSaveButton from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/FormCommons/components/FormSaveButton"
import React from "react"

export type { AddNewContactFormApiInput } from "./components/ContextAndProvider"

export const AddNewContactForm = {
  Provider: AddNewContactFormProvider,
  Context: AddNewContactFormContext,
  Root: AddNewContactFormRoot,
  SaveButtons: function SaveButtons() {
    const form = React.useContext(AddNewContactFormContext).form
    return (
      <>
        <FormSaveButton form={form} resetOnSubmit></FormSaveButton>
      </>
    )
  },
}
