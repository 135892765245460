import _ from "lodash"
import PaperFieldset from "./components/PaperFieldset/PaperFieldset"
import FormRoot from "./components/FormRoot/FormRoot"

const DocumentFormsFieldsGroups = {
  Root: FormRoot,
  Fieldset: PaperFieldset,
}

export default DocumentFormsFieldsGroups
