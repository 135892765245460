import { NetworkStatus } from "@apollo/client"
import { isQueryLoaded } from "lib/apolloNetworkUtils/apolloNetworkUtils"
import { useEffect, useRef, useState } from "react"

export default function useQueryWasFetched(props: {
  networkStatus: NetworkStatus
}) {
  const networkStatus = props.networkStatus

  const [queryFetched, setQueryFetched] = useState(false)

  const prevNetworkStatus = usePreviousValue({ currentValue: networkStatus })

  const prev = { networkStatus: prevNetworkStatus }
  const current = { networkStatus }

  const value = isQueryLoaded(prev, current, { from_cache: false })

  useEffect(() => {
    if (!value) return
    setQueryFetched(true)
  }, [value])

  return queryFetched
}

function usePreviousValue({ currentValue }) {
  const [previous, setPrevious] = useState()

  const currentValueCopy = useRef(currentValue)

  useEffect(() => {
    if (currentValue === currentValueCopy.current) return

    const prevValue = currentValueCopy.current
    currentValueCopy.current = currentValue

    setPrevious(prevValue)
  }, [currentValue])

  return previous
}
