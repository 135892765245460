import React from "react"

/**
 * contextAndProviderFactory is a Higher Order Function (HOF) that generates
 * a React Context and a corresponding Provider using a provided hook. It's intended
 * to be used for creating React Context providers that need to share the result
 * of a hook across a React component tree.
 *
 * @template Hook A function type that represents a React hook, which is a function
 * that can accept any arguments and returns any type.
 *
 * @param {Object} props The properties to configure the Context and Provider.
 * 
 * @param {Hook} props.hookApi The React hook function that will be used as the
 * basis for the Context value.
 * @param {string} [props.contextName] Optional. The name for the Context object
 * which will be visible in React Developer Tools. If no name is provided, the name
 * of the hook function will be used instead, with any leading 'use' stripped.
 *
 * @returns {Object} Returns an object containing the created Context object,
 * a custom hook `useAPI` to use the Context, and a Provider component.
 *
 * @example
 * const { Context, useAPI, Provider } = contextAndProviderFactory({ hookApi: useCustomHook, contextName: "CustomContext" });
 * 
 * @additionalInfo
 * This function is essentially a tool for creating a context-provider pair based on a given 
 * hook. It's intended for scenarios where you want to make the result of a hook available 
 * throughout your component tree via context.

 * By passing a hook into contextAndProviderFactory, you can create a Context and a Provider 
 * that uses this hook. The hook's return value will be shared via the created Context. 

 * You can optionally provide a custom name for the context, which is useful for identifying the context in React DevTools.

 * The function returns an object containing the created Context, a useAPI hook that consumes the Context, and a Provider component. 
   The Provider component uses the hook to determine its value, and will re-render its children whenever this value changes 
   (as per the standard behavior of React context providers).
 */

export default function contextAndProviderFactory<
  Hook extends (input: any) => any | (() => any)
>(props: { hookApi: Hook; contextName?: string }) {
  type Return = ReturnType<Hook>
  type Props = Parameters<Hook>[0]

  const ApiContext = React.createContext({} as Return)

  const contextName =
    props.contextName || props.hookApi.name.replace(/^use/, "") + "Context"

  ApiContext.displayName = contextName

  type ProviderProps<Input extends any> = Input extends undefined
    ? { children: React.ReactNode }
    : { children: React.ReactNode } & Input

  const Provider: React.FC<ProviderProps<Props>> = function Provider(
    ProviderProps
  ) {
    const { children, ...hookProps } = ProviderProps

    const value = props.hookApi(hookProps)

    return (
      <ApiContext.Provider value={value}>
        {ProviderProps.children}
      </ApiContext.Provider>
    )
  }

  Provider.displayName = contextName.replace("Context", "Provider")

  return {
    Context: ApiContext,
    useAPI: () => React.useContext(ApiContext),
    Provider,
    Consumer: ApiContext.Consumer,
  }
}
