import useMemoAPI from "hooks/useMemoAPI"
import useVariousImagesWithAuth from "./useVariousImagesWithAuth"

export default function useImageWithAuth(props: {
  src: string
  lazyFetching: boolean
}) {
  const { src, lazyFetching = false } = props

  const { isImageFetching, getError, fetchAllImages, getImageObjectURL } =
    useVariousImagesWithAuth({
      sources: [src],
      lazyFetching,
    })

  return useMemoAPI({
    objectURL: getImageObjectURL(src),
    fetchImage: fetchAllImages,
    isFetching: isImageFetching(src),
    error: getError(src),
  })
}
