import { makeStyles, useTheme } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import BasicIndentation from "components/Indentation/Indentation"

export type IndentationProps = React.ComponentProps<typeof BasicIndentation>

export default function Indentation(props: IndentationProps) {
  const theme = useTheme()
  const indentation =
    typeof props.indentation === "number" ? props.indentation : theme.spacing(6)

  const c = useIndentationStyles()

  return (
    <BasicIndentation
      indentation={indentation}
      {...props}
      className={clsx(c.root, props.className)}
    ></BasicIndentation>
  )
}
const useIndentationStyles = makeStyles((theme) => {
  return {
    root: {
      paddingRight: theme.spacing(2),
    },
  }
})
