import { useEffect, useState, useCallback, useRef, useContext } from "react"
// import ZoomClientContext from "../../../context/ZoomClientContext"
import { VideoClient } from "@zoom/videosdk"
// import { ZoomClient } from '../../../index-types';

export function useAudioLevel(zoomClient: typeof VideoClient) {
  const [level, setLevel] = useState(0)
  const onAudioLevelChange = useCallback(({ level }: any) => {
    setLevel(level)
  }, [])
  useEffect(() => {
    zoomClient.on("current-audio-level-change", onAudioLevelChange)
    return () => {
      zoomClient.off("current-audio-level-change", onAudioLevelChange)
    }
  }, [zoomClient, onAudioLevelChange])
  return level
}
