import { Typography } from "@material-ui/core"
import { PageIndentation } from "components/Page/Page"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import AddMembersInputPageContext from "../../contexts/AddMembersInputPageContext"
import UserListItem from "../../../../../UserListItem/UserListItem"

export default function Body() {
  const api = React.useContext(AddMembersInputPageContext)
  const autoComplete = api.autoCompleteSuggestions
  const selectedUsers = api.selectUsersAPI.selectedUsers

  const t = useI18n()

  if (!autoComplete.isVisible) {
    if (!selectedUsers?.length) return null

    return (
      <div className="selected-users">
        <PageIndentation left={null}>
          <Typography
            color="textSecondary"
            variant="overline"
            style={{
              fontSize: "0.725rem",
              lineHeight: 1,
              marginBottom: 4,
            }}
          >
            {t["added-members"]}
          </Typography>
        </PageIndentation>
        <div className="list" style={{ marginTop: 8 }}>
          {selectedUsers.map((user) => {
            const removeUser = api.selectUsersAPI.removeUser.bind(
              undefined,
              user
            )

            return (
              <UserListItem
                key={user.id}
                user={user}
                ListItemWithAvatarProps={{
                  onDelete: removeUser,
                  IndentationProps: { leftPosition: "center" },
                }}
              ></UserListItem>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="body">
      {autoComplete.options?.map((option, index) => {
        const user = option.metadata

        return (
          <React.Fragment key={user?.id || index}>
            {(() => {
              if (!user) return null

              const removeUser = api.selectUsersAPI.removeUser.bind(
                undefined,
                user
              )

              return (
                <UserListItem
                  user={user}
                  ListItemWithAvatarProps={{
                    onClick: () => api.selectOption(option),
                    onDelete: (() => {
                      const selectedUsersIDs = selectedUsers?.map((a) => a.id)
                      if (!selectedUsersIDs.includes(user?.id)) return
                      return removeUser
                    })(),
                  }}
                ></UserListItem>
              )
            })()}
          </React.Fragment>
        )
      })}
    </div>
  )
}
