import React from "react"
import AdapterLink from "../AdapterLink/AdapterLink"
import { ButtonBase as MuiButtonBase } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"

function ButtonBase({
  link = "",
  children = null,
  className = "",
  onClick,
}: {
  link?: string
  children?: React.ReactNode
  className?: string
  onClick?: () => void
}) {
  const c = useStyles({})

  return (
    <MuiButtonBase
      className={clsx(c.buttonBase, className)}
      {...(link
        ? {
            to: link,
            component: AdapterLink,
          }
        : {})}
      onClick={onClick}
      focusRipple
    >
      {children}
    </MuiButtonBase>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    buttonBase: {
      display: "block",
      textAlign: "left",
      "&>*": {
        height: "100%",
      },
    },
  }
})

export default ButtonBase
