import { useContextRoom } from "components/Room/contexts/room"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import _ from "lodash"
import _f from "lodash/fp"

export function useLastAuthorMessage() {
  const { room } = useContextRoom()
  const { workspace: w } = useCurrentWorkspace()

  const lastMessage = _f.flow(
    _f.get("messages"),
    _f.findLast((m) => {
      return _.get(m, "author.id") === _.get(w, "user.id")
    })
  )(room)

  return lastMessage
}
