import _ from "lodash"
import React from "react"
import Workspaces from "components/Workspaces/Workspaces"

export default function useGetRoomWorkspacesIDs() {
  const { workspaces } = React.useContext(Workspaces.Context)

  const workspacesIDsByRoomID = React.useMemo(
    () =>
      workspaces.reduce((acc, w) => {
        ;(w.roomsIDs || [])?.forEach((roomID) => {
          acc[roomID] = _.uniq([...(acc[roomID] || []), w.id])
        })

        return acc
      }, {} as Record<string, string[]>),
    [workspaces]
  )

  const getRoomWorkspacesIDs = React.useCallback(
    function getRoomWorkspacesIDs(props: { roomID: string }) {
      return workspacesIDsByRoomID[props.roomID]
    },
    [workspacesIDsByRoomID]
  )

  return getRoomWorkspacesIDs
}
