import uniqid from "uniqid"
import React from "react"
import useOnChange from "hooks/useOnChange"
import KeyListenersContext from "../contexts/KeyListenersContext"
import { KeyListener } from "./useKeyListenersAPI"

export default function useKeyListener(
  keyListener: KeyListener | undefined | null,
  options: { enabled: boolean }
) {
  const id = React.useRef<string>(uniqid()).current

  const listeners = React.useContext(KeyListenersContext).listeners

  React.useEffect(() => {
    if (!keyListener) return

    return () => {
      listeners.remove(id) //the listener should be removed when the component unmounts
    }
  }, [])

  React.useEffect(() => {
    if (!keyListener) return

    if (options.enabled) {
      return listeners.push({ id, ...keyListener })
    } else {
      listeners.remove(id)
    }
  }, [options.enabled])

  useOnChange({
    value: keyListener,
    onChange() {
      if (!keyListener) return
      listeners.update(id, (a) => ({ ...a, ...keyListener }))
    },
  })
}
