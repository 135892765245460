import { ColumnOrderingFragment } from "lib/graphql/operations"
import { DataProxy } from "apollo-cache/lib/types/DataProxy"
import { readQuery } from "lib/updateQuery/updateQuery"

export function createColumnOrdering(
  {
    workspaceID,
    roomID,
    columnID,
    indexPosition,
  }: {
    workspaceID: string
    roomID: string
    columnID: string
    indexPosition: number | "last"
  },
  client: DataProxy
) {
  const a = readQuery(
    { queryName: "decoupledKanbanBoard", variables: { roomID, workspaceID } },
    client
  )

  const columnOrdering = a?.decoupledKanbanBoard
    ?.columnOrdering as ColumnOrderingFragment

  if (!columnOrdering) return undefined

  const columnsIDs = (columnOrdering.orderedColumnsIDs || []).filter(
    (a) => a !== columnID
  )

  const index = indexPosition === "last" ? columnsIDs.length : indexPosition

  const updated: ColumnOrderingFragment = {
    ...columnOrdering,
    __typename: "ColumnOrdering",
    orderedColumnsIDs: [
      ...columnsIDs.slice(0, index),
      columnID,
      ...columnsIDs.slice(index),
    ],
  }

  return updated
}
