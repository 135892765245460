import AssetsCacheUpdate from "components/AssetsCacheUpdate/AssetsCacheUpdate"
import { Workbox } from "workbox-window"

export function register() {
  if ("serviceWorker" in navigator) {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js?env=${process.env.NODE_ENV}`
    const wb = new Workbox(swUrl)

    wb.addEventListener("waiting", () => {
      AssetsCacheUpdate.update$.next()
    })

    AssetsCacheUpdate.accepted$.subscribe(() => {
      wb.messageSkipWaiting()
    })

    wb.register()
  }

  return Promise.resolve()
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister()
    })
  }
}

export function subscribeToPushNotification({
  pushNotificationPubKey,
}: {
  pushNotificationPubKey: string
}) {
  const applicationServerKey = urlB64ToUint8Array(pushNotificationPubKey)

  if (!navigator?.serviceWorker?.ready) return

  return new Promise<PushSubscription>((resolve) => {
    // serviceWorkerRegistration$.subscribe(async (registration) => {
    navigator.serviceWorker.ready.then(async (registration) => {
      if (!registration) return

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey,
      })

      resolve(subscription)
    })
  })
}

export function getPushNotificationSubscription() {
  return new Promise<PushSubscription | null>((resolve) => {
    // serviceWorkerRegistration$.subscribe(async (registration) => {

    navigator.serviceWorker.ready.then(async (registration) => {
      if (!registration) return

      const subscription = await registration.pushManager.getSubscription()

      resolve(subscription)
    })
  })
}

function urlB64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }

  return outputArray
}
