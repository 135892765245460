import { useApolloClient } from "@apollo/client";
import React from "react"
import { useActiveRoomID } from "contexts/activeRoom"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import { TrackEnvironmentVariablesMutationVariables } from "lib/graphql/operations"
import AppVisibility from "components/AppVisibility/AppVisibility"

export default function useEnvironmentVariables() {
  const { workspaceID } = useActiveResources()
  let { activeRoomID: roomID = null } = useActiveRoomID()

  const { appIsVisible } = React.useContext(AppVisibility.Context)

  const env: TrackEnvironmentVariablesMutationVariables["environmentVariables"] =
    {
      appIsVisible,
      roomID,
      workspaceID,
    }

  return useMemoAPI(env)
}
