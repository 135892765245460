import React from "react"
import clsx from "clsx"
import RootContainerProvider, {
  RootContainerProviderChildren,
} from "../RootContainerProvider/RootContainerProvider"

export default function RootContainerDefault(props: {
  children: RootContainerProviderChildren
  style?: React.CSSProperties
  className: string
}) {
  const { children, className = "" } = props

  return (
    <RootContainerProvider>
      {(providerValue) => {
        return React.createElement("div", {
          ref: providerValue.containerRef,
          className: clsx("root-container-default", className),
          style: props.style,
          children:
            typeof children === "function" ? children(providerValue) : children,
        })
      }}
    </RootContainerProvider>
  )
}
