import _ from "lodash"
import { makeStyles } from "@material-ui/core"
import React from "react"
import DetailsTabsContext from "../../contexts/DetailsTabsContext"
import Partner from "components/Partner/index"
import Memo from "components/Memo/Memo"

export default function PartnerTab() {
  const c = useStyles({})

  return (
    <DetailsTabsContext.Consumer>
      {({ partner, partnerIsLoading }) => {
        return (
          <Memo
            deps={[partner?.id, partnerIsLoading]}
            factory={() => ({ partner, isLoading: partnerIsLoading })}
          >
            {(value) => {
              return (
                <Partner.Context.Provider value={value}>
                  <Partner.Form hideDocumentsButton></Partner.Form>
                </Partner.Context.Provider>
              )
            }}
          </Memo>
        )
      }}
    </DetailsTabsContext.Consumer>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    padding: { padding: theme.spacing(3, 2) },
  }
})
