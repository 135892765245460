import React from "react"

export default function UseMemo<T>(props: {
  factory: () => T
  children: (value: T) => React.ReactNode
  deps?: React.DependencyList | undefined
}) {
  const memoized = React.useMemo(props.factory, props.deps || [])
  return <>{props.children(memoized)}</>
}
