import { copyToClipboard as copyToClipBoard } from "util/copyToClipboard"
import { useI18n } from "contexts/i18nContext/i18nContext"
import KanbanContext from "components/Kanban/contexts/KanbanContext"
import React, { MouseEventHandler } from "react"
import {
  Icon,
  IconButton,
  IconButtonProps,
  makeStyles,
  Snackbar,
} from "@material-ui/core"

import clsx from "clsx"
import HighestZIndexLayer, {
  HIGHEST_POSSIBLE_Z_INDEX,
} from "components/HighestZIndexLayer/HighestZIndexLayer"
import { ID, ShareableType } from "types/type"
import useGetShareableLink from "../../hooks/useGetShareableLink"

export interface CopyShareableLinkAPI {
  copyShareableLink: () => void
}

export default React.forwardRef(CopyShareableLink)

function CopyShareableLink(
  props: {
    className?: string
    shareableType: ShareableType
    shareableID: ID
    style?: React.CSSProperties
    pathname?: string
    onClick?: MouseEventHandler<HTMLElement>
    IconButtonProps?: IconButtonProps
    children?:
      | React.ReactNode
      | ((api: CopyShareableLinkAPI) => React.ReactNode)
  },
  ref: React.Ref<CopyShareableLinkAPI>
) {
  const [open, setOpen] = React.useState<boolean>(false)
  const getShareableLink = useGetShareableLink()

  // const ref

  const copyShareableLink = React.useCallback(() => {
    const link = getShareableLink({
      shareableType: props.shareableType,
      shareableID: props.shareableID,
      pathname: props.pathname,
    })

    link && copyToClipBoard(link)

    setOpen(true)

    setTimeout(() => {
      setOpen(false)
    }, 2000)
  }, [getShareableLink, props.shareableType, props.shareableID, props.pathname])

  React.useImperativeHandle(ref, () => ({ copyShareableLink }), [
    copyShareableLink,
  ])

  const onClick = React.useCallback(
    (e) => {
      copyShareableLink()
      props.onClick?.call(null, e)
    },
    [props.onClick, copyShareableLink]
  )
  const c = useStyles()

  const t = useI18n()

  return (
    <>
      {(React.isValidElement(props.children) && props.children) ||
        (typeof props.children === "function" &&
          props.children({ copyShareableLink })) || (
          <IconButton
            size="small"
            onClick={onClick}
            style={props.style}
            className={clsx("copy-link-button", props.className)}
            {...props.IconButtonProps}
          >
            <Icon>link</Icon>
          </IconButton>
        )}
      <HighestZIndexLayer.Portal>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          className={clsx("left-snackbar")}
          open={open}
          message={t.copied}
        ></Snackbar>
      </HighestZIndexLayer.Portal>
    </>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    popper: {
      zIndex: HIGHEST_POSSIBLE_Z_INDEX,
    },
  }
})
