import _ from "lodash"
import React from "react"
import useActiveResources from "hooks/useActiveResources"
import { PaginatedDocumentsDocument } from "lib/graphql/operations"
import { useCallback, useMemo } from "react"
import { Nullable, PaginatedDocuments } from "types/type"
// import { WatchQueryFetchPolicy } from "apollo-client"
import { WatchQueryFetchPolicy, useQuery } from "@apollo/client"
import useMemoAPI from "hooks/useMemoAPI"

export default function useDeprecatedPaginatedDocuments(props: {
  offset?: number
  limit?: number
  collectionID: Nullable<string>
  relatedCollectionsIDs?: Nullable<Array<string>>
  fetchPolicy?: WatchQueryFetchPolicy
  activeDocumentID?: Nullable<string>
  memoizeActiveDocumentID?: boolean
}) {
  const {
    offset = 0,
    collectionID = undefined,
    relatedCollectionsIDs = undefined,
    fetchPolicy = "cache-and-network",
    activeDocumentID,
    memoizeActiveDocumentID = true,
  } = props

  const { workspaceID } = useActiveResources()

  const collectionsIDs = React.useMemo(() => {
    const hasRelatedIDs = _.get(relatedCollectionsIDs, "length")
    if (!hasRelatedIDs) return [collectionID]
    return [collectionID, ...(relatedCollectionsIDs || [])]
  }, [collectionID, JSON.stringify(relatedCollectionsIDs)])?.filter(
    (a): a is string => !!a
  )

  const limit = props.limit || 40

  const vars = {
    offset: offset || 0,
    limit,
    collectionsIDs,
    workspaceID,
    activeDocumentID,
  }

  const deps = (() => {
    if (memoizeActiveDocumentID)
      return [
        JSON.stringify(_.omit(vars, "activeDocumentID")),
        !!activeDocumentID,
      ]

    return [JSON.stringify(vars)]
  })()

  const variables = useMemo(() => vars, deps)

  let { data, loading, fetchMore } = useQuery<PaginatedDocuments.Data>(
    PaginatedDocumentsDocument,
    {
      fetchPolicy,
      variables,
    }
  )

  const total = data?.paginatedDocuments.list?.length

  const retrieveMore = useCallback(
    ({ offset = total, limit = 30 } = {}) => {
      const reachedLimit = total === 0

      if (reachedLimit) return

      requestIdleCallback(() => {
        fetchMore({
          variables: { offset, limit },
          updateQuery({ paginatedDocuments }, { fetchMoreResult }) {
            const list = _.uniqBy(
              [
                ..._.get(paginatedDocuments, "list", []),
                ..._.get(fetchMoreResult, "paginatedDocuments.list", []),
              ],
              "document.id"
            )

            return {
              paginatedDocuments: {
                ...paginatedDocuments,
                list,
              },
            }
          },
        })
      })
    },
    [fetchMore, total]
  )

  return useMemoAPI({
    fetchMore: retrieveMore,
    queryData: data?.paginatedDocuments,
    dataIsLoaded: !!data,
    isLoading: loading,
    total: data?.paginatedDocuments.list?.length,
  })
}
