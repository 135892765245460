import React from "react"
import { KanbanActivity_KanbanAddCardActivity_Fragment } from "lib/graphql/operations"
import { useI18n } from "contexts/i18nContext/i18nContext"
import ActivitiesLines from "../ActivitiesLines/ActivitiesLines"

export default function AddCardActivity(props: {
  activity: KanbanActivity_KanbanAddCardActivity_Fragment
}) {
  const t = useI18n()

  return (
    <ActivitiesLines
      action={t.createdThisTask}
      icon="subtitles"
      dateTime={props.activity.when}
      user={props.activity.user}
    ></ActivitiesLines>
  )
}
