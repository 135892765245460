import React from "react"
import { Button, makeStyles } from "@material-ui/core"
import ReactKanban from "@asseinfo/react-kanban"
import ListCard from "../ListCard/ListCard"
import KanbanContext from "../../contexts/KanbanContext"
import clsx from "clsx"
import Header from "../Header/Header"
import { KanbanCardFragment } from "lib/graphql/operations"
import { Icon } from "components/Icon/Icon"

export type BoardProps = {
  className?: string
  renderCard?: (props: { defaultComponent: React.ReactNode }) => React.ReactNode
}

export default function Board(props: BoardProps) {
  const context = React.useContext(KanbanContext)
  const c = useStyles({})

  return (
    <div className={clsx("kanban-board", props.className, c.root)}>
      {
        <ReactKanban
          onCardDragEnd={context.onCardDragEnd}
          onColumnDragEnd={context.onColumnDragEnd}
          allowAddColumn={true}
          renderColumnAdder={() => {
            return (
              <Button
                variant="outlined"
                className={c.button}
                size="small"
                onClick={() => context.addColumn?.()}
              >
                <Icon name="add"></Icon>
                column
              </Button>
            )
          }}
          renderColumnHeader={(column) => {
            return (
              <div style={{}}>
                <Header column={column} />
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "100%", margin: "10px 0" }}
                  onClick={() => context.addCard({ columnID: column.id })}
                >
                  +
                </Button>
              </div>
            )
          }}
          renderCard={(card: KanbanCardFragment) => {
            const defaultComponent = <ListCard card={card}></ListCard>
            if (props.renderCard) return props.renderCard({ defaultComponent })
            return defaultComponent
          }}
        >
          {context.board}
        </ReactKanban>
      }
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      overflow: "hidden",
      height: "100%",

      "&>*": {
        width: "100%",
        overflow: "auto",
        // userSelect: "none",
        overflowY: "auto !important",
        height: "100%",

        "&>*": {
          // height: "100%",

          "& .react-kanban-column": {
            // height: "100% !important",
            // overflow: "hidden",
            display: "inline-flex !important",
            // maxHeight: "500px !important",
            flexDirection: "column",
            // padding: 0,
            "&>[data-rbd-drag-handle-context-id='1']": {
              // padding: "0 15px",
            },
            "&>[data-rbd-droppable-context-id='1']": {
              // background: "red",
              // padding: "0 15px",
              // overflow: "auto !important",
              // flex: 1,
              // overflow: "auto",
            },
          },
        },
        // overflowY: "auto !important",
      },
    },

    button: { marginTop: 10, width: 250, minWidth: 250, marginRight: 12 },
    kanbanBoard: {
      // flex: 1,
      // overflowY: 'scroll'
    },
  }
})
