import { getDefaultKeyBinding, KeyBindingUtil } from "draft-js"
import { useCallback } from "react"
import { useFooterContext } from "../../contexts/useFooterContext"
import { useEditLastMessage } from "./useEditLastMessage"

export function useKeyBindingFn({ inputRef, onSubmit }) {
  const { discardMessageSelection } = useFooterContext()

  const editLastMessage = useEditLastMessage()

  const keyBinding = useCallback(
    (e) => {
      const action = {
        ArrowUp() {
          const isEmpty = inputRef.current.isEmpty
          if (!isEmpty) return
          editLastMessage()
          return "handled"
        },
        Enter() {
          const hasCommandModifier = KeyBindingUtil.hasCommandModifier(e)

          if (e.shiftKey || e.altKey || hasCommandModifier) {
            // const value = inputRef.current.richText
            // value && onSubmit(value)
            return "not-handled"
          }

          const value = inputRef.current.richText
          value && onSubmit(value)
          // onSubmit()
          return "handled"
        },
      }[e.key]

      if (typeof action !== "function") return "not-handled"

      return action()
    },
    [editLastMessage, discardMessageSelection, onSubmit]
  )

  return useCallback(
    (e) => {
      const value = keyBinding(e) //improve
      if (value === "handled") return
      return getDefaultKeyBinding(e)
    },
    [keyBinding]
  )
}
