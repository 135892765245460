import React from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import arePropsEqual from "util/arePropsEqual"
import { ListEntryProps } from "../../types"
import { NewPageBoardItem_KanbanCardCommentPageBoardItem_Fragment } from "lib/graphql/operations"
import KanbanCardItem, {
  KanbanCardItemProps,
} from "../KanbanCardListItem/KanbanCardItem"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { Icon } from "components/Icon/Icon"
import SelfContainedBall from "components/SelfContainedBall/SelfContainedBall"
import { purple, red } from "constants/colors"
import MessageChain from "../MessageChain/MessageChain"
import extractText from "components/LexicalEditor/lib/extractText"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import { MessageLine } from "../MessageLine/MessageLine"

export default function KanbanCardCommentListItem(props: ListEntryProps) {
  if (props.entry?.__typename !== "KanbanCardCommentPageBoardItem") return null

  return (
    <MemoizedItem
      entry={props.entry}
      className={props.className}
      style={props.style}
    />
  )
}

const MemoizedItem = React.memo(
  Item,
  arePropsEqual([
    // (a) => a.entry?.id,
    (a) => a.entry?.lastUpdate,
    (a) => a.entry?.name,
    (a) => a.entry?.additionalText,
    // (a) => a.entry?.comment?.title,
    // (a) => a.entry?.card?.description,
  ])
)

const badge = (
  <SelfContainedBall>
    <div
      style={{
        height: 19,
        width: 19,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon name="message" style={{ fontSize: 14 }}></Icon>
    </div>
  </SelfContainedBall>
)

function Item(props: {
  entry: NewPageBoardItem_KanbanCardCommentPageBoardItem_Fragment
  className?: string
  style?: React.CSSProperties
}) {
  const t = useI18n()

  const title = props.entry?.cardSummary?.title || t["taskWithoutTitle"]

  // const c = useStyles()
  const text = props.entry.comment?.text || props.entry.additionalText || ""

  // const renderAvatar = React.useCallback<
  //   Exclude<KanbanCardItemProps["renderAvatar"], undefined>
  // >(
  //   (p) => {
  //     return (
  //       <div className={clsx(c.avatarWithBadgeContainer)}>
  //         {p.defaultComponent}
  //         {badge}
  //       </div>
  //     )
  //   },
  //   [c.avatarWithBadgeContainer]
  // )
  const renderText = React.useCallback<
    Exclude<KanbanCardItemProps["renderText"], undefined>
  >(() => {
    return (
      <ItemsListContext.Consumer>
        {(api) => {
          const text =
            extractText({
              value: props.entry.comment?.text || "",
              namespace: "kanban-card-comment-list-item",
            }) ||
            props.entry.comment?.text ||
            ""

          const authorName = props.entry?.comment?.author?.shortName || ""

          if (api.variant === "list") {
            return (
              <MessageChain text={text} authorName={authorName}></MessageChain>
            )
          }

          return <MessageLine text={text} authorName={authorName}></MessageLine>
        }}
      </ItemsListContext.Consumer>
    )
  }, [])

  return (
    <KanbanCardItem
      entry={props.entry}
      className={props.className}
      style={props.style}
      // renderAvatar={renderAvatar}
      badge={badge}
      renderText={renderText}
      title={title}
      text={text}
      // ListItemProps={{ disabled: true }}
    ></KanbanCardItem>
  )
}

// const useStyles = makeStyles((theme) => {
//   return {
//     avatarWithBadgeContainer: {
//       position: "relative",
//       "&>.badge": {
//         "&>*": {
//           background: purple[2],
//           // content: "''",
//           color: "white",
//         },
//         // display: "block",
//         right: 0,
//         bottom: -7,
//         position: "absolute",

//         // position: 'absolute',
//       },
//     },
//   }
// })
