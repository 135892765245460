import useActiveRoomShareableLink from "hooks/useActiveRoomShareableLink/useActiveRoomShareableLink"
import useMemoAPI from "hooks/useMemoAPI"
import useThrottledValue from "hooks/useThrottledValue"
import React from "react"
import useSearchMessageListItems from "./useSearchMessageListItems"

export default function useSearchMessagesAPI() {
  const [search, setSearch] = React.useState<string>("")

  const querySearch = useThrottledValue(search, 1000)
  const searchResult = useSearchMessageListItems({ search: querySearch })

  return useMemoAPI({ search, setSearch, searchResult })
}
