import { IconButton, Paper, Typography, makeStyles } from "@material-ui/core"
import { Icon } from "components/Icon/Icon"
import KeyListeners from "components/KeyListeners/KeyListeners"
import LexicalEditor from "components/LexicalEditor/LexicalEditor"
import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { KanbanActivityFragment } from "lib/graphql/operations"
import React from "react"
import CommentInput from "../../../CommentInput/CommentInput"
import ActivitiesLines from "../ActivitiesLines/ActivitiesLines"
import { ActionsChips } from "./ActionsChips/ActionsChips"
import ActivityUserAvatar from "./ActivityUserAvatar"
// import { KanbanCommentFragment } from '../../../../../../../../../../../../lib/graphql/operations';

export default function AddCommentActivity(props: {
  activity: KanbanActivityFragment
}) {
  const c = useStyles()

  const api = React.useContext(KanbanCardPageBoardItemContext)

  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const element = ref.current

    if (!element) return

    api.events$.renderComment$.next(element)
  }, [])

  if (props.activity.__typename !== "KanbanAddCommentActivity") return null

  const author = props.activity?.comment?.author || props.activity.user

  const comment = props.activity.comment

  if (!comment || !comment?.id || !!comment.isDeleted) return null

  return (
    <div className="add-comment-activity" ref={ref}>
      {(() => {
        return (
          <ActivitiesLines
            action={null}
            IndentationProps={{
              left: <ActivityUserAvatar user={author}></ActivityUserAvatar>,
            }}
            dateTime={props.activity.when}
            user={props.activity.user}
            render={(p) => {
              const selectedComment = api.comments.edition.ui.selectedComment

              const isEditing = [
                comment.id === selectedComment?.id,
                api.comments.edition.ui.isOpen,
              ].every(Boolean)

              if (isEditing && !api.viewport?.isSmallView) {
                return (
                  <KeyListeners.Escape
                    enabled
                    callback={api.comments.edition.ui.close}
                  >
                    <CommentInput
                      namespace={"edit-comment-" + comment.id}
                      value={selectedComment?.text || ""}
                      onSave={(text) => {
                        api.comments.edition.edit(text)
                        api.comments.edition.ui.close()
                      }}
                      renderSubmitButton={(args) => {
                        return (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {args.defaultComponent}
                            <IconButton
                              style={{ marginLeft: 12, padding: 0 }}
                              onClick={() => api.comments.edition.ui.close()}
                            >
                              <Icon name="close"></Icon>
                            </IconButton>
                          </div>
                        )
                      }}
                      clearEditorOnSave={false}
                    ></CommentInput>
                  </KeyListeners.Escape>
                )
              }

              return (
                <>
                  <div
                    className={c.titleContainer}
                    ref={api.comments.rendered.getRef(comment.id || "")}
                  >
                    <Typography variant="body2" className={c.authorName}>
                      {author.name}
                    </Typography>
                  </div>

                  {(() => {
                    return (
                      <div className={c.messageContainer}>
                        <Paper elevation={1} className={c.message}>
                          <LexicalEditor.Provider
                            editable={false}
                            namespace={["comment", comment.id].join("-")}
                            markdownMode={false}
                            value={comment?.text || ""}
                          >
                            <LexicalEditor.Root></LexicalEditor.Root>
                          </LexicalEditor.Provider>
                        </Paper>

                        <ActionsChips comment={comment}></ActionsChips>
                      </div>
                    )
                  })()}

                  <div className={c.when}>{p.components?.when}</div>
                </>
              )
            }}
          ></ActivitiesLines>
        )
      })()}
    </div>
  )
}

export function MoveCardBetweenColumnsActivity(props: {
  activity: KanbanActivityFragment
}) {
  const t = useI18n()

  if (props.activity.__typename !== "KanbanMoveCardBetweenColumnsActivity")
    return null

  const action = (() => {
    const fromRoom = props.activity.fromRoom || null
    const toRoom = props.activity.toRoom || null

    // console.log({ fromRoom, toRoom })

    if (fromRoom?.roomID !== toRoom?.roomID)
      return t["movedThisTaskFromTheRoomToTheRoom"]({
        fromRoom: fromRoom?.title || "",
        toRoom: toRoom?.title,
      })

    const fromColumn = props.activity.fromColumn
    const toColumn = props.activity.toColumn

    if (fromColumn?.id !== toColumn?.id)
      return t["movedThisTaskFromColumnToColumn"]({
        fromColumn: fromColumn?.title || "",

        toColumn: toColumn?.title || "",
      })

    return null
  })()

  if (!action) return null

  return (
    // null
    <ActivitiesLines
      action={action}
      icon="change_circle"
      dateTime={props.activity.when}
      user={props.activity.user}
    ></ActivitiesLines>
  )
}

export const useStyles = makeStyles((theme) => {
  return {
    root: {},
    message: {
      padding: theme.spacing(1),
      marginRight: theme.spacing(0.5),
    },

    menuIconButton: {
      padding: 0,
    },
    authorName: { lineHeight: 1 },
    titleContainer: {
      position: "relative",
      "& .menu-icon-button": {
        position: "absolute",
        top: 0,
        right: 0,
      },
    },
    toggleableMenuIcon: { fontSize: "19px !important", marginTop: -5.5 },
    when: {
      marginTop: theme.spacing(0.75),
    },
    messageContainer: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-end",
      marginTop: theme.spacing(0.75),
    },
  }
})
