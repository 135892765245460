import React from "react"
import useActiveResources from "hooks/useActiveResources"
import { useEffect } from "react"

export default function ActiveWorkspaceIDListener(props: {
  onChangeActiveWorkspaceID: (workspaceID: string) => any
}) {
  const { workspaceID } = useActiveResources()

  useEffect(() => {
    props.onChangeActiveWorkspaceID(workspaceID)
  }, [workspaceID])

  return null
}
