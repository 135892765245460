import DecisionDialog from "components/DecisionDialog/DecisionDialog"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React, { useEffect } from "react"
import { ConferenceActivity } from "../ConferenceActivityContext"
// import { RoomLeavingManager } from './LeaveVideoSessionDialogComponent';
export function LeaveVideoSessionDialog() {
  const t = useI18n()
  const conferenceApi = React.useContext(ConferenceActivity.Context)
  const open = conferenceApi.leavingDialogIsOpen.isTrue
  const deactivated = !conferenceApi.isActive.isTrue

  useEffect(() => {
    if (deactivated) {
      return
    }

    const handleBeforeUnload: (this: Window, ev: BeforeUnloadEvent) => any = (
      event
    ) => {
      console.log(event)
    }

    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [open, deactivated])

  return (
    <>
      <DecisionDialog
        open={deactivated ? false : open}
        onClose={conferenceApi.userWantsToStay}
        onDisagree={conferenceApi.userWantsToStay}
        onAgree={conferenceApi.userWantsToLeave}
        disagreeText={t.no}
        agreeText={t.yes}
        title={t.conference.leaveConferenceQuestionMark}
        content={t.conference.doYouWantToLeave}
      />
    </>
  )
}
