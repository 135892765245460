import React from "react"
import DocumentSelection from "components/DocumentSelection/DocumentSelection"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Fab, { FabProps } from "components/Fab/Fab"
import { Paper, useTheme } from "@material-ui/core"
import AddTasksPanel from "components/Room/components/Messages/components/MessageRow/components/TextSelectionKanbanCardCreation/components/AddTasksPanel/AddTasksPanel"
import UseEffect from "components/UseEffect/UseEffect"
import { RoomContext } from "../../../../../../contexts/room"

type TextSelectionKanbanCardCreationProps = {
  children: React.ReactNode
  actionsPosition: "left" | "right"
  disabled?: boolean
}

export default function TextSelectionKanbanCardCreation(
  props: TextSelectionKanbanCardCreationProps
) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const c = useStyles({ actionsPosition: props.actionsPosition })

  if (props.disabled) return <>{props.children}</>

  return (
    <div
      ref={containerRef}
      className={clsx("text-selection-kanban-card-creation", c.root)}
    >
      {props.children}
      <OptionsHandle
        containerRef={containerRef}
        actionsPosition={props.actionsPosition}
      ></OptionsHandle>
    </div>
  )
}

type Style = Pick<TextSelectionKanbanCardCreationProps, "actionsPosition">

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
    },
    left: {
      left: 0,
    },

    right: {
      right: 0,
    },
    content: {
      position: "absolute",
      transform: (props: Style) =>
        `translateX(${props.actionsPosition === "left" ? "-" : ""}100%)`,
      bottom: 0,
    },
  }
})

function KanbanCardsCreationFab(props: {
  actionsPosition: "left" | "right"
  className?: string
}) {
  const c = useKanbanCardsCreationFabStyles()

  return (
    <Fab
      className={props.className}
      disableClosingOnClickingOutside
      actions={[]}
      ballSizes={{ fab: 18, illustration: 0 }}
      MuiFabProps={{ style: { boxShadow: "none", background: "#d9d3d3" } }}
      FabIconProps={{ style: { fontSize: 16 } }}
      renderActionsPanel={(args) => (
        <ActionsPanel
          onClose={args?.onClose}
          panelPosition={props.actionsPosition}
        ></ActionsPanel>
      )}
      classes={{ fab: c.fab }}
    >
      {function renderFab(p) {
        const l = (() => {
          const handle = <div className={c.handle}>{p.components.handle}</div>
          if (props.actionsPosition === "left")
            return [p.components.actionsPanel, handle]

          return [handle, p.components.actionsPanel]
        })()
        return (
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            {l.map((c, index) => (
              <React.Fragment key={index}>{c}</React.Fragment>
            ))}
          </div>
        )
      }}
    </Fab>
  )
}

function ActionsPanel(props: {
  onClose?: () => void
  panelPosition: "left" | "right"
}) {
  const theme = useTheme()

  const paperRef = React.useRef<HTMLDivElement>(null)
  const [visibility, setVisibility] = React.useState<boolean>(false)

  const documentSelection = React.useContext(DocumentSelection.Context)

  const selectionRestorerProps = documentSelection.selectionRestorerProps
  const rootContainerAPI = React.useContext(RoomContext).template.rootContainer
  const containerRef = rootContainerAPI.containerRef

  const [diff, setDiff] = React.useState<number>(0)

  const prop = props.panelPosition

  const calculateLayout = () => {
    process.nextTick(() => {
      if (!paperRef.current || !containerRef.current) return

      const sign = prop === "left" ? 1 : -1
      const diff =
        paperRef.current.getBoundingClientRect()[prop] -
        containerRef.current.getBoundingClientRect()[prop]

      setDiff(sign * diff)
      setVisibility(true)
    })
  }

  React.useEffect(() => {
    calculateLayout()
  }, [])

  return (
    <Paper
      ref={paperRef}
      style={{
        visibility: visibility ? "visible" : "hidden",
        padding: theme.spacing(1),
        width: 300,
        position: "relative",

        ...(diff < 0
          ? //on right we need a compensation because of the vertical scrollbar
            { [prop]: -1 * diff + (prop === "right" ? 30 : 0) }
          : {}),
        zIndex: 1,
        ...selectionRestorerProps.style,
      }}
    >
      <AddTasksPanel
        documentSelection={documentSelection}
        onClickColumnOption={() => {
          selectionRestorerProps.onClick()
        }}
        onClose={props.onClose}
      ></AddTasksPanel>
    </Paper>
  )
}

const useKanbanCardsCreationFabStyles = makeStyles((theme) => {
  return {
    fab: {
      boxShadow: "none",
    },
    handle: {
      margin: theme.spacing(0, 1),
    },
  }
})

function OptionsHandle(props: {
  containerRef: React.RefObject<HTMLDivElement | null>
  actionsPosition: "left" | "right"
}) {
  const c = useStyles({ actionsPosition: props.actionsPosition })

  const api = React.useContext(DocumentSelection.Context)

  const hasContent = api.containerContainsAnchor(props.containerRef.current)

  return (
    <div
      className={clsx(c.content, c[props.actionsPosition])}
      style={{
        ...api.selectionRestorerProps.style,
        display: !hasContent ? "none" : undefined,
      }}
      onClick={api.selectionRestorerProps.onClick}
    >
      <KanbanCardsCreationFab
        actionsPosition={props.actionsPosition}
      ></KanbanCardsCreationFab>
    </div>
  )
}
