import _ from "lodash"
import { useEffect } from "react"
import React from "react"

/**
 * `useOnChange` is a custom React hook that manages value change events for a given component.
 * This hook accepts an object of `props` which includes:
 * - `onChange`: An optional callback function that will be called when the value changes.
 * - `value`: The current value of the component that needs to be tracked for changes.
 * - `disabled`: An optional boolean flag to disable the change event handling.
 *
 * The hook initializes with a `useState` for storing the current value and another `useState`
 * to track if the value has been set at least once. It uses a `useEffect` that watches for
 * changes in the `props.value` and ensures that the `onChange` callback is only called when
 * the value has changed and the hook is not disabled. If the `onChange` function is not defined,
 * the hook will silently ignore the change event.
 *
 * @template T - The type of the value being managed.
 * @param {Object} props - The configuration properties for the hook.
 * @param {(value: T) => any} [props.onChange] - Optional callback function to call when the value changes.
 * @param {T} props.value - The current value of the component to track for changes.
 * @param {boolean} [props.disabled] - Optional flag to disable the change event handling.
 * @returns {void}
 *
 * @example
 * const MyComponent = ({ value, onChange, disabled }) => {
 *   useOnChange({ value, onChange, disabled })
 *   // ... rest of the component
 * }
 */

export default function useOnChange<T = any>(props: {
  onChange?: (value: T) => any
  value: T
  disabled?: boolean
}) {
  const [value, setValue] = React.useState<T>()
  const [valueWasSet, setValueWasSet] = React.useState(false)

  useEffect(() => {
    if (!!valueWasSet) {
      if (typeof props.onChange !== "function") return

      if (props.value !== value) {
        if (props.disabled) return
        props.onChange(props.value)
      }
    } else {
      setValueWasSet(() => true)
    }

    setValue(() => props.value)
  }, [props.value])
}
