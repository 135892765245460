import React from "react"
import { getCreateRoomPageComponent } from "../../../../../../CreateRoomPageComponent/CreateRoomPageComponent"
import { ItemTemplate } from "../../ItemTemplate/ItemTemplate"
import { PageBoardItemContext } from "../../PageBoardItemContext"
import { SecondPage } from "../../SecondPage/SecondPage"

export function useCreateRoomMiniPage() {
  const templateApi = React.useContext(ItemTemplate.Context)
  const pageBoardItem = React.useContext(PageBoardItemContext)

  const Page = React.useMemo(() => {
    const item = pageBoardItem.item

    if (!item) return

    const PageComponent = getCreateRoomPageComponent({
      onCreate: (a) => {
        if (a.__typename !== "RoomPageBoardItem") return

        templateApi.twoPagesAPI.openFullPage(function RoomPage(props) {
          return (
            <SecondPage
              onClose={props.onClose}
              getItemParams={{
                item: a,
              }}
            ></SecondPage>
          )
        })
      },
      relatedItem: {
        itemID: item.itemID || "",
        type: item.type,
      },
    })

    return PageComponent
  }, [pageBoardItem.item, templateApi.twoPagesAPI.openFullPage])

  return React.useCallback(() => {
    if (!Page) return
    templateApi.twoPagesAPI.openMiniPage(Page)
  }, [Page])
}
