import {
  cacheUpdate$,
  cacheUpdateAccepted$,
} from "components/AssetsCacheUpdate/lib"
import React from "react"

export default function useOnCacheUpdate(onCacheUpdate: () => void) {
  React.useEffect(() => {
    cacheUpdate$.subscribe(() => {
      console.log(3)
      cacheUpdateAccepted$.next()
      onCacheUpdate?.call(undefined)
    })
  }, [])
}
