import { Typography } from "@material-ui/core"
import Hook from "components/Hook/Hook"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import { getIcon } from "components/PageBoard/hooks/useTypesOptions"
import I18nContext from "contexts/i18nContext/i18nContext"
import { useCopyToClipBoard } from "hooks/useCopyToClipBoard"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { getSecondPage } from "../../../SecondPage/getSecondPage"
import { HeaderIconButton } from "../../../HeaderIconButton"
import { RelatedItemsIconButtons } from "../../../RelatedItemsIconButtons/RelatedItemsIconButtons"
import { ShopOrderForm } from "../../../ShopOrderForm/ShopOrderForm"
import { ShopOrderPageBoardItemContext } from "../../ShopOrderPageBoardItemContext"
import { OrderCurl } from "../OrderCurl/OrderCurl"

export function Title() {
  const api = React.useContext(ShopOrderPageBoardItemContext)

  const item = api.item

  return (
    <ItemTemplate.Context.Consumer>
      {(templatePageApi) => {
        return (
          <>
            <div>
              <I18nContext.Consumer>
                {(t) => (
                  <Typography variant="h6">
                    {t["orderTitle"].large(
                      item.order?.companyOrderId || item.order?.id || ""
                    )}
                  </Typography>
                )}
              </I18nContext.Consumer>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* {!api.order?.closedAt && (
                <FormCommons.SaveButton
                  ButtonProps={{
                    children: (
                      <I18nContext.Consumer>
                        {(t) => t.conclude}
                      </I18nContext.Consumer>
                    ),
                  }}
                ></FormCommons.SaveButton>
              )} */}

              {(() => {
                const showGoToMessageButton =
                  api.initialParams?.showGoToMessageButton ?? true

                return (
                  <RelatedItemsIconButtons
                    item={api.item}
                    onClick={(itemParams) => {
                      if (itemParams.type === PageBoardItemType.Message) {
                        templatePageApi.twoPagesAPI.openHalfPage(
                          getSecondPage({
                            initialParams: {
                              RoomPageBoardItemProps: {
                                initialParams: {
                                  summarizedLayout: true,
                                },
                              },
                            },
                            itemParams: {
                              type: PageBoardItemType.Message,
                              itemID: itemParams.itemID,
                            },
                          })
                        )
                      }

                      if (itemParams.type === PageBoardItemType.Document) {
                        templatePageApi.twoPagesAPI.openHalfPage(
                          getSecondPage({
                            initialParams: {
                              initialPage: "viewer",
                            },
                            itemParams: {
                              type: PageBoardItemType.Document,
                              itemID: itemParams.itemID,
                            },
                          })
                        )
                      }
                    }}
                    // render={({ itemParams, defaultComponent }) => {
                    //   if (
                    //     itemParams.type === PageBoardItemType.Message &&
                    //     !showGoToMessageButton
                    //   ) {
                    //     return null
                    //   }

                    //   return (
                    //     <div style={{ marginLeft: 8 }}>{defaultComponent}</div>
                    //   )
                    // }}
                  ></RelatedItemsIconButtons>
                )
              })()}

              <Hook hook={useCurrentWorkspace} hookProps={undefined}>
                {(w) => {
                  if (!w.workspace?.isOnDeveloperMode) return null

                  return (
                    <ShopOrderForm.Context.Consumer>
                      {(api) => {
                        if (!api.form.values.partnerParams) return null

                        return (
                          <HeaderIconButton
                            name="code"
                            style={{ marginLeft: 8 }}
                            onClick={() => {
                              templatePageApi.twoPagesAPI.openHalfPage(
                                function Page(props) {
                                  return (
                                    <OrderCurl.Provider input={api.form.values}>
                                      <ItemTemplate.Provider
                                        onClose={props.onClose}
                                      >
                                        <ItemTemplate.Root
                                          EnhancedPageProps={{
                                            titleComponent: (
                                              <>
                                                <Typography variant="h6">
                                                  Curl
                                                </Typography>
                                                <OrderCurl.Context.Consumer>
                                                  {(curlApi) => {
                                                    if (!curlApi.curl)
                                                      return null

                                                    return (
                                                      <Hook
                                                        hook={
                                                          useCopyToClipBoard
                                                        }
                                                        hookProps={undefined}
                                                      >
                                                        {(
                                                          copyToClipBoardApi
                                                        ) => {
                                                          const feedback =
                                                            copyToClipBoardApi.feedbackComponent

                                                          return (
                                                            <>
                                                              <HeaderIconButton
                                                                name="content_copy"
                                                                onClick={() =>
                                                                  copyToClipBoardApi.copyToClipBoard(
                                                                    curlApi.curl
                                                                  )
                                                                }
                                                              ></HeaderIconButton>
                                                              {feedback}
                                                            </>
                                                          )
                                                        }}
                                                      </Hook>
                                                    )
                                                  }}
                                                </OrderCurl.Context.Consumer>
                                              </>
                                            ),

                                            body: (
                                              <div
                                                style={{
                                                  height: "100%",
                                                  padding: 16,
                                                  paddingTop: 0,
                                                }}
                                              >
                                                <OrderCurl.Code
                                                  style={{
                                                    height: "100%",
                                                    overflow: "auto",
                                                  }}
                                                ></OrderCurl.Code>
                                              </div>
                                            ),
                                          }}
                                        ></ItemTemplate.Root>
                                      </ItemTemplate.Provider>
                                    </OrderCurl.Provider>
                                  )
                                }
                              )
                            }}
                          ></HeaderIconButton>
                        )
                      }}
                    </ShopOrderForm.Context.Consumer>
                  )
                }}
              </Hook>
            </div>
          </>
        )
      }}
    </ItemTemplate.Context.Consumer>
  )
}
