import _ from "lodash"
import React from "react"
import { useMemo, useCallback } from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import SelectWithAutocomplete from "components/SelectWithAutocomplete/SelectWithAutocomplete"
import useCollectionsSuggestion from "../../hooks/useCollectionsSuggestion"
import DocumentCollectionsContext from "components/DocumentCollections/contexts/DocumentCollectionsContext"
import { TextFieldProps } from "@material-ui/core"

export default function CollectionSelection(props: {
  type: string
  text?: string
  onSelectCollectionID: (id: string | null) => void
  onChangeTextField: (text: string | null) => void
  TextField?: React.FC<TextFieldProps>
  classes?: React.ComponentProps<typeof SelectWithAutocomplete>["classes"]
}) {
  const t = useI18n()

  const { collectionsSuggestions } = React.useContext(
    DocumentCollectionsContext
  )

  const { collections, filterSuggestions } = collectionsSuggestions

  const filter = useCallback(_.debounce(filterSuggestions, 500), [])

  const options = useMemo(
    () =>
      (collections || []).map((collection) => ({
        value: collection.id,
        label: collection.title || "",
        metadata: collection,
      })),
    [collections]
  )

  const onChangeText = useCallback(
    (text) => {
      filter(text)
      props.onChangeTextField(text)
    },
    [filter, props.onChangeTextField]
  )

  const onSelect = useCallback(
    (selected: typeof options[0]) => {
      if (!selected) return
      const id = selected.value
      props.onSelectCollectionID(id)
    },
    [props.onSelectCollectionID]
  )

  return (
    <SelectWithAutocomplete
      options={options}
      textField={props.text || ""}
      label={t.title}
      onSelect={onSelect}
      TextField={props.TextField}
      onChangeTextField={onChangeText}
      classes={props.classes}
    />
  )
}
