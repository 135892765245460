import { withStyles } from "@material-ui/core/styles"
import React from "react"
import { Snackbar, SnackbarProps } from "@material-ui/core"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import { DrawerContext } from "../../contexts/drawerContext/drawerContext"

export type LeftSnackbarProps = SnackbarProps

/**
 *  It prevents overflow with left menu
 */
export default function LeftSnackbar(props: LeftSnackbarProps) {
  const drawerApi = React.useContext(DrawerContext)
  const c = useStyles({ drawerWidth: drawerApi.container.width })

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      {...props}
      classes={{ ...c, ...(props.classes || {}) }}
      className={clsx("left-snackbar", props.className)}
    ></Snackbar>
  )
}

type Style = {
  drawerWidth: number
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      //preventing overflow with left menu
      // marginLeft: (props: Style) => theme.spacing(props.drawerWidth ? 0 : 10),
      // marginLeft: (props: Style) => theme.spacing(props.drawerWidth ? 0 : 10),
      // marginLeft: (props: Style) => props.drawerWidth,
      marginLeft: (props: Style) => 45,
      // visibility: (props: Style) => (props.drawerWidth ? "hidden" : "visible"),
    },
  }
})
