import React from "react"
import useTwoPagesAPI from "../hooks/useTwoPagesAPI"

export type TwoPagesAPI = ReturnType<typeof useTwoPagesAPI>

const TwoPagesContext = React.createContext(
  {} as ReturnType<typeof useTwoPagesAPI>
)

TwoPagesContext.displayName = "TwoPagesContext"

export default TwoPagesContext
