import { RoomFragment, RoomsQuery } from "lib/graphql/operations"
import _ from "lodash"
import updateQuery from "lib/updateQuery/updateQuery"
import { DataProxy } from "apollo-cache"

const updateRoomsQuery = {
  addRoom(newRoom: RoomFragment, dataProxy: DataProxy) {
    updateQuery(
      {
        queryName: "rooms",
        variables: { workspaceID: newRoom?.workspaceID },
        updateFn: (a) => {
          return {
            ...a,
            rooms: _.uniqBy([newRoom, ...(a?.rooms || [])], (a) => a.id),
          }
        },
      },
      dataProxy
    )
  },
  removeRoom(
    props: { roomID: string; workspaceID: string },
    dataProxy: DataProxy
  ) {
    updateQuery(
      {
        queryName: "rooms",
        variables: { workspaceID: props.workspaceID },
        updateFn: (a) => {
          const rooms = a?.rooms?.filter((r) => r.id !== props.roomID)

          const value: RoomsQuery = {
            __typename: "Query",
            rooms: rooms || [],
          }

          return value
        },
      },
      dataProxy
    )
  },
}

export default updateRoomsQuery
