import { IconButton, makeStyles } from "@material-ui/core"
import { MobileHiddenXScrollbar } from "components/MobileHiddenXScrollbar/MobileHiddenXScrollbar"
import _ from "lodash"
import React from "react"
// import { IndexedItems } from "../IndexedItems/IndexedItems"
import clsx from "clsx"
import { Icon, IconProps } from "components/Icon/Icon"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import { StyledProps } from "types/type"
// import { FilterSelectorContext } from "./components/DateAndTypesFilter/FilterSelector/FilterSelectorContext"
import { ScrollChildTracker } from "../../../ScrollChildTracker"
import { FilterSelectorContext } from "./FilterSelectorContext"

export function ControlButton(
  props: { direction: "left" | "right" } & StyledProps
) {
  const selectedIDs = React.useContext(FilterSelectorContext).selectedChipsIDs

  const isDotVisible = (items: string[]) => {
    return _.intersection(selectedIDs, items).length !== 0
  }

  const c = useStyles()

  return (
    <MobileHiddenXScrollbar.Context.Consumer>
      {(scrollApi) => {
        if (!scrollApi.isActive) return null

        type ControlButtonProps = {
          icon: IconProps["name"]
          onClick: () => void
          isHidden: boolean
          dotIsVisible: boolean
          backgroundImage: string
        }

        return (
          <ScrollChildTracker.Context.Consumer>
            {(trackerApi) => {
              const directionArgs: Record<
                typeof props.direction,
                ControlButtonProps
              > = {
                right: (() => {
                  const itemsHidden = trackerApi.invisibleInTheRight

                  return {
                    isHidden: !itemsHidden?.length,
                    onClick: () => scrollApi.goToNextPage(),
                    icon: "arrow_right_outlined",
                    dotIsVisible: isDotVisible(itemsHidden),
                    backgroundImage:
                      "linear-gradient(to right, transparent, white 58%)",
                  }
                })(),

                left: (() => {
                  const itemsHidden = trackerApi.invisibleInTheLeft

                  return {
                    isHidden: !itemsHidden?.length,
                    onClick: () => scrollApi.goToPreviousPage(),
                    icon: "arrow_left_outlined",
                    dotIsVisible: isDotVisible(itemsHidden),
                    backgroundImage:
                      "linear-gradient(to left, transparent, white 58%)",
                  }
                })(),
              }

              const { isHidden, onClick, icon, dotIsVisible } =
                directionArgs[props.direction]

              if (isHidden) return null

              return (
                <div
                  className={clsx(c.root, props.className, "control-button")}
                  style={{
                    ...props.style,

                    backgroundImage:
                      directionArgs[props.direction].backgroundImage,
                  }}
                >
                  <PurpleBadge
                    badgeContent={" "}
                    invisible={!dotIsVisible}
                    variant="dot"
                    classes={{
                      badge: c.badge,
                    }}
                    style={{
                      position: "relative",
                      top: -12,
                    }}
                  >
                    <IconButton
                      style={{
                        marginBottom: 8,
                      }}
                      onClick={onClick}
                    >
                      <Icon name={icon} color="primary"></Icon>
                    </IconButton>
                  </PurpleBadge>
                </div>
              )
            }}
          </ScrollChildTracker.Context.Consumer>
        )
      }}
    </MobileHiddenXScrollbar.Context.Consumer>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      // background: "white",
    },
    badge: {
      top: 12,
      right: 12,
    },
  }
})
