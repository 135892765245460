import useForm, { UseFormProps } from "hooks/useForm"
import { DetailedContactFragment } from "lib/graphql/operations"
import _ from "lodash"
import React from "react"
import { DetailedContactInput } from "../../../../../../../../../lib/graphql/types"
import useUpdateDetailedContact from "../../../../../../../../../hooks/useUpdateDetailedContact/useUpdateDetailedContact"
import * as Yup from "yup"
import { useI18n } from "contexts/i18nContext/i18nContext"

export function useContactForm(props?: {
  contact?: DetailedContactFragment | null
}) {
  const initialValues = React.useMemo(() => {
    if (!props?.contact) return {} as DetailedContactInput

    const keys: (keyof DetailedContactInput)[] = [
      "salutation",
      "title",
      "firstName",
      "lastName",
      "position",
      "landline",
      "mobile",
      "active",
      "remarks",
      "email",
      "remindMe",
      // "accessKey",
      "attachFile",
      "notifyAccessKey",
      "createColKey",
      // "checkboxes-flags"
    ]

    const v: DetailedContactInput = {
      ...(_.pick(props.contact, keys) || {}),
    }

    return v
  }, [props?.contact])

  const { updateDetailedContact } = useUpdateDetailedContact({
    contactID: props?.contact?.id || "",
  })

  const onSubmit = React.useCallback(
    (() => {
      const onSubmit: UseFormProps<typeof initialValues>["onSubmit"] =
        function onSubmit(a) {
          const update = a.values
          return updateDetailedContact({ dataUpdate: update })
        }
      return onSubmit
    })(),
    [props?.contact?.id, updateDetailedContact]
  )

  const t = useI18n()

  const form = useForm({
    initialValues,
    onSubmit,
    enableReinitialize: true,

    validationSchema: Yup.object({
      email: Yup.string().email(t.wrongEmail).nullable(),
    }),
  })

  return form
}
