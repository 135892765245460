import { Button, makeStyles } from "@material-ui/core"
import React from "react"
import clsx from "clsx"
import { useI18n } from "contexts/i18nContext/i18nContext"
import DocumentCollectionsContext from "components/DocumentCollections/contexts/DocumentCollectionsContext"
import Views from "components/Views/Views"
import CollectionForm from "../CollectionForm/CollectionForm"
import AddNewPartner from "./AddNewPartner"
import Actions from "components/Actions/Actions"

export default function Forms(props: {
  className?: string
  isSmall?: boolean
}) {
  const api = React.useContext(DocumentCollectionsContext)

  React.useEffect(() => {
    return api.form.resetForm
  }, [])

  const isMobile = !!props.isSmall

  const t = useI18n()
  const c = useStyles({ isMobile: isMobile })

  const views: { id: typeof api.form.viewID; component: React.ReactElement }[] =
    [
      {
        id: "main",
        component: <CollectionForm></CollectionForm>,
      },

      {
        id: "add-new-partner",
        component: (
          <AddNewPartner.Default
            onClose={() => {
              api.form.setViewID("main")
            }}
            onSubmit={() => api.form.setViewID("main")}
            renderButtons={({ components: { saveButton } }) => {
              return (
                <Actions
                  isStack={isMobile}
                  leftActions={null}
                  className={c.addPartnerActions}
                  classes={{ stackedActions: c.stackedAddPartnerActions }}
                  rightActions={
                    <React.Fragment>
                      <Button
                        color="primary"
                        onClick={() => {
                          api.form.setViewID("main")
                        }}
                        size="small"
                      >
                        {t.cancel}
                      </Button>
                      {saveButton}
                    </React.Fragment>
                  }
                ></Actions>
              )
            }}
          ></AddNewPartner.Default>
        ),
      },
    ]

  return (
    <div className={clsx("forms", c.root, props.className)}>
      <Views currentViewID={api.form.viewID} views={views} noScroll></Views>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: (props: { isMobile: boolean }) => {
        if (props.isMobile) return 300

        return 540
      },
      "&>*": {
        width: "100%",
        "&:not(:last-child)": {
          marginBottom: theme.spacing(1),
        },
      },
    },

    addPartnerActions: {
      width: "100%",
      marginLeft: 0,
    },

    stackedAddPartnerActions: {
      width: "100%",
      "& button": {
        marginBottom: theme.spacing(1),
        marginLeft: "0 !important",
      },
    },
  }
})
