import { makeStyles } from "@material-ui/core"
import { MessageFragment } from "lib/graphql/operations"
import React from "react"
import MessageMenu from "../../../../MessageMenu/MessageMenu"
import MessageBalloon, {
  MessageBalloonProps,
} from "./MessageBalloon/MessageBalloon"

export function MessageWrapper<T extends MessageFragment>(props: {
  message: T
  MessageBalloonProps: Omit<MessageBalloonProps, "message" | "children">
  children: (props: {
    components: { authorName: React.ReactNode; bottom: React.ReactNode }
  }) => React.ReactNode
}) {
  const message = props.message
  const children = props.children

  const render = (props: {
    components: {
      authorName: React.ReactNode
      bottom: React.ReactNode
    }
  }) => {
    return (
      <MessageMenu message={message}>
        {children({
          components: {
            authorName: props.components.authorName,
            bottom: props.components.bottom,
          },
        })}
      </MessageMenu>
    )
  }
  return (
    <MessageBalloon message={message} {...props.MessageBalloonProps}>
      {render}
    </MessageBalloon>
  )
}
