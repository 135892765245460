import { useApolloClient } from "@apollo/client";
import _ from "lodash"
import React from "react"
import useCollectionAssociation from "hooks/useCollectionAssociation/useCollectionAssociation"
import { DataProxy } from "apollo-cache"
import useCollectionsOverviews from "hooks/useCollectionsOverviews/useCollectionsOverviews"
import useCollectionIDFormAPI from "./useCollectionIDFormAPI"
import useMemoAPI from "hooks/useMemoAPI"
import { CollectionItemFragment } from "lib/graphql/operations"
import readFragment from "lib/updateFragment/readFragment"
import { Typename } from "types/type"
import useCollectionsSuggestion from "./useCollectionsSuggestion"
import useCreateCollection from "./useCreateCollection"
import useDeleteCollection from "./useDeleteCollection"
import { ChipProps } from "@material-ui/core"

export default function useDocumentCollectionsAPI(
  props: DocumentCollectionsProviderProps
) {
  const [selectedCollectionID, setSelectedCollectionID] = React.useState<
    string | null
  >(props.selectedCollectionID || null)

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false)

  const openDialog = React.useCallback(() => setIsDialogOpen(true), [])
  const resetSelectedCollectionID = React.useCallback(
    () => setSelectedCollectionID(null),
    []
  )

  const { associateCollection, dissociateCollection } =
    useCollectionAssociation({
      documentID: props.documentID,
      onDissociate: props.onDissociate,
      onAssociate: props.onAssociate,
    })

  const closePanel = React.useCallback(() => {
    setSelectedCollectionID(null)
    setIsDialogOpen(false)
  }, [])

  const { collectionsOverviews } = useCollectionsOverviews({
    excludeSystemTypes: true,
    fetchPolicy: "cache-and-network",
    includeMissingTypes: true,
  })

  const { createCollection, isLoading: isCreatingCollection } =
    useCreateCollection()

  const toggleCollection = React.useCallback(
    async function toggleCollection(selectedID: string) {
      const newCollectionID = selectedID
      const oldCollectionID = selectedCollectionID

      // debugger
      if (oldCollectionID && oldCollectionID !== newCollectionID) {
        dissociateCollection({ collectionID: oldCollectionID })
      }

      return associateCollection({ collectionID: selectedID })
    },
    [associateCollection, dissociateCollection, selectedCollectionID]
  )
  const client = useApolloClient()

  const collection = React.useMemo(() => {
    if (!selectedCollectionID) return null

    return readFragment(
      {
        typename: Typename.CollectionItem,
        cacheIDParams: { id: selectedCollectionID },
      },
      client
    )
  }, [selectedCollectionID, client])

  const form = useCollectionIDFormAPI({
    setCollectionID: setSelectedCollectionID,
    collection,
  })

  const onSave = React.useCallback(
    async function onPressSaveButton() {
      let selectedID = selectedCollectionID

      if (!selectedID) {
        const collectionID = await createCollection({
          title: form.collectionText,
          type: form.type,
        }).then((a) => {
          return a.data?.createCollectionItem?.id
        })

        if (!collectionID) return

        selectedID = collectionID
      }

      toggleCollection(selectedID)
      closePanel()
      resetSelectedCollectionID()

      form.setCollectionText("")
    },
    [
      selectedCollectionID,
      closePanel,
      resetSelectedCollectionID,
      toggleCollection,
      createCollection,
      form,
    ]
  )

  const collectionsSuggestions = useCollectionsSuggestion({
    type: form.type,
  })

  const { deleteCollection } = useDeleteCollection()

  const typeOverview = React.useMemo(() => {
    return collectionsOverviews?.find((a) => a.type === form.type) || null
  }, [collectionsOverviews, form.type])

  return useMemoAPI({
    ...props,
    collectionsOverviews,
    isLoading: isCreatingCollection,
    typeOverview,
    deleteCollection,
    collectionsSuggestions,
    form,
    collection,
    isDialogOpen,
    dissociateCollection,
    setSelectedCollectionID,
    collectionID: selectedCollectionID,
    resetSelectedCollectionID,
    closePanel,
    openDialog,
    onSave,
    setIsDialogOpen,
  })
}

export type DocumentCollectionsProviderProps = {
  documentID: string
  collections?: CollectionItemFragment[]

  selectedCollectionID?: string

  onAssociate?: (props: {
    collection: CollectionItemFragment
    apolloCache: DataProxy
  }) => void

  onDissociate?: (props: {
    collection: CollectionItemFragment
    apolloCache: DataProxy
  }) => void

  renderChips?: (props: {
    components: { chips?: React.ReactNode[] }
    api: { openDialog?: () => void }
  }) => React.ReactNode
}
