import { useCallback, useEffect, useMemo, useState } from "react"
import _ from "lodash"
import useMemoAPI from "hooks/useMemoAPI"

export default function useEditControlProviderValue({
  object = {},
  onSaveEditedObject = (newObjectValue = {}) => undefined,
  isEditing: isEditingProps = false,
  endEditionOnSave = true,
}) {
  const [isEditing, setIsEditing] = useState(isEditingProps)
  const [newObjectValue, setNewObjectValue] = useState(object)

  useEffect(() => {
    setIsEditing(isEditingProps)
  }, [isEditingProps])

  useEffect(() => {
    setNewObjectValue(object)
  }, [JSON.stringify(object)])

  useEffect(() => {
    if (isEditing) return
    setNewObjectValue(object)
  }, [isEditing])

  const updateObject = useCallback(function updateObject({ field, value }) {
    setNewObjectValue((state) => {
      let cloned = {}

      if (typeof state !== "undefined") {
        cloned = JSON.parse(JSON.stringify(state))
      }

      _.set(cloned, field, value)

      return cloned
    })
  }, [])

  const changeOccurred =
    JSON.stringify(newObjectValue) !== JSON.stringify(object)

  const startEdition = useCallback(function cancelEdition() {
    setIsEditing(true)
  }, [])

  const cancelEdition = useCallback(function cancelEdition() {
    setIsEditing(false)
  }, [])

  const saveEdition = useCallback(
    function saveEdition() {
      endEditionOnSave && setIsEditing(false)

      if (!changeOccurred) return

      return onSaveEditedObject(newObjectValue)
    },
    [
      endEditionOnSave,
      newObjectValue,
      JSON.stringify(object),
      onSaveEditedObject,
      changeOccurred,
    ]
  )
  const restore = useCallback(
    (property) => {
      const oldValue = _.get(object, property)

      updateObject({ field: property, value: oldValue })
    },
    [updateObject, JSON.stringify(object)]
  )

  return useMemoAPI({
    objectValue: object,
    isEditing,
    restore,
    editionChanged: changeOccurred,
    newObjectValue,
    setIsEditing,
    updateObject,
    saveEdition,
    startEdition,
    cancelEdition,
  })
}
