import React from "react"
import { makeStyles } from "@material-ui/core"
import EmojiPicker from "components/EmojiPicker/EmojiPicker"
import { Modal } from "../Modal/Modal"
import clsx from "clsx"
import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import { BottomAndPopoverModal } from "components/BottomAndPopoverModal/BottomAndPopoverModal"
import cslx from "clsx"

export default function EmojiPickerModal() {
  const c = useStyles()

  const api = React.useContext(KanbanCardPageBoardItemContext)

  const modal = api.comments.reaction.modal
  const emojiPicker = modal.emojiPicker

  return (
    <BottomAndPopoverModal
      popoverAnchorRef={modal.emojiPicker.popoverRef}
      className={clsx("emoji-picker-modal")}
      openClose={{
        isOpen: emojiPicker.isOpen,
        open: () => {},
        close: emojiPicker.close,
        toggle: () => {},
      }}
    >
      {(a) => {
        return (
          <div
            className={cslx(
              "picker-wrapper",
              a.modalVariant === "bottom-modal" && c.pickerFullHeight
            )}
          >
            <EmojiPicker
              perLine={8}
              emoji=""
              onClick={(a) => {
                emojiPicker.select(a.unified)
              }}
            ></EmojiPicker>
          </div>
        )
      }}
    </BottomAndPopoverModal>
  )
}
export const useStyles = makeStyles((theme) => {
  return {
    pickerFullHeight: {
      "&>.emoji-mart": {
        width: "100% !important",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",

        border: "none",
        flex: "1 1 0%",
        height: "100%",

        "& .emoji-mart-scroll": {
          flex: 1,
          minHeight: 217,
        },

        "& .emoji-mart-preview-emoji": {
          display: "block",
        },

        "& .emoji-mart-bar": {
          "&:first-child": {
            borderBottomWidth: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        },
      },
    },
  }
})
