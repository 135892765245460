import _ from "lodash"
import isEmptyValue from "./isEmptyValue"
import queryString from "query-string"

export default function searchParamsString(obj) {
  const o = _.pickBy(obj, (v) => !isEmptyValue(v))
  if (isEmptyValue(o)) return ""
  return "?" + queryString.stringify(o, { arrayFormat: "comma" })
}

window.queryString = queryString
