import { Button, Paper, Typography, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { EnhancedPage } from "components/EnhancedPage/EnhancedPage"
import FrameWithLoadingProgress from "components/FrameWithLoadingProgress/FrameWithLoadingProgress"
import TextField from "components/TextField/TextField"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useActiveResources from "hooks/useActiveResources"
import useForm, { UseFormProps } from "hooks/useForm"
import useTrueFalse from "hooks/useTrueFalse"
import {
  NewPageBoardItem_RoomPageBoardItem_Fragment,
  useCreateRoomPageBoardItemMutation,
} from "lib/graphql/operations"
import updatePageBoardItemsQueryCache from "lib/updatePageBoardItemsQueryCache"
import React from "react"
import { CreateRoomPageBoardItemMutationVariables } from "../../../../lib/graphql/operations"

type Fields = { roomName: string }

export type CreateRoomProps = {
  onClose?: () => any
  onCreate?: (a: NewPageBoardItem_RoomPageBoardItem_Fragment) => any
  relatedItem?: CreateRoomPageBoardItemMutationVariables["relatedItem"]
}

export default function CreateRoom(props: CreateRoomProps) {
  const [mutate] = useCreateRoomPageBoardItemMutation()
  const loading = useTrueFalse()

  const r = useActiveResources()

  const onSubmit = React.useCallback(
    (() => {
      const fn: UseFormProps<Fields>["onSubmit"] = function onSubmit({
        values,
      }) {
        loading.setTrue()

        if (!r.workspaceID) return

        return mutate({
          variables: {
            workspaceID: r.workspaceID,
            name: values.roomName,
            ...(props.relatedItem ? { relatedItem: props.relatedItem } : {}),
          },
          update(proxy, { data }) {
            const item = data?.createRoomPageBoardItem
            if (!item) return

            updatePageBoardItemsQueryCache(
              { item, workspaceID: r.workspaceID },
              proxy
            )
          },
        })
          .then((a) => {
            if (!!a.errors) return
            // props.onClose?.call(undefined)

            const item = a.data?.createRoomPageBoardItem

            if (!item) return

            props.onCreate?.call(undefined, item)
          })
          .finally(() => loading.setFalse())
      }

      return fn
    })(),
    [mutate, r.workspaceID, props.relatedItem]
  )

  const form = useForm<Fields>({ initialValues: { roomName: "" }, onSubmit })
  const c = useStyles()
  const t = useI18n()

  return (
    <FrameWithLoadingProgress
      className={clsx(c.root, "create-room")}
      isLoading={loading.isTrue}
      Wrapper={Paper}
      WrapperProps={{ elevation: 0 }}
    >
      <EnhancedPage
        style={{ height: "100%" }}
        onClose={props.onClose}
        titleComponent={
          <Typography variant="h6">{t["create-room"]}</Typography>
        }
        body={
          <div style={{ padding: "8px 16px" }}>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                form.submit()
              }}
              className={c.form}
            >
              <TextField
                {...form.getFieldProps("roomName")}
                inputProps={{ className: c.input }}
                className={c.textField}
                InputLabelProps={{
                  shrink: !!form.values.roomName,
                }}
                label="Name"
              ></TextField>
              <Button
                variant="contained"
                size="small"
                color="primary"
                type="submit"
                disabled={!form.readyToSubmit}
              >
                {t.create}
              </Button>
            </form>
          </div>
        }
      ></EnhancedPage>
      {/* <div className={c.head}>
        <Typography variant="h6">{t["create-room"]}</Typography>
        <IconButton onClick={props.onClose} edge="end" size="small">
          <Icon className={c.icon}>close</Icon>
        </IconButton>
      </div> */}
    </FrameWithLoadingProgress>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    icon: { fontSize: 20 },
    input: { width: "100%" },
    textField: { marginBottom: 16 },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    root: {
      // padding: 16,
      // minWidth: 250,
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  }
})
