//REMOVE
import React from "react"
import Ball from "../Ball/Ball"
import initials from "../../pages/util/initials"
import "./workspaceBall.scss"
import { purple } from "../../constants/colors"
import { Typography } from "@material-ui/core"
import { StyledProps } from "types/type"

export default function WorkspaceBall({
  name,
  format,
  size,
  className,
  background = purple[2],
  color = "white",
  ...someStyles
}: {
  name?: string | null
  format?: "square" | "round"
  size?: number
  className?: string
  background: string
} & Exclude<StyledProps["style"], undefined>) {
  if (!name) return null

  className = `workspace-ball ${className || ""}`

  const styles = {
    color,
    background,
    ...someStyles,
  }

  if (format === "square")
    return (
      <div className={className + " square"} style={styles}>
        <Typography variant="h6">{initials(name)}</Typography>
      </div>
    )

  return (
    <Ball className={className} size={size} {...styles}>
      {initials(name)}
    </Ball>
  )
}
