import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Box, makeStyles } from "@material-ui/core"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import { AppBar as MuiAppBar } from "@material-ui/core"
import { Toolbar } from "@material-ui/core"
import { appBarPadding } from "styles/globalStyles"
import Title from "./components/Title/Title"
import Search, { SearchVariants } from "./components/Search/Search"
import FrameWithLoadingProgress from "components/FrameWithLoadingProgress/FrameWithLoadingProgress"
import clsx from "clsx"

export type TopBarProps = Partial<{
  className: string
  title: string | React.ReactNode
  renderRight: (props: {
    components: { search: React.ReactNode }
    isMobileSearchActive: boolean
    defaultComponent: React.ReactNode
  }) => React.ReactNode

  renderLeft: (props: {
    components: { title: React.ReactNode }
    isMobileSearchActive: boolean
    searchIsActive: boolean
  }) => React.ReactNode
  search?: TopBarSearchProps
  showProgressBar: boolean
  secondaryToolbar: React.ReactNode
  elevation: number
  classes: { right: (props: { searchIsActive: boolean }) => Object }
}>

export default function TopBar(props: TopBarProps) {
  const {
    className = "",
    title = "",
    renderRight,
    renderLeft,
    search = defaultSearchProps,
    showProgressBar = false,
    secondaryToolbar = null,
    elevation = 1,
    classes = { right: ({ searchIsActive }) => undefined },
  } = props

  const { isMobile } = React.useContext(DeviceObserver.Context)

  const searchValue = _.get(search, "value")
  const [searchIsActive, setSearchIsActive] = useState(!!searchValue)
  const mobileSearchIsActive = isMobile && searchIsActive

  useEffect(() => {
    setSearchIsActive(!!searchValue)
  }, [searchValue])

  const c = useStyles({
    isMobile,
    isMobileSearchActive: mobileSearchIsActive,
  })

  return (
    <MuiAppBar
      color="default"
      position="relative"
      elevation={_.isFinite(elevation) ? elevation : 1}
      className={clsx("top-bar", c.root, className)}
    >
      <FrameWithLoadingProgress isLoading={showProgressBar}>
        <Toolbar classes={{ root: c.toolbar }}>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" className={c.left}>
              {(function Left() {
                const titleComponent = (function TitleComponent() {
                  if (!title) return null

                  const g = (key) => _.get(title, key)

                  const isValidTitle =
                    _.isString(title) || React.isValidElement(title)
                  if (isValidTitle) return <Title firstLine={title} />

                  if (g("firstLine") || g("secondLine"))
                    return (
                      <Title
                        firstLine={g("firstLine")}
                        secondLine={g("secondLine")}
                      />
                    )

                  return null
                })()
                const components = {
                  title: titleComponent,
                }

                if (_.isFunction(renderLeft))
                  return renderLeft({
                    components,
                    isMobileSearchActive: mobileSearchIsActive,
                    searchIsActive,
                  })

                return !mobileSearchIsActive && components.title
              })()}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              className={clsx(c.right, classes.right({ searchIsActive }))}
            >
              {(function Right() {
                const searchComponent = !_.isEmpty(search) && (
                  <Search
                    variant={search.variant}
                    suggestions={search.suggestions}
                    defaultValue={search.defaultValue}
                    onChange={search.onChange}
                    onceEmpty={search.onceEmpty}
                    value={search.value}
                    isActive={searchIsActive}
                    onSubmit={search.onSubmit}
                    classes={{
                      mobile: mobileSearchIsActive && c.mobileSearchIsActive,
                    }}
                    onceActivate={() => setSearchIsActive(true)}
                    onceDeactivate={() => setSearchIsActive(false)}
                  />
                )

                const components = {
                  // search: !mobileSearchIsActive ? searchComponent : null,
                  search: searchComponent,
                }

                const defaultComponent = components.search

                if (typeof renderRight === "function")
                  return renderRight({
                    components,
                    isMobileSearchActive: mobileSearchIsActive,
                    defaultComponent,
                  })

                return defaultComponent
              })()}
            </Box>
          </Box>
        </Toolbar>
        {secondaryToolbar}
      </FrameWithLoadingProgress>
    </MuiAppBar>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    marginBottom: 0.5, //make bottom shadow evident
  },
  toolbar: {
    ...appBarPadding(theme),
  },

  mobileSearchIsActive: {
    flex: 1,
  },

  // left: {
  //   flex: 1,
  // },
  left: {
    maxWidth: "100%",
  },

  right: {
    // flex: 1,
    width: ({ isMobileSearchActive = false } = {}) =>
      isMobileSearchActive ? "100%" : "initial",
  },
}))

// export const SearchVariants = Search.variants

export type TopBarSearchProps = {
  variant?: SearchVariants
  defaultValue?: string
  onChange?: (value: string | null) => any
  onceEmpty?: boolean
  value?: string | null
  onSubmit?: () => any
  suggestions?: {
    items: Array<[]>
    renderItem?: () => React.ReactNode
    onSelect?: (item: any) => any
  }
}

export const defaultSearchProps: TopBarSearchProps = {
  variant:
    Search.variants.MATCH_WINDOW_SIZE ||
    Search.variants.FILLED ||
    Search.variants.TOGGLEABLE,
  suggestions: { items: [], renderItem: undefined, onSelect: undefined },
  defaultValue: "",
  onChange: (value: string) => {},
  onceEmpty: undefined,
  value: "",
  onSubmit: () => {},
}
