import _, { memoize } from "lodash"
import moment from "moment"
import SelectWithAutocomplete from "components/SelectWithAutocomplete/SelectWithAutocomplete"
import Case from "case"
import {
  find,
  defaultTo,
  entries,
  flow,
  groupBy,
  map,
  pickBy,
  reduce,
  values,
  filter,
} from "lodash/fp"
import { makeStyles, Paper } from "@material-ui/core"
import React, { useContext } from "react"
import DocumentsFilterContext from "../../contexts/DocumentsFilterContext"
import clsx from "clsx"

export default function DocumentsFilterRoot({ className = "" }) {
  const { inputs } = useContext(DocumentsFilterContext)

  if (!inputs?.length) return null

  return (
    <div className={clsx("documents-filter-root", className)}>{inputs}</div>
  )
}
