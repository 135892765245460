import DocumentsDashboard from "components/DocumentsDashboard"
import React from "react"

export default function DocumentsEntryListItem({
  entry: document,
  listContainerWidth = 0,
}) {
  return (
    <DocumentsDashboard.EntryListItem
      title={document?.title}
      totalAmount={document?.totalAmount}
      type={document?.type}
      id={document?.id}
      listContainerWidth={listContainerWidth}
      entry={document}
    ></DocumentsDashboard.EntryListItem>
  )
}
