import classnames from "classnames"
import clsx from "clsx"
import { useContext, useRef, useState } from "react"
import { StyledProps } from "types/type"
import ZoomMediaContext from "../../context/MediaContext"
import ZoomClientContext from "../../context/ZoomClientContext"
import Avatar from "./components/avatar"
import Pagination from "./components/pagination"
import RemoteCameraControlPanel from "./components/remote-camera-control"
import ReportBtn from "./components/report-btn"
import ShareView from "./components/share-view"
import VideoFooter from "./components/video-footer"
import AvatarActionContext from "./context/avatar-context"
import { useAvatarAction } from "./hooks/useAvatarAction"
import { useActiveVideo } from "./hooks/useAvtiveVideo"
import { useCanvasDimension } from "./hooks/useCanvasDimension"
import { useGalleryLayout } from "./hooks/useGalleryLayout"
import { useNetworkQuality } from "./hooks/useNetworkQuality"
import { usePagination } from "./hooks/usePagination"
import { useVideoStyles } from "./useVideoStyles"
import { SELF_VIDEO_ID } from "./video-constants"

// import "./video.scss"

export function Video(props: StyledProps) {
  const zmClient = useContext(ZoomClientContext)

  const {
    mediaStream,
    video: { decode: isVideoDecodeReady },
  } = useContext(ZoomMediaContext)

  const videoRef = useRef<HTMLCanvasElement | null>(null)

  const shareViewRef = useRef<{
    selfShareRef: HTMLCanvasElement | HTMLVideoElement | null
  }>(null)

  const [isReceiveSharing, setIsReceiveSharing] = useState(false)

  const canvasDimension = useCanvasDimension(mediaStream, videoRef)
  const activeVideo = useActiveVideo(zmClient)
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(
    zmClient,
    canvasDimension
  )
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize,
    }
  )
  /**
   * position for self video
   */
  const currentUserIndex = visibleParticipants.findIndex(
    (user) => user.userId === zmClient.getCurrentUserInfo()?.userId
  )
  let selfVideoLayout = null
  if (currentUserIndex > -1) {
    const item = videoLayout[currentUserIndex]
    if (item && canvasDimension) {
      selfVideoLayout = {
        ...item,
        y: canvasDimension.height - item.y - item.height,
      }
    }
  }
  const avatarActionState = useAvatarAction(zmClient, visibleParticipants)
  const networkQuality = useNetworkQuality(zmClient)
  // return (
  //   <div className="viewport">
  //     <ShareView
  //       ref={shareViewRef}
  //       onRecieveSharingChange={setIsRecieveSharing}
  //     />
  //     <div
  //       className={classnames("video-container", {
  //         "video-container-in-sharing": isRecieveSharing,
  //       })}
  //     >
  //       <canvas
  //         className="video-canvas"
  //         id="video-canvas"
  //         width="800"
  //         height="600"
  //         ref={videoRef}
  //       />
  //       {selfVideoLayout && mediaStream?.isRenderSelfViewWithVideoElement() && (
  //         <video
  //           id={SELF_VIDEO_ID}
  //           className="self-video-tag"
  //           playsInline
  //           muted
  //           autoPlay
  //           style={{
  //             display: "block",
  //             width: `${selfVideoLayout.width}px`,
  //             height: `${selfVideoLayout.height}px`,
  //             top: `${selfVideoLayout.y}px`,
  //             left: `${selfVideoLayout.x}px`,
  //             pointerEvents: "none",
  //           }}
  //         />
  //       )}
  //       <AvatarActionContext.Provider value={avatarActionState}>
  //         <ul className="avatar-list">
  //           {visibleParticipants.map((user, index) => {
  //             if (index > videoLayout.length - 1) {
  //               return null
  //             }
  //             const dimension = videoLayout[index]
  //             const { width, height, x, y } = dimension
  //             const { height: canvasHeight } = canvasDimension
  //             return (
  //               <Avatar
  //                 participant={user}
  //                 key={user.userId}
  //                 isActive={activeVideo === user.userId}
  //                 networkQuality={networkQuality[`${user.userId}`]}
  //                 style={{
  //                   width: `${width}px`,
  //                   height: `${height}px`,
  //                   top: `${canvasHeight - y - height}px`,
  //                   left: `${x}px`,
  //                 }}
  //               />
  //             )
  //           })}
  //         </ul>
  //         <RemoteCameraControlPanel />
  //       </AvatarActionContext.Provider>
  //     </div>
  //     <VideoFooter
  //       className="video-operations"
  //       sharing
  //       selfShareCanvas={shareViewRef.current?.selfShareRef}
  //     />

  //     {totalPage > 1 && (
  //       <Pagination
  //         page={page}
  //         totalPage={totalPage}
  //         setPage={setPage}
  //         inSharing={isRecieveSharing}
  //       />
  //     )}
  //     <ReportBtn />
  //   </div>
  // )

  const c = useVideoStyles()

  return (
    <div className={clsx(c.root, props.className)} style={props.style}>
      <ShareView
        ref={shareViewRef}
        onRecieveSharingChange={setIsReceiveSharing}
      />
      <div
        className={classnames(c.videoContainer, {
          [c.videoContainerInSharing]: isReceiveSharing,
        })}
      >
        <canvas
          className={c.videoCanvas}
          id="video-canvas"
          width="800"
          height="600"
          ref={videoRef}
        />
        {selfVideoLayout && mediaStream?.isRenderSelfViewWithVideoElement() && (
          <video
            id={SELF_VIDEO_ID}
            className={c.selfVideoTag}
            playsInline
            muted
            autoPlay
            style={{
              display: "block",
              width: `${selfVideoLayout.width}px`,
              height: `${selfVideoLayout.height}px`,
              top: `${selfVideoLayout.y}px`,
              left: `${selfVideoLayout.x}px`,
              pointerEvents: "none",
            }}
          />
        )}
        <AvatarActionContext.Provider value={avatarActionState}>
          <ul className={c.avatarList}>
            {visibleParticipants.map((user, index) => {
              if (index > videoLayout.length - 1) {
                return null
              }
              const dimension = videoLayout[index]
              const { width, height, x, y } = dimension
              const { height: canvasHeight } = canvasDimension
              return (
                <Avatar
                  participant={user}
                  key={user.userId}
                  isActive={activeVideo === user.userId}
                  networkQuality={networkQuality[`${user.userId}`]}
                  style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    top: `${canvasHeight - y - height}px`,
                    left: `${x}px`,
                  }}
                />
              )
            })}
          </ul>
          <RemoteCameraControlPanel />
        </AvatarActionContext.Provider>
      </div>
      <VideoFooter
        className={c.videoOperations}
        sharing
        selfShareCanvas={shareViewRef.current?.selfShareRef}
      />
      {totalPage > 1 && (
        <Pagination
          page={page}
          totalPage={totalPage}
          setPage={setPage}
          inSharing={isReceiveSharing}
        />
      )}
      {/* <ReportBtn /> */}
    </div>
  )
}
