import React, { useContext } from "react"
import { FooterContext } from "./FooterContext"

// export function FooterProvider({ children }: { children: React.ReactNode }) {
//   const [mode, setMode] = useState(NO_SELECTED_MESSAGE)
//   const [messageID, setMessageID] = React.useState<string | null>(null)
//   const getMessage = useGetMessage()
//   const [hasFocus, setHasFocus] = useState(false)
//   useEffect(() => {
//     const selectedMessage$ = merge(
//       reply$.pipe(tap(({ messageID }) => messageID && setMode(REPLY_MESSAGE))),
//       edit$.pipe(tap(({ messageID }) => messageID && setMode(EDIT_MESSAGE)))
//     )
//     const sub = selectedMessage$.subscribe(({ messageID }) => {
//       setMessageID(messageID)
//       !messageID && setMode(NO_SELECTED_MESSAGE)
//     })
//     return () => sub.unsubscribe()
//   }, [])
//   const api = useAPI({ selectedMessageID: messageID })
//   const { stopTyping, startTyping } = useTyping()
//   React.useEffect(() => {
//     return () => {
//       stopTyping()
//     }
//   }, [])
//   const value = useMemo(() => {
//     return {
//       mode,
//       selectedMessage: messageID ? getMessage({ messageID }) : null,
//       ...api[mode],
//       submitMessage(message) {
//         api[mode].submitMessage(message)
//         api[mode].discardMessageSelection()
//       },
//       onFiles(files: File[]) {
//         api[mode].onFiles(files)
//         api[mode].discardMessageSelection()
//       },
//       onChange(text: string) {
//         if (!text) return stopTyping()
//         return hasFocus && startTyping()
//       },
//       onBlur() {
//         setHasFocus(false)
//         stopTyping()
//       },
//       onFocus() {
//         setHasFocus(true)
//       },
//     }
//   }, [mode, messageID, api])
//   return (
//     <FooterContext.Provider value={value}>
//       {typeof children === "function" ? children(value) : children}
//     </FooterContext.Provider>
//   )
// }
export function useFooterContext() {
  return React.useContext(FooterContext)
}
