import React from "react"
import { makeStyles } from "@material-ui/core"
import SelectUsersInput from "components/Input/components/SelectUsersInput/SelectUsersInput"
import CalendarEventContext from "components/CalendarEvent/contexts/CalendarEventContext"
import { useAutoCompleteSuggestions } from "components/SelectWithAutocomplete/SelectWithAutocomplete"
import useMemoAPI from "hooks/useMemoAPI"
import { Option } from "components/SelectWithAutocomplete/SelectWithAutocomplete copy"
import { UserFragment } from "lib/graphql/operations"

export default function useAddMembersInputPageAPI(props?: {
  onClose?: () => any
}) {
  const api = React.useContext(CalendarEventContext)
  const classes = useStyles()

  const selectUsersAPI = SelectUsersInput.useAPI({
    fetchPolicy: "cache-and-network",
    ...api.form.getFieldProps("members"),
  })

  const autoCompleteSuggestions = useAutoCompleteSuggestions({
    options: selectUsersAPI.options,
  })

  const hideSuggestions = React.useCallback(() => {
    autoCompleteSuggestions.clearTextInput()
    autoCompleteSuggestions.hideSuggestions()
  }, [
    autoCompleteSuggestions.clearTextInput,
    autoCompleteSuggestions.hideSuggestions,
  ])

  const inputRef = React.useRef<HTMLInputElement | null>(null)

  const selectOption = React.useCallback(
    (option: Option<UserFragment>) => {
      selectUsersAPI.addUserOption(option)
      hideSuggestions()

      inputRef.current?.focus()
    },
    [selectUsersAPI.addUserOption, hideSuggestions, inputRef]
  )

  return useMemoAPI({
    ...(props || {}),
    inputRef,
    autoCompleteSuggestions,
    selectUsersAPI,
    classes,
    hideSuggestions,
    selectOption,
  })
}

export const useStyles = makeStyles((theme) => {
  return {}
})
