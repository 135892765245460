import React from "react"
import { useLoadingState } from "components/PageLayoutFromPageID/PageLayoutFromPageID"
import UploadDocuments from "components/OldUploadDocuments/UploadDocuments"
import { PartnersTabsIDs } from "pages/Partners/types"
import PartnersContext from "pages/Partners/contexts/PartnersContext"

export const documents = {
  label: "Dokumente",
  id: PartnersTabsIDs.DOCUMENTS,
  component: (
    <PartnersContext.Consumer>
      {({ CollectionDashboard }) => {
        return <CollectionDashboard.Root></CollectionDashboard.Root>
      }}
    </PartnersContext.Consumer>
  ),
}

export const upload = {
  label: "Hochladen",
  id: PartnersTabsIDs.UPLOAD,
  Component: function UploadTab({ partnerID, collectionsIDs, className = "" }) {
    const [, setLoadingState] = useLoadingState()

    return (
      <UploadDocuments
        collectionsIDs={collectionsIDs}
        partnerID={partnerID}
        className={className}
        onChangeLoadingState={setLoadingState}
      ></UploadDocuments>
    )
  },
}
