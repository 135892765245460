import React, { useState, useRef, useEffect } from "react"
import { useContext } from "react"
import ConnectionStatus from "components/ConnectionStatus/ConnectionStatus"
import {
  Collapse,
  Paper,
  Typography,
  makeStyles,
  Icon,
} from "@material-ui/core"
import useIsMounted from "hooks/useIsMounted"
import { useI18n } from "contexts/i18nContext/i18nContext"
import clsx from "clsx"
import IconButton from "components/IconButton/IconButton"
import { purple, red } from "constants/colors"

export default function ConnectionStatusWarning() {
  const { isOnline, showConnectionStatus } = useContext(
    ConnectionStatus.Context
  )
  const [show, setShow] = useState(showConnectionStatus)

  useEffect(() => {
    setShow(showConnectionStatus)
  }, [showConnectionStatus])

  const t = useI18n()
  const c = useStyles({})

  return (
    <Collapse in={show}>
      <Paper
        elevation={4}
        className={clsx(c.paper, c.isOffline, isOnline && c.isOnline)}
        square
      >
        <IconButton
          className={c.close}
          iconName={"close"}
          onClick={() => setShow(false)}
          size="small"
          edge="start"
        />
        <Typography className={c.content}>
          <Icon className={c.icon}>{isOnline ? "wifi" : "wifi_off"}</Icon>{" "}
          {t[isOnline ? "you-are-back-online" : "you-are-offline"]}
        </Typography>
      </Paper>
    </Collapse>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    close: {
      position: "absolute",
      left: theme.spacing(2),
      top: "50%",
      transform: "translateY(-50%)",
      color: "white",
    },
    paper: {
      position: "relative",
      textAlign: "center",
      transition: "all 300ms",
    },
    icon: {
      marginRight: theme.spacing(0.5),
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      padding: theme.spacing(0.25),
    },
    isOffline: {
      background: red[0],
    },
    isOnline: {
      background: theme.palette.secondary.main,
    },
  }
})
