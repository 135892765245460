export async function copyToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text)
    console.log("Text copied to clipboard")
  } catch (err) {
    oldClipboardMethod(text)
    console.error("Error in copy to clipboard: ", err)
  }
}

export function oldClipboardMethod(text: string) {
  var textarea = document.createElement("textarea")
  textarea.value = text

  document.body.appendChild(textarea)

  setTimeout(() => {
    textarea.select()
    textarea.setSelectionRange(0, 99999) /*For mobile devices*/
    document.execCommand("copy")
    document.body.removeChild(textarea)
  }, 0)
}
