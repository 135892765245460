import useMemoAPI from "hooks/useMemoAPI"
import { Observable } from "rxjs"
import { MessagesRendered } from "components/Room/types"
import useReplaySubject from "hooks/rxjs/useReplaySubject"
import { useObservableState } from "observable-hooks"
import useCountAPI from "./useCountAPI"
import useLoadedFirstUnreadIndex$ from "./useLoadedFirstUnreadIndex$"
import useGuideLine from "./useGuideLine"

export default function useUnreadAPI(props: {
  messagesRendered$: Observable<MessagesRendered>
}) {
  const loadedFirstUnreadIndex$ = useLoadedFirstUnreadIndex$()

  const messagesRendered$ = useReplaySubject(props.messagesRendered$, {
    bufferSize: 1,
  })

  const messagesRendered = useObservableState(messagesRendered$)

  const guideLine = useGuideLine({
    loadedFirstUnreadIndex$: loadedFirstUnreadIndex$,
  })

  const count = useCountAPI({
    messagesRendered,
    loadedFirstUnreadIndex$,
    messagesRendered$,
  })

  const api = useMemoAPI({
    count,
    thisMessageHasGuideLine: guideLine.hasUnreadLine,
  })

  return api
}
