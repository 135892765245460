import { getTypesOptions } from "components/PageBoard/hooks/useTypesOptions"
import {
  PartnerTextField,
  PartnerTextFieldProps,
} from "components/PartnerTextField/PartnerTextField"
import { PageBoardItemType } from "lib/graphql/types"
import { I18nAPI } from "contexts/i18nContext/i18nContext"
import { FieldsetIndentationProps } from "../components/FieldsetIndentation/FieldsetIndentation"

export default function getPartnerFieldProps(props: {
  translations: I18nAPI
  PartnerTextFieldProps?: PartnerTextFieldProps
}) {
  const options = getTypesOptions({ translations: props.translations })

  const partner = props.PartnerTextFieldProps?.partner

  if (!partner) return null

  const a = {
    VENDOR: PageBoardItemType.Vendor,
    LEAD: PageBoardItemType.Lead,
    CUSTOMER: PageBoardItemType.Customer,
  }

  const icon = options[a[partner?.type as keyof typeof a]]?.icon

  const indentationProps: FieldsetIndentationProps = {
    icon: icon || "business",
    children: (
      <PartnerTextField
        {...(props.PartnerTextFieldProps || {})}
      ></PartnerTextField>
    ),
  }

  return indentationProps
}
