import React from "react"
import { RoomContext } from "components/Room/contexts/room"
import { filter, map, take, withLatestFrom } from "rxjs/operators"

export default function useLoadedFirstUnreadIndex$() {
  const { room$, roomWasFetched$ } = React.useContext(RoomContext)

  const loadedIndex$ = React.useMemo(() => {
    return roomWasFetched$.pipe(
      filter((value) => !!value),
      take(1),
      withLatestFrom(room$),
      map(([__, room]) => {
        return room?.notifications?.firstUnreadMessageIndex
      })
    )
  }, [roomWasFetched$, room$])

  return loadedIndex$
}
