import _ from "lodash"
import { makeStyles } from "@material-ui/styles"
import React, { useContext } from "react"
import clsx from "clsx"
import { Typography, Icon } from "@material-ui/core"
import { DropzoneContext } from "../../contexts/dropzone"
import { states } from "../../constants"

const { IDLE, REJECT, ACCEPT } = states

export default function DropzoneArea({
  className = "",
  acceptFileMessage = "",
  rejectFileMessage = "",
  idleMessage = "",
  style,
}: {
  className?: string
  acceptFileMessage?: string
  rejectFileMessage?: string
  idleMessage: string
  style?: React.CSSProperties
}) {
  const { dropzoneState, open } = useContext(DropzoneContext)

  const c = useStyles({})

  return (
    <div className={clsx(c.dropzone, className)} onClick={open} style={style}>
      <div className={clsx(c.dropzoneContent)}>
        <Icon className={c.uploadIcon} fontSize="large">
          {
            {
              [REJECT]: "block_circle",
              [ACCEPT]: "move_to_inbox",
              [IDLE]: "cloud_upload",
            }[dropzoneState]
          }
        </Icon>
        <Typography variant="body1">
          {
            {
              [REJECT]: rejectFileMessage,
              [ACCEPT]: acceptFileMessage,
              [IDLE]: idleMessage,
            }[dropzoneState]
          }
        </Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    dropzone: {
      height: "100%",
      background: "#f5f5f5",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.secondary,
      cursor: "pointer",
    },
    uploadIcon: {
      fontSize: "4rem",
      color: "inherit",
    },
    dropzoneContent: {
      maxWidth: 200,
      color: "inherit",
      textAlign: "center",
    },
  }
})
