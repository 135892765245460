import { ID } from "types/type"
import getAccountRootPathname from "util/getAccountRootPathname"
import useWorkspace from "hooks/useWorkspace/useWorkspace"

export default function useWorkspaceRootPathname(props: {
  workspaceID: ID | null
}) {
  const { workspace } = useWorkspace({ workspaceID: props.workspaceID || "" })

  if (!workspace || !props.workspaceID) return null

  const accountID = workspace?.account?.id
  const guestUserID = workspace?.type === "GUEST" ? workspace?.user?.id : null

  const rootPathname = getAccountRootPathname({ accountID, guestUserID })

  return rootPathname
}
