import React from "react"
import Modal, { ModalProps } from "components/Modal/Modal"
import {
  Button,
  ButtonProps,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import { UnionOmit } from "types/type"

export default function DeleteModal(props: {
  isOpen: boolean
  onClose: () => any
  onDelete: () => any
  warningText: string
}) {
  const t = useI18n()
  const c = useStyles()

  const templatePageApi = React.useContext(ItemTemplate.Context)
  const isSmallViewport = templatePageApi?.rootContainer.width < 600 ?? false
  const buttonSize: ButtonProps["size"] = isSmallViewport ? "medium" : "small"

  // const modalProps: UnionOmit<ModalProps, "isOpen" | "onClose" | "children"> =
  //   isSmallViewport ? { variant: "bottom-modal" } : {}

  return (
    <Modal
      variant={isSmallViewport ? "bottom-modal" : "dialog"}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Paper square elevation={3} className={c.container}>
        <Typography className={c.warning}>{props.warningText}</Typography>

        <div className={isSmallViewport ? c.stackedButtons : c.inlineButtons}>
          <Button
            variant="contained"
            color="primary"
            onClick={props.onClose}
            size={buttonSize}
          >
            {t["cancel"]}
          </Button>

          <Button color="primary" onClick={props.onDelete} size={buttonSize}>
            {t["delete"]}
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}
const useStyles = makeStyles((theme) => ({
  container: { padding: theme.spacing(1, 2) },
  warning: { textAlign: "center", marginBottom: theme.spacing(1) },
  stackedButtons: {
    display: "flex",
    flexDirection: "column",
    "&>*:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },

  inlineButtons: {
    marginTop: theme.spacing(2),

    "&>*:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
}))
