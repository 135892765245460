import React from "react"
import { CreateFreeRoomMutation } from "lib/graphql/operations"
import { useCreateFreeRoomMutation } from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import updateRoomsQuery from "../../lib/updateRoomsQuery"

export default function useCreateFreeRoom() {
  const [mutation, { loading: isLoading }] = useCreateFreeRoomMutation()

  const { workspaceID } = useActiveResources()

  const createFreeRoom = React.useCallback(
    async (props: { name: string }) => {
      return new Promise<CreateFreeRoomMutation["createFreeRoom"]>(
        async (resolve, reject) => {
          const mutationResult = await mutation({
            variables: { name: props.name, workspaceID },
            update(dataProxy, { data }) {
              const newRoom = data?.createFreeRoom

              if (!newRoom) return

              updateRoomsQuery.addRoom(newRoom, dataProxy)
            },
          })

          if (mutationResult.errors) {
            return reject(mutationResult.errors)
          }

          resolve(mutationResult.data?.createFreeRoom)
        }
      )
    },
    [mutation, workspaceID]
  )

  return useMemoAPI({ isLoading, createRoom: createFreeRoom })
}
