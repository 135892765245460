import { PaperListApiInput } from "components/PaperList/types"
import RootContainer from "components/RootContainer/RootContainer"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { List } from "../../List/List"

export function usePaperListAPI(props: PaperListApiInput) {
  const list = React.useContext(List.Context)

  return useMemoAPI({
    ...props,
    ...list,
    RootContainer,
  })
}
