import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import WindowSizeContext from "components/WindowSize/contexts/WindowSizeContext"
import { white } from "constants/colors"
import React from "react"
import PageBoardContext from "../../contexts/PageBoardContext"
import { IndexedItems } from "../IndexedItems/IndexedItems"
import ItemDetails from "../ItemDetails/ItemDetails"
import TopBar from "./components/TopBar/TopBar"
import { RoomConference } from "./Conference/RoomConference"

export type PageBoardRootProps = {
  className?: string
  style?: React.CSSProperties
}

export default function PageBoardRoot(props: PageBoardRootProps) {
  const api = React.useContext(PageBoardContext)
  const c = useStyles()

  const windowSize = React.useContext(WindowSizeContext)

  const ac = api.classes

  const listIsVisible = api.listIsVisible

  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (!listIsVisible) return
    inputRef.current?.focus()
  }, [listIsVisible])

  return (
    <div
      className={clsx(c.root, props.className, "start-page-board")}
      style={props.style}
      ref={api.template.rootContainer.containerRef}
    >
      <TopBar
        className={c.topBar}
        // style={(() => {
        //   if (windowSize.width < 600)
        //     return { marginLeft: -12, marginRight: -12 }

        //   return {}
        // })()}
      ></TopBar>
      <div className={clsx(c.body, ac.mainWidth, ac.center)}>
        {(() => {
          const showDetails = !listIsVisible && !!api.selected.params

          return (
            <React.Fragment>
              {showDetails ? (
                <ItemDetails
                  style={{
                    height: "95%",
                  }}
                ></ItemDetails>
              ) : null}

              <div
                style={showDetails ? { display: "none" } : {}}
                className={clsx(c.viewWrapper)}
              >
                <IndexedItems.ItemsList.Root
                  style={{ height: "100%" }}
                  className="items-list-root"
                ></IndexedItems.ItemsList.Root>
                {/* <h1>hey</h1> */}

                {/* <ZoomVideoMeeting style={{ height: "100%" }}></ZoomVideoMeeting> */}

                {/* <LabVideoConference roomID="exeAYJWNJErAg5H5v"></LabVideoConference> */}
                {/* <LabVideoConference roomID="Dd6BTFS88mHus5SEhqDSQdfKbNcYm933Le"></LabVideoConference> */}
                {/* <RoomConference
                  roomID="AizDs9G6dT35oSmKm"
                  style={{ height: "100%", marginTop: 8 }}
                  onLeave={() => console.log("left")}
                ></RoomConference> */}
              </div>
            </React.Fragment>
          )
        })()}
      </div>
    </div>
  )
}

// function LabVideoConference(props: { roomID: string }) {
//   return (
//     <RoomVideoConference roomID={props.roomID}>
//       {(p) => {
//         return (
//           <ZoomProvider meetingArgs={p.meetingArgs}>
//             <></>
//           </ZoomProvider>
//         )
//       }}
//     </RoomVideoConference>
//   )
// }

const useStyles = makeStyles((theme) => {
  return {
    viewWrapper: {
      position: "relative",

      "&>.items-list-root": {
        height: "100%",
      },

      "&>.add-item-to-list-button": {
        position: "absolute",
        right: theme.spacing(1),
        bottom: theme.spacing(1),
      },
    },
    topBar: {
      padding: theme.spacing(2, 0, 1),
    },
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: white[1],
    },

    body: {
      flex: 1,
      // marginBottom: theme.spacing(2),
      "&>*": {
        height: "100%",
      },
    },
  }
})
