import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import { Subject } from "rxjs"
import { StyledProps } from "types/type"
import { MobileHiddenXScrollbarContext } from "./MobileHiddenXScrollbarContext"

export function MobileHiddenXScrollbarRoot(
  props: {
    children:
      | React.ReactNode
      | ((args: {
          scroll$: Subject<HTMLElement>
          containerMounted$: Subject<HTMLElement>
        }) => React.ReactNode)
  } & StyledProps
) {
  const api = React.useContext(MobileHiddenXScrollbarContext)
  const c = useScrollbarStyles({ isActive: api.isActive })

  const children =
    typeof props.children === "function"
      ? props.children({
          containerMounted$: api.containerMounted$,
          scroll$: api.scroll$,
        })
      : props.children

  if (!api.isActive) return <>{children}</>

  return (
    <div
      className={clsx(c.root, "mobile-hidden-x-scrollbar", props.className)}
      style={props.style}
      onScroll={api.onScroll}
      ref={(e) => {
        api.setContainerRef(e)
      }}
    >
      {children}
    </div>
  )
}
export const useScrollbarStyles = makeStyles((theme) => {
  return {
    root: {
      overflow: "auto",
      position: "relative",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  }
})
