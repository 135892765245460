import useMemoAPI from "hooks/useMemoAPI"
import useTrueFalse from "hooks/useTrueFalse"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import React from "react"
import { Subject } from "rxjs"
import { Subscription } from "rxjs/internal/Subscription"
import { LeaveVideoSessionDialog } from "./LeaveConferenceManager/LeaveConferenceDialog"

export function useConferenceActivity() {
  const isActive = useTrueFalse(false)
  const leavingDialogIsOpen = useTrueFalse(false)

  const userAgreeToClose$ = React.useRef(new Subject<void>()).current

  const subs = React.useRef<Subscription[]>([])

  const leaveSessionIfActive = React.useCallback(
    (callback: () => any) => {
      if (isActive.isTrue) {
        const sub = userAgreeToClose$.subscribe(() => {
          callback()
        })

        leavingDialogIsOpen.setTrue()
        subs.current.push(sub)
      } else {
        callback()
      }
    },
    [isActive.isTrue]
  )

  const userWantsToLeave = React.useCallback(() => {
    userAgreeToClose$.next()
    leavingDialogIsOpen.setFalse()
    isActive.setFalse()
  }, [])

  const userWantsToStay = React.useCallback(() => {
    leavingDialogIsOpen.setFalse()
    subs.current.forEach((sub) => sub.unsubscribe())
    subs.current = []
  }, [])

  return useMemoAPI({
    leavingDialogIsOpen,
    isActive,
    userWantsToLeave,
    userWantsToStay,
    handleSessionExit: leaveSessionIfActive,
  })
}

export const F = contextAndProviderFactory({
  hookApi: useConferenceActivity,
  contextName: "ConferenceApiContext",
})

export const ConferenceActivityApiProvider = F.Provider
export const ConferenceActivityApiContext = F.Context

export const ConferenceActivity = {
  Provider: function ConferenceActivityProvider(props: {
    children: React.ReactNode
  }) {
    return (
      <ConferenceActivityApiProvider>
        <>
          {props.children}
          <LeaveVideoSessionDialog />
        </>
      </ConferenceActivityApiProvider>
    )
  },
  Context: ConferenceActivityApiContext,
}
