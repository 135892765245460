import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { useContext } from "react"
import { ListContext } from "./ListContext"

export function Item(props: {
  index: number
  // fetchingPoint?: number
  // lastIndex?: number
  children: (p: {
    entry: any
    isFirst: boolean
    isFetchingPoint: boolean
    isLast: boolean
  }) => React.ReactNode
}) {
  const api = useContext(ListContext)

  const { index } = props

  const entry = api.items ? api.items[index] : null

  const isFirst = index === 0

  const lastIndex = (api.items?.length || 0) - 1

  const isLast = index === lastIndex

  const fetchingPoint = api.fetchingPoint

  const isFetchingPoint = fetchingPoint === index

  React.useEffect(() => {
    // console.log(index, fetchingPoint)
    if (fetchingPoint === index) {
      api.fetchMore?.call(undefined)
    }
  }, [])

  const v = useMemoAPI({ entry, isFirst, isLast, isFetchingPoint })

  return <>{props.children(v)}</>
}
