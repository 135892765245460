import useMemoAPI from "hooks/useMemoAPI"
import useTrueFalse from "./useTrueFalse"

export default function useOpenClose(initialValue?: boolean) {
  const trueFalse = useTrueFalse(initialValue)

  return useMemoAPI({
    isOpen: trueFalse.isTrue,
    open: trueFalse.setTrue,
    close: trueFalse.setFalse,
    toggle: trueFalse.toggle,
  })
}

export type OpenCloseApi = ReturnType<typeof useOpenClose>
