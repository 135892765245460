import { Icon, IconButton, makeStyles } from "@material-ui/core"
import useComponentSize from "@rehooks/component-size"
import clsx from "clsx"
import { excludeNullable } from "lib/excludeNullable"
import React from "react"
import { CarouselContext } from "./CarouselContext"
import image from "./image-placeholder.png"

export function CarouselRoot() {
  const { selectedIndex, setSelectedIndex, images } =
    React.useContext(CarouselContext)

  const divRef = React.useRef<HTMLDivElement>(null)
  const size = useComponentSize(divRef)

  const i = [...new Set((images || []).filter(excludeNullable))]

  const c = useStyles({
    hasMoreThanOneImage: (i?.length || 0) > 1,
    rootWidth: size.width,
  })

  return (
    <div className={clsx(c.carrousel, CarouselRoot.name)} ref={divRef}>
      {(i || []).length > 1 && (
        <IconButton
          disabled={selectedIndex <= 0}
          onClick={() => setSelectedIndex((i) => i - 1)}
        >
          <Icon fontSize="small">chevron_left</Icon>
        </IconButton>
      )}
      <img
        src={i[selectedIndex || 0] || image}
        alt={"Carousel Image " + selectedIndex}
        className={c.img}
      />
      {i.length > 1 && (
        <IconButton
          disabled={selectedIndex >= i.length - 1}
          onClick={() => setSelectedIndex((i) => i + 1)}
        >
          <Icon fontSize="small">chevron_right</Icon>
        </IconButton>
      )}
    </div>
  )
}

type Style = {
  hasMoreThanOneImage: boolean
  rootWidth: number
}

const useStyles = makeStyles(() => ({
  carrousel: {
    display: "flex",
    alignItems: "center",
    justifyContent: ({ hasMoreThanOneImage }: Style) =>
      hasMoreThanOneImage ? "space-between" : "center",
  },
  img: {
    display: "block",
    maxWidth: ({ rootWidth }: Style) => {
      const size =
        (rootWidth < 700 && "75%") ||
        (rootWidth < 900 && "50%") ||
        (rootWidth < 1200 && "33%") ||
        "400px"

      return size
    },
    flex: 1,
  },
}))
