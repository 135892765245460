import useMemoAPI from "hooks/useMemoAPI"
import { PageBoardVisitationHistoryEntryFragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"

export function useMostUsedTypes(props: {
  history: PageBoardVisitationHistoryEntryFragment[]
  types: PageBoardItemType[]
}) {
  const [maxWidthAvailable, setMaxWidthAvailable] = React.useState(0)

  const estimatedWidthIcon = 34 //verified in the browser

  const totalTypesAllowed = Math.floor(maxWidthAvailable / estimatedWidthIcon)

  const count = React.useMemo(() => {
    return props.history?.reduce((acc, h) => {
      if (!acc[h.type]) acc[h.type] = 0
      acc[h.type] += 1
      return acc
    }, {} as Record<PageBoardItemType, number>)
  }, [props.history])

  let types: PageBoardItemType[] = []

  if (props?.history?.length === 0) {
    types = props.types
  } else {
    types = [...(props.types || [])].sort((typeA, typeB) => {
      const countA = count?.[typeA] || 0
      const countB = count?.[typeB] || 0

      if (countA > countB) return -1

      return 1
    })
  }
  const obj = React.useMemo(
    () => ({
      visible: types.slice(0, totalTypesAllowed),
      hidden: types.slice(totalTypesAllowed),
    }),
    [types, totalTypesAllowed]
  )

  return useMemoAPI({ ...obj, setMaxWidthAvailable })
}
