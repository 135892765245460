import Hook from "components/Hook/Hook"
import { OutlinedButtonWithIconAndTextCentered } from "components/OutlinedButtonWithIconAndTextCentered"
import { PageComponentProps } from "components/Page/Page"
import { GetItemParams } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/hooks/usePageBoardItem"
import { useFetchPageBoardItemFromParams } from "components/PageBoard/components/IndexedItems/hooks/useFetchPageBoardItemFromParams"
import { Shop } from "components/Shop/Shop"
import I18nContext from "contexts/i18nContext/i18nContext"
import { PageBoardItemType } from "lib/graphql/types"
import { StyledProps } from "types/type"
import { SecondPage } from "../../../../SecondPage/SecondPage"
import { ShopArticlePageBoardItem } from "../../../../ShopArticlePageBoardItem/ShopArticlePageBoardItem"
import { useShareItem } from "../../../hooks/useShareItem"
import { CartButton } from "../CartButton/CartButton"

export function ShoppingArticlePage(
  props: PageComponentProps & {
    getItemParams?: GetItemParams<PageBoardItemType.ShopArticle> | null
  }
) {
  return (
    <SecondPage
      getItemParams={props.getItemParams || undefined}
      onClose={props.onClose}
    >
      {(args) => {
        const pageBoardItemApi = args.api

        return (
          <Shop.Context.Consumer>
            {(shopApi) => {
              const item = pageBoardItemApi.item
              if (!item) return null

              if (item.__typename !== "ShopArticlePageBoardItem") return null

              return (
                <ShopArticlePageBoardItem
                  onClose={props.onClose}
                  renderTitleLine={() => {
                    return (
                      <>
                        <div></div>
                        <CartButton></CartButton>
                      </>
                    )
                  }}
                  ArticleDetailPanelProps={{
                    ShareButton,
                    CartButtons: Shop.PageCartButtons,
                    CartItemQuantity: Shop.CartItemQuantity,
                    getQuantity: shopApi.cartApi.getItemQuantity,
                  }}
                ></ShopArticlePageBoardItem>
              )
            }}
          </Shop.Context.Consumer>
        )
      }}
    </SecondPage>
  )
}

function ShareButton(props: StyledProps & { articleID: string }) {
  const a = useShareItem()

  return (
    <I18nContext.Consumer>
      {(t) => {
        return (
          <Hook hook={useFetchPageBoardItemFromParams} hookProps={undefined}>
            {(f) => {
              return (
                <OutlinedButtonWithIconAndTextCentered
                  icon="share"
                  text={t.share}
                  variant="outlined"
                  onClick={async function shareArticle() {
                    f(PageBoardItemType.ShopArticle, {
                      articleID: props.articleID,
                    })
                      .then((item) => {
                        if (item?.__typename !== "ShopArticlePageBoardItem")
                          return

                        return a.share({
                          params: [
                            {
                              type: item.type,
                              itemID: item.itemID || "",
                            },
                          ],
                        })
                      })
                      .catch((err) => {
                        console.error(err)
                      })
                  }}
                ></OutlinedButtonWithIconAndTextCentered>
              )
            }}
          </Hook>
        )
      }}
    </I18nContext.Consumer>
  )

  // return (
  //   <PageBoardItem.Context.Consumer>
  //     {(pageBoardItemApi) => {
  //       const item = pageBoardItemApi.item
  //       if (!item) return null

  //       if (item.__typename !== "ShopArticlePageBoardItem") return null

  //       return (

  //       )
  //     }}
  //   </PageBoardItem.Context.Consumer>
  // )
}
