import _ from "lodash"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import useHomeWorkspace from "./useHomeWorkspace"
import { useWorkspacesQuery, WorkspaceFragment } from "lib/graphql/operations"
import { WatchQueryFetchPolicy } from "@apollo/client"
import { workspaces } from "../../../graphql-server/lib/apollo/resolvers/workspace/workspaces"

export default function useWorkspaces(props?: {
  fetchPolicy: WatchQueryFetchPolicy
}) {
  const fetchPolicy = props?.fetchPolicy || "cache-first"

  const { homeWorkspace, isLoading: isLoadingHomeWorkspace } = useHomeWorkspace(
    { fetchPolicy }
  )

  const queryResult = useWorkspacesQuery({
    skip: !homeWorkspace?.id,
    fetchPolicy: props?.fetchPolicy,
  })

  const workspacesData =
    queryResult?.data?.workspaces || queryResult?.previousData?.workspaces || []

  const workspaces = React.useMemo(() => {
    return _.uniqBy([homeWorkspace, ...workspacesData], "id")?.filter(
      (a): a is WorkspaceFragment => !!a
    )
  }, [workspacesData, homeWorkspace])

  const workspacesByID = React.useMemo(
    () => _.keyBy(workspaces, "id"),
    [workspaces]
  )

  const getWorkspace = React.useCallback(
    (props: { workspaceID: string }) => {
      const value = workspacesByID[props.workspaceID] || null
      return value
    },
    [workspacesByID]
  )

  return useMemoAPI({
    workspaces,
    isLoading: isLoadingHomeWorkspace || queryResult?.loading,
    getWorkspace,
  })
}
