import { makeStyles } from "@material-ui/core"
import useComponentSize from "@rehooks/component-size"
import clsx from "clsx"
import { PriceDescription } from "components/ShopArticleCard/ShopArticleCard"
import { ShopArticleFragment } from "lib/graphql/operations"
import React, { useRef } from "react"
import { StyledProps } from "types/type"
import { ArticleDetailPanelContext } from "../../ArticleDetailPanelContext"

export default function Price({
  article,
  className,
}: { article: ShopArticleFragment } & StyledProps) {
  const c = useStyles()
  const articleID = article.id

  const api = React.useContext(ArticleDetailPanelContext)
  const quantity = api.quantity

  const ref = useRef(null)
  const { width } = useComponentSize(ref)

  return (
    <div className={clsx(c.price, className)} ref={ref}>
      <div className={c.left}>
        {article.price?.value && (
          <PriceDescription
            classes={{ totalPrice: c.totalPrice }}
            price={article.price?.value}
            typographyVariant={{ totalPrice: "h4", unit: "caption" }}
            quantity={quantity || 0}
          />
        )}
      </div>
      {api.CartItemQuantity && (
        <api.CartItemQuantity
          articleID={articleID}
          className={c.cartItemQuantity}
        ></api.CartItemQuantity>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    price: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
    },
    left: {
      display: "flex",
      alignItems: "center",
    },
    cartItemQuantity: {
      maxWidth: 100,
    },
    totalPrice: {
      marginRight: theme.spacing(1),
    },
  }
})
