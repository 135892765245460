import _ from "lodash"
import React from "react"
import { useContext } from "react"
import ChatContext from "components/Chat/contexts/ChatContext"
import PaperList from "components/PaperList"
import ChatListItem from "./components/ChatListItem/ChatListItem"
import clsx from "clsx"
import { ChatListRoot } from "./components/ChatListRoot/ChatListRoot"
import { makeStyles } from "@material-ui/core"
import AddRoomButton from "../AddRoomButton/AddRoomButton"
import arePropsEqual from "util/arePropsEqual"

const MemoizedChatListItem = React.memo(
  ChatListItem,
  arePropsEqual([(a) => a.entry?.id, (a) => a.entry?.lastMessage?.id])
)

export default function ChatList(props: {
  className?: string
  style?: React.CSSProperties
}) {
  const { rooms } = useContext(ChatContext)
  const { RootContainer } = useContext(ChatContext)
  const { isSmall } = useContext(RootContainer.Context)
  const c = useStyles()

  return (
    <PaperList.Provider items={rooms} ListItem={MemoizedChatListItem}>
      <div
        className={clsx("chat-list", c.chatList, props.className)}
        style={props.style}
      >
        <ChatListRoot marginZero={!!isSmall}></ChatListRoot>e{" "}
        <AddRoomButton className="add-room-button"></AddRoomButton>
      </div>
    </PaperList.Provider>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    chatList: {
      position: "relative",
      "&>.add-room-button": {
        position: "absolute",
        bottom: theme.spacing(4),
        right: theme.spacing(4),
      },
    },
  }
})
