import React from "react"
import ReactDOM from "react-dom"
import uniqid from "uniqid"

const LAYER_ID = uniqid("highest-zIndex-layer-root-")

export const HIGHEST_POSSIBLE_Z_INDEX = 2147483647
const HighestZIndexLayer = {
  Root: function LayerRoot() {
    return (
      <div
        id={LAYER_ID}
        //Creating a z-index context with highest value where we will render modals' content
        // This component will be rendered in the root of our tree and host children created by `ReactDOM.createPortal`

        // About z-index contexts: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
        style={{ position: "relative", zIndex: HIGHEST_POSSIBLE_Z_INDEX }}
      ></div>
    )
  },
  Portal: function LayerPortal(props: {
    children:
      | ((props: { zIndex: number }) => React.ReactChild)
      | React.ReactChild
  }) {
    return ReactDOM.createPortal(
      typeof props.children === "function"
        ? props.children({ zIndex: HIGHEST_POSSIBLE_Z_INDEX })
        : props.children,
      document.querySelector(`#${LAYER_ID}`) as HTMLElement
    )
  },
}

export default HighestZIndexLayer
