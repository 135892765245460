import React, { ForwardedRef } from "react"
import Slider from "react-slick"
import clsx from "clsx"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import { makeStyles } from "@material-ui/core"
import CardImage from "../CardImage/CardImage"
import {
  KanbanCardImagesFragment,
  KanbanImageFragment,
} from "lib/graphql/operations"
import useOnChange from "hooks/useOnChange"

export default React.forwardRef(ImagesCarousel)

function ImagesCarousel(
  props: {
    className?: string
    children: (i: KanbanImageFragment) => React.ReactNode
    SliderProps?: React.ComponentProps<typeof Slider>
    activeSlide?: number
  },
  ref: ForwardedRef<Slider>
) {
  const api = React.useContext(KanbanCardPageBoardItemContext)
  const images = api.images.data

  useOnChange({
    value: api.images.modal.selected.index,
    onChange(index) {
      // console.log("labo", api.images.modal.isOpen)
      api.images.modal.sliderRef.current?.slickGoTo(index, true)
    },
  })

  if (!images?.list?.length) return null

  const list = (images.list || [])
    .map((i) => {
      return (
        <div key={i.id} className="image-item-a">
          {props.children(i)}
        </div>
      )
    })
    .filter((a): a is Exclude<typeof a, null> => !!a)

  if (!list?.length) return null

  return (
    <Slider
      {...(props.SliderProps || {})}
      // ref={ref}
      ref={ref}
      className={clsx(props.className, "images-carousel")}
    >
      {list}
    </Slider>
  )
}

const useStyles = makeStyles((theme) => {
  return {}
})
