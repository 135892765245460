import { PageBoardItemType } from "lib/graphql/types"
import { useMemo, ReactNode } from "react"

export default function Memo<T>(props: {
  factory: () => T
  deps: Array<any>
  children: (memoized: T) => JSX.Element
}) {
  const memoized = useMemo(props.factory, [props.deps])
  return props.children(memoized)
}
