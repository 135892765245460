import useLogout from "hooks/useLogout/useLogout"
import React from "react"
import unauthenticated$ from "../../lib/unauthenticated$"

import { useSubscription } from "observable-hooks"

export default function AuthenticationController(props: {
  children: React.ReactChild
}) {
  const api = useLogout()

  useSubscription(unauthenticated$, api.logout)

  return <>{props.children}</>
}
