import { useMutation, gql } from "@apollo/client"
import _ from "lodash"
import useActiveResources from "hooks/useActiveResources"
import { useCallback, useMemo } from "react"
import useSequentialUpload from "./useSequentialUpload"
import { UploadDocumentsDocument } from "lib/graphql/operations"

export default function useUploadDocuments({ partnerID, collectionsIDs }) {
  const { workspaceID } = useActiveResources()
  const [mutate, result] = useMutation(UploadDocumentsDocument, { partnerID })

  const uploadFile = useCallback(
    function upload(file) {
      return mutate({
        variables: {
          workspaceID,
          partnerID,
          collectionsIDs: collectionsIDs || [],
          files: [file].map((f) => ({
            filename: f.filename,
            collectionType: f.collectionType,
            text: f.text,
            file: f.fileObj,
            title: f.title,
          })),
        },
        // context: {
        //   fetchOptions: {
        //     onUploadProgress(...e) {
        //       console.log("hey", e)
        //     }
        //   }
        // }
      })
    },
    [workspaceID, partnerID, JSON.stringify(collectionsIDs)]
  )

  const { amountUploaded, uploadFiles, loading, uploadDone, resetUpload } =
    useSequentialUpload({
      uploadFile,
    })

  return useMemo(
    () => ({
      uploadDocuments: ({ files }) => uploadFiles(files),
      error: result.error,
      loading,
      uploadDone,
      resetUpload,
      amountUploaded,
    }),
    [
      result.data,
      uploadFiles,
      amountUploaded,
      loading,
      result.error,
      uploadDone,
    ]
  )
}
