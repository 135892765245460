import {
  PageBoardItemContext,
  PageBoardItemProvider,
} from "./components/PageBoardItemContext"

import { DocumentPageBoardItem } from "./components/DocumentPageBoardItem/DocumentPageBoardItem"
import { ShopArticlePageBoardItem } from "./components/ShopArticlePageBoardItem/ShopArticlePageBoardItem"
import { useRelatedRoom } from "./hooks/useRelatedRoom"
import { getRelatedRoomsFieldProps } from "./lib/getRelatedRoomsFieldProps"
import { ItemPage } from "./components/ItemPage/ItemPage"
import { SecondPage } from "./components/SecondPage/SecondPage"

export { ControlButtons } from "./components/ControlButtons/ControlButtons"
export { HeaderIconButton } from "./components/HeaderIconButton"
export type { InitialParams } from "./types"

export type { PageBoardItemApi } from "./components/PageBoardItemContext"
export type { PageBoardItemApiInput } from "./hooks/usePageBoardItem"
export { getSecondPage as getPage } from "./components/SecondPage/getSecondPage"

export const PageBoardItem = {
  Context: PageBoardItemContext,
  Consumer: PageBoardItemContext.Consumer,
  Provider: PageBoardItemProvider,
  DocumentPageBoardItem,
  ShopArticlePageBoardItem,
  useRelatedRoom,
  ItemPage,
  getRelatedRoomsFieldProps,
  SecondPage,
}
