import { useMemo, useCallback } from "react"
import useUpdatePartner from "hooks/useUpdatePartner/useUpdatePartner"
import useOnChange from "hooks/useOnChange"
import useKontaktToggleDelete from "hooks/useKontaktToggleDelete/useKontaktToggleDelete"

export default function usePartnerModification(props: {
  partnerID?: string
  onChangeLoadingState?: (loadingState: boolean) => any
}) {
  const { partnerID, onChangeLoadingState } = props

  const { isLoading: isUpdating, updatePartner } = useUpdatePartner({
    partnerID,
  })

  const { isLoading: isTogglingDelete, toggleDelete } = useKontaktToggleDelete({
    id: partnerID,
    kontaktType: "PARTNER",
  })

  useOnChange({
    value: isUpdating || isTogglingDelete,
    onChange: onChangeLoadingState,
  })

  const update = useCallback(
    function update(dataUpdate) {
      return updatePartner({ dataUpdate })
    },
    [partnerID, updatePartner]
  )

  return useMemo(() => {
    return { update, toggleDelete }
  }, [toggleDelete, update])
}
