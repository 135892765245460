import _ from "lodash"
import { useRelatedCollectionsItemsQuery } from "lib/graphql/operations"
import useActiveResources from "../useActiveResources"
import { RequireAtLeastOne } from "types/type"
import { WatchQueryFetchPolicy } from "@apollo/client"
import useMemoAPI from "hooks/useMemoAPI"

export default function useRelatedCollectionsItems(
  props: RequireAtLeastOne<{
    collectionID: string
    collectionType: string
  }> & {
    fetchPolicy?: WatchQueryFetchPolicy
    pollInterval?: number
  }
) {
  const { workspaceID } = useActiveResources()

  const { data, loading } = useRelatedCollectionsItemsQuery({
    variables: {
      workspaceID: workspaceID || "",
      collectionID: props.collectionID,
      collectionType: props.collectionType,
    },
    skip: !workspaceID || (!props.collectionID && !props.collectionType),
    fetchPolicy: props.fetchPolicy || "cache-and-network",

    pollInterval: (function pollInterval() {
      const fetchPolicyAllowed = ["cache-only", "cache-first"].every(
        (policy) => props.fetchPolicy !== policy
      )

      if (fetchPolicyAllowed && props.pollInterval) {
        return props.pollInterval
      }

      return 0
    })(),
  })

  return useMemoAPI({
    relatedCollectionsItems: data?.relatedCollectionsItems,
    isLoading: loading,
  })
}
