import moment from "moment"
import React from "react"
import { Box, Chip, makeStyles } from "@material-ui/core"
import clsx from "clsx"

export default function DayIndicator({ isoDate, className = "" }) {
  const c = useStyles({})
  const day = moment(isoDate).format("DD.MM.YYYY")

  return (
    <Box
      display="flex"
      justifyContent="center"
      className={clsx("day-indicator", className)}
    >
      <Chip label={day} className={c.chip} />
    </Box>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    chip: {
      color: theme.palette.text.secondary,
    },
  }
})
