import React from "react"
import _ from "lodash"
import { makeStyles } from "@material-ui/core"
import UserAvatar from "components/UserAvatar/UserAvatar"
import { UserFragment } from "lib/graphql/operations"
import ListItemWithAvatar, {
  ListItemWithAvatarProps,
} from "components/ListItemWithAvatar/ListItemWithAvatar"

export default function UserListItem(props: {
  user: UserFragment
  ListItemWithAvatarProps?: ListItemWithAvatarProps | null
}) {
  const c = useStyles()

  const user = props.user

  return (
    <React.Fragment>
      {(() => {
        if (!user) return null

        return (
          <ListItemWithAvatar
            avatar={
              <UserAvatar
                user={user}
                classes={{ avatar: c.avatar }}
              ></UserAvatar>
            }
            firstLine={user.name}
            secondLine={user.company}
            {...(props.ListItemWithAvatarProps || {})}
          ></ListItemWithAvatar>
        )
      })()}
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    avatar: {
      width: "28px",
      height: "28px",
    },
  }
})
