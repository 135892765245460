import ZoomVideo, {
  ConnectionState,
  ReconnectReason,
  Stream,
} from "@zoom/videosdk"
import { message, Modal } from "antd"
import produce from "immer"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react"
import "./App.css"
import { MeetingArgs } from "./context/MeetingArgsContext"
import { ZoomClientContext } from "./context/ZoomClientContext"

const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
}
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case "audio-encode": {
      draft.audio.encode = action.payload
      break
    }
    case "audio-decode": {
      draft.audio.decode = action.payload
      break
    }
    case "video-encode": {
      draft.video.encode = action.payload
      break
    }
    case "video-decode": {
      draft.video.decode = action.payload
      break
    }
    case "share-encode": {
      draft.share.encode = action.payload
      break
    }
    case "share-decode": {
      draft.share.decode = action.payload
      break
    }
    case "reset-media": {
      Object.assign(draft, { ...mediaShape })
      break
    }
    default:
      break
  }
}, mediaShape)

declare global {
  interface Window {
    webEndpoint: string | undefined
    zmClient: any | undefined
    mediaStream: any | undefined
    crossOriginIsolated: boolean
    ltClient: any | undefined
    logClient: any | undefined
  }
}

export function useMeetingState(props: { meetingArgs: MeetingArgs }) {
  const meetingArgs = props.meetingArgs

  const {
    sdkKey,
    topic,
    signature,
    // name,
    password,
    webEndpoint: webEndpointArg,
    enforceGalleryView,
    enforceVB,
    customerJoinId,
    lang,
    userIdentity,
    useVideoPlayer,
  } = meetingArgs

  const [loading, setIsLoading] = useState(true)
  const [loadingText, setLoadingText] = useState("")
  const [isFailover, setIsFailover] = useState(false)
  const [status, setStatus] = useState("closed")
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape)
  const [mediaStream, setMediaStream] = useState<typeof Stream | null>(null)
  const [isSupportGalleryView, setIsSupportGalleryView] = useState(false)
  const [joined, setJoined] = useState(false)

  const zmClient = useContext(ZoomClientContext)

  const webEndpoint = webEndpointArg || window?.webEndpoint || "zoom.us"

  const mediaContext = useMemo(
    () => ({ ...mediaState, mediaStream }),
    [mediaState, mediaStream]
  )

  const galleryViewWithoutSAB =
    Number(enforceGalleryView) === 1 && !window.crossOriginIsolated
  const vbWithoutSAB = Number(enforceVB) === 1 && !window.crossOriginIsolated
  const galleryViewWithAttach =
    Number(useVideoPlayer) === 1 &&
    (window.crossOriginIsolated || galleryViewWithoutSAB)

  useEffect(() => {
    const init = async () => {
      await zmClient.init("en-US", `${window.location.origin}/lib`, {
        webEndpoint,
        // enforceMultipleVideos: galleryViewWithoutSAB,
        enforceMultipleVideos: true,
        enforceVirtualBackground: vbWithoutSAB,
        stayAwake: true,
        patchJsMedia: true,
        leaveOnPageUnload: false,
      })
      try {
        setLoadingText("Joining the session...")
        await zmClient
          .join(topic || "", signature || "", userIdentity || "", password)
          .catch((e) => {
            console.log(e)
          })
        const stream = zmClient.getMediaStream()
        setMediaStream(stream)
        setJoined(true)
        setIsSupportGalleryView(stream.isSupportMultipleVideos())
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        // @ts-ignore
        message.error(e.reason)
      }
    }
    init()
    return () => {
      ZoomVideo.destroyClient()
    }
  }, [
    sdkKey,
    signature,
    zmClient,
    topic,
    // name,
    password,
    webEndpoint,
    galleryViewWithoutSAB,
    customerJoinId,
    lang,
    vbWithoutSAB,
  ])

  const onConnectionChange = useCallback(
    (payload) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true)
        setIsFailover(true)
        setStatus("connecting")
        const { reason, subsessionName } = payload
        if (reason === ReconnectReason.Failover) {
          setLoadingText("Session Disconnected, Try to reconnect")
        } else if (
          reason === ReconnectReason.JoinSubsession ||
          reason === ReconnectReason.MoveToSubsession
        ) {
          setLoadingText(`Joining ${subsessionName}...`)
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText("Returning to Main Session...")
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus("connected")
        if (isFailover) {
          setIsLoading(false)
        }
        window.zmClient = zmClient
        window.mediaStream = zmClient.getMediaStream()
        console.log("getSessionInfo", zmClient.getSessionInfo())
      } else if (payload.state === ConnectionState.Closed) {
        setStatus("closed")
        dispatch({ type: "reset-media" })
        if (payload.reason === "ended by host") {
          Modal.warning({
            title: "Meeting ended",
            content: "This meeting has been ended by host",
          })
        }
      }
    },
    [isFailover, zmClient]
  )

  const onMediaSDKChange = useCallback((payload) => {
    const { action, type, result } = payload
    dispatch({ type: `${type}-${action}`, payload: result === "success" })
  }, [])

  const onLeaveOrJoinSession = useCallback(async () => {
    if (status === "closed") {
      setIsLoading(true)
      await zmClient.join(
        topic || "",
        signature || "",
        userIdentity || "",
        password
      )
      setIsLoading(false)
      setJoined(true)
    } else if (status === "connected") {
      await zmClient.leave()

      // @ts-ignore
      message.warn("You have left the session.")
    }
  }, [zmClient, status, topic, signature, userIdentity, password])

  useEffect(() => {
    zmClient.on("connection-change", onConnectionChange)
    zmClient.on("media-sdk-change", onMediaSDKChange)
    return () => {
      zmClient.off("connection-change", onConnectionChange)
      zmClient.off("media-sdk-change", onMediaSDKChange)
    }
  }, [zmClient, onConnectionChange, onMediaSDKChange])

  return {
    loading,
    loadingText,
    status,
    mediaContext,
    isSupportGalleryView,
    galleryViewWithAttach,
    onLeaveOrJoinSession,
    userJoined: joined,
  }
}

const F = contextAndProviderFactory({
  hookApi: useMeetingState,
  contextName: "MeetingStateContext",
})

export const MeetingStateContext = F.Context
export const MeetingStateProvider = F.Provider
