import React, { useCallback, useContext, useMemo, useState } from "react"
import { Icon, IconButton, makeStyles } from "@material-ui/core"
import ChatOverlayContext from "../../contexts/ChatOverlayContext"
import { useKey, useKeys } from "rooks"
import useActiveResources from "hooks/useActiveResources"
import SectionsRoutes, { SECTIONS_IDS } from "components/SectionsRoutes/index"
import ChatDraggableOverlay from "../ChatDraggableOverlay/ChatDraggableOverlay"
import ChatWindowOverlay from "../ChatWindowOverlay/ChatWindowOverlay"
import Chat from "components/Chat/Chat"
import { overlayModes } from "../../constants"

export default function ChatOverlay() {
  const c = useStyles({})
  const { workspaceID } = useActiveResources()

  const { setRoomIdentifier, roomIdentifier, overlayMode, closeOverlay } =
    useContext(ChatOverlayContext)

  const { currentSectionRouteID } = useContext(SectionsRoutes.Context)
  const isOnChatPage = currentSectionRouteID === SECTIONS_IDS.chat
  const [chatListTabID, onChangeChatListTabID] = useState("")

  useKeysShortCuts()

  const RoomProps = useMemo(() => {
    return {
      renderSideSheet() {
        return null
      },
    }
  }, [])

  const isWindowOverlay = overlayMode === overlayModes.WINDOW_OVERLAY

  const renderChatListTopBar = useCallback(
    ({ defaultComponent }) => {
      if (isWindowOverlay) return <WindowOverlayChatListTopBar />
      return defaultComponent
    },
    [isWindowOverlay]
  )

  if (isOnChatPage) return null

  const ChatListProps = {
    tabID: chatListTabID,
    onChangeTabID: onChangeChatListTabID,
    renderTopBar: renderChatListTopBar,
  }

  const chatComponent = (
    <Chat.Default
      // roomID={roomID}
      {...(isWindowOverlay ? { getOutOfRoom: closeOverlay } : {})}
      onChangeRoomIdentifier={setRoomIdentifier}
      roomIdentifier={roomIdentifier}
      key={workspaceID}
      ChatListProps={ChatListProps}
      RoomProps={RoomProps}
      className={c.chat}
    ></Chat.Default>
  )

  if (isWindowOverlay)
    return <ChatWindowOverlay>{chatComponent}</ChatWindowOverlay>

  return <ChatDraggableOverlay>{chatComponent}</ChatDraggableOverlay>
}

const useStyles = makeStyles((theme) => {
  return {
    chatOverlayPaper: {
      background: "#f5f5f5",
      position: "absolute",
      left: 0,
      top: 0,
      display: "flex",
      flexDirection: "column",
    },

    chat: {
      height: "100%",
      flex: 1,
      maxHeight: "100%",
      // overflow: "auto",
    },
  }
})

function useKeysShortCuts() {
  const { overlayIsOpen, closeOverlay, openOverlay } =
    useContext(ChatOverlayContext)

  // useKey(["Escape"], closeOverlay, { when: overlayIsOpen })

  // useKeys(
  //   ["Control", "Shift", "KeyC"],
  //   (e) => {
  //     e.preventDefault()
  //     openOverlay()
  //   },
  //   {
  //     when: !overlayIsOpen,
  //   }
  // )
}

function WindowOverlayChatListTopBar() {
  const { closeOverlay } = useContext(ChatOverlayContext)

  return (
    <Chat.ListTopBar
      renderLeft={({ searchIsActive, components: { title } }) =>
        !searchIsActive && (
          <>
            <IconButton onClick={closeOverlay} edge="start">
              <Icon>arrow_back</Icon>
            </IconButton>
            {title}
          </>
        )
      }
    ></Chat.ListTopBar>
  )
}
