import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import {
  useCreateCollectionItemMutation,
  CreateCollectionItemMutationVariables,
  CollectionsOverviewsDocument,
} from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"

export default function useCreateCollection() {
  const { workspaceID } = useActiveResources()

  const [mutate, { data, loading }] = useCreateCollectionItemMutation()

  const createCollection = React.useCallback(
    (props: CreateCollectionItemMutationVariables["collectionItem"]) => {
      return mutate({
        variables: {
          workspaceID,
          collectionItem: {
            title: props.title,
            type: props.type,
          },
        },

        refetchQueries: [
          { query: CollectionsOverviewsDocument, variables: { workspaceID } },
        ],
      })
    },
    [workspaceID]
  )

  return useMemoAPI({ createCollection, isLoading: loading })
}
