import useOnChange from "hooks/useOnChange"
import { useMemo, useState } from "react"

export default function useSequentialUpload({ uploadFile }) {
  const [uploaded, setUploaded] = useState([])
  const [toUpload, setToUpload] = useState([])

  useOnChange({
    value: uploaded.length,
    onChange: function uploadNext(totalUploaded) {
      if (!totalUploaded) return

      if (totalUploaded < toUpload.length) {
        const nextIndex = totalUploaded
        const nextFile = toUpload[nextIndex]

        uploadFile(nextFile).then(() => setUploaded([...uploaded, nextFile]))
      }
    }
  })

  useOnChange({
    value: toUpload.length,
    onChange: function startUpload(toUploadAmount) {
      if (!!toUploadAmount) {
        setUploaded([])
        const first = toUpload[0]
        uploadFile(first).then(() => setUploaded([first]))
      }
    }
  })

  return useMemo(() => {
    return {
      loading: uploaded.length < toUpload.length,
      uploadFiles: setToUpload,
      uploaded,
      uploadDone: toUpload.length > 0 && uploaded.length === toUpload.length,
      resetUpload: () => {
        setToUpload([])
        setUploaded([])
      },
      amountUploaded: uploaded.length
    }
  }, [uploaded, toUpload.length, uploaded.length])
}
