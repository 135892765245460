import moment from "moment"
import { Period } from "types/type"
import {
  IndexedItemsConsumer,
  IndexedItemsContext,
  IndexedItemsProvider as IndexedItemsProviderApi,
} from "./IndexedItemsContext/IndexedItemsContext"
import { DateIntervalSelector } from "./components/DateIntervalSelector/DateIntervalSelector"
import { PeriodState } from "./components/DateIntervalSelector/components/CalendarViews/hooks/usePeriodSelector"
import { GoogleSearch } from "./components/GoogleSearch/GoogleSearch"
import { ItemsList } from "./components/ItemsList/ItemsList"
import { TypesChips } from "./components/TypesChips/TypesChips"
import { useSelectedPeriodChipProps } from "./hooks/useSelectedPeriodChipProps"
import { IndexedItemsApiInput, SerializedPeriod } from "./types"
import { HistoryThread } from "../HistoryThread/HistoryThread"
import React from "react"
import { PageBoardItemParams } from "lib/graphql/types"
import UseEffect from "components/UseEffect/UseEffect"
import { HistoryNavigation } from "./components/HistoryNavigation/HistoryNavigation"

export {
  IndexedItemsContext,
  IndexedItemsProvider,
} from "./IndexedItemsContext/IndexedItemsContext"
export { indexedTypes as indexedTypes } from "./hooks/indexedTypes"
export { useFetchPageBoardItemFromParams as useFetchPageBoardItem } from "./hooks/useFetchPageBoardItemFromParams"
export { useIndexedItems as useBoard } from "./hooks/useIndexedItems"
export { fetchPageBoardItem } from "./lib/fetchPageBoardItem"
export type { IndexedItemsApiInput } from "./types"

export const IndexedItems = {
  Context: IndexedItemsContext,
  Consumer: IndexedItemsConsumer,
  useSelectedPeriodChipProps,
  GoogleSearch,
  TypesChips,
  ItemsList,
  HistoryNavigation,
  DateIntervalModal: function DateIntervalModal() {
    return (
      <IndexedItemsConsumer>
        {(api) => {
          return (
            <DateIntervalSelector.Modal
              isOpen={api.dateIntervalSelectorApi.modal.isOpen}
              onClose={api.dateIntervalSelectorApi.modal.close}
            ></DateIntervalSelector.Modal>
          )
        }}
      </IndexedItemsConsumer>
    )
  },
  Provider: function IndexedItemsProvider(
    props: IndexedItemsApiInput & {
      children: React.ReactNode
    }
  ) {
    const [selectedParams, setSelectedParams] = React.useState<
      PageBoardItemParams | null | undefined
    >(null)

    const periodState: PeriodState | undefined = (() => {
      const periodState = props.filter?.periodState

      if (!periodState) return undefined

      const p = periodState as SerializedPeriod

      if (p.start) {
        const j: Period = {
          start: moment(p.start).startOf("day"),
          end: moment((periodState as SerializedPeriod).end).endOf("day"),
        }

        return j
      }

      return periodState as PeriodState
    })()

    return (
      <DateIntervalSelector.Provider
        MonthsProps={{
          periodState: periodState,
          onChangePeriodState(periodState) {
            if (!props.onChangeFilter) return

            if ((periodState as Period)?.start) {
              const p = periodState as Period

              return props.onChangeFilter({
                periodState: !p.start
                  ? null
                  : {
                      start: p.start.startOf("day").toISOString(),
                      end: p.end?.endOf("day").toISOString(),
                    },
              })
            }

            props.onChangeFilter({
              periodState: periodState as Exclude<PeriodState, Period>,
            })
          },
        }}
      >
        <DateIntervalSelector.Context.Consumer>
          {(dateIntervalSelectorApi) => {
            return (
              <HistoryThread.Provider entryPoint={selectedParams}>
                <HistoryThread.Consumer>
                  {(historyThreadyApi) => {
                    return (
                      <IndexedItemsProviderApi
                        {...props}
                        dateIntervalSelectorApi={dateIntervalSelectorApi}
                        historyThreadApi={historyThreadyApi}
                      >
                        <IndexedItems.Consumer>
                          {(a) => {
                            return (
                              <React.Fragment>
                                <UseEffect
                                  deps={[JSON.stringify(a.selected.params)]}
                                  effect={() => {
                                    setSelectedParams(a.selected.params)
                                  }}
                                ></UseEffect>
                                {props.children}
                              </React.Fragment>
                            )
                          }}
                        </IndexedItems.Consumer>
                      </IndexedItemsProviderApi>
                    )
                  }}
                </HistoryThread.Consumer>
              </HistoryThread.Provider>
            )
          }}
        </DateIntervalSelector.Context.Consumer>
      </DateIntervalSelector.Provider>
    )
  },
}
