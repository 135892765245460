import { RoomContext } from "components/Room/contexts/room"
import React from "react"
import _ from "lodash"
import { useContext } from "react"
import {
  MessageFragment,
  MessageListItemFragment,
} from "lib/graphql/operations"
import useMemoAPI from "hooks/useMemoAPI"

type Index = MessageListItemFragment["index"]

type Indexed = {
  messagesByIndex?: Record<Index, MessageFragment | null>
  nextItemNotDeleted?: Record<Index, MessageListItemFragment | null>
  previousItemNotDeleted?: Record<Index, MessageListItemFragment | null>
}

export default function useIndexedMessages() {
  const { messageList } = useContext(RoomContext)

  // console.log(messageList)

  const sortedMessages = React.useMemo(() => {
    const a = [...(messageList.data?.list || [])].sort((a, b) => {
      if (a?.index < b?.index) return -1

      return 1
    })

    // console.log({ a })

    // return messageList.data?.list || []
    return a
  }, [messageList.data?.list])

  const getNextItemNotDeleted = React.useCallback<
    (index: number) => MessageListItemFragment | null
  >(
    (index) => {
      const m = sortedMessages.find(
        (i) => i.index > index && !!i.message && !i.message?.deletedAt
      )

      return m || null
    },
    [sortedMessages]
  )

  const getPreviousItemNotDeleted = React.useCallback<
    (index: number) => MessageListItemFragment | null
  >(
    (index) => {
      //we are cloning because `Array.prototype.reverse` mutates the original array
      const m = [...sortedMessages]
        .reverse()
        .find((i) => i.index < index && !!i.message && !i.message?.deletedAt)

      return m || null
    },
    [sortedMessages]
  )

  const { messagesByIndex, nextItemNotDeleted, previousItemNotDeleted } =
    React.useMemo(() => {
      const byIndex = _.keyBy(messageList.data?.list, (i) => i.index)

      const accumulated = Object.entries(byIndex)

      const v = accumulated.reduce((acc, [index, item]) => {
        const i = Number(index)

        acc.messagesByIndex = {
          ...(acc.messagesByIndex || {}),
          [i]: item?.message,
        }

        acc.nextItemNotDeleted = {
          ...(acc.nextItemNotDeleted || {}),
          [i]: getNextItemNotDeleted(i),
        }

        acc.previousItemNotDeleted = {
          ...(acc.previousItemNotDeleted || {}),
          [i]: getPreviousItemNotDeleted(i),
        }
        return acc
      }, {} as Indexed)

      return v
    }, [
      messageList.data?.list,
      getNextItemNotDeleted,
      getPreviousItemNotDeleted,
    ])

  const v = useMemoAPI({
    messagesByIndex,
    nextItemNotDeleted,
    previousItemNotDeleted,
  })

  return v
}
