import { MutationUpdaterFn, FetchResult } from "@apollo/client";
import { CartFragment, CartQueryVariables } from "lib/graphql/operations"
import { CartQuery, CartDocument } from "../../../lib/graphql/operations"
import _ from "lodash"

export const getUpdateCartQueryFn = <T extends Record<string, any>>(
  props: CartQueryVariables & {
    getCart: (b: FetchResult<T>) => CartFragment | null | undefined
  }
) => {
  const fn: MutationUpdaterFn<T> = (cache, mutationResult) => {
    const cartData = props.getCart(mutationResult)

    if (!cartData) return

    cache.writeQuery<CartQuery, CartQueryVariables>({
      query: CartDocument,
      variables: _.omit(props, "getCart"),
      data: {
        __typename: "Query",
        cart: cartData,
      },
    })

    // const a = cache.readQuery<CartQuery, CartQueryVariables>({
    //   query: CartDocument,
    //   variables: _.omit(props, "getCart"),
    // })

    // console.log(a)
  }

  return fn
}
