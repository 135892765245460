import { ChipProps } from "@material-ui/core"
import { InlinedChips } from "components/InlinedChips/InlinedChips"
import { IndexedItems } from "components/PageBoard/components/IndexedItems/IndexedItems"
import React from "react"
import { StyledProps } from "types/type"
import PageBoardContext from "../../../../contexts/PageBoardContext"

export function HiddenMostUsedTypesChips(
  props: {
    children?: (p: { typesChipsProps: ChipProps[] }) => React.ReactNode
  } & StyledProps
) {
  const api = React.useContext(PageBoardContext)

  const selected = api.types.value

  const types = api.mostUsedTypes.hidden.filter((type) =>
    selected.includes(type)
  )

  return (
    <IndexedItems.TypesChips types={types}>
      {({ typesChipsProps: chipProps }) => {
        if (props.children) {
          return <>{props.children({ typesChipsProps: chipProps })}</>
        }

        if (!chipProps.length) return null

        return (
          <InlinedChips
            chips={chipProps}
            className={props.className}
            style={props.style}
            wrap
          ></InlinedChips>
        )
      }}
    </IndexedItems.TypesChips>
  )
}
