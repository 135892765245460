import _ from "lodash"

import { Button, Icon, makeStyles } from "@material-ui/core"
import React, { useContext } from "react"
import MembersContext from "../../../../contexts/MembersContext"
import { RoomContext } from "components/Room/contexts/room"
import { useI18n } from "contexts/i18nContext/i18nContext"
import clsx from "clsx"
import CenteredOnContainer from "components/CenteredOnContainer/CenteredOnContainer"

export default function AddUserPanel() {
  const { modeAPI } = useContext(MembersContext)

  const c = useStyles({})
  const t = useI18n()

  return (
    <CenteredOnContainer className={clsx(c.root, "add-user-panel")}>
      <Button variant="outlined" color="primary" onClick={() => modeAPI.edit()}>
        <Icon className={c.icon}>group_add</Icon>
        {t["add"]}
      </Button>
    </CenteredOnContainer>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})
