import { makeStyles, Paper, Typography } from "@material-ui/core"
import clsx from "clsx"
import ActionsPanel from "components/ActionsPanel/ActionsPanel"
import Dropzone from "components/Dropzone/Dropzone"
import ScrollableContentWithFixedTopAndBottom from "components/ScrollableContentWithFixedTopAndBottom/ScrollableContentWithFixedTopAndBottom"
import {
  useParamsContext,
  useUtilsContext,
} from "components/OldUploadDocuments/context/libContext"
import { useUploadContext } from "components/OldUploadDocuments/context/UploadContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import _ from "lodash"
import { useContext, useEffect } from "react"
import BackwardButton from "../BackwardButton/BackwardButton"
import Body from "../Body/Body"
import EditFilesMetadata from "../EditFilesMetadata/EditFilesMetadata"
import ForwardButton from "../ForwardButton/ForwardButton"
import UploadButton from "../UploadButton/UploadButton"
import useStepsController from "./hooks/useStepsController/useStepsController"

const { IDLE } = Dropzone.states

export default function UploadSteps({
  className = "",
}: {
  className?: string
}) {
  const c = useStyles({})

  const controller = useStepsController({ steps })

  const { goForward, goBackward, activeStep, goToStepIndex, goToStepWithID } =
    controller

  const { enableFileSelection, FirstColumn, StepActions } = activeStep

  const { dropzoneState } = useContext(Dropzone.Context)

  useEffect(() => {
    if (dropzoneState !== IDLE) goToStepWithID("dropzone-area")
  }, [dropzoneState])

  return (
    <ScrollableContentWithFixedTopAndBottom
      className={clsx(c.uploadSteps, className)}
      content={
        <Paper className={clsx("upload-steps-content", c.paper)}>
          <Body
            FirstColumn={FirstColumn}
            enableFileSelection={enableFileSelection}
            goForward={goForward}
            goToStepIndex={goToStepIndex}
            goBackward={goBackward}
          />
        </Paper>
      }
      bottom={
        StepActions ? (
          <StepActions goForward={goForward} goBackward={goBackward} />
        ) : null
      }
    />
  )
}

const steps = [
  {
    id: "dropzone-area",
    FirstColumn({ ...props }) {
      const t = useI18n()
      return (
        <Dropzone.Area
          {...props}
          idleMessage={t.documentUploadDropzone.idleMessage}
          acceptFileMessage={t.documentUploadDropzone.acceptFileMessage}
          rejectFileMessage={t.documentUploadDropzone.rejectFileMessage}
        ></Dropzone.Area>
      )
    },
    StepActions: function Step({ goForward }: { goForward: () => void }) {
      const { uploadedFiles } = useUploadContext()
      const hasFiles = !_.isEmpty(uploadedFiles)

      if (!hasFiles) return null

      return (
        <ActionsPanel actionButtons={[<ForwardButton onClick={goForward} />]} />
      )
    },
  },
  {
    id: "categorize",
    FirstColumn: EditFilesMetadata,
    enableFileSelection: true,
    StepActions: function Step({ goBackward }: { goBackward: () => void }) {
      const { uploadedFiles } = useUploadContext()
      const { uploadFiles } = useUtilsContext()
      const { amountUploaded, loading } = useParamsContext()

      return (
        <ActionsPanel
          // style={{ justifyContent: "space-between" }}
          actionButtons={[
            <BackwardButton onClick={goBackward} disabled={loading} />,
            <UploadButton
              onClick={() => uploadFiles({ files: uploadedFiles })}
              disabled={loading}
            />,
          ]}
        >
          {({ defaultComponent }: { defaultComponent: React.ReactNode }) => (
            <>
              {loading && _.isFinite(amountUploaded) ? (
                <Typography variant="body1">{`${amountUploaded}/${uploadedFiles.length} uploaded`}</Typography>
              ) : (
                <span />
              )}
              {defaultComponent}
            </>
          )}
        </ActionsPanel>
      )
    },
  },
]
const useStyles = makeStyles((theme) => {
  return {
    uploadSteps: {
      height: "100%",
    },
    paper: {
      padding: theme.spacing(2, 3),
      height: "100%",
      margin: theme.spacing(7),
    },
  }
})
