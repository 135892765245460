import useMemoAPI from "hooks/useMemoAPI"
import { useWidth } from "hooks/useWidth"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { useSubscription } from "observable-hooks"
import React from "react"
import { Subject } from "rxjs"

const Factory = contextAndProviderFactory({
  hookApi: function useMobileHiddenXScrollbar(props: { isActive?: boolean }) {
    const isActive = props.isActive ?? true

    const scroll$ = React.useMemo(() => new Subject<HTMLElement>(), [])

    const containerMounted$ = React.useMemo(
      () => new Subject<HTMLElement>(),
      []
    )

    const containerResized$ = React.useMemo(
      () => new Subject<HTMLElement>(),
      []
    )

    const [ref, setRef] = React.useState<HTMLDivElement | null>(null)

    const { width$, width: containerWidth } = useWidth(ref)

    useSubscription(
      width$,
      React.useCallback(
        (width) => {
          if (!ref) return
          containerResized$.next(ref)
        },
        [ref]
      )
    )

    const goToNextPage = React.useCallback(() => {
      ref?.scrollTo({
        left: ref.scrollLeft + ref.clientWidth,
        behavior: "smooth",
      })
    }, [ref])

    const goToPreviousPage = React.useCallback(() => {
      ref?.scrollTo({
        left: ref.scrollLeft - ref.clientWidth,
        behavior: "smooth",
      })
    }, [ref])

    const handleScroll = React.useCallback(
      (event: React.UIEvent<HTMLDivElement>) => {
        const target = event.target as HTMLDivElement
        scroll$.next(target)
      },
      []
    )

    const goToTheEnd = React.useCallback(() => {
      ref?.scrollTo({
        left: ref.scrollWidth,
        behavior: "smooth",
      })
    }, [ref])

    React.useEffect(() => {
      if (!!ref) containerMounted$.next(ref)
    }, [ref])

    return useMemoAPI({
      isActive,
      scroll$,
      containerMounted$,
      containerResized$,
      onScroll: handleScroll,
      containerRef: ref,
      setContainerRef: setRef,
      goToNextPage,
      goToPreviousPage,
      containerWidth,
      goToTheEnd,
    })
  },
})

export const MobileHiddenXScrollbarContext = Factory.Context
export const MobileHiddenXScrollbarProvider = Factory.Provider
