import React from "react"
import _ from "lodash"
import { SelectUsersInputProviderProps } from "../../types"
import useSelectUsersInputAPI from "../../hooks/useSelectUsersInputAPI"
import SelectUsersInputContext from "../../contexts/SelectUsersInputContext"

export default function SelectUsersInputProvider(
  props: SelectUsersInputProviderProps & { children: React.ReactNode }
) {
  const api = useSelectUsersInputAPI(_.omit(props, "children"))

  return (
    <SelectUsersInputContext.Provider value={api}>
      {props.children}
    </SelectUsersInputContext.Provider>
  )
}
