import clsx from "clsx"
import PaperList from "components/PaperList"
import { ListItem } from "components/PaperList/types"
import { DocumentFragment } from "lib/graphql/operations"
import React from "react"
import {
  DocumentsListApiInput,
  DocumentsListContext,
  DocumentsListProvider,
} from "./ContextAndProvider"
import { EntryListItem } from "./EntryListItem"

export const DocumentsList = {
  Provider: DocumentsListProvider,
  Root: DocumentsListRoot,
  Default: DocumentsListDefault,
  Context: DocumentsListContext,
}

export function DocumentsListRoot(props: {
  className?: string
  style?: React.CSSProperties
}) {
  const api = React.useContext(DocumentsListContext)
  return (
    <PaperList.Provider
      ListItem={Entry}
      items={api.documents || []}
      fetchMore={api.fetchMore}
      ItemPaperProps={api.ItemPaperProps}
    >
      <PaperList.Root
        style={props.style}
        className={clsx("documents-list", props.className)}
      ></PaperList.Root>
    </PaperList.Provider>
  )
}

export function DocumentsListDefault(
  props: {
    className?: string
    style?: React.CSSProperties
  } & DocumentsListApiInput
) {
  const { className, style, ...DocumentsListApiInput } = props

  return (
    <DocumentsListProvider {...DocumentsListApiInput}>
      <DocumentsListRoot
        className={className}
        style={style}
      ></DocumentsListRoot>
    </DocumentsListProvider>
  )
}

const Entry: ListItem<DocumentFragment> = function DocumentsEntryListItem({
  entry: document,
}) {
  if (!document) return null

  return (
    <EntryListItem
      title={document?.title || ""}
      totalAmount={document?.totalAmount || 0}
      type={document?.type || ""}
      id={document?.id || ""}
      entry={document}
    ></EntryListItem>
  )
}
