import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import {
  // KanbanCardFragment,
  KanbanContentAPI,
  TransformKanbanCard,
} from "../@types/index"
import { ID } from "types/type"
import { KanbanCardFragment } from "lib/graphql/operations"
import useChangeKanbanCard from "../../PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/hooks/useChangeKanbanCard"

export default function useContentAPI(props: {
  getCard?: (cardID: ID) => KanbanCardFragment | null
  setCard: (cardID: ID, transform: TransformKanbanCard) => void
}): KanbanContentAPI {
  const changeKanbanCard = useChangeKanbanCard()

  const title = React.useCallback(
    (cardID: ID) => {
      // return props.getCard?.call(null, cardID)?.title || ""
      return ""
    },
    [props.getCard]
  )

  const description = React.useCallback(
    (cardID: ID) => {
      // return props.getCard?.call(null, cardID)?.description || ""
      return ""
    },
    [props.getCard]
  )

  const setDescription = React.useCallback(
    (cardID: ID, description: string) => {
      // props.setCard(cardID, (oldCard: KanbanCardFragment | null) => {
      //   if (!oldCard) return null
      //   return { ...oldCard, description }
      // })
    },
    [props.setCard]
  )

  const setTitle = React.useCallback(
    (cardID: string, title: string) => {
      return changeKanbanCard({ title }, { cardID: cardID })
    },
    [props.setCard, changeKanbanCard]
  )

  return useMemoAPI({ title, description, setDescription, setTitle })
}
