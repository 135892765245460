import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import Fab, { Action } from "components/Fab/Fab"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function KanbanCardFab(props: {
  className?: string
  style?: React.CSSProperties
}) {
  const c = useStyles({})

  const api = React.useContext(KanbanCardPageBoardItemContext)

  const t = useI18n()

  const actions: Action[] = [
    (() => {
      const isSmallView = api.viewport?.isSmallView

      if (!isSmallView) return null

      const a: Action = {
        text: t["comment"],
        icon: "add_comment",
        id: "add-comment",
        onClick: api.comments.addCommentModal.open,
      }

      return a
    })(),
    {
      text: t["coverImage"],
      id: "cover",
      onClick: api.images.coverFileUI.open,
      icon: "image",
    },
    {
      text: t["addImage"],
      id: "add-image",
      onClick: api.images.listFileUI.open,
      icon: "add_photo_alternate",
    },
    (() => {
      const a: Action = {
        id: "delete-card",
        text: t["deleteTask"],
        onClick: () => api.card.deleteModal.open(),
        icon: "delete",
      }

      return a
    })(),
  ].filter((a): a is Action => !!a)

  const memoizedActions = React.useMemo<Action[]>(() => {
    return actions
  }, [...actions.map((a) => a.onClick), actions.length])

  return (
    <Fab
      actions={memoizedActions}
      className={clsx(c.root, props.className)}
    ></Fab>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {},
  }
})
