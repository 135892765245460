import useCopyToWindow from "hooks/useCopyToWindow/useCopyToWindow"
import _ from "lodash"
import queryString from "query-string"
import useAppRouter from "hooks/useAppRouter"
import { useMemo } from "react"
import useParams from "hooks/useParams"
import { join } from "path"

export default function usePageParams() {
  const { match, location } = useAppRouter()

  const partner =
    useParams(join(match.url, "/partner/:partnerID/:tabID?")) || {}
  const contact =
    useParams(join(match.url, "/contact/:contactID/:tabID?")) || {}
  const document = useParams("/document/:documentID")
  const collection =
    useParams(join(match.url, ":collectionID", ":tabID?")) || {}

  const params = {
    partnerID: partner.partnerID,
    contactID: contact.contactID,
    documentID: document.documentID,
    tabID: (() => {
      if (partner.partnerID) return partner.tabID
      if (contact.contactID) return contact.tabID

      return collection.tabID
    })(),

    collectionID:
      partner.partnerID || contact.contactID
        ? undefined
        : collection.collectionID,
  }

  const parsed = queryString.parse(location.search, {
    arrayFormat: "comma",
  })

  const a = {
    ...params,
    searchedTerm:
      typeof parsed.searchedTerm === "string" ? parsed.searchedTerm : null,
    types: Array.isArray(parsed.types)
      ? parsed.types
      : [parsed.types].filter(Boolean),
    modal: typeof parsed.modal === "string" ? parsed.modal : null,
    relatedCollectionsIDs: (() => {
      const collsIDs = parsed.relatedCollectionsIDs

      if (!collsIDs) return undefined

      if (!Array.isArray(collsIDs)) return [collsIDs]

      return collsIDs
    })(),
    modalPartnerID: parsed.modalPartnerID,
  }

  useCopyToWindow("params", a)

  return useMemo(() => a, [JSON.stringify(a)])
}
