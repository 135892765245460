import React from "react"
import usePdfAPI from "../hooks/usePdfAPI"
// import { usePDFApi } from "./usePDFApi"
import contextAndProviderFactory from "../../../lib/contextAndProviderFactory"

const Factory = contextAndProviderFactory({ hookApi: usePdfAPI })

export const PdfContext = Factory.Context
export const PdfProvider = Factory.Provider
export const PdfConsumer = Factory.Consumer

export default PdfContext
