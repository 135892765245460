import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { usePageBoardItemApi } from "../hooks/usePageBoardItem"

const PageBoardItem = contextAndProviderFactory({
  hookApi: usePageBoardItemApi,
  contextName: "PageBoardItemContext",
})

export type PageBoardItemApi = ReturnType<typeof usePageBoardItemApi>

export const PageBoardItemContext = PageBoardItem.Context
export const PageBoardItemProvider = PageBoardItem.Provider
export const PageBoardItemConsumer = PageBoardItem.Consumer
