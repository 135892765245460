import _ from "lodash"
// import
import { useContext } from "react"
import PresenceList from "components/PresenceList/index"
import MembersContext from "../../contexts/MembersContext"
import { RoomContext } from "components/Room/contexts/room"
import AddUserPanel from "./components/AddUserPanel/AddUserPanel"
import RoomTemplateContext from "components/Room/components/RoomTemplate/contexts/RoomTemplateContext"
import InclusionButton from "../InclusionButton/InclusionButton"
import { RoomMemberFragment, UserFragment } from "lib/graphql/operations"

export default function IdlePresenceList() {
  const { classes: c, members } = useContext(MembersContext)
  const { peerMembers, allowMembersEdition } = useContext(RoomContext)

  if (!!allowMembersEdition && !peerMembers?.length)
    return <AddUserPanel></AddUserPanel>

  return (
    <PresenceList.Root
      members={members}
      className={c.presenceList}
      ListItem={IdlePresenceListItem}
    />
  )
}

function IdlePresenceListItem<T extends RoomMemberFragment>(props: {
  entry: T
}) {
  const { allowMembersEdition, room } = useContext(RoomContext)

  const ownerID = room?.owner?.id

  return (
    <PresenceList.ListItem
      entry={props.entry}
      disableRipple
      left={
        <PresenceList.ListItemLeft
          member={props.entry}
          primaryLine={
            <>
              {[
                props.entry.name,
                (props.entry as UserFragment).id === ownerID ? (
                  <>
                    <span style={{ marginLeft: 2, opacity: 0.4 }}>(owner)</span>
                  </>
                ) : (
                  ""
                ),
              ].filter((a) => !!a)}
            </>
          }
        ></PresenceList.ListItemLeft>
      }
      right={
        allowMembersEdition ? (
          <InclusionButton member={props.entry} added></InclusionButton>
        ) : null
      }
    ></PresenceList.ListItem>
  )
}
