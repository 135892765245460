import { Chip } from "@material-ui/core"
import clsx from "clsx"
import CalendarEvent from "components/CalendarEvent/CalendarEvent"
import { Icon, IconProps } from "components/Icon/Icon"
import { Interface } from "components/Interface/Interface"
import {
  PageComponent,
  PageComponentProps,
} from "components/Page/hooks/useSecondPage"
import { NewPartnerFormPage } from "components/PageBoard/components/NewPartnerFormPage"
import I18nContext from "contexts/i18nContext/i18nContext"
import { excludeNullable } from "lib/excludeNullable"
import _ from "lodash"
import React from "react"
import { StyledProps } from "types/type"
import { CreateRoomPageComponent } from "../../../../../CreateRoomPageComponent/CreateRoomPageComponent"
import { ItemsListContext } from "../../context/ItemsListContext"
import { TypeBallBadge } from "components/PageBoard/components/TypeBallBadge"
import { PageBoardItemType } from "lib/graphql/types"
import TwoPages from "components/TwoPages/TwoPages"
import { purple, red } from "constants/colors"
import { EnhancedPage } from "components/EnhancedPage/EnhancedPage"

// const getPaper: (actionsAPI: ReturnType<typeof useActions>) => PageComponent = (
//   actions
// ) =>

function AdditionSidePaper(props: PageComponentProps) {
  const actionsAPI = useActions()

  return (
    <TwoPages.Provider>
      <TwoPages.Root
        style={{
          height: "100%",
        }}
        mainPage={
          <EnhancedPage
            style={{ height: "100%" }}
            onClose={props.onClose}
            HeaderProps={{
              iconButton: {
                iconName: "close",
                onClick: () => {
                  props.onClose()
                },
              },
            }}
            body={
              <TwoPages.Context.Consumer>
                {(api) => {
                  return (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        padding: 8,

                        justifyContent: "center",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        {actionsAPI.actions.map((action) => {
                          return (
                            <div key={action.id} style={{ width: "100%" }}>
                              <Chip
                                variant="outlined"
                                style={{
                                  marginBottom: 8,
                                  width: "100%",
                                  paddingTop: 20,
                                  paddingBottom: 20,
                                }}
                                onClick={() => {
                                  if (!action.Component) return
                                  api.openMiniPage(action.Component)
                                }}
                                label={action.text}
                                size="medium"
                                icon={
                                  <TypeBallBadge
                                    type={action.type}
                                    size={30}
                                    icon={action.icon}
                                    backgroundColor={action.backgroundColor}
                                  ></TypeBallBadge>
                                }
                              ></Chip>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                }}
              </TwoPages.Context.Consumer>
            }
          ></EnhancedPage>
        }
      ></TwoPages.Root>
    </TwoPages.Provider>
  )
}

export function AddItemToListButton(props: StyledProps) {
  const api = React.useContext(ItemsListContext)

  const actionsAPI = useActions()
  const { actions: actions, getAction: getComponent } = actionsAPI

  const open = React.useCallback(
    (type: string): void => {
      const Page = getComponent(type)

      if (!Page) return

      api.itemsListTwoPagesApi.openMiniPage(Page)
    },
    [api.itemsListTwoPagesApi.openMiniPage, actions]
  )

  return (
    <I18nContext.Consumer>
      {(t) => {
        return (
          <Chip
            icon={<Icon name="add"></Icon>}
            variant="outlined"
            style={props.style}
            size="medium"
            color="default"
            className={clsx(props.className)}
            label={t.new}
            onClick={() => {
              api.itemsListTwoPagesApi.openMiniPage(AdditionSidePaper)
            }}
          >
            {/* <span>
          <Icon name="plus_one"></Icon>
        </span> */}
            {/* <Typography>New</Typography> */}
            {/* <span style={{ marginLeft: 5 }}>New</span> */}
          </Chip>
        )
      }}
    </I18nContext.Consumer>

    // <I18nContext.Consumer>
    //   {(t) => {
    //     return (
    //       <Chip
    //         icon={<Icon name="add"></Icon>}
    //         variant="outlined"
    //         size="medium"
    //         color="default"
    //         label={t.new}
    //         // label={t.active}
    //         onClick={() => {}}
    //       >
    //         {/* <span>
    //     <Icon name="plus_one"></Icon>
    //   </span> */}
    //         {/* <Typography>New</Typography> */}
    //         {/* <span style={{ marginLeft: 5 }}>New</span> */}
    //       </Chip>
    //     )
    //   }}
    // </I18nContext.Consumer>
  )
}

type Item = {
  text: React.ReactNode
  icon?: IconProps["name"]
  backgroundColor?: string
  id: string
  Component?: PageComponent
  type?: PageBoardItemType
}

function useActions() {
  const interfaceApi = React.useContext(Interface.Context)

  const components: (Item | null)[] = [
    {
      text: <I18nContext.Consumer>{(t) => t["new-room"]}</I18nContext.Consumer>,

      type: PageBoardItemType.Room,
      id: "room",
      Component: CreateRoomPageComponent,
    },

    {
      text: (
        <I18nContext.Consumer>{(t) => t["new-event"]}</I18nContext.Consumer>
      ),
      type: PageBoardItemType.CalendarEvent,
      id: "calendar-event",
      Component: function CalendarEvents(args) {
        const indexedItemsApi =
          React.useContext(ItemsListContext).indexedItemsApi

        return (
          <CalendarEvent.Default
            onClose={args.onClose}
            BodyProps={{ style: { height: "100%" } }}
            onCreateEvent={() => {
              indexedItemsApi.refetch?.()
              args.onClose()
            }}
          ></CalendarEvent.Default>
        )
      },
    },
    {
      type: PageBoardItemType.Document,
      text: (
        <I18nContext.Consumer>{(t) => t["new-document"]}</I18nContext.Consumer>
      ),
      id: "document",
    },

    !interfaceApi.allowPartnerInsertion
      ? null
      : {
          text: (
            <I18nContext.Consumer>
              {(t) => t["new-partner"]}
            </I18nContext.Consumer>
          ),
          backgroundColor: red[0],
          icon: "business",
          id: "partner",
          Component: function NewPartner(args) {
            return (
              <NewPartnerFormPage
                onClose={args.onClose}
                style={{ height: "100%" }}
              ></NewPartnerFormPage>
            )
          },
        },
  ]

  const c = components.filter(excludeNullable)

  const byId = _.keyBy(c, (a) => a.id) as {
    [id: string]: Item | undefined | null
  }

  const getAction = (id: string) => {
    return byId[id]?.Component
  }

  return { actions: c, getAction }
}
