import _ from "lodash"
import { makeStyles, Paper, IconButton, Icon } from "@material-ui/core"
import clsx from "clsx"
import React, { ReactNode } from "react"
import { Nullable } from "types/type"

export default function ColoredHeaderWithCloseButton(props: {
  className?: string
  onClose?: () => any
  renderContent?: (c?: { left?: ReactNode; right?: ReactNode }) => ReactNode
  render?: (c?: { closeButton?: ReactNode; content?: ReactNode }) => ReactNode
  left?: ReactNode
  right?: ReactNode
  backgroundColor?: Nullable<string>
  hideCloseButton?: boolean
}) {
  const c = useStyles({ backgroundColor: props.backgroundColor })

  return (
    <Paper
      className={clsx(
        c.root,
        "colored-header-with-close-button",
        props.className
      )}
      square
    >
      {(function renderStuff() {
        const components = {
          closeButton: !props.hideCloseButton && (
            <IconButton
              className={c.arrow}
              onClick={props.onClose}
              size="small"
            >
              <Icon fontSize="small">arrow_back</Icon>
            </IconButton>
          ),

          content: (
            <div className={c.content}>
              {(function content() {
                const components = {
                  left: props.left || <span />,
                  right: props.right || <span />,
                }

                return typeof props.renderContent === "function" ? (
                  props.renderContent({
                    left: components.left,
                    right: components.right,
                  })
                ) : (
                  <>
                    {components.left && (
                      <div className="left">{components.left}</div>
                    )}
                    {components.right && (
                      <div className={clsx("right", c.right)}>
                        {components.right}
                      </div>
                    )}
                  </>
                )
              })()}
            </div>
          ),
        }

        return typeof props.render === "function" ? (
          props.render({
            closeButton: components.closeButton,
            content: components.content,
          })
        ) : (
          <>
            {components.closeButton}
            {components.content}
          </>
        )
      })()}
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor || theme.palette.primary.light,
      padding: theme.spacing(2, 3),
      display: "flex",
      color: "white",
      minWidth: ({ headerMinWidth }) =>
        _.isNumber(headerMinWidth) ? headerMinWidth : "auto",
    },
    arrow: {
      color: "white",
      position: "relative",
      left: -theme.spacing(2),
    },

    // right: { flex: 1, },
    left: {
      flex: 1,
    },
    content: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& *": {
        color: "white",
      },
    },
  }
})
