import { WatchQueryFetchPolicy } from "@apollo/client"
import useMemoAPI from "hooks/useMemoAPI"
import { ID } from "types/type"
import { useWorkspaceQuery } from "../../lib/graphql/operations"

export default function useWorkspace(props: {
  workspaceID?: ID
  fetchPolicy?: WatchQueryFetchPolicy
}) {
  const query = useWorkspaceQuery({
    skip: !props.workspaceID,
    variables: { workspaceID: props.workspaceID || "" },
    fetchPolicy: props.fetchPolicy || "cache-first",
  })

  return useMemoAPI({
    workspace: query.data?.workspace || query.previousData?.workspace,
    isLoading: query.loading,
  })
}
