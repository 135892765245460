import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import clsx from "clsx"
import Transition from "react-transition-group/Transition"

export default function IndexTrack(props: {
  show: boolean
  className?: string
}) {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const total = api.images.data?.list?.length || 0
  const index = api.images.modal.selected.index

  const c = useIndexTrackStyles()
  // if (!total || !props.show) return null
  return (
    <Transition in={props.show} timeout={200}>
      {(a) => {
        const b: Record<typeof a, React.CSSProperties> = {
          exited: { display: "none" },
          entering: { opacity: 0, display: "block", transition: "500ms" },
          entered: { opacity: 1, display: "block", transition: "500ms" },
          exiting: { opacity: 0, display: "block", transition: "500ms" },
          unmounted: { display: "none" },
        }

        return (
          <div
            className={clsx(props.className, "index-track", c.root)}
            style={b[a]}
          >
            <Typography variant="body2" color="textSecondary">
              {index + 1} of {total}
            </Typography>
          </div>
        )
      }}
    </Transition>
  )
}
const useIndexTrackStyles = makeStyles((theme) => {
  return {
    root: {
      // background: "rba(0,0,0)"
      // borderRadius: '6px'
      padding: theme.spacing(0.75),
      // padding: "6px",
      borderRadius: "6px",
      background: "#f7f6f6",
    },
  }
})
