import { makeStyles } from "@material-ui/styles"
import React from "react"
import { Icon, Typography, Paper } from "@material-ui/core"
import clsx from "clsx"
import { alignmentUnits } from "styles/globalStyles"

function SuccessFeedback({ onClickComeback, className }) {
  const c = useStyles({})

  return (
    <div className={clsx(c.successFeedback, className)}>
      <Paper className={c.paper}>
        <Icon className={c.icon} color="primary">
          check_circle
        </Icon>
        <Typography variant="h4" color="textSecondary">
          hochgeladen mit Erfolg
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={c.downloadMore}
          onClick={onClickComeback}
        >
          mehr hochladen
        </Typography>
      </Paper>
    </div>
  )
}

const useStyles = makeStyles(theme => {
  return {
    successFeedback: { padding: theme.spacing(3, alignmentUnits) },
    paper: {
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "&>*:not(:last-child)": {
        marginBottom: theme.spacing(2)
      }
    },
    icon: {
      fontSize: "4.5rem"
    },

    downloadMore: {
      textDecoration: "underline",
      cursor: "pointer"
    }
  }
})
export default SuccessFeedback
