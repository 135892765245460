export function computeTextElementNumberOfLines(
  containerRef: HTMLElement | null
) {
  if (!containerRef) return

  const lineHeight = parseInt(
    getComputedStyle(containerRef as Element).lineHeight
  )

  const containerHeight = containerRef.clientHeight

  if (typeof containerHeight !== "number") return

  return Math.round(containerHeight / lineHeight)
}
