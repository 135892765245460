import React from "react"
import Case from "case"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import colors from "../../styles/color"
import { UserStatus } from "lib/graphql/types"

export default function StatusDot(props: {
  status?: UserStatus | null
  className?: string
  style?: React.CSSProperties
}) {
  const c = useStyles()

  if (!props.status) return null

  if (![UserStatus.Online, UserStatus.Offline].includes(props.status))
    return null

  return (
    <div
      className={clsx(
        c.statusDot,
        Case.lower(props.status || ""),
        props.className
      )}
      style={props.style}
    />
  )
}

const useStyles = makeStyles((theme) => {
  const w = 10

  return {
    statusDot: {
      width: w,
      height: w,
      borderRadius: "50%",

      "&.online": {
        background: colors.onlineDot,
      },

      "&.offline": {
        background: colors.offlineDot,
      },
    },
  }
})
