import { ApolloError } from "@apollo/client"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import useApolloCacheReset from "components/ApolloSetup/hooks/useApolloCacheReset"
import AudibleNotifications from "components/AudibleNotification/AudibleNotification"
import ComposeProviders from "components/ComposeProviders/ComposeProviders"
import ConnectionStatusWarning from "components/ConnectionStatusWarning/ConnectionStatusWarning"
import ErrorController from "components/ErrorSnackbar/ErrorController"
import GraphqlSubscriptions from "components/GraphqlSubscriptions/GraphqlSubscriptions"
import { Interface } from "components/Interface/Interface"
import LoadingPage from "components/LoadingPage/LoadingPage"
import { NotificationsAPI } from "components/NotificationsAPI/NotificationsAPI"
import Shareable from "components/Shareable/Shareable"
import Title from "components/Title/Title"
import Workspaces from "components/Workspaces/Workspaces"
import {
  useIsLoggedQuery,
  useLoginWithTokenMutation,
} from "lib/graphql/operations"
import isProduction from "lib/isProduction"
import Page404 from "pages/Page404/Page404"
import React, { Suspense } from "react"
import { Helmet } from "react-helmet"
import { Redirect, Route, Switch } from "react-router-dom"
import * as serviceWorker from "serviceWorker"

const Home = React.lazy(() => import("pages/Home/Home"))
const Login = React.lazy(() => import("pages/Login/Login"))
const Logout = React.lazy(() => import("pages/Logout/index"))

function App() {
  const c = useStyles()

  const loginQuery = useIsLoggedQuery({
    fetchPolicy: "no-cache",
  })

  const isLogged = loginQuery?.data?.isLogged

  React.useEffect(() => {
    serviceWorker.register()
  }, [])

  return (
    <div className={clsx(c.app, "app")}>
      <Helmet>
        <meta name="theme-color" content="#2f1937" />
      </Helmet>
      <ConnectionStatusWarning />
      <ErrorController.Provider>
        <React.Fragment>
          <ErrorController.SnackBar />
          <div>
            <div className={c.content}>
              <div />
              <Suspense fallback={<LoadingPage />}>
                <Switch>
                  {isLogged && (
                    <ComposeProviders
                      Providers={[
                        Workspaces.Provider,
                        GraphqlSubscriptions.Provider,
                        // ChatOverlay.Provider,
                        // Toasts.Provider,

                        AudibleNotifications.Provider,
                        NotificationsAPI.Provider,
                        Interface.Provider,
                      ]}
                    >
                      <Title></Title>
                      {!isProduction && <AbilityToLoginWithToken />}
                      <AudibleNotifications.Audio></AudibleNotifications.Audio>
                      <Switch>
                        {[
                          <Redirect path="/login" to="/" key="logged-login" />,
                          <Route
                            render={(props) => {
                              return (
                                <Shareable.Redirector
                                  url={new URL(window.location.href)}
                                  pathname404="/404"
                                ></Shareable.Redirector>
                              )
                            }}
                            path={Shareable.pathnameRoot}
                            key="shareable"
                          />,
                          <Route
                            component={Logout}
                            path="/logoff"
                            key="logout"
                          />,
                          <Route component={Page404} path="/404" key="404" />,
                          <Route component={Home} path="/" key="home" />,
                        ].filter(Boolean)}
                      </Switch>
                    </ComposeProviders>
                  )}
                  {!isLogged &&
                    !loginQuery.loading && [
                      <Route
                        render={() => (
                          <Login onLogin={loginQuery.refetch}></Login>
                        )}
                        path="/login"
                        key="login"
                      />,
                      <Redirect
                        key="redirect-to-login"
                        to={{
                          pathname: "/login",
                          ...(window.location.pathname !== "/logoff"
                            ? {
                                state: { from: window.location.pathname },
                              }
                            : {}),
                        }}
                      />,

                      <Route component={Logout} path="/logoff" key="logout" />,
                    ]}
                </Switch>
              </Suspense>
            </div>
          </div>
        </React.Fragment>
      </ErrorController.Provider>
    </div>
  )
}

export default App

const useStyles = makeStyles(() => {
  return {
    app: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flex: 1,
    },
  }
})

function AbilityToLoginWithToken() {
  const [mutation] = useLoginWithTokenMutation()
  const { resetCache } = useApolloCacheReset()

  const login = React.useCallback(
    async (token: string) => {
      if (isProduction) throw new Error("BANG!")

      await resetCache()

      await mutation({ variables: { token } })
        .then(() => {
          // window.location.
          window.location.href = "/"
          window.location.reload()
        })
        .catch((error: ApolloError) => {
          console.log({ error })
        })
    },
    [resetCache]
  )

  React.useEffect(() => {
    //@ts-ignore
    window.loginWithToken = login
  }, [])

  return null
}
