import Checkbox from "components/Input/components/Checkbox/Checkbox"
import RadioGroup from "components/Input/components/RadioGroup/RadioGroup"
import SelectInput from "components/Input/components/SelectInput/SelectInput"
import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { I18nAPI } from "contexts/i18nContext/i18nContext"
import { ContactFormContext } from "../components/ProviderAndContext/ProviderAndContext"

const selectOptions = [
  {
    label: "Ja",
    value: "YES",
  },
  {
    label: "Nein",
    value: "NO",
  },
  {
    label: "OrderResp",
    value: "ORDERRESP",
  },
  {
    label: "Order",
    value: "ORDER",
  },
  {
    label: "Invoice",
    value: "INVOICE",
  },
]

export function getSettingsFieldProps(props: { translations: I18nAPI }) {
  const { translations } = props

  const fieldProps: FieldsetIndentationProps = {
    icon: "settings",
    children: (
      <ContactFormContext.Consumer>
        {(api) => {
          return (
            <>
              <SelectInput
                {...api.form.getFieldProps("notifyAccessKey")}
                label={translations["automatic-notification"]}
                options={selectOptions}
                style={{ width: "100%" }}
              ></SelectInput>
              <Checkbox
                label={translations["create-access-on-upload"]}
                {...api.form.getFieldProps("createColKey")}
                style={{ display: "block", marginBottom: 0 }}
              ></Checkbox>

              <Checkbox
                label={translations["weekly-reminder-email-for-new-documents"]}
                {...api.form.getFieldProps("remindMe")}
                style={{ display: "block" }}
              ></Checkbox>

              <RadioGroup
                options={[
                  { label: translations.yes, value: "YES" },
                  { label: translations.no, value: "NO" },
                  { label: translations.direct, value: "DIRECT" },
                ]}
                label={translations["attach-file-to-email"]}
                {...api.form.getFieldProps("attachFile")}
                style={{ width: "100%" }}
                styles={{
                  radioGroup: {
                    flexDirection: "row",
                  },
                }}
              ></RadioGroup>
            </>
          )
        }}
      </ContactFormContext.Consumer>
    ),
  }

  return fieldProps
}
