import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { ID } from "types/type"
import { KanbanEditAPI } from "components/Kanban/@types"
// import { KanbanEditAPI } from "../@types/index"

export default function useEditAPI() {
  const [id, setID] = React.useState<string | null>(null)

  const isEditing = React.useCallback(
    function isEditing(columnID: ID, cardID?: ID) {
      return id === getID(columnID, cardID)
    },
    [id]
  )

  const startEdition = React.useCallback(function startEdition(
    columnID: ID,
    cardID?: ID
  ) {
    setID(() => getID(columnID, cardID))
  },
  [])

  const cancelEdition = React.useCallback(function cancelEdition() {
    setID(null)
  }, [])

  const { cardID, columnID } = parseID(id)

  const api: KanbanEditAPI = {
    isEditing,
    startEdition,
    cancelEdition,
    editingCardID: cardID,
    editingColumnID: columnID,
  }

  return useMemoAPI(api)
}

const getID = (columnID: ID, cardID?: ID) => {
  return [columnID, cardID].filter(Boolean).join("-")
}

const parseID = (id: string | null) => {
  if (!id) return { columnID: null, cardID: null }

  const [columnID, cardID] = id?.split("-")

  return { columnID, cardID }
}
