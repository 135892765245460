import _ from "lodash"
import PaperList from "components/PaperList"
import React, { useContext } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import getStringFromMessageContent from "lib/getStringFromMessageContent"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { MessageFragment } from "lib/graphql/operations"
import RootContainer from "components/RootContainer/RootContainer"

export default function ShortMessageLine(props: {
  message: MessageFragment
  RootContainer: typeof RootContainer
  hideAuthorName?: boolean
  maxPercentageMap?: React.ComponentProps<
    typeof RootContainer.ClippedText
  >["maxPercentageMap"]
}) {
  const t = useI18n()

  if (!props.message) return null

  const message = (function getLastMessage() {
    return {
      ...props.message,
      content: getStringFromMessageContent({
        message: props.message,
        translations: t,
      }),
    }
  })()

  return (
    <>
      <props.RootContainer.ClippedText
        maxPercentageMap={
          props.maxPercentageMap || {
            small: "40%",
            medium: "60%",
            large: "70%",
          }
        }
      >
        <>
          {!props.hideAuthorName && (
            <React.Fragment>
              <Typography component="span" variant="body2" color="textPrimary">
                {message.author.name}
              </Typography>
              {" - "}
            </React.Fragment>
          )}

          {message.content}
        </>
      </props.RootContainer.ClippedText>
    </>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    richText: {
      "&, & *": {
        display: "inline !important",
        color: theme.palette.text.secondary,
      },
    },
  }
})
