import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { defaultMeetingArgs } from "../config/defaultMeetingArgs"
import { b64DecodeUnicode, generateVideoToken } from "../utils/util"

// export interface MeetingArgs {
//   sdkKey: string
//   topic: string
//   name: string
//   signature: string
//   password?: string
//   sessionKey?: string
//   userIdentity?: string
//   role?: string | number
//   web?: string
//   enforceGalleryView?: number
//   enforceVB?: number
//   cloud_recording_option?: number
//   cloud_recording_election?: number
//   telemetry_tracking_id: string
//   useVideoPlayer: number
//   sdkSecret?: string
//   [key: string]: any
// }
export interface MeetingArgs {
  topic?: string
  name?: string
  signature?: string
  password?: string
  sessionKey?: string
  userIdentity?: string
  role?: string | number
  web?: string
  enforceGalleryView?: number
  enforceVB?: number
  lang?: string
  customerJoinId?: string
  webEndpoint?: string
  cloud_recording_option?: number
  cloud_recording_election?: number
  telemetry_tracking_id?: string
  useVideoPlayer?: number
  sdkKey?: string
  sdkSecret?: string
}

function useMeetingArgs(props?: { meetingArgs?: Partial<MeetingArgs> }) {
  let meetingArgs: MeetingArgs = { ...props?.meetingArgs } as MeetingArgs // Add enforceGalleryView to turn on the gallery view without SharedAddayBuffer

  if (
    !meetingArgs.sdkKey ||
    !meetingArgs.topic ||
    !meetingArgs.name ||
    !meetingArgs.signature
  ) {
    meetingArgs = { ...defaultMeetingArgs, ...meetingArgs }
  }

  if (meetingArgs.web && meetingArgs.web !== "0") {
    const keys = [
      "topic",
      "name",
      "password",
      "sessionKey",
      "userIdentity",
    ] as (keyof MeetingArgs)[]

    keys.forEach((field) => {
      if (Object.hasOwn(meetingArgs, field)) {
        try {
          // @ts-ignore
          meetingArgs[field] = b64DecodeUnicode(meetingArgs[field] as string)
        } catch (e) {
          console.log("ingore base64 decode", field, meetingArgs[field])
        }
      }
    })
    if (meetingArgs.role) {
      meetingArgs.role = parseInt(meetingArgs.role + "", 10)
    } else {
      meetingArgs.role = 1
    }
  }
  // enforce use <video> tag render video, https://marketplacefront.zoom.us/sdk/custom/web/modules/Stream.html#attachVideo
  meetingArgs.useVideoPlayer = 1

  const keys = [
    "enforceGalleryView",
    "enforceVB",
    "cloud_recording_option",
    "cloud_recording_election",
  ] as (keyof MeetingArgs)[]

  keys.forEach((field) => {
    if (Object.hasOwn(meetingArgs, field)) {
      try {
        // @ts-ignore
        meetingArgs[field] = Number(meetingArgs[field])
      } catch (e) {
        // @ts-ignore
        meetingArgs[field] = 0
      }
    }
  })
  if (meetingArgs?.telemetry_tracking_id) {
    try {
      meetingArgs.telemetry_tracking_id = b64DecodeUnicode(
        meetingArgs.telemetry_tracking_id
      )
    } catch (e) {}
  } else {
    meetingArgs.telemetry_tracking_id = ""
  }

  if (!meetingArgs.signature && meetingArgs.sdkSecret && meetingArgs.topic) {
    meetingArgs.signature = generateVideoToken(
      meetingArgs.sdkKey || "",
      meetingArgs.sdkSecret,
      meetingArgs.topic,
      meetingArgs.sessionKey,
      meetingArgs.userIdentity,
      Number(meetingArgs.role ?? 1)
      // meetingArgs.cloud_recording_option  ? meetingArgs.cloud_recording_option + "" : "0",
      // meetingArgs.cloud_recording_election,
      // meetingArgs.telemetry_tracking_id
    )

    console.log("=====================================")
    console.log("meetingArgs", meetingArgs)

    const urlArgs: any = {
      topic: meetingArgs.topic,
      name: meetingArgs.name,
      password: meetingArgs.password,
      sessionKey: meetingArgs.sessionKey,
      userIdentity: meetingArgs.userIdentity,
      role: meetingArgs.role || 1,
      cloud_recording_option: meetingArgs.cloud_recording_option || "",
      cloud_recording_election: meetingArgs.cloud_recording_election || "",
      telemetry_tracking_id: meetingArgs.telemetry_tracking_id || "",
      enforceGalleryView: 0,
      enforceVB: 0,
      web: "1",
    }
    console.log("use url args")
    console.log(
      window.location.origin + "/?" + new URLSearchParams(urlArgs).toString()
    )
  }

  return meetingArgs
}
const F = contextAndProviderFactory({
  hookApi: useMeetingArgs,
  contextName: "MeetingArgsContext",
})
export const MeetingArgsContext = F.Context
export const MeetingArgsProvider = F.Provider
