import KanbanProvider from "./components/Provider/Provider"
import KanbanRoot from "./components/KanbanRoot/KanbanRoot"
import KanbanContext from "./contexts/KanbanContext"

const Kanban = {
  Provider: KanbanProvider,
  Context: KanbanContext,
  Root: KanbanRoot,
}

export default Kanban
