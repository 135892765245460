import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import React from "react"
import { SELECTION_CHANGE_COMMAND, FORMAT_TEXT_COMMAND } from "lexical"
import {
  INSERT_ORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from "@lexical/list"
import clsx from "clsx"
import FormatButton from "./components/FormatButton/FormatButton"
import { makeStyles, Toolbar } from "@material-ui/core"
import LinkEditor from "./components/LinkEditor/LinkEditor"
import ToggleHeadButton from "./components/ToggleHeadButton/ToggleHeadButton"
import _ from "lodash"
import ToolbarPluginContext from "./contexts/ToolbarPluginContext"
import useToolbarPluginAPI, {
  ToolbarPluginAPIInput,
} from "./hooks/useToolbarPluginAPI"
// import { ToolbarPluginAPIInput } from "./types"

export default function ToolbarPlugin(
  props: {
    className?: string
    style?: React.CSSProperties
  } & ToolbarPluginAPIInput
) {
  const [editor] = useLexicalComposerContext()

  const c = useStyles()

  const { className, style, ...inputProps } = props

  const api = useToolbarPluginAPI(inputProps)

  return (
    <ToolbarPluginContext.Provider value={api}>
      <ToolbarPluginContext.Consumer>
        {(api) => {
          return (
            <div
              className={clsx("toolbar-plugin", props.className, c.root)}
              style={props.style}
              ref={api.toolbarRef}
            >
              <div className={clsx("format-buttons", c.formatButtons)}>
                <FormatButton
                  isActive={api.isBold}
                  iconName="format_bold"
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
                  }}
                ></FormatButton>

                <FormatButton
                  isActive={api.isItalic}
                  iconName="format_italic"
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
                  }}
                ></FormatButton>

                <FormatButton
                  isActive={api.isUnderline}
                  iconName="format_underlined"
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
                  }}
                ></FormatButton>
                <FormatButton
                  isActive={api.isStrikethrough}
                  iconName="strikethrough_s"
                  onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
                  }}
                ></FormatButton>

                {(() => {
                  const isList = api.blockType === "ul"

                  return (
                    <FormatButton
                      isActive={isList}
                      iconName="list"
                      onClick={() => {
                        if (isList) {
                          return editor.dispatchCommand(
                            REMOVE_LIST_COMMAND,
                            undefined
                          )
                        }

                        return editor.dispatchCommand(
                          INSERT_UNORDERED_LIST_COMMAND,
                          undefined
                        )
                      }}
                    ></FormatButton>
                  )
                })()}
                {(() => {
                  const isList = api.blockType === "ol"

                  return (
                    <FormatButton
                      isActive={isList}
                      iconName="format_list_numbered"
                      onClick={() => {
                        if (isList) {
                          return editor.dispatchCommand(
                            REMOVE_LIST_COMMAND,
                            undefined
                          )
                        }

                        return editor.dispatchCommand(
                          INSERT_ORDERED_LIST_COMMAND,
                          undefined
                        )
                      }}
                    ></FormatButton>
                  )
                })()}
                <ToggleHeadButton
                  blockType="h1"
                  isActive={api.blockType === "h1"}
                  editor={editor}
                ></ToggleHeadButton>

                <ToggleHeadButton
                  blockType="h2"
                  isActive={api.blockType === "h2"}
                  editor={editor}
                ></ToggleHeadButton>

                {/* <button
            onClick={insertLink}
            className={"toolbar-item spaced " + (isLink ? "active" : "")}
            aria-label="Insert Link"
          >
            <i className="format link" />
          </button>
          {isLink &&
            createPortal(<FloatingLinkEditor editor={editor} />, document.body)} */}
              </div>
              {/* {isLink && <LinkEditor></LinkEditor>} */}
            </div>
          )
        }}
      </ToolbarPluginContext.Consumer>
    </ToolbarPluginContext.Provider>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    "&>*": {
      // marginLeft: theme.spacing(2),
      // marginRight: theme.spacing(2),
    },
  },

  formatButtons: {
    marginLeft: -theme.spacing(1),
    width: "100%",
    display: "flex",
  },
}))
