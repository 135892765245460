import { useApolloClient, useMutation } from "@apollo/client";
import { ID, RoomQueryVariables } from "types/type"
import _ from "lodash"
import React from "react"
import {
  // KanbanAPIProps,
  TransformKanbanCard,
} from "components/Kanban/@types"
import {
  ChangeRoomKanbanCardDocument,
  KanbanCardFragment,
  KanbanCardFragmentDoc,
} from "lib/graphql/operations"
import { KanbanAPIInput } from "components/Kanban/hooks/useKanbanAPI"

export default function useSetCard(
  props: RoomQueryVariables
): KanbanAPIInput["setCard"] {
  const client = useApolloClient()
  const [mutate] = useMutation(ChangeRoomKanbanCardDocument)

  return React.useCallback(
    async function setCard(cardID: ID, transform: TransformKanbanCard) {
      const graphqlCard = client.readFragment({
        fragmentName: "KanbanCard",
        fragment: KanbanCardFragmentDoc,
        id: `KanbanCard:${cardID}`,
      }) as KanbanCardFragment | undefined

      if (!graphqlCard) return

      const card = _.omit(graphqlCard, "__typename")

      const newCard = transform(card)

      if (!newCard) return

      await mutate({
        variables: {
          workspaceID: props.workspaceID,
          roomID: props.roomID,
          cardID: cardID,
          card: _.pick(newCard, ["id", "columnID", "description", "title"]),
        },
        optimisticResponse: {
          changeRoomKanbanCard: { ...newCard, __typename: "KanbanCard" },
        },
      })
    },
    [mutate, client]
  )
}
