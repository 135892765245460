import React from "react"
import { useTheme, useMediaQuery } from "@material-ui/core"
import useMemoAPI from "hooks/useMemoAPI"

export default function useDeviceObserver() {
  const theme = useTheme()

  return useMemoAPI({
    isSmallMobile: useMediaQuery(theme.breakpoints.down("xs")),
    isMobile: useMediaQuery(theme.breakpoints.down("sm")),
    isDesktop: useMediaQuery(theme.breakpoints.up("lg")),
    isLargeDesktop: useMediaQuery(theme.breakpoints.up("xl")),
    fromTablet: useMediaQuery(theme.breakpoints.up("sm")),
  })
}
