import _ from "lodash"
import React from "react"
import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import EditorModal from "../EditorModal/EditorModal"

export default function EditCommentModal() {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const edition = api.comments.edition

  const comment = api.comments.edition.ui.selectedComment

  const getEditorKey = React.useCallback(() => comment?.id || "", [comment?.id])

  const t = useI18n()

  return (
    <EditorModal
      onClose={edition.ui.close}
      editable
      markdownMode={false}
      namespace="edit-comment-modal"
      isOpen={edition.ui.isOpen}
      initialValue={comment?.text || ""}
      onSave={edition.edit}
      saveButtonText={t["save"]}
      getEditorKey={getEditorKey}
    ></EditorModal>
  )
}
