import { useApolloClient } from "@apollo/client";
import { RemoveKanbanCardCoverImageDocument } from "lib/graphql/operations"
import React from "react"
import { ID } from "types/type"

export default function useRemoveCoverImage(props: {
  workspaceID: ID
  onChangeLoadingState?: (state: boolean) => void
}) {
  const client = useApolloClient()

  return React.useCallback(
    async function removeCoverImage(cardID: ID) {
      props.onChangeLoadingState?.call(null, true)

      await client
        .mutate({
          mutation: RemoveKanbanCardCoverImageDocument,
          variables: { workspaceID: props.workspaceID, cardID },
          optimisticResponse: {
            removeKanbanCardCoverImage: {
              __typename: "KanbanCardImages",
              cardID,
              cover: null,
            },
          },
        })
        .finally(() => props.onChangeLoadingState?.call(null, false))
    },
    [props.onChangeLoadingState, props.workspaceID]
  )
}
