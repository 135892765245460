import { ID, ShareableType } from "types/type"

import _ from "lodash"
import Shareable from "components/Shareable/Shareable"
import clsx from "clsx"
import { useContext } from "react"
import React, { useRef } from "react"
import PartnerProfile from "../../../PartnerProfile/PartnerProfile"
import ContactProfile from "../../../ContactProfile/ContactProfile"
import { ListItemText, makeStyles } from "@material-ui/core"
import ColoredHeaderWithCloseButton from "components/ColoredHeaderWithCloseButton/ColoredHeaderWithCloseButton"
import PartnersContext from "pages/Partners/contexts/PartnersContext"

export function CollectionDetails() {
  const {
    closeDetails,
    collection,
    collectionID,
    collectionTypeColor,
    CollectionDashboard,
  } = useContext(PartnersContext)

  const c = useStyles({})
  const rootRef = useRef<HTMLDivElement | null>(null)

  if (!collection) return null

  const viewID = collection.type === "CONTACT" ? ViewID.CONTACT : ViewID.PARTNER

  return (
    <div className={clsx(c.root, "collection-details")} ref={rootRef}>
      <ColoredHeaderWithCloseButton
        backgroundColor={collectionTypeColor}
        left={
          <ListItemText
            primary={
              <>
                {collection?.title}
                <Shareable.CopyLink
                  shareableID={collection?.id as ID}
                  shareableType={collection?.type as ShareableType}
                ></Shareable.CopyLink>
              </>
            }
          ></ListItemText>
        }
        right={<ListItemText secondary={collection?.number}></ListItemText>}
        onClose={closeDetails}
      />

      <CollectionDashboard.Provider
        collectionID={collectionID}
        detailsOverlayAnchor={rootRef.current}
        renderDetailsOnOverlay
      >
        <div className="view">{views[viewID]}</div>
      </CollectionDashboard.Provider>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",

      "&>*.view": {
        flex: 1,
        overflow: "hidden",
        height: "100%",
        maxHeight: "100%",
      },
    },
  }
})

enum ViewID {
  PARTNER = "PARTNER",
  CONTACT = "CONTACT",
}

const views = {
  [ViewID.CONTACT]: <ContactProfile></ContactProfile>,
  [ViewID.PARTNER]: <PartnerProfile></PartnerProfile>,
}
