import _ from "lodash"

export default function formatPrice(
  price: number,
  currency: "€" | "$ " | "£" = "€"
) {
  if ("Intl" in window) {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(price)
  }

  const calculatedPrice = _.round(price, 2)

  const value = `${currency || ""} ${calculatedPrice.toFixed(2)}`

  return value
}
