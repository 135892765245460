import contextAndProviderFactory from "lib/contextAndProviderFactory"

export type ZoomApiInput = {
  manageLeaving?: (callback: () => void) => void
}

function useZoomApiInput(props: ZoomApiInput) {
  return props
}

const F = contextAndProviderFactory({
  hookApi: useZoomApiInput,
  contextName: "ZoomApiInputContext",
})

export const ZoomApiInputContext = F.Context
export const ZoomApiInputProvider = F.Provider
