import _ from "lodash"
import React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import useActiveResources from "hooks/useActiveResources"
import useRoomsMembers from "hooks/useRoomsMembers/useRoomsMembers"
import useRooms from "hooks/useRooms/useRooms"
import useLastValue from "hooks/useLastValue/useLastValue"
import RootContainer from "components/RootContainer/RootContainer"
import useMemoAPI from "hooks/useMemoAPI"
import { RenderLeft, RenderRight } from "components/AppBar/AppBar"
import { sortRooms } from "components/DevDraft/sortRooms"
import { useSubscription } from "observable-hooks"
import useActiveRoomIsRemoved$ from "./useActiveRoomIsRemoved$"
import { RoomIdentifier } from "types/type"
import { UserFragment } from "lib/graphql/operations"

export type ChatAPIProps = {
  getRoomPathname?: (roomID: string) => string | Promise<string>
  roomIdentifier?: RoomIdentifier | null
  tabID?: string
  onChangeRoomIdentifier?: (identifier: RoomIdentifier | null) => void
  onChangeTabID?: (tabID: string) => void
  renderRoomAppBar?: (props: {
    search: any
    showProgressBar?: boolean
    title?: React.ReactNode
    renderLeft?: RenderLeft
    renderRight?: RenderRight
  }) => React.ReactNode
  renderRoomSideSheet?: any
  getOutOfRoom?: () => void
}

export default function useChatAPI(props: ChatAPIProps) {
  const {
    roomIdentifier: propsRoomIdentifier = null,
    tabID: propsTabID,
    onChangeRoomIdentifier: propsOnChangeRoomIdentifier,
    onChangeTabID: propsOnChangeTabID,
    renderRoomAppBar,
    renderRoomSideSheet,
    getOutOfRoom: propsGetOutOfRoom,
  } = props

  const { workspaceID } = useActiveResources()

  const [roomIdentifier, setRoomIdentifier] =
    React.useState<RoomIdentifier | null>(propsRoomIdentifier || null)

  useEffect(() => {
    if (JSON.stringify(roomIdentifier) === JSON.stringify(propsRoomIdentifier))
      return
    if (typeof propsOnChangeRoomIdentifier !== "function") return
    propsOnChangeRoomIdentifier(roomIdentifier)
  }, [JSON.stringify(roomIdentifier)])

  useEffect(() => {
    if (JSON.stringify(roomIdentifier) === JSON.stringify(propsRoomIdentifier))
      return
    console.log(propsRoomIdentifier)
    setRoomIdentifier(propsRoomIdentifier)
  }, [JSON.stringify(propsRoomIdentifier)])

  const [tabID, setTabID] = useState(propsTabID)

  useEffect(() => {
    if (tabID === propsTabID) return
    if (typeof propsOnChangeTabID !== "function") return

    propsOnChangeTabID(tabID)
  }, [tabID])

  useEffect(() => {
    if (tabID === propsTabID) return
    setTabID(propsTabID)
  }, [propsTabID])

  const goToPrivateRoom = useCallback(
    async ({ userID }) => {
      setRoomIdentifier({ userID })
    },
    [setRoomIdentifier]
  )

  const { roomsMembers, isLoading: roomsMembersAreLoading } = useRoomsMembers({
    workspaceID,
  })

  const [filter, setFilter] = useState("")

  const { rooms, isLoading: roomsAreLoading } = useRooms({ workspaceID })

  const sortedRooms = useMemo(() => {
    const r = (rooms || []).filter((r) => {
      if (!filter) return true

      if (!r.name) return false

      const getFilter = (text?: string | undefined | null) =>
        (text || "").toLowerCase().includes(filter.toLowerCase())

      return getFilter(r.name) || getFilter(r.context)
    })

    // .sort(sortRooms)
    return sortRooms(r)
  }, [sortRooms, filter, rooms])

  const filteredMembers = useMemo(
    () =>
      (roomsMembers || []).filter((user: UserFragment) => {
        if (!filter) return true

        return [user?.account?.name, user?.name]
          .filter((a): a is string => !!a)
          .some((string) => new RegExp(filter, "i").test(string))
      }),
    [roomsMembers, filter]
  )

  const getOutOfRoom = useCallback(() => {
    return setRoomIdentifier(null)
  }, [])

  const activeRoomIsRemoved$ = useActiveRoomIsRemoved$({ roomIdentifier })

  useSubscription(activeRoomIsRemoved$, getOutOfRoom)

  return useMemoAPI({
    roomIdentifier,
    getRoomPathname: props.getRoomPathname,
    setRoomIdentifier,
    RootContainer,
    renderRoomAppBar,
    renderRoomSideSheet,
    getOutOfRoom: propsGetOutOfRoom || getOutOfRoom,
    roomsMembers: filteredMembers,
    roomsMembersAreLoading,
    filter,
    setFilter,
    roomsAreLoading,
    goToPrivateRoom,
    rooms: sortedRooms,
    tabID,
    setTabID,
  })
}
