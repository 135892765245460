import _ from "lodash"
// import ChatContext from "components/Chat/contexts/ChatContext"
import React, { useContext, useRef } from "react"
import { Fab, Icon, makeStyles } from "@material-ui/core"
// import { alignmentUnits } from "styles/globalStyles"
import clsx from "clsx"
// import useResizeObserver from "use-resize-observer"
import { useEffect } from "react"
import ChatOverlayContext from "../../contexts/ChatOverlayContext"
import LightTooltip from "components/LightTooltip/LightTooltip"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"

export default function ChatBalloon({ className = "" }) {
  const rootRef = useRef(null)
  const { workspace } = useCurrentWorkspace() || {}

  const unread = workspace?.notificationsCount?.visible || 0

  const {
    setFloatingButtonPosition,
    overlayIsOpen,
    toggleOverlay,
    chatBalloonIsHidden,
  } = useContext(ChatOverlayContext)

  useEffect(() => {
    if (!rootRef.current) return

    const rect = rootRef.current.getBoundingClientRect()

    setFloatingButtonPosition({ x: rect.left, y: rect.bottom })
  }, [])

  if (chatBalloonIsHidden) return null

  return (
    <div
      className={clsx("chat-balloon", className)}
      ref={rootRef}
      onClick={toggleOverlay}
    >
      <LightTooltip
        title={overlayIsOpen && "close chat"}

        // open={overlayIsOpen}
      >
        <PurpleBadge badgeContent={overlayIsOpen ? undefined : unread}>
          <Fab color="primary">
            <Icon>{overlayIsOpen ? "close" : "chat"}</Icon>
          </Fab>
        </PurpleBadge>
      </LightTooltip>
    </div>
  )
}
