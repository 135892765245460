import React from "react"
import useGetRoomWorkspacesIDs from "hooks/useGetRoomWorkspacesIDs"
import Workspaces from "components/Workspaces/Workspaces"

export default function useIsRoomMuted() {
  const getRoomWorkspacesIDs = useGetRoomWorkspacesIDs()
  const { getWorkspace } = React.useContext(Workspaces.Context)

  const isRoomMuted = React.useCallback(
    function isRoomMuted(props: { roomID: string }) {
      const roomID = props.roomID

      const workspacesIDs = getRoomWorkspacesIDs({ roomID })

      const roomWorkspaces = workspacesIDs
        .map((id) => getWorkspace({ workspaceID: id }))
        .filter(Boolean)

      const roomIsMuted = roomWorkspaces.some((w) =>
        w.settings?.silencedRoomsIDs?.includes(roomID)
      )

      return roomIsMuted
    },
    [getRoomWorkspacesIDs, getWorkspace]
  )

  return isRoomMuted
}
