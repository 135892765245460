import { useRef } from "react"
import useMemoAPI from "hooks/useMemoAPI"
import useComponentSize from "@rehooks/component-size"

export enum SizeEnums {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  COLLAPSED = "collapsed",
}

export type BreakingPoints = { isMedium?: number; isLarge?: number }

export type RootContainerAPI = ReturnType<typeof useRootContainerAPI>

export default function useRootContainerAPI(props?: {
  breakingPoints?: BreakingPoints
}) {
  const containerRef = useRef<HTMLDivElement>(null)
  const size = useComponentSize(containerRef)

  const sizeLabel = getSizeLabel({
    size,
    breakingPoints: props?.breakingPoints,
  })

  const api = {
    isCollapsed: sizeLabel === SizeEnums.COLLAPSED,
    isSmall: sizeLabel === SizeEnums.SMALL,
    isMedium: sizeLabel === SizeEnums.MEDIUM,
    isLarge: sizeLabel === SizeEnums.LARGE,
    sizeLabel,
    containerRef,
    width: size.width,
  }

  return useMemoAPI(api)
}

function getSizeLabel(props: {
  size: ReturnType<typeof useComponentSize>
  breakingPoints?: BreakingPoints
}) {
  const get = (key: keyof BreakingPoints) => {
    const def: Record<keyof BreakingPoints, number> = {
      isLarge: 920,
      isMedium: 720,
    }
    const breakingPoint = props.breakingPoints?.[key]

    if (typeof breakingPoint !== "number") return def[key]

    return breakingPoint
  }

  const size = props.size

  if (typeof size?.width !== "number") return

  return (
    [
      size.width === 0 && SizeEnums.COLLAPSED,
      size.width < get("isMedium") && SizeEnums.SMALL,
      size.width < get("isLarge") && SizeEnums.MEDIUM,
      SizeEnums.LARGE,
    ].find((a): a is SizeEnums => !!a) || undefined
  )
}
