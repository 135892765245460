import { Fab as MuiFab, Typography, Paper, makeStyles } from "@material-ui/core"
import React from "react"
import clsx from "clsx"
import KeyListeners from "components/KeyListeners/KeyListeners"
import { purple } from "constants/colors"
import useOpenClose from "hooks/useOpenClose"
import { IconProps, Icon } from "components/Icon/Icon"
import useOnChange from "../../hooks/useOnChange"
import defaultIfUndefined from "../../lib/defaultIfUndefined"
import useClickOutside from "hooks/useClickOutside"

export type Action = {
  text: React.ReactNode
  icon: IconProps["name"]
  id: string
  onClick?: () => any
}

export type FabProps = {
  className?: string
  onClose?: () => any
  actions: Action[]
  onSelect?: (action: Action) => any
  ballSizes?: Partial<Style["ballsSize"]>
  MuiFabProps?: Omit<React.ComponentProps<typeof MuiFab>, "children">
  toggleButtonIconName?: (isOpen: boolean) => IconProps["name"]
  classes?: { fab: string }
  FabIconProps?: Omit<React.ComponentProps<typeof Icon>, "name">
  open?: boolean
  disableClosingOnClickingOutside?: boolean
  renderActionsPanel?: (props?: { onClose?: () => any }) => React.ReactNode
  children?: (props: {
    defaultComponent: React.ReactNode
    components: { actionsPanel: React.ReactNode; handle: React.ReactNode }
    passedProps: FabProps
  }) => React.ReactNode
}

export default function Fab(props: FabProps) {
  const ballsSize = {
    fab: defaultIfUndefined(props.ballSizes?.fab, 40),
    illustration: defaultIfUndefined(props.ballSizes?.illustration, 35),
  }

  const c = useStyles({ ballsSize })

  const oc = useOpenClose(props.open ?? false)

  useOnChange({
    value: oc.isOpen,
    disabled: !props.onClose,
    onChange(isOpen) {
      if (!!isOpen) return
      props.onClose?.()
    },
  })

  const rootRef = React.useRef<HTMLDivElement>(null)

  useClickOutside(rootRef, oc.close, {
    disabled: !oc.isOpen || !!props.disableClosingOnClickingOutside,
  })

  KeyListeners.useEscapeListener(oc.close, { enabled: oc.isOpen })

  return (
    <div className={clsx("fab", props.className, c.root)} ref={rootRef}>
      {(() => {
        const components = {
          actionsPanel: (() => {
            if (!oc.isOpen) return null

            if (props.renderActionsPanel)
              return props.renderActionsPanel({ onClose: () => oc.close() })

            return (
              <div className={c.items}>
                {props.actions
                  .map((a) => ({
                    ...a,
                    onClick: () => {
                      props.onSelect?.(a)
                      a.onClick?.()
                      oc.close()
                    },
                  }))
                  .map((a, index) => (
                    <div key={index} className={c.item} onClick={a.onClick}>
                      <Paper className="paper">
                        <Typography variant="body2" color="textSecondary">
                          {a.text}
                        </Typography>
                      </Paper>

                      <div className="illustration">
                        <Icon name={a.icon} style={{ fontSize: 19 }}></Icon>
                      </div>
                    </div>
                  ))}
              </div>
            )
          })(),

          handle: (
            <MuiFab
              className={clsx(c.fab, props.classes?.fab)}
              variant="circular"
              color="primary"
              onClick={oc.toggle}
              {...props.MuiFabProps}
            >
              <Icon
                name={(() => {
                  if (!props.toggleButtonIconName)
                    return oc.isOpen ? "close" : "add"
                  return props.toggleButtonIconName(oc.isOpen)
                })()}
                {...(props.FabIconProps || {})}
              ></Icon>
            </MuiFab>
          ),
        }

        const defaultComponent = (
          <>
            {components.actionsPanel}
            {components.handle}
          </>
        )

        return (
          props.children?.({
            defaultComponent,
            components,
            passedProps: props,
          }) ?? defaultComponent
        )
      })()}
      {}
    </div>
  )
}

type Style = {
  ballsSize: { fab: number; illustration: number }
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },

    fab: {
      width: (s: Style) => s.ballsSize.fab,
      height: (s: Style) => s.ballsSize.fab,
      minHeight: "0",
    },

    item: {
      cursor: "pointer",
      textAlign: "center",
      marginBottom: 8,
      display: "flex",
      alignItems: "center",

      "&>.paper": {
        padding: 2,

        marginRight: 12,
      },

      "&>.illustration": (() => {
        return {
          width: (s: Style) => s.ballsSize.illustration,
          height: (s: Style) => s.ballsSize.illustration,
          background: purple[2],
          borderRadius: "50%",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          color: "white",
        }
      })(),
    },

    items: {
      position: "relative",
      right: (s: Style) => (s.ballsSize.fab - s.ballsSize.illustration) / 2, //centering fab ball and item ball
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "column",
    },
  }
})
