import { ChipProps } from "@material-ui/core"
import { InlinedChips } from "components/InlinedChips/InlinedChips"
import { useI18n } from "contexts/i18nContext/i18nContext"
import {
  DocumentsChipsProvider,
  DocumentsChipsContext,
  DocumentsChipApiInput,
} from "./DocumentsChipsProvider"

export function DocumentsChips(props: DocumentsChipApiInput) {
  const t = useI18n()

  return (
    <DocumentsChipsProvider {...props}>
      <DocumentsChipsContext.Consumer>
        {(api) => {
          const documents = api.documentsApi.lastDocuments

          if (!documents?.length) return null

          const chipsProps = [
            ...documents.map((doc) => {
              const chipProps: ChipProps = {
                key: doc.id,
                onClick: () => {
                  if (!doc.id) return
                  api.onClickDocumentChip?.(doc.id)
                },
                label:
                  (doc.title || "").length <= 25
                    ? doc.title
                    : doc.title?.slice(0, 25) + "...",
              }

              return chipProps
            }),

            {
              key: "all-documents-button",
              color: "primary",
              variant: "outlined",
              label: t["allDocuments"],
              onClick: () => {
                api.onClickOpenAllDocuments?.()
              },
            } as ChipProps,
          ]

          return (
            <InlinedChips
              wrap
              chips={chipsProps}
              style={{ marginTop: 8 }}
            ></InlinedChips>
          )
        }}
      </DocumentsChipsContext.Consumer>
    </DocumentsChipsProvider>
  )
}
