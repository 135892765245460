// import { ModalTextField as TextField } from "components/ModalPage/ModalPage"
import clsx from "clsx"
import React from "react"
import RichText from "components/RichText/RichText"
import { RawDraftContentState } from "draft-js"
import {
  InputBaseComponentProps,
  makeStyles,
  TextField,
  TextFieldProps,
} from "@material-ui/core"

export default function RichTextField(props: {
  textFieldProps?: TextFieldProps
  TextField?: React.FC<TextFieldProps>
}) {
  const textFieldProps = {
    ...(props.textFieldProps || {}),
    multiline: true,
    InputProps: {
      ...(props.textFieldProps?.InputProps || {}),
      inputComponent: RichTextTextFieldComponent,
    },
  }

  if (props.TextField) return <props.TextField {...textFieldProps} />

  return <TextField {...textFieldProps}></TextField>
}
function RichTextTextFieldComponent(props: InputBaseComponentProps) {
  const c = useRichTextTextFieldComponentStyles()

  const ref = React.useRef<HTMLInputElement>(null)

  const onChange = React.useCallback(
    (v: RawDraftContentState) => {
      if (!ref.current) return

      const input = ref.current
      const value = JSON.stringify(v)

      input.setAttribute("value", value)
      input.dispatchEvent(new Event("change", { bubbles: true }))
    },
    [props.onChange]
  )

  return (
    <>
      <RichText
        className={clsx("rich-text-field-component", c.root, props.className)}
        value={props.value}
        onChange={onChange}
        EditorProps={{
          onFocus: props.onFocus,
          onBlur: props.onBlur,
        }}
      ></RichText>
      <input
        ref={ref}
        name={props.name}
        onChange={(e) => {
          if (!props.onChange) return
          props.onChange(e)
        }}
        type="text"
        style={{ display: "none" }}
      ></input>
    </>
  )
}
const useRichTextTextFieldComponentStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      height: "auto",
    },
  }
})
