import contextAndProviderFactory from "../../../lib/contextAndProviderFactory"
import { useUploadDocumentsApi } from "./useUploadDocumentsApi"

export const {
  Provider: UploadDocumentsProvider,
  Context: UploadDocumentsContext,
} = contextAndProviderFactory({
  hookApi: useUploadDocumentsApi,
  contextName: "UploadDocumentsContext",
})
