import { ChipProps } from "@material-ui/core"
import useComponentSize from "@rehooks/component-size"
import {
  InlineChipsProps,
  InlinedChips,
} from "components/InlinedChips/InlinedChips"
import { MobileHiddenXScrollbar } from "components/MobileHiddenXScrollbar/MobileHiddenXScrollbar"
import React from "react"
import { StyledProps } from "types/type"
import { ControlButton } from "./ControlButton"
import { ScrollChildTracker } from "../../../ScrollChildTracker"
import {
  FilterSelectorProvider,
  FilterSelectorContext,
} from "./FilterSelectorContext"

export function FilterSelector(
  props: { typesChipsProps: ChipProps[] } & StyledProps
) {
  const chipsRef = React.useRef<HTMLDivElement | null>(null)
  const { width: chipsWidth } = useComponentSize(chipsRef)

  return (
    <FilterSelectorProvider typesChipsProps={props.typesChipsProps}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
        className="root"
      >
        <ControlButton
          direction="left"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            zIndex: 1,
          }}
        ></ControlButton>

        <MobileHiddenXScrollbar.Root style={{ flex: 1, position: "relative" }}>
          {(() => {
            return (
              <MobileHiddenXScrollbar.Context.Consumer>
                {(a) => {
                  return (
                    <div
                      className="mobile-child"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent:
                          a.containerWidth > chipsWidth
                            ? "center"
                            : "flex-start",
                      }}
                    >
                      <FilterSelectorContext.Consumer>
                        {(filterApi) => {
                          return (
                            <InlinedChips
                              ref={chipsRef}
                              renderChip={renderChips}
                              style={{
                                flexWrap: "nowrap",
                              }}
                              chips={filterApi.chipsProps}
                              className="chips"
                            ></InlinedChips>
                          )
                        }}
                      </FilterSelectorContext.Consumer>
                    </div>
                  )
                }}
              </MobileHiddenXScrollbar.Context.Consumer>
            )
          })()}
        </MobileHiddenXScrollbar.Root>
        <ControlButton
          direction="right"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        ></ControlButton>
      </div>
    </FilterSelectorProvider>
  )
}

const renderChips: Exclude<InlineChipsProps["renderChip"], undefined> = ({
  defaultComponent,
  index,
}) => {
  return (
    <FilterSelectorContext.Consumer>
      {(api) => {
        const id = api.chipsProps[index].id

        return (
          <ScrollChildTracker.Child id={id}>
            {defaultComponent}
          </ScrollChildTracker.Child>
        )
      }}
    </FilterSelectorContext.Consumer>
  )
}
