import { WatchQueryFetchPolicy } from "@apollo/client"
import useMemoAPI from "../useMemoAPI"
import { useRoomsMembersQuery } from "../../lib/graphql/operations"

export default function useRoomsMembers(props: {
  fetchPolicy?: WatchQueryFetchPolicy
  workspaceID?: string | null
}) {
  const query = useRoomsMembersQuery({
    variables: { workspaceID: props.workspaceID || "" },
    fetchPolicy: props.fetchPolicy || "cache-and-network",
    skip: !props.workspaceID,
  })

  return useMemoAPI({
    roomsMembers: query?.data?.roomsMembers,
    isLoading: query.loading,
  })
}
