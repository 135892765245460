import { useContactForm } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ContactForm/lib/useContactForm"
import { TwoPagesAPI } from "components/TwoPages/TwoPages"
import useActiveResources from "hooks/useActiveResources"
import useDetailedContact from "hooks/useDetailedContact/useDetailedContact"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { useToggleContactDeletionMutation } from "lib/graphql/operations"

export type ContactFormApiInput = {
  twoPagesAPI?: TwoPagesAPI
  onClickOpenButton?: () => void
  summarized?: boolean
  openPartnerPage?: () => void

  contactID?: string
}

export const useContactFormAPI = (props?: ContactFormApiInput) => {
  const contactId = props?.contactID || ""
  const { detailedContact } = useDetailedContact({ id: contactId })

  const toggleDeletionMutation = useToggleContactDeletionMutation()[0]

  const workspaceId = useActiveResources().workspaceID

  const toggleDeletion = React.useCallback(() => {
    if (!contactId || !workspaceId) return

    return toggleDeletionMutation({
      variables: { contactID: contactId, workspaceID: workspaceId },
    })
  }, [toggleDeletionMutation, contactId, workspaceId])

  const form = useContactForm({ contact: detailedContact })

  return useMemoAPI({
    form,
    contact: detailedContact || null,
    twoPagesAPI: props?.twoPagesAPI,
    openPartnerPage: props?.openPartnerPage,
    toggleDeletion,
    onClickOpenButton: props?.onClickOpenButton,
    summarized: props?.summarized,
  })
}
