import React from "react"
import { MessageFragment } from "lib/graphql/operations"
import { Subject } from "rxjs"
import useMemoAPI from "hooks/useMemoAPI"
import { useSubscription } from "observable-hooks"
import { useChangeMessageFavoriteStatusMutation } from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import { RoomContext } from "../../../../contexts/room"

export function useChangeFavoriteStatus(props: { message: MessageFragment }) {
  const isFavorite$ = React.useRef(new Subject<boolean>()).current

  const roomApi = React.useContext(RoomContext)

  const [mutation] = useChangeMessageFavoriteStatusMutation()

  const r = useActiveResources()

  useSubscription(
    isFavorite$,
    React.useCallback(
      (isFavorite) => {
        return mutation({
          variables: {
            messageID: props.message.id,
            isFavorite,
            workspaceID: r.workspaceID,
            roomID: roomApi.roomID || "",
          },
          optimisticResponse: {
            __typename: "Mutation",
            changeMessageFavoriteStatus: {
              ...props.message,
              isFavorite: !props.message.isFavorite,
            },
          },
        })
      },
      [mutation, r.workspaceID, props.message.id, roomApi.roomID]
    )
  )

  return useMemoAPI({ isFavorite$ })
}
