import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import { useRoomMembersQuery } from "lib/graphql/operations"
import useCurrentWorkspace from "./useCurrentWorkspace/useCurrentWorkspace"
import useSearchMembersAPI from "./useSearchMembersAPI"

export default function useRoomMembers(props: {
  roomID?: string
  fetchAllowedMembers?: boolean
}) {
  const { roomID } = props

  const { workspaceID } = useActiveResources()
  const query = useRoomMembersQuery({
    variables: { roomID: roomID || "", workspaceID },
    fetchPolicy: "cache-and-network",
    skip: !roomID || !workspaceID,
  })

  const { user: workspaceOwner } = useCurrentWorkspace()
  const roomMembers =
    (query?.data?.roomMembers || query.previousData?.roomMembers || undefined)
      ?.list || []

  const peerMembers = (roomMembers || [])?.filter(
    (u) => workspaceOwner?.id !== u.id
  )

  const searchMembersAPI = useSearchMembersAPI({
    roomID,
    disabled: !props.fetchAllowedMembers,
  })

  // const allowedMembers = React.useMemo(()=>{}, [roomMembers])

  return useMemoAPI({
    roomMembers,
    isLoading: query.loading,
    searchMembersAPI: searchMembersAPI,
    peerMembers,
  })
}
