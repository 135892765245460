import { IconButton } from "@material-ui/core"
import { ConferenceActivity } from "components/ConferenceActivity/ConferenceActivityContext"
import { Icon } from "components/Icon/Icon"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import { RoomPageBoardItemApiContext } from "../../../../contexts/RoomPageBoardItemContext"

export function ConferenceButton(props: {
  variant: "icon-button" | "menu-item"
}) {
  const api = React.useContext(RoomPageBoardItemApiContext)

  const t = useI18n()

  const conferenceAPI = React.useContext(ConferenceActivity.Context)

  const onClick = api.toggleVideoCallPage
  const isActive = conferenceAPI.isActive.isTrue

  return (
    <React.Fragment>
      {(() => {
        if (props.variant === "icon-button")
          return (
            <PurpleBadge badgeContent={"*"} variant="dot" invisible={!isActive}>
              <IconButton onClick={onClick} size="small">
                <Icon name="videocam"></Icon>
              </IconButton>
            </PurpleBadge>
          )

        return (
          <div onClick={onClick} style={{ width: "100%" }}>
            {isActive ? t.conference.getBack : t.conference.start}
          </div>
        )
      })()}
    </React.Fragment>
  )
}
