import React from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import CalendarEventContext from "../../contexts/CalendarEventContext"
import DateTimeIntervalInput from "../DateTimeIntervalInput/DateTimeIntervalInput"
import { PageTextField, PageForm } from "components/Page/Page"
import {
  EnhancedPageProps,
  SaveButton,
  EnhancedPage,
} from "components/EnhancedPage/EnhancedPage"
import { useI18n } from "contexts/i18nContext/i18nContext"
import TwoViewsWithTransition from "components/ViewsWithTransition/TwoViewsWithTransition"
import MembersInput from "./components/MembersInput/MembersInput"
import RichTextField from "components/RichTextField/RichTextField"
import FrameWithLoadingProgress from "components/FrameWithLoadingProgress/FrameWithLoadingProgress"
import { SecondPageAPI as SecondPageAPI } from "components/Page/Page"

export default function EventForm(props: {
  classes?: { content?: string }
  buttons?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  onClose?: () => any
}) {
  const c = useStyles()

  return (
    <SecondPageAPI.Provider>
      <SecondPageAPI.Context.Consumer>
        {(secondPage) => {
          return (
            <TwoViewsWithTransition
              className={clsx(c.root, "event-form", props.className)}
              style={props.style}
              views={[
                <MainForm onClose={props.onClose}></MainForm>,
                secondPage.view,
              ]}
            ></TwoViewsWithTransition>
          )
        }}
      </SecondPageAPI.Context.Consumer>
    </SecondPageAPI.Provider>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {},
  }
})

function MainForm(props: { onClose?: () => any }) {
  const ctx = React.useContext(CalendarEventContext)
  const onClose = props.onClose || ctx.onClose

  const form = ctx.form

  const c = useStyles()

  const renderTitleLine = React.useCallback(
    (() => {
      const r: EnhancedPageProps["renderTitleLine"] = function render({
        titleComponent,
      }) {
        return (
          <>
            {titleComponent}
            <SaveButton
              disabled={!ctx.form.readyToSubmit}
              onClick={() => {
                form.submit()
                onClose?.call(undefined)
              }}
            ></SaveButton>
          </>
        )
      }

      return r
    })(),
    [ctx.form.readyToSubmit, ctx.form.submit, onClose]
  )

  const t = useI18n()

  const titleInputRef = React.useRef<HTMLInputElement | null>(null)

  React.useEffect(() => {
    // if(!ctx)
    titleInputRef.current?.focus()
  }, [])

  const body = (
    <PageForm
      fieldsIndentationsProps={[
        {
          icon: null,
          children: (
            <PageTextField
              multiline
              typographyVariant="h6"
              placeholder={t.title}
              inputProps={{ ref: titleInputRef }}
              {...ctx.form.getFieldProps("title")}
            ></PageTextField>
          ),
        },
        {
          icon: "access_time",
          children: (
            <DateTimeIntervalInput
              style={{ marginTop: -6 }}
            ></DateTimeIntervalInput>
          ),
        },
        {
          icon: "text_rotation_none_icon",
          children: (
            <RichTextField
              textFieldProps={{
                label: "Description",
                ...ctx.form.getFieldProps("description"),
              }}
              TextField={PageTextField}
            ></RichTextField>
          ),
        },
        {
          icon: "people_outline",
          children: <MembersInput></MembersInput>,
        },
      ]}
    ></PageForm>
  )

  return (
    <FrameWithLoadingProgress
      className={clsx("event-form", c.root)}
      isLoading={form.isSubmitting}
    >
      <EnhancedPage
        onClose={onClose}
        onCloseIconName="close"
        style={{ height: "100%" }}
        renderTitleLine={renderTitleLine}
        body={body}
      ></EnhancedPage>
    </FrameWithLoadingProgress>
  )
}
