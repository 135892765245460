import { ID } from "types/type"
import useMemoAPI from "hooks/useMemoAPI"
import React, { ReactNode } from "react"

interface WorkspaceContextAPI {
  workspaceID: ID
}

const WorkspaceContext = React.createContext({} as WorkspaceContextAPI)

const Workspace = {
  Context: WorkspaceContext,
  Provider: function WorkspaceProvider(props: {
    workspaceID: ID
    children: ReactNode
  }) {
    const value = useMemoAPI({ workspaceID: props.workspaceID })

    return (
      <WorkspaceContext.Provider value={value}>
        {props.children}
      </WorkspaceContext.Provider>
    )
  },
}

export default Workspace
