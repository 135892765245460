import _ from "lodash"
import React from "react"
import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import DeleteModal from "../DeleteImageModal/DeleteModal"

export default function RemoveCommentModal() {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const removeModal = api.comments.removeModal

  const t = useI18n()

  return (
    <DeleteModal
      isOpen={removeModal.isOpen}
      onClose={removeModal.close}
      warningText={t["deleteACommentAnIrreversibleOperation"]}
      onDelete={() => {
        removeModal.remove()
        removeModal.close()
      }}
    ></DeleteModal>
  )
}
