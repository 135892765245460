import { InlinedChips } from "components/InlinedChips/InlinedChips"
import { ShopOrderPageBoardItemFragment } from "lib/graphql/operations"
import { StyledProps } from "types/type"
import { ShopArticleFragment } from "../../../lib/graphql/operations"
import { BallWithQuantity } from "./BallWithQuantity"
import SelfContainedBall from "components/SelfContainedBall/SelfContainedBall"

export function ShopOrderArticlesInlinedChips(
  props: {
    item: ShopOrderPageBoardItemFragment
    onClickArticle?: (props: { article: ShopArticleFragment }) => void
  } & StyledProps
) {
  const item = props.item

  const orderItems = item.order?.items || []

  if (!orderItems.length) return null

  return (
    <InlinedChips
      className={props.className}
      style={props.style}
      charactersLimit={10}
      wrap
      chips={orderItems.map((a) => {
        return {
          key: a.article.id,
          label: a.article.name || "",
          size: "small",
          onClick: (e) => {
            e.stopPropagation()
            props.onClickArticle?.({ article: a.article })
          },
          icon: (
            <SelfContainedBall
              style={{
                background: "#fffbfb",
                textAlign: "center",
              }}
            >
              {a.quantity}
            </SelfContainedBall>
          ),
        }
      })}
    ></InlinedChips>
  )
}
