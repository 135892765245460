import _ from "lodash"
import { makeStyles, Paper, Typography } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import RootContainer from "components/RootContainer/RootContainer"

export default function Fieldset({
  fields,
  getField,
  className = "",
  fullWidth: propsFullWidth = false,
  twoColumns = false,
  object = undefined,
}) {
  const c = useStyles({})
  if (!fields) return null
  return (
    <RootContainer.Default
      className={clsx("fieldset", c.root, c.fieldsContainer, className)}
    >
      {({ sizeLabel }) => {
        const isSmallContainer = sizeLabel === RootContainer.SizeEnums.SMALL
        const isLargeContainer = sizeLabel === RootContainer.SizeEnums.LARGE

        return (
          fields
            .map((key) => {
              const o = getField[key]

              if (typeof o?.render !== "function") return null

              const value = _.get(object, key)

              return (
                <div
                  className={clsx(
                    "field",
                    [
                      propsFullWidth && "full-width",
                      (isSmallContainer || twoColumns) &&
                        "two-columns-container-child",
                      isLargeContainer && "three-columns-container-child",
                    ].find(Boolean)
                  )}
                  key={key}
                >
                  <Typography variant="caption" color="textSecondary">
                    {o?.getLabel(value, key, object)}
                  </Typography>
                  <div className="value" key={`${key}-value`}>
                    {o?.render(value, key, object)}
                  </div>
                </div>
              )
            })
            .filter(Boolean) || null
        )
      }}
    </RootContainer.Default>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexWrap: "wrap",
      display: "flex",
    },

    fieldsContainer: {
      "& .field": {
        width: "50%",

        "&.full-width": {
          width: "100%",
        },

        "&.three-columns-container-child": {
          width: 100 / 3 + "%",
        },

        "&.two-columns-container-child": {
          width: "50%",
          "&:nth-child(odd)": {
            paddingRight: theme.spacing(0.5),
          },
          "&:nth-child(even)": {
            paddingLeft: theme.spacing(0.5),
          },
        },

        "&:not(:last-child)": {
          marginBottom: theme.spacing(2),
        },

        "& .value": {
          "&, &>*": {
            width: "100%",
          },
        },
      },
    },
  }
})
