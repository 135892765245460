import { AddNewPartnerForm } from "components/AddNewPartnerForm/AddNewPartnerForm"
import { PageComponentProps } from "components/Page/hooks/useSecondPage"
import { PartnerPageBoardItemFragment } from "lib/graphql/operations"
import { StyledProps } from "types/type"
import { RecentlyUpsertedItemsSection } from "./IndexedItems/components/PageBoardItem/components/FormCommons/components/RecentlyUpsertedItemsSection"
import { FormPage } from "./FormPage"

export function NewPartnerFormPage(props: PageComponentProps & StyledProps) {
  return (
    <AddNewPartnerForm.Provider>
      <AddNewPartnerForm.Context.Consumer>
        {(api) => {
          return (
            <FormPage
              form={api.form as any}
              onClose={props.onClose}
              SaveButtonProps={{ resetOnSubmit: true }}
              style={props.style}
              className={props.className}
              body={
                <div style={{ padding: 16 }}>
                  <AddNewPartnerForm.Root></AddNewPartnerForm.Root>
                  <AddNewPartnerForm.Consumer>
                    {(api) => {
                      const addedPartnersIDs =
                        api.addedPartners?.map((a) => a.id) || []

                      return (
                        <RecentlyUpsertedItemsSection
                          filter={(
                            item
                          ): item is PartnerPageBoardItemFragment => {
                            const partnerItem =
                              item as PartnerPageBoardItemFragment

                            const partnerID = partnerItem?.partner?.id

                            if (!partnerID) return false

                            return addedPartnersIDs.includes(partnerID)
                          }}
                          style={{ marginTop: 16 }}
                        ></RecentlyUpsertedItemsSection>
                      )
                    }}
                  </AddNewPartnerForm.Consumer>
                </div>
              }
            ></FormPage>
          )
        }}
      </AddNewPartnerForm.Context.Consumer>
    </AddNewPartnerForm.Provider>
  )
}
