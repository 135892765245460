import useUpdateDocument from "hooks/useUpdateDocument/useUpdateDocument"

export default function UpdateDocument(props: {
  documentID: string
  children: (updateDocument: (newObject: any) => any) => JSX.Element
}) {
  const { updateDocument } = useUpdateDocument({ documentID: props.documentID })

  return props.children(updateDocument)
}
