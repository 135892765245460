import { Chip } from "@material-ui/core"
import CalendarDateTimeInput from "components/CalendarDateTimeInput/CalendarDateTimeInput"
import Hook from "components/Hook/Hook"
import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { PageForm } from "components/Page/Page"
import { FetchPageBoardItem } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/FetchPageBoardItem/FetchPageBoardItem"
import { getIcon } from "components/PageBoard/hooks/useTypesOptions"
import ShopArticleCard from "components/ShopArticleCard/ShopArticleCard"
import { ShopArticlesGrid } from "components/ShopArticlesGrid/ShopArticlesGrid"
import TextField from "components/TextField/TextField"
import I18nContext from "contexts/i18nContext/i18nContext"
import useForm, { UseFormApi } from "hooks/useForm"
import {
  ItemsFn,
  useGroupInTwoInLargeDevices,
} from "hooks/useGroupInTwoInLargeDevices"
import useWorkspace from "hooks/useWorkspace/useWorkspace"
import { excludeNullable } from "lib/excludeNullable"
import {
  NewPageBoardItemFragment,
  PartnerPageBoardItem_CustomerPageBoardItem_Fragment,
  ShopArticleFragment,
} from "lib/graphql/operations"
import { PageBoardItemType, ShopOrderAddressInput } from "lib/graphql/types"
import moment, { MomentInput } from "moment"
import React from "react"
import { StyledProps } from "types/type"
import * as Yup from "yup"
import { PageBoardItemsAutoComplete } from "../../../../../../../PageBoardItemsAutoComplete/PageBoardItemsAutoComplete"
import { AddressForm } from "../../../../../../../PartnerForm/components/AddressForm"
import { ShopOrderFormContext } from "../../ShopOrderFormContext"
import { FormFreezer } from "../FormFreezer/FormFreezer"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"

export function ShopOrderFormBody(
  props: {
    renderArticles?: () => React.ReactNode
    onClickArticle?: (props: { article: ShopArticleFragment }) => void
    renderPartnerInput?: () => React.ReactNode
  } & StyledProps
) {
  const api = React.useContext(ShopOrderFormContext)

  const items = React.useCallback<ItemsFn<FieldsetIndentationProps>>(
    (args) => {
      const fieldset: Array<FieldsetIndentationProps | null | false> = [
        {
          icon: "info",
          children: (
            <>
              <FormFreezer>
                <ShopOrderFormContext.Consumer>
                  {(api) => {
                    return (
                      <I18nContext.Consumer>
                        {(t) => {
                          return (
                            <>
                              <TextField
                                label={t.shopOrderId}
                                {...api.form.getFieldProps("companyOrderId")}
                                error={
                                  !!api.form.formik.errors["companyOrderId"]
                                }
                                helperText={
                                  api.form.formik.errors["companyOrderId"] || ""
                                }
                              ></TextField>
                              <TextField
                                label={t.salesCommission}
                                {...api.form.getFieldProps("commission")}
                                error={!!api.form.formik.errors["commission"]}
                                helperText={
                                  api.form.formik.errors["commission"] || ""
                                }
                              ></TextField>

                              <CalendarDateTimeInput
                                TextFieldProps={{
                                  label: t.deliveryDate,
                                  error:
                                    !!api.form.formik.errors["deliveryDate"],
                                  helperText:
                                    api.form.formik.errors["deliveryDate"],
                                }}
                                {...api.form.getFieldProps("deliveryDate")}
                                value={(() => {
                                  const value =
                                    api.form.getFieldProps("deliveryDate").value

                                  const isValid = moment(value).isValid()

                                  if (isValid) return moment(value)

                                  return null
                                })()}
                                onChange={(e: {
                                  target: {
                                    name: string
                                    value: MomentInput | null
                                  }
                                }) => {
                                  const value = e.target.value

                                  if (moment(value).isValid()) {
                                    return api.form.setValue(
                                      "deliveryDate",
                                      moment(value).format("YYYY-MM-DD")
                                    )
                                  }

                                  api.form.setValue("deliveryDate", null)
                                }}
                              ></CalendarDateTimeInput>
                            </>
                          )
                        }}
                      </I18nContext.Consumer>
                    )
                  }}
                </ShopOrderFormContext.Consumer>
              </FormFreezer>
            </>
          ),
        },
        {
          icon: "business",
          children: (
            <FormFreezer>
              <ShopOrderFormContext.Consumer>
                {(api) => {
                  return (
                    <I18nContext.Consumer>
                      {(t) => {
                        return (
                          <Hook
                            hook={useForm as UseFormApi<ShopOrderAddressInput>}
                            hookProps={{
                              initialValues: api.form.getFieldProps("address")
                                .value || {
                                street: "",
                                country: "",
                                name1: "",
                                name2: "",
                                city: "",
                              },
                              validationSchema: Yup.object().shape({
                                street: Yup.string().required(t.required),
                                city: Yup.string().required(t.required),
                                country: Yup.string().required(t.required),
                                zip: Yup.string().required(t.required),
                              }),
                              onChange(props) {
                                const value = {
                                  ...props.formikWithOldValue.values,
                                  [props.target.name]: props.target.value,
                                }

                                api.form.setValue("address", value)
                              },
                            }}
                          >
                            {(formApi) => {
                              return (
                                <AddressForm
                                  form={formApi}
                                  hide={{ active: true }}
                                  label={{ name2: t.additionalInfo }}
                                ></AddressForm>
                              )
                            }}
                          </Hook>
                        )
                      }}
                    </I18nContext.Consumer>
                  )
                }}
              </ShopOrderFormContext.Consumer>
            </FormFreezer>
          ),
        },
      ]

      return fieldset.filter(excludeNullable)
    },
    [props.renderArticles]
  )

  const grouped = useGroupInTwoInLargeDevices({
    items,
  })

  const indentationProps: Array<
    (NonNullable<FieldsetIndentationProps> | null)[]
  > = React.useMemo(() => {
    return [
      [
        {
          icon: getIcon(PageBoardItemType.Customer),
          children: (
            <I18nContext.Consumer>
              {(t) => {
                return (
                  <ShopOrderFormContext.Consumer>
                    {(api) => {
                      const render = (
                        item?: NewPageBoardItemFragment | null
                      ) => {
                        return (
                          <Hook
                            hook={useCurrentWorkspace}
                            hookProps={undefined}
                          >
                            {(w) => {
                              if (
                                w.workspace?.__typename === "GuestWorkspace"
                              ) {
                                const partner = w.workspace.partner

                                if (!partner) return null

                                return (
                                  <FormFreezer freezed={true}>
                                    <TextField
                                      InputProps={{ readOnly: true }}
                                      label={"Partner"}
                                      value={partner.name}
                                    ></TextField>
                                  </FormFreezer>
                                )
                              }

                              return (
                                <FormFreezer freezed={!api.partnerIsEditable}>
                                  <PageBoardItemsAutoComplete
                                    filter={{
                                      types: [PageBoardItemType.Customer],
                                    }}
                                    label={t.customer}
                                    name="partner"
                                    value={item || null}
                                    onChange={(a) => {
                                      const value = a.target
                                        .value as PartnerPageBoardItem_CustomerPageBoardItem_Fragment

                                      if (
                                        value.__typename !==
                                        "CustomerPageBoardItem"
                                      )
                                        return

                                      const itemID = value.itemID || ""

                                      api.form
                                        .getFieldProps("partnerParams")
                                        .onChange({
                                          target: {
                                            value: { itemID: itemID },
                                            name: "partnerParams",
                                          },
                                        })
                                    }}
                                  ></PageBoardItemsAutoComplete>
                                </FormFreezer>
                              )
                            }}
                          </Hook>
                        )
                      }
                      const partnerParams =
                        api.form.getFieldProps("partnerParams").value

                      if (partnerParams) {
                        return (
                          <FetchPageBoardItem
                            type={PageBoardItemType.Partner}
                            {...(() => {
                              if ("itemID" in partnerParams)
                                return { itemID: partnerParams.itemID || "" }

                              if ("partnerID" in partnerParams) {
                                return {
                                  params: {
                                    partnerID: partnerParams.partnerID || "",
                                  },
                                }
                              }

                              return {}
                            })()}
                            // {...("itemID" in partnerParams
                            //   ? {}
                            //   : { itemID: partnerParams.itemID || "" })}
                            // {...("partnerID" in partnerParams
                            //   ? {}
                            //   : { partnerID: partnerParams.partnerID || "" })}
                          >
                            {(a) => {
                              return render(a.item)
                            }}
                          </FetchPageBoardItem>
                        )
                      }

                      return render()
                    }}
                  </ShopOrderFormContext.Consumer>
                )
              }}
            </I18nContext.Consumer>
          ),
        },
      ],
      ...grouped.grouped,
      [
        {
          icon: "shopping_cart",
          title: (
            <I18nContext.Consumer>
              {(t) => t.shoppingArticles}
            </I18nContext.Consumer>
          ),
          children: (() => {
            if (props.renderArticles) return props.renderArticles()

            const cardHeight = 270

            return (
              <ShopArticlesGrid.Provider
                articles={api.articles || []}
                renderArticle={(a) => {
                  return (
                    //   article={a}
                    // ></ShopArticleCard>
                    <ShopArticleCard
                      article={a.article}
                      style={{ height: cardHeight }}
                      renderContent={(p) => {
                        return (
                          <>
                            {p.components.id}
                            {p.components.name}

                            <div style={{ marginBottom: 6 }}>
                              {p.components.brandName}
                            </div>

                            <div style={{ marginBottom: 6 }}>
                              {p.components.price}
                            </div>
                            <div>
                              <I18nContext.Consumer>
                                {(t) => {
                                  return (
                                    <Chip
                                      label={t.units(
                                        api.getQuantity?.({
                                          articleID: a.article.id || "",
                                        }) || 0
                                      )}
                                      size="small"
                                    ></Chip>
                                  )
                                }}
                              </I18nContext.Consumer>
                            </div>
                          </>
                        )
                      }}
                    ></ShopArticleCard>
                  )
                }}
                onClickArticle={props.onClickArticle}
              >
                <ShopArticlesGrid.Context.Consumer>
                  {(api) => {
                    return (
                      <ShopArticlesGrid.Grid
                        noVirtualization
                        style={{
                          marginLeft: -4,
                          marginRight: -4,
                          height: (() => {
                            const height = api.lines?.length * cardHeight
                            if (!isNaN(height)) return height
                            return "100%"
                          })(),
                        }}
                      ></ShopArticlesGrid.Grid>
                    )
                  }}
                </ShopArticlesGrid.Context.Consumer>
              </ShopArticlesGrid.Provider>
            )
          })(),
        },
      ],
    ]
  }, [grouped.grouped])

  return (
    <PageForm
      fieldsIndentationsProps={indentationProps}
      className={props.className}
      style={props.style}
    ></PageForm>
  )
}
