import _ from "lodash"
import React, { useMemo, useState, useContext, useEffect } from "react"

export function UploadProvider({ children, transformOnDropFile = undefined }) {
  const api = useFilesAPI({ transformOnDropFile })
  return <UploadContext.Provider value={api}>{children}</UploadContext.Provider>
}

export function useUploadContext() {
  return useContext(UploadContext)
}

export const UploadContext = React.createContext({
  uploadedFiles: [],
  addUploadedFiles: () => null,
  updateUploadedFile: ({ id, dataUpdate = {} }) => null,
  removeUploadedFile: () => null,
  updateAllFiles: dataUpdate => null
})

function useFilesAPI({ transformOnDropFile }) {
  const [uploadedFiles = [], setUploadedFiles] = useState([])

  return useMemo(() => {
    return {
      uploadedFiles,
      updateAllFiles(dataUpdate) {
        setUploadedFiles(old => old.map(f => ({ ...f, ...dataUpdate })))
      },
      addUploadedFiles(newFiles) {
        const mapping = f =>
          typeof transformOnDropFile === "function" ? transformOnDropFile(f) : f

        setUploadedFiles(old => [...old, ...(newFiles || [])].map(mapping))
      },
      updateUploadedFile({ id: argID, dataUpdate = {} }) {
        setUploadedFiles(old => {
          const clone = [...old]
          const fileIndex = clone.findIndex(
            ({ id: fileID }) => fileID === argID
          )

          if (fileIndex === -1) return
          clone[fileIndex] = { ...clone[fileIndex], ...dataUpdate }

          return clone
        })
      },

      removeUploadedFile(id) {
        setUploadedFiles(old =>
          (old || []).filter(({ id: fileID }) => fileID !== id)
        )
      }
    }
  }, [uploadedFiles])
}
