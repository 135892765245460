import React from "react"
import TwoPages from "components/TwoPages/TwoPages"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import {
  EnhancedPageProps,
  EnhancedPage,
} from "components/EnhancedPage/EnhancedPage"
import TemplatePageContext from "../../contexts/TemplatePageContext"
import { StyledProps } from "types/type"
export { Indentation } from "components/EnhancedPage/EnhancedPage"

export default function TemplatePageRoot(
  props: {
    EnhancedPageProps?: EnhancedPageProps
  } & StyledProps
) {
  const c = useStyles()

  const api = React.useContext(TemplatePageContext)

  return (
    <div
      className={clsx(c.root, "template-page-root", props.className)}
      ref={api.rootContainer.containerRef}
      style={props.style}
    >
      <TwoPages.Root
        mainPage={
          <EnhancedPage
            onClose={api.onClose}
            className={c.fullHeight}
            onCloseIconName="close"
            style={{ height: "100%" }}
            {...(props.EnhancedPageProps || {})}
          ></EnhancedPage>
        }
      ></TwoPages.Root>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",

    "&>*": {
      height: "100%",
    },
  },

  fullHeight: {
    height: "100%",
  },
}))
