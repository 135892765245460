import { ApolloClient } from "@apollo/client";
import {
  TrackEnvironmentVariablesDocument,
  TrackEnvironmentVariablesMutationVariables,
} from "lib/graphql/operations"

export default function trackEnvironmentVariables(
  environmentVariables: TrackEnvironmentVariablesMutationVariables["environmentVariables"],
  client: ApolloClient<{}>
) {
  if (!environmentVariables.workspaceID) return

  client.mutate({
    mutation: TrackEnvironmentVariablesDocument,
    variables: { environmentVariables },
  })
}
