import _f from "lodash/fp"
import React, { useRef } from "react"
import { makeStyles, Tooltip } from "@material-ui/core"
import { Emoji } from "emoji-mart"

export default function Reactions({ children, message, position }) {
  const c = useStyles({ position })
  const refs = useRef({})

  // if (message) return children

  const emojis = _f.flow(
    _f.get("reactions"),
    _f.orderBy(["users.length"], ["desc"]),
    _f.map((reaction) => {
      const { emoji, users = [] } = reaction || {}

      return (
        <Tooltip
          title={users.map((user) => {
            return (
              <>
                {user.name}
                <br />
              </>
            )
          })}
        >
          <div ref={(e) => (refs.current[emoji] = e)} key={emoji}>
            <Emoji emoji={emoji} size={20} />
          </div>
        </Tooltip>
      )
    })
  )(message)

  const emojisComponent = !!emojis.length && (
    <div className={c.emojis}>{emojis}</div>
  )

  return (
    <div className={c.reactions}>
      {children}
      {emojisComponent}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    emojis: {
      display: "flex",
      // marginLeft: margin,
      // marginRight: margin
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.7),
      width: "100%",
      justifyContent: ({ position }) =>
        position === "right" ? "flex-end" : "flex-start",
    },
    reactions: {
      display: "flex",
      flexDirection: "column",
      // marginBottom: ({ emojisLength }) => (emojisLength ? -20 : "initial")
      // alignItems: "flex-end",
      // position: "relative"
    },
  }
})
