import DocumentFormContext from "./contexts/DocumentFormContext"
import DocumentFormProvider from "./components/DocumentFormProvider/DocumentFormProvider"
import DocumentFormRoot from "./components/OldDocumentFormRoot/OldDocumentFormRoot"
import useDocumentForm from "./hooks/useDocumentForm"

const DocumentForm = {
  Context: DocumentFormContext,
  Root: DocumentFormRoot,
  Provider: DocumentFormProvider,
}

export default DocumentForm
