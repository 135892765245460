import React from "react"
import DocumentFormContext from "components/OldDocumentForm/contexts/DocumentFormContext"
import DocumentCollections from "components/DocumentCollections/DocumentCollections"

export default function CollectionsInputPageProvider(props: {
  children: React.ReactNode
}) {
  const api = React.useContext(DocumentFormContext)

  const document = api.document

  if (!document?.id) return null

  return (
    <DocumentCollections.Provider
      documentID={document.id}
      collections={document?.collections || []}
    >
      {props.children}
    </DocumentCollections.Provider>
  )
}
