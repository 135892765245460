import { makeStyles } from "@material-ui/core"
import CreateRoomMetaCard from "./components/CreateRoomMetaCard/CreateRoomMetaCard"
import clsx from "clsx"
import RoomWall from "components/Room/components/RoomWall/RoomWall"

export default function CreateRoomWall() {
  return (
    <RoomWall
      className="create-room-all"
      bottomRight={<CreateRoomMetaCard></CreateRoomMetaCard>}
    ></RoomWall>
  )
}
