import useMemoAPI from "hooks/useMemoAPI"
import _ from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import useFetchImageWithAuth from "./useFetchImageWithAuth"
import { useWorkspaceCache } from "hooks/useWorkspaceCache"
import useOnChange from "hooks/useOnChange"

export default function useVariousImagesWithAuth(props: {
  sources: string[]
  lazyFetching?: boolean
}) {
  const { lazyFetching = false } = props

  const uniqueSources = _.uniq([...(props.sources || [])].sort())

  const [objectURL, setObjectURL] = useState<{
    [src: string]: string | null | undefined
  }>({})

  const [error, setError] = useState<{ [src: string]: string | Error }>({})

  const sources = React.useMemo(
    () => uniqueSources,
    [JSON.stringify(uniqueSources)]
  )

  const [isFetching, setIsFetching] = useState<{ [src: string]: boolean }>({})

  const fetchImageWithAuth = useFetchImageWithAuth()

  const fetchImage = useCallback(
    async (src: string) => {
      setIsFetching((state) => ({ ...state, [src]: true }))

      const objectUrl =
        (await fetchImageWithAuth(src)
          .then((objectURL) => {
            setObjectURL((state) => ({ ...state, [src]: objectURL }))
            return objectURL || null
          })
          .catch((e) => setError((state) => ({ ...state, [src]: e })))
          .finally(() =>
            setIsFetching((state) => ({ ...state, [src]: false }))
          )) || null

      return objectUrl
    },

    [fetchImageWithAuth, setObjectURL]
  )

  const fetchAllImages = useCallback(() => {
    //fetching only images that are not fetched yet
    return Promise.all(sources.filter((a) => !objectURL[a]).map(fetchImage))
  }, [sources.join(","), objectURL, fetchImage])

  useEffect(() => {
    if (lazyFetching) return
    fetchAllImages()
  }, [sources.join(",")])

  const isImageFetching = React.useCallback(
    (src: string) => {
      return isFetching[src]
    },
    [isFetching]
  )
  const imageCache = useWorkspaceCache<typeof objectURL>({ key: "imageCache" })

  const getImageObjectURL = React.useCallback(
    (src: string) => {
      if (!src?.trim()) return null

      return objectURL[src] || imageCache.value?.[src]
    },
    [objectURL, imageCache]
  )

  useOnChange({
    value: objectURL,
    onChange: (value) => {
      imageCache.setValue({ ...(imageCache.value || {}), ...objectURL })
    },
  })

  const getError = React.useCallback(
    (src: string) => {
      return error[src]
    },
    [error]
  )

  return useMemoAPI({
    getImageObjectURL,
    getError,
    fetchAllImages,
    isImageFetching,
    isFetchingAnyImage: Object.values(isFetching).some(Boolean),
    fetchImage,
    error,
  })
}
