import { useSubscription } from "@apollo/client";
import _ from "lodash"
import {
  MessageUpdateDocument,
  WorkspacesUpdatesDocument,
} from "lib/graphql/operations"
import updateMessage from "lib/updateMessage"

export default function useMessageUpdateSubscription() {
  const a = useSubscription(MessageUpdateDocument, {
    // onError(err) {},
    shouldResubscribe: true,

    onSubscriptionData({ client, subscriptionData }) {
      // console.log(subscriptionData)

      const messageUpdate = _.get(subscriptionData, "data.messageUpdate")
      updateMessage({ update: messageUpdate }, client)
    },
  })
}
