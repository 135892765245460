import React from "react"
import moment from "moment"
import clsx from "clsx"
import { makeStyles, Typography, TypographyProps } from "@material-ui/core"
import { purple } from "constants/colors"
import { Interval } from "../../types"
import { StyledProps } from "types/type"

export default function Day(
  props: {
    period?: Interval
    day: moment.Moment
    defaultComponent: React.ReactNode
    TypographyProps?: React.ComponentProps<typeof Typography>
  } & StyledProps
) {
  const format = "YYYY-MM-DD"

  const start = props.period?.[0]?.format(format)
  const end = props.period?.[1]?.format(format)

  const day = props.day.format(format)

  let type: Type = React.useMemo(() => {
    if (day === end && day === start) return "single"
    if (day === start) return "left-extreme"
    if (day === end) return "right-extreme"

    if (!!start && start < day && !!end && day < end) return "between"

    return "none"
  }, [start, end, day])

  const isSingleExtreme = (props.period || []).filter(Boolean).length < 2
  const isAExtreme = day === start || day === end

  const isFirstDay = props.day.isSame(props.day.clone().startOf("month"), "day")
  const isLastDay = props.day.isSame(props.day.clone().endOf("month"), "day")

  const c = useDayStyles({
    type,
    isSingleExtreme,
    isAExtreme,
    isFirstDay,
    isLastDay,
  })

  return (
    <div className={clsx(c.root, "day", props.className)} style={props.style}>
      <div className={clsx(c.background, "background")}></div>
      <div className={clsx(c.selector, "selector")}></div>
      <Typography
        className={clsx(c.number, "number")}
        {...props?.TypographyProps}
      >
        {props.day.format("DD")}
      </Typography>
    </div>
  )
}
type Type = "right-extreme" | "left-extreme" | "none" | "between" | "single"

type Styles = {
  type: Type
  isSingleExtreme: boolean
  isAExtreme: boolean
  isLastDay: boolean
  isFirstDay: boolean
}
const useDayStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
      paddingTop: "100%",
      userSelect: "none",
      cursor: "pointer",
    },
    background: {
      position: "absolute",
      right(props: Styles) {
        if (props.type === "right-extreme") return "50%"

        if (props.type === "left-extreme") return 0
        return 0
      },
      left(props: Styles) {
        if (props.type === "left-extreme") return "50%"

        if (props.type === "right-extreme") return 0
        return 0
      },
      top: 0,
      bottom: 0,
      display(props: Styles) {
        if (props.isSingleExtreme) return "none"
        return props.type === "none" ? "none" : "block"
      },
      background(props: Styles) {
        if (props.type === "single") return "none"
        if (props.type !== "none") return "rgba(0,0,0,0.05)"
        return "none"
      },
    },
    selector: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,

      borderRadius: "50%",
      background: purple[1],
      // color: "white",
      color(props: Styles) {
        if (props.type === "none") return "rgba(0,0,0,0.05)"
        return "none"
      },
      display(props: Styles) {
        if (["right-extreme", "left-extreme", "single"].includes(props.type))
          return "block"

        return "none"
      },
    },
    number: {
      color(props: Styles) {
        if (props.isAExtreme) return "white"
        return theme.palette.text.secondary
      },
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  }
})
