import { CodeMat } from "components/CodeMat/CodeMat"
import React from "react"
import { StyledProps } from "types/type"
import { OrderCurlContext } from "./OrderCurlContext"

export function OrderCurlCode(props: StyledProps) {
  const { curl } = React.useContext(OrderCurlContext)

  if (!curl) return null

  return <CodeMat code={curl} {...props} />
}
