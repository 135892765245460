import React from "react"
import MessageBalloon, {
  MessageBalloonProps,
} from "./components/MessageBalloon/MessageBalloon"
import { makeStyles } from "@material-ui/core"
import MessageMenu from "../../../MessageMenu/MessageMenu"
import MetaCardWithUserAvatar from "components/MetaCardWithUserAvatar/MetaCardWithUserAvatar"
import UserAvatar from "components/UserAvatar/UserAvatar"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { Message_UserInclusionMessage_Fragment } from "lib/graphql/operations"
import clsx from "clsx"

export function UserInclusionMessage(props: {
  message: Message_UserInclusionMessage_Fragment
  MessageBalloonProps: Omit<MessageBalloonProps, "message" | "children">
  classes?: { userCard?: string }
}) {
  const message = props.message

  const c = useStyles({})

  const render = React.useCallback(
    (p: {
      components: {
        authorName: React.ReactNode
        bottom: React.ReactNode
      }
    }) => {
      return (
        <MessageMenu message={message}>
          <React.Fragment>
            {p.components.authorName}

            <UserCard
              message={message}
              className={clsx(props.classes?.userCard, c.userCard)}
            />
            {p.components.bottom}
          </React.Fragment>
        </MessageMenu>
      )
    },
    [message.id, props.classes?.userCard]
  )

  return (
    <MessageBalloon
      message={message}
      // showAuthorName={props.showAuthorName}
      // isCurrentUserMessage={props.isCurrentUserMessage}
      {...props.MessageBalloonProps}
    >
      {render}
    </MessageBalloon>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    userCard: {
      // margin: theme.spacing(1),
      minWidth: 250,
    },
  }
})

function UserCard(props: {
  message: Message_UserInclusionMessage_Fragment
  className?: string
}) {
  // const content = (key) => _.get(message, `userInclusionContent.${key}`) || ""
  const c = useUserCardStyles({})

  const t = useI18n()

  const overline = {
    ADDITION: t["added"],
    REMOVAL: t["removed"],
    "": "empty",
  }
  const userName = props.message.userInclusionContent?.user.name

  if (!userName) return null

  return (
    <MetaCardWithUserAvatar
      userName={userName}
      overline={
        overline[props.message.userInclusionContent?.inclusionType || ""]
      }
      subtitle={userName}
      className={clsx(c.messageMetacard, props.className)}
      avatar={
        <UserAvatar
          userID={props.message.userInclusionContent?.user.id}
        ></UserAvatar>
      }
    />
  )
}
const useUserCardStyles = makeStyles((theme) => {
  return {
    messageMetacard: {
      minWidth: "250px",
    },
  }
})
