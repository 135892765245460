import React, { useMemo } from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import AutoComplete, { AutoCompleteProps } from "../AutoComplete/AutoComplete"

export default function DocumentTypesAutocomplete(
  props: Omit<React.ComponentProps<typeof AutoComplete>, "options"> & {
    AutoComplete?: React.FC<AutoCompleteProps>
  }
) {
  const t = useI18n()

  const collections = t.collectionsTypes?.title_type

  const options = useMemo(() => {
    return Object.entries(collections || {})
      .map(([key, translation]) => ({
        label: translation,
        value: key,
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1))
  }, [collections]) as { label: string; value: string }[]

  const autoCompleteProps = {
    ...props,
    options,
  }

  if (props.AutoComplete)
    return <props.AutoComplete {...autoCompleteProps}></props.AutoComplete>

  return <AutoComplete {...autoCompleteProps}></AutoComplete>
}
