import PresenceListItem from "./components/PresenceListItem/PresenceListItem"
import PresenceListRoot from "./components/PresenceListRoot/PresenceListRoot"
import ActionsButtons from "./components/ActionsButtons/ActionsButtons"
import ListItemLeft from "./components/PresenceListItem/components/ListItemLeft/ListItemLeft"
import getRoomMemberColor from "./lib/getRoomMemberColor"

const PresenceList = {
  Root: PresenceListRoot,
  ListItem: PresenceListItem,
  ActionsButtons: ActionsButtons,
  ListItemLeft,
  getRoomMemberColor,
}

export default PresenceList
