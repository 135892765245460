import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { StyledProps } from "types/type"
import { AddItemToListButton } from "./components/AddItemToListButton/AddItemToListButton"
import { ListPage } from "./components/ListPage/ListPage"
import { UploadedThumbnailEditor } from "./components/UploadedThumbnailEditor/UploadedThumbnailEditor"
import { ItemsListContext, ItemsListProvider } from "./context/ItemsListContext"

export const ItemsList = {
  Root: ItemsListRoot,
  Provider: ItemsListProvider,
  Context: ItemsListContext,
  AddItemToListButton,
}

function ItemsListRoot(props: StyledProps) {
  const c = useStyles()

  return (
    <ItemsListContext.Consumer>
      {(listItemsApi) => {
        return (
          <div
            className={clsx(props.className, "items-list", c.root)}
            // ref={api.template.itemsListContainer.containerRef}
            ref={listItemsApi.rootContainer.containerRef}
            style={props.style}
          >
            <>
              <div style={{ height: "100%" }}>
                <ListPage style={{ height: "100%" }}></ListPage>
              </div>

              <UploadedThumbnailEditor></UploadedThumbnailEditor>
            </>
          </div>
        )
      }}
    </ItemsListContext.Consumer>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",

      "&>.add-item-to-list-button": {
        position: "absolute",
        right: theme.spacing(1),
        bottom: theme.spacing(1),
      },
      "&>.list": {
        height: "100%",
      },
    },
  }
})
