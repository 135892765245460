import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { CalendarEventInput } from "lib/graphql/types"
import useActiveResources from "hooks/useActiveResources"
import { useCreateCalendarEventMutation } from "lib/graphql/operations"
import updateQuery from "../lib/updateQuery/updateQuery"

export default function useCreateCalendarEvent() {
  const r = useActiveResources()
  const [mutate, { loading }] = useCreateCalendarEventMutation()

  const createCalendarEvent = React.useCallback(
    (event: CalendarEventInput) => {
      return mutate({
        variables: { workspaceID: r.workspaceID, event },
        update(dataProxy, { data }) {
          const newEvent = data?.createCalendarEvent

          if (!newEvent) return

          updateQuery(
            {
              queryName: "calendarEntries",
              variables: {
                startDateTime: "",
                endDateTime: "",
                workspaceID: r.workspaceID,
              },
              updateFn: (a) => {
                return {
                  ...a,
                  calendarEntries: [...(a.calendarEntries || []), newEvent],
                }
              },
            },
            dataProxy
          )
        },
      })
    },
    [r.workspaceID]
  )

  return useMemoAPI({
    createCalendarEvent,
    isLoading: loading,
  })
}
