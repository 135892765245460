import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"

export default function SimpleScrollbars(props: { children: React.ReactNode }) {
  const c = useStyles()
  return (
    <div className={clsx("simple-scrolbars", c.root)}>{props.children}</div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      overflow: "auto",
      maxHeight: "100%",
      height: "100%",
    },
  }
})
