import { ButtonBase, makeStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import KanbanCardPageBoardItemContext from "../../../../contexts/KanbanCardPageBoardItemContext"
import BoardAndColumnSelectionAPI from "../../BoardAndColumnSelectionAPI"
import { SelectionPanel } from "../SelectionPanel/SelectionPanel"
import { BottomAndPopoverModal } from "components/BottomAndPopoverModal/BottomAndPopoverModal"

export default function BoardAndColumnSelectionRoot(props: {
  className?: string
  style?: React.CSSProperties
}) {
  const api = React.useContext(KanbanCardPageBoardItemContext)
  const t = useI18n()

  const c = useStyles()
  const boardAndColumnAPI = React.useContext(BoardAndColumnSelectionAPI.Context)

  const openClose = boardAndColumnAPI.openClose

  const [ref, setRef] = React.useState<HTMLElement | null>(null)
  return (
    <>
      <ButtonBase
        style={props.style}
        className={clsx(props.className, c.root, "board-and-column-selection")}
        onClick={() => {
          openClose.open()
        }}
      >
        <div className="content">
          {(() => {
            const roomName = api.card.data?.roomName || ""

            if (!roomName) return null

            return (
              <Typography variant="body2">
                <span className={c.roomName} ref={(e) => setRef(e)}>
                  {t["room"]}:
                </span>{" "}
                {roomName}
              </Typography>
            )
          })()}
          {(() => {
            const columnName = api.card.data?.columnName || ""

            if (!columnName) return null

            return (
              <Typography variant="body2">
                <span className={c.columnName}>{t["list"]}:</span> {columnName}
              </Typography>
            )
          })()}
        </div>
      </ButtonBase>

      <BottomAndPopoverModal popoverAnchorRef={ref} openClose={openClose}>
        {(a) => {
          const selectionPanel = (
            <SelectionPanel
              onClose={openClose.close}
              style={(() => {
                if (a.modalVariant === "bottom-modal") {
                  return { height: "100%" }
                }

                return {
                  height: 400,
                }
              })()}
            ></SelectionPanel>
          )

          return selectionPanel
        }}
      </BottomAndPopoverModal>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    width: "100%",

    "&>.content": {
      width: "100%",
    },
  },
  columnName: { color: theme.palette.text.secondary },

  roomName: {
    color: theme.palette.text.secondary,
  },
}))
