import { useApolloClient } from "@apollo/client";
import useEditAPI from "./useEditAPI"
import _ from "lodash"
import useImagesAPI from "./useImagesAPI/useImagesAPI"
import React from "react"
import useSelectionAPI from "./useSelectionAPI"
import { getCardImages } from "../lib/getCardImages"

export default function usePastedImageListener(props: {
  selectionAPI: ReturnType<typeof useSelectionAPI>
  editAPI: ReturnType<typeof useEditAPI>
  imagesAPI: ReturnType<typeof useImagesAPI>
}) {
  const { editingCardID } = props.editAPI
  const selectedCardID = props.selectionAPI.selectedCard?.id
  const client = useApolloClient()

  const onImages = React.useCallback(
    async (files: File[]) => {
      const cardID = editingCardID || selectedCardID

      if (!cardID) return

      const oldCardImages = getCardImages(cardID, client)

      await props.imagesAPI.addImages(cardID, files)

      if (editingCardID) {
        const { list: oldList = [] } = oldCardImages || {}
        const { list: newList = [], cover = undefined } =
          getCardImages(cardID, client) || {}

        const newImageID = _.difference(
          newList?.map((a) => a?.id) || [],
          oldList?.map((a) => a?.id) || []
        )[0]

        if (newImageID && cover?.id !== newImageID) {
          await props.imagesAPI.setCover(newImageID, editingCardID)
        }
      }
    },
    [
      props.imagesAPI.addImages,
      props.imagesAPI.setCover,
      editingCardID,
      selectedCardID,

      client,
    ]
  )

  usePasteImageListener({
    onImages,
    disabled: !(editingCardID || selectedCardID),
  })
}

function usePasteImageListener(props: {
  onImages: (images: File[]) => void
  disabled?: boolean
}) {
  const enabled = !props.disabled

  React.useEffect(() => {
    if (!enabled) return

    const onPaste = getOnPaste(props.onImages)

    document.addEventListener("paste", onPaste)

    return () => {
      document.removeEventListener("paste", onPaste)
    }
  }, [enabled])
}

function getOnPaste(onImages: (images: File[]) => void) {
  return function onPaste(evt: ClipboardEvent) {
    const clipboardItems = evt?.clipboardData?.items
      ? Array.from(evt?.clipboardData?.items)
      : []

    const items = clipboardItems.filter(function (item) {
      return item.type.indexOf("image") !== -1
    })

    const files = items?.map((i) => i.getAsFile()).filter((a) => !!a) as File[]

    if (!files?.length) return

    onImages(files)
  }
}
