import _ from "lodash"
import React from "react"
import _f from "lodash/fp"
import useQueryWasFetched from "hooks/useQueryWasFetched/useQueryWasFetched"
import { useCallback } from "react"
import {
  RoomMessageListQuery,
  useRoomMessageListQuery,
} from "lib/graphql/operations"
import getLimitVariables, { LimitVariables } from "./getLimitVariables"
import useMemoAPI from "hooks/useMemoAPI"
import useWhenIsBackOnline from "../useWhenIsBackOnline"

export default function useRoomMessageList(props: {
  roomID: string
  workspaceID: string
}) {
  const { roomID, workspaceID } = props
  const variables = { roomID, workspaceID, limit: 15 }

  const [isFetchingMore, setIsFetchingMore] = React.useState<boolean>(false)
  const [fetchingInterval, setFetchInterval] = React.useState<{
    startIndex: number
    stopIndex: number
  } | null>(null)

  const skip = !(variables.workspaceID && variables.roomID)

  const query = useRoomMessageListQuery({
    fetchPolicy: "cache-and-network",
    skip,
    variables,
  })

  const wasFetched = useQueryWasFetched({
    networkStatus: query.networkStatus,
  })

  const fetchMoreListItems = useCallback(
    async (props: LimitVariables) => {
      setIsFetchingMore(() => true)

      const { endDate, startDate, startIndex, stopIndex, limit } = props

      if (startIndex && stopIndex) {
        setFetchInterval({ startIndex, stopIndex })
      }

      const { data } = await query
        ?.fetchMore({
          variables: getLimitVariables({
            endDate,
            startDate,
            startIndex,
            stopIndex,
            limit,
          }),

          updateQuery(previousResult, { fetchMoreResult }) {
            const result: RoomMessageListQuery = {
              __typename: "Query",
              roomMessageList: {
                ...(previousResult?.roomMessageList || {}),
                ...(fetchMoreResult?.roomMessageList || {}),
                list: [
                  ...(previousResult?.roomMessageList?.list || []),
                  ...(fetchMoreResult?.roomMessageList?.list || []),
                ],
              },
            }
            // console.log(result)

            return result
          },
        })
        .finally(() => {
          setIsFetchingMore(() => false)
          setFetchInterval(null)
        })

      return data?.roomMessageList?.list
    },

    [roomID, workspaceID, query?.fetchMore]
  )

  const totalItems = query.data?.roomMessageList.totalItems || 0

  const throttledFetchMore = React.useCallback(
    _.throttle(fetchMoreListItems, 2000),
    [fetchMoreListItems]
  )

  const whenAppReconnect = React.useCallback(() => {
    if (skip) return
    const startIndex = Math.max(totalItems - 1, 0)
    throttledFetchMore({ startIndex, stopIndex: startIndex + 30 })
  }, [totalItems, skip, throttledFetchMore])

  useWhenIsBackOnline(whenAppReconnect)

  // console.log(query?.data?.roomMessageList)

  return useMemoAPI({
    fetchMore: throttledFetchMore,
    isFetching: query.loading || isFetchingMore,
    data: query?.data?.roomMessageList,
    fetchingInterval,
    wasFetched,
  })
}
