import React from "react"
import _ from "lodash"
import { UserFragment } from "lib/graphql/operations"
import { Chip, makeStyles, Paper, Typography } from "@material-ui/core"
import Color from "color"
import clsx from "clsx"

export default function People(props: {
  users: UserFragment[] | undefined | null
  onDelete?: (user: UserFragment) => void
  className?: string
}) {
  const users = props.users

  const c = usePeopleStyles()

  return (
    <div
      className={clsx("people", c.root, props.className)}
      style={{ marginTop: -8 }}
    >
      {Object.entries(_.groupBy(_.uniqBy(users, "id"), (user) => user?.company))
        .sort((a, b) => {
          const companyA = a[0].toLowerCase()
          const companyB = b[0].toLowerCase()

          return companyA < companyB ? -1 : 1
        })
        .map(([company, users]) => (
          <React.Fragment key={company}>
            <Typography variant="body1" color="textSecondary">
              {company}
            </Typography>

            <div className={clsx("users", c.users)}>
              {[...(users || [])]
                .sort((userA, userB) =>
                  (userA?.name || "").toLowerCase() <
                  (userB?.name || "")?.toLowerCase()
                    ? -1
                    : 1
                )
                .map((u) => {
                  return (
                    <Paper key={u?.id} className={c.badge} elevation={0}>
                      <Typography variant="body1">{u.name}</Typography>
                    </Paper>
                  )
                })}
            </div>
          </React.Fragment>
        ))}
    </div>
  )
}
const usePeopleStyles = makeStyles((theme) => {
  return {
    root: {},
    users: {
      display: "flex",
      flexWrap: "wrap",
    },

    badge: {
      background: Color("black").alpha(0.05).string(),
      color: Color("black").alpha(0.6).string(),
      padding: theme.spacing(0.3),
      marginRight: theme.spacing(0.3),
      marginBottom: theme.spacing(1),
      // "&>*": {
      //   lineHeight: 1,
      // },
    },
  }
})
