import { ID, RoomQueryVariables } from "types/type"
import React from "react"
import { GetKanbanCard, KanbanCommentAPI } from "components/Kanban/@types"
import useMemoAPI from "hooks/useMemoAPI"
import useAddComment from "./useAddComment"
import useEditComment from "./useEditComment"
import useRemoveComment from "./useRemoveComment"
import useReactToComment from "./useReactToComment"
import useRemoveReaction from "./useRemoveReaction"

export default function useCommentAPI(
  props: RoomQueryVariables & { getCard: GetKanbanCard }
): KanbanCommentAPI {
  const commentList = React.useCallback(
    (cardID: ID) => props.getCard(cardID)?.commentList || null,
    [props.getCard]
  )

  const addComment = useAddComment({
    roomID: props.roomID,
    workspaceID: props.workspaceID,
    commentList,
  })

  const editComment = useEditComment({
    roomID: props.roomID,
    workspaceID: props.workspaceID,
  })

  const removeComment = useRemoveComment({
    roomID: props.roomID,
    workspaceID: props.workspaceID,
  })

  const reactToComment = useReactToComment({
    roomID: props.roomID,
    workspaceID: props.workspaceID,
  })

  const removeReaction = useRemoveReaction({
    roomID: props.roomID,
    workspaceID: props.workspaceID,
  })

  return useMemoAPI({
    commentList,
    editComment,
    addComment,
    reactToComment,
    removeComment,
    removeReaction,
  })
}
