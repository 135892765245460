import { useEffect, useMemo, useRef } from "react"
import edit$ from "../../../../observers/edit$"
import reply$ from "../../../../observers/reply$"
import useEditMessage from "../useEditMessage"
import useSendMessage from "../useSendMessage"
import { Subject } from "rxjs"
import { modes } from "./constants"
import { setProgressDone } from "./setProgressDone"
import { onProgress } from "./onProgress"

export function useModesApi({
  selectedMessageID,
}: {
  selectedMessageID: string | null
}) {
  const {
    sendMessage,
    sendFiles,
    isLoading: isSendingMessage,
  } = useSendMessage({
    onProgress: onProgress,
    onCacheUpdate: setProgressDone,
  })

  const { editMessage } = useEditMessage()

  const submittedMessage$ = useRef(new Subject())

  useEffect(() => {
    const sub = submittedMessage$.current.subscribe((message) => {
      sendMessage({ message })
    })

    return () => sub.unsubscribe()
  }, [sendMessage])

  return useMemo(() => {
    return {
      [modes.REPLY_MESSAGE]: {
        submitMessage(message: string) {
          if (!selectedMessageID) return
          return sendMessage({ message, repliedMessageID: selectedMessageID })
        },
        discardMessageSelection() {
          reply$.next({ messageID: null })
        },
        onFiles(files: File[]) {
          sendFiles({ files })
        },
      },
      [modes.EDIT_MESSAGE]: {
        submitMessage(message: string) {
          if (!selectedMessageID) return
          return editMessage({ messageID: selectedMessageID, message })
        },
        discardMessageSelection() {
          edit$.next({ messageID: null })
        },
        onFiles(files: File[]) {
          return editMessage({
            messageID: selectedMessageID,
            attachments: files,
          })
        },
      },
      [modes.NO_SELECTED_MESSAGE]: {
        isSendingMessage,
        async submitMessage(message: string) {
          submittedMessage$.current.next(message)
        },
        discardMessageSelection() {},
        onFiles(files: File[]) {
          sendFiles({ files })
        },
      },
    }
  }, [sendMessage, editMessage, sendFiles, isSendingMessage])
}
