import { Box, Icon, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import {
  fieldsetWithDefaultValue,
  spreadObjectOfType,
} from "components/Form/util"
import FormWithMetaCards from "components/FormWithMetaCards/FormWithMetaCards"
import PartnerMetaCard from "components/PartnerMetaCard/PartnerMetaCard"
import { DetailedContactContext } from "contexts/DetailedContactProvider"
import _ from "lodash"
import PartnersContext from "pages/Partners/contexts/PartnersContext"
import useContactFields from "pages/Partners/hooks/useContactFields"
import { useCallback, useContext } from "react"
import getter from "util/getter"
import ActiveSwitchAlignedToRight from "../ActiveSwitchAlignedToRight/ActiveSwitchAlignedToRight"

export default function ContactForm({
  updateContact,
  toggleDelete,
  className = "",
}) {
  const { detailedContact } = useContext(DetailedContactContext)

  const g = getter(detailedContact)
  const c = useStyles({})

  const { setPartnerID } = useContext(PartnersContext)
  const partner = g("partner")

  const partnerID = _.get(partner, "id")

  const fieldset = useFieldset(detailedContact)
  const onSubmit = useOnSubmit({ fieldset, update: updateContact })

  if (_.isEmpty(detailedContact)) return null

  return (
    <FormWithMetaCards
      /**
       * form should be reset when partner changes.
       * It prevents the mixing of data reported at
       * https://trello.com/c/ktpxN6ln/672-bug-if-web-null-old-value-remains
       *
       * by informing the key should change when the partnerID changes,
       * we achieve this effect.
       */
      key={partnerID}
      toggleDelete={toggleDelete}
      className={clsx("contact-form", className)}
      created={{ by: g("createdBy"), when: g("created") }}
      updated={{ by: g("updatedBy"), when: g("updated") }}
      deleted={{ by: g("deletedBy"), when: g("deleted") }}
      MetaCards={({
        basicMetaCards: { deleted = null, created = null, updated = null } = {},
      } = {}) => [
        {
          component: partner && (
            <PartnerMetaCard
              fetchPolicy="cache-only"
              partnerID={partner.id}
              onClick={() => setPartnerID(partner?.id)}
            />
          ),
          grid: { xs: 12, md: 6 },
        },
        created,
        updated,
        deleted,
      ]}
      onSubmit={onSubmit}
      subForms={[
        {
          fields: [
            {
              name: "salutation",
              grid: { xs: 6 },
            },
            {
              render: ActiveSwitchAlignedToRight,
              grid: { xs: 6 },
            },
            "title",
            "firstName",
            "lastName",
            "position",
          ],
        },
        {
          fields: [
            { name: "accessKey", grid: { xs: g("isUser") ? 6 : 12 } },
            g("isUser") && {
              component: (
                <IsUserFlag isUser={g("isUser")} justifyContent="flex-end" />
              ),
              grid: { xs: 6 },
            },
            "email",
            "mobile",
            "landline",
          ],
        },

        { fields: ["remarks"] },

        {
          fields: ["notifyAccessKey", "checkboxes-flags", "attachFile"],
        },
      ]}
      fieldset={fieldset}
    />
  )
}

function useFieldset(contact) {
  const contactFields = useContactFields()
  return fieldsetWithDefaultValue({ data: contact, fieldset: contactFields })
}

function useOnSubmit({ fieldset, update: updateContact }) {
  return useCallback(
    function onSubmit(formValue) {
      const value = spreadObjectOfType({
        type: "checkboxes",
        fieldset,
        object: formValue,
      })

      const readOnlyFields = fieldset
        .filter((f) => !!f.readOnly)
        .map((f) => f.name)
      updateContact(_.omit(value, readOnlyFields))
    },
    [updateContact, fieldset]
  )
}

const useStyles = makeStyles((theme) => {
  return {}
})

function IsUserFlag({ isUser, justifyContent }) {
  return (
    <Box display="flex" justifyContent={justifyContent} alignItems="center">
      <Tooltip title={isUser ? "is a user" : "is not a user"}>
        <Icon>person</Icon>
      </Tooltip>
    </Box>
  )
}
