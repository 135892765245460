import _ from "lodash"
import React, { useCallback } from "react"
import AppBar from "../AppBar/AppBar"

function AppBarWithReactiveSearch({
  search: { debounce, throttle, onChange: propsOnChange, ...searchProps },
  ...appBarProps
}) {
  const func = _.isFinite(throttle) ? _.throttle : _.debounce
  const time = throttle || debounce || 1000

  const onChange = useCallback(func(propsOnChange, time), [
    func,
    time,
    propsOnChange
  ])

  return (
    <AppBar
      {...appBarProps}
      search={{ onChange, ...(searchProps || {}) }}
    ></AppBar>
  )
}

export default AppBarWithReactiveSearch
