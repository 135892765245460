import _ from "lodash"

export default function groupBy({ key, collection: array }) {
  if (!key || !_.isString(key))
    throw new Error(`You should pass a valid grouping key`)
  if (!_.isArray(array)) throw new Error("you should pass an array")

  return (array || []).reduce((acc, p) => {
    if (!p[key]) return acc
    return { ...acc, [p[key]]: p }
  }, {})
}
