import { useSubscription } from "observable-hooks"
import React from "react"
import { Observable, ReplaySubject } from "rxjs"

export default function useReplaySubject<T = any>(
  observable$: Observable<T>,
  args: { bufferSize?: number | undefined } = { bufferSize: 1 }
) {
  const subject$ = React.useRef(new ReplaySubject<T>(args.bufferSize)).current

  const next = React.useCallback((value: T) => {
    subject$.next(value)
  }, [])

  useSubscription(observable$, next)

  return subject$
}
