import React from "react"
import { IconButton, makeStyles } from "@material-ui/core"
import { Icon } from "components/Icon/Icon"
import ToolbarPluginContext from "../../contexts/ToolbarPluginContext"

export type FormatButtonProps = {
  onClick: () => any
  isActive: boolean
  iconName: React.ComponentProps<typeof Icon>["name"]
}

export default function FormatButton(props: FormatButtonProps) {
  const api = React.useContext(ToolbarPluginContext)
  const c = useStyles({ isActive: props.isActive, fontSize: api.fontSize })

  return (
    <IconButton onClick={props.onClick} size="small">
      <Icon className={c.icon} name={props.iconName}></Icon>
    </IconButton>
  )
}

type Style = {
  fontSize?: number
  isActive: boolean
}

const useStyles = makeStyles((theme) => ({
  icon: {
    opacity: ({ isActive }: Style) => (isActive ? 1 : 0.3),
    fontSize: (props: Style) =>
      [props.fontSize ? `${props.fontSize}px` : "24px", " !important"].join(
        " "
      ),
  },
}))
