import React from "react"
import { KeyListener } from "./useKeyListenersAPI"
import useKeyListener from "./useKeyListener"

export default function useEscapeListener(
  /** the callback should be memoized, otherwise chances are it will reload this hooks forever */
  callback: (() => any) | undefined,
  options: { enabled: boolean }
) {
  const listener = React.useMemo(() => {
    if (!callback) return

    const ln: KeyListener = {
      altKey: false,
      fn: callback,
      code: "Escape",
    }

    return ln
  }, [callback])

  return useKeyListener(listener, options)
}
