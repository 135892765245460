import React from "react"
import useDeviceObserver from "hocs/withDeviceObserver"

type DeviceObserverAPI = ReturnType<typeof useDeviceObserver>

const DeviceObserverContext = React.createContext({} as DeviceObserverAPI)

DeviceObserverContext.displayName = "DeviceObserverContext"

const DeviceObserver = {
  Provider: function DeviceObserverProvider(props: {
    children: React.ReactNode
  }) {
    const api = useDeviceObserver()

    return (
      <DeviceObserverContext.Provider value={api}>
        {props.children}
      </DeviceObserverContext.Provider>
    )
  },

  Context: DeviceObserverContext,
}

export default DeviceObserver
