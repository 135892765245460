import clsx from "clsx"
import { alignmentUnits, highlightBorder } from "styles/globalStyles"
import React from "react"
import { Paper, makeStyles } from "@material-ui/core"

export default function ControlBar({
  className = "",
  children,
  flexEnd = true
}) {
  const c = useStyles({ flexEnd })

  return (
    <Paper elevation={0} className={clsx(c.controlBar, className)}>
      {children}
    </Paper>
  )
}

const useStyles = makeStyles(theme => {
  return {
    controlBar: {
      padding: theme.spacing(0.5, alignmentUnits),
      borderBottom: highlightBorder,
      display: "flex",
      justifyContent: ({ flexEnd }) => (flexEnd ? "flex-end" : "flex-start")
    }
  }
})
