// import {
//   NetworkStatus,
//   isNetworkRequestInFlight,
// } from "@apollo-client/core/networkStatus"

import { NetworkStatus } from "@apollo/client"
import { isNetworkRequestInFlight } from "@apollo/client/core/networkStatus"

export const NetworkTransition = {
  LOADED_DATA: "loaded-data",
  WAS_IN_CACHE: "was-in-cache",
  LOADING: "loading",
  REFETCHING: "refetching",
  REFETCHED: "refetched",
  FETCHING_MORE: "fetching-more",
}

export const networkTransitionIs = (prev, current, transition) => {
  switch (transition) {
    case NetworkTransition.WAS_IN_CACHE:
      return (
        current.networkStatus === NetworkStatus.ready &&
        prev.networkStatus === NetworkStatus.ready
      )
    case NetworkTransition.LOADED_DATA:
      return (
        current.networkStatus === NetworkStatus.ready &&
        isNetworkRequestInFlight(prev.networkStatus)
      )
    case NetworkTransition.REFETCHING:
      return current.networkStatus === NetworkStatus.refetch
    case NetworkTransition.REFETCHED:
      return (
        current.networkStatus === NetworkStatus.ready &&
        prev.networkStatus === NetworkStatus.refetch
      )
    case NetworkTransition.FETCHING_MORE:
      return (
        current.networkStatus === NetworkStatus.ready &&
        prev.networkStatus === NetworkStatus.fetchMore
      )
    case NetworkTransition.LOADING:
      return (
        current.networkStatus === NetworkStatus.loading &&
        isNetworkRequestInFlight(prev.networkStatus)
      )
    default:
      return false
  }
}

export const isQueryLoading = (prev, current) => {
  return networkTransitionIs(prev, current, NetworkTransition.LOADING)
}

export const isQueryLoaded = (prev, current, { from_cache = false }) => {
  return networkTransitionIs(
    prev,
    current,
    from_cache ? NetworkTransition.WAS_IN_CACHE : NetworkTransition.LOADED_DATA
  )
}

export const isQueryRefetching = (prev, current) => {
  return networkTransitionIs(prev, current, NetworkTransition.REFETCHING)
}

export const isQueryRefetched = (prev, current) => {
  return networkTransitionIs(prev, current, NetworkTransition.REFETCHED)
}
