import React from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import DeleteModal from "./DeleteModal"

export default function DeleteImageModal(props: {
  isOpen: boolean
  onClose: () => any
}) {
  const t = useI18n()
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const deleteImage = React.useCallback(() => {
    props.onClose()
    process.nextTick(() => {
      api.images.modal.selected.delete()
    })
  }, [api.images.modal.selected.delete])

  return (
    <DeleteModal
      warningText={t["deleteAnImageIsAnIrreversibleOperation"]}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onDelete={deleteImage}
    ></DeleteModal>
  )
}
