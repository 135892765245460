import DetailsTabsContext, {
  DetailsTabsParams,
} from "./contexts/DetailsTabsContext"
import { default as Root } from "./components/DetailsTabsRoot/DetailsTabsRoot"
import useDetailsTabsProviderValue from "./hooks/useDetailsTabsProviderValue"
import GoToDocumentTabButton from "./components/GoToDocumentTabButton/GoToDocumentTabButton"
import GoToDocumentRoomButton from "./components/GoToDocumentRoomButton/GoToDocumentRoomButton"
import EditButtons from "./components/EditButtons/EditButtons"
import basicTabs from "./lib/basicTabs"
import DetailsTabsIDs from "./lib/detailsTabsIDs"
import EditControl from "components/EditControl"
import BasicFieldsGroups from "components/DeprecatedDocumentForm/components/BasicFieldsGroups/BasicFieldsGroups"
import DocumentFormFieldsGroups from "components/DeprecatedDocumentForm/components/DocumentFormFieldsGroups/index"

const DetailsTabs = {
  ActivityFieldset: DocumentFormFieldsGroups.Fieldset,
  Context: DetailsTabsContext,
  tabsIDs: DetailsTabsIDs,
  tabs: basicTabs,
  Root,
  GoToDocumentTabButton,
  GoToDocumentRoomButton,
  // EditControl,
  EditButtons,
  InfoTabBasicFieldsGroups: BasicFieldsGroups,
  Provider: function DetailsTabsProvider(
    props: {
      onSaveEdition(obj: Object): any
      children: React.ReactChild | React.ReactChild[]
    } & DetailsTabsParams
  ) {
    const value = useDetailsTabsProviderValue(props)

    return (
      // <EditControl.Provider
      //   object={value?.entry}
      //   onSaveEditedObject={props.onSaveEdition}
      //   isEditing
      // >
      // </EditControl.Provider>
      <DetailsTabsContext.Provider value={value}>
        {props.children}
      </DetailsTabsContext.Provider>
    )
  },
}

export default DetailsTabs
