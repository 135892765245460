import Typography from "@material-ui/core/Typography"
import CountriesAutoComplete from "components/Input/components/CountriesAutoComplete/CountriesAutoComplete"
import AutoComplete from "components/OldDocumentForm/components/AutoComplete/AutoComplete"
import { PageTextField as TextField } from "components/Page/Page"
import Switch from "components/Switch/Switch"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { FormApi } from "hooks/useForm"
import { InputMaybe } from "lib/graphql/types"

type FormProps = {
  name1?: InputMaybe<string> | null
  active?: InputMaybe<boolean> | null
  name2?: InputMaybe<string> | null
  street?: InputMaybe<string> | null
  zip?: InputMaybe<string> | null
  country?: InputMaybe<string> | null
  city?: InputMaybe<string> | null
}

export function AddressForm<T extends FormProps>(props: {
  form: FormApi<T>
  hide?: { [i in keyof FormProps]: true }
  label?: { [i in keyof FormProps]?: string }
}) {
  const translations = useI18n()

  const form = props.form as any as FormApi<FormProps>

  return (
    <>
      {!props.hide?.name1 && (
        <TextField
          {...form.getFieldProps("name1")}
          InputProps={{
            endAdornment: !props.hide?.active ? (
              <Switch {...form.getFieldProps("active")}></Switch>
            ) : null,
          }}
          label={props.label?.name1 || translations.name}
        ></TextField>
      )}
      {!props.hide?.name2 && (
        <TextField
          {...form.getFieldProps("name2")}
          label={props.label?.name2 || translations.name2}
        ></TextField>
      )}
      {!props.hide?.street && (
        <TextField
          {...form.getFieldProps("street")}
          label={props.label?.street || translations.street}
        ></TextField>
      )}
      {!props.hide?.zip && (
        <TextField
          {...form.getFieldProps("zip")}
          label={props.label?.zip || translations.zip}
        ></TextField>
      )}
      {!props.hide?.city && (
        <TextField
          {...form.getFieldProps("city")}
          label={props.label?.city || translations.city}
        ></TextField>
      )}
      {!props.hide?.country && (
        <CountriesAutoComplete
          {...form.getFieldProps("country")}
          TextFieldProps={{
            label: props.label?.country || translations.country,
          }}
          AutoComplete={AutoComplete}
          renderSuggestionsItem={(item) => {
            return <Typography>{item}</Typography>
          }}
        ></CountriesAutoComplete>
      )}
    </>
  )
}
