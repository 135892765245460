import React from "react"
import _ from "lodash"
import useCollectionsSearch from "hooks/useCollectionsSearch/useCollectionsSearch"
import { PARTNER_TYPES } from "lib/constants"
import Autocomplete from "../OldAutocomplete/Autocomplete"
import { TextField } from "@material-ui/core"

export default function PartnerAutocomplete({ value, ...other }) {
  const { collections } = useCollectionsSearch({
    searchTerm: value,
    types: PARTNER_TYPES,
    limit: 5,
    searchType: "collections",
    fetchPolicy: "cache-and-network",
  })

  console.log(collections, value, other)

  return (
    <Autocomplete
      value={value}
      options={collections}
      getOptionLabel={(option) => (option || {}).title || ""}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Combo box"
            onChange={(e) => {
              console.log(e.target.value)
              other.onChange(e)
            }}
            variant="outlined"
            fullWidth
          />
        )
      }}
      {...other}
    ></Autocomplete>
  )
}
