import React from "react"
import ChatContext from "./contexts/ChatContext"
import useChatAPI, {
  ChatAPIProps,
} from "./hooks/useChatProviderValue/useChatAPI"

export default function ChatProvider(
  props: ChatAPIProps & { children: React.ReactNode }
) {
  const { children, ...apiProps } = props

  const api = useChatAPI(apiProps)

  return (
    <ChatContext.Provider value={api}>{props.children}</ChatContext.Provider>
  )
}
