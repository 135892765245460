import React from "react"
import { Button } from "@material-ui/core"

export default function BackwardButton({ className = "", onClick, disabled = false }) {
  return (
    <Button
      className={className}
      color="primary"
      disabled={disabled}
      variant="text"
      onClick={onClick}
    >
      zurück
    </Button>
  )
}
