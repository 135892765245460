import { useI18n } from "contexts/i18nContext/i18nContext"
import useOnChange from "hooks/useOnChange"
import { Button } from "@material-ui/core"
import React, { useState, useContext, useCallback } from "react"
import useAppRouter from "hooks/useAppRouter"
import { useUtilsContext } from "pages/Partners/contexts/libContext"
import clsx from "clsx"
import PartnersContext from "pages/Partners/contexts/PartnersContext"

export default function SaveAndOpenContactButton({
  className = "",
  contactID,
  style = {},
  cacheUpdateDone = false,
  readyToSubmit,
  submitForm,
}) {
  // const { set } = useUtilsContext()
  const { setContactID } = useContext(PartnersContext)
  const goToContactProfile = useCallback(
    () => setContactID(contactID),
    [setContactID]
  )
  const t = useI18n()
  const buttonPressed = React.useRef(false)

  /** after submission, just go to contact page when the cache's update is done */
  useOnChange({
    value: cacheUpdateDone,
    onChange() {
      //if the cache's update wasn't triggered by the submission done by this button, do nothing.
      if (!buttonPressed.current) return
      if (cacheUpdateDone) goToContactProfile()
    },
  })

  return (
    <Button
      className={clsx("save-and-open-contact-button", className)}
      style={style}
      color="primary"
      onClick={() => {
        if (readyToSubmit) {
          /**
           * by registering the button click, we assure the update of cache
           * was triggered by the submission done by this button.
           */
          buttonPressed.current = true
          submitForm()
        } else {
          goToContactProfile()
        }
      }}
    >
      {t["open-the-contact"]}
    </Button>
  )
}
