import _ from "lodash"
import React, { useContext } from "react"
import { makeStyles } from "@material-ui/styles"
import Avatar from "components/Avatar/Avatar"

import UserAvatarWithPopover from "components/UserAvatarWithPopover/UserAvatarWithPopover"
import { roomTypesColors } from "lib/constants"
import isPrivateRoom from "util/isPrivateRoom"
import { red } from "constants/colors"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import { Icon } from "@material-ui/core"
import ChatContext from "components/Chat/contexts/ChatContext"
import { RoomFragment } from "lib/graphql/operations"

export default function RoomAvatar(props: {
  room: RoomFragment
  showNotification?: boolean
}) {
  const { room, showNotification = true } = props

  const { name, type } = room || {}

  const { goToPrivateRoom } = useContext(ChatContext)

  const classes = useStyles({})
  const isPrivate = isPrivateRoom(room)
  const avatarBackgroundColor = type ? roomTypesColors[type] : null

  return (
    <PurpleBadge
      badgeContent={
        showNotification ? room?.notificationsCount?.visible || 0 : 0
      }
      classes={{ badge: classes.badge }}
      max={9999}
    >
      {isPrivate ? (
        <UserAvatarWithPopover
          goToPrivateRoom={goToPrivateRoom}
          userID={_.get(room, "secondUser.id")}
          avatarBackgroundColor={avatarBackgroundColor}
        />
      ) : (
        <Avatar
          name={name}
          backgroundColor={avatarBackgroundColor}
          content={(() => {
            const types = {
              COLLECTION: <Icon>file_copy</Icon>,
              DOCUMENT: <Icon>description</Icon>,
            }

            return types[type] || null
          })()}
        />
      )}
    </PurpleBadge>
  )
}

const useStyles = makeStyles((theme) => ({
  badge: {
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(1),
    backgroundColor: red[1],
    color: "white",
  },
}))
