import { $convertToMarkdownString } from "@lexical/markdown"
import React from "react"
import { $createCodeNode, $isCodeNode } from "@lexical/code"
import {
  $createLineBreakNode,
  $createTextNode,
  $isParagraphNode,
  RootNode,
} from "lexical"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { TRANSFORMERS } from "@lexical/markdown"
import { LexicalEditorContext } from "components/LexicalEditor/contexts/LexicalEditorContext"

export default function EnforceMarkdownPlugin() {
  const [editor] = useLexicalComposerContext()

  const api = React.useContext(LexicalEditorContext)

  React.useEffect(() => {
    // If we're not in markdown mode, don't enforce markdown
    if (!api.markdownMode.isTrue) return

    return editor.registerNodeTransform(RootNode, (rootNode) => {
      let firstNode = rootNode.getFirstChild()

      if (!$isCodeNode(firstNode)) {
        const markdown = $convertToMarkdownString(TRANSFORMERS)
        const codeNode = $createCodeNode("markdown").append(
          $createTextNode(markdown)
        )

        // rootNode.clear().append(codeNode).selectEnd()
        rootNode.clear().append(codeNode)

        // .selectEnd()

        firstNode = codeNode
      }

      const codeNode = firstNode

      const notCodeNodeChildren = rootNode
        .getChildren()
        .filter((a) => !$isCodeNode(a))

      if (!notCodeNodeChildren.length) return

      codeNode.append(
        ...notCodeNodeChildren
          .map((a, __, array) => {
            if (array.length === 1) {
              return [a, $createLineBreakNode()]
            }

            if ($isParagraphNode(a)) {
              return [
                $createTextNode(a.getTextContent()),
                $createLineBreakNode(),
              ]
            }

            return a
          })
          .flat()
      )

      rootNode.clear().append(codeNode).selectEnd()
    })
  }, [api.markdownMode.isTrue])

  return null
}
