import React from "react"

const EditControlContext = React.createContext({
  editionChanged: false,
  isEditing: undefined,
  newObjectValue: null,
  objectValue: null,
  setIsEditing(value) {},
  updateObject({ field, value }) {},
  startEdition() {},
  saveEdition() {},
  restore(property) {},
  cancelEdition() {},
})

EditControlContext.displayName = "EditControlContext"

export default EditControlContext
