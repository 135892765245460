import _ from "lodash"
import React, { useContext } from "react"
import IconButton from "components/IconButton/IconButton"
import { Tooltip } from "@material-ui/core"
import Partner from "components/Partner/index"

function InvitationButton() {
  const { partner } = useContext(Partner.Context) || {}
  const isInvited = _.get(partner, "bilateral.id")

  return (
    <Tooltip title={isInvited ? "Linked" : "Einladen zum eBilling"}>
      <div>
        <IconButton
          iconName={isInvited ? "link" : "email"}
          disabled={isInvited}
          edge="end"
        ></IconButton>
      </div>
    </Tooltip>
  )
}

export default InvitationButton
