import { useApolloClient } from "@apollo/client"
import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import logout from "lib/logout/logout"
import usePushNotifications from "hooks/usePushNotifications/usePushNotifications"
import * as Sentry from "@sentry/react"
import { enableSentry } from "lib/config"
import useApolloCacheReset from "components/ApolloSetup/hooks/useApolloCacheReset"
import { Subject } from "rxjs"

export const logout$ = new Subject<void>()

export default function useLogout() {
  const client = useApolloClient()
  const pushNotifications = usePushNotifications()
  const { resetCache } = useApolloCacheReset()

  const [isLoading, setIsLoading] = React.useState<Boolean>(false)

  const logoutCall = React.useCallback(() => {
    async function onEnd() {
      setIsLoading(true)

      if (enableSentry) {
        Sentry.configureScope((scope) => {
          console.log({ scope })
          scope.clear()
          scope.setUser(null)
        })
      }

      await resetCache()

      return logout(client).finally(() => {
        setIsLoading(false)
        logout$.next()
        window.location.reload()
      })
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(new Error("Timeout"))
      }, 1000)

      return pushNotifications.unsubscribe().then(resolve).catch(reject)
    }).finally(onEnd)
  }, [client, resetCache])

  return useMemoAPI({ logout: logoutCall, isLoading })
}
