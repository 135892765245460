import React from "react"
import DocumentCollectionsContext from "../../contexts/DocumentCollectionsContext"
import useDocumentCollectionsAPI, {
  DocumentCollectionsProviderProps,
} from "../../hooks/useDocumentCollectionsAPI"

export default function DocumentCollectionsProvider(
  props: DocumentCollectionsProviderProps & {
    children: React.ReactNode
  }
) {
  const api = useDocumentCollectionsAPI(props)

  return (
    <DocumentCollectionsContext.Provider value={api}>
      {props.children}
    </DocumentCollectionsContext.Provider>
  )
}
