import React from "react"
import People from "components/People/People"
import SelectUsersInputContext from "../../contexts/SelectUsersInputContext"
import clsx from "clsx"

export default function SelectedPeople(props: { className?: string }) {
  const api = React.useContext(SelectUsersInputContext)

  return (
    <People
      className={clsx("selected-people", props.className)}
      users={api.selectedUsers}
      onDelete={api.removeUser}
    ></People>
  )
}
