import { DocumentFragment, PartnerFragment } from "lib/graphql/operations"
import { DocumentUpdateInput } from "lib/graphql/types"
import { TwoPagesAPI } from "../../TwoPages/TwoPages"

export enum DocumentStatusType {
  jobStatus = "job-status",
  workStatus = "work-status",
}

export type DocumentStatus = {
  type: DocumentStatusType
  value: string
}

export type APIProps = {
  partner?: PartnerFragment | null
  document?: DocumentFragment | null
  onSaveDocument?: (document: DocumentUpdateInput) => any
  status?: DocumentStatus | null
  twoPagesAPI: TwoPagesAPI
  openPartnerPage?: () => void
}
