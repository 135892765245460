import _ from "lodash"
import React, { useMemo, useContext, useRef, useEffect } from "react"
import { Icon, TextField, InputAdornment } from "@material-ui/core"
import MembersContext from "../../contexts/MembersContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { RoomContext } from "components/Room/contexts/room"

export default function SearchMemberInput() {
  const { classes: c } = useContext(MembersContext)
  const t = useI18n()
  const inputRef = useRef<HTMLInputElement>(null)

  const { searchMembersAPI } = React.useContext(RoomContext)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <TextField
      variant="standard"
      className={c.textField}
      inputRef={inputRef}
      placeholder={t["search-user"]}
      InputProps={{
        value: searchMembersAPI.searched,
        onChange: (e) => searchMembersAPI.search(e.target?.value || ""),
        startAdornment: (
          <InputAdornment position="start">
            <Icon>search</Icon>
          </InputAdornment>
        ),
      }}
    />
  )
}
