import clsx from "clsx"
import _ from "lodash"
import { Scrollbars } from "react-custom-scrollbars"
import React, { useContext } from "react"
import { makeStyles, Drawer, Typography } from "@material-ui/core"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import SideSheetContext from "./contexts/sideSheetContext/sideSheetContext"

export default function SideSheet({
  title = "",
  children = null,
  onClose = () => null,
  className = "",
  isOpen: propsIsOpen = undefined,
  anchor = "right",
  classes: {
    title: titleClass = "",
    sideSheetContent: sideSheetContentClass = "",
  } = {},
  drawerVariant: propsVariant = "",
  paddingZero = false,
}) {
  const { isMobile, isSmallMobile } = React.useContext(DeviceObserver.Context)
  const controllerAPI = useContext(SideSheetContext)
  const variant = propsVariant || (isMobile ? "temporary" : "permanent")
  const c = useStyles({ isMobile, isSmallMobile, variant, paddingZero })

  const isControlledByProps = typeof propsIsOpen !== "undefined"

  return (
    <Drawer
      variant={variant}
      anchor={anchor}
      open={isControlledByProps ? propsIsOpen : controllerAPI.isSideSheetOpen}
      classes={{ paper: c.drawerContent, root: c.drawer }}
      className={clsx("sideSheet", className)}
      onClose={function onCloseSideSheet() {
        _.isFunction(onClose) && onClose()

        if (!isControlledByProps) {
          controllerAPI.closeSideSheet()
        }
      }}
    >
      <Scrollbars className={c.scrollbar}>
        <div className={clsx(c.sideSheetContent, sideSheetContentClass)}>
          {title && (
            <div className={c.firstLine}>
              <Typography variant="h6" className={clsx("title", titleClass)}>
                {title}
              </Typography>
            </div>
          )}
          {children}
        </div>
      </Scrollbars>
    </Drawer>
  )
}

const useStyles = makeStyles((theme) => {
  const s = (...l) => theme.spacing(...l)
  const width = ({ isSmallMobile }) =>
    `${s(isSmallMobile ? 34 : 40)}px !important`

  return {
    drawerContent: {
      position: mobileBranch("fixed", "static"),
      backgroundColor: "#f0f0f0",
      overflow: "hidden",
    },
    drawer: {
      maxHeight: "100%",
      height: "100%",
    },
    sideSheetContent: {
      height: "100%",
      padding: ({ paddingZero }) => (paddingZero ? 0 : s(1, 2)),
      width,
    },
    scrollbar: {
      // width: props => (props.variant === "persistent" ? "auto" : width(props))
      width,
    },
    firstLine: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: s(6),
    },
  }
})

const mobileBranch = (trueValue, falseValue) => (props) =>
  props.isMobile ? trueValue : falseValue
