import { useQuery } from "@apollo/client";
import _ from "lodash"
import { useMemo } from "react"
import { DocumentsTypesTranslationsDocument } from "lib/graphql/operations"

export default function useDocumentsTypesTranslations() {
  const { data, loading } = useQuery(DocumentsTypesTranslationsDocument)
  const result = {
    documentsTypesTranslations: _.get(data, "documentsTypesTranslations"),
    isLoading: loading,
  }
  return useMemo(() => result, [JSON.stringify(result)])
}
