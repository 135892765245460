import useMemoAPI from "hooks/useMemoAPI"
import * as pdfjs from "pdfjs-dist"
import React from "react"
//@ts-ignore
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

let caching: Record<string, pdfjs.PDFDocumentProxy> = {}

export default function usePdfAPI(props: {
  url: string
  enableCaching?: boolean
}) {
  const [documentProxy, setDocumentProxy] =
    React.useState<pdfjs.PDFDocumentProxy | null>(caching[props.url] || null)

  const [pages, setPages] = React.useState<Record<
    number,
    pdfjs.PDFPageProxy
  > | null>(null)

  const [fetchingError, setFetchingError] = React.useState<any>(null)

  React.useEffect(() => {
    if (!fetchingError) return
    console.error(fetchingError)
  }, [!!fetchingError])

  React.useEffect(() => {
    if (!caching[props.url]) return
    setDocumentProxy(caching[props.url] || null)
  }, [props.url])

  React.useEffect(() => {
    setFetchingError(null)

    if (!!props.enableCaching && caching[props.url]) return

    // const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');

    pdfjs
      .getDocument(props.url)
      .promise.then((a) => {
        setDocumentProxy(a)
        caching[props.url] = a
      })
      .catch((e) => setFetchingError(e))
  }, [props.url])

  React.useEffect(() => {
    setPages(null)
  }, [documentProxy])

  const getPage = React.useCallback(
    async function getPage(pageIndex: number) {
      if (!documentProxy?.numPages) return null

      const lastPageIndex = documentProxy?.numPages - 1

      if (pageIndex <= lastPageIndex) {
        let page = pages?.[pageIndex]

        if (!!page) return Promise.resolve(page)

        return documentProxy.getPage(pageIndex + 1)?.then((page) => {
          setPages((pages) => ({ ...pages, [pageIndex]: page }))
          return page
        })
      }

      return
    },
    [documentProxy]
  )

  const [scale, setScale] = React.useState<number>(1)
  const [angle, setAngle] = React.useState<number>(0)

  const zoomIn = React.useCallback(() => {
    setScale((scale) => scale + 0.07)
  }, [])

  const zoomOut = React.useCallback(() => {
    setScale((scale) => scale - 0.07)
  }, [])
  const resetTransformation = React.useCallback(() => {
    setScale(() => 1)
    setAngle(() => 0)
  }, [])

  const rotateLeft = React.useCallback(() => {
    setAngle((angle) => angle - 90)
  }, [])
  const rotateRight = React.useCallback(() => {
    setAngle((angle) => angle + 90)
  }, [])

  return useMemoAPI({
    getPage,
    documentProxy,
    scale,
    url: props.url,
    zoomIn,
    zoomOut,
    resetTransformation,
    angle,
    rotateLeft,
    rotateRight,
  })
}
