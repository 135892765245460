import React from "react"
import { DropzoneProvider, DropzoneContext } from "./contexts/dropzone"
import DropzoneRoot from "./components/DropzoneRoot/DropzoneRoot"
import DropzoneArea from "./components/DropzoneArea/DropzoneArea"
import { states } from "./constants"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
// import { useI18n } from "contexts/i18nContext/i18nContext"

const Dropzone = {
  states,
  Provider: DropzoneProvider,
  Context: DropzoneContext,
  Root: DropzoneRoot,
  Area: DropzoneArea,

  Default(props: {
    multiple?: boolean
    style?: React.CSSProperties
    accept?: string
    disable?: boolean
    children?: React.ReactNode
    className?: string
    onDrop: (acceptedFiles: File[], rejectedFiles: File[]) => any
    acceptFileMessage?: string
    rejectFileMessage?: string
    idleMessage?: string
  }) {
    const {
      multiple,
      accept,
      disable = false,
      children,
      className = "",
      onDrop = (acceptedFiles, rejectedFiles) => undefined,
      acceptFileMessage = "",
      rejectFileMessage = "",
      idleMessage = "",
    } = props

    const c = useStyles()

    return (
      <DropzoneProvider multiple={multiple} accept={accept} onDrop={onDrop}>
        <DropzoneRoot
          className={clsx(className, "dropzone-root")}
          style={props.style}
        >
          <DropzoneContext.Consumer>
            {({ isDragActive }) => {
              return (
                <div className={c.dropzoneRegion}>
                  {children}
                  {!disable && isDragActive && (
                    <DropzoneArea
                      className="area"
                      acceptFileMessage={acceptFileMessage}
                      rejectFileMessage={rejectFileMessage}
                      idleMessage={idleMessage}
                    ></DropzoneArea>
                  )}
                </div>
              )
            }}
          </DropzoneContext.Consumer>
        </DropzoneRoot>
      </DropzoneProvider>
    )
  },
}
export default Dropzone

const useStyles = makeStyles((theme) => {
  return {
    dropzoneRegion: {
      height: "100%",
      position: "relative",
      "& .area": {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
      },
    },
  }
})
