import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import React from "react"
import { StyledProps } from "types/type"
import { TypeBallBadge } from "../../../../../../../TypeBallBadge"
import { ItemGridCellContext } from "./ItemGridCellContext"

export function TypeIconWrapper(
  props: {
    children: React.ReactNode
  } & StyledProps
) {
  const api = React.useContext(ItemGridCellContext)

  const type = api.entry?.type
  const width = 30

  const c = useStyles({ size: width })

  return (
    <div
      className={clsx("type-thumbnail", props.className)}
      style={props.style}
    >
      {props.children}
      <TypeBallBadge
        type={type}
        size={width}
        backgroundColor={api.typeIconBackgroundColor || undefined}
        className={clsx(c.iconWrapper)}
      >
        {(a) => {
          return (
            <PurpleBadge {...api.IconBadgeProps}>
              {a.defaultComponent}
            </PurpleBadge>
          )
        }}
      </TypeBallBadge>
      {/* </PurpleBadge> */}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    iconWrapper: (props: { size: number }) =>
      (() => {
        const bottom = -props.size / 2

        return {
          position: "absolute",
          bottom,
          right: 4,
        }
      })(),
  }
})
