/**
 *  unicode codepoints should be joined by "_"
 *
 * For example: '1F64D_1F3FF_200D_2642_FE0F' renders 🙍🏿‍♂️ (black guy)
 */

export default function Emoji(props: { unicode: string }) {
  const codepoints = props.unicode.split("_").map((a) => parseInt(a, 16))
  return <>{String.fromCodePoint(...codepoints)}</>
}

function getEmoji(unicode) {
  const codepoints = unicode.split("_").map((a) => parseInt(a, 16))
  return String.fromCodePoint(...codepoints)
}
