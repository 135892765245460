import clsx from "clsx"
import { useContext } from "react"
import DetailsTabsContext from "../../contexts/DetailsTabsContext"
import DetailsTabsIDs from "../../lib/detailsTabsIDs"

export default function EditButtons({
  className = "",
  showCloseButton = true,
}) {
  const { tabID, EditControl } = useContext(DetailsTabsContext)

  const isInfoTab = tabID === DetailsTabsIDs.INFO

  if (!isInfoTab) return null

  return (
    <EditControl.EditButtons
      hidden={!isInfoTab}
      className={clsx("activity-details-buttons", className)}
      showCloseButton={showCloseButton}
    ></EditControl.EditButtons>
  )
}
