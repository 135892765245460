import React from "react"
import { makeStyles } from "@material-ui/core"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import ActivityUserAvatar from "../ActivityEntry/components/AddCommentActivity/ActivityUserAvatar"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import { LexicalEditorAPIInput } from "components/LexicalEditor/hooks/useLexicalEditorAPI"
import CommentInput from "../CommentInput/CommentInput"

export default function AddCommentInput() {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const onSave = React.useCallback<
    Exclude<LexicalEditorAPIInput["onSave"], undefined>
  >(
    (text) => {
      return api.comments.add({ text: text })
    },
    [api.comments.add]
  )
  const w = useCurrentWorkspace()

  const c = useStyles()

  if (!w.user) return null

  return (
    <ItemTemplate.Indentation
      leftPosition="top"
      left={<ActivityUserAvatar user={w.user}></ActivityUserAvatar>}
      className={c.root}
    >
      <CommentInput
        namespace="add-comment"
        onSave={onSave}
        clearEditorOnSave
      ></CommentInput>
    </ItemTemplate.Indentation>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
}))
