import _ from "lodash"
import { Typography } from "@material-ui/core"
import clsx from "clsx"
import React, { useCallback } from "react"
import getter from "util/getter"
// import ActivityStatus from "components/DocumentsDashboard/components/EntryListItem/componetns/ActvityStatus/ActivityStatus"

export default function SmallContent({
  activity: selectedActivity,
  renderTitle: propsRenderTitle,
  className = "",
  EntryStatus,
}) {
  const a = useCallback(getter(selectedActivity), [selectedActivity])

  if (!a("id")) return null

  return (
    <div className={clsx("small-content", className)}>
      {(function renderTitle() {
        const defaultComponent = (
          <Typography className="title" variant="body1">
            {a("title")}
          </Typography>
        )

        if (typeof propsRenderTitle === "function")
          return propsRenderTitle({ defaultComponent })

        return defaultComponent
      })()}

      <EntryStatus
        entry={selectedActivity}
        showColorDot={false}
        showColorExplanation={true}
      ></EntryStatus>
    </div>
  )
}
