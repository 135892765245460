import { ActivitiesMode } from "lib/graphql/types"
import moment from "moment"
import React from "react"
import { ShopOrderFragment } from "../../lib/graphql/operations"

export const quote = (stuff: React.ReactNode) => (
  <>
    {['"', stuff, '"'].map((a, index) => {
      return React.cloneElement(<>{a}</>, { key: index })
    })}
  </>
)

const join = (ok: React.ReactNode[]) => (
  <>
    {ok.map((a, index) => {
      return React.cloneElement(<>{a}</>, { key: index })
    })}
  </>
)

// function getDurationInGerman(startIso, endIso) {
//   const start = new Date(startIso);
//   const end = new Date(endIso);
//   const diffInSeconds = Math.round((end - start) / 1000);

//   const minutes = Math.floor(diffInSeconds / 60);
//   const seconds = diffInSeconds % 60;

//   let result = [];

//   if (minutes > 0) {
//     result.push(minutes === 1 ? "1 Minute" : `${minutes} Minuten`);
//   }

//   if (seconds > 0 || (minutes === 0 && seconds === 0)) {
//     result.push(seconds === 1 ? "1 Sekunde" : `${seconds} Sekunden`);
//   }

//   return result.join(" und ");

// }
function getDurationInGerman(startDate: Date, endDate: Date): string {
  const start: number = new Date(startDate).getTime()
  const end: number = new Date(endDate).getTime()
  const diffInSeconds: number = Math.round((end - start) / 1000)

  const minutes: number = Math.floor(diffInSeconds / 60)
  const seconds: number = diffInSeconds % 60

  const result: string[] = []

  if (minutes > 0) {
    result.push(minutes === 1 ? "1 Minute" : `${minutes} Minuten`)
  }

  if (seconds > 0 || (minutes === 0 && seconds === 0)) {
    result.push(seconds === 1 ? "1 Sekunde" : `${seconds} Sekunden`)
  }

  return result.join(" und ")
}

const defaultTranslations = {
  conference: {
    conference: "Konferenz",
    inProgress: "Konferenz läuft",
    getBack: "Zurück zur Konferenz",
    start: "Konferenz starten",
    join: "Konferenz beitreten",
    ended: "Konferenz beendet",
    doYouWantToLeave: "Möchten Sie wirklich die Video-Konferenz verlassen?",
    leaveConferenceQuestionMark: "Video Konferenz verlassen?",
    // startMic: "Mikrofon einschalten",
    activateMic: "Mikrofon aktivieren",
    muteMic: "Mikrofon stummschalten",
    unmuteMic: "Mikrofon einschalten",
    activateCamera: "Kamera aktivieren",
    deactivateCamera: "Kamera deaktivieren",
    leaveConference: "Konferenz verlassen",
  },

  getDuration: getDurationInGerman,

  // doYouWantToCloseTheConference: "Möchten Sie die Konferenz beenden?",

  joinConference: "Konferenz beitreten",
  conferenceEnded: "Konferenz beendet",
  duration: "Dauer",
  aileenIsThinking: "Aileen denkt nach...",
  shopArticle: "Artikel",
  home: "Startseite",
  clear: "Leeren",
  done: "Fertig",
  close: "Schließen",
  shopOrder: "Bestellung",
  shoppingArticles: "Einkaufsartikel",
  additionalInfo: "Zusatz",
  conclude: "Abschließen",
  address: "Adresse",
  shopOrderId: "Bestell-Nr.",
  name: "Name",
  createOrder: "Bestellung erstellen",
  closeOrder: "Bestellung schließen",
  orderIsBeingCreated: "Bestellung wird erstellt",
  deliveryDate: "Lieferdatum",
  cart: "Warenkorb",
  addToTheCart: "In den Warenkorb legen",
  seeOnCart: "Im Warenkorb ansehen",
  removeFromCart: "Aus dem Warenkorb entfernen",
  salesCommission: "Kommission",
  market: "Markt",
  orderTitle: {
    large: (id: ShopOrderFragment["id"]) => {
      return ["Bestellung #", id].join("")
    },

    shorter: (id: ShopOrderFragment["id"]) => {
      return ["#", id].join("")
    },
  },

  share: "Teilen",
  recentlyCreated: "Kürzlich erstellt",
  attachmentNotFound: "Anhang nicht gefunden",
  uploadDocuments: "Dokumente hochladen",
  documentWithoutTitle: "Dokument ohne Titel",
  contactWithoutTitle: "Kontakt ohne Titel",
  restore: "Wiederherstellen",
  vendorWithoutTitle: "Lieferant ohne Titel",
  units: (a: number) => {
    if (a === 0) return "Einheiten"
    return a === 1 ? `${a} Einheit` : `${a} Einheiten`
  },
  readAll: "Alle gelesen",
  createTasksBoard: "Aufgabenbrett erstellen",
  move: "Verschieben",
  iveMovedATaskBetweenColumns: (
    task: React.ReactNode,
    fromColumn: React.ReactNode,
    toColumn: React.ReactNode
  ) => {
    // return join([
    //   task,
    //   " wurde von ",
    //   fromColumn,
    //   " nach ",
    //   toColumn,
    //   " verschoben.",
    // ])

    return join([
      task,
      " wurde von ",
      fromColumn,
      " nach ",
      toColumn,
      " verschoben.",
    ])
  },
  addColumn: "Spalte hinzufügen",
  chatRoom: "Chat-Raum",
  untitledColumn: "Unbenannte Spalte",
  fileTooLarge: "Datei zu groß",
  deletedTask: "Aufgabe gelöscht",
  iveMovedATaskToThisRoom: (task: React.ReactNode, room: React.ReactNode) => {
    return join([task, " wurde nach ", room, " verschoben."])
  },
  iveMovedATaskFromThisRoom: (task: React.ReactNode, room: React.ReactNode) => {
    return join([task, " wurde von ", room, " verschoben."])
  },
  iveDeletedATaskWithTitle: (title: React.ReactNode) => (
    <>
      {["Ich habe eine Aufgabe mit dem Titel ", title, " gelöscht."].map(
        (a, index) => {
          return React.cloneElement(<>{a}</>, { key: index })
        }
      )}
    </>
  ),
  // `${title} gelöscht`,
  selectGoal: "Ziel auswählen",
  position: "Position",
  deleteTask: "Aufgabe löschen",
  creatingTasks: "Erstelle Aufgaben",
  addImage: "Bild hinzufügen",
  createTasks: (n: number) => `${n} Aufgaben erstellen`,
  addNTasksToColumn: (n: number, columnName: string) => {
    if (n === 1) return `${n} Aufgabe zu "${columnName}" hinzufügen`

    return `${n} Aufgaben zu "${columnName}" hinzufügen`
  },
  changeColumn: "Spalte ändern",
  enableMarkdown: "Markdown aktivieren",
  comment: "Kommentar",
  coverImage: "Cover Bild",
  disableMarkdown: "Markdown deaktivieren",
  iveAddedACommentToTheTask: "Ich habe einen Kommentar zur Aufgabe hinzugefügt",
  iveCreatedATask: "Ich habe eine Aufgabe erstellt",
  iveAddedAnImageToTheTask: "Ich habe ein Bild zur Aufgabe hinzugefügt",
  deleteAnImageIsAnIrreversibleOperation:
    "Löschen eines Bildes ist eine irreversible Operation.",

  deleteTaskIsAnIrreversibleOperation:
    "Löschen einer Aufgabe ist eine irreversible Operation.",
  deleteACommentAnIrreversibleOperation:
    "Die Löschung eines Kommentars ist eine irreversible Operation.",

  people: "Personen",
  person: "Person",
  attachments: "Anhänge",
  kanbanCard: "Kanban-Karte",
  tasks: "Aufgaben",
  tasksMessage: "Aufgaben-Nachricht",
  cards: "Karten",
  list: "Liste",
  week: "Woche",
  start: "Start",
  end: "Ende",
  createdThisTask: "hat diese Aufgabe erstellt",
  movedThisTaskFromTheRoomToTheRoom: (props: {
    fromRoom: React.ReactNode
    toRoom: React.ReactNode
  }) => {
    const b = [
      "hat diese Aufgabe von dem Raum ",
      quote(props.fromRoom),
      " ie den Raum ",
      quote(props.toRoom),
      " verschoben",
    ]

    return (
      <>
        {b.map((a, index) => {
          return React.cloneElement(<>{a}</>, { key: index })
        })}
      </>
    )
  },
  movedThisTaskFromColumnToColumn: (props: {
    fromColumn: React.ReactNode
    toColumn: React.ReactNode
  }) => {
    const b = [
      "hat diese Aufgabe von der Spalte ",
      quote(props.fromColumn),
      " in die Spalte ",
      quote(props.toColumn),
      " verschoben",
    ]

    return (
      <>
        {b.map((a, index) => {
          return React.cloneElement(<>{a}</>, { key: index })
        })}
      </>
    )
  },
  invalidDate: "ungültiges Datum",
  dateIsHigherThan(props: { date: string }) {
    return `höher als ${props.date} `
  },
  room: "Raum",
  "new-room": "Neuer Raum",
  "new-event": "Neuer Termin",
  "new-document": "Neues Dokument",
  "new-contact": "Neuer Partner",
  document: "Dokument",
  contact: "Kontakt",
  partner: "Partner",
  event: "Termin",
  filter: "Filter",
  recent: "Zuletzt",
  alarm: "Neuigkeiten",
  "ive-created-card": "Ich habe eine Karte erstellt",
  "search-members": "Mitglieder suchen",
  "added-members": "Mitglieder hinzugefügt",
  "card-without-title": "Karte ohne Title",
  // "without-title": "",
  withoutTitle: "Ohne Titel",
  taskWithoutTitle: "Aufgabe ohne Titel",
  member: "Mitglied",
  members: "Mitglieder",
  country: "Land",
  today: "heute",
  "add-members": "Mitglieder hinzufügen",
  "turn-on-notifications": "Benachrichtigung an",
  "turn-off-notifications": "Benachrichtigung aus",
  "archive-room": "Raum archivieren",
  "active-room": "Raum aktivieren",
  "delete-room": "Raum löschen",
  "user-cannot-be-added": "Benutzer kann nicht hinzugefügt werden",
  image: "Bild",
  salutation: "Anrede",
  month: "Monat",
  year: "Jahr",
  preview: "Vorschau",
  logout: "Abmelden",
  write: "Schreiben",
  you: "Du",
  open: "Öffnen",

  create: "Erstellen",
  "invite-member": "Einladen Mitglieder",
  youWereAddedToTheRoom: (props: { roomName: string }) => {
    if (!props.roomName) return "Sie wurden zu einem Raum hinzugefügt"
    return `Sie wurden dem Raum '${props.roomName}' hinzugefügt`
  },
  comments: "Kommentare",
  description: "Beschreibung",
  soundIsOn: "ton ist an",
  soundIsOff: "ton ist aus",
  kanban: {
    isBeingUploaded: "es wird hochgeladen...",
    createImageCover: "Titelbild erstellen",
    removeImageCover: "Titelbild entfernen",
    deleteCard: "Karte löschen",
  },
  "add-image": "Bild hinzufügen",
  "add-a-description": "Fügen Sie eine Beschreibung hinzu",
  "write-comment": "Kommentieren",
  "article-number": "Artikelnummer",
  "fetching-image": "Fetching Bild",
  "something-went-wrong": "Etwas ist schief gelaufen",
  "show-fewer-images": "Weniger Bilder anzeigen",
  images: "Bilder",
  "show-all-images": (totalHidden) => {
    return [
      "Alle Bilder anzeigen",
      totalHidden ? `(${totalHidden} ausgeblendet)` : "",
    ].join(" ")
  },
  "unread-messages": function unreadMessages(count) {
    if (count === 1) return "1 ungelesene Nachricht"
    else if (count > 1) return `${count} ungelesene Nachrichten`

    return ""
  },
  restore: "Wiederherstellen",
  "click-to-view": "klicke zum Zeigen",
  position: "Position",
  chat: "Chat",
  cart: "Warenkorb",
  "open-in-the-chat": "Öffne im Chat",
  continue: "Weiter",
  "success!": "Erfolg!",
  "selected-articles": "Ausgewählte Artikel",
  "remove-all": "Alles entfern",
  brands: "Hersteller",
  "order-templates": "Bestellvorlagen",
  usersAreTyping(props: { usersNames: string[] }) {
    const usersNames = props.usersNames || []

    if (usersNames.length === 1) return usersNames[0] + " tippt..."

    const lastOne = usersNames.slice(-1)[0]
    const others = usersNames.slice(0, usersNames.length - 1)

    return others.join(", ") + " und " + lastOne + " tippen..."
  },

  all: "Alle",
  more: "Mehr",
  less: "Weniger",
  showMoreItems({ amount }) {
    return `${amount} weitere...`
  },
  install: "Installieren",
  "create-room": "Erstelle Raum",

  "delete-dialog": {
    content: "Dies ist ein irreversibler Vorgang",
    title: "Bist du sicher?",
    agreeText: "Ja, bitte lösche es",
    disagreeText: "Nein",
  },
  "contacts-filter": {
    customer: "Kunden",
    vendor: "Lieferanten",
    lead: "Leads",
    team: "Teams",
    contact: "Ansprechpartner",
  },
  "system-collections": {
    year: "Jahr",
    monat: "Monat",
    tag: "Tag",
    type: "Belegart",
    workflow: "Arbeitsablauf",
    document: "Dokument",
    new: "neue Dokumente",
    marketplace: "Erfassung",
  },
  details: "Einzelheiten",
  "added-at": (addedAt) => {
    return `Hinzugefügt: ${moment(addedAt).format(
      "D MMMM YYYY, [um] HH:mm U[h]r"
    )}`
  },
  "data-privacy": (
    <>
      Mit der Nutzung von onBOXX® erklären Sie sich mit unserer <br />
      <a href="https://www.onboxx.net/datenschutz" target="_blank">
        Datenschutzerklärung
      </a>{" "}
      und{" "}
      <a href="https://onboxx.net/nutzungsbedingungen" target="_blank">
        Nutzungsbedingungen
      </a>{" "}
      einverstanden.
    </>
  ),
  warning: "Warnung",
  name1: "Name 1",
  name2: "Gesellschaftsform",
  partnerNumber: "Partnernr.",
  street: "Strasse",
  zip: "PLZ",
  loading: "Wird geladen",
  city: "Stadt",
  taxID: "USt-ID",
  contacts: "Kontakte",
  rooms: "Räume",
  lastDocuments: "Letzte Dokumente",
  subject: "Betreff",
  firstName: "Vorname",
  lastName: "Nachname",
  landline: "Festnetz",
  search: "Suchen",
  save: "Speichern",
  mobile: "Mobile",
  required: "Erforderlich",
  remarks: "Bemerkung",
  shop: ({ name }) => {
    return `${name.split(" ")[0]} Shop`
  },
  "items-of-page-already-included-in-the-portfolio":
    "Die Elemente der Seite waren bereits im Portfolio enthalten",
  upload: "Hochladen",
  email: "eMail",
  title: "Titel",
  new: "Neu",
  active: "Aktiv",
  inactive: "Inaktiv",
  submit: "Einreichen",
  inspect: "Inspektion",
  reply: "Antworten",
  edited: "Bearbeitet",
  "search-user": "Benutzer suchen",
  "automatic-notification": "Automatische Benachrichtigung",
  "create-access-on-upload": "Erstelle Zugriffsschlüssel bei Upload",
  "weekly-reminder-email-for-new-documents":
    "Wöchentliche Erinnerung für neue Dokumente",
  "attach-file-to-email": "Dokument im eMail-Anhang",
  yes: "Ja",
  showAllDocuments: "Alle Dokumente anzeigen",
  pickADate: "Datum auswählen",
  allDocuments: "Alle Dokumente",
  documents: "Dokumente",
  vendor: "Lieferant",
  lead: "Lead",
  customer: "Kunde",
  wrongEmail: "Falsche eMail",
  emailAlreadyUsed: "eMail bereits verwendet",
  "one-document": "ein Dokument",
  "type-something-here": "Tippe hier etwas ein",
  type: "Type",
  no: "Nein",
  wrongPassword: "Falsches Passwort",
  direct: "Eine Datei pro eMail",
  "you-are-back-online": "Sie sind wieder online",
  "you-are-offline": "Du bist offline",
  herr: "Herr",
  frau: "Frau",
  team: "Team",
  copy: "Kopieren",
  created: "Erstellt",
  deleted: "Gelöscht",
  delete: "Löschen",
  updated: "Geändert",
  "access-key": "Zugangscode",
  copied: "Kopiert",
  message: "Nachricht",
  "copied-to-clipboard": "in die Zwischenablage kopiert",
  "for-all-files": "für alle Dateien",
  "only-this-file": "nur diese Datei",
  "new-partner": "Neuen Partner",
  "save-and-new": "Speichern und Neu",
  "new-contact": "Neuen Kontakt",
  "open-the-contact": "Öffne den Kontakt",
  activities: "Aktivitäten",
  calendar: "Kalender",
  status: "Status",
  from: "Von",
  letterbox: "Briefkasten",
  order: "Auftrag/Bestellung",
  invoice: "Rechnung",
  orderresp: "Auftragsbestätigung",
  "credit-memo": "Gutschrift",
  receipt: "Quittung",
  perform: "Leistungsnachweis",
  contract: "Vertrag",
  quotation: "Angebot",
  ledger: "Kontenblatt",
  "payment-reminder": "Zahlungserinnerung/Mahnung",
  certificate: "Zertifikat",
  "travel-expense": "Reisekostenabrechnung",
  cancellation: "Stornierung",
  loan: "Darlehen",
  balance: "Bilanz",
  add: "Hinzufügen",
  payment: "Zahlung",
  send: "Senden",
  category: "Kategorie",
  cancel: "Abbrechen",
  "add-partner": "Partner hinzufügen",
  "add-contact": "Kontakt hinzufügen",
  "partner-number-already-in-use":
    "Partnernummer ist schon vergeben, die Daten wurden nicht gespeichert.",

  "insert-emoji": "Emoji einfügen",
  "write-a-message": "eine Nachricht schreiben...",
  edit: "Bearbeiten",
  ok: "Ok",
  star: "Stern",
  unstar: "Unstern",
  workstatus: "Arbeitsablauf",
  "job-status": "Job-Status",
  "edit-image": "Bild bearbeiten",
  react: "Reagieren",
  removed: "entfernt",
  added: "hinzugefügt",
  fileLimitMessage(maxSizeInMB) {
    return `Die Datei darf nicht größer als ${maxSizeInMB}MB sein`
  },
  isNotAUser({ username }) {
    if (!username) return ""
    return `${username} ist kein Benutzer`
  },
  isNotProductionVersionWarning: ({ productionURL }) => {
    return (
      <>
        Dies ist das Test/Staging-System und nutzt nur Test-Daten. Für die
        Produktionsumgebung nutzen Sie bitte{" "}
        <a href={productionURL}>{productionURL}</a>
      </>
    )
  },

  "copy-link": "Link kopieren",
  emailYouHaveANewMessage: {
    body({ roomLink, name }) {
      return `Hallo${name ? " " + name : ""}, 
      
      ich habe ihnen neue Nachrichten auf ${roomLink} gesendet.`
    },
    subject() {
      return "chat-Nachrichten warten auf Sie auf 365my.biz"
    },
  },

  emailShareLink: {
    body({ link, name }) {
      return `Hallo${name ? " " + name : ""}, 
      
      bitte besuchen Sie ${link}`
    },
    subject() {
      return "A link"
    },
  },
  "creating-the-chat-room": "Erstellen des Chatrooms...",
  userWasAddedToAChatRoom({ userName }) {
    return (
      <>
        <b>{userName}</b> wurde einem Chatraum hinzugefügt.
      </>
    )
  },
  userWasAdded({ userName }) {
    return (
      <>
        <b>{userName}</b> wurde hinzugefügt
      </>
    )
  },
  userWasRemoved({ userName }) {
    return (
      <>
        <b>{userName}</b> wurde entfernt
      </>
    )
  },
  appGroups: {
    customer: "Kunde",
    vendor: "Lieferant",
    lead: "Lead",
    team: "Team",
    contact: "Ansprechpartner",
    project: "Projekt",
    inbox: "Eingangsbox",
    timemng: "Zeitmanagement",
    account: "Konten",
    costcenter: "Kostenstelle",
    costunit: "Kostenträger",
    profitloss: "Profit/Loss (EÜR)",
  },
  ["BY_PROCESSING_DATE"]: "Aktivitäten",
  ["BY_USER_DATE"]: "Belegdatum",
  ["INVOICES_PAYABLE"]: "Zahldatum",

  activitiesModes: {
    [ActivitiesMode.ByProcessingDate]: "Aktivitäten",
    [ActivitiesMode.ByUserDate]: "Belegdatum",
    [ActivitiesMode.InvoicesPayable]: "Zahldatum",
  },

  aspectRatio: {
    SMALL: "Klein",
    MEDIUM: "Mittel",
    LARGE: "Groß",
  },
  chatImageUploadDropzone: {
    acceptFileMessage: "Bild hier ablegen",
    rejectFileMessage: "Es werden nur Bilder akzeptiert",
  },
  documentUploadDropzone: {
    acceptFileMessage: "Dateien hier ablegen",
    rejectFileMessage: "Nur PDF wird akzeptiert",
    idleMessage:
      "Klicken Sie in den Bereich, oder ziehen Sie einfach'n' Drop-Dateien hierher.",
  },
  netValueGoods: "Summe Netto",
  totalTax: "Summe Steuer",
  totalAmount: "Summe Brutto",
  paymentDate: "Zahlungstermin",
  postDate: "Zahlung",

  activityDetails: {
    title: "Beleg",
    type: "Belegart",
    workstatus: "Arbeitsablauf",
    collection: "Kategorien",
    date: "Belegdatum",
    text: "Text",
  },
  documentType: {
    INVOICE: "Rechnung",
    CREDIT_MEMO: "Gutschrift",
    AUTHORIZATION: "Bevollmächtigung",
    BANK_STATEMENT: "Bankauszug",
    CANCELLATION: "Kündigung",
    CASHFLOW: "Liquidität",
    CATALOG: "Katalog",
    CERTIFICATE: "Zertifikat",
    COMMERCIAL_REGISTER: "Handelsregister",
    CONFIRMATION_OF_PAYMENT: "Zahlungsbestätigung",
    CONTRACT: "Vertrag",
    DEFERRED_PAYMENT: "Ratenzahlung",
    DEMAND_NOTE: "Zahlungsaufforderung",
    LEDGER: "Kontenblatt",
    LETTERBOX: "Briefkasten",
    LOAN: "Darlehen",
    MARKETING: "Marketing",
    META: "Metadaten",
    ORDER: "Auftrag / Bestellung",
    ORDERRESP: "Auftragsbestätigung",
    PAYMENT_REMINDER: "Zahlungserinnerung",
    PERFORM: "Leistungsnachweis",
    PLANNING: "Planung",
    PROOF_OF_AUTHORITY: "Berechtigungsnachweis",
    QUOTATION: "Angebot",
    RECEIPT: "Quittung",
    SIGNATURE: "Signatur",
    TAX_BILL: "Steuerbescheid",
    Template: "Vorlage",
    TRAVEL_EXPENSE: "Reisekostenabrechnung",
    WHITEPAPER: "Whitepaper",
    TASK: "Aufgabe",
    PROJECT: "Projekt",
  },
  invoiceFlow: {
    INCOMING: "Eingangsrechnungen",
    OUTGOING: "Ausgangsrechnungen",
  },
  timemng: {
    IMP_URG: "wichtig & dringend",
    IMP_NOURG: "wichtig, aber nicht dringend",
    NOIMP_URG: "nicht wichtig, aber dringend",
    NOIMP_NOURG: "weder wichtig noch dringend",
  },
  idAlreadUsed: "Die ID wird bereits verwendet",
  reloadPageToUpdateCache: {
    message: "Neu laden, um ein Update verfügbar zu machen",
    reload: "neu laden",
  },
}

export default defaultTranslations
