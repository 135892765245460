import { PageBoardItemParamsInput } from "lib/graphql/types"
import { MakePropsOptionalAndNullable } from "types/type"

export function isPageBoardItemParamsInput(
  a: MakePropsOptionalAndNullable<PageBoardItemParamsInput> | null | undefined
): a is PageBoardItemParamsInput {
  if (!a) return false
  const params = a as PageBoardItemParamsInput
  return params.itemID !== undefined && params.type !== undefined
}
