import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { useConference } from "./hooks/useConference"

const F = contextAndProviderFactory({
  hookApi: useConference,
  contextName: "ConferenceContext",
})

export const ConferenceContext = F.Context
export const ConferenceProvider = F.Provider
