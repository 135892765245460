import { useApolloClient, gql } from "@apollo/client";
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import { userTypesColor } from "lib/constants"
import { UserFragment } from "lib/graphql/operations"
import { UserStatus, UserType } from "lib/graphql/types"
import React from "react"
import readFragment from "util/readFragment"
import Avatar from "../Avatar/Avatar"
import StatusDot from "../StatusDot/StatusDot"

export default function UserAvatar(props: {
  name?: string
  status?: UserStatus
  className?: string
  type?: UserType
  userID?: string
  classes?: { avatar: string }
  backgroundColor?: string
  user?: UserFragment | null
  AvatarProps?: React.ComponentProps<typeof Avatar>
  StatusDotProps?: React.ComponentProps<typeof StatusDot>
  showStatusDot?: boolean
}) {
  const {
    name = undefined,
    status = UserStatus.Offline,
    className = "",
    type = undefined,
    userID = "",
    showStatusDot = true,
    classes: { avatar: avatarClassName = "" } = {},
    backgroundColor: propsBackgroundColor = undefined,
  } = props

  const apolloClient = useApolloClient()

  let user = props.user || { name, status, type }

  if (userID) {
    user = readFragment(
      {
        fragment: gql`
          fragment UserAvatar on User {
            name
            status
            type
          }
        `,
        id: `User:${userID}`,
      },
      apolloClient
    )
  }

  const c = useStyles({})

  const backgroundColor =
    props.backgroundColor || (user?.type ? userTypesColor[user.type || ""] : "")

  if (!user) return null

  return (
    <div className={clsx(c.userAvatar, className, "user-avatar")}>
      <Avatar
        name={user.name || ""}
        className={avatarClassName}
        backgroundColor={backgroundColor}
        {...(props.AvatarProps || {})}
      />
      {showStatusDot && user.status && user.status !== "OFFLINE" && (
        <StatusDot
          status={user.status}
          {...(props.StatusDotProps || {})}
          className={clsx(c.statusDot, props.StatusDotProps?.className)}
        />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    userAvatar: {
      position: "relative",
    },
    statusDot: {
      position: "absolute",
      bottom: 0,
      right: 0,
    },
  }
})
