import { Icon } from "@material-ui/core"
import FileInput, { FileInputHandle } from "components/FileInput/FileInput"
import ImageWithAuth from "components/ImageWithAuth/ImageWithAuth"
import { useI18n } from "contexts/i18nContext/i18nContext"
import {
  Message_TextMessage_Fragment,
  RepliedMessage_TextMessage_Fragment,
} from "lib/graphql/operations"
import React from "react"
import arePropsEqual from "util/arePropsEqual"
import PreviewTemplate from "../../../MessagePreview/components/PreviewTemplate/PreviewTemplate"
import getAttachmentImage from "../../../MessagePreview/util/getAttachmentImage"
import FooterContext from "../../contexts/FooterContext"

function AttachmentPreview(props: {
  message: RepliedMessage_TextMessage_Fragment | Message_TextMessage_Fragment
}) {
  const message = props.message
  const thumb = getAttachmentImage(message)

  const fileInputRef = React.useRef<FileInputHandle>(null)

  const t = useI18n()
  const { onFiles: onFiles } = React.useContext(FooterContext)

  if (!thumb?.src) return null

  return (
    <>
      <ImageWithAuth src={thumb.src}>
        {({ imageObjectURL }) => {
          return (
            <PreviewTemplate
              thumbSrc={imageObjectURL}
              description={
                <Description
                  onClick={() => fileInputRef.current?.openFileDialog()}
                />
              }
              title={t["image"]}
            />
          )
        }}
      </ImageWithAuth>
      <FileInput accept="image/*" ref={fileInputRef} onFiles={onFiles} />
    </>
  )
}

function Description(props: { onClick: () => void }) {
  const t = useI18n()

  return (
    <span
      className="description"
      onClick={props.onClick}
      style={{ display: "flex", alignItems: "center" }}
    >
      <span>{t["edit-image"]}</span>{" "}
      <Icon fontSize="small" style={{ fontSize: "1em", marginLeft: 4 }}>
        create
      </Icon>
    </span>
  )
}

export default React.memo(
  AttachmentPreview,
  arePropsEqual([(a) => JSON.stringify(a.message)])
)
