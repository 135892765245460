import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"

export function useWorkspaceCache<
  Value extends Record<string, any> | undefined | null | string | null
>({ key }: { key: string }) {
  const workspaceID = useActiveResources().workspaceID

  const getStoredValue = React.useCallback(() => {
    let parsedValue: { [workspaceID: string]: any } | null = null

    try {
      const stringifiedValue = window.localStorage.getItem(key)
      parsedValue = JSON.parse(stringifiedValue || "{}")
    } catch (e) {
      console.error(e)
    }

    return parsedValue
  }, [key])

  const setValue = React.useCallback(
    (value: Value) => {
      const storedValue = getStoredValue()

      window.localStorage.setItem(
        key,
        JSON.stringify({ ...storedValue, [workspaceID]: value })
      )
    },
    [key, getStoredValue, workspaceID]
  )

  const value = React.useMemo(() => {
    let v: Value | null = null
    const stringifiedValue = getStoredValue()
    v = stringifiedValue?.[workspaceID] || null

    return v
  }, [workspaceID, getStoredValue])

  return useMemoAPI({ value, setValue })
}
