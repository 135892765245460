import _ from "lodash"
import DeprecatedDocumentForm from "components/DeprecatedDocumentForm/index"
import DocumentsDashboard from "components/DocumentsDashboard"
import { useContext } from "react"

export default function CollectionDashboardDetailsTabsRoot() {
  const { DetailsTabs } = DocumentsDashboard.ActivityDetails
  const { tabs, tabsIDs } = DetailsTabs
  const { partner } = useContext(DetailsTabs.Context)

  return (
    <DetailsTabs.Root
      tabs={{
        ...tabs,
        [tabsIDs.INFO]: {
          ...tabs[tabsIDs.INFO],
          component: (
            <DetailsTabs.Context.Consumer>
              {({ entry: documentObj }) => {
                return (
                  <DeprecatedDocumentForm.Provider
                    partner={partner}
                    document={documentObj || { id: "" }}
                  >
                    <DeprecatedDocumentForm.Root></DeprecatedDocumentForm.Root>
                  </DeprecatedDocumentForm.Provider>
                )
              }}
            </DetailsTabs.Context.Consumer>
          ),
        },
      }}
    ></DetailsTabs.Root>
  )
}
