import {
  ContactSummaryFragmentDoc,
  useUpdateDetailedContactMutation,
} from "lib/graphql/operations"
import { DetailedContactInput } from "lib/graphql/types"
import _ from "lodash"
import { useMemo, useState } from "react"
import fragmentFields from "util/fragmentFields"
import useActiveResources from "../useActiveResources"

export const contactFragmentFields = fragmentFields({
  fragment: ContactSummaryFragmentDoc,
})

export default function useUpdateDetailedContact({
  contactID,
}: {
  contactID: string
}) {
  const { workspaceID } = useActiveResources()

  const [mutate, { data, loading: mutating }] =
    useUpdateDetailedContactMutation()

  const detailedContact = data?.updateDetailedContact

  const [cacheUpdateDone, setCacheUpdateDone] = useState(false)

  const result = {
    detailedContact,
    cacheUpdateDone,
    isLoading: mutating,
  }

  return useMemo(
    () => ({
      ...result,
      async updateDetailedContact(props: { dataUpdate: DetailedContactInput }) {
        const detailedContactInput = props.dataUpdate

        setCacheUpdateDone(false)

        return await mutate({
          variables: {
            workspaceID,
            detailedContactInput,
            contactID,
          },
          update(cache, { data }) {
            const updateDetailedContact = data?.updateDetailedContact

            if (!updateDetailedContact) return

            const basicContact = _.pick(
              updateDetailedContact,
              contactFragmentFields
            )

            cache.writeFragment({
              data: { ...basicContact, __typename: "ContactSummary" },
              id: `ContactSummary:${basicContact.id}`,
              fragment: ContactSummaryFragmentDoc,
            })

            setCacheUpdateDone(true)
          },
        })
      },
    }),
    [JSON.stringify(result), contactID, workspaceID]
  )
}
