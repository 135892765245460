import _ from "lodash"
import Provider from "./components/TemplateProvider/TemplateProvider"
import RoomBody from "./components/RoomBody/RoomBody"
import RoomAppBar from "../RoomAppBar/RoomAppBar"
import RoomTemplateContext from "./contexts/RoomTemplateContext"
import DefaultTemplate from "./components/DefaultTemplate/DefaultTemplate"
import useTemplateAPI from "./hooks/useTemplateAPI"

export const RoomTemplate = {
  Context: RoomTemplateContext,
  useAPI: useTemplateAPI,
  Default: DefaultTemplate,
  Provider,
  AppBar: RoomAppBar,
  Body: RoomBody,
}

export default RoomTemplate
