import { gql } from "@apollo/client";
import _f, { defaultTo, flow, forEach, get, map, pick } from "lodash/fp"
import _ from "lodash"
import readWrite from "util/readWrite"
import getter from "util/getter"

export default function updateMessage({ update }, client) {
  const updateFn = updateAction[update.__typename]

  if (typeof updateFn !== "function") return

  updateAction[update.__typename]({ update }, client)
}

const updateAction = {
  MessageDeletion({ update }, client) {
    const g = getter(update)

    const { read, write } = readWrite({
      proxy: client,
      fragment: gql`
        fragment TextMessageDeleted on TextMessage {
          content
          deletedAt
        }
      `,
      id: `TextMessage:${g("messageID")}`,
    })

    write({
      ..._.pick(update, ["deletedAt", "content"]),
      __typename: "TextMessage",
    })
  },
  MessageContentEdition({ update }, client) {
    const g = getter(update)

    const { read, write } = readWrite({
      proxy: client,
      fragment: gql`
        fragment MessageContentEdition on TextMessage {
          editedAt
          content
        }
      `,
      id: `TextMessage:${g("messageID")}`,
    })

    write({
      ..._.pick(update, ["editedAt", "content"]),
      __typename: "TextMessage",
    })
  },
  MessageReactionsUpdate({ update }, client) {
    const g = getter(update)

    const { read, write } = readWrite({
      proxy: client,
      id: `TextMessage:${g("messageID")}`,
      fragment: gql`
        fragment ReactionUpdate on Message {
          id
          reactions {
            messageID
            emoji
            users {
              id
            }
          }
        }
      `,
    })

    const newData = {
      id: g("messageID"),
      reactions: flow(
        get("reactionsUpdates"),
        map((a) => ({
          ..._.pick(a, ["messageID", "emoji"]),
          users: (a.usersIDs || []).map((id) => ({ id, __typename: "User" })),
          __typename: "Reaction",
        }))
      )(update),
      __typename: "TextMessage",
    }

    write(newData)
  },
}
