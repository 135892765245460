import React from "react"
import _ from "lodash"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import {
  NewPageBoardItem_KanbanCardPageBoardItem_Fragment,
  useKanbanCardPageBoardItemQuery,
} from "lib/graphql/operations"
import useKanbanCardPageBoardItemAPI from "../../hooks/useKanbanCardPageBoardItemAPI"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import Hook from "components/Hook/Hook"
import useActiveResources from "hooks/useActiveResources"
import UseEffect from "components/UseEffect/UseEffect"

export type KanbanCardPageBoardItemApiInput = {
  children: React.ReactNode
  initialParams?: { imageID: string } | { commentID: string }
  TemplatePageProps: Omit<
    React.ComponentProps<typeof ItemTemplate.Provider>,
    "children"
  >
} & (
  | { entry: NewPageBoardItem_KanbanCardPageBoardItem_Fragment }
  | { cardID: string }
)

export default function KanbanPageBoardItemProvider(
  props: KanbanCardPageBoardItemApiInput
) {
  const hasCardID = "cardID" in props
  const hasEntry = "entry" in props

  const r = useActiveResources()

  const fetchedItem = useKanbanCardPageBoardItemQuery({
    fetchPolicy: "cache-and-network",
    skip: (!!hasEntry && !!props.entry) || !hasCardID,
    variables: {
      workspaceID: r.workspaceID,
      cardID: hasCardID ? props.cardID : "",
    },
  }).data?.kanbanCardPageBoardItem

  const entry = hasEntry ? props.entry : fetchedItem

  if (!entry) return null

  return (
    <ItemTemplate.Provider {...props.TemplatePageProps}>
      <Hook hook={useKanbanCardPageBoardItemAPI} hookProps={{ entry }}>
        {(api) => {
          return (
            <>
              <KanbanCardPageBoardItemContext.Provider value={api}>
                {props.children}
              </KanbanCardPageBoardItemContext.Provider>
              <UseEffect
                effect={() => {
                  if (!props.initialParams) return

                  const commentID =
                    "commentID" in props.initialParams &&
                    props.initialParams.commentID

                  if (!commentID) return

                  setTimeout(() => {
                    api.comments.rendered.goTo(commentID)
                  }, 500)
                }}
                deps={[]}
              ></UseEffect>
            </>
          )
        }}
      </Hook>
    </ItemTemplate.Provider>
  )
}
