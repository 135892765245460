import React from "react"
import { Icon, IconButton, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import CalendarEventContext from "../../contexts/CalendarEventContext"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import EventForm from "../EventForm/EventForm"
import SecondPageAPI from "components/Page/components/SecondPageAPI/SecondPageAPI"
import useDeleteCalendarEvent from "hooks/useDeleteCalendarEvent"

export default function ControlButtons() {
  const api = React.useContext(CalendarEventContext)
  const secondPage = React.useContext(SecondPageAPI.Context)
  const r = useCurrentWorkspace()

  const { deleteCalendarEvent } = useDeleteCalendarEvent()

  const c = useControlButtonsStyles()
  const workspaceUser = r.user

  if (!workspaceUser) return null

  const isCreator = api.event?.creator.id === workspaceUser.id

  if (!isCreator) return null

  return (
    <div className={clsx("controls-buttons", c.root)}>
      <IconButton
        size="small"
        onClick={() => {
          secondPage.openPage({ Page: EventForm })
        }}
      >
        <Icon className={c.icon}>edit</Icon>
      </IconButton>

      <IconButton
        size="small"
        onClick={() => {
          if (!api.eventID) return
          deleteCalendarEvent(api.eventID)
          api.onClose?.call(undefined)
        }}
      >
        <Icon className={c.icon}>delete_outline</Icon>
      </IconButton>
    </div>
  )
}
const useControlButtonsStyles = makeStyles((theme) => {
  return {
    icon: {
      fontSize: "19px !important",
    },
    root: {
      display: "flex",
      alignItems: "center",
      "&>*": {
        marginLeft: theme.spacing(1),
      },
    },
  }
})
