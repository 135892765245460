import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import _ from "lodash"
import React from "react"
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core"

export default function ExpansionPanel({
  summary = null,
  details = null,
  className = "",
  onChange = undefined,
  isExpanded: propsExpanded = false,
  defaultExpansion = false,
  timeout = undefined,
  SummaryProps = {},
  DetailsProps = {},
  ExpansionPanelSummaryProps = {},
  ExpansionPanelDetailsProps = {},
  unmountOnExit = true,
  expandIcon = undefined,
  ...props
}) {
  const [isExpanded, setIsExpanded] = React.useState(
    _.isUndefined(propsExpanded) ? defaultExpansion : propsExpanded
  )

  React.useEffect(() => {
    !_.isUndefined(propsExpanded) && setIsExpanded(propsExpanded)
  }, [propsExpanded])

  const handleChange = React.useCallback(
    function handleChange(__, isExpanded) {
      setIsExpanded(isExpanded)
      _.isFunction(onChange) && onChange({ isExpanded })
    },
    [onChange]
  )
  return (
    <MuiExpansionPanel
      onChange={handleChange}
      className={className}
      /** if there is no summary, there is no way to control the expansion, so it should be open **/
      expanded={!summary ? true : isExpanded}
      TransitionProps={{ unmountOnExit, ...(timeout ? { timeout } : {}) }}
      {...props}
    >
      {summary && (
        <ExpansionPanelSummary
          {...{
            expandIcon:
              typeof expandIcon !== "undefined" ? (
                expandIcon
              ) : (
                <ExpandMoreIcon />
              ),
            ...ExpansionPanelSummaryProps,
            ...SummaryProps,
          }}
        >
          {/* // SummaryProps={{
      //   expandIcon: <ExpandMoreIcon />
      // }} */}
          {_.isFunction(summary) ? summary({ isExpanded }) : summary}
        </ExpansionPanelSummary>
      )}
      {details && (
        <ExpansionPanelDetails
          {...DetailsProps}
          {...ExpansionPanelDetailsProps}
        >
          {_.isFunction(details) ? details({ isExpanded }) : details}
        </ExpansionPanelDetails>
      )}
    </MuiExpansionPanel>
  )
}
