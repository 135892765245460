import { getIcon } from "components/PageBoard/hooks/useTypesOptions"
import { DetailedContactFragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import { FormCommons } from "../FormCommons/FormCommons"
import { useCreateRoomMiniPage } from "./hooks/useCreateRoomMiniPage"

export function Controls(props: { contact: DetailedContactFragment | null }) {
  const contact = props.contact

  const openCreateRoomMiniPage = useCreateRoomMiniPage()

  if (!contact) return null

  return (
    <FormCommons.ControlsButtons
      UploadDocumentsProps={{
        collectionsIDs: [contact.collectionID || ""],
      }}
    >
      {({ components, renderIconButton }) => {
        return (
          <>
            <>
              {renderIconButton({
                iconName: getIcon(PageBoardItemType.Room),
                onClick: () => {
                  openCreateRoomMiniPage()
                },
              })}
            </>
            {components.toggleButton}
            {components.uploadButton}
            {components.saveButton}
          </>
        )
      }}
    </FormCommons.ControlsButtons>
  )
}
