import { NotificationsAPI } from "components/NotificationsAPI/NotificationsAPI"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import React from "react"
import NotificationCount from "../NotificationCount/NotificationCount"

function AccountNotifications() {
  const notification = React.useContext(
    NotificationsAPI.Context
  ).sumCountThisWorkspace

  if (!notification) return null

  return <NotificationCount count={notification} />
}

export default AccountNotifications
