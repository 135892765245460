import React from "react"
import { MessagesRendered } from "components/Room/types"
import { RoomContext } from "components/Room/contexts/room"
import { Observable } from "rxjs"
import { filter, take } from "rxjs/operators"
import { useObservableState } from "observable-hooks"

export default function useToBeSeen(props: {
  loadedFirstUnreadIndex: number | null | undefined
  messagesRendered$: Observable<MessagesRendered>
}) {
  const { loadedFirstUnreadIndex: firstUnreadIndex, messagesRendered$ } = props

  const { messageList } = React.useContext(RoomContext)

  // const [, setLowestIndex] = React.useState(low)
  const firstRendered = useObservableState(
    React.useMemo(() => {
      return messagesRendered$.pipe(
        filter((a): a is MessagesRendered => !!a as boolean),
        take(1)
      )
    }, [])
  )

  const lowestIndex = React.useMemo(() => {
    if (typeof firstUnreadIndex === "number") return firstUnreadIndex

    const visibleStartIndex = firstRendered?.visibleStartIndex
    if (typeof visibleStartIndex === "number") return visibleStartIndex

    return undefined
  }, [firstUnreadIndex, firstRendered])

  const totalMsgs = messageList.data?.totalItems
  const lastIndex = typeof totalMsgs === "number" ? totalMsgs - 1 : undefined

  const toBeRendered = React.useMemo(() => {
    if (!lastIndex) return []
    if (typeof lowestIndex !== "number") return []

    return Array.from({ length: lastIndex - lowestIndex + 1 }, (_, index) => {
      return lowestIndex + index
    })
  }, [lastIndex, lowestIndex])

  return toBeRendered
}
