import React from "react"
import GraphqlSubscriptions from "components/GraphqlSubscriptions/GraphqlSubscriptions"
import { merge } from "rxjs"
import { filter, map, mapTo } from "rxjs/operators"
import { RoomIdentifier } from "./useChatAPI"
import useActiveResources from "hooks/useActiveResources"

export default function useActiveRoomIsRemoved$(props: {
  roomIdentifier: RoomIdentifier | null
}) {
  const roomIdentifier = props.roomIdentifier

  const { excludedFromRoom$, deletedRoom$ } = React.useContext(
    GraphqlSubscriptions.Context
  )

  const { workspaceID } = useActiveResources()

  const activeRoomIsRemoved$ = React.useMemo(() => {
    return merge(excludedFromRoom$, deletedRoom$).pipe(
      filter((a) => {
        if (a.workspaceID !== workspaceID) return false
        return a.roomID === roomIdentifier?.roomID
      }),
      mapTo(() => true)
    )
  }, [excludedFromRoom$, deletedRoom$, roomIdentifier?.roomID, workspaceID])

  return activeRoomIsRemoved$
}
