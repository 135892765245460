import React, { useCallback, useMemo } from "react"
import Grid from "components/Grid/Grid"
import clsx from "clsx"

//@TODO: document | add index.d.ts
function ComponentsGrid({ components, spacing = 2, className = "", grid = {xs: 12} }) {
  const renderEntry = useCallback(({ entry: { component } }) => {
    return component
  }, [])

  const entries = useMemo(
    () => (components || []).filter(c => Boolean(c.component)),
    [components]
  )

  return (
    <Grid
      grid={grid}
      spacing={spacing}
      renderEntry={renderEntry}
      entries={entries}
      className={clsx("components-grid", className)}
    ></Grid>
  )
}

export default ComponentsGrid
