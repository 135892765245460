import { useApolloClient, gql } from "@apollo/client";
import { ID } from "types/type"

import { TrackActiveKanbanCardDocument } from "lib/graphql/operations"
import React from "react"

export default function useTrackActiveKanbanCard(props: {
  workspaceID: string
  roomID: string
}) {
  const { workspaceID, roomID } = props

  const client = useApolloClient()
  return React.useCallback(
    (cardID: ID | null) => {
      // if (!workspaceID || !roomID) return null
      // return client.mutate({
      //   mutation: TrackActiveKanbanCardDocument,
      //   variables: { workspaceID, cardID: cardID ? cardID + "" : null },
      //   ...(!cardID
      //     ? {
      //         refetchQueries: [
      //           {
      //             query: UPDATE_ROOM_KANBAN_NOTIFICATIONS_COUNT,
      //             variables: { workspaceID, roomID },
      //           },
      //         ],
      //       }
      //     : {}),
      // })
    },
    [workspaceID, roomID]
  )
}
