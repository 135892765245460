import React from "react"
import useGlobalStyles from "styles/globalStyles"
import { useTheme } from "@material-ui/core"
import clsx from "clsx"

export default function Thumbnail(props: {
  src: string
  className?: string
  height?: number
  width?: number
}) {
  const {
    src: url,
    className = "",
    height: propsHeight,
    width: propsWidth,
  } = props
  const gs = useGlobalStyles({})
  const theme = useTheme()

  return (
    <div
      className={clsx("thumbail", gs.centerBackgroundImage, className)}
      style={{
        ...(url
          ? { backgroundImage: `url(${url})` }
          : { backgroundColor: "lightgray" }),

        width: typeof propsHeight === "number" ? propsHeight : theme.spacing(5),
        height: typeof propsWidth === "number" ? propsWidth : theme.spacing(5),
      }}
    />
  )
}
