import { makeStyles } from "@material-ui/styles"
import React from "react"
import { LinearProgress, Paper } from "@material-ui/core"
import clsx from "clsx"
import { StyledProps } from "types/type"

function FrameWithLoadingProgress<
  T extends React.FunctionComponent<
    {
      children: React.ReactNode
    } & StyledProps
  >
>(
  props: {
    className?: string
    isLoading: boolean
    children: React.ReactNode
    Wrapper?: T
    WrapperProps?: Omit<React.ComponentProps<T>, "children" | "className">
  } & StyledProps
) {
  const c = useStyles({})

  return React.createElement(props.Wrapper || "div", {
    ...(props.WrapperProps || {}),
    style: { ...props.style, ...(props.WrapperProps?.style || {}) },
    children: (
      <>
        {props.isLoading && (
          <LinearProgress className={c.linearProgress}></LinearProgress>
        )}
        {props.children}
      </>
    ),
    className: clsx(
      "frame-with-loading-progress",
      c.positionRelative,
      props.className,
      (props.WrapperProps as StyledProps)?.className
    ),
  })
}

const useStyles = makeStyles((theme) => {
  return {
    positionRelative: {
      position: "relative",
    },
    linearProgress: {
      top: 0,
      left: 0,
      right: 0,
      position: "absolute",
      zIndex: 10000,
    },
  }
})

export default FrameWithLoadingProgress

// const a = <FrameWithLoadingProgress Wrapper={Paper}></FrameWithLoadingProgress>
