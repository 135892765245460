import getLibContext from "util/getLibContext"

//REMOVE
export let { LibProvider, useParamsContext, useUtilsContext } = getLibContext({
  utilsDefault: {
    getLink: () => null, //
    getCollectionLink: () => "", //
    getPartnerLink: () => "", //
    getContactLink: () => "", //
    openNewPartnerModal() {}, //
    openNewContactModal({ modalPartnerID }) {}, //
    closeModal() {}, //
    closeDetails: () => null,
    setSearchedTerm: () => null, //
    setTypes: () => null, //
    getDocumentsTabLink: () => null,
    goToTab: () => null,
    getDocumentLink: ({ documentID }) => "",
  },
  paramsDefault: {
    collection: undefined,
    collectionTypeColor: "",
    collectionID: "",
    modal: "",
    documentID: "",
    searchedTerm: "",
    types: [],
    partnerID: "",
    contactID: "",
  },
})
