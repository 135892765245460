import React from "react"
import { Paper } from "@material-ui/core"
import clsx from "clsx"
import { useContext } from "react"
import PaperListApiContext from "../../contexts/PaperListContext"
import { List } from "../../../List/List"
import { useStyles } from "./PaperListRoot"

export function PaperListRootItem(props: { index: number }) {
  const c = useStyles({})

  const api = useContext(PaperListApiContext)

  if (!api.ListItem) return null

  return (
    <List.Item index={props.index}>
      {({ entry, isFirst, isLast }) => {
        return (
          <Paper
            {...(api.ItemPaperProps || {})}
            className={clsx(
              c.itemPaper,
              api.classes?.itemPaper,
              isFirst && c.firstItemPaper,
              isLast && c.lastItemPaper,
              api.marginZero && c.marginZero,
              api.ItemPaperProps?.className
            )}
          >
            <api.ListItem entry={entry}></api.ListItem>
          </Paper>
        )
      }}
    </List.Item>
  )
}
