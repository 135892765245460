import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown"
import React from "react"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { CodeNode } from "@lexical/code"
import { LexicalEditorContext } from "components/LexicalEditor/contexts/LexicalEditorContext"

export function $enforceWYSIWYG(props: { codeNode: CodeNode }) {
  const codeNode = props.codeNode

  const language = codeNode.getLanguage()

  if (language !== "markdown") return

  $convertFromMarkdownString(codeNode.getTextContent(), TRANSFORMERS)
}

export default function EnforceWYSIWYGPlugin() {
  const [editor] = useLexicalComposerContext()

  const api = React.useContext(LexicalEditorContext)

  React.useEffect(() => {
    // If we're in markdown mode, don't enforce WYSIWYG
    if (!!api.markdownMode.isTrue) return

    return editor.registerNodeTransform(CodeNode, (codeNode) => {
      $enforceWYSIWYG({ codeNode })
    })
  }, [api.markdownMode.isTrue])

  return null
}
