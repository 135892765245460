import useMemoAPI from "hooks/useMemoAPI"
import { ShopArticleFragment } from "lib/graphql/operations"
import { useObservableState } from "observable-hooks"
import React from "react"
import { Subject } from "rxjs"
import contextAndProviderFactory from "../../lib/contextAndProviderFactory"
import { useGridProps } from "components/List/hooks/useGridProps"

export type ShopArticlesGridProps = {
  articles: (ShopArticleFragment | undefined | null)[]
  fetchMore?: () => void
  onClickArticle?: (p: { article: ShopArticleFragment; index: number }) => void
  renderArticle?: (p: {
    article: ShopArticleFragment
    index: number
  }) => React.ReactNode
}

export type ShopArticlesGridApi = ReturnType<typeof useShopArticlesGrid>

function useShopArticlesGrid(props: ShopArticlesGridProps) {
  const gridProps = useGridProps({ items: props.articles })

  return useMemoAPI({
    ...props,
    ...gridProps,
  })
}

const Factory = contextAndProviderFactory({ hookApi: useShopArticlesGrid })

export const ShopArticlesGridContext = Factory.Context
export const ShopArticlesGridProvider = Factory.Provider
