import { Icon, Typography, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import Avatar from "components/Avatar/Avatar"
import ClippedText from "components/ClippedText/ClippedText"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import PaperList from "components/PaperList"
import { NewPageBoardItem_CalendarEventPageBoardItem_Fragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import moment from "moment"
import React from "react"
import arePropsEqual from "util/arePropsEqual"
import useTypesOptions from "../../../../../../../../hooks/useTypesOptions"
import { ListEntryProps } from "../../types"
import { ChainProps } from "../Chain/Chain"
import ListItem from "../ListItem/ListItem"
import { ItemGridCell } from "../ItemGridCell/ItemGridCell"
import { titleGettersMap } from "components/PageBoard/components/IndexedItems/components/HistoryNavigation/HistoryNavigation"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function CalendarEventPageBoardItem(props: ListEntryProps) {
  if (props.entry?.__typename !== "CalendarEventPageBoardItem") return null

  return (
    <MemoizedItem
      entry={props.entry}
      className={props.className}
      style={props.style}
    />
  )
}
const MemoizedItem = React.memo(
  Item,
  arePropsEqual([
    (a) => a.entry?.id,
    (a) => a.entry?.name,
    (a) => a.entry?.event?.title,
  ])
)

function Item(props: {
  entry: NewPageBoardItem_CalendarEventPageBoardItem_Fragment
  className?: string
  style?: React.CSSProperties
}) {
  const c = useStyles()

  // const { RootContainer } = React.useContext(PaperList.Context)

  // const rootContainer = React.useContext(RootContainer.Context)
  const api = React.useContext(ItemsListContext)

  const rootContainer = api.rootContainer

  const maxSize = rootContainer.width < 700 ? 150 : 500

  const options = useTypesOptions()

  const chainDisplay: ChainProps["display"] =
    rootContainer.width < 500 ? "block" : "line"

  // const title = props.entry.event?.title || props.entry.name || ""

  const t = useI18n()

  const title = titleGettersMap({ translations: t })[
    PageBoardItemType.CalendarEvent
  ]?.(props.entry)

  if (!props.entry?.event) return null

  const description = (() => {
    const start = moment(props.entry.event.startDateTime).format("DD.MM.YYYY")
    const end = moment(props.entry.event.endDateTime).format("DD.MM.YYYY")

    return [start, end].join(" - ")
  })()

  if (api.variant === "grid") {
    return (
      <ItemGridCell
        entry={props.entry}
        descriptionText={description}
        title={title}
        // timestamp={props.e}
        // timestamp={ti}
      ></ItemGridCell>
    )
  }

  return (
    <ListItem
      className={clsx(c.root, "indexed-item", props.className)}
      style={props.style}
      entry={props.entry}
      // right={
      //   <PaperList.ListItemText
      //     secondary={
      //       // <div style={{ display: "flex", justifyContent: "flex-end" }}>
      //       //   <StopPropagation>
      //       //     <div style={{ display: "flex" }}>
      //       //       {props.entry.room?.document?.documentID ? (
      //       //         <OpenDocumentButton
      //       //           entry={props.entry.room.document}
      //       //         ></OpenDocumentButton>
      //       //       ) : (
      //       //         <div></div>
      //       //       )}
      //       //     </div>
      //       //   </StopPropagation>
      //       // </div>
      //     }
      //     {...(() => {
      //       // const sentAt = props.entry.room?.lastMessage?.sentAt

      //       if (!!sentAt)
      //         return {
      //           primary: (
      //             <Typography variant="body2" color="textSecondary">
      //               {formatDate(
      //                 props.entry.room?.lastMessage?.sentAt,
      //                 rootContainer.width > 400
      //               )}
      //             </Typography>
      //           ),
      //         }

      //       return {}
      //     })()}
      //   ></PaperList.ListItemText>
      // }
      right={null}
      left={
        <React.Fragment>
          <Avatar
            backgroundColor={options[PageBoardItemType.CalendarEvent]?.color}
            content={(() => {
              return (
                <Icon>{options[PageBoardItemType.CalendarEvent]?.icon}</Icon>
              )
            })()}
            // BadgeProps={{
            //   overlap: "circular",
            //   anchorOrigin: { vertical: "top", horizontal: "right" },
            //   badgeContent:
            //     (!props.entry.room?.isSilenced &&
            //       props.entry.notificationCount) ||
            //     0,
            //   max: 99,
            // }}
          />
          <div style={{ marginLeft: 8 }}></div>
          <PaperList.ListItemText
            primary={(() => {
              return (
                <Typography variant="body1" color="textPrimary">
                  <ClippedText maxWidth={maxSize}>{title}</ClippedText>
                </Typography>
              )
              // return (
              //   <Chain separator="-" display={chainDisplay}>
              //     {(() => {
              //       const a = [
              //         props.entry.room?.context && (
              //           <Typography
              //             color="textSecondary"
              //             variant={(() => {
              //               if (chainDisplay === "block") return "caption"
              //               return "body1"
              //             })()}
              //           >
              //             <ClippedText maxWidth={maxSize}>
              //               {props.entry.room?.context}
              //             </ClippedText>
              //           </Typography>
              //         ),

              //         <div style={{ display: "flex", alignItems: "center" }}>
              //           <Typography variant="body1" color="textPrimary">
              //             <ClippedText maxWidth={maxSize}>
              //               {props.entry.room?.name || props.entry.name}
              //             </ClippedText>
              //           </Typography>

              //           {props.entry.room?.isSilenced && (
              //             <Icon
              //               className={c.notificationsOffIcon}
              //               style={{ fontSize: 19, marginLeft: 4 }}
              //             >
              //               notifications_off
              //             </Icon>
              //           )}
              //         </div>,
              //       ]

              //       if (chainDisplay == "block") return a

              //       return a.reverse()
              //     })()}
              //   </Chain>
              // )
            })()}
            secondary={(() => {
              if (!props.entry.event) return null

              return (
                <Typography color="textSecondary" variant="body2">
                  {description}
                </Typography>
              )
            })()}
          ></PaperList.ListItemText>
        </React.Fragment>
      }
    ></ListItem>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      // opacity: (props: { isReadOnly: boolean }) =>
      //   props.isReadOnly ? 0.3 : "inherit",
    },

    // notificationsOffIcon: {
    //   color: theme.palette.text.secondary,
    // },
  }
})
