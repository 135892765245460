import { Participant, Stream, VideoClient } from "@zoom/videosdk"
import { usePrevious } from "components/Zoom/hooks"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  ConferenceUserFragment,
  useZoomSessionUsersQuery,
} from "lib/graphql/operations"
import { useSubscription } from "observable-hooks"
import React from "react"
import { Subject } from "rxjs"
import {
  ParticipantsUpdate,
  useParticipantsUpdate$,
} from "./useParticipantsUpdate$"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"

export function useParticipants({
  zoomClient,
  mediaStream,
  sessionID,
}: {
  zoomClient: typeof VideoClient
  mediaStream: typeof Stream | null
  sessionID: string | null
}) {
  const [list, setParticipants] = React.useState<Participant[]>(
    zoomClient.getAllUser()
  )
  const [usersIDsWithVideoOn, setUsersIDsWithVideoOn] = React.useState<
    number[]
  >([])
  const previousSubscribers = usePrevious(usersIDsWithVideoOn)

  const participantsUpdate$ = useParticipantsUpdate$(zoomClient)

  useSubscription(
    participantsUpdate$,
    React.useCallback((update: ParticipantsUpdate) => {
      const { allParticipants } = update
      let pageParticipants: Participant[] = []

      if (allParticipants.length > 0) {
        if (allParticipants.length === 1) {
          pageParticipants = allParticipants
        } else {
          pageParticipants = allParticipants
            .filter(
              (user) => user.userId !== zoomClient.getSessionInfo().userId
            )
            .sort(
              (user1, user2) => Number(user2.bVideoOn) - Number(user1.bVideoOn)
            )
          const currentUser = zoomClient.getCurrentUserInfo()
          if (currentUser) {
            pageParticipants.splice(1, 0, currentUser)
          }
        }
      }

      setParticipants(pageParticipants)
      setUsersIDsWithVideoOn(
        pageParticipants.filter((user) => user.bVideoOn).map((u) => u.userId)
      )
    }, [])
  )

  // useParticipantsChange(zoomClient, (newParticipants) => {
  //   let pageParticipants: Participant[] = []
  //   if (newParticipants.length > 0) {
  //     if (newParticipants.length === 1) {
  //       pageParticipants = newParticipants
  //     } else {
  //       pageParticipants = newParticipants
  //         .filter((user) => user.userId !== zoomClient.getSessionInfo().userId)
  //         .sort(
  //           (user1, user2) => Number(user2.bVideoOn) - Number(user1.bVideoOn)
  //         )
  //       const currentUser = zoomClient.getCurrentUserInfo()
  //       if (currentUser) {
  //         pageParticipants.splice(1, 0, currentUser)
  //       }
  //     }
  //   }

  //   setParticipants(pageParticipants)
  //   setUsersIDsWithVideoOn(
  //     pageParticipants.filter((user) => user.bVideoOn).map((u) => u.userId)
  //   )
  // })

  const addedParticipants$ = React.useRef(new Subject<number[]>())
  const removedParticipants$ = React.useRef(new Subject<number[]>())

  React.useEffect(() => {
    const addedUsers = usersIDsWithVideoOn.filter(
      (user) => !(previousSubscribers || []).includes(user)
    )
    const removedUsers = (previousSubscribers || []).filter(
      (user) => !usersIDsWithVideoOn.includes(user)
    )
    if (removedUsers.length > 0) {
      removedParticipants$.current.next(removedUsers)
      // removedUsers.forEach((userId) => {
      //   mediaStream?.detachVideo(userId)
      // })
    }
    if (addedUsers.length > 0) {
      // addedUsers.forEach((userId) => {
      //   const attachment = videoPlayerListRef.current[`${userId}`]
      //   if (attachment) {
      //     mediaStream?.attachVideo(userId, VideoQuality.Video_360P, attachment)
      //   }
      // })

      // addedUsers.
      addedParticipants$.current.next(addedUsers)
    }
  }, [usersIDsWithVideoOn, previousSubscribers, mediaStream])

  const appUsers = useSessionAppUsers({
    // sessionID: sessionID,
    displayNames: list.map((user) => user.displayName),
  })

  React.useEffect(() => {
    if (!sessionID || !list?.length) return
    appUsers.refetch()
  }, [list.length])

  return useMemoAPI({
    list,
    getAppUser: appUsers.getUser.bind(appUsers),
    usersIdsWithVideoOn: usersIDsWithVideoOn,
    removedParticipantsIds$: removedParticipants$.current,
    addedParticipantsIds$: addedParticipants$.current,
  })
}

function useSessionAppUsers(props: { displayNames: string[] }) {
  const workspaceID = useActiveResources().workspaceID

  const queryResult = useZoomSessionUsersQuery({
    variables: {
      displayNames: props.displayNames || [],
      workspaceID: workspaceID,
    },
    skip: !workspaceID || !props.displayNames?.length,
    fetchPolicy: "network-only",
  })

  const currentUser = useCurrentWorkspace().user

  const sessionUsers = (() => {
    const a = queryResult.data?.zoomSessionUsers
    return a
  })()

  const sessionUsersById = React.useMemo(
    () =>
      sessionUsers?.reduce((acc, user) => {
        acc[user.displayName] = user || currentUser
        return acc
      }, {} as Record<string, ConferenceUserFragment>),
    [sessionUsers]
  )

  const getUser = React.useCallback(
    function (props: { displayName: number | string }) {
      if (!Object.keys(sessionUsersById || {})?.length) return currentUser
      return (sessionUsersById?.[props.displayName + ""] || null)?.user || null
    },
    [sessionUsersById, currentUser]
  )

  return useMemoAPI({
    refetch: queryResult.refetch.bind(queryResult),
    getUser,
  })
}
