import React from "react"
import { Typography } from "@material-ui/core"
import ClippedText from "components/ClippedText/ClippedText"
import Chain from "../Chain/Chain"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"

export default function MessageChain(props: {
  authorName: string
  text: string
}) {
  const api = React.useContext(ItemsListContext)
  const chainDisplay = api.template.chainDisplay
  const lineMaxWidth = api.template.lineMaxWidth

  return (
    <Chain separator="-" display={chainDisplay}>
      <Typography component="span" variant="body2" color="textPrimary">
        <ClippedText maxWidth={lineMaxWidth}>{props.authorName}</ClippedText>
      </Typography>
      <Typography component="span" variant="body2" color="textSecondary">
        <ClippedText maxWidth={lineMaxWidth}>{props.text}</ClippedText>
      </Typography>
    </Chain>
  )
}
