import _ from "lodash"
import React from "react"
import { useContext } from "react"

export default function getUtilsContext({ defaultValue = {} } = {}) {
  const UtilsContext = React.createContext(defaultValue)
  UtilsContext.displayName = "UtilsContext"

  function UtilsProvider({ utils, children }) {
    _.entries(utils).forEach(([key, value]) => {
      if (!_.isFunction(value)) throw new Error(`${key} should be a function`)
    })

    return (
      <UtilsContext.Provider value={utils}>{children}</UtilsContext.Provider>
    )
  }

  function useUtilsContext() {
    return useContext(UtilsContext)
  }

  return { UtilsProvider, useUtilsContext }
}
