import { useApolloClient, gql } from "@apollo/client";
import { ID, RoomQueryVariables, Typename } from "types/type"
import _ from "lodash"
import React from "react"
import { KanbanCommentAPI } from "components/Kanban/@types"
import {
  AddKanbanCommentDocument,
  useAddKanbanCommentMutation,
} from "lib/graphql/operations"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import useActiveResources from "hooks/useActiveResources"
import updateFragment from "lib/updateFragment/updateFragment"
import readFragment from "lib/updateFragment/readFragment"

export default function useAddComment(
  props: RoomQueryVariables & {
    commentList: KanbanCommentAPI["commentList"]
  }
) {
  const [mutate, {}] = useAddKanbanCommentMutation()

  return React.useCallback(
    (cardID: ID, text: string) => {
      return mutate({
        variables: {
          roomID: props.roomID,
          workspaceID: props.workspaceID,
          cardID,
          text,
        },
        update(dataProxy, { data }) {
          if (!data?.addKanbanComment) return

          const commentList = data.addKanbanComment.updatedCommentList

          updateFragment(
            {
              typename: Typename.KanbanCard,
              cacheIDParams: { id: cardID },
              updateFn: (card) => ({ ...card, commentList }),
            },
            dataProxy
          )

          const a = readFragment(
            {
              typename: Typename.KanbanCard,
              cacheIDParams: { id: cardID },
            },
            dataProxy
          )

          console.log(a)
        },
      })
    },
    [props.roomID, props.workspaceID, mutate]
  )
}
