import EmojiPicker from "components/EmojiPicker/EmojiPicker"
import { RoomContext } from "components/Room/contexts/room"
import delete$ from "components/Room/observers/delete$"
import edit$ from "components/Room/observers/edit$"
import reply$ from "components/Room/observers/reply$"
import I18nContext, { useI18n } from "contexts/i18nContext/i18nContext"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import useReaction from "hooks/useReaction/useReactions"
import { excludeNullable } from "lib/excludeNullable"
import { MessageFragment } from "lib/graphql/operations"
import _ from "lodash"
import React from "react"
import { useChangeFavoriteStatus } from "./useChangeFavoriteStatus"

const additionalItems = {
  TextMessage: ({
    message,
    isAuthor,
  }: {
    message: MessageFragment
    isAuthor: boolean
  }) => {
    if (!isAuthor) return []

    return [
      {
        label: <I18nContext.Consumer>{(t) => t["edit"]}</I18nContext.Consumer>,
        icon: "create",
        onClick: () => {
          edit$.next({ messageID: message.id })
        },
      },
    ]
  },
}

export type MessageMenuItem = {
  label: React.ReactNode
  icon: string
  onClick: () => any
  modalContent?: React.ReactNode
}

export default function useMenuItems(props: {
  closeModal: () => any
  message: MessageFragment
}) {
  const t = useI18n()
  const { addReaction } = useReaction({ message: props.message })
  const roomAPI = React.useContext(RoomContext)

  const { workspace } = useCurrentWorkspace()

  const isAuthor =
    _.get(props.message, "author.id") === _.get(workspace, "user.id")

  const { isFavorite$ } = useChangeFavoriteStatus({ message: props.message })

  const additional = (() => {
    const fn =
      additionalItems[props.message.__typename as keyof typeof additionalItems]

    if (!fn) return []

    return fn({ message: props.message, isAuthor })
  })()

  const a: MessageMenuItem[] = [
    {
      label: t["reply"],
      icon: "reply",
      onClick: () => {
        reply$.next({ messageID: props.message.id })
      },
    },
    (() => {
      if (props.message.isFavorite) {
        return {
          label: t["unstar"],
          icon: "star",
          onClick: () => {
            isFavorite$.next(false)
          },
        }
      }

      return {
        label: t["star"],
        icon: "star",
        onClick: () => {
          isFavorite$.next(true)
        },
      }
    })(),

    isAuthor && {
      label: t["delete"],
      icon: "delete",
      onClick: () => {
        delete$.next({ messageID: props.message.id })
      },
    },

    {
      label: t["react"],
      icon: "insert_emoticon",
      onClick: () => {},
      modalContent: (
        <EmojiPicker
          onSelect={({ colons: emoji, ...others }) => {
            if (emoji) addReaction(others.id)
            props.closeModal()
          }}
        />
      ),
    },

    ...(additional || []),
  ].filter(excludeNullable)

  if (roomAPI.renderMessageMenuItems)
    return roomAPI.renderMessageMenuItems({
      message: props.message,
      defaultItems: a,
    })

  return a
}
