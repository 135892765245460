import { useApolloClient } from "@apollo/client";
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  PrivateRoomPageBoardItemDocument,
  PrivateRoomPageBoardItemQuery,
  PrivateRoomPageBoardItemQueryVariables,
  useActivePrivateRoomPageBoardItemMutation,
} from "lib/graphql/operations"
import React from "react"

export function useGoToPrivateRoom(props: { userID: string }) {
  const r = useActiveResources()
  const api = React.useContext(ItemsListContext).indexedItemsApi

  const client = useApolloClient()

  const [mutate] = useActivePrivateRoomPageBoardItemMutation()

  const goToRoom = React.useCallback(async () => {
    const item = await (async () => {
      const item = await client.query<
        PrivateRoomPageBoardItemQuery,
        PrivateRoomPageBoardItemQueryVariables
      >({
        fetchPolicy: "cache-first",
        query: PrivateRoomPageBoardItemDocument,
        variables: {
          workspaceID: r.workspaceID,
          peerUserID: props.userID,
        },
      })

      const readyItem = item.data?.privateRoomPageBoardItem

      if (readyItem) return readyItem

      return mutate({
        variables: { peerUserID: props.userID, workspaceID: r.workspaceID },

        update(cache, { data }) {
          const item = data?.activePrivateRoomPageBoardItem

          if (!item) return

          cache.writeQuery<
            PrivateRoomPageBoardItemQuery,
            PrivateRoomPageBoardItemQueryVariables
          >({
            query: PrivateRoomPageBoardItemDocument,
            variables: {
              workspaceID: r.workspaceID,
              peerUserID: props.userID,
            },
            data: {
              __typename: "Query",
              privateRoomPageBoardItem: item,
            },
          })

          // console.log(
          //   cache.readQuery<
          //     PrivateRoomPageBoardItemQuery,
          //     PrivateRoomPageBoardItemQueryVariables
          //   >({
          //     query: PrivateRoomPageBoardItemDocument,
          //     variables: {
          //       workspaceID: r.workspaceID,
          //       peerUserID: props.userID,
          //     },
          //   })
          // )
        },
      }).then((a) => a.data?.activePrivateRoomPageBoardItem || null)
    })()

    if (!item) return

    api.selected.set(item)
  }, [
    // cachedItem,
    mutate,
    props.userID,
    api.selected.set,
    r.workspaceID,
    client,
  ])

  return useMemoAPI({ goToRoom })
}
