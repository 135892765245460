import { useApolloClient } from "@apollo/client";
import {
  CartFragment,
  useAddOneUnitToCartMutation,
} from "lib/graphql/operations"
import React from "react"
import { getUpdateCartQueryFn } from "../lib/getUpdateCartQueryFn"
import { updateCart } from "../lib/updateCart"

export function useAddMoreOne({
  cartID,
  cart,
  workspaceID,
}: {
  cart: CartFragment | null | undefined
  cartID: string
  workspaceID: string
}) {
  const [addOneMutation, { loading: addingOneUnit }] =
    useAddOneUnitToCartMutation()
  const client = useApolloClient()

  const addMoreOneApi = React.useMemo(() => {
    return {
      async action({ articleID = "" }: { articleID: string }) {
        if (!cartID) return
        const updated = await updateCart(
          {
            cart,
            workspaceID,
            articleID,
            updateItemFn: (item) => ({
              ...item,
              quantity: item.quantity + 1,
            }),
          },
          client
        )

        if (!updated) return

        const result = await addOneMutation({
          variables: { articleID, workspaceID, cartID },
          update: getUpdateCartQueryFn({
            cartID,
            workspaceID,
            getCart: (result) => {
              return result.data?.addOneUnitToCart || null
            },
          }),
          optimisticResponse: {
            __typename: "Mutation",
            addOneUnitToCart: updated,
          },
        })

        return result
        // return changeAPI.addOneUnit({ articleID })
      },
      loading: addingOneUnit,
    }
  }, [cart, addOneMutation, addingOneUnit, workspaceID, client, cartID])

  return addMoreOneApi
}
