import {
  UploadDocuments,
  UploadDocumentsProps,
} from "components/UploadDocuments/UploadDocuments"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { TwoPagesAPI } from "../../../../../../../../TwoPages/contexts/TwoPagesContext"

export function useOpenUploadUI(props: {
  twoPagesAPI?: TwoPagesAPI
  UploadDocumentsProps?: Omit<UploadDocumentsProps, "onClose">
}) {
  const openUploadPage = React.useCallback(
    (args: { UploadDocumentsProps: typeof props.UploadDocumentsProps }) => {
      const UploadDocumentsProps =
        args.UploadDocumentsProps || props.UploadDocumentsProps

      props.twoPagesAPI?.openSecondPage({
        preferentialLayout: { id: "one-visible-view" },
        Page: function UploadPage(args) {
          return (
            <UploadDocuments
              onClose={args.onClose}
              {...(UploadDocumentsProps || {})}
            ></UploadDocuments>
          )
        },
      })
    },
    [props.twoPagesAPI]
  )

  return useMemoAPI({ openUploadPage })
}
