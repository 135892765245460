import { makeStyles, Icon } from "@material-ui/core"
import clsx from "clsx"
import Indentation from "../../../Indentation/Indentation"
import React from "react"
import { IconProps } from "components/Icon/Icon"

export default function FieldsetIndentation(props: FieldsetIndentationProps) {
  const c = useStyles()

  if (!props.children) return null

  return (
    <Indentation
      {...props}
      {...{
        left: props.icon ? <Icon className={c.icon}>{props.icon}</Icon> : null,
        leftPosition: "top",
      }}
      className={clsx(c.root, "fieldset-indentation", props.className)}
    ></Indentation>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      // paddingRight: [theme.spacing(2) + "px", "!important"].join(" "),
    },
    icon: {
      opacity: 0.7,
      fontSize: 24,
    },
  }
})
export type FieldsetIndentationProps = Omit<
  React.ComponentProps<typeof Indentation>,
  "icon"
> & {
  icon?: IconProps["name"] | null
  title?: string | null | React.ReactNode
}
