import TemplatePage from "components/PageBoard/components/TemplatePage/TemplatePage"
import _ from "lodash"
import React from "react"
import { PageBoardItemContext } from "../PageBoardItemContext"
import { TwoPagesAPIInputFn } from "../../../../../TemplatePage/components/TemplatePageProvider/TemplatePageProvider"

export const ItemTemplate = {
  ...TemplatePage,

  Provider: function ItemTemplateProvider(
    props: Omit<
      React.ComponentProps<typeof TemplatePage.Provider>,
      "twoPagesApiMandatoryLayout"
    >
  ) {
    const mandatoryLayout =
      React.useContext(PageBoardItemContext).twoPagesApiMandatoryLayout

    const TwoPagesAPIProps = React.useCallback<TwoPagesAPIInputFn>(
      (...args) => {
        const additional = mandatoryLayout ? { mandatoryLayout } : {}

        if (typeof props.TwoPagesAPIProps === "function") {
          return {
            ...props.TwoPagesAPIProps(...args),
            ...additional,
          }
        }

        return {
          ...props.TwoPagesAPIProps,
          ...additional,
        }
      },
      [props.TwoPagesAPIProps, mandatoryLayout]
    )

    return (
      <TemplatePage.Provider
        {..._.omit(props, "children")}
        TwoPagesAPIProps={TwoPagesAPIProps}
      >
        {props.children}
      </TemplatePage.Provider>
    )
  },
}
