import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { RoomContext } from "components/Room/contexts/room"
import { MessagesRendered } from "components/Room/types"
import UseEffect from "components/UseEffect/UseEffect"
import React from "react"
import { ListRange, Virtuoso, VirtuosoHandle } from "react-virtuoso"

export default React.forwardRef(MessagesList)

function MessagesList(
  props: {
    Message: React.FC<{ index: number }>
    onItemsRendered?: (props: MessagesRendered) => void

    overscan?: number
    initialTopMostItemIndex?: number
    totalCount: number
    setIsScrolling?: (isScrolling: boolean) => void
  },
  externalRef: React.Ref<MessagesListHandle | null>
) {
  const c = useStyles()

  const api = React.useContext(RoomContext)
  const totalCount = api.messagesTotalCount

  const [rendered, setRendered] = React.useState(false)

  const itemContent = React.useCallback(
    (index: number) => {
      const component = (
        <>
          <UseEffect
            deps={[]}
            effect={() => {
              if (!rendered) setRendered(true)
            }}
          ></UseEffect>
          <props.Message index={index}></props.Message>
        </>
      )

      return component

      // return (
      //   <RoomContext.Consumer>
      //     {(roomAPI) => {
      //       // if (!roomAPI.renderMessage) return defaultComponent

      //       // if (!roomAPI.messagesTotalCount) return defaultComponent

      //       // return roomAPI.renderMessage({
      //       //   index,
      //       //   defaultComponent,
      //       //   isLastMessage: index === roomAPI.messagesTotalCount - 1,
      //       // })
      //       return
      //     }}
      //   </RoomContext.Consumer>
      // )
    },
    [props.Message]
  )

  const overscan = props.overscan || 10

  const listRef = React.useRef<VirtuosoHandle>(null)

  React.useImperativeHandle(
    externalRef,
    () =>
      getMessageListHandle({
        virtuoso: listRef.current,
        totalCount: totalCount,
      }),
    [totalCount]
  )

  const onItemsRendered = React.useCallback(
    (p: ListRange) => {
      if (!totalCount) return

      props?.onItemsRendered?.call(undefined, {
        overscanStartIndex: Math.max(0, p.startIndex - overscan),
        overscanStopIndex: Math.min(p.endIndex + overscan, totalCount),
        visibleStartIndex: Math.max(0, p.startIndex),
        visibleStopIndex: Math.min(p.endIndex, totalCount),
      })
    },
    [props.onItemsRendered, overscan, totalCount]
  )

  const listProps = {
    className: clsx("message-list", c.root),
    totalCount: totalCount,
    isScrolling: props.setIsScrolling,
    initialTopMostItemIndex: (() => {
      if (typeof props.initialTopMostItemIndex === "number")
        return props.initialTopMostItemIndex
      return totalCount
    })(),
    itemContent: itemContent,
    overscan: { main: overscan, reverse: overscan },
    followOutput: "smooth",
    defaultItemHeight: 80,
    rangeChanged: onItemsRendered,
    ref: listRef,
    // logLevel: 0
  }

  // React.useEffect(()=>{
  //   // if(!listProps.totalCount || !!rendered) return

  //   // setTimeout(()=>{

  //   // setKey(Math.random())

  //   // }, 0)

  //   // // setInterval(()=>{
  //   // //   setKey(Math.random())
  //   // //   console.log(key)
  //   // // }, 1000)
  // }, [!!listProps.totalCount])
  //   console.log(listProps.key)

  // console.log(listProps)
  return (
    <div className={listProps.className}>
      <Virtuoso
        // className={clsx("message-list", c.root)}
        // totalCount={totalCount}
        // isScrolling={props.setIsScrolling}
        // initialTopMostItemIndex={(() => {
        //   if (typeof props.initialTopMostItemIndex === "number")
        //     return props.initialTopMostItemIndex
        //   return totalCount
        // })()}
        // itemContent={itemContent}
        // key={key + ""}
        // overscan={{ main: overscan, reverse: overscan }}
        // followOutput="smooth"
        // defaultItemHeight={80}
        // rangeChanged={onItemsRendered}
        // ref={listRef}
        // logLevel={0}
        {...listProps}
        className=""
        style={{ height: "100%" }}
      ></Virtuoso>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
    },
  }
})

export type MessagesListHandle = ReturnType<typeof getMessageListHandle>

function getMessageListHandle(props: {
  virtuoso: VirtuosoHandle | null
  totalCount?: number | null
}) {
  return {
    scrollToItem(p: { index: number }) {
      return props.virtuoso?.scrollToIndex({
        index: p.index,
        behavior: "smooth",
        align: "center",
      })
    },

    scrollToBottom() {
      if (typeof totalCount !== "number") return

      props.virtuoso?.scrollToIndex({
        index: totalCount,

        behavior: "smooth",
      })
    },
  }
}
