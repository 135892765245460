import React from "react"
import {
  NewPageBoardItemFragment,
  NewPageBoardItem_KanbanCardPageBoardItem_Fragment,
} from "lib/graphql/operations"
import { useI18n } from "contexts/i18nContext/i18nContext"
import arePropsEqual from "util/arePropsEqual"
import { ListEntryProps } from "../../types"
import KanbanCardItem from "./KanbanCardItem"

export default function KanbanCardListItem(props: ListEntryProps) {
  if (props.entry?.__typename !== "KanbanCardPageBoardItem") return null

  return (
    <MemoizedItem
      entry={props.entry}
      className={props.className}
      style={props.style}
    />
  )
}

const MemoizedItem = React.memo(
  Item,
  arePropsEqual([
    // (a) => a.entry?.id,
    (a) => a.entry?.lastUpdate,
    (a) => a.entry?.name,
    (a) => a.entry?.additionalText,
    (a) => a.entry?.card?.title,
    (a) => a.entry?.card?.description,
  ])
)
function Item(props: {
  entry: NewPageBoardItem_KanbanCardPageBoardItem_Fragment
  className?: string
  style?: React.CSSProperties
}) {
  const t = useI18n()
  const title =
    props.entry.card?.title || props.entry.name || t["taskWithoutTitle"]

  const text = props.entry.card?.description || props.entry.additionalText || ""

  return (
    <KanbanCardItem
      entry={props.entry}
      className={props.className}
      style={props.style}
      title={title}
      text={text}
    ></KanbanCardItem>
  )
}
