import { RelatedDocumentsChips } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/RelatedDocumentsChips"
import React from "react"
import { FormCommonsApi, FormCommonsContext } from "./FormCommonsContext"

export function FormCommonsRelatedDocumentsChips(props: {
  openAllDocumentsMiniPageArgs?: Parameters<
    FormCommonsApi["openAllDocumentsMiniPage"]
  >[0]

  openUploadPageArgs?: Parameters<FormCommonsApi["openUploadPage"]>[0]
}) {
  const formCommons = React.useContext(FormCommonsContext)

  return (
    <RelatedDocumentsChips
      onClickChip={(item) => {
        if (item.__typename !== "DocumentPageBoardItem") return
        formCommons.openDocumentWithItemParams(item)
      }}
      onClickOpenAllDocuments={() => {
        if (!props.openAllDocumentsMiniPageArgs) return
        return formCommons.openAllDocumentsMiniPage(
          props.openAllDocumentsMiniPageArgs
        )
      }}
      onClickUploadButton={() => {
        if (!props.openUploadPageArgs) return
        formCommons.openUploadPage(props.openUploadPageArgs)
      }}
    ></RelatedDocumentsChips>
  )
}
