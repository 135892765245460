import React from "react"
import TextField from "components/TextField/TextField"
import useTrueFalse from "hooks/useTrueFalse"
import KeyListeners from "components/KeyListeners/KeyListeners"

export default function TextFieldWithControlledFocus(
  props: React.ComponentProps<typeof TextField> & {
    limitOfCharsWhenIdle?: number
  }
) {
  const focused = useTrueFalse(false)

  const inputRef = React.useRef<HTMLInputElement>(null)

  const blur = React.useCallback((): void => {
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }, [])

  KeyListeners.useEscapeListener(blur, { enabled: focused.isTrue })

  return (
    <TextField
      {...props}
      {...(() => {
        const limit = props.limitOfCharsWhenIdle

        if (typeof limit === "number" && focused.isFalse) {
          const value = props.value

          if (typeof value !== "string") return

          const passedLimit = value.length > limit

          if (passedLimit) return { value: value.slice(0, limit) + "..." }

          return { value: props.value }
        }

        return {}
      })()}
      variant="standard"
      InputProps={{
        disableUnderline: focused.isFalse,
        ...(props?.InputProps || {}),
      }}
      inputProps={{ ...(props?.inputProps || {}), ref: inputRef }}
      onBlur={(e) => {
        props.onBlur?.call(undefined, e)
        focused.setFalse()
      }}
      onFocus={focused.setTrue}
    ></TextField>
  )
}
