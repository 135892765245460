import SecondPage from "./SecondPage/SecondPage"
import CollectionsInputPageDefault from "./components/CollectionsInputPageDefault/CollectionsInputPageDefault"
import CollectionsInputPageProvider from "./components/CollectionsInputPageProvider/CollectionsInputPageProvider"
import AddNewPartner from "./components/AddNewPartner/AddNewPartner"

const CollectionsInputPage = {
  Provider: CollectionsInputPageProvider,
  Default: CollectionsInputPageDefault,
  AddNewPartner,
}

export default CollectionsInputPage
