import _ from "lodash"
import useAppRouter from "hooks/useAppRouter"
import usePageParams from "./usePageParams"
import searchParamsString from "util/searchParamsString"
import { join } from "path"
import { useCallback } from "react"

export default function useGetLink({ baseURL = "" } = {}) {
  const { match } = useAppRouter()
  baseURL = baseURL || match.url

  const {
    searchedTerm: currentSearchedTerm,
    types: currentTypes,
    relatedCollectionsIDs: currentRelatedCollectionsIDs,
  } = usePageParams()

  return useCallback(
    function getLink({
      //if no searchedTerm is explicit defined, keep the current one (https://trello.com/c/mHFDCAGU/419-partner-search-keep-search)
      searchedTerm,
      types = currentTypes,
      relatedCollectionsIDs,
      collectionID,
      partnerID,
      contactID,
      documentID,
      tabID,
      modal,
      modalPartnerID,
    } = {}) {
      const secondPart = (() => {
        if (contactID) return "/contact/" + contactID
        if (partnerID) return "/partner/" + partnerID
        return collectionID
      })()

      const complement = (() => {
        if (documentID) return "/document/" + documentID
        return ""
      })()

      const link =
        join(...[baseURL, secondPart, tabID, complement].filter(Boolean)) +
        searchParamsString(
          _.pickBy(
            {
              searchedTerm,
              types,
              modal,
              modalPartnerID,
              relatedCollectionsIDs,
            },
            (v) => !_.isEmpty(v)
          )
        )

      return link

      // console.log({ matchUrl: match.url, secondPart, tabID, complement })
    },
    [
      baseURL,
      currentSearchedTerm,
      currentRelatedCollectionsIDs,
      JSON.stringify(currentTypes),
    ]
  )
}
