import moment from "moment"
import clsx from "clsx"
import React from "react"
import { Variant } from "@material-ui/core/styles/createTypography"
import { IconButton, makeStyles, TextFieldProps } from "@material-ui/core"
import { Icon } from "components/Icon/Icon"
import { FieldInputProps } from "formik"
import { PageTextField as TextField } from "components/Page/Page"
import useOpenClose from "hooks/useOpenClose"
import useComponentSize from "@rehooks/component-size"
import MonthCalendar from "../MonthCalendar/MonthCalendar"
import { MonthCalendarProviderProps } from "components/MonthCalendar/types"
import StopPropagation from "components/StopPropagation/StopPropagation"
import getMonth from "components/MonthCalendar/lib/getMonth"
import { KeyListener } from "../KeyListeners/hooks/useKeyListenersAPI"
import KeyListeners from "components/KeyListeners/KeyListeners"

export default function CalendarDateTimeInput(
  props: {
    textFieldVariant?: Variant
    className?: string
    format?: string
    classes?: { calendar?: string }
    TextFieldProps?: React.ComponentProps<typeof TextField>
    hideClearButton?: boolean
  } & FieldInputProps<moment.MomentInput>
) {
  const oc = useOpenClose()

  KeyListeners.useEscapeListener(oc.close, { enabled: oc.isOpen })

  const c = useStyles()

  const isEmpty = !props.value || !moment(props.value).isValid()

  const value = (() => {
    if (!props.value || !moment(props.value).isValid()) return "-"

    const format = (() => {
      if (props.format) return props.format

      const thisYear = new Date().getFullYear()
      const dateYear = moment(props.value).year()

      const showYear = thisYear !== dateYear

      return ["dddd, Do MMM", showYear ? " YYYY" : ""]
        .filter((a) => !!a)
        .join("")
    })()

    return moment(props.value).format(format)
  })()

  const ref = React.useRef<HTMLDivElement | null>(null)
  const height = useComponentSize(ref)?.height

  const onChangeSelectedDay = React.useCallback(
    (() => {
      const onChange: MonthCalendarProviderProps["onChangeSelectedDay"] =
        function onChange(day) {
          let d = day

          props.onChange({ target: { name: props.name, value: d.selectedDay } })
        }

      return onChange
    })(),
    [props.onChange, props.name]
  )
  const onChangeMonth = React.useCallback(
    (() => {
      const onChange: MonthCalendarProviderProps["onChangeMonth"] =
        function onChange(a) {
          const { month, selectedDay } = a

          if (!month || !selectedDay) return

          if (
            JSON.stringify(getMonth({ month })) ===
            JSON.stringify(getMonth({ day: selectedDay }))
          )
            return

          const d = moment()
            .month(month.index)
            .year(month.year)
            .startOf("month")
            .startOf("day")

          props.onChange({ target: { name: props.name, value: d } })
        }

      return onChange
    })(),
    [props.onChange, props.name]
  )
  const endAdornment = (() => {
    return (
      <StopPropagation>
        <div style={{ display: "flex", alignItems: "center" }}>
          {(() => {
            if (props.hideClearButton) return null
            if (isEmpty) return null

            const clear = () =>
              props.onChange({ target: { name: props.name, value: null } })

            return (
              <IconButton size="small" onClick={clear}>
                <Icon className={c.iconControl} name="delete_outline"></Icon>
              </IconButton>
            )
          })()}
          {(() => {
            if (!oc.isOpen) return null
            return (
              <MonthCalendar.Context.Consumer>
                {(api) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 8,
                      }}
                    >
                      <IconButton
                        size="small"
                        className={c.iconButton}
                        onClick={() => api.addMonth(-1)}
                      >
                        <Icon
                          className={c.iconControl}
                          name="chevron_left"
                        ></Icon>
                      </IconButton>

                      <IconButton
                        size="small"
                        className={c.iconButton}
                        onClick={() => api.addMonth(+1)}
                      >
                        <Icon
                          className={c.iconControl}
                          name="chevron_right"
                        ></Icon>
                      </IconButton>
                    </div>
                  )
                }}
              </MonthCalendar.Context.Consumer>
            )
          })()}
          {(() => {
            // if (props.hideClearButton) return null
            // if (!props.value) return null

            if (!oc.isOpen) return null

            return (
              <IconButton size="small" onClick={() => oc.close()}>
                <Icon className={c.iconControl} name="close"></Icon>
              </IconButton>
            )
          })()}
        </div>
      </StopPropagation>
    )
  })()

  return (
    <MonthCalendar.Provider
      selectedDay={props.value}
      onChangeSelectedDay={onChangeSelectedDay}
      onChangeMonth={onChangeMonth}
    >
      <div className={clsx("calendar-date-time-input", props.className)}>
        <TextField
          typographyVariant={props.textFieldVariant}
          value={value}
          onClick={oc.toggle}
          {...(props.TextFieldProps || {})}
          InputProps={{
            ...(props.TextFieldProps?.InputProps || {}),
            readOnly: true,
            endAdornment,
          }}
        ></TextField>

        <div
          className={clsx("date-input", props.classes?.calendar)}
          style={{
            height: oc.isOpen ? height + 12 : 0,
            overflow: "hidden",
            transition: "height 300ms",
            // style={{}}
          }}
        >
          <div
            ref={ref}
            className="date-input-content"
            style={{
              paddingTop: 8,
            }}
          >
            <MonthCalendar.WithSelection></MonthCalendar.WithSelection>
          </div>
        </div>
      </div>
    </MonthCalendar.Provider>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    iconButton: {
      padding: 0,
    },
    iconControl: {
      fontSize: "19px !important",
    },
  }
})
