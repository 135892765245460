import React, { useContext } from "react"
import _ from "lodash"
import { Icon, IconButton, makeStyles } from "@material-ui/core"
import CollectionDashboardContext from "../../../../contexts/CollectionDashboardContext"
import documentsViewsEnums from "components/CollectionDocumentsDashboard/constants/documentsViewsEnums"

export default function DocumentsViewsButtons() {
  const { setDocumentView, documentView } = useContext(
    CollectionDashboardContext
  )
  const isViewSelected = (view) => view === documentView
  const buttonColor = (view) => (isViewSelected(view) ? "primary" : "default")

  return (
    <div className="documents-views-buttons">
      <IconButton
        size="small"
        edge="start"
        onClick={setDocumentView.bind(null, documentsViewsEnums.LIST)}
      >
        <Icon color={buttonColor(documentsViewsEnums.LIST)} fontSize="small">
          reorder
        </Icon>
      </IconButton>
      <IconButton
        size="small"
        onClick={setDocumentView.bind(null, documentsViewsEnums.GRID)}
      >
        <Icon color={buttonColor(documentsViewsEnums.GRID)} fontSize="small">
          view_module
        </Icon>
      </IconButton>
    </div>
  )
}
