import {
  CollectionRoomDocument,
  CollectionRoomQuery,
  CollectionRoomQueryVariables,
  CreateCollectionRoomDocument,
  CreateCollectionRoomMutation,
  CreateCollectionRoomMutationVariables,
  RoomFragment,
} from "lib/graphql/operations"
import RoomAPI from "../RoomAPI/RoomAPI"
import { CollectionRoomIdentifier } from "types/type"
import { Icon } from "@material-ui/core"
import CollectionDocumentsDashboard from "components/CollectionDocumentsDashboard"
import { RoomContext } from "components/Room/contexts/room"
import basicTabs from "components/Room/components/tabs/basicTabs"

export default class CollectionRoomAPI extends RoomAPI<CollectionRoomIdentifier> {
  allowAutomaticCreation = false
  allowMembersToEdit = true

  static tabs = {
    ...basicTabs,
    COLLECTION: {
      label: <Icon>view_comfy</Icon>,
      component: (
        <RoomContext.Consumer>
          {({ room }) => {
            const collectionID = room?.identifier?.identifierID

            if (!collectionID) return null

            return (
              <CollectionDocumentsDashboard.Provider
                collectionID={collectionID}
              >
                <CollectionDocumentsDashboard.Root></CollectionDocumentsDashboard.Root>
              </CollectionDocumentsDashboard.Provider>
            )
          }}
        </RoomContext.Consumer>
      ),
    },
  }

  getVariables(params: CollectionRoomIdentifier) {
    const variables = {
      workspaceID: this.workspaceID,
      collectionID: params.collectionID,
    }

    return variables
  }

  async getRoom(identifier: CollectionRoomIdentifier) {
    const query = await this.client.query<
      CollectionRoomQuery,
      CollectionRoomQueryVariables
    >({
      query: CollectionRoomDocument,
      variables: {
        workspaceID: this.workspaceID,
        collectionID: identifier.collectionID,
      },
      fetchPolicy: "cache-first",
    })

    return query.data?.collectionRoom
  }

  async createRoom(identifier: CollectionRoomIdentifier) {
    const room = await this.client
      .mutate<
        CreateCollectionRoomMutation,
        CreateCollectionRoomMutationVariables
      >({
        mutation: CreateCollectionRoomDocument,
        variables: {
          collectionID: identifier.collectionID,
          workspaceID: this.workspaceID,
        },
      })
      .then((a) => a.data?.createCollectionRoom)

    return room
  }
}
