import PaperList from "components/PaperList"
import { makeStyles } from "@material-ui/core"
import { OrdinaryRoomIdentifier } from "types/type"
import React, { useContext } from "react"
import ChatContext from "components/Chat/contexts/ChatContext"
import RoomAvatar from "components/Chat/components/RoomAvatar/RoomAvatar"
import { Typography } from "@material-ui/core"
import formatMessageDate from "pages/util/formatMessageDate"
import { roomTypesColors } from "lib/constants"
import ShortMessageLine from "components/ShortMessageLine/ShortMessageLine"
import MuteStatus from "components/MuteStatus/MuteStatus"
import { RoomFragment } from "lib/graphql/operations"

export default function ChatListItem(props: { entry?: RoomFragment }) {
  const { entry: room } = props
  const c = useStyles({ isReadOnly: !!room?.isReadOnly })

  const { roomIdentifier, setRoomIdentifier } = useContext(ChatContext)
  const { RootContainer } = useContext(PaperList.Context)
  const activeRoomID = (roomIdentifier as OrdinaryRoomIdentifier)?.roomID

  if (!room) return null

  const isSelected = activeRoomID === room?.id

  return (
    <PaperList.ListItem
      className={c.root}
      isSelected={isSelected}
      selectedColor={room?.type ? roomTypesColors[room?.type] : ""}
      classes={{ listItemLeft: c.listItemLeft, listItemRight: c.listItemRight }}
      left={
        <React.Fragment>
          <RoomAvatar room={room}></RoomAvatar>
          <div style={{ marginLeft: 8 }}></div>
          <PaperList.CenteredListItemText
            primary={
              <React.Fragment>
                <RootContainer.ClippedText
                  maxPercentageMap={{
                    small: "50%",
                  }}
                >
                  <>
                    {room.name}
                    {room.context ? (
                      <Typography
                        color="textSecondary"
                        style={{ display: "inline" }}
                      >
                        {" "}
                        - {room.context}
                      </Typography>
                    ) : null}
                  </>
                </RootContainer.ClippedText>

                <MuteStatus
                  roomID={room?.id}
                  style={{
                    fontSize: 17,
                    marginLeft: 4,
                    marginTop: 3,
                  }}
                ></MuteStatus>
                {/* </span> */}
              </React.Fragment>
            }
            secondary={
              <>
                {room.lastMessage && (
                  <ShortMessageLine
                    message={room.lastMessage}
                    RootContainer={RootContainer}
                  ></ShortMessageLine>
                )}
              </>
            }
          ></PaperList.CenteredListItemText>
        </React.Fragment>
      }
      right={
        room.lastMessage?.sentAt ? (
          <PaperList.ListItemText
            secondary={formatMessageDate(room.lastMessage?.sentAt)}
          ></PaperList.ListItemText>
        ) : null
      }
      onClick={() => setRoomIdentifier(room?.id ? { roomID: room?.id } : null)}
    ></PaperList.ListItem>
  )
}

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      opacity: (props: { isReadOnly: boolean }) =>
        props.isReadOnly ? 0.3 : "inherit",
      "& .share-icon": {
        display: "none",
      },

      "&:hover .share-icon": {
        display: "block",
      },
    },

    left: {
      display: "flex",
      alignItems: "center",
      "&>*:first-child": {
        marginRight: theme.spacing(1),
      },
    },
    right: {
      display: "flex",
      justifyContent: "flex-end",
      flex: 1,
    },
    listItemLeft: {
      flex: "3 !important",
    },

    listItemRight: {
      flex: 1,
      textAlign: "end",
    },
  }
})
