export type Month = {
  index: number
  year: number
}

export enum WeekDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export type MonthCalendarProviderProps = {
  month?: Month | null
  selectedDay?: moment.MomentInput | undefined | null
  onChangeSelectedDay?: (props: {
    month: Month | null
    selectedDay: moment.Moment | null
  }) => any
  onChangeMonth?: (props: {
    month: Month | null
    selectedDay: moment.Moment | null
  }) => any
}
