import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import Avatar from "components/Avatar/Avatar"
import ClippedText from "components/ClippedText/ClippedText"
import { Icon } from "components/Icon/Icon"
import useTypesOptions from "components/PageBoard/hooks/useTypesOptions"
import PaperList from "components/PaperList"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { PartnerPageBoardItemFragment } from "lib/graphql/operations"
import React from "react"
import EntryRight from "../EntryRight/EntryRight"
import ListItem from "../ListItem/ListItem"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import { Item } from "components/List/Item"
import { ItemGridCell } from "../ItemGridCell/ItemGridCell"

export type PartnerItemProps = {
  entry: PartnerPageBoardItemFragment
  className?: string
  style?: React.CSSProperties
}

export function PartnerItem(props: PartnerItemProps) {
  const c = useStyles()
  const t = useI18n()

  // const { RootContainer } = React.useContext(PaperList.Context)

  // const rootContainer = React.useContext(RootContainer.Context)
  const api = React.useContext(ItemsListContext)

  const rootContainer = api.rootContainer

  const maxSize = rootContainer.width < 700 ? 150 : 500

  const options = useTypesOptions()
  const title = props.entry.name || t["vendorWithoutTitle"]

  const isReadOnly = props.entry.partner?.isReadOnly
  if (!props.entry) return null

  if (api.variant === "grid") {
    return (
      <ItemGridCell
        title={title}
        descriptionText={props.entry.additionalText || ""}
        entry={props.entry}
        timestamp={props.entry.lastUpdate}
      ></ItemGridCell>
    )
  }

  return (
    <ListItem
      className={clsx(c.root, "partner-list-item", props.className)}
      style={{
        ...props.style,
        ...(isReadOnly ? { opacity: 0.3 } : {}),
      }}
      entry={props.entry}
      right={
        <EntryRight
          // iconButton={{ icon: "delete_outlined" }}
          {...(isReadOnly ? { iconButton: { icon: "delete" } } : {})}
          lastUpdate={props.entry.lastUpdate}
        ></EntryRight>
      }
      left={
        <React.Fragment>
          {(() => {
            const defaultComponent = (
              <Avatar
                backgroundColor={options[props.entry.type]?.color}
                content={(() => {
                  const icon = options[props.entry.type]?.icon || ""

                  if (!icon) return null
                  return <Icon name={icon}></Icon>
                })()}
              />
            )

            return defaultComponent
          })()}
          <div style={{ marginLeft: 8 }}></div>
          <PaperList.ListItemText
            primary={(() => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body1" color="textPrimary">
                    <ClippedText maxWidth={maxSize}>{title}</ClippedText>
                  </Typography>
                </div>
              )
            })()}
            secondary={(() => {
              const desc = props.entry.additionalText || ""

              if (!desc) return null

              const defaultComponent = (
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  <ClippedText maxWidth={maxSize}>{desc}</ClippedText>
                </Typography>
              )

              return defaultComponent
            })()}
          ></PaperList.ListItemText>
        </React.Fragment>
      }
    ></ListItem>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {},
  }
})
