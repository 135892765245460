import React from "react"
import { KanbanActivityFragment } from "lib/graphql/operations"
import { useI18n } from "contexts/i18nContext/i18nContext"
import ImageAttachment from "components/ImageAttachment/ImageAttachment"
import { makeStyles } from "@material-ui/core"

export default function AddImage(props: {
  cardLink: React.ReactNode
  activity: KanbanActivityFragment
}) {
  const t = useI18n()

  const c = useStyles()
  if (props.activity.__typename !== "KanbanAddImageActivity") return null

  return (
    <>
      <span>{t["iveAddedAnImageToTheTask"]} </span>
      {props.cardLink}

      <ImageAttachment.Provider src={props.activity.image?.src || ""}>
        <ImageAttachment.Root
          classes={{ image: c.image }}
        ></ImageAttachment.Root>
      </ImageAttachment.Provider>
    </>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    image: {
      width: "100%",
      height: "auto",
      // maxWidth: 500,
    },
  }
})
