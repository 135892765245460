import moment from "moment"
import React from "react"
import { UserFragment } from "lib/graphql/operations"
import isValidTimestamp from "lib/isValidTimestamp"
import { DocumentTimelog } from "../../DocumentTimelog/DocumentTimelog"

export default function renderTimeLog(props: {
  label?: string
  dateTime: moment.MomentInput
  person?: UserFragment | null
  style?: React.CSSProperties
}) {
  const dateTime = props.dateTime

  if (!isValidTimestamp(dateTime)) return null

  return (
    <DocumentTimelog
      label={props.label}
      dateTime={props.dateTime}
      person={props.person}
      style={props.style}
    ></DocumentTimelog>
  )
}
