import React from "react"
import { makeStyles } from "@material-ui/styles"
import { clipText } from "styles/globalStyles"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import { Typography } from "@material-ui/core"
import clsx from "clsx"

export default function Title({
  firstLine = "",
  secondLine = "",
  className = "",
}) {
  const { isMobile, isSmallMobile } = React.useContext(DeviceObserver.Context)
  const c = useStyles({ isMobile, isSmallMobile })

  return (
    <div className={clsx(className, c.title)}>
      {firstLine && (
        <Typography variant="h6" color="textPrimary" className={className}>
          {firstLine}
        </Typography>
      )}

      {secondLine && (
        <Typography variant="body2" color="textSecondary" className={className}>
          {secondLine}
        </Typography>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    title: {
      maxWidth: (props) =>
        (props.isSmallMobile && "150px") ||
        (props.isMobile && "300px") ||
        "none",

      "&>*": {
        ...clipText,
        width: "100%",
      },
    },
  }
})
