import { Button as MuiButton, ButtonProps, makeStyles } from "@material-ui/core"
import { Icon, IconProps } from "components/Icon/Icon"

export function OutlinedButtonWithIconAndTextCentered({
  icon,
  text,
  onClick,
  variant = "contained",
  color,
  ...other
}: ButtonProps & { icon: IconProps["name"]; text: string }) {
  const c = useStyles()

  return (
    <MuiButton variant={variant} onClick={onClick} color={color} {...other}>
      <Icon className={c.spacing} name={icon}></Icon>
      {text}
    </MuiButton>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    spacing: {
      marginRight: theme.spacing(1),
    },
  }
})
