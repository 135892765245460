import PageBoardContext from "components/PageBoard/contexts/PageBoardContext"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import usePartner from "hooks/usePartner"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
// import contextAndProviderFactory from "lib/contextAndProviderFactory"
import {
  DocumentQueryVariables,
  PartnerQueryVariables,
  useDocumentQuery,
} from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { PageBoardItemInput } from "../../types"
import { PageBoardItemContext } from "../PageBoardItemContext"

export type DocumentPageBoardItemProps = PageBoardItemInput & {
  initialParams?: { initialPage: "viewer" | "form" }
}

export function useDocumentPageBoardItem(props: DocumentPageBoardItemProps) {
  const r = useActiveResources()

  const entry = React.useContext(PageBoardItemContext).item

  const pageBoardApi = React.useContext(PageBoardContext)

  const initialPage = props.initialParams?.initialPage || "form"

  const workspaceID = r.workspaceID

  const variables: DocumentQueryVariables = {
    // documentID: props.entry?.document?.id || "",
    documentID: entry?.itemID || "",
    workspaceID,
  }

  const query = useDocumentQuery({
    variables,
    skip: !variables.workspaceID || !variables.documentID,
    // onError: props.onError,
    fetchPolicy: "cache-and-network",
  })

  const document = query.data?.document

  const partnerVariables = (() => {
    let variables: Pick<PartnerQueryVariables, "collectionID" | "type">

    variables = {
      collectionID: document?.partnerCollection?.id,
      type: document?.partnerCollection?.type,
    }

    return variables
  })()

  const { partner } = usePartner({
    collectionID: partnerVariables.collectionID || "",
    type: partnerVariables.type || "",
  })

  const openPartnerPage = React.useCallback(
    () =>
      pageBoardApi.openItemPage(PageBoardItemType.Partner, {
        partnerID: partner?.id || "",
      }),
    [pageBoardApi.openItemPage, partner]
  )

  return useMemoAPI({
    ...props,
    item: entry?.__typename === "DocumentPageBoardItem" ? entry : null,
    document: document || null,
    openPartnerPage,
    query,
    initialPage,
    partner: partner || null,
  })
}

const Factory = contextAndProviderFactory({
  hookApi: useDocumentPageBoardItem,
})

export const DocumentPageBoardItemContext = Factory.Context
export const DocumentPageBoardItemProvider = Factory.Provider
