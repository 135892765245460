import clsx from "clsx"
import Hook from "components/Hook/Hook"
import Shareable from "components/Shareable/Shareable"
import { UseShareableProps } from "components/Shareable/hooks/useShareable"
import SameOriginLinkButton, {
  SameOriginLinkButtonProps,
} from "./SameOriginLinkButton"

export default function ShareableLink(props: {
  className?: string
  UseShareableProps?: UseShareableProps
  shareableProps?: ReturnType<typeof Shareable.useShareable>
  SameOriginLinkButtonProps?: Partial<SameOriginLinkButtonProps>
}) {
  function render(params: {
    shareableProps: ReturnType<typeof Shareable.useShareable>
  }) {
    const { shareable, isLoading, workspaceURL, type, fetchingIsComplete } =
      params.shareableProps

    const basicProps = {
      ...(props.SameOriginLinkButtonProps || {}),
      className: clsx(
        props.className,
        props.SameOriginLinkButtonProps?.className
      ),
    }

    const notFoundComponent = (
      <SameOriginLinkButton
        description="nothing found"
        iconName="browser_not_supported"
        disabled
        {...basicProps}
      ></SameOriginLinkButton>
    )

    if (!shareable?.name) {
      if (isLoading)
        return (
          <SameOriginLinkButton
            description="loading"
            iconName="hourglass_empty"
            disabled
            {...basicProps}
          ></SameOriginLinkButton>
        )

      if (fetchingIsComplete) return notFoundComponent
    }

    if (!type || !Shareable.icons[type]) return notFoundComponent

    return (
      <SameOriginLinkButton
        description={shareable?.name || ""}
        iconName={Shareable.icons[type] as any}
        url={workspaceURL}
        {...basicProps}
      ></SameOriginLinkButton>
    )
  }

  if (props.shareableProps)
    return render({ shareableProps: props.shareableProps })

  return (
    <Hook hook={Shareable.useShareable} hookProps={props.UseShareableProps}>
      {(a) => {
        return render({ shareableProps: a })
      }}
    </Hook>
  )
}
