import Room from "components/Room/Room"
import React from "react"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import { useSharePageBoardItemsInTheRoomMutation } from "lib/graphql/operations"
import { PageBoardItemParamsInput } from "lib/graphql/types"
import updateRoomMessageList from "lib/updateRoomMessageList"

export function useShareItem() {
  const [mutate, { loading }] = useSharePageBoardItemsInTheRoomMutation()

  const workspaceID = useActiveResources().workspaceID

  const roomApi = React.useContext(Room.Context)

  return useMemoAPI({
    loading,
    share: React.useCallback(
      function share(props: { params: PageBoardItemParamsInput[] }) {
        const roomID = roomApi.room?.id
        if (!roomID) return
        if (!workspaceID) return
        if (!props.params?.length) return

        return mutate({
          variables: {
            roomID,
            workspaceID,
            itemsParams: props.params,
          },
          update: function update(cache, mutationResult) {
            const a = mutationResult.data?.sharePageBoardItemsInTheRoom

            if (!a) return

            const { newListItems, totalItems } = a

            updateRoomMessageList(
              { totalItems, newListItems, roomID, workspaceID },
              cache
            )
          },
        })
      },
      [workspaceID, mutate, roomApi.room?.id]
    ),
  })
}
