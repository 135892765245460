import { makeStyles } from "@material-ui/styles"
import React from "react"
import TextInput from "../TextInput/TextInput"
import { Box, InputAdornment, IconButton, Icon } from "@material-ui/core"

function WebInput(props) {
  const c = useStyles({})
  return (
    <TextInput
      {...props}
      InputProps={{
        endAdornment: props.value && (
          <InputAdornment edge="end">
            <a href={"//" + props.value} target="__blank">
              <IconButton
                className={c.webInputIconButton}
                disabled={!props.value}
                edge="end"
              >
                <Icon>launch</Icon>
              </IconButton>
            </a>
          </InputAdornment>
        )
      }}
    ></TextInput>
  )
}

export default WebInput

const useStyles = makeStyles(theme => {
  return {
    webInput: {
      display: "flex",
      alignItems: "center",

      "& a": {
        color: "inherit"
      }
    },

    webInputIconButton: {
      marginLeft: theme.spacing(1)
    }
  }
})
