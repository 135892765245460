import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { LoadingLayer } from "components/Zoom/components/LoadingLayer"
import { MeetingArgs } from "components/Zoom/context/MeetingArgsContext"
import { MeetingStateContext } from "components/Zoom/useMeetingState"
import { StyledProps } from "types/type"
import { ZoomProvider } from "../ZoomProvider/ZoomProvider"
import { Video } from "components/Zoom/feature/video/TheVideo"
import { VideoAttach } from "components/Zoom/feature/video/VideoAttach"
import { VideoSingle } from "components/Zoom/feature/video/VideoSingle"
import { ZoomApiInput } from "../ZoomProvider/ZoomApiInputContext"

export function ZoomVideoMeeting(
  props: StyledProps & { meetingArgs?: Partial<MeetingArgs> } & ZoomApiInput
) {
  const c = useStyles()

  return (
    <ZoomProvider
      meetingArgs={props.meetingArgs}
      manageLeaving={props.manageLeaving}
    >
      <MeetingStateContext.Consumer>
        {(meetingState) => {
          return (
            <div
              className={clsx(c.root, "zoom-meeting-video", props.className)}
              style={props.style}
            >
              {(() => {
                const C = meetingState.isSupportGalleryView
                  ? meetingState.galleryViewWithAttach
                    ? VideoAttach
                    : Video
                  : VideoSingle

                return meetingState.loading ? (
                  <LoadingLayer content={meetingState.loadingText} />
                ) : (
                  <C></C>
                )
              })()}
            </div>
          )
        }}
      </MeetingStateContext.Consumer>
    </ZoomProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    "&>*": {
      height: "100%",
      overflow: "auto",
    },
  },
}))
