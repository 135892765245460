import React from "react"
import {
  Icon,
  makeStyles,
  Typography,
  IconButton,
  Paper,
} from "@material-ui/core"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import EnhancedPageModal from "components/EnhancedPageModal/EnhancedPageModal"
import ImagesCarousel from "../ImagesCarousel/ImagesCarousel"
import useComponentSize from "@rehooks/component-size"
import useOnChange from "hooks/useOnChange"
import Slider from "react-slick"
import useMemoAPI from "hooks/useMemoAPI"
import TextFieldWithControlledFocus from "../TextFieldWithControlledFocus/TextFieldWithControlledFocus"
import useForm from "hooks/useForm"
import useTrueFalse from "hooks/useTrueFalse"
import IndexTrack from "../IndexTrack/IndexTrack"
import clsx from "clsx"
// import IconButton from "components/IconButton/IconButton"
import Hook from "components/Hook/Hook"
import ToggleableMenu from "components/ToggleableMenu/ToggleableMenu"
import Shareable from "components/Shareable/Shareable"
import { ShareableType } from "types/type"
import DeleteImageModal from "../DeleteImageModal/DeleteImageModal"

export default function ImagesModal(props: {
  isOpen: boolean
  onClose: () => any
}) {
  const api = React.useContext(KanbanCardPageBoardItemContext)
  const c = useStyles()

  const carouselRef = React.useRef<HTMLDivElement>(null)
  // const activeIndexAPI = useActiveIndex()

  const showIndexTrack = useTrueFalse(false)
  const timeoutID = React.useRef<NodeJS.Timeout | undefined>(undefined)

  const size = useComponentSize(carouselRef)

  // console.log(api.images.modal.selected.index)

  return (
    <EnhancedPageModal
      isOpen={props.isOpen}
      variant="full-screen"
      onClose={props.onClose}
      EnhancedPageProps={{
        titleComponent: <Title></Title>,
        body: (
          <div className={c.body}>
            <IndexTrack
              show={showIndexTrack.isTrue}
              className="index-track"
            ></IndexTrack>

            <div className={clsx(c.carousel, "carousel")} ref={carouselRef}>
              {(() => {
                const activeIndex = api.images.modal.selected.index

                return (
                  <ImagesCarousel
                    ref={api.images.modal.sliderRef}
                    SliderProps={{
                      initialSlide: activeIndex,
                      // adaptiveHeight: true,
                      beforeChange(a, b) {
                        // console.log("before", a, b)
                      },
                      afterChange(currentSlide) {
                        clearTimeout(timeoutID.current)

                        showIndexTrack.setTrue()

                        timeoutID.current = setTimeout(() => {
                          showIndexTrack.setFalse()
                        }, 5000)

                        // console.log("after", currentSlide)
                        api.images.modal.selected.changeIndex(currentSlide)
                      },
                    }}
                    children={(i) => {
                      const a = api.images.getObjectURL(i.src)

                      if (!a) return null

                      return (
                        <div
                          style={{
                            height: size.height,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "auto",
                          }}
                        >
                          <img src={a} style={{ width: "100%" }}></img>
                        </div>
                      )
                    }}
                  ></ImagesCarousel>
                )
              })()}

              {/* <div style={{ height: "100%", background: "black" }}></div> */}
            </div>

            <Paper square elevation={2} className={c.bottomController}>
              {(() => {
                if ("share" in navigator) {
                  return (
                    <Hook hook={Shareable.useGetLink} hookProps={undefined}>
                      {(getLink) => {
                        const link = getLink({
                          shareableType: ShareableType.KanbanCard,
                          shareableID: api.card?.data?.id || "",
                        })

                        return (
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => {
                              navigator.share({
                                title:
                                  api.images.modal.selected?.data?.filename ||
                                  "",
                                url: link,
                              })
                            }}
                          >
                            <Icon>share</Icon>
                          </IconButton>
                        )
                      }}
                    </Hook>
                  )
                }

                return (
                  <Shareable.CopyLink
                    IconButtonProps={{ style: { padding: 0 } }}
                    shareableType={ShareableType.KanbanCard}
                    shareableID={api.card?.data?.id || ""}
                  ></Shareable.CopyLink>
                )
              })()}

              <Hook hook={useTrueFalse}>
                {(api) => {
                  return (
                    <>
                      <IconButton style={{ padding: 0 }} onClick={api.toggle}>
                        <Icon>delete</Icon>
                      </IconButton>

                      <DeleteImageModal
                        isOpen={api.isTrue}
                        onClose={api.setFalse}
                      ></DeleteImageModal>
                    </>
                  )
                }}
              </Hook>
            </Paper>
          </div>
        ),
        // BodyProps:
        // body:
      }}
    ></EnhancedPageModal>
  )
}
export const useStyles = makeStyles((theme) => {
  return {
    bottomController: {
      padding: theme.spacing(2, 2),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    body: {
      position: "relative",
      display: "flex",
      flexDirection: "column",

      "&> .index-track": {
        position: "absolute",
        zIndex: 1,
        right: theme.spacing(2),
        top: theme.spacing(2),
      },

      "& .carousel": {
        flex: 1,
        maxHeight: "100%",
        overflow: "hidden",
      },
    },
    indexTrack: {},
    carousel: {
      // height: "100%",
      background: "rgba(0,0,0,0.02)",
      "&>.slick-list": {
        // height: "100%",
      },
    },
  }
})

function Title() {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const a = useForm({
    initialValues: { filename: api.images.modal.selected.data?.filename || "" },
    enableReinitialize: true,
    onBlur({ target }) {
      const filename = target.name
      const value = target.value

      api.images.modal.selected.update({ filename: value })
    },
  })

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <TextFieldWithControlledFocus
        {...a.getFieldProps("filename")}
        limitOfCharsWhenIdle={20}
      ></TextFieldWithControlledFocus>

      <Settings></Settings>
    </div>
  )
}

function Settings() {
  const coverState = useManageCover()

  return (
    <ToggleableMenu
      IconButtonProps={{ size: "small" }}
      menuItems={[
        {
          component: <Typography>{coverState.text}</Typography>,
          onClick: coverState.action,
        },
      ]}
      // open={false}
      // anchor={ref.current}
      // icon={<div ref={ref}>oi</div>}
    ></ToggleableMenu>
  )
}

function useManageCover() {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const selectedID = api.images.modal.selected?.data?.id

  const state: "set-cover" | "remove-cover" = (() => {
    const coverID = api.images?.data?.cover?.id

    if (!selectedID || !coverID) return "set-cover"

    if (coverID === selectedID) return "remove-cover"

    return "set-cover"
  })()

  const a: Record<typeof state, { text: string; action: () => any }> =
    React.useMemo(
      () => ({
        "remove-cover": {
          text: "remove cover",
          action: api.images.removeCover,
        },
        "set-cover": {
          text: "set cover",
          action: () => api.images.setCover(selectedID),
        },
      }),
      [api.images.removeCover, selectedID]
    )

  return a[state]
}
