import { useApolloClient } from "@apollo/client";
import { MessageListItemFragment } from "lib/graphql/operations"
import { useSubscription } from "observable-hooks"
import React from "react"
import { getUpdateCache } from "./getUpdateCache"
import useGraphqlSubscriptions from "./hooks/useGraphqlSubscriptions"

type GraphqlSubscriptionsAPI = ReturnType<typeof useGraphqlSubscriptions>

const GraphqlSubscriptionsContext = React.createContext(
  {} as GraphqlSubscriptionsAPI
)

GraphqlSubscriptionsContext.displayName = "GraphqlSubscriptionsContext"

const GraphqlSubscriptions = {
  Context: GraphqlSubscriptionsContext,
  Provider(props: { children: React.ReactNode }) {
    const api = useGraphqlSubscriptions()

    const client = useApolloClient()

    const updateCache = React.useMemo(() => getUpdateCache(client), [client])

    useSubscription(api.createdRoom$, updateCache.addRoom)
    useSubscription(api.deletedRoom$, updateCache.removeRoom)
    useSubscription(api.updateRoomMembers$, updateCache.updateRoomMembers)
    useSubscription(api.newRoom$, updateCache.addRoom)
    useSubscription(api.excludedFromRoom$, updateCache.removeRoom)

    return (
      <GraphqlSubscriptionsContext.Provider value={api}>
        {props.children}
      </GraphqlSubscriptionsContext.Provider>
    )
  },
}

export default GraphqlSubscriptions
