import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  TenAbleShopOrderCreationCurlQueryVariables,
  useTenAbleShopOrderCreationCurlQuery,
} from "lib/graphql/operations"
import { ShopOrderAdditionalInfoInput } from "lib/graphql/types"
import isProduction from "lib/isProduction"
import React from "react"
import { ShopOrderPageBoardItemContext } from "../../ShopOrderPageBoardItemContext"

import _ from "lodash"

export function useOrderCurl(props: { input: ShopOrderAdditionalInfoInput }) {
  const api = React.useContext(ShopOrderPageBoardItemContext)

  const input = React.useMemo(
    () => ({ ...props.input, deliveryDate: props.input.deliveryDate || null }),
    [JSON.stringify(props.input)]
  )

  const variables: TenAbleShopOrderCreationCurlQueryVariables = {
    workspaceID: useActiveResources().workspaceID,
    orderID: api.item.order?.id || "",
    input,
  }

  const curl =
    useTenAbleShopOrderCreationCurlQuery({
      variables,
      skip: Object.values(_.omit(variables, "input")).some((v) => !v),
      fetchPolicy: isProduction ? "cache-first" : "cache-and-network",
    }).data?.tenAbleShopOrderCreationCurl || ""

  return useMemoAPI({ curl })
}
