import { DataProxy } from "apollo-cache/lib/types/DataProxy"
import { RoomMemberFragmentDoc } from "lib/graphql/operations"
import cachedIDGetters from "lib/getCacheID/getCacheID"
import { Typename } from "types/type"
import { FragmentMap } from "./fragmentMap"
import getFragmentParams from "./getFragmentParams"
import readFragment from "./readFragment"

export default function updateFragment<
  Type extends Extract<keyof FragmentMap, Typename>,
  Fragment extends FragmentMap[Type]
>(
  props: {
    typename: Type
    cacheIDParams: Parameters<(typeof cachedIDGetters)[Type]>[0]
    updateFn: Fragment["updateFn"]
  },
  dataProxy: DataProxy
) {
  const fragmentParams = getFragmentParams({
    typename: props.typename,
    cacheIDParams: props.cacheIDParams,
  })

  const data = readFragment(
    {
      cacheIDParams: props.cacheIDParams,
      typename: props.typename,
      fragmentParams: fragmentParams,
    },
    dataProxy
  )
  // console.log(data, {
  //   cacheIDParams: props.cacheIDParams,
  //   typename: props.typename,
  //   fragmentParams: fragmentParams,
  // })

  if (!data) {
    console.error(`Item with ${fragmentParams.id} doesnt on the cache`)
  }

  try {
    const updatedData = props.updateFn(data)

    dataProxy.writeFragment<typeof data>({
      ...fragmentParams,
      data: updatedData as typeof data,
    })

    // const b = dataProxy.readFragment<typeof data>({
    //   ...fragmentParams,
    //   // data: updatedData as typeof data,
    // })

    // console.log(b)
  } catch (e) {
    console.error(e)
  }
}
