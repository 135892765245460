import React from "react"
import { useContext } from "react"
import { Icon } from "@material-ui/core"
import { RoomContext } from "components/Room/contexts/room"
import { useRoomQuery } from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import { useIsRoomSilenced } from "../../hooks/useIsRoomSilenced"

export default function MuteStatus(props: {
  roomID: string
  style?: React.CSSProperties
}) {
  const { workspaceID } = useActiveResources()

  const { isSilenced } = useIsRoomSilenced({
    roomID: props.roomID,
    workspaceID,
  })

  if (!isSilenced) return null

  return (
    <Icon style={{ ...(props.style || {}), opacity: 0.4 }}>
      notifications_off
    </Icon>
  )
}
