import { PageComponentProps } from "components/Page/Page"
import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { ShopOrderPageBoardItemFragment } from "lib/graphql/operations"
import React from "react"

const Factory = contextAndProviderFactory({
  hookApi: useShopOrderPageBoardItem,
})
export const ShopOrderPageBoardItemContext = Factory.Context

export type ShopOrderPageBoardItemProps = PageComponentProps & {
  initialParams?: { showGoToMessageButton?: boolean }
}

export const ShopOrderPageBoardItemProvider = Factory.Provider

function useShopOrderPageBoardItem(
  props: {
    item: ShopOrderPageBoardItemFragment
  } & ShopOrderPageBoardItemProps
) {
  return useMemoAPI({
    ...props,
    item: props.item,
    order: props.item.order,
    articles: React.useMemo(
      () => props.item.order?.items?.map((a) => a.article) || [],
      [props.item]
    ),
    getQuantity: React.useCallback(
      (p: { articleID: string }) => {
        return (
          props.item.order?.items?.find((a) => a.article.id === p.articleID)
            ?.quantity || 0
        )
      },
      [props.item?.order]
    ),
  })
}
