import { TextField as MaterialTextField } from "@material-ui/core"
import { TextFieldProps as MaterialTextFieldProps } from "@material-ui/core"
import useTextFieldProps from "./hooks/useTextFieldProps"
import { Variant } from "@material-ui/core/styles/createTypography"

export type TextFieldProps = MaterialTextFieldProps & {
  typographyVariant?: Variant
}

export default function TextField(props?: TextFieldProps) {
  const biasedProps = useTextFieldProps(props as any)
  return <MaterialTextField {...biasedProps}></MaterialTextField>
}
