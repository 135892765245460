import React from "react"
import DocumentsFilter from "components/DocumentsFilter/index"

const CollectionDocumentsFilterContext = React.createContext({
  isFilterOpen: false,
  toggleFilter() {},
  DocumentsFilter,
})
CollectionDocumentsFilterContext.displayName =
  "CollectionDocumentsFilterContext"

export default CollectionDocumentsFilterContext
