import { useSubscription } from "@apollo/client";
import _ from "lodash"
import { RoomsUpdatesDocument } from "lib/graphql/operations"
import updateRoom from "lib/updateRoom"

export default function useRoomsUpdatesSubscription() {
  useSubscription(RoomsUpdatesDocument, { onSubscriptionData })
}

function onSubscriptionData({ client, subscriptionData }) {
  const roomsUpdates = _.get(subscriptionData, "data.roomsUpdates")

  roomsUpdates.forEach((update) => {
    updateRoom({ update: update }, client)
  })
}
