import React from "react"
import cloneElWithClass from "util/cloneElWithClass"
import clsx from "clsx"
import { makeStyles } from "@material-ui/styles"
import { RequireAtLeastOne } from "types/type"

export type View<ViewID extends string = string> = {
  id: ViewID
} & RequireAtLeastOne<{
  Component: React.FC<{
    isActiveView?: boolean
    className?: string
  }>
  component: React.ReactNode
}>

export default function Views<ViewID extends string>(props: {
  views: View<ViewID>[]
  currentViewID?: ViewID | null
  className?: string
  style?: React.CSSProperties
  classes?: { view?: string; viewContent?: string }
  lazyRendering?: boolean // render the component only when the respective view is active
  noScroll?: boolean
}) {
  const c = useStyles({})

  const [renderedViews, setRenderedViews] = React.useState<
    Record<string, boolean>
  >({})
  const currentViewID = props.currentViewID || props.views[0]?.id

  React.useEffect(() => {
    if (!renderedViews[currentViewID]) {
      setRenderedViews((views) => ({ ...views, [currentViewID]: true }))
    }
  }, [currentViewID])

  const views = (props.views || []).map((t) => {
    const isVisible = t.id === currentViewID

    if (!t.component && !t.Component) return null

    const content = t.component ? (
      cloneElWithClass(props.classes?.view || "", <>{t.component}</>)
    ) : t.Component ? (
      <t.Component
        isActiveView={isVisible}
        className={props.classes?.view || ""}
      />
    ) : null

    return (
      <div
        key={t.id}
        className={clsx("view", !isVisible && c.invisible, props.className)}
        style={props.style}
      >
        {(() => {
          const component = (
            <div className={clsx(c.viewContent, props.classes?.viewContent)}>
              {props.lazyRendering ? renderedViews[t.id] && content : content}
            </div>
          )

          if (!!props.noScroll) return component

          return <div className={c.scrolledContainer}>{component}</div>
        })()}
      </div>
    )
  })

  return <React.Fragment>{views}</React.Fragment>
}

const useStyles = makeStyles(() => {
  return {
    scrolledContainer: { overflow: "auto", height: "100%", maxHeight: "100%" },
    invisible: {
      display: "none",
    },
    viewContent: {
      height: "100%",
      "&>*": {
        maxWidth: "100%",
      },
    },
  }
})
