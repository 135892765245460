import { PageComponent } from "components/Page/hooks/useSecondPage"
import PageBoardContext from "components/PageBoard/contexts/PageBoardContext"
import { NewPageBoardItem_RoomPageBoardItem_Fragment } from "lib/graphql/operations"
import _ from "lodash"
import CreateRoom, { CreateRoomProps } from "./CreateRoom"

export const CreateRoomPageComponent: PageComponent =
  getCreateRoomPageComponent()

export function getCreateRoomPageComponent(
  props?: Omit<CreateRoomProps, "onClose">
): PageComponent {
  return function CreateRoomPageComponent(args) {
    return (
      <PageBoardContext.Consumer>
        {(api) => {
          return (
            <CreateRoom
              onClose={args.onClose}
              {..._.omit(props, "onClose")}
              onCreate={(a: NewPageBoardItem_RoomPageBoardItem_Fragment) => {
                args.onClose()
                // api.selected.set(a)
                props?.onCreate?.(a)
              }}
            ></CreateRoom>
          )
        }}
      </PageBoardContext.Consumer>
    )
  }
}
