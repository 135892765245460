import Kanban from "components/Kanban/Kanban"
import React from "react"
import useRoomKanban from "./hooks/useRoomKanban"
import { OpenKanbanCardPayload } from "types/type"

const RoomKanban = {
  Provider: RoomKanbanProvider,
  Root: Kanban.Root,
  Context: Kanban.Context,
}

export default RoomKanban

function RoomKanbanProvider(props: {
  children: React.ReactNode
  openKanbanCard?: (payload: OpenKanbanCardPayload) => void
  roomID?: string
}) {
  const kanbanAPI = useRoomKanban({
    roomID: props.roomID || "",
    openKanbanCard: props.openKanbanCard,
  })

  if (!props.roomID) return null

  return <Kanban.Provider {...kanbanAPI}>{props.children}</Kanban.Provider>
}
