import useSecondPage from "components/Page/hooks/useSecondPage"
import React from "react"

const SecondPageContext = React.createContext(
  {} as ReturnType<typeof useSecondPage>
)

SecondPageContext.displayName = "SecondPageContext"

const SecondPageAPI = {
  Provider(props: { children: React.ReactNode }) {
    const secondPage = useSecondPage()

    return (
      <SecondPageContext.Provider value={secondPage}>
        {props.children}
      </SecondPageContext.Provider>
    )
  },
  Context: SecondPageContext,
}

export default SecondPageAPI
