import React from "react"
import ToggleableMenu from "components/ToggleableMenu/ToggleableMenu"
import { Icon, makeStyles, Typography } from "@material-ui/core"
import KanbanContext from "../../contexts/KanbanContext"
import Editable from "components/Editable/Editable"
import { KanbanColumn } from "components/Kanban/@types"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import clsx from "clsx"
import Hyphenated from "components/Hyphenated/Hyphenated"

export default function Header(props: { column: KanbanColumn }) {
  const context = React.useContext(KanbanContext)
  const column = props.column
  const { isMobile } = React.useContext(DeviceObserver.Context)
  const c = useStyles({ isMobile })
  const onChange = React.useCallback(
    (value) => {
      context.updateColumnTitle?.({ columnID: column.id, title: value })
    },
    [context.updateColumnTitle]
  )

  return (
    <Editable
      defaultValue={column.title || ""}
      onChange={onChange}
      placeholder="Title"
      onCancelEditing={context.editAPI.cancelEdition}
      isEditing={context.editAPI.isEditing(column?.id)}
      multiline
    >
      <div className={c.nonEditing}>
        <Typography variant="body1" className={c.title}>
          <Hyphenated>{column.title || "Title"}</Hyphenated>
        </Typography>
        <KanbanColumnSettings
          column={column}
          className="settings"
        ></KanbanColumnSettings>
      </div>
    </Editable>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    nonEditing: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: "relative",
      width: 250,

      "& .settings": {
        // position: "absolute",
        // right: "50%",
        // top: 0,
        // transform: "translate(-50%, -50%)",
        visibility: ({ isMobile }) => (isMobile ? "visible" : "hidden"),
      },

      "&:hover": {
        "& .settings": {
          visibility: "visible",
        },
      },
    },
    title: {
      whiteSpace: "normal",
      width: "100%",
    },
  }
})

function KanbanColumnSettings(props: {
  column: KanbanColumn
  className?: string
}) {
  // const
  const context = React.useContext(KanbanContext)

  const menuItems = React.useMemo(() => {
    return [
      {
        component: "edit",
        onClick: context.editAPI.startEdition.bind(null, props.column.id),
      },
      {
        component: "delete",
        onClick: context.deleteColumn.bind(null, props.column),
      },
    ]
  }, [props.column.id, context.deleteColumn])

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={clsx("kanban-column-settings", props.className)}
    >
      <ToggleableMenu
        edge="end"
        icon={<Icon style={{ marginTop: -8 }}>more_vert</Icon>}
        menuItems={menuItems}
      ></ToggleableMenu>
    </div>
  )
}
