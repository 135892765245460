import { useApolloClient, gql } from "@apollo/client";
import { ID, RoomQueryVariables } from "types/type"
import React from "react"
import {
  EditKanbanCommentDocument,
  RemoveKanbanCommentDocument,
} from "lib/graphql/operations"

export default function useRemoveComment(props: RoomQueryVariables) {
  const client = useApolloClient()

  return React.useCallback(
    (cardID: ID, commentID: ID) => {
      const card = client.readFragment({
        id: `KanbanCard:${cardID}`,
        fragment: gql`
          fragment KanbanCard on KanbanCard {
            commentList {
              id
              comments {
                id
              }
            }
          }
        `,
      })

      const optimisticResponse = {
        __typename: "Mutation",
        removeKanbanComment: {
          id: card?.commentList?.id,
          comments:
            card?.commentList?.comments
              ?.filter(
                (c: { id: ID; __typename: string }) => c.id !== commentID
              )
              .map((c: { id: ID; __typename: string }) => ({
                id: c.id,
                __typename: c.__typename,
              })) || [],
          __typename: "KanbanCommentList",
        },
      }

      client.mutate<typeof optimisticResponse>({
        mutation: RemoveKanbanCommentDocument,
        variables: {
          roomID: props.roomID,
          workspaceID: props.workspaceID,
          cardID,
          commentID,
        },
        optimisticResponse,
      })
    },
    [props.roomID, props.workspaceID]
  )
}
