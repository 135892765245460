import { useApolloClient } from "@apollo/client";
import useMemoAPI from "hooks/useMemoAPI"
import getPublicAppConfig from "lib/getPublicAppConfig"
import React, { useContext, useEffect, useState, useMemo } from "react"
import defaultTranslations from "./defaultTranslations"

const I18nContext = React.createContext(defaultTranslations)
I18nContext.displayName = "I18nContext"

export function I18nProvider(props: {
  i18n?: typeof defaultTranslations
  children: React.ReactNode
}) {
  const { i18n: basicTranslations = defaultTranslations } = props

  const collectionsTranslations = useCollectionsTranslations()

  const t = useMemoAPI({
    ...(basicTranslations || {}),
    collectionsTypes: collectionsTranslations,
  })

  return <I18nContext.Provider value={t}>{props.children}</I18nContext.Provider>
}

export type I18nAPI = ReturnType<typeof useI18n>

export function useI18n() {
  const basicTranslations = useContext(I18nContext)

  return basicTranslations
}

export default I18nContext

let cachedCollections = undefined

function useCollectionsTranslations() {
  const [value, setValue] = useState(cachedCollections || {})

  const client = useApolloClient()

  // client.
  useEffect(() => {
    if (cachedCollections) return

    getPublicAppConfig("filesEndpoints.translations.collections", client)
      .then((collectionsTranslationsEndpoint) => {
        return fetch(collectionsTranslationsEndpoint).then((a) => a.json())
      })
      .then((v) => {
        cachedCollections = v
        return v
      })
      .then((v) => setValue(v))
      .catch((e) => {
        console.error(e)
      })
  }, [])

  if (!value) return null

  return value
}
