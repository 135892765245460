import _ from "lodash"
import { CollectionItemDocument } from "lib/graphql/operations"
import useMemoAPI from "./useMemoAPI"
import { CollectionItem } from "types/type"
import useActiveResources from "./useActiveResources"
import { useQuery } from "@apollo/client"

export default function useCollectionItem(params: {
  collectionID: string | null
}) {
  const { workspaceID } = useActiveResources()

  const { data, loading } = useQuery(CollectionItemDocument, {
    variables: {
      collectionID: params.collectionID,
      workspaceID,
    },
  })

  return useMemoAPI({
    collectionItem: data?.collectionItem as CollectionItem | undefined,
    isLoading: loading,
  })
}
