import isProduction from "lib/isProduction"

// "workspace.account.457.user.434442482d413548352d344331312d48454c4c2d4f4e4341"
const dev = isProduction ? false : false
// const dev = isProduction ? false : true

if (dev) {
  require("./dev")
} else {
  require("./root")
}
