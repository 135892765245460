import { urlRegex } from "./constants"

export default function extractURLsFromString(string?: string | null) {

  const urls = (string || "").match(urlRegex)

  if (!urls) return null

  return urls
}
