import { ChipProps } from "@material-ui/core"
import { Icon } from "components/Icon/Icon"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { excludeNullable } from "lib/excludeNullable"
import { PageBoardItemType } from "lib/graphql/types"
import { RelatedItemsChips } from "./RelatedDocumentsChips/RelatedItemsChips"
import { RelatedItemsChipApiInput } from "./RelatedDocumentsChips/RelatedItemsChipsContext"

export type { RelatedItemsChipApiInput as RelatedDocumentsChipApiInput } from "./RelatedDocumentsChips/RelatedItemsChipsContext"

export function RelatedDocumentsChips(
  props: Omit<RelatedItemsChipApiInput, "type"> & {
    onClickUploadButton?: () => void
    onClickOpenAllDocuments?: () => void
  }
) {
  const t = useI18n()

  return (
    <RelatedItemsChips
      {...props}
      type={PageBoardItemType.Document}
      additionalChips={(api) => {
        return [
          !!api.related.length &&
            ({
              key: "all-documents-button",
              color: "primary",
              variant: "outlined",

              icon: <Icon name="list"></Icon>,
              label: t["allDocuments"],
              onClick: () => {
                props.onClickOpenAllDocuments?.()
              },
            } as ChipProps),

          {
            key: "open-upload-interface",
            color: "primary",
            variant: "outlined",
            label: t["upload"],
            icon: <Icon name="cloud_upload"></Icon>,
            onClick: () => {
              props.onClickUploadButton?.()
            },
          } as ChipProps,
        ]?.filter(excludeNullable)
      }}
    ></RelatedItemsChips>
  )
}
