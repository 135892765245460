import { Typography } from "@material-ui/core"
import { ParagraphFragment } from "lib/graphql/operations"
import MessageRichText from "../MessageRichText/MessageRichText"
import { ShopArticlePageBoardItemSummaryParagraph } from "./ShopArticlePageBoardItemSummaryParagraph"
import { TableParagraph } from "./TableParagraph/TableParagraph"
import { ShopArticleText } from "./ShopArticleText/ShopArticleText"

export function Paragraph(props: { paragraph: ParagraphFragment }) {
  const p = props.paragraph

  if (p.__typename === "TextParagraph") {
    return (
      <Typography variant="body2">
        <ShopArticleText text={p.text || ""}></ShopArticleText>
      </Typography>
    )
  }

  if (p.__typename === "PageBoardItemSummaryParagraph") {
    return (
      <ShopArticlePageBoardItemSummaryParagraph
        paragraph={p}
      ></ShopArticlePageBoardItemSummaryParagraph>
    )
  }

  if (p.__typename === "TableParagraph") {
    return <TableParagraph paragraph={p}></TableParagraph>
  }

  return null
}
