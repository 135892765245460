import { PaperListApiProps } from "components/PaperList/types"
import RootContainer from "components/RootContainer/RootContainer"
import React from "react"
// import List from "./List/List"
import { PaperListApiProvider } from "../../contexts/PaperListContext"
import { List } from "../../../List/List"

export default function PaperListProvider(
  props: PaperListApiProps & {
    children: React.ReactNode
  }
) {
  return (
    <RootContainer.Provider>
      <List.Provider
        itemKey={props.itemKey}
        items={props.items}
        fetchMore={props.fetchMore}
        totalCount={props.totalCount}
        variant="list"
      >
        <PaperListApiProvider
          ListItem={props.ListItem}
          selectedEntryColor={props.selectedEntryColor}
          classes={props.classes}
          ItemPaperProps={props.ItemPaperProps}
          marginZero={props.marginZero}
        >
          {props.children}
        </PaperListApiProvider>
      </List.Provider>
    </RootContainer.Provider>
  )
}
