import * as Yup from "yup"
import clsx from "clsx"
import _ from "lodash"
import React, { useMemo, useCallback, useEffect, useState } from "react"
import { useUploadContext } from "../../context/UploadContext"
import EditForm from "./components/EditForm"
import { useSelectedFileIDContext } from "components/OldUploadDocuments/context/SelectedFileContext"

export default function EditFilesMetadata() {
  const {
    uploadedFiles = [],
    updateUploadedFile,
    updateAllFiles,
  } = useUploadContext()

  const [selectedFileID] = useSelectedFileIDContext()

  const file = useMemo(() => {
    return uploadedFiles.find(({ id }) => id === selectedFileID)
  }, [uploadedFiles, selectedFileID])

  const [repeated, setRepeated] = useState({})

  const onChange = useCallback(
    (formValues) => {
      const v = (key) => _.get(formValues, key, "")

      const dataUpdate = {
        collectionType: v("collectionType.value"),
        text: v("text.value"),
        filename: v("filename"),
        title: v("title"),
      }

      updateUploadedFile({ id: selectedFileID, dataUpdate })

      if (v("collectionType.repeated"))
        updateAllFiles({ collectionType: v("collectionType.value") })
      if (v("text.repeated")) updateAllFiles({ text: v("text.value") })

      setRepeated({
        collectionType: v("collectionType.repeated"),
        text: v("text.repeated"),
      })
    },
    [selectedFileID, updateAllFiles, updateUploadedFile]
  )

  const initialValue = useMemo(() => {
    const value = (key) => _.get(file, key, "")
    const shouldRepeat = (key) =>
      typeof repeated[key] === "undefined" ? true : repeated[key]

    return {
      text: { value: value("text"), repeated: shouldRepeat("text") },
      collectionType: {
        value: value("collectionType"),
        repeated: shouldRepeat("collectionType"),
      },
      title: value("title"),
      filename: value("filename"),
    }
  }, [JSON.stringify(file), repeated])

  if (!_.get(file, "id")) return null

  return (
    <EditForm
      defaultValues={initialValue}
      onChange={onChange}
      showRepeatToggle={uploadedFiles.length > 1}
    />
  )
}
