import RoomTemplateContext from "components/Room/components/RoomTemplate/contexts/RoomTemplateContext"
import React from "react"
import { useCallback, useState } from "react"
import { MODES } from "../../constants"
import useMemoAPI from "hooks/useMemoAPI"
import { RoomContext } from "components/Room/contexts/room"

export default function useModeAPI() {
  const { isEditingMembers, setIsEditingMembers } =
    React.useContext(RoomContext).template

  const mode = isEditingMembers ? MODES.editing : MODES.idle

  const [searchText] = useState("")

  const edit = useCallback(
    function edit() {
      setIsEditingMembers(true)
    },
    [setIsEditingMembers]
  )

  const cancelEdit = useCallback(
    function cancelEdit() {
      setIsEditingMembers(false)
    },
    [setIsEditingMembers]
  )

  const api = useMemoAPI({
    mode,
    searchText,
    edit,
    cancelEdit,
  })

  return api
}
