import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { filter, map } from "rxjs/operators"
import { useObservable } from "observable-hooks"
import useUnreadAPI from "./useUnreadAPI"
import useOnScroll from "./useOnScroll"
import { MessagesRendered } from "components/Room/types"
import { MessagesListHandle } from "../components/MessagesList/MessagesList"
import useScrolling from "./useScrolling"
import useMessagesDataAPI from "./useMessagesDataAPI"

export default function useRendererAPI(props: {
  messagesData: ReturnType<typeof useMessagesDataAPI>
}) {
  const [messagesRendered, setMessagesRendered] =
    React.useState<MessagesRendered | null>(null)

  const messagesRendered$ = useObservable(
    (input$) =>
      input$.pipe(
        map(([a]) => a),
        filter((a): a is MessagesRendered => !!a)
      ),
    [messagesRendered]
  )

  const unread = useUnreadAPI({
    messagesRendered$,
  })

  const messageListRef = React.useRef<MessagesListHandle>(null)

  const scrolling = useScrolling({
    messageListRef: messageListRef,
    messagesData: props.messagesData,
  })

  const value = useMemoAPI({
    unread,
    messageListRef,
    messagesRendered$,
    messagesRendered,
    setMessagesRendered,
    scrolling,
  })

  return value
}
