import { makeStyles } from "@material-ui/core"
import { TwoPagesAPI } from "components/TwoPages/TwoPages"
import useDocument from "hooks/useDocument/useDocument"
import useMemoAPI from "hooks/useMemoAPI"
import usePartner from "hooks/usePartner"
import { DocumentStatusType } from "../types"
import useDocumentForm from "./useDocumentForm"

export type DocumentFormApiInput = {
  openPartnerPage?: () => void
  twoPagesAPI?: TwoPagesAPI
  onClickOpenButton?: () => void
  summarized?: boolean
  hidePartnerField?: boolean
  documentID?: string
}

export function useDocumentFormAPI(props?: DocumentFormApiInput) {
  const { document } = useDocument({
    documentID: props?.documentID || "",
    fetchPolicy: "cache-and-network",
  })

  const { partner } = usePartner({
    collectionID: document?.partnerCollection?.id || "",
    type: document?.partnerCollection?.type || "",
  })

  const form = useDocumentForm({ document })

  const status = (() => {
    const documentStatus = document?.workStatus

    if (!documentStatus) return null

    return {
      type: DocumentStatusType.workStatus,
      value: documentStatus,
    }
  })()

  const classes = useStyles()

  return useMemoAPI({
    ...(props || {}),
    document,
    form,
    partner,
    status,
    classes,
  })
}

const useStyles = makeStyles((theme) => {
  return {
    indentation: {
      paddingRight: [theme.spacing(2) + "px", "!important"].join(" "),
    },
  }
})
