import FastAverageColor, { FastAverageColorResult } from "fast-average-color"
import React from "react"

export default function AverageColor(props: {
  imageURL: string
  onBackgroundColor?: (backgroundColor: FastAverageColorResult | null) => void
  children: (props: {
    averageColor?: FastAverageColorResult | null
  }) => React.ReactNode
}) {
  const color = useImageAverageColor(props.imageURL || null)

  React.useEffect(() => {
    props.onBackgroundColor?.call(null, color)
  }, [color])

  return <>{props.children({ averageColor: color || null })}</>
}

export function useImageAverageColor(
  imageURL: string | null
): FastAverageColorResult | null {
  const [color, setColor] = React.useState<FastAverageColorResult | null>(null)

  React.useEffect(() => {
    if (!imageURL) return

    let downloadedImg = new Image()

    downloadedImg.crossOrigin = "Anonymous"

    downloadedImg.addEventListener(
      "load",
      () => {
        const fac = new FastAverageColor()
        const color = fac.getColor(downloadedImg, { algorithm: "sqrt" })
        setColor(color)
      },
      false
    )
    downloadedImg.src = imageURL
  }, [imageURL])

  return color
}
