import _ from "lodash"
import React from "react"
import MainAppBar from "../MainAppBar/MainAppBar"
import { makeStyles } from "@material-ui/styles"
import IconButton from "components/IconButton/IconButton"
import CollectionDescription from "components/CollectionDescription/CollectionDescription"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import CollectionItemStatus from "components/CollectionItemStatus/CollectionItemStatus"
import { useUtilsContext } from "pages/Partners/contexts/libContext"

export default function ProfileAppBar({
  title,
  subtitle,
  deleted,
  type,
  renderRight = ({
    defaultComponent,
    components: { search, settings },
    isMobileSearchActive,
  }) => defaultComponent,
}) {
  return (
    <MainAppBar
      Left={({ isMobileSearchActive }) => (
        <Left
          title={title}
          subtitle={subtitle}
          deleted={deleted}
          type={type}
          isMobileSearchActive={isMobileSearchActive}
        ></Left>
      )}
      renderRight={renderRight}
    ></MainAppBar>
  )
}

function Left({ isMobileSearchActive, title, subtitle, deleted, type }) {
  const { isDesktop } = React.useContext(DeviceObserver.Context)
  const { goToHome } = useUtilsContext()
  const collection = { title, subtitle, deleted, type }

  const description = (
    <CollectionDescription
      renderTitle={(p) => renderTitle({ ...p, collection })}
      collection={{
        ...collection,
        subtitle: isDesktop ? collection.subtitle : null,
      }}
    ></CollectionDescription>
  )

  const c = useLeftStyles({})

  if (isMobileSearchActive) return null

  return (
    <div className={c.left}>
      <IconButton
        iconName="arrow_back"
        edge="start"
        onClick={goToHome}
      ></IconButton>
      {description}
    </div>
  )
}

function renderTitle({ defaultComponent: title, collection }) {
  return (
    <CollectionItemStatus collection={collection}>
      {({ components: { status } }) => [title, status]}
    </CollectionItemStatus>
  )
}

const useLeftStyles = makeStyles((theme) => {
  return {
    left: {
      display: "flex",
      alignItems: "center",
      "&>*": {
        marginRight: theme.spacing(1),
      },
    },
  }
})
