import { ChipProps } from "@material-ui/core"
import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { PageBoardItemContext } from "../PageBoardItemContext"

export type RelatedItemsChipApiInput = {
  onClickChip?: (item: NewPageBoardItemFragment) => void
  additionalChips?: (api: RelatedItemsChipsApi) => ChipProps[]
  type: PageBoardItemType
}

export type RelatedItemsChipsApi = ReturnType<typeof useRelatedItemsChipsApi>

function useRelatedItemsChipsApi(props: RelatedItemsChipApiInput) {
  const api = React.useContext(PageBoardItemContext)

  const related = React.useMemo(
    () => api.related.filter((item) => item.type === props.type),
    [api.related, props.type]
  )

  return useMemoAPI({
    ...(props || {}),
    related,
  })
}

export const {
  Context: RelatedItemsChipsContext,
  Provider: RelatedItemsChipsProvider,
} = contextAndProviderFactory({
  hookApi: useRelatedItemsChipsApi,
  contextName: "RelatedItemsChipsContext",
})
