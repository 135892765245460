import _ from "lodash"
import React, { useCallback } from "react"
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  RadioGroup as MuiRadioGroup,
} from "@material-ui/core"

import Radio from "@material-ui/core/Radio"
import { FieldInputProps } from "formik"

function RadioGroup({
  error,
  value,
  onChange: propsOnChange,
  label,
  options,
  onBlur,
  name: propsName,
  row,
  style,
  disabled,
  classes,
  styles,
}: FieldInputProps<string> & {
  disabled?: boolean
  options: { label: string; value: string }[]
  label?: string
  row?: boolean | undefined
  error?: boolean
  style?: React.CSSProperties
  classes?: { radioGroup?: string }
  styles?: { radioGroup?: React.CSSProperties }
}) {
  return (
    <FormControl error={error} style={style}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      {!_.isEmpty(options) && (
        <MuiRadioGroup
          value={value}
          onChange={propsOnChange}
          name={propsName}
          className={classes?.radioGroup}
          onBlur={onBlur}
          style={styles?.radioGroup}
          row={row}
        >
          {options.map(({ label, value: optionValue }) => {
            return (
              <FormControlLabel
                key={optionValue}
                label={label}
                value={optionValue}
                disabled={disabled}
                control={<Radio />}
              />
            )
          })}
        </MuiRadioGroup>
      )}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default RadioGroup
