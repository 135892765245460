import _ from "lodash"
import { useMemo, useContext } from "react"
import clsx from "clsx"
import { useLoadingState } from "components/PageLayoutFromPageID/PageLayoutFromPageID"
import TabsWithBody from "components/TabsWithBody/TabsWithBody"
import PartnersContext from "pages/Partners/contexts/PartnersContext"
import { PartnersTabsIDs } from "pages/Partners/types"

export default function Profile({ tabs: propsTabs, className = "" }) {
  const { collectionID, tabID, collectionTypeColor, setTabID } =
    useContext(PartnersContext)
  const [, setLoadingState] = useLoadingState()

  const tabs = useTabs({
    tabs: propsTabs,
    onChangeLoadingState: setLoadingState,
    collectionID,
  })

  return (
    <TabsWithBody<PartnersTabsIDs | null>
      className={clsx("profile", className)}
      tabs={tabs}
      tabID={tabID}
      key={collectionID}
      onChange={setTabID}
      contentZeroPadding
      selectedTabColor={collectionTypeColor}
    ></TabsWithBody>
  )
}

function useTabs({ tabs, collectionID, onChangeLoadingState }) {
  return useMemo(() => {
    return (tabs || []).map((t) => ({
      ...t,
      Component(props) {
        if (!t.Component) return null

        return (
          <t.Component
            {...props}
            onChangeLoadingState={onChangeLoadingState}
            collectionID={collectionID}
          />
        )
      },
    }))
  }, [collectionID])
}
