import { useApolloClient } from "@apollo/client";
import { createColumnOrdering } from "components/RoomKanban/hooks/createColumnOrdering"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  CreateKanbanColumnMutation,
  KanbanColumnFragment,
  MutatedBoardFragment,
  useCreateKanbanColumnMutation,
} from "lib/graphql/operations"
import updateQuery, { readQuery } from "lib/updateQuery/updateQuery"
import React from "react"
import { useI18n } from "../contexts/i18nContext/i18nContext"

export default function useCreateKanbanColumn(props: {
  roomID: string | null | undefined
}) {
  const r = useActiveResources()

  const t = useI18n()

  const [mutate, { data, loading, error }] = useCreateKanbanColumnMutation()

  const client = useApolloClient()

  const createColumn = React.useCallback(
    async (title?: string) => {
      const workspaceID = r.workspaceID
      const roomID = props.roomID

      if (!workspaceID || !roomID) return

      const columns = readQuery(
        {
          queryName: "decoupledKanbanBoard",
          variables: { workspaceID: r.workspaceID, roomID },
        },
        client
      )?.decoupledKanbanBoard?.columnOrdering?.orderedColumnsIDs

      const isFirstColumn = columns?.length === 0

      const d = title || (isFirstColumn ? "Todo" : t["untitledColumn"])

      const fakeColumn: KanbanColumnFragment = {
        __typename: "KanbanColumn",
        id: -1 * Math.random() + "",
        title: d,
        orderedCardsIDs: [],
        isDeleted: false,
      }

      const { data } = await mutate({
        optimisticResponse: (() => {
          const columnOrdering = createColumnOrdering(
            {
              columnID: fakeColumn.id,
              roomID: props.roomID || "",
              workspaceID: r.workspaceID,
              indexPosition: "last",
            },
            client
          )

          const b: MutatedBoardFragment = {
            __typename: "MutatedBoard",
            upsertedColumns: [fakeColumn],
            columnOrdering,
          }

          const a: CreateKanbanColumnMutation = {
            __typename: "Mutation",
            createKanbanColumn: b,
          }

          return a
        })(),

        update(cache, { data }) {
          updateQuery(
            {
              queryName: "decoupledKanbanBoard",
              variables: { roomID, workspaceID },
              updateFn(a) {
                if (!a || !a.decoupledKanbanBoard) return a as typeof a

                const b: typeof a = {
                  ...a,

                  decoupledKanbanBoard: {
                    ...a.decoupledKanbanBoard,

                    columns: [
                      ...(a.decoupledKanbanBoard.columns || []),
                      ...(data?.createKanbanColumn?.upsertedColumns || []),
                    ],
                  },
                }

                return b
              },
            },
            cache
          )
        },
        variables: {
          workspaceID,
          roomID,
          column: {
            title: d,
          },
        },
      })

      return data?.createKanbanColumn
    },
    [props.roomID, r.workspaceID, mutate, client]
  )

  return useMemoAPI({ createColumn, loading })
}
