import stringOrNumber from "./stringOrNumber"

//REMOVE
export default function matches({ value, searchedTerm }) {
  if (!searchedTerm) return false
  const searchRegex = new RegExp(
    searchedTerm.replace(/[\\^$.|?*+()[{]/g, "\\$&"),
    "i"
  )

  const itMatches = stringOrNumber(value) && (value || "").match(searchRegex)

  return itMatches
}
