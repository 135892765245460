import { ID } from "types/type"
import { ShareableVariables } from "../@types"
import { SHAREABLE_PATHNAME_ROOT } from "../constants"
import shareableSearchParams from "../hooks/shareableSearchParams"

const shareableLib = {
  isSharedURL(props: { url: URL }) {
    return !!props.url.pathname.match(new RegExp("^" + SHAREABLE_PATHNAME_ROOT))
  },
  getShareableURL(
    props: {
      urlToShare: URL
      workspaceRootPathname: string
      // sharerID: ID
      // shareableType: string
      // shareableID: string
    } & ShareableVariables
  ) {
    const url = new URL(props.urlToShare)

    if (!props.workspaceRootPathname || !props.sharerID) return null

    url.pathname = url.pathname.replace(
      props.workspaceRootPathname,
      SHAREABLE_PATHNAME_ROOT
    )

    shareableSearchParams.set(
      {
        shareableType: props.shareableType,
        shareableID: props.shareableID,
        sharerID: props.sharerID,
      },
      url
    )

    return url
  },

  getWorkspaceURL(props: { sharedURL: URL; workspaceRootPathname: string }) {
    if (!props.workspaceRootPathname) return null

    if (!!props.sharedURL && !this.isSharedURL({ url: props.sharedURL })) {
      throw Error("It is not a sharedURL")
    }

    if (!props.sharedURL) return null

    const url = new URL(
      shareableSearchParams.removeShareableParams(props.sharedURL)
    )

    url.pathname = url.pathname.replace(
      SHAREABLE_PATHNAME_ROOT,
      props.workspaceRootPathname + ""
    )

    return url
  },
}

export default shareableLib
