import {
  AddNewContactForm,
  AddNewContactFormApiInput,
} from "components/AddNewContactForm/AddNewContactForm"
import { PageComponentProps } from "components/Page/hooks/useSecondPage"
import { FormPage } from "../../FormPage"

export function NewContactFormPage(
  props: PageComponentProps & AddNewContactFormApiInput
) {
  return (
    <AddNewContactForm.Provider partnerId={props.partnerId}>
      <AddNewContactForm.Context.Consumer>
        {(api) => {
          return (
            <FormPage
              form={api.form as any}
              onClose={props.onClose}
              SaveButtonProps={{ resetOnSubmit: true }}
              body={
                <AddNewContactForm.Root
                  style={{ padding: 16 }}
                ></AddNewContactForm.Root>
              }
            ></FormPage>
          )
        }}
      </AddNewContactForm.Context.Consumer>
    </AddNewContactForm.Provider>
  )
}
