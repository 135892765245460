import {
  OrdinaryRoomIdentifier,
  RoomIdentifier,
  RoomQueryVariables,
} from "types/type"
import ApolloClient from "apollo-client"
import basicTabs from "components/Room/components/tabs/basicTabs"
import { RoomDocument, RoomFragment, RoomQuery } from "lib/graphql/operations"
import RoomAPI from "../RoomAPI/RoomAPI"
// import { RoomIdentifier } from "@types"

export default class OrdinaryRoomAPI extends RoomAPI<OrdinaryRoomIdentifier> {
  async getRoom(identifier: OrdinaryRoomIdentifier) {
    const room = await this.client
      .query<RoomQuery, RoomQueryVariables>({
        query: RoomDocument,
        variables: { workspaceID: this.workspaceID, roomID: identifier.roomID },
        fetchPolicy: "cache-first",
      })
      .then((a) => a.data.room)

    return room
  }

  getRoomID(identifier: OrdinaryRoomIdentifier) {
    return Promise.resolve(identifier.roomID)
  }

  async createRoom(identifier: OrdinaryRoomIdentifier) {
    return undefined
  }
}
