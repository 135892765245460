import React from "react"
import useMemoAPI from "hooks/useMemoAPI"

export default function useAudioControl() {
  const audioRef = React.useRef<HTMLAudioElement>(null)

  const ring = React.useCallback(() => {
    if (!audioRef?.current) return
    audioRef.current.currentTime = 0
    audioRef.current.play()
  }, [])

  return useMemoAPI({ audioRef, ring })
}
