import _ from "lodash"
import React, { useMemo, useEffect } from "react"
import { UploadDocumentsContext } from "../components/ContextAndProvider"
import { useSelectedFileIDContext } from "../context/SelectedFileContext"
import { useUploadContext } from "../context/UploadContext"

export default function useFileSelectionManger() {
  const { uploadedFiles = [] } = useUploadContext()
  const { selectedFileID, setSelectedFileID } = React.useContext(
    UploadDocumentsContext
  )

  const reset = () => setSelectedFileID(null)
  const selectFirst = () => setSelectedFileID(_.get(uploadedFiles, "0.id"))

  const selectedFile = uploadedFiles.find(
    ({ id: fileID }) => fileID === selectedFileID
  )

  useEffect(() => {
    if (uploadedFiles.length === 0) {
      reset()
      return
    }

    if (!selectedFile) {
      selectFirst()
    }
  }, [!selectedFile, uploadedFiles.length])

  return useMemo(
    () => ({
      selectedFile,
      selectFile({ id } = {}) {
        if (!id) return
        setSelectedFileID(id)
      },
    }),
    [_.get(selectedFile, "id")]
  )
}
