import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { List } from "components/List/List"
import { Observer } from "components/Observer"
import ShopArticleCard from "components/ShopArticleCard/ShopArticleCard"
import React from "react"
import { StyledProps } from "types/type"
import { ShopArticlesGridContext } from "./ShopArticlesGridContext"
import { ShopArticleFragment } from "lib/graphql/operations"

export function Grid(props: StyledProps & { noVirtualization?: boolean }) {
  const c = useStyles()

  const api = React.useContext(ShopArticlesGridContext)

  return (
    <div
      style={{ ...props.style }}
      className={clsx("articles-grid", props.className)}
    >
      <List.Provider
        variant="grid"
        items={api.articles}
        fetchMore={api.fetchMore}
      >
        <List.Root
          style={{ ...props.style, height: "100%" }}
          noVirtualization={props.noVirtualization}
          itemContent={(
            index,
            entry: ShopArticleFragment | null | undefined
          ) => {
            const a = entry

            if (!a) return null

            if (!api.renderArticle) {
              return null
            }

            const onClickItem = () => {
              const fn = api.onClickArticle

              if (!a) return

              if (!fn) return

              return fn({
                article: a,
                index: index,
              })
            }

            return (
              <div onClick={onClickItem} className="grid-cell">
                {(() => {
                  const c = api.renderArticle?.({
                    article: a,
                    index,
                  })

                  return c || null
                })()}
              </div>
            )
          }}
        ></List.Root>
      </List.Provider>
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    cell: {
      "&>*": {
        height: "100%",
      },
    },
  }
})
