import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { makeStyles, Typography } from "@material-ui/core"
import { Variant } from "@material-ui/core/styles/createTypography"
import clsx from "clsx"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useTypographyClass from "hooks/useTypographyClass"
import React from "react"
import { LexicalEditorContext } from "../../contexts/LexicalEditorContext"

export default function LexicalEditorRoot(props: {
  className?: string
  style?: React.CSSProperties
  classes?: {
    inside?: string
  }
  PlaceholderProps?: {
    showEvenWhenNotEditable?: boolean
    text?: string
    // ref: React.RefObject<HTMLElement>
    className?: string
    style?: React.CSSProperties
  }
}) {
  const c = useStyles({})
  const api = React.useContext(LexicalEditorContext)

  const typographyClass = useTypographyClass()
  const t = useI18n()

  const variant: Variant = "body1"

  return (
    <div
      className={clsx(c.root, "lexical-editor-root", props.className)}
      style={props.style}
    >
      <div className={clsx(c.inside, props.classes?.inside)}>
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              className={clsx(
                c.contentEditable,
                "lexical-editor-content-editable",
                typographyClass[variant]
              )}
            ></ContentEditable>
          }
          ErrorBoundary={LexicalErrorBoundary}
          placeholder={
            <Typography
              variant={variant}
              // ref={props.PlaceholderProps?.ref}
              className={clsx(props.PlaceholderProps?.className, "placeholder")}
              style={props.PlaceholderProps?.style}
            >
              {(() => {
                const show =
                  props.PlaceholderProps?.showEvenWhenNotEditable ||
                  api.editable

                const text =
                  props.PlaceholderProps?.text || t["type-something-here"]

                return show ? text : ""
              })()}
            </Typography>
          }
        ></RichTextPlugin>
      </div>
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {},
    inside: {
      position: "relative",

      "& .placeholder": {
        position: "absolute",
        display: "inline-block",
        opacity: 0.5,
        pointerEvents: "none",

        left: theme.spacing(2),
        top: theme.spacing(2),
      },
    },

    contentEditable: {
      height: "100%",
      outline: "none",
      "&>*:first-child": { marginTop: 0 },
    },
  }
})
