import BasicDialog from "components/BasicDialog/BasicDialog"
import React from "react"
import { makeStyles } from "@material-ui/core"
import CollectionForms from "../Forms/Forms"
import DocumentCollectionsContext from "../../contexts/DocumentCollectionsContext"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"

export default function Dialog() {
  const c = useDialogStyles()

  const api = React.useContext(DocumentCollectionsContext)

  const deviceObserver = React.useContext(DeviceObserver.Context)

  return (
    <BasicDialog
      open={!!api.isDialogOpen}
      onClose={api.closePanel}
      classes={{ paper: c.dialogPaper }}
      isLoading={api.isLoading}
      showCloseIcon={false}
    >
      <CollectionForms isSmall={deviceObserver.isSmallMobile} />
    </BasicDialog>
  )
}
const useDialogStyles = makeStyles((theme) => {
  return {
    root: {},

    dialogPaper: {
      overflow: "visible",
      display: "table",
    },
  }
})
