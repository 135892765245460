import _ from "lodash"
import MonthCalendarRoot from "./components/MonthCalendarRoot/MonthCalendarRoot"
import WeekRow from "./components/WeekRow/WeekRow"
import MonthCalendarProvider from "./components/MonthCalendarProvider/MonthCalendarProvider"
import { Month } from "./types/index"
import MonthCalendarContext from "./contexts/MonthCalendarContext"
import MonthCalendarWithSelection from "./components/MonthCalendarWithSelection/MonthCalendarWithSelection"

export type { WeekProps } from "./components/MonthCalendarRoot/MonthCalendarRoot"
export type { Month, WeekDay } from "./types/index"

const MonthCalendar = {
  Week: WeekRow,
  Provider: MonthCalendarProvider,
  WithSelection: MonthCalendarWithSelection,
  Root: MonthCalendarRoot,
  Context: MonthCalendarContext,

  Default(
    props: { month: Month } & React.ComponentProps<typeof MonthCalendarRoot>
  ) {
    return (
      <MonthCalendarProvider month={props.month}>
        <MonthCalendarRoot {..._.omit(props, "month")}></MonthCalendarRoot>
      </MonthCalendarProvider>
    )
  },
}

export default MonthCalendar
