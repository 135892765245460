import { useMutation } from "@apollo/client";
import { RoomQueryVariables } from "types/type"
import _ from "lodash"
import React from "react"
import {
  ChangeRoomKanbanBoardDocument,
  KanbanBoardFragment,
  RoomKanbanBoardDocument,
} from "lib/graphql/operations"
import useMemoAPI from "hooks/useMemoAPI"
import { isNullableType } from "graphql"

export default function useChangeRoomKanbanBoard(props: RoomQueryVariables) {
  const [mutate] = useMutation(ChangeRoomKanbanBoardDocument)

  const changeRoomKanbanBoard = React.useCallback(
    async (newBoard: KanbanBoardFragment | null) => {
      const update = _.omit(newBoard, ["id", "__typename"])

      await mutate({
        variables: {
          workspaceID: props.workspaceID,
          roomID: props.roomID,
          board: newBoard
            ? {
                columns: (update.columns || []).map((col) => ({
                  ..._.omit(col, "__typename"),
                  cards: col.cards.map((card) => {
                    const cardInputFields = [
                      "id",
                      "columnID",
                      "description",
                      "title",
                    ]
                    return _.pick(card, cardInputFields)
                  }),
                })),
              }
            : null,
        },
        optimisticResponse: {
          changeRoomKanbanBoard: {
            id: newBoard?.id || Math.random() + "",
            notificationsCount: newBoard?.notificationsCount || 0,

            columns: (newBoard?.columns || []).map((col) => ({
              ...col,
              cards: (col?.cards || []).map((card) => ({
                ...card,
                notificationsCount: card?.notificationsCount || 0,
                __typename: "KanbanCard",
              })),
              __typename: "KanbanColumn",
            })),
            __typename: "KanbanBoard",
          },
        },
        update(proxy, { data }) {
          const newBoard = data?.changeRoomKanbanBoard

          proxy.writeQuery({
            query: RoomKanbanBoardDocument,
            variables: { roomID: props.roomID, workspaceID: props.workspaceID },
            data: {
              roomKanbanBoard: newBoard,
            },
          })
        },
      })
    },
    [props.roomID, props.workspaceID]
  )

  return useMemoAPI({ changeRoomKanbanBoard })
}
