import React, { useState, useRef, useEffect } from "react"
import useIsMounted from "hooks/useIsMounted"
import LeftSnackbar from "components/LeftSnackbar/LeftSnackbar"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { makeStyles, SnackbarContent } from "@material-ui/core"
import clsx from "clsx"

export default function RoomCreationSnackbar(props: { isCreating: boolean }) {
  const t = useI18n()
  const { isCreating } = props
  const [visible, setVisible] = useState(isCreating)
  const oldValueRef = React.useRef<boolean>()
  const isMounted = useIsMounted()

  useEffect(() => {
    if (!isMounted) return

    oldValueRef.current = !isCreating

    if (oldValueRef.current === true && !isCreating) {
      setTimeout(() => {
        setVisible(isCreating)
      }, 3000)
    } else {
      setVisible(isCreating)
    }
  }, [isCreating])

  const c = useStyles({})

  return (
    <LeftSnackbar open={visible}>
      <SnackbarContent
        className={clsx(
          "snackbar-content",
          c.snackbarContent,
          !isCreating && "success"
        )}
        message={isCreating ? t["creating-the-chat-room"] : t["success!"]}
      ></SnackbarContent>
    </LeftSnackbar>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    snackbarContent: {
      transition: "background-color 500ms !important",
      "&.success": {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }
})
