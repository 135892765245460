import { Tooltip, TooltipProps } from "@material-ui/core"
import _ from "lodash"
import React from "react"
import { FormControlLabel, Switch as MuiSwitch } from "@material-ui/core"
import useInputOnChange from "../../util/useInputOnChange"
import clsx from "clsx"
import { FieldInputProps } from "formik"

export default function Switch({
  value = false,
  className: classNameProps = "",
  onChange: propsOnChange,
  label = "",
  onBlur,
  name = "",
  TooltipProps = ({ value }) => ({
    placement: "bottom",
    title: value ? "active" : "inactive",
    className: "tooltip",
  }),
  disabled = false,
  ...other
}: FieldInputProps<boolean> & {
  label?: string
  className?: string
  TooltipProps?: (p: { value: boolean }) => Omit<TooltipProps, "children">
  disabled?: boolean
}) {
  // const {
  //   value = false,
  //   className: classNameProps = "",
  //   onChange: propsOnChange,
  //   label = "",
  //   onBlur = () => null,
  //   name = "",
  //   TooltipProps = ({ value }) => ({
  //     placement: "bottom",
  //     title: value ? "active" : "inactive",
  //     className: "tooltip",
  //   }),
  //   disabled = false,
  //   ...other
  // } = props

  const onChange = useInputOnChange({ onChange: propsOnChange })
  const className = clsx("switch", classNameProps)

  const switchComp = (
    <MuiSwitch
      checked={value}
      disabled={disabled}
      name={name}
      onChange={onChange}
      size="small"
      onBlur={onBlur}
    />
  )

  let comp = React.cloneElement(switchComp, { className })

  if (label)
    comp = (
      <FormControlLabel
        label={label}
        disabled={disabled}
        className={className}
        {...other}
        control={switchComp}
      />
    )

  const tooltipProps = _.isFunction(TooltipProps)
    ? TooltipProps({ value })
    : null

  if (tooltipProps)
    return (
      <Tooltip placement="bottom" {...tooltipProps}>
        <div>{comp}</div>
      </Tooltip>
    )

  return comp
}
