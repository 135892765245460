import { useTheme } from "@material-ui/core"
import TemplatePageProvider from "./components/TemplatePageProvider/TemplatePageProvider"
import TemplatePageRoot, {
  Indentation,
} from "./components/TemplatePageRoot/TemplatePageRoot"
import TemplatePageContext from "./contexts/TemplatePageContext"

export type { TemplatePageApi } from "./contexts/TemplatePageContext"

const TemplatePage = {
  Context: TemplatePageContext,
  Provider: TemplatePageProvider,
  Root: TemplatePageRoot,
  Indentation(props: React.ComponentProps<typeof Indentation>) {
    const theme = useTheme()

    return (
      <Indentation
        {...props}
        RightWrapperProps={{ style: { marginLeft: theme.spacing(-0.5) } }}
      ></Indentation>
    )
  },
}

export default TemplatePage
