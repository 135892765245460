import { IconButton, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import React from "react"
// import useCartItemController from "pages/Shop/hooks/useCartItemController"
import DelayedLoadingBar from "components/DelayedLoadingBar/DelayedLoadingBar"
// import IconButton from "components/IconButton/IconButton"
import { Icon } from "components/Icon/Icon"
import { ShopContext } from "components/Shop/ShopContext"
import { StyledProps } from "types/type"

export function CartItemQuantity(props: { articleID: string } & StyledProps) {
  const c = useStyles()

  const lowestNumber = 0

  const { changeQuantity, getItemQuantity } =
    React.useContext(ShopContext).cartApi

  const quantity = getItemQuantity({ articleID: props.articleID })

  const [inputValue, setInputValue] = React.useState(quantity)

  React.useEffect(() => {
    setInputValue(quantity)
  }, [quantity])

  const isLoading = changeQuantity.loading

  const addOne = () => {
    if (typeof quantity !== "number") return
    changeQuantity.action({
      quantity: quantity + 1,
      articleID: props.articleID,
    })
  }

  const subtractOne = () => {
    if (typeof quantity !== "number") return
    changeQuantity.action({
      quantity: Math.max(0, quantity - 1),
      articleID: props.articleID,
    })
  }

  if (typeof quantity !== "number") return null

  return (
    <div className={clsx(c.itemQuantity, props.className)}>
      <DelayedLoadingBar isLoading={isLoading}></DelayedLoadingBar>

      {(() => {
        const cantSubtract = quantity >= 1

        if (!cantSubtract) return null

        return (
          <IconButton
            className={clsx(!cantSubtract && c.hidden)}
            edge="start"
            size="small"
            // disabled={isLoading}
            onClick={subtractOne}
          >
            <Icon name="remove"></Icon>
          </IconButton>
        )
      })()}

      <TextField
        value={inputValue}
        onChange={(e) => {
          const text = Number(e.target.value)
          if (!isNaN(text)) setInputValue(text)
        }}
        onBlur={(e) => {
          const number = Number(e.target.value)
          const isAccepted = number >= lowestNumber

          changeQuantity.action({
            quantity: isAccepted ? number : lowestNumber,
            articleID: props.articleID,
          })
        }}
        className={c.textField}
      ></TextField>
      <IconButton edge="end" size="small" onClick={addOne}>
        <Icon name="add"></Icon>
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    hidden: {
      visibility: "hidden",
    },
    itemQuantity: {
      display: "flex",
      alignItems: "center",
    },
    textField: {
      width: "70%",
      "& input": {
        textAlign: "center",
      },
    },
  }
})
