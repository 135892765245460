import BasicDialog from "components/BasicDialog/BasicDialog"
import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import { useInputController } from "hooks/useInputController"

export default function Dialog(props: {
  Content?: React.FC<{
    onChangeLoadingState: (loadingState: boolean) => void
  }>
  renderContent?: (props: {
    onChangeLoadingState: (loadingState: boolean) => void
  }) => JSX.Element | null
  open: boolean
  onClose?: () => void
  children?: React.ReactNode
  className?: string
  classes?: { content?: string }
  showLoadingProgress?: boolean
}) {
  const c = useStyles({})
  const { inputValue: isLoading, setInputValue: setIsLoading } =
    useInputController({ value: props.showLoadingProgress })

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      isLoading={isLoading || false}
      className={clsx(c.root, "dialog", props.className)}
      classes={{ content: props.classes?.content }}
    >
      {(() => {
        if (!props.open) return null

        if (props.Content) {
          return <props.Content onChangeLoadingState={setIsLoading} />
        }

        if (props.renderContent) {
          return props.renderContent({ onChangeLoadingState: setIsLoading })
        }

        if (props.children) return props.children

        return null
      })()}
    </BasicDialog>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {},
  }
})
