import { makeStyles } from "@material-ui/core"
import _ from "lodash"
// import
import React from "react"
import DocumentCollectionsContext from "components/DocumentCollections/contexts/DocumentCollectionsContext"
import { AddNewPartner as AddNewPartnerBasic } from "components/AddNewKontakt/index"

export default {
  ...AddNewPartnerBasic,
  Provider: AddNewPartnerProvider,
  Default: AddNewPartnerDefault,
}

function AddNewPartnerProvider(
  props: Omit<
    React.ComponentProps<typeof AddNewPartnerBasic.Default>,
    "partnerName" | "partnerType"
  > & { children: React.ReactNode }
) {
  const api = React.useContext(DocumentCollectionsContext)

  return (
    <AddNewPartnerBasic.Provider
      {...props}
      partnerName={api.form.collectionText}
      partnerType={api.form.type}
      onSubmit={({ addedPartner }) => {
        props.onSubmit?.call(undefined, { addedPartner })

        const collectionID = addedPartner?.collection?.id || null
        if (!collectionID) return

        api.setSelectedCollectionID(collectionID)
      }}
    >
      {props.children}
    </AddNewPartnerBasic.Provider>
  )
}

function AddNewPartnerDefault(
  props: {
    className?: string
    isSmall?: boolean
  } & React.ComponentProps<typeof AddNewPartnerBasic.Default>
) {
  return (
    <AddNewPartnerProvider {..._.omit(props, "children")}>
      <AddNewPartnerBasic.Root></AddNewPartnerBasic.Root>
    </AddNewPartnerProvider>
  )
}
