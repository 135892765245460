import React from "react"
import {
  aspectRatioEnums,
  overlayModes,
} from "components/ChatOverlay/constants"

const ChatOverlayContext = React.createContext({
  floatingButtonPosition: { x: 0, y: 0 },
  setFloatingButtonPosition({ x = 0, y = 0 }) {},
  overlayZIndex: 0,
  setOverlayZIndex(zIndex = 0) {},
  dragOverlay({ position: { x = 0, y = 0 } }) {},
  overlayPosition: { x: undefined, y: undefined },
  aspectRatio: Object.values(aspectRatioEnums)[0],
  dimensions: { width: undefined, height: undefined },
  setAspectRatio(aspectRatio) {},
  overlayIsOpen: false,
  closeOverlay() {},
  toggleOverlay() {},
  openOverlay({
    zIndex = undefined,
    mode = overlayModes.WINDOW_OVERLAY,
    roomIdentifier = {
      userID: undefined,
      roomID: undefined,
      documentID: undefined,
    },
  } = {}) {},
  goToRoom({ userID = undefined, roomID = undefined }) {},
  setRoomID(roomID) {},
  roomID: undefined,
  roomIdentifier: { roomID: undefined, userID: undefined },
  setRoomIdentifier(identifier = { roomID: undefined, userID: undefined }) {},
  overlayMode: overlayModes.DRAGGABLE,

  chatBalloonIsHidden: false,
  hideChatBalloon() {},
  showChatBalloon() {},
})

ChatOverlayContext.displayName = "ChatOverlayContext"

export default ChatOverlayContext
