import React from "react"
import { useContext } from "react"
import { RoomContext } from "components/Room/contexts/room"
import basicTabs, { RoomTabIDs } from "../../tabs/basicTabs"
import useMemoAPI from "hooks/useMemoAPI"
import useSearchParams from "hooks/useSearchParams/useSearchParams"
import { RoomType } from "lib/graphql/types"
import CollectionRoomAPI from "components/Room/hooks/useResolveRoom/lib/RoomResolver/CollectionRoomAPI/CollectionRoomAPI"
import DocumentRoomAPI from "components/Room/hooks/useResolveRoom/lib/RoomResolver/DocumentRoomAPI/DocumentRoomAPI"
import { useLocation } from "react-router"
import { useGetRoomShareableLink } from "hooks/useActiveRoomShareableLink/useGetRoomShareableLink"
import useOnChange from "hooks/useOnChange"
import { RoomFragment } from "lib/graphql/operations"

const Tabs: {
  [key in RoomType]?: Record<
    string,
    { label: React.ReactNode; component: React.ReactNode }
  >
} = {
  [RoomType.Collection]: CollectionRoomAPI.tabs,
  [RoomType.Document]: DocumentRoomAPI.tabs,
}

export default function useTabs<Tabs extends typeof basicTabs>(props: {
  tabs?: Tabs | undefined
  searchParams: ReturnType<typeof useSearchParams>
  room: RoomFragment | null
}) {
  const room = props.room

  const tabs = React.useMemo(() => {
    if (props.tabs) return props.tabs

    const roomType = room?.type
    if (!roomType) return basicTabs

    if (!!Tabs[roomType]) return Tabs[roomType]

    return basicTabs
  }, [room?.type, props.tabs])

  const setTabID = React.useCallback(
    (tabID: Exclude<keyof Tabs, symbol>) => {
      props.searchParams.set({ ["tab-id"]: tabID })
    },
    [props.searchParams]
  )

  const [selectedCardID, setSelectedCardID] = React.useState(
    props.searchParams.get(["kanban-card-id"])[0]
  )

  // const selectedCardID = props.searchParams.get(["kanban-card-id"])[0]

  // const setSelectedCardID = React.useCallback(
  //   (cardID) => {
  //     props.searchParams.set({
  //       ["kanban-card-id"]: cardID,
  //     })
  //   },
  //   [props.searchParams]
  // )

  useOnChange({
    value: selectedCardID,
    onChange: (cardID) => {
      props.searchParams.set({
        ["kanban-card-id"]: cardID,
      })
    },
  })

  // const location = useLocation()

  const getRoomShareableLink = useGetRoomShareableLink()

  const appendKanbanCardSearchParams = React.useCallback(
    (p: { url: URL; cardID: string }) => {
      const cardKey = props.searchParams.getKey("kanban-card-id")
      if (!cardKey) return ""

      props.searchParams.urlSearchParams.forEach((value, key) => {
        p.url.searchParams.append(key, value)
      })

      p.url.searchParams.set(cardKey, p.cardID + "")
    },
    [props.searchParams]
  )

  const getCardLink = React.useCallback(
    (cardID: string) => {
      const shareableLink = getRoomShareableLink(room?.id)

      if (!shareableLink) return ""

      const url = new URL(shareableLink)

      // const cardKey = props.searchParams.getKey("kanban-card-id")
      // if (!cardKey) return ""

      // props.searchParams.urlSearchParams.forEach((value, key) => {
      //   url.searchParams.append(key, value)
      // })

      // url.searchParams.set(cardKey, cardID + "")
      appendKanbanCardSearchParams({ url, cardID })

      return url.href
    },
    [
      props.searchParams,
      // location,
      getRoomShareableLink,
      room?.id,
      appendKanbanCardSearchParams,
    ]
  )

  const kanban = useMemoAPI({
    getCardLink,
    selectedCardID: selectedCardID + "",
    setSelectedCardID,
    appendCardSearchParams: appendKanbanCardSearchParams,
  })

  return useMemoAPI({
    setTabID,
    tabs,
    tabID: props.searchParams.get(["tab-id"])[0],
    kanban,
  })
}
