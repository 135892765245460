import _ from "lodash"
import React, { useMemo, useContext } from "react"
// import {
//   useUtilsContext,
//   useParamsContext,
// } from "pages/Partners/contexts/libContext"
import CollectionsTypesFilter from "components/CollectionsTypesFilter/CollectionsTypesFilter"
import AppBarWithReactiveSearch from "components/AppBarWithReactiveSearch/AppBarWithReactiveSearch"
import { useLoadingState } from "components/PageLayoutFromPageID/PageLayoutFromPageID"
import { useI18n } from "contexts/i18nContext/i18nContext"
import AddPartner from "./components/AddPartner/AddPartner"
import PartnersContext from "../../contexts/PartnersContext"

export default function MainAppBar({
  Left = undefined,
  renderRight: propsRenderRight = ({
    defaultComponent,
    components: { search, settings },
    isMobileSearchActive,
  }) => defaultComponent,
}) {
  // const { setSearchedTerm } = useUtilsContext()
  // const { searchedTerm } = useParamsContext() || {}
  const { setSearchedTerm, searchedTerm } = useContext(PartnersContext)

  const [isLoading] = useLoadingState()
  const t = useI18n()

  const renderRight = useMemo(() => {
    return (props) => <Right {...props} render={propsRenderRight}></Right>
  }, [propsRenderRight])

  return (
    <AppBarWithReactiveSearch
      title={t.contacts}
      showProgressBar={isLoading}
      search={{
        onChange: setSearchedTerm,
        value: searchedTerm,
        debounce: 1000,
      }}
      right={renderRight}
      left={Left}
    ></AppBarWithReactiveSearch>
  )
}

function Right({ components, isMobileSearchActive, render }) {
  const { search, settings } = components || {}
  const { types, setTypes } = useContext(PartnersContext)

  const defaultComponent = (
    <>
      {search}
      <CollectionsTypesFilter
        value={types}
        onChange={setTypes}
        typesToOmit={["DOCUMENT"]}
        edge="end"
      />
      {!isMobileSearchActive && <AddPartner></AddPartner>}
      {!isMobileSearchActive && settings}
    </>
  )

  if (typeof render === "function")
    return render({
      components: { search, settings },
      defaultComponent,
      isMobileSearchActive,
    })

  return defaultComponent

  return "oi"
}
