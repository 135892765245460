import ContactForm from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ContactForm/ContactForm"
import { Icon } from "components/Icon/Icon"
import I18nContext from "contexts/i18nContext/i18nContext"
import { FormCommons } from "../FormCommons/FormCommons"
import { PageBoardItem } from "../../PageBoardItem"
import { ContactPageBoardItemConsumer } from "./ContactPageBoardItemApi/ContactPageBoardItemApi"
import { useCreateRoomMiniPage } from "./hooks/useCreateRoomMiniPage"

export function ContactPageBoardItemBody() {
  const openPage = useCreateRoomMiniPage()

  return (
    <ContactPageBoardItemConsumer>
      {(api) => {
        const contact = api.contact

        if (!contact) return null

        return (
          <FormCommons.Freezer>
            <ContactForm.Root
              fieldsetProps={(args) => {
                const f = args.fieldset
                return [
                  f.personal,
                  f.business,
                  f.richText,
                  f.partner,
                  f.settings,
                  f.timeLog,

                  PageBoardItem.getRelatedRoomsFieldProps({
                    RelatedItemsChipsProps: {
                      filter: (a) => a?.__typename === "RoomPageBoardItem",
                      additionalChips: [
                        {
                          key: "add-room",
                          color: "primary",
                          variant: "outlined",
                          label: (
                            <I18nContext.Consumer>
                              {(t) => t["room"]}
                            </I18nContext.Consumer>
                          ),
                          icon: <Icon name="add"></Icon>,
                          onClick: () => {
                            openPage()
                          },
                        },
                      ],
                    },
                  }),
                  f.lastDocuments,
                ]
              }}
              lastDocuments={
                <FormCommons.RelatedDocumentsChips
                  itemParams={
                    api.entry
                      ? {
                          itemID: api.entry.itemID || "",
                          type: api.entry.type,
                        }
                      : undefined
                  }
                  openUploadPageArgs={{
                    UploadDocumentsProps: {
                      collectionsIDs: [contact.collectionID || ""],
                    },
                  }}
                  openAllDocumentsMiniPageArgs={{
                    collectionId: contact.collectionID || "",
                  }}
                ></FormCommons.RelatedDocumentsChips>
              }
            ></ContactForm.Root>
          </FormCommons.Freezer>
        )
      }}
    </ContactPageBoardItemConsumer>
  )
}
