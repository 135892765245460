import React from "react"
import useDocumentCollectionsAPI from "../hooks/useDocumentCollectionsAPI"

type DocumentCollectionsContextAPI = ReturnType<
  typeof useDocumentCollectionsAPI
>

const DocumentCollectionsContext = React.createContext(
  {} as DocumentCollectionsContextAPI
)

DocumentCollectionsContext.displayName = "DocumentCollectionsContext"

export default DocumentCollectionsContext
