import { useQuery, gql } from "@apollo/client";
import _ from "lodash"
// import useMemoAPI from "hooks/useMemoAPI/useMem"
import { useState, useEffect, useCallback, useMemo } from "react"
import useActiveResources from "hooks/useActiveResources"
import useOnChange from "hooks/useOnChange"
import {
  CollectionItemFragmentDoc,
  CollectionsItemsDocument,
} from "lib/graphql/operations"
import useMemoAPI from "hooks/useMemoAPI"
// import { useMemo } from "react-transition-group/node_modules/@types/react"

const SEARCH_TYPES = ["document-collections", "collections"]

//REMOVE
export default function useCollectionsSearch({
  searchTerm = "",
  types = [],
  limit: propsLimit = 30,
  initialLimit = 0,
  maxLimit = 1000,
  identifiers = undefined,
  searchType = "collections",
  fetchPolicy = "cache-and-network",
  onChangeLoadingState = () => null,
}) {
  if (!SEARCH_TYPES.includes(searchType))
    throw new Error(
      `$searchType should assume one of the values ${SEARCH_TYPES}`
    )

  initialLimit = initialLimit || propsLimit

  const { workspaceID } = useActiveResources()
  const [limit, setLimit] = useState(initialLimit)

  const queries = {
    "document-collections": SEARCH_DOCUMENT_COLLECTIONS,
    collections: CollectionsItemsDocument,
  }

  useEffect(() => {
    setLimit(initialLimit)
  }, [searchTerm, (types || []).length])

  const { data, loading } = useQuery(queries[searchType], {
    fetchPolicy,
    variables: _.pickBy(
      {
        search: searchTerm,
        types,
        workspaceID,
        identifiers,
        limit,
      },
      (v) => typeof v !== "undefined"
    ),
  })

  useOnChange({ value: loading, onChange: onChangeLoadingState })

  const fetchMore = useCallback(
    function fetchMore(amount = 50) {
      const newLimit = limit + (amount || 0)
      if (!!maxLimit && maxLimit < newLimit) return
      setLimit(newLimit)
    },

    [limit, maxLimit]
  )
  const collections = useMemo(() => {
    const queryFields = {
      "document-collections": "searchDocumentCollections",
      collections: "collectionsItems",
    }

    return _.get(data, queryFields[searchType])
  }, [data, searchType])

  const result = useMemoAPI({
    fetchMore,
    collections,
    isLoading: loading,
  })

  return result
}

const SEARCH_DOCUMENT_COLLECTIONS = gql`
  ${CollectionItemFragmentDoc}
  query SearchDocumentCollections(
    $search: String
    $limit: Int
    $workspaceID: ID!
    $types: [String!]
  ) {
    searchDocumentCollections(
      search: $search
      limit: $limit
      workspaceID: $workspaceID
      types: $types
    ) {
      ...CollectionItem
    }
  }
`
