import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import useOnChange from "hooks/useOnChange"
import useStateController from "hooks/useStateController"
import React from "react"
import { StyledProps } from "types/type"

export const SnappedCarousel = React.forwardRef(SnappedCarouselComponent)

export type SnappedCarouselApi = {
  scrollSmoothly: (props: { index: number }) => void
}

export type SnappedCarouselProps = {
  activeIndex?: number
  setActiveIndex?: (index: number) => void
  count: number
  children: (props: { index: number }) => React.ReactNode
  classes?: { snapped?: string }
} & StyledProps

function SnappedCarouselComponent(
  props: SnappedCarouselProps,
  ref: React.Ref<SnappedCarouselApi>
) {
  // const { activeIndex, setActiveIndex } = props

  const [activeIndex, setActiveIndex] = useStateController({
    onChange: props.setActiveIndex,
    value: props.activeIndex || 0,
  })

  const c = useStyles()
  const carouselRef = React.useRef<HTMLDivElement>(null)

  const scrollTo = React.useCallback(
    (props: { index: number; behavior?: ScrollBehavior }) => {
      const squareWidth = (carouselRef.current?.firstChild as HTMLElement)
        ?.clientWidth

      if (squareWidth) {
        const newScrollLeft = props.index * squareWidth

        carouselRef.current?.scrollTo({
          left: newScrollLeft,
          behavior: props.behavior,
        })
      }
    },
    []
  )

  React.useImperativeHandle(ref, () => ({
    scrollSmoothly: (props) => {
      scrollTo({ index: props.index, behavior: "smooth" })
    },
  }))

  // Effect to scroll to the activeIndex when it changes
  React.useEffect(() => {
    setTimeout(() => {
      scrollTo({ index: activeIndex, behavior: "instant" })
    }, 0)
  }, [])

  useOnChange({
    value: activeIndex,
    onChange: (index) => {
      scrollTo({ index: activeIndex, behavior: "smooth" })
      // setTimeout(() => {
      // }, 0)
    },
  })

  React.useEffect(() => {
    const carousel = carouselRef.current
    let cancelID: NodeJS.Timeout | null = null

    const handleScroll = () => {
      if (cancelID) {
        clearTimeout(cancelID)
      }

      cancelID = setTimeout(() => {
        if (!carouselRef.current) return

        const scrollLeft = carouselRef.current?.scrollLeft || 0
        const squareWidth = (carouselRef.current?.firstChild as HTMLElement)
          ?.clientWidth

        if (squareWidth) {
          const index = Math.floor(scrollLeft / squareWidth)
          setActiveIndex(index)
        }
      }, 100)
    }

    carousel?.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      carousel?.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div className={clsx(c.root, props.className)} style={props.style}>
      <div className={c.carousel} ref={carouselRef}>
        {Array.from({ length: props.count }).map((_, index) => (
          <div key={index} className={clsx(c.snapped, props.classes?.snapped)}>
            {props.children({ index })}
            {/* <>oi</> */}
          </div>
        ))}
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    position: "relative",
  },

  carousel: {
    display: "flex",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    "-webkit-overflow-scrolling": "touch",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
  snapped: {
    flex: "0 0 auto",
    width: "100%", // Your fixed width
    margin: 0,
    // display: "flex",
    scrollSnapAlign: "start",
  },
})
