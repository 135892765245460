import React from "react"
import useApolloSetupAPI from "../hooks/useApolloSetupAPI"

export const ApolloSetupContext = React.createContext(
  {} as ReturnType<typeof useApolloSetupAPI>
)

ApolloSetupContext.displayName = "ApolloSetupContext"

export default ApolloSetupContext
