import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { useContactPageBoardItem } from "./useContactPageBoardItem"

const Factory = contextAndProviderFactory({ hookApi: useContactPageBoardItem })

export type { ContactPageBoardItemApiInput } from "./useContactPageBoardItem"

export const ContactPageBoardItemContext = Factory.Context
export const ContactPageBoardItemProvider = Factory.Provider
export const ContactPageBoardItemConsumer = Factory.Consumer
