import { Chip, Typography, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import Avatar from "components/Avatar/Avatar"
import ClippedText from "components/ClippedText/ClippedText"
import { Icon, IconProps } from "components/Icon/Icon"
import extractText from "components/LexicalEditor/lib/extractText"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import useTypesOptions from "components/PageBoard/hooks/useTypesOptions"
import PaperList from "components/PaperList"
import { green, purple } from "constants/colors"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { excludeNullable } from "lib/excludeNullable"
import {
  NewPageBoardItem_KanbanCardCommentPageBoardItem_Fragment,
  NewPageBoardItem_KanbanCardPageBoardItem_Fragment,
} from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import EntryRight from "../EntryRight/EntryRight"
import { ItemGridCell, ItemGridCellBody } from "../ItemGridCell/ItemGridCell"
import ListItem from "../ListItem/ListItem"
import Color from "color"

type KanbanCardItemEntry =
  | NewPageBoardItem_KanbanCardPageBoardItem_Fragment
  | NewPageBoardItem_KanbanCardCommentPageBoardItem_Fragment

export type KanbanCardItemProps = {
  entry: KanbanCardItemEntry

  title: string

  className?: string
  text?: string
  renderAvatar?: (props: {
    defaultComponent: React.ReactNode
  }) => React.ReactNode

  style?: React.CSSProperties
  renderText?: (props: {
    defaultComponent?: React.ReactNode
  }) => React.ReactNode
  badge?: React.ReactNode
  ListItemProps?: Omit<React.ComponentProps<typeof ListItem>, "right" | "left">
}

export default function KanbanCardItem(props: KanbanCardItemProps) {
  const c = useStyles()
  const t = useI18n()

  const api = React.useContext(ItemsListContext)

  const rootContainer = api.rootContainer

  const maxSize = rootContainer.width < 700 ? 150 : 500

  const options = useTypesOptions()

  if (!props.entry) return null

  const title = props.title || t["taskWithoutTitle"]

  const text = props.text

  const formattedText = extractText({
    namespace: ["kanban-card-list-item", props.entry.id].join("_"),
    value: text || "",
  })

  const wrapInBadge = ({
    component,
    badgeStyle,
  }: {
    component: React.ReactNode
    badgeStyle?: React.CSSProperties
  }) => {
    return (
      <div className={clsx(c.badgeWrapper)}>
        {component}
        <div className="badge" style={badgeStyle}>
          {props.badge}
        </div>
      </div>
    )
  }

  if (api.variant === "grid") {
    return (
      <ItemGridCell
        timestamp={props.entry.lastUpdate}
        title={title}
        descriptionText={
          props.renderText?.({ defaultComponent: formattedText }) ||
          formattedText ||
          ""
        }
        entry={props.entry}
        renderIcon={(p) => {
          return wrapInBadge({
            component: p.defaultComponent,
            badgeStyle: { right: -5, bottom: 0, position: "absolute" },
          })
        }}
        AppCardProps={{
          body: (
            <ItemGridCellBody>
              {(a) => {
                return (
                  <React.Fragment>
                    {a.title}
                    {a.description}
                    <Tag entry={props.entry}></Tag>
                    {a.bottom}
                  </React.Fragment>
                )
              }}
            </ItemGridCellBody>
          ),
        }}
        buttons={[
          {
            icon: options?.[PageBoardItemType.Room]?.icon as IconProps["name"],
            onClick: () => {},
          },
        ]
          .map((a) => (a.icon ? a : null))
          .filter(excludeNullable)}
      ></ItemGridCell>
    )
  }

  return (
    <ListItem
      className={clsx(c.root, "indexed-item", props.className)}
      style={props.style}
      entry={props.entry}
      right={
        <EntryRight
          iconButton={{ icon: options?.[PageBoardItemType.Room]?.icon }}
          lastUpdate={props.entry.lastUpdate}
        ></EntryRight>
      }
      left={
        <React.Fragment>
          {(() => {
            const avatar = (
              <Avatar
                backgroundColor={options[PageBoardItemType.KanbanCard]?.color}
                content={(() => {
                  const icon = options[PageBoardItemType.KanbanCard]?.icon || ""

                  if (!icon) return null
                  return <Icon name={icon}></Icon>
                })()}
              />
            )

            let defaultComponent = (() => {
              if (props.badge) {
                return wrapInBadge({ component: avatar })
              }

              return avatar
            })()

            if (props.renderAvatar)
              return props.renderAvatar({ defaultComponent })

            return defaultComponent
          })()}
          <div style={{ marginLeft: 8 }}></div>
          <PaperList.ListItemText
            primary={(() => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body1" color="textPrimary">
                    <ClippedText maxWidth={maxSize}>{title}</ClippedText>
                  </Typography>
                </div>
              )
            })()}
            secondary={(() => {
              if (!text) return null

              const defaultComponent = (
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  <ClippedText maxWidth={maxSize}>{formattedText}</ClippedText>
                </Typography>
              )

              if (props.renderText)
                return props.renderText({ defaultComponent })

              return defaultComponent
            })()}
          ></PaperList.ListItemText>
        </React.Fragment>
      }
      {...(props.ListItemProps || {})}
    ></ListItem>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {},

    notificationsOffIcon: {
      color: theme.palette.text.secondary,
    },

    badgeWrapper: {
      position: "relative",
      "&>.badge": {
        "&>*": {
          background: purple[2],
          // content: "''",
          color: "white",
        },
        // display: "block",
        right: 0,
        bottom: -7,
        position: "absolute",

        // position: 'absolute',
      },
    },
  }
})

function Tag(props: { entry: KanbanCardItemEntry }) {
  // const a = props.entry.

  const tags = (() => {
    if (props.entry.__typename === "KanbanCardPageBoardItem") {
      return props.entry.card?.tags
    }

    if (props.entry.__typename === "KanbanCardCommentPageBoardItem") {
      return props.entry.cardSummary?.tags
    }

    return null
  })()

  return (
    <div>
      {(tags || []).map((a, i) => {
        const colorPair = (color: string) => {
          const isDark = new Color(color).isDark()

          return {
            text: isDark ? "white" : "black",
            background: isDark ? new Color(color).lighten(0.5).string() : color,
          }
        }

        return (
          <Chip
            size="small"
            label={a.name}
            variant="outlined"
            // style={{
            //   borderColor: a.color,
            //   color: a.color,
            // }}
          ></Chip>
          // <div
          //   key={i}
          //   style={{
          //     display: "inline-block",
          //     marginRight: 4,
          //     background: a.color,
          //     width: "25%",
          //     height: 10,
          //     borderRadius: 6,
          //   }}
          // />
        )
      })}
    </div>
  )
}
