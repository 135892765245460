import { Icon, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import Avatar from "components/Avatar/Avatar"
import ClippedText from "components/ClippedText/ClippedText"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import formatDate from "components/PageBoard/components/IndexedItems/components/ItemsList/lib/formatDate"
import useTypesOptions from "components/PageBoard/hooks/useTypesOptions"
import PaperList from "components/PaperList"
import { NewPageBoardItem_MessagePageBoardItem_Fragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import arePropsEqual from "util/arePropsEqual"
import { ListEntryProps } from "../../types"
import { ItemGridCell } from "../ItemGridCell/ItemGridCell"
import ListItem from "../ListItem/ListItem"
import { MessageLine } from "../MessageLine/MessageLine"

export default function MessageListItem(props: ListEntryProps) {
  if (props.entry?.__typename !== "MessagePageBoardItem") return null

  return (
    <MemoizedItem
      entry={props.entry}
      className={props.className}
      style={props.style}
    />
  )
}

const MemoizedItem = React.memo(
  Item,
  arePropsEqual([
    (a) => a.entry?.id,
    (a) => a.entry?.message?.roomName,
    (a) => a.entry?.message?.text,
    (a) => a.entry?.message?.author.shortName,
    // (a) => a.entry?.message.author.name,
    // (a) => a.entry?.user?.status,
    // (a) => a.entry?.user?.company,
  ])
)

function Item(props: {
  entry: NewPageBoardItem_MessagePageBoardItem_Fragment
  className?: string
  style?: React.CSSProperties
}) {
  const c = useStyles()

  const options = useTypesOptions()
  // const { RootContainer } = React.useContext(PaperList.Context)

  // const rootContainer = React.useContext(RootContainer.Context)
  const api = React.useContext(ItemsListContext)

  const rootContainer = api.rootContainer

  const maxSize = rootContainer.width < 700 ? 150 : 500

  // const { goToRoom } = useGoToPrivateRoom({
  //   userID: props.entry?.user.id || "",

  // })
  const timestamp = props.entry.message?.sentAt || ""

  const description = (
    <MessageLine
      authorName={props.entry.message?.author.shortName || ""}
      text={props.entry?.message?.text || ""}
    />
  )

  const title = props.entry.message?.roomName

  if (api.variant === "grid" && !!props.entry) {
    return (
      <ItemGridCell
        title={title}
        descriptionText={description}
        entry={props.entry}
        timestamp={timestamp}
        imageThumbnailUrl={props.entry.message?.featuringImageSrc || ""}
      ></ItemGridCell>
    )
  }

  if (!props.entry) return null

  return (
    <ListItem
      className={clsx(c.root, "indexed-item", props.className)}
      style={props.style}
      entry={props.entry}
      // right={
      //   <StopPropagation>
      //     <IconButton onClick={goToRoom} size="small">
      //       <Icon style={{ fontSize: 22 }} color="primary">
      //         message
      //       </Icon>
      //     </IconButton>
      //   </StopPropagation>
      // }

      right={
        <PaperList.ListItemText
          {...(() => {
            if (!!timestamp)
              return {
                primary: (
                  <Typography variant="body2" color="textSecondary">
                    {formatDate(timestamp, rootContainer.width > 400)}
                  </Typography>
                ),
              }

            return {}
          })()}
        ></PaperList.ListItemText>
      }
      left={
        <React.Fragment>
          {/* <UserAvatar user={props.entry.user} /> */}

          <Avatar
            content={<Icon>{options[PageBoardItemType.Message]?.icon}</Icon>}
            backgroundColor={options[PageBoardItemType.Message]?.color}
          ></Avatar>

          <div style={{ marginLeft: 8 }}></div>
          <PaperList.ListItemText
            primary={(() => {
              return (
                <Typography variant="body1" color="textPrimary">
                  <ClippedText maxWidth={maxSize}>{title}</ClippedText>
                </Typography>
              )
            })()}
            secondary={(() => {
              // if (!props.entry.user.account?.name) return null

              if (!description) return null

              return (
                <Typography color="textSecondary" variant="body2">
                  {/* <ClippedText maxWidth={maxSize}> */}
                  {description}
                  {/* </ClippedText> */}
                </Typography>
              )
            })()}
          ></PaperList.ListItemText>
        </React.Fragment>
      }
    ></ListItem>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {},
  }
})
