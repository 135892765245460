import useActiveResources from "hooks/useActiveResources"
import { LiveQueryInput, useLiveQuery } from "hooks/useLiveQuery"
import {
  PageBoardItemsNotificationsSubscriptionDocument,
  useDeletedPageBoardItemSubscription,
  usePageBoardItemsNotificationsQuery,
} from "lib/graphql/operations"
import React from "react"
import { PageBoardItemParams } from "lib/graphql/types"

export default function usePageBoardNotifications(props: {
  selected: PageBoardItemParams | null
}) {
  const r = useActiveResources()

  const query = useNotificationsLiveQuery({
    workspaceID: r.workspaceID,
  })

  useDeletedPageBoardItemSubscription({
    variables: { workspaceID: r.workspaceID },
    onSubscriptionData(options) {},
  })

  const activeID = props.selected?.itemID

  const notifications = React.useMemo(() => {
    return (query.data?.pageBoardItemsNotifications || [])
      .filter((n) => {
        return n.item.itemID !== activeID
      })
      .filter((n) => {
        return !!n.item.notificationCount
      })
  }, [query.data?.pageBoardItemsNotifications, activeID])

  return notifications
}

function useNotificationsLiveQuery(props: { workspaceID: string }) {
  type Input = LiveQueryInput<typeof usePageBoardItemsNotificationsQuery>

  const queryProps: Input = {
    useQuery: usePageBoardItemsNotificationsQuery,
    options: React.useRef<Input["options"]>({
      variables: { workspaceID: props.workspaceID },
      fetchPolicy: "cache-and-network",
      skip: !props.workspaceID,
    }).current,
    subscriptionDocument: PageBoardItemsNotificationsSubscriptionDocument,
    arrayOptions: React.useRef<Input["arrayOptions"]>({
      unicityFn(a) {
        return a.id
      },
      filter(a) {
        return !!a?.item?.notificationCount
      },
    }).current,
  }

  const query = useLiveQuery(queryProps)

  return query
}
