import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import CalendarEventContext from "../../contexts/CalendarEventContext"
import { PageForm } from "components/Page/Page"
import {
  EnhancedPageProps,
  EnhancedPage,
} from "components/EnhancedPage/EnhancedPage"
import TwoViewsWithTransition from "components/ViewsWithTransition/TwoViewsWithTransition"
import FrameWithLoadingProgress from "components/FrameWithLoadingProgress/FrameWithLoadingProgress"
import SecondPageAPI from "components/Page/components/SecondPageAPI/SecondPageAPI"
import moment from "moment"
import Description from "./Description"
import ControlButtons from "./ControlButtons"
import MembersList from "./MembersList"

export default function EventVisualization(props: {
  classes?: { content?: string }
  className?: string
  style?: React.CSSProperties
}) {
  const c = useStyles()

  return (
    <SecondPageAPI.Provider>
      <SecondPageAPI.Context.Consumer>
        {(secondPage) => {
          return (
            <TwoViewsWithTransition
              className={clsx(c.root, "event-form", props.className)}
              style={props.style}
              views={[<Visualization></Visualization>, secondPage.view]}
            ></TwoViewsWithTransition>
          )
        }}
      </SecondPageAPI.Context.Consumer>
    </SecondPageAPI.Provider>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {},
    squareLabel: {
      background: theme.palette.secondary.light,
      width: 30,
      height: 30,
      borderRadius: 12,
    },
  }
})

function Visualization() {
  const ctx = React.useContext(CalendarEventContext)

  const form = ctx.form

  const c = useStyles()

  const renderTitleLine = React.useCallback(
    (() => {
      const r: EnhancedPageProps["renderTitleLine"] = function render({
        titleComponent,
      }) {
        return (
          <>
            {titleComponent}
            <ControlButtons></ControlButtons>
          </>
        )
      }

      return r
    })(),
    [ctx.form.readyToSubmit, ctx.form.submit, ctx.onClose]
  )

  const e = ctx.event
  if (!e) return null

  const body = (
    <PageForm
      hideFieldBottomLine
      fieldsIndentationsProps={[
        {
          children: (
            <>
              <Typography variant="h6">{e?.title}</Typography>

              <Typography variant="body1" color="textSecondary">
                {(() => {
                  const start = moment(e?.startDateTime)
                  const end = moment(e?.endDateTime)

                  const format = "dddd, DD MMM YYYY"

                  if (start.isSame(end, "day")) return start.format(format)

                  return (
                    <span>
                      {start.format(format) + " —"}
                      <br></br>
                      {end.format(format)}
                    </span>
                  )
                })()}
              </Typography>
            </>
          ),
        },
        Description.isVisible({ event: e }) && {
          icon: "subject",
          children: (
            <>
              <Description.Component event={e}></Description.Component>
            </>
          ),
        },
        MembersList.isVisible({ event: e }) && {
          icon: "people_outline",
          children: (
            <>
              <MembersList.Component event={e}></MembersList.Component>
            </>
          ),
        },
      ]}
    ></PageForm>
  )

  return (
    <FrameWithLoadingProgress
      className={clsx("visualization", c.root)}
      isLoading={form.isSubmitting}
    >
      <EnhancedPage
        onClose={ctx.onClose}
        onCloseIconName="close"
        style={{ height: "100%" }}
        renderTitleLine={renderTitleLine}
        body={body}
      ></EnhancedPage>
    </FrameWithLoadingProgress>
  )
}
