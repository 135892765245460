import { Chip, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import MessagesContext from "components/Room/components/Messages/contexts/MessagesContext"
import RoomTemplateContext from "components/Room/components/RoomTemplate/contexts/RoomTemplateContext"
import moment from "moment"
import { useObservable, useObservableState } from "observable-hooks"
import React from "react"
import { merge, Observable, of, timer } from "rxjs"
import { filter, map, switchMap, withLatestFrom } from "rxjs/operators"

export default function Badge(props: { className?: string }) {
  const { listRenderer } = React.useContext(MessagesContext)
  const c = useStyles({})

  let title = useTimeTitle()

  // if (unreadAPI.count.above) title = `${unreadAPI.count.above} unread above`
  const countAbove = listRenderer.unread.count.above
  if (countAbove) title = `${countAbove} unread above`

  if (!title) return null

  return (
    <Chip
      label={title}
      className={clsx("badge", c.chip, props.className)}
    ></Chip>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    chip: {
      color: theme.palette.text.secondary,
    },
  }
})

function useTimeTitle() {
  const { messagesData, listRenderer } = React.useContext(MessagesContext)

  const getTitle = React.useCallback(
    (index: number) => {
      const sentAt = messagesData.getMessage(index)?.sentAt || ""
      return sentAt ? moment(sentAt).format("DD.MM.YYYY") : ""
    },
    [messagesData.getMessage]
  )

  const getTitle$ = React.useCallback(
    (input$: Observable<[boolean, (index: number) => string]>) =>
      listRenderer.messagesRendered$.pipe(
        withLatestFrom(input$),
        filter(([__, [isScrolling]]) => {
          return isScrolling
        }),
        switchMap(([rendered, [__, getTitle]]) => {
          const startIndex = rendered?.visibleStartIndex

          const t =
            typeof startIndex === "number"
              ? getTitle(rendered?.visibleStartIndex)
              : ""

          return merge(of(t), timer(2000).pipe(map(() => "")))
        })
      ),
    [getTitle, listRenderer]
  )

  const title$ = useObservable(
    (input$) => getTitle$(input$),
    [listRenderer.scrolling.isScrolling, getTitle]
  )

  const title = useObservableState(title$)

  return title
}
