//REMOVE
import { Icon, IconButton } from "@material-ui/core"
import React, { useContext } from "react"
import SpeedDialContainer from "components/SpeedDialContainer/SpeedDialContainer"
import { useUtilsContext } from "pages/Partners/contexts/libContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import LightTooltip from "components/LightTooltip/LightTooltip"
import PartnerViewContext from "pages/Partners/contexts/PartnerViewContext"
import PartnersContext from "pages/Partners/contexts/PartnersContext"

export default function AddContact({ edge = "end" }) {
  const utils = useUtilsContext()
  const t = useI18n()
  const { partner = {} } = useContext(PartnerViewContext) || {}
  const { openNewContactModal } = useContext(PartnersContext)

  return (
    <LightTooltip title={t["add-contact"]}>
      <IconButton onClick={openNewContactModal}>
        <Icon>person_add</Icon>
      </IconButton>
    </LightTooltip>
  )
}

// const getSpeedDialActions = ({ utils, translations }) => {
//   return [
//     { name: translations.partner, onClick: () => utils.openNewPartnerModal() },
//     // { name: translations.contact, onClick: () => utils.openNewContactModal() }
//   ].map((f) => ({ ...f, icon: <Icon>person</Icon> }))
// }
