import _ from "lodash"
import React from "react"
import useActiveResources from "../useActiveResources"
import { useUpdatePartnerMutation } from "lib/graphql/operations"
import { PartnerUpdateInput } from "lib/graphql/types"
import useMemoAPI from "hooks/useMemoAPI"

export default function useUpdatePartner(props?: {
  partnerID?: string | null
}) {
  const { workspaceID } = useActiveResources()

  const [mutate, { data, loading }] = useUpdatePartnerMutation()

  const updatePartner = React.useCallback(
    function updatePartner(p: {
      dataUpdate: PartnerUpdateInput
      partnerID?: string | null
    }) {
      const partnerID = p.partnerID || props?.partnerID || ""

      if (!partnerID) return

      return mutate({
        variables: { workspaceID, partnerUpdateInput: p.dataUpdate, partnerID },
      })
    },

    [props?.partnerID, mutate]
  )

  const api = useMemoAPI({
    partner: data?.updatePartner,
    updatePartner,
    isLoading: loading,
  })

  return api
}
