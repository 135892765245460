import React from "react"
import _f from "lodash/fp"
import useMemoAPI from "hooks/useMemoAPI"
import useImageWithAuth from "hooks/useImageWithAuth/useImageWithAuth"

export interface ImageAttachmentAPIInput {
  lazyFetching?: boolean
  src: string
  height?: number
  aspect?: number
}

export function useImageAttachmentAPI(props: ImageAttachmentAPIInput) {
  const { objectURL, error, isFetching, fetchImage } = useImageWithAuth({
    src: props.src,
    lazyFetching: props.lazyFetching || false,
  })

  const [imageLoaded, setLoaded] = React.useState(false)

  // React.useEffect(() => {
  //   if (objectURL) {
  //     const img = new Image()
  //     img.src = objectURL
  //     img.onload = () => {
  //       setLoaded(true)
  //     }
  //   }
  // }, [objectURL])

  return useMemoAPI({
    ...props,
    imageLoaded,
    isFetchingAttachment: isFetching,
    fetchingError: error,
    fetchAttachment: fetchImage,
    objectURL: objectURL,
    onLoad: React.useCallback(() => {
      setLoaded(true)
    }, []),
    height: typeof props.height === "undefined" ? 200 : props.height,
  })
}
