import React from "react"
import { Fab, Icon } from "@material-ui/core"
import ChatContext from "components/Chat/contexts/ChatContext"
import AddRoomModal, { AddModalAPI } from "components/AddRoomModal/AddRoomModal"
import clsx from "clsx"

export default function AddRoomButton(props: { className?: string }) {
  const ref = React.useRef<AddModalAPI>(null)

  const { setRoomIdentifier } = React.useContext(ChatContext)

  return (
    <div className={clsx("add-room-button", props.className)}>
      <Fab
        variant="circular"
        color="primary"
        onClick={() => ref.current?.open()}
      >
        <Icon>add_comment</Icon>
      </Fab>
      <AddRoomModal
        ref={ref}
        onCreateRoom={({ roomID }) => {
          setRoomIdentifier({ roomID })
        }}
      ></AddRoomModal>
    </div>
  )
}
