import React from "react"
import "./ball.scss"

export type BallProps = {
  background?: string
  size?: number
  color?: string
  fontSize?: string
  children?: React.ReactNode
  borderRadius?: string
  className?: string
  style?: React.CSSProperties
}

export default function Ball({
  background = "black",
  size = 30,
  color = "white",
  fontSize = "1rem",
  children,
  borderRadius,
  className,
  style,
}: BallProps) {
  return (
    <div
      className={`ball ${className || ""}`}
      style={{
        background,
        color,
        width: size,
        height: size,
        fontSize,
        borderRadius,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
