import _ from "lodash"
import PartnersContext from "./contexts/PartnersContext"
import usePartnersProviderValue from "./hooks/usePartnersProviderValue"
import MainAppBar from "./components/MainAppBar/MainAppBar"
import Main from "./components/Main/Main"
import { useCallback, useMemo } from "react"
import useAppRouter from "hooks/useAppRouter"
import usePageParams from "./hooks/usePageParams"
import useGetLink from "./hooks/useGetLink"
import PageLayout from "components/PageLayout/PageLayout"
import FrameWithLoadingProgress from "components/FrameWithLoadingProgress/FrameWithLoadingProgress"

export default function Partners() {
  const params = usePageParams()

  const utils = useUtils()

  const { history } = useAppRouter()

  const onChangeCollectionID = useCallback(
    (collectionID) => {
      const collectionLink = utils.getCollectionLink({ collectionID })
      collectionLink && history.push(collectionLink)
    },
    [utils.getCollectionLink]
  )

  const partnersProviderValue = usePartnersProviderValue({
    collectionID: params.collectionID,
    documentID: params.documentID,
    searchedTerm: params.searchedTerm,
    types: params.types?.filter((a) => !!a),
    onChangeCollectionID,
    onChangeDocumentID: console.log,
    onChangeModal: console.log,
    onChangeSearchedTerm: utils.setSearchedTerm,
    onChangeTypes: utils.setTypes,
  })

  return (
    <PartnersContext.Provider value={partnersProviderValue}>
      <FrameWithLoadingProgress isLoading={partnersProviderValue.loadingData}>
        <PageLayout body={<Main />} topBar={<MainAppBar />}></PageLayout>
      </FrameWithLoadingProgress>
    </PartnersContext.Provider>
  )
}

function useUtils() {
  const getLink = useGetLink()
  const params = usePageParams()

  const { history } = useAppRouter()
  const goTo = (link: string) => history.push(link)

  return useMemo(
    () => ({
      getLink,
      getCollectionLink: (props: { collectionID: string | null }) =>
        getLink({ ...params, collectionID: props.collectionID }),
      setSearchedTerm(searchedTerm: string | null) {
        goTo(getLink({ ...params, searchedTerm }))
      },
      setTypes(types: Array<string>) {
        goTo(getLink({ ...params, types }))
      },
    }),
    [getLink, JSON.stringify(params)]
  )
}
