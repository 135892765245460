import { useTheme } from "@material-ui/core"
import {
  DocumentsDashboardParams,
  IDocumentsDashboardContext,
} from "../contexts/DocumentsDashboardContext"
import _ from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import { DefaultEntryListItem, DefaultEntryStatus } from "../defaultSignatures"
import useMemoAPI from "hooks/useMemoAPI"
import { ObjectWithID } from "types/type"

import React from "react"

export interface DocumentsDashboardProps extends DocumentsDashboardParams {
  onChangeActiveID?: (id: string | null) => any
  onChangeActiveEntry?: (activeEntry: ObjectWithID | null) => any
  getEntryColor?: (activeEntry: ObjectWithID | null) => string | null
}

export default function useDocumentsDashboardProviderValue(
  props: DocumentsDashboardProps
): IDocumentsDashboardContext {
  const { isDesktop } = React.useContext(DeviceObserver.Context)
  const renderRightOnOverlay =
    typeof props.renderRightOnOverlay == "undefined"
      ? !isDesktop
      : props.renderRightOnOverlay

  const defaultActiveID = renderRightOnOverlay
    ? null
    : props.selectedEntryID || _.get(props.entries, "0.id")

  const [activeID, setActiveID] = useState(props.selectedEntryID)

  const activitiesByID: { [key: string]: ObjectWithID } = useMemo(() => {
    return _.keyBy(props.entries, "id")
  }, [props.entries])

  useEffect(() => {
    if (typeof props.onChangeActiveID === "function") {
      props.onChangeActiveID(activeID)
    }

    if (typeof props.onChangeActiveEntry === "function") {
      props.onChangeActiveEntry(activitiesByID[activeID || ""])
    }
  }, [activeID])

  const resetSelection = useCallback(() => setActiveID(null), [])

  useEffect(() => {
    setActiveID(props.selectedEntryID)
  }, [props.selectedEntryID])

  const lessDetailedEntry = activeID ? activitiesByID[activeID] : null

  const selectedEntry = props.detailedSelectedEntry || lessDetailedEntry

  const theme = useTheme()

  const onSaveEdition = useCallback(
    (newObject) => props.onSaveEdition?.call(null, newObject, { activeID }),
    [activeID, props.onSaveEdition]
  )
  useEffect(() => {
    if (!!activeID) return
    setActiveID(defaultActiveID)
  }, [!!defaultActiveID])

  useEffect(() => {
    if (props.entries) return
    setActiveID(defaultActiveID)
  }, [props.entries])

  useEffect(() => {
    if (!defaultActiveID) return
    const someIsAlreadySelected = props.entries?.some((a) => a.id === activeID)

    if (someIsAlreadySelected) return

    setActiveID(defaultActiveID)
  }, [defaultActiveID])

  const api: IDocumentsDashboardContext = {
    ...props,
    selectedEntryID: activeID,
    setActiveID,
    resetSelection,
    onSaveEdition,
    selectedEntry,
    renderRightOnOverlay,
    headersMinHeight: !renderRightOnOverlay ? 62 : undefined,
    selectedEntryColor: (() => {
      const defaultColor = theme.palette.primary.dark
      return props.getEntryColor
        ? props.getEntryColor(selectedEntry) || defaultColor
        : defaultColor
    })(),
  }

  return useMemoAPI(api)
}
