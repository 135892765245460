import DocumentFormPage from "components/DocumentFormPage/DocumentFormPage"
import Hook from "components/Hook/Hook"
import PageBoardContext from "components/PageBoard/contexts/PageBoardContext"
import { TwoPagesAPI } from "components/TwoPages/TwoPages"
import { FormApi } from "hooks/useForm"
import useMemoAPI from "hooks/useMemoAPI"
import { usePaginatedDocuments } from "hooks/usePaginatedDocuments"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { DocumentPageBoardItemFragment } from "lib/graphql/operations"
import React from "react"
import { AllDocumentsListPage } from "../../../../AllDocumentsListPage/AllDocumentsListPage"
import { useOpenUploadUI } from "./useOpenUploadUI"
import { PageBoardItemType } from "lib/graphql/types"
import { SecondPage } from "../../SecondPage/SecondPage"

type DeletionApi = {
  isDeleted?: boolean
  toggleDeletion?: () => void
}

export type FormCommonsApi<T extends Object = Object> = ReturnType<
  typeof useFormCommons<T>
>

function useFormCommons<T extends { [i: string]: any }>(props: {
  twoPagesApi?: TwoPagesAPI
  form?: FormApi<T>
  deletion?: DeletionApi
  readOnly?: boolean
}) {
  const pageBoardAPI = React.useContext(PageBoardContext)

  const onSelectDocument = React.useCallback(
    (documentID: string) => {
      if (!documentID) return

      return props.twoPagesApi?.openHalfPage?.(function Page(args) {
        return (
          <SecondPage
            onClose={args.onClose}
            getItemParams={{
              fetchItemProps: {
                type: PageBoardItemType.Document,
                params: {
                  documentID: documentID,
                },
              },
            }}
          ></SecondPage>
        )
      })
    },
    [props.twoPagesApi]
  )

  const openAllDocumentsMiniPage = React.useCallback(
    ({ collectionId }: { collectionId: string }) => {
      return props.twoPagesApi?.openHalfPage(function Page(args) {
        return (
          <Hook hook={usePaginatedDocuments} hookProps={{ collectionId }}>
            {(api) => {
              return (
                <AllDocumentsListPage
                  onClose={args.onClose}
                  documents={api.allDocuments}
                  fetchMore={api.fetchMore}
                  onOpenDocument={(id) => {
                    // pageBoardAPI.openItemPage(PageBoardItemType.Document, {
                    //   documentID: id,
                    // })
                    onSelectDocument(id)
                  }}
                ></AllDocumentsListPage>
              )
            }}
          </Hook>
        )
      })
    },
    [props.twoPagesApi, onSelectDocument]
  )
  const openDocumentItem = React.useCallback(
    (item: DocumentPageBoardItemFragment) => {
      if (item.__typename !== "DocumentPageBoardItem") return

      const documentID = item.document?.id || ""
      if (!documentID) return

      // return props.twoPagesApi?.openMiniPage?.(function Page(args) {
      //   return (
      //     <DocumentFormPage
      //       onClose={args.onClose}
      //       DocumentFormApiInput={{
      //         documentID: documentId,
      //         summarized: true,
      //         hidePartnerField: true,
      //         onClickOpenButton() {
      //           pageBoardAPI.selected.set(item)
      //         },
      //       }}
      //     ></DocumentFormPage>
      //   )
      // })
      return props.twoPagesApi?.openHalfPage?.(function Page(args) {
        return (
          <SecondPage
            onClose={args.onClose}
            getItemParams={{
              fetchItemProps: {
                type: PageBoardItemType.Document,
                params: {
                  documentID: documentID,
                },
              },
            }}
          ></SecondPage>
        )
      })
    },
    [pageBoardAPI, props?.twoPagesApi, pageBoardAPI.selected.set]
  )

  const { openUploadPage } = useOpenUploadUI({
    twoPagesAPI: props?.twoPagesApi,
  })

  return useMemoAPI({
    ...(props || {}),
    openDocumentWithDocumentID: onSelectDocument,
    openDocumentWithItemParams: openDocumentItem,
    openAllDocumentsMiniPage,
    openUploadPage,
  })
}

const a = contextAndProviderFactory({
  contextName: "FormCommonsContext",
  hookApi: useFormCommons,
})

export const FormCommonsContext = a.Context
export const FormCommonsConsumer = a.Context.Consumer
export const FormCommonsProvider = a.Provider
