import getFieldsMap from "../../lib/getFieldsMap"
import React, { useContext, useMemo } from "react"
import _ from "lodash"
// import DocumentFormContext from "../../contexts/DocumentFormContext"
import useBasicFieldsGroups from "components/DeprecatedDocumentForm/hooks/useBasicFieldsGroups/useBasicFieldsGroups"
import DocumentFormFieldsGroups from "../DocumentFormFieldsGroups/index"

const fieldsMap = getFieldsMap()

export default function DocumentFormRoot({}) {
  const { basicInfoFields, textFields, collectionsFields, paymentFields } =
    useBasicFieldsGroups()

  return (
    <DocumentFormFieldsGroups.Root
      fieldsets={[
        basicInfoFields,
        <DocumentFormFieldsGroups.Fieldset
          fullWidth
          fields={collectionsFields}
          fieldsMap={fieldsMap}
        />,
        <DocumentFormFieldsGroups.Fieldset
          fullWidth
          fields={textFields}
          fieldsMap={fieldsMap}
        />,
        paymentFields,
      ]}
      fieldsMap={fieldsMap}
    ></DocumentFormFieldsGroups.Root>
  )
}
