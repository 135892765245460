import CollectionsTextField from "components/DocumentForm/components/CollectionsTextField/CollectionsTextField"
import { I18nAPI } from "contexts/i18nContext/i18nContext"
import { FieldsetIndentationProps } from "../../../../../Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { DocumentFormAPI } from "../../../../contexts/DocumentFormContext"

export default function getCollectionsFieldProps(props: {
  translations: I18nAPI
  api: DocumentFormAPI
}) {
  const indentationProps: FieldsetIndentationProps = {
    children: <CollectionsTextField></CollectionsTextField>,
    icon: "label",
  }

  return indentationProps
}
