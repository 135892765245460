import { makeStyles } from "@material-ui/core"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import ArticleDesktopLayout from "./ArticleDesktopLayout/ArticleDesktopLayout"
import ArticleMobileLayout from "./ArticleMobileLayout/ArticleMobileLayout"

export function ArticleDetailPanelBody() {
  const api = useRootContainerAPI()

  const c = useStyles()
  const isMobile = api.width < 800

  return (
    <div ref={api.containerRef} className={c.body}>
      {(() => {
        if (isMobile) {
          return <ArticleMobileLayout></ArticleMobileLayout>
        }

        return <ArticleDesktopLayout></ArticleDesktopLayout>
      })()}
    </div>
  )
}
const useStyles = makeStyles((theme) => ({
  body: {
    "& > *": {
      height: "100%",
    },
  },
}))
