import { useApolloClient, gql } from "@apollo/client";
import {
  AddKanbanImagesDocument,
  RemoveKanbanImageDocument,
} from "lib/graphql/operations"
import React from "react"
import { ID } from "types/type"
import cachedIDGetters from "lib/getCacheID/getCacheID"
import { KanbanImagesAPI } from "components/Kanban/@types"

export default function useRemoveImage(props: {
  workspaceID: ID
  onChangeLoadingState?: (state: boolean) => void
}): KanbanImagesAPI["removeImage"] {
  const client = useApolloClient()

  return React.useCallback(
    async function removeImage(imageID: ID, cardID: ID) {
      props.onChangeLoadingState?.call(null, true)

      await client
        .mutate({
          mutation: RemoveKanbanImageDocument,
          variables: { workspaceID: props.workspaceID, cardID, imageID },
          optimisticResponse: (() => {
            const cardImages = client.readFragment({
              id: cachedIDGetters.KanbanCardImages({ cardID }),
              fragment: gql`
                fragment RemovingImage on KanbanCardImages {
                  cardID
                  list {
                    id
                  }
                  cover {
                    id
                  }
                }
              `,
            })

            return {
              removeKanbanImage: {
                __typename: "KanbanCardImages",
                cardID,
                list: (cardImages?.list || []).filter((i) => i.id !== imageID),
                cover: (() => {
                  if (cardImages?.cover?.id === imageID) return null
                  return cardImages?.cover
                })(),
              },
            }
          })(),
        })
        .finally(() => props.onChangeLoadingState?.call(null, false))
    },
    [props?.onChangeLoadingState, props.workspaceID]
  )
}
