import { PureQueryOptions } from "@apollo/client"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  RelatedPageBoardItemsDocument,
  RelatedPageBoardItemsQueryVariables,
  useActivateRelatedRoomMutation,
} from "lib/graphql/operations"
import React from "react"
import { PageBoardItemContext } from "../components/PageBoardItemContext"

export function useRelatedRoom() {
  const itemParams = React.useContext(PageBoardItemContext).itemParams

  const workspaceID = useActiveResources().workspaceID

  const [mutate, { loading }] = useActivateRelatedRoomMutation()

  const goToRelatedRoom = React.useCallback(() => {
    if (!itemParams || !workspaceID) return

    const query: PureQueryOptions = {
      query: RelatedPageBoardItemsDocument,
      variables: (() => {
        const variables: RelatedPageBoardItemsQueryVariables = {
          workspaceID,
          itemID: itemParams.itemID,
          type: itemParams.type,
        }

        return variables
      })(),
    }

    return mutate({
      variables: { itemParams, workspaceID },
      refetchQueries: [query],
    })
  }, [mutate, itemParams?.itemID, itemParams?.type, workspaceID])

  return useMemoAPI({ goToRelatedRoom, loading })
}
