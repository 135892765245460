import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

export function TwoColumns({
  firstColumn,
  secondColumn,
  className,
}: {
  firstColumn: React.ReactNode
  secondColumn?: React.ReactNode
  className?: string
}) {
  const c = useStyles()
  return (
    <div className={clsx("two-columns", c.root, className)}>
      {firstColumn}
      {secondColumn}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",

      "&>*": {
        flex: 1,
      },
    },
  }
})
