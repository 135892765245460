import _ from "lodash"
import clsx from "clsx"
import React from "react"
import { makeStyles, Popover, ClickAwayListener } from "@material-ui/core"
import popoverPositioning from "util/popoverPositioning/popoverPositioning"

/** show popover when hover children */
export default function PopoverWrapper(props: {
  children: React.ReactNode
  popover: React.ReactNode
  className?: string
  style?: React.CSSProperties
  childrenUnderlinedOnHover?: boolean
  showTimeout?: number
  classes?: { popover?: string }
  open?: boolean
  disabledHover?: boolean
}) {
  const {
    popover = null,
    style = {},
    childrenUnderlinedOnHover = false,
    showTimeout = 500,
    classes = { popover: "" },
    open: propsOpen = undefined,
    disabledHover = false,
  } = props

  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null)

  let destroyID: NodeJS.Timeout | undefined, showID: NodeJS.Timeout | undefined

  const destroy = React.useCallback(() => setAnchor(null), [])

  const anchorRef = React.useRef(null)

  const programDestruction = React.useCallback(() => {
    destroyID = setTimeout(destroy, showTimeout)
  }, [])

  const show = React.useCallback(() => setAnchor(anchorRef.current), [])

  React.useEffect(() => {
    if (!!propsOpen) return show()
    destroy()
  }, [propsOpen])

  const programShow = () => {
    showID = setTimeout(show, 500)
  }

  const cancelShow = () => clearTimeout(showID)
  const stopDestruction = () => clearTimeout(destroyID)

  const isOpen = Boolean(anchor)

  const c = useStyles({ childrenUnderlinedOnHover })

  const hoverShowProps = React.useMemo(() => {
    return {
      onMouseEnter: programShow,
      onMouseLeave() {
        cancelShow()
        isOpen && programDestruction()
      },
    }
  }, [programShow, isOpen, cancelShow, programDestruction])

  const hoveringIsActivated = !disabledHover && typeof propsOpen === "undefined"

  return (
    <ClickAwayListener onClickAway={destroy}>
      <div
        {...(hoveringIsActivated ? hoverShowProps : {})}
        onClick={(e) => {
          e.stopPropagation()
          show()
        }}
        className={clsx(props.className, "popover-wrapper")}
        style={style}
        ref={anchorRef}
      >
        <div className={c.children}>{props.children}</div>

        <Popover
          className={classes.popover}
          {...popoverPositioning({ anchorEl: anchor })}
          anchorEl={anchor}
          open={isOpen}
          style={{ pointerEvents: "none" }} //it prevents the onMouseEnter and onMouseLeave on UserAvatar's container to be called
        >
          <div
            onMouseLeave={destroy}
            style={{ pointerEvents: "auto" }} //reestablishing "pointerEvents"
            {...(hoveringIsActivated ? { onMouseEnter: stopDestruction } : {})}
          >
            {popover}
          </div>
        </Popover>
      </div>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    popoverWrapper: {},
    children: {
      cursor: "pointer",
      "& *:hover": {
        textDecoration: (props: { childrenUnderlinedOnHover?: boolean }) =>
          props.childrenUnderlinedOnHover ? "underline" : "none",
      },
    },
  }
})
