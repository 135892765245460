import { Icon, Typography } from "@material-ui/core"
import clsx from "clsx"
import LightTooltip from "components/LightTooltip/LightTooltip"
import ToggleableMenu, {
  ToggleableMenuItem,
  ToggleableMenuProps,
} from "components/ToggleableMenu/ToggleableMenu"
import { useI18n } from "contexts/i18nContext/i18nContext"
import _ from "lodash"
import React from "react"
import PageBoardContext from "../../contexts/PageBoardContext"
import useTypesOptions from "../../hooks/useTypesOptions"

export default function History(props: {
  className?: string
  style?: React.CSSProperties
}) {
  const api = React.useContext(PageBoardContext)
  const t = useI18n()
  const options = useTypesOptions()

  if (!api.history?.length) return null

  const b: ToggleableMenuProps["menuItems"] = _.uniqBy(
    api.history || [],
    (a) => a.id
  )
    .sort((a, b) => {
      if (a.timestamp > b.timestamp) return -1
      return 1
    })
    .map((h) => {
      const c: ToggleableMenuItem = {
        // isSelected: h.item.id === api.selected.value?.id,
        component: (
          <Typography
            key={h.itemID + h.type}
            style={{ display: "flex", alignItems: "center" }}
            color="textSecondary"
          >
            <Icon style={{ lineHeight: 1, marginRight: 4 }}>
              {(() => {
                return options[h.type as keyof typeof options]?.icon || ""
              })()}
            </Icon>
            <span>{h.name || t["withoutTitle"]}</span>
          </Typography>
        ),
        onClick() {
          api.selected.setParams({
            type: h.type,
            itemID: h.itemID || "",
          })
        },
      }
      return c
    })

  return (
    <ToggleableMenu
      style={props.style}
      className={clsx(props.className, "toggleable-menu-history")}
      menuItems={b}
      closeOnSelect
      icon={
        <LightTooltip title={t["recent"]}>
          <Icon>restore</Icon>
        </LightTooltip>
      }
    ></ToggleableMenu>
  )
}
