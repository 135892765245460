import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import SelectInput, {
  SelectInputProps,
} from "components/Input/components/SelectInput/SelectInput"
import Switch from "components/Input/components/Switch/Switch"
import TextField from "components/TextField/TextField"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { FieldInputProps, FormikErrors, useFormik } from "formik"
import React from "react"
import * as Yup from "yup"
import { UploadDocumentsContext } from "../../ContextAndProvider"

const onSubmit = () => {}

type DefaultValue = {
  filename: string
  title: string
  text: {
    value: string
    repeated: boolean
  }
  collectionType: {
    value: string
    repeated: boolean
  }
}
export default function EditForm<T extends DefaultValue>({
  defaultValues,
  onChange: propsOnChange,
  className = "",
  showRepeatToggle,
}: {
  defaultValues: T

  onChange?: (value: T) => any
  className?: string
  showRepeatToggle?: boolean
}) {
  const t = useI18n()

  const formik = useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    onSubmit,
    // validateOnMount: true,
    // validationSchema: Yup.object({
    //   title: Yup.string().required(t["required"]),
    //   collectionType: Yup.object({
    //     value: Yup.string().required(t["required"]),
    //   }),
    // }),
  })

  React.useEffect(() => {
    formik.dirty && propsOnChange?.(formik.values)
  }, [JSON.stringify(formik.values)])

  const c = useStyles({})

  return (
    <div className={clsx("edit-form", className, c.root)}>
      <div className={c.inputContainer}>
        <TextField
          {...formik.getFieldProps("filename")}
          label={t.name}
          InputProps={{ readOnly: true }}
        />
      </div>
      <div className={c.inputContainer}>
        <TextField
          {...formik.getFieldProps("title")}
          label={t.title}
          helperText={formik.errors.title}
        />
      </div>
      <div className={c.inputContainer}>
        <CollectionTypeSelect
          label={t["type"]}
          helperText={
            (formik.errors.collectionType as FormikErrors<T["collectionType"]>)
              ?.value || ""
          }
          {...formik.getFieldProps("collectionType.value")}
        />

        {showRepeatToggle && (
          <div className={clsx(c.repeatToggle, "repeat-switch")}>
            <RepeatSwitch
              {...formik.getFieldProps("collectionType.repeated")}
            />
          </div>
        )}
      </div>
      <div className={c.inputContainer}>
        <TextField {...formik.getFieldProps("text.value")} label={t.subject} />

        {showRepeatToggle && (
          <div className={clsx(c.repeatToggle, "repeat-switch")}>
            <RepeatSwitch {...formik.getFieldProps("text.repeated")} />
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    repeatToggle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "&>*": {
        marginRight: -12,
      },
    },

    root: {
      "&>*": {
        marginBottom: theme.spacing(2),
      },
    },
    inputContainer: {
      display: "flex",
      alignItems: "center",
      "&>*:first-child": {
        flex: 1,
      },
    },
  }
})

function RepeatSwitch(props: FieldInputProps<boolean>) {
  const t = useI18n()

  return (
    <Switch
      {...props}
      TooltipProps={({ value: v }) => ({
        title: v ? t["for-all-files"] : t["only-this-file"],
        placement: "bottom",
        className: "tooltip",
      })}
    />
  )
}

function CollectionTypeSelect(
  props: Omit<SelectInputProps, "TextField" | "options">
) {
  const { fileTypesAvailable } = React.useContext(UploadDocumentsContext)

  const options = React.useMemo(() => {
    const opts = (fileTypesAvailable || []).map(({ locale, value }) => ({
      label: locale,
      value,
    }))

    return opts
  }, [fileTypesAvailable])

  return <SelectInput options={options} TextField={TextField} {...props} />
}
