import clsx from "clsx"
import ToggleableMenu, {
  ToggleableMenuItem,
  ToggleableMenuProps,
} from "components/ToggleableMenu/ToggleableMenu"
import React from "react"
import useDeleteRoomOption from "./hooks/useDeleteRoomOption"
import useNotificationOption from "./hooks/useNotificationOption"
import useReadOnlyOption from "./hooks/useReadOnlyOption"

export default function SettingsMenu(
  props: {
    className?: string
    children?: (options: ToggleableMenuItem[]) => React.ReactNode
    style?: React.CSSProperties
    options?: ToggleableMenuItem[]
  } & Omit<ToggleableMenuProps, "menuItems">
) {
  const notificationOption = useNotificationOption()
  const readOnlyOption = useReadOnlyOption()
  const deleteRoomOption = useDeleteRoomOption()

  const options = React.useMemo<ToggleableMenuProps["menuItems"]>(() => {
    return [
      ...(props.options || []),
      notificationOption,
      readOnlyOption,
      deleteRoomOption,
    ].filter((a): a is ToggleableMenuItem => {
      return !!a
    })
  }, [props.options, notificationOption, readOnlyOption, deleteRoomOption])

  if (typeof props.children === "function")
    return <>{props.children(options)}</>

  return (
    <ToggleableMenu
      {...props}
      menuItems={options}
      className={clsx("settings-menu", props.className)}
      style={props.style}
    ></ToggleableMenu>
  )
}
