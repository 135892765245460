import React from "react"
import clsx from "clsx"
import { ButtonBase, makeStyles } from "@material-ui/core"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import LexicalEditor from "components/LexicalEditor/LexicalEditor"

export default function DescriptionField() {
  const api = React.useContext(KanbanCardPageBoardItemContext)
  const t = useI18n()

  const [container, setContainer] = React.useState<HTMLElement | null>(null)

  const c = useStyles()

  const notClickable = !api.viewport?.isSmallView

  return (
    <div className={clsx("description-field")} ref={(e) => setContainer(e)}>
      <LexicalEditor.Provider
        editable={notClickable}
        markdownMode={false}
        autoFocus={false}
        onSave={api.card.description.submitChange}
        allowToSaveEmptyValue
        namespace="description"
        value={api.card.description.text}
      >
        {(() => {
          const clickable = !notClickable

          return React.createElement(notClickable ? "div" : ButtonBase, {
            ...(() => {
              if (clickable)
                return {
                  onClick: api.card.description.modal.open,
                }

              return {}
            })(),
            children: (
              <>
                <LexicalEditor.Root
                  PlaceholderProps={{
                    showEvenWhenNotEditable: true,
                    text: t["description"],
                    style: {
                      left: 0,
                      top: 0,
                    },
                  }}
                ></LexicalEditor.Root>
              </>
            ),
            className: c.container,
          })
        })()}
        {notClickable && (
          <LexicalEditor.Context.Consumer>
            {(lexicalAPI) => {
              return (
                <LexicalEditor.FocusTrack
                  containerElement={container}
                  onBlur={() =>
                    lexicalAPI.saveAPI.save({ clearEditorOnSave: false })
                  }
                >
                  {(api) => {
                    if (!api.hasFocus) return null

                    return (
                      <LexicalEditor.Toolbar
                        fontSize={19}
                        style={{ marginTop: 8 }}
                      ></LexicalEditor.Toolbar>
                    )
                  }}
                </LexicalEditor.FocusTrack>
              )
            }}
          </LexicalEditor.Context.Consumer>
        )}
      </LexicalEditor.Provider>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      opacity: 0.7,
      paddingBottom: 7,
      textAlign: "left",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      width: "100%",
      display: "block",
    },
  }
})
