import { useMemo, useState } from "react"

export default function useStepsController<Step extends { id: string }>({
  steps,
}: {
  steps: Step[]
}) {
  const [activeStepIndex, setActiveStepIndex] = useState(0)

  return useMemo(
    () => ({
      activeStepIndex,
      activeStep: steps[activeStepIndex],
      goToStepIndex: setActiveStepIndex,
      goToStepWithID(id: string) {
        const index = steps.findIndex(({ id: stepID }) => stepID === id)
        if (index !== -1) setActiveStepIndex(index)
      },
      goForward() {
        const lastStepIndex = (steps || []).length - 1

        if (activeStepIndex < lastStepIndex) {
          setActiveStepIndex(activeStepIndex + 1)
        }
      },
      goBackward() {
        if (activeStepIndex > 0) setActiveStepIndex(activeStepIndex - 1)
      },
    }),
    [activeStepIndex, steps]
  )
}
