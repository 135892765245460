import FrameWithLoadingProgress from "components/FrameWithLoadingProgress/FrameWithLoadingProgress"
import clsx from "clsx"
import { pick } from "lodash/fp"
import React from "react"
import ChatContext, { IChatContext } from "./contexts/ChatContext"
import ChatRoot from "./components/ChatRoot/ChatRoot"
import useChatAPI from "./hooks/useChatProviderValue/useChatAPI"
import arePropsEqual from "util/arePropsEqual"
import { makeStyles } from "@material-ui/core"

/** legacy */
export default function ChatDefault(props: {
  roomIdentifier: any
  onChangeRoomIdentifier?: any
  ChatListProps?: any
  getOutOfRoom?: any
  RoomProps?: any
  getRoomPathname?: IChatContext["getRoomPathname"]
  className?: any
}) {
  const {
    roomIdentifier,
    onChangeRoomIdentifier = undefined,
    ChatListProps = undefined,
    getOutOfRoom: propsGetOutOfRoom = undefined,
    RoomProps = undefined,
    className = "",
  } = props

  const chatListProps = pick(["renderTopBar", "tabID", "onChangeTabID"])(
    ChatListProps
  )
  const roomProps = pick(["renderTopBar", "renderSideSheet"])(RoomProps)

  const chatProviderValue = useChatAPI({
    getRoomPathname: props.getRoomPathname,
    roomIdentifier,
    renderRoomAppBar: roomProps.renderTopBar,
    tabID: chatListProps.tabID,
    onChangeTabID: chatListProps.onChangeTabID,
    renderRoomSideSheet: roomProps.renderSideSheet,
    onChangeRoomIdentifier,
    getOutOfRoom: propsGetOutOfRoom,
  })

  const { RootContainer } = chatProviderValue

  const c = useStyles({})
  return (
    <ChatContext.Provider value={chatProviderValue}>
      <FrameWithLoadingProgress className={clsx("chat", c.chat, className)}>
        <RootContainer.Provider>
          {({ containerRef }) => {
            return (
              <div ref={containerRef} className={clsx("chat-root", c.chatRoot)}>
                <ChatRoot {...chatListProps}></ChatRoot>
              </div>
            )
          }}
        </RootContainer.Provider>
      </FrameWithLoadingProgress>
    </ChatContext.Provider>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    chat: {
      overflow: "hidden",
    },

    chatRoot: {
      height: "100%",
    },
    room: {
      height: "100%",
    },
  }
})
