import _ from "lodash"
import React from "react"
import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import EditorModal from "../EditorModal/EditorModal"

export default function DescriptionModal() {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const description = api.card.description

  const t = useI18n()

  return (
    <EditorModal
      onClose={description.modal.close}
      namespace="description-modal"
      markdownMode={false}
      // editable={false}
      editable
      isOpen={description.modal.isOpen}
      allowToSaveEmptyValue
      initialValue={description?.text || ""}
      onSave={description.submitChange}
      saveButtonText={t["save"]}
    ></EditorModal>
  )
}
