import _ from "lodash"
import React, { useContext } from "react"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import SideSheetContext from "components/SideSheet/contexts/sideSheetContext/sideSheetContext"
import IconButton from "components/IconButton/IconButton"
import SideSheetDocumentContext from "contexts/sideSheetDocument/sideSheetDocument"

function DocumentInfoButton({ documentID, edge }) {
  const { openSideSheet } = useContext(SideSheetContext)
  const { isMobile } = React.useContext(DeviceObserver.Context)
  const { setSelectedDocument } = useContext(SideSheetDocumentContext)

  const onPressInfo = () => {
    setSelectedDocument({ id: documentID })
    isMobile && openSideSheet()
  }

  if (!documentID) return null

  return (
    <IconButton iconName="info" onClick={onPressInfo} edge={edge}></IconButton>
  )
}

export default DocumentInfoButton
