import TwoPagesRoot from "./components/TwoPagesRoot/TwoPagesRoot"
import React from "react"
import TwoPagesContext from "./contexts/TwoPagesContext"
import useTwoPagesAPI, { TwoPagesAPIInput } from "./hooks/useTwoPagesAPI"
import useSecondPage from "./hooks/useSecondPage"
import Button from "./components/TwoPagesRoot/Button/Button"

export { default as useSecondPage } from "./hooks/useSecondPage"

export type { TwoPagesAPIInput } from "./hooks/useTwoPagesAPI"

const TwoPages = {
  Root: TwoPagesRoot,
  useSecondPage,
  Button,
  Context: TwoPagesContext,
  useAPI: useTwoPagesAPI,
  Provider: function TwoPagesProvider(props: {
    children: React.ReactNode
    TwoPagesAPIProps?: TwoPagesAPIInput
  }) {
    const { children, TwoPagesAPIProps } = props

    const api = useTwoPagesAPI(TwoPagesAPIProps)

    return (
      <TwoPagesContext.Provider value={api}>
        {children}
      </TwoPagesContext.Provider>
    )
  },
}

export type TwoPagesAPI = ReturnType<typeof TwoPages.useAPI>

export default TwoPages
