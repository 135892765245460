import useKanbanColumns from "hooks/useKanbanColumns"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import KanbanCardPageBoardItemContext from "../../../contexts/KanbanCardPageBoardItemContext"
import useRoomSelection from "./useRoomSelection"
import useOpenClose from "hooks/useOpenClose"
import useChangeKanbanColumn from "hooks/useChangeKanbanColumn"
import { KanbanColumnFragment } from "lib/graphql/operations"
import { useModalVariant } from "../../../../../../../../../../BottomAndPopoverModal/useModalVariant"

export default function useBoardAndColumnSelectionAPI() {
  const roomSelection = useRoomSelection()

  const oc = useOpenClose()
  const cardAPI = React.useContext(KanbanCardPageBoardItemContext)

  const selectedRoomID = roomSelection.selected?.room?.id || ""
  const roomID = selectedRoomID || cardAPI.card.data?.roomID || ""

  const columnSelection = useKanbanColumns({
    roomID,
    filter: React.useCallback((column: KanbanColumnFragment) => {
      return !!column.title
    }, []),
  })

  const change = useChangeKanbanColumn().changeCardColumn

  const selectedColumnID = columnSelection.selectedColumn?.id
  const cardID = cardAPI.card.data?.id

  const submit = React.useCallback(() => {
    if (!cardID || !selectedColumnID) return
    change({ cardID, columnID: selectedColumnID })

    oc.close()
  }, [change, selectedColumnID, oc])

  return useMemoAPI({
    readyToSubmit: !!columnSelection.selectedColumn?.id,
    roomSelection,
    columnSelection,
    submit,
    openClose: oc,
  })
}
