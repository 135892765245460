import _ from "lodash"
import { useFormik } from "formik"
import * as Yup from "yup"

export default function useFormFormik({ fieldsets, onSubmit }) {
  function getValues(method) {
    return _.pickBy(
      generateObject({ fieldsets, method }),
      v => !_.isUndefined(v)
    )
  }

  const formik = useFormik({
    onSubmit,
    initialValues: getValues(({ field }) => field.value || field.defaultValue),
    validationSchema: Yup.object(getValues(({ field }) => field.schema)),
    initialTouched: getValues(({ field }) => field.touched || false),
    enableReinitialize: true,
    initialErrors: getValues(({ field }) => field.initialError || undefined)
  })

  return formik
}

function generateObject({ fieldsets, method }) {
  return (fieldsets || []).reduce((acc, f) => {
    const { name: fieldsetName, fieldset } = f

    fieldset.forEach(field => {
      const key = [fieldsetName, field.name].filter(Boolean).join(".")
      _.set(acc, key, method({ field }))
    })

    return acc
  }, {})
}
