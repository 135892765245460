import PageBoardContext from "components/PageBoard/contexts/PageBoardContext"
import useDetailedContact from "hooks/useDetailedContact/useDetailedContact"
import useMemoAPI from "hooks/useMemoAPI"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { PageBoardItemContext } from "../../PageBoardItemContext"

export type ContactPageBoardItemApiInput = {
  onClose: () => any
}

export function useContactPageBoardItem(props: ContactPageBoardItemApiInput) {
  const item = React.useContext(PageBoardItemContext).item
  const contactID = item?.itemID || ""
  const pageBoardAPI = React.useContext(PageBoardContext)

  const data = useDetailedContact({
    id: contactID,
    fetchPolicy: "cache-and-network",
  })

  const contact = data.detailedContact || undefined

  const openPartnerPage = React.useCallback(() => {
    const partner = contact?.partner

    if (!partner) return

    pageBoardAPI.openItemPage(PageBoardItemType.Partner, {
      partnerID: partner.id,
    })
  }, [pageBoardAPI.openItemPage, contact])

  return useMemoAPI({
    ...props,
    contact,
    openPartnerPage,
  })
}
