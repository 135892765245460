import _ from "lodash"

/** to be used with Array.sort */
export default function sortBy({ order, property, transform = undefined }) {
  return function sort(a = {}, b = {}) {
    order = order || "asc"
    order = order.toLowerCase()

    if (!["asc", "desc"].includes(order))
      throw new Error("Invalid $order=" + order)

    let A = _.get(a, property)
    let B = _.get(b, property)

    if (typeof transform === "function") {
      A = transform(A)
      B = transform(B)
    }

    if (order === "desc") {
      if (A > B) return -1
      return 1
    } else if (order === "asc") {
      if (A < B) return -1
      return 1
    }
  }
}
