import { Chip, makeStyles } from "@material-ui/core"
import React from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useOpenClose from "hooks/useOpenClose"
import ToggleableMenu from "components/ToggleableMenu/ToggleableMenu"
import useDocumentSelection from "hooks/useDocumentSelection"
import useOnChange from "hooks/useOnChange"
import KeyListeners from "components/KeyListeners/KeyListeners"
import useCreateTasksFromLines from "hooks/useCreateTasksFromLines"
import clsx from "clsx"
import { RoomContext } from "components/Room/contexts/room"
import useKanbanColumns from "hooks/useKanbanColumns"

export default function AddTasksPanel(props: {
  className?: string
  documentSelection: ReturnType<typeof useDocumentSelection>
  onClickColumnOption?: () => any
  onClose?: () => any
}) {
  const t = useI18n()

  const oc = useOpenClose()
  const documentSelection = props.documentSelection
  const amountOfLines = documentSelection?.selectedLines?.length || 0

  const creation = useCreateTasksFromLines()

  KeyListeners.useEscapeListener(oc.close, { enabled: oc.isOpen })

  useOnChange({
    value: oc.isOpen,
    onChange: (isOpen) => {
      if (!isOpen) {
        props.onClose?.()
      }
    },
  })

  useOnChange({
    value: amountOfLines,
    onChange(number) {
      if (number > 0) {
        oc.open()
      }

      if (number === 0) {
        oc.close()
      }
    },
  })

  const ref = React.useRef<HTMLDivElement>(null)

  const roomID = React.useContext(RoomContext).roomID || ""

  const { columns, selectedColumn, setSelectedColumn } = useKanbanColumns({
    setFirstColumnAsDefault: true,
    roomID,
  })

  const c = useStyles()
  const length = amountOfLines

  const variables = {
    columnID: selectedColumn?.id || "",
    text: documentSelection?.textSelection || "",
  }

  if (!variables.columnID || !variables.text) return null

  return (
    <div className={clsx(props.className, "add-tasks-panel")} ref={ref}>
      <Chip
        variant="default"
        color="primary"
        disabled={creation.isCreating}
        className={c.chip}
        label={t.addNTasksToColumn(length, selectedColumn?.title || "")}
        size="small"
        clickable
        onClick={() => {
          const m = creation.createTasksFromLines(variables)

          if (!m) return

          m.then(() => {
            oc.close()
          })
        }}
      ></Chip>
      {length > 1 ? (
        <Chip
          variant="default"
          color="primary"
          disabled={creation.isCreating}
          className={c.chip}
          label={t.addNTasksToColumn(1, selectedColumn?.title || "")}
          size="small"
          clickable
          onClick={() => {
            const m = creation.createASingleTask(variables)
            m?.then(() => {
              oc.close()
            })
          }}
        ></Chip>
      ) : null}
      {columns.length > 1 ? (
        <ToggleableMenu
          closeOnSelect
          menuItems={columns.map((c) => ({
            text: c.title || "",
            isSelected: c.id === selectedColumn?.id,
            onClick: () => {
              props.onClickColumnOption?.()
              setSelectedColumn(c)
            },
          }))}
          anchor={
            <Chip
              variant="outlined"
              label={t.changeColumn}
              className={c.chip}
              color="primary"
              size="small"
              style={{ marginBottom: 0, width: "100%" }}
              clickable
            ></Chip>
          }
        ></ToggleableMenu>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    chip: {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
  }
})
