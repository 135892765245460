import _ from "lodash"
import {
  DissociateCollectionDocument,
  DissociateCollectionMutation,
  DissociateCollectionMutationVariables,
  CollectionItemFragment,
  DocumentDocument,
} from "lib/graphql/operations"
import { DataProxy } from "apollo-cache"
import { ApolloClient, gql } from "@apollo/client";
import { Typename } from "types/type"
import updateFragment from "lib/updateFragment/updateFragment"

export default async function dissociateCollection(
  props: {
    collectionID: string
    documentID: string
    workspaceID: string
    onDissociate?: (props: {
      collection: CollectionItemFragment
      apolloCache: DataProxy
    }) => void
  },
  client: ApolloClient<{}>
) {
  const {
    collectionID,
    documentID,
    workspaceID,
    onDissociate = ({ collection, apolloCache }) => undefined,
  } = props

  await client.mutate<
    DissociateCollectionMutation,
    DissociateCollectionMutationVariables
  >({
    mutation: DissociateCollectionDocument,
    variables: { collectionID, documentID, workspaceID },
    optimisticResponse: {
      dissociateCollection: {
        __typename: "BasicResponse",
        success: true,
      },
    },
    refetchQueries: [
      { query: DocumentDocument, variables: { documentID, workspaceID } },
    ],
    update(cache, { data }) {
      if (!data?.dissociateCollection?.success) return

      updateFragment(
        {
          typename: Typename.Document,
          cacheIDParams: { id: documentID },
          updateFn(a) {
            const updated = {
              ...a,
              collections: [...(a.collections || [])].filter(
                (c) => c.id !== collectionID
              ),
            }

            return updated
          },
        },
        cache
      )
    },
  })
}
