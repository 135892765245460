import {
  RoomUpdateFragment,
  RoomUpdate_CreatedRoom_Fragment,
  RoomUpdate_DeletedRoom_Fragment,
  RoomUpdate_ExcludedFromRoom_Fragment,
  RoomUpdate_NewRoom_Fragment,
  RoomUpdate_RoomNewMessage_Fragment,
  RoomUpdate_UpdatedRoomMembers_Fragment,
  WorkspacesUpdatesSubscription,
} from "lib/graphql/operations"
import React from "react"
import { filter, mergeMap } from "rxjs/operators"
import { Observable } from "rxjs"

export default function useRoomUpdate$(props: {
  workspacesUpdates$: Observable<
    WorkspacesUpdatesSubscription["workspacesUpdates"]
  >
}) {
  const roomUpdate$ = React.useMemo(
    () => props.workspacesUpdates$.pipe(mergeMap(getRoomUpdate)),
    [props.workspacesUpdates$]
  )

  const api = React.useMemo(() => {
    return {
      createdRoom$: getUpdate<RoomUpdate_CreatedRoom_Fragment>(
        roomUpdate$,
        "CreatedRoom"
      ),
      updateRoomMembers$: getUpdate<RoomUpdate_UpdatedRoomMembers_Fragment>(
        roomUpdate$,
        "UpdatedRoomMembers"
      ),
      deletedRoom$: getUpdate<RoomUpdate_DeletedRoom_Fragment>(
        roomUpdate$,
        "DeletedRoom"
      ),
      roomNewMessage$: getUpdate<RoomUpdate_RoomNewMessage_Fragment>(
        roomUpdate$,
        "RoomNewMessage"
      ),
      newRoom$: getUpdate<RoomUpdate_NewRoom_Fragment>(roomUpdate$, "NewRoom"),
      excludedFromRoom$: getUpdate<RoomUpdate_ExcludedFromRoom_Fragment>(
        roomUpdate$,
        "ExcludedFromRoom"
      ),
    }
  }, [roomUpdate$])

  return api
}

function getRoomUpdate(
  workspaceUpdates: WorkspacesUpdatesSubscription["workspacesUpdates"]
) {
  return (workspaceUpdates || [])
    .map((a) => a?.roomsUpdates || [])
    .flat()
    .filter((a): a is RoomUpdateFragment => {
      return !!a
    })
}

function getUpdate<T extends RoomUpdateFragment>(
  update$: Observable<RoomUpdateFragment>,
  typename: T["__typename"]
) {
  return update$.pipe(filter((a): a is T => typename === a.__typename))
  // return
}
