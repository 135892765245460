import _f from "lodash/fp"
import _ from "lodash"
import React from "react"
import { Typography, makeStyles } from "@material-ui/core"
import { clipText } from "styles/globalStyles"
import Thumbnail from "components/Thumbnail/Thumbnail"
import clsx from "clsx"
import RootContainer from "components/RootContainer/RootContainer"

export default function OpenGraphsTemplate(props: {
  thumbURL?: string
  description?: string
  title?: string
  caption?: string
  imageOnTop?: boolean
  url?: string
  thumbWidth?: number
  thumbHeight?: number
  className?: string
  clipText?: boolean
}) {
  const {
    thumbURL = "",
    description = "",
    title = "",
    caption = "",
    imageOnTop = false,
    url = "",
    thumbWidth = 60,
    thumbHeight = 60,
    className = "",
    clipText = true,
  } = props

  const c = useStyles({ imageOnTop })

  return (
    <div className={clsx(c.previewTemplate, className)}>
      <a
        href={url || "#"}
        style={url ? {} : { cursor: "default" }}
        className="content"
        {...(url ? { target: "_blank" } : {})}
      >
        {thumbURL && (
          <Thumbnail
            width={thumbWidth}
            height={thumbHeight}
            className={clsx(
              c.thumb,
              imageOnTop && "on-top",
              !imageOnTop && "on-left"
            )}
            src={thumbURL}
          />
        )}

        <RootContainer.Context.Consumer>
          {({ sizeLabel }) => {
            return (
              <div
                className="preview-text"
                style={{
                  width: thumbWidth ? `calc(100% - ${thumbWidth}px)` : "75%",
                  // minWidth: 250,
                  minWidth:
                    (sizeLabel === RootContainer.SizeEnums.SMALL && "0") || 250,
                  paddingLeft: 8,
                }}
              >
                <div className={clsx(clipText && c.clipText)}>
                  {title && (
                    <Typography variant="body2" color="textSecondary">
                      {title}
                    </Typography>
                  )}
                  {caption && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={c.caption}
                    >
                      {caption}
                    </Typography>
                  )}
                  {/* {description && ( */}
                  <Typography variant="body2">
                    {description || (
                      <span style={{ visibility: "hidden" }}>placeholder</span>
                    )}
                  </Typography>
                  {/* )} */}
                </div>
              </div>
            )
          }}
        </RootContainer.Context.Consumer>
      </a>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    thumb: {
      // marginRight: theme.spacing(1),
      // height: "auto !important",
      "&.on-left": {
        // flex: 1
      },
      "&.on-top": {
        width: "100% !important",
        marginBottom: theme.spacing(0.5),
        minWidth: ({ isSmallMobile }) => (!isSmallMobile ? 250 : 0),
        minHeight: "150px",
        marginRight: 0,
      },
    },
    caption: { "margin-top": "-2px", display: "block", marginBottom: "2px" },
    previewTemplate: {
      paddingLeft: theme.spacing(1),
      cursor: "pointer",
      borderLeft: "2px solid ".concat(theme.palette.primary.main),
      alignItems: "center",
      "&>*": { maxWidth: "100%" },
      "& .content": {
        display: "flex",
        display: ({ imageOnTop }) => (imageOnTop ? "block" : "flex"),
        alignItems: "stretch",
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    clipText: { "&>*": { ...clipText }, display: "block" },
  }
})
