import React from "react"
import moment from "moment"
import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"

export type WeekRowProps = React.ComponentProps<typeof WeekRow>

export default function WeekRow(props: {
  week: moment.Moment[]
  renderWeekName?: (props: {
    day: moment.Moment
    defaultComponent: React.ReactNode
  }) => React.ReactNode
  renderDay?: (props: {
    day: moment.Moment
    defaultComponent: React.ReactNode
  }) => React.ReactNode
  showDayOfWeekName?: boolean
  style?: React.CSSProperties
  className?: string
  classes?: { cell?: string }
  onClickCell?: (props: { day: moment.Moment; target: EventTarget }) => void
  renderHeader?: (props: {
    weekDayIndex: number
    defaultComponent: React.ReactNode
  }) => React.ReactNode
  renderCellContent?: (props: {
    header: React.ReactNode
    day: moment.Moment
  }) => React.ReactNode
}) {
  const c = useStyles()

  return (
    <div
      className={clsx("week-row", c.root, props.className)}
      style={props.style}
    >
      {props.week.map((day, dayIndex) => (
        <div
          className={clsx("cell", props.classes?.cell)}
          key={day.format("YYYY-MM-DD")}
          onClick={(e) =>
            props.onClickCell?.call(undefined, { day, target: e.target })
          }
        >
          {(() => {
            const header = (() => {
              const defaultComponent = (
                <div className={c.header}>
                  {(function renderWeekDay() {
                    if (!props.showDayOfWeekName) return null

                    const defaultComponent = (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className={clsx(
                          "default-week-name",
                          c.textAlignCenter,
                          c.displayBlock
                        )}
                      >
                        {day.format("ddd").replace(".", "")}
                      </Typography>
                    )

                    if (!props.renderWeekName) return defaultComponent

                    return props.renderWeekName({ day, defaultComponent })
                  })()}
                  {(function renderDay() {
                    const defaultComponent = (
                      <Typography
                        // variant="body1"
                        variant="body1"
                        color="textSecondary"
                        // className={c.defaultCell}

                        className={clsx(
                          "default-day",
                          c.textAlignCenter,
                          c.displayBlock
                        )}
                      >
                        {day.format("DD")}
                      </Typography>
                    )

                    if (!props.renderDay) return defaultComponent

                    return props.renderDay({ day, defaultComponent })
                  })()}
                </div>
              )

              if (!props.renderHeader) return defaultComponent

              return props.renderHeader({
                weekDayIndex: dayIndex,
                defaultComponent,
              })
            })()

            return (
              props.renderCellContent?.call(undefined, { header, day }) ||
              header
            )
          })()}
        </div>
      ))}
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      "&>.cell": {
        flex: 1,
      },
    },

    header: {
      // paddingBottom: theme.spacing(0.5),
    },

    cell: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },

    textAlignCenter: {
      textAlign: "center",
    },

    displayBlock: {
      display: "block",
    },
  }
})
