import React from "react"
import {
  DeleteRoomMutation,
  useDeleteRoomMutation,
} from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import updateRoomsQuery from "lib/updateRoomsQuery"

export default function useDeleteRoom() {
  const [mutation, { loading: isLoading }] = useDeleteRoomMutation()

  const { workspaceID } = useActiveResources()

  const deleteRoom = React.useCallback(
    async (props: { id: string }) => {
      return new Promise<DeleteRoomMutation["deleteRoom"]>(
        async (resolve, reject) => {
          const mutationResult = await mutation({
            variables: { workspaceID, id: props.id },
            optimisticResponse: {
              __typename: "Mutation",
              deleteRoom: {
                __typename: "BasicResponse",
                success: true,
              },
            },
            update(dataProxy, { data }) {
              const success = data?.deleteRoom?.success

              if (!success) return

              updateRoomsQuery.removeRoom(
                { roomID: props.id, workspaceID },
                dataProxy
              )
            },
          })

          if (mutationResult.errors) {
            return reject(mutationResult.errors)
          }

          resolve(mutationResult.data?.deleteRoom)
        }
      )
    },
    [mutation, workspaceID]
  )

  return useMemoAPI({ isLoading, deleteRoom })
}
