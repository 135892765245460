import React, { useEffect } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import clsx from "clsx"

export default function DecisionDialog(props: {
  title: string
  content: string
  onAgree: () => void
  className?: string
  onDisagree: () => void
  agreeText?: string
  disagreeText?: string
  open: boolean
  style?: React.CSSProperties
  onClose?: () => void
}) {
  const {
    title,
    content,
    onAgree,
    onDisagree,
    agreeText = "yes",
    disagreeText = "no",
    open: propsOpen,
    onClose: propsOnClose = () => undefined,
  } = props

  const [open, setOpen] = React.useState(!!propsOpen)

  useEffect(() => {
    setOpen(!!propsOpen)
  }, [propsOpen])

  const agree = () => {
    if (typeof onAgree === "function") onAgree()
    setOpen(false)
  }

  const disagree = () => {
    if (typeof onDisagree === "function") onDisagree()
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
        if (typeof propsOnClose === "function") propsOnClose()
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={clsx("decision-dialog", props.className)}
      style={props.style}
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      {content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {disagreeText && (
          <Button onClick={disagree} color="primary" variant="contained">
            {disagreeText}
          </Button>
        )}
        {agreeText && (
          <Button onClick={agree} color="primary">
            {agreeText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
