import _ from "lodash"
import React, { useMemo, useContext } from "react"
import useCollectionsSearch from "hooks/useCollectionsSearch/useCollectionsSearch"
import { COLLECTIONS_TYPES_COLOR, CONTACT_TYPES } from "lib/constants"
import { makeStyles } from "@material-ui/core"
import { useLoadingState } from "components/PageLayoutFromPageID/PageLayoutFromPageID"
import useOnChange from "hooks/useOnChange"
import {
  useUtilsContext,
  useParamsContext,
} from "pages/Partners/contexts/libContext"
import PaperList from "components/PaperList"
import { CollectionsListItem } from "./components/CollectionsListItem/CollectionsListItem"
import SideBySideLayout from "components/SideBySideLayout/SideBySideLayout"
import { CollectionDetails } from "./components/CollectionDetails/CollectionDetails"
import PartnersContext from "pages/Partners/contexts/PartnersContext"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"

export default function Main(props: { rootContainer?: Element | null }) {
  const { searchedTerm, types, collectionID, collectionTypeColor } =
    useContext(PartnersContext)

  const {
    collections: collectionsItems,
    isLoading,
    fetchMore,
  } = useCollectionsSearch({
    searchTerm: searchedTerm,
    types: _.isEmpty(types) ? CONTACT_TYPES : types,
    maxLimit: 1000,
    searchType: "collections",
    fetchPolicy: "cache-and-network",
  })

  // const { getCollectionLink } = useUtilsContext()
  const c = useStyles()

  const [, setIsLoading] = useLoadingState()
  useOnChange({ value: isLoading, onChange: setIsLoading })

  const items = useMemo(
    () => collectionsItems,
    [_.map(collectionsItems, "id").join("-")]
  )

  return (
    <SideBySideLayout
      rightIsOpen={!!collectionID}
      renderLeft={function renderLeft() {
        return (
          <PaperList.Provider
            selectedEntryColor={collectionTypeColor}
            items={items}
            fetchMore={fetchMore}
            ListItem={CollectionsListItem}
          >
            <PaperList.OldRoot className={c.paperListRoot}></PaperList.OldRoot>
          </PaperList.Provider>
        )
      }}
      renderRight={() => {
        return <CollectionDetails></CollectionDetails>
      }}
    ></SideBySideLayout>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    paperListRoot: {
      height: "100%",
    },
  }
})
