import { makeStyles } from "@material-ui/core"
import clsx from "clsx"

import { StyledProps } from "types/type"
import { CameraButton } from "./components/CameraButton/CameraButton"
import { MicButton } from "./components/MicButton/MicButton"
import { LeaveButton } from "./components/LeaveButton/LeaveButton"

export function ConferenceControls(props: StyledProps) {
  const c = useStyles()

  return (
    <div
      className={clsx("conference-controls", props.className, c.root)}
      style={props.style}
    >
      <CameraButton></CameraButton>
      <MicButton></MicButton>
      <LeaveButton></LeaveButton>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "&>*:not(:last-child)": {
      marginRight: 16,
    },
  },
}))
