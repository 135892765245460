import AccountNotificationsCount from "components/AccountNotificationsCount"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import useSearchParams from "hooks/useSearchParams/useSearchParams"
import _ from "lodash"
import ChatAPI from "pages/ChatPage/index"
import { StartPageBoard } from "pages/Home/components/StartPageBoard/StartPageBoard"
import Partners from "pages/Partners/Partners"
import React, { useMemo } from "react"
import { SECTIONS_IDS } from "../constants"

const Start = React.lazy(() => import("pages/Start/Start"))
const Documents = React.lazy(() => import("pages/Documents/Documents"))
const Chat = React.lazy(() => import("pages/ChatPage/ChatPage"))

export default function useSectionsRoutes() {
  const { workspace } = useCurrentWorkspace()
  const shopIsEnabled = !!(workspace || {}).shop
  const t = useI18n()

  const params = useSearchParams()

  const sectionsRoutes = useMemo(() => {
    return [
      {
        path: "/" + SECTIONS_IDS.home,
        exact: false,
        icon: "home",
        name: "Home",
        menuRightComponent: AccountNotificationsCount,
        component: function Home() {
          return <StartPageBoard style={{ height: "100%" }}></StartPageBoard>
        },
        id: SECTIONS_IDS.home,
      },
      {
        path: "/" + SECTIONS_IDS.calendar,
        exact: false,
        icon: "event",
        name: "Calendar",
        component: Start,
        id: SECTIONS_IDS.calendar,
      },
      ...(() => {
        if (params.get(["old"])[0])
          //TODO: remove! Keeping for while
          return [
            {
              path: "/" + SECTIONS_IDS.chat,
              exact: false,
              icon: "comment",
              name: t["chat"],
              menuRightComponent: AccountNotificationsCount,
              component: Chat,
              getLink: ChatAPI.getLink,
              id: SECTIONS_IDS.chat,
            },
          ]

        return []
      })(),
      {
        path: "/" + SECTIONS_IDS.contacts,
        icon: "contacts",
        name: t["contacts"],
        component: Partners,
        exact: false,
        id: SECTIONS_IDS.contacts,
      },
      {
        path: "/" + SECTIONS_IDS.documents,
        icon: "find_in_page",
        name: t["documents"],
        component: Documents,
        exact: false,
        id: SECTIONS_IDS.documents,
      },
      // shopIsEnabled && {
      //   path: "/" + SECTIONS_IDS.shop,
      //   icon: "shopping-cart",
      //   name: (
      //     <LightTooltip
      //       title={_.get(workspace, "shop.slogan")}
      //       placement="bottom"
      //     >
      //       <div>{t["shop"]({ name: workspace.name })}</div>
      //     </LightTooltip>
      //   ),
      //   component: Shop,
      //   menuRightComponent: ShopCartQuantity,
      //   exact: false,
      //   id: SECTIONS_IDS.shop,
      // },
    ].filter(Boolean)
  }, [t, shopIsEnabled, (workspace || {}).id])

  const sectionsByID = useMemo(() => {
    return _.keyBy(sectionsRoutes, "id")
  }, [])

  return useMemo(
    () => ({
      sectionsRoutes,
      getSectionRoute({ id }) {
        return sectionsByID[id]
      },
    }),
    [sectionsByID, sectionsRoutes]
  )
}
