import groupsJSON from "lib/groups.json"
import _ from "lodash"
import { useI18n } from "contexts/i18nContext/i18nContext"
import SelectWithAutocomplete from "components/SelectWithAutocomplete/SelectWithAutocomplete"
import { TextFieldProps } from "@material-ui/core"

export default function GroupSelection(props: {
  type: string
  onChange?: (type: string) => void

  TextField?: React.FC<TextFieldProps>
  classes?: { suggestionsItem?: string }
  filter?: (o: (typeof groupsJSON)[0]) => boolean
  TextFieldProps?: TextFieldProps
}) {
  const t = useI18n()

  const options = groupsJSON
    .filter((o) => (props.filter ? props.filter(o) : true))
    .map(({ group, de }) => ({
      value: group,
      label: de,
    }))

  const selected = options.find((o) => o.value === props.type)

  return (
    <SelectWithAutocomplete
      options={options}
      textField={(selected || {}).label || ""}
      classes={props.classes}
      TextField={props.TextField}
      label={t.category}
      TextFieldProps={props.TextFieldProps}
      onSelect={(selected) => {
        if (!selected) return
        const type = (selected || {}).value
        props.onChange?.call(undefined, type)
      }}
    />
  )
}
