import ErrorSnackbar from "./components/ErrorSnackbar/ErrorSnackbar"
import ErrorControllerContext from "./contexts/ErrorControllerContext"
import useErrorControllerAPI from "./hooks/useErrorControllerAPI"

const ErrorController = {
  Context: ErrorControllerContext,
  SnackBar: ErrorSnackbar,
  Provider: function ErrorControllerProvider(props: {
    children: React.ReactNode
  }) {
    const api = useErrorControllerAPI()

    return (
      <ErrorControllerContext.Provider value={api}>
        {props.children}
      </ErrorControllerContext.Provider>
    )
  },
}

export default ErrorController
