import _ from "lodash"
import { makeStyles, ListItem, ButtonBase } from "@material-ui/core"
import clsx from "clsx"
import React, { useContext } from "react"
import PaperListApiContext from "../../contexts/PaperListContext"
import { ListItemProps } from "components/PaperList/types"

export default function PaperListItem<T>(props: ListItemProps<T>) {
  const {
    right = null,
    disableRipple = false,

    left = null,
  } = props

  const context = useContext(PaperListApiContext)

  const c = useStyles({
    selectedEntryColor: props.selectedColor || context.selectedEntryColor,
  })

  return React.createElement(
    // typeof props.onClick === "function" ? ButtonBase : "div",
    "div",
    {
      className: clsx(
        "paper-list-item",
        c.root,
        props.isSelected && "selected",
        props.className
      ),
      disableRipple,
      style: props.style,
      onClick: props.onClick,
      children: (
        <>
          {left && (
            <ListItem
              className={clsx(
                "list-item-left",
                c.listItemLeft,
                c.listItem,
                props.classes?.listItemLeft
              )}
            >
              {left}
            </ListItem>
          )}
          {right && (
            <ListItem
              className={clsx(
                "list-item-right",

                c.listItem,
                c.listItemRight,
                props.classes?.listItemRight
              )}
            >
              {right}
            </ListItem>
          )}
        </>
      ),
    }
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      textAlign: "left",
      cursor: "pointer",
      display: "flex",
      padding: 0,
      borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
      alignItems: "flex-start",
      // ...(index === 0 ? { background: "rgba(0, 0, 0, 0.08)" } : {}),
      "&.selected": {
        borderLeft: (props: { selectedEntryColor?: string }) =>
          `4px solid ${props.selectedEntryColor || theme.palette.primary.dark}`,
        // backgroundColor: "rgba(0, 0, 0, 0.08)",
      },

      "&>*": {
        flex: 1,
        overflow: "hidden",
      },
    },

    listItem: {
      overflow: "hidden",
      width: "auto",
    },

    listItemLeft: {},
    listItemRight: { display: "flex", justifyContent: "flex-end", flex: 1 },
  }
})
//
