import { useApolloClient } from "@apollo/client";
import React from "react"
import {
  useChangeKanbanCardImagesMutation,
  ChangeKanbanCardImagesMutationVariables,
  KanbanCardActivitiesDocument,
  KanbanCardActivitiesQueryVariables,
} from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import readFragment from "lib/updateFragment/readFragment"
import { Typename } from "types/type"
import updateQuery from "../../../../../../../../../lib/updateQuery/updateQuery"

export default function useDeleteImage(props: { cardID: string }) {
  const workspaceID = useActiveResources().workspaceID
  const [mutate] = useChangeKanbanCardImagesMutation()

  const client = useApolloClient()

  const deleteImage = React.useCallback(
    (imageID: string) => {
      if (!props.cardID || !workspaceID || !imageID) return

      const variables: ChangeKanbanCardImagesMutationVariables = {
        cardID: props.cardID,
        workspaceID,
        update: { list: { remove: [imageID] } },
      }

      return mutate({
        variables,
        refetchQueries: [
          {
            query: KanbanCardActivitiesDocument,
            variables: {
              cardID: props.cardID,
              workspaceID,
            } as KanbanCardActivitiesQueryVariables,
          },
        ],
        optimisticResponse(vars) {
          const fragment = readFragment(
            {
              typename: Typename.KanbanCardImages,
              cacheIDParams: { cardID: props.cardID },
            },
            client
          )

          return {
            __typename: "Mutation",
            changeKanbanCardImages: !fragment
              ? null
              : {
                  ...fragment,
                  list: fragment.list?.filter((a) => a.id !== imageID) || null,
                },
          }
        },
      })
    },
    [mutate, workspaceID, props.cardID, client]
  )

  return deleteImage
}
