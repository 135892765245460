import { LoggedUser } from "components/LoggedUser/LoggedUser"
import Workspaces from "components/Workspaces/Workspaces"
import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { UserType } from "lib/graphql/types"
import React from "react"

export const Interface = contextAndProviderFactory({
  hookApi: useInterfaceAPI,
})

function useInterfaceAPI() {
  const loggedUser = React.useContext(LoggedUser.Context)?.loggedUser

  // console.log(query)

  // console.log(query)

  const workspacesApi = React.useContext(Workspaces.Context)

  const isUser = loggedUser?.type === UserType.User
  const hasManyWorkspaces = workspacesApi.workspaces?.length > 1
  const showOldNavigation = isUser

  return useMemoAPI({
    /** legacy */
    showOldNavigation: isUser,
    allowPartnerInsertion: isUser,
    showWorkspaceSelector: hasManyWorkspaces,
    showHamburgerMenu: showOldNavigation || hasManyWorkspaces,
  })
}
