import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import useActiveResources from "hooks/useActiveResources"
import { useUpdateKanbanImageMutation } from "lib/graphql/operations"

export default function useUpdateKanbanImage(props: {
  imageID?: string | null
}) {
  const [mutate] = useUpdateKanbanImageMutation()

  const r = useActiveResources()

  const update = React.useCallback(
    (p: { filename: string }) => {
      // debugger
      if (!props.imageID) return

      return mutate({
        variables: {
          imageID: props.imageID,
          workspaceID: r.workspaceID,
          update: { filename: p.filename },
        },
      })
    },
    [r.workspaceID, props.imageID, mutate]
  )

  return useMemoAPI({ update })
}
