import { RoomMessageListQueryVariables } from "lib/graphql/operations"

export type LimitVariables = Pick<
  RoomMessageListQueryVariables,
  "startDate" | "endDate" | "startIndex" | "stopIndex" | "limit"
>

export default function limitVariables(props: LimitVariables) {
  const { endDate, startDate, startIndex, stopIndex, limit } = props
  const limitCriteria = (() => {
    if (startIndex || stopIndex) return "index"
    if (startDate || endDate) return "date"
  })()

  const uncompletedLimits =
    !(typeof startIndex === "number" && typeof stopIndex === "number") &&
    !(startDate && endDate)

  let variables: LimitVariables = {}

  if (limitCriteria === "index") {
    variables = {
      startIndex: startIndex || null,
      stopIndex: stopIndex || null,
      endDate: null,
      startDate: null,
      limit: null,
    }
  } else if (limitCriteria === "date") {
    variables = {
      endDate: endDate || null,
      startDate: startDate || null,
      startIndex: null,
      stopIndex: null,
      limit: null,
    }
  }

  return {
    ...variables,
    ...(uncompletedLimits ? { limit: limit || 0 } : {}),
  }
}
