import AssetsCacheUpdateModal from "./components/AssetsCacheUpdateModal/AssetsCacheUpdateModal"
import useOnCacheUpdate from "./components/AssetsCacheUpdateModal/useOnCacheUpdate"
import { cacheUpdate$, cacheUpdateAccepted$ } from "./lib"

const AssetsCacheUpdate = {
  update$: cacheUpdate$,
  accepted$: cacheUpdateAccepted$,
  Modal: AssetsCacheUpdateModal,
  useReloadWindowOnUpdate: function useReloadOnUpdate() {
    useOnCacheUpdate(() => window.location.reload())
  },
}

export default AssetsCacheUpdate
