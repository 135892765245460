import React, { useState } from "react"
import { useCollectionsItemsQuery } from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"

export default function useCollectionsSuggestion(props: { type: string }) {
  const [filter, setFilter] = useState("")

  const { workspaceID } = useActiveResources()

  const query = useCollectionsItemsQuery({
    skip: !workspaceID || !props.type,

    variables: {
      workspaceID,
      search: filter,
      limit: 5,
      types: [props.type],
    },
  })

  const collections = React.useMemo(
    () => query?.data?.collectionsItems || [],
    [query.data?.collectionsItems]
  )

  const removeFilter = React.useCallback(() => setFilter(""), [setFilter])

  const r = useMemoAPI({
    collections,
    filterSuggestions: setFilter,
    removeFilter,
  })

  return r
}
