import _ from "lodash"
import React, { useMemo } from "react"
import clsx from "clsx"
import MembersContext from "./contexts/MembersContext"
import modesProps from "./modesProps"
import { Paper } from "@material-ui/core"
import Scrollbars from "react-custom-scrollbars"
import useMembersProviderProps from "./hooks/useMembersProviderProps"
import { RoomContext } from "components/Room/contexts/room"

export default function Members(props: {
  className?: string
  isEditing?: boolean
  style?: React.CSSProperties
}) {
  const api = useMembersProviderProps()
  const { searchMembersAPI } = React.useContext(RoomContext)

  const allowEdit = !!searchMembersAPI?.allowedMembersToBeAdded

  const { modeButton, presenceList, search } = useMemo(
    () => modesProps({ allowEdit })[api.modeAPI.mode],

    [allowEdit, api.modeAPI.mode]
  )

  return (
    <MembersContext.Provider value={api}>
      <div className={clsx("members", api.classes.root)} style={props.style}>
        <Scrollbars className={api.classes.presenceListContainer}>
          {/* <React.Fragment key={allowedMembers?.searched}> */}
          {presenceList}
          {/* </React.Fragment> */}
        </Scrollbars>

        {allowEdit && (
          <Paper elevation={1} square className={api.classes.controlPanel}>
            {search || <div />}
            {modeButton}
          </Paper>
        )}
      </div>
    </MembersContext.Provider>
  )
}
