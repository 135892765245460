import { OrdinaryRoomIdentifier, RoomIdentifier } from "types/type"
import { ApolloClient } from "@apollo/client";
import { RoomType } from "lib/graphql/types"
import getRoomAPI from "./getRoomAPI"

export default class RoomResolver {
  workspaceID: string
  client: ApolloClient<{}>

  constructor(props: { workspaceID: string; client: ApolloClient<{}> }) {
    this.workspaceID = props.workspaceID
    this.client = props.client
  }

  async resolveRoom(identifier?: RoomIdentifier) {
    let result: {
      roomID?: string | null
      roomIsInexistent?: boolean
      api?: ReturnType<typeof getRoomAPI> | null
    } = {}

    if (!identifier) {
      result = { roomID: null, api: null }
      return result
    }

    const roomAPI = getRoomAPI({
      identifier,
      workspaceID: this.workspaceID,
      client: this.client,
    })

    if (!roomAPI) return { roomID: null, api: null }

    try {
      result.roomID = await roomAPI?.getRoomID()
    } catch (e) {
      if (roomAPI.allowAutomaticCreation) {
        result.roomID = await roomAPI.createRoom()?.then((a) => a?.id)
      } else {
        result.roomIsInexistent = true
      }
    }

    result.api = roomAPI

    return result
  }
}
