import TextField from "components/TextField/TextField"
import DocumentFormContext from "components/OldDocumentForm/contexts/DocumentFormContext"
import React from "react"
import SelectInput, {
  SelectInputProps,
} from "components/Input/components/SelectInput/SelectInput"
import useTypographyClass from "hooks/useTypographyClass"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { PartnerFormContext } from "../ProviderAndContext/ProviderAndContext"

export default function PartnerTypeSelectInput(
  props: Omit<SelectInputProps, "options">
) {
  const ctx = React.useContext(PartnerFormContext)

  const typesAvailable = React.useMemo(() => {
    return ctx.partner?.typesAvailable || []
  }, [ctx.partner?.typesAvailable])

  const options = React.useMemo(() => {
    let opts: SelectInputProps["options"] = typesAvailable.map((t) => ({
      value: t.value.toUpperCase(),
      label: t.locale,
    }))
    return opts
  }, [typesAvailable])

  const tClass = useTypographyClass()

  const c = usePartnerTypesSelectStyles()
  return (
    <SelectInput
      {...props}
      value={(props.value || "").toUpperCase()}
      className={clsx("partner-types-select-input", c.root)}
      options={options}
      label="Type"
      TextField={TextField}
      MenuItemProps={{ classes: { root: tClass.body1 } }}
    ></SelectInput>
  )
}
const usePartnerTypesSelectStyles = makeStyles((theme) => {
  return {
    root: {
      "& .MuiSvgIcon-root": {
        fontSize: "24px !important",
      },
    },
  }
})
