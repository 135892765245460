import { PageBoardItemParams } from "lib/graphql/types"
import { PageComponent } from "../../../../../../../Page/hooks/useSecondPage"
import { SecondPage } from "./SecondPage"
import { SecondPageItemProps } from "./types"

export function getSecondPage<Type extends PageBoardItemParams["type"]>(
  props: SecondPageItemProps<Type>
): PageComponent {
  return function PageBoardItemPage({ onClose }) {
    // console.log(props, "gingau")

    return <SecondPage {...props} onClose={onClose}></SecondPage>
  }
}
