import { makeStyles, Tab, Tabs } from "@material-ui/core"
import useComponentSize from "@rehooks/component-size"
import clsx from "clsx"
import { InlinedChips } from "components/InlinedChips/InlinedChips"
import { IndexedItems } from "components/PageBoard/components/IndexedItems/IndexedItems"
import { ViewID } from "components/PageBoard/hooks/useViews"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { excludeNullable } from "lib/excludeNullable"
import React from "react"
import { StyledProps } from "types/type"
import PageBoardContext from "../../../../contexts/PageBoardContext"
import { IconsFilters } from "../../../IconsFilters/IconsFilters"
import { HiddenMostUsedTypesChips } from "./HiddenMostUsedTypesChips"

export function ListHeader(props: StyledProps) {
  const api = React.useContext(PageBoardContext)

  const c = useStyles()

  const rootRef = React.useRef<HTMLDivElement>(null)
  const tabsRef = React.useRef<HTMLDivElement>(null)

  const rootSize = useComponentSize(rootRef)
  const tabsSize = useComponentSize(tabsRef)

  const selectedPeriodChipProps = IndexedItems.useSelectedPeriodChipProps()

  const t = useI18n()

  return (
    <div
      ref={rootRef}
      className={clsx(props.className, "list-header")}
      style={props.style}
    >
      {(() => {
        const labels: {
          [id in ViewID]: string
        } = {
          cards: t["cards"],
          list: t["list"],
          // week: t["week"],
        }

        return (
          <div className={c.content}>
            <div ref={tabsRef}>
              <Tabs
                value={api.views.selectedID}
                onChange={(_, viewID) => api.views.setSelectedID(viewID)}
                className={c.tabs}
                classes={{ indicator: c.indicator }}
              >
                {(api.views.possible || []).map((t) => {
                  return <Tab className={c.tab} value={t} label={labels[t]} />
                })}
              </Tabs>
            </div>

            <IconsFilters
              maxWidthAvailable={Math.min(
                rootSize.width - tabsSize.width,
                rootSize.width / 2
              )}
            ></IconsFilters>
          </div>
        )
      })()}

      <HiddenMostUsedTypesChips
        children={(p) => (
          <>
            <InlinedChips
              wrap
              chips={[
                selectedPeriodChipProps,
                ...(p.typesChipsProps || []),
              ].filter(excludeNullable)}
              className={c.hiddenMostUsedTypesChips}
              style={props.style}
            ></InlinedChips>
          </>
        )}
      ></HiddenMostUsedTypesChips>
    </div>
  )
}

export const useStyles = makeStyles((theme) => {
  return {
    tabs: {
      minHeight: 0,
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    hiddenMostUsedTypesChips: {
      marginTop: 12,
      marginBottom: -8,
    },
    tab: {
      textTransform: "capitalize",
      minHeight: 43,
      textAlign: "initial",
      lineHeight: 1,
      minWidth: 0,
      padding: theme.spacing(0, 1.5),
    },
    indicator: {
      backgroundColor: "black",
    },
  }
})
