import { useApolloClient } from "@apollo/client";
import useActiveResources from "hooks/useActiveResources"
import {
  ChangeKanbanCardMutation,
  KanbanCardActivitiesDocument,
  KanbanCardActivitiesQueryVariables,
  KanbanCardFragment,
  useChangeKanbanCardMutation,
} from "lib/graphql/operations"
import { KanbanCardInput } from "lib/graphql/types"
import readFragment from "lib/updateFragment/readFragment"
import React from "react"
import { Typename } from "types/type"

export default function useChangeKanbanCard(props?: {
  cardID?: string | undefined | null
}) {
  const [changeKanbanCardMutation] = useChangeKanbanCardMutation()

  const r = useActiveResources()

  const client = useApolloClient()

  const changeKanbanCard = React.useCallback(
    (input: KanbanCardInput, options?: { cardID?: string }) => {
      const cardID = options?.cardID || props?.cardID

      if (!cardID) return

      return changeKanbanCardMutation({
        variables: {
          cardID,
          workspaceID: r.workspaceID,
          card: input,
        },

        optimisticResponse: (() => {
          const a: ChangeKanbanCardMutation = {
            __typename: "Mutation",
            changeKanbanCard: (() => {
              const oldCard = readFragment(
                {
                  typename: Typename.KanbanCard,
                  cacheIDParams: { id: cardID },
                },
                client
              )

              if (!oldCard) return null

              const a: KanbanCardFragment = {
                ...oldCard,
                ...input,
                id: oldCard.id,
              }

              return a
            })(),
          }

          return a
        })(),

        refetchQueries: [
          {
            query: KanbanCardActivitiesDocument,
            variables: {
              cardID,
              workspaceID: r.workspaceID,
            } as KanbanCardActivitiesQueryVariables,
          },
        ],
      })
    },
    [client, changeKanbanCardMutation, r.workspaceID, props?.cardID]
  )

  return changeKanbanCard
}
