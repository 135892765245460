import cachedIDGetters from "lib/getCacheID/getCacheID"
import { Typename } from "types/type"
import { fragmentMap, FragmentMap } from "./fragmentMap"
// import FragmentMap, { fragmentMap } from "./fragmentMap"

export default function getFragmentParams<
  Type extends Extract<keyof FragmentMap, Typename>
>(props: {
  typename: Type
  cacheIDParams: Parameters<(typeof cachedIDGetters)[Type]>[0]
}) {
  const fragment = {
    fragment: fragmentMap[props.typename].fragment,
    fragmentName: fragmentMap[props.typename].fragmentName,
    id: cachedIDGetters[props.typename](props.cacheIDParams),
  }

  return fragment
}
