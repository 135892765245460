import React from "react"

const ActiveRoomContext = React.createContext(
  {} as {
    activeRoomID: string | null
    setActiveRoomID: (roomID: string | null) => void
  }
)

ActiveRoomContext.displayName = "ActiveRoomContext"

export function ActiveRoomProvider(props: { children: React.ReactNode }) {
  const [activeRoomID, setActiveRoomID] = React.useState<string | null>(null)

  const api = React.useMemo(
    () => ({ activeRoomID, setActiveRoomID }),
    [activeRoomID]
  )

  return (
    <ActiveRoomContext.Provider value={api}>
      {props.children}
    </ActiveRoomContext.Provider>
  )
}

export function useActiveRoomID() {
  return React.useContext(ActiveRoomContext)
}
