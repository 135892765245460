import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import {
  SearchMembersQueryVariables,
  useSearchMembersQuery,
} from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import useThrottledValue from "./useThrottledValue"

export default function useSearchMembersAPI(props: {
  roomID?: string
  disabled?: boolean
}) {
  const { roomID } = props

  const { workspaceID } = useActiveResources()

  const [searched, setSearched] = React.useState<string>("")

  const throttledSearched = useThrottledValue(searched, 2000)

  const defaultLimit = 80

  const limit = React.useRef<number>(defaultLimit)

  const variables: SearchMembersQueryVariables = {
    roomID: roomID || "",
    workspaceID,
    search: throttledSearched,
    limit: defaultLimit,
  }

  React.useCallback(() => {
    limit.current = defaultLimit
  }, Object.values(variables))

  const query = useSearchMembersQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip: !roomID || !workspaceID || !!props.disabled,
  })

  const fetchMore = React.useCallback(() => {
    limit.current += 80

    return query.fetchMore({
      variables: { ...variables, limit: limit.current },
      updateQuery(__, { fetchMoreResult }) {
        const fetched = fetchMoreResult?.allowedMembersToBeAdded || []
        return { __typename: "Query", allowedMembersToBeAdded: fetched }
      },
    })
  }, [query.fetchMore, variables])

  return useMemoAPI({
    isLoading: query.loading,
    allowedMembersToBeAdded: query?.data?.allowedMembersToBeAdded || [],
    roomMembers: query?.data?.roomMembers || [],
    search: setSearched,
    searched,
    fetchMore,
  })
}
