import useActiveResources from "../useActiveResources"
// import useQuery from "../apolloHooks/useQuery"
import { WatchQueryFetchPolicy } from "@apollo/client"
import useMemoAPI from "hooks/useMemoAPI"
import {
  DetailedContactFragment,
  DetailedContactQueryVariables,
  useDetailedContactQuery,
} from "lib/graphql/operations"

export default function useDetailedContact({
  id = "",
  collectionID = "",
  fetchPolicy = "cache-first",
  contact: propsContact,
}: {
  id?: string
  collectionID?: string
  fetchPolicy?: WatchQueryFetchPolicy
  contact?: DetailedContactFragment | null
}) {
  const { workspaceID } = useActiveResources()

  const variables: DetailedContactQueryVariables = {
    collectionID,
    id,
    workspaceID,
  }

  const skip =
    !!propsContact ||
    (!variables.id && !variables.collectionID) ||
    !variables.workspaceID

  const { data, loading } = useDetailedContactQuery({
    fetchPolicy,
    variables,
    skip: skip,
  })

  const result = useMemoAPI({
    detailedContact: propsContact || data?.detailedContact || null,
    isLoading: loading,
  })

  return result
}
