import _ from "lodash"
import React from "react"
import useList from "hooks/useList"
import useMemoAPI from "hooks/useMemoAPI"

type Keys = "Escape" | "KeyF" | "ArrowDown" | "ArrowUp" | "Enter"
type KeyListenerWithID = { id: string } & KeyListener

export type KeyListener = {
  fn: () => any
  code: Keys
  altKey: boolean
}

export default function useKeyListenersAPI() {
  const listeners = useList<KeyListenerWithID>()

  const activeListeners = React.useRef<{ [i in Keys]?: KeyListenerWithID }>({})

  React.useEffect(() => {
    activeListeners.current = {}

    listeners.items.forEach((i) => {
      /**
       *  Grabbing the last listener for a specific key.
       *  The last listener should be the active.
       */
      activeListeners.current[i.code] = i
    })
  }, [listeners.items])

  React.useEffect(() => {
    function obs(this: Document, evt: KeyboardEvent) {
      evt = evt || window.event

      if (!evt.key) return

      const code = evt.code as Keys

      const keyListener = activeListeners.current?.[code]

      if (!keyListener?.fn) return

      const execute = () => {
        evt.preventDefault()
        return keyListener.fn()
      }

      if (!!keyListener.altKey) {
        if (!evt.altKey) return
        execute()
      }

      return execute()
    }

    document.addEventListener("keydown", obs)

    return () => {
      document.removeEventListener("keydown", obs)
    }
  }, [])

  return useMemoAPI({
    listeners,
  })
}
