import useMemoAPI from "hooks/useMemoAPI"
import useRootContainerAPI, {
  RootContainerAPI,
} from "hooks/useRootContainerAPI"
import { ChainProps } from "../components/ListItemEntry/components/Chain/Chain"
// import { ChainProps } from "../components/ItemsList/components/ListItemEntry/components/Chain/Chain"

//TODO: gradually, move to this hook all template related variables.
export default function useTemplate(props: {
  rootContainer: ReturnType<typeof useRootContainerAPI>
}) {
  const rootContainer = props.rootContainer
  const itemsListContainer = useRootContainerAPI()

  const chainDisplay: ChainProps["display"] =
    itemsListContainer.width < 500 ? "block" : "line"

  const lineMaxWidth = rootContainer.width < 700 ? 150 : 500
  // const lineMaxWidth = 1000

  return useMemoAPI({
    itemsListContainer,
    lineMaxWidth,
    chainDisplay,
  })
}
