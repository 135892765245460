import React from "react"
import { Button as PageButton } from "components/Page/Page"
import { useSecondPage } from "components/TwoPages/TwoPages"
import { OpenPageProps } from "components/Page/hooks/useSecondPage"
import TwoPagesContext from "components/TwoPages/contexts/TwoPagesContext"

export default function Button(props: {
  page: OpenPageProps
  iconName: string
  className?: string
}) {
  const api = React.useContext(TwoPagesContext)

  const p = useSecondPage({ twoPagesAPI: api, page: props.page })

  return (
    <PageButton
      invisible={false}
      iconName={props.iconName}
      className={props.className}
      isActive={p.isOpen}
      onClick={p.toggle}
    ></PageButton>
  )
}
