import clsx from "clsx"
import _ from "lodash"
import React from "react"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import ToggleableSearchField from "components/ToggeableSearchField/ToggleableSearchField"
import { makeStyles } from "@material-ui/styles"
import FilledSearchField from "components/FilledSearchInput/FilledSearchInput"
import SuggestionsWrapper from "components/SuggestionsWrapper/SuggestionsWrapper"
import { useInputController } from "hooks/useInputController"

export enum SearchVariants {
  MATCH_WINDOW_SIZE = "match-window-size",
  FILLED = "filled",
  TOGGLEABLE = "toggleable",
}

export default function Search({
  onChange,
  isActive = false,
  onSubmit,
  onceEmpty,
  defaultValue,
  value,
  variant = SearchVariants.MATCH_WINDOW_SIZE,
  className,
  onceActivate = () => undefined,
  onceDeactivate = () => undefined,
  suggestions: {
    items: suggestionsItems = [],
    renderItem: renderSuggestionItem = undefined,
    onSelect: onSelectSuggestionItem = undefined,
  } = {},
}) {
  const { isMobile } = React.useContext(DeviceObserver.Context)
  const c = useStyles()

  const { inputValue, setInputValue } = useInputController({
    value,
    onChange(value) {
      if (_.isFunction(onChange)) onChange(value)
      if (!value && _.isFunction(onceEmpty)) onceEmpty()
    },
  })

  const cleanInput = React.useCallback(() => {
    setInputValue("")
  }, [setInputValue])

  const commonProps = {
    onSubmit,
  }

  const render = ({ getInputProps, suggestionsComponent }) => {
    const inputProps = (additional = {}) => {
      return getInputProps({
        value: inputValue || "",
        type: "search",
        onChange: (e) => {
          const value = e.target.value
          setInputValue(value)
          ;(_.get(additional, "onChange") || ((value) => undefined))(value)
        },
      })
    }

    const common = {
      ...commonProps,
      suggestionsComponent,
      inputProps: inputProps(),
    }

    const toggleableSearch = (
      <ToggleableSearchField
        {...common}
        searchIsActive={isActive}
        onSearchIconPressed={onceActivate}
        onCloseIconPressed={() => {
          cleanInput()
          onceDeactivate()
        }}
        className={c.mobileInput}
      />
    )

    const filledSearch = (
      <FilledSearchField
        {...common}
        inputProps={inputProps({
          onBlur: onceDeactivate,
          onFocus: onceActivate,
        })}
      />
    )

    const searchComponents = {
      [SearchVariants.TOGGLEABLE]: toggleableSearch,
      [SearchVariants.FILLED]: filledSearch,
      [SearchVariants.MATCH_WINDOW_SIZE]: isMobile
        ? toggleableSearch
        : filledSearch,
    }

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          _.isFunction(common.onSubmit) && onSubmit(common.inputProps.value)
        }}
        className={clsx("search")}
      >
        {searchComponents[variant]}
      </form>
    )
  }

  return (
    <SuggestionsWrapper
      items={suggestionsItems}
      renderItem={renderSuggestionItem}
      onSelect={onSelectSuggestionItem}
      className={clsx("search", c.search, className)}
      inputValue={inputValue}
    >
      {render}
    </SuggestionsWrapper>
  )
}

Search.variants = SearchVariants

const useStyles = makeStyles((theme) => {
  return {
    search: {
      width: "100%",
    },
  }
})
