import { ChipProps } from "@material-ui/core"
import { InlinedChips } from "components/InlinedChips/InlinedChips"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import { PartnerFormContext } from "./ProviderAndContext/ProviderAndContext"

export function ContactsChips(props: { onClickNewContactButton?: () => void }) {
  const api = React.useContext(PartnerFormContext)

  const contacts = api.partner?.contacts

  const t = useI18n()

  return (
    <InlinedChips
      wrap
      chips={[
        ...(contacts || []).map((contact) => {
          const a: ChipProps = {
            key: contact.id,
            onClick: () => {
              api.onClickContactChip?.(contact)
            },
            label: [contact.firstName, contact.lastName]
              .filter((a): a is string => !!a)
              .join(" "),
          }

          return a
        }),
        {
          color: "primary",
          variant: "outlined",
          label: t["new-contact"],
          onClick: props.onClickNewContactButton,
        },
      ]}
    ></InlinedChips>
  )
}
