import { useApolloClient } from "@apollo/client"
import useAppIsVisible from "hooks/useAppIsVisible"
import isTouchDevice from "lib/isTouchDevice"
import moment from "moment"
import { useSubscription } from "observable-hooks"
import React from "react"
import { filter } from "rxjs/operators"

function useAppBackFromInactivityAfterCertainTime$(props: {
  periodInMinutes: number
}) {
  const periodInMinutes = props.periodInMinutes

  const { appIsVisible$ } = useAppIsVisible()

  const [lastTime, setLastTime] = React.useState<string | null>(null)

  const appIsInactive$ = React.useRef(
    appIsVisible$.pipe(filter((value) => !value))
  ).current

  const appIsActive$ = React.useRef(
    appIsVisible$.pipe(filter((value) => !!value))
  ).current

  useSubscription(
    appIsInactive$,
    React.useCallback(() => {
      setLastTime(new Date().toISOString())
    }, [])
  )

  const isPassed = React.useCallback(() => {
    const now = new Date().toISOString()

    const diff = moment(now).diff(moment(lastTime), "minutes", true)

    return diff > periodInMinutes
  }, [lastTime])

  return React.useMemo(() => {
    return appIsActive$.pipe(filter(() => isPassed()))
  }, [isPassed, appIsActive$])
}

export function ReloadAppAfterSomeTimeInactive() {
  const shouldReload$ = useAppBackFromInactivityAfterCertainTime$({
    periodInMinutes: isTouchDevice ? 2 : 20,
  })

  const client = useApolloClient()

  useSubscription(
    shouldReload$,
    React.useCallback(() => {
      process.nextTick(() => {
        client.refetchQueries({ include: "active" })
      })
    }, [])
  )

  return null
}
