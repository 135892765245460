import { useMemo } from "react"
import useRouter from "use-react-router"

export default function useAppRouter() {
  const router = useRouter()

  return useMemo(() => {
    if (!("Proxy" in window)) return router
    return {
      ...router,
      //we use a proxy here to intercept calls to the history object for the sake of debugging
      history: new Proxy(router.history, {
        get(target, propKey, receiver) {
          const targetValue = Reflect.get(target, propKey, receiver)

          if (typeof targetValue === "function") {
            return function proxyFunction(...args) {
              const toReturn = targetValue.apply(this, args)
              return toReturn
            }
          }

          return targetValue
        },
      }),
    }
  }, [router])

  // const

  //   const ok = new Proxy(history, {
  //     get(target, propKey, receiver) {
  //       const targetValue = Reflect.get(target, propKey, receiver)

  //       console.log({ target, propKey, receiver, targetValue })
  //       // if (!["get", "post"].includes(propKey)) return targetValue

  //       return function request(...args) {
  //         // const pathname = args[0]
  //         // const baseURL = this.baseURL
  //         // const params = args[1]
  //         // const options = args[2]

  //         // const curl = getCurlString({
  //         //   baseURL,
  //         //   pathname,
  //         //   method: propKey,
  //         //   params,
  //         //   options
  //         // })

  //         // console.log(curl)
  //         console.log(...args)

  //         return targetValue.apply(this, args)
  //       }
  //     },
  //   })
}
