import React from "react"
// import { DocumentFormAPI } from "../../../../contexts/DocumentFormContext"
import { I18nAPI } from "contexts/i18nContext/i18nContext"
import { DocumentFormAPI } from "components/OldDocumentForm/contexts/DocumentFormContext"
import { FieldsetIndentationProps } from "../../../../../Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import TextField from "../../../../../TextField/TextField"

export default function getTextFieldProps(props: {
  translations: I18nAPI
  api: DocumentFormAPI
}) {
  const { translations, api } = props

  const indentationProps: FieldsetIndentationProps = {
    children: (
      <>
        <TextField
          label={"Text"}
          {...api.documentForm.getFieldProps("text")}
          multiline
        ></TextField>
      </>
    ),
    icon: "text_rotation_none_icon",
  }

  return indentationProps
}
