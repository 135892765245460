// import { FieldsetIndentationProps } from "components/DocumentForm/components/FieldsetIndentation/FieldsetIndentation"
import SaveButton from "components/SaveButton/SaveButton"
import React from "react"
import {
  ContactFormContext,
  ContactFormProvider,
} from "./components/ProviderAndContext/ProviderAndContext"
import { ContactFormRoot as Root } from "./ContactFormRoot"

export type ContactFormAPI = React.ComponentProps<typeof ContactFormProvider>

const ContactForm = {
  Root,
  Context: ContactFormContext,
  Provider: ContactFormProvider,
  SaveButton() {
    return (
      <ContactFormContext.Consumer>
        {(api) => {
          return (
            <SaveButton
              disabled={!api.form.readyToSubmit}
              onClick={() => {
                api.form.submit()
              }}
            ></SaveButton>
          )
        }}
      </ContactFormContext.Consumer>
    )
  },
}

export default ContactForm
