import Hook from "components/Hook/Hook"
import {
  fetchPageBoardItem,
  useFetchPageBoardItem,
} from "components/PageBoard/components/IndexedItems/IndexedItems"
import { getSecondPage } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/SecondPage/getSecondPage"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import { PageBoardItemType } from "lib/graphql/types"
import { ShopOrderForm } from "../../../ShopOrderForm/ShopOrderForm"

export function Body() {
  return (
    <ItemTemplate.Context.Consumer>
      {(templatePageApi) => {
        return (
          <Hook hook={useFetchPageBoardItem} hookProps={undefined}>
            {(fetchPageBoardItem) => {
              return (
                <ShopOrderForm.Body
                  onClickArticle={async (a) => {
                    const shopArticleItem = await fetchPageBoardItem(
                      PageBoardItemType.ShopArticle,
                      { articleID: a.article.id }
                    )

                    if (!shopArticleItem) return

                    templatePageApi.twoPagesAPI.openHalfPage(
                      getSecondPage({
                        item: shopArticleItem,
                        type: PageBoardItemType.ShopArticle,
                      })
                    )
                  }}
                ></ShopOrderForm.Body>
              )
            }}
          </Hook>
        )
      }}
    </ItemTemplate.Context.Consumer>
  )

  // <PageForm fieldsIndentationsProps={indentationProps}></PageForm>
}
