import Memo from "components/Memo/Memo"
import AnimationStatus from "components/AnimationStatus/AnimationStatus"
import React, { useCallback, useEffect, useMemo } from "react"
import { ImageViewerAPI } from "../../@types"
import useRange from "../../hooks/useRange/useRange"

export default function ImageViewerAPIProvider(props: {
  imageURL: string
  children: (api: ImageViewerAPI) => React.ReactChild | null
  active: boolean
  onClose: () => void
  duration: number
}): JSX.Element {
  const duration = props.duration

  const scale = useRange({ min: 0.1, max: 10, step: 0.1, defaultValue: 1 })

  useEffect(() => {
    if (!props.active) {
      scale.reset()
    }
  }, [props.active])

  const durationInMs = props.active ? 0 : duration

  return (
    <AnimationStatus active={props.active} durationInMs={durationInMs}>
      {(status) => {
        const api: ImageViewerAPI = {
          closeViewer: props.onClose,
          viewerIsOpen: props.active,
          animation: { duration, status },
          scale: scale,
          active: props.active,
        }

        return (
          <Memo factory={() => api} deps={Object.values(api)}>
            {(value) => {
              return <>{props.children(value)}</>
            }}
          </Memo>
        )
      }}
    </AnimationStatus>
  )
}
