import { IconButtonProps } from "@material-ui/core"
import { useI18n } from "../../contexts/i18nContext/i18nContext"
import { CartButton } from "./components/CartButton/CartButton"

export default function CartButtons({
  addMoreOne,
  remove,
  disabled = false,
  quantity,
  ...other
}: {
  addMoreOne: () => void
  remove: () => void
  disabled?: boolean
  quantity?: number
  IconButtonProps?: IconButtonProps
}) {
  const t = useI18n()

  return (
    <div>
      {!!quantity && (
        <CartButton
          tooltip={{ title: t["removeFromCart"] }}
          onClick={remove}
          iconName="remove_shopping_cart"
          disabled={disabled}
          IconButtonProps={other.IconButtonProps}
        ></CartButton>
      )}

      <CartButton
        tooltip={{ title: t["addToTheCart"] }}
        badge={{ content: quantity }}
        onClick={addMoreOne}
        disabled={disabled}
        iconName="add_shopping_cart"
        IconButtonProps={other.IconButtonProps}
        style={{ marginLeft: !!quantity ? 12 : 0 }}
      ></CartButton>
    </div>
  )
}
