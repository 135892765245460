import _ from "lodash"
import { VariableSizeGrid } from "react-window"
import { useMergeRefs } from "use-callback-ref"
import AutoSizer from "react-virtualized-auto-sizer"
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react"
import clsx from "clsx"
import useResizeObserver from "use-resize-observer"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import useIsMounted from "hooks/useIsMounted"
import useOnChange from "hooks/useOnChange"
import { makeStyles } from "@material-ui/core"
import invertMouseWheelScroll from "../../lib/invertMouseWheelScroll"

function VirtualizedGrid(
  {
    children = ({ index, data = undefined, onChangeHeight = undefined }) =>
      null,
    overscanRowCount = undefined,
    columnCount,
    defaultRowHeight = undefined,
    count,
    itemKey = undefined,
    // listRef = null,
    className = "",
    invertedScroll = false,
    getRowHeight: propsGetRowHeight = undefined,
    onItemsRendered: propsOnItemsRendered = ({
      overscanColumnStartIndex,
      overscanColumnStopIndex,
      overscanRowStartIndex,
      overscanRowStopIndex,
      visibleColumnStartIndex,
      visibleColumnStopIndex,
      visibleRowStartIndex,
      visibleRowStopIndex,
    }) => undefined,
    itemData = undefined,
  },
  ref
) {
  const localRef = useRef(null)
  const gridRef = useMergeRefs([localRef, ref].filter(Boolean))

  const rowHeightMap = useRef([])
  const gridSizeMap = useRef({ height: undefined, width: undefined })
  const { isMobile } = React.useContext(DeviceObserver.Context)
  const renderedItems = useRef({})

  useOnChange({
    value: columnCount,
    onChange(value) {
      rowHeightMap.current = []
      gridSizeMap.current = { height: undefined, width: undefined }

      gridRef.current && gridRef.current.resetAfterColumIndex(0)
    },
  })

  const refreshList = useCallback(
    _.throttle(() => {
      const index = (renderedItems.current || {}).overscanRowStartIndex

      const ref = gridRef.current
      ref && ref.resetAfterRowIndex(index > 0 ? index - 1 : 0)
    }, 1000),
    []
  )

  const saveCellSize = useCallback(
    ({ height, rowIndex, columnIndex }) => {
      if (!rowHeightMap.current[rowIndex]) rowHeightMap.current[rowIndex] = []

      const sizeHeight = rowHeightMap.current

      if (sizeHeight[rowIndex][columnIndex] !== height && height) {
        rowHeightMap.current[rowIndex][columnIndex] = height

        refreshList()
      }
    },
    [refreshList]
  )

  const getRowHeight = useCallback(
    (rowIndex) => {
      const defaultHeight = defaultRowHeight || 300
      if ((rowHeightMap.current[rowIndex] || []).length !== columnCount)
        return defaultHeight

      const max = Math.max(...(rowHeightMap.current[rowIndex] || []))

      const height = max === -Infinity ? defaultHeight : max

      return height
    },
    [columnCount, defaultRowHeight]
  )

  const onResizeGrid = useCallback(({ width, height }) => {
    const currentSize = gridSizeMap.current

    const heightChanged = currentSize.height !== height
    const widthChanged = currentSize.width !== width

    if (!gridRef.current) {
    } else if (heightChanged && widthChanged) {
      gridRef.current.resetAfterIndices({ columnIndex: 0, rowIndex: 0 })
    } else if (heightChanged) {
      gridRef.current.resetAfterRowIndex(0)
    } else if (widthChanged) {
      gridRef.current.resetAfterColumnIndex(0)
    }

    gridSizeMap.current = { width, height }
  }, [])

  const Child = useCallback(
    function Child({ style, rowIndex, columnIndex, data }) {
      const index = rowIndex * columnCount + columnIndex

      return (
        <div style={style} className={clsx(invertedScroll && c.scaleYMinusOne)}>
          <SizeObserver
            onResize={saveCellSize}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
          >
            {children({
              rowIndex,
              columnIndex,
              index,
              data,
              onChangeHeight() {
                gridRef.current && gridRef.current.resetAfterRowIndex(index)
              },
            })}
          </SizeObserver>
        </div>
      )
    },
    [children, saveCellSize, columnCount]
  )

  const onItemsRendered = useCallback(
    function onItemsRendered(...args) {
      renderedItems.current = args[0]

      if (typeof propsOnItemsRendered !== "function") return
      propsOnItemsRendered(...args)
    },
    [propsOnItemsRendered]
  )
  const c = useStyles({})

  const outerRef = useRef(null)
  const isMounted = useIsMounted()

  useEffect(() => {
    if (!isMounted || !invertedScroll) return
    if (!outerRef.current) return
    invertMouseWheelScroll(outerRef.current)
  }, [isMounted])

  return (
    <AutoSizer
      onResize={onResizeGrid}
      key={columnCount}
      className={clsx("virtual-grid", className)}
    >
      {({ height, width }) => {
        const props = {
          columnCount,
          columnWidth: () => {
            const w = (width - (isMobile ? 0 : 16)) / columnCount
            return w
          },

          ref: gridRef,
          height,
          rowCount: Math.ceil(count / columnCount),
          rowHeight: propsGetRowHeight || getRowHeight,
          width,
          itemData,
          overscanRowCount,
          onItemsRendered,
          className: clsx(invertedScroll && c.scaleYMinusOne),
        }

        return (
          <VariableSizeGrid {...props} itemKey={itemKey} outerRef={outerRef}>
            {Child}
          </VariableSizeGrid>
        )
      }}
    </AutoSizer>
  )
}

function SizeObserver({
  onResize: onResizeProps,
  children,
  rowIndex,
  columnIndex,
}) {
  const onResize = useCallback(
    ({ width, height }) => {
      onResizeProps({ width, height, rowIndex, columnIndex })
    },
    [onResizeProps]
  )

  const { ref } = useResizeObserver({ onResize })
  return <div ref={ref}>{children}</div>
}

export default React.forwardRef(VirtualizedGrid)

const useStyles = makeStyles((theme) => {
  return {
    scaleYMinusOne: {
      transform: "scaleY(-1)",
    },
  }
})
