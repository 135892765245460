import { ID } from "types/type"
import { useInputController } from "hooks/useInputController"
import useMemoAPI from "hooks/useMemoAPI"
import useOnChange from "hooks/useOnChange"
import React from "react"
import useGetCard from "./useGetCard"
import useTrackActiveKanbanCard from "./useTrackActiveKanbanCard"

export type KanbanSelectionAPI = ReturnType<typeof useSelectionAPI>

export default function useSelectionAPI(props: {
  selectedCardID?: ID
  onChangeSelectedCardID?: (cardID: ID | null) => void
  getCard: ReturnType<typeof useGetCard>
  trackActiveCard: ReturnType<typeof useTrackActiveKanbanCard>
}) {
  const { inputValue: selectedCardID, setInputValue: setSelectedCardID } =
    useInputController<typeof props.selectedCardID | null>({
      value: props.selectedCardID || null,
      onChange: props.onChangeSelectedCardID,
    })

  const selectedCard = React.useMemo(() => {
    if (!selectedCardID) return null
    return props.getCard(selectedCardID)
  }, [props.getCard, selectedCardID])

  React.useEffect(() => {
    setSelectedCardID(props.selectedCardID || null)
  }, [props.selectedCardID])

  React.useEffect(() => {
    props.trackActiveCard?.call(null, selectedCardID || null)
    // return () => {
    //   props.trackActiveCard?.call(null, null)
    // }
  }, [selectedCardID, props.trackActiveCard])

  return useMemoAPI({ selectedCard, setSelectedCardID })
}
