import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { pluckFirst, useObservable } from "observable-hooks"
import useOnChange from "./useOnChange"

export default function useAppIsVisible() {
  const [appIsVisible, setAppIsVisible] = React.useState<boolean>(
    document.visibilityState === "visible"
  )

  const appIsVisible$ = useObservable(pluckFirst, [appIsVisible])

  React.useEffect(() => {
    const fn = () => {
      setAppIsVisible(document.visibilityState === "visible")
    }

    document.addEventListener("visibilitychange", fn)

    return () => {
      document.removeEventListener("visibilitychange", fn)
    }
  }, [])

  return useMemoAPI({ appIsVisible, appIsVisible$ })
}
