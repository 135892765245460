import {
  Button as BasicButton,
  ButtonProps,
  makeStyles,
} from "@material-ui/core"
import clsx from "clsx"

export function Button(props: ButtonProps) {
  const c = useStyles()

  return (
    <BasicButton
      variant="contained"
      size="small"
      color="primary"
      {...props}
      className={clsx("modal-button", c.root, props.className)}
    >
      {props.children}
    </BasicButton>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      // padding: "1px 7px",
      fontSize: "0.7125rem",
      boxShadow: "none",
    },
  }
})
