import isTouchDevice from "lib/isTouchDevice"
import React from "react"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import useMemoAPI from "hooks/useMemoAPI"

export type ApiProps = {
  isSmall: boolean
  wrapChips?: boolean
  children: React.ReactNode
}
const Factory = contextAndProviderFactory({
  hookApi: function useDateAndTypesFilterApi(props: ApiProps) {
    const isActive = !props.wrapChips
    return useMemoAPI({ ...props, isActive })
  },
})

export const DateAndTypesFilterApiContext = Factory.Context
export const DateAndTypesFilterApiProvider = Factory.Provider
