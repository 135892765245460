import { ID } from "types/type"
import { RoomContext } from "components/Room/contexts/room"
import { ToggleableMenuItem } from "components/ToggleableMenu/ToggleableMenu"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useDeleteRoom from "hooks/useDeleteRoom/useDeleteRoom"
import DeleteRoomModal from "../DeleteRoomModal/DeleteRoomModal"

export default function useDeleteRoomOption(): ToggleableMenuItem | null {
  const api = React.useContext(RoomContext)
  const { deleteRoom } = useDeleteRoom()
  const [visible, setVisible] = React.useState<boolean>(false)

  const t = useI18n()
  const { workspace: w } = useCurrentWorkspace()

  const deleteOption = useMemoAPI({
    component: (
      <>
        {t["delete-room"]}
        <div onClick={(e) => e.stopPropagation()}>
          <DeleteRoomModal
            visible={visible}
            onClose={() => setVisible(false)}
            deleteRoom={() => {
              if (!api.room) return

              deleteRoom({ id: api.room.id })

              api.deleteRoom$.next()
            }}
          ></DeleteRoomModal>
        </div>
      </>
    ),
    onClick: () => {
      setVisible(true)
    },
  })

  if (!api.room?.isReadOnly) return null
  if (api.room?.owner?.id !== w?.user?.id) return null

  return deleteOption
}
