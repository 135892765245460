import { LexicalEditorAPIInput } from "./useLexicalEditorAPI"
import React from "react"
import { InitialConfigType } from "@lexical/react/LexicalComposer"
import useLexicalTheme from "./useLexicalTheme"
import $setEditorValueDealingWithMarkdown from "../lib/setEditorValueDealingWithMarkdown"
import nodes from "../lib/nodes"

export function getInitialEditorState(props: {
  value: LexicalEditorAPIInput["value"]
  markdownMode: LexicalEditorAPIInput["markdownMode"]
}) {
  const fn: InitialConfigType["editorState"] = (editor) => {
    if (props.value) {
      return $setEditorValueDealingWithMarkdown({
        value: props.value,
        editor,
        markdownMode: props.markdownMode,
      })
    }
  }

  return fn
}

export default function useInitialConfig(props: {
  value?: LexicalEditorAPIInput["value"]
  editable: LexicalEditorAPIInput["editable"]
  namespace: LexicalEditorAPIInput["namespace"]
  markdownMode: LexicalEditorAPIInput["markdownMode"]
}) {
  const initialEditorState = React.useMemo(() => {
    return getInitialEditorState({
      value: props.value,
      markdownMode: props.markdownMode,
    })
  }, [props.value, props.markdownMode])

  const lexicalTheme = useLexicalTheme()

  const initialConfig = React.useMemo(() => {
    const namespace = props.namespace

    const config: InitialConfigType = {
      namespace,
      editable: props.editable,
      theme: lexicalTheme,
      editorState: initialEditorState,
      nodes,
      onError(error, editor) {
        console.log({ namespace, error, editor })
      },
    }

    return config
  }, [props.editable, initialEditorState, lexicalTheme, props.namespace])

  return initialConfig
}
