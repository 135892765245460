import { UserFragment } from "lib/graphql/operations"
import { makeStyles } from "@material-ui/core"
import UserAvatar from "components/UserAvatar/UserAvatar"

export default function ActivityUserAvatar(props: { user: UserFragment }) {
  const c = useStyles()

  return (
    <UserAvatar
      user={props.user}
      AvatarProps={{ className: c.avatar }}
      StatusDotProps={{ className: c.statusDot }}
    ></UserAvatar>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    avatar: { width: 30, height: 30, fontSize: 15 },
    statusDot: { width: 8, height: 8 },
  }
})
