import _ from "lodash"
import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import getStatusObject from "pages/Start/util/getStatusObject"
import React, { useCallback } from "react"
import getter from "util/getter"
import formatPrice from "lib/formatPrice"
import RootContainer from "components/RootContainer/RootContainer"

export default function LargeContent({
  activity: selectedActivity,
  renderTitle: propsRenderTitle,
  className,
  EntryStatus,
}) {
  const c = useStyles()
  const a = useCallback(getter(selectedActivity), [selectedActivity])

  if (!a("id")) return null

  return (
    <RootContainer.Default className={clsx("large-content", c.root, className)}>
      <div className={clsx("left", c.left)}>
        <div>
          {(function renderTitle() {
            const defaultComponent = (
              <Typography className="title" variant="h6">
                <RootContainer.ClippedText>
                  {a("title")}
                </RootContainer.ClippedText>
              </Typography>
            )

            if (typeof propsRenderTitle === "function")
              return propsRenderTitle({ defaultComponent })

            return defaultComponent
          })()}
          {/* <ActivityStatus
            activity={selectedActivity}
            showColorDot={false}
            showColorExplanation={true}
          ></ActivityStatus> */}

          <EntryStatus
            entry={selectedActivity}
            showColorDot={false}
            showColorExplanation={true}
          ></EntryStatus>
        </div>
      </div>
      <div className="right">
        {(function renderTotalAmount() {
          const totalAmount = (() => {
            if (a("totalAmount")) {
              return formatPrice(a("totalAmount"), "€")
            }
            return null
          })()

          if (!totalAmount) return null

          return (
            <Typography className="total-amount" variant="body2">
              {totalAmount}
            </Typography>
          )
        })()}
        {/* {leftStatus !== a("workStatus") &&
          (function renderWorkStatus() {
            if (!a("workStatus")) return null

            return (
              <Typography className="work-status" variant="body2">
                {a("workStatus")}
              </Typography>
            )
          })()} */}
      </div>
    </RootContainer.Default>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "& .right": {
        textAlign: "right",
      },
    },

    left: {
      display: "flex",
      alignItems: "center",
    },
  }
})
