import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { MessagesListHandle } from "../components/MessagesList/MessagesList"
import { RoomContext } from "components/Room/contexts/room"
import useMessagesDataAPI from "./useMessagesDataAPI"

export default function useScrolling(props: {
  messageListRef?: React.RefObject<MessagesListHandle | null>
  messagesData: ReturnType<typeof useMessagesDataAPI>
}) {
  const { messageList } = React.useContext(RoomContext)

  const [isScrolling, setIsScrolling] = React.useState<boolean>(false)
  const [highlightedIndex, setHighlightedIndex] = React.useState<number | null>(
    null
  )

  const id = React.useRef<NodeJS.Timeout | undefined>(undefined)

  const jumpToItem = React.useCallback(
    (p: { index: number; highlight?: boolean }) => {
      if (id.current) clearTimeout(id.current)

      props.messageListRef?.current?.scrollToItem({ index: p.index })
      setHighlightedIndex(() => p.index)

      id.current = setTimeout(() => {
        setHighlightedIndex(null)
      }, 6000)
    },
    [props.messageListRef]
  )

  const scrollToItem = React.useCallback(
    (p: { index: number }) => {
      const number = p.index

      const jump = () =>
        Promise.resolve(jumpToItem({ index: number, highlight: true }))

      if (!props.messagesData.getMessage(number)) {
        messageList
          .fetchMore({
            startIndex: number - 15,
            stopIndex: number + 15,
          })
          ?.finally(() => jump())
      }

      jump()
    },
    [jumpToItem, props.messagesData]
  )

  return useMemoAPI({
    isScrolling,
    setIsScrolling,
    scrollToItem,
    highlightedIndex,
  })
}
