import {
  makeStyles,
  IconButton as MuiIconButton,
  Icon,
} from "@material-ui/core"
import clsx from "clsx"
// import clsx from "clsx"
// import ChatContext from "components/Chat/contexts/ChatContext"
// import ChatOverlayContext from "components/ChatOverlay/contexts/ChatOverlayContext"
import React, { useState } from "react"

export default function IconButton({ className = "", iconName = "", onClick }) {
  const c = useStyles({})

  return (
    <MuiIconButton
      size="small"
      className={clsx("overlay-control", className)}
      onClick={onClick}
    >
      <Icon className={c.icon} fontSize="small">
        {iconName}
      </Icon>
    </MuiIconButton>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      color: "white",
    },
  }
})
