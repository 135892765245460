import { I18nAPI } from "contexts/i18nContext/i18nContext"
import renderTimeLog from "./renderTimeLog"
import { FieldsetIndentationProps } from "../../../../Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { UserFragment } from "lib/graphql/operations"

export default function getTimelogFieldProps(props: {
  translations: I18nAPI
  updated?: {
    timestamp?: moment.MomentInput | null
    person?: UserFragment | null
  }
  stack?: boolean
  created?: {
    timestamp?: moment.MomentInput | null
    person?: UserFragment | null
  }
}) {
  const { translations } = props

  const indentationProps: FieldsetIndentationProps | null = (() => {
    const created = renderTimeLog({
      label: translations.created,
      dateTime: props.created?.timestamp,
      person: props.created?.person,
    })

    const updated = renderTimeLog({
      label: translations.updated,
      dateTime: props.updated?.timestamp,
      person: props.updated?.person,
    })

    if (!updated && !created) return null

    const isRow = !props.stack

    const a: FieldsetIndentationProps = {
      icon: "history",
      children: (
        <div
          style={{
            display: "flex",
            flexDirection: isRow ? "row" : "column",
            ...(isRow ? { justifyContent: "flex-start" } : {}),
          }}
        >
          {created}
          {updated}
        </div>
      ),
    }

    return a
  })()

  return indentationProps
}
