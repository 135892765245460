import _ from "lodash"
import React from "react"

import SelectInput from "./components/SelectInput/SelectInput"
import Checkboxes from "./components/Checkboxes/Checkboxes"
import ActiveSwitch from "./components/ActiveSwitch/ActiveSwitch"
import Checkbox from "./components/Checkbox/Checkbox"
import TextInput from "./components/TextInput/TextInput"
import WebInput from "./components/WebInput/WebInput"
import RadioGroup from "./components/RadioGroup/RadioGroup"
import Switch from "./components/Switch/Switch"
import Autocomplete from "./components/OldAutocomplete/Autocomplete"
import CopyToClipboardInput from "./components/CopyToClipboardInput/CopyToClipboardInput"
import CopyAccessKeyToClipboardInput from "./components/CopyAccessKeyToClipboardInput/CopyAccessKeyToClipboardInput"
import RichEditor from "./components/RichEditor/RichEditor"
import KeyBoardDateInput from "./components/KeyBoardDateInput/KeyBoardDateInput"
import PartnerAutocomplete from "./components/PartnerAutocomplete/PartnerAutocomplete"
import TextInputWithReset from "./components/TextInputWithReset/TextInputWithReset"
import EuropeanMoneyInput from "./components/EuropeanMoneyInput/EuropeanMoneyInput"
import DocumentTypesAutocomplete from "./components/DocumentTypesAutocomplete/DocumentTypesAutocomplete"
// import TextInputWithReset from "./components/TextInputWithReset/TextInputWithReset"
import SelectUsersInput from "./components/SelectUsersInput/SelectUsersInput"

export { default as TextInputWithReset } from "./components/TextInputWithReset/TextInputWithReset"
export { default as SelectButton } from "./components/SelectButton/SelectButton"

const Input = {
  "text-with-reset": TextInputWithReset,
  text: TextInput,
  date: KeyBoardDateInput,
  "new-partner": "Neuen Partner",
  "rich-editor": RichEditor,
  checkboxes: Checkboxes,
  select: SelectInput,
  "copy-to-clipboard": CopyToClipboardInput,
  "copy-access-key-to-clipboard": CopyAccessKeyToClipboardInput,
  checkbox: Checkbox,
  web: WebInput,
  "radio-group": RadioGroup,
  "active-switch": ActiveSwitch,
  switch: Switch,
  autocomplete: Autocomplete,
  "partner-autocomplete": PartnerAutocomplete,
  euro: EuropeanMoneyInput,
  "document-types-autocomplete": DocumentTypesAutocomplete,
  textarea: function TextArea(props) {
    return <TextInput {...props} multiline></TextInput>
  },
  "select-users": SelectUsersInput.Default,
}

export default Input
