import moment from "moment"
import { Month, WeekDay } from "../types"

export default function getWeekGrid(props: {
  firstDayOfWeek: WeekDay
  month: Month
}) {
  const firstDay = moment()
    .startOf("month")
    .startOf("day")
    .month(props.month.index)
    .year(props.month.year)

  const firstDayOfGrid = (() => {
    const count = firstDay.day() - props.firstDayOfWeek
    return firstDay.clone().subtract(count < 0 ? count + 7 : count, "days")
  })()

  const lastDay = firstDay.endOf("month").startOf("day")

  const lastDayOfGrid = (() => {
    const count = 7 + props.firstDayOfWeek - lastDay.day()
    const r = lastDay.clone().add(count, "days")
    return r
  })()

  const daysOfGrid = Array.from(
    {
      length: lastDayOfGrid.clone().diff(firstDayOfGrid, "days"),
    },
    (__, index) => firstDayOfGrid.clone().add(index, "days")
  )

  const weekGrid = daysOfGrid.reduce((acc, dayMoment, index) => {
    const weekIndex = Math.floor(index / 7)
    acc[weekIndex] = [...(acc[weekIndex] || []), dayMoment]
    return acc
  }, [] as Array<Array<moment.Moment>>)

  return weekGrid
}
