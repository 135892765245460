import _ from "lodash"
import path from "path"
import { ROOM_BASE_URL } from "../constants"

import { ID } from "types/type"

export default async function getRoomLink(props: { roomID: ID; userID: ID }) {
  let roomID = props.roomID

  return path.join(ROOM_BASE_URL, roomID + "")
}

export async function getPrivateRoomLink(props: { userID: ID }) {}
