import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import RootContainerContext from "components/RootContainer/contexts/RootContainerContext"
import { useContext } from "react"
import BasicClippedText from "components/ClippedText/ClippedText"

export default function ClippedText(props: {
  maxPercentage?: string
  className?: string
  children: React.ReactChild
  maxWidth?: number
  maxPercentageMap?: { small?: string; medium?: string; large?: string } //small, medium and large should indicate percentage
}) {
  const { className = "", children, maxWidth = undefined } = props

  const {
    width: containerWidth,
    isLarge,
    isMedium,
  } = useContext(RootContainerContext)

  return (
    <BasicClippedText
      containerWidth={containerWidth}
      maxWidth={maxWidth}
      maxPercentage={(function maxPercentage() {
        const defaultPercentage = props.maxPercentage || "50%"

        if (!!props.maxPercentageMap) {
          return (
            [
              isLarge && props.maxPercentageMap?.large,
              isMedium && props.maxPercentageMap?.medium,
              props.maxPercentageMap?.small,
            ].find(Boolean) || defaultPercentage
          )
        }

        return defaultPercentage
      })()}
      className={className}
    >
      {children}
    </BasicClippedText>
  )
}
