import React from "react"
import useDocumentFormAPI from "../hooks/useDocumentFormAPI"

export type DocumentFormAPI = ReturnType<typeof useDocumentFormAPI>

const DocumentFormContext = React.createContext(
  {} as ReturnType<typeof useDocumentFormAPI>
)

DocumentFormContext.displayName = "DocumentFormContext"

export default DocumentFormContext
