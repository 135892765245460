import clsx from "clsx"
import {
  BottomModal,
  BottomModalProps,
} from "components/BottomModal/BottomModal"
import React from "react"
import { StyledProps } from "types/type"

export function BottomModalFrame(
  props: Omit<
    BottomModalProps,
    "className" | "style" | "rootStylePosition" | "children"
  > &
    StyledProps & {
      classes?: { bottomModal?: string }
      styles?: { bottomModal?: React.CSSProperties }
      children: React.ReactNode
      modalContent: React.ReactNode
    }
) {
  return (
    <BottomModal
      {...props}
      className={props?.classes?.bottomModal}
      style={props.styles?.bottomModal}
      renderFrame={(p) => {
        return (
          <div
            className={clsx("bottom-modal-frame", props.classes?.bottomModal)}
            style={{ ...props.style, position: "relative" }}
          >
            {props.children}
            {p.absolutePositionedContent}
          </div>
        )
      }}
    >
      {props.modalContent}
    </BottomModal>
  )
}
