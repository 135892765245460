import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import Hook from "components/Hook/Hook"
import StopPropagation from "components/StopPropagation/StopPropagation"
import useTrueFalse from "hooks/useTrueFalse"
import isTouchDevice from "lib/isTouchDevice"
import { uniqueId } from "lodash"
import React from "react"
import { CellThumbnail } from "./CellThumbnail"
import { ImagePlaceholder } from "./ImagePlaceholder"
import { ItemGridCellContext } from "./ItemGridCellContext"
import { ItemIconButton } from "./ItemIconButton"
import { TypeIconWrapper } from "./TypeIconWrapper"

export function Header() {
  const api = React.useContext(ItemGridCellContext)
  const [uploadedThumbnail, setUploadedThumbnail] = React.useState<string>("")

  const c = useStyles()

  const img = (() => {
    const defaultComponent = (
      <TypeIconWrapper>
        {(() => {
          const src = uploadedThumbnail || api.imageThumbnailUrl

          const defaultUrl = "/static-images/placeholder.png"

          const placeholder = (
            <ImagePlaceholder src={defaultUrl} lightOpacity></ImagePlaceholder>
          )

          if (src)
            return (
              <CellThumbnail.Provider src={src} placeholder={placeholder}>
                <CellThumbnail.Root />
              </CellThumbnail.Provider>
            )

          return placeholder
        })()}
      </TypeIconWrapper>
    )

    return defaultComponent
  })()

  const allowThumb = api.entry.allowThumbnailUpload

  const input = React.useRef<HTMLInputElement>(null)

  const [inputKey, setInputKey] = React.useState(uniqueId())

  const containerRef = React.useRef<HTMLDivElement>(null)

  return (
    <div className={clsx("image-header", c.root)} ref={containerRef}>
      <div className={c.contentWrapper}>
        {/* <Hover isEnabled={!!allowThumb}> */}
        <Hover isEnabled={false}>
          {(hoverAPI) => {
            const icon = allowThumb && (
              <StopPropagation>
                <input
                  type="file"
                  key={inputKey}
                  onChange={(e) => {
                    const file = e.target.files?.[0]

                    if (!file || !containerRef.current) return

                    const a = containerRef.current

                    api.onNewItemThumbnail?.({
                      file,
                      containerAspect: a.clientWidth / a.clientHeight,
                    })

                    setUploadedThumbnail(URL.createObjectURL(file))
                    //unmounting and remounting the input element to clear the input value
                    //if this step is skipped, the same file cannot be uploaded twice
                    setInputKey(uniqueId())
                  }}
                  style={{ display: "none" }}
                  ref={input}
                  multiple={false}
                  accept="image/*"
                />

                <ItemIconButton
                  className={c.itemIconButton}
                  icon="add_a_photo"
                  onClick={() => {
                    input.current?.click()
                  }}
                ></ItemIconButton>
              </StopPropagation>
            )
            return (
              <>
                <div className={c.imageWrapper}>{img}</div>
                {hoverAPI.hoverIsEnabled ? hoverAPI.isHovered && icon : icon}
              </>
            )
          }}
        </Hover>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    icon: {},
    itemIconButton: {
      position: "absolute",
      top: 4,
      right: 8,
      opacity: 0.3,
    },
    imageWrapper: {
      height: "100%",
      overflow: "hidden",
    },
    contentWrapper: {
      position: "relative",
      height: "100%",
    },
    root: {
      "&>*": {
        // position: "relative",
        // height: "100%",
        // overflow: ''
      },

      // "&>settings": {
      //   position: "absolute",
      //   top: 4,
      //   right: 4,
      // },

      // "&>*": {
      //   height: "100%",
      // },
    },
  }
})

function Hover(props: {
  children: (props: {
    isHovered: boolean
    hoverIsEnabled: boolean
  }) => React.ReactNode
  isEnabled?: boolean | null
  className?: string
}) {
  if (isTouchDevice || !props.isEnabled)
    return <>{props.children({ isHovered: false, hoverIsEnabled: false })}</>

  return (
    <Hook hook={useTrueFalse} hookProps={undefined}>
      {(api) => {
        return (
          <div
            className={"hover"}
            onMouseEnter={() => api.setTrue()}
            onMouseLeave={() => {
              api.setFalse()
            }}
          >
            {props.children({ isHovered: api.isTrue, hoverIsEnabled: true })}
          </div>
        )
      }}
    </Hook>
  )
}
