import React from "react"
import ActionMetaCard from "components/ActionMetaCard/ActionMetaCard"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function DateMetaCards({
  by,
  when,
  type = null,
  onlyTime = false,
  avatar = undefined,
  goToPrivateRoom = undefined,
  renderAuthorName = undefined,
}) {
  const t = useI18n()

  const overline = {
    created: t["created"],
    updated: t["updated"],
  }

  return (
    <ActionMetaCard
      actionAuthor={by || {}}
      actionDate={when}
      renderAuthorName={renderAuthorName}
      overline={overline[type] || null}
      avatar={avatar}
      onlyTime={onlyTime}
      goToPrivateRoom={goToPrivateRoom}
    ></ActionMetaCard>
  )
}
