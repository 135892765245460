import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import { Icon } from "components/Icon/Icon"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"

const OPEN_IMAGE_DIALOG = "open-image-dialog"
const OPEN_DOCUMENT_DIALOG = "open-document-dialog"

export function useControls({
  openImageDialog,
  openDocumentDialog: openDocumentDialog,
}: {
  openImageDialog: () => void
  openDocumentDialog?: { isAllowed: boolean; callback: () => void }
}) {
  const { isMobile } = React.useContext(DeviceObserver.Context)

  const openImageDialogOption = useMemoAPI({
    name: OPEN_IMAGE_DIALOG,
    icon: <Icon name="image"></Icon>,
    type: "callback",
    onClick: openImageDialog,
    enabled: true,
  })

  const openDocumentDialogOption = React.useMemo(() => {
    return {
      name: OPEN_DOCUMENT_DIALOG,
      icon: <Icon name="description"></Icon>,
      type: "callback",
      onClick: openDocumentDialog?.callback,
      enabled: !!openDocumentDialog?.isAllowed,
    }
  }, [])

  // const options = React.useMemo(() => [openImageDialogOption], [openImageDialogOption, openDocumentDialogOption])
  const options = useMemoAPI(
    [openImageDialogOption, openDocumentDialogOption].filter(
      (option) => option.enabled
    )
  )

  return React.useMemo(
    () => ({
      controls: [
        ...(!isMobile ? RICH_TEXT_CONTROLS : []),
        ...options.map((o) => o.name),
      ],
      customControls: options,
    }),
    [isMobile, options]
  )
}

const RICH_TEXT_CONTROLS = [
  "title",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "highlight",
  "undo",
  "redo",
  "numberList",
  "bulletList",
]
