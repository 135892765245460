import ConnectionStatus from "components/ConnectionStatus/ConnectionStatus"
import { Subject, merge } from "rxjs"
import React from "react"
import { usePingQuery } from "lib/graphql/operations"

export default function useConnectionStatusController(props: {
  httpNetworkError$: Subject<any>
  httpData$: Subject<any>
  wsConnected$: Subject<any>
  wsReconnected$: Subject<any>
  wsDisconnected$: Subject<any>
  isConnectionAlive: () => Promise<boolean>
}) {
  const ctx = React.useContext(ConnectionStatus.Context)

  // const pingQuery = usePingQuery()

  React.useEffect(() => {
    console.log({ isOnline: ctx.isOnline })
  }, [ctx.isOnline])

  const onConnected = React.useCallback(() => {
    if (ctx.isOnline) return
    ctx.setOnline()
  }, [ctx.isOnline, ctx.setOnline])

  const onDisconnected = React.useCallback(async () => {
    if (!ctx.isOnline) return

    const connectionIsBroken = !(await props.isConnectionAlive())

    connectionIsBroken && ctx.setOffline()
    // }, [ctx.isOnline, ctx.setOffline, pingQuery.refetch])
  }, [ctx.isOnline, ctx.setOffline, props.isConnectionAlive])

  React.useEffect(() => {
    const obs = [props.httpNetworkError$, props.wsDisconnected$]
    const sub = merge(...obs).subscribe(onDisconnected)

    return () => {
      sub.unsubscribe()
    }
  }, [onDisconnected])

  React.useEffect(() => {
    const obs = [props.httpData$, props.wsConnected$, props.wsReconnected$]
    const sub = merge(...obs).subscribe(onConnected)

    return () => {
      sub.unsubscribe()
    }
  }, [onConnected])
}

// const ping = (props: { url: string; timeout?: number }) => {
//   const timeout = typeof props.timeout === "undefined" ? 3000 : props.timeout

//   return new Promise((resolve) => {
//     const isOnline = () => resolve(true)
//     const isOffline = () => resolve(false)

//     const xhr = new XMLHttpRequest()

//     xhr.onerror = isOffline
//     xhr.ontimeout = isOffline
//     xhr.onreadystatechange = () => {
//       if (xhr.readyState === xhr.HEADERS_RECEIVED) {
//         if (xhr.status) {
//           isOnline()
//         } else {
//           isOffline()
//         }
//       }
//     }

//     xhr.open("HEAD", props.url)
//     xhr.timeout = timeout
//     xhr.send()
//   })
// }
