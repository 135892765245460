import _ from "lodash"
import useAppRouter from "./useAppRouter"
import { matchPath } from "react-router"
import { useContext, useMemo } from "react"
import getAccountRootPathname from "util/getAccountRootPathname"
import { ID } from "types/type"
import Workspace from "components/Workspace/Workspace"

export default function useActiveResources() {
  const { location } = useAppRouter()

  const { workspaceID } = useContext(Workspace.Context)

  let value: { workspaceID: ID; accountID?: ID; guestUserID?: ID }

  if (workspaceID) {
    value = { workspaceID }
  } else {
    const getParams = paramsGetter(location.pathname)

    let { accountID, guestUserID } = (getParams(
      getAccountRootPathname({
        accountID: ":accountID",
        guestUserID: ":guestUserID",
      })
    ) ||
      getParams(getAccountRootPathname({ accountID: ":accountID" })) ||
      {}) as { accountID?: ID; guestUserID?: ID }

    accountID = accountID === "undefined" ? undefined : accountID
    guestUserID = guestUserID === "undefined" ? undefined : guestUserID

    value = {
      accountID,
      guestUserID,

      //TODO: move this logic to backend
      workspaceID: (guestUserID && accountID
        ? `account.${accountID}.guest.${guestUserID}`
        : accountID
        ? `account.${accountID}`
        : undefined) as ID,
    }
  }

  return useMemo(() => value, [...Object.values(value)])
}

function paramsGetter(pathname: string) {
  return (path: string) => {
    const { params } = matchPath(pathname, { path }) || {}
    return params
  }
}
