import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { CalendarEventInput } from "lib/graphql/types"
import useActiveResources from "hooks/useActiveResources"
import { useUpdateCalendarEventMutation } from "lib/graphql/operations"
import { UpdateCalendarEventMutationVariables } from "lib/graphql/operations"

export default function useUpdateCalendarEvent(props: { eventID: string }) {
  const r = useActiveResources()
  const [mutate, { loading }] = useUpdateCalendarEventMutation()

  const updateCalendarEvent = React.useCallback(
    (event: CalendarEventInput) => {
      const variables: UpdateCalendarEventMutationVariables = {
        workspaceID: r.workspaceID,
        event,
        eventID: props.eventID,
      }

      if (!Object.values(variables).every((a) => !!a)) return

      return mutate({ variables })
    },
    [r.workspaceID, props.eventID]
  )

  return useMemoAPI({
    updateCalendarEvent: updateCalendarEvent,
    isLoading: loading,
  })
}
