// import IconButton from "components/IconButton/IconButton"
import { DateAndTypesFilterRoot } from "./DateAndTypesFilterRoot"
import { DateAndTypesFilterProvider } from "./DateAndTypesFilterProvider"
import { DateAndTypesFilterApiContext } from "./DateAndTypesFilterApiContext"

export const DateAndTypesFilter = {
  Context: DateAndTypesFilterApiContext,
  Provider: DateAndTypesFilterProvider,
  Root: DateAndTypesFilterRoot,
}
