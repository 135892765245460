import { makeStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import clsx from "clsx"
import getTimelogFieldProps from "components/OldDocumentForm/components/lib/renderTimeLog/getTimelogFieldProps"
import { PageForm } from "components/Page/Page"
import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import getPartnerFieldProps from "components/Page/components/PageForm/lib/getPartnerFieldProps"
import { FormCommons } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/FormCommons/FormCommons"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import { StyledProps } from "types/type"
import { useGroupInTwoInLargeDevices } from "../../../../../../../../hooks/useGroupInTwoInLargeDevices"
import { excludeNullable } from "../../../../../../../../lib/excludeNullable"
import { Nullable } from "../../../../../../../../types/type"
import { ContactFormContext } from "./components/ProviderAndContext/ProviderAndContext"
import { getBusinessFieldProps } from "./lib/getBusinessDataFieldProps"
import { getPersonalDataFieldProps } from "./lib/getPersonalDataFieldProps"
import getRichTextFieldProps from "./lib/getRichTextFieldProps"
import { getSettingsFieldProps } from "./lib/getSettingsFieldProps"

type Fieldset = {
  personal: Nullable<FieldsetIndentationProps>
  business: Nullable<FieldsetIndentationProps>
  richText: Nullable<FieldsetIndentationProps>
  partner: Nullable<FieldsetIndentationProps>
  settings: Nullable<FieldsetIndentationProps>
  timeLog: Nullable<FieldsetIndentationProps>
  lastDocuments: Nullable<FieldsetIndentationProps>
}

export function ContactFormRoot(
  props: {
    lastDocuments?: React.ReactNode
    fieldsetProps?: (args: {
      isStacked: boolean
      fieldset: Fieldset
    }) => Nullable<FieldsetIndentationProps>[]
  } & StyledProps
) {
  const translations = useI18n()
  const c = useStyles()

  const api = React.useContext(ContactFormContext)

  const {
    contact,
    twoPagesAPI,
    summarized,
    openPartnerPage,
    onClickOpenButton,
  } = api

  const richTextFieldProps = useRichTextFieldProps()

  const indentationProps = (args: { isStacked: boolean }) => {
    const personal = getPersonalDataFieldProps({ translations })
    const business = getBusinessFieldProps({ translations })
    const richText = !summarized && richTextFieldProps

    const partner =
      !summarized &&
      twoPagesAPI &&
      getPartnerFieldProps({
        translations,
        PartnerTextFieldProps: {
          layout: twoPagesAPI,
          partner: contact?.partner,
          PartnerFormProps: {
            onClickOpenButton: openPartnerPage,
          },
        },
      })

    const settings = !summarized && getSettingsFieldProps({ translations })

    const timeLog =
      !summarized &&
      getTimelogFieldProps({
        translations,
        stack: args.isStacked,
        updated: {
          timestamp: contact?.updated,
          person: contact?.updatedBy,
        },
        created: {
          timestamp: contact?.created,
          person: contact?.createdBy,
        },
      })

    const lastDocuments = (() => {
      const args:
        | Parameters<typeof FormCommons.getLastDocumentsFieldProps>[0]
        | null = (() => {
        if (props.lastDocuments) return { lastDocuments: props.lastDocuments }

        return null
      })()

      if (!args) return null

      return FormCommons.getLastDocumentsFieldProps(args)
    })()

    if (props.fieldsetProps) {
      const fieldset: Fieldset = {
        personal,
        business,
        richText,
        partner,
        settings,
        timeLog,
        lastDocuments,
      }

      return props
        .fieldsetProps({ isStacked: args.isStacked, fieldset })
        .filter(excludeNullable)
    }

    return [
      personal,
      business,
      richText,
      partner,
      settings,
      timeLog,
      lastDocuments,
    ].filter(excludeNullable)
  }

  const { grouped, containerRef } = useGroupInTwoInLargeDevices({
    items: indentationProps,
  })

  const t = useI18n()

  return (
    <div
      ref={containerRef}
      style={props.style}
      className={clsx("contact-form", props.className)}
    >
      <PageForm fieldsIndentationsProps={grouped}></PageForm>
      {summarized && (
        <div className={clsx(c.openItem)}>
          <Button
            size="large"
            onClick={onClickOpenButton}
            variant="outlined"
            color="primary"
            disableElevation
          >
            {t.open}
          </Button>
        </div>
      )}
    </div>
  )
}
export function useRichTextFieldProps() {
  const translations = useI18n()

  const c = useRichTextFieldPropsStyles()

  return getRichTextFieldProps({
    // api,
    translations,
    indentationProps: { classes: { rightWrapper: c.container } },
  })
}

const useRichTextFieldPropsStyles = makeStyles((theme) => {
  return {
    container: {
      overflow: "hidden",
    },
  }
})

const useStyles = makeStyles((theme) => {
  return {
    openItem: {
      // paddingTop:
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "center",

      "& button": {
        width: "100%",
      },
    },
    root: {
      display: "flex",
      flexDirection: "column",
      "&>*:first-child": {
        flex: 1,
        overflow: "auto",
      },
    },
  }
})
