import { Chip, IconButton, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import PaperListItem from "components/PaperList/components/PaperListItem/PaperListItem"
import { Icon } from "components/Icon/Icon"
import ClippedText from "components/ClippedText/ClippedText"
import TwoPages from "components/TwoPages/TwoPages"
import StopPropagation from "components/StopPropagation/StopPropagation"
import useTrueFalse from "hooks/useTrueFalse"
import useOnChange from "hooks/useOnChange"
import KeyListeners from "components/KeyListeners/KeyListeners"
import TextField from "components/TextField/TextField"
import { Variant } from "@material-ui/core/styles/createTypography"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"

export type ListItemProps = {
  name: string
  onSelect: () => any
  isSelected: boolean
  allowEdit?: boolean
  saveEditChanges?: (editedText: string) => any
}

export default function ListItem(props: ListItemProps) {
  const rootContainer = React.useContext(ItemTemplate.Context).rootContainer
  const c = useListItemStyles()

  const twoPagesAPI = React.useContext(TwoPages.Context)

  const { name, onSelect, isSelected } = props
  const [editedText, setEditedText] = React.useState<string>(props.name)

  const isEditing = useTrueFalse()

  KeyListeners.useEscapeListener(isEditing.setFalse, {
    enabled: isEditing.isTrue,
  })

  useOnChange({
    value: isEditing.isTrue,
    disabled: !props.allowEdit,
    onChange: (isEditing) => {
      if (isEditing) return
      if (editedText.trim() === props.name.trim()) return
      props.saveEditChanges?.(editedText)
    },
  })

  // React.useEffect(() => {
  //   return () => {
  //     if (isEditing) return
  //     if (editedText.trim() === props.name.trim()) return
  //     props.saveEditChanges?.(editedText)
  //   }
  // }, [])

  useOnChange({
    value: props.name,
    disabled: !props.allowEdit,
    onChange: (propsName) => {
      setEditedText(propsName)
    },
  })

  const renderCheckIcon = (args?: { style?: React.CSSProperties }) => {
    return (
      <Icon
        name="check"
        style={{
          color: "green",
          visibility: isSelected ? "visible" : "hidden",
          ...(args?.style || {}),
        }}
      ></Icon>
    )
  }
  return (
    <>
      <PaperListItem
        style={{ alignItems: "center" }}
        classes={{ listItemRight: c.right }}
        onClick={() => {
          if (isEditing.isTrue) return
          onSelect()
          twoPagesAPI.secondPage.closePage()
        }}
        left={
          <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
            {props.allowEdit &&
              renderCheckIcon({
                style: {
                  position: "relative",
                  marginRight: 8,
                  left: -4,
                },
              })}
            {(() => {
              const variant: Variant = "body1"

              if (isEditing.isFalse)
                return (
                  <Typography
                    variant={variant}
                    style={{
                      height: 32,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ClippedText maxWidth={0.6 * rootContainer.width}>
                      {name}
                    </ClippedText>
                  </Typography>
                )

              return (
                <>
                  <TextField
                    typographyVariant={variant}
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                    onBlur={(e) => e.preventDefault()}
                    style={{ flex: 1, opacity: 1 }}
                    inputProps={{
                      ref: (input: HTMLInputElement) => input?.focus(),
                    }}
                  ></TextField>
                </>
              )
            })()}
          </div>
        }
        right={
          <div style={{ display: "flex", alignItems: "center" }}>
            {!props.allowEdit && renderCheckIcon()}

            {props.allowEdit && (
              <StopPropagation>
                <IconButton
                  size="small"
                  style={{ marginRight: 4 }}
                  onClick={isEditing.toggle}
                >
                  <Icon
                    name={isEditing.isTrue ? "edit_off" : "edit"}
                    style={{ fontSize: 19, opacity: 0.4 }}
                  ></Icon>
                </IconButton>
              </StopPropagation>
            )}
          </div>
        }
      ></PaperListItem>
    </>
  )
}
export const useListItemStyles = makeStyles((theme) => {
  return {
    right: {
      flex: "none",
    },
  }
})

function UseOnUnmount(props: {
  callback: () => any
  children?: React.ReactNode
}) {
  React.useEffect(() => {
    return props.callback
  }, [])

  return <>{props.children || null}</>
}
