import { ChipProps, makeStyles } from "@material-ui/core"
import { InlinedChips } from "components/InlinedChips/InlinedChips"
import { KanbanCommentFragment } from "lib/graphql/operations"
import React from "react"
import KanbanCardPageBoardItemContext from "../../../../../contexts/KanbanCardPageBoardItemContext"
import { useReactionsChips } from "../../../../../hooks/useReactionsChips"
import {
  ActionsChipsContext,
  ActionsChipsProvider,
} from "./ActionsChipsContext"

export function ActionsChips(props: { comment: KanbanCommentFragment }) {
  if (!props.comment) return null

  return (
    <ActionsChipsProvider comment={props.comment}>
      <ActionChipsRoot></ActionChipsRoot>
    </ActionsChipsProvider>
  )
}

function ActionChipsRoot() {
  const reactionsChips = useReactionsChips({
    emojiPosition: "right",
  })

  const api = React.useContext(ActionsChipsContext)
  const editionChip = useEditionChip()
  const deleteChip = useDeleteChip()

  const c = useStyles()

  return (
    <InlinedChips
      chips={[
        ...(() => {
          if (!api.allowCommentManipulation) return []
          return [editionChip, deleteChip]
        })(),
        ...reactionsChips,
      ]}
      classes={{
        chip: c.chip,
      }}
    ></InlinedChips>
  )
}

function useEditionChip() {
  const api = React.useContext(ActionsChipsContext)

  const b: ChipProps = {
    ...api.getBasicChipProps({ iconName: "edit" }),
    onClick: () => {
      return api.editionApi.openEdition()
    },
  }

  return b
}

const useStyles = makeStyles((theme) => {
  return {
    chip: {
      marginBottom: "0px !important",
      marginRight: theme.spacing(0.5) + " !important",
    },
  }
})

function useDeleteChip() {
  const cardApi = React.useContext(KanbanCardPageBoardItemContext)
  const api = React.useContext(ActionsChipsContext)

  const b: ChipProps = {
    ...api.getBasicChipProps({ iconName: "delete" }),
    onClick: () => {
      cardApi.comments.removeModal.open({
        comment: api.comment,
      })
    },
  }

  return b
}
