import { ObjectWithID } from "types/type"
import { useContext, useEffect } from "react"
import { RoomContext } from "components/Room/contexts/room"
import useDocument from "hooks/useDocument/useDocument"
import { DocumentFragment } from "lib/graphql/operations"

const cached: { [key: string]: true } = {}

export default function RoomDocument(props: {
  cacheKey?: string
  children: (args: {
    document?: DocumentFragment | null
    loading: boolean
  }) => React.ReactNode
}) {
  const { room } = useContext(RoomContext)
  const identifier = room?.identifier
  const documentID = identifier?.identifierID

  const variables = { documentID }

  const cacheKey = props.cacheKey + JSON.stringify(variables) || ""

  useEffect(() => {
    cached[cacheKey] = true
  }, [cacheKey])

  const fetchPolicy = cached[cacheKey] ? "cache-first" : "cache-and-network"

  const { document, isLoading } = useDocument({
    ...variables,
    fetchPolicy,
  })

  return props.children({ document, loading: isLoading })
}
