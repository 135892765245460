import PageBoardContext from "components/PageBoard/contexts/PageBoardContext"
import useActiveResources from "hooks/useActiveResources"
import {
  NewPageBoardItem_KanbanCardCommentPageBoardItem_Fragment,
  NewPageBoardItem_KanbanCardPageBoardItem_Fragment,
  useKanbanCardPageBoardItemQuery,
} from "lib/graphql/operations"
import React from "react"
import KanbanCardPageBoardItem from "../KanbanCardPageBoardItem/KanbanCardPageBoardItem"

export default function KanbanCardCommentPageBoardItem(props: {
  entry: NewPageBoardItem_KanbanCardCommentPageBoardItem_Fragment
  onClose: () => any
}) {
  const cardID = props.entry.cardSummary?.cardID || ""
  const workspaceID = useActiveResources().workspaceID

  // const api = React.useContext(PageBoardContext)

  const item = useKanbanCardPageBoardItemQuery({
    variables: { cardID, workspaceID },
    fetchPolicy: "network-only",
  })?.data?.kanbanCardPageBoardItem

  // React.useEffect(() => {
  //   if (!kanbanCardItem) return

  //   api.selected.openItemPage({
  //     item: kanbanCardItem,
  //     openPageParams: {
  //       commentID: props.entry.comment?.id || "",
  //     },
  //   })
  // }, [!!kanbanCardItem])

  if (!item) return null

  return (
    <KanbanCardPageBoardItem.Default
      entry={item as NewPageBoardItem_KanbanCardPageBoardItem_Fragment}
      // initialParams={pageBoardApi.selected.openedItemPageParams || undefined}
      TemplatePageProps={{ onClose: props.onClose }}
      initialParams={{ commentID: props.entry.comment?.id || "" }}
    ></KanbanCardPageBoardItem.Default>
  )
}
