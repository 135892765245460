import { BadgeProps, TypographyProps } from "@material-ui/core"
import { AppCardProps } from "components/AppCard/AppCard"
import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import React from "react"
import { StyledProps } from "types/type"
import { ItemIconButton } from "./ItemIconButton"

export type NewThumbnailImage = {
  containerAspect: number
  file: File
}

export type ItemGridCellProps = {
  entry: NewPageBoardItemFragment
  title: React.ReactNode
  renderDescription?: (props: {
    defaultComponent: React.ReactNode
  }) => React.ReactNode
  descriptionText?:
    | React.ReactNode
    | { component: React.ReactNode; numberOfLines: number }
  buttons?: React.ComponentProps<typeof ItemIconButton>[]
  typeIconBackgroundColor?: string
  imageThumbnailUrl?: string
  timestamp?: string
  onNewItemThumbnail?: (props: NewThumbnailImage) => void
  renderIcon?: (props: { defaultComponent: React.ReactNode }) => React.ReactNode
  IconBadgeProps?: Partial<BadgeProps>
  renderTitle?: (props: {
    defaultComponent: React.ReactNode
    typographyProps: TypographyProps
  }) => React.ReactNode
  AppCardProps?: AppCardProps
} & StyledProps

const F = contextAndProviderFactory({
  hookApi: function useItemGridCell(props: ItemGridCellProps) {
    return useMemoAPI(props)
  },
})
export const ItemGridCellProvider = F.Provider
export const ItemGridCellContext = F.Context
