import { Typography, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import moment from "moment"
import { StyledProps } from "types/type"

export function WeekHeader(props: StyledProps) {
  const c = useStyles()

  return (
    <div className={clsx(c.root, props.className)} style={props.style}>
      {Array.from({ length: 7 }, (_, index) => (
        <Typography
          key={index}
          color="textSecondary"
          variant="body1"
          className={c.weekName}
        >
          {moment()
            .day(index + 1)
            .format("dd")}
        </Typography>
      ))}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    // paddingTop: 0,
    // paddingBottom: 8,
  },

  weekName: { flex: 1, textAlign: "center" },
}))
