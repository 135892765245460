import React from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import clsx from "clsx"
import Switch from "../Switch/Switch"

export default function ActiveSwitch({ value, className, ...other }) {
  const t = useI18n()

  return (
    <Switch
      value={value}
      className={clsx("active-switch", className)}
      TooltipProps={({ value }) => ({
        placement: "bottom",
        title: value ? t.active : t.inactive,
        className: "active-switch-tooltip"
      })}
      {...other}
    ></Switch>
  )
}
