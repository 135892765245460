import { TwoPagesAPI } from "components/TwoPages/TwoPages"
import useMemoAPI from "hooks/useMemoAPI"
import { UserPageBoardItemAPIProps } from "../types"

export default function useRoomPageBoardItemAPI(
  props: UserPageBoardItemAPIProps & {
    twoPagesAPI: TwoPagesAPI
  }
) {
  return useMemoAPI({ ...props })
}
