import useMemoAPI from "hooks/useMemoAPI"
import { InitialConfigType } from "@lexical/react/LexicalComposer"
import useTypographyClass from "hooks/useTypographyClass"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"

export default function useLexicalTheme() {
  const tc = useTypographyClass()

  const c = useStyles()

  const theme: InitialConfigType["theme"] = {
    paragraph: clsx(tc["body1"], c.marginZero),
    text: {
      underline: clsx(c.underline, c.marginZero),
      strikethrough: clsx(c.strikethrough, c.marginZero),
    },
    link: c.link,
    heading: {
      h1: clsx(tc["h5"], c.marginZero),
      h2: clsx(tc["h6"], c.marginZero),
      h3: clsx(tc["subtitle1"], c.marginZero),
      h4: clsx(tc["subtitle2"], c.marginZero),
      h5: clsx(tc["button"], c.marginZero),
      h6: clsx(tc["overline"], c.marginZero),
    },
    list: {
      ul: c.list,
      ol: c.list,
    },
  }

  return useMemoAPI(theme)
}
const useStyles = makeStyles((theme) => {
  return {
    link: {
      textDecoration: "underline",
      color: theme.palette.primary.main,
    },
    underline: {
      textDecoration: "underline",
    },

    strikethrough: {
      textDecoration: "line-through",
    },

    list: {
      marginLeft: theme.spacing(4),
    },

    marginZero: {
      margin: 0,
    },
  }
})
