import useCountries from "hooks/useCountries"
import { useMemo } from "react"

export default function useCountriesField({ name = "country" }) {
  const { countries } = useCountries()

  return useMemo(
    () => ({
      name,
      label: "Land",
      type: "select",
      options: (countries || []).map(c => ({ value: c.value, label: c.locale }))
    }),
    [JSON.stringify(countries)]
  )
}
