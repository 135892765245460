import useMemoAPI from "hooks/useMemoAPI"
import useStateController from "hooks/useStateController"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import { PageBoardItemParams } from "lib/graphql/types"
import React from "react"
import { InitialParams } from "../components/PageBoardItem/PageBoardItem"
import { ItemParams, PageItemParams, Selected } from "../types"

export type SelectedAPI = ReturnType<typeof useSelectedAPI>

export type SelectedApiInput<Type extends PageBoardItemParams["type"]> = {
  params?: PageItemParams<Type> | null
  onChangeParams?: (props: ItemParams | null | undefined) => any
}

export function useSelectedAPI<Type extends PageBoardItemParams["type"]>(
  props: SelectedApiInput<Type>
) {
  const [params, setParams] = useStateController({
    value: props.params || null,
    onChange: props.onChangeParams,
  })

  const setSelected = React.useCallback<(args: Selected | null) => any>(
    (args) => {
      const v =
        args?.itemID && args.type
          ? { itemID: args.itemID, type: args.type }
          : null

      setParams(v)
    },
    [setParams]
  )

  const [openedItemPageParams, setOpenItemPageParams] = React.useState<
    InitialParams[keyof InitialParams] | null
  >(null)

  const setSelectedNull = React.useCallback(() => {
    setParams(null)
    setOpenItemPageParams(null)
  }, [setParams, setOpenItemPageParams])

  const openItemPage = React.useCallback(
    async function openItemPage<Item extends NewPageBoardItemFragment>(args: {
      item: Item
      openPageParams?: InitialParams[Extract<Item["type"], keyof InitialParams>]
    }) {
      if ("item" in args) {
        setSelected(args.item)
        setOpenItemPageParams(args.openPageParams || null)
        return null
      }
    },
    [setSelected, setOpenItemPageParams]
  )

  const selectedAPI = useMemoAPI({
    set: setSelected,
    setParams,
    setNull: setSelectedNull,
    openItemPage,
    openedItemPageParams,
    params,
  })

  return selectedAPI
}
