import { makeStyles } from "@material-ui/core"
import { Icon } from "components/Icon/Icon"
import { useI18n } from "contexts/i18nContext/i18nContext"
import {
  Message_ZoomSessionRegistrationMessage_Fragment,
  MessageFragment,
  RepliedMessageFragment,
} from "lib/graphql/operations"
import { BasicMessagePreview } from "./BasicMessagePreview"

export function ZoomSessionPreview(props: {
  message: MessageFragment | RepliedMessageFragment
}) {
  const t = useI18n()

  const c = useStyles()

  if (
    (props.message as Message_ZoomSessionRegistrationMessage_Fragment)
      .__typename !== "ZoomSessionRegistrationMessage"
  )
    return null

  const description = (
    <div className={c.line}>
      <Icon name="video_call"></Icon> <span>{t.conference.conference}</span>
    </div>
  )

  return <BasicMessagePreview {...props} text={description} />
}

const useStyles = makeStyles((theme) => {
  return {
    line: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.primary,
      "&>*:first-child": {
        marginRight: theme.spacing(0.5),
      },
    },
  }
})
