import _ from "lodash"
import _f from "lodash/fp"
import { useRoomQuery } from "lib/graphql/operations"
import useQueryWasFetched from "hooks/useQueryWasFetched/useQueryWasFetched"
import useRoomMessageList from "hooks/useRoomMessageList/useRoomMessageList"
import isRoomInexistent from "util/isRoomInexistent"
import useMemoAPI from "hooks/useMemoAPI"
import { pluckFirst, useObservable } from "observable-hooks"
import { filter, mapTo } from "rxjs/operators"
import useRoomMembers from "hooks/useRoomMembers"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import useRootContainerAPI from "../useRootContainerAPI"

export default function useRoom(props: {
  roomID: string
  workspaceID: string
}) {
  const { roomID, workspaceID } = props

  const messageList = useRoomMessageList({
    roomID,
    workspaceID,
  })

  const variables = { roomID, workspaceID }

  const roomQuery = useRoomQuery({
    fetchPolicy: "cache-and-network",
    variables,
    skip: !(variables.roomID && variables.workspaceID),
  })

  const { user: workspaceUser } = useCurrentWorkspace()

  const room = roomQuery?.data?.room || roomQuery?.previousData?.room

  const allowMembersEdition = (() => {
    const workspaceUserID = workspaceUser?.id
    const ownerID = room?.owner?.id

    return workspaceUserID === ownerID
  })()

  const { roomMembers, searchMembersAPI, peerMembers } = useRoomMembers({
    roomID,
    fetchAllowedMembers: allowMembersEdition,
  })

  const roomWasFetched = useQueryWasFetched({
    networkStatus: roomQuery.networkStatus,
  })

  const room$ = useObservable(pluckFirst, [room])

  const roomWasFetched$ = useObservable(
    ($input) =>
      $input.pipe(
        filter(([fetched]) => !!fetched),
        mapTo(true)
      ),
    [roomWasFetched]
  )

  const api = useMemoAPI({
    isLoading: false,
    messagesTotalCount: messageList.data?.totalItems,
    messageList,
    room$,
    roomIsInexistent: isRoomInexistent(roomQuery),
    room,
    searchMembersAPI,
    allowMembersEdition,

    roomMembers,
    peerMembers,
    roomWasFetched$,
    roomWasFetched,
  })

  return api
}
