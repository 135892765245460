import { Typography, TextFieldProps } from "@material-ui/core"
import TextField from "../../../TextField/TextField"
import BasicAutoComplete from "components/Input/components/AutoComplete/AutoComplete"
import { AutoCompleteProps } from "components/Input/components/AutoComplete/AutoComplete"

export default function AutoComplete(
  props: AutoCompleteProps & { TextFieldProps?: TextFieldProps }
) {
  return (
    <BasicAutoComplete
      {...props}
      renderSuggestionsItem={
        props.renderSuggestionsItem ||
        ((a) => <Typography variant="caption">{a}</Typography>)
      }
      renderInput={(props) => {
        return <TextField {...props}></TextField>
      }}
    ></BasicAutoComplete>
  )
}
