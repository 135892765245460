import _ from "lodash"
import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/styles"
import { Box } from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import { TextField } from "@material-ui/core"
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons"

export default function ToggleableSearchField({
  searchIsActive = true,
  onSearchIconPressed,
  className = "",
  style,
  inputProps,
  onCloseIconPressed,
  render = undefined,
}) {
  const classes = useStyles()
  const [textField, setTextField] = React.useState(null)

  const focus = () => textField && textField.querySelector("input").focus()

  useEffect(() => {
    focus()
    !searchIsActive && setTextField(null)
  }, [textField, searchIsActive])

  const defaultComponent = (
    <React.Fragment>
      {searchIsActive && (
        <TextField
          className={classes.searchField}
          ref={setTextField}
          inputProps={{ ...inputProps }}
        />
      )}

      <Icon
        onClosePressed={onCloseIconPressed}
        onGlassPressed={() => {
          onSearchIconPressed()
          focus()
        }}
        searchIsActive={searchIsActive}
      />
    </React.Fragment>
  )

  return (
    <Box
      display="flex"
      alignItems="center"
      className={className}
      style={style || {}}
    >
      {typeof render === "function"
        ? render({ defaultComponent })
        : defaultComponent}
    </Box>
  )
}

function Icon({ onClosePressed, onGlassPressed, searchIsActive }) {
  const state = searchIsActive
    ? { icon: CloseIcon, action: onClosePressed }
    : { icon: SearchIcon, action: onGlassPressed }

  return (
    <IconButton edge="end" onClick={state.action}>
      {React.createElement(state.icon, { color: "textPrimary" })}
    </IconButton>
  )
}

const useStyles = makeStyles((theme) => ({
  searchField: {
    flexGrow: 1,
  },
}))
