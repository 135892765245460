import clsx from "clsx"
import React from "react"
import useTypographyClass from "hooks/useTypographyClass"
import {
  makeStyles,
  StandardTextFieldProps,
  TextFieldProps,
  TypographyVariant,
} from "@material-ui/core"
import { Variant } from "@material-ui/core/styles/createTypography"

export default function useTextFieldProps(
  props?: StandardTextFieldProps & { typographyVariant?: Variant }
) {
  const tClass = useTypographyClass()

  const { typographyVariant, ...TextFieldProps } = props || {}

  const c = useStyles()

  const textFieldProps = React.useMemo(() => {
    const o: TextFieldProps = {
      ...TextFieldProps,
      InputLabelProps: {
        shrink: true,
        ...(props?.InputLabelProps || {}),
      },
      style: {
        // opacity: 0.7,
        // width: "100%",
        ...(props?.style || {}),
      },
      className: clsx(c.root, props?.className),
      inputProps: {
        ...(props?.inputProps || {}),
        className: clsx(
          // tClass[props?.typographyVariant || "caption"],
          tClass[props?.typographyVariant || "body1"],
          props?.inputProps?.className
        ),
      },
    }

    return o
  }, [props, tClass.caption])

  return textFieldProps
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100% !important",
      "&>*:not(:last-child)": {
        marginBottom: theme.spacing(1),
      },
    },
  }
})
