import { ChipProps } from "@material-ui/core"
import { useChipsProps } from "./hooks/useChipsProps"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import useMemoAPI from "hooks/useMemoAPI"

const F = contextAndProviderFactory({
  hookApi: function useFilterSelector(props: { typesChipsProps: ChipProps[] }) {
    const chipsProps = useChipsProps({ typesChipsProps: props.typesChipsProps })
    const selectedChipsIDs = chipsProps
      .filter((a) => a.isSelected)
      .map((a) => a.id)
    return useMemoAPI({ chipsProps, selectedChipsIDs })
  },
})
export const FilterSelectorContext = F.Context
export const FilterSelectorProvider = F.Provider
