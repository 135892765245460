import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { Observer } from "components/Observer"
import React from "react"
import { Virtuoso, VirtuosoProps } from "react-virtuoso"
import { StyledProps } from "../../types/type"
import { Item } from "./Item"
import { ListContext } from "./ListContext"

export function ListRoot<Data, Context>(
  props: StyledProps & {
    noVirtualization?: boolean
    onClickItem?: (item: Data | null | undefined, index: number) => any
    itemContent?: VirtuosoProps<Data, Context>["itemContent"]
  }
) {
  const api = React.useContext(ListContext)

  const gridCellPadding = api.gridProps.numOfColumns === 1 ? 0 : 16

  const c = useStyles({
    noVirtualization: props.noVirtualization,
    gridCellPadding,
  })

  return (
    <div
      className={clsx("list-root", c.root, props.className)}
      ref={api.rootContainer.containerRef}
      style={props.style}
    >
      {(() => {
        const gridProps = api.gridProps

        const getIndex = (row: number, column: number) => {
          return gridProps.numOfColumns * row + column
        }

        let virtuosoProps: VirtuosoProps<(Data | null)[], Context> = {
          data: gridProps.lines,
          totalCount: gridProps.lines.length,
          computeItemKey: (row, group) => {
            const b = group
              .map(
                (a, column) =>
                  (typeof api.itemKey === "function" &&
                    api.itemKey?.(getIndex(row, column), a)) ||
                  getIndex(row, column)
              )
              .join("-")
            return b
          },
          itemContent: (row, entry, context) => {
            return (
              <Item index={row}>
                {() => {
                  return (
                    <div className={c.row}>
                      {entry.map((data, column) => {
                        const index = getIndex(row, column)

                        return (
                          <div
                            onClick={() => props.onClickItem?.(data, index)}
                            className={c.cell}
                            key={
                              (typeof api.itemKey === "function" &&
                                api.itemKey?.(getIndex(row, column), data)) ||
                              getIndex(row, column)
                            }
                            style={{
                              ...(() => {
                                if (row === 0)
                                  return { marginTop: -(gridCellPadding / 2) }
                                return {}
                              })(),
                              cursor: props.onClickItem ? "pointer" : "auto",
                            }}
                          >
                            {(() => {
                              if (!data) return null
                              const index = getIndex(row, column)

                              return props.itemContent?.(index, data, context)
                            })()}
                          </div>
                        )
                      })}
                    </div>
                  )
                }}
              </Item>
            )
          },
        }

        return (
          <>
            <Observer
              value={api.rootContainer.width}
              subject={gridProps.rootWidth$}
            ></Observer>
            <Virtuoso {...virtuosoProps}></Virtuoso>
          </>
        )
        // ctx
      })()}
    </div>
  )
}

type Style = {
  noVirtualization?: boolean
  gridCellPadding: number
}

const useStyles = makeStyles((theme) => {
  return {
    row: {
      display: "flex",
    },
    cell: {
      flex: 1,
      overflow: "hidden",
      padding: (p: Style) => p.gridCellPadding / 2,

      "&>*": {
        height: "100%",
      },
    },
    root: {
      "&>*": {
        overflow: ({ noVirtualization }: Style) =>
          noVirtualization ? "visible !important" : "",
      },
    },
  }
})
