import React from "react"
import { Variant as TypographyVariant } from "@material-ui/core/styles/createTypography"
import { makeStyles } from "@material-ui/core"
import SelectUsersInputAutocomplete from "./components/SelectUsersInputAutocomplete/SelectUsersInputAutocomplete"
import SelectedPeople from "./components/SelectedPeople/SelectedPeople"
import SelectUsersInputProvider from "./components/SelectUsersInputProvider/SelectUsersInputProvider"
import SelectUsersInputContext from "./contexts/SelectUsersInputContext"
import useSelectUsersInputAPI from "./hooks/useSelectUsersInputAPI"

const SelectUsersInput = {
  useAPI: useSelectUsersInputAPI,
  Context: SelectUsersInputContext,
  Provider: SelectUsersInputProvider,
  Default: SelectUsersInputDefault,
  AutoComplete: SelectUsersInputAutocomplete,
}

export default SelectUsersInput

function SelectUsersInputDefault(props: {
  render?: (props: {
    components: {
      selectWithAutoComplete: React.ReactNode
      peopleSelected: React.ReactNode
    }
    defaultComponent: React.ReactNode
  }) => React.ReactNode
}) {
  const c = useStyles()

  return (
    <div className="select-users-input">
      {(() => {
        const selectWithAutoComplete = (
          <SelectUsersInputAutocomplete></SelectUsersInputAutocomplete>
        )

        const peopleSelected = (
          <SelectedPeople className={c.companies}></SelectedPeople>
        )

        const defaultComponent = (
          <React.Fragment>
            {selectWithAutoComplete}
            {peopleSelected}
          </React.Fragment>
        )

        if (!props.render) return defaultComponent

        const components = {
          selectWithAutoComplete,
          peopleSelected,
        }

        return props.render({ components, defaultComponent })
      })()}
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    companies: { marginTop: theme.spacing(1) },
    input: {},
  }
})
