export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: any;
  /** JS RegExp represented as string */
  RegExp: any;
  /** A field whose value matches /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)|^https?:\/\/localhost:[0-9]{1,5}\/([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)|^(\/.+)+/. */
  Src: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Access = {
  __typename?: 'Access';
  key?: Maybe<Scalars['ID']>;
  visible?: Maybe<Scalars['String']>;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum ActivitiesMode {
  ByProcessingDate = 'BY_PROCESSING_DATE',
  ByUserDate = 'BY_USER_DATE',
  InvoicesPayable = 'INVOICES_PAYABLE'
}

export type ActivitiesSearchInput = {
  documentType?: InputMaybe<Scalars['String']>;
  invoiceFlow?: InputMaybe<InvoiceFlow>;
  jobStatus?: InputMaybe<Scalars['String']>;
  regExp?: InputMaybe<Scalars['RegExp']>;
  workStatus?: InputMaybe<Scalars['String']>;
};

export type Activity = {
  __typename?: 'Activity';
  collections?: Maybe<Array<CollectionItem>>;
  created?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  date?: Maybe<Scalars['Date']>;
  documentID?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  invoiceFlow?: Maybe<InvoiceFlow>;
  job?: Maybe<ActivityJob>;
  netValueGoods?: Maybe<Scalars['Float']>;
  paymentDate?: Maybe<Scalars['Date']>;
  postDate?: Maybe<Scalars['Date']>;
  text?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['URL']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalTax?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  uuid?: Maybe<Scalars['String']>;
  workStatus?: Maybe<Scalars['String']>;
};

export type ActivityJob = {
  __typename?: 'ActivityJob';
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  partner?: Maybe<Partner>;
  status?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export enum AppError {
  CanNotAddUser = 'CAN_NOT_ADD_USER',
  DuplicateCollection = 'DUPLICATE_COLLECTION',
  InvalidAuthToken = 'INVALID_AUTH_TOKEN',
  NothingFound = 'NOTHING_FOUND',
  NoRoomFound = 'NO_ROOM_FOUND',
  ShopOrderIdAlreadyUsed = 'SHOP_ORDER_ID_ALREADY_USED',
  UserNotFound = 'USER_NOT_FOUND',
  YouDontHaveModeratorRights = 'YOU_DONT_HAVE_MODERATOR_RIGHTS'
}

export type Article = {
  __typename?: 'Article';
  brand?: Maybe<Brand>;
  details?: Maybe<Array<ArticleDetails>>;
  ean?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['URL']>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  thumbnail?: Maybe<Scalars['URL']>;
};

export type ArticleDetails = {
  __typename?: 'ArticleDetails';
  category: Scalars['String'];
  html: Scalars['String'];
};

export type ArticlesGroup = {
  __typename?: 'ArticlesGroup';
  articles?: Maybe<Array<Article>>;
  name?: Maybe<Scalars['String']>;
};

export type Attachment = FileAttachment | ImageAttachment | NotFoundAttachment | PageBoardItemAttachment;

export type AuthPayload = {
  __typename?: 'AuthPayload';
  user?: Maybe<User>;
};

export enum AuthorizationType {
  Accesskey = 'ACCESSKEY',
  AccesskeyWithPassword = 'ACCESSKEY_WITH_PASSWORD',
  FullUser = 'FULL_USER',
  Guestaccount = 'GUESTACCOUNT',
  GuestaccountNeedPassword = 'GUESTACCOUNT_NEED_PASSWORD',
  Invitation = 'INVITATION'
}

export type BasicResponse = {
  __typename?: 'BasicResponse';
  success: Scalars['Boolean'];
};

export type Bilateral = {
  __typename?: 'Bilateral';
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};

export type Brand = {
  __typename?: 'Brand';
  imageSrc?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CalendarDocumentActivityEntry = CalendarEntry & {
  __typename?: 'CalendarDocumentActivityEntry';
  activity: Activity;
  description?: Maybe<Scalars['String']>;
  endDateTime: Scalars['DateTime'];
  id: Scalars['ID'];
  mode: ActivitiesMode;
  startDateTime: Scalars['DateTime'];
  title: Scalars['String'];
};

export type CalendarDocumentsOverviewsEntry = CalendarEntry & {
  __typename?: 'CalendarDocumentsOverviewsEntry';
  description?: Maybe<Scalars['String']>;
  endDateTime: Scalars['DateTime'];
  id: Scalars['ID'];
  mode: ActivitiesMode;
  numberOfDocs: Scalars['Int'];
  startDateTime: Scalars['DateTime'];
  title: Scalars['String'];
};

export type CalendarEntry = {
  description?: Maybe<Scalars['String']>;
  endDateTime: Scalars['DateTime'];
  id: Scalars['ID'];
  startDateTime: Scalars['DateTime'];
  title: Scalars['String'];
};

export type CalendarEvent = CalendarEntry & {
  __typename?: 'CalendarEvent';
  createdAt?: Maybe<Scalars['DateTime']>;
  creator: User;
  description?: Maybe<Scalars['String']>;
  endDateTime: Scalars['DateTime'];
  id: Scalars['ID'];
  members?: Maybe<Array<User>>;
  startDateTime: Scalars['DateTime'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CalendarEventInput = {
  description?: InputMaybe<Scalars['String']>;
  endDateTime: Scalars['DateTime'];
  members?: InputMaybe<Array<UserListUpdate>>;
  startDateTime: Scalars['DateTime'];
  title: Scalars['String'];
};

export type CalendarEventPageBoardItem = NewPageBoardItem & {
  __typename?: 'CalendarEventPageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  event?: Maybe<CalendarEvent>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['ID'];
  items?: Maybe<Array<CartItem>>;
  workspaceID: Scalars['ID'];
};

export type CartItem = {
  __typename?: 'CartItem';
  article: ShopArticle;
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type ChatWithAssistantEntry = {
  __typename?: 'ChatWithAssistantEntry';
  entity: ChatWithAssistantEntryType;
  id: Scalars['ID'];
  message: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export enum ChatWithAssistantEntryType {
  Ai = 'Ai',
  User = 'User'
}

export type CloseShopOrderResponse = {
  __typename?: 'CloseShopOrderResponse';
  documentID?: Maybe<Scalars['ID']>;
  order: ShopOrderPageBoardItem;
};

export type CollectionItem = {
  __typename?: 'CollectionItem';
  bilateralID?: Maybe<Scalars['ID']>;
  count?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  number?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  translatedTitle?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CollectionItemInput = {
  title: Scalars['String'];
  type: Scalars['String'];
};

export enum CollectionItemType {
  Contact = 'CONTACT',
  Customer = 'CUSTOMER',
  Document = 'DOCUMENT',
  Lead = 'LEAD',
  Vendor = 'VENDOR'
}

export type CollectionType = {
  __typename?: 'CollectionType';
  locale: Scalars['String'];
  value: Scalars['String'];
};

export enum CollectionTypeValue {
  Contact = 'CONTACT',
  Customer = 'CUSTOMER',
  Document = 'DOCUMENT',
  Lead = 'LEAD',
  Marketplace = 'MARKETPLACE',
  Vendor = 'VENDOR'
}

export type CollectionsOverview = {
  __typename?: 'CollectionsOverview';
  count: Scalars['Int'];
  hasPartnerAssociated?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type ColumnOrdering = {
  __typename?: 'ColumnOrdering';
  id: Scalars['ID'];
  orderedColumnsIDs?: Maybe<Array<Scalars['ID']>>;
  roomID: Scalars['ID'];
};

export type ConferenceUser = {
  __typename?: 'ConferenceUser';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  user: User;
};

export type ContactMember = {
  __typename?: 'ContactMember';
  collection?: Maybe<CollectionItem>;
  collectionID?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  user?: Maybe<User>;
};

export type ContactPageBoardItem = NewPageBoardItem & {
  __typename?: 'ContactPageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  contact?: Maybe<DetailedContact>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type ContactSummary = {
  __typename?: 'ContactSummary';
  active?: Maybe<Scalars['Boolean']>;
  collectionID?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isUser?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type CreateShopOrderResponse = {
  __typename?: 'CreateShopOrderResponse';
  cart?: Maybe<Cart>;
  order: ShopOrderPageBoardItem;
};

export type CreatedRoom = RoomUpdate & {
  __typename?: 'CreatedRoom';
  room: Room;
  roomID: Scalars['ID'];
};

export type Crop = {
  __typename?: 'Crop';
  format: CropFormat;
  height: Scalars['Float'];
  unit: CropUnit;
  width: Scalars['Float'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export enum CropFormat {
  Avatar = 'AVATAR',
  Thumbnail = 'THUMBNAIL'
}

export type CropInput = {
  format: CropFormat;
  height: Scalars['Float'];
  unit: CropUnit;
  width: Scalars['Float'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export enum CropUnit {
  Percent = 'PERCENT',
  Pixel = 'PIXEL'
}

export type CustomerPageBoardItem = NewPageBoardItem & {
  __typename?: 'CustomerPageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  partner?: Maybe<PartnerSummary>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type DecoupledKanbanBoard = {
  __typename?: 'DecoupledKanbanBoard';
  cards?: Maybe<Array<KanbanCard>>;
  columnOrdering?: Maybe<ColumnOrdering>;
  columns?: Maybe<Array<KanbanColumn>>;
  id: Scalars['ID'];
  roomID: Scalars['ID'];
};

export type DeletedRoom = RoomUpdate & {
  __typename?: 'DeletedRoom';
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};

export type DeprecatedKanbanColumnInput = {
  cards?: InputMaybe<Array<InputMaybe<KanbanCardInput>>>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type DetailedContact = Kontakt & {
  __typename?: 'DetailedContact';
  access?: Maybe<Access>;
  active?: Maybe<Scalars['Boolean']>;
  attachFile?: Maybe<Scalars['String']>;
  collection?: Maybe<CollectionItem>;
  collectionID?: Maybe<Scalars['ID']>;
  createColKey?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deleted?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<User>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isUser?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notifyAccessKey?: Maybe<Scalars['String']>;
  partner?: Maybe<Partner>;
  position?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  remindMe?: Maybe<Scalars['Boolean']>;
  salutation?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type DetailedContactInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  attachFile?: InputMaybe<Scalars['String']>;
  collectionID?: InputMaybe<Scalars['ID']>;
  createColKey?: InputMaybe<Scalars['Boolean']>;
  created?: InputMaybe<Scalars['DateTime']>;
  deleted?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isUser?: InputMaybe<Scalars['Boolean']>;
  landline?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  notifyAccessKey?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  remindMe?: InputMaybe<Scalars['Boolean']>;
  salutation?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type Document = {
  __typename?: 'Document';
  collections?: Maybe<Array<CollectionItem>>;
  created?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  date?: Maybe<Scalars['Date']>;
  discountFactor?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  isProcessing?: Maybe<Scalars['Boolean']>;
  netValueGoods?: Maybe<Scalars['Float']>;
  partnerCollection?: Maybe<CollectionItem>;
  partnerID?: Maybe<Scalars['ID']>;
  paymentDate?: Maybe<Scalars['Date']>;
  paymentDays?: Maybe<Scalars['Int']>;
  pdf?: Maybe<Scalars['URL']>;
  postDate?: Maybe<Scalars['Date']>;
  text?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['URL']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalTax?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  typesAvailable?: Maybe<Array<Scalars['String']>>;
  updated?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  user?: Maybe<User>;
  workStatus?: Maybe<Scalars['String']>;
};

export type DocumentCollection = {
  __typename?: 'DocumentCollection';
  count?: Maybe<Scalars['Int']>;
  group?: Maybe<DocumentGroup>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type DocumentGroup = {
  __typename?: 'DocumentGroup';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type DocumentList = {
  __typename?: 'DocumentList';
  id: Scalars['ID'];
  list?: Maybe<Array<DocumentListItem>>;
};

export type DocumentListItem = {
  __typename?: 'DocumentListItem';
  document: Document;
  index: Scalars['Int'];
  listID: Scalars['ID'];
};

export type DocumentPageBoardItem = NewPageBoardItem & {
  __typename?: 'DocumentPageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  document?: Maybe<DocumentSummary>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type DocumentSummary = {
  __typename?: 'DocumentSummary';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pdfUrl?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DocumentUpdate = {
  __typename?: 'DocumentUpdate';
  date?: Maybe<Scalars['Date']>;
  netValueGoods?: Maybe<Scalars['Float']>;
  paymentDate?: Maybe<Scalars['Date']>;
  postDate?: Maybe<Scalars['Date']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalTax?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type DocumentUpdateInput = {
  date?: InputMaybe<Scalars['Date']>;
  netValueGoods?: InputMaybe<Scalars['Float']>;
  paymentDate?: InputMaybe<Scalars['Date']>;
  postDate?: InputMaybe<Scalars['Date']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalAmount?: InputMaybe<Scalars['Float']>;
  totalTax?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};

export type EnvironmentVariablesInput = {
  appIsVisible?: InputMaybe<Scalars['Boolean']>;
  pageBoardItemParams?: InputMaybe<PageBoardItemParamsInput>;
  roomID?: InputMaybe<Scalars['ID']>;
  workspaceID?: InputMaybe<Scalars['ID']>;
};

export type ExcludedFromRoom = RoomUpdate & {
  __typename?: 'ExcludedFromRoom';
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};

export type File = FileInterface & {
  __typename?: 'File';
  filename: Scalars['String'];
  mimetype?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  src?: Maybe<Scalars['Src']>;
  thumbnail?: Maybe<Image>;
};

export type FileAttachment = {
  __typename?: 'FileAttachment';
  file: FileInterface;
  id: Scalars['ID'];
};

export type FileInterface = {
  filename: Scalars['String'];
  mimetype?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  src?: Maybe<Scalars['Src']>;
  thumbnail?: Maybe<Image>;
};

export type FilesEndpoints = {
  __typename?: 'FilesEndpoints';
  translations: TranslationsEndpoints;
};

export type GuestWorkspace = WorkspaceInterface & {
  __typename?: 'GuestWorkspace';
  account?: Maybe<Account>;
  id: Scalars['ID'];
  isOnDeveloperMode?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  notificationsCount?: Maybe<WorkspaceNotificationsCount>;
  partner?: Maybe<WorkspacePartner>;
  roomsIDs?: Maybe<Array<Scalars['ID']>>;
  settings?: Maybe<WorkspaceSettings>;
  shop?: Maybe<Shop>;
  type: WorkspaceType;
  unread?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type IceCandidate = {
  __typename?: 'ICECandidate';
  candidate: Scalars['String'];
  sdpMLineIndex: Scalars['Int'];
  sdpMid: Scalars['String'];
  sender: User;
  usernameFragment?: Maybe<Scalars['String']>;
};

export type IceCandidateInput = {
  candidate: Scalars['String'];
  sdpMLineIndex: Scalars['Int'];
  sdpMid: Scalars['String'];
  usernameFragment?: InputMaybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  aspect?: Maybe<Scalars['Float']>;
  crop?: Maybe<Crop>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['Src']>;
  url?: Maybe<Scalars['URL']>;
  width?: Maybe<Scalars['Float']>;
};

export type ImageAttachment = {
  __typename?: 'ImageAttachment';
  id: Scalars['ID'];
  image: Image;
};

export type ImageListInput = {
  add?: InputMaybe<Array<Scalars['Upload']>>;
  remove?: InputMaybe<Array<Scalars['ID']>>;
};

export enum InclusionType {
  Addition = 'ADDITION',
  Removal = 'REMOVAL'
}

export enum InvoiceFlow {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING'
}

export enum KanbanAction {
  AddCard = 'ADD_CARD',
  AddComment = 'ADD_COMMENT',
  AddImage = 'ADD_IMAGE',
  ChangeCardDescription = 'CHANGE_CARD_DESCRIPTION',
  ChangeCardTitle = 'CHANGE_CARD_TITLE',
  DeleteCard = 'DELETE_CARD',
  MoveCardBetweenColumns = 'MOVE_CARD_BETWEEN_COLUMNS',
  RemoveComment = 'REMOVE_COMMENT',
  RemoveImage = 'REMOVE_IMAGE'
}

export type KanbanActivity = {
  action: KanbanAction;
  id: Scalars['ID'];
  user: User;
  when: Scalars['DateTime'];
};

export type KanbanAddCardActivity = KanbanActivity & {
  __typename?: 'KanbanAddCardActivity';
  action: KanbanAction;
  id: Scalars['ID'];
  user: User;
  when: Scalars['DateTime'];
};

export type KanbanAddCommentActivity = KanbanActivity & {
  __typename?: 'KanbanAddCommentActivity';
  action: KanbanAction;
  comment?: Maybe<KanbanComment>;
  id: Scalars['ID'];
  user: User;
  when: Scalars['DateTime'];
};

export type KanbanAddImageActivity = KanbanActivity & {
  __typename?: 'KanbanAddImageActivity';
  action: KanbanAction;
  id: Scalars['ID'];
  image?: Maybe<KanbanImage>;
  user: User;
  when: Scalars['DateTime'];
};

export type KanbanBoard = {
  __typename?: 'KanbanBoard';
  columns?: Maybe<Array<KanbanColumn>>;
  id: Scalars['ID'];
  notificationsCount?: Maybe<Scalars['Int']>;
};

export type KanbanBoardInput = {
  columns?: InputMaybe<Array<InputMaybe<DeprecatedKanbanColumnInput>>>;
};

export type KanbanCard = {
  __typename?: 'KanbanCard';
  columnID?: Maybe<Scalars['ID']>;
  columnName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  roomID?: Maybe<Scalars['ID']>;
  roomName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type KanbanCardCommentPageBoardItem = NewPageBoardItem & {
  __typename?: 'KanbanCardCommentPageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  cardSummary?: Maybe<KanbanCardSummary>;
  comment?: Maybe<KanbanComment>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type KanbanCardCoverInput = {
  imageID?: InputMaybe<Scalars['ID']>;
  upload?: InputMaybe<Scalars['Upload']>;
};

export type KanbanCardImages = {
  __typename?: 'KanbanCardImages';
  cardID: Scalars['ID'];
  cover?: Maybe<KanbanImage>;
  list?: Maybe<Array<KanbanImage>>;
};

export type KanbanCardImagesInput = {
  cover?: InputMaybe<KanbanCardCoverInput>;
  list?: InputMaybe<ImageListInput>;
};

export type KanbanCardInput = {
  columnID?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type KanbanCardLogMessage = Message & {
  __typename?: 'KanbanCardLogMessage';
  actions?: Maybe<Array<MessageAction>>;
  activity?: Maybe<KanbanActivity>;
  author: User;
  card?: Maybe<KanbanCard>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  editedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  reactions?: Maybe<Array<Reaction>>;
  roomID: Scalars['ID'];
  sentAt: Scalars['DateTime'];
  workspaces?: Maybe<Array<Workspace>>;
};

export type KanbanCardPageBoardItem = NewPageBoardItem & {
  __typename?: 'KanbanCardPageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  card?: Maybe<KanbanCardSummary>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type KanbanCardSummary = {
  __typename?: 'KanbanCardSummary';
  cardID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  tags?: Maybe<Array<Tag>>;
  title?: Maybe<Scalars['String']>;
};

export type KanbanChangeCardDescriptionActivity = KanbanActivity & {
  __typename?: 'KanbanChangeCardDescriptionActivity';
  action: KanbanAction;
  cardDescription: Scalars['String'];
  id: Scalars['ID'];
  user: User;
  when: Scalars['DateTime'];
};

export type KanbanChangeCardTitleActivity = KanbanActivity & {
  __typename?: 'KanbanChangeCardTitleActivity';
  action: KanbanAction;
  cardTitle: Scalars['String'];
  id: Scalars['ID'];
  user: User;
  when: Scalars['DateTime'];
};

export type KanbanColumn = {
  __typename?: 'KanbanColumn';
  /** @deprecated No longer supported */
  cards?: Maybe<Array<KanbanCard>>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  orderedCardsIDs?: Maybe<Array<Scalars['ID']>>;
  title?: Maybe<Scalars['String']>;
};

export type KanbanColumnInput = {
  cards?: InputMaybe<Array<InputMaybe<KanbanCardInput>>>;
  title?: InputMaybe<Scalars['String']>;
};

export type KanbanColumnSummary = {
  __typename?: 'KanbanColumnSummary';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type KanbanComment = {
  __typename?: 'KanbanComment';
  author?: Maybe<User>;
  id?: Maybe<Scalars['ID']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  reactions: Array<KanbanCommentReaction>;
  sentAt?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type KanbanCommentInput = {
  add?: InputMaybe<KanbanTextCommentInput>;
  edit?: InputMaybe<KanbanEditCommentInput>;
  react?: InputMaybe<KanbanReactToCommentInput>;
  remove?: InputMaybe<KanbanRemoveCommentInput>;
};

export type KanbanCommentList = {
  __typename?: 'KanbanCommentList';
  comments: Array<KanbanComment>;
  id: Scalars['ID'];
};

export type KanbanCommentReaction = {
  __typename?: 'KanbanCommentReaction';
  /**
   * Hexadecimal codepoints joined by _ ('dash').
   * For example, "1f469_200d_1f9b0" represents 👩‍🦰 (woman with red hair)
   */
  emojiUnicode: Scalars['String'];
  user: User;
};

export type KanbanDeleteCardActivity = KanbanActivity & {
  __typename?: 'KanbanDeleteCardActivity';
  action: KanbanAction;
  deletedCardTitle: Scalars['String'];
  id: Scalars['ID'];
  user: User;
  when: Scalars['DateTime'];
};

export type KanbanEditCommentInput = {
  commentID: Scalars['ID'];
  text: Scalars['String'];
};

export type KanbanImage = {
  __typename?: 'KanbanImage';
  addedAt?: Maybe<Scalars['DateTime']>;
  filename: Scalars['String'];
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

export type KanbanImageInput = {
  filename?: InputMaybe<Scalars['String']>;
};

export type KanbanMoveCardBetweenColumnsActivity = KanbanActivity & {
  __typename?: 'KanbanMoveCardBetweenColumnsActivity';
  action: KanbanAction;
  fromColumn?: Maybe<KanbanColumnSummary>;
  fromRoom?: Maybe<KanbanRoom>;
  id: Scalars['ID'];
  toColumn?: Maybe<KanbanColumnSummary>;
  toRoom?: Maybe<KanbanRoom>;
  user: User;
  when: Scalars['DateTime'];
};

export type KanbanNewCardCommentUpdate = {
  __typename?: 'KanbanNewCardCommentUpdate';
  newComment: KanbanComment;
  updatedCommentList: KanbanCommentList;
};

export type KanbanReactToCommentInput = {
  commentID: Scalars['ID'];
  emojiUnicode: Scalars['String'];
  updateType: UpdateType;
};

export type KanbanRemoveCommentInput = {
  commentID: Scalars['ID'];
};

export type KanbanRemoveImageActivity = KanbanActivity & {
  __typename?: 'KanbanRemoveImageActivity';
  action: KanbanAction;
  id: Scalars['ID'];
  imageName?: Maybe<Scalars['String']>;
  user: User;
  when: Scalars['DateTime'];
};

export type KanbanRoom = {
  __typename?: 'KanbanRoom';
  id?: Maybe<Scalars['String']>;
  roomID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type KanbanTextCommentInput = {
  text: Scalars['String'];
};

export type KanbanUpdate = KanbanBoard | KanbanCard | KanbanCardImages | KanbanComment | KanbanNewCardCommentUpdate;

export type Kontakt = {
  created?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deleted?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<User>;
  id: Scalars['ID'];
  updated?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
};

export enum KontaktType {
  DetailedContact = 'DETAILED_CONTACT',
  Partner = 'PARTNER'
}

export type LeadPageBoardItem = NewPageBoardItem & {
  __typename?: 'LeadPageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  partner?: Maybe<PartnerSummary>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type Message = {
  actions?: Maybe<Array<MessageAction>>;
  author: User;
  deletedAt?: Maybe<Scalars['DateTime']>;
  editedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  reactions?: Maybe<Array<Reaction>>;
  roomID: Scalars['ID'];
  sentAt: Scalars['DateTime'];
};

export type MessageAction = {
  __typename?: 'MessageAction';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type MessageContentEdition = MessageUpdate & {
  __typename?: 'MessageContentEdition';
  content?: Maybe<Scalars['String']>;
  editedAt?: Maybe<Scalars['String']>;
  messageID: Scalars['ID'];
};

export type MessageDeletion = MessageUpdate & {
  __typename?: 'MessageDeletion';
  content?: Maybe<Scalars['String']>;
  deletedAt: Scalars['DateTime'];
  messageID: Scalars['ID'];
};

export type MessageInParagraphs = Message & {
  __typename?: 'MessageInParagraphs';
  actions?: Maybe<Array<MessageAction>>;
  author: User;
  deletedAt?: Maybe<Scalars['DateTime']>;
  editedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  paragraphs?: Maybe<Array<Paragraph>>;
  reactions?: Maybe<Array<Reaction>>;
  roomID: Scalars['ID'];
  sentAt: Scalars['DateTime'];
};

export type MessageListItem = {
  __typename?: 'MessageListItem';
  index: Scalars['Int'];
  message: Message;
  roomID: Scalars['ID'];
  sessionID?: Maybe<Scalars['ID']>;
};

export type MessagePageBoardItem = NewPageBoardItem & {
  __typename?: 'MessagePageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  message?: Maybe<MessageSummary>;
  messageFull?: Maybe<Message>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type MessageReactionsUpdate = MessageUpdate & {
  __typename?: 'MessageReactionsUpdate';
  messageID: Scalars['ID'];
  reactionsUpdates?: Maybe<Array<ReactionUpdate>>;
};

export type MessageSummary = {
  __typename?: 'MessageSummary';
  author: User;
  featuringImageSrc?: Maybe<Scalars['Src']>;
  id: Scalars['ID'];
  roomID: Scalars['ID'];
  roomName?: Maybe<Scalars['String']>;
  sentAt: Scalars['DateTime'];
  text?: Maybe<Scalars['String']>;
};

export type MessageUpdate = {
  messageID: Scalars['ID'];
};

export type MutatedBoard = {
  __typename?: 'MutatedBoard';
  columnOrdering?: Maybe<ColumnOrdering>;
  upsertedColumns?: Maybe<Array<KanbanColumn>>;
};

export type MutatedColumns = {
  __typename?: 'MutatedColumns';
  changedCard?: Maybe<KanbanCard>;
  upsertedColumns?: Maybe<Array<Maybe<KanbanColumn>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  activateRelatedRoom?: Maybe<RoomPageBoardItem>;
  activateWorkspace?: Maybe<BasicResponse>;
  activePrivateRoomPageBoardItem?: Maybe<RoomPageBoardItem>;
  addArticlesToPortfolioItem: PortfolioItem;
  addDetailedContact: DetailedContact;
  addKanbanCard?: Maybe<MutatedColumns>;
  addKanbanComment?: Maybe<KanbanNewCardCommentUpdate>;
  addKanbanImages?: Maybe<KanbanCardImages>;
  addOneUnitToCart: Cart;
  addPartner: Partner;
  addPortfolioItem: Portfolio;
  addReaction?: Maybe<Message>;
  addToRoom: BasicResponse;
  associateCollection?: Maybe<BasicResponse>;
  cancelWebRtcsdpOffer?: Maybe<Scalars['Boolean']>;
  changeCartItemQuantity: Cart;
  changeColumnIndexPosition?: Maybe<ColumnOrdering>;
  changeKanbanCard?: Maybe<KanbanCard>;
  changeKanbanCardColumn?: Maybe<MutatedColumns>;
  changeKanbanCardImages?: Maybe<KanbanCardImages>;
  changeKanbanColumnTitle?: Maybe<KanbanColumn>;
  changeMessageFavoriteStatus?: Maybe<Message>;
  changeRoomKanbanBoard?: Maybe<KanbanBoard>;
  changeRoomKanbanCard?: Maybe<KanbanCard>;
  changeRoomName?: Maybe<BasicResponse>;
  closeShopOrder?: Maybe<CloseShopOrderResponse>;
  createCalendarEvent?: Maybe<CalendarEvent>;
  createCollectionItem?: Maybe<CollectionItem>;
  createCollectionRoom?: Maybe<Room>;
  createDocumentRoom?: Maybe<Room>;
  createFreeRoom?: Maybe<Room>;
  createKanbanCard?: Maybe<KanbanCard>;
  createKanbanColumn?: Maybe<MutatedBoard>;
  createOneCardForEachReturnLineFromText?: Maybe<Array<KanbanCard>>;
  createPrivateRoom?: Maybe<Room>;
  createRoomPageBoardItem?: Maybe<RoomPageBoardItem>;
  createShopOrder: CreateShopOrderResponse;
  declineWebRtcsdpOffer?: Maybe<Scalars['Boolean']>;
  deleteCalendarEvent?: Maybe<BasicResponse>;
  deleteCollectionItem?: Maybe<BasicResponse>;
  deleteKanbanCard?: Maybe<MutatedColumns>;
  deleteKanbanColumn?: Maybe<MutatedBoard>;
  deleteMessage?: Maybe<Message>;
  deletePushNotificationSubscription: BasicResponse;
  deleteRoom?: Maybe<BasicResponse>;
  dissociateCollection?: Maybe<BasicResponse>;
  editKanbanComment?: Maybe<KanbanComment>;
  editMessage?: Maybe<Message>;
  kanbanCommentMutation?: Maybe<KanbanComment>;
  login: AuthPayload;
  loginWithAccessKey: AuthPayload;
  loginWithToken: AuthPayload;
  logout?: Maybe<BasicResponse>;
  makeRoomReadOnly?: Maybe<BasicResponse>;
  reactToKanbanComment?: Maybe<KanbanComment>;
  readWorkspaceNotifications?: Maybe<BasicResponse>;
  registerVisitationToPageBoardItem?: Maybe<PageBoardVisitationHistory>;
  removeArticlesFromPortfolioItem: PortfolioItem;
  removeFromCart: Cart;
  removeFromRoom: BasicResponse;
  removeKanbanCardCoverImage?: Maybe<KanbanCardImages>;
  removeKanbanComment?: Maybe<KanbanCommentList>;
  removeKanbanCommentReaction?: Maybe<KanbanComment>;
  removeKanbanImage?: Maybe<KanbanCardImages>;
  removePortfolioItem: Portfolio;
  selectArticleVariation?: Maybe<SelectVariationResponse>;
  sendMessage?: Maybe<RoomMessageListUpdate>;
  sendWebRTCICECandidate?: Maybe<Scalars['Boolean']>;
  sendWebRtcsdpAnswer?: Maybe<Scalars['Boolean']>;
  sendWebRtcsdpOffer?: Maybe<Scalars['Boolean']>;
  setKanbanCardCoverImage?: Maybe<KanbanCardImages>;
  sharePageBoardItemsInTheRoom?: Maybe<RoomMessageListUpdate>;
  startTyping?: Maybe<Scalars['Boolean']>;
  stopTyping?: Maybe<Scalars['Boolean']>;
  storePushNotificationSubscription: BasicResponse;
  toggleContactDeletion?: Maybe<DetailedContact>;
  toggleKontaktDeletion: Kontakt;
  togglePartnerDeletion?: Maybe<Partner>;
  trackActiveKanbanCard?: Maybe<Scalars['Boolean']>;
  trackEnvironmentVariables?: Maybe<Scalars['Boolean']>;
  triggerMessageAction?: Maybe<BasicResponse>;
  updateCalendarEvent?: Maybe<CalendarEvent>;
  updateDetailedContact?: Maybe<DetailedContact>;
  updateDocument?: Maybe<UpdateDocumentMutationResponse>;
  updateKanbanImage?: Maybe<KanbanImage>;
  updatePartner: Partner;
  updatePortfolioItem: PortfolioItem;
  updateRoomMembers: BasicResponse;
  updateRoomSilence?: Maybe<BasicResponse>;
  updateWorkspaceAudioNotifications?: Maybe<BasicResponse>;
  uploadDocuments?: Maybe<UploadDocumentsMutationResponse>;
  uploadPageBoardItemThumbnail?: Maybe<NewPageBoardItem>;
};


export type MutationActivateRelatedRoomArgs = {
  itemParams: PageBoardItemParamsInput;
  workspaceID: Scalars['ID'];
};


export type MutationActivateWorkspaceArgs = {
  workspaceID: Scalars['ID'];
};


export type MutationActivePrivateRoomPageBoardItemArgs = {
  peerUserID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationAddArticlesToPortfolioItemArgs = {
  articlesIDs: Array<Scalars['ID']>;
  portfolioItemID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationAddDetailedContactArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  partnerID: Scalars['ID'];
  remarks?: InputMaybe<Scalars['String']>;
  workspaceID: Scalars['ID'];
};


export type MutationAddKanbanCardArgs = {
  columnID: Scalars['ID'];
  indexPosition?: InputMaybe<Scalars['Int']>;
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationAddKanbanCommentArgs = {
  cardID: Scalars['ID'];
  roomID: Scalars['ID'];
  text: Scalars['String'];
  workspaceID: Scalars['ID'];
};


export type MutationAddKanbanImagesArgs = {
  cardID: Scalars['ID'];
  images: Array<Scalars['Upload']>;
  workspaceID: Scalars['ID'];
};


export type MutationAddOneUnitToCartArgs = {
  articleID: Scalars['ID'];
  cartID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationAddPartnerArgs = {
  name1: Scalars['String'];
  no?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  taxID?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceID: Scalars['ID'];
};


export type MutationAddPortfolioItemArgs = {
  partnerID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationAddReactionArgs = {
  emoji: Scalars['String'];
  messageID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationAddToRoomArgs = {
  roomID: Scalars['ID'];
  userID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationAssociateCollectionArgs = {
  collectionID: Scalars['ID'];
  documentID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationCancelWebRtcsdpOfferArgs = {
  sdp: Scalars['String'];
  userCalledID: Scalars['ID'];
  userCallerID: Scalars['ID'];
};


export type MutationChangeCartItemQuantityArgs = {
  articleID: Scalars['ID'];
  cartID: Scalars['ID'];
  quantity: Scalars['Int'];
  workspaceID: Scalars['ID'];
};


export type MutationChangeColumnIndexPositionArgs = {
  columnID: Scalars['ID'];
  indexPosition: Scalars['Int'];
  workspaceID: Scalars['ID'];
};


export type MutationChangeKanbanCardArgs = {
  card?: InputMaybe<KanbanCardInput>;
  cardID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationChangeKanbanCardColumnArgs = {
  cardID: Scalars['ID'];
  columnID: Scalars['ID'];
  indexPosition?: InputMaybe<Scalars['Int']>;
  workspaceID: Scalars['ID'];
};


export type MutationChangeKanbanCardImagesArgs = {
  cardID: Scalars['String'];
  update: KanbanCardImagesInput;
  workspaceID: Scalars['String'];
};


export type MutationChangeKanbanColumnTitleArgs = {
  columnID: Scalars['ID'];
  title: Scalars['String'];
  workspaceID: Scalars['ID'];
};


export type MutationChangeMessageFavoriteStatusArgs = {
  isFavorite?: InputMaybe<Scalars['Boolean']>;
  messageID: Scalars['ID'];
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationChangeRoomKanbanBoardArgs = {
  board?: InputMaybe<KanbanBoardInput>;
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationChangeRoomKanbanCardArgs = {
  card?: InputMaybe<KanbanCardInput>;
  cardID: Scalars['ID'];
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationChangeRoomNameArgs = {
  name: Scalars['String'];
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationCloseShopOrderArgs = {
  data: ShopOrderAdditionalInfoInput;
  orderID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationCreateCalendarEventArgs = {
  event: CalendarEventInput;
  workspaceID: Scalars['ID'];
};


export type MutationCreateCollectionItemArgs = {
  collectionItem: CollectionItemInput;
  workspaceID: Scalars['ID'];
};


export type MutationCreateCollectionRoomArgs = {
  collectionID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationCreateDocumentRoomArgs = {
  documentID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationCreateFreeRoomArgs = {
  name: Scalars['String'];
  workspaceID: Scalars['ID'];
};


export type MutationCreateKanbanCardArgs = {
  card: KanbanCardInput;
  columnID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationCreateKanbanColumnArgs = {
  column: KanbanColumnInput;
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationCreateOneCardForEachReturnLineFromTextArgs = {
  columnID: Scalars['ID'];
  text: Scalars['String'];
  workspaceID: Scalars['ID'];
};


export type MutationCreatePrivateRoomArgs = {
  secondMemberID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationCreateRoomPageBoardItemArgs = {
  name: Scalars['String'];
  relatedItem?: InputMaybe<PageBoardItemParamsInput>;
  workspaceID: Scalars['ID'];
};


export type MutationCreateShopOrderArgs = {
  cartID: Scalars['ID'];
  data: ShopOrderAdditionalInfoInput;
  workspaceID: Scalars['ID'];
};


export type MutationDeclineWebRtcsdpOfferArgs = {
  sdp: Scalars['String'];
  userCalledID: Scalars['ID'];
  userCallerID: Scalars['ID'];
};


export type MutationDeleteCalendarEventArgs = {
  eventID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationDeleteCollectionItemArgs = {
  collectionID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationDeleteKanbanCardArgs = {
  cardID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationDeleteKanbanColumnArgs = {
  columnID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationDeleteMessageArgs = {
  messageID: Scalars['ID'];
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationDeletePushNotificationSubscriptionArgs = {
  subscriptionAuthKey: Scalars['String'];
};


export type MutationDeleteRoomArgs = {
  id: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationDissociateCollectionArgs = {
  collectionID: Scalars['ID'];
  documentID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationEditKanbanCommentArgs = {
  cardID: Scalars['ID'];
  commentID: Scalars['ID'];
  roomID: Scalars['ID'];
  text: Scalars['String'];
  workspaceID: Scalars['ID'];
};


export type MutationEditMessageArgs = {
  attachments?: InputMaybe<Array<Scalars['Upload']>>;
  content?: InputMaybe<Scalars['String']>;
  messageID: Scalars['ID'];
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationKanbanCommentMutationArgs = {
  cardID: Scalars['ID'];
  input: KanbanCommentInput;
  workspaceID: Scalars['ID'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationLoginWithAccessKeyArgs = {
  accessKey: Scalars['String'];
};


export type MutationLoginWithTokenArgs = {
  token: Scalars['String'];
};


export type MutationMakeRoomReadOnlyArgs = {
  isReadOnly: Scalars['Boolean'];
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationReactToKanbanCommentArgs = {
  cardID: Scalars['ID'];
  commentID: Scalars['ID'];
  emoji: Scalars['String'];
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationReadWorkspaceNotificationsArgs = {
  workspaceID: Scalars['ID'];
};


export type MutationRegisterVisitationToPageBoardItemArgs = {
  itemID: Scalars['ID'];
  itemType: PageBoardItemType;
  name: Scalars['String'];
  workspaceID: Scalars['ID'];
};


export type MutationRemoveArticlesFromPortfolioItemArgs = {
  articlesIDs: Array<Scalars['ID']>;
  portfolioItemID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationRemoveFromCartArgs = {
  articleID: Scalars['ID'];
  cartID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationRemoveFromRoomArgs = {
  roomID: Scalars['ID'];
  userID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationRemoveKanbanCardCoverImageArgs = {
  cardID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationRemoveKanbanCommentArgs = {
  cardID: Scalars['ID'];
  commentID: Scalars['ID'];
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationRemoveKanbanCommentReactionArgs = {
  cardID: Scalars['ID'];
  commentID: Scalars['ID'];
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationRemoveKanbanImageArgs = {
  cardID: Scalars['ID'];
  imageID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationRemovePortfolioItemArgs = {
  portfolioItemID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationSelectArticleVariationArgs = {
  articleID: Scalars['ID'];
  attributeName: Scalars['String'];
  variationName: Scalars['String'];
  workspaceID: Scalars['ID'];
};


export type MutationSendMessageArgs = {
  attachments?: InputMaybe<Array<Scalars['Upload']>>;
  content?: InputMaybe<Scalars['String']>;
  repliedMessageID?: InputMaybe<Scalars['ID']>;
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationSendWebRtciceCandidateArgs = {
  iceCandidate: IceCandidateInput;
  receiverID: Scalars['ID'];
  senderID: Scalars['ID'];
};


export type MutationSendWebRtcsdpAnswerArgs = {
  sdp: Scalars['String'];
  userCallerID: Scalars['ID'];
};


export type MutationSendWebRtcsdpOfferArgs = {
  sdp: Scalars['String'];
  userCalledID: Scalars['ID'];
  userCallerID: Scalars['ID'];
};


export type MutationSetKanbanCardCoverImageArgs = {
  cardID: Scalars['ID'];
  imageID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationSharePageBoardItemsInTheRoomArgs = {
  itemsParams: Array<PageBoardItemParamsInput>;
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationStartTypingArgs = {
  roomID: Scalars['ID'];
  userID: Scalars['ID'];
};


export type MutationStopTypingArgs = {
  roomID: Scalars['ID'];
  userID: Scalars['ID'];
};


export type MutationStorePushNotificationSubscriptionArgs = {
  subscription?: InputMaybe<PushNotificationSubscriptionInput>;
};


export type MutationToggleContactDeletionArgs = {
  contactID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationToggleKontaktDeletionArgs = {
  id: Scalars['ID'];
  kontaktType: KontaktType;
  workspaceID: Scalars['ID'];
};


export type MutationTogglePartnerDeletionArgs = {
  partnerID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationTrackActiveKanbanCardArgs = {
  cardID?: InputMaybe<Scalars['ID']>;
  workspaceID: Scalars['ID'];
};


export type MutationTrackEnvironmentVariablesArgs = {
  environmentVariables: EnvironmentVariablesInput;
};


export type MutationTriggerMessageActionArgs = {
  actionID: Scalars['ID'];
  messageID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationUpdateCalendarEventArgs = {
  event: CalendarEventInput;
  eventID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationUpdateDetailedContactArgs = {
  contactID: Scalars['ID'];
  detailedContactInput: DetailedContactInput;
  workspaceID: Scalars['ID'];
};


export type MutationUpdateDocumentArgs = {
  documentID: Scalars['ID'];
  update: DocumentUpdateInput;
  workspaceID: Scalars['ID'];
};


export type MutationUpdateKanbanImageArgs = {
  imageID: Scalars['ID'];
  update: KanbanImageInput;
  workspaceID: Scalars['ID'];
};


export type MutationUpdatePartnerArgs = {
  partnerID: Scalars['ID'];
  partnerUpdateInput: PartnerUpdateInput;
  workspaceID: Scalars['ID'];
};


export type MutationUpdatePortfolioItemArgs = {
  name?: InputMaybe<Scalars['String']>;
  portfolioItemID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationUpdateRoomMembersArgs = {
  members: Array<RoomMemberInput>;
  roomID: Scalars['ID'];
  updateType: UpdateType;
  workspaceID: Scalars['ID'];
};


export type MutationUpdateRoomSilenceArgs = {
  isSilenced: Scalars['Boolean'];
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationUpdateWorkspaceAudioNotificationsArgs = {
  isOff: Scalars['Boolean'];
  workspaceID: Scalars['ID'];
};


export type MutationUploadDocumentsArgs = {
  collectionsIDs?: InputMaybe<Array<Scalars['ID']>>;
  files: Array<UploadInput>;
  partnerID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type MutationUploadPageBoardItemThumbnailArgs = {
  crop?: InputMaybe<CropInput>;
  file: Scalars['Upload'];
  item: PageBoardItemParamsInput;
  workspaceID: Scalars['ID'];
};

export type NewPageBoardItem = {
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type NewRoom = RoomUpdate & {
  __typename?: 'NewRoom';
  room: Room;
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};

export type NotFoundAttachment = {
  __typename?: 'NotFoundAttachment';
  id?: Maybe<Scalars['ID']>;
};

export type NotificationsCount = {
  kanban?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  unreadMessages?: Maybe<Scalars['Int']>;
  visible?: Maybe<Scalars['Int']>;
};

export type OpenGraph = {
  __typename?: 'OpenGraph';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<OpenGraphImage>;
  site_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['URL']>;
};

export type OpenGraphImage = {
  __typename?: 'OpenGraphImage';
  height?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['URL']>;
  width?: Maybe<Scalars['Int']>;
};

export type PageBoardItem = {
  __typename?: 'PageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  itemID: Scalars['ID'];
  name: Scalars['String'];
  notificationCount?: Maybe<Scalars['Int']>;
  type: PageBoardItemType;
};

export type PageBoardItemAttachment = {
  __typename?: 'PageBoardItemAttachment';
  id: Scalars['ID'];
  item: NewPageBoardItem;
};

export type PageBoardItemFile = FileInterface & {
  __typename?: 'PageBoardItemFile';
  filename: Scalars['String'];
  item?: Maybe<PageBoardItemParams>;
  mimetype?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  src?: Maybe<Scalars['Src']>;
  thumbnail?: Maybe<Image>;
};

export type PageBoardItemNotification = {
  __typename?: 'PageBoardItemNotification';
  id: Scalars['String'];
  item: NewPageBoardItem;
  text: Scalars['String'];
  timestamp?: Maybe<Scalars['DateTime']>;
  workspaceID: Scalars['ID'];
};

export type PageBoardItemParams = PageBoardItemParamsInterface & {
  __typename?: 'PageBoardItemParams';
  itemID: Scalars['ID'];
  type: PageBoardItemType;
};

export type PageBoardItemParamsInput = {
  itemID: Scalars['ID'];
  type: PageBoardItemType;
};

export type PageBoardItemParamsInterface = {
  itemID: Scalars['ID'];
  type: PageBoardItemType;
};

export type PageBoardItemParamsWithRedirect = PageBoardItemParamsInterface & {
  __typename?: 'PageBoardItemParamsWithRedirect';
  itemID: Scalars['ID'];
  redirect: PageBoardItemParams;
  type: PageBoardItemType;
};

export type PageBoardItemSummary = {
  __typename?: 'PageBoardItemSummary';
  id: Scalars['ID'];
  itemID: Scalars['ID'];
  type: PageBoardItemType;
};

export type PageBoardItemSummaryParagraph = {
  __typename?: 'PageBoardItemSummaryParagraph';
  id: Scalars['ID'];
  item: NewPageBoardItem;
  summary: Scalars['String'];
};

export enum PageBoardItemType {
  CalendarEvent = 'CALENDAR_EVENT',
  Contact = 'CONTACT',
  Customer = 'CUSTOMER',
  Document = 'DOCUMENT',
  DocumentCollection = 'DOCUMENT_COLLECTION',
  KanbanCard = 'KANBAN_CARD',
  KanbanCardComment = 'KANBAN_CARD_COMMENT',
  Lead = 'LEAD',
  Message = 'MESSAGE',
  Partner = 'PARTNER',
  Room = 'ROOM',
  ShopArticle = 'SHOP_ARTICLE',
  ShopOrder = 'SHOP_ORDER',
  User = 'USER',
  Vendor = 'VENDOR'
}

export type PageBoardItemTypeCount = {
  __typename?: 'PageBoardItemTypeCount';
  count: Scalars['Int'];
  type: PageBoardItemType;
};

export type PageBoardItemsResponse = {
  __typename?: 'PageBoardItemsResponse';
  items?: Maybe<Array<NewPageBoardItem>>;
  pagination?: Maybe<Pagination>;
};

export type PageBoardVisitationHistory = {
  __typename?: 'PageBoardVisitationHistory';
  entries: Array<PageBoardVisitationHistoryEntry>;
  id: Scalars['ID'];
  workspaceID: Scalars['ID'];
};

export type PageBoardVisitationHistoryEntry = {
  __typename?: 'PageBoardVisitationHistoryEntry';
  id: Scalars['ID'];
  itemID: Scalars['ID'];
  name: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: PageBoardItemType;
};

export type PaginatedActivities = {
  __typename?: 'PaginatedActivities';
  availableDocumentTypes?: Maybe<Array<Scalars['String']>>;
  availableInvoiceFlows?: Maybe<Array<Maybe<InvoiceFlow>>>;
  availableJobStatuses?: Maybe<Array<Scalars['String']>>;
  entries?: Maybe<Array<Activity>>;
  sumToBePaid?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Int']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  from?: Maybe<Scalars['Int']>;
  lastItemIndex?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginationInput = {
  from?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type Paragraph = PageBoardItemSummaryParagraph | TableParagraph | TextParagraph;

export type Partner = Kontakt & {
  __typename?: 'Partner';
  active?: Maybe<Scalars['Boolean']>;
  amountOfDocs?: Maybe<Scalars['Int']>;
  bilateral?: Maybe<Bilateral>;
  city?: Maybe<Scalars['String']>;
  collection?: Maybe<CollectionItem>;
  contacts?: Maybe<Array<ContactSummary>>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deleted?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<User>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  name1?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['String']>;
  ocr?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typesAvailable?: Maybe<Array<CollectionType>>;
  updated?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  url?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export enum PartnerCollection {
  Customer = 'CUSTOMER',
  Lead = 'LEAD',
  Vendor = 'VENDOR'
}

export type PartnerPageBoardItem = CustomerPageBoardItem | LeadPageBoardItem | VendorPageBoardItem;

export type PartnerParams = {
  itemID?: InputMaybe<Scalars['ID']>;
  partnerID?: InputMaybe<Scalars['ID']>;
};

export type PartnerSummary = {
  __typename?: 'PartnerSummary';
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type PartnerUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['DateTime']>;
  name1?: InputMaybe<Scalars['String']>;
  name2?: InputMaybe<Scalars['String']>;
  no?: InputMaybe<Scalars['String']>;
  ocr?: InputMaybe<Scalars['Boolean']>;
  remarks?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  taxID?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type PerDayActivity = {
  __typename?: 'PerDayActivity';
  day: Scalars['Date'];
  numberOfDocuments?: Maybe<Scalars['Int']>;
};

export type Portfolio = {
  __typename?: 'Portfolio';
  id: Scalars['ID'];
  items?: Maybe<Array<PortfolioItem>>;
  partnerID: Scalars['ID'];
};

export type PortfolioItem = {
  __typename?: 'PortfolioItem';
  articles?: Maybe<Array<Article>>;
  created: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  partnerID: Scalars['ID'];
};

export type Price = {
  __typename?: 'Price';
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type PublicAppConfig = {
  __typename?: 'PublicAppConfig';
  fileMaxSizeInMB: Scalars['Float'];
  filesEndpoints: FilesEndpoints;
  isProductionVersion: Scalars['Boolean'];
  productionVersionURL: Scalars['URL'];
  pushNotificationPubKey: Scalars['ID'];
};

export type PushNotificationSubscriptionInput = {
  endpoint: Scalars['URL'];
  expirationTime?: InputMaybe<Scalars['Int']>;
  keys: PushNotificationSubscriptionKeysInput;
};

export type PushNotificationSubscriptionKeysInput = {
  auth: Scalars['String'];
  p256dh: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  account?: Maybe<Account>;
  activities?: Maybe<PaginatedActivities>;
  allowedMembersToBeAdded?: Maybe<Array<RoomMember>>;
  areRoomsUpdating: Scalars['Boolean'];
  article?: Maybe<Article>;
  articles?: Maybe<Array<Article>>;
  articlesCount?: Maybe<Scalars['Int']>;
  articlesNamesSuggestions?: Maybe<Array<Scalars['String']>>;
  articlesSearchBrandNames?: Maybe<Array<Scalars['String']>>;
  authorizationType?: Maybe<AuthorizationType>;
  calendarDocumentsActivitiesEntries?: Maybe<Array<CalendarDocumentActivityEntry>>;
  calendarEntries?: Maybe<Array<CalendarEntry>>;
  calendarEvent?: Maybe<CalendarEvent>;
  cart?: Maybe<Cart>;
  collectionItem?: Maybe<CollectionItem>;
  collectionRoom?: Maybe<Room>;
  collectionRoomID?: Maybe<Scalars['ID']>;
  collectionsItems?: Maybe<Array<CollectionItem>>;
  collectionsOverviews?: Maybe<Array<CollectionsOverview>>;
  collectionsTypesTranslations?: Maybe<Array<TranslatedWord>>;
  companyMembers?: Maybe<Array<User>>;
  contactPageBoardItem?: Maybe<ContactPageBoardItem>;
  countries?: Maybe<Array<TranslatedWord>>;
  decoupledKanbanBoard?: Maybe<DecoupledKanbanBoard>;
  detailedContact?: Maybe<DetailedContact>;
  document?: Maybe<Document>;
  documentCollections?: Maybe<Array<DocumentCollection>>;
  documentPageBoardItem?: Maybe<DocumentPageBoardItem>;
  documentRoom?: Maybe<Room>;
  documentRoomID?: Maybe<Scalars['ID']>;
  documents?: Maybe<Array<Document>>;
  documentsTypesTranslations?: Maybe<Array<TranslatedWord>>;
  fileMaxSizeInMB: Scalars['Float'];
  hello: Scalars['Boolean'];
  homeWorkspace?: Maybe<WorkspaceInterface>;
  isAssistantWorking: Scalars['Boolean'];
  isLogged?: Maybe<Scalars['Boolean']>;
  isProductionVersion: Scalars['Boolean'];
  isRoomSilenced: Scalars['Boolean'];
  kanbanCard?: Maybe<KanbanCard>;
  kanbanCardActivities?: Maybe<Array<KanbanActivity>>;
  kanbanCardImages?: Maybe<KanbanCardImages>;
  kanbanCardPageBoardItem?: Maybe<KanbanCardPageBoardItem>;
  kanbanCards?: Maybe<Array<KanbanCard>>;
  kanbanColumnOrdering?: Maybe<ColumnOrdering>;
  kanbanColumns?: Maybe<Array<KanbanColumn>>;
  kontakt?: Maybe<Kontakt>;
  loggedUser?: Maybe<User>;
  messageIndex?: Maybe<Scalars['Int']>;
  monthActivities?: Maybe<Array<PerDayActivity>>;
  openGraphs?: Maybe<Array<OpenGraph>>;
  /** @deprecated No longer supported */
  openShopOrder?: Maybe<ShopOrder>;
  orders?: Maybe<Array<ShopOrder>>;
  pageBoardItem?: Maybe<NewPageBoardItem>;
  pageBoardItemTypesCount?: Maybe<Array<PageBoardItemTypeCount>>;
  pageBoardItems?: Maybe<PageBoardItemsResponse>;
  pageBoardItemsInCommonWithUsers?: Maybe<PageBoardItemsResponse>;
  pageBoardItemsNotifications?: Maybe<Array<PageBoardItemNotification>>;
  pageBoardItemsSemanticSearch?: Maybe<PageBoardItemsResponse>;
  pageBoardVisitationHistory?: Maybe<PageBoardVisitationHistory>;
  paginatedDocuments: DocumentList;
  partner?: Maybe<Partner>;
  partnerPageBoardItem?: Maybe<PartnerPageBoardItem>;
  ping: Scalars['String'];
  portfolio: Portfolio;
  privateRoom?: Maybe<Room>;
  privateRoomPageBoardItem?: Maybe<RoomPageBoardItem>;
  productionVersionURL: Scalars['URL'];
  publicAppConfig: PublicAppConfig;
  relatedCollectionsItems?: Maybe<Array<CollectionItem>>;
  relatedPageBoardItems?: Maybe<Array<NewPageBoardItem>>;
  room?: Maybe<Room>;
  /** @deprecated No longer supported */
  roomKanbanBoard?: Maybe<KanbanBoard>;
  roomKanbanColumns?: Maybe<Array<KanbanColumn>>;
  roomMembers?: Maybe<RoomMembersList>;
  roomMessageList: RoomMessageList;
  roomName?: Maybe<Scalars['String']>;
  roomNotificationsCount?: Maybe<RoomNotificationsCount>;
  roomPageBoardItem?: Maybe<RoomPageBoardItem>;
  roomType?: Maybe<RoomType>;
  rooms?: Maybe<Array<Room>>;
  roomsMembers?: Maybe<Array<User>>;
  searchArticles?: Maybe<Array<Article>>;
  searchDocumentCollections?: Maybe<Array<Maybe<CollectionItem>>>;
  searchMessageListItems?: Maybe<Array<MessageListItem>>;
  searchRoomMembers?: Maybe<Array<RoomMember>>;
  shareable?: Maybe<Shareable>;
  sharedRoom?: Maybe<Room>;
  shopArticlePageBoardItem?: Maybe<ShopArticlePageBoardItem>;
  shopArticleVariationsAttributes?: Maybe<Array<ShopArticleVariationsAttribute>>;
  shopAssistantMessagePrompt?: Maybe<ShopAssistantMessagePrompt>;
  shopOrderPreferences?: Maybe<ShopOrderPreferences>;
  suggestedArticles?: Maybe<Array<ArticlesGroup>>;
  tenAbleShopOrderCreationCurl?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userCollections?: Maybe<Array<UserCollection>>;
  usersAllowedToBeAddedToTheRoom?: Maybe<Array<User>>;
  variationArticles?: Maybe<Array<ShopArticlePageBoardItem>>;
  webrtcUsers?: Maybe<Array<User>>;
  workspace?: Maybe<WorkspaceInterface>;
  /** @deprecated No longer supported */
  workspaceNotificationsCount?: Maybe<WorkspaceNotificationsCount>;
  workspaceSettings?: Maybe<WorkspaceSettings>;
  workspaceUnreadMessages?: Maybe<Array<MessageListItem>>;
  workspaces?: Maybe<Array<WorkspaceInterface>>;
  workspacesAlertsCount?: Maybe<Array<WorkspaceAlertCount>>;
  zoomSessionUsers?: Maybe<Array<ConferenceUser>>;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryActivitiesArgs = {
  date: Scalars['Date'];
  limit?: InputMaybe<Scalars['Int']>;
  mode: ActivitiesMode;
  offset?: InputMaybe<Scalars['Int']>;
  requestID?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<ActivitiesSearchInput>;
  selectedActivityID?: InputMaybe<Scalars['ID']>;
  workspaceID: Scalars['ID'];
};


export type QueryAllowedMembersToBeAddedArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  roomID: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  workspaceID: Scalars['ID'];
};


export type QueryAreRoomsUpdatingArgs = {
  workspaceID: Scalars['ID'];
};


export type QueryArticleArgs = {
  id: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryArticlesArgs = {
  brandNames?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  workspaceID: Scalars['ID'];
};


export type QueryArticlesCountArgs = {
  brandNames?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  workspaceID: Scalars['ID'];
};


export type QueryArticlesNamesSuggestionsArgs = {
  search: Scalars['String'];
  workspaceID: Scalars['ID'];
};


export type QueryArticlesSearchBrandNamesArgs = {
  search?: InputMaybe<Scalars['String']>;
  workspaceID: Scalars['ID'];
};


export type QueryAuthorizationTypeArgs = {
  userID: Scalars['String'];
};


export type QueryCalendarDocumentsActivitiesEntriesArgs = {
  endDateTime: Scalars['DateTime'];
  modes?: InputMaybe<Array<ActivitiesMode>>;
  startDateTime: Scalars['DateTime'];
  workspaceID: Scalars['ID'];
};


export type QueryCalendarEntriesArgs = {
  endDateTime: Scalars['DateTime'];
  startDateTime: Scalars['DateTime'];
  workspaceID: Scalars['ID'];
};


export type QueryCalendarEventArgs = {
  eventID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryCartArgs = {
  cartID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryCollectionItemArgs = {
  collectionID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryCollectionRoomArgs = {
  collectionID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryCollectionRoomIdArgs = {
  collectionID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryCollectionsItemsArgs = {
  identifiers?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<Scalars['String']>>;
  workspaceID: Scalars['ID'];
};


export type QueryCollectionsOverviewsArgs = {
  excludeSystemTypes?: InputMaybe<Scalars['Boolean']>;
  includeMissingTypes?: InputMaybe<Scalars['Boolean']>;
  workspaceID: Scalars['ID'];
};


export type QueryCompanyMembersArgs = {
  workspaceID: Scalars['ID'];
};


export type QueryContactPageBoardItemArgs = {
  contactID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryDecoupledKanbanBoardArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryDetailedContactArgs = {
  collectionID?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  workspaceID: Scalars['ID'];
};


export type QueryDocumentArgs = {
  documentID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryDocumentCollectionsArgs = {
  groupID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryDocumentPageBoardItemArgs = {
  documentID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryDocumentRoomArgs = {
  documentID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryDocumentRoomIdArgs = {
  documentID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryDocumentsArgs = {
  collectionsIDs: Array<Scalars['ID']>;
  workspaceID: Scalars['ID'];
};


export type QueryIsAssistantWorkingArgs = {
  itemID: Scalars['ID'];
  type: PageBoardItemType;
  workspaceID: Scalars['ID'];
};


export type QueryIsRoomSilencedArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryKanbanCardArgs = {
  cardID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryKanbanCardActivitiesArgs = {
  cardID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryKanbanCardImagesArgs = {
  cardID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryKanbanCardPageBoardItemArgs = {
  cardID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryKanbanCardsArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryKanbanColumnOrderingArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryKanbanColumnsArgs = {
  columnsIDs: Array<Scalars['ID']>;
  workspaceID: Scalars['ID'];
};


export type QueryKontaktArgs = {
  collectionID?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  kontaktType: KontaktType;
  type?: InputMaybe<Scalars['String']>;
  workspaceID: Scalars['ID'];
};


export type QueryMessageIndexArgs = {
  messageID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryMonthActivitiesArgs = {
  date: Scalars['Date'];
  mode: ActivitiesMode;
  workspaceID: Scalars['ID'];
};


export type QueryOpenGraphsArgs = {
  urls: Array<Scalars['URL']>;
};


export type QueryOpenShopOrderArgs = {
  workspaceID: Scalars['ID'];
};


export type QueryOrdersArgs = {
  workspaceID: Scalars['ID'];
};


export type QueryPageBoardItemArgs = {
  itemID: Scalars['ID'];
  type: PageBoardItemType;
  workspaceID: Scalars['ID'];
};


export type QueryPageBoardItemTypesCountArgs = {
  types: Array<PageBoardItemType>;
  workspaceID: Scalars['ID'];
};


export type QueryPageBoardItemsArgs = {
  itemsIDs?: InputMaybe<Array<Scalars['ID']>>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
  timeInterval?: InputMaybe<TimeIntervalInput>;
  types?: InputMaybe<Array<PageBoardItemType>>;
  workspaceID: Scalars['ID'];
};


export type QueryPageBoardItemsInCommonWithUsersArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
  timeInterval?: InputMaybe<TimeIntervalInput>;
  types?: InputMaybe<Array<PageBoardItemType>>;
  usersIDs: Array<Scalars['ID']>;
  workspaceID: Scalars['ID'];
};


export type QueryPageBoardItemsNotificationsArgs = {
  workspaceID: Scalars['ID'];
};


export type QueryPageBoardItemsSemanticSearchArgs = {
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<PageBoardItemType>>;
  workspaceID: Scalars['ID'];
};


export type QueryPageBoardVisitationHistoryArgs = {
  workspaceID: Scalars['ID'];
};


export type QueryPaginatedDocumentsArgs = {
  activeDocumentID?: InputMaybe<Scalars['ID']>;
  collectionsIDs?: InputMaybe<Array<Scalars['ID']>>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  workspaceID: Scalars['ID'];
};


export type QueryPartnerArgs = {
  collectionID?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceID: Scalars['ID'];
};


export type QueryPartnerPageBoardItemArgs = {
  partnerID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryPortfolioArgs = {
  partnerID?: InputMaybe<Scalars['ID']>;
  workspaceID: Scalars['ID'];
};


export type QueryPrivateRoomArgs = {
  secondMemberID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryPrivateRoomPageBoardItemArgs = {
  peerUserID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryRelatedCollectionsItemsArgs = {
  collectionID?: InputMaybe<Scalars['ID']>;
  collectionType?: InputMaybe<Scalars['String']>;
  workspaceID: Scalars['ID'];
};


export type QueryRelatedPageBoardItemsArgs = {
  itemID: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  type: PageBoardItemType;
  workspaceID: Scalars['ID'];
};


export type QueryRoomArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryRoomKanbanBoardArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryRoomKanbanColumnsArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryRoomMembersArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryRoomMessageListArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  roomID: Scalars['ID'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  startIndex?: InputMaybe<Scalars['Int']>;
  stopIndex?: InputMaybe<Scalars['Int']>;
  workspaceID: Scalars['ID'];
};


export type QueryRoomNameArgs = {
  roomID: Scalars['String'];
  workspaceID: Scalars['String'];
};


export type QueryRoomNotificationsCountArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryRoomPageBoardItemArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryRoomTypeArgs = {
  roomID: Scalars['String'];
  workspaceID: Scalars['String'];
};


export type QueryRoomsArgs = {
  workspaceID: Scalars['ID'];
};


export type QueryRoomsMembersArgs = {
  workspaceID: Scalars['ID'];
};


export type QuerySearchArticlesArgs = {
  search?: InputMaybe<Scalars['String']>;
  workspaceID: Scalars['ID'];
};


export type QuerySearchDocumentCollectionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<Scalars['String']>>;
  workspaceID: Scalars['ID'];
};


export type QuerySearchMessageListItemsArgs = {
  roomID: Scalars['ID'];
  search: Scalars['String'];
  workspaceID: Scalars['ID'];
};


export type QuerySearchRoomMembersArgs = {
  roomID: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  workspaceID: Scalars['ID'];
};


export type QueryShareableArgs = {
  shareableID: Scalars['ID'];
  shareableType: ShareableType;
  sharerID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QuerySharedRoomArgs = {
  shareableID: Scalars['ID'];
  sharerID: Scalars['ID'];
};


export type QueryShopArticlePageBoardItemArgs = {
  articleID?: InputMaybe<Scalars['ID']>;
  sku?: InputMaybe<Scalars['ID']>;
  workspaceID: Scalars['ID'];
};


export type QueryShopArticleVariationsAttributesArgs = {
  articleID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryShopAssistantMessagePromptArgs = {
  messageID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryShopOrderPreferencesArgs = {
  partnerItemID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QuerySuggestedArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  workspaceID: Scalars['ID'];
};


export type QueryTenAbleShopOrderCreationCurlArgs = {
  input: ShopOrderAdditionalInfoInput;
  orderID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserCollectionsArgs = {
  workspaceID: Scalars['ID'];
};


export type QueryUsersAllowedToBeAddedToTheRoomArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type QueryVariationArticlesArgs = {
  articleID: Scalars['ID'];
  attributeName: Scalars['String'];
  variationName: Scalars['String'];
  workspaceID: Scalars['ID'];
};


export type QueryWorkspaceArgs = {
  workspaceID: Scalars['ID'];
};


export type QueryWorkspaceNotificationsCountArgs = {
  workspaceID: Scalars['ID'];
};


export type QueryWorkspaceSettingsArgs = {
  workspaceID: Scalars['ID'];
};


export type QueryWorkspaceUnreadMessagesArgs = {
  limitPerRoom?: InputMaybe<Scalars['Int']>;
  roomID?: InputMaybe<Scalars['ID']>;
  workspaceID: Scalars['ID'];
};


export type QueryZoomSessionUsersArgs = {
  displayNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  workspaceID: Scalars['ID'];
};

export type Reaction = {
  __typename?: 'Reaction';
  emoji: Scalars['String'];
  messageID: Scalars['ID'];
  users: Array<User>;
};

export type ReactionUpdate = {
  __typename?: 'ReactionUpdate';
  emoji: Scalars['ID'];
  messageID: Scalars['ID'];
  usersIDs?: Maybe<Array<Scalars['ID']>>;
};

export type Room = {
  __typename?: 'Room';
  allowDocumentUpload?: Maybe<Scalars['Boolean']>;
  cartID?: Maybe<Scalars['ID']>;
  context?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  identifier?: Maybe<RoomIdentifier>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  isSilenced: Scalars['Boolean'];
  kanbanNotificationsCount?: Maybe<Scalars['Int']>;
  lastMessage?: Maybe<Message>;
  members?: Maybe<Array<User>>;
  messages?: Maybe<RoomMessageList>;
  name: Scalars['String'];
  notifications?: Maybe<RoomNotifications>;
  notificationsCount?: Maybe<RoomNotificationsCount>;
  owner?: Maybe<User>;
  secondUser?: Maybe<User>;
  shareableID: Scalars['ID'];
  toEditNameIsAllowed: Scalars['Boolean'];
  type?: Maybe<RoomType>;
  unread?: Maybe<Scalars['Int']>;
  usersTyping?: Maybe<Array<Maybe<TypingUser>>>;
  workspaceID: Scalars['ID'];
  workspaces?: Maybe<Array<Workspace>>;
};


export type RoomMessagesArgs = {
  after?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  startIndex?: InputMaybe<Scalars['Int']>;
  stopIndex?: InputMaybe<Scalars['Int']>;
};

export type RoomIdentifier = {
  __typename?: 'RoomIdentifier';
  identifierID: Scalars['ID'];
  identifierName: Scalars['String'];
};

export type RoomKanbanUpdate = {
  __typename?: 'RoomKanbanUpdate';
  boardNotificationsCount: Scalars['Int'];
  roomID: Scalars['ID'];
  update?: Maybe<KanbanUpdate>;
  workspaceID: Scalars['ID'];
};

export type RoomMember = Account | ContactMember | User;

export type RoomMemberAddition = RoomUpdate & {
  __typename?: 'RoomMemberAddition';
  addedMembers: Array<User>;
  newMessageListItems?: Maybe<Array<MessageListItem>>;
  roomID: Scalars['ID'];
  totalMessages: Scalars['Int'];
};

export type RoomMemberInput = {
  id: Scalars['ID'];
  type: RoomMemberType;
};

export type RoomMemberRemoval = RoomUpdate & {
  __typename?: 'RoomMemberRemoval';
  newMessageListItems?: Maybe<Array<MessageListItem>>;
  removedMembers: Array<User>;
  roomID: Scalars['ID'];
  totalMessages: Scalars['Int'];
};

export enum RoomMemberType {
  Account = 'ACCOUNT',
  ContactMember = 'CONTACT_MEMBER',
  User = 'USER'
}

export type RoomMembersList = {
  __typename?: 'RoomMembersList';
  list?: Maybe<Array<RoomMember>>;
  roomID: Scalars['ID'];
};

export type RoomMessageDeletion = RoomUpdate & {
  __typename?: 'RoomMessageDeletion';
  messagesUpdates?: Maybe<Array<MessageUpdate>>;
  notifications?: Maybe<RoomNotifications>;
  notificationsCount?: Maybe<RoomNotificationsCount>;
  roomID: Scalars['ID'];
  unread?: Maybe<Scalars['Int']>;
};

export type RoomMessageList = {
  __typename?: 'RoomMessageList';
  list?: Maybe<Array<MessageListItem>>;
  roomID: Scalars['ID'];
  totalItems: Scalars['Int'];
};

export type RoomMessageListUpdate = {
  __typename?: 'RoomMessageListUpdate';
  newListItems?: Maybe<Array<MessageListItem>>;
  roomID: Scalars['ID'];
  totalItems: Scalars['Int'];
};

export type RoomNewMessage = RoomUpdate & {
  __typename?: 'RoomNewMessage';
  lastMessage?: Maybe<Message>;
  newMessageListItems?: Maybe<Array<MessageListItem>>;
  notifications?: Maybe<RoomNotifications>;
  notificationsCount?: Maybe<RoomNotificationsCount>;
  roomID: Scalars['ID'];
  totalMessages?: Maybe<Scalars['Int']>;
  unread?: Maybe<Scalars['Int']>;
  workspaceID: Scalars['ID'];
};

export type RoomNotificationUpdate = RoomUpdate & {
  __typename?: 'RoomNotificationUpdate';
  notifications?: Maybe<RoomNotifications>;
  notificationsCount?: Maybe<RoomNotificationsCount>;
  roomID: Scalars['ID'];
  unread?: Maybe<Scalars['Int']>;
};

export type RoomNotifications = {
  __typename?: 'RoomNotifications';
  count?: Maybe<RoomNotificationsCount>;
  firstUnreadMessageIndex?: Maybe<Scalars['Int']>;
  roomID: Scalars['ID'];
};

export type RoomNotificationsCount = NotificationsCount & {
  __typename?: 'RoomNotificationsCount';
  kanban?: Maybe<Scalars['Int']>;
  roomID: Scalars['ID'];
  total?: Maybe<Scalars['Int']>;
  unreadMessages?: Maybe<Scalars['Int']>;
  visible?: Maybe<Scalars['Int']>;
};

export type RoomPageBoardItem = NewPageBoardItem & {
  __typename?: 'RoomPageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  room?: Maybe<RoomSummary>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type RoomPageBoardItemMetadata = {
  __typename?: 'RoomPageBoardItemMetadata';
  id: Scalars['ID'];
  roomID: Scalars['ID'];
};

export type RoomRemotion = {
  __typename?: 'RoomRemotion';
  roomID: Scalars['ID'];
  userID: Scalars['ID'];
};

export type RoomSummary = {
  __typename?: 'RoomSummary';
  context?: Maybe<Scalars['String']>;
  document?: Maybe<DocumentPageBoardItem>;
  id: Scalars['ID'];
  isReadOnly?: Maybe<Scalars['Boolean']>;
  isSilenced?: Maybe<Scalars['Boolean']>;
  lastMessage?: Maybe<MessageSummary>;
  name: Scalars['String'];
  workspaceID: Scalars['ID'];
};

export enum RoomType {
  Collection = 'COLLECTION',
  Company = 'COMPANY',
  CompanyCompany = 'COMPANY_COMPANY',
  CompanyGuest = 'COMPANY_GUEST',
  Document = 'DOCUMENT',
  Free = 'FREE',
  System = 'SYSTEM',
  UserGuest = 'USER_GUEST',
  UserUser = 'USER_USER'
}

export type RoomUpdate = {
  roomID: Scalars['ID'];
};

export type RoomUpdatedProps = RoomUpdate & {
  __typename?: 'RoomUpdatedProps';
  room: Room;
  roomID: Scalars['ID'];
};

export type Row = {
  __typename?: 'Row';
  cells: Array<Scalars['String']>;
  id: Scalars['ID'];
};

export type SdpAnswer = {
  __typename?: 'SDPAnswer';
  sdp: Scalars['String'];
};

export type SdpOffer = {
  __typename?: 'SDPOffer';
  sdp: Scalars['String'];
  userCaller: User;
};

export type SelectVariationResponse = {
  __typename?: 'SelectVariationResponse';
  code?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  variationArticles?: Maybe<Array<ShopArticlePageBoardItem>>;
};

export type Shareable = {
  __typename?: 'Shareable';
  name: Scalars['String'];
  workspaceID: Scalars['ID'];
};

export enum ShareableType {
  Contact = 'CONTACT',
  Customer = 'CUSTOMER',
  Document = 'DOCUMENT',
  KanbanBoard = 'KANBAN_BOARD',
  KanbanCard = 'KANBAN_CARD',
  Lead = 'LEAD',
  Room = 'ROOM',
  Vendor = 'VENDOR'
}

export type Shop = {
  __typename?: 'Shop';
  domain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slogan?: Maybe<Scalars['String']>;
};

export type ShopArticle = {
  __typename?: 'ShopArticle';
  brand?: Maybe<Brand>;
  details?: Maybe<Array<ShopArticleDetails>>;
  ean?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['URL']>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  sku?: Maybe<Scalars['ID']>;
  thumbnail?: Maybe<Scalars['URL']>;
};

export type ShopArticleDetails = {
  __typename?: 'ShopArticleDetails';
  category: Scalars['String'];
  html: Scalars['String'];
};

export type ShopArticlePageBoardItem = NewPageBoardItem & {
  __typename?: 'ShopArticlePageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  article?: Maybe<ShopArticle>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type ShopArticleVariation = {
  __typename?: 'ShopArticleVariation';
  articles?: Maybe<Array<ShopArticlePageBoardItem>>;
  isSelectable?: Maybe<Scalars['Boolean']>;
  isSelected?: Maybe<Scalars['Boolean']>;
  variationName?: Maybe<Scalars['String']>;
};

export type ShopArticleVariationsAttribute = {
  __typename?: 'ShopArticleVariationsAttribute';
  displayName?: Maybe<Scalars['String']>;
  variations?: Maybe<Array<ShopArticleVariation>>;
};

export type ShopAssistantMessagePrompt = {
  __typename?: 'ShopAssistantMessagePrompt';
  historyUsed?: Maybe<Array<ChatWithAssistantEntry>>;
  id: Scalars['ID'];
  llmThoughts?: Maybe<Scalars['String']>;
  messageID: Scalars['ID'];
  prompt: Scalars['String'];
  question: Scalars['String'];
};

export type ShopOrder = {
  __typename?: 'ShopOrder';
  address?: Maybe<ShopOrderAddress>;
  closedAt?: Maybe<Scalars['DateTime']>;
  commission?: Maybe<Scalars['String']>;
  companyOrderId?: Maybe<Scalars['ID']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  items?: Maybe<Array<ShopOrderItem>>;
  partnerID: Scalars['ID'];
};

export type ShopOrderAdditionalInfoInput = {
  address?: InputMaybe<ShopOrderAddressInput>;
  commission?: InputMaybe<Scalars['String']>;
  companyOrderId?: InputMaybe<Scalars['ID']>;
  deliveryDate?: InputMaybe<Scalars['Date']>;
  partnerParams: PartnerParams;
};

export type ShopOrderAddress = {
  __typename?: 'ShopOrderAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name1?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ShopOrderAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  name1?: InputMaybe<Scalars['String']>;
  name2?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type ShopOrderItem = {
  __typename?: 'ShopOrderItem';
  article: ShopArticle;
  quantity: Scalars['Int'];
};

export type ShopOrderPageBoardItem = NewPageBoardItem & {
  __typename?: 'ShopOrderPageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  order?: Maybe<ShopOrder>;
  partnerSummary?: Maybe<PartnerSummary>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type ShopOrderPreferences = {
  __typename?: 'ShopOrderPreferences';
  address?: Maybe<ShopOrderAddress>;
  id: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']>;
  cart?: Maybe<Cart>;
  deletedPageBoardItem?: Maybe<PageBoardItemSummary>;
  editedMessage?: Maybe<MessageListItem>;
  isAssistantWorking: Scalars['Boolean'];
  kanbanCard?: Maybe<KanbanCard>;
  kanbanCardActivities?: Maybe<Array<KanbanActivity>>;
  kanbanCardImages?: Maybe<KanbanCardImages>;
  kanbanCards?: Maybe<Array<KanbanCard>>;
  kanbanColumnOrdering?: Maybe<ColumnOrdering>;
  messageUpdate: MessageUpdate;
  newPageBoardItem?: Maybe<NewPageBoardItem>;
  /** @deprecated No longer supported */
  pageBoardItemNotification?: Maybe<PageBoardItemNotification>;
  pageBoardItemUpdate?: Maybe<NewPageBoardItem>;
  pageBoardItems?: Maybe<PageBoardItemsResponse>;
  pageBoardItemsNotifications?: Maybe<Array<PageBoardItemNotification>>;
  relatedPageBoardItems?: Maybe<Array<NewPageBoardItem>>;
  roomKanbanColumns?: Maybe<Array<KanbanColumn>>;
  /** @deprecated No longer supported */
  roomsKanbanUpdates?: Maybe<Array<Maybe<RoomKanbanUpdate>>>;
  roomsUpdates: Array<RoomUpdate>;
  typingOnRoom?: Maybe<TypingOnRoom>;
  userState?: Maybe<User>;
  webRTCICECandidate: IceCandidate;
  webRTCSDPAnswer: SdpAnswer;
  webRTCSDPOffer: SdpOffer;
  webRTCSDPOfferCancelling: SdpOffer;
  webRTCSDPOfferDeclining: SdpOffer;
  workspacesAlertsCount?: Maybe<Array<WorkspaceAlertCount>>;
  /** @deprecated No longer supported */
  workspacesUpdates?: Maybe<Array<Maybe<WorkspaceUpdate>>>;
};


export type SubscriptionCartArgs = {
  cartID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type SubscriptionDeletedPageBoardItemArgs = {
  workspaceID: Scalars['ID'];
};


export type SubscriptionEditedMessageArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type SubscriptionIsAssistantWorkingArgs = {
  itemID: Scalars['ID'];
  type: PageBoardItemType;
  workspaceID: Scalars['ID'];
};


export type SubscriptionKanbanCardArgs = {
  cardID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type SubscriptionKanbanCardActivitiesArgs = {
  cardID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type SubscriptionKanbanCardImagesArgs = {
  cardID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type SubscriptionKanbanCardsArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type SubscriptionKanbanColumnOrderingArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type SubscriptionNewPageBoardItemArgs = {
  workspaceID: Scalars['ID'];
};


export type SubscriptionPageBoardItemNotificationArgs = {
  workspaceID: Scalars['ID'];
};


export type SubscriptionPageBoardItemUpdateArgs = {
  workspaceID: Scalars['ID'];
};


export type SubscriptionPageBoardItemsArgs = {
  itemsIDs?: InputMaybe<Array<Scalars['ID']>>;
  pagination?: InputMaybe<PaginationInput>;
  search?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<PageBoardItemType>>;
  workspaceID: Scalars['ID'];
};


export type SubscriptionPageBoardItemsNotificationsArgs = {
  workspaceID: Scalars['ID'];
};


export type SubscriptionRelatedPageBoardItemsArgs = {
  itemID: Scalars['ID'];
  type: PageBoardItemType;
  workspaceID: Scalars['ID'];
};


export type SubscriptionRoomKanbanColumnsArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type SubscriptionTypingOnRoomArgs = {
  roomID: Scalars['ID'];
  workspaceID: Scalars['ID'];
};


export type SubscriptionUserStateArgs = {
  workspaceID: Scalars['ID'];
};


export type SubscriptionWorkspacesUpdatesArgs = {
  activeRoomID?: InputMaybe<Scalars['ID']>;
};

export type TableParagraph = {
  __typename?: 'TableParagraph';
  id: Scalars['ID'];
  rows: Array<Row>;
};

export type Tag = {
  __typename?: 'Tag';
  color: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TextMessage = Message & {
  __typename?: 'TextMessage';
  actions?: Maybe<Array<MessageAction>>;
  attachments?: Maybe<Array<Attachment>>;
  author: User;
  content?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  editedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  loading: Scalars['Boolean'];
  reactions?: Maybe<Array<Reaction>>;
  repliedMessageListItem?: Maybe<MessageListItem>;
  roomID: Scalars['ID'];
  sentAt: Scalars['DateTime'];
  workspaces?: Maybe<Array<Workspace>>;
};

export type TextParagraph = {
  __typename?: 'TextParagraph';
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type TimeIntervalInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type TranslatedWord = {
  __typename?: 'TranslatedWord';
  locale: Scalars['String'];
  value: Scalars['String'];
};

export type TranslationsEndpoints = {
  __typename?: 'TranslationsEndpoints';
  collections?: Maybe<Scalars['Src']>;
};

export type TypingOnRoom = {
  __typename?: 'TypingOnRoom';
  roomID: Scalars['ID'];
  usersIDs?: Maybe<Array<Scalars['ID']>>;
};

export type TypingUser = {
  __typename?: 'TypingUser';
  id: Scalars['ID'];
  shortName?: Maybe<Scalars['String']>;
};

export type UpdateDocumentMutationResponse = {
  __typename?: 'UpdateDocumentMutationResponse';
  code?: Maybe<Scalars['Int']>;
  document?: Maybe<Document>;
};

export enum UpdateType {
  Add = 'ADD',
  Remove = 'REMOVE',
  Toggle = 'TOGGLE'
}

export type UpdatedRoomMembers = RoomUpdate & {
  __typename?: 'UpdatedRoomMembers';
  members: Array<RoomMember>;
  roomID: Scalars['ID'];
  updateType: UpdateType;
  workspaceID: Scalars['ID'];
};

export type UploadDocumentsMutationResponse = {
  __typename?: 'UploadDocumentsMutationResponse';
  code?: Maybe<Scalars['Int']>;
  uploadedFiles?: Maybe<Array<UploadedFile>>;
};

export type UploadInput = {
  collectionType: Scalars['String'];
  file: Scalars['Upload'];
  filename: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UploadedFile = {
  __typename?: 'UploadedFile';
  created?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  documentID: Scalars['ID'];
  file?: Maybe<FileInterface>;
  id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  account?: Maybe<Account>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  id: Scalars['ID'];
  lastExit?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  partnerID?: Maybe<Scalars['ID']>;
  previouslyLoggedIn?: Maybe<Scalars['Boolean']>;
  privateRoom?: Maybe<Room>;
  shortName: Scalars['String'];
  status?: Maybe<UserStatus>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<UserType>;
};

export type UserCollection = {
  __typename?: 'UserCollection';
  collectionType: Scalars['String'];
  count: Scalars['Int'];
  title: Scalars['String'];
};

export type UserInclusion = {
  __typename?: 'UserInclusion';
  inclusionType: InclusionType;
  user: User;
};

export type UserInclusionMessage = Message & {
  __typename?: 'UserInclusionMessage';
  actions?: Maybe<Array<MessageAction>>;
  author: User;
  content?: Maybe<UserInclusion>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  editedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  reactions?: Maybe<Array<Reaction>>;
  roomID: Scalars['ID'];
  sentAt: Scalars['DateTime'];
  workspaces?: Maybe<Array<Workspace>>;
};

export type UserListUpdate = {
  updateType: UpdateType;
  userID: Scalars['ID'];
};

export type UserPageBoardItem = NewPageBoardItem & {
  __typename?: 'UserPageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
  user?: Maybe<User>;
};

export enum UserStatus {
  Away = 'AWAY',
  Busy = 'BUSY',
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export enum UserType {
  Bot = 'BOT',
  Guest = 'GUEST',
  User = 'USER'
}

export type VendorPageBoardItem = NewPageBoardItem & {
  __typename?: 'VendorPageBoardItem';
  additionalText?: Maybe<Scalars['String']>;
  allowThumbnailUpload?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  itemID?: Maybe<Scalars['ID']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  partner?: Maybe<PartnerSummary>;
  significativeRelations?: Maybe<Array<PageBoardItemParamsInterface>>;
  thumbnail?: Maybe<Image>;
  type: PageBoardItemType;
};

export type Workspace = WorkspaceInterface & {
  __typename?: 'Workspace';
  account?: Maybe<Account>;
  id: Scalars['ID'];
  isOnDeveloperMode?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  notificationsCount?: Maybe<WorkspaceNotificationsCount>;
  roomsIDs?: Maybe<Array<Scalars['ID']>>;
  settings?: Maybe<WorkspaceSettings>;
  shop?: Maybe<Shop>;
  type: WorkspaceType;
  unread?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type WorkspaceAlertCount = {
  __typename?: 'WorkspaceAlertCount';
  id: Scalars['ID'];
  total: Scalars['Int'];
};

export type WorkspaceInterface = {
  account?: Maybe<Account>;
  id: Scalars['ID'];
  isOnDeveloperMode?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated No longer supported */
  notificationsCount?: Maybe<WorkspaceNotificationsCount>;
  roomsIDs?: Maybe<Array<Scalars['ID']>>;
  settings?: Maybe<WorkspaceSettings>;
  shop?: Maybe<Shop>;
  type: WorkspaceType;
  unread?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
};

export type WorkspaceNotificationsCount = NotificationsCount & {
  __typename?: 'WorkspaceNotificationsCount';
  kanban?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  unreadMessages?: Maybe<Scalars['Int']>;
  visible?: Maybe<Scalars['Int']>;
  workspaceID: Scalars['ID'];
};

export type WorkspacePartner = {
  __typename?: 'WorkspacePartner';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type WorkspaceSettings = {
  __typename?: 'WorkspaceSettings';
  audioNotificationsIsOff?: Maybe<Scalars['Boolean']>;
  silencedRoomsIDs?: Maybe<Array<Scalars['ID']>>;
  workspaceID: Scalars['ID'];
};

export enum WorkspaceType {
  Guest = 'GUEST',
  User = 'USER'
}

export type WorkspaceUpdate = {
  __typename?: 'WorkspaceUpdate';
  notificationsCount?: Maybe<WorkspaceNotificationsCount>;
  roomsUpdates?: Maybe<Array<RoomUpdate>>;
  settings?: Maybe<WorkspaceSettings>;
  unread?: Maybe<Scalars['Int']>;
  workspaceID: Scalars['ID'];
};

export type ZoomSession = {
  __typename?: 'ZoomSession';
  endTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  sessionName: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export type ZoomSessionRegistrationMessage = Message & {
  __typename?: 'ZoomSessionRegistrationMessage';
  actions?: Maybe<Array<MessageAction>>;
  author: User;
  deletedAt?: Maybe<Scalars['DateTime']>;
  editedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  reactions?: Maybe<Array<Reaction>>;
  roomID: Scalars['ID'];
  sentAt: Scalars['DateTime'];
  zoomSession: ZoomSession;
};
