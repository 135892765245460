import { MessageFragment } from "lib/graphql/operations"

export default function getAttachmentImage(message: MessageFragment) {
  if (message.__typename !== "TextMessage") return null

  const attachment = message?.attachments?.[0]

  if (attachment?.__typename === "FileAttachment") {
    if (attachment?.file.__typename !== "File") return null

    const file = attachment?.file

    const url = file?.thumbnail?.url || file?.src
    const filename = file?.filename

    return { src: url, filename }
  }

  if (attachment?.__typename === "ImageAttachment") {
    const url = attachment?.image.src
    const filename = attachment?.image.name

    return { src: url, filename }
  }

  return null
}
