import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import Color from "color"
import React from "react"
import { StyledProps } from "types/type"

export const codeMatBackgroundColor = new Color("grey").lighten(0.8).hex()

export function CodeMat(props: StyledProps & { code: string }) {
  const c = useStyles()

  return (
    <div
      style={props.style}
      className={clsx(props.className, "code-map", c.root)}
    >
      <pre>{props.code}</pre>
    </div>
  )
}
export const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: codeMatBackgroundColor,
      padding: theme.spacing(2),
    },
  }
})
