import useActiveResources from "hooks/useActiveResources"
import {
  NewPageBoardItemFragment,
  PageBoardVisitationHistoryDocument,
  useRegisterVisitationToPageBoardItemMutation,
} from "lib/graphql/operations"
import React from "react"

export function useRegisterItemInTheHistory({
  item,
}: {
  item?: NewPageBoardItemFragment | null
}) {
  const r = useActiveResources()
  const [registerSelected] = useRegisterVisitationToPageBoardItemMutation()

  React.useEffect(() => {
    if (!item || !r.workspaceID) return

    registerSelected({
      refetchQueries: [
        {
          query: PageBoardVisitationHistoryDocument,
          variables: { workspaceID: r.workspaceID },
        },
      ],
      variables: {
        itemID: item?.itemID || "",
        itemType: item?.type,
        name: item?.name || "",
        workspaceID: r.workspaceID,
      },
    })
  }, [item])
}
