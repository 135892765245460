import _f from "lodash/fp"
import _ from "lodash"
import { makeStyles, Button, Icon } from "@material-ui/core"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import ToggleableMenu from "components/ToggleableMenu/ToggleableMenu"
import clsx from "clsx"

export default function SelectButton({
  className = "",
  options = [],
  value: propsValue = null,
  onChange: propsOnChange = undefined,
  placeholder = "",
  disabled,
}) {
  const c = useStyles({})
  const [value, setValue] = useState(propsValue)

  useEffect(() => {
    // if (value === propsValue) return
    setValue(propsValue)
  }, [JSON.stringify(propsValue)])

  useEffect(() => {
    if (typeof propsOnChange !== "function") return
    // if (value === propsValue) return
    propsOnChange(value)
  }, [JSON.stringify(value)])

  const menuItems = useMemo(() => {
    return options.map((option) => {
      return {
        component: option.label,
        onClick() {
          setValue(option.value)
        },
        isSelected: value === option.value,
      }
    })
  }, [value, options])

  const selectedItem = useMemo(() => {
    if (!value) return null
    return options.find((o) => o?.value === value)
  }, [value])

  return (
    <ToggleableMenu
      className={clsx(c.root, "select-button", className)}
      menuItems={menuItems}
      disabled={disabled}
      anchor={
        <Button
          variant="outlined"
          disabled={disabled}
          size="small"
          disableElevation
          style={!selectedItem ? { opacity: 0.5 } : {}}
        >
          {(!value && placeholder) || selectedItem?.label || "select"}
          <Icon style={{ fontSize: 19 }}>arrow_drop_down</Icon>
        </Button>
      }
    ></ToggleableMenu>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      // padding: theme.spacing(1, 2),
      // display: "flex",
      // // justifyContent: '',
    },
  }
})
