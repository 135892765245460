import { ID, Nullable, ObjectWithID, RoomIdentifier } from "types/type"
import RootContainer from "components/RootContainer/RootContainer"
import React from "react"
import useChatAPI from "../hooks/useChatProviderValue/useChatAPI"

export type IChatContext = ReturnType<typeof useChatAPI>

const ChatContext = React.createContext<IChatContext>({} as IChatContext)

ChatContext.displayName = "ChatContext"

export default ChatContext
