import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
// import { RoomNewMessage } from "lib/graphql/types"
import { filter, map, mergeMap, tap, withLatestFrom } from "rxjs/operators"
import { Observable } from "rxjs"
import { useObservable } from "observable-hooks"

import Workspaces from "components/Workspaces/Workspaces"
import { RoomUpdate_RoomNewMessage_Fragment } from "lib/graphql/operations"

type RoomNewMessage = RoomUpdate_RoomNewMessage_Fragment

export default function useNewMessage$(props: {
  roomNewMessageUpdate$: Observable<RoomNewMessage>
}) {
  const { workspaces } = React.useContext(Workspaces.Context)

  const workspacesUsersIDs = React.useMemo(
    () => workspaces.map((w) => w.user?.id).filter(Boolean),
    [workspaces]
  )

  const newMessage$ = useObservable(() =>
    props.roomNewMessageUpdate$.pipe(
      mergeMap((a: RoomNewMessage) => {
        return a.newMessageListItems?.map((a) => a.message) || []
      })
    )
  )
  const newMessageListItem$ = useObservable(() =>
    props.roomNewMessageUpdate$.pipe(
      mergeMap((a: RoomNewMessage) => {
        return a.newMessageListItems || []
      })
    )
  )

  const externalUsersNewMessage$ = useObservable(
    (loggedUsersIDs$) =>
      newMessage$.pipe(
        withLatestFrom(loggedUsersIDs$),
        filter(
          ([message, [loggedUsersIDs]]) =>
            !loggedUsersIDs.includes(message.author.id)
        ),
        map(([message]) => message)
      ),
    [workspacesUsersIDs]
  )

  //it represents a message list item sent by one workspaces' user
  const workspaceUserNewMessageListItem$ = useObservable(
    ($input) =>
      newMessageListItem$.pipe(
        withLatestFrom($input),
        filter(([a, [usersIDs]]) => usersIDs.includes(a.message.author.id)),
        map(([a]) => {
          return a
        })
      ),

    [workspacesUsersIDs]
  )

  const externalUserNewMessageListItem$ = useObservable(
    ($input) =>
      newMessageListItem$.pipe(
        withLatestFrom($input),
        filter(([a, [usersIDs]]) => !usersIDs.includes(a.message.author.id)),
        // tap((a) => console.log(a)),
        map(([a]) => {
          return a
        })
      ),

    [workspacesUsersIDs]
  )

  return useMemoAPI({
    externalUsersNewMessage$,
    workspaceUserNewMessageListItem$,
    externalUserNewMessageListItem$,
    newMessage$,
  })
}
