import React from "react"

export default function UseCallback<
  Callback extends (...args: any[]) => any
>(props: {
  callback: Callback
  deps?: React.DependencyList
  children: (callback: Callback) => React.ReactNode
}) {
  const callback = React.useCallback(props.callback, props.deps || [])
  return <>{props.children(callback)}</>
}
