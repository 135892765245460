export default function requestFrame(callback: () => void) {
  const fn =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (func) {
      window.setTimeout(func, 1000 / 50)
    }

  fn(callback)
}
