import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { Subject, of } from "rxjs"

export default function useRenderedComment() {
  const rendered = React.useRef<Record<string, HTMLElement | null>>({})
  const containerRef = React.useRef<HTMLElement | null>(null)

  const getRef = React.useCallback((commentID: string) => {
    return (element: HTMLElement | null) => {
      rendered.current[commentID] = element
    }
  }, [])

  const goTo = React.useCallback((commentID: string) => {
    process.nextTick(() => {
      const element = rendered.current[commentID] as HTMLElement | null

      if (!element || !containerRef.current) return

      const offsetTop = element.offsetTop - containerRef.current.offsetTop

      containerRef.current.scrollTop = offsetTop
    })
  }, [])

  const api = useMemoAPI({
    goTo,
    getRef,
    containerRef,
  })

  return api
}
