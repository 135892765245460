import { Icon } from "components/Icon/Icon"
import { ButtonBase, Typography, makeStyles } from "@material-ui/core"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { useReadWorkspaceNotificationsMutation } from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
// import useActiveResources

export function ReadAllNotificationsButton() {
  const t = useI18n()

  const r = useActiveResources()

  const [readAllMutation] = useReadWorkspaceNotificationsMutation({
    variables: { workspaceID: r.workspaceID },
  })

  const c = useStyles({})

  return (
    <ButtonBase className={c.root} onClick={() => readAllMutation()}>
      <Typography variant="caption" className={c.typography}>
        <span>
          <Icon name="check" className={c.icon}></Icon>
        </span>
        <span>{t["readAll"]}</span>
      </Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: "left",
  },

  typography: {
    lineHeight: "1",
    display: "flex",
    alignItems: "center",
  },
  icon: { marginRight: 4, fontSize: 19 },
}))
