import { useMemo } from "react"
import filterSearched from "./util/filterSearched"
import sortBy from "util/sortBy"

export default function useFilteredAndSortedEntries({
  entries,
  searchedTerm,
  order,
  orderBy,
  fields,
}) {
  return useMemo(
    () =>
      (entries || [])
        .filter(filterSearched(searchedTerm, { fields }))
        .sort(sortBy({ order, property: orderBy })),
    [entries, searchedTerm, order, orderBy, fields]
  )
}
