import React from "react"
import Spinner from "react-spinkit"
import COLORS from "constants/colors"
import { useTheme } from "@material-ui/core"

function LoadingPage() {
  const theme = useTheme()

  return (
    <div
      className="loading-rooms"
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.primary.main,
      }}
    >
      <Spinner name="cube-grid" color={"white"} />
    </div>
  )
}

export default LoadingPage
