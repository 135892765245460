import clsx from "clsx"
import React, { useMemo } from "react"
import UserDetails from "./components/UserDetails/UserDetails"
import PopoverWrapper from "components/PopoverWrapper/PopoverWrapper"
import useUser from "hooks/useUser/useUser"
import _ from "lodash"
import UserPopoverContext from "./contexts/UserPopoverContext"

export default function UserPopover({
  userID,
  children,
  className = "",
  childrenUnderlinedOnHover = false,
  goToPrivateRoom = undefined,
}) {
  const user = useUser({ id: userID })

  const api = { goToPrivateRoom }

  const ctxValue = useMemo(() => api, [..._.values(api)])

  if (!user) return children

  return (
    <UserPopoverContext.Provider value={ctxValue}>
      <PopoverWrapper
        popover={<UserDetails user={user} />}
        className={clsx("user-popover", className)}
        childrenUnderlinedOnHover={childrenUnderlinedOnHover}
      >
        {children}
      </PopoverWrapper>
    </UserPopoverContext.Provider>
  )
}
