export default function initials(name: string) {
  if (!name) return ""

  const words = name.split(/\s/).filter((a) => !!a)

  const emoji = (words || []).find((w) => isEmoji(w))

  if (emoji) return emoji

  return words.slice(0, 2).reduce((a, text) => {
    return a + text[0]
  }, "")
}

function isEmoji(s: string) {
  const regexExp =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi
  return regexExp.test(s)
}
