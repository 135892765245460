import { ApolloClient, gql } from "@apollo/client";

export default function setRoomUsersTyping(
  props: { roomID: string; usersIDs: string[] },
  client: ApolloClient<{}>
) {
  const { roomID, usersIDs } = props
  client.writeFragment({
    id: `Room:${roomID}`,
    fragment: gql`
      fragment UsersTypingOnRoom on Room {
        usersTyping @client {
          id
        }
      }
    `,
    data: {
      __typename: "Room",
      usersTyping: usersIDs.map((id) => ({ id, __typename: "User" })),
    },
  })
}
