import _ from "lodash"
import { makeStyles, TextField, TextFieldProps } from "@material-ui/core"

export default function TextInput(
  props: TextFieldProps & { readOnly?: boolean }
) {
  const c = useStyles({ fullWidth: props.fullWidth })

  return (
    <TextField
      {..._.omit(props, ["value", "defaultValue"])}
      helperText={props.error}
      // value prop cannot be undefined.
      // It leads to the overlapping between input and label
      // as reported in https://stackoverflow.com/questions/50955603/react-material-ui-label-overlaps-with-text
      value={props.value || props.defaultValue || ""}
      inputProps={{
        readOnly: !!props.readOnly,
        ...{ ...(props.inputProps || {}) },
        // className: clsx(
        //   props?.inputProps?.className,
        // ),
      }}
    />
  )
}

const useStyles = makeStyles((theme) => {
  return {}
})
