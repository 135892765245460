import { PartnerPageBoardItemFragment } from "lib/graphql/operations"
import React from "react"
import arePropsEqual from "util/arePropsEqual"
import { ListEntryProps } from "../../types"
import { PartnerItem } from "./PartnerItem"

export default function getPartnerListItem(
  __typename: PartnerPageBoardItemFragment["__typename"]
) {
  const MemoizedItem = React.memo(
    PartnerItem,
    arePropsEqual([
      // (a) => a.entry?.id,
      (a) => a.entry?.lastUpdate,
      (a) => a.entry?.name,
      (a) => a.entry?.additionalText,
      (a) => a.entry?.partner,
    ])
  )

  return function PartnerListItem(
    props: ListEntryProps<PartnerPageBoardItemFragment>
  ) {
    if (props.entry?.__typename !== __typename) return null

    return (
      <MemoizedItem
        entry={props.entry}
        className={props.className}
        style={props.style}
      />
    )
  }
}
