import useActiveResources from "hooks/useActiveResources"
import _ from "lodash"
import useMemoAPI from "hooks/useMemoAPI"
import useWorkspace from "../useWorkspace/useWorkspace"

export default function useCurrentWorkspace() {
  const { workspaceID } = useActiveResources()

  const { workspace } = useWorkspace({ workspaceID })

  return useMemoAPI({
    workspace,
    user: workspace?.user || null,
    hasShopping: !!workspace?.shop,
  })
}
