import { useApolloClient } from "@apollo/client";
import {
  DeletePushNotificationSubscriptionDocument,
  PublicAppConfigDocument,
  StorePushNotificationSubscriptionDocument,
} from "lib/graphql/operations"
import _ from "lodash"
import { useCallback, useMemo } from "react"
import * as serviceWorker from "serviceWorker"

export default function usePushNotifications() {
  const client = useApolloClient()

  const subscribe = useCallback(async () => {
    const queryResult = await client.query({
      query: PublicAppConfigDocument,
      fetchPolicy: "cache-first",
    })

    const pushNotificationPubKey = _.get(
      queryResult,
      "data.publicAppConfig.pushNotificationPubKey"
    )

    const subscription = await serviceWorker.subscribeToPushNotification({
      pushNotificationPubKey,
    })

    if (!subscription) return

    client.mutate({
      mutation: StorePushNotificationSubscriptionDocument,
      variables: {
        subscription: _.pickBy(
          subscription.toJSON(),
          (v) => typeof v !== "undefined"
        ),
      },
    })
  }, [])

  const unsubscribe = useCallback(async () => {
    const subscription = await serviceWorker.getPushNotificationSubscription()

    if (!subscription) return

    const authKey = _.get(subscription.toJSON(), "keys.auth")

    await subscription.unsubscribe()

    return client.mutate({
      mutation: DeletePushNotificationSubscriptionDocument,
      variables: {
        subscriptionAuthKey: authKey,
      },
    })
  }, [])

  return useMemo(() => {
    return { subscribe, unsubscribe }
  }, [subscribe, unsubscribe])
}
