import _ from "lodash"
import React, { useEffect, useMemo } from "react"
import { useFormik } from "formik"
import { Grid, TextField, makeStyles } from "@material-ui/core"
import { useI18n } from "contexts/i18nContext/i18nContext"
import SelectInput from "components/Input/components/SelectInput/SelectInput"
import Switch from "components/Input/components/Switch/Switch"
import clsx from "clsx"
import { useParamsContext } from "components/OldUploadDocuments/context/libContext"

const onSubmit = () => null

export default function EditForm({
  defaultValues,
  onChange: propsOnChange,
  className = "",
  showRepeatToggle,
}) {
  const value = (key) => _.get(defaultValues, key, "")

  const formik = useFormik({
    initialValues: {
      filename: value("filename"),
      title: value("title"),
      text: {
        value: value("text.value"),
        repeated: value("text.repeated"),
      },
      collectionType: {
        value: value("collectionType.value"),
        repeated: value("collectionType.repeated"),
      },
    },
    enableReinitialize: true,
    onSubmit,
  })

  useEffect(() => {
    formik.dirty && propsOnChange(formik.values)
  }, [JSON.stringify(formik.values)])

  const t = useI18n()
  const c = useStyles({})

  const inputWithRepeatSize = showRepeatToggle ? 6 : 12

  return (
    <Grid container spacing={2} className={clsx("edit-form", className)}>
      <Grid xs={12} item className={c.inputContainer}>
        <TextField
          {...formik.getFieldProps("filename")}
          label={t.name}
          InputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid xs={12} item className={c.inputContainer}>
        <TextField {...formik.getFieldProps("title")} label={t.title} />
      </Grid>
      <Grid xs={inputWithRepeatSize} item className={c.inputContainer}>
        <CollectionTypeSelect
          {...formik.getFieldProps("collectionType.value")}
        />
      </Grid>
      {showRepeatToggle && (
        <Grid xs={6} item className={c.repeatToggle}>
          <RepeatSwitch {...formik.getFieldProps("collectionType.repeated")} />
        </Grid>
      )}
      <Grid xs={inputWithRepeatSize} item className={c.inputContainer}>
        <TextField {...formik.getFieldProps("text.value")} label={t.subject} />
      </Grid>
      {showRepeatToggle && (
        <Grid xs={6} item className={c.repeatToggle}>
          <RepeatSwitch {...formik.getFieldProps("text.repeated")} />
        </Grid>
      )}
    </Grid>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    repeatToggle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "&>*": {
        marginRight: -12,
      },
    },
    inputContainer: {
      "&>*": {
        width: "100%",
      },
    },
  }
})

function RepeatSwitch({ value, onChange, name }) {
  const t = useI18n()

  return (
    <Switch
      TooltipProps={({ value: v }) => ({
        title: v ? t["for-all-files"] : t["only-this-file"],
        placement: "bottom",
        className: "tooltip",
      })}
      value={value}
      onChange={onChange}
      name={name}
    />
  )
}

function CollectionTypeSelect({ name, value, onChange }) {
  const { fileTypesAvailable } = useParamsContext()

  const options = useMemo(() => {
    const opts = (fileTypesAvailable || []).map(({ locale, value }) => ({
      label: locale,
      value,
    }))

    return opts
  }, [fileTypesAvailable])

  return (
    <SelectInput
      options={options}
      name={name}
      value={value}
      onChange={onChange}
    />
  )
}
