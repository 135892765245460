import { Typography, TypographyProps, makeStyles } from "@material-ui/core"
import ClippedText from "components/ClippedText/ClippedText"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import React from "react"
import formatDate from "../../../../lib/formatDate"
import useGlobalStyles from "styles/globalStyles"
import { ItemIconButton } from "./ItemIconButton"
import { ItemGridCellContext } from "./ItemGridCellContext"
import clsx from "clsx"

export function Body(props: {
  children?: (props: {
    title: React.ReactNode
    description: React.ReactNode
    bottom: React.ReactNode
  }) => React.ReactNode
}) {
  const api = React.useContext(ItemGridCellContext)

  const itemsListApi = React.useContext(ItemsListContext)

  const rootContainer = itemsListApi.rootContainer

  const numberOfLines = (() => {
    if (!api.descriptionText) return 3

    if (
      typeof api.descriptionText === "object" &&
      "numberOfLines" in api.descriptionText
    ) {
      return api.descriptionText.numberOfLines
    }

    return 3
  })()

  const c = useStyles({ numberOfLines })

  const timestamp = api.timestamp
    ? formatDate(api.timestamp, rootContainer.width > 400)
    : null

  const timestampComponent = timestamp ? (
    <Typography variant="body2" color="textSecondary">
      {timestamp}
    </Typography>
  ) : null

  const description = (() => {
    // if (!api.descriptionText) return null

    const component = (() => {
      if (
        typeof api.descriptionText === "object" &&
        api.descriptionText &&
        "component" in api.descriptionText
      ) {
        return api.descriptionText?.component || ""
      }

      return api.descriptionText || ""
    })()

    const defaultComponent = (
      <Typography
        variant="body2"
        color="textSecondary"
        className={c.description}
      >
        {component}
      </Typography>
    )

    if (typeof api.renderDescription === "function") {
      return api.renderDescription({
        defaultComponent,
      })
    }

    return defaultComponent
  })()

  const gs = useGlobalStyles()

  return (
    <div className={clsx(c.root, "item-grid-cell-body")}>
      {(() => {
        const title = (
          <div style={{ marginBottom: 4 }}>
            {(() => {
              const typographyProps: TypographyProps = {
                variant: "body1",
                color: "textPrimary",
                className: gs.clipText,
              }

              const defaultComponent = (
                <Typography {...typographyProps}>
                  <ClippedText maxWidth="85%">{api.title}</ClippedText>
                </Typography>
              )

              if (typeof api.renderTitle === "function") {
                return api.renderTitle({
                  defaultComponent,
                  typographyProps,
                })
              }

              return defaultComponent
            })()}
          </div>
        )

        const descriptionComponent = (
          <div style={{ marginBottom: 16 }}>{description}</div>
        )

        const bottom = (
          <div className={c.bottom}>
            {timestampComponent}
            <div style={{ display: "flex", alignItems: "center" }}>
              {(api.buttons || [])?.map((a, index) => (
                <ItemIconButton key={index} {...a}></ItemIconButton>
              ))}
            </div>
          </div>
        )

        if (typeof props.children === "function")
          return props.children({
            title,
            description: descriptionComponent,
            bottom,
          })

        return (
          <React.Fragment>
            <div>
              {title}
              {descriptionComponent}
            </div>
            {bottom}
          </React.Fragment>
        )
      })()}
    </div>
  )
}

type Styles = {
  numberOfLines: number
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },

    bottom: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    description: {
      display: "-webkit-box",
      WebkitLineClamp: (styles: Styles) => styles.numberOfLines,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
    },
  }
})
