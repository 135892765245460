import React from "react"
import KeyListenersContext from "./contexts/KeyListenersContext"
import useKeyListenersAPI from "./hooks/useKeyListenersAPI"
import useEscKeyListener from "./hooks/useEscapeListener"
import useKeyListener from "./hooks/useKeyListener"
import Escape from "./components/Escape/Escape"
export type { KeyListener } from "./hooks/useKeyListenersAPI"

const KeyListeners = {
  Provider: function EscKeyProvider(props: { children: React.ReactNode }) {
    const api = useKeyListenersAPI()

    return (
      <KeyListenersContext.Provider value={api}>
        {props.children}
      </KeyListenersContext.Provider>
    )
  },
  useEscapeListener: useEscKeyListener,
  Context: KeyListenersContext,
  useListener: useKeyListener,
  Escape: Escape,
}

export default KeyListeners
