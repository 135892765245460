import { TextFieldProps, TextField } from "@material-ui/core"
import { FieldInputProps } from "formik"
import { getGermanyMoneyFormat } from "../../../../lib/getGermanyMoneyFormat"

export default function EuropeanMoneyInput(
  props: Omit<TextFieldProps, keyof FieldInputProps<number>> &
    FieldInputProps<number>
) {
  return (
    <TextField
      {...props}
      value={(() => {
        return getGermanyMoneyFormat(props.value)
      })()}
      onChange={(e) => {
        const v = e?.target?.value || "0"

        const numberString = v.replace(/\D/g, "")
        const number = Number(numberString) / 100

        props.onChange({ target: { name: props.name, value: number } })
      }}
    ></TextField>
  )
}
