import { useMutation, gql } from "@apollo/client";
import _ from "lodash"
import _f from "lodash/fp"
import { AddReactionDocument } from "lib/graphql/operations"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"

export default function useReaction({ message }) {
  const { workspace } = useCurrentWorkspace() || {}
  const [mutate] = useMutation(AddReactionDocument)
  const messageID = (message || {}).id

  return {
    addReaction: (emoji) => {
      mutate({
        variables: { workspaceID: workspace.id, emoji, messageID },
        // update(proxy, { data }) {
        //   if (!_.get(data, "addReaction.success")) return
        //   const __typename = message.__typename

        //   const args = {
        //     id: `${__typename}:${messageID}`,
        //     fragment: gql`
        //       fragment ${__typename}Reactions on ${__typename} {
        //         reactions {
        //           emoji
        //           users {
        //             id
        //           }
        //         }
        //       }
        //     `
        //   }

        //   let { reactions } = proxy.readFragment({ ...args }) || {}

        //   const byEmoji = _.keyBy(reactions, r =>
        //     (r.emoji || "").replace(/:/g, "")
        //   )

        //   if (!byEmoji[emoji]) {
        //     byEmoji[emoji] = { emoji, users: [], __typename: "Reaction" }
        //   }

        //   let { users } = byEmoji[emoji]

        //   users = _.uniqBy(
        //     [...users, { id: _.get(workspace, "user.id"), __typename: "User" }],
        //     "id"
        //   )

        //   proxy.writeFragment({
        //     ...args,
        //     data: {
        //       reactions: _.values(byEmoji),
        //       __typename: message.__typename
        //     }
        //   })
        // }
      })
    },
    removeReaction: () => alert("removedReaction"),
  }
}
