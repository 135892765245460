import React from "react"

export default function UseEffect(props: {
  effect: React.EffectCallback
  deps?: React.DependencyList
}) {
  React.useEffect(props.effect, props.deps)

  return <></>
}
