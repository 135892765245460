import { makeStyles } from "@material-ui/core"
import { RoomContext } from "components/Room/contexts/room"
import React from "react"
import { FooterMessagePreview } from "./components/FooterMessagePreview/FooterMessagePreview"
import MessageInput from "./components/MessageInput/MessageInput"
import FooterContext, { FooterProvider } from "./contexts/FooterContext"

export default function Footer() {
  const c = useStyles()
  const { room } = React.useContext(RoomContext)

  if (room?.isReadOnly) return null

  return (
    <div className={c.footer}>
      <FooterProvider>
        <FooterContext.Consumer>
          {({ isSendingMessage }) => (
            <>
              <FooterMessagePreview />
              <MessageInput />
            </>
          )}
        </FooterContext.Consumer>
      </FooterProvider>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    footer: {
      maxWidth: "100vw",
    },
  }
})
