import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { PageTextField as TextField } from "components/Page/Page"
import RichTextField from "components/RichTextField/RichTextField"
import { I18nAPI } from "contexts/i18nContext/i18nContext"
import { ContactFormContext } from "../components/ProviderAndContext/ProviderAndContext"

export default function getRichTextFieldProps(props: {
  translations: I18nAPI
  // api: DocumentFormAPI
  indentationProps?: Omit<FieldsetIndentationProps, "icon" | "children">
}) {
  const { translations } = props

  const fieldProps: FieldsetIndentationProps = {
    ...(props.indentationProps || {}),
    icon: "text_rotation_none_icon",
    children: (
      <ContactFormContext.Consumer>
        {(api) => {
          return (
            <>
              <RichTextField
                TextField={TextField}
                textFieldProps={{
                  label: translations.remarks,
                  ...api.form.getFieldProps("remarks"),
                }}
              ></RichTextField>
            </>
          )
        }}
      </ContactFormContext.Consumer>
    ),
  }

  return fieldProps
}
