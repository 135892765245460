import _ from "lodash"
import { createUploadLink } from "apollo-upload-client"
import axios from "axios"
import { buildAxiosFetch } from "@lifeomic/axios-fetch"
import { LinkProps } from "./link"
import graphqlURI from "./graphqlURI"

export default function httpLink(props: {
  apolloURL?: LinkProps["apolloURL"]
}) {
  return createUploadLink({
    uri: graphqlURI({
      url: {
        protocol: window.location.protocol,
        ..._.omit(props.apolloURL, "protocol"),
        // ...props.apolloURL,
      },
    }),
    //extracted from: https://github.com/jaydenseric/apollo-upload-client/issues/88#issuecomment-434907278
    fetch: buildAxiosFetch(axios, (config, __, init) => ({
      ...config,
      onUploadProgress: (init || {}).onUploadProgress,
    })),
  })
}
