import BoardAndColumnSelectionAPI from "./BoardAndColumnSelectionAPI"
import BoardAndColumnSelectionRoot from "./components/BoardAndColumnSelectionRoot/BoardAndColumnSelectionRoot"

export default {
  Root: BoardAndColumnSelectionRoot,
  Default: function SelectRoomDefault() {
    return (
      <BoardAndColumnSelectionAPI.Provider>
        <BoardAndColumnSelectionRoot></BoardAndColumnSelectionRoot>
      </BoardAndColumnSelectionAPI.Provider>
    )
  },
  Provider: BoardAndColumnSelectionAPI.Provider,
  Context: BoardAndColumnSelectionAPI.Context,
}
