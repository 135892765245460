import { Button, Icon, Snackbar, IconButton } from "@material-ui/core"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import useOnCacheUpdate from "./useOnCacheUpdate"

export default function AssetsCacheUpdateModal() {
  const [isOpen, setIsOpen] = React.useState(false)

  useOnCacheUpdate(() => setIsOpen(true))

  const update = React.useCallback(() => window.location.reload(), [])
  const close = React.useCallback(() => setIsOpen(false), [])

  const t = useI18n()

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      message={t.reloadPageToUpdateCache.message}
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={update}>
            {t.reloadPageToUpdateCache.reload}
          </Button>
          <IconButton aria-label="close" color="inherit" onClick={close}>
            <Icon>close</Icon>
          </IconButton>
        </React.Fragment>
      }
    ></Snackbar>
  )
}
