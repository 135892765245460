import { BottomModalFrame } from "components/BottomModalFrame/BottomModalFrame"
import useEscapeListener from "components/KeyListeners/hooks/useEscapeListener"
import React from "react"
import { ArticleDetailPanelContext } from "../ArticleDetailPanelContext"
import { ArticlesToSelect } from "./ArticlesToSelect"

export function AttributeVariationSelectionModalFrame(props: {
  children: React.ReactNode
}) {
  const api = React.useContext(ArticleDetailPanelContext)

  const close = React.useCallback(() => {
    api.setSelectedVariation(null)
  }, [])

  const isOpen = (api.selectedVariationArticles?.length || 0) > 1

  useEscapeListener(close, { enabled: isOpen })

  return (
    <BottomModalFrame
      isOpen={isOpen}
      onClose={close}
      modalContent={<ArticlesToSelect></ArticlesToSelect>}
      TransitionProps={{
        timeout: 300,
      }}
    >
      {props.children}
    </BottomModalFrame>
  )
}
