import React from "react"
import { RoomContext } from "components/Room/contexts/room"
import MessagesContext from "../../contexts/MessagesContext"
import MessagesList from "../MessagesList/MessagesList"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import Badge from "../Badge/Badge"
import JumpToBottom from "../JumpToBottom/JumpToBottom"
import Message from "../Message/Message"
import { white } from "constants/colors"
import useRootContainerAPI from "hooks/useRootContainerAPI"

export default function MessagesRoot(props: {
  className?: string
  onReady?: () => void
}) {
  const api = React.useContext(RoomContext)
  const totalCount = api.messagesTotalCount

  const c = useStyles()

  if (typeof totalCount !== "number") return null

  return (
    <div
      className={clsx("all-messages", c.root, props.className)}
      ref={api.template.messagesListContainer.containerRef}
    >
      <Badge className="all-messages-badge badge"></Badge>
      <MessagesContext.Consumer>
        {({ listRenderer }) => {
          return (
            <MessagesList
              // totalCount={totalCount || 0}
              Message={Message}
              onItemsRendered={listRenderer.setMessagesRendered}
              initialTopMostItemIndex={api.initialTopMostItemIndex}
              overscan={20}
              ref={listRenderer.messageListRef}
              setIsScrolling={listRenderer.scrolling.setIsScrolling}
            ></MessagesList>
          )
        }}
      </MessagesContext.Consumer>
      <JumpToBottom className="jump-to-bottom"></JumpToBottom>
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: white[1],
      height: "100%",
      position: "relative",
      "&>.badge": {
        top: theme.spacing(2),
        marginLeft: -7,
        position: "absolute",
        zIndex: 1,
        left: "50%",
        transform: "translateX(-50%)",
      },
      "&>.jump-to-bottom": {
        position: "absolute",
        right: theme.spacing(2),
        bottom: theme.spacing(2),
      },
    },
  }
})
