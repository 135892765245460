import DocumentsFilterContext from "../../contexts/DocumentsFilterContext"
import useDocumentsFilterProviderValue from "../../hooks/useDocumentsFilterProviderValue/useDocumentsFilterProviderValue"
import React from "react"

export default function DocumentsFilterDefault({
  mainCollectionID,
  relatedCollectionsIDs,
  onChangeRelatedCollectionsIDs,
  children = null,
}) {
  const value = useDocumentsFilterProviderValue({
    mainCollectionID,
    relatedCollectionsIDs,
    onChangeRelatedCollectionsIDs,
  })

  return (
    <DocumentsFilterContext.Provider value={value}>
      {children}
    </DocumentsFilterContext.Provider>
  )
}
