import { Stream, VideoClient } from "@zoom/videosdk"
import MediaContext from "components/Zoom/context/MediaContext"
import ZoomClientContext from "components/Zoom/context/ZoomClientContext"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
// import Fab from "components/Fab/Fab"

export function useVideo(props: {
  zoomClient: typeof VideoClient
  mediaStream: typeof Stream | null
}) {
  const zmClient = props.zoomClient
  const mediaStream = props.mediaStream

  const [isStartedVideo, setIsStartedVideo] = React.useState(
    zmClient.getCurrentUserInfo()?.bVideoOn
  )

  const startVideo = React.useCallback(async () => {
    if (isStartedVideo) {
      await mediaStream?.stopVideo()
      setIsStartedVideo(false)
    } else {
      const startVideoOptions = {
        hd: true,
        fullHd: true,
        ptz: mediaStream?.isBrowserSupportPTZ(),
        originalRatio: true,
      }
      await mediaStream?.startVideo(startVideoOptions)

      setIsStartedVideo(true)
    }
  }, [mediaStream, isStartedVideo, zmClient])

  return useMemoAPI({
    startVideo,
    isStartedVideo,
  })
}
