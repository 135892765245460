import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import React from "react"

export interface CarouselProps {
  images?: string[] | undefined | null
}

const Factory = contextAndProviderFactory({
  hookApi: function useCarouselApi(props: CarouselProps) {
    const [selectedIndex = 0, setSelectedIndex] = React.useState<number>(0)
    return useMemoAPI({
      ...props,
      images: props.images,
      selectedIndex,
      setSelectedIndex,
    })
  },
})

export const CarouselProvider = Factory.Provider
export const CarouselContext = Factory.Context
