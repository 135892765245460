import React from "react"
import clsx from "clsx"
import PageLayout from "components/PageLayout/PageLayout"
import { fullHeight } from "styles/globalStyles"
import { RoomTemplate } from "../../RoomTemplate"
import SearchMessages from "../../../SearchMessages/SearchMessages"
import { makeStyles } from "@material-ui/core"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"

export default function DefaultTemplate(props: {
  appBar?: React.ReactElement
  mustRenderTopBar?: boolean
  renderTopBar?: () => React.ReactElement
  className?: string
  onClickTopBarBackButton?: () => void
  onChangeTabID?(props: { id: string }): void
}) {
  const {
    appBar = undefined,
    mustRenderTopBar = true,
    renderTopBar,
    onClickTopBarBackButton,
  } = props

  const { isDesktop } = React.useContext(DeviceObserver.Context)

  const c = useStyles()

  return (
    <div className={clsx(c.root, "default-template")}>
      <PageLayout
        topBar={
          mustRenderTopBar &&
          (typeof appBar === "undefined" ? (
            <RoomTemplate.AppBar
              render={renderTopBar}
              onClickBackButton={onClickTopBarBackButton}
            />
          ) : (
            appBar
          ))
        }
        className={clsx("room-layout")}
        style={{ flex: 1 }}
        body={<RoomTemplate.Body />}
      ></PageLayout>
      <SearchMessages.Provider>
        {!!isDesktop && (
          <RoomTemplate.Context.Consumer>
            {({ searchIsOpen }) => {
              if (!searchIsOpen) return null
              return (
                <SearchMessages.Root
                  className={c.searchMessage}
                ></SearchMessages.Root>
              )
            }}
          </RoomTemplate.Context.Consumer>
        )}
      </SearchMessages.Provider>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    searchMessage: {
      width: 400,
      ...fullHeight,
      display: "flex",
      flexDirection: "column",
    },
    root: {
      height: "100%",
      display: "flex",
    },
  }
})
