import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import useComponentSize from "@rehooks/component-size"

/** it is a wrapping ball with the size (width, height) given by the child height*/
export default function SelfContainedBall(props: {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  classes?: { child?: string }
  defaultHeight?: number
}) {
  const childRef = React.useRef<HTMLDivElement | null>(null)

  const size = useComponentSize(childRef)

  const c = useStyles({
    childHeight: size.height || props.defaultHeight || 0,
  })

  return (
    <div
      className={clsx("self-contained-ball", c.root, props.className)}
      style={props.style || {}}
    >
      <div ref={childRef} className={props.classes?.child}>
        {props.children}
      </div>
    </div>
  )
}
const useStyles = makeStyles(() => {
  return {
    root: {
      borderRadius: "50%",
      width: (props: { childHeight: number }) => props.childHeight,
      height: (props: { childHeight: number }) => props.childHeight,
    },
  }
})
