import { IconProps } from "components/Icon/Icon"
import { green, purple, red, yellow } from "constants/colors"
import { I18nAPI, useI18n } from "contexts/i18nContext/i18nContext"
import useMemoAPI from "hooks/useMemoAPI"
import { PageBoardItemType } from "lib/graphql/types"
import _ from "lodash"
import React from "react"

export default function useTypesOptions() {
  const t = useI18n()

  const options = React.useMemo(() => getTypesOptions({ translations: t }), [t])

  return useMemoAPI(options)
}

export const getTypesOptions = ({
  translations: t,
}: {
  translations: I18nAPI
}) => {
  type Options = {
    [P in PageBoardItemType]?: {
      value: P
      label: string
      icon: IconProps["name"]
      color?: string
    }
  }

  const options: Options = _.keyBy(
    [
      {
        value: PageBoardItemType.ShopOrder,
        label: t["cart"],
        icon: getIcon(PageBoardItemType.ShopOrder),
        color: red[0],
      },
      {
        value: PageBoardItemType.Room,
        label: t["room"],
        icon: getIcon(PageBoardItemType.Room),
        color: yellow[0],
      },
      {
        value: PageBoardItemType.CalendarEvent,
        label: t["event"],
        icon: getIcon(PageBoardItemType.CalendarEvent),
        color: green[2],
      },
      {
        value: PageBoardItemType.User,
        label: t["member"],
        icon: getIcon(PageBoardItemType.User),
      },
      {
        value: PageBoardItemType.KanbanCardComment,
        label: t["tasksMessage"],
        icon: getIcon(PageBoardItemType.KanbanCard),
        color: red[1],
      },
      {
        value: PageBoardItemType.KanbanCard,
        label: t["tasks"],
        icon: getIcon(PageBoardItemType.KanbanCard),
        color: red[1],
      },
      {
        value: PageBoardItemType.Document,
        label: t["document"],
        icon: getIcon(PageBoardItemType.Document),
        color: purple[1],
      },

      {
        value: PageBoardItemType.Vendor,
        label: t["vendor"],
        icon: getIcon(PageBoardItemType.Vendor),
        color: green[1],
      },

      {
        value: PageBoardItemType.Lead,
        label: t["lead"],
        icon: getIcon(PageBoardItemType.Lead),
        color: purple[3],
      },

      {
        value: PageBoardItemType.Customer,
        label: t["customer"],

        icon: getIcon(PageBoardItemType.Customer),
        color: yellow[1],
      },

      {
        value: PageBoardItemType.ShopArticle,
        label: t["shopArticle"],
        icon: getIcon(PageBoardItemType.ShopArticle),
        color: purple[0],
      },

      {
        value: PageBoardItemType.Contact,
        label: t["contact"],
        icon: getIcon(PageBoardItemType.Contact),
        color: red[3],
      },
      {
        value: PageBoardItemType.Message,
        label: t["message"],
        color: purple[1],
        icon: getIcon(PageBoardItemType.Message),
      },
    ],
    (a) => a.value
  )

  return options
}

type Options = {
  [P in PageBoardItemType]?: {
    value: P
    icon: IconProps["name"]
  }
}

const icons: Options = _.keyBy(
  [
    {
      value: PageBoardItemType.Contact,
      icon: "message",
    },
    {
      value: PageBoardItemType.ShopArticle,
      icon: "shopping_cart",
    },
    {
      value: PageBoardItemType.ShopOrder,
      icon: "shopping_cart",
    },
    {
      value: PageBoardItemType.Customer,
      icon: "message",
    },
    {
      value: PageBoardItemType.Room,
      icon: "forum",
    },
    {
      value: PageBoardItemType.CalendarEvent,
      icon: "event",
    },
    { value: PageBoardItemType.User, icon: "person" },
    {
      value: PageBoardItemType.KanbanCardComment,
      icon: "view_kanban",
    },
    {
      value: PageBoardItemType.KanbanCard,
      icon: "view_kanban",
    },
    {
      value: PageBoardItemType.Document,
      icon: "description",
    },

    {
      value: PageBoardItemType.Vendor,
      icon: "store_mall_directory_outlined",
    },

    {
      value: PageBoardItemType.Lead,
      icon: "monetization_on_outlined",
    },

    {
      value: PageBoardItemType.Customer,
      icon: "business_center_outlined",
    },

    {
      value: PageBoardItemType.Contact,
      icon: "face_outlined",
    },
    {
      value: PageBoardItemType.Message,
      icon: "message",
    },
  ],
  (a) => a.value
)

export const getIcon = (type: keyof typeof icons) => {
  return icons[type]?.icon || ("" as IconProps["name"])
}
