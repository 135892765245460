import { useLazyQuery } from "@apollo/client";
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import _ from "lodash"
import OpenGraphs from "components/OpenGraphs/OpenGraphs"
import { OpenGraphsDocument } from "lib/graphql/operations"
import { useContext, useEffect } from "react"
import clsx from "clsx"
import extractURLsFromString from "lib/extractURLsFromString"
import formatTextURLs from "lib/formatTextURLs"
import RootContainer from "components/RootContainer/RootContainer"

import React from "react"

export default function MessageOpenGraphs({ messageText, className = "" }) {
  const [fetchOpenGraph, { data }] = useLazyQuery(OpenGraphsDocument, {
    fetchPolicy: "cache-and-network",
  })

  const f = formatTextURLs(messageText)
  const urls = extractURLsFromString(f)
  const { sizeLabel } = useContext(RootContainer.Context)

  useEffect(() => {
    if (!_.get(urls, "length")) return

    const toFetch = urls.filter((url) => {
      try {
        const isSameOrigin = new URL(url).origin === window.location.origin
        return !isSameOrigin
      } catch (e) {
        return false
      }
    })

    if (!toFetch?.length) {
      return false
    }

    fetchOpenGraph({ variables: { urls: toFetch } })
  }, [JSON.stringify(urls)])

  const openGraphs = data?.openGraphs

  if (!openGraphs || !openGraphs.length) return null

  return (
    <Wrapper>
      <OpenGraphs
        openGraphs={openGraphs}
        className={clsx("message-open-graphs", className)}
        clipText={sizeLabel === RootContainer.SizeEnums.SMALL}
      ></OpenGraphs>
    </Wrapper>
  )
}

function Wrapper({ children }) {
  const { isSmallMobile, isMobile } = React.useContext(DeviceObserver.Context)
  const { sizeLabel } = useContext(RootContainer.Context)

  return (
    <div
      style={{
        background: "rgba(128, 128, 128, .1)",
        padding: 4,
        borderRadius: 5,
      }}
      className="wrapper"
    >
      <div
        className="wrapper-content"
        style={{
          maxWidth: (() => {
            const widths = {
              [RootContainer.SizeEnums.LARGE]: 620,
              [RootContainer.SizeEnums.MEDIUM]: 420,
              [RootContainer.SizeEnums.SMALL]: "100%",
            }

            return widths[sizeLabel]
          })(),
        }}
      >
        {children}
      </div>
    </div>
  )
}
