import { TwoPagesAPI } from "../../../../../../TwoPages/TwoPages"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { PageComponent, PageComponentProps } from "components/Page/Page"
import { Subject } from "rxjs"
import { Paper } from "@material-ui/core"

const wrapPageWithPaper = (Page: PageComponent) => {
  return function WrappedPage(props: PageComponentProps) {
    return (
      <Paper elevation={2}>
        <Page {...props}></Page>
      </Paper>
    )
  }
}

export function useItemsListTwoPagesApi() {
  type OpenPageProps = {
    action: Extract<keyof TwoPagesAPI, "openHalfPage" | "openMiniPage">
    Page: PageComponent
  }

  const openPage$ = React.useRef(new Subject<OpenPageProps>()).current

  const openSecondPage = React.useCallback((p: OpenPageProps) => {
    openPage$.next(p)
  }, [])

  const openHalfPage = React.useCallback((Page: PageComponent) => {
    openSecondPage({ action: "openHalfPage", Page: wrapPageWithPaper(Page) })
  }, [])

  const openMiniPage = React.useCallback((Page: PageComponent) => {
    openSecondPage({ action: "openMiniPage", Page: wrapPageWithPaper(Page) })
  }, [])

  return useMemoAPI({ openHalfPage, openMiniPage, openPage$ })
}
