import clsx from "clsx"
import React from "react"
import Page from "../../../Page/Page"
import getDescriptionFieldProps from "./lib/getIndentationProps/getDescriptionFieldProps"
import getCollectionsFieldProps from "./lib/getIndentationProps/getCollectionsFieldProps"
import getTextFieldProps from "./lib/getIndentationProps/getTextFieldProps"
import getMoneyFieldProps from "./lib/getIndentationProps/getMoneyFieldProps"
import getTimelogFieldProps from "../lib/renderTimeLog/getTimelogFieldProps"
import getPartnerFieldProps from "../../../Page/components/PageForm/lib/getPartnerFieldProps"
import { PageForm } from "components/Page/Page"
import { useI18n } from "contexts/i18nContext/i18nContext"
import DocumentFormContext from "components/OldDocumentForm/contexts/DocumentFormContext"
import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { SaveButton } from "components/EnhancedPage/EnhancedPage"
import PDFButton from "../PDFButton/PDFButton"
import { makeStyles } from "@material-ui/core"
import TwoPages from "components/TwoPages/TwoPages"
import RoomPageBoardItem from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/RoomPageBoardItem/RoomPageBoardItem"
import { Freezer } from "components/Freezer/Freezer"

export default function DocumentForm(props: {
  className?: string
  onClose?: () => any
  style?: React.CSSProperties
}) {
  const c = useStyles()

  const ctx = React.useContext(DocumentFormContext)

  const HeaderProps = React.useMemo(() => {
    const headerProps: React.ComponentProps<typeof Page>["HeaderProps"] = {
      iconButton: { iconName: "close", onClick: props.onClose },
      content: (() => {
        const form = ctx.documentForm

        const activeDocument = (
          <React.Fragment>
            <PDFButton className={c.button}></PDFButton>
            <TwoPages.Button
              iconName="chat"
              className={c.button}
              page={{
                id: "chat",
                Page: function MemberList(props: { onClose: () => any }) {
                  return (
                    <DocumentFormContext.Consumer>
                      {(api) => {
                        return (
                          <RoomPageBoardItem.Provider
                            identifier={{
                              documentID: api.document?.id || "",
                            }}
                            onClose={props.onClose?.bind(undefined)}
                          >
                            <RoomPageBoardItem.Root></RoomPageBoardItem.Root>
                          </RoomPageBoardItem.Provider>
                        )
                        // return <Room.Provider identifier={{documentID: api.document?.id}}></Room.Provider>
                      }}
                    </DocumentFormContext.Consumer>
                  )
                },
              }}
            ></TwoPages.Button>
            <SaveButton
              disabled={!form.readyToSubmit || !form.formik.dirty}
              variant="contained"
              size="small"
              onClick={() => {
                form.submit()
              }}
              color="primary"
            ></SaveButton>
          </React.Fragment>
        )

        return (
          <div className={c.headerRight}>
            {ctx.document?.isProcessing ? null : activeDocument}
          </div>
        )
      })(),
    }

    return headerProps
  }, [ctx.documentForm, props.onClose])

  const translations = useI18n()

  return (
    <Page
      className="document-form"
      HeaderProps={HeaderProps}
      style={props.style}
      body={(() => {
        const body = (() => {
          const indentationProps: Array<FieldsetIndentationProps | null> = [
            getDescriptionFieldProps({ api: ctx, translations }),
            getPartnerFieldProps({
              translations,
              PartnerTextFieldProps: {
                layout: ctx.twoPagesAPI,
                partner: ctx.partner,
                PartnerFormProps: {
                  onClickOpenButton: ctx.openPartnerPage,
                },
              },
            }),
            getCollectionsFieldProps({ api: ctx, translations }),
            getTextFieldProps({ api: ctx, translations }),
            getMoneyFieldProps({ api: ctx, translations }),
            getTimelogFieldProps({
              translations,
              updated: {
                timestamp: ctx.document?.updated,
                person: ctx.document?.updatedBy,
              },
              created: {
                timestamp: ctx.document?.created,
                person: ctx.document?.createdBy,
              },
            }),
          ]

          return (
            <PageForm
              fieldsIndentationsProps={indentationProps}
              className={clsx("document-form-body", props.className)}
            ></PageForm>
          )
        })()

        return <Freezer freezed={!!ctx.document?.isProcessing}>{body}</Freezer>
      })()}
    ></Page>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    headerRight: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    button: {
      marginRight: theme.spacing(1),
    },
  }
})
