import { ApolloError } from "@apollo/client"
import CalendarEvent from "components/CalendarEvent/CalendarEvent"
import {
  NewPageBoardItem_CalendarEventPageBoardItem_Fragment,
  NewPageBoardItem_KanbanCardCommentPageBoardItem_Fragment,
  NewPageBoardItem_KanbanCardPageBoardItem_Fragment,
  NewPageBoardItem_MessagePageBoardItem_Fragment,
  NewPageBoardItem_RoomPageBoardItem_Fragment,
  NewPageBoardItem_UserPageBoardItem_Fragment,
  PartnerPageBoardItemFragment,
} from "lib/graphql/operations"
import { AppError, PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { ContactPageBoardItem } from "../ContactPageBoardItem/ContactPageBoardItem"
import {
  DocumentPageBoardItem,
  DocumentPageBoardItemProps,
} from "../DocumentPageBoardItem/DocumentPageBoardItem"
import FourZeroFour from "../FourZeroFour/FourZeroFour"
import KanbanCardCommentPageBoardItem from "../KanbanCardCommentPageBoardItem/KanbanCardCommentPageBoardItem"
import KanbanCardPageBoardItem from "../KanbanCardPageBoardItem/KanbanCardPageBoardItem"
import {
  MessagePageBoardItem,
  MessagePageBoardItemProps,
} from "../MessagePageBoardItem/MessagePageBoardItem"
import { PageBoardItemContext } from "../PageBoardItemContext"
import { PartnerPageBoardItem } from "../PartnerPageBoardItem/PartnerPageBoardItem"
import RoomPageBoardItem, {
  RoomPageBoardItemProps,
} from "../RoomPageBoardItem/RoomPageBoardItem"
import { ShopArticlePageBoardItem } from "../ShopArticlePageBoardItem/ShopArticlePageBoardItem"
import {
  ShopOrderPageBoardItem,
  ShopOrderPageBoardItemProps,
} from "../ShopOrderPageBoardItem/ShopOrderPageBoardItem"
import UserPageBoardItem from "../UserPageBoardItem/UserPageBoardItem"

export function ItemPage(props: {
  style?: React.CSSProperties
  onClose: () => void
}) {
  const [is404, setIs404] = React.useState(false)

  const onError = React.useCallback((e: ApolloError) => {
    setIs404(
      () =>
        !!(e.graphQLErrors || []).find(
          (e) => e.message === AppError.NothingFound
        )
    )
  }, [])

  const api = React.useContext(PageBoardItemContext)

  const item = api.item

  const onClose = props.onClose

  if (!item) return null

  const partnerPageBoardItem = (
    <PartnerPageBoardItem
      entry={item as PartnerPageBoardItemFragment}
      onClose={onClose}
    ></PartnerPageBoardItem>
  )

  const components = {
    [PageBoardItemType.Partner]: partnerPageBoardItem,
    [PageBoardItemType.Vendor]: partnerPageBoardItem,
    [PageBoardItemType.Customer]: partnerPageBoardItem,
    [PageBoardItemType.Lead]: partnerPageBoardItem,
    [PageBoardItemType.Document]: (
      <DocumentPageBoardItem
        onError={onError}
        onClose={onClose}
        initialParams={
          api.initialParams as DocumentPageBoardItemProps["initialParams"]
        }
      ></DocumentPageBoardItem>
    ),
    [PageBoardItemType.User]: (
      <UserPageBoardItem.Default
        entry={item as NewPageBoardItem_UserPageBoardItem_Fragment}
        onError={onError}
        onClose={onClose}
      ></UserPageBoardItem.Default>
    ),
    [PageBoardItemType.Room]: (
      <RoomPageBoardItem.Default
        entry={item as NewPageBoardItem_RoomPageBoardItem_Fragment}
        onClose={onClose}
        initialParams={
          api.initialParams as RoomPageBoardItemProps["initialParams"]
        }
      ></RoomPageBoardItem.Default>
    ),
    [PageBoardItemType.KanbanCard]: (
      <KanbanCardPageBoardItem.Default
        entry={item as NewPageBoardItem_KanbanCardPageBoardItem_Fragment}
        // initialParams={pageBoardApi.selected.openedItemPageParams || undefined}
        TemplatePageProps={{ onClose: onClose }}
      ></KanbanCardPageBoardItem.Default>
    ),
    [PageBoardItemType.KanbanCardComment]: (
      <KanbanCardCommentPageBoardItem
        entry={item as NewPageBoardItem_KanbanCardCommentPageBoardItem_Fragment}
        onClose={onClose}
      ></KanbanCardCommentPageBoardItem>
    ),
    [PageBoardItemType.Message]: (
      <MessagePageBoardItem
        entry={item as NewPageBoardItem_MessagePageBoardItem_Fragment}
        initialParams={
          api.initialParams as MessagePageBoardItemProps["initialParams"]
        }
        onError={onError}
        onClose={onClose}
      ></MessagePageBoardItem>
    ),
    [PageBoardItemType.Contact]: (
      <ContactPageBoardItem onClose={onClose}></ContactPageBoardItem>
    ),
    [PageBoardItemType.CalendarEvent]: (
      <CalendarEvent.Default
        onClose={onClose}
        eventID={
          (item as NewPageBoardItem_CalendarEventPageBoardItem_Fragment).event
            ?.id
        }
      ></CalendarEvent.Default>
    ),
    [PageBoardItemType.ShopArticle]: (
      <ShopArticlePageBoardItem
        onClose={props.onClose}
      ></ShopArticlePageBoardItem>
    ),
    [PageBoardItemType.ShopOrder]: (
      <ShopOrderPageBoardItem
        onClose={props.onClose}
        initialParams={
          api.initialParams as ShopOrderPageBoardItemProps["initialParams"]
        }
      ></ShopOrderPageBoardItem>
    ),
  }

  const component = components[item.type as keyof typeof components]

  if (!component) return null

  return is404 ? <FourZeroFour></FourZeroFour> : component
}
