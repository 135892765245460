import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import React from "react"

// const ErrorContext = React.createContext({
//   errorMessage: null,
//   setErrorMessage: React.Dispatch<React.SetStateAction<null>>
// })

const Factory = contextAndProviderFactory({
  hookApi: function useError() {
    const [errorMessage, setErrorMessage] = React.useState(null)

    return useMemoAPI({ errorMessage, setErrorMessage })
  },
})

export const ErrorContext = Factory.Context
export const ErrorProvider = Factory.Provider

export function useErrorContext() {
  return React.useContext(ErrorContext)
}
