import _ from "lodash"
import React, { useContext, useCallback } from "react"
import PresenceList from "components/PresenceList/index"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import ChatContext from "components/Chat/contexts/ChatContext"

export default function WorkspaceRoomsMembers({ className = "" }) {
  const { roomsMembers } = useContext(ChatContext)

  const c = useStyles({})

  return (
    <PresenceList.Root
      className={clsx("workspace-rooms-members", className)}
      members={roomsMembers}
      ListItem={ListItemWithActions}
    />
  )
}

function ListItemWithActions({ entry: user }) {
  const { setRoomIdentifier } = useContext(ChatContext)

  const goToPrivateRoom = useCallback(() => {
    setRoomIdentifier({ userID: user?.id })
  }, [user?.id])

  return (
    <PresenceList.ListItem
      entry={user}
      onClick={goToPrivateRoom}
      // disableRipple
      // right={
      //   <PresenceList.ActionsButtons
      //     user={user}
      //     goToPrivateRoom={goToPrivateRoom}
      //   ></PresenceList.ActionsButtons>
      // }
    ></PresenceList.ListItem>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    listItem: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  }
})
