import { Scrollbars } from "react-custom-scrollbars"
import React from "react"
import { useEffect } from "react"
import TwoColumns from "../TwoColumns/TwoColumns"
import UploadedGrid from "../UploadedGrid/UploadedGrid"
import { useUploadContext } from "../../context/UploadContext"
import { makeStyles } from "@material-ui/core"

export default function Body({
  FirstColumn,
  enableFileSelection,
  goForward,
  goBackward,
  goToStepIndex
}) {
  const { uploadedFiles } = useUploadContext()
  const amount = (uploadedFiles || []).length

  useEffect(() => {
    amount === 0 && goToStepIndex(0)
  }, [amount])

  const c = useStyles({})

  return (
    <TwoColumns
      className={c.body}
      firstColumn={
        <FirstColumn
          uploadedFiles={uploadedFiles}
          goForward={goForward}
          goBackward={goBackward}
        />
      }
      secondColumn={
        !!amount && (
          <Scrollbars className={c.scrollbars}>
            <UploadedGrid enableSelection={enableFileSelection && amount > 1} className={c.uploadedGrid}/>
          </Scrollbars>
        )
      }
    ></TwoColumns>
  )
}

const useStyles = makeStyles(theme => {
  return {
    scrollbars:{
      marginRight: -16,
      paddingRight: 16
    },
    uploadedGrid: {
      maxWidth: '100%',
      margin: 0,
      marginTop: theme.spacing(-1)
    },
    body: {
      "&>*": {
        maxWidth: '100%'
      },
      height: "100%"
    }
  }
})
