import React from "react"
import { UserFragment } from "lib/graphql/operations"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import { makeStyles, Typography, useTheme } from "@material-ui/core"
import moment from "moment"
import { IconProps } from "components/Icon/Icon"

export default function ActivitiesLines(props: {
  user: UserFragment
  action: React.ReactNode | null
  dateTime: string
  icon?: IconProps["name"]
  render?: (props: {
    defaultComponent?: React.ReactNode
    components?: { action?: React.ReactNode; when?: React.ReactNode }
    whenText?: string
  }) => React.ReactNode

  IndentationProps?: Omit<
    React.ComponentProps<typeof ItemTemplate.Indentation>,
    "children"
  >
}) {
  const c = useStyles()
  const theme = useTheme()

  return (
    <ItemTemplate.Indentation
      icon={props.icon}
      IconProps={{ className: c.iconProps }}
      leftPosition="top"
      {...(props.IndentationProps || {})}
    >
      <div>
        {(() => {
          const action = (() => {
            if (!props.action) return null

            return (
              <Typography
                variant="body2"
                color="textSecondary"
                // style={{ lineHeight: 1, marginBottom: theme.spacing(0.25) }}
                style={{ marginTop: -3 }}
              >
                <span style={{ color: theme.palette.text.primary }}>
                  {props.user.name}
                </span>{" "}
                {props.action}
              </Typography>
            )
          })()
          const whenText = moment(props.dateTime).format(
            "[Am] DD[.] MMMM YYYY [um] HH:mm [Uhr]"
          )

          const when = (
            <Typography variant="body2" color="textSecondary">
              {whenText}
            </Typography>
          )

          const defaultComponent = (
            <React.Fragment>
              {action}
              {when}
            </React.Fragment>
          )

          if (props.render)
            return (
              <>
                {props.render({
                  defaultComponent,
                  components: { action, when },
                  whenText,
                })}
              </>
            )

          return defaultComponent
        })()}
      </div>
    </ItemTemplate.Indentation>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    iconProps: {
      fontSize: 19,
    },
  }
})
