import { RoomContext } from "../../contexts/room"
import React from "react"
import EditRoomName, { EditNameProps } from "../EditRoomName/EditRoomName"
import { ClippedTextProps } from "../../../ClippedText/ClippedText"
import { Variant } from "@material-ui/core/styles/createTypography"
import ClippedTypography from "components/ClippedTypography/ClippedTypography"
import { Icon, Typography } from "@material-ui/core"

type ClippedProps = Omit<ClippedTextProps, "children">

export default function RoomName(props: {
  disabledEdition?: boolean
  typographyVariant?: Variant
  ClippedTextProps?:
    | ClippedProps
    | ((props: { editionButtonIsShown: boolean }) => ClippedProps)
  className?: string
  style?: React.CSSProperties
  EditRoomNameProps: EditNameProps
}) {
  const r = React.useContext(RoomContext)

  const typographyVariant = props.typographyVariant || "h6"

  const roomName = r.room?.name || ""

  const editionIsAllowed = !!(
    r.room?.toEditNameIsAllowed &&
    !r.room?.isReadOnly &&
    !props.disabledEdition
  )
  const clippedProps = (() => {
    if (typeof props.ClippedTextProps === "function")
      return props.ClippedTextProps({
        editionButtonIsShown: editionIsAllowed,
      })

    return props.ClippedTextProps
  })()

  if (!editionIsAllowed)
    return (
      <>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ClippedTypography
              variant={typographyVariant}
              ClippedTextProps={clippedProps}
              style={props.style}
            >
              {roomName}
            </ClippedTypography>

            {r.room?.isSilenced && (
              <Icon
                style={{
                  marginLeft: 4,
                  color: "rgba(0,0,0,0.6)",
                  fontSize: 19,
                }}
              >
                notifications_off
              </Icon>
            )}
          </div>

          {r.room?.isReadOnly && (
            <Typography variant="caption" color="textSecondary">
              (read only)
            </Typography>
          )}
        </div>
      </>
    )

  return (
    <EditRoomName
      {...props.EditRoomNameProps}
      typographyVariant={typographyVariant}
      ClippedTextProps={clippedProps}
      style={props.style}
      renderIdle={({ components }) => {
        return (
          <>
            {components.name}
            {r.room?.isSilenced && (
              <Icon
                style={{
                  marginLeft: 6,
                  marginRight: -4,
                  color: "rgba(0,0,0,0.6)",
                }}
              >
                notifications_off
              </Icon>
            )}
            {components.editButton}
          </>
        )
      }}
    ></EditRoomName>
  )
}
