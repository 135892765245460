import { IconButton, makeStyles } from "@material-ui/core"
import { IconProps, Icon } from "components/Icon/Icon"
import { UploadDocumentsProps } from "components/UploadDocuments/UploadDocuments"
import React from "react"
import { ToggleDeletionButton } from "../ToggleDeletionButton"
import { FormCommonsApi, FormCommonsContext } from "./FormCommonsContext"
import { SaveButton } from "./SaveButton"

export function ControlsButtons(props: {
  UploadDocumentsProps?: UploadDocumentsProps
  children?: (args: {
    api: FormCommonsApi
    components: {
      toggleButton: React.ReactNode
      uploadButton: React.ReactNode
      saveButton: React.ReactNode
    }
    renderIconButton: typeof renderIconButton
  }) => React.ReactNode
}) {
  const api = React.useContext(FormCommonsContext)
  const c = useStyles()

  function notRenderIfIsDeleted(p: React.ReactNode) {
    if (!api.deletion?.isDeleted) return p

    return null
  }

  const toggleButton = <ToggleDeletionButton></ToggleDeletionButton>

  const uploadButton = notRenderIfIsDeleted(
    <IconButton
      size="small"
      onClick={() =>
        api.openUploadPage({
          UploadDocumentsProps: props.UploadDocumentsProps,
        })
      }
    >
      <Icon name="cloud_upload"></Icon>
    </IconButton>
  )

  const saveButton = notRenderIfIsDeleted(<SaveButton></SaveButton>)

  const components = {
    toggleButton,
    uploadButton,
    saveButton,
  }

  const defaultComponent = (
    <>
      {Object.entries(components).map(([key, a]) => (
        <React.Fragment key={key}>{a}</React.Fragment>
      ))}
    </>
  )

  return (
    <div className={c.root}>
      {props.children?.({ components, api, renderIconButton }) ||
        defaultComponent}
    </div>
  )
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    "&>*": {
      marginLeft: 10,
    },
  },
}))

function renderIconButton(props: {
  onClick: () => void
  iconName: IconProps["name"]
}) {
  return (
    <IconButton size="small" onClick={props.onClick}>
      <Icon name={props.iconName}></Icon>
    </IconButton>
  )
}
