import { useSubscription } from "@apollo/client";
import { UserStateDocument } from "lib/graphql/operations"
import useMessageUpdateSubscription from "hooks/useMessageUpdateSubscription/useMessageUpdateSubscription"
import useRoomsUpdatesSubscription from "hooks/useRoomsUpdatesSubscription/useRoomsUpdatesSubscription"
import useWorkspacesUpdatesSubscription from "hooks/useWorkspacesUpdatesSubscription/useWorkspacesUpdatesSubscription"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import useRoomNewMessageUpdate$ from "./useRoomNewMessageUpdate$"
import useRoomsKanbanUpdates from "./useRoomsKanbanUpdates"
import useNewMessage$ from "./useNewMessage$"
import useRoomUpdate$ from "./useRoomUpdate$"
import React from "react"

export default function useGraphqlSubscriptions() {
  const { workspaceID } = useActiveResources()
  const { workspacesUpdates$ } = useWorkspacesUpdatesSubscription()

  const roomUpdate$API = useRoomUpdate$({
    workspacesUpdates$,
  })

  const {
    externalUsersNewMessage$,
    newMessage$,
    workspaceUserNewMessageListItem$,
    externalUserNewMessageListItem$,
  } = useNewMessage$({
    roomNewMessageUpdate$: roomUpdate$API.roomNewMessage$,
  })

  useSubscription(UserStateDocument, { variables: { workspaceID } })

  useRoomsKanbanUpdates()
  useRoomsUpdatesSubscription()
  useMessageUpdateSubscription()

  const api = useMemoAPI({
    workspacesUpdates$,
    // roomNewMessage$,
    // roomUpdate$,
    ...roomUpdate$API,
    externalUsersNewMessage$,
    workspaceUserNewMessageListItem$,
    externalUserNewMessageListItem$,
    newMessage$,
  })

  return api
}
