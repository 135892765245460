import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import Cover from "../Cover/Cover"

export default function CoverContainer() {
  const c = useCoverStyles()
  const api = React.useContext(KanbanCardPageBoardItemContext)

  return (
    <div className={clsx(c.root, "cover-container")}>
      {api.images.data?.cover && <Cover className={c.cover}></Cover>}
    </div>
  )
}
const useCoverStyles = makeStyles((theme) => {
  return {
    // root: { overflow: "hidden" },
    root: {},
    cover: { height: 150 },
  }
})
