import { NotificationsAPI } from "components/NotificationsAPI/NotificationsAPI"
import PurpleBadge, {
  PurpleBadgeProps,
} from "components/PurpleBadge/PurpleBadge"
import useWorkspace from "hooks/useWorkspace/useWorkspace"
import React from "react"
import { StyledProps } from "types/type"

export type WorkspaceBadgeProps = {
  workspaceID: string | null | undefined
  children: React.ReactNode
  badgeProps?: PurpleBadgeProps
} & StyledProps

export function WorkspaceBadge(props: WorkspaceBadgeProps) {
  const { workspace: w } = useWorkspace({
    workspaceID: props.workspaceID || undefined,
    fetchPolicy: "cache-only",
  })

  const notifications = React.useContext(
    NotificationsAPI.Context
  ).getWorkspaceCount({ workspaceID: w?.id || "" })

  if (!w) return <>{props.children}</>

  return (
    <PurpleBadge
      badgeContent={!!notifications}
      variant="dot"
      invisible={!notifications}
      {...props.badgeProps}
    >
      {props.children}
    </PurpleBadge>
  )
}
