import { Breadcrumbs, Typography, makeStyles } from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { ApolloClient, useApolloClient } from "@apollo/client"
import clsx from "clsx"
import ButtonBase from "components/ButtonBase/ButtonBase"
import { Icon, IconProps } from "components/Icon/Icon"
import { MobileHiddenXScrollbar } from "components/MobileHiddenXScrollbar/MobileHiddenXScrollbar"
import { getIcon } from "components/PageBoard/hooks/useTypesOptions"
import UseEffect from "components/UseEffect/UseEffect"
import { I18nAPI, useI18n } from "contexts/i18nContext/i18nContext"
import { DocumentNode } from "graphql"
import { excludeNullable } from "lib/excludeNullable"
import { getPageBoardItemCacheId } from "lib/getCacheID/getCacheID"
import {
  CalendarEventPageBoardItemFragmentDoc,
  ContactPageBoardItemFragmentDoc,
  CustomerPageBoardItemFragmentDoc,
  DocumentPageBoardItemFragmentDoc,
  KanbanCardCommentPageBoardItemFragmentDoc,
  KanbanCardPageBoardItemFragmentDoc,
  LeadPageBoardItemFragmentDoc,
  MessagePageBoardItemFragmentDoc,
  NewPageBoardItem_CalendarEventPageBoardItem_Fragment,
  NewPageBoardItem_ContactPageBoardItem_Fragment,
  NewPageBoardItem_CustomerPageBoardItem_Fragment,
  NewPageBoardItem_DocumentPageBoardItem_Fragment,
  NewPageBoardItem_KanbanCardCommentPageBoardItem_Fragment,
  NewPageBoardItem_KanbanCardPageBoardItem_Fragment,
  NewPageBoardItem_LeadPageBoardItem_Fragment,
  NewPageBoardItem_MessagePageBoardItem_Fragment,
  NewPageBoardItem_RoomPageBoardItem_Fragment,
  NewPageBoardItem_ShopArticlePageBoardItem_Fragment,
  NewPageBoardItem_ShopOrderPageBoardItem_Fragment,
  NewPageBoardItem_VendorPageBoardItem_Fragment,
  RoomPageBoardItemFragmentDoc,
  ShopArticlePageBoardItemFragmentDoc,
  ShopOrderPageBoardItemFragmentDoc,
  UserPageBoardItemFragment,
  UserPageBoardItemFragmentDoc,
  VendorPageBoardItemFragmentDoc,
} from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { StyledProps } from "types/type"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import { IndexedItems } from "../../IndexedItems"

type Item = {
  onClick: () => any
  id: string
  icon?: IconProps["name"]
  title?: string
}

export function HistoryNavigation(
  props: {
    goToHome: () => any
    children?: (args: {
      defaultComponent: React.ReactNode
      // items: Item[]
      amountOfRenderedItems: number
    }) => React.ReactNode
  } & StyledProps
) {
  const api = React.useContext(IndexedItems.Context)

  const historyApi = api.historyThreadApi

  const c = useStyles()

  const getTitle = useGetTitle()
  const t = useI18n()

  if (!historyApi.thread) return null

  const items: Item[] = [
    {
      title: t["home"],
      onClick: () => props.goToHome(),
      id: "home",
      icon: "home",
    },

    ...historyApi.thread.map((item, index) => {
      const isLast = index + 1 === historyApi.thread.length

      const a: Item = {
        id: item.id,
        icon: item.type ? getIcon(item.type) : undefined,
        title:
          getTitle({
            itemID: item.itemID || "",
            type: item.type || PageBoardItemType.User,
          }) || "",
        onClick: () => {
          if (isLast) return

          return historyApi.thread[index + 1]?.onClose?.()
        },
        // label: item.type || "",
      }

      return a
    }),
  ]

  const component = (
    <MobileHiddenXScrollbar.Provider>
      <MobileHiddenXScrollbar.Root>
        <MobileHiddenXScrollbar.Context.Consumer>
          {(api) => {
            return (
              <UseEffect
                deps={[historyApi.thread.length]}
                effect={() => {
                  api.goToTheEnd()
                }}
              ></UseEffect>
            )
          }}
        </MobileHiddenXScrollbar.Context.Consumer>
        {(() => {
          return (
            <Breadcrumbs
              maxItems={20000}
              separator={
                <NavigateNextIcon fontSize="small" style={{ fontSize: 15 }} />
              }
              aria-label="breadcrumb"
              className={clsx("history-navigation", props.className)}
              style={props.style}
              classes={{
                separator: c.separator,
                ol: c.breadcrumbOl,
              }}
            >
              {items.map((item, index) => {
                const isLast = index + 1 === items.length

                return (
                  <ButtonBase onClick={item.onClick} key={item.id}>
                    <Typography
                      color={isLast ? "textPrimary" : "textSecondary"}
                      className={c.buttonContent}
                      variant="body2"
                    >
                      {item.icon ? (
                        <Icon name={item.icon} style={{ fontSize: 19 }}></Icon>
                      ) : null}
                      <span>
                        {[
                          (item.title || "")?.slice(0, 15),
                          (item.title || "")?.length > 15 && "...",
                        ]
                          .filter(excludeNullable)
                          .join("")}
                      </span>
                    </Typography>
                  </ButtonBase>
                )
              })}
            </Breadcrumbs>
          )
        })()}
      </MobileHiddenXScrollbar.Root>
    </MobileHiddenXScrollbar.Provider>
  )

  return (
    <>
      {typeof props.children === "function"
        ? props.children?.({
            defaultComponent: component,
            amountOfRenderedItems: items?.length,
          })
        : component}
    </>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    breadcrumbOl: {
      flexWrap: "nowrap",
    },
    buttonContent: {
      display: "flex",
      whiteSpace: "nowrap",
      alignItems: "center",
      "&>*": {
        marginRight: theme.spacing(0.5),
      },
    },
    separator: {
      margin: 0,
    },
  }
})

function useGetTitle() {
  const client = useApolloClient()
  const t = useI18n()

  return React.useCallback(function getTitle<
    T extends PageBoardItemType = PageBoardItemType
  >(params: { type: T; itemID: string }) {
    const map = titleGettersMap({ translations: t })

    const title = map[params.type as keyof typeof map]?.(params, client)

    return title || ""
  },
  [])
}

export const titleGettersMap = (args: { translations: I18nAPI }) => {
  return {
    [PageBoardItemType.User]: itemGetter({
      typename: "UserPageBoardItem",
      fragment: UserPageBoardItemFragmentDoc,
      getProperty: (item: UserPageBoardItemFragment) =>
        item.user?.name || item.name || "",
    }),
    [PageBoardItemType.CalendarEvent]: itemGetter({
      typename: "CalendarEventPageBoardItem",
      fragment: CalendarEventPageBoardItemFragmentDoc,
      getProperty: (
        item: NewPageBoardItem_CalendarEventPageBoardItem_Fragment
      ) => item.event?.title || item.name || "",
    }),
    [PageBoardItemType.Contact]: itemGetter({
      typename: "ContactPageBoardItem",
      fragment: ContactPageBoardItemFragmentDoc,
      getProperty: (item: NewPageBoardItem_ContactPageBoardItem_Fragment) =>
        item.name ||
        item.contact?.title ||
        args.translations["contactWithoutTitle"],
    }),

    [PageBoardItemType.Document]: itemGetter({
      typename: "DocumentPageBoardItem",
      fragment: DocumentPageBoardItemFragmentDoc,
      getProperty: (item: NewPageBoardItem_DocumentPageBoardItem_Fragment) =>
        item.name ||
        item.document?.title ||
        args.translations["documentWithoutTitle"],
    }),

    [PageBoardItemType.Customer]: itemGetter({
      typename: "CustomerPageBoardItem",
      fragment: CustomerPageBoardItemFragmentDoc,
      getProperty: (item: NewPageBoardItem_CustomerPageBoardItem_Fragment) =>
        item.name || item.partner?.name || "",
    }),

    [PageBoardItemType.KanbanCard]: itemGetter({
      typename: "KanbanCardPageBoardItem",
      fragment: KanbanCardPageBoardItemFragmentDoc,
      getProperty: (item: NewPageBoardItem_KanbanCardPageBoardItem_Fragment) =>
        item.name || item.card?.title || "",
    }),
    [PageBoardItemType.KanbanCardComment]: itemGetter({
      typename: "KanbanCardCommentPageBoardItem",
      fragment: KanbanCardCommentPageBoardItemFragmentDoc,
      getProperty: (
        item: NewPageBoardItem_KanbanCardCommentPageBoardItem_Fragment
      ) => item.cardSummary?.title || "",
    }),
    [PageBoardItemType.Lead]: itemGetter({
      typename: "LeadPageBoardItem",
      fragment: LeadPageBoardItemFragmentDoc,
      getProperty: (item: NewPageBoardItem_LeadPageBoardItem_Fragment) =>
        item.name || item.partner?.name || "",
    }),
    [PageBoardItemType.Message]: itemGetter({
      typename: "MessagePageBoardItem",
      fragment: MessagePageBoardItemFragmentDoc,
      getProperty: (item: NewPageBoardItem_MessagePageBoardItem_Fragment) =>
        item.name || item.message?.roomName || "",
    }),
    [PageBoardItemType.Room]: itemGetter({
      typename: "RoomPageBoardItem",
      fragment: RoomPageBoardItemFragmentDoc,
      getProperty: (item: NewPageBoardItem_RoomPageBoardItem_Fragment) =>
        item.name || item.room?.name,
    }),
    [PageBoardItemType.ShopArticle]: itemGetter({
      typename: "ShopArticlePageBoardItem",
      fragment: ShopArticlePageBoardItemFragmentDoc,
      getProperty: (item: NewPageBoardItem_ShopArticlePageBoardItem_Fragment) =>
        item.name || item.article?.name || "",
      // args.translations["shopArticleWithoutTitle"],
    }),
    [PageBoardItemType.ShopOrder]: itemGetter({
      typename: "ShopOrderPageBoardItem",
      fragment: ShopOrderPageBoardItemFragmentDoc,
      getProperty: (item: NewPageBoardItem_ShopOrderPageBoardItem_Fragment) =>
        item.name || item.order?.id || "",
      // args.translations["shopOrderWithoutTitle"],
    }),
    [PageBoardItemType.Vendor]: itemGetter({
      typename: "VendorPageBoardItem",
      fragment: VendorPageBoardItemFragmentDoc,
      getProperty: (item: NewPageBoardItem_VendorPageBoardItem_Fragment) =>
        item.name ||
        item.partner?.name ||
        args.translations["vendorWithoutTitle"],
    }),
  }
}

function itemGetter<
  T extends NewPageBoardItemFragment,
  R extends any = any
>(props: {
  typename: T["__typename"]
  fragment: DocumentNode
  getProperty: (item: T) => R
}) {
  return function getItemProperty(
    item: T | { type: T["type"]; itemID: T["itemID"] },
    client?: ApolloClient<any>
  ) {
    const t = (() => {
      if ((item as T).__typename === props.typename) {
        return item as T
      }

      return client?.readFragment<T>({
        id:
          getPageBoardItemCacheId({
            ...(item as { type: T["type"]; itemID: T["itemID"] }),
            __typename: props.typename,
          }) || "",
        fragment: props.fragment,
        fragmentName: props.typename,
      })
    })()

    if (!t) return null

    return props.getProperty(t)
  }
}
