import DocumentsDashboardContext from "./contexts/DocumentsDashboardContext"
import DocumentsDashboardProvider from "./components/Provider/Provider"
import DocumentsDashboardRoot from "./components/Root/Root"
import useDocumentsDashboardProviderValue from "./hooks/useDocumentsDashboardProviderValue"
import Details from "./components/Details/index"
import EntryListItem from "./components/EntryListItem/EntryListItem"
import EntryStatus from "./components/EntryStatus/EntryStatus"

const DocumentsDashboard = {
  Context: DocumentsDashboardContext,
  Provider: DocumentsDashboardProvider,
  Root: DocumentsDashboardRoot,
  useProviderValue: useDocumentsDashboardProviderValue,
  ActivityDetails: Details,
  EntryListItem,
  EntryStatus,
}

export default DocumentsDashboard
