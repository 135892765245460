import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import React from "react"
import clsx from "clsx"
import MetaCardWithUserAvatar from "components/MetaCardWithUserAvatar/MetaCardWithUserAvatar"
import UserAvatarWithPopover from "components/UserAvatarWithPopover/UserAvatarWithPopover"

function ActionMetaCard({
  actionAuthor: { id: authorID = "", name: authorName = "" } = {},
  actionDate = "",
  goToPrivateRoom = undefined,
  onlyTime,
  overline = "",
  className = "",
  link = "",
  avatar = undefined,
  renderAuthorName,
}) {
  const c = useStyles({})
  const date = moment(new Date(actionDate))

  const metaCard = (
    <MetaCardWithUserAvatar
      link={link}
      overline={overline}
      className={clsx("action-meta-card", className)}
      userName={authorName}
      title={date.format(onlyTime ? "HH:mm" : "DD.MM.YYYY")}
      avatar={
        typeof avatar === "undefined" ? (
          <UserAvatarWithPopover
            userID={authorID}
            goToPrivateRoom={goToPrivateRoom}
          ></UserAvatarWithPopover>
        ) : (
          avatar
        )
      }
      subtitle={
        <>
          {onlyTime ? null : `${date.format("HH:mm")} `}
          {authorName ? (
            <span className={c.bold}>
              by{" "}
              {typeof renderAuthorName === "function"
                ? renderAuthorName({ authorName })
                : authorName}
            </span>
          ) : (
            ""
          )}
        </>
      }
    ></MetaCardWithUserAvatar>
  )

  return metaCard
}

export default ActionMetaCard

const useStyles = makeStyles((theme) => {
  return {
    bold: {
      fontWeight: 500,
    },
  }
})
