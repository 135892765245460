import React, { useContext, useMemo, useState } from "react"
import _ from "lodash"

const SideSheetDocumentContext = React.createContext({
  selectedDocumentID: undefined,
  selectedDocument: undefined,
  setSelectedDocument(doc = {}) {},
  resetSelection() {},
})

export default SideSheetDocumentContext

export function SideSheetDocumentProvider({ children }) {
  const [selectedDocument, setSelectedDocument] = useState({})

  const api = useMemo(
    () => ({
      selectedDocumentID: _.get(selectedDocument, "id"),
      selectedDocument,
      setSelectedDocument(doc = {}) {
        if (!doc.id) return
        setSelectedDocument(doc)
      },
      resetSelection() {
        setSelectedDocument(null)
      },
    }),
    [(selectedDocument || {}).id]
  )

  return (
    <SideSheetDocumentContext.Provider value={api}>
      {children}
    </SideSheetDocumentContext.Provider>
  )
}

export function useSideSheetDocumentContext() {
  return useContext(SideSheetDocumentContext)
}
