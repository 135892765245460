import { makeStyles } from "@material-ui/styles"
import React, { useCallback, useState, useMemo } from "react"
import TextInput from "../TextInput/TextInput"
import { InputAdornment, IconButton, Icon, Snackbar } from "@material-ui/core"
import { copyToClipboard } from "util/copyToClipboard"
import { useI18n } from "contexts/i18nContext/i18nContext"
import clsx from "clsx"

export default function CopyToClipboardInput({
  getClipboardValue,
  ...props
}: { getClipboardValue: (value: string) => string } & React.ComponentProps<
  typeof TextInput
>) {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const open = useCallback(() => setIsSnackbarOpen(true), [])
  const close = useCallback(() => setIsSnackbarOpen(false), [])

  const copyToClipboard = useCopyToClipboard({
    value: props.value as string,
    transformValue: getClipboardValue,
    onCopy: open,
  })

  const t = useI18n()
  const c = useStyles({})

  return (
    <div className="copy-to-clipboard-input">
      <TextInput
        {...props}
        className={clsx(props.className, c.input)}
        InputProps={{
          endAdornment: props.value && (
            <InputAdornment position="end">
              <IconButton
                disabled={!props.value}
                edge="end"
                onClick={copyToClipboard}
              >
                <Icon fontSize="small">file_copy</Icon>
              </IconButton>
            </InputAdornment>
          ),
        }}
      ></TextInput>

      <Snackbar
        open={isSnackbarOpen}
        onClose={close}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        message={t["copied-to-clipboard"]}
      />
    </div>
  )
}

function useCopyToClipboard({
  value: initialValue,
  onCopy,
  transformValue,
}: {
  value: string
  onCopy: () => void
  transformValue: (value: string) => string
}) {
  return useCallback(() => {
    const value =
      typeof transformValue == "function"
        ? transformValue(initialValue)
        : initialValue

    copyToClipboard(value)
    onCopy()
  }, [initialValue])
}

const useStyles = makeStyles((theme) => {
  return {
    input: {
      width: "100%",
    },
  }
})
