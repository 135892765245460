import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  DocumentFragment,
  PaginatedDocumentsQuery,
  usePaginatedDocumentsQuery,
} from "lib/graphql/operations"
import _ from "lodash"
import React from "react"

export function usePaginatedDocuments(args: {
  collectionId: string
  limit?: number
}) {
  const r = useActiveResources()

  const limit = args.limit || 20
  const collectionsIDs = [args.collectionId]

  const workspaceID = r.workspaceID

  const offsetRef = React.useRef(0)

  const query = usePaginatedDocumentsQuery({
    variables: {
      collectionsIDs,
      workspaceID,
      limit,
      offset: 0,
    },
    fetchPolicy: "cache-and-network",
    skip: !args.collectionId || !r.workspaceID,
  })

  const documents = React.useMemo(
    () =>
      query.data?.paginatedDocuments?.list?.map((a) => a.document).flat() ||
      ([] as DocumentFragment[]),
    [query.data]
  ).sort((a, b) => {
    if (!a.date || !b.date) return 0
    return a.date > b.date ? -1 : 1
  })

  const fetchMore = React.useCallback(() => {
    offsetRef.current = documents.length

    return query.fetchMore({
      variables: {
        offset: offsetRef.current,
        limit: 40,
        collectionsIDs: collectionsIDs,
        workspaceID: workspaceID,
      },

      updateQuery(prev, { fetchMoreResult }) {
        const a: PaginatedDocumentsQuery = {
          __typename: "Query",
          paginatedDocuments: {
            __typename: "DocumentList",
            id: prev.paginatedDocuments?.id || "",
            list: _.uniqBy(
              [
                ...(prev.paginatedDocuments?.list || []),
                ...(fetchMoreResult?.paginatedDocuments?.list || []),
              ],
              (a) => a.document.id || ""
            ).sort((a, b) => {
              const relevantDate = (document: DocumentFragment) => {
                return document.updated || document.created
              }

              return relevantDate(a.document) > relevantDate(b.document)
                ? -1
                : 1
            }),
          },
        }

        return a
      },
    })
  }, [query.fetchMore, collectionsIDs, workspaceID])

  return useMemoAPI({
    lastDocuments: documents.slice(0, 20),
    allDocuments: documents,
    fetchMore,
    loading: query.loading,
  })
}
