import { Nullable } from "types/type"
import useMemoAPI from "hooks/useMemoAPI"
import { useCallback, useMemo, useState } from "react"
import {
  DetailsTabsParams,
  IDetailsTabsContext,
} from "../contexts/DetailsTabsContext"
import DetailsTabsIDs from "../lib/detailsTabsIDs"

export default function useDetailsTabsProviderValue(
  props: DetailsTabsParams
): IDetailsTabsContext {
  const [tabID, setTabID] = useState<DetailsTabsIDs | null>(DetailsTabsIDs.INFO)

  const goToPartnerTab = useCallback(() => {
    setTabID(DetailsTabsIDs.PARTNER)
  }, [])

  const goToDocumentTab = useCallback(() => {
    setTabID(DetailsTabsIDs.DOCUMENT)
  }, [])

  const api: IDetailsTabsContext = useMemoAPI({
    ...props,
    tabID,
    setTabID,
    goToPartnerTab,
    goToDocumentTab,
  })

  return api
}
