import SaveButton from "components/SaveButton/SaveButton"
import DocumentFormRoot from "./components/DocumentFormRoot/DocumentFormRoot"
import {
  DocumentFormContext,
  DocumentFormProvider,
} from "./contexts/DocumentFormContext"

const DocumentForm = {
  Context: DocumentFormContext,
  Root: DocumentFormRoot,
  Provider: DocumentFormProvider,
  SaveButton() {
    return (
      <DocumentFormContext.Consumer>
        {(api) => {
          return (
            <SaveButton
              disabled={!api.form.readyToSubmit}
              onClick={() => {
                api.form.submit()
              }}
            ></SaveButton>
          )
        }}
      </DocumentFormContext.Consumer>
    )
  },
}

export default DocumentForm
