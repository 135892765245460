import clsx from "clsx"
import React from "react"
import Transition, { TransitionStatus } from "react-transition-group/Transition"
import { StyledProps } from "types/type"

export type BottomModalProps = {
  isOpen: boolean
  onClose?: () => any
  children: React.ReactNode | ((status: TransitionStatus) => React.ReactNode)
  rootStylePosition?: React.CSSProperties["position"]
  containerTransitionStyles?: {
    [i in TransitionStatus]?: React.CSSProperties | (() => React.CSSProperties)
  }
  TransitionProps?: React.ComponentProps<typeof Transition>
  backgroundOpacity?: number

  renderFrame?: (props: {
    absolutePositionedContent: React.ReactNode
    background: React.ReactNode
    status: TransitionStatus
  }) => React.ReactNode
} & StyledProps

export function BottomModal(props: BottomModalProps) {
  const timeout = (() => {
    const timeout = props.TransitionProps?.timeout
    if (typeof timeout === "number") return timeout
    return 500
  })()

  return (
    <Transition
      in={props.isOpen}
      {...(props.TransitionProps || {})}
      timeout={timeout}
    >
      {(status) => {
        const content = (() => {
          return (
            <div
              onClick={(e) => e.stopPropagation()}
              className="content"
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                zIndex: 1,

                transition: timeout + "ms",
                transform: "translateY(100%)",

                ...(() => {
                  const styles: {
                    [i in typeof status]?: React.CSSProperties
                  } = {
                    entered: {
                      transform: "translateY(0)",
                    },
                  }

                  return {
                    ...(styles[status] || {} || {}),
                    ...(() => {
                      const style = props.containerTransitionStyles?.[status]

                      if (typeof style === "function") return style()

                      return style
                    })(),
                  }
                })(),
              }}
            >
              <React.Fragment>
                {(() => {
                  if (typeof props.children === "function")
                    return props.children(status)

                  return props.children
                })()}
              </React.Fragment>
            </div>
          )
        })()

        const background = (() => {
          return (
            <div
              onClick={props.onClose}
              className="background"
              style={{
                position: "absolute",
                opacity: 0,
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                background: "black",
                transition: timeout + "ms",
                ...(() => {
                  const styles: {
                    [i in typeof status]?: React.CSSProperties
                  } = {
                    entered: {
                      opacity: props.backgroundOpacity ?? 0.1,
                    },
                  }
                  return styles[status] || {}
                })(),
              }}
            ></div>
          )
        })()

        if (props.renderFrame)
          return (
            <>
              {props.renderFrame({
                absolutePositionedContent: content,
                background,
                status,
              })}
            </>
          )

        return (
          <div
            className={clsx(
              "bottom-modal",
              props.className,
              props.TransitionProps?.className
            )}
            style={{
              position: props.rootStylePosition ?? "fixed",
              display: "block",
              zIndex: 1,
              ...(() => {
                const styles: {
                  [i in typeof status]?: React.CSSProperties
                } = {
                  exited: {
                    display: "none",
                    zIndex: -1,
                  },
                }
                return styles[status] || {}
              })(),

              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {background}
            {content}
          </div>
        )
      }}
    </Transition>
  )
}
