import useMemoAPI from "hooks/useMemoAPI"
import React, { useState, useMemo, useEffect } from "react"
const ConnectionStatusContext = React.createContext(
  {} as ReturnType<typeof useConnectionStatusAPI>
)

ConnectionStatusContext.displayName = "ConnectionStatusContext"

function useConnectionStatusAPI() {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine)
  const [showConnectionStatus, setShowConnectionStatus] = React.useState(
    !isOnline
  )

  React.useEffect(() => {
    let timeoutID: number | NodeJS.Timeout = 0

    const isOffline = !isOnline

    if (isOnline) {
      timeoutID = setTimeout(() => {
        setShowConnectionStatus(false)
      }, 3000)
    }

    if (isOffline) {
      setShowConnectionStatus(true)
    }

    return () => clearTimeout(timeoutID)
  }, [isOnline])

  const setOnline = React.useRef(() => setIsOnline(true)).current
  const setOffline = React.useRef(() => setIsOnline(false)).current

  const value = useMemoAPI({
    isOnline,
    showConnectionStatus,
    setOnline,
    setOffline,
  })

  return value
}

let ConnectionStatus = {
  Context: ConnectionStatusContext,
  Provider: function ConnectionStatusProvider(props: {
    children: React.ReactNode
  }) {
    const value = useConnectionStatusAPI()

    return (
      <ConnectionStatus.Context.Provider value={value}>
        {props.children}
      </ConnectionStatus.Context.Provider>
    )
  },
}

export default ConnectionStatus
