import { Button, makeStyles } from "@material-ui/core"
import ActionsPanel from "components/ActionsPanel/ActionsPanel"
import clsx from "clsx"
import { useContext } from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import DocumentForm from "components/OldDocumentForm/OldDocumentForm"

export default function SaveButtons(props: {
  className?: string
  hidden?: boolean
}) {
  const api = useContext(DocumentForm.Context)

  const t = useI18n()

  if (props.hidden) return null

  return (
    <ActionsPanel
      className={clsx("save-buttons", props.className)}
      actionButtons={[
        <Button
          color="primary"
          disabled={!api.documentForm.readyToSubmit}
          onClick={api.documentForm.submit}
          variant="contained"
          size="small"
        >
          {t.save}
        </Button>,
      ]}
    ></ActionsPanel>
  )
}
