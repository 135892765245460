import { ItemParams } from "components/PageBoard/components/IndexedItems/types"
import useAppRouter from "hooks/useAppRouter"
import useParams from "hooks/useParams"
import { PageBoardItemParams, PageBoardItemType } from "lib/graphql/types"
import React from "react"
import useMemoAPI from "hooks/useMemoAPI"

export function useBrowserItemParams(args: {
  router: ReturnType<typeof useAppRouter>
}) {
  const router = args.router

  const push = React.useCallback(
    (a: PageBoardItemParams | null | undefined) => {
      if (!a) return router.history.push(router.match.url)

      const type = a?.type.toLowerCase()
      const itemID = a?.itemID

      router.history.push([router.match.url, type, itemID].join("/"))
    },
    [router.history, router.match.url]
  )

  const searchParams = useParams(
    [router.match.url, ":type", ":itemID"].join("/")
  ) as {
    type: string | null | undefined
    itemID: string | null | undefined
  }

  const params = React.useMemo<ItemParams | null>(() => {
    if (!searchParams.itemID || !searchParams.type) return null

    const type = searchParams.type.toUpperCase() as PageBoardItemType

    if (!Object.values(PageBoardItemType).includes(type)) return null

    return {
      itemID: searchParams.itemID,
      type,
    }
  }, [searchParams.itemID, searchParams.type])

  return useMemoAPI({ params, push })
}
