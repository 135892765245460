import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import { IconProps } from "components/Icon/Icon"
import KeyListeners from "components/KeyListeners/KeyListeners"
import _ from "lodash"
import React from "react"
import Page, { PageProps } from "../Page/Page"

export { PageIndentation as Indentation } from "../Page/Page"
export { default as SaveButton } from "../SaveButton/SaveButton"

type HeaderProps = PageProps["HeaderProps"]
type BodyProps = PageProps["BodyProps"]

export type EnhancedPageProps = {
  title?: React.ReactNode
  style?: React.CSSProperties
  onClose?: () => any
  className?: string
  titleComponent?: React.ReactNode
  body?: React.ReactNode
  classes?: { body: string; title?: string }
  onCloseIconName?: IconProps["name"]
  BodyProps?: BodyProps
  disableClosingOnEscape?: boolean
  header?: React.ReactNode
  renderTitleLine?: (props: {
    titleComponent: React.ReactNode
  }) => React.ReactNode
  HeaderProps?: HeaderProps

  noHeader?: boolean
}

export const headerIndentation = 48

export function EnhancedPage(props: EnhancedPageProps) {
  const c = useStyles()

  const HeaderProps = React.useMemo(() => {
    if (props.noHeader) return null

    const p: HeaderProps = {
      iconButton: {
        iconName: props.onCloseIconName ? props.onCloseIconName : "arrow_back",
        onClick: props.onClose,
      },

      content: (() => {
        if (!props.title && !props.renderTitleLine && !props.titleComponent)
          return null

        return (
          <div className={clsx("content", c.content)}>
            <div className={c.headerLine}>
              {(() => {
                if (props.titleComponent) {
                  return props.titleComponent
                }

                const title = (
                  <Typography
                    variant="h6"
                    className={clsx(c.title, props?.classes?.title)}
                  >
                    {props.title}
                  </Typography>
                )

                if (props.renderTitleLine) {
                  return props.renderTitleLine({ titleComponent: title })
                }

                return title
              })()}
            </div>
          </div>
        )
      })(),

      ...(props.HeaderProps || {}),

      IndentationProps: {
        ...props?.HeaderProps?.IndentationProps,
        indentation: (() => {
          const hasPropsIndentation =
            typeof props.HeaderProps?.IndentationProps?.indentation === "number"

          if (hasPropsIndentation)
            return props.HeaderProps?.IndentationProps?.indentation

          return headerIndentation
        })(),
      },
    }

    return p
  }, [
    props.onClose,
    props.title,
    props.renderTitleLine,
    props.classes?.title,
    props.titleComponent,
    ..._.values(props.HeaderProps),
  ])

  KeyListeners.useEscapeListener(props.onClose, {
    enabled: !props.disableClosingOnEscape && !!props.onClose && true,
  })

  return (
    <Page
      classes={props.classes}
      className={clsx("enhanced-page", props.className)}
      HeaderProps={HeaderProps}
      BodyProps={props.BodyProps}
      header={props.header}
      body={props.body}
      style={props.style}
    ></Page>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    content: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    headerLine: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      minHeight: 32,
    },
    title: {
      lineHeight: 1,
    },
  }
})
