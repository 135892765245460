import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { useSubscription } from "observable-hooks"
import { Subject } from "rxjs"
import { set } from "lodash"

/**
 * Element that has that  has position different of static
 * (position: relative, position: absolute, or position: fixed)
 */
type ElementWithPositionDifferentOfStatic = HTMLElement

const Factory = contextAndProviderFactory({
  hookApi: function useTrackChildren(props: {
    scroll$: Subject<ElementWithPositionDifferentOfStatic>
    containerMounted$: Subject<ElementWithPositionDifferentOfStatic>
    containerResized$: Subject<ElementWithPositionDifferentOfStatic>
  }) {
    type ID = string

    const [invisibleItems, setInvisibleItems] = React.useState<{
      inTheRight: string[]
      inTheLeft: string[]
    }>({ inTheRight: [], inTheLeft: [] })

    const [children, setChildren] = React.useState<
      Record<ID, HTMLDivElement | null>
    >({})

    // console.log(children)
    const resolveVisibility = React.useCallback(
      (container: HTMLElement) => {
        const itemsInTheLeft = (() => {
          if (!container) return []

          return Object.keys(children).filter((id) => {
            const leftPosition =
              (children[id]?.offsetLeft || 0) - (container?.scrollLeft || 0)

            return leftPosition < 0
          })
        })()

        const itemsInTheRight = (() => {
          if (!container) return []

          return Object.keys(children).filter((id) => {
            const leftPosition =
              (children[id]?.offsetLeft || 0) - (container?.scrollLeft || 0)

            const diff =
              leftPosition -
              (container.offsetWidth - (children[id]?.offsetWidth || 0))

            // adding an error margin to avoid problem with rounding of floating point numbers
            return diff >= 2
          })
        })()

        setInvisibleItems({
          inTheLeft: itemsInTheLeft,
          inTheRight: itemsInTheRight,
        })
      },
      [children]
    )

    useSubscription(props.scroll$, resolveVisibility)
    useSubscription(props.containerMounted$, resolveVisibility)
    useSubscription(props.containerResized$, resolveVisibility)
    // useSubscription(props.containerMounted$, setContainer)
    const setChildRef = React.useCallback(
      (id: string, ref: HTMLDivElement | null) => {
        // console.log("id", id, "ref", ref)
        if (!ref)
          return setChildren((a) => {
            const { [id]: _, ...rest } = a
            return rest
          })

        setChildren((a) => ({ ...a, [id]: ref }))
      },
      []
    )

    const deleteChildRef = React.useCallback((id: string) => {
      setChildren((a) => {
        const { [id]: _, ...rest } = a
        return rest
      })
    }, [])

    return useMemoAPI({
      setChild: setChildRef,
      deleteChild: deleteChildRef,
      invisibleInTheLeft: invisibleItems.inTheLeft,
      invisibleInTheRight: invisibleItems.inTheRight,
    })
  },
})
const TrackChildrenContext = Factory.Context
const TrackChildrenProvider = Factory.Provider

function TrackerChild(props: { id: string; children: React.ReactNode }) {
  const api = React.useContext(TrackChildrenContext)

  return <div ref={(e) => api.setChild(props.id, e)}>{props.children}</div>
}

export const ScrollChildTracker = {
  Child: TrackerChild,
  Provider: TrackChildrenProvider,
  Context: TrackChildrenContext,
}
