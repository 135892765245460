import { gql } from "@apollo/client";
import _ from "lodash"
import React, { useContext } from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { RoomMemberInput, RoomMemberType, UpdateType } from "lib/graphql/types"
import {
  RoomMembersDocument,
  RoomMemberFragment,
  useUpdateRoomMembersMutation,
} from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import cachedIDGetters from "lib/getCacheID/getCacheID"
import { getUpdateCache } from "lib/getUpdateCache"

export default function useUpdateRoomMembers(props: { roomID: string }) {
  const { workspaceID } = useActiveResources()

  const [mutation] = useUpdateRoomMembersMutation()

  const updateFn = React.useCallback(
    (updateType: UpdateType) => {
      return async function updateMember(member: RoomMemberFragment) {
        if (!props.roomID) return
        if (!workspaceID) return
        if (!member.__typename || !member.id) return

        const type = {
          Account: RoomMemberType.Account,
          User: RoomMemberType.User,
          ContactMember: RoomMemberType.ContactMember,
        }

        const memberInput: RoomMemberInput = {
          type: type[member.__typename],
          id: member.id,
        }

        await mutation({
          variables: {
            roomID: props.roomID || "",
            workspaceID,
            members: [memberInput],
            updateType,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateRoomMembers: {
              __typename: "BasicResponse",
              success: true,
            },
          },
          refetchQueries: [
            {
              query: RoomMembersDocument,
              variables: { workspaceID, roomID: props.roomID || "" },
            },
          ],
          update(dataProxy, { data }) {
            if (!data?.updateRoomMembers?.success) return

            getUpdateCache(dataProxy).updateRoomMembers({
              updateType,
              __typename: "UpdatedRoomMembers",
              members: [member],
              roomID: props.roomID,
              workspaceID,
            })
          },
          // update(dataProxy, { data }) {
          //   if (!data?.updateRoomMembers?.success) return

          //   const queryResult = dataProxy.readQuery<
          //     RoomMembersQuery,
          //     RoomMembersQueryVariables
          //   >({
          //     query: RoomMembersDocument,
          //     variables: { roomID: props.roomID || "", workspaceID },
          //   })

          //   const old = queryResult?.roomMembers?.list || []

          //   const __typenames = {
          //     [RoomMemberType.Account]: "Account",
          //     [RoomMemberType.User]: "User",
          //     [RoomMemberType.ContactMember]: "ContactMember",
          //   }

          //   const newList = _.uniqBy(
          //     [
          //       ...old,
          //       {
          //         __typename: __typenames[memberInput.type],
          //         id: memberInput.id,
          //       },

          //       ...(() => {
          //         const addingAccount =
          //           memberInput.type === RoomMemberType.Account

          //         if (!addingAccount) return []

          //         const accountID = member.id

          //         // return (allowedMembers?.list || []).filter(
          //         // return ([]).filter(
          //         //   (b): b is UserFragment => {
          //         //     if (b.__typename !== "User") return false

          //         //     return b?.account?.id === accountID
          //         //   }
          //         // )

          //         return []
          //       })(),
          //     ],
          //     (a) => a.id + a.__typename
          //   ).filter((a) => {
          //     if (updateType === UpdateType.Remove) {
          //       if (__typenames[memberInput.type] === a.__typename) {
          //         return a.id !== member.id
          //       }

          //       if (a.__typename === "User") {
          //         const accountID = memberInput.id
          //         const userAccountID = (a as UserFragment)?.account?.id
          //         return userAccountID !== accountID
          //       }
          //     }

          //     return true
          //   })

          //   dataProxy.writeFragment({
          //     data: {
          //       __typename: "RoomMembersList",
          //       roomID: props.roomID || "",
          //       list: newList.map((a) => ({
          //         id: a.id,
          //         __typename: a.__typename,
          //       })),
          //     },
          //     id: getCacheID.RoomMembersList({ roomID: props.roomID || "" }),
          //     fragment: gql`
          //       fragment MemberList on RoomMembersList {
          //         __typename
          //         roomID
          //         list {
          //           ... on Account {
          //             __typename
          //             id
          //           }
          //           ... on User {
          //             __typename
          //             id
          //           }
          //         }
          //       }
          //     `,
          //   })
          // },
        })
      }
    },
    [props.roomID, workspaceID, mutation]
  )

  const addMember = React.useCallback(updateFn(UpdateType.Add), [updateFn])
  const removeMember = React.useCallback(updateFn(UpdateType.Remove), [
    updateFn,
  ])

  return useMemoAPI({
    addMember,
    removeMember,
  })
}
