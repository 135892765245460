import { Icon, makeStyles, Paper } from "@material-ui/core"
import clsx from "clsx"
import TextField, { TextFieldProps } from "components/TextField/TextField"
import useInputAutoFocus from "hooks/useInputAutoFocus"
import { StyledProps } from "types/type"

export function GooglSearch(
  props: StyledProps & {
    TextFieldProps?: Pick<
      TextFieldProps,
      "onChange" | "value" | "InputProps" | "inputProps"
    >
    glassAdornmentOnRight?: boolean
  }
) {
  const c = useStyles()

  const autoFocus = useInputAutoFocus()

  const glassAdornment = <Icon className={c.searchIcon}>search</Icon>

  return (
    <Paper
      className={clsx(c.root, props.className, "google-search-bar")}
      style={props.style}
    >
      <TextField
        typographyVariant="h6"
        {...props.TextFieldProps}
        InputProps={{
          ...(props.glassAdornmentOnRight
            ? { endAdornment: glassAdornment }
            : { startAdornment: glassAdornment }),
          disableUnderline: true,
          ...props.TextFieldProps?.InputProps,
        }}
        inputProps={{
          ref: autoFocus.inputRef,
          ...props.TextFieldProps?.inputProps,
        }}
      ></TextField>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "--ntp-realbox-border-radius": "calc(0.5 * var(--ntp-realbox-height))",
      "--ntp-realbox-height": "44px",
      "--ntp-realbox-shadow": "0 1px 6px 0 var(--color-realbox-shadow)",
      "--color-realbox-shadow": "#0000001a",
      borderRadius: "var(--ntp-realbox-border-radius)",
      boxShadow: "var(--ntp-realbox-shadow) !important",
      fontSize: "16px",
      height: "var(--ntp-realbox-height)",
      alignItems: "center",
      display: "flex",
    },
    searchIcon: {
      marginLeft: 10,
      marginRight: 10,
    },
  }
})
