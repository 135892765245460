import useActiveResources from "hooks/useActiveResources"
import { excludeNullable } from "lib/excludeNullable"
import {
  PageBoardItemTypesCountQueryVariables,
  usePageBoardItemTypesCountQuery,
} from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"

export function useAvailableTypes(args: {
  requestedTypes?: PageBoardItemType[] | null
}) {
  const w = useActiveResources().workspaceID

  const variables: PageBoardItemTypesCountQueryVariables = React.useMemo(
    () => ({
      workspaceID: w,
      types: args.requestedTypes || [],
    }),
    [JSON.stringify(args.requestedTypes), w]
  )

  const query = usePageBoardItemTypesCountQuery({
    skip: variables.types.length === 0 || !variables.workspaceID,
    fetchPolicy: "cache-and-network",
    variables,
  })

  const typesWithoutCountZero = React.useMemo(
    () =>
      query.data?.pageBoardItemTypesCount
        ?.map((a) => !!a.count && a.type)
        ?.filter(excludeNullable),
    [query.data?.pageBoardItemTypesCount]
  )

  return React.useMemo(() => {
    if (!typesWithoutCountZero?.length) return args.requestedTypes || []
    return typesWithoutCountZero?.filter(excludeNullable) || []
  }, [
    JSON.stringify(typesWithoutCountZero),
    JSON.stringify(args.requestedTypes),
  ])
}
