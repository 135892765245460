import React from "react"
import SectionsRoutesContext from "./contexts/SectionsRoutesContext"
import useSectionsRoutesAPI from "./hooks/useSectionsRoutesAPI"

const SectionsRoutes = {
  Provider(props: { children: React.ReactNode }) {
    const api = useSectionsRoutesAPI()

    return (
      <SectionsRoutesContext.Provider value={api}>
        {props.children}
      </SectionsRoutesContext.Provider>
    )
  },
  Context: SectionsRoutesContext,
}

export default SectionsRoutes

export { SECTIONS_IDS } from "./constants"
