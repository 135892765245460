import {
  useMessageIndexQuery,
  NewPageBoardItem_MessagePageBoardItem_Fragment,
} from "lib/graphql/operations"
import { ApolloError } from "@apollo/client"
import useActiveResources from "hooks/useActiveResources"
import RoomPageBoardItem from "../RoomPageBoardItem/RoomPageBoardItem"
import { RoomPageBoardItemProps } from "../RoomPageBoardItem/types"

export type MessagePageBoardItemProps = {
  entry: NewPageBoardItem_MessagePageBoardItem_Fragment
  onClose: () => any
  onError: (error: ApolloError) => any
  initialParams?: {
    RoomPageBoardItemProps?: {
      initialParams?: RoomPageBoardItemProps["initialParams"]
    }
  }
}

export function MessagePageBoardItem(props: MessagePageBoardItemProps) {
  const r = useActiveResources()

  const a = useMessageIndexQuery({
    skip: !(props.entry.message?.id || ""),
    variables: {
      workspaceID: r.workspaceID,
      messageID: props.entry.message?.id || "",
    },
  })

  const messageIndex = a.data?.messageIndex

  if (typeof messageIndex !== "number") return null

  return (
    <RoomPageBoardItem.Provider
      identifier={{ roomID: props.entry.message?.roomID || "" }}
      onClose={props.onClose}
      initialTopMostItemIndex={messageIndex}
      initialParams={props.initialParams?.RoomPageBoardItemProps?.initialParams}
    >
      <RoomPageBoardItem.Root></RoomPageBoardItem.Root>
    </RoomPageBoardItem.Provider>
  )
}
