import { makeStyles, Paper } from "@material-ui/core"
import clsx from "clsx"
import Dropzone from "components/Dropzone/Dropzone"
import React, { useContext, useEffect } from "react"
import { Body } from "../Body/Body"
import { UploadDocumentsContext } from "../ContextAndProvider"

const { IDLE } = Dropzone.states

export default function UploadSteps({
  className = "",
}: {
  className?: string
}) {
  const c = useStyles({})

  const api = React.useContext(UploadDocumentsContext)

  const { activeStep, goToStepWithID } = api.stepsController
  const { enableFileSelection, Header } = activeStep
  const { dropzoneState } = React.useContext(Dropzone.Context)

  useEffect(() => {
    if (dropzoneState !== IDLE) return goToStepWithID("dropzone-area")
  }, [dropzoneState])

  return (
    <div className={clsx(c.root, className)}>
      <Paper className={clsx("upload-steps-content", c.paper)} elevation={2}>
        {Header && <Header></Header>}
        <Body className="body" enableFileSelection={enableFileSelection} />
      </Paper>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      height: "100%",
      // alignItems
      flexDirection: "column",
      "&>*:first-child": {
        flex: 1,
      },
    },
    paper: {
      // padding: theme.spacing(2, 3),
      display: "flex",
      flexDirection: "column",
      height: "100%",

      "&>.body": {
        flex: 1,
        overflow: "hidden",
      },
    },
  }
})
