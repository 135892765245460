import _, { memoize } from "lodash"
import moment from "moment"
import SelectWithAutocomplete from "components/SelectWithAutocomplete/SelectWithAutocomplete"
import Case from "case"
import {
  find,
  defaultTo,
  entries,
  flow,
  groupBy,
  map,
  pickBy,
  reduce,
  values,
  filter,
} from "lodash/fp"
import { makeStyles } from "@material-ui/core"
import useRelatedCollectionsItems from "hooks/useRelatedCollectionsItems/useRelatedCollectionsItems"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React, { useCallback, useMemo } from "react"
import sortBy from "util/sortBy"
import useCollectionsTranslations from "hooks/useCollectionsTranslations/useCollectionsTranslations"
import { useInputController } from "hooks/useInputController"
import useMemoAPI from "hooks/useMemoAPI"

export default function useDocumentsFilterProviderValue({
  mainCollectionID,
  relatedCollectionsIDs: propsRelatedCollectionsIDs,
  onChangeRelatedCollectionsIDs: propsOnChangeRelatedCollectionsIDs,
}) {
  const { relatedCollectionsItems: collections } = useRelatedCollectionsItems({
    collectionID: mainCollectionID,
  })

  const {
    inputValue: relatedCollectionsIDs,
    setInputValue: onChangeRelatedCollectionsIDs,
  } = useInputController({
    value: propsRelatedCollectionsIDs,
    onChange: propsOnChangeRelatedCollectionsIDs,
  })

  const collectionsTranslations = useCollectionsTranslations()

  const c = useStyles()

  const t = useI18n()

  const {
    inputs,
    idsSelected,
    unselectCollection,
    getTypeCollections,
    selectCollection,
  } = (function inputsAndIDsSelected() {
    if (!_.get(collections, "length"))
      return { inputs: null, idsSelected: null }

    const collectionsByType = groupBy("type")(collections)

    const typeEntries = flow(
      entries,
      filter(([type, collections]) => {
        return type === "WORKFLOW" || (collections || []).length > 1
      }),
      defaultTo([])
    )(collectionsByType)

    const selectedByTypes = flow(
      reduce((acc, [type, collections]) => {
        if (!relatedCollectionsIDs) return acc

        const collectionSelected = find((c) => {
          return relatedCollectionsIDs.includes(c.id)
        })(collections)

        return { ...acc, [type]: collectionSelected }
      }, {}),

      pickBy((v) => typeof v !== "undefined")
    )(typeEntries)

    const idsSelected = flow(values, map("id"))(selectedByTypes)
    function unselectCollection(selectedID) {
      const newIDs = idsSelected.filter((id) => id !== selectedID)

      return onChangeRelatedCollectionsIDs(newIDs)
    }
    function selectCollection(collection) {
      if (!collection?.type) return

      const newSelection = {
        ...selectedByTypes,
        [collection?.type]: collection,
      }

      const newRelatedIDs = flow(values, map("id"))(newSelection)

      if (typeof onChangeRelatedCollectionsIDs !== "function") return

      onChangeRelatedCollectionsIDs(newRelatedIDs)
    }

    return {
      idsSelected,
      unselectCollection,
      selectCollection,
      getTypeCollections(type) {
        return collectionsByType?.[type] || null
      },
      inputs: flow(
        map(([type, collections]) => {
          const options = flow(
            function sortItems(collections) {
              if (typeof sortCollections[type] !== "function")
                return collections

              return [...(collections || [])].sort(sortCollections[type])
            },
            map((c) => {
              return {
                label: (function label() {
                  if (typeof formatLabel[type] !== "function")
                    return c.translatedTitle || c.title
                  return formatLabel[type](c)
                })(),
                value: c.id,
                data: c,
                ...(function metaData() {
                  if (typeof getMetadata[type] !== "function") return {}
                  return { metadata: getMetadata[type](c) }
                })(),
              }
            })
          )(collections)

          return [type, options]
        }),

        map(([type, options]) => {
          const selectedCollection = _.get(selectedByTypes, type)

          const optionAll = selectedCollection
            ? { value: "all", label: t["all"] }
            : null

          const selectedID = _.get(selectedCollection, "id")

          const cleanField = unselectCollection.bind(null, selectedID)

          return (
            <SelectWithAutoCompleteWithMemoizedOptions
              key={type}
              className={c.autoComplete}
              options={[optionAll, ...options].filter(Boolean)}
              placeholder={(function placeholder() {
                const translation = collectionsTranslations.translate(type)
                const value = Case.capital(translation || Case.capital(type))
                return value
              })()}
              onChangeTextField={(value) => {
                if (!value) {
                  cleanField()
                }
              }}
              textField={getLabel(selectedCollection)}
              onSelect={(option) => {
                if (optionAll && option.value === optionAll.value)
                  return cleanField()

                selectCollection(option.data)
              }}
            ></SelectWithAutoCompleteWithMemoizedOptions>
          )
        })
      )(typeEntries),
    }
  })()

  const getCollection = useCallback(
    function getCollection(id) {
      const collectionsByID = _.keyBy(collections, "id")
      return collectionsByID?.[id]
    },
    [collections?.map((i) => i.id).join("-")]
  )

  const selectedCollections = useMemo(
    () => relatedCollectionsIDs?.map(getCollection),
    [getCollection, relatedCollectionsIDs]
  )

  const api = {
    inputs,
    selectedRelatedCollectionsIDs: idsSelected,
    selectedCollections,
    unselectCollection,
    selectCollection,
    getLabel,
    getTypeCollections,
  }

  return useMemoAPI(api)
}

const useStyles = makeStyles((theme) => {
  return {
    card: {
      // padding: theme.spacing(2),
    },
    autoComplete: {
      marginBottom: theme.spacing(2),
    },
  }
})

const formatLabel = {
  Monat(collection) {
    return (
      moment(collection.title, "YYYYMM").format("MMM[/]YY") || ""
    ).replace(".", "")
  },
}

const sortCollections = {
  Monat: sortBy({ order: "desc", property: "title" }),
}

function deUmlaut(value) {
  value = value.toLowerCase()
  value = value.replace(/ä/g, "a")
  value = value.replace(/ö/g, "o")
  value = value.replace(/ü/g, "u")
  value = value.replace(/ß/g, "ss")
  value = value.replace(/ /g, "-")
  value = value.replace(/\./g, "")
  value = value.replace(/,/g, "")
  value = value.replace(/\(/g, "")
  value = value.replace(/\)/g, "")
  return value
}

const getMetadata = {
  Monat(collection) {
    return {
      title: collection.title,
      value: deUmlaut(formatLabel.Monat(collection)),
    }
  },
}

function SelectWithAutoCompleteWithMemoizedOptions(props) {
  const memoizedOptions = useMemo(() => {
    return props.options
  }, [JSON.stringify(props.options)])

  return (
    <SelectWithAutocomplete
      {..._.omit(props, ["options"])}
      options={memoizedOptions}
    ></SelectWithAutocomplete>
  )
}

function getLabel(collection) {
  if (!collection) return ""

  if (typeof formatLabel[collection.type] === "function")
    return formatLabel[collection.type](collection)

  return _.get(collection, "translatedTitle", "")
}
