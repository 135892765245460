import requestFrame from "./requestFrame"

export default function invertMouseWheelScroll(target: HTMLElement) {
  if (!target) return

  target.addEventListener("wheel", scrolled, { passive: false })

  function scrolled(e: WheelEvent) {
    e.preventDefault() // disable default scrolling

    const delta = e.deltaY

    let pos = target.scrollTop

    pos += -delta * 1
    pos = Math.max(0, Math.min(pos, target.scrollHeight - target.clientHeight)) // limit scrolling

    requestFrame(() => (target.scrollTop = pos))
  }
}
