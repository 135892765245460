import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { IconProps } from "components/Icon/Icon"
import PurpleBadge, {
  PurpleBadgeProps,
} from "components/PurpleBadge/PurpleBadge"
// import { HeaderIconButton } from "components/PageBoard/components/PageBoardItem/components/HeaderIconButton"
import ToggleableMenu, {
  ToggleableMenuItem,
} from "components/ToggleableMenu/ToggleableMenu"
import React from "react"
import { HeaderIconButton } from "../HeaderIconButton"

export type Action =
  | {
      icon: IconProps["name"]
      onClick: () => any
      text: React.ReactNode
      BadgeProps?: PurpleBadgeProps
    }
  | { Component: React.FC<{ variant: "icon-button" | "menu-item" }> }

export function ControlButtons(props: {
  collapsed: boolean
  actions: Action[]
  renderCollapsedSettings?(props: {
    menuItems: ToggleableMenuItem[]
  }): React.ReactNode
}) {
  const c = useStyles()

  const menuItems = React.useMemo(() => {
    if (!props.collapsed) return [] as ToggleableMenuItem[]

    const a: ToggleableMenuItem[] = props.actions.map((a) => {
      if ("Component" in a)
        return {
          component: a?.Component ? (
            <a.Component variant="menu-item"></a.Component>
          ) : null,
        }

      return {
        component: <>{a.text}</>,
        onClick: a.onClick,
      }
    })

    return a
  }, [props.collapsed, props.actions])

  return (
    <div className={clsx(c.root, "control-buttons")}>
      {(props.collapsed ? [] : props.actions).map((b, index) => {
        if ("Component" in b)
          return <b.Component variant="icon-button"></b.Component>

        const c = (
          <HeaderIconButton
            name={b.icon as IconProps["name"]}
            onClick={b.onClick}
          ></HeaderIconButton>
        )

        if ("BadgeProps" in b) {
          return (
            <PurpleBadge {...(b.BadgeProps || {})}>
              <div>{c}</div>
            </PurpleBadge>
          )
        }

        return c
      })}

      {(() => {
        if (props.renderCollapsedSettings)
          return props.renderCollapsedSettings({ menuItems })

        if (!props.collapsed) return null

        return (
          <ToggleableMenu
            menuItems={menuItems}
            closeOnSelect
            IconButtonProps={{
              size: "small",
            }}
          ></ToggleableMenu>
        )
      })()}
      {}
    </div>
  )
}
const useStyles = makeStyles(() => ({
  root: {
    "&>*:not(:last-child)": {
      marginRight: 6,
    },
  },
}))
