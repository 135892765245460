import { useApolloClient, gql } from "@apollo/client";
import { ID, RoomQueryVariables } from "types/type"
import _ from "lodash"
import React from "react"
import { ReactToKanbanCommentDocument } from "lib/graphql/operations"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"

export default function useReactToComment(props: RoomQueryVariables) {
  const client = useApolloClient()

  const { user: loggedUser } = useCurrentWorkspace()

  return React.useCallback(
    (cardID: ID, commentID: ID, emoji: string) => {
      const comment = client.readFragment({
        id: `KanbanComment:${commentID}`,
        fragment: gql`
          fragment KanbanComment on KanbanComment {
            id
            reactions {
              emoji
              user {
                id
              }
            }
          }
        `,
      })

      const newReaction = {
        __typename: "KanbanCommentReaction",
        emoji,
        user: {
          __typename: "User",
          id: loggedUser.id,
        },
      }

      const optimisticResponse = {
        __typename: "Mutation",
        reactToKanbanComment: {
          __typename: "KanbanComment",
          id: comment?.id,
          reactions: _.uniqBy(
            [newReaction, ...(comment?.reactions?.filter(Boolean) || [])],
            "user.id"
          ),
        },
      }

      client.mutate<typeof optimisticResponse>({
        mutation: ReactToKanbanCommentDocument,
        variables: {
          roomID: props.roomID,
          workspaceID: props.workspaceID,
          cardID,
          commentID,
          emoji,
        },
        optimisticResponse,
      })
    },
    [props.roomID, props.workspaceID, loggedUser?.id]
  )
}
