import edit$ from "components/Room/observers/edit$"
import { useCallback } from "react"
import { useLastAuthorMessage } from "./useLastAuthorMessage"

export function useEditLastMessage() {
  const lastMessage = useLastAuthorMessage()

  return useCallback(
    function editLastMessage() {
      const lastMessageID = (lastMessage || {}).id
      if (!lastMessageID) return

      edit$.next({ messageID: lastMessageID })
    },
    [(lastMessage || {}).id]
  )
}
