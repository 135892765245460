import clsx from "clsx"
import React from "react"
import { StyledProps } from "types/type"
import { IndexedItems } from "../../../IndexedItems/IndexedItems"
import { FilterSelector } from "./FilterSelector/FilterSelector"

export function DateAndTypesFilterRoot(props: StyledProps) {
  const indexedApi = React.useContext(IndexedItems.Context)

  return (
    <IndexedItems.TypesChips
      types={indexedApi.availableTypes}
      className={clsx("date-and-types-filter", props.className)}
      style={props.style}
    >
      {(p) => {
        return (
          <FilterSelector typesChipsProps={p.typesChipsProps}></FilterSelector>
        )
      }}
    </IndexedItems.TypesChips>
  )
}
