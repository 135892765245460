import _ from "lodash"
import { styled } from "@material-ui/core/styles"

const Div = styled("div")

export const FlexColumn = Div({
  display: "flex",
  flexDirection: "column"
})

export const FlexOne = Div({
  flex: 1
})

export const FlexSpaceBetween = Div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
})

export const PositionRelative = Div({
  position: "relative"
})
