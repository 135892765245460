import DocumentsDashboard from "components/DocumentsDashboard"
import React from "react"
import {
  workStatusesColors,
  workStatusTranslation,
} from "hooks/useActivityStatus/workStatuses"

export default function DocumentEntryStatus({
  entry: document = undefined,
  showColorDot = false,
  className = "",
  showColorExplanation = true,
}) {
  const statusValue = document?.workStatus
  const statusTranslated = workStatusTranslation[statusValue] || statusValue

  const statusColor = workStatusesColors[statusValue] || ""

  const colorMap = workStatusesColors
  const translationMap = workStatusTranslation

  return (
    <DocumentsDashboard.EntryStatus
      statusValue={statusValue}
      statusTranslated={statusTranslated}
      statusColor={statusColor}
      showColorDot={showColorDot}
      className={className}
      colorMap={colorMap}
      translationMap={translationMap}
      showColorExplanation={showColorExplanation}
    />
  )
}
