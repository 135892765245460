import { makeStyles } from "@material-ui/styles"
import React, { useContext } from "react"
import clsx from "clsx"
import { Button } from "@material-ui/core"
import MetaCardPaper from "components/MetaCardPaper/MetaCardPaper"
import Avatar from "components/Avatar/Avatar"

export default function RoomWallMetaCard(props: {
  className?: string
  avatarProps: React.ComponentProps<typeof Avatar>
  buttonProps?: React.ComponentProps<typeof Button>
}) {
  const c = useStyles({})

  return (
    <MetaCardPaper className={clsx("room-meta-card", c.root, props.className)}>
      <div className={c.wrapper}>
        <Button
          size="small"
          variant="outlined"
          disableElevation
          {...(props.buttonProps || {})}
        ></Button>
        <Avatar {...props.avatarProps}></Avatar>
      </div>
    </MetaCardPaper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: 250,
    },
    wrapper: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    },
  }
})
