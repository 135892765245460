import _ from "lodash"
import React from "react"
import RootContainer from "components/RootContainer/RootContainer"
import RoomTemplateContext from "../../contexts/RoomTemplateContext"
import { RoomContext } from "components/Room/contexts/room"

export default function TemplateProvider(props: {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}) {
  const api = React.useContext(RoomContext)

  return (
    <RoomTemplateContext.Provider value={api.template}>
      <RootContainer.Default
        className={props.className || ""}
        style={props.style}
      >
        {props.children}
      </RootContainer.Default>
    </RoomTemplateContext.Provider>
  )
}
