import React from "react"
import { switchMap, first, tap } from "rxjs/operators"
import useMemoAPI from "hooks/useMemoAPI"
import { Subject } from "rxjs"
import { useSubscription } from "observable-hooks"

export function useEvents$() {
  const send$ = React.useRef(new Subject<void>()).current

  const renderComment$ = React.useRef(new Subject<Element>()).current

  const jumpToComment$ = React.useRef(
    (() => {
      return send$.pipe(
        switchMap(() => renderComment$.pipe(tap(console.log), first()))
      )
    })()
  ).current

  useSubscription(
    jumpToComment$,
    React.useCallback((element: Element) => {
      console.log(element)
      element.scrollIntoView(false)
    }, [])
  )

  const events = useMemoAPI({
    send$,
    renderComment$,
  })

  return events
}
