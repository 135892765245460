import { useApolloClient } from "@apollo/client"
import {
  ContactPageBoardItemDocument,
  ContactPageBoardItemQuery,
  ContactPageBoardItemQueryVariables,
  DocumentPageBoardItemDocument,
  DocumentPageBoardItemQuery,
  DocumentPageBoardItemQueryVariables,
  KanbanCardPageBoardItemDocument,
  KanbanCardPageBoardItemQueryVariables,
  PartnerPageBoardItemDocument,
  PartnerPageBoardItemQuery,
  PartnerPageBoardItemQueryVariables,
  RoomPageBoardItemDocument,
  RoomPageBoardItemQuery,
  RoomPageBoardItemQueryVariables,
  ShopArticlePageBoardItemDocument,
  ShopArticlePageBoardItemQuery,
  ShopArticlePageBoardItemQueryVariables,
} from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import { KanbanCardPageBoardItemQuery } from "../../../../../lib/graphql/operations"

function fetchRoom<Params extends RoomPageBoardItemQueryVariables>(
  params: Params,
  client: ReturnType<typeof useApolloClient>
) {
  return client
    .query<RoomPageBoardItemQuery, Params>({
      query: RoomPageBoardItemDocument,
      variables: params,
      fetchPolicy: "network-only",
    })
    .then((a) => a.data?.roomPageBoardItem || null)
}
function fetchDocument<Params extends DocumentPageBoardItemQueryVariables>(
  params: Params,
  client: ReturnType<typeof useApolloClient>
) {
  return client
    .query<DocumentPageBoardItemQuery, Params>({
      query: DocumentPageBoardItemDocument,
      variables: params,
      fetchPolicy: "network-only",
    })
    .then((a) => a.data?.documentPageBoardItem || null)
}
function fetchPartner<Params extends PartnerPageBoardItemQueryVariables>(
  params: Params,
  client: ReturnType<typeof useApolloClient>
) {
  return client
    .query<PartnerPageBoardItemQuery, Params>({
      query: PartnerPageBoardItemDocument,
      variables: params,
      fetchPolicy: "network-only",
    })
    .then((a) => a.data?.partnerPageBoardItem || null)
}
function fetchContact<Params extends ContactPageBoardItemQueryVariables>(
  params: Params,
  client: ReturnType<typeof useApolloClient>
) {
  return client
    .query<ContactPageBoardItemQuery, Params>({
      query: ContactPageBoardItemDocument,
      variables: params,
      fetchPolicy: "network-only",
    })
    .then((a) => a.data?.contactPageBoardItem || null)
}

function fetchShopArticle<
  Params extends ShopArticlePageBoardItemQueryVariables
>(params: Params, client: ReturnType<typeof useApolloClient>) {
  return client
    .query<ShopArticlePageBoardItemQuery, Params>({
      query: ShopArticlePageBoardItemDocument,
      variables: params,
      fetchPolicy: "network-only",
    })
    .then((a) => a.data?.shopArticlePageBoardItem || null)
}

function fetchKanbanCard<
  // Params extends ShopArticlePageBoardItemQueryVariables
  Params extends KanbanCardPageBoardItemQueryVariables
>(params: Params, client: ReturnType<typeof useApolloClient>) {
  return client
    .query<KanbanCardPageBoardItemQuery, Params>({
      query: KanbanCardPageBoardItemDocument,
      variables: params,
      fetchPolicy: "network-only",
    })
    .then((a) => a.data?.kanbanCardPageBoardItem || null)
}

export const fetchPageBoardItem = {
  [PageBoardItemType.Room]: fetchRoom,
  [PageBoardItemType.Document]: fetchDocument,
  [PageBoardItemType.Partner]: fetchPartner,
  [PageBoardItemType.Contact]: fetchContact,
  [PageBoardItemType.ShopArticle]: fetchShopArticle,
  [PageBoardItemType.KanbanCard]: fetchKanbanCard,
}
