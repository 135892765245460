import { getLastDocumentsFieldProps } from "./lib/getLastDocumentsFieldProps"
import {
  FormCommonsContext,
  FormCommonsProvider,
} from "./components/FormCommonsContext"
import { FormFreezer } from "./components/FormFreezer"
import { ToggleDeletionButton } from "./ToggleDeletionButton"
import { ControlsButtons } from "./components/ControlButtons"
import { SaveButton } from "./components/SaveButton"
import { FormCommonsRelatedDocumentsChips as RelatedDocumentsChips } from "./components/FormCommonsRelatedDocumentsChips"
import React from "react"
import { AddressForm } from "components/PageBoard/components/PartnerForm/components/AddressForm"
import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"

export const FormCommons = {
  Context: FormCommonsContext,
  Provider: FormCommonsProvider,
  Consumer: FormCommonsContext.Consumer,
  SaveButton,
  Freezer: FormFreezer,
  ToggleDeletionButton,
  ControlsButtons,
  RelatedDocumentsChips,
  getLastDocumentsFieldProps,
}

export function getBusinessFieldProps(props: {
  form: React.ComponentProps<typeof AddressForm>["form"]
}) {
  const fieldProps: FieldsetIndentationProps = {
    icon: "business",
    children: <AddressForm form={props.form}></AddressForm>,
  }

  return fieldProps
}
