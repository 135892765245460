import React from "react"
import clsx from "clsx"
import TopBar from "./components/TopBar/TopBar"
import SearchMessagesContext from "./contexts/SearchMessagesContext"
import useSearchMessagesAPI from "./hooks/useSearchMessagesAPI"
import List from "./components/List/List"

const SearchMessages = {
  Context: SearchMessagesContext,
  Provider: function SearchMessagesProvider(props: {
    children: React.ReactNode
  }) {
    const api = useSearchMessagesAPI()

    return (
      <SearchMessagesContext.Provider value={api}>
        {props.children}
      </SearchMessagesContext.Provider>
    )
  },

  Root: function SearchMessagesRoot(props: {
    style?: React.CSSProperties
    className?: string
  }) {
    return (
      <div
        style={props.style}
        className={clsx("search-messages", props.className)}
      >
        <TopBar></TopBar>
        <List></List>
      </div>
    )
  },
}

export default SearchMessages
