import { useEffect } from "react"

/**
 * Expose value to window object. So, will get: window[key] === value
 * It is expected to be useful on debugging.
 **/
export default function useCopyToWindow(key, value) {
  useEffect(() => {
    window[key] = value
    return () => {
      delete window[key]
    }
  }, [value])
}
