import React, { FormEvent, useImperativeHandle } from "react"
import clsx from "clsx"
import Dialog from "components/Dialog/Dialog"
import { Typography, makeStyles, Button } from "@material-ui/core"
import Input from "components/Input/Input"
import { RoomType } from "lib/graphql/types"
import useCreateFreeRoom from "hooks/useCreateFreeRoom/useCreateFreeRoom"
import { useI18n } from "contexts/i18nContext/i18nContext"

export type AddModalAPI = { open: () => void }

export default React.forwardRef(AddRoomModal)

function AddRoomModal(
  props: {
    onCreateRoom?: (props: { roomID: string }) => void
  },
  ref: React.ForwardedRef<AddModalAPI>
) {
  const c = useStyles()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  React.useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
    }),
    [setIsOpen]
  )

  const closeModal = React.useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const [value, setValue] = React.useState<FormValue>({
    [InputNames.roomName]: "",
    [InputNames.roomType]: RoomType.Free,
  })

  React.useEffect(() => {
    if (!!isOpen) return
    setValue((a) => {
      return { ...a, [InputNames.roomName]: "" }
    })
  }, [isOpen])

  const onChange = React.useCallback(
    (e: { target: { value: string; name: string } }) => {
      setValue((value) => ({ ...value, [e.target.name]: e.target.value }))
    },
    []
  )

  const createRoomAPI = useCreateFreeRoom()

  const createRoom = React.useCallback(
    async (...args: Parameters<typeof createRoomAPI.createRoom>) => {
      const room = await createRoomAPI.createRoom(...args)

      if (!room?.id) return

      props.onCreateRoom?.call(undefined, { roomID: room?.id })

      closeModal()
    },
    [createRoomAPI.createRoom, closeModal]
  )

  const onSubmit = React.useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      await createRoom({
        name: value[InputNames.roomName],
        type: value[InputNames.roomType],
      })
    },
    [createRoom, value, props.onCreateRoom]
  )

  const formIsDisabled = createRoomAPI.isLoading

  const t = useI18n()

  return (
    <Dialog
      open={isOpen}
      className={clsx(c.root, "add-room-modal")}
      showLoadingProgress={createRoomAPI.isLoading}
      onClose={closeModal}
    >
      <form className={c.content} onSubmit={onSubmit}>
        <Typography variant="h6" className={c.title}>
          {t["create-room"]}
        </Typography>

        <Input.text
          label="Name"
          value={value[InputNames.roomName]}
          name={InputNames.roomName}
          fullWidth
          disabled={formIsDisabled}
          className={c.input}
          onChange={onChange}
        ></Input.text>

        {/* <Input.select
          label="Type"
          className={c.input}
          value={value[InputNames.roomType]}
          name={InputNames.roomType}
          fullWidth
          options={options}
          disabled={formIsDisabled}
          onChange={onChange}
        ></Input.select> */}

        <Button
          className="button"
          type="submit"
          variant="contained"
          color="primary"
          disabled={!value[InputNames.roomName] || formIsDisabled}
          size="small"
        >
          {t.create}
        </Button>
      </form>
    </Dialog>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingTop: 0,
    },

    content: {
      display: "flex",
      flexDirection: "column",

      "& button[type='submit']": {
        alignSelf: "flex-end",
      },
    },
    title: {
      marginBottom: theme.spacing(0.5),
    },
    input: {
      "&:not(:last-child)": {
        marginBottom: theme.spacing(2),
      },
    },
  }
})

interface FormValue {
  [InputNames.roomName]: string
  [InputNames.roomType]: RoomType.Free | RoomType.System
}

enum InputNames {
  roomName = "room-name",
  roomType = "room-type",
}

const options = [
  { label: "Free Topic", value: RoomType.Free },
  { label: "System", value: RoomType.System },
]
