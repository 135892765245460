import moment from "moment"
import { Month } from "../types/index"

export default function getMonth(props: {
  month?: Month | null
  day?: moment.MomentInput
}) {
  if (props.month) return props.month

  if (!!props.day && !!moment(props.day).isValid()) {
    const d = moment(props.day)

    const v: Month = {
      index: d.month(),
      year: d.year(),
    }

    return v
  }

  const currentDay = moment()

  const currentMonth: Month = {
    index: currentDay.month(),
    year: currentDay.year(),
  }

  return currentMonth
}
