import {
  ShopArticlesGrid,
  ShopArticlesGridProps,
} from "components/ShopArticlesGrid/ShopArticlesGrid"
import { ShopArticleFragment } from "lib/graphql/operations"
import React from "react"
import { StyledProps } from "types/type"
import { ArticleCard } from "../ArticleCard"

export type ArticleCardProps = (p: {
  article: ShopArticleFragment
  index: number
}) => Omit<React.ComponentProps<typeof ArticleCard>, "article">

export function ArticlesGrid(
  props: Omit<ShopArticlesGridProps, "renderArticle"> & {
    noVirtualization?: boolean
    renderArticle?: (p: {
      defaultComponent: React.ReactNode
      article: ShopArticleFragment
    }) => React.ReactNode
    ArticleCardProps?: ArticleCardProps
  } & StyledProps
) {
  const {
    renderArticle,
    ArticleCardProps,
    style,
    className,
    ...shopArticlesGridProps
  } = props

  const cartHeight = 290

  const render = React.useCallback(
    (p: { article: ShopArticleFragment; index: number }) => {
      const a = p.article
      const index = p.index

      return (() => {
        if (!a) return null

        const defaultComponent = (() => {
          const articleProps =
            props.ArticleCardProps?.({
              article: a,
              index,
            }) || {}
          const c = (
            <ArticleCard
              article={a}
              {...articleProps}
              {...(props.noVirtualization
                ? {
                    style: {
                      ...(articleProps.style || {}),
                      height: cartHeight,
                    },
                  }
                : {})}
            ></ArticleCard>
          )

          return c
        })()

        if (props.renderArticle)
          return props.renderArticle({
            defaultComponent,
            article: a,
          })

        return defaultComponent
      })()
    },
    [props.renderArticle, props.ArticleCardProps, props.noVirtualization]
  )

  return (
    <ShopArticlesGrid.Provider
      {...shopArticlesGridProps}
      renderArticle={render}
    >
      <ShopArticlesGrid.Context.Consumer>
        {(api) => {
          return (
            <ShopArticlesGrid.Grid
              noVirtualization={props.noVirtualization}
              style={{
                ...style,
                // marginLeft: -4,
                // marginRight: -4,

                ...(props.noVirtualization
                  ? { height: api.lines?.length * cartHeight }
                  : {}),
              }}
              className={className}
            ></ShopArticlesGrid.Grid>
          )
        }}
      </ShopArticlesGrid.Context.Consumer>
    </ShopArticlesGrid.Provider>
  )
}
