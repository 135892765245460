import { makeStyles, IconButton } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import PaperList from "components/PaperList"
import UseCallback from "components/UseCallback/UseCallback"
import BoardAndColumnSelectionAPI from "../../../../BoardAndColumnSelectionAPI"
import TwoPages from "components/TwoPages/TwoPages"
import { KanbanColumnFragment } from "lib/graphql/operations"
import ListItem, { ListItemProps } from "../ListItem/ListItem"
import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import { ListItemProps as PaperListItemProps } from "components/PaperList/types"

export default function SelectColumn(props: {
  className?: string
  style?: React.CSSProperties
}) {
  const c = useStyles()

  const api = React.useContext(BoardAndColumnSelectionAPI.Context)

  const items = api.columnSelection.columns

  return (
    <div className={clsx(c.root, props.className)} style={props.style}>
      <UseCallback callback={(index: number) => items[index].id} deps={[items]}>
        {(getItemKey) => {
          return (
            <PaperList.Provider
              items={items}
              ListItem={ColumnListItem}
              itemKey={getItemKey}
              classes={{ itemPaper: c.itemPaper }}
            >
              <PaperList.Root></PaperList.Root>
            </PaperList.Provider>
          )
        }}
      </UseCallback>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    itemPaper: {
      boxShadow: "none !important",
    },
    root: {
      display: "flex",
      height: "100%",

      "&>*": {
        // alignItems: "stretch",
        "&:first-child": {
          flex: 1,
          overflow: "auto",
        },
      },
    },
  }
})

const ColumnListItem: React.FunctionComponent<
  PaperListItemProps<KanbanColumnFragment>
> = function ColumnListItem(props) {
  const api = React.useContext(BoardAndColumnSelectionAPI.Context)
  const cardColumnID =
    React.useContext(KanbanCardPageBoardItemContext).card?.data?.columnID || ""

  const entry = props.entry

  const twoPagesAPI = React.useContext(TwoPages.Context)
  const onSaveChanges = React.useCallback<
    Exclude<ListItemProps["saveEditChanges"], undefined>
  >(
    (editedText: string) => {
      if (!entry) return
      api.columnSelection.changeColumnTitle({
        title: editedText,
        columnID: entry.id,
      })
    },
    [api.columnSelection.changeColumnTitle, entry?.id]
  )

  if (!entry) return null
  const onSelect = () => {
    api.columnSelection.setSelectedColumn(entry || null)
    twoPagesAPI.secondPage.closePage()
  }

  const isSelected = (() => {
    const selectedID = api.columnSelection.selectedColumn?.id

    if (selectedID) return selectedID === entry.id

    return cardColumnID === entry.id
  })()

  return (
    <ListItem
      name={entry?.title || ""}
      onSelect={onSelect}
      isSelected={isSelected}
      allowEdit
      saveEditChanges={onSaveChanges}
    ></ListItem>
  )
}
