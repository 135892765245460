import { IconButton } from "@material-ui/core"
import { Icon } from "components/Icon/Icon"
import { MetaCardWithAvatar } from "components/MetaCardWithAvatar/MetaCardWithAvatar"
import { HistoryThread } from "components/PageBoard/components/HistoryThread/HistoryThread"
import { PdfFirstPage } from "components/PdfFirstPage/PdfFirstPage"
import SelfContainedBall from "components/SelfContainedBall/SelfContainedBall"
import { Shop } from "components/Shop/Shop"
import { red } from "constants/colors"
import I18nContext from "contexts/i18nContext/i18nContext"
import { PageBoardItemAttachmentFragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import { ShopOrderArticlesInlinedChips } from "../../../../../../../ShopOrderArticlesInlinedChips"
import { ItemTemplate } from "../../../ItemTemplate/ItemTemplate"
import { SecondPage } from "../../../SecondPage/SecondPage"
import { SecondPageItemProps } from "../../../SecondPage/types"
import { ShoppingArticlePage } from "../Shopping/ShoppingArticlePage/ShoppingArticlePage"
import { prop } from "lodash/fp"
import { StyledProps } from "types/type"

export const renderAttachmentLib = {
  ShopArticlePageBoardItem(
    props: {
      attachment: PageBoardItemAttachmentFragment
      onClick?: () => void
    } & StyledProps
  ) {
    const item = props.attachment.item

    if (item.__typename !== "ShopArticlePageBoardItem") return null

    if (!item.article) return null

    return (
      <Shop.ArticleCard
        article={item.article}
        onClick={props.onClick}
        className={props.className}
        style={{ width: 240, ...(props.style || {}) }}
      />
    )
  },
  DocumentPageBoardItem(props: {
    attachment: PageBoardItemAttachmentFragment
    onClick?: () => void
  }) {
    const item = props.attachment.item

    if (item.__typename !== "DocumentPageBoardItem") return null

    const url = item.document?.pdfUrl

    if (!url) return null

    return <PdfFirstPage url={url} height={200}></PdfFirstPage>
  },
  ShopOrderPageBoardItem(props: {
    attachment: PageBoardItemAttachmentFragment
    onClick?: () => void
  }) {
    const item = props.attachment.item

    if (item.__typename !== "ShopOrderPageBoardItem") return null

    return (
      <ItemTemplate.Context.Consumer>
        {(template) => {
          return (
            <I18nContext.Consumer>
              {(t) => {
                return (
                  <MetaCardWithAvatar
                    style={{ maxWidth: 500 }}
                    overline={(() => {
                      const order = item.order

                      const i =
                        order?.companyOrderId || item.order?.id.slice(0, 6)

                      if (!i) return null

                      const id = t["orderTitle"].large(i)

                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>{id}</div>
                          <HistoryThread.Consumer>
                            {(historyThread) => {
                              if (historyThread.alreadyVisited(item))
                                return null

                              return (
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    template.twoPagesAPI.openHalfPage(
                                      function ShopOrderPage({ onClose }) {
                                        const props: SecondPageItemProps<PageBoardItemType.ShopOrder> =
                                          {
                                            initialParams: {
                                              showGoToMessageButton: false,
                                            },
                                            item: item,
                                            type: PageBoardItemType.ShopOrder,
                                          }

                                        return (
                                          <SecondPage
                                            {...props}
                                            onClose={onClose}
                                          ></SecondPage>
                                        )
                                      }
                                    )
                                  }}
                                >
                                  <Icon
                                    name="launch"
                                    style={{ fontSize: 18 }}
                                  ></Icon>
                                </IconButton>
                              )
                            }}
                          </HistoryThread.Consumer>
                        </div>
                      )
                    })()}
                    title={
                      <>
                        <ShopOrderArticlesInlinedChips
                          item={item}
                          onClickArticle={({ article }) => {
                            template.twoPagesAPI.openHalfPage(
                              function ShopArticlePage({ onClose }) {
                                return (
                                  <SecondPage
                                    onClose={onClose}
                                    getItemParams={{
                                      fetchItemProps: {
                                        type: PageBoardItemType.ShopArticle,
                                        params: { articleID: article.id },
                                      },
                                    }}
                                  >
                                    {({ api }) => {
                                      return (
                                        <ShoppingArticlePage
                                          onClose={onClose}
                                          getItemParams={{
                                            item: api.item,
                                          }}
                                        ></ShoppingArticlePage>
                                      )
                                    }}
                                  </SecondPage>
                                )

                                // return (
                                //   <OldSecondPage onClose={onClose}>
                                //     {() => {
                                //       return (
                                //         <ShoppingArticlePage
                                //           onClose={onClose}
                                //           getItemParams={{
                                //             fetchItemProps: {
                                //               type: PageBoardItemType.ShopArticle,
                                //               params: { articleID: article.id },
                                //             },
                                //           }}
                                //         ></ShoppingArticlePage>
                                //       )
                                //     }}
                                //   </OldSecondPage>
                                // )
                              }
                            )
                          }}
                        ></ShopOrderArticlesInlinedChips>
                      </>
                    }
                    avatar={
                      <SelfContainedBall
                        style={{
                          background: red[0],
                          textAlign: "center",
                        }}
                      >
                        <div style={{ padding: 5 }}>
                          <Icon
                            name="shopping_cart"
                            style={{ color: "white", marginTop: 4 }}
                          ></Icon>
                        </div>
                      </SelfContainedBall>
                      // null
                    }
                  ></MetaCardWithAvatar>
                )
              }}
            </I18nContext.Consumer>
          )
        }}
      </ItemTemplate.Context.Consumer>
    )
  },
}
export const hasRenderer = (
  typename: string
): typename is keyof typeof renderAttachmentLib => {
  return !!renderAttachmentLib[typename as keyof typeof renderAttachmentLib]
}
