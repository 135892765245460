import React from "react"
import moment from "moment"
import _ from "lodash"
import useActiveResources from "hooks/useActiveResources"
import {
  DocumentFragment,
  UpdateDocumentMutation,
  useUpdateDocumentMutation,
} from "lib/graphql/operations"
import { DocumentUpdateInput } from "lib/graphql/types"
import updateFragment from "lib/updateFragment/updateFragment"
import { Typename } from "types/type"
import { MutationUpdaterFn } from "@apollo/client"
import useMemoAPI from "hooks/useMemoAPI"

export default function useUpdateDocument(props?: { documentID?: string }) {
  const { workspaceID } = useActiveResources()

  const [mutation, { loading: updatingDocument }] = useUpdateDocumentMutation()

  const updateDocument = React.useCallback(
    async function updateDocument(
      update: DocumentUpdateInput,
      p?: {
        documentID: string
        onUpdateCache?: MutationUpdaterFn<UpdateDocumentMutation>
      }
    ) {
      const documentID = p?.documentID || props?.documentID || ""

      if (!documentID || !workspaceID || !update) return

      const updateDoc = await mutation({
        variables: {
          update: formatUpdatedData(update),
          workspaceID,
          documentID,
        },
        update(dataProxy, result) {
          const data = result?.data?.updateDocument

          if (!data) return

          p?.onUpdateCache?.call(undefined, dataProxy, result)

          updateFragment(
            {
              typename: Typename.Document,
              cacheIDParams: { id: documentID },
              updateFn: (a) => {
                const update = _.omit(data, "__typename")

                const updated: DocumentFragment = {
                  ...a,
                  ...update,
                }

                return updated
              },
            },
            dataProxy
          )
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateDocument: {
            __typename: "DocumentUpdate",
            ...update,
          },
        },
      })

      return updateDoc
    },

    [props?.documentID, mutation]
  )

  return useMemoAPI({
    updateDocument,
    isLoading: updatingDocument,
  })
}

function formatUpdatedData(updated: DocumentUpdateInput): DocumentUpdateInput {
  const format = (input: moment.MomentInput) =>
    moment(input).format("YYYY-MM-DD")

  return {
    ...updated,
    ...(updated.paymentDate
      ? { paymentDate: format(updated.paymentDate) }
      : {}),
    ...(updated.date ? { date: format(updated.date) } : {}),
    ...(updated.postDate ? { postDate: format(updated.postDate) } : {}),
  }
}
