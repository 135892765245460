import React from "react"
import _ from "lodash"
import clsx from "clsx"
import { Icon } from "components/Icon/Icon"
import ToggleableMenu, {
  ToggleableMenuItem,
  ToggleableMenuProps,
} from "components/ToggleableMenu/ToggleableMenu"
import PageBoardContext from "../../contexts/PageBoardContext"
import NotificationsItem from "./NotificationsItem"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import LightTooltip from "components/LightTooltip/LightTooltip"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { ReadAllNotificationsButton } from "./ReadAllNotificationsButton"

export default function Notifications(props: {
  className?: string
  style?: React.CSSProperties
}) {
  const api = React.useContext(PageBoardContext)
  const notifications = api.notifications

  const total = (notifications || []).reduce<number>((acc, i) => {
    return acc + (i.item?.notificationCount || 0)
  }, 0)

  const t = useI18n()

  const renderMenuItems = React.useCallback<
    Exclude<ToggleableMenuProps["renderMenuItems"], undefined>
  >(({ defaultComponent }) => {
    return (
      <>
        {defaultComponent}
        <ReadAllNotificationsButton></ReadAllNotificationsButton>
      </>
    )
  }, [])

  if (!notifications?.length) return null

  const b: ToggleableMenuProps["menuItems"] = _.uniqBy(
    notifications || [],
    (a) => a.id
  )
    .sort((a, b) => {
      if (a.timestamp > b.timestamp) return -1
      return 1
    })
    .map((h) => {
      const c: ToggleableMenuItem = {
        isSelected: false,

        component: (
          <NotificationsItem
            key={h.id}
            name={h.item.name}
            text={h.text}
            timestamp={h.timestamp}
            title={h.item.name}
            count={h.item.notificationCount}
          ></NotificationsItem>
        ),

        onClick() {
          api.selected.set(h.item)
        },
      }
      return c
    })

  return (
    <ToggleableMenu
      style={props.style}
      className={clsx(props.className, "toggleable-menu-notifications")}
      menuItems={b}
      renderMenuItems={renderMenuItems}
      icon={
        <LightTooltip title={t["alarm"]}>
          <div>
            <PurpleBadge badgeContent={total}>
              <Icon name="notifications_none"></Icon>
            </PurpleBadge>
          </div>
        </LightTooltip>
      }
    ></ToggleableMenu>
  )
}
