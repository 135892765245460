import React from "react"
import { KanbanActivity_KanbanRemoveImageActivity_Fragment } from "lib/graphql/operations"
import { useTheme } from "@material-ui/core"
import ActivitiesLines from "../ActivitiesLines/ActivitiesLines"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"

export default function RemoveImageActivity(props: {
  activity: KanbanActivity_KanbanRemoveImageActivity_Fragment
}) {
  const templatePageApi = React.useContext(ItemTemplate.Context)

  const theme = useTheme()

  return (
    <div>
      <ActivitiesLines
        action={(() => {
          return (
            <span>
              hat den Anhang{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: theme.palette.text.primary,
                }}
              >
                {(() => {
                  const filename = props.activity.imageName

                  if (!filename) return null

                  const max = templatePageApi.rootContainer.isSmall ? 12 : 40

                  if (filename.length > max)
                    return filename.slice(0, max) + "..."

                  return filename
                })()}
              </span>{" "}
              zu dieser Aufgabe gelöscht
            </span>
          )
        })()}
        icon="remove"
        dateTime={props.activity.when}
        user={props.activity.user}
      ></ActivitiesLines>
    </div>
  )
}
