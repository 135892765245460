import React from "react"
import { makeStyles } from "@material-ui/core"
import { alignmentUnits } from "styles/globalStyles"
import clsx from "clsx"

export default function FabsContainers({ fabs: children }) {
  const c = useStyles({})

  return <div className={clsx(c.root, "fabs-containers")}>{children}</div>
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "absolute",
      bottom: theme.spacing(alignmentUnits),
      right: theme.spacing(alignmentUnits),
      "&>*:not(:last-child)": {
        marginBottom: theme.spacing(1),
      },
    },
  }
})
