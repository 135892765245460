import _ from "lodash"
import React from "react"
import HeaderDescription from "../HeaderDescription/HeaderDescription"
import CollectionItemAvatar from "components/CollectionItemAvatar/CollectionItemAvatar"

function CollectionDescription({ collection, renderTitle }) {
  if (_.isEmpty(collection)) return null

  return (
    <HeaderDescription
      avatar={
        <CollectionItemAvatar type={collection.type}></CollectionItemAvatar>
      }
      renderFirstLine={renderTitle}
      firstLine={collection.title}
      secondLine={collection.subtitle}
    ></HeaderDescription>
  )
}

export default CollectionDescription
