import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { NavigationButton } from "components/AppBar/AppBar"
import Notifications from "components/PageBoard/components/Notifications/Notifications"
import Settings from "components/Settings/Settings"
import _ from "lodash"
import React from "react"
import { StyledProps } from "types/type"
import PageBoardContext from "../../../../contexts/PageBoardContext"
import History from "../../../History/History"
// import { IntervalPeriodModal } from "../DateIntervalSelector/components/IntervalPeriodModal"
import { IndexedItems } from "components/PageBoard/components/IndexedItems/IndexedItems"
import { ItemsList } from "components/PageBoard/components/IndexedItems/components/ItemsList/ItemsList"
import { ListHeader } from "./ListHeader"

export default function TopBar(props: StyledProps) {
  const api = React.useContext(PageBoardContext)

  const c = useStyles()

  const detailsPageIsOpen = !!api.selected.params

  const googleSearch = <IndexedItems.GoogleSearch></IndexedItems.GoogleSearch>

  const buttons = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {!detailsPageIsOpen && (
        <ItemsList.AddItemToListButton></ItemsList.AddItemToListButton>
      )}
      <History></History>
      <Notifications></Notifications>
      <Settings edge="end"></Settings>
    </div>
  )

  return (
    <div className={clsx(c.root, props.className)} style={props.style}>
      {(() => {
        if (api.template.rootContainer.width > 895) {
          return (
            <div style={{ position: "relative", height: 44 }}>
              <div
                style={{
                  position: "absolute",
                  left: "16px",
                  top: "50%",
                  transform: "translate(0,-50%)",
                }}
              >
                <NavigationButton></NavigationButton>
              </div>
              {!detailsPageIsOpen && (
                <div
                  className={clsx(api.classes.center)}
                  style={{
                    minWidth: "300px",
                    maxWidth: "500px",
                    width: "60%",
                    margin: "0 auto",
                  }}
                >
                  {googleSearch}
                </div>
              )}

              <div
                style={{
                  position: "absolute",
                  right: "16px",
                  top: "50%",
                  transform: "translate(0,-50%)",
                }}
              >
                {buttons}
              </div>
            </div>
          )
        }

        return (
          <div style={{ marginTop: -8 }}>
            <div
              style={{
                padding: "0px 16px",
                marginBottom: 8,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* <div>oi</div> */}

              <NavigationButton></NavigationButton>
              {buttons}
            </div>
            {!api.selected.params && (
              <div style={{ padding: "0px 16px" }}>{googleSearch}</div>
            )}
          </div>
        )
      })()}

      {!api.selected.params && (
        <ListHeader
          className={clsx(api.classes.mainWidth, api.classes.center)}
        ></ListHeader>
      )}
    </div>
  )
}

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",

      "&>*:not(:last-child)": {
        marginBottom: `${theme.spacing(1)}px !important`,
      },
    },

    body: {
      flex: 1,
      overflow: "hidden",
      marginBottom: theme.spacing(2),
      "&>*": {
        height: "100%",
      },
    },
  }
})
