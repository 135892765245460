import { ApolloProvider as ApolloHooksProvider } from "@apollo/client"
import React from "react"
import Authentication from "../Authentication/Authentication"
import useMemoAPI from "hooks/useMemoAPI"
import { ApolloErrorContext } from "../../../../contexts/apolloErrorContext/ApolloErrorContext"
import useApolloSetupAPI from "../../hooks/useApolloSetupAPI"
import ApolloSetupContext from "../../contexts/ApolloSetupContext"

export default function ApolloSetupProvider(props: {
  children: React.ReactNode
}) {
  const api = useApolloSetupAPI()

  const apolloErrorProviderValue = useMemoAPI({
    graphQLError$: api.graphQLError$,
  })

  return (
    <ApolloHooksProvider client={api.client}>
      <ApolloSetupContext.Provider value={api}>
        <Authentication.Controller>
          <ApolloErrorContext.Provider value={apolloErrorProviderValue}>
            {props.children}
          </ApolloErrorContext.Provider>
        </Authentication.Controller>
      </ApolloSetupContext.Provider>
    </ApolloHooksProvider>
  )
}
