import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { useKanbanCardImagesQuery } from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import useVariousImagesWithAuth from "hooks/useImageWithAuth/useVariousImagesWithAuth"
import _ from "lodash"
import useTrueFalse from "hooks/useTrueFalse"
import useModalUI from "./useModalUI"
import useChangeKanbanCardImages from "./useChangeKanbanCardImages"
import useFileUI from "./useFileUI"
import { LiveQueryInput, useLiveQuery } from "hooks/useLiveQuery"
import { KanbanCardImagesSubscriptionDocument } from "lib/graphql/operations"

export default function useImages(props: { cardID: string }) {
  const change = useChangeKanbanCardImages({
    cardID: props.cardID,
  })

  type Input = LiveQueryInput<typeof useKanbanCardImagesQuery>

  const variables = {
    cardID: props.cardID,
    workspaceID: useActiveResources().workspaceID,
  }

  const options = React.useMemo<Input["options"]>(() => {
    return { variables, fetchPolicy: "cache-and-network" }
  }, [...Object.values(variables)])

  const queryData = useLiveQuery({
    useQuery: useKanbanCardImagesQuery,
    options,
    subscriptionDocument: KanbanCardImagesSubscriptionDocument,
  })?.data?.kanbanCardImages

  // const queryData = useKanbanCardImagesQuery({
  //   variables: {
  //     cardID: props.cardID,
  //     workspaceID: useActiveResources().workspaceID,
  //   },
  //   fetchPolicy: "cache-and-network",
  // })?.data?.kanbanCardImages

  const data = React.useMemo(() => {
    if (!queryData) return null

    const ok: typeof queryData = {
      ...queryData,
      list: queryData?.list?.filter((a) => !!a.src),
    }

    return ok
  }, [queryData])

  const isUploading = useTrueFalse()

  const getObjectURL = useVariousImagesWithAuth({
    sources: _.uniq(
      [data?.cover?.src, ...(data?.list?.map((l) => l.src) || [])].filter(
        (a): a is string => !!a
      )
    ),
  }).getImageObjectURL

  const onCoverFiles = React.useCallback(
    (files: File[]) => {
      isUploading.setTrue()
      change({ cover: { upload: files[0] } })?.finally(isUploading.setFalse)
    },
    [change]
  )

  const onListFiles = React.useCallback(
    (files: File[]) => {
      isUploading.setTrue()
      change({ list: { add: files } })?.finally(isUploading.setFalse)
    },
    [change]
  )

  const removeCover = React.useCallback(() => {
    return change({ cover: null })
  }, [change])

  const setCover = React.useCallback(
    (imageID?: string | undefined | null) => {
      if (!imageID) return
      return change({ cover: { imageID } })
    },
    [change]
  )

  const coverFileUI = useFileUI({ onFiles: onCoverFiles })
  const listFileUI = useFileUI({ onFiles: onListFiles, multiple: true })

  const modal = useModalUI({ images: data })

  return useMemoAPI({
    data,
    coverFileUI,
    listFileUI,
    modal,
    getObjectURL,
    isUploading: isUploading.isTrue,
    removeCover,
    setCover,
  })
}
