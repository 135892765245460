import Scrollbars from "components/SimpleScrollbars/SimpleScrollbars"
import React from "react"
import { FlexOne } from "../Styled"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"

function FlexOneWithScrollbars({ children, className = "" }) {
  const c = useStyles({})
  return (
    <FlexOne className={clsx("flex-one-with-scrollbars", c.root, className)}>
      <Scrollbars>{children}</Scrollbars>
    </FlexOne>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
      overflow: "hidden",
    },
  }
})

export default FlexOneWithScrollbars
