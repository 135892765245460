import _ from "lodash"
import React, { useCallback, useState } from "react"
import MUIRichTextEditor from "mui-rte"
import { ContentState, convertFromHTML, convertToRaw } from "draft-js"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import useOnChange from "hooks/useOnChange"

export default function RichEditor({
  defaultValue = "",
  name = "",
  onChange: propsOnChange,
  controls = CONTROLS,
  className = "",
  disabled = false,
  value: propsValue = "",
}) {
  const t = useI18n()

  const c = useStyles({ disabled })

  const [value, setValue] = useState(
    isRichEditorValue(defaultValue)
      ? defaultValue
      : convertToRichEditor(defaultValue)
  )

  const editorWasReset = !propsValue

  useOnChange({
    value: editorWasReset,
    onChange() {
      if (editorWasReset) {
        const emptyState = ContentState.createFromText("")
        const resetValue = JSON.stringify(convertToRaw(emptyState))
        setValue(resetValue)
      }
    },
  })

  const onChange = useCallback((editorState) => {
    const contentState = convertToRaw(editorState.getCurrentContent())
    propsOnChange({ target: { value: JSON.stringify(contentState), name } })
    //why we don't call setValue(editorState): https://github.com/niuware/mui-rte/issues/52#issuecomment-552251461
  }, [])

  return (
    <div className={clsx(c.richEditor, "rich-editor", className)}>
      <MUIRichTextEditor
        toolbarButtonSize="small"
        label={t["type-something-here"]}
        controls={controls}
        value={value}
        readOnly={disabled}
        onChange={onChange}
        inlineToolbar={true}
      />
    </div>
  )
}

const CONTROLS = [
  "title",
  "bold",
  "italic",
  "underline",
  "strikethrough",
  "highlight",
  "undo",
  "redo",
  "numberList",
  "bulletList",
]

function convertToRichEditor(value) {
  const blocksFromHTML = convertFromHTML(value || "")

  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  )

  return JSON.stringify(convertToRaw(contentState))
}

function isRichEditorValue(value) {
  if (typeof value !== "string") return false

  try {
    return !!JSON.parse(value).blocks
  } catch (e) {
    return false
  }
}

const useStyles = makeStyles((theme) => {
  return {
    richEditor: {
      "& #mui-rte-toolbar": {},
      "& #mui-rte-editor": {
        padding: theme.spacing(0, 2),
      },
    },
  }
})
