import { makeStyles } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { ParagraphFragment } from "lib/graphql/operations"
import _ from "lodash"
import {
  OpenArticlePage,
  ShopArticleText,
} from "../ShopArticleText/ShopArticleText"

export function TableParagraph(props: {
  paragraph: ParagraphFragment
  openArticlePage?: OpenArticlePage
}) {
  const c = useStyles()

  if (props.paragraph.__typename !== "TableParagraph") return null

  const paragraph = props.paragraph

  return (
    <TableContainer component={Paper}>
      <Table className={c.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {paragraph.rows[0].cells.map((cell, index) => {
              return (
                <TableCell key={index} align={"left"}>
                  {/* {cell} */}

                  <ShopArticleText
                    text={cell}
                    openArticlePage={props.openArticlePage}
                  ></ShopArticleText>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.tail(paragraph.rows).map((row, index) => {
            return (
              <TableRow key={index}>
                {row.cells.map((cell, index) => {
                  return (
                    <TableCell key={index} align={"left"}>
                      <ShopArticleText
                        text={cell}
                        openArticlePage={props.openArticlePage}
                      ></ShopArticleText>
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})
