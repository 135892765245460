import _ from "lodash"
import { Room } from "lib/graphql/types"
import _f from "lodash/fp"
import lastSeen from "util/lastSeen"
import React from "react"
import { RoomContext } from "../../contexts/room"
import { makeStyles, Typography, IconButton, Icon } from "@material-ui/core"
import RoomAvatar from "components/Chat/components/RoomAvatar/RoomAvatar"
import isPrivateRoom from "util/isPrivateRoom"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import { useI18n } from "contexts/i18nContext/i18nContext"
import TopBar, { TopBarProps } from "components/TopBar/TopBar"
import CopyShareableLink from "components/Shareable/components/CopyShareableLink/CopyShareableLink"
import { ShareableType } from "types/type"
import SettingsMenu from "../SettingsMenu/SettingsMenu"
import MuteStatus from "components/MuteStatus/MuteStatus"
import { RoomFragment } from "lib/graphql/operations"
import EditRoomName from "../EditRoomName/EditRoomName"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import RoomTemplateContext from "../RoomTemplate/contexts/RoomTemplateContext"
import useTrueFalse from "hooks/useTrueFalse"

export default function RoomAppBar(roomAppBarProps: {
  render?: (props: TopBarProps) => React.ReactElement
  onClickBackButton?: () => void
}) {
  const { render, onClickBackButton } = roomAppBarProps
  const { room, isLoading } = React.useContext(RoomContext)
  // const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const isEditing = useTrueFalse(false)
  const { workspace: w } = useCurrentWorkspace()

  const editionIsAllowed = !room?.isReadOnly && room?.owner?.id === w?.user?.id

  const props: TopBarProps = {
    title: editionIsAllowed ? (
      <EditRoomName
        isEditing={isEditing}
        typographyVariant="h6"
        // isEditing={!!isEditing}
        // setIsEditing={setIsEditing}
      ></EditRoomName>
    ) : (
      room?.name
    ),

    showProgressBar: isLoading,
    renderLeft: ({ components, isMobileSearchActive }) =>
      !isMobileSearchActive ? (
        <AppBarLeft
          title={components.title}
          isEditing={isEditing}
          navigation={
            <IconButton onClick={onClickBackButton} edge="start">
              <Icon>arrow_back</Icon>
            </IconButton>
          }
        />
      ) : null,
    renderRight() {
      return (
        <React.Fragment>
          <DeviceObserver.Context.Consumer>
            {({ isDesktop }) => {
              if (!isDesktop) return null
              return <RoomSearchIconButton></RoomSearchIconButton>
            }}
          </DeviceObserver.Context.Consumer>
          <SettingsMenu className="room-settings-menu"></SettingsMenu>
        </React.Fragment>
      )
    },
  }

  if (typeof render === "function") return render(props)

  return <TopBar {...props} />
}

function AppBarLeft(props: {
  title: string | React.ReactNode
  navigation: React.ReactChild
  isEditing: boolean
}) {
  const { title, navigation } = props
  const c = useStyles({})
  const ctx = React.useContext(RoomContext)
  const room = ctx.room

  const isPrivate = isPrivateRoom(room)
  const { isMobile } = React.useContext(DeviceObserver.Context)

  const namesUsersTyping = (room?.usersTyping || [])

    .map((a) => a?.shortName)
    .filter((a): a is string => !!a)
  const t = useI18n()

  if (!room) return null

  return (
    <div className={c.roomAppBarLeft}>
      {navigation}
      {!isMobile && <RoomAvatar room={room} showNotification={false} />}
      <div className={c.contentDescription}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {title}
          {!props.isEditing && (
            <MuteStatus
              roomID={room?.id}
              style={{ marginLeft: 3 }}
            ></MuteStatus>
          )}

          {!props.isEditing && room?.id && (
            <CopyShareableLink
              shareableID={room?.id}
              shareableType={ShareableType.Room}
            ></CopyShareableLink>
          )}
        </div>
        {(function description() {
          let d = ""
          let color: "textPrimary" | "textSecondary" = "textPrimary"

          if (room?.isReadOnly) {
            d = "(read-only)"
            color = "textSecondary"
          } else {
            if (namesUsersTyping.length) {
              d = t.usersAreTyping({ usersNames: namesUsersTyping })
            } else if (!isPrivate) {
              const amount =
                ctx?.roomMembers?.filter(
                  (a) =>
                    a.__typename === "User" || a.__typename === "ContactMember"
                ).length || 0

              d = !amount ? "" : `${amount} member${amount > 1 ? "s" : ""}`
            } else {
              d =
                privateRoomDescription({
                  room,
                  showOnline: isMobile,
                }) || ""
            }
          }

          return (
            <Typography variant="body2" color={color} style={{ marginTop: -4 }}>
              {d}
            </Typography>
          )
        })()}
      </div>
    </div>
  )
}

function privateRoomDescription(props: {
  room: RoomFragment
  showOnline: boolean
}) {
  const { room, showOnline } = props

  const secondUserStatus = _f.get("secondUser.status")(room)

  if (secondUserStatus === "OFFLINE") {
    const date = _f.get("secondUser.statusUpdatedAt")(room)
    return date ? lastSeen(date) : null
  }

  if (showOnline && secondUserStatus === "ONLINE") {
    return "online"
  }

  return null
}

const useStyles = makeStyles((theme) => {
  return {
    contentDescription: {
      // maxWidth: "56%",
      flex: 1,
      overflow: "hidden",
    },
    roomAppBarLeft: {
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      "&>*:not(:last-child)": {
        marginRight: theme.spacing(2),
      },
    },
  }
})

function RoomSearchIconButton() {
  const { setSearchIsOpen } = React.useContext(RoomTemplateContext)

  return (
    <IconButton onClick={() => setSearchIsOpen(() => true)}>
      <Icon>search</Icon>
    </IconButton>
  )
}
