// import { ReactNode } from "react"

import { KanbanCardImages } from "components/Kanban/@types"

import { Scalars } from "lib/graphql/types"
import {
  KanbanBoardFragment,
  KanbanCardFragment,
} from "../lib/graphql/operations"

export type Period = { start: moment.Moment | null; end?: moment.Moment | null }

export type Json = {
  [key: string]: string | number | boolean | Date | Json | Json[]
}

export { ShareableType } from "lib/graphql/types"
export type { Account, Workspace } from "lib/graphql/types"

export type MakePropsOptionalAndNullable<T extends { [i: string]: any }> = {
  [K in keyof T]?: T[K] | null
}

export type StyledProps = { className?: string; style?: React.CSSProperties }

export type RequiredFields<
  T extends { [key: string]: any },
  Key extends keyof T
> = Omit<T, Key> & Required<Pick<T, Key>>

export type OpenKanbanCardPayload = { cardID: string }

export type ExcludeNullable<T extends any> = Exclude<
  T,
  null | undefined | false
>

//
/**
 * The `UnionOmit` type is used when you want to omit certain properties from each type in a union of types.
 *
 * The Omit utility type in TypeScript does not distribute over union types directly.
 * Its actual implementation is `Pick<T, Exclude<keyof T, K>>`, where `keyof` for a union will only return properties
 * that are common across all members of the union.
 * For example, for a union of two types with properties `{name, location}` and `{name, age}`, `keyof` would only return 'name'.
 *
 * When omitting 'location' from this union, only 'name' is left for Pick, which is not the intended result.
 * Hence, we cannot directly use the Omit type for our use case.
 *
 * The solution to this problem is using conditional types to process each member of the union separately.
 * `UnionOmit` achieves this by using a distributive conditional type, that is, `T extends unknown ? Omit<T, K> : never;`
 * It distributes the Omit operation over each member of the union type `T`, rather than treating `T` as a single type.
 *
 * The `UnionOmit` type takes two type parameters, `T` and `K`.
 * `T` is expected to be a union of types, and `K` is expected to be a union of `string | number | symbol`, which represents the keys to be omitted.
 *
 * Source: Comment generated with chatgpt from the comment https://github.com/microsoft/TypeScript/issues/39556#issuecomment-656925230
 */

export type UnionOmit<T, K extends string | number | symbol> = T extends unknown
  ? T extends any
    ? Omit<T, K>
    : never
  : never

export type Nullable<T> = T | null | undefined | false

export type ObjectWithID = { id: string }
export type ReactProps<TFunctionComponent extends (...args: any) => any> =
  Parameters<TFunctionComponent>[0]
export type ID = Scalars["ID"]
export type DocumentRoomIdentifier = { documentID: string }
export type CollectionRoomIdentifier = { collectionID: string }
export type PrivateRoomIdentifier = { userID: string }
export type OrdinaryRoomIdentifier = { roomID: string }

export type RoomIdentifier =
  | OrdinaryRoomIdentifier
  | DocumentRoomIdentifier
  | CollectionRoomIdentifier
  | PrivateRoomIdentifier

export interface Tab<Enum> {
  id?: Enum
  label: React.ReactNode
  component: React.ReactNode
}

export type Tabs<Enum extends string | number | symbol> =
  | Record<Enum, Tab<Enum>>
  | Array<Tab<Enum> & { id: Enum }>

///REMOVE
export enum CollectionItemType {
  VENDOR = "VENDOR",
  CONTACT = "CONTACT",
  CUSTOMER = "CUSTOMER",
  LEAD = "LEAD",
  DOCUMENT = "DOCUMENT",
}

///REMOVE
export interface CollectionItem {
  id?: string
  number?: string
  type?: string
  translatedTitle?: string
  count?: string
  title?: string
  subtitle?: string
  bilateralID?: string
  deleted?: string
}

///REMOVE
export interface MessageListItem {
  index: string
  roomID: string
  message: Message
}

///REMOVE
export interface Message {
  id?: string
  sentAt?: string
  editedAt?: string
  deletedAt?: string
  content?: string
  loading?: string
  repliedMessageListItem?: MessageListItem[]
  attachments?: [Attachment | null] | null
}

export type PartialNullable<T> = { [P in keyof T]?: T[P] | undefined | null }

export type DocumentList = PartialNullable<{
  id: string
  list: DocumentListItem[]
}>

export type DocumentListItem = Partial<{
  index: number
  document: ObjectWithID
  listID: string
}>

export declare namespace PaginatedDocuments {
  export interface Data {
    paginatedDocuments: DocumentList
  }
}

export interface Attachment {
  src: string
}

export type RoomQueryVariables = { roomID: ID; workspaceID: ID }

export interface User {
  id: ID
  name: string
  status?: "OFFLINE" | "ONLINE"
}

// Partial Graphql<>

type Graphql<Typename, Type> = Type & { __typename: Typename }

type GraphqlKanbanBoard = KanbanBoardFragment
type GraphqlKanbanCard = KanbanCardFragment
type GraphqlKanbanCardImages = Graphql<"KanbanCardImages", KanbanCardImages>

type KanbanUpdate =
  | GraphqlKanbanBoard
  | GraphqlKanbanCard
  | GraphqlKanbanCardImages
export interface RoomKanbanUpdate {
  workspaceID: ID
  roomID: ID
  boardNotificationsCount: number
  update: KanbanUpdate
}

export type Props<T extends React.FC<P>, P> = P

export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>
}[keyof T]

export enum Typename {
  DecoupledKanbanBoard = "DecoupledKanbanBoard",
  NewPageBoardItem = "NewPageBoardItem",
  PageBoardItemTypeCount = "PageBoardItemTypeCount",
  ShopArticle = "ShopArticle",
  KanbanColumn = "KanbanColumn",
  KanbanComment = "KanbanComment",
  CalendarEvent = "CalendarEvent",
  Room = "Room",
  User = "User",
  Workspace = "Workspace",
  GuestWorkspace = "GuestWorkspace",
  TypingOnRoom = "TypingOnRoom",
  DocumentListItem = "DocumentListItem",
  MessageListItem = "MessageListItem",
  RoomMessageList = "RoomMessageList",
  Document = "Document",
  CollectionItem = "CollectionItem",
  TranslatedWord = "TranslatedWord",
  Reaction = "Reaction",
  KanbanCardImages = "KanbanCardImages",
  KanbanCard = "KanbanCard",
  KanbanCommentList = "KanbanCommentList",
  Order = "Order",
  RoomMembersList = "RoomMembersList",
  RoomNotificationsCount = "RoomNotificationsCount",
  WorkspaceNotificationsCount = "WorkspaceNotificationsCount",
  WorkspaceSettings = "WorkspaceSettings",
  Activity = "Activity",
  RoomPageBoardItem = "RoomPageBoardItem",
}

export type ArrayElement<T> = T extends Array<infer A> ? A : never

export type Size = { height: number; width: number }
