import LinkButtonWithIcon from "components/LinkButtonWithIcon/LinkButtonWithIcon"
import RichText, { findWithRegex } from "components/RichText/RichText"
import { CompositeDecorator, ContentBlock } from "draft-js"
import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"

export type OpenArticlePage = (props: { sku: string }) => void

function useShopArticleTextApi(props: { openArticlePage?: OpenArticlePage }) {
  return useMemoAPI({
    openArticlePage: props.openArticlePage,
  })
}

const T = contextAndProviderFactory({
  hookApi: useShopArticleTextApi,
  contextName: "ShopArticleTextContext",
})

const ShopArticleTextContext = T.Context
const ShopArticleTextProvider = T.Provider

const nameWithSkuRegex = /\[([^\]]+)\]\(#(.+)\)/

function ProductNamePill(props: {
  children: React.ReactChildren
  decoratedText: string
}) {
  const [_, name = "", sku = ""] =
    nameWithSkuRegex.exec(props.decoratedText) || []

  if (!name || !sku) return props.children

  return (
    <ShopArticleTextContext.Consumer>
      {(t) => {
        return (
          <LinkButtonWithIcon
            onClick={() => t.openArticlePage?.({ sku })}
            iconName={undefined}
            description={name}
          ></LinkButtonWithIcon>
        )
      }}
    </ShopArticleTextContext.Consumer>
  )
}

export const productPillDecorator = new CompositeDecorator([
  {
    component: ProductNamePill,
    strategy: function urlStrategy(
      contentBlock: ContentBlock,
      callback: (start: number, end: number) => void
    ) {
      findWithRegex(nameWithSkuRegex, contentBlock, callback)
    },
  },
])

export function ShopArticleText(props: {
  text: string
  openArticlePage?: OpenArticlePage
}) {
  return (
    <ShopArticleTextProvider openArticlePage={props.openArticlePage}>
      <RichText
        EditorProps={{ readOnly: true }}
        value={props.text}
        decorator={productPillDecorator}
      ></RichText>
    </ShopArticleTextProvider>
  )
}
