import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  LexicalEditor,
  SerializedEditorState,
  SerializedLexicalNode,
} from "lexical"

export default function $setEditorValue(args: {
  value: string | SerializedEditorState<SerializedLexicalNode>
  editor: LexicalEditor
  updateFn?: (() => void) | undefined
}) {
  try {
    const editorState = args.editor.parseEditorState(args.value, args.updateFn)
    return args.editor.setEditorState(editorState)
  } catch (e) {
    if (!(e instanceof SyntaxError)) {
      throw e
    }

    if (typeof args.value === "string") {
      const nodes = args.value.split("\n").map((a) => {
        const paragraph = $createParagraphNode().append($createTextNode(a))

        return paragraph
      })

      const root = $getRoot()

      root.append(...nodes)
    }
  }
}
