import React, { useContext } from "react"
import RootContainer from "components/RootContainer/RootContainer"
import _ from "lodash"
import { Icon, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import CollectionDashboardContext from "../../contexts/CollectionDashboardContext"
import documentsViewsEnums from "../../constants/documentsViewsEnums"
import CollectionDashboardHeader from "../CollectionDashboardHeader/CollectionDashboardHeader"
import Views from "components/Views/Views"
import CenteredOnContainer from "components/CenteredOnContainer/CenteredOnContainer"

export default function CollectionDashboardRoot() {
  const {
    DocumentsGrid,
    DocumentsDashboard,
    documentView: viewMode,
  } = useContext(CollectionDashboardContext)

  const {
    selectedEntryID: activeID,
    setActiveID,
    entriesAreLoaded,
    entries,
  } = useContext(DocumentsDashboard.Context)

  const c = useStyles()

  // if (entriesAreLoaded && !entries?.length)
  //   return (
  //     <CenteredOnContainer>
  //       {/* <h1>oi</h1> */}
  //       <Typography
  //         variant="h4"
  //         style={{ display: "flex", alignItems: "center" }}
  //       >
  //         <Icon size="large" style={{ fontSize: 32, marginRight: 6 }}>
  //           browser_not_supported
  //         </Icon>
  //         <span>No document</span>
  //       </Typography>
  //     </CenteredOnContainer>
  //   )

  return (
    <DocumentsDashboard.Root
      className="collection-dashboard"
      renderLeft={({ components: { list } }) => {
        const views = [
          {
            id: documentsViewsEnums.GRID,
            component: (
              <RootContainer.Default className={c.rootContainer}>
                <RootContainer.Context.Consumer>
                  {({ width }) => {
                    return (
                      <DocumentsGrid.Main
                        columnCount={width < 660 ? 2 : 3}
                        onClickDocument={(document) =>
                          setActiveID(document?.id)
                        }
                        renderDocument={({ defaultComponent, document }) => {
                          const isSelected = document?.id === activeID

                          return (
                            <div
                              className={clsx(
                                "document-wrapper",
                                isSelected && c.selectedDocument
                              )}
                            >
                              {defaultComponent}
                            </div>
                          )
                        }}
                      ></DocumentsGrid.Main>
                    )
                  }}
                </RootContainer.Context.Consumer>
              </RootContainer.Default>
            ),
          },
          {
            id: documentsViewsEnums.LIST,
            component: list,
          },
        ]

        return (
          <div className={clsx("collection-dashboard", c.root)}>
            <CollectionDashboardHeader></CollectionDashboardHeader>
            {(() => {
              if (entriesAreLoaded && !entries?.length) {
                return (
                  <CenteredOnContainer>
                    {/* <h1>oi</h1> */}
                    <Typography
                      variant="h4"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Icon
                        size="large"
                        style={{ fontSize: 32, marginRight: 6 }}
                      >
                        browser_not_supported
                      </Icon>
                      <span>No document</span>
                    </Typography>
                  </CenteredOnContainer>
                )
              }

              return (
                <Views
                  views={views}
                  currentViewID={viewMode}
                  lazyRendering
                  noScroll
                  className={c.view}
                ></Views>
              )
            })()}
          </div>
        )
      }}
    ></DocumentsDashboard.Root>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    rootContainer: {
      height: "100%",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    view: {
      flex: 1,
      overflow: "hidden",
    },
    selectedDocument: {
      border: `3px solid ${theme.palette.primary.main}`,
      borderRadius: 6,
    },
  }
})
