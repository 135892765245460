import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import useActiveResources from "hooks/useActiveResources"
import { useDeleteCalendarEventMutation } from "lib/graphql/operations"
import updateQuery from "../lib/updateQuery/updateQuery"

export default function useDeleteCalendarEvent() {
  const r = useActiveResources()
  const [mutate, { loading }] = useDeleteCalendarEventMutation()

  const deleteCalendarEvent = React.useCallback(
    (eventID: string) => {
      return mutate({
        variables: { workspaceID: r.workspaceID, eventID },
        optimisticResponse: {
          __typename: "Mutation",
          deleteCalendarEvent: {
            __typename: "BasicResponse",
            success: true,
          },
        },
        update(dataProxy, { data }) {
          const success = data?.deleteCalendarEvent?.success

          if (!success) return

          updateQuery(
            {
              queryName: "calendarEntries",
              variables: {
                startDateTime: "",
                endDateTime: "",
                workspaceID: r.workspaceID,
              },
              updateFn: (a) => {
                return {
                  ...a,
                  calendarEntries: (a.calendarEntries || []).filter(
                    (e) => e.id !== eventID
                  ),
                }
              },
            },
            dataProxy
          )
        },
      })
    },
    [r.workspaceID]
  )

  return useMemoAPI({
    deleteCalendarEvent: deleteCalendarEvent,
    isLoading: loading,
  })
}
