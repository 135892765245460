import _ from "lodash"
import React, { useMemo } from "react"
import { NavigationButton } from "./components/NavigationButton/NavigationButton"
import Settings from "../Settings/Settings"
import MobileSideSheetToggle from "components/MobileSideSheetToggle/MobileSideSheetToggle"
import TopBar, { defaultSearchProps } from "components/TopBar/TopBar"
import { SearchVariants } from "components/TopBar/components/Search/Search"

export { NavigationButton } from "./components/NavigationButton/NavigationButton"

export default function AppBar(props: {
  className?: string
  title?: string
  left?: RenderLeft | React.ReactNode
  settings?: React.ReactNode
  renderLeft?: RenderLeft | React.ReactNode
  right?: RenderRight | React.ReactNode
  renderRight?: RenderRight | React.ReactNode
  search?: typeof defaultSearchProps | null
  showProgressBar?: boolean
  navigation?: string
  secondaryToolbar?: React.ReactNode
  elevation?: number
}) {
  let {
    className = "",
    title = "",
    right,
    left,
    renderRight: propsRenderRight,
    renderLeft: propsRenderLeft,
    search: searchProps = defaultSearchProps,
    showProgressBar = false,
    navigation = "toggle-drawer",
    secondaryToolbar = null,
    elevation = 1,
  } = props

  left = left || propsRenderLeft
  right = right || propsRenderRight

  const renderLeft: RenderLeft = function renderLeft({
    components: { title: titleComponent = "" } = {},
    isMobileSearchActive,
  }) {
    const navigationComponent = (
      <NavigationButton type={navigation || "toggle-drawer"} />
    )

    const components = {
      navigation: navigationComponent,
      title: titleComponent,
    }

    if (typeof left === "function")
      return left({
        components,
        isMobileSearchActive,
      })

    if (left === null || React.isValidElement(left)) return left

    return (
      !isMobileSearchActive && (
        <>
          {components.navigation}
          {components.title}
        </>
      )
    )
  }

  const renderRight: RenderRight = function renderRight({
    components: { search: searchComponent = "" } = {},
    isMobileSearchActive,
  }) {
    const settingsComponent = props.settings || <Settings edge="end" />

    const components = {
      search: searchComponent,
      settings: settingsComponent,
      mobileSideSheetToggle: <MobileSideSheetToggle></MobileSideSheetToggle>,
    }
    const defaultComponent = (
      <>
        {components.search}
        {!isMobileSearchActive && (
          <>
            {components.mobileSideSheetToggle}
            {components.settings}
          </>
        )}
      </>
    )

    if (props.right === null || React.isValidElement(props.right))
      return props.right

    if (typeof right === "function")
      return right({
        components,
        isMobileSearchActive,
        defaultComponent,
      })

    return defaultComponent
  }

  const search = useMemo(() => {
    return { ...searchProps, variant: SearchVariants.MATCH_WINDOW_SIZE }
  }, [searchProps])

  return (
    <TopBar
      className={className}
      title={title}
      renderRight={renderRight}
      renderLeft={renderLeft}
      search={search}
      showProgressBar={showProgressBar}
      secondaryToolbar={secondaryToolbar}
      elevation={elevation}
    ></TopBar>
  )
}

export type RenderRight = (props: {
  components?: {
    search?: React.ReactNode

    settings?: React.ReactNode
  }
  isMobileSearchActive: boolean
  defaultComponent?: React.ReactNode
}) => React.ReactNode

export type RenderLeft = (props: {
  components?: { title?: React.ReactNode; navigation?: React.ReactNode }
  isMobileSearchActive: boolean
  defaultComponent?: React.ReactNode
}) => React.ReactNode
