import React from "react"
import clsx from "clsx"
import * as ChatOverlay from "components/ChatOverlay/index"
import { makeStyles } from "@material-ui/core"
import FabsContainers from "components/FloatingButtonsContainer/FloatingButtonsContainers"

export default function FabsWrapper({
  className = "",
  showChatBalloon = true,
  floatingButtons = [],
  children = null,
  style = {},
  isVisible = true,
}) {
  const c = useStyles({})

  return (
    <div className={clsx(c.root, "fabs-wrapper", className)} style={style}>
      {children || null}
      {isVisible &&
        (function fabs() {
          const fbs = [
            ...(floatingButtons || []),
            showChatBalloon && (
              <ChatOverlay.Balloon className="chat-overlay-balloon"></ChatOverlay.Balloon>
            ),
          ].filter(Boolean)

          if (!fbs.length) return null

          return <FabsContainers fabs={<>{fbs}</>}></FabsContainers>
        })()}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
      height: "100%",
    },
  }
})
