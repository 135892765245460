import React, { Fragment } from "react"
import _ from "lodash"
import ToggleableMenu from "../ToggleableMenu/ToggleableMenu"
import { Checkbox } from "@material-ui/core"
import { FilterList as FilterListIcon } from "@material-ui/icons"
import { Typography } from "@material-ui/core"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import { ToggleableMenuProps } from "../ToggleableMenu/ToggleableMenu"
import useStateController from "hooks/useStateController"
import { StyledProps } from "types/type"

export default function OptionsToggleMenu<Value extends string>(
  props: {
    options: { label: string; value: Value }[]
    edge?: ToggleableMenuProps["edge"]
    onChange?: (value: Value[]) => any
    defaultValue?: Value
    typesToOmit?: Value[]
    value?: Value[]
    anchorOrigin?: ToggleableMenuProps["anchorOrigin"]
    onChangeOpenState?: ToggleableMenuProps["onChangeOpenState"]
    transformOrigin?: ToggleableMenuProps["transformOrigin"]
    IconButtonProps?: ToggleableMenuProps["IconButtonProps"]
    anchor?: ToggleableMenuProps["anchor"]

    renderAnchor?: (props: {
      defaultComponent: React.ReactNode
      selectedValues: Value[] | null
      isOpen: boolean
    }) => React.ReactNode
  } & StyledProps
) {
  const [selectedValues, changeValues] = useStateController({
    onChange: props.onChange,
    value: props.value || [],
  })
  const [isOpen, setIsOpen] = React.useState(false)

  const isChecked = (value: Value) => (selectedValues || []).includes(value)

  const toggleCheckbox = (value: Value) => {
    const remove = () => (selectedValues || []).filter((t) => t !== value)
    const add = () => _.uniq([...(selectedValues || []), value])

    changeValues(isChecked(value) ? remove() : add())
  }

  return (
    <ToggleableMenu
      className={props.className}
      style={props.style}
      edge={props.edge}
      closeOnSelect={false}
      anchorOrigin={
        props.anchorOrigin || { vertical: "top", horizontal: "left" }
      }
      transformOrigin={
        props.transformOrigin || { horizontal: "right", vertical: "top" }
      }
      {...(() => {
        if (props.anchor) return { anchor: props.anchor }

        return {
          icon: (() => {
            if (typeof props.anchor !== "undefined") return props.anchor

            const defaultComponent = (
              <PurpleBadge variant="dot" invisible={_.isEmpty(selectedValues)}>
                <FilterListIcon />
              </PurpleBadge>
            )

            if (!props.renderAnchor) return defaultComponent

            return props.renderAnchor?.call(undefined, {
              defaultComponent,
              selectedValues,
              isOpen,
            })
          })(),
        }
      })()}
      IconButtonProps={props.IconButtonProps}
      onChangeOpenState={setIsOpen}
      menuItems={(function menuItems() {
        const options = [...(props.options || [])]
          .sort((a, b) => (a.label < b.label ? -1 : 1))
          .filter((o) => !(props.typesToOmit || []).includes(o.value))

        return options.map((option) => {
          return {
            onClick: () => toggleCheckbox(option.value),
            component: (
              <Fragment>
                <Checkbox
                  checked={isChecked(option.value)}
                  value={option.value}
                />
                <Typography variant="body1">{option.label}</Typography>
              </Fragment>
            ),
          }
        })
      })()}
    />
  )
}
