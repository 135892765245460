import { Grid } from "./Grid"
import {
  ShopArticlesGridContext,
  ShopArticlesGridProvider,
} from "./ShopArticlesGridContext"

export type { ShopArticlesGridProps } from "./ShopArticlesGridContext"

export const ShopArticlesGrid = {
  Context: ShopArticlesGridContext,
  Provider: ShopArticlesGridProvider,
  Grid,
}
