import { createMuiTheme } from "@material-ui/core"
import { darkRoyalPurple, deepPlum } from "./constants/tokenized-colors"

const theme = createMuiTheme({
  palette: (() => {
    const colors = {
      primary: (() => {
        return {
          main: deepPlum,
          light: darkRoyalPurple,
        }
      })(),
      secondary: {
        main: "rgb(84,119,0)",
        light: "rgb(107,137,34)",
      },
    }

    return colors
  })(),
})

export default theme
