import { Icon, IconButton, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import { StyledProps } from "types/type"
import { Months } from "./components/Months"
// import { Months } from "../CalendarViews/Months"
// import { useStyles } from "./IntervalPeriodModal"

export function MonthControls(
  props: {
    children: React.ReactNode
    additionPeriod: moment.unitOfTime.DurationConstructor
  } & StyledProps
) {
  const c = useStyles()

  const api = React.useContext(Months.Context)

  const firstYear = api.list.start.year()
  const lastYear = api.list.end.year()

  return (
    <div
      className={clsx(c.root, "month-controls", props.className)}
      style={props.style}
    >
      <div className={c.content}>
        <IconButton
          style={{ padding: 8 }}
          disabled={Math.abs(api.list.activeMonth.month.year - firstYear) <= 0}
          onClick={() => {
            api.list.activeMonth.add({ amount: -1, unit: props.additionPeriod })
          }}
        >
          <Icon>chevron_left</Icon>
        </IconButton>
        {props.children}
        <IconButton
          style={{ padding: 8 }}
          disabled={Math.abs(api.list.activeMonth.month.year - lastYear) <= 0}
          onClick={() =>
            api.list.activeMonth.add({ amount: 1, unit: props.additionPeriod })
          }
        >
          <Icon>chevron_right</Icon>
        </IconButton>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // padding: "8px 0",
    },
  }
})
