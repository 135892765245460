import { makeStyles } from "@material-ui/core"
import { ItemsInCommonWithUser } from "components/PageBoard/components/ItemsInCommonWithUser/ItemsInCommonWithUser"
import { PageBoardItem } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/PageBoardItem"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import UserPageBoardItemContext from "../../contexts/UserPageBoardItemContext"

export function Body() {
  const rootContainer = useRootContainerAPI()

  const fullWidth = rootContainer.width < 800

  const c = useStyles({ fullWidth })

  return (
    <div
      className={c.root}
      ref={rootContainer.containerRef}
      key={!!rootContainer.width + ""}
    >
      <div className={c.searchWrapper}>
        <div
          className={c.search}
          style={{
            width: fullWidth ? "100%" : "50%",
          }}
        >
          <ItemsInCommonWithUser.Search
            className={c.centralized}
          ></ItemsInCommonWithUser.Search>
        </div>

        <ItemsInCommonWithUser.DateAndTypesFilter.Provider
          isSmall={rootContainer.width < 500}
          wrapChips={!fullWidth}
        >
          <ItemsInCommonWithUser.DateAndTypesFilter.Root
            className={c.centralized}
          ></ItemsInCommonWithUser.DateAndTypesFilter.Root>
        </ItemsInCommonWithUser.DateAndTypesFilter.Provider>
      </div>
      <UserPageBoardItemContext.Consumer>
        {(api) => {
          return (
            <ItemsInCommonWithUser.Root
              className="root"
              openItemPage={(a) => {
                const openPage = (() => {
                  if (a?.__typename === "UserPageBoardItem")
                    return api.twoPagesAPI.openFullPage
                  return api.twoPagesAPI.openHalfPage
                })().bind(api.twoPagesAPI)

                openPage(function UserProfileSecondPage({ onClose }) {
                  return (
                    <PageBoardItem.SecondPage
                      getItemParams={{ item: a }}
                      onClose={onClose}
                    ></PageBoardItem.SecondPage>
                  )
                })
              }}
            ></ItemsInCommonWithUser.Root>
          )
        }}
      </UserPageBoardItemContext.Consumer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",

    "&>.root": { flex: 1 },
  },
  centralized: {
    display: "flex",
    justifyContent: "center",
  },
  searchWrapper: {
    marginBottom: 8,
    paddingRight: 8,
    paddingLeft: 8,
  },
  search: {
    width: (props: { fullWidth: boolean }) =>
      props.fullWidth ? "100%" : "50%",
    "&>*": {},

    margin: "0 auto 12px",
    // marginBottom: 8,
  },
}))
