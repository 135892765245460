import React from "react"
import clsx from "clsx"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import { KanbanImageFragment } from "lib/graphql/operations"

export default function CardImage(props: {
  image: KanbanImageFragment | null
  style?: React.CSSProperties
  render?: (props: {
    objectURL?: string | null
    defaultComponent: React.ReactNode
    openImage(): any
  }) => React.ReactNode
  className?: string
  imgProps?: { className?: string; style?: React.CSSProperties }
}) {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const openImage = React.useCallback(() => {
    if (!props.image?.id) return
    api.images.modal.open({ imageID: props.image.id })
  }, [props.image?.id])

  if (!props.image?.src) return null

  const objectURL = api.images.getObjectURL(props.image?.src)

  const defaultComponent = objectURL ? (
    <img
      src={objectURL}
      onClick={openImage}
      className={clsx("card-image", props.className, props.imgProps?.className)}
      style={{ ...(props.imgProps?.style || {}), ...(props.style || {}) }}
    />
  ) : null

  if (props.render)
    return <>{props.render({ objectURL, defaultComponent, openImage })}</>

  return defaultComponent
}
