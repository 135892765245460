import React, { useContext } from "react"
import _ from "lodash"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { Icon, IconButton } from "@material-ui/core"
import clsx from "clsx"
import LightTooltip from "components/LightTooltip/LightTooltip"
import EditControlContext from "components/EditControl/contexts/EditControlContext"

export default function RestoreButton({ className = "", property }) {
  const {
    objectValue,
    newObjectValue: newActivityValue,
    restore,
  } = useContext(EditControlContext)

  const valueChanged = (() => {
    const newValue = JSON.stringify(_.get(newActivityValue, property))
    const oldValue = JSON.stringify(_.get(objectValue, property))

    return newValue !== oldValue
  })()

  const t = useI18n()

  if (!valueChanged) return null

  return (
    <LightTooltip
      title={t.restore}
      className={clsx("restore-button", className)}
    >
      <IconButton size="small" onClick={() => restore(property)}>
        <Icon>restore</Icon>
      </IconButton>
    </LightTooltip>
  )
}
