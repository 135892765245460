import React from "react"
import DocumentsFilterRoot from "../DocumentsFilterRoot/DocumentsFilterRoot"
import DocumentsFilterProvider from "../DocumentsFilterProvider/DocumentsFilterProvider"

export default function DocumentsFilterDefault({
  mainCollectionID,
  relatedCollectionsIDs,
  onChangeRelatedCollectionsIDs,
  className = "",
}) {
  return (
    <DocumentsFilterProvider
      mainCollectionID={mainCollectionID}
      relatedCollectionsIDs={relatedCollectionsIDs}
      onChangeRelatedCollectionsIDs={onChangeRelatedCollectionsIDs}
    >
      <DocumentsFilterRoot className={className}></DocumentsFilterRoot>
    </DocumentsFilterProvider>
  )
}
