import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { StyledProps } from "types/type"

export function LoadingLayer(props: { content: string } & StyledProps) {
  const { content } = props
  const c = useStyles()

  return (
    <div className={clsx(c.root, props.className, "loading-layer")}>
      <div>Loading</div>
      <p className={c.loadingText}>{content}</p>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(0,0,0,0.8)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99,
  },
  loadingText: {
    marginTop: 20,
    fontSize: 18,
    color: "#fff",
  },
}))

export default useStyles
