import {
  ActivityFragment,
  ActivityFragmentDoc,
  CollectionItemFragment,
  CollectionItemFragmentDoc,
  DecoupledKanbanBoardFragment,
  DecoupledKanbanBoardFragmentDoc,
  DocumentFragment,
  DocumentFragmentDoc,
  KanbanCardFragmentDoc,
  KanbanCardImagesFragmentDoc,
  KanbanColumnFragmentDoc,
  KanbanCommentFragment,
  RoomFragment,
  RoomFragmentDoc,
  RoomMembersListFragment,
  RoomMembersListFragmentDoc,
  RoomMessageListFragment,
  RoomMessageListFragmentDoc,
  RoomPageBoardItemFragment,
  RoomPageBoardItemFragmentDoc,
  // ItemDoc
  UserFragment,
  UserFragmentDoc,
} from "lib/graphql/operations"
import { Typename } from "types/type"
import { KanbanCommentFragmentDoc } from "../graphql/operations"
import {
  KanbanCardFragment,
  KanbanColumnFragment,
  KanbanCardImagesFragment,
} from "../graphql/operations"

export const fragmentMap = {
  [Typename.DecoupledKanbanBoard]: {
    fragment: DecoupledKanbanBoardFragmentDoc,
    fragmentName: "DecoupledKanbanBoard",
    updateFn: (a: DecoupledKanbanBoardFragment): DecoupledKanbanBoardFragment =>
      a,
  },
  [Typename.KanbanCardImages]: {
    fragment: KanbanCardImagesFragmentDoc,
    fragmentName: "KanbanCardImages",
    updateFn: (a: KanbanCardImagesFragment): KanbanCardImagesFragment => a,
  },
  [Typename.Room]: {
    fragment: RoomFragmentDoc,
    fragmentName: "Room",
    updateFn: (a: RoomFragment): RoomFragment => a,
  },

  [Typename.User]: {
    fragment: UserFragmentDoc,
    fragmentName: "User",
    updateFn: (a: UserFragment): UserFragment => a,
  },

  [Typename.Activity]: {
    fragment: ActivityFragmentDoc,
    fragmentName: "Activity",
    updateFn: (a: ActivityFragment): ActivityFragment => a,
  },

  [Typename.User]: {
    fragment: UserFragmentDoc,
    fragmentName: "User",
    updateFn: (a: UserFragment): UserFragment => a,
  },

  [Typename.RoomMessageList]: {
    fragment: RoomMessageListFragmentDoc,
    fragmentName: "RoomMessageList",
    updateFn: (a: RoomMessageListFragment | null) => a,
  },
  [Typename.RoomMembersList]: {
    fragment: RoomMembersListFragmentDoc,
    fragmentName: "RoomMembersList",
    updateFn: (a: RoomMembersListFragment) => a,
  },
  [Typename.CollectionItem]: {
    fragment: CollectionItemFragmentDoc,
    fragmentName: "CollectionItem",
    updateFn: (a: CollectionItemFragment) => a,
  },
  [Typename.KanbanCard]: {
    fragment: KanbanCardFragmentDoc,
    fragmentName: "KanbanCard",
    updateFn: (a: KanbanCardFragment) => a,
  },
  [Typename.KanbanColumn]: {
    fragment: KanbanColumnFragmentDoc,
    fragmentName: "KanbanColumn",
    updateFn: (a: KanbanColumnFragment) => a,
  },
  [Typename.Document]: {
    fragment: DocumentFragmentDoc,
    fragmentName: "Document",
    updateFn: (a: DocumentFragment) => a,
  },
  [Typename.RoomPageBoardItem]: {
    fragment: RoomPageBoardItemFragmentDoc,
    fragmentName: "RoomPageBoardItem",
    updateFn: (a: RoomPageBoardItemFragment) => a,
  },
  [Typename.KanbanComment]: {
    fragment: KanbanCommentFragmentDoc,
    fragmentName: "KanbanComment",
    updateFn: (a: KanbanCommentFragment) => a,
  },
}
export type FragmentMap = typeof fragmentMap
