import useActiveResources from "hooks/useActiveResources"
import { getActiveWorkspaceID } from "lib/activeWorkspaceID"
import sessionID from "lib/setupApollo/sessionID"
import React from "react"

export function useAppHttpGet() {
  const { workspaceID } = useActiveResources()

  const httpGet = React.useCallback(
    (
      url: string,
      options?: { headers?: Record<string, string | number | undefined> }
    ) => {
      return fetch(url, {
        headers: {
          "session-id": sessionID,
          "active-workspace-id": workspaceID || getActiveWorkspaceID() || "",
          ...options?.headers,
        },
      })
    },
    [workspaceID]
  )

  return httpGet
}
