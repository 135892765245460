import AutoComplete from "components/AutoComplete/AutoComplete"
import { AutoCompleteOption } from "components/Input/components/AutoComplete/AutoComplete"
import { FieldFn } from "hooks/useForm"
import { excludeNullable } from "lib/excludeNullable"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import _ from "lodash"
import React from "react"
import { TextFieldProps } from "components/TextField/TextField"
import { IndexedItems } from "../IndexedItems/IndexedItems"
import { PageBoardItemsAutoCompleteContext } from "./PageBoardItemsAutoCompleteContext"

const getOptionValue = (value: NewPageBoardItemFragment | null) => {
  if (!value) return null
  const params = [value.type, value.itemID]

  if (params.some((v) => !v)) return null

  return params.join("-")
}
const filter = (a: any): a is AutoCompleteOption => !!a.value && !!a.label
export function PageBoardItemsAutoCompleteRoot() {
  const api = React.useContext(PageBoardItemsAutoCompleteContext)
  const indexedItemsApi = React.useContext(IndexedItems.Context)

  const itemsByOptionValue = React.useMemo(() => {
    return _.keyBy(
      (indexedItemsApi.pageBoardItems || [])?.filter(excludeNullable),
      getOptionValue
    ) as Record<string, NewPageBoardItemFragment | undefined>
  }, [indexedItemsApi.pageBoardItems, getOptionValue])

  const onChange = React.useCallback<FieldFn<string>>(
    (e) => {
      const value = itemsByOptionValue[e.target.value]
      api.onChange({ target: { name: api.name, value: value || null } })
    },
    [itemsByOptionValue, api.onChange, api.name]
  )

  const fetchedOptions = React.useMemo<Array<AutoCompleteOption>>(() => {
    return (indexedItemsApi.pageBoardItems || [])
      .map((a) => {
        return {
          label: a.name,
          value: getOptionValue(a),
        }
      })
      .filter(filter)
  }, [indexedItemsApi.pageBoardItems])

  const itemProperties = {
    label: api.value?.name || "",
    value: getOptionValue(api.value),
  }

  const options = React.useMemo<Array<AutoCompleteOption>>(() => {
    const v = [itemProperties, ...fetchedOptions].filter(filter)

    return _.uniqBy(v, (a) => a.value)
  }, [itemProperties.label, itemProperties.value, fetchedOptions])

  const textFieldProps = React.useMemo<TextFieldProps>(() => {
    return {
      label: api.label,
      onChange(e) {
        indexedItemsApi.search.set(e.target.value)
      },
    }
  }, [api.label, indexedItemsApi.search.set])

  return (
    //
    <AutoComplete
      onlyOptionsMatchingText={false}
      TextFieldProps={textFieldProps}
      options={options}
      name={api.name}
      onChange={onChange}
      value={getOptionValue(api.value) || ""}
    ></AutoComplete>
  )
}
