import { Typography } from "@material-ui/core"
import MetaCardPaper from "components/MetaCardPaper/MetaCardPaper"
import _ from "lodash"
import { StyledProps } from "types/type"

export type MetaCardProps = {
  render?: (args: {
    defaultComponent: React.ReactNode
    components: {
      overline: React.ReactNode
      subtitle: React.ReactNode
      title: React.ReactNode
    }
  }) => React.ReactNode
  children?: React.ReactNode
  overline?: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
} & StyledProps

export default function MetaCard({
  className = "",
  render: propsRender,
  children,
  overline,
  title,
  subtitle,
  style,
}: MetaCardProps) {
  const components = {
    overline: overline && (
      <Typography variant="overline">{overline}</Typography>
    ),
    title: title && <Typography variant="body1">{title}</Typography>,

    subtitle: subtitle && (
      <Typography variant="body2" color="textSecondary">
        {subtitle}
      </Typography>
    ),
  }

  const noComponent = _.isEmpty(_.pickBy(components, (v) => !!v))
  if (noComponent && !propsRender) return null

  const defaultComponent = (
    <>
      {components.overline}
      {components.title}
      {components.subtitle}
    </>
  )

  const render = propsRender || children

  return (
    <MetaCardPaper className={className} style={style}>
      {_.isFunction(render)
        ? render({ defaultComponent, components })
        : defaultComponent}
    </MetaCardPaper>
  )
}
