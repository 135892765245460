import buildMap from "./buildMap"

const jobStatuses = [
  { value: "FINISHED", color: "#82b700", translation: "fertig" },
  { value: "EDITING", color: "#ca3396", translation: "in Bearbeitung" },
  { value: "DELETED", color: "#61003f", translation: "gelöscht" },
  { value: "FAILED", color: "#960091", translation: "fehlgeschlagen" },
  {
    value: "PROCESSING",
    color: "#ca5ba3",
    translation: "in Bearbeitung",
  },
  {
    value: "MARKETPLACE",
    color: "#c2bd00",
    translation: "Erfassung Eingang",
  },
  {
    value: "ORDER",
    color: "#c2bd00",
    translation: "Erfassung Eingang",
  },
  {
    value: "INVOICE",
    color: "#c2bd00",
    translation: "Erfassung Eingang",
  },
  { value: "WAITING", color: "#c2bd00", translation: "wartet..." },
  { value: "DONE", color: "#547700", translation: "fertig" },
  { value: "CLEARANCE", color: "#701c53", translation: "in Klärung" },
  {
    value: "Simple",
    color: "#e0dd65",
    translation: "Erfassung Einfach",
  },
  {
    value: "Complex",
    color: "#e0dc38",
    translation: "Erfassung Komplex",
  },
  {
    value: "Allocation",
    color: "#c2bd00",
    translation: "Erfassung Kontierung",
  },
  {
    value: "Approval",
    color: "#7e7b00",
    translation: "Erfassung Bestätigung",
  },
  { value: "365Admin", color: "#918f24", translation: "Administrator" },
]

export default jobStatuses
export const jobStatusesColors = buildMap("color", jobStatuses)
