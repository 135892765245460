import { Button, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import getTimelogFieldProps from "components/OldDocumentForm/components/lib/renderTimeLog/getTimelogFieldProps"
import { PageForm } from "components/Page/Page"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { useGroupInTwoInLargeDevices } from "hooks/useGroupInTwoInLargeDevices"
import React from "react"
import { StyledProps } from "types/type"
import {
  FormCommons,
  getBusinessFieldProps,
} from "../../IndexedItems/components/PageBoardItem/components/FormCommons/FormCommons"
import { getContactsFieldProps } from "../lib/getContactsFieldProps"
import getDetailsFieldProps from "../lib/getDetailsFieldProps"
import getRichTextFieldProps from "../lib/getRichTextFieldProps"
import { PartnerFormContext } from "./ProviderAndContext/ProviderAndContext"

export function PartnerFormRoot(
  props: {
    lastDocuments?: React.ReactNode
  } & StyledProps
) {
  const translations = useI18n()

  const { partner, summarized, onClickOpenButton, paginatedDocuments, form } =
    React.useContext(PartnerFormContext)

  const richTextFieldProps = useRichTextFieldProps()

  const indentationProps = ({ isStacked }: { isStacked: boolean }) =>
    [
      getBusinessFieldProps({ form }),
      getDetailsFieldProps({ translations }),
      !summarized && richTextFieldProps,
      !summarized &&
        getTimelogFieldProps({
          stack: isStacked,
          translations,
          updated: {
            timestamp: partner?.updated,
            person: partner?.updatedBy,
          },
          created: {
            timestamp: partner?.created,
            person: partner?.createdBy,
          },
        }),

      !summarized &&
        getContactsFieldProps({
          AddNewContactFormApiInput: { partnerId: partner?.id || "" },
        }),

      (() => {
        const args:
          | Parameters<typeof FormCommons.getLastDocumentsFieldProps>[0]
          | null = (() => {
          if (props.lastDocuments) return { lastDocuments: props.lastDocuments }

          return null
        })()

        if (!args) return null

        return FormCommons.getLastDocumentsFieldProps(args)
      })(),
    ].filter((a) => !!a)

  const { grouped, containerRef } = useGroupInTwoInLargeDevices({
    items: indentationProps,
  })

  const c = useStyles()
  const t = useI18n()

  return (
    <div
      ref={containerRef}
      className={clsx("partner-form", c.root, props.className)}
      style={props.style}
    >
      <div>
        <PageForm fieldsIndentationsProps={grouped}></PageForm>
      </div>
      {summarized && (
        <div className={clsx(c.openItem)}>
          <Button
            size="large"
            onClick={onClickOpenButton}
            variant="outlined"
            color="primary"
            disableElevation
          >
            {t.open}
          </Button>
        </div>
      )}
    </div>
  )
}
function useRichTextFieldProps() {
  const translations = useI18n()

  const c = useRichTextFieldPropsStyles()

  return getRichTextFieldProps({
    // api,
    translations,
    indentationProps: { classes: { rightWrapper: c.container } },
  })
}
const useRichTextFieldPropsStyles = makeStyles((theme) => {
  return {
    container: {
      overflow: "hidden",
    },
  }
})

const useStyles = makeStyles((theme) => {
  return {
    openItem: {
      // paddingTop:
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "center",

      "& button": {
        width: "100%",
      },
    },
    root: {
      display: "flex",
      flexDirection: "column",
      "&>*:first-child": {
        flex: 1,
        overflow: "auto",
      },
    },
  }
})
