import React from "react"

/**
 * create na object containing Context, Provider, and Consumer, that
 * facilitates the injection of any props down the tree
 * */
export function propsInjectorFactory<Props extends {}>(args: {
  contextName: string
}) {
  const Context = React.createContext({} as Props)

  Context.displayName = args.contextName

  function Provider(props: Props & { children: React.ReactNode }) {
    return <Context.Provider value={props}>{props.children}</Context.Provider>
  }

  Provider.displayName = args.contextName.replace("Context", "Provider")

  return {
    Context,
    Provider,
    Consumer: Context.Consumer,
    useContext() {
      return React.useContext(Context)
    },
  }
}
