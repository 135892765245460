import {
  CollectionItemFragment,
  DocumentFragment,
  DocumentListItemFragment,
  KanbanCardImagesFragment,
  MessageListItemFragment,
  RoomFragment,
  RoomMembersListFragment,
  RoomMessageListFragment,
  RoomNotificationsCountFragment,
  TranslatedWordFragment,
  UserFragment,
  WorkspaceFragment,
  WorkspaceNotificationsCountFragment,
  WorkspaceSettingsFragment,
} from "lib/graphql/operations"
import { PageBoardItemType, Reaction, TypingOnRoom } from "lib/graphql/types"
import { Typename } from "types/type"
import {
  ActivityFragment,
  CalendarEventFragment,
  KanbanCardFragment,
  KanbanColumnFragment,
  KanbanCommentFragment,
  KanbanCommentListFragment,
  NewPageBoardItemFragment,
  RoomPageBoardItemFragment,
} from "../graphql/operations"

import { defaultDataIdFromObject } from "@apollo/client"

const cachedIDGetters = {
  [Typename.Activity](object: Pick<ActivityFragment, "id">) {
    return `Activity:${object.id}`
  },

  [Typename.NewPageBoardItem](
    object: Pick<NewPageBoardItemFragment, "itemID" | "type">
  ) {
    return `NewPageBoardItem:${object.type}-${object.itemID}`
  },

  [Typename.KanbanColumn](object: Pick<KanbanColumnFragment, "id">) {
    return `KanbanColumn:${object.id}`
  },

  [Typename.CalendarEvent](object: Pick<CalendarEventFragment, "id">) {
    return `CalendarEvent:${object.id}`
  },

  [Typename.Workspace](object: Pick<WorkspaceFragment, "id">) {
    return `Workspace:${object.id}`
  },

  [Typename.User](object: Pick<UserFragment, "id">) {
    return `User:${object.id}`
  },

  [Typename.TypingOnRoom](object: Pick<TypingOnRoom, "roomID">) {
    return `TypingOnRoom:${object.roomID}`
  },

  [Typename.DocumentListItem](
    object: Pick<DocumentListItemFragment, "index" | "listID">
  ) {
    return `DocumentListItem:${object.listID}.${object.index}`
  },

  [Typename.MessageListItem](
    object: Pick<MessageListItemFragment, "roomID" | "index">
  ) {
    return `MessageListItem:${object.roomID}.${object.index}`
  },

  [Typename.RoomMessageList](object: Pick<RoomMessageListFragment, "roomID">) {
    return `RoomMessageList:${object.roomID}`
  },

  [Typename.TranslatedWord](object: Pick<TranslatedWordFragment, "value">) {
    return `TranslatedWord:${object.value}`
  },

  [Typename.Reaction](object: Pick<Reaction, "emoji" | "messageID">) {
    return `Reaction:${object.messageID}.${object.emoji}`
  },

  [Typename.KanbanCommentList](object: Pick<KanbanCommentListFragment, "id">) {
    return `KanbanCommentList:${object.id}`
  },

  [Typename.KanbanCard](object: Pick<KanbanCardFragment, "id">) {
    return `KanbanCard:${object.id}`
  },

  [Typename.KanbanCardImages](
    object: Pick<KanbanCardImagesFragment, "cardID">
  ) {
    return `KanbanCardImages:cardID.${object.cardID}`
  },

  [Typename.Order](object: Pick<OrderFragment, "id">) {
    return `Order:${object.id}`
  },

  [Typename.RoomMembersList](object: Pick<RoomMembersListFragment, "roomID">) {
    return `RoomMembersList:${object.roomID}`
  },

  [Typename.RoomNotificationsCount](
    object: Pick<RoomNotificationsCountFragment, "roomID">
  ) {
    return `RoomNotificationsCount:${object.roomID}`
  },

  [Typename.WorkspaceNotificationsCount](
    object: Pick<WorkspaceNotificationsCountFragment, "workspaceID">
  ) {
    return `WorkspaceNotificationsCount:${object.workspaceID}`
  },

  [Typename.WorkspaceSettings](
    object: Pick<WorkspaceSettingsFragment, "workspaceID">
  ) {
    return `WorkspaceSettings:${object.workspaceID}`
  },

  [Typename.CollectionItem](object: Pick<CollectionItemFragment, "id">) {
    return `CollectionItem:${object.id}`
  },

  [Typename.Document](object: Pick<DocumentFragment, "id">) {
    return `Document:${object.id}`
  },

  [Typename.Room](object: Pick<RoomFragment, "id">) {
    return `Room:${object.id}`
  },

  [Typename.RoomPageBoardItem](object: Pick<RoomPageBoardItemFragment, "id">) {
    return `RoomPageBoardItem:${object.id}`
  },

  [Typename.KanbanComment](object: Pick<KanbanCommentFragment, "id">) {
    return `KanbanComment:${object.id}`
  },

  [Typename.PageBoardItemTypeCount](
    object: Pick<{ type: PageBoardItemType }, "type">
  ) {
    return `PageBoardItemTypeCount:${object.type}`
  },

  [Typename.ShopArticle](object: Pick<{ id: string }, "id">) {
    return `ShopArticle:${object.id}`
  },
}

function isPageBoardItem(object: any): object is NewPageBoardItemFragment {
  if (
    object.__typename?.includes("PageBoardItem") &&
    object.id &&
    object.type
  ) {
    return true
  }

  return false
}

export function getPageBoardItemCacheIDPrefix(
  object: Pick<NewPageBoardItemFragment, "__typename" | "type" | "itemID">
) {
  return object.__typename + ":"
}

export function getPageBoardItemCacheId(
  object: Pick<NewPageBoardItemFragment, "__typename" | "type" | "itemID">
) {
  const prefix = getPageBoardItemCacheIDPrefix(object)
  const id = getPageBoardItemIDWithoutPrefix(object)

  return prefix + id
}

export function getPageBoardItemIDWithoutPrefix(
  object: Pick<NewPageBoardItemFragment, "__typename" | "type" | "itemID">
) {
  return [object.type, object.itemID].join("-")
}

export function getCacheID(object: any) {
  if (isPageBoardItem(object)) {
    return getPageBoardItemCacheId(object)
  }

  const o = object as { __typename: keyof typeof cachedIDGetters }

  if (o.__typename in cachedIDGetters) {
    return cachedIDGetters[o.__typename](object)
  }

  return defaultDataIdFromObject(object as any)
}

/** @deprecated */
export default cachedIDGetters

export type CacheIDParams<
  T extends Extract<keyof typeof cachedIDGetters, Typename>
> = (typeof cachedIDGetters)[T] extends (object: infer A) => string ? A : never
