import React, { useRef } from "react"
import { Paper } from "@material-ui/core"
import clsx from "clsx"
import Pdf from "components/Pdf/Pdf"

export default function SinglePagePDF(props: {
  url: string
  elevation: number
  className: string
  classes: { paper: string; page: string }
}) {
  const {
    url,
    elevation,
    className,
    classes: { paper: paperClassName, page: pageClassName } = {},
  } = props
  const ref = React.useRef<HTMLDivElement | null>(null)

  return (
    <div ref={ref} className={clsx("single-page-pdf", className)}>
      <Paper elevation={elevation} className={paperClassName}>
        <Pdf.Provider url={url}>
          <Pdf.Page index={0}></Pdf.Page>
        </Pdf.Provider>
      </Paper>
    </div>
  )
}
