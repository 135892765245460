import useMemoAPI from "hooks/useMemoAPI"
import useTrueFalse from "./useTrueFalse"

export default function useEditState(initialValue?: boolean) {
  const trueFalse = useTrueFalse(initialValue)

  return useMemoAPI({
    isEditing: trueFalse.isTrue,
    startEdition: trueFalse.setTrue,
    endEdition: trueFalse.setFalse,
    toggle: trueFalse.toggle,
  })
}
