import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import CalendarEventListItem from "./components/CalendarEventListItem/CalendarEventListItem"
import ContactListItem from "./components/ContactListItem/ContactListItem"
import DocumentListItem from "./components/DocumentListItem/DocumentListItem"
import KanbanCardCommentListItem from "./components/KanbanCardCommentListItem/KanbanCardCommentListItem"
import KanbanCardListItem from "./components/KanbanCardListItem/KanbanCardListItem"
import MessageListItem from "./components/MessageListItem/MessageListItem"
import RoomListItem from "./components/RoomListItem/RoomListItem"
import UserListItem from "./components/UserListItem/UserListItem"
import getPartnerListItem from "./components/VendorListItem/PartnerListItem"
import { ListEntryProps } from "./types"
import { PartnerPageBoardItemFragment } from "lib/graphql/operations"
import ShopOrderListItem from "./components/ShopOrderListItem/ShopOrderListItem"

export default function ListItemEntry(props: {
  entry?: NewPageBoardItemFragment
  className?: string
  style?: React.CSSProperties
}) {
  const c = useStyles()

  if (!props.entry) return null

  const PartnerEntry =
    PartnerEntryComponents[
      props.entry.type as keyof typeof PartnerEntryComponents
    ]

  if (!!PartnerEntry) {
    return (
      <PartnerEntry
        entry={props.entry as PartnerPageBoardItemFragment}
        className={clsx(props.className, c.root)}
        style={props.style}
      ></PartnerEntry>
    )
  }

  const BasicEntry =
    BasicEntryComponents[props.entry.type as keyof typeof BasicEntryComponents]

  if (!BasicEntry) return null

  return (
    <BasicEntry
      entry={props.entry}
      className={clsx(props.className, c.root)}
      style={props.style}
    ></BasicEntry>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      alignItems: "center",
    },
  }
})

const BasicEntryComponents: {
  [key in PageBoardItemType]?: React.FC<ListEntryProps>
} = {
  [PageBoardItemType.User]: UserListItem,
  [PageBoardItemType.Room]: RoomListItem,
  [PageBoardItemType.Message]: MessageListItem,
  [PageBoardItemType.CalendarEvent]: CalendarEventListItem,
  [PageBoardItemType.KanbanCard]: KanbanCardListItem,
  [PageBoardItemType.KanbanCardComment]: KanbanCardCommentListItem,
  [PageBoardItemType.Document]: DocumentListItem,
  [PageBoardItemType.Contact]: ContactListItem,
  [PageBoardItemType.ShopOrder]: ShopOrderListItem,
}

const PartnerEntryComponents: {
  [key in PageBoardItemType]?: React.FC<
    ListEntryProps<PartnerPageBoardItemFragment>
  >
} = {
  [PageBoardItemType.Vendor]: getPartnerListItem("VendorPageBoardItem"),
  [PageBoardItemType.Customer]: getPartnerListItem("CustomerPageBoardItem"),
  [PageBoardItemType.Lead]: getPartnerListItem("LeadPageBoardItem"),
}
