import {
  HomeWorkspaceDocument,
  useHomeWorkspaceQuery,
} from "lib/graphql/operations"
import useMemoAPI from "./useMemoAPI"
import { WatchQueryFetchPolicy } from "@apollo/client"

export default function useHomeWorkspace(props?: {
  fetchPolicy?: WatchQueryFetchPolicy
}) {
  const queryResult = useHomeWorkspaceQuery({
    fetchPolicy: props?.fetchPolicy || "cache-first",
  })

  return useMemoAPI({
    homeWorkspace: queryResult.data?.homeWorkspace,
    isLoading: queryResult.loading,
    called: queryResult.called,
  })
}
