import React from "react"
import { useImageAttachmentAPI } from "../hooks/useImageAttachmentAPI"

export type ImageAttachmentAPI = ReturnType<typeof useImageAttachmentAPI>

const ImageAttachmentContext = React.createContext<ImageAttachmentAPI>(
  {} as ImageAttachmentAPI
)

ImageAttachmentContext.displayName = "AttachmentContext"

export default ImageAttachmentContext
