import _ from "lodash"
import clsx from "clsx"
import moment from "moment"
import React, { useState, useEffect, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import useResizeObserver from "use-resize-observer"
import AdapterLink from "../AdapterLink/AdapterLink"
import DocumentInfoButton from "../DocumentInfoButton/DocumentInfoButton"
import { clipText } from "styles/globalStyles"
import arePropsEqual from "util/arePropsEqual"
import { useI18n } from "contexts/i18nContext/i18nContext"

function DocumentCard({
  className = "",
  showInfoButton = true,
  onClick,
  document: {
    id,
    thumbnail,
    title,
    date,
    type,
    created,
    user,
    workStatus,
    link,
  } = {},
}) {
  // const { mediaHeight, ref } = useMediaHeightObserver(144)

  // const {getTypeTranslation} = useGetTypeTranslation()
  const t = useI18n()

  const c = useStyles({})
  const placeholder = <span style={{ visibility: "hidden" }}>placeholder</span>

  return (
    <Card className={clsx(c.card, className)} onClick={onClick}>
      <CardActionArea {...(link ? { component: AdapterLink, to: link } : {})}>
        <CardMedia // ref={ref}
          className={c.media}
          image={thumbnail}
          title={title}
        />
        {/* </div> */}
        <CardContent>
          <Typography gutterBottom variant="h6" className={c.textLine}>
            {title}
          </Typography>
          {[
            !type ? null : t.collectionsTypes?.title_type?.[type] || null,
            date
              ? `${moment(date).format("DD.MM.YYYY")} ${
                  user ? `- ${user.name}` : ""
                }`
              : null,
            workStatus,
          ]
            .sort((a) => (!!a ? -1 : 1))
            .map((a) => a || placeholder)
            .map((a, i) => (
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                key={`line-${i}`}
                className={c.textLine}
              >
                {a}
              </Typography>
            ))}
        </CardContent>
      </CardActionArea>
      {showInfoButton && (
        <CardActions className={c.actions} disableSpacing>
          <DocumentInfoButton documentID={id}></DocumentInfoButton>
        </CardActions>
      )}
    </Card>
  )
}

export default React.memo(
  DocumentCard,
  arePropsEqual([
    "className",
    "showInfoButton",
    "onClick",
    "document.id",
    "document.thumbnail",
    "document.title",
    "document.date",
    "document.type",
    "document.created",
    "document.user ",
    "document.workStatus ",
    "document.link",
  ])
)

const useStyles = makeStyles((theme) => {
  return {
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    media: {
      backgroundPosition: "top",
      paddingTop: 0.8056 * 100 + "%",
    },
    textLine: {
      ...clipText,
    },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    imageContainer: {},
  }
})
