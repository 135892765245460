import { makeStyles } from "@material-ui/core"
import FastAverageColor, { FastAverageColorResult } from "fast-average-color"
import React from "react"
import clsx from "clsx"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import CardImage from "../CardImage/CardImage"
import AverageColor from "components/AverageColor/AverageColor"

export default function Cover(props: {
  className?: string
  onBackgroundColor?: (backgroundColor: FastAverageColorResult | null) => void
}) {
  const c = useStyles()

  const api = React.useContext(KanbanCardPageBoardItemContext)

  const cover = api.images.data?.cover

  return (
    <CardImage
      image={cover || null}
      render={(p) => {
        const isUploading = api.images.isUploading

        if (!p.objectURL && isUploading) return null

        return (
          <AverageColor
            imageURL={p.objectURL || ""}
            onBackgroundColor={props.onBackgroundColor}
          >
            {({ averageColor }) => {
              return (
                <div
                  className={clsx("kanban-card-cover", c.root, props.className)}
                  style={{ backgroundColor: averageColor?.rgba }}
                  onClick={p.openImage}
                >
                  {(() => {
                    if (!!isUploading)
                      return (
                        <div
                          style={{
                            background: "rgba(0,0,0,.08)",
                            width: "100%",
                            height: "100%",
                          }}
                        ></div>
                      )

                    if (p.objectURL) return p.defaultComponent

                    return null
                  })()}
                </div>
              )
            }}
          </AverageColor>
        )
      }}
    ></CardImage>
  )
}
// export default function Cover(props: {
//   className?: string
//   onBackgroundColor?: (backgroundColor: FastAverageColorResult | null) => void
// }) {
//   const c = useStyles()

//   const api = React.useContext(KanbanCardPageBoardItemContext)

//   const cover = api.images.data?.cover
//   const objectURL = api.images.getObjectURL(cover?.src || "")

//   const color = useImageAverageColor(objectURL || null)

//   useEffect(() => {
//     props.onBackgroundColor?.call(null, color)
//   }, [color])

//   const isUploading = api.images.isUploading

//   if (!objectURL && isUploading) return null

//   // console.log(api.images.isUploading)
//   return (
//     <div
//       className={clsx("kanban-card-cover", c.root, props.className)}
//       style={{ backgroundColor: color?.rgba }}
//     >
//       {(() => {
//         if (!!isUploading)
//           return (
//             <div
//               style={{
//                 background: "rgba(0,0,0,.08)",
//                 width: "100%",
//                 height: "100%",
//               }}
//             ></div>
//           )

//         if (objectURL) return <img src={objectURL} />

//         return null
//       })()}
//     </div>
//   )
// }

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "center",
      "&>*": {
        height: "100%",
      },
    },
  }
})
