import useMemoAPI from "hooks/useMemoAPI"
import { useObservable, useSubscription } from "observable-hooks"
import React from "react"
import { fromEventPattern } from "rxjs"
import { filter, switchMap } from "rxjs/operators"

export function useWidth(ref: HTMLElement | null) {
  const [width, setWidth] = React.useState(0)

  const width$ = useObservable(
    (inputs$) =>
      inputs$
        .pipe(
          switchMap(([ref]) =>
            fromEventPattern((handler) => {
              const observer = new ResizeObserver((entries) => {
                handler(entries[0].contentRect.width)
              })
              if (ref) {
                observer.observe(ref)
              }
              return () => {
                if (ref) {
                  observer.unobserve(ref)
                }
              }
            })
          )
        )
        .pipe(filter((a): a is number => typeof a === "number")),
    [ref]
  )

  useSubscription(width$, setWidth)

  return useMemoAPI({ width, width$ })
}
