import { Typography } from "@material-ui/core"

export function FieldsetTitle(props: { children: React.ReactNode }) {
  return (
    <Typography
      variant="body1"
      color="textSecondary"
      style={{ lineHeight: 1, marginBottom: 8 }}
    >
      {props.children}
    </Typography>
  )
}
