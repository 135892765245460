import moment from "moment-timezone"
import { setContext } from "@apollo/client/link/context"
import { getActiveWorkspaceID } from "../activeWorkspaceID"
import sessionID from "./sessionID"

export default () =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "Apollo-Require-Preflight": true,
        "session-id": sessionID,
        "active-workspace-id": getActiveWorkspaceID(),
        timezone: moment.tz.guess(),
      },
    }
  })
