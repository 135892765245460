import React from "react"
import useKanbanCardPageBoardItemAPI from "../hooks/useKanbanCardPageBoardItemAPI"

type KanbanCardPageBoardItemAPI = ReturnType<
  typeof useKanbanCardPageBoardItemAPI
>
const KanbanCardPageBoardItemContext = React.createContext(
  {} as KanbanCardPageBoardItemAPI
)
export default KanbanCardPageBoardItemContext
KanbanCardPageBoardItemContext.displayName = "KanbanCardPageBoardItemContext"
