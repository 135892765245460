import clsx from "clsx"
import React, { useEffect } from "react"
import { LinearProgress } from "@material-ui/core"

function DelayedLoadingBar({
  isLoading,
  showAfterMs = 500,
  className,
}: {
  isLoading: boolean
  showAfterMs?: number
  className?: string
}) {
  const id = React.useRef(null)

  const [show, setShow] = React.useState(false)

  useEffect(() => {
    clearTimeout(id.current)

    if (!isLoading) {
      setShow(false)
      return
    }

    id.current = setTimeout(() => {
      setShow(true)
    }, showAfterMs)
  }, [isLoading])

  if (!show) return null

  return (
    <LinearProgress
      className={clsx("delayed-loading-bar", className)}
    ></LinearProgress>
  )
}

export default DelayedLoadingBar
