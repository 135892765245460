import React, { useContext } from "react"
import { Button, Icon, IconButton } from "@material-ui/core"
import MembersContext from "../../contexts/MembersContext"
import LightTooltip from "components/LightTooltip/LightTooltip"

export default function CancelButton() {
  const { modeAPI } = useContext(MembersContext)

  return (
    <LightTooltip title="cancel edition">
      <IconButton size="small" edge="end" onClick={modeAPI.cancelEdit}>
        <Icon fontSize="small" color="primary">
          edit_off
        </Icon>
      </IconButton>
    </LightTooltip>
  )
}
