import { useApolloClient } from "@apollo/client";
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import { useCallback, useMemo, useState } from "react"
import { useActiveRoomID } from "contexts/activeRoom"
import { StartTypingDocument, StopTypingDocument } from "lib/graphql/operations"

export default function useTyping() {
  const client = useApolloClient()
  const { user } = useCurrentWorkspace()
  const userID = (user || {}).id
  const { activeRoomID: roomID } = useActiveRoomID()
  const [isTyping, setIsTyping] = useState(false)

  const variables = useMemo(
    () => ({
      userID,
      roomID,
    }),
    [userID, roomID]
  )

  return useMemo(
    () => ({
      startTyping() {
        if (!roomID || isTyping) return

        setIsTyping(true)

        return client
          .mutate({ mutation: StartTypingDocument, variables })
          .catch(console.error)
      },
      stopTyping() {
        if (!roomID || !isTyping) return

        setIsTyping(false)

        return client
          .mutate({ mutation: StopTypingDocument, variables })
          .catch(console.error)
      },
    }),
    [variables, isTyping]
  )
}
