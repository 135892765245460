import { ID } from "types/type"

export default function getAccountRootPathname(props: {
  accountID: ID | null | undefined
  guestUserID?: ID | null
}) {
  if (!props.accountID) return "/"

  if (props.guestUserID)
    return `/account/${props.accountID}/guest/${props.guestUserID}`

  return `/account/${props.accountID}`
}
