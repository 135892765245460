import clsx from "clsx"
import React from "react"
import { StyledProps } from "types/type"
import ImageAttachmentContext from "../contexts/ImageAttachmentContext"

export function ImageWithPlaceholder(
  props: { children: React.ReactNode } & StyledProps
) {
  return (
    <ImageAttachmentContext.Consumer>
      {(api) => {
        const height = api.height

        return (
          <div
            className={clsx("image-with-placeholder", props.className)}
            style={props.style}
          >
            <div
              style={{
                ...(() => {
                  if (!api.imageLoaded)
                    return {
                      height: 0,
                      overflow: "hidden",
                    }

                  return {}
                })(),
              }}
            >
              {props.children}
            </div>
            {(() => {
              if (typeof api.aspect !== "number") return null

              const aspect = api.aspect
              const show = api.aspect && !api.imageLoaded

              return (
                <div
                  style={{
                    height: show ? height : 0,
                    width: show ? height * aspect : 0,
                    // maxWidth: "100%",
                    background: "rgba(0,0,0,.02)",
                    overflow: "hidden",
                  }}
                ></div>
              )
            })()}
          </div>
        )
      }}
    </ImageAttachmentContext.Consumer>
  )
}
