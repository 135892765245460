import React from "react"
import useCountries from "hooks/useCountries"
import AutoComplete, { AutoCompleteProps } from "../AutoComplete/AutoComplete"

export default function CountriesAutoComplete(
  props: Omit<AutoCompleteProps, "options"> & {
    AutoComplete?: React.FC<AutoCompleteProps>
  }
) {
  const { countries } = useCountries()

  const options = React.useMemo(() => {
    const opts: AutoCompleteProps["options"] = countries.map((a) => ({
      label: a.locale,
      value: a.value.toUpperCase(),
    }))

    return opts
  }, [countries?.length])

  const p: AutoCompleteProps = {
    ...props,
    options,
    value: props.value?.toUpperCase(),
  }

  if (props.AutoComplete)
    return <props.AutoComplete {...p}></props.AutoComplete>

  return <AutoComplete {...p}></AutoComplete>
}
