import React from "react"
import _ from "lodash"
import { MessagesRendered } from "components/Room/types"
import { useObservableState, useObservable } from "observable-hooks"
import { combineLatest, Observable } from "rxjs"
import { filter, map, scan, switchMap } from "rxjs/operators"
import AppVisibility from "components/AppVisibility/AppVisibility"

export default function useSeenMessages(props: {
  messagesRendered$: Observable<MessagesRendered>
  minIndexToBeSeen?: number
}) {
  const { appIsVisible$ } = React.useContext(AppVisibility.Context)

  const seenMessages$ = React.useMemo(() => {
    return combineLatest([props.messagesRendered$, appIsVisible$]).pipe(
      filter(([__, isVisible]) => !!isVisible),
      map(([rendered]) => rendered),
      map((rendered) => {
        const { visibleStartIndex: start, visibleStopIndex: stop } = rendered
        const frameItems = Array.from(
          { length: stop - start + 1 },
          (_, index) => index + start
        )
        return frameItems
      }),
      scan((acc, value) => {
        acc = _.uniq([...acc, ...value]).sort()
        return acc
      }, [] as number[])
    )
  }, [props.messagesRendered$])

  const seen$ = useObservable(
    ($input) =>
      $input.pipe(
        filter((a): a is [number] => typeof a[0] === "number"),
        switchMap(([minIndex]) =>
          seenMessages$.pipe(
            map((seenMessages) => seenMessages.filter((m) => m >= minIndex))
          )
        )
      ),
    [props.minIndexToBeSeen]
  )

  const seen = useObservableState(seen$)

  return seen
}
