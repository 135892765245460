import { Typename } from "types/type"
import { DataProxy } from "apollo-cache"
import {
  RoomUpdate_CreatedRoom_Fragment,
  RoomUpdate_DeletedRoom_Fragment,
  RoomUpdate_ExcludedFromRoom_Fragment,
  RoomUpdate_NewRoom_Fragment,
  RoomUpdate_UpdatedRoomMembers_Fragment,
} from "lib/graphql/operations"
import { UpdateType } from "lib/graphql/types"
import updateFragment from "lib/updateFragment/updateFragment"
import updateRoomsQuery from "lib/updateRoomsQuery"
import _ from "lodash"

export function getUpdateCache(dataProxy: DataProxy) {
  return {
    addRoom(a: RoomUpdate_CreatedRoom_Fragment | RoomUpdate_NewRoom_Fragment) {
      return updateRoomsQuery.addRoom(a.room, dataProxy)
    },
    removeRoom(
      a: RoomUpdate_DeletedRoom_Fragment | RoomUpdate_ExcludedFromRoom_Fragment
    ) {
      return updateRoomsQuery.removeRoom(a, dataProxy)
    },

    updateRoomMembers(roomUpdate: RoomUpdate_UpdatedRoomMembers_Fragment) {
      return updateFragment(
        {
          typename: Typename.RoomMembersList,
          cacheIDParams: { roomID: roomUpdate.roomID },
          updateFn: (roomMembersList) => ({
            ...roomMembersList,
            list: _.uniqBy(
              [...(roomMembersList.list || []), ...roomUpdate.members],
              (a) => a.id
            ).filter((member) => {
              const filter = {
                [UpdateType.Add]: () => true,
                [UpdateType.Remove]: () => {
                  const toBeExcluded = roomUpdate.members.map((i) => i.id)
                  //individual is in the list to be exclude
                  const exclude = !!toBeExcluded.includes(member.id)

                  //keep him if he is not in the list to be excluded
                  return !exclude
                },
              }

              return filter[roomUpdate.updateType]()
            }),
          }),
        },
        dataProxy
      )
    },
  }
}
