import { Participant, VideoClient } from "@zoom/videosdk"
import { useCallback, useEffect, useRef } from "react"
import { Subject } from "rxjs"

export type ParticipantsUpdate = {
  allParticipants: Participant[]
  updatedParticipants?: Participant[]
}

export function useParticipantsUpdate$(zmClient: typeof VideoClient) {
  const participantsUpdate$ = useRef(new Subject<ParticipantsUpdate>())

  const callback = useCallback(
    (updatedParticipants?: Participant[]) => {
      const participants = zmClient.getAllUser()
      participantsUpdate$.current.next({
        allParticipants: participants,
        updatedParticipants,
      })
    },
    [zmClient]
  )

  useEffect(() => {
    if (!zmClient) return

    zmClient.on("user-added", callback)
    zmClient.on("user-removed", callback)
    zmClient.on("user-updated", callback)

    return () => {
      zmClient.off("user-added", callback)
      zmClient.off("user-removed", callback)
      zmClient.off("user-updated", callback)
    }
  }, [zmClient, callback])

  return participantsUpdate$.current
}
