import React from "react"
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableCellProps,
} from "@material-ui/core"

type Column = {
  name: string
  label: string
  // [key: string]: any // Para propiedades adicionales no especificadas
} & TableCellProps

interface HeadProps {
  columns: Column[]
  orderBy: string
  order: "asc" | "desc"
  onClickLabel: (data: { columnName: string }) => void
}

const Head: React.FC<HeadProps> = ({
  columns,
  orderBy,
  order,
  onClickLabel,
}) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((c, index) => (
          <TableCell {...c} key={`header-${index}`}>
            <TableSortLabel
              active={c.name === orderBy}
              direction={order}
              onClick={() => onClickLabel({ columnName: c.name })}
            >
              {c.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default Head
