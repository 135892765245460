import React from "react"
import _ from "lodash"
import DocumentFormContext from "../../contexts/DocumentFormContext"
import useDocumentFormAPI from "../../hooks/useDocumentFormAPI"
import { APIProps, DocumentStatusType } from "../../types"
import TwoPages from "../../../TwoPages/TwoPages"

export default function DocumentFormProvider(
  props: Omit<APIProps, "twoPagesAPI"> & {
    children: React.ReactNode
  }
) {
  return (
    <TwoPages.Provider>
      <TwoPages.Context.Consumer>
        {(twoPagesLayout) => {
          return (
            <APIProvider {...props} twoPagesAPI={twoPagesLayout}>
              {props.children}
            </APIProvider>
          )
        }}
      </TwoPages.Context.Consumer>
    </TwoPages.Provider>
  )
}

function APIProvider(
  props: APIProps & {
    children: React.ReactNode
  }
) {
  const status = (() => {
    if (props.status) return props.status

    const documentStatus = props.document?.workStatus

    if (!documentStatus) return null

    return {
      type: DocumentStatusType.workStatus,
      value: documentStatus,
    }
  })()

  const api = useDocumentFormAPI({
    ..._.omit(props, "children"),
    status,
  })

  return (
    <DocumentFormContext.Provider value={api}>
      {props.children}
    </DocumentFormContext.Provider>
  )
}
