import { IconButton } from "@material-ui/core"
import { GooglSearch as GooglSearchComponent } from "components/GoogleSearch/GoogleSearch"
import { Icon } from "components/Icon/Icon"
import LightTooltip from "components/LightTooltip/LightTooltip"
import { IndexedItemsContext } from "components/PageBoard/components/IndexedItems/IndexedItems"
import I18nContext from "contexts/i18nContext/i18nContext"
import React from "react"
import { FilterToggleableMenu } from "../FilterToggleableMenu/FilterToggleableMenu"
import { StyledProps } from "types/type"

export function GoogleSearch(props: StyledProps) {
  const api = React.useContext(IndexedItemsContext)

  const googleSearch = (
    <GooglSearchComponent
      {...props}
      TextFieldProps={{
        value: api.search.value,
        onChange: (e) => api.search.set(e.target.value),
        InputProps: {
          endAdornment: (() => {
            return (
              <React.Fragment>
                {(() => {
                  return (
                    <I18nContext.Consumer>
                      {(t) => {
                        return (
                          <LightTooltip
                            title={t["pickADate"]}
                            style={{
                              position: "relative",
                              top: -1,
                              marginRight: 2,
                            }}
                          >
                            <IconButton
                              onClick={() =>
                                api.dateIntervalSelectorApi.modal.open()
                              }
                            >
                              <Icon name="date_range"></Icon>
                            </IconButton>
                          </LightTooltip>
                        )
                      }}
                    </I18nContext.Consumer>
                  )
                })()}
                {(() => {
                  if (!api.selected.params && api.availableTypes.length > 1)
                    return (
                      <FilterToggleableMenu
                        style={{ marginRight: 10 }}
                      ></FilterToggleableMenu>
                    )

                  return null
                })()}
              </React.Fragment>
            )
          })(),
        },
      }}
    ></GooglSearchComponent>
  )

  return googleSearch
}
