import clsx from "clsx"
import Modal, { VariantProps } from "components/Modal/Modal"
import useOpenClose, { OpenCloseApi } from "hooks/useOpenClose"
import React from "react"
import { StyledProps } from "types/type"
import { useBottomModalProps } from "./useBottomModalProps"
import { useModalVariant } from "./useModalVariant"

/**
 * ### BottomAndPopoverModal

 * This component behaves in two different ways depending on the screen size.

 * On large screens, it acts as a popover, appearing at a specific location on the screen.

 * On small screens, it acts as a modal that expands from the bottom of the screen upwards.
 */
export function BottomAndPopoverModal(
  props: {
    popoverAnchorRef: HTMLElement | null
    openClose?: OpenCloseApi
    children:
      | React.ReactNode
      | ((props: {
          modalVariant: ReturnType<typeof useModalVariant>
        }) => React.ReactNode)
  } & StyledProps
) {
  const propsOpenClose = props.openClose
  const innerOpenClose = useOpenClose()

  const openClose = propsOpenClose || innerOpenClose

  const modalVariant = useModalVariant()
  const bottomModalProps = useBottomModalProps()

  const children = (() => {
    if (typeof props.children === "function") {
      return props.children({ modalVariant })
    }

    return props.children
  })()

  const ref = props.popoverAnchorRef

  return (
    <Modal
      className={clsx(props.className, "bottom-and-popover-modal")}
      style={props.style}
      isOpen={openClose.isOpen}
      onClose={openClose.close}
      {...(() => {
        let v: VariantProps = {
          variant: "popover",
          PopoverProps: {
            anchorEl: ref,
          },
        }

        if (modalVariant === "bottom-modal") {
          return bottomModalProps
        }

        return v
      })()}
    >
      {children}
    </Modal>
  )
}
