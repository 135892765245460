import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import {
  SnappedCarousel,
  SnappedCarouselProps,
} from "components/SnappedCarousel/SnappedCarousel"
import React from "react"
import ReactCrop, {
  PercentCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop"
import { StyledProps } from "types/type"
import { ImageEditorContext } from "./ImageEditorProvider"
import { EditorControls } from "./components/EditorControls/EditorControls"
import Color from "color"

export function ImageEditorRoot(props: StyledProps) {
  const api = React.useContext(ImageEditorContext)
  const c = useStyles({ isOpen: api.isOpen })

  const render = React.useCallback<SnappedCarouselProps["children"]>((args) => {
    return (
      <ImageEditorContext.Consumer>
        {(api) => {
          const src = api.validUrls[args.index]

          const a = api.imagesApi.getImageObjectURL(src)

          if (!a) return null

          return (
            <div className={c.renderWrapper}>
              {(() => {
                const setCrop = (crop: PercentCrop | null | undefined) => {
                  api.cropApi.setCrop({ url: src, crop })
                }

                const crop = api.cropApi.getCrop({ url: src })

                const onLoad: React.ReactEventHandler<HTMLImageElement> = (
                  e
                ) => {
                  const { naturalWidth: width, naturalHeight: height } =
                    e.currentTarget

                  const crop = (() => {
                    if (!api.aspect) {
                      const b: PercentCrop = {
                        unit: "%",
                        width: 80,
                        height: 80,
                        x: 10,
                        y: 10,
                      }

                      return b
                    }

                    return centerCrop(
                      makeAspectCrop(
                        {
                          // You don't need to pass a complete crop into
                          // makeAspectCrop or centerCrop.
                          unit: "%",
                          width: 90,
                        },
                        // 16 / 9,
                        api.aspect,
                        width,
                        height
                      ),
                      width,
                      height
                    )
                  })()

                  setCrop(crop)
                }

                return (
                  <>
                    <div className={c.cropWrapper}>
                      <ReactCrop
                        aspect={api.aspect}
                        // style={{ width: "100%", maxWidth: 1200 }}
                        crop={
                          api.cropApi.isActive.isTrue
                            ? crop || undefined
                            : undefined
                        }
                        onChange={(__, percentageCrop) =>
                          setCrop(percentageCrop)
                        }
                        ruleOfThirds
                        className={clsx("image-editor-crop", c.cropRoot)}
                      >
                        <img
                          src={a}
                          style={{ width: "100%", maxWidth: 1200 }}
                          onLoad={onLoad}
                        ></img>
                      </ReactCrop>
                    </div>
                  </>
                )
              })()}
            </div>
          )
        }}
      </ImageEditorContext.Consumer>
    )
  }, [])

  return (
    <div
      className={clsx(props.className, "image-editor", c.root)}
      style={props.style}
    >
      <SnappedCarousel
        activeIndex={api.activeIndex}
        setActiveIndex={api.setActiveIndex}
        children={render}
        className={clsx(c.carousel, "image-editor-carousel")}
        count={api.validUrls.length}
      ></SnappedCarousel>
      <EditorControls className={c.editorControls}></EditorControls>
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    renderWrapper: {
      overflowY: "auto",
      overflowX: "auto",
      width: "100%",
      display: "flex",
      alignItems: "center",
      maxHeight: "100%",
      height: "100%",
      paddingBottom: 16,
    },
    cropWrapper: {
      width: "100%",
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },
    editorControls: {
      width: "100%",
      position: "absolute",
      bottom: 0,
    },
    carousel: {
      position: "absolute",
      inset: 0,
      height: "100%",
      "&>*": {
        height: "100%",
      },
    },
    root: {
      position: "fixed",
      inset: 0,
      // opacity: 0.8,
      background: "rgba(0,0,0,0.9)",
      display: (props: { isOpen: boolean }) => (props.isOpen ? "flex" : "none"),
    },
    cropRoot: {
      // "&,&>*": {
      //   height: "100%",
      // },
    },
  }
})
