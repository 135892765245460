import _f from "lodash/fp"
import Helmet from "react-helmet"
import _ from "lodash"
import React, { useCallback, useContext } from "react"
import DocumentsDashboardContext from "../../contexts/DocumentsDashboardContext"
import Details from "../Details/index"
import ChatOverlayContext from "components/ChatOverlay/contexts/ChatOverlayContext"
import { overlayModes } from "components/ChatOverlay"

export default function ActivitiesRight({
  className = "",
  onClose,
  getWindowOverlayZIndex,
  isRenderedOnOverlay = false,
}) {
  const { openOverlay } = useContext(ChatOverlayContext)
  const { headersMinHeight } = useContext(DocumentsDashboardContext)

  const goToRoom = useCallback(
    ({ userID, roomID, documentID = "" }) => {
      const windowOverlayZIndex = getWindowOverlayZIndex() + 1
      openOverlay({
        zIndex: windowOverlayZIndex,
        roomIdentifier: { userID, roomID, documentID },
        mode: isRenderedOnOverlay
          ? overlayModes.WINDOW_OVERLAY
          : overlayModes.DRAGGABLE,
      })
    },
    [getWindowOverlayZIndex, isRenderedOnOverlay]
  )

  return (
    <DocumentsDashboardContext.Consumer>
      {({
        selectedEntryColor: selectedActivityStatusColor,
        entries: activities,
      }) => {
        const statusColor = selectedActivityStatusColor
        return (
          <>
            {isRenderedOnOverlay && (
              <Helmet>
                <meta name="theme-color" content={statusColor} />
              </Helmet>
            )}
            <Details.Root
              smallHeader={!isRenderedOnOverlay}
              className={className}
              onClose={onClose}
              goToRoom={goToRoom}
              hideBackButton={!isRenderedOnOverlay && activities?.length === 1}
              statusColor={statusColor}
              headerMinHeight={headersMinHeight}
            ></Details.Root>
          </>
        )
      }}
    </DocumentsDashboardContext.Consumer>
  )
}
