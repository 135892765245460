import { useApolloClient } from "@apollo/client";
import _ from "lodash"
import React from "react"
import { useState } from "react"
import associateCollection from "./utils/associateCollection"
import dissociateCollection from "./utils/dissociateCollection"
import useActiveResources from "hooks/useActiveResources"
import { DataProxy } from "apollo-cache"
import { CollectionItemFragment } from "lib/graphql/operations"
import useMemoAPI from "hooks/useMemoAPI"

export default function useCollectionAssociation(props: {
  documentID: string
  onAssociate?: (props: {
    collection: CollectionItemFragment
    apolloCache: DataProxy
  }) => void
  onDissociate?: (props: {
    collection: CollectionItemFragment
    apolloCache: DataProxy
  }) => void
}) {
  const client = useApolloClient()
  const { workspaceID } = useActiveResources()
  const [isLoading, setIsLoading] = useState(false)

  const asyncCall = React.useCallback(
    async (method: () => void) => {
      try {
        setIsLoading(true)
        await method()
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading]
  )

  const associate = React.useCallback(
    async function associate(p: { collectionID: string }) {
      return asyncCall(() =>
        associateCollection(
          {
            collectionID: p.collectionID,
            documentID: props.documentID,
            workspaceID,
            onAssociate: props.onAssociate,
          },
          client
        )
      )
    },
    [client, workspaceID, asyncCall, ...Object.values(props)]
  )

  const dissociate = React.useCallback(
    async function dissociate(p: { collectionID: string }) {
      return asyncCall(() =>
        dissociateCollection(
          {
            collectionID: p.collectionID,
            documentID: props.documentID,
            workspaceID,
            onDissociate: props.onDissociate,
          },
          client
        )
      )
    },
    [client, workspaceID, asyncCall, ...Object.values(props)]
  )

  return useMemoAPI({
    isLoading,
    dissociateCollection: dissociate,
    associateCollection: associate,
  })
}
