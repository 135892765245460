import useActiveResources from "hooks/useActiveResources"
import {
  ShopArticleFragment,
  ShopOrderPreferencesQueryResult,
  useShopOrderPreferencesQuery,
} from "lib/graphql/operations"
import React from "react"
import { ShopOrderPreferencesQueryVariables } from "lib/graphql/operations"
import { ShopOrderForm } from "../../../../ShopOrderForm/ShopOrderForm"
import { FormProps } from "../../../../ShopOrderForm/ShopOrderFormContext"
import { CartBody } from "./CartBody"
import { CartPageBody } from "./CartPageBody"
import { CartPageApiContext, CartPageApiProvider } from "./CartPageContext"

export type OnClickArticle = (props: {
  article: ShopArticleFragment
  index: number
}) => void

function CartPageProvider(props: {
  children: React.ReactNode
  onClickArticle?: OnClickArticle
  partnerIdIsEditable?: boolean
  partnerItemID?: string | null
}) {
  const [_, setFormKey] = React.useState(Math.random())

  return (
    <CartPageApiProvider partnerItemID={props.partnerItemID}>
      <CartPageApiContext.Consumer>
        {(api) => {
          return (
            <ShopOrderPreferencesQuery partnerItemID={api.partnerItemID || ""}>
              {(query) => {
                return (
                  <ShopOrderForm.Provider
                    articles={api.articles}
                    getQuantity={api.getQuantity}
                    onSubmit={async (...args) => {
                      await api.onSubmit(...args)
                      setFormKey(Math.random())
                    }}
                    onChange={(a) => {
                      type ChangePartnerEvent = {
                        name: "partnerParams"
                        value: {
                          itemID: string
                        }
                      }

                      const event = a.target as ChangePartnerEvent

                      if (event.name === "partnerParams") {
                        if (!event.value.itemID) return
                        api.onChangePartnerItemID(event.value.itemID)
                      }
                    }}
                    partnerIsEditable={props.partnerIdIsEditable}
                    initialFormValues={{
                      ...(() => {
                        const address =
                          query?.data?.shopOrderPreferences?.address

                        if (!!address)
                          return {
                            address,
                          }

                        return {}
                      })(),

                      partnerParams: {
                        itemID: api.partnerItemID,
                        // ...(cachedApi.value?.partnerParams || {}),
                      },
                    }}
                  >
                    {props.children}
                  </ShopOrderForm.Provider>
                )
                // <ShopCachedFormValues
                //   cacheKey={window.location.pathname}
                //   key={formKey}
                // >

                //   {(cachedApi) => {
                //     return (
                //     )
                //   }}
                // </ShopCachedFormValues>
              }}
            </ShopOrderPreferencesQuery>
          )
        }}
      </CartPageApiContext.Consumer>
    </CartPageApiProvider>
  )
}

export const CartPage = {
  Provider: CartPageProvider,
  Context: CartPageApiContext,
  Body: CartPageBody,
}

export type CartBodyProps = React.ComponentProps<typeof CartBody>

export type CachedApi = {
  value: FormProps["initialValues"] | null
  onChange: Exclude<FormProps["onChange"], undefined>
  onSubmit: Exclude<FormProps["onSubmit"], undefined>
}

function ShopOrderPreferencesQuery(props: {
  partnerItemID: string
  children: (value: ShopOrderPreferencesQueryResult | null) => React.ReactNode
}) {
  const variables: ShopOrderPreferencesQueryVariables = {
    workspaceID: useActiveResources().workspaceID,
    partnerItemID: props.partnerItemID || "",
  }

  const preferencesQuery = useShopOrderPreferencesQuery({
    skip: Object.values(variables).some((a) => !a),
    variables,
    fetchPolicy: "cache-and-network",
  })

  return <>{props.children(preferencesQuery)}</>
} //
