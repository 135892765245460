import { PartnerFormApiInput } from "components/PageBoard/components/PartnerForm/components/ProviderAndContext/usePartnerFormAPI"
import PageBoardContext from "components/PageBoard/contexts/PageBoardContext"
import useMemoAPI from "hooks/useMemoAPI"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { ItemTemplate } from "../ItemTemplate/ItemTemplate"
import { SecondPage } from "../SecondPage/SecondPage"

export function usePartnerFormMinimizedPage() {
  const openMiniPage = React.useContext(ItemTemplate.Context).twoPagesAPI
    ?.openMiniPage

  const pageBoardAPI = React.useContext(PageBoardContext)
  const api = React.useContext(ItemTemplate.Context)

  const openContactPage = React.useCallback<
    Exclude<PartnerFormApiInput["onClickContactChip"], undefined>
  >(
    (contact) => {
      if (!contact.id) return

      return api.twoPagesAPI.openHalfPage(function ContactPage(props) {
        return (
          <SecondPage
            onClose={props.onClose}
            getItemParams={{
              fetchItemProps: {
                type: PageBoardItemType.Contact,
                params: { contactID: contact.id },
              },
            }}
          ></SecondPage>
        )
      })
      // return openMiniPage?.(function Page(args) {
      //   return (
      //     <ContactFormPage
      //       onClose={args.onClose}
      //       summarized={true}
      //       contactID={contact.id}
      //       onClickOpenButton={() => {
      //         api.twoPagesAPI.openHalfPage(function ContactPage(props) {
      //           return (
      //             <SecondPage
      //               onClose={props.onClose}
      //               getItemParams={{
      //                 fetchItemProps: {
      //                   type: PageBoardItemType.Contact,
      //                   params: { contactID: contact.id },
      //                 },
      //               }}
      //             ></SecondPage>
      //           )
      //         })
      //       }}
      //     ></ContactFormPage>
      //   )
      // })
    },
    [pageBoardAPI, openMiniPage]
  )

  return useMemoAPI({ openContactPage })
}
