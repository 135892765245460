import { convertFromRaw, RawDraftContentState } from "draft-js"

export default function contentStateFromString(string: string) {
  let error, contentState

  try {
    contentState = convertFromRaw(JSON.parse(string) as RawDraftContentState)
  } catch (e) {
    error = e
  }

  if (error) return null

  return contentState
}
