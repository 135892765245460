import { useApolloClient } from "@apollo/client";
import React from "react"
import CalendarEventContext from "../../../../contexts/CalendarEventContext"
import {
  PageTextField,
  SecondPageAPI as SecondPageAPI,
} from "components/Page/Page"
import { useI18n } from "contexts/i18nContext/i18nContext"
import AddMembersInputPage from "../AddMembersInputPage/AddMembersInputPage"
import clsx from "clsx"
import readFragment from "lib/updateFragment/readFragment"
import { Typename } from "types/type"
import { UserFragment } from "lib/graphql/operations"
// import People from "components/People/People"
import { UpdateType } from "lib/graphql/types"
import People from "components/People/People"

export default function MembersInput(props: { className?: string }) {
  const ctx = React.useContext(CalendarEventContext)

  const secondPage = React.useContext(SecondPageAPI.Context)
  const t = useI18n()

  const label = t["add-members"]

  const openPage = () => {
    secondPage.openPage({
      Page: AddMembersInputPage,
    })
  }
  const client = useApolloClient()

  const users = ctx.form.values["members"]
    ?.filter((a) => a.updateType === UpdateType.Add)
    ?.map((a) => a.userID)
    .map((a) =>
      readFragment(
        { typename: Typename.User, cacheIDParams: { id: a } },
        client
      )
    )
    ?.filter((a): a is UserFragment => !!a)

  return (
    <div className={clsx("members-input", props.className)}>
      <PageTextField
        onClick={openPage}
        style={{ marginBottom: 8, paddingTop: 0 }}
        InputProps={{ readOnly: true, disableUnderline: true }}
        inputProps={{ style: { paddingTop: 2 } }}
        placeholder={label}
      ></PageTextField>
      {!!users && <People users={users}></People>}
    </div>
  )
}
