import { useQuery } from "@apollo/client";
import _ from "lodash"
import React, { useContext, useEffect } from "react"
import PresenceList from "components/PresenceList/index"
import ListItem from "../ListItem/ListeItem"
import EditPresenceListItem from "./components/EditPresenceListItem/EditPresenceListItem"
import MembersContext from "../../contexts/MembersContext"
import { RoomContext } from "components/Room/contexts/room"
import {
  RoomMemberFragment,
  UsersAllowedToBeAddedToTheRoomDocument,
} from "lib/graphql/operations"

export default function EditPresenceList() {
  const { searchMembersAPI } = useContext(RoomContext)

  const { classes: c } = useContext(MembersContext)

  const sortedAllowedMembers = React.useMemo(() => {
    return _.uniqBy(
      [
        ...(searchMembersAPI.roomMembers || []),
        ...(searchMembersAPI.allowedMembersToBeAdded || []),
      ],
      (a) => a.id
    )
  }, [searchMembersAPI.roomMembers, searchMembersAPI.allowedMembersToBeAdded])

  return (
    <PresenceList.Root
      // members={sortedAllowedMembers}
      members={sortedAllowedMembers}
      className={c.presenceList}
      ListItem={EditPresenceListItem}
      fetchMore={searchMembersAPI.fetchMore}
    />
  )
}

// const renderData = { thirdLine: () => null }

function renderItem({ roomMembersIDs }) {
  return ({ components: { item }, user }) => {
    return (
      <ListItem
        item={item}
        user={user}
        added={roomMembersIDs.includes(user.id)}
      />
    )
  }
}

function useUsersToBeAdded({ workspaceID, roomID, fetchPolicy }) {
  const queryResult = useQuery(UsersAllowedToBeAddedToTheRoomDocument, {
    variables: { workspaceID, roomID },
    fetchPolicy: fetchPolicy || "cache-and-network",
  })

  return {
    users: _.get(queryResult, "data.usersAllowedToBeAddedToTheRoom"),
    isLoading: _.get(queryResult, "loading"),
    refetchUsers: queryResult.refetch,
  }
}
