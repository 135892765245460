import { useApolloClient } from "@apollo/client";
import _ from "lodash"
import VirtualizedGrid from "components/VirtualizedGrid/VirtualizedGrid"
import React, { useCallback, useContext, useMemo } from "react"
import { makeStyles } from "@material-ui/core/styles"
// import useOnChange from "../../../../hooks/useOnChange"
import DocumentCard from "../../../DocumentCard/DocumentCard"
import cachedIDGetters from "lib/getCacheID/getCacheID"
import { DocumentListItemFragmentDoc } from "lib/graphql/operations"
import TodoOnceVisible from "components/TodoOnceVisible/TodoOnceVisible"
import clsx from "clsx"
import DocumentsGridContext from "../../context/DocumentsGridContext"

export default function Grid({
  className = "",
  showInfoButton = undefined,
  getLink = undefined,
  onClickDocument = (document) => undefined,
  columnCount = 4,
  renderDocument = undefined,
}) {
  const { paginatedDocuments, relatedCollectionsIDs } =
    useContext(DocumentsGridContext)

  const { fetchMore, queryData, total } = paginatedDocuments || {}

  const listID = _.get(queryData, "id")

  const RenderDocument = useCallback(
    function RenderDocument({ index, data: { total } }) {
      return (
        <div className={c.renderDocument}>
          <div className={c.documentWrapper}>
            <>
              {(function Fetcher() {
                const fetcherIndex = (total || 0) - 6

                if (index === fetcherIndex)
                  return (
                    <TodoOnceVisible
                      todoOnceVisible={fetchMore}
                    ></TodoOnceVisible>
                  )

                return null
              })()}
              <Document
                index={index}
                listID={listID}
                onClickDocument={onClickDocument}
                getLink={getLink}
                showInfoButton={showInfoButton}
                renderDocument={renderDocument}
              ></Document>
            </>
          </div>
        </div>
      )
    },
    [listID, renderDocument, fetchMore, getLink]
  )

  const c = useStyles()

  const itemData = useMemo(() => {
    return { total }
  }, [total])

  return (
    <div className={clsx(c.documents, className)}>
      <VirtualizedGrid
        key={(relatedCollectionsIDs || []).join(",")}
        columnCount={columnCount}
        count={total}
        itemData={itemData}
      >
        {RenderDocument}
      </VirtualizedGrid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  documents: {
    flexGrow: 1,
    // ...appBarPadding(theme),
    // padding: theme.spacing(2),
    height: "100%",
    width: "100%",
    maxHeight: "100%",
  },

  documentWrapper: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(1),
  },

  renderDocument: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "100%",
  },
  height100: {
    height: "100%",
  },
  gridItem: {
    "&>*": {
      height: "100%",
    },
  },
  itemChild: {
    height: "100%",
  },
}))

function getDoc({ index, listID }, client) {
  const id = cachedIDGetters.DocumentListItem({ index, listID })

  const listItem = client.readFragment({
    fragment: DocumentListItemFragmentDoc,
    fragmentName: "DocumentListItem",
    id,
  })

  const doc = _.get(listItem, "document")

  return doc
}

function Document({
  index,
  listID,
  onClickDocument,
  getLink,
  renderDocument,
  showInfoButton,
  className = "",
}) {
  const client = useApolloClient()

  const document = getDoc(
    // { index, listID: _.get(paginatedDocuments, "id") },
    { index, listID },
    client
  )

  if (!document) return null

  const defaultComponent = (
    <DocumentCard
      className={className}
      onClick={() => onClickDocument(document)}
      document={{
        ...document,
        ...(_.isFunction(getLink)
          ? { link: getLink({ id: document.id }) }
          : {}),
      }}
      showInfoButton={showInfoButton}
    ></DocumentCard>
  )

  return _.isFunction(renderDocument)
    ? renderDocument({ defaultComponent, document })
    : defaultComponent
}
