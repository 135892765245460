import React from "react"
import { Paper } from "@material-ui/core"
import PdfControls from "../PdfControls/PdfControls"
// import { PdfContext } from "../../contexts/PdfContext"

export default function PdfTopBar(props: { style?: React.CSSProperties }) {
  return (
    <Paper
      square
      style={{
        display: "flex",
        justifyContent: "flex-end",
        padding: 8,
        ...(props.style || {}),
      }}
    >
      <PdfControls></PdfControls>
    </Paper>
  )
}
