import { useApolloClient, useMutation } from "@apollo/client";
import _f from "lodash/fp"
import _ from "lodash"
import getRoomMessageList from "lib/getRoomMessageList"
import { RoomContext } from "../../../contexts/room"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import { useMemo, useContext, useCallback, useEffect, useRef } from "react"
import useActiveResources from "hooks/useActiveResources"
import updateRoomMessageList from "lib/updateRoomMessageList"
import { Subject } from "rxjs"
import { useSendMessageMutation } from "lib/graphql/operations"
import React from "react"
import ErrorController from "components/ErrorSnackbar/ErrorController"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function useSendMessage({
  onCacheUpdate,
  onProgress,
}: {
  onProgress?: (e: ProgressEvent) => void
  onCacheUpdate?: () => void
} = {}) {
  const { room } = React.useContext(RoomContext) || {}
  const r = useActiveResources()
  const t = useI18n()

  const errorAPI = React.useContext(ErrorController.Context)

  let activeRoomID = _.get(room, "id")

  const roomData$ = useRef(new Subject())

  useEffect(() => {
    if (!r.workspaceID) return
    roomData$.current.next(room)
  }, [!!r.workspaceID])

  const getOptimisticResponse = useOptimisticResponseGetter()

  const [mutate, { loading }] = useSendMessageMutation()

  const sendMessage = useCallback(
    function sendMessage({
      message = null,
      repliedMessageID = null,
      attachments = null,
      workspaceID: argWorkspaceID,
      roomID: argRoomID,
    }) {
      const roomID = argRoomID || activeRoomID
      const workspaceID = argWorkspaceID || r.workspaceID

      return mutate({
        variables: _.pickBy(
          {
            content: message,
            roomID,
            workspaceID,
            repliedMessageID,
            attachments,
          },
          (v) => typeof v !== "undefined"
        ),
        optimisticResponse: getOptimisticResponse({
          message,
          repliedMessageID,
          roomID,
          attachments,
        }),
        context: {
          fetchOptions: {
            onUploadProgress(e) {
              _f.isFunction(onProgress) && onProgress(e)
            },
          },
        },
        update: function update(cache, mutationResult) {
          const { newListItems, totalItems } =
            _.get(mutationResult, "data.sendMessage") || {}

          updateRoomMessageList(
            { totalItems, newListItems, roomID, workspaceID },
            cache
          )

          if (typeof onCacheUpdate === "function") onCacheUpdate(...arguments)
        },
      })
    },
    [
      mutate,
      activeRoomID,
      r.workspaceID,
      getOptimisticResponse,
      onCacheUpdate,
      onProgress,
    ]
  )

  return useMemo(() => {
    return {
      sendMessage({ ...args }) {
        if (!args.roomID && !activeRoomID) {
          const sub = roomData$.current.subscribe((roomData) => {
            sendMessage({ ...args, roomID: _.get(roomData, "id") })
            sub.unsubscribe()
          })

          return Promise.resolve()
        }

        return sendMessage(args)
      },
      isLoading: loading,
      sendFiles({ files }) {
        sendMessage({ message: "", attachments: files }).catch((e) => {
          const tooLarge = e.message?.includes("Request Entity Too Large")

          if (tooLarge) {
            errorAPI.showErrorOnUI(t.fileTooLarge)
          }
        })
      },
    }
  }, [loading, sendMessage, activeRoomID, errorAPI, t])
}

function useOptimisticResponseGetter() {
  const { workspace } = useCurrentWorkspace()
  const { room } = useContext(RoomContext)
  const client = useApolloClient()

  return function getOptimisticResponse({
    message,
    repliedMessageID,
    attachments,
    roomID,
  }) {
    const messageList = getRoomMessageList({ roomID }, client)
    const lastIndex = (messageList || {}).totalItems || undefined

    const optimisticResponse = {
      __typename: "Mutation",
      sendMessage: {
        totalItems: (lastIndex || 0) + 1,
        roomID: roomID,
        newListItems: [
          {
            index: lastIndex || 0,
            roomID: roomID,
            message: {
              id: Math.random() * -1,
              sentAt: new Date().toISOString(),
              editedAt: null,
              deletedAt: null,
              loading: true,
              content: message,
              repliedMessageListItem: null,
              __typename: "TextMessage",
              reactions: null,
              author: {
                id: _.get(workspace, "user.id"),
                name: _.get(workspace, "user.name"),
                __typename: "User",
              },
              workspaces: null,
              rooms: null,
              attachments: null,
            },
            __typename: "MessageListItem",
          },
        ],
        __typename: "RoomMessageListUpdate",
      },
    }

    return optimisticResponse
  }
}
