import { makeStyles } from "@material-ui/styles"
import React, { useCallback } from "react"
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab"
import { TextField } from "@material-ui/core"
import clsx from "clsx"

export default function Autocomplete({
  variant = undefined,
  getOptionLabel = (option) => option,
  options,
  value,
  onChange,
  onBlur = undefined,
  name = undefined,
  label = undefined,
  renderInput: renderInputProps = undefined,
  ...props
}) {
  const c = useStyles({})

  const onSelect = useCallback(
    (e, value) => onChange({ target: { name, value } }),
    [onChange]
  )

  const renderInput = useCallback(
    typeof renderInputProps === "function"
      ? renderInputProps
      : (params) => {
          return (
            <TextField
              {...params}
              variant={variant}
              // onChange={onChange}
              onBlur={onBlur}
              name={name}
              label={label}
            ></TextField>
          )
        },
    [renderInputProps]
  )

  return (
    <MuiAutocomplete
      {...props}
      className={clsx("autocomplete", c.autocomplete, props.className)}
      options={options}
      // classes={{}}
      // classes={{clearIndicator: }}
      noOptionsText={null}
      freeSolo
      autoComplete
      value={value}
      onChange={onSelect}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
    ></MuiAutocomplete>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    autocomplete: {
      "&>*": {
        width: "100%",
      },
    },
  }
})
