import useMemoAPI from "hooks/useMemoAPI"
import {
  PageBoardItemsQuery,
  usePageBoardItemsQuery,
} from "lib/graphql/operations"
import _ from "lodash"
import React from "react"
import {
  NewPageBoardItemFragment,
  PageBoardItemsSubscriptionDocument,
  usePageBoardItemsSubscriptionSubscription,
} from "../../lib/graphql/operations"
import { LiveQueryInput, useLiveQuery } from "../useLiveQuery"
import {
  PageBoardItemsHookResult,
  createUsePageBoardItems,
} from "./createUsePageBoardItems"
import { useFetchNextPage } from "./useFetchNextPage"

export const usePageBoardItems = createUsePageBoardItems({
  useHookApi: function usePageBoardItemsHook(args) {
    const variables = args.variables
    const { workspaceID } = variables

    const options = React.useMemo<QueryInput["options"]>(() => {
      return {
        variables,
        skip: !workspaceID,
        fetchPolicy: "cache-and-network",
      }
    }, [variables, workspaceID])

    const updateQuery = React.useCallback<
      Exclude<QueryInput["updateQuery"], undefined>
    >((prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev

      const updatedItems = subscriptionData.data.pageBoardItems?.items || []

      const toRemove = updatedItems.filter((u) => !!u.isDeleted)

      return {
        ...prev,
        pageBoardItems: {
          ...prev.pageBoardItems,
          pagination: {
            ...prev.pageBoardItems?.pagination,
            total:
              (prev.pageBoardItems?.pagination?.total || 0) +
              ((updatedItems?.length || 0) - (toRemove?.length || 0)),
          },
          items: _.uniqBy(
            [
              ...(prev.pageBoardItems?.items || []),
              ...(subscriptionData.data.pageBoardItems?.items || []),
            ],
            (a) => a.id
          )
            .sort((a, b) => {
              return a.lastUpdate > b.lastUpdate ? -1 : 1
            })
            .filter((a) => !toRemove.find((b) => b.id === a.id)),
        },
      }
    }, [])

    const [newItems, setNewItems] = React.useState<NewPageBoardItemFragment[]>(
      []
    )

    const onSubscriptionData = React.useCallback(
      (newItems: NewPageBoardItemFragment[]) => {
        setNewItems((oldItems) => {
          return _.uniqBy([...newItems, ...oldItems], (a) => a.id)
        })
      },
      []
    )
    usePageBoardItemsSubscriptionSubscription({
      variables: { workspaceID },
      onSubscriptionData: (data) => {
        const a = data.subscriptionData.data?.pageBoardItems?.items

        if (!a?.length) return

        onSubscriptionData(a)
      },
    })

    type QueryInput = LiveQueryInput<typeof usePageBoardItemsQuery>

    const queryInput: QueryInput = {
      useQuery: usePageBoardItemsQuery,
      options,
      subscriptionDocument: PageBoardItemsSubscriptionDocument,
      //if there is a search, disable subscription
      disableSubscription: !!(variables?.search || "")?.trim(),
      updateQuery,
      // onSubscriptionData,
    }

    const itemsQuery = useLiveQuery(queryInput)

    const fetchNextPage = useFetchNextPage({
      queryResult: itemsQuery,
      getResponse: (p: PageBoardItemsQuery | undefined) => p?.pageBoardItems,
      updateQuery(p) {
        const query: PageBoardItemsQuery = {
          __typename: "Query",
          pageBoardItems: {
            __typename: "PageBoardItemsResponse",
            pagination: p.pagination,
            items: p.items || [],
          },
        }

        return query
      },
    })

    const hookResult: PageBoardItemsHookResult = {
      fetchingResponse: itemsQuery.data?.pageBoardItems,
      fetchNextPage,
      refetch: itemsQuery.refetch,
    }

    return useMemoAPI({ ...hookResult, itemsQuery })
  },
})

// function (props: PageBoardItemsInput) {
//   const throttledSearch = useThrottledValue(props.search, 1000)
//   const throttledPeriod = props.period

//   const workspaceID = useActiveResources().workspaceID

//   const [newItems, setNewItems] = React.useState<NewPageBoardItemFragment[]>([])

//   useOnChange({
//     value: workspaceID,
//     onChange: () => {
//       setNewItems([])
//     },
//   })

//   const types = React.useMemo(() => {
//     if (typeof props.types === "function") {
//       return props.types({ throttledSearch })
//     }

//     return props.types
//   }, [props.types])

//   const formatDate = (date: Moment | null | undefined) => {
//     if (!date) return null
//     return date.format("YYYY-MM-DD")
//   }

//   const formatPeriod = (period: Period | null | undefined) => {
//     const a = {
//       from: formatDate(period?.start),
//       to: formatDate(period?.end),
//     }

//     return [a.from, a.to].filter((a) => !!a).join("-")
//   }

//   const variables = React.useMemo<QueryInput["options"]["variables"]>(() => {
//     return {
//       search: (throttledSearch || "").trim(),
//       types,
//       workspaceID,
//       ...(() => {
//         if (!throttledPeriod?.start) return {}

//         return {
//           timeInterval: {
//             start: throttledPeriod.start?.toISOString(),
//             end: (throttledPeriod.end || throttledPeriod.start)
//               .endOf("day")
//               .toISOString(),
//           },
//         }
//       })(),
//       pagination: {
//         from: 0,
//         size: 30,
//       },
//     }
//   }, [
//     throttledSearch,
//     JSON.stringify(types),
//     workspaceID,
//     formatPeriod(throttledPeriod),
//   ])

//   const onSubscriptionData = React.useCallback(
//     (newItems: NewPageBoardItemFragment[]) => {
//       setNewItems((oldItems) => {
//         return _.uniqBy([...newItems, ...oldItems], (a) => a.id)
//       })
//     },
//     []
//   )

//   const options = React.useMemo<QueryInput["options"]>(() => {
//     return {
//       variables,
//       skip: !workspaceID,
//       fetchPolicy: "cache-and-network",
//     }
//   }, [variables, workspaceID])

//   const updateQuery = React.useCallback<
//     Exclude<QueryInput["updateQuery"], undefined>
//   >((prev, { subscriptionData }) => {
//     if (!subscriptionData.data) return prev

//     const updatedItems = subscriptionData.data.pageBoardItems?.items || []

//     const toRemove = updatedItems.filter((u) => !!u.isDeleted)

//     return {
//       ...prev,
//       pageBoardItems: {
//         ...prev.pageBoardItems,
//         pagination: {
//           ...prev.pageBoardItems?.pagination,
//           total:
//             (prev.pageBoardItems?.pagination?.total || 0) +
//             ((updatedItems?.length || 0) - (toRemove?.length || 0)),
//         },
//         items: _.uniqBy(
//           [
//             ...(prev.pageBoardItems?.items || []),
//             ...(subscriptionData.data.pageBoardItems?.items || []),
//           ],
//           (a) => a.id
//         )
//           .sort((a, b) => {
//             return a.lastUpdate > b.lastUpdate ? -1 : 1
//           })
//           .filter((a) => !toRemove.find((b) => b.id === a.id)),
//       },
//     }
//   }, [])

//   usePageBoardItemsSubscriptionSubscription({
//     variables: { workspaceID },
//     onSubscriptionData: (data) => {
//       const a = data.subscriptionData.data?.pageBoardItems?.items

//       if (!a?.length) return

//       onSubscriptionData(a)
//     },
//   })

//   type QueryInput = LiveQueryInput<typeof usePageBoardItemsQuery>

//   const queryInput: QueryInput = {
//     useQuery: usePageBoardItemsQuery,
//     options,
//     subscriptionDocument: PageBoardItemsSubscriptionDocument,
//     //if there is a search, disable subscription
//     disableSubscription: !!(variables?.search || "")?.trim(),
//     updateQuery,
//     // onSubscriptionData,
//   }

//   const itemsQuery = useLiveQuery(queryInput)

//   const fetchNextPage = useFetchNextPage(itemsQuery)

//   return useMemoAPI({ throttledSearch, itemsQuery, fetchNextPage, newItems })
// }

/** @deprecated */
export default usePageBoardItems
