import { ButtonBase, Icon, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { useContext } from "react"
import DetailsTabsContext from "../../contexts/DetailsTabsContext"
import DetailsTabsIDs from "../../lib/detailsTabsIDs"

export default function GoToDocumentTabButton({
  children,
  classes: { icon: iconClass = "" } = {},
  iconStyle = {},
}) {
  const { tabID, goToDocumentTab } = useContext(DetailsTabsContext)
  const isDocumentTab = tabID === DetailsTabsIDs.DOCUMENT
  const c = useStyles({ iconIsVisible: !isDocumentTab })

  return (
    <ButtonBase
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
      disabled={isDocumentTab}
      className={clsx("go-to-document-tab-button", c.root)}
      onClick={goToDocumentTab}
    >
      {children}
      <Icon
        className={clsx(isDocumentTab && c.none, iconClass)}
        style={iconStyle}
      >
        launch
      </Icon>
    </ButtonBase>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "&>*:first-child": {
        marginRight: ({ iconIsVisible }) =>
          iconIsVisible ? theme.spacing(0.5) : "initial",
      },
    },
    none: {
      display: "none !important",
    },
  }
})
