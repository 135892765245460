import { ContentState } from "draft-js"

export default function firstNonEmptyParagraphString(
  contentState: ContentState
) {
  const blockWithText = contentState
    .getBlocksAsArray()
    .filter(Boolean)
    .find((c) => !!c.getText())

  if (!blockWithText) return ""

  return blockWithText.getText()
}
