import _ from "lodash"
import React from "react"
import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import EditorModal, { EditorModalProps } from "../EditorModal/EditorModal"

export default function AddCommentModal() {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const modal = api.comments.addCommentModal

  const onSave = React.useCallback<EditorModalProps["onSave"]>(
    async function onSave(text) {
      await modal.addComment(text)
      // return clearEditor()
    },
    [modal.addComment]
  )

  const t = useI18n()

  return (
    <EditorModal
      editable
      onClose={modal.close}
      namespace="add-comment-modal"
      markdownMode={false}
      isOpen={modal.isOpen}
      initialValue={""}
      onSave={onSave}
      saveButtonText={t["send"]}
    ></EditorModal>
  )
}
