import React from "react"
// import _ from "lodash"
import useMemoAPI from "hooks/useMemoAPI"
import { NewPageBoardItem_KanbanCardPageBoardItem_Fragment } from "lib/graphql/operations"
import useCard from "./useCard"
import useImages from "./useImages"
import useKanbanCardActivities from "./useKanbanCardActivities"
import useKanbanComments from "./useKanbanComments"
import { RootContainerAPI } from "hooks/useRootContainerAPI"
import { ItemTemplate } from "../../ItemTemplate/ItemTemplate"
import { useEvents$ } from "./useEvents$"

export default function useKanbanCardPageBoardItemAPI(props: {
  entry: NewPageBoardItem_KanbanCardPageBoardItem_Fragment
}) {
  const cardID = props.entry.card?.cardID || ""
  const card = useCard({ cardID })
  const images = useImages({ cardID })

  const pageBoardAPI = React.useContext(ItemTemplate.Context)

  const activities = useKanbanCardActivities({ cardID })

  const events$ = useEvents$()

  const comments = useKanbanComments({ cardID })

  const viewport = useViewportVariables({
    root: pageBoardAPI.rootContainer || null,
  })

  return useMemoAPI({
    card,
    images,
    activities,
    comments,
    events$,
    viewport,
    containerRef: comments.rendered.containerRef,
  })
}

function useViewportVariables(props: { root: RootContainerAPI | null }) {
  const variables = React.useMemo(() => {
    if (!props.root) return null

    return {
      isSmallView: props.root.width <= 509,
    }
  }, [props.root?.width])

  return useMemoAPI(variables)
}
