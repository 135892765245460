import { ContentState, convertToRaw } from "draft-js"
import _ from "lodash"
import { useMemo } from "react"
import { modes } from "../../hooks/useFooterApi/constants"
import { useFooterContext } from "../../contexts/useFooterContext"

export function useModeProps() {
  const { selectedMessage, mode } = useFooterContext()

  const contentStateFromText = (text) =>
    JSON.stringify(convertToRaw(ContentState.createFromText(text || "")))

  const api = useMemo(
    () => ({
      [modes.NO_SELECTED_MESSAGE]: {
        getDefaultValue: contentStateFromText.bind(null, ""),
        submitButtonIcon: "send",
      },
      [modes.REPLY_MESSAGE]: {
        getDefaultValue: contentStateFromText.bind(null, ""),
        submitButtonIcon: "send",
      },
      [modes.EDIT_MESSAGE]: {
        getDefaultValue: () => (selectedMessage || {}).content || null,
        submitButtonIcon: "create",
      },
    }),
    [_.get(selectedMessage, "id")]
  )

  return useMemo(() => {
    return {
      emptyTextObject: api[mode].getDefaultValue(),
      submitButtonIcon: api[mode].submitButtonIcon,
    }
  }, [mode])
}
