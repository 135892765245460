import React from "react"
import { ArticleDetailPanelContext } from "../../ArticleDetailPanelContext"

import _ from "lodash"
import { ChipProps, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import { InlinedChips } from "components/InlinedChips/InlinedChips"

export function Attributes() {
  const api = React.useContext(ArticleDetailPanelContext)

  const selectedVariation = api.selectedVariation

  const c = useStyles()

  const thisArticleAttributes = React.useMemo(() => {
    return api.variations
      .map((a) => {
        return {
          ..._.omit(a, "variations"),
          value: a.variations?.find((v) =>
            v.articles?.map((a) => a.article?.id).includes(api.article?.id)
          )?.variationName,
        }
      })
      .filter((a) => !!a.value)
  }, [api.variations, api.article?.id])

  const indexedVariations = React.useMemo(() => {
    return _.keyBy(api.variations, (v) => v.displayName || "")
  }, [api.variations])

  return (
    <div className={clsx("articles-attributes", c.articlesAttributes)}>
      {thisArticleAttributes?.map((articleAttribute) => {
        return (
          <div key={articleAttribute.displayName} className={c.attribute}>
            <Typography color="textPrimary" style={{ marginBottom: 4 }}>
              {articleAttribute.displayName}:{" "}
              <Typography
                style={{ display: "inline", fontWeight: 700 }}
                color="textPrimary"
              >
                {articleAttribute.value}
              </Typography>
            </Typography>

            {(() => {
              const articleAttributeVariations =
                indexedVariations[articleAttribute.displayName || ""]

              if (!articleAttributeVariations?.variations?.length) return null

              const isSelected = (variationName: string) =>
                articleAttribute.value === variationName

              const isClicked = (variationName: string) => {
                if (
                  articleAttribute.displayName !==
                  selectedVariation?.attributeName
                )
                  return false
                return variationName === selectedVariation?.variationName
              }

              const chipProps: ChipProps[] =
                articleAttributeVariations.variations.map((a): ChipProps => {
                  const chipIsClicked = isClicked(a.variationName || "")
                  // const chipIsSelected = isSelected(a.variationName || "")
                  const chipIsSelected = !!a.isSelected

                  const hasNoArticle = !a.isSelectable

                  return {
                    key: a.variationName,
                    label: a.variationName,
                    variant:
                      chipIsClicked || chipIsSelected ? "default" : "outlined",
                    color:
                      (chipIsSelected && "primary") ||
                      (chipIsClicked && "secondary") ||
                      "default",
                    // chipIsClicked || chipIsSelected ? "primary" : "default",
                    size: "small",
                    disabled: chipIsSelected ? false : hasNoArticle,
                    onClick: !hasNoArticle
                      ? () =>
                          api.setSelectedVariation({
                            variationName: a.variationName,
                            attributeName: articleAttribute.displayName || "",
                          })
                      : undefined,
                  }
                })

              return <InlinedChips wrap chips={chipProps}></InlinedChips>
            })()}
          </div>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  articlesAttributes: {
    "&>*:not(:last-child)": {
      marginBottom: theme.spacing(0.5),
    },
  },

  attribute: {},
}))
