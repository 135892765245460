import {
  ButtonProps,
  Icon,
  makeStyles,
  TextFieldProps,
} from "@material-ui/core"
import React from "react"
import { Button } from "@material-ui/core"
import CollectionSelection from "../CollectionSelection/CollectionSelection"
import { useI18n } from "contexts/i18nContext/i18nContext"
import TypeSelection from "../TypeSelection/TypeSelection"
import DocumentCollectionsContext from "components/DocumentCollections/contexts/DocumentCollectionsContext"
import Actions from "../../../Actions/Actions"
import clsx from "clsx"
import useTypographyClass from "hooks/useTypographyClass"

export default function CollectionForm(props: {
  className?: string
  isSmall?: boolean
  TextField?: React.FC<TextFieldProps>
  SaveButtonProps?: ButtonProps
  classes?: { suggestionsItem: string }
  AddPartnerButtonProps?: ButtonProps
  renderActions?: (props: {
    defaultComponent?: React.ReactNode
    components: {
      addPartnerButton?: React.ReactNode | null
      cancelButton?: React.ReactNode | null
      saveButton?: React.ReactNode | null
    }
  }) => React.ReactNode
}) {
  const ctx = React.useContext(DocumentCollectionsContext)

  const c = useStyles()

  const {
    form: form,
    setSelectedCollectionID: setCollectionID,
    collection,
    resetSelectedCollectionID: resetCollectionID,
    closePanel,
    onSave,
  } = ctx

  const isMobile = !!props.isSmall

  const t = useI18n()

  // const tClass = useTypographyClass()

  return (
    <div className={clsx("collection-form", props.className, c.root)}>
      <TypeSelection
        TextField={props.TextField}
        // classes={{ suggestionsItem: props?.classes?.suggestionsItem }}

        classes={{ textField: c.input }}
      />
      <CollectionSelection
        TextField={props.TextField}
        type={form.type}
        text={form.collectionText}
        // classes={{ suggestionsItem: props?.classes?.suggestionsItem }}
        classes={{ textField: c.input }}
        onSelectCollectionID={setCollectionID}
        onChangeTextField={(value) => {
          form.setCollectionText(value || "")
          if (value?.trim() !== collection?.title?.trim()) {
            resetCollectionID()
          }
        }}
      />

      {(() => {
        const addPartnerButton = (() => {
          const showButton = ctx.typeOverview?.hasPartnerAssociated

          if (!showButton) return null

          return (
            <React.Fragment>
              <Button
                color="primary"
                style={{ visibility: !showButton ? "hidden" : "visible" }}
                variant="contained"
                onClick={() => ctx.form.setViewID("add-new-partner")}
                // size="small"
                {...(props.AddPartnerButtonProps || {})}
              >
                <Icon fontSize="small">add</Icon> {ctx.typeOverview?.title}
              </Button>
            </React.Fragment>
          )
        })()

        const cancelButton = (
          <Button
            color="primary"
            onClick={closePanel?.bind(undefined)}
            size="small"
          >
            {t.cancel}
          </Button>
        )
        const saveButton = (
          <Button
            color="primary"
            // size="small"
            variant="contained"
            type="submit"
            disabled={
              !form.collectionText ||
              !form.type ||
              (!collection && !!ctx.typeOverview?.hasPartnerAssociated)
            }
            onClick={onSave}
            {...(props.SaveButtonProps || {})}
          >
            {/* {t.save} */}
            {/** TODO: include translation */}
            {collection ? t.save : "Anlegen + Speichern"}{" "}
          </Button>
        )

        const components = {
          addPartnerButton,
          cancelButton,
          saveButton,
        }

        const defaultComponent = (
          <Actions
            isStack={isMobile}
            leftActions={components.addPartnerButton}
            rightActions={
              <React.Fragment>
                {components.cancelButton}
                {components.saveButton}
              </React.Fragment>
            }
          ></Actions>
        )

        if (props.renderActions)
          return props.renderActions({ defaultComponent, components })

        return defaultComponent
      })()}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    input: {
      width: "100%",
    },
    root: {
      "&>*": {
        marginBottom: theme.spacing(1),
      },
    },
  }
})
