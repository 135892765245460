import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import React from "react"
import useVariousImagesWithAuth from "hooks/useImageWithAuth/useVariousImagesWithAuth"
import useTrueFalse from "hooks/useTrueFalse"
import { PercentCrop } from "react-image-crop"

const F = contextAndProviderFactory({
  hookApi: function useImageEditor(props: {
    imagesUrls: string[]
    isOpen: boolean
    aspect?: number
    onClose: () => void
    submitCrop?: (props: {
      percentCrop: PercentCrop | undefined | null
      imageUrl: string
    }) => void
  }) {
    const [activeIndex, setActiveIndex] = React.useState(0)

    const validUrls = React.useMemo(() => {
      return props.imagesUrls.filter((url) => {
        try {
          // new URL(url)
          return true
        } catch (e) {
          return false
        }
      })
    }, [props.imagesUrls])

    const a = useVariousImagesWithAuth({ sources: validUrls })
    const cropApi = useCropApi()

    const submitCrop = React.useCallback(() => {
      const url = validUrls[activeIndex]

      if (!url) return

      const crop = cropApi.getCrop({ url })

      props.submitCrop?.({ percentCrop: crop, imageUrl: url })
    }, [validUrls, cropApi.getCrop, activeIndex, props.submitCrop])

    return useMemoAPI({
      ...props,
      validUrls,
      activeIndex,
      setActiveIndex,
      imagesApi: a,
      cropApi,
      submitCrop,
    })
  },
})

function useCropApi() {
  const isActive = useTrueFalse(true)

  type Url = string

  const [cropMap, setCropMap] = React.useState<
    Map<Url, PercentCrop | undefined | null>
  >(new Map())

  const setCrop = React.useCallback(
    (props: { url: Url; crop: PercentCrop | undefined | null }) => {
      setCropMap((state) => {
        const newState = new Map(state)
        newState.set(props.url, props.crop)
        return newState
      })
    },
    []
  )

  const getCrop = React.useCallback(
    (props: { url: Url }) => {
      return cropMap.get(props.url)
    },
    [cropMap]
  )

  // const crop =

  return useMemoAPI({
    isActive,
    getCrop,
    setCrop,
  })
}

export const ImageEditorProvider = F.Provider
export const ImageEditorContext = F.Context
