import { useApolloClient } from "@apollo/client"
import { EnhancedPage } from "components/EnhancedPage/EnhancedPage"

import Room from "components/Room/Room"

import KeyListeners, { KeyListener } from "components/KeyListeners/KeyListeners"
import { PageComponent } from "components/Page/Page"
import PageBoardContext from "components/PageBoard/contexts/PageBoardContext"
import { RoomAPI } from "components/Room/Room"
import { TwoPagesAPI } from "components/TwoPages/TwoPages"
import I18nContext from "contexts/i18nContext/i18nContext"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import useOpenClose from "hooks/useOpenClose"
import {
  AttachmentFragment,
  Attachment_FileAttachment_Fragment,
  NewPageBoardItem_RoomPageBoardItem_Fragment,
  useIsAssistantWorkingSubscription,
} from "lib/graphql/operations"
import { PageBoardItemType, UpdateType } from "lib/graphql/types"
import updatePageBoardItemsQueryCache from "lib/updatePageBoardItemsQueryCache"
import { useSubscription } from "observable-hooks"
import React from "react"
import { Subject } from "rxjs"
import { OpenKanbanCardPayload } from "types/type"
import openKanbanCard from "../../../../../../../lib/openKanbanCard"
import { HistoryThread } from "../../../../../../HistoryThread/HistoryThread"
import { SecondPage } from "../../SecondPage/SecondPage"
import { getSecondPage as getItemPage } from "../../SecondPage/getSecondPage"
import { ShoppingArticlePage } from "../components/Shopping/ShoppingArticlePage/ShoppingArticlePage"
import { RoomPageBoardItemProps } from "../types"
import { RoomConference } from "components/PageBoard/components/PageBoardRoot/Conference/RoomConference"
// import { VideoConference } from "../../../../../../../../Room/components/VideoConference/VideoConference"
// import { StyledProps } from "@material-ui/core"

export type RoomPageBoardApiInput = RoomPageBoardItemProps & {
  twoPagesAPI: TwoPagesAPI | null
  roomAPI: RoomAPI | null
}

const videoCallPageID = "video-call"

export default function useRoomPageBoardItemAPI(props: RoomPageBoardApiInput) {
  const pageBoardApi = React.useContext(PageBoardContext)

  const searchField = useOpenClose(false)

  const r = useActiveResources()

  const toggleVideoCallPage = React.useCallback(() => {
    if (props.twoPagesAPI?.secondPage.viewID === videoCallPageID) {
      return props.twoPagesAPI.secondPage.hidden.toggle()
    }

    props.twoPagesAPI?.secondPage.openPage({
      id: videoCallPageID,
      Page: function VideoCallPage(props: {
        onClose: () => any
        hide?: () => any
        toggleVisibility?: () => any
      }) {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return (
                <EnhancedPage
                  title={t.conference.conference}
                  // onClose={props.onClose}
                  onClose={props.hide}
                  style={{ height: "100%" }}
                  body={
                    <Room.Context.Consumer>
                      {(room) => {
                        if (!room.roomID) return null

                        return (
                          <RoomConference
                            // roomID="AizDs9G6dT35oSmKm"
                            roomID={room.roomID}
                            style={{ height: "100%" }}
                            // onLeave={() => console.log("left")}
                            onLeave={props.onClose}
                          ></RoomConference>
                        )
                      }}
                    </Room.Context.Consumer>

                    // <Room.VideoConference
                    //   style={{ height: "100%" }}
                    //   onLeave={props.onClose}
                    // ></Room.VideoConference>
                  }
                ></EnhancedPage>
              )
            }}
          </I18nContext.Consumer>
        )
      },
    })
  }, [
    props.twoPagesAPI?.secondPage.openPage,
    props.twoPagesAPI?.secondPage.viewID,
  ])

  const openMembersList = React.useCallback(() => {
    props.twoPagesAPI?.secondPage.openPage({
      id: "members-list",
      Page: function MemberList(props: { onClose: () => any }) {
        return (
          <EnhancedPage
            title="Members"
            onClose={props.onClose}
            style={{ height: "100%" }}
            body={<Room.Members style={{ height: "100%" }}></Room.Members>}
          ></EnhancedPage>
        )
      },
    })
  }, [props.twoPagesAPI?.secondPage.openPage])

  const client = useApolloClient()

  const deleteItem = React.useCallback(() => {
    updatePageBoardItemsQueryCache(
      {
        item: props.entry,
        workspaceID: r.workspaceID,
        updateType: UpdateType.Remove,
      },
      client as any
    )

    pageBoardApi.selected.setNull()
  }, [r.workspaceID, client, pageBoardApi.selected.setNull, props.entry])

  const emptyObservable$ = React.useRef(new Subject<void>()).current

  useSubscription(props.roomAPI?.deleteRoom$ || emptyObservable$, deleteItem)
  useSubscription(
    props.roomAPI?.openKanbanCard$ || (emptyObservable$ as any),
    React.useCallback(
      (payload: OpenKanbanCardPayload) => {
        if (!payload.cardID) return
        if (!props.twoPagesAPI) return
        return openKanbanCard(payload, { twoPagesAPI: props.twoPagesAPI })
      },
      [props.twoPagesAPI]
    )
  )

  useSubscription(
    props.roomAPI?.template.goToMembersInvitation$ || emptyObservable$,
    openMembersList
  )

  const historyThread = React.useContext(HistoryThread.Context)

  useSubscription(
    props.roomAPI?.clickAttachment$ || (emptyObservable$ as any),
    React.useCallback(
      (attachment: AttachmentFragment) => {
        const item = (() => {
          if (!attachment) return null

          if (attachment.__typename === "PageBoardItemAttachment") {
            return attachment.item
          }

          if (attachment.__typename === "NotFoundAttachment") {
            return
          }

          const a = (attachment as Attachment_FileAttachment_Fragment).file

          if (a?.__typename === "PageBoardItemFile") {
            return a?.item
          }

          return null
        })()

        if (!item || !item.itemID) return null

        if (!!historyThread.alreadyVisited(item)) return

        let Page: PageComponent | null = null

        if (item.type === PageBoardItemType.Document) {
          Page = getItemPage({
            itemParams: {
              type: PageBoardItemType.Document,
              itemID: item.itemID,
            },
            initialParams: { initialPage: "viewer" },
          })
        } else if (item.type === PageBoardItemType.ShopArticle) {
          Page = ({ onClose }) => {
            return (
              <SecondPage
                onClose={onClose}
                getItemParams={{
                  // type: PageBoardItemType.ShopArticle,
                  // itemID: item.itemID,
                  itemParams: {
                    type: PageBoardItemType.ShopArticle,
                    itemID: item.itemID || "",
                  },
                }}
              >
                {({ api }) => {
                  return (
                    <ShoppingArticlePage
                      getItemParams={{ item: api.item }}
                      onClose={onClose}
                    ></ShoppingArticlePage>
                  )
                }}
              </SecondPage>
            )
          }
        } else {
          Page = getItemPage({
            itemParams: { itemID: item.itemID, type: item.type },
          })
        }

        if (!Page) return

        props.twoPagesAPI?.openHalfPage(Page)
      },
      [
        pageBoardApi.selected.setParams,
        props.twoPagesAPI?.openHalfPage,
        historyThread.alreadyVisited,
      ]
    )
  )

  KeyListeners.useEscapeListener(searchField.close, {
    enabled: !!searchField.isOpen,
  })

  const altFKey = React.useMemo(() => {
    const a: KeyListener = { altKey: true, fn: searchField.open, code: "KeyF" }
    return a
  }, [searchField.open])

  KeyListeners.useListener(altFKey, { enabled: !searchField.isOpen })

  const assistant = useIsAssistantWorking({ entry: props.entry })

  return useMemoAPI({
    ...props,
    isVideoCallPanelOpen:
      props.twoPagesAPI?.secondPage.viewID === videoCallPageID,
    openMembersList,
    toggleVideoCallPage,
    assistantIsWorking: assistant.isAssistantWorking,
    searchField,
  })
}

export type RoomPageBoardItemAPI = ReturnType<typeof useRoomPageBoardItemAPI>

function useIsAssistantWorking(props: {
  entry?: NewPageBoardItem_RoomPageBoardItem_Fragment | undefined
}) {
  const r = useActiveResources()

  const b = useIsAssistantWorkingSubscription({
    skip: !r.workspaceID || !props.entry?.itemID,
    variables: {
      workspaceID: r.workspaceID || "",
      type: PageBoardItemType.Room,
      itemID: props.entry?.itemID || "",
    },
  })

  const isAssistantWorking = b.data?.isAssistantWorking || false

  return { isAssistantWorking }
}
