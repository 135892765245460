import { concat, split } from "@apollo/client"
import {
  ErrorResponse,
  onError as onErrorLink,
} from "@apollo/client/link/error"
import { getMainDefinition } from "apollo-utilities"
import authLink from "./authLink"
import httpLink from "./httpLink"
import wsLink, { WsLinkProps } from "./wsLink"

export default function link(props: LinkProps) {
  return concat(
    onErrorLink(({ networkError, graphQLErrors, operation, response }) => {
      props.onError?.call(undefined, {
        networkError,
        graphQLErrors,
        operation,
        response,
      })

      networkError &&
        props.onHttpNetworkError?.call(undefined, { networkError, operation })

      graphQLErrors &&
        props.onGraphqlError?.call(undefined, {
          graphQLErrors,
          operation,
          response,
        })
    }),

    split(
      ({ query }) => {
        const c = getMainDefinition(query)

        if (c.kind === "FragmentDefinition") return false

        return (
          c.kind === "OperationDefinition" && c.operation === "subscription"
        )
      },

      wsLink({
        apolloURL: props.apolloURL,
        username: props.username,
        onDisconnected: props.onWsDisconnected,
        onConnected: props.onWsConnected,
        onReconnected: props.onWsReconnected,
      }),

      authLink().concat(httpLink({ apolloURL: props.apolloURL }))
    )
  )
}

export type LinkProps = {
  apolloURL?: {
    hostname?: URL["hostname"]
    pathname?: URL["pathname"]
    protocol?: URL["protocol"]
    port?: URL["port"]
  }
  username?: string
  onGraphqlError?: (
    props: Pick<ErrorResponse, "operation" | "graphQLErrors" | "response">
  ) => any
  onHttpNetworkError?: (
    props: Pick<ErrorResponse, "operation" | "networkError">
  ) => any
  onError?: (
    props: Pick<
      ErrorResponse,
      "operation" | "networkError" | "graphQLErrors" | "response"
    >
  ) => any
  onWsReconnected?: WsLinkProps["onReconnected"]
  onWsConnected?: WsLinkProps["onConnected"]
  onWsDisconnected?: WsLinkProps["onDisconnected"]
}
