import { ModalProps } from "components/Modal/Modal"
import useWindowSize from "hooks/useWindowSize"

export function useModalVariant() {
  const windowSize = useWindowSize()

  const modalVariant = (() => {
    let v: ModalProps["variant"] = "bottom-modal"

    if (windowSize.width > 600) {
      v = "popover"
    }

    return v
  })()
  return modalVariant
}
