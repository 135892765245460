import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import PaperList from "components/PaperList"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import React from "react"

export default function ListItem(props: {
  left: React.ReactNode
  right: React.ReactNode
  isSelected?: Boolean
  className?: string
  style?: React.CSSProperties
  entry?: NewPageBoardItemFragment | null
  onClick?: () => void
  disabled?: boolean
}) {
  const c = useStyles()

  const api = React.useContext(ItemsListContext)

  return (
    <PaperList.ListItem
      className={clsx("list-item", props.className)}
      isSelected={!!props.isSelected}
      classes={{ listItemLeft: c.listItemLeft, listItemRight: c.listItemRight }}
      style={props.style}
      left={props.left}
      right={props.right}
      onClick={() => {
        if (props.disabled) return
        if (props.onClick) return props.onClick()
        api.openItemPage(props.entry)
      }}
    ></PaperList.ListItem>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {},

    left: {
      display: "flex",
      alignItems: "center",
      "&>*:first-child": {
        marginRight: theme.spacing(1),
      },
    },
    right: {
      display: "flex",
      justifyContent: "flex-end",
      flex: 1,
    },
    listItemLeft: {
      flex: "3 !important",
    },

    listItemRight: {
      flex: 1,
      textAlign: "end",
    },
  }
})
