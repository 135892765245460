import { useApolloClient } from "@apollo/client";
import {
  KanbanCardActivitiesDocument,
  KanbanCardActivitiesQueryVariables,
  KanbanCommentFragment,
  useKanbanCommentMutationMutation,
} from "lib/graphql/operations"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import useActiveResources from "hooks/useActiveResources"
import {
  KanbanCommentInput,
  KanbanRemoveCommentInput,
  KanbanTextCommentInput,
  UpdateType,
} from "lib/graphql/types"
import { useSubscription } from "observable-hooks"
import useEmojiPickerModal from "./useEmojiPickerModal"
import useUsersReactionsModal from "./useUsersReactionsModal"
import useEdition from "./useEditModal"
import readFragment from "lib/updateFragment/readFragment"
import { Typename } from "types/type"
import { useCommentSelectionUI } from "./useCommentSelectionUI"
import useOpenClose from "hooks/useOpenClose"
import useRenderedComment from "./useRenderedComment"

export type EmojiReaction = {
  emojiUnicode: string
  comment: KanbanCommentFragment
}

export default function useKanbanComments(props: { cardID: string }) {
  const [mutate] = useKanbanCommentMutationMutation()

  type MutationOptions = Exclude<Parameters<typeof mutate>[0], undefined>

  const r = useActiveResources()

  const client = useApolloClient()

  const mutation = React.useCallback(
    function mutation<T extends keyof KanbanCommentInput>(key: T) {
      return (
        input: KanbanCommentInput[T],
        options?: {
          optimisticResponse?: (args: {
            comment: KanbanCommentFragment | null
          }) => MutationOptions["optimisticResponse"]
          update?: MutationOptions["update"]
        }
      ) => {
        return mutate({
          refetchQueries: [
            {
              query: KanbanCardActivitiesDocument,
              variables: {
                cardID: props.cardID,
                workspaceID: r.workspaceID,
              } as KanbanCardActivitiesQueryVariables,
            },
          ],
          variables: {
            cardID: props.cardID,
            workspaceID: r.workspaceID,
            input: { [key]: input },
          },

          ...(options?.update ? { update: options.update } : {}),
          ...((() => {
            if (!options?.optimisticResponse) return {}

            const commentID =
              !!input && "commentID" in input ? input.commentID : null

            const comment = readFragment(
              {
                typename: Typename.KanbanComment,
                cacheIDParams: { id: commentID || null },
              },
              client
            )

            return options.optimisticResponse({ comment: comment || null })
          })() || null),
        })
      }
    },
    [mutate, client]
  )

  const add = React.useCallback(mutation("add"), [mutation])
  const react = React.useCallback(mutation("react"), [mutation])

  const edit = React.useCallback(
    (value: KanbanCommentInput["edit"]) =>
      mutation("edit")(value, {
        optimisticResponse(props) {
          return {
            __typename: "Mutation",
            kanbanCommentMutation: (() => {
              if (!props.comment) return null

              let update: KanbanCommentFragment = {
                ...props.comment,
                text: value?.text || "",
              }

              return update
            })(),
          }
        },
      }),
    [mutation]
  )
  const removeComment = React.useCallback(
    (value: KanbanCommentInput["remove"]) =>
      mutation("remove")(value, {
        optimisticResponse(props) {
          return {
            __typename: "Mutation",
            kanbanCommentMutation: (() => {
              if (!props.comment) return null

              let update: KanbanCommentFragment = {
                ...props.comment,
                // text: value?.text || "",
                isDeleted: true,
              }

              return update
            })(),
          }
        },
      }),
    [mutation]
  )

  const addReaction = React.useCallback(
    (props: EmojiReaction) => {
      if (!props.comment?.id) return

      react({
        emojiUnicode: props.emojiUnicode,
        commentID: props.comment.id,
        updateType: UpdateType.Add,
      })
    },
    [react]
  )

  const toggleReaction = React.useCallback(
    (props: EmojiReaction) => {
      if (!props.comment?.id) return

      react({
        emojiUnicode: props.emojiUnicode,
        commentID: props.comment.id,
        updateType: UpdateType.Toggle,
      })
    },
    [react]
  )

  const reactionModal = useMemoAPI({
    emojiPicker: useEmojiPickerModal(),
    userReactions: useUsersReactionsModal(),
  })

  const reaction = useMemoAPI({
    add: addReaction,
    toggle: toggleReaction,
    modal: reactionModal,
  })

  useSubscription(reaction.modal.emojiPicker.selectEmoji$, reaction.add)
  useSubscription(
    reaction.modal.emojiPicker.selectEmoji$,
    reaction.modal.emojiPicker.close
  )

  const rendered = useRenderedComment()

  return useMemoAPI({
    add,
    reaction,
    edition: useEdition({ edit }),
    rendered,
    removeModal: useRemoveCommentModal({ remove: removeComment }),
    addCommentModal: useAddCommentModal({ add }),
  })
}

function useAddCommentModal(props: {
  add: (input: KanbanTextCommentInput) => any
}) {
  const oc = useOpenClose()

  const addComment = React.useCallback(
    (text: string) => {
      return props.add({ text })
    },
    [props.add]
  )

  return useMemoAPI({
    close: oc.close,
    open: oc.open,
    isOpen: oc.isOpen,
    addComment: addComment,
  })
}

function useRemoveCommentModal(props: {
  remove: (comment: KanbanRemoveCommentInput) => any
}) {
  const commentModalAPI = useCommentSelectionUI()

  const commentID = commentModalAPI.selectedComment?.id

  const remove = React.useCallback(() => {
    if (!commentID) return
    return props.remove({ commentID })
  }, [commentID])

  return useMemoAPI({
    close: commentModalAPI.close,
    open: commentModalAPI.open,
    isOpen: commentModalAPI.isOpen,
    remove,
  })
}
