import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import GroupSelection from "components/GroupSelection/GroupSelection"
import SaveButtons from "../SaveButtons/SaveButtons"
import { TextField, Typography, makeStyles } from "@material-ui/core"
import RichEditor from "components/Input/components/RichEditor/RichEditor"
import { useAddNewPartnerAPI } from "./AddNewPartnerProvider"
import {
  AddNewPartnerApiInput,
  AddNewPartnerProvider,
  AddNewPartnerContext,
} from "./AddNewPartnerProvider"

const AddNewPartner = {
  Context: AddNewPartnerContext,
  Provider: AddNewPartnerProvider,
  Root: AddNewPartnerRoot,
  useApi: useAddNewPartnerAPI,
  Default: function AddNewPartner(
    props: {
      onClose?: () => void
      renderButtons?: (props: {
        components: { saveButton: React.ReactNode }
      }) => React.ReactNode
    } & AddNewPartnerApiInput
  ) {
    const c = useStyles({})
    const t = useI18n()

    return (
      <AddNewPartnerProvider {..._.omit(props, ["renderButtons", "onClose"])}>
        <AddNewPartnerRoot></AddNewPartnerRoot>
      </AddNewPartnerProvider>
    )
  },
}

export default AddNewPartner

const useStyles = makeStyles((theme) => {
  return {
    newPartner: {
      "&>*": {
        width: "100%",
        "&:not(:last-child)": {
          marginBottom: theme.spacing(2),
        },
      },
    },
  }
})

function AddNewPartnerRoot(
  props: {
    onClose?: () => void
    renderButtons?: (props: {
      components: { saveButton: React.ReactNode }
    }) => React.ReactNode
  } & AddNewPartnerApiInput
) {
  const c = useStyles({})
  const t = useI18n()

  return (
    <AddNewPartnerContext.Consumer>
      {({ form }) => {
        const { formik } = form

        return (
          <div className={c.newPartner}>
            <Typography variant="h6">{t["new-partner"]}</Typography>
            {(() => {
              const field = form.getFieldProps("type")

              return (
                <GroupSelection
                  onChange={(type) => {
                    field.onChange({
                      target: { value: type, name: "type" },
                    })
                  }}
                  type={field.value}
                  filter={(group) => !!group.hasPartnerAssociated}
                />
              )
            })()}
            <TextField {...form.getFieldProps("name1")} label={t.name} />
            <TextField {...form.getFieldProps("taxID")} label={t.taxID} />
            <TextField {...form.getFieldProps("no")} label={t.partnerNumber} />
            <RichEditor {...form.getFieldProps("remarks")} />
            <SaveButtons
              form={formik}
              submit={formik.submitForm.bind(formik)}
              onClose={props.onClose}
              readyToSubmit={form.readyToSubmit}
            >
              {props.renderButtons}
            </SaveButtons>
          </div>
        )
      }}
    </AddNewPartnerContext.Consumer>
  )
}
