import { ID } from "types/type"
import { ApolloClient, gql } from "@apollo/client";
import { DataProxy } from "apollo-cache"

export default function bindCardImageToCardOnCache(
  props: { cardID: ID },
  proxy: ApolloClient<{}> | DataProxy
) {
  const cardID = props.cardID

  if (!cardID) return

  const args = {
    id: `KanbanCard:${cardID}`,
    fragment: gql`
      fragment AddKanbanCardFragment on KanbanCard {
        images {
          cardID
        }
      }
    `,
  }

  // debugger

  proxy.writeFragment({
    ...args,
    data: {
      __typename: "KanbanCard",
      images: { cardID, __typename: "KanbanCardImages" },
    },
  })
}
