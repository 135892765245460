import React from "react"
import useMonthCalendarAPI from "../hooks/useMonthCalendarAPI"

const MonthCalendarContext = React.createContext(
  {} as ReturnType<typeof useMonthCalendarAPI>
)

MonthCalendarContext.displayName = "MonthCalendarContext"

export default MonthCalendarContext
