import * as Yup from "yup"
import React from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useAddContact from "hooks/useAddContact/useAddContact"
import AddNew from "../AddNew/AddNew"
import { useParamsContext } from "pages/Partners/contexts/libContext"

export default function NewContact({
  onClose,
  onChangeLoadingState,
  partnerID,
}) {
  const fieldset = useFieldset()
  const t = useI18n()

  const { addContact } = useAddContact({ partnerID })

  return (
    <AddNew
      fieldset={fieldset}
      onClose={onClose}
      title={t["new-contact"]}
      onSubmit={addContact}
      onChangeLoadingState={onChangeLoadingState}
    ></AddNew>
  )
}

function useFieldset() {
  const t = useI18n()

  return [
    {
      name: "firstName",
      label: t.firstName,
      type: "text",
      defaultValue: "",
    },
    {
      name: "lastName",
      label: t.lastName,
      type: "text",
      defaultValue: "",
      schema: Yup.string().required(t.required),
    },
    {
      name: "email",
      label: t.email,
      type: "text",
      defaultValue: "",
      schema: Yup.string().email(t.wrongEmail),
    },
    {
      name: "remarks",
      label: t.remarks,
      type: "rich-editor",
      defaultValue: "",
    },
  ]
}
