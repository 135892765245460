import React from "react"
import arePropsEqual from "util/arePropsEqual"

export type FileInputHandle = ReturnType<typeof getHandle>

function FileInput(
  props: {
    onFiles: (files: File[]) => void
    accept?: string
    multiple?: boolean
  },
  ref: React.ForwardedRef<FileInputHandle>
) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useImperativeHandle(ref, () => getHandle(inputRef), [])

  const onChange = React.useCallback(
    (e) => {
      props.onFiles?.call(undefined, e.target.files)
    },
    [props.onFiles]
  )

  return (
    <input
      ref={inputRef}
      style={{ display: "none" }}
      onChange={onChange}
      accept={props.accept}
      type="file"
      multiple={props.multiple || false}
    />
  )
}

export default React.memo(
  React.forwardRef(FileInput),
  arePropsEqual(["onFiles", "accept", "multiple"])
)

function getHandle(inputRef: React.RefObject<HTMLInputElement | null>) {
  return {
    openFileDialog() {
      inputRef.current?.click()
    },
  }
}
