import React from "react"

export default function useListSelection<T extends any>(props: { list: T[] }) {
  const [index, setIndex] = React.useState<number | undefined>()

  React.useEffect(() => {
    setIndex(undefined)
  }, [props.list])

  const next = React.useCallback(
    function next() {
      const lastIndex = props.list.length - 1
      setIndex((index) => {
        return Math.min(
          typeof index === "undefined" ? 0 : (index || 0) + 1,
          lastIndex
        )
      })
    },
    [props.list?.length]
  )

  const previous = React.useCallback(function previous() {
    setIndex((index) =>
      Math.max(0, typeof index === "undefined" ? 0 : (index || 0) - 1)
    )
  }, [])

  return {
    next,
    previous,
    selected: typeof index === "number" ? props.list[index] : undefined,
  }
}
