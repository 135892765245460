import React from "react"
import AppTopBar from "components/TopBar/TopBar"
import { Icon, IconButton } from "@material-ui/core"
import RoomTemplateContext from "components/Room/components/RoomTemplate/contexts/RoomTemplateContext"
import SearchInput from "./SearchInput"

export default function TopBar() {
  const renderLeft = React.useCallback(() => {
    return (
      <RoomTemplateContext.Consumer>
        {({ setSearchIsOpen }) => {
          return (
            <React.Fragment>
              <IconButton onClick={() => setSearchIsOpen(() => false)}>
                <Icon>arrow_back</Icon>
              </IconButton>
              <SearchInput></SearchInput>
            </React.Fragment>
          )
        }}
      </RoomTemplateContext.Consumer>
    )
  }, [])

  const renderRight = React.useCallback(() => null, [])

  return (
    <AppTopBar renderLeft={renderLeft} renderRight={renderRight}></AppTopBar>
  )
}
