import _ from "lodash"
import React from "react"
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function SaveButtons(props: {
  // formik: Pick<ReturnType<typeof useFormik>, "dirty" | "errors" | "resetForm">
  form: { dirty: boolean; errors: Record<string, any>; resetForm: () => void }
  submit: () => Promise<void>
  onClose?: () => void
  children?: React.ReactNode
  readyToSubmit?: boolean
}) {
  const t = useI18n()
  const c = useStyles({})

  const readyToSubmit =
    typeof props.readyToSubmit === "undefined"
      ? _.isEmpty(props.form.errors) && props.form.dirty
      : props.readyToSubmit

  return (
    <div className={c.buttons}>
      {(() => {
        const components = {
          saveAndNewButton: (
            <Button
              color="primary"
              disabled={!readyToSubmit}
              onClick={async () => {
                await props.submit()
                props.form.resetForm()
              }}
            >
              {t["save-and-new"]}
            </Button>
          ),
          saveButton: (
            <Button
              color="primary"
              variant="contained"
              disabled={!readyToSubmit}
              onClick={async () => {
                await props.submit()
                typeof props.onClose === "function" && props.onClose()
              }}
            >
              {t.save}
            </Button>
          ),
        }

        if (typeof props.children === "function")
          return props.children({ components })

        return (
          <>
            {components.saveAndNewButton}
            {components.saveButton}
          </>
        )
      })()}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(8),
      "&>*": {
        marginLeft: theme.spacing(1),
      },
    },
  }
})
