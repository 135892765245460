import React from "react"
import {
  PartnerFormContext,
  PartnerFormProvider,
} from "./components/ProviderAndContext/ProviderAndContext"
import { PartnerFormRoot } from "./components/PartnerFormRoot"
import FormSaveButton from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/FormCommons/components/FormSaveButton"

export type PartnerFormAPIInput = React.ComponentProps<
  typeof PartnerFormProvider
>

export default {
  Root: PartnerFormRoot,
  Context: PartnerFormContext,
  Provider: PartnerFormProvider,
  SaveButton() {
    return (
      <PartnerFormContext.Consumer>
        {(api) => {
          return <FormSaveButton form={api.form} />
        }}
      </PartnerFormContext.Consumer>
    )
  },
}
