import { RoomAPI } from "components/Room/Room"
import { TwoPagesAPI } from "components/TwoPages/TwoPages"
import useUserPageBoardItemAPI from "../../hooks/useUserPageBoardItemAPI"
import { UserPageBoardItemAPIProps } from "../../types"

export default function UserPageBoardItemAPI(
  props: {
    twoPagesAPI: TwoPagesAPI
    children: (api: ReturnType<typeof useUserPageBoardItemAPI>) => JSX.Element
  } & UserPageBoardItemAPIProps
) {
  const { children, ...a } = props

  const api = useUserPageBoardItemAPI(a)

  return props.children(api)
}
