import {
  OpenPageProps,
  PageComponent,
  RenderView,
} from "components/Page/hooks/useSecondPage"
import { useSecondPage } from "components/Page/Page"
import useMemoAPI from "hooks/useMemoAPI"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import React from "react"
import SideBySideLayout from "components/SideBySideLayout/SideBySideLayout"

const viewsTransitionInMs = 300

export type OpenedSecondPage = OpenPageProps & { preferentialLayout?: Layout }

export type TwoPagesAPIInput = {
  openedSecondPage?: OpenedSecondPage
  mandatoryLayout?: Layout
}

export type OneVisibleView = { id: "one-visible-view" }
export type SideBySideLayout = { id: "side-by-side"; minimized?: boolean }

export type Layout = OneVisibleView | SideBySideLayout

/**
 *  Here we distinguish three types of layouts:
 *
 * - `mandatoryLayout` is the layout that is enforced, imposed.
 * - `preferredLayout` is the layout that the user prefers to use.
 * - `naturalLayout` is the layout determined by the size of the screen. It is used when no other layout is enforced.
 */
export default function useTwoPagesAPI(props?: TwoPagesAPIInput) {
  const rootContainer = useRootContainerAPI({
    breakingPoints: { isLarge: 1000 },
  })

  const [layoutState, setLayoutState] = React.useState<Layout | null>(
    (() => {
      if (props?.mandatoryLayout) return props?.mandatoryLayout

      return props?.openedSecondPage
        ? props?.openedSecondPage?.preferentialLayout || null
        : null
    })()
  )

  const naturalLayout: Layout = (() => {
    if (rootContainer.isLarge) return { id: "side-by-side" }
    return { id: "one-visible-view" }
  })()

  const layout = layoutState || naturalLayout

  const view = React.useCallback<RenderView>(
    (api) => {
      if (props?.openedSecondPage) {
        return {
          id: props.openedSecondPage.id || "",
          component: (
            <props.openedSecondPage.Page
              onClose={api.closePage}
            ></props.openedSecondPage.Page>
          ),
        }
      }

      return null
    },
    [props?.openedSecondPage]
  )

  const secondPage = useSecondPage({
    enableEscapeKey: layout.id === "one-visible-view",
    view,
  })

  const openSecondPage = React.useCallback(
    (args: OpenedSecondPage) => {
      const { preferentialLayout, ...openPageProps } = args

      const layout = (() => {
        if (props?.mandatoryLayout) return props?.mandatoryLayout
        return preferentialLayout || naturalLayout || null
      })()

      if (layout?.id) {
        setLayoutState(layout)
      }

      secondPage.openPage({
        ...openPageProps,
        Page: function DecoratedPage(p: { onClose: () => any }) {
          const onClose = () => {
            p.onClose()

            setTimeout(() => {
              setLayoutState(null)
            }, viewsTransitionInMs + 200)
          }

          return <openPageProps.Page onClose={onClose}></openPageProps.Page>
        },
      })
    },
    [secondPage.openPage, setLayoutState, props?.mandatoryLayout, naturalLayout]
  )

  const openHalfPage = React.useCallback(
    (Page: PageComponent) => {
      return openSecondPage({
        Page,
        ...(() => {
          if (rootContainer.width > 650) {
            return { preferentialLayout: { id: "side-by-side" } }
          }

          return {}
        })(),
      })
    },
    [openSecondPage, rootContainer.width]
  )
  const openMiniPage = React.useCallback(
    (Page: PageComponent) => {
      return openSecondPage({
        Page,
        ...(() => {
          if (rootContainer.width > 650) {
            return {
              preferentialLayout: { id: "side-by-side", minimized: true },
            }
          }

          return {}
        })(),
      })
    },
    [openSecondPage, rootContainer.width]
  )

  const openFullPage = React.useCallback(
    (Page: PageComponent) => {
      return openSecondPage({
        Page,
        preferentialLayout: { id: "one-visible-view" },
      })
    },
    [openSecondPage, rootContainer.width]
  )

  return useMemoAPI({
    layout,
    secondPage,
    viewsTransitionInMs,
    rootContainer,
    openHalfPage,
    openFullPage,
    openMiniPage,
    openSecondPage,
  })
}
