import { makeStyles } from "@material-ui/styles"
import _ from "lodash"
import React, { useEffect } from "react"
import Paper from "@material-ui/core/Paper"
import MuiTabs from "@material-ui/core/Tabs"
// import Badge from "@material-ui/core/Badge"
import Tab from "@material-ui/core/Tab"
import AdapterLink from "../AdapterLink/AdapterLink"
import clsx from "clsx"
import useOnChange from "../../hooks/useOnChange"
import { highlightBorder } from "styles/globalStyles"

type Tab = {
  id: string
  link?: string
  label: string
}

type TabProps = {
  onChange: (tabID: string) => void
  currentTabID?: string
  tabs: Tab[]
  elevation?: number
  className?: string
  square?: boolean
  classes?: {
    muiTabs?: Record<string, string>
    muiTab?: Record<string, string>
  }
}

function Tabs({
  onChange = (tabID) => null,
  currentTabID: propsTabsID,
  tabs, // [{id, link?, label}]
  elevation = 0,
  className = "",
  square = true,
  classes: { muiTabs: muiTabsClasses = {}, muiTab: muiTabClasses = {} } = {},
}: TabProps) {
  propsTabsID = propsTabsID || _.get(tabs, "0.id")

  const [tab, changeTab] = React.useState(propsTabsID)

  useOnChange({ value: propsTabsID, onChange: changeTab })
  useOnChange({ value: tab, onChange })

  const c = useStyles()

  return (
    <Paper
      square={square}
      elevation={_.isFinite(elevation) ? elevation : 1}
      className={clsx(c.tabs, className)}
    >
      <MuiTabs
        variant="scrollable"
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        // classes={{...(muiTabsClasses), selected: c.tabSelected, }

        classes={muiTabsClasses}
        onChange={function onChangeTab(__, tab) {
          changeTab(tab)
        }}
      >
        {(tabs || []).map((t) => {
          return (
            <Tab
              key={t.id}
              classes={{ selected: c.selected, ...muiTabClasses }}
              value={t.id}
              label={t.label}
              {...(t.link ? { component: AdapterLink, to: t.link } : {})}
            />
          )
        })}
      </MuiTabs>
    </Paper>
  )
}

export default Tabs

const useStyles = makeStyles((theme) => {
  return {
    tabs: {
      borderBottom: highlightBorder,
    },
  }
})
