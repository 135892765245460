import _ from "lodash"
import React, { useContext, useEffect, useMemo } from "react"
import clsx from "clsx"
import TabsWithBody from "components/TabsWithBody/TabsWithBody"

import RoomTemplateContext from "../../contexts/RoomTemplateContext"
import { makeStyles } from "@material-ui/core"

export default function RoomBody(props: {
  className?: string
  selectedTabColor?: string
  tabsBackgroundColor?: string
  smallTabsIcon?: boolean
  hideTabIndicator?: boolean
}) {
  const {
    className = "",
    selectedTabColor,
    tabsBackgroundColor,
    smallTabsIcon,
    hideTabIndicator,
  } = props

  const c = useStyles({})

  const { tabsAPI } = useContext(RoomTemplateContext)

  const { tabs, setTabID } = tabsAPI

  if (!tabs) return null

  return (
    <TabsWithBody<keyof typeof tabs>
      tabs={tabs}
      smallTabsIcon={smallTabsIcon}
      className={clsx("room-body", className)}
      tabID={tabsAPI.tabID}
      classes={{ bodyRoot: c.bodyRoot }}
      noScroll
      selectedTabColor={selectedTabColor}
      backgroundColor={tabsBackgroundColor}
      onChange={setTabID}
      contentZeroPadding
      hideTabIndicator={hideTabIndicator}
      hideTabSelector={Object.keys(tabs).length <= 1}
    ></TabsWithBody>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    bodyRoot: {
      overflow: "hidden",
    },
  }
})
