import { IconButton, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { Icon } from "components/Icon/Icon"
import React from "react"
import { selectionBorder } from "styles/globalStyles"
import { useUploadContext } from "../../context/UploadContext"
import useFileSelectionManger from "../../hooks/useFileSelectionManager"
import SinglePagePDF from "../SinglePagePDF/SinglePagePDF"

export default function UploadedGrid({
  enableSelection,
  className,
}: {
  enableSelection: boolean
  className?: string
}) {
  const { uploadedFiles } = useUploadContext()
  const { selectedFile: { id: selectedID } = {}, selectFile } =
    useFileSelectionManger()

  const amount = (uploadedFiles || []).length

  const c = useStyles()

  return (
    <div className={clsx(c.root, "uploaded-grid", className)}>
      {(uploadedFiles || []).map(({ base64URL, id }, index) => {
        const isSelected = enableSelection && selectedID === id

        return (
          <div onClick={() => selectFile({ id: id })}>
            <DeleteWrapper
              fileID={id}
              key={index}
              className={clsx(enableSelection && c.cursorPointer)}
            >
              <SinglePagePDF
                elevation={2}
                url={base64URL}
                className={isSelected ? c.pdfSelected : ""}
              ></SinglePagePDF>
            </DeleteWrapper>
          </div>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      "&>*": {
        width: "calc(50% - 4px)",
        marginBottom: theme.spacing(1),
      },
    },
    cursorPointer: {
      cursor: "pointer",
    },
    pdfSelected: {
      "&": selectionBorder(theme),
    },
  }
})

function DeleteWrapper({
  fileID,
  children,
  className,
}: {
  fileID: string
  children: React.ReactNode
  className?: string
}) {
  const c = useDeleteWrapperStyles()
  const { removeUploadedFile } = useUploadContext()

  return (
    <div className={clsx(c.deleteWrapper, className)}>
      <IconButton
        className={c.deleteButton}
        onClick={() => removeUploadedFile(fileID)}
        size="small"
      >
        <Icon name="remove_circle"></Icon>
      </IconButton>
      {children}
    </div>
  )
}

const useDeleteWrapperStyles = makeStyles((theme) => {
  return {
    deleteWrapper: {
      position: "relative",
    },
    deleteButton: {
      position: "absolute",
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
      zIndex: 1,
    },
  }
})
