import { Switch as MuiSwitch } from "@material-ui/core"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import LightTooltip from "components/LightTooltip/LightTooltip"
import { FieldInputProps } from "formik"

export default function Switch(props: FieldInputProps<boolean>) {
  const t = useI18n()

  const title = props.value ? t.active : t.inactive

  return (
    <LightTooltip title={title}>
      <MuiSwitch
        size="small"
        checked={!!props.value}
        onChange={(e) => {
          return props.onChange({
            target: { name: props.name, value: e.target.checked },
          })
        }}
      ></MuiSwitch>
    </LightTooltip>
  )
}
