import { Icon, IconButton } from "@material-ui/core"
import { useContext } from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import Dialog from "components/Dialog/Dialog"
import { PartnersModalIDs } from "../../../../types"
import { AddNewPartner } from "components/AddNewKontakt/index"
import LightTooltip from "components/LightTooltip/LightTooltip"
import PartnersContext from "pages/Partners/contexts/PartnersContext"

export default function AddPartner() {
  const { openNewPartnerModal, modalID, closeModal } =
    useContext(PartnersContext)
  const t = useI18n()

  return (
    <>
      <LightTooltip title={t["add-partner"]}>
        <IconButton onClick={openNewPartnerModal}>
          <Icon>person_add</Icon>
        </IconButton>
      </LightTooltip>
      <Dialog
        open={modalID === PartnersModalIDs.NEW_PARTNER}
        onClose={closeModal}
        renderContent={({ onChangeLoadingState }) => {
          return (
            <AddNewPartner.Default
              onClose={closeModal}
              onChangeLoadingState={onChangeLoadingState}
            ></AddNewPartner.Default>
          )
        }}
      ></Dialog>
    </>
  )
}
