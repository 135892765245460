import React from "react"
import _ from "lodash"
import KanbanContext from "components/Kanban/contexts/KanbanContext"
import useKanbanAPI, { KanbanAPIInput } from "../../hooks/useKanbanAPI"

export default function KanbanProvider(
  props: KanbanAPIInput & { children: React.ReactNode }
) {
  const value = useKanbanAPI(_.omit(props, "children"))

  return (
    <KanbanContext.Provider value={value}>
      {props.children}
    </KanbanContext.Provider>
  )
}
