import PaperList from "components/PaperList"
import React, { useMemo, useContext } from "react"
import TabsWithBody from "components/TabsWithBody/TabsWithBody"
import { makeStyles } from "@material-ui/core"
import IconWithNumber from "components/IconWithNumber/IconWithNumber"
import WorkspaceRoomsMembers from "components/Chat/components/WorkspaceRoomsMembers/WorkspaceRoomsMembers"
import _ from "lodash"
import PageLayout from "components/PageLayout/PageLayout"
import ChatListTopBar, {
  ChatListTopBarRender,
} from "./components/ChatListTopBar/ChatListTopBar"
import SideBySideLayout from "components/SideBySideLayout/SideBySideLayout"
import ChatContext from "components/Chat/contexts/ChatContext"
import isAllValuesEmpty from "lib/isAllValuesEmpty"
import Room from "../Room/Room"
import ChatList from "../ChatList/ChatList"
import clsx from "clsx"

export default function ChatRoot() {
  const {
    roomIdentifier,
    tabID,
    setTabID,
    renderRoomAppBar: renderTopBar,
  } = useContext(ChatContext)
  const isRoomOpen = !isAllValuesEmpty(roomIdentifier)
  const c = useStyles({})

  const ref = React.useRef(null)

  return (
    <div className={clsx("chat-root", c.root)} ref={ref}>
      <SideBySideLayout
        rightIsOpen={isRoomOpen}
        overlayAnchor={ref.current}
        renderLeft={() => {
          return (
            <PageLayout
              className="chat"
              body={
                <TabsWithBody
                  tabs={tabs}
                  tabID={tabID}
                  contentZeroPadding
                  hideTabSelector={tabs.length < 2}
                  onChange={setTabID}
                ></TabsWithBody>
              }
              topBar={<ChatListTopBar render={renderTopBar}></ChatListTopBar>}
            />
          )
        }}
        renderRight={() => (
          <Room
            /**
             * it important to reset the component,
             * always the room changes.
             */
            key={JSON.stringify(roomIdentifier)}
            className={c.rightSideContent}
          ></Room>
        )}
      ></SideBySideLayout>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
    },
    rightSideContent: {
      height: "100%",
      maxHeight: "100%",
      maxWidth: "100%",
      overflowX: "hidden",
      overflowY: "hidden",
    },
  }
})

const tabs = [
  {
    id: "list",
    label: (
      <ChatContext.Consumer>
        {({ rooms }) => {
          return (
            <IconWithNumber
              number={rooms.length}
              iconName="chat"
            ></IconWithNumber>
          )
        }}
      </ChatContext.Consumer>
    ),
    component: <ChatList></ChatList>,
  },
  {
    id: "Members",
    label: (
      <ChatContext.Consumer>
        {({ roomsMembers }) => {
          return (
            <IconWithNumber
              number={roomsMembers.length}
              iconName="group"
            ></IconWithNumber>
          )
        }}
      </ChatContext.Consumer>
    ),
    component: <WorkspaceRoomsMembers></WorkspaceRoomsMembers>,
  },
]
