import {
  IconButton,
  makeStyles,
  Tooltip,
  TooltipProps,
} from "@material-ui/core"
import clsx from "clsx"
import { IconProps, Icon } from "components/Icon/Icon"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import { StyledProps } from "types/type"

export function CartButton({
  tooltip: { title: tooltipTitle = "", placement = "bottom" } = {
    title: "",
    placement: "bottom",
  },
  badge: { className: badgeClassName, content } = { className: "", content: 0 },
  onClick,
  iconName,
  disabled,
  ...other
}: {
  tooltip?: { title: string; placement?: TooltipProps["placement"] }
  badge?: { className?: string; content?: number }
  onClick?: () => void
  iconName?: IconProps["name"]
  disabled?: boolean
  IconButtonProps?: React.ComponentProps<typeof IconButton>
} & StyledProps) {
  const c = useStyles()

  const component = iconName ? (
    <PurpleBadge
      badgeContent={content}
      overlap="circle"
      classes={{
        badge: clsx(badgeClassName, c.badge),
      }}
    >
      <IconButton disabled={disabled} {...(other.IconButtonProps || {})}>
        <Icon name={iconName}></Icon>
      </IconButton>
    </PurpleBadge>
  ) : null

  if (!component) return null

  return (
    <div
      className={clsx("button", c.root, other.className)}
      style={{ display: "inline", ...other.style }}
      onClick={(e) => {
        e.stopPropagation()

        if (!!disabled) return

        onClick?.()
      }}
    >
      {tooltipTitle ? (
        <Tooltip placement={placement} title={tooltipTitle}>
          {component}
        </Tooltip>
      ) : (
        component
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      userSelect: "none",
    },
    badge: {
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  }
})
