import { makeStyles, Typography, useTheme } from "@material-ui/core"
import PaperList from "components/PaperList/index"
import { useI18n } from "contexts/i18nContext/i18nContext"
import formatPrice from "lib/formatPrice"
import { useContext } from "react"
import { Nullable, ObjectWithID } from "types/type"
import DocumentsDashboardContext from "../../contexts/DocumentsDashboardContext"

export default function EntryListItem(props: {
  id?: string
  title?: string
  type?: string
  partnerName?: string
  totalAmount?: number
  entry: Nullable<ObjectWithID>
}) {
  const {
    selectedEntryID: activeID,
    setActiveID,
    selectedEntryColor,
    EntryStatus,
  } = useContext(DocumentsDashboardContext)

  const t = useI18n()
  const isSelected = props.id === activeID
  const c = useStyles({ selectedEntryColor })
  const theme = useTheme()
  const { RootContainer } = useContext(PaperList.Context)

  const left = (
    <PaperList.ListItemText
      primary={
        <RootContainer.ClippedText maxPercentage="50%">
          {props.title || ""}
        </RootContainer.ClippedText>
      }
      primaryTypographyProps={{
        style: {
          transition: "color 500ms",
          color: isSelected ? selectedEntryColor : theme.palette.primary.dark,
        },
      }}
      secondary={
        <>
          {props.type && (
            <Typography variant="body2">
              {t.documentType[props.type as keyof typeof t.documentType] ||
                props.type}
            </Typography>
          )}
          {props.partnerName && (
            <Typography variant="body2">
              <RootContainer.ClippedText maxPercentage="50%">
                {props.partnerName}
              </RootContainer.ClippedText>
            </Typography>
          )}
        </>
      }
    ></PaperList.ListItemText>
  )

  const right = (
    <PaperList.ListItemText
      primary={<div></div>}
      secondary={
        <>
          {!!props.totalAmount && (
            <span
              style={{
                display: "block",
                color: theme.palette.secondary.dark,
              }}
            >
              {formatPrice(props.totalAmount, "€")}
            </span>
          )}
          {EntryStatus && (
            <EntryStatus
              entry={props.entry}
              className={c.activityStatus}
              showColorDot={!isSelected}
              showColorExplanation={isSelected}
            ></EntryStatus>
          )}
        </>
      }
      secondaryTypographyProps={{ style: { textAlign: "right" } }}
    ></PaperList.ListItemText>
  )

  return (
    <PaperList.ListItem
      left={left}
      right={right}
      onClick={() => props.id && setActiveID(props?.id)}
      isSelected={isSelected}
    ></PaperList.ListItem>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    activityStatus: {
      float: "right",
    },
  }
})
