import useGetShareableLink from "./hooks/useGetShareableLink"
import useShareable from "./hooks/useShareable"
import CopyShareableLink from "./components/CopyShareableLink/CopyShareableLink"
import ShareableRedirector from "./components/ShareableRedirector/ShareableRedirector"
import { SHAREABLE_PATHNAME_ROOT } from "./constants"
import shareableLib from "./lib/shareableLib"
import { ShareableType } from "types/type"
import { iconsNames } from "lib/collectionsTypesIcons"

export type { CopyShareableLinkAPI } from "./components/CopyShareableLink/CopyShareableLink"

const icons: { [key in ShareableType]?: string } = {
  [ShareableType.Room]: "chat",
  [ShareableType.Document]: "description",
  [ShareableType.Vendor]: iconsNames["VENDOR"],
  [ShareableType.Lead]: iconsNames["LEAD"],
  [ShareableType.Customer]: iconsNames["CUSTOMER"],
  [ShareableType.KanbanCard]: iconsNames["KANBAN_CARD"],
}

/**
 * This module deals with a 'Shareable'.
 *
 * Today, the app deals with resources (contact, chat room, kanban cards)
 * that is accessible in two or more workspaces, by a link.
 *
 * It means that the same resource might have two or more possible urls.
 *
 * To deal with the problem of resolving these urls, we created this module.
 * */

const Shareable = {
  icons,
  pathnameRoot: SHAREABLE_PATHNAME_ROOT,
  isShareableURL: shareableLib.isSharedURL.bind(shareableLib),
  Redirector: ShareableRedirector,
  useShareable,
  useGetLink: useGetShareableLink,
  CopyLink: CopyShareableLink,
}

export default Shareable
