import { PageComponentProps } from "components/Page/hooks/useSecondPage"
// import { ItemTemplate as TemplatePage } from "./PageBoardItem/components/ItemTemplate/ItemTemplate"
import { Typography } from "@material-ui/core"
import { FormCommons } from "./IndexedItems/components/PageBoardItem/components/FormCommons/FormCommons"
import { FormApi } from "hooks/useForm"
import { StyledProps } from "types/type"
import TemplatePage from "./TemplatePage/TemplatePage"

export function FormPage<T extends Object = Object>(
  props: PageComponentProps & {
    title?: React.ReactNode
    body?: React.ReactNode
    SaveButtonProps: React.ComponentProps<typeof FormCommons.SaveButton>
    form?: FormApi<T>
  } & StyledProps
) {
  return (
    <TemplatePage.Provider onClose={props.onClose}>
      <TemplatePage.Context.Consumer>
        {(templateApi) => {
          return (
            <FormCommons.Provider
              twoPagesApi={templateApi.twoPagesAPI}
              form={props.form as any}
            >
              <TemplatePage.Root
                style={props.style}
                className={props.className}
                EnhancedPageProps={{
                  titleComponent: (
                    <>
                      {props?.title ? (
                        <Typography>{props.title}</Typography>
                      ) : (
                        <div></div>
                      )}

                      <FormCommons.SaveButton
                        {...(props.SaveButtonProps || {})}
                      ></FormCommons.SaveButton>
                    </>
                  ),

                  body: props.body || null,
                }}
              ></TemplatePage.Root>
            </FormCommons.Provider>
          )
        }}
      </TemplatePage.Context.Consumer>
    </TemplatePage.Provider>
  )
}
