import React from "react"
import DocumentForm from "../DocumentForm/DocumentForm"
import TwoPages from "../../../TwoPages/TwoPages"
import clsx from "clsx"

export default function DocumentFormRoot(props: {
  className?: string
  style?: React.CSSProperties
  DocumentFormProps?: React.ComponentProps<typeof DocumentForm>
}) {
  return (
    <TwoPages.Root
      style={props.style}
      className={clsx("document-form-root", props.className)}
      mainPage={
        <DocumentForm {...(props.DocumentFormProps || {})}></DocumentForm>
      }
    ></TwoPages.Root>
  )
}
