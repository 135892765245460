import _ from "lodash"
import { Icon as MuiIcon, IconProps as MuiIconProps } from "@material-ui/core"
import typeH1SVGPath from "./icons/type-h1.svg"
import typeH2SVGPath from "./icons/type-h2.svg"
import typeMarkdownSVGPath from "./icons/markdown.svg"
import { prop } from "lodash/fp"

export type IconProps = Omit<MuiIconProps, "children" | "fontSize"> & {
  name: MuiIconName | CustomIcon
  fontSize?: MuiIconProps["fontSize"] | number
}

export function Icon(props: IconProps) {
  const { name, fontSize, ...rest } = props

  const style = {
    ...(props.style || {}),
    ...(typeof props.fontSize === "number" ? { fontSize: props.fontSize } : {}),
  }

  return (
    <MuiIcon
      // {..._.omit(props, ["name", "children", "fontSize"])}
      {...rest}
      {...(typeof props.fontSize !== "number"
        ? { fontSize: props.fontSize }
        : {})}
      style={style}
      {...(() => {
        if (props.name in svgIconsURLs)
          return {
            style: {
              backgroundImage: `url(${svgIconsURLs[props.name as CustomIcon]})`,
              backgroundSize: "contain",
              ...style,
            },
            children: null,
          }

        if (muiIcons.includes(props.name as MuiIconName))
          return {
            children: props.name,
          }

        return { children: null }
      })()}
    >
      {props.name}
    </MuiIcon>
  )
}
type CustomIcon = keyof typeof svgIconsURLs
type MuiIconName = (typeof muiIcons)[number]

const svgIconsURLs = {
  h1: typeH1SVGPath,
  h2: typeH2SVGPath,
  markdown: typeMarkdownSVGPath,
}

//extracted from MUI V4 Icons (https://v4.mui.com/components/material-icons/#material-icons)
const muiIcons = [
  "ac_unit",
  "ac_unitoutlined",
  "ac_unit_rounded",
  "ac_unit_sharp",
  "ac_unit_two_tone",
  "access_alarm",
  "access_alarm_outlined",
  "access_alarm_rounded",
  "access_alarm_sharp",
  "access_alarm_two_tone",
  "access_alarms",
  "access_alarms_outlined",
  "access_alarms_rounded",
  "access_alarms_sharp",
  "access_alarms_two_tone",
  "access_time",
  "access_time_outlined",
  "access_time_rounded",
  "access_time_sharp",
  "access_time_two_tone",
  "accessibility",
  "accessibility_new",
  "accessibility_new_outlined",
  "accessibility_new_rounded",
  "accessibility_new_sharp",
  "accessibility_new_two_tone",
  "accessibility_outlined",
  "accessibility_rounded",
  "accessibility_sharp",
  "accessibility_two_tone",
  "accessible",
  "accessible_forward",
  "accessible_forward_outlined",
  "accessible_forward_rounded",
  "accessible_forward_sharp",
  "accessible_forward_two_tone",
  "accessible_outlined",
  "accessible_rounded",
  "accessible_sharp",
  "accessible_two_tone",
  "account_balance",
  "account_balance_outlined",
  "account_balance_rounded",
  "account_balance_sharp",
  "account_balance_two_tone",
  "account_balance_wallet",
  "account_balance_wallet_outlined",
  "account_balance_wallet_rounded",
  "account_balance_wallet_sharp",
  "account_balance_wallet_two_tone",
  "account_box",
  "account_box_outlined",
  "account_box_rounded",
  "account_box_sharp",
  "account_box_two_tone",
  "account_circle",
  "account_circle_outlined",
  "account_circle_rounded",
  "account_circle_sharp",
  "account_circle_two_tone",
  "account_tree",
  "account_tree_outlined",
  "account_tree_rounded",
  "account_tree_sharp",
  "account_tree_two_tone",
  "adb",
  "adb_outlined",
  "adb_rounded",
  "adb_sharp",
  "adb_two_tone",
  "add",
  "add_a_photo",
  "add_a_photo_outlined",
  "add_a_photo_rounded",
  "add_a_photo_sharp",
  "add_a_photo_two_tone",
  "add_alarm",
  "add_alarm_outlined",
  "add_alarm_rounded",
  "add_alarm_sharp",
  "add_alarm_two_tone",
  "add_alert",
  "add_alert_outlined",
  "add_alert_rounded",
  "add_alert_sharp",
  "add_alert_two_tone",
  "add_box",
  "add_box_outlined",
  "add_box_rounded",
  "add_box_sharp",
  "add_box_two_tone",
  "add_circle",
  "add_circle_outline",
  "add_circle_outline_outlined",
  "add_circle_outline_rounded",
  "add_circle_outline_sharp",
  "add_circle_outline_two_tone",
  "add_circle_outlined",
  "add_circle_rounded",
  "add_circle_sharp",
  "add_circle_two_tone",
  "add_comment",
  "add_comment_outlined",
  "add_comment_rounded",
  "add_comment_sharp",
  "add_comment_two_tone",
  "add_ic_call",
  "add_ic_call_outlined",
  "add_ic_call_rounded",
  "add_ic_call_sharp",
  "add_ic_call_two_tone",
  "add_location",
  "add_location_outlined",
  "add_location_rounded",
  "add_location_sharp",
  "add_location_two_tone",
  "add_outlined",
  "add_photo_alternate",
  "add_photo_alternate_outlined",
  "add_photo_alternate_rounded",
  "add_photo_alternate_sharp",
  "add_photo_alternate_two_tone",
  "add_rounded",
  "add_sharp",
  "add_shopping_cart",
  "add_shopping_cart_outlined",
  "add_shopping_cart_rounded",
  "add_shopping_cart_sharp",
  "add_shopping_cart_two_tone",
  "add_to_home_screen",
  "add_to_home_screen_outlined",
  "add_to_home_screen_rounded",
  "add_to_home_screen_sharp",
  "add_to_home_screen_two_tone",
  "add_to_photos",
  "add_to_photos_outlined",
  "add_to_photos_rounded",
  "add_to_photos_sharp",
  "add_to_photos_two_tone",
  "add_to_queue",
  "add_to_queue_outlined",
  "add_to_queue_rounded",
  "add_to_queue_sharp",
  "add_to_queue_two_tone",
  "add_two_tone",
  "adjust",
  "adjust_outlined",
  "adjust_rounded",
  "adjust_sharp",
  "adjust_two_tone",
  "airline_seat_flat",
  "airline_seat_flat_angled",
  "airline_seat_flat_angled_outlined",
  "airline_seat_flat_angled_rounded",
  "airline_seat_flat_angled_sharp",
  "airline_seat_flat_angled_two_tone",
  "airline_seat_flat_outlined",
  "airline_seat_flat_rounded",
  "airline_seat_flat_sharp",
  "airline_seat_flat_two_tone",
  "airline_seat_individual_suite",
  "airline_seat_individual_suite_outlined",
  "airline_seat_individual_suite_rounded",
  "airline_seat_individual_suite_sharp",
  "airline_seat_individual_suite_two_tone",
  "airline_seat_legroom_extra",
  "airline_seat_legroom_extra_outlined",
  "airline_seat_legroom_extra_rounded",
  "airline_seat_legroom_extra_sharp",
  "airline_seat_legroom_extra_two_tone",
  "airline_seat_legroom_normal",
  "airline_seat_legroom_normal_outlined",
  "airline_seat_legroom_normal_rounded",
  "airline_seat_legroom_normal_sharp",
  "airline_seat_legroom_normal_two_tone",
  "airline_seat_legroom_reduced",
  "airline_seat_legroom_reduced_outlined",
  "airline_seat_legroom_reduced_rounded",
  "airline_seat_legroom_reduced_sharp",
  "airline_seat_legroom_reduced_two_tone",
  "airline_seat_recline_extra",
  "airline_seat_recline_extra_outlined",
  "airline_seat_recline_extra_rounded",
  "airline_seat_recline_extra_sharp",
  "airline_seat_recline_extra_two_tone",
  "airline_seat_recline_normal",
  "airline_seat_recline_normal_outlined",
  "airline_seat_recline_normal_rounded",
  "airline_seat_recline_normal_sharp",
  "airline_seat_recline_normal_two_tone",
  "airplanemode_active",
  "airplanemode_active_outlined",
  "airplanemode_active_rounded",
  "airplanemode_active_sharp",
  "airplanemode_active_two_tone",
  "airplanemode_inactive",
  "airplanemode_inactive_outlined",
  "airplanemode_inactive_rounded",
  "airplanemode_inactive_sharp",
  "airplanemode_inactive_two_tone",
  "airplay",
  "airplay_outlined",
  "airplay_rounded",
  "airplay_sharp",
  "airplay_two_tone",
  "airport_shuttle",
  "airport_shuttle_outlined",
  "airport_shuttle_rounded",
  "airport_shuttle_sharp",
  "airport_shuttle_two_tone",
  "alarm",
  "alarm_add",
  "alarm_add_outlined",
  "alarm_add_rounded",
  "alarm_add_sharp",
  "alarm_add_two_tone",
  "alarm_off",
  "alarm_off_outlined",
  "alarm_off_rounded",
  "alarm_off_sharp",
  "alarm_off_two_tone",
  "alarm_on",
  "alarm_on_outlined",
  "alarm_on_rounded",
  "alarm_on_sharp",
  "alarm_on_two_tone",
  "alarm_outlined",
  "alarm_rounded",
  "alarm_sharp",
  "alarm_two_tone",
  "album",
  "album_outlined",
  "album_rounded",
  "album_sharp",
  "album_two_tone",
  "all_inbox",
  "all_inbox_outlined",
  "all_inbox_rounded",
  "all_inbox_sharp",
  "all_inbox_two_tone",
  "all_inclusive",
  "all_inclusive_outlined",
  "all_inclusive_rounded",
  "all_inclusive_sharp",
  "all_inclusive_two_tone",
  "all_out",
  "all_out_outlined",
  "all_out_rounded",
  "all_out_sharp",
  "all_out_two_tone",
  "alternate_email",
  "alternate_email_outlined",
  "alternate_email_rounded",
  "alternate_email_sharp",
  "alternate_email_two_tone",
  "amp_stories",
  "amp_stories_outlined",
  "amp_stories_rounded",
  "amp_stories_sharp",
  "amp_stories_two_tone",
  "android",
  "android_outlined",
  "android_rounded",
  "android_sharp",
  "android_two_tone",
  "announcement",
  "announcement_outlined",
  "announcement_rounded",
  "announcement_sharp",
  "announcement_two_tone",
  "apartment",
  "apartment_outlined",
  "apartment_rounded",
  "apartment_sharp",
  "apartment_two_tone",
  "apple",
  "apps",
  "apps_outlined",
  "apps_rounded",
  "apps_sharp",
  "apps_two_tone",
  "archive",
  "archive_outlined",
  "archive_rounded",
  "archive_sharp",
  "archive_two_tone",
  "arrow_back",
  "arrow_back_ios",
  "arrow_back_ios_outlined",
  "arrow_back_ios_rounded",
  "arrow_back_ios_sharp",
  "arrow_back_ios_two_tone",
  "arrow_back_outlined",
  "arrow_back_rounded",
  "arrow_back_sharp",
  "arrow_back_two_tone",
  "arrow_downward",
  "arrow_downward_outlined",
  "arrow_downward_rounded",
  "arrow_downward_sharp",
  "arrow_downward_two_tone",
  "arrow_drop_down",
  "arrow_drop_down_circle",
  "arrow_drop_down_circle_outlined",
  "arrow_drop_down_circle_rounded",
  "arrow_drop_down_circle_sharp",
  "arrow_drop_down_circle_two_tone",
  "arrow_drop_down_outlined",
  "arrow_drop_down_rounded",
  "arrow_drop_down_sharp",
  "arrow_drop_down_two_tone",
  "arrow_drop_up",
  "arrow_drop_up_outlined",
  "arrow_drop_up_rounded",
  "arrow_drop_up_sharp",
  "arrow_drop_up_two_tone",
  "arrow_forward",
  "arrow_forward_ios",
  "arrow_forward_ios_outlined",
  "arrow_forward_ios_rounded",
  "arrow_forward_ios_sharp",
  "arrow_forward_ios_two_tone",
  "arrow_forward_outlined",
  "arrow_forward_rounded",
  "arrow_forward_sharp",
  "arrow_forward_two_tone",
  "arrow_left",
  "arrow_left_outlined",
  "arrow_left_rounded",
  "arrow_left_sharp",
  "arrow_left_two_tone",
  "arrow_right",
  "arrow_right_alt",
  "arrow_right_alt_outlined",
  "arrow_right_alt_rounded",
  "arrow_right_alt_sharp",
  "arrow_right_alt_two_tone",
  "arrow_right_outlined",
  "arrow_right_rounded",
  "arrow_right_sharp",
  "arrow_right_two_tone",
  "arrow_upward",
  "arrow_upward_outlined",
  "arrow_upward_rounded",
  "arrow_upward_sharp",
  "arrow_upward_two_tone",
  "art_track",
  "art_track_outlined",
  "art_track_rounded",
  "art_track_sharp",
  "art_track_two_tone",
  "aspect_ratio",
  "aspect_ratio_outlined",
  "aspect_ratio_rounded",
  "aspect_ratio_sharp",
  "aspect_ratio_two_tone",
  "assessment",
  "assessment_outlined",
  "assessment_rounded",
  "assessment_sharp",
  "assessment_two_tone",
  "assignment",
  "assignment_ind",
  "assignment_ind_outlined",
  "assignment_ind_rounded",
  "assignment_ind_sharp",
  "assignment_ind_two_tone",
  "assignment_late",
  "assignment_late_outlined",
  "assignment_late_rounded",
  "assignment_late_sharp",
  "assignment_late_two_tone",
  "assignment_outlined",
  "assignment_return",
  "assignment_return_outlined",
  "assignment_return_rounded",
  "assignment_return_sharp",
  "assignment_return_two_tone",
  "assignment_returned",
  "assignment_returned_outlined",
  "assignment_returned_rounded",
  "assignment_returned_sharp",
  "assignment_returned_two_tone",
  "assignment_rounded",
  "assignment_sharp",
  "assignment_turned_in",
  "assignment_turned_in_outlined",
  "assignment_turned_in_rounded",
  "assignment_turned_in_sharp",
  "assignment_turned_in_two_tone",
  "assignment_two_tone",
  "assistant",
  "assistant_outlined",
  "assistant_photo",
  "assistant_photo_outlined",
  "assistant_photo_rounded",
  "assistant_photo_sharp",
  "assistant_photo_two_tone",
  "assistant_rounded",
  "assistant_sharp",
  "assistant_two_tone",
  "atm",
  "atm_outlined",
  "atm_rounded",
  "atm_sharp",
  "atm_two_tone",
  "attach_file",
  "attach_file_outlined",
  "attach_file_rounded",
  "attach_file_sharp",
  "attach_file_two_tone",
  "attach_money",
  "attach_money_outlined",
  "attach_money_rounded",
  "attach_money_sharp",
  "attach_money_two_tone",
  "attachment",
  "attachment_outlined",
  "attachment_rounded",
  "attachment_sharp",
  "attachment_two_tone",
  "audiotrack",
  "audiotrack_outlined",
  "audiotrack_rounded",
  "audiotrack_sharp",
  "audiotrack_two_tone",
  "autorenew",
  "autorenew_outlined",
  "autorenew_rounded",
  "autorenew_sharp",
  "autorenew_two_tone",
  "av_timer",
  "av_timer_outlined",
  "av_timer_rounded",
  "av_timer_sharp",
  "av_timer_two_tone",
  "backspace",
  "backspace_outlined",
  "backspace_rounded",
  "backspace_sharp",
  "backspace_two_tone",
  "backup",
  "backup_outlined",
  "backup_rounded",
  "backup_sharp",
  "backup_two_tone",
  "ballot",
  "ballot_outlined",
  "ballot_rounded",
  "ballot_sharp",
  "ballot_two_tone",
  "bar_chart",
  "bar_chart_outlined",
  "bar_chart_rounded",
  "bar_chart_sharp",
  "bar_chart_two_tone",
  "bathtub",
  "bathtub_outlined",
  "bathtub_rounded",
  "bathtub_sharp",
  "bathtub_two_tone",
  "battery20",
  "battery20_outlined",
  "battery20_rounded",
  "battery20_sharp",
  "battery20_two_tone",
  "battery30",
  "battery30_outlined",
  "battery30_rounded",
  "battery30_sharp",
  "battery30_two_tone",
  "battery50",
  "battery50_outlined",
  "battery50_rounded",
  "battery50_sharp",
  "battery50_two_tone",
  "battery60",
  "battery60_outlined",
  "battery60_rounded",
  "battery60_sharp",
  "battery60_two_tone",
  "battery80",
  "battery80_outlined",
  "battery80_rounded",
  "battery80_sharp",
  "battery80_two_tone",
  "battery90",
  "battery90_outlined",
  "battery90_rounded",
  "battery90_sharp",
  "battery90_two_tone",
  "battery_alert",
  "battery_alert_outlined",
  "battery_alert_rounded",
  "battery_alert_sharp",
  "battery_alert_two_tone",
  "battery_charging20",
  "battery_charging20_outlined",
  "battery_charging20_rounded",
  "battery_charging20_sharp",
  "battery_charging20_two_tone",
  "battery_charging30",
  "battery_charging30_outlined",
  "battery_charging30_rounded",
  "battery_charging30_sharp",
  "battery_charging30_two_tone",
  "battery_charging50",
  "battery_charging50_outlined",
  "battery_charging50_rounded",
  "battery_charging50_sharp",
  "battery_charging50_two_tone",
  "battery_charging60",
  "battery_charging60_outlined",
  "battery_charging60_rounded",
  "battery_charging60_sharp",
  "battery_charging60_two_tone",
  "battery_charging80",
  "battery_charging80_outlined",
  "battery_charging80_rounded",
  "battery_charging80_sharp",
  "battery_charging80_two_tone",
  "battery_charging90",
  "battery_charging90_outlined",
  "battery_charging90_rounded",
  "battery_charging90_sharp",
  "battery_charging90_two_tone",
  "battery_charging_full",
  "battery_charging_full_outlined",
  "battery_charging_full_rounded",
  "battery_charging_full_sharp",
  "battery_charging_full_two_tone",
  "battery_full",
  "battery_full_outlined",
  "battery_full_rounded",
  "battery_full_sharp",
  "battery_full_two_tone",
  "battery_std",
  "battery_std_outlined",
  "battery_std_rounded",
  "battery_std_sharp",
  "battery_std_two_tone",
  "battery_unknown",
  "battery_unknown_outlined",
  "battery_unknown_rounded",
  "battery_unknown_sharp",
  "battery_unknown_two_tone",
  "beach_access",
  "beach_access_outlined",
  "beach_access_rounded",
  "beach_access_sharp",
  "beach_access_two_tone",
  "beenhere",
  "beenhere_outlined",
  "beenhere_rounded",
  "beenhere_sharp",
  "beenhere_two_tone",
  "block",
  "block_outlined",
  "block_rounded",
  "block_sharp",
  "block_two_tone",
  "bluetooth",
  "bluetooth_audio",
  "bluetooth_audio_outlined",
  "bluetooth_audio_rounded",
  "bluetooth_audio_sharp",
  "bluetooth_audio_two_tone",
  "bluetooth_connected",
  "bluetooth_connected_outlined",
  "bluetooth_connected_rounded",
  "bluetooth_connected_sharp",
  "bluetooth_connected_two_tone",
  "bluetooth_disabled",
  "bluetooth_disabled_outlined",
  "bluetooth_disabled_rounded",
  "bluetooth_disabled_sharp",
  "bluetooth_disabled_two_tone",
  "bluetooth_outlined",
  "bluetooth_rounded",
  "bluetooth_searching",
  "bluetooth_searching_outlined",
  "bluetooth_searching_rounded",
  "bluetooth_searching_sharp",
  "bluetooth_searching_two_tone",
  "bluetooth_sharp",
  "bluetooth_two_tone",
  "blur_circular",
  "blur_circular_outlined",
  "blur_circular_rounded",
  "blur_circular_sharp",
  "blur_circular_two_tone",
  "blur_linear",
  "blur_linear_outlined",
  "blur_linear_rounded",
  "blur_linear_sharp",
  "blur_linear_two_tone",
  "blur_off",
  "blur_off_outlined",
  "blur_off_rounded",
  "blur_off_sharp",
  "blur_off_two_tone",
  "blur_on",
  "blur_on_outlined",
  "blur_on_rounded",
  "blur_on_sharp",
  "blur_on_two_tone",
  "book",
  "book_outlined",
  "book_rounded",
  "book_sharp",
  "book_two_tone",
  "bookmark",
  "bookmark_border",
  "bookmark_border_outlined",
  "bookmark_border_rounded",
  "bookmark_border_sharp",
  "bookmark_border_two_tone",
  "bookmark_outlined",
  "bookmark_rounded",
  "bookmark_sharp",
  "bookmark_two_tone",
  "bookmarks",
  "bookmarks_outlined",
  "bookmarks_rounded",
  "bookmarks_sharp",
  "bookmarks_two_tone",
  "border_all",
  "border_all_outlined",
  "border_all_rounded",
  "border_all_sharp",
  "border_all_two_tone",
  "border_bottom",
  "border_bottom_outlined",
  "border_bottom_rounded",
  "border_bottom_sharp",
  "border_bottom_two_tone",
  "border_clear",
  "border_clear_outlined",
  "border_clear_rounded",
  "border_clear_sharp",
  "border_clear_two_tone",
  "border_color",
  "border_color_outlined",
  "border_color_rounded",
  "border_color_sharp",
  "border_color_two_tone",
  "border_horizontal",
  "border_horizontal_outlined",
  "border_horizontal_rounded",
  "border_horizontal_sharp",
  "border_horizontal_two_tone",
  "border_inner",
  "border_inner_outlined",
  "border_inner_rounded",
  "border_inner_sharp",
  "border_inner_two_tone",
  "border_left",
  "border_left_outlined",
  "border_left_rounded",
  "border_left_sharp",
  "border_left_two_tone",
  "border_outer",
  "border_outer_outlined",
  "border_outer_rounded",
  "border_outer_sharp",
  "border_outer_two_tone",
  "border_right",
  "border_right_outlined",
  "border_right_rounded",
  "border_right_sharp",
  "border_right_two_tone",
  "border_style",
  "border_style_outlined",
  "border_style_rounded",
  "border_style_sharp",
  "border_style_two_tone",
  "border_top",
  "border_top_outlined",
  "border_top_rounded",
  "border_top_sharp",
  "border_top_two_tone",
  "border_vertical",
  "border_vertical_outlined",
  "border_vertical_rounded",
  "border_vertical_sharp",
  "border_vertical_two_tone",
  "branding_watermark",
  "branding_watermark_outlined",
  "branding_watermark_rounded",
  "branding_watermark_sharp",
  "branding_watermark_two_tone",
  "brightness1",
  "brightness1_outlined",
  "brightness1_rounded",
  "brightness1_sharp",
  "brightness1_two_tone",
  "brightness2",
  "brightness2_outlined",
  "brightness2_rounded",
  "brightness2_sharp",
  "brightness2_two_tone",
  "brightness3",
  "brightness3_outlined",
  "brightness3_rounded",
  "brightness3_sharp",
  "brightness3_two_tone",
  "brightness4",
  "brightness4_outlined",
  "brightness4_rounded",
  "brightness4_sharp",
  "brightness4_two_tone",
  "brightness5",
  "brightness5_outlined",
  "brightness5_rounded",
  "brightness5_sharp",
  "brightness5_two_tone",
  "brightness6",
  "brightness6_outlined",
  "brightness6_rounded",
  "brightness6_sharp",
  "brightness6_two_tone",
  "brightness7",
  "brightness7_outlined",
  "brightness7_rounded",
  "brightness7_sharp",
  "brightness7_two_tone",
  "brightness_auto",
  "brightness_auto_outlined",
  "brightness_auto_rounded",
  "brightness_auto_sharp",
  "brightness_auto_two_tone",
  "brightness_high",
  "brightness_high_outlined",
  "brightness_high_rounded",
  "brightness_high_sharp",
  "brightness_high_two_tone",
  "brightness_low",
  "brightness_low_outlined",
  "brightness_low_rounded",
  "brightness_low_sharp",
  "brightness_low_two_tone",
  "brightness_medium",
  "brightness_medium_outlined",
  "brightness_medium_rounded",
  "brightness_medium_sharp",
  "brightness_medium_two_tone",
  "broken_image",
  "broken_image_outlined",
  "broken_image_rounded",
  "broken_image_sharp",
  "broken_image_two_tone",
  "browser_not_supported",
  "brush",
  "brush_outlined",
  "brush_rounded",
  "brush_sharp",
  "brush_two_tone",
  "bubble_chart",
  "bubble_chart_outlined",
  "bubble_chart_rounded",
  "bubble_chart_sharp",
  "bubble_chart_two_tone",
  "bug_report",
  "bug_report_outlined",
  "bug_report_rounded",
  "bug_report_sharp",
  "bug_report_two_tone",
  "build",
  "build_outlined",
  "build_rounded",
  "build_sharp",
  "build_two_tone",
  "burst_mode",
  "burst_mode_outlined",
  "burst_mode_rounded",
  "burst_mode_sharp",
  "burst_mode_two_tone",
  "business",
  "business_center",
  "business_center_outlined",
  "business_center_rounded",
  "business_center_sharp",
  "business_center_two_tone",
  "business_outlined",
  "business_rounded",
  "business_sharp",
  "business_two_tone",
  "cached",
  "cached_outlined",
  "cached_rounded",
  "cached_sharp",
  "cached_two_tone",
  "cake",
  "cake_outlined",
  "cake_rounded",
  "cake_sharp",
  "cake_two_tone",
  "calendar_today",
  "calendar_today_outlined",
  "calendar_today_rounded",
  "calendar_today_sharp",
  "calendar_today_two_tone",
  "calendar_view_day",
  "calendar_view_day_outlined",
  "calendar_view_day_rounded",
  "calendar_view_day_sharp",
  "calendar_view_day_two_tone",
  "call",
  "call_end",
  "call_end_outlined",
  "call_end_rounded",
  "call_end_sharp",
  "call_end_two_tone",
  "call_made",
  "call_made_outlined",
  "call_made_rounded",
  "call_made_sharp",
  "call_made_two_tone",
  "call_merge",
  "call_merge_outlined",
  "call_merge_rounded",
  "call_merge_sharp",
  "call_merge_two_tone",
  "call_missed",
  "call_missed_outgoing",
  "call_missed_outgoing_outlined",
  "call_missed_outgoing_rounded",
  "call_missed_outgoing_sharp",
  "call_missed_outgoing_two_tone",
  "call_missed_outlined",
  "call_missed_rounded",
  "call_missed_sharp",
  "call_missed_two_tone",
  "call_outlined",
  "call_received",
  "call_received_outlined",
  "call_received_rounded",
  "call_received_sharp",
  "call_received_two_tone",
  "call_rounded",
  "call_sharp",
  "call_split",
  "call_split_outlined",
  "call_split_rounded",
  "call_split_sharp",
  "call_split_two_tone",
  "call_to_action",
  "call_to_action_outlined",
  "call_to_action_rounded",
  "call_to_action_sharp",
  "call_to_action_two_tone",
  "call_two_tone",
  "camera",
  "camera_alt",
  "camera_alt_outlined",
  "camera_alt_rounded",
  "camera_alt_sharp",
  "camera_alt_two_tone",
  "camera_enhance",
  "camera_enhance_outlined",
  "camera_enhance_rounded",
  "camera_enhance_sharp",
  "camera_enhance_two_tone",
  "camera_front",
  "camera_front_outlined",
  "camera_front_rounded",
  "camera_front_sharp",
  "camera_front_two_tone",
  "camera_outlined",
  "camera_rear",
  "camera_rear_outlined",
  "camera_rear_rounded",
  "camera_rear_sharp",
  "camera_rear_two_tone",
  "camera_roll",
  "camera_roll_outlined",
  "camera_roll_rounded",
  "camera_roll_sharp",
  "camera_roll_two_tone",
  "camera_rounded",
  "camera_sharp",
  "camera_two_tone",
  "cancel",
  "cancel_outlined",
  "cancel_presentation",
  "cancel_presentation_outlined",
  "cancel_presentation_rounded",
  "cancel_presentation_sharp",
  "cancel_presentation_two_tone",
  "cancel_rounded",
  "cancel_schedule_send",
  "cancel_schedule_send_outlined",
  "cancel_schedule_send_rounded",
  "cancel_schedule_send_sharp",
  "cancel_schedule_send_two_tone",
  "cancel_sharp",
  "cancel_two_tone",
  "card_giftcard",
  "card_giftcard_outlined",
  "card_giftcard_rounded",
  "card_giftcard_sharp",
  "card_giftcard_two_tone",
  "card_membership",
  "card_membership_outlined",
  "card_membership_rounded",
  "card_membership_sharp",
  "card_membership_two_tone",
  "card_travel",
  "card_travel_outlined",
  "card_travel_rounded",
  "card_travel_sharp",
  "card_travel_two_tone",
  "casino",
  "casino_outlined",
  "casino_rounded",
  "casino_sharp",
  "casino_two_tone",
  "cast",
  "cast_connected",
  "cast_connected_outlined",
  "cast_connected_rounded",
  "cast_connected_sharp",
  "cast_connected_two_tone",
  "cast_for_education",
  "cast_for_education_outlined",
  "cast_for_education_rounded",
  "cast_for_education_sharp",
  "cast_for_education_two_tone",
  "cast_outlined",
  "cast_rounded",
  "cast_sharp",
  "cast_two_tone",
  "category",
  "category_outlined",
  "category_rounded",
  "category_sharp",
  "category_two_tone",
  "cell_wifi",
  "cell_wifi_outlined",
  "cell_wifi_rounded",
  "cell_wifi_sharp",
  "cell_wifi_two_tone",
  "center_focus_strong",
  "center_focus_strong_outlined",
  "center_focus_strong_rounded",
  "center_focus_strong_sharp",
  "center_focus_strong_two_tone",
  "center_focus_weak",
  "center_focus_weak_outlined",
  "center_focus_weak_rounded",
  "center_focus_weak_sharp",
  "center_focus_weak_two_tone",
  "change_circle",
  "change_history",
  "change_history_outlined",
  "change_history_rounded",
  "change_history_sharp",
  "change_history_two_tone",
  "chat",
  "chat_bubble",
  "chat_bubble_outline",
  "chat_bubble_outline_outlined",
  "chat_bubble_outline_rounded",
  "chat_bubble_outline_sharp",
  "chat_bubble_outline_two_tone",
  "chat_bubble_outlined",
  "chat_bubble_rounded",
  "chat_bubble_sharp",
  "chat_bubble_two_tone",
  "chat_outlined",
  "chat_rounded",
  "chat_sharp",
  "chat_two_tone",
  "check",
  "check_box",
  "check_box_outline_blank",
  "check_box_outline_blank_outlined",
  "check_box_outline_blank_rounded",
  "check_box_outline_blank_sharp",
  "check_box_outline_blank_two_tone",
  "check_box_outlined",
  "check_box_rounded",
  "check_box_sharp",
  "check_box_two_tone",
  "check_circle",
  "check_circle_outline",
  "check_circle_outline_outlined",
  "check_circle_outline_rounded",
  "check_circle_outline_sharp",
  "check_circle_outline_two_tone",
  "check_circle_outlined",
  "check_circle_rounded",
  "check_circle_sharp",
  "check_circle_two_tone",
  "check_outlined",
  "check_rounded",
  "check_sharp",
  "check_two_tone",
  "chevron_left",
  "chevron_left_outlined",
  "chevron_left_rounded",
  "chevron_left_sharp",
  "chevron_left_two_tone",
  "chevron_right",
  "chevron_right_outlined",
  "chevron_right_rounded",
  "chevron_right_sharp",
  "chevron_right_two_tone",
  "child_care",
  "child_care_outlined",
  "child_care_rounded",
  "child_care_sharp",
  "child_care_two_tone",
  "child_friendly",
  "child_friendly_outlined",
  "child_friendly_rounded",
  "child_friendly_sharp",
  "child_friendly_two_tone",
  "chrome_reader_mode",
  "chrome_reader_mode_outlined",
  "chrome_reader_mode_rounded",
  "chrome_reader_mode_sharp",
  "chrome_reader_mode_two_tone",
  "class",
  "class_outlined",
  "class_rounded",
  "class_sharp",
  "class_two_tone",
  "clear",
  "clear_all",
  "clear_all_outlined",
  "clear_all_rounded",
  "clear_all_sharp",
  "clear_all_two_tone",
  "clear_outlined",
  "clear_rounded",
  "clear_sharp",
  "clear_two_tone",
  "close",
  "close_outlined",
  "close_rounded",
  "close_sharp",
  "close_two_tone",
  "closed_caption",
  "closed_caption_outlined",
  "closed_caption_rounded",
  "closed_caption_sharp",
  "closed_caption_two_tone",
  "cloud",
  "cloud_circle",
  "cloud_circle_outlined",
  "cloud_circle_rounded",
  "cloud_circle_sharp",
  "cloud_circle_two_tone",
  "cloud_done",
  "cloud_done_outlined",
  "cloud_done_rounded",
  "cloud_done_sharp",
  "cloud_done_two_tone",
  "cloud_download",
  "cloud_download_outlined",
  "cloud_download_rounded",
  "cloud_download_sharp",
  "cloud_download_two_tone",
  "cloud_off",
  "cloud_off_outlined",
  "cloud_off_rounded",
  "cloud_off_sharp",
  "cloud_off_two_tone",
  "cloud_outlined",
  "cloud_queue",
  "cloud_queue_outlined",
  "cloud_queue_rounded",
  "cloud_queue_sharp",
  "cloud_queue_two_tone",
  "cloud_rounded",
  "cloud_sharp",
  "cloud_two_tone",
  "cloud_upload",
  "cloud_upload_outlined",
  "cloud_upload_rounded",
  "cloud_upload_sharp",
  "cloud_upload_two_tone",
  "code",
  "code_outlined",
  "code_rounded",
  "code_sharp",
  "code_two_tone",
  "collections",
  "collections_bookmark",
  "collections_bookmark_outlined",
  "collections_bookmark_rounded",
  "collections_bookmark_sharp",
  "collections_bookmark_two_tone",
  "collections_outlined",
  "collections_rounded",
  "collections_sharp",
  "collections_two_tone",
  "color_lens",
  "color_lens_outlined",
  "color_lens_rounded",
  "color_lens_sharp",
  "color_lens_two_tone",
  "colorize",
  "colorize_outlined",
  "colorize_rounded",
  "colorize_sharp",
  "colorize_two_tone",
  "comment",
  "comment_outlined",
  "comment_rounded",
  "comment_sharp",
  "comment_two_tone",
  "commute",
  "commute_outlined",
  "commute_rounded",
  "commute_sharp",
  "commute_two_tone",
  "compare",
  "compare_arrows",
  "compare_arrows_outlined",
  "compare_arrows_rounded",
  "compare_arrows_sharp",
  "compare_arrows_two_tone",
  "compare_outlined",
  "compare_rounded",
  "compare_sharp",
  "compare_two_tone",
  "compass_calibration",
  "compass_calibration_outlined",
  "compass_calibration_rounded",
  "compass_calibration_sharp",
  "compass_calibration_two_tone",
  "computer",
  "computer_outlined",
  "computer_rounded",
  "computer_sharp",
  "computer_two_tone",
  "confirmation_number",
  "confirmation_number_outlined",
  "confirmation_number_rounded",
  "confirmation_number_sharp",
  "confirmation_number_two_tone",
  "contact_mail",
  "contact_mail_outlined",
  "contact_mail_rounded",
  "contact_mail_sharp",
  "contact_mail_two_tone",
  "contact_phone",
  "contact_phone_outlined",
  "contact_phone_rounded",
  "contact_phone_sharp",
  "contact_phone_two_tone",
  "contact_support",
  "contact_support_outlined",
  "contact_support_rounded",
  "contact_support_sharp",
  "contact_support_two_tone",
  "contactless",
  "contactless_outlined",
  "contactless_rounded",
  "contactless_sharp",
  "contactless_two_tone",
  "contacts",
  "contacts_outlined",
  "contacts_rounded",
  "contacts_sharp",
  "contacts_two_tone",
  "content_copy",
  "control_camera",
  "control_camera_outlined",
  "control_camera_rounded",
  "control_camera_sharp",
  "control_camera_two_tone",
  "control_point",
  "control_point_duplicate",
  "control_point_duplicate_outlined",
  "control_point_duplicate_rounded",
  "control_point_duplicate_sharp",
  "control_point_duplicate_two_tone",
  "control_point_outlined",
  "control_point_rounded",
  "control_point_sharp",
  "control_point_two_tone",
  "copyright",
  "copyright_outlined",
  "copyright_rounded",
  "copyright_sharp",
  "copyright_two_tone",
  "create",
  "create_new_folder",
  "create_new_folder_outlined",
  "create_new_folder_rounded",
  "create_new_folder_sharp",
  "create_new_folder_two_tone",
  "create_outlined",
  "create_rounded",
  "create_sharp",
  "create_two_tone",
  "credit_card",
  "credit_card_outlined",
  "credit_card_rounded",
  "credit_card_sharp",
  "credit_card_two_tone",
  "crop",
  "crop169",
  "crop169_outlined",
  "crop169_rounded",
  "crop169_sharp",
  "crop169_two_tone",
  "crop32",
  "crop32_outlined",
  "crop32_rounded",
  "crop32_sharp",
  "crop32_two_tone",
  "crop54",
  "crop54_outlined",
  "crop54_rounded",
  "crop54_sharp",
  "crop54_two_tone",
  "crop75",
  "crop75_outlined",
  "crop75_rounded",
  "crop75_sharp",
  "crop75_two_tone",
  "crop_din",
  "crop_din_outlined",
  "crop_din_rounded",
  "crop_din_sharp",
  "crop_din_two_tone",
  "crop_free",
  "crop_free_outlined",
  "crop_free_rounded",
  "crop_free_sharp",
  "crop_free_two_tone",
  "crop_landscape",
  "crop_landscape_outlined",
  "crop_landscape_rounded",
  "crop_landscape_sharp",
  "crop_landscape_two_tone",
  "crop_original",
  "crop_original_outlined",
  "crop_original_rounded",
  "crop_original_sharp",
  "crop_original_two_tone",
  "crop_outlined",
  "crop_portrait",
  "crop_portrait_outlined",
  "crop_portrait_rounded",
  "crop_portrait_sharp",
  "crop_portrait_two_tone",
  "crop_rotate",
  "crop_rotate_outlined",
  "crop_rotate_rounded",
  "crop_rotate_sharp",
  "crop_rotate_two_tone",
  "crop_rounded",
  "crop_sharp",
  "crop_square",
  "crop_square_outlined",
  "crop_square_rounded",
  "crop_square_sharp",
  "crop_square_two_tone",
  "crop_two_tone",
  "dashboard",
  "dashboard_outlined",
  "dashboard_rounded",
  "dashboard_sharp",
  "dashboard_two_tone",
  "data_usage",
  "data_usage_outlined",
  "data_usage_rounded",
  "data_usage_sharp",
  "data_usage_two_tone",
  "date_range",
  "date_range_outlined",
  "date_range_rounded",
  "date_range_sharp",
  "date_range_two_tone",
  "deck",
  "deck_outlined",
  "deck_rounded",
  "deck_sharp",
  "deck_two_tone",
  "dehaze",
  "dehaze_outlined",
  "dehaze_rounded",
  "dehaze_sharp",
  "dehaze_two_tone",
  "delete",
  "delete_forever",
  "delete_forever_outlined",
  "delete_forever_rounded",
  "delete_forever_sharp",
  "delete_forever_two_tone",
  "delete_outline",
  "delete_outline_outlined",
  "delete_outline_rounded",
  "delete_outline_sharp",
  "delete_outline_two_tone",
  "delete_outlined",
  "delete_rounded",
  "delete_sharp",
  "delete_sweep",
  "delete_sweep_outlined",
  "delete_sweep_rounded",
  "delete_sweep_sharp",
  "delete_sweep_two_tone",
  "delete_two_tone",
  "departure_board",
  "departure_board_outlined",
  "departure_board_rounded",
  "departure_board_sharp",
  "departure_board_two_tone",
  "description",
  "description_outlined",
  "description_rounded",
  "description_sharp",
  "description_two_tone",
  "desktop_access_disabled",
  "desktop_access_disabled_outlined",
  "desktop_access_disabled_rounded",
  "desktop_access_disabled_sharp",
  "desktop_access_disabled_two_tone",
  "desktop_mac",
  "desktop_mac_outlined",
  "desktop_mac_rounded",
  "desktop_mac_sharp",
  "desktop_mac_two_tone",
  "desktop_windows",
  "desktop_windows_outlined",
  "desktop_windows_rounded",
  "desktop_windows_sharp",
  "desktop_windows_two_tone",
  "details",
  "details_outlined",
  "details_rounded",
  "details_sharp",
  "details_two_tone",
  "developer_board",
  "developer_board_outlined",
  "developer_board_rounded",
  "developer_board_sharp",
  "developer_board_two_tone",
  "developer_mode",
  "developer_mode_outlined",
  "developer_mode_rounded",
  "developer_mode_sharp",
  "developer_mode_two_tone",
  "device_hub",
  "device_hub_outlined",
  "device_hub_rounded",
  "device_hub_sharp",
  "device_hub_two_tone",
  "device_unknown",
  "device_unknown_outlined",
  "device_unknown_rounded",
  "device_unknown_sharp",
  "device_unknown_two_tone",
  "devices",
  "devices_other",
  "devices_other_outlined",
  "devices_other_rounded",
  "devices_other_sharp",
  "devices_other_two_tone",
  "devices_outlined",
  "devices_rounded",
  "devices_sharp",
  "devices_two_tone",
  "dialer_sip",
  "dialer_sip_outlined",
  "dialer_sip_rounded",
  "dialer_sip_sharp",
  "dialer_sip_two_tone",
  "dialpad",
  "dialpad_outlined",
  "dialpad_rounded",
  "dialpad_sharp",
  "dialpad_two_tone",
  "directions",
  "directions_bike",
  "directions_bike_outlined",
  "directions_bike_rounded",
  "directions_bike_sharp",
  "directions_bike_two_tone",
  "directions_boat",
  "directions_boat_outlined",
  "directions_boat_rounded",
  "directions_boat_sharp",
  "directions_boat_two_tone",
  "directions_bus",
  "directions_bus_outlined",
  "directions_bus_rounded",
  "directions_bus_sharp",
  "directions_bus_two_tone",
  "directions_car",
  "directions_car_outlined",
  "directions_car_rounded",
  "directions_car_sharp",
  "directions_car_two_tone",
  "directions_outlined",
  "directions_railway",
  "directions_railway_outlined",
  "directions_railway_rounded",
  "directions_railway_sharp",
  "directions_railway_two_tone",
  "directions_rounded",
  "directions_run",
  "directions_run_outlined",
  "directions_run_rounded",
  "directions_run_sharp",
  "directions_run_two_tone",
  "directions_sharp",
  "directions_subway",
  "directions_subway_outlined",
  "directions_subway_rounded",
  "directions_subway_sharp",
  "directions_subway_two_tone",
  "directions_transit",
  "directions_transit_outlined",
  "directions_transit_rounded",
  "directions_transit_sharp",
  "directions_transit_two_tone",
  "directions_two_tone",
  "directions_walk",
  "directions_walk_outlined",
  "directions_walk_rounded",
  "directions_walk_sharp",
  "directions_walk_two_tone",
  "disc_full",
  "disc_full_outlined",
  "disc_full_rounded",
  "disc_full_sharp",
  "disc_full_two_tone",
  "dns",
  "dns_outlined",
  "dns_rounded",
  "dns_sharp",
  "dns_two_tone",
  "dock",
  "dock_outlined",
  "dock_rounded",
  "dock_sharp",
  "dock_two_tone",
  "domain",
  "domain_disabled",
  "domain_disabled_outlined",
  "domain_disabled_rounded",
  "domain_disabled_sharp",
  "domain_disabled_two_tone",
  "domain_outlined",
  "domain_rounded",
  "domain_sharp",
  "domain_two_tone",
  "done",
  "done_all",
  "done_all_outlined",
  "done_all_rounded",
  "done_all_sharp",
  "done_all_two_tone",
  "done_outline",
  "done_outline_outlined",
  "done_outline_rounded",
  "done_outline_sharp",
  "done_outline_two_tone",
  "done_outlined",
  "done_rounded",
  "done_sharp",
  "done_two_tone",
  "donut_large",
  "donut_large_outlined",
  "donut_large_rounded",
  "donut_large_sharp",
  "donut_large_two_tone",
  "donut_small",
  "donut_small_outlined",
  "donut_small_rounded",
  "donut_small_sharp",
  "donut_small_two_tone",
  "double_arrow",
  "double_arrow_outlined",
  "double_arrow_rounded",
  "double_arrow_sharp",
  "double_arrow_two_tone",
  "drafts",
  "drafts_outlined",
  "drafts_rounded",
  "drafts_sharp",
  "drafts_two_tone",
  "drag_handle",
  "drag_handle_outlined",
  "drag_handle_rounded",
  "drag_handle_sharp",
  "drag_handle_two_tone",
  "drag_indicator",
  "drag_indicator_outlined",
  "drag_indicator_rounded",
  "drag_indicator_sharp",
  "drag_indicator_two_tone",
  "drive_eta",
  "drive_eta_outlined",
  "drive_eta_rounded",
  "drive_eta_sharp",
  "drive_eta_two_tone",
  "duo",
  "duo_outlined",
  "duo_rounded",
  "duo_sharp",
  "duo_two_tone",
  "dvr",
  "dvr_outlined",
  "dvr_rounded",
  "dvr_sharp",
  "dvr_two_tone",
  "dynamic_feed",
  "dynamic_feed_outlined",
  "dynamic_feed_rounded",
  "dynamic_feed_sharp",
  "dynamic_feed_two_tone",
  "eco",
  "eco_outlined",
  "eco_rounded",
  "eco_sharp",
  "eco_two_tone",
  "edit",
  "edit_attributes",
  "edit_attributes_outlined",
  "edit_attributes_rounded",
  "edit_attributes_sharp",
  "edit_attributes_two_tone",
  "edit_location",
  "edit_location_outlined",
  "edit_location_rounded",
  "edit_location_sharp",
  "edit_location_two_tone",
  "edit_off",
  "edit_outlined",
  "edit_rounded",
  "edit_sharp",
  "edit_two_tone",
  "eject",
  "eject_outlined",
  "eject_rounded",
  "eject_sharp",
  "eject_two_tone",
  "email",
  "email_outlined",
  "email_rounded",
  "email_sharp",
  "email_two_tone",
  "emoji_emotions",
  "emoji_emotions_outlined",
  "emoji_emotions_rounded",
  "emoji_emotions_sharp",
  "emoji_emotions_two_tone",
  "emoji_events",
  "emoji_events_outlined",
  "emoji_events_rounded",
  "emoji_events_sharp",
  "emoji_events_two_tone",
  "emoji_flags",
  "emoji_flags_outlined",
  "emoji_flags_rounded",
  "emoji_flags_sharp",
  "emoji_flags_two_tone",
  "emoji_food_beverage",
  "emoji_food_beverage_outlined",
  "emoji_food_beverage_rounded",
  "emoji_food_beverage_sharp",
  "emoji_food_beverage_two_tone",
  "emoji_nature",
  "emoji_nature_outlined",
  "emoji_nature_rounded",
  "emoji_nature_sharp",
  "emoji_nature_two_tone",
  "emoji_objects",
  "emoji_objects_outlined",
  "emoji_objects_rounded",
  "emoji_objects_sharp",
  "emoji_objects_two_tone",
  "emoji_people",
  "emoji_people_outlined",
  "emoji_people_rounded",
  "emoji_people_sharp",
  "emoji_people_two_tone",
  "emoji_symbols",
  "emoji_symbols_outlined",
  "emoji_symbols_rounded",
  "emoji_symbols_sharp",
  "emoji_symbols_two_tone",
  "emoji_transportation",
  "emoji_transportation_outlined",
  "emoji_transportation_rounded",
  "emoji_transportation_sharp",
  "emoji_transportation_two_tone",
  "enhanced_encryption",
  "enhanced_encryption_outlined",
  "enhanced_encryption_rounded",
  "enhanced_encryption_sharp",
  "enhanced_encryption_two_tone",
  "equalizer",
  "equalizer_outlined",
  "equalizer_rounded",
  "equalizer_sharp",
  "equalizer_two_tone",
  "error",
  "error_outline",
  "error_outline_outlined",
  "error_outline_rounded",
  "error_outline_sharp",
  "error_outline_two_tone",
  "error_outlined",
  "error_rounded",
  "error_sharp",
  "error_two_tone",
  "euro",
  "euro_outlined",
  "euro_rounded",
  "euro_sharp",
  "euro_symbol",
  "euro_symbol_outlined",
  "euro_symbol_rounded",
  "euro_symbol_sharp",
  "euro_symbol_two_tone",
  "euro_two_tone",
  "ev_station",
  "ev_station_outlined",
  "ev_station_rounded",
  "ev_station_sharp",
  "ev_station_two_tone",
  "event",
  "event_available",
  "event_available_outlined",
  "event_available_rounded",
  "event_available_sharp",
  "event_available_two_tone",
  "event_busy",
  "event_busy_outlined",
  "event_busy_rounded",
  "event_busy_sharp",
  "event_busy_two_tone",
  "event_note",
  "event_note_outlined",
  "event_note_rounded",
  "event_note_sharp",
  "event_note_two_tone",
  "event_outlined",
  "event_rounded",
  "event_seat",
  "event_seat_outlined",
  "event_seat_rounded",
  "event_seat_sharp",
  "event_seat_two_tone",
  "event_sharp",
  "event_two_tone",
  "exit_to_app",
  "exit_to_app_outlined",
  "exit_to_app_rounded",
  "exit_to_app_sharp",
  "exit_to_app_two_tone",
  "expand_less",
  "expand_less_outlined",
  "expand_less_rounded",
  "expand_less_sharp",
  "expand_less_two_tone",
  "expand_more",
  "expand_more_outlined",
  "expand_more_rounded",
  "expand_more_sharp",
  "expand_more_two_tone",
  "explicit",
  "explicit_outlined",
  "explicit_rounded",
  "explicit_sharp",
  "explicit_two_tone",
  "explore",
  "explore_off",
  "explore_off_outlined",
  "explore_off_rounded",
  "explore_off_sharp",
  "explore_off_two_tone",
  "explore_outlined",
  "explore_rounded",
  "explore_sharp",
  "explore_two_tone",
  "exposure",
  "exposure_neg1",
  "exposure_neg1_outlined",
  "exposure_neg1_rounded",
  "exposure_neg1_sharp",
  "exposure_neg1_two_tone",
  "exposure_neg2",
  "exposure_neg2_outlined",
  "exposure_neg2_rounded",
  "exposure_neg2_sharp",
  "exposure_neg2_two_tone",
  "exposure_outlined",
  "exposure_plus1",
  "exposure_plus1_outlined",
  "exposure_plus1_rounded",
  "exposure_plus1_sharp",
  "exposure_plus1_two_tone",
  "exposure_plus2",
  "exposure_plus2_outlined",
  "exposure_plus2_rounded",
  "exposure_plus2_sharp",
  "exposure_plus2_two_tone",
  "exposure_rounded",
  "exposure_sharp",
  "exposure_two_tone",
  "exposure_zero",
  "exposure_zero_outlined",
  "exposure_zero_rounded",
  "exposure_zero_sharp",
  "exposure_zero_two_tone",
  "extension",
  "extension_outlined",
  "extension_rounded",
  "extension_sharp",
  "extension_two_tone",
  "face",
  "face_outlined",
  "face_rounded",
  "face_sharp",
  "face_two_tone",
  "facebook",
  "fast_forward",
  "fast_forward_outlined",
  "fast_forward_rounded",
  "fast_forward_sharp",
  "fast_forward_two_tone",
  "fast_rewind",
  "fast_rewind_outlined",
  "fast_rewind_rounded",
  "fast_rewind_sharp",
  "fast_rewind_two_tone",
  "fastfood",
  "fastfood_outlined",
  "fastfood_rounded",
  "fastfood_sharp",
  "fastfood_two_tone",
  "favorite",
  "favorite_border",
  "favorite_border_outlined",
  "favorite_border_rounded",
  "favorite_border_sharp",
  "favorite_border_two_tone",
  "favorite_outlined",
  "favorite_rounded",
  "favorite_sharp",
  "favorite_two_tone",
  "featured_play_list",
  "featured_play_list_outlined",
  "featured_play_list_rounded",
  "featured_play_list_sharp",
  "featured_play_list_two_tone",
  "featured_video",
  "featured_video_outlined",
  "featured_video_rounded",
  "featured_video_sharp",
  "featured_video_two_tone",
  "feedback",
  "feedback_outlined",
  "feedback_rounded",
  "feedback_sharp",
  "feedback_two_tone",
  "fiber_dvr",
  "fiber_dvr_outlined",
  "fiber_dvr_rounded",
  "fiber_dvr_sharp",
  "fiber_dvr_two_tone",
  "fiber_manual_record",
  "fiber_manual_record_outlined",
  "fiber_manual_record_rounded",
  "fiber_manual_record_sharp",
  "fiber_manual_record_two_tone",
  "fiber_new",
  "fiber_new_outlined",
  "fiber_new_rounded",
  "fiber_new_sharp",
  "fiber_new_two_tone",
  "fiber_pin",
  "fiber_pin_outlined",
  "fiber_pin_rounded",
  "fiber_pin_sharp",
  "fiber_pin_two_tone",
  "fiber_smart_record",
  "fiber_smart_record_outlined",
  "fiber_smart_record_rounded",
  "fiber_smart_record_sharp",
  "fiber_smart_record_two_tone",
  "file_copy",
  "file_copy_outlined",
  "file_copy_rounded",
  "file_copy_sharp",
  "file_copy_two_tone",
  "filter",
  "filter1",
  "filter1_outlined",
  "filter1_rounded",
  "filter1_sharp",
  "filter1_two_tone",
  "filter2",
  "filter2_outlined",
  "filter2_rounded",
  "filter2_sharp",
  "filter2_two_tone",
  "filter3",
  "filter3_outlined",
  "filter3_rounded",
  "filter3_sharp",
  "filter3_two_tone",
  "filter4",
  "filter4_outlined",
  "filter4_rounded",
  "filter4_sharp",
  "filter4_two_tone",
  "filter5",
  "filter5_outlined",
  "filter5_rounded",
  "filter5_sharp",
  "filter5_two_tone",
  "filter6",
  "filter6_outlined",
  "filter6_rounded",
  "filter6_sharp",
  "filter6_two_tone",
  "filter7",
  "filter7_outlined",
  "filter7_rounded",
  "filter7_sharp",
  "filter7_two_tone",
  "filter8",
  "filter8_outlined",
  "filter8_rounded",
  "filter8_sharp",
  "filter8_two_tone",
  "filter9",
  "filter9_outlined",
  "filter9_plus",
  "filter9_plus_outlined",
  "filter9_plus_rounded",
  "filter9_plus_sharp",
  "filter9_plus_two_tone",
  "filter9_rounded",
  "filter9_sharp",
  "filter9_two_tone",
  "filter_b_and_w",
  "filter_b_and_w_outlined",
  "filter_b_and_w_rounded",
  "filter_b_and_w_sharp",
  "filter_b_and_w_two_tone",
  "filter_center_focus",
  "filter_center_focus_outlined",
  "filter_center_focus_rounded",
  "filter_center_focus_sharp",
  "filter_center_focus_two_tone",
  "filter_drama",
  "filter_drama_outlined",
  "filter_drama_rounded",
  "filter_drama_sharp",
  "filter_drama_two_tone",
  "filter_frames",
  "filter_frames_outlined",
  "filter_frames_rounded",
  "filter_frames_sharp",
  "filter_frames_two_tone",
  "filter_hdr",
  "filter_hdr_outlined",
  "filter_hdr_rounded",
  "filter_hdr_sharp",
  "filter_hdr_two_tone",
  "filter_list",
  "filter_list_outlined",
  "filter_list_rounded",
  "filter_list_sharp",
  "filter_list_two_tone",
  "filter_none",
  "filter_none_outlined",
  "filter_none_rounded",
  "filter_none_sharp",
  "filter_none_two_tone",
  "filter_outlined",
  "filter_rounded",
  "filter_sharp",
  "filter_tilt_shift",
  "filter_tilt_shift_outlined",
  "filter_tilt_shift_rounded",
  "filter_tilt_shift_sharp",
  "filter_tilt_shift_two_tone",
  "filter_two_tone",
  "filter_vintage",
  "filter_vintage_outlined",
  "filter_vintage_rounded",
  "filter_vintage_sharp",
  "filter_vintage_two_tone",
  "find_in_page",
  "find_in_page_outlined",
  "find_in_page_rounded",
  "find_in_page_sharp",
  "find_in_page_two_tone",
  "find_replace",
  "find_replace_outlined",
  "find_replace_rounded",
  "find_replace_sharp",
  "find_replace_two_tone",
  "fingerprint",
  "fingerprint_outlined",
  "fingerprint_rounded",
  "fingerprint_sharp",
  "fingerprint_two_tone",
  "fireplace",
  "fireplace_outlined",
  "fireplace_rounded",
  "fireplace_sharp",
  "fireplace_two_tone",
  "first_page",
  "first_page_outlined",
  "first_page_rounded",
  "first_page_sharp",
  "first_page_two_tone",
  "fitness_center",
  "fitness_center_outlined",
  "fitness_center_rounded",
  "fitness_center_sharp",
  "fitness_center_two_tone",
  "flag",
  "flag_outlined",
  "flag_rounded",
  "flag_sharp",
  "flag_two_tone",
  "flare",
  "flare_outlined",
  "flare_rounded",
  "flare_sharp",
  "flare_two_tone",
  "flash_auto",
  "flash_auto_outlined",
  "flash_auto_rounded",
  "flash_auto_sharp",
  "flash_auto_two_tone",
  "flash_off",
  "flash_off_outlined",
  "flash_off_rounded",
  "flash_off_sharp",
  "flash_off_two_tone",
  "flash_on",
  "flash_on_outlined",
  "flash_on_rounded",
  "flash_on_sharp",
  "flash_on_two_tone",
  "flight",
  "flight_land",
  "flight_land_outlined",
  "flight_land_rounded",
  "flight_land_sharp",
  "flight_land_two_tone",
  "flight_outlined",
  "flight_rounded",
  "flight_sharp",
  "flight_takeoff",
  "flight_takeoff_outlined",
  "flight_takeoff_rounded",
  "flight_takeoff_sharp",
  "flight_takeoff_two_tone",
  "flight_two_tone",
  "flip",
  "flip_camera_android",
  "flip_camera_android_outlined",
  "flip_camera_android_rounded",
  "flip_camera_android_sharp",
  "flip_camera_android_two_tone",
  "flip_camera_ios",
  "flip_camera_ios_outlined",
  "flip_camera_ios_rounded",
  "flip_camera_ios_sharp",
  "flip_camera_ios_two_tone",
  "flip_outlined",
  "flip_rounded",
  "flip_sharp",
  "flip_to_back",
  "flip_to_back_outlined",
  "flip_to_back_rounded",
  "flip_to_back_sharp",
  "flip_to_back_two_tone",
  "flip_to_front",
  "flip_to_front_outlined",
  "flip_to_front_rounded",
  "flip_to_front_sharp",
  "flip_to_front_two_tone",
  "flip_two_tone",
  "folder",
  "folder_open",
  "folder_open_outlined",
  "folder_open_rounded",
  "folder_open_sharp",
  "folder_open_two_tone",
  "folder_outlined",
  "folder_rounded",
  "folder_shared",
  "folder_shared_outlined",
  "folder_shared_rounded",
  "folder_shared_sharp",
  "folder_shared_two_tone",
  "folder_sharp",
  "folder_special",
  "folder_special_outlined",
  "folder_special_rounded",
  "folder_special_sharp",
  "folder_special_two_tone",
  "folder_two_tone",
  "font_download",
  "font_download_outlined",
  "font_download_rounded",
  "font_download_sharp",
  "font_download_two_tone",
  "format_align_center",
  "format_align_center_outlined",
  "format_align_center_rounded",
  "format_align_center_sharp",
  "format_align_center_two_tone",
  "format_align_justify",
  "format_align_justify_outlined",
  "format_align_justify_rounded",
  "format_align_justify_sharp",
  "format_align_justify_two_tone",
  "format_align_left",
  "format_align_left_outlined",
  "format_align_left_rounded",
  "format_align_left_sharp",
  "format_align_left_two_tone",
  "format_align_right",
  "format_align_right_outlined",
  "format_align_right_rounded",
  "format_align_right_sharp",
  "format_align_right_two_tone",
  "format_bold",
  "format_bold_outlined",
  "format_bold_rounded",
  "format_bold_sharp",
  "format_bold_two_tone",
  "format_clear",
  "format_clear_outlined",
  "format_clear_rounded",
  "format_clear_sharp",
  "format_clear_two_tone",
  "format_color_fill",
  "format_color_fill_outlined",
  "format_color_fill_rounded",
  "format_color_fill_sharp",
  "format_color_fill_two_tone",
  "format_color_reset",
  "format_color_reset_outlined",
  "format_color_reset_rounded",
  "format_color_reset_sharp",
  "format_color_reset_two_tone",
  "format_color_text",
  "format_color_text_outlined",
  "format_color_text_rounded",
  "format_color_text_sharp",
  "format_color_text_two_tone",
  "format_indent_decrease",
  "format_indent_decrease_outlined",
  "format_indent_decrease_rounded",
  "format_indent_decrease_sharp",
  "format_indent_decrease_two_tone",
  "format_indent_increase",
  "format_indent_increase_outlined",
  "format_indent_increase_rounded",
  "format_indent_increase_sharp",
  "format_indent_increase_two_tone",
  "format_italic",
  "format_italic_outlined",
  "format_italic_rounded",
  "format_italic_sharp",
  "format_italic_two_tone",
  "format_line_spacing",
  "format_line_spacing_outlined",
  "format_line_spacing_rounded",
  "format_line_spacing_sharp",
  "format_line_spacing_two_tone",
  "format_list_bulleted",
  "format_list_bulleted_outlined",
  "format_list_bulleted_rounded",
  "format_list_bulleted_sharp",
  "format_list_bulleted_two_tone",
  "format_list_numbered",
  "format_list_numbered_outlined",
  "format_list_numbered_rounded",
  "format_list_numbered_rtl",
  "format_list_numbered_rtl_outlined",
  "format_list_numbered_rtl_rounded",
  "format_list_numbered_rtl_sharp",
  "format_list_numbered_rtl_two_tone",
  "format_list_numbered_sharp",
  "format_list_numbered_two_tone",
  "format_paint",
  "format_paint_outlined",
  "format_paint_rounded",
  "format_paint_sharp",
  "format_paint_two_tone",
  "format_quote",
  "format_quote_outlined",
  "format_quote_rounded",
  "format_quote_sharp",
  "format_quote_two_tone",
  "format_shapes",
  "format_shapes_outlined",
  "format_shapes_rounded",
  "format_shapes_sharp",
  "format_shapes_two_tone",
  "format_size",
  "format_size_outlined",
  "format_size_rounded",
  "format_size_sharp",
  "format_size_two_tone",
  "format_strikethrough",
  "format_strikethrough_outlined",
  "format_strikethrough_rounded",
  "format_strikethrough_sharp",
  "format_strikethrough_two_tone",
  "format_textdirection_l_to_r",
  "format_textdirection_l_to_r_outlined",
  "format_textdirection_l_to_r_rounded",
  "format_textdirection_l_to_r_sharp",
  "format_textdirection_l_to_r_two_tone",
  "format_textdirection_r_to_l",
  "format_textdirection_r_to_l_outlined",
  "format_textdirection_r_to_l_rounded",
  "format_textdirection_r_to_l_sharp",
  "format_textdirection_r_to_l_two_tone",
  "format_underlined",
  "format_underlined_outlined",
  "format_underlined_rounded",
  "format_underlined_sharp",
  "format_underlined_two_tone",
  "forum",
  "forum_outlined",
  "forum_rounded",
  "forum_sharp",
  "forum_two_tone",
  "forward",
  "forward10",
  "forward10_outlined",
  "forward10_rounded",
  "forward10_sharp",
  "forward10_two_tone",
  "forward30",
  "forward30_outlined",
  "forward30_rounded",
  "forward30_sharp",
  "forward30_two_tone",
  "forward5",
  "forward5_outlined",
  "forward5_rounded",
  "forward5_sharp",
  "forward5_two_tone",
  "forward_outlined",
  "forward_rounded",
  "forward_sharp",
  "forward_two_tone",
  "four_k",
  "four_k_outlined",
  "four_k_rounded",
  "four_k_sharp",
  "four_k_two_tone",
  "free_breakfast",
  "free_breakfast_outlined",
  "free_breakfast_rounded",
  "free_breakfast_sharp",
  "free_breakfast_two_tone",
  "fullscreen",
  "fullscreen_exit",
  "fullscreen_exit_outlined",
  "fullscreen_exit_rounded",
  "fullscreen_exit_sharp",
  "fullscreen_exit_two_tone",
  "fullscreen_outlined",
  "fullscreen_rounded",
  "fullscreen_sharp",
  "fullscreen_two_tone",
  "functions",
  "functions_outlined",
  "functions_rounded",
  "functions_sharp",
  "functions_two_tone",
  "g_translate",
  "g_translate_outlined",
  "g_translate_rounded",
  "g_translate_sharp",
  "g_translate_two_tone",
  "gamepad",
  "gamepad_outlined",
  "gamepad_rounded",
  "gamepad_sharp",
  "gamepad_two_tone",
  "games",
  "games_outlined",
  "games_rounded",
  "games_sharp",
  "games_two_tone",
  "gavel",
  "gavel_outlined",
  "gavel_rounded",
  "gavel_sharp",
  "gavel_two_tone",
  "gesture",
  "gesture_outlined",
  "gesture_rounded",
  "gesture_sharp",
  "gesture_two_tone",
  "get_app",
  "get_app_outlined",
  "get_app_rounded",
  "get_app_sharp",
  "get_app_two_tone",
  "gif",
  "gif_outlined",
  "gif_rounded",
  "gif_sharp",
  "gif_two_tone",
  "git_hub",
  "golf_course",
  "golf_course_outlined",
  "golf_course_rounded",
  "golf_course_sharp",
  "golf_course_two_tone",
  "gps_fixed",
  "gps_fixed_outlined",
  "gps_fixed_rounded",
  "gps_fixed_sharp",
  "gps_fixed_two_tone",
  "gps_not_fixed",
  "gps_not_fixed_outlined",
  "gps_not_fixed_rounded",
  "gps_not_fixed_sharp",
  "gps_not_fixed_two_tone",
  "gps_off",
  "gps_off_outlined",
  "gps_off_rounded",
  "gps_off_sharp",
  "gps_off_two_tone",
  "grade",
  "grade_outlined",
  "grade_rounded",
  "grade_sharp",
  "grade_two_tone",
  "gradient",
  "gradient_outlined",
  "gradient_rounded",
  "gradient_sharp",
  "gradient_two_tone",
  "grain",
  "grain_outlined",
  "grain_rounded",
  "grain_sharp",
  "grain_two_tone",
  "graphic_eq",
  "graphic_eq_outlined",
  "graphic_eq_rounded",
  "graphic_eq_sharp",
  "graphic_eq_two_tone",
  "grid_off",
  "grid_off_outlined",
  "grid_off_rounded",
  "grid_off_sharp",
  "grid_off_two_tone",
  "grid_on",
  "grid_on_outlined",
  "grid_on_rounded",
  "grid_on_sharp",
  "grid_on_two_tone",
  "group",
  "group_add",
  "group_add_outlined",
  "group_add_rounded",
  "group_add_sharp",
  "group_add_two_tone",
  "group_outlined",
  "group_rounded",
  "group_sharp",
  "group_two_tone",
  "group_work",
  "group_work_outlined",
  "group_work_rounded",
  "group_work_sharp",
  "group_work_two_tone",
  "hd",
  "hd_outlined",
  "hd_rounded",
  "hd_sharp",
  "hd_two_tone",
  "hdr_off",
  "hdr_off_outlined",
  "hdr_off_rounded",
  "hdr_off_sharp",
  "hdr_off_two_tone",
  "hdr_on",
  "hdr_on_outlined",
  "hdr_on_rounded",
  "hdr_on_sharp",
  "hdr_on_two_tone",
  "hdr_strong",
  "hdr_strong_outlined",
  "hdr_strong_rounded",
  "hdr_strong_sharp",
  "hdr_strong_two_tone",
  "hdr_weak",
  "hdr_weak_outlined",
  "hdr_weak_rounded",
  "hdr_weak_sharp",
  "hdr_weak_two_tone",
  "headset",
  "headset_mic",
  "headset_mic_outlined",
  "headset_mic_rounded",
  "headset_mic_sharp",
  "headset_mic_two_tone",
  "headset_outlined",
  "headset_rounded",
  "headset_sharp",
  "headset_two_tone",
  "healing",
  "healing_outlined",
  "healing_rounded",
  "healing_sharp",
  "healing_two_tone",
  "hearing",
  "hearing_outlined",
  "hearing_rounded",
  "hearing_sharp",
  "hearing_two_tone",
  "height",
  "height_outlined",
  "height_rounded",
  "height_sharp",
  "height_two_tone",
  "help",
  "help_outline",
  "help_outline_outlined",
  "help_outline_rounded",
  "help_outline_sharp",
  "help_outline_two_tone",
  "help_outlined",
  "help_rounded",
  "help_sharp",
  "help_two_tone",
  "high_quality",
  "high_quality_outlined",
  "high_quality_rounded",
  "high_quality_sharp",
  "high_quality_two_tone",
  "highlight",
  "highlight_off",
  "highlight_off_outlined",
  "highlight_off_rounded",
  "highlight_off_sharp",
  "highlight_off_two_tone",
  "highlight_outlined",
  "highlight_rounded",
  "highlight_sharp",
  "highlight_two_tone",
  "history",
  "history_outlined",
  "history_rounded",
  "history_sharp",
  "history_two_tone",
  "home",
  "home_outlined",
  "home_rounded",
  "home_sharp",
  "home_two_tone",
  "home_work",
  "home_work_outlined",
  "home_work_rounded",
  "home_work_sharp",
  "home_work_two_tone",
  "horizontal_split",
  "horizontal_split_outlined",
  "horizontal_split_rounded",
  "horizontal_split_sharp",
  "horizontal_split_two_tone",
  "hot_tub",
  "hot_tub_outlined",
  "hot_tub_rounded",
  "hot_tub_sharp",
  "hot_tub_two_tone",
  "hotel",
  "hotel_outlined",
  "hotel_rounded",
  "hotel_sharp",
  "hotel_two_tone",
  "hourglass_empty",
  "hourglass_empty_outlined",
  "hourglass_empty_rounded",
  "hourglass_empty_sharp",
  "hourglass_empty_two_tone",
  "hourglass_full",
  "hourglass_full_outlined",
  "hourglass_full_rounded",
  "hourglass_full_sharp",
  "hourglass_full_two_tone",
  "house",
  "house_outlined",
  "house_rounded",
  "house_sharp",
  "house_two_tone",
  "how_to_reg",
  "how_to_reg_outlined",
  "how_to_reg_rounded",
  "how_to_reg_sharp",
  "how_to_reg_two_tone",
  "how_to_vote",
  "how_to_vote_outlined",
  "how_to_vote_rounded",
  "how_to_vote_sharp",
  "how_to_vote_two_tone",
  "http",
  "http_outlined",
  "http_rounded",
  "http_sharp",
  "http_two_tone",
  "https",
  "https_outlined",
  "https_rounded",
  "https_sharp",
  "https_two_tone",
  "image",
  "image_aspect_ratio",
  "image_aspect_ratio_outlined",
  "image_aspect_ratio_rounded",
  "image_aspect_ratio_sharp",
  "image_aspect_ratio_two_tone",
  "image_outlined",
  "image_rounded",
  "image_search",
  "image_search_outlined",
  "image_search_rounded",
  "image_search_sharp",
  "image_search_two_tone",
  "image_sharp",
  "image_two_tone",
  "import_contacts",
  "import_contacts_outlined",
  "import_contacts_rounded",
  "import_contacts_sharp",
  "import_contacts_two_tone",
  "import_export",
  "import_export_outlined",
  "import_export_rounded",
  "import_export_sharp",
  "import_export_two_tone",
  "important_devices",
  "important_devices_outlined",
  "important_devices_rounded",
  "important_devices_sharp",
  "important_devices_two_tone",
  "inbox",
  "inbox_outlined",
  "inbox_rounded",
  "inbox_sharp",
  "inbox_two_tone",
  "indeterminate_check_box",
  "indeterminate_check_box_outlined",
  "indeterminate_check_box_rounded",
  "indeterminate_check_box_sharp",
  "indeterminate_check_box_two_tone",
  "info",
  "info_outlined",
  "info_rounded",
  "info_sharp",
  "info_two_tone",
  "input",
  "input_outlined",
  "input_rounded",
  "input_sharp",
  "input_two_tone",
  "insert_chart",
  "insert_chart_outlined",
  "insert_chart_outlined_outlined",
  "insert_chart_outlined_rounded",
  "insert_chart_outlined_sharp",
  "insert_chart_outlined_two_tone",
  "insert_chart_rounded",
  "insert_chart_sharp",
  "insert_chart_two_tone",
  "insert_comment",
  "insert_comment_outlined",
  "insert_comment_rounded",
  "insert_comment_sharp",
  "insert_comment_two_tone",
  "insert_drive_file",
  "insert_drive_file_outlined",
  "insert_drive_file_rounded",
  "insert_drive_file_sharp",
  "insert_drive_file_two_tone",
  "insert_emoticon",
  "insert_emoticon_outlined",
  "insert_emoticon_rounded",
  "insert_emoticon_sharp",
  "insert_emoticon_two_tone",
  "insert_invitation",
  "insert_invitation_outlined",
  "insert_invitation_rounded",
  "insert_invitation_sharp",
  "insert_invitation_two_tone",
  "insert_link",
  "insert_link_outlined",
  "insert_link_rounded",
  "insert_link_sharp",
  "insert_link_two_tone",
  "insert_photo",
  "insert_photo_outlined",
  "insert_photo_rounded",
  "insert_photo_sharp",
  "insert_photo_two_tone",
  "instagram",
  "invert_colors",
  "invert_colors_off",
  "invert_colors_off_outlined",
  "invert_colors_off_rounded",
  "invert_colors_off_sharp",
  "invert_colors_off_two_tone",
  "invert_colors_outlined",
  "invert_colors_rounded",
  "invert_colors_sharp",
  "invert_colors_two_tone",
  "iso",
  "iso_outlined",
  "iso_rounded",
  "iso_sharp",
  "iso_two_tone",
  "keyboard",
  "keyboard_arrow_down",
  "keyboard_arrow_down_outlined",
  "keyboard_arrow_down_rounded",
  "keyboard_arrow_down_sharp",
  "keyboard_arrow_down_two_tone",
  "keyboard_arrow_left",
  "keyboard_arrow_left_outlined",
  "keyboard_arrow_left_rounded",
  "keyboard_arrow_left_sharp",
  "keyboard_arrow_left_two_tone",
  "keyboard_arrow_right",
  "keyboard_arrow_right_outlined",
  "keyboard_arrow_right_rounded",
  "keyboard_arrow_right_sharp",
  "keyboard_arrow_right_two_tone",
  "keyboard_arrow_up",
  "keyboard_arrow_up_outlined",
  "keyboard_arrow_up_rounded",
  "keyboard_arrow_up_sharp",
  "keyboard_arrow_up_two_tone",
  "keyboard_backspace",
  "keyboard_backspace_outlined",
  "keyboard_backspace_rounded",
  "keyboard_backspace_sharp",
  "keyboard_backspace_two_tone",
  "keyboard_capslock",
  "keyboard_capslock_outlined",
  "keyboard_capslock_rounded",
  "keyboard_capslock_sharp",
  "keyboard_capslock_two_tone",
  "keyboard_hide",
  "keyboard_hide_outlined",
  "keyboard_hide_rounded",
  "keyboard_hide_sharp",
  "keyboard_hide_two_tone",
  "keyboard_outlined",
  "keyboard_return",
  "keyboard_return_outlined",
  "keyboard_return_rounded",
  "keyboard_return_sharp",
  "keyboard_return_two_tone",
  "keyboard_rounded",
  "keyboard_sharp",
  "keyboard_tab",
  "keyboard_tab_outlined",
  "keyboard_tab_rounded",
  "keyboard_tab_sharp",
  "keyboard_tab_two_tone",
  "keyboard_two_tone",
  "keyboard_voice",
  "keyboard_voice_outlined",
  "keyboard_voice_rounded",
  "keyboard_voice_sharp",
  "keyboard_voice_two_tone",
  "king_bed",
  "king_bed_outlined",
  "king_bed_rounded",
  "king_bed_sharp",
  "king_bed_two_tone",
  "kitchen",
  "kitchen_outlined",
  "kitchen_rounded",
  "kitchen_sharp",
  "kitchen_two_tone",
  "label",
  "label_important",
  "label_important_outlined",
  "label_important_rounded",
  "label_important_sharp",
  "label_important_two_tone",
  "label_off",
  "label_off_outlined",
  "label_off_rounded",
  "label_off_sharp",
  "label_off_two_tone",
  "label_outlined",
  "label_rounded",
  "label_sharp",
  "label_two_tone",
  "landscape",
  "landscape_outlined",
  "landscape_rounded",
  "landscape_sharp",
  "landscape_two_tone",
  "language",
  "language_outlined",
  "language_rounded",
  "language_sharp",
  "language_two_tone",
  "laptop",
  "laptop_chromebook",
  "laptop_chromebook_outlined",
  "laptop_chromebook_rounded",
  "laptop_chromebook_sharp",
  "laptop_chromebook_two_tone",
  "laptop_mac",
  "laptop_mac_outlined",
  "laptop_mac_rounded",
  "laptop_mac_sharp",
  "laptop_mac_two_tone",
  "laptop_outlined",
  "laptop_rounded",
  "laptop_sharp",
  "laptop_two_tone",
  "laptop_windows",
  "laptop_windows_outlined",
  "laptop_windows_rounded",
  "laptop_windows_sharp",
  "laptop_windows_two_tone",
  "last_page",
  "last_page_outlined",
  "last_page_rounded",
  "last_page_sharp",
  "last_page_two_tone",
  "launch",
  "launch_outlined",
  "launch_rounded",
  "launch_sharp",
  "launch_two_tone",
  "layers",
  "layers_clear",
  "layers_clear_outlined",
  "layers_clear_rounded",
  "layers_clear_sharp",
  "layers_clear_two_tone",
  "layers_outlined",
  "layers_rounded",
  "layers_sharp",
  "layers_two_tone",
  "leak_add",
  "leak_add_outlined",
  "leak_add_rounded",
  "leak_add_sharp",
  "leak_add_two_tone",
  "leak_remove",
  "leak_remove_outlined",
  "leak_remove_rounded",
  "leak_remove_sharp",
  "leak_remove_two_tone",
  "lens",
  "lens_outlined",
  "lens_rounded",
  "lens_sharp",
  "lens_two_tone",
  "library_add",
  "library_add_check",
  "library_add_check_outlined",
  "library_add_check_rounded",
  "library_add_check_sharp",
  "library_add_check_two_tone",
  "library_add_outlined",
  "library_add_rounded",
  "library_add_sharp",
  "library_add_two_tone",
  "library_books",
  "library_books_outlined",
  "library_books_rounded",
  "library_books_sharp",
  "library_books_two_tone",
  "library_music",
  "library_music_outlined",
  "library_music_rounded",
  "library_music_sharp",
  "library_music_two_tone",
  "line_style",
  "line_style_outlined",
  "line_style_rounded",
  "line_style_sharp",
  "line_style_two_tone",
  "line_weight",
  "line_weight_outlined",
  "line_weight_rounded",
  "line_weight_sharp",
  "line_weight_two_tone",
  "linear_scale",
  "linear_scale_outlined",
  "linear_scale_rounded",
  "linear_scale_sharp",
  "linear_scale_two_tone",
  "link",
  "link_off",
  "link_off_outlined",
  "link_off_rounded",
  "link_off_sharp",
  "link_off_two_tone",
  "link_outlined",
  "link_rounded",
  "link_sharp",
  "link_two_tone",
  "linked_camera",
  "linked_camera_outlined",
  "linked_camera_rounded",
  "linked_camera_sharp",
  "linked_camera_two_tone",
  "linked_in",
  "list",
  "list_alt",
  "list_alt_outlined",
  "list_alt_rounded",
  "list_alt_sharp",
  "list_alt_two_tone",
  "list_outlined",
  "list_rounded",
  "list_sharp",
  "list_two_tone",
  "live_help",
  "live_help_outlined",
  "live_help_rounded",
  "live_help_sharp",
  "live_help_two_tone",
  "live_tv",
  "live_tv_outlined",
  "live_tv_rounded",
  "live_tv_sharp",
  "live_tv_two_tone",
  "local_activity",
  "local_activity_outlined",
  "local_activity_rounded",
  "local_activity_sharp",
  "local_activity_two_tone",
  "local_airport",
  "local_airport_outlined",
  "local_airport_rounded",
  "local_airport_sharp",
  "local_airport_two_tone",
  "local_atm",
  "local_atm_outlined",
  "local_atm_rounded",
  "local_atm_sharp",
  "local_atm_two_tone",
  "local_bar",
  "local_bar_outlined",
  "local_bar_rounded",
  "local_bar_sharp",
  "local_bar_two_tone",
  "local_cafe",
  "local_cafe_outlined",
  "local_cafe_rounded",
  "local_cafe_sharp",
  "local_cafe_two_tone",
  "local_car_wash",
  "local_car_wash_outlined",
  "local_car_wash_rounded",
  "local_car_wash_sharp",
  "local_car_wash_two_tone",
  "local_convenience_store",
  "local_convenience_store_outlined",
  "local_convenience_store_rounded",
  "local_convenience_store_sharp",
  "local_convenience_store_two_tone",
  "local_dining",
  "local_dining_outlined",
  "local_dining_rounded",
  "local_dining_sharp",
  "local_dining_two_tone",
  "local_drink",
  "local_drink_outlined",
  "local_drink_rounded",
  "local_drink_sharp",
  "local_drink_two_tone",
  "local_florist",
  "local_florist_outlined",
  "local_florist_rounded",
  "local_florist_sharp",
  "local_florist_two_tone",
  "local_gas_station",
  "local_gas_station_outlined",
  "local_gas_station_rounded",
  "local_gas_station_sharp",
  "local_gas_station_two_tone",
  "local_grocery_store",
  "local_grocery_store_outlined",
  "local_grocery_store_rounded",
  "local_grocery_store_sharp",
  "local_grocery_store_two_tone",
  "local_hospital",
  "local_hospital_outlined",
  "local_hospital_rounded",
  "local_hospital_sharp",
  "local_hospital_two_tone",
  "local_hotel",
  "local_hotel_outlined",
  "local_hotel_rounded",
  "local_hotel_sharp",
  "local_hotel_two_tone",
  "local_laundry_service",
  "local_laundry_service_outlined",
  "local_laundry_service_rounded",
  "local_laundry_service_sharp",
  "local_laundry_service_two_tone",
  "local_library",
  "local_library_outlined",
  "local_library_rounded",
  "local_library_sharp",
  "local_library_two_tone",
  "local_mall",
  "local_mall_outlined",
  "local_mall_rounded",
  "local_mall_sharp",
  "local_mall_two_tone",
  "local_movies",
  "local_movies_outlined",
  "local_movies_rounded",
  "local_movies_sharp",
  "local_movies_two_tone",
  "local_offer",
  "local_offer_outlined",
  "local_offer_rounded",
  "local_offer_sharp",
  "local_offer_two_tone",
  "local_parking",
  "local_parking_outlined",
  "local_parking_rounded",
  "local_parking_sharp",
  "local_parking_two_tone",
  "local_pharmacy",
  "local_pharmacy_outlined",
  "local_pharmacy_rounded",
  "local_pharmacy_sharp",
  "local_pharmacy_two_tone",
  "local_phone",
  "local_phone_outlined",
  "local_phone_rounded",
  "local_phone_sharp",
  "local_phone_two_tone",
  "local_pizza",
  "local_pizza_outlined",
  "local_pizza_rounded",
  "local_pizza_sharp",
  "local_pizza_two_tone",
  "local_play",
  "local_play_outlined",
  "local_play_rounded",
  "local_play_sharp",
  "local_play_two_tone",
  "local_post_office",
  "local_post_office_outlined",
  "local_post_office_rounded",
  "local_post_office_sharp",
  "local_post_office_two_tone",
  "local_printshop",
  "local_printshop_outlined",
  "local_printshop_rounded",
  "local_printshop_sharp",
  "local_printshop_two_tone",
  "local_see",
  "local_see_outlined",
  "local_see_rounded",
  "local_see_sharp",
  "local_see_two_tone",
  "local_shipping",
  "local_shipping_outlined",
  "local_shipping_rounded",
  "local_shipping_sharp",
  "local_shipping_two_tone",
  "local_taxi",
  "local_taxi_outlined",
  "local_taxi_rounded",
  "local_taxi_sharp",
  "local_taxi_two_tone",
  "location_city",
  "location_city_outlined",
  "location_city_rounded",
  "location_city_sharp",
  "location_city_two_tone",
  "location_disabled",
  "location_disabled_outlined",
  "location_disabled_rounded",
  "location_disabled_sharp",
  "location_disabled_two_tone",
  "location_off",
  "location_off_outlined",
  "location_off_rounded",
  "location_off_sharp",
  "location_off_two_tone",
  "location_on",
  "location_on_outlined",
  "location_on_rounded",
  "location_on_sharp",
  "location_on_two_tone",
  "location_searching",
  "location_searching_outlined",
  "location_searching_rounded",
  "location_searching_sharp",
  "location_searching_two_tone",
  "lock",
  "lock_open",
  "lock_open_outlined",
  "lock_open_rounded",
  "lock_open_sharp",
  "lock_open_two_tone",
  "lock_outlined",
  "lock_rounded",
  "lock_sharp",
  "lock_two_tone",
  "logout",
  "looks",
  "looks3",
  "looks3_outlined",
  "looks3_rounded",
  "looks3_sharp",
  "looks3_two_tone",
  "looks4",
  "looks4_outlined",
  "looks4_rounded",
  "looks4_sharp",
  "looks4_two_tone",
  "looks5",
  "looks5_outlined",
  "looks5_rounded",
  "looks5_sharp",
  "looks5_two_tone",
  "looks6",
  "looks6_outlined",
  "looks6_rounded",
  "looks6_sharp",
  "looks6_two_tone",
  "looks_one",
  "looks_one_outlined",
  "looks_one_rounded",
  "looks_one_sharp",
  "looks_one_two_tone",
  "looks_outlined",
  "looks_rounded",
  "looks_sharp",
  "looks_two",
  "looks_two_outlined",
  "looks_two_rounded",
  "looks_two_sharp",
  "looks_two_tone",
  "looks_two_two_tone",
  "loop",
  "loop_outlined",
  "loop_rounded",
  "loop_sharp",
  "loop_two_tone",
  "loupe",
  "loupe_outlined",
  "loupe_rounded",
  "loupe_sharp",
  "loupe_two_tone",
  "low_priority",
  "low_priority_outlined",
  "low_priority_rounded",
  "low_priority_sharp",
  "low_priority_two_tone",
  "loyalty",
  "loyalty_outlined",
  "loyalty_rounded",
  "loyalty_sharp",
  "loyalty_two_tone",
  "mail",
  "mail_outline",
  "mail_outline_outlined",
  "mail_outline_rounded",
  "mail_outline_sharp",
  "mail_outline_two_tone",
  "mail_outlined",
  "mail_rounded",
  "mail_sharp",
  "mail_two_tone",
  "map",
  "map_outlined",
  "map_rounded",
  "map_sharp",
  "map_two_tone",
  "markunread",
  "markunread_mailbox",
  "markunread_mailbox_outlined",
  "markunread_mailbox_rounded",
  "markunread_mailbox_sharp",
  "markunread_mailbox_two_tone",
  "markunread_outlined",
  "markunread_rounded",
  "markunread_sharp",
  "markunread_two_tone",
  "maximize",
  "maximize_outlined",
  "maximize_rounded",
  "maximize_sharp",
  "maximize_two_tone",
  "meeting_room",
  "meeting_room_outlined",
  "meeting_room_rounded",
  "meeting_room_sharp",
  "meeting_room_two_tone",
  "memory",
  "memory_outlined",
  "memory_rounded",
  "memory_sharp",
  "memory_two_tone",
  "menu",
  "menu_book",
  "menu_book_outlined",
  "menu_book_rounded",
  "menu_book_sharp",
  "menu_book_two_tone",
  "menu_open",
  "menu_open_outlined",
  "menu_open_rounded",
  "menu_open_sharp",
  "menu_open_two_tone",
  "menu_outlined",
  "menu_rounded",
  "menu_sharp",
  "menu_two_tone",
  "merge_type",
  "merge_type_outlined",
  "merge_type_rounded",
  "merge_type_sharp",
  "merge_type_two_tone",
  "message",
  "message_outlined",
  "message_rounded",
  "message_sharp",
  "message_two_tone",
  "mic",
  "mic_none",
  "mic_none_outlined",
  "mic_none_rounded",
  "mic_none_sharp",
  "mic_none_two_tone",
  "mic_off",
  "mic_off_outlined",
  "mic_off_rounded",
  "mic_off_sharp",
  "mic_off_two_tone",
  "mic_outlined",
  "mic_rounded",
  "mic_sharp",
  "mic_two_tone",
  "minimize",
  "minimize_outlined",
  "minimize_rounded",
  "minimize_sharp",
  "minimize_two_tone",
  "missed_video_call",
  "missed_video_call_outlined",
  "missed_video_call_rounded",
  "missed_video_call_sharp",
  "missed_video_call_two_tone",
  "mms",
  "mms_outlined",
  "mms_rounded",
  "mms_sharp",
  "mms_two_tone",
  "mobile_friendly",
  "mobile_friendly_outlined",
  "mobile_friendly_rounded",
  "mobile_friendly_sharp",
  "mobile_friendly_two_tone",
  "mobile_off",
  "mobile_off_outlined",
  "mobile_off_rounded",
  "mobile_off_sharp",
  "mobile_off_two_tone",
  "mobile_screen_share",
  "mobile_screen_share_outlined",
  "mobile_screen_share_rounded",
  "mobile_screen_share_sharp",
  "mobile_screen_share_two_tone",
  "mode_comment",
  "mode_comment_outlined",
  "mode_comment_rounded",
  "mode_comment_sharp",
  "mode_comment_two_tone",
  "monetization_on",
  "monetization_on_outlined",
  "monetization_on_rounded",
  "monetization_on_sharp",
  "monetization_on_two_tone",
  "money",
  "money_off",
  "money_off_outlined",
  "money_off_rounded",
  "money_off_sharp",
  "money_off_two_tone",
  "money_outlined",
  "money_rounded",
  "money_sharp",
  "money_two_tone",
  "monochrome_photos",
  "monochrome_photos_outlined",
  "monochrome_photos_rounded",
  "monochrome_photos_sharp",
  "monochrome_photos_two_tone",
  "mood",
  "mood_bad",
  "mood_bad_outlined",
  "mood_bad_rounded",
  "mood_bad_sharp",
  "mood_bad_two_tone",
  "mood_outlined",
  "mood_rounded",
  "mood_sharp",
  "mood_two_tone",
  "more",
  "more_horiz",
  "more_horiz_outlined",
  "more_horiz_rounded",
  "more_horiz_sharp",
  "more_horiz_two_tone",
  "more_outlined",
  "more_rounded",
  "more_sharp",
  "more_two_tone",
  "more_vert",
  "more_vert_outlined",
  "more_vert_rounded",
  "more_vert_sharp",
  "more_vert_two_tone",
  "motorcycle",
  "motorcycle_outlined",
  "motorcycle_rounded",
  "motorcycle_sharp",
  "motorcycle_two_tone",
  "mouse",
  "mouse_outlined",
  "mouse_rounded",
  "mouse_sharp",
  "mouse_two_tone",
  "move_to_inbox",
  "move_to_inbox_outlined",
  "move_to_inbox_rounded",
  "move_to_inbox_sharp",
  "move_to_inbox_two_tone",
  "movie",
  "movie_creation",
  "movie_creation_outlined",
  "movie_creation_rounded",
  "movie_creation_sharp",
  "movie_creation_two_tone",
  "movie_filter",
  "movie_filter_outlined",
  "movie_filter_rounded",
  "movie_filter_sharp",
  "movie_filter_two_tone",
  "movie_outlined",
  "movie_rounded",
  "movie_sharp",
  "movie_two_tone",
  "multiline_chart",
  "multiline_chart_outlined",
  "multiline_chart_rounded",
  "multiline_chart_sharp",
  "multiline_chart_two_tone",
  "museum",
  "museum_outlined",
  "museum_rounded",
  "museum_sharp",
  "museum_two_tone",
  "music_note",
  "music_note_outlined",
  "music_note_rounded",
  "music_note_sharp",
  "music_note_two_tone",
  "music_off",
  "music_off_outlined",
  "music_off_rounded",
  "music_off_sharp",
  "music_off_two_tone",
  "music_video",
  "music_video_outlined",
  "music_video_rounded",
  "music_video_sharp",
  "music_video_two_tone",
  "my_location",
  "my_location_outlined",
  "my_location_rounded",
  "my_location_sharp",
  "my_location_two_tone",
  "nature",
  "nature_outlined",
  "nature_people",
  "nature_people_outlined",
  "nature_people_rounded",
  "nature_people_sharp",
  "nature_people_two_tone",
  "nature_rounded",
  "nature_sharp",
  "nature_two_tone",
  "navigate_before",
  "navigate_before_outlined",
  "navigate_before_rounded",
  "navigate_before_sharp",
  "navigate_before_two_tone",
  "navigate_next",
  "navigate_next_outlined",
  "navigate_next_rounded",
  "navigate_next_sharp",
  "navigate_next_two_tone",
  "navigation",
  "navigation_outlined",
  "navigation_rounded",
  "navigation_sharp",
  "navigation_two_tone",
  "near_me",
  "near_me_outlined",
  "near_me_rounded",
  "near_me_sharp",
  "near_me_two_tone",
  "network_cell",
  "network_cell_outlined",
  "network_cell_rounded",
  "network_cell_sharp",
  "network_cell_two_tone",
  "network_check",
  "network_check_outlined",
  "network_check_rounded",
  "network_check_sharp",
  "network_check_two_tone",
  "network_locked",
  "network_locked_outlined",
  "network_locked_rounded",
  "network_locked_sharp",
  "network_locked_two_tone",
  "network_wifi",
  "network_wifi_outlined",
  "network_wifi_rounded",
  "network_wifi_sharp",
  "network_wifi_two_tone",
  "new_releases",
  "new_releases_outlined",
  "new_releases_rounded",
  "new_releases_sharp",
  "new_releases_two_tone",
  "next_week",
  "next_week_outlined",
  "next_week_rounded",
  "next_week_sharp",
  "next_week_two_tone",
  "nfc",
  "nfc_outlined",
  "nfc_rounded",
  "nfc_sharp",
  "nfc_two_tone",
  "nights_stay",
  "nights_stay_outlined",
  "nights_stay_rounded",
  "nights_stay_sharp",
  "nights_stay_two_tone",
  "no_encryption",
  "no_encryption_outlined",
  "no_encryption_rounded",
  "no_encryption_sharp",
  "no_encryption_two_tone",
  "no_meeting_room",
  "no_meeting_room_outlined",
  "no_meeting_room_rounded",
  "no_meeting_room_sharp",
  "no_meeting_room_two_tone",
  "no_sim",
  "no_sim_outlined",
  "no_sim_rounded",
  "no_sim_sharp",
  "no_sim_two_tone",
  "not_interested",
  "not_interested_outlined",
  "not_interested_rounded",
  "not_interested_sharp",
  "not_interested_two_tone",
  "not_listed_location",
  "not_listed_location_outlined",
  "not_listed_location_rounded",
  "not_listed_location_sharp",
  "not_listed_location_two_tone",
  "note",
  "note_add",
  "note_add_outlined",
  "note_add_rounded",
  "note_add_sharp",
  "note_add_two_tone",
  "note_outlined",
  "note_rounded",
  "note_sharp",
  "note_two_tone",
  "notes",
  "notes_outlined",
  "notes_rounded",
  "notes_sharp",
  "notes_two_tone",
  "notification_important",
  "notification_important_outlined",
  "notification_important_rounded",
  "notification_important_sharp",
  "notification_important_two_tone",
  "notifications",
  "notifications_active",
  "notifications_active_outlined",
  "notifications_active_rounded",
  "notifications_active_sharp",
  "notifications_active_two_tone",
  "notifications_none",
  "notifications_none_outlined",
  "notifications_none_rounded",
  "notifications_none_sharp",
  "notifications_none_two_tone",
  "notifications_off",
  "notifications_off_outlined",
  "notifications_off_rounded",
  "notifications_off_sharp",
  "notifications_off_two_tone",
  "notifications_outlined",
  "notifications_paused",
  "notifications_paused_outlined",
  "notifications_paused_rounded",
  "notifications_paused_sharp",
  "notifications_paused_two_tone",
  "notifications_rounded",
  "notifications_sharp",
  "notifications_two_tone",
  "offline_bolt",
  "offline_bolt_outlined",
  "offline_bolt_rounded",
  "offline_bolt_sharp",
  "offline_bolt_two_tone",
  "offline_pin",
  "offline_pin_outlined",
  "offline_pin_rounded",
  "offline_pin_sharp",
  "offline_pin_two_tone",
  "ondemand_video",
  "ondemand_video_outlined",
  "ondemand_video_rounded",
  "ondemand_video_sharp",
  "ondemand_video_two_tone",
  "opacity",
  "opacity_outlined",
  "opacity_rounded",
  "opacity_sharp",
  "opacity_two_tone",
  "open_in_browser",
  "open_in_browser_outlined",
  "open_in_browser_rounded",
  "open_in_browser_sharp",
  "open_in_browser_two_tone",
  "open_in_new",
  "open_in_new_outlined",
  "open_in_new_rounded",
  "open_in_new_sharp",
  "open_in_new_two_tone",
  "open_with",
  "open_with_outlined",
  "open_with_rounded",
  "open_with_sharp",
  "open_with_two_tone",
  "outdoor_grill",
  "outdoor_grill_outlined",
  "outdoor_grill_rounded",
  "outdoor_grill_sharp",
  "outdoor_grill_two_tone",
  "outlined_flag",
  "outlined_flag_outlined",
  "outlined_flag_rounded",
  "outlined_flag_sharp",
  "outlined_flag_two_tone",
  "pages",
  "pages_outlined",
  "pages_rounded",
  "pages_sharp",
  "pages_two_tone",
  "pageview",
  "pageview_outlined",
  "pageview_rounded",
  "pageview_sharp",
  "pageview_two_tone",
  "palette",
  "palette_outlined",
  "palette_rounded",
  "palette_sharp",
  "palette_two_tone",
  "pan_tool",
  "pan_tool_outlined",
  "pan_tool_rounded",
  "pan_tool_sharp",
  "pan_tool_two_tone",
  "panorama",
  "panorama_fish_eye",
  "panorama_fish_eye_outlined",
  "panorama_fish_eye_rounded",
  "panorama_fish_eye_sharp",
  "panorama_fish_eye_two_tone",
  "panorama_horizontal",
  "panorama_horizontal_outlined",
  "panorama_horizontal_rounded",
  "panorama_horizontal_sharp",
  "panorama_horizontal_two_tone",
  "panorama_outlined",
  "panorama_rounded",
  "panorama_sharp",
  "panorama_two_tone",
  "panorama_vertical",
  "panorama_vertical_outlined",
  "panorama_vertical_rounded",
  "panorama_vertical_sharp",
  "panorama_vertical_two_tone",
  "panorama_wide_angle",
  "panorama_wide_angle_outlined",
  "panorama_wide_angle_rounded",
  "panorama_wide_angle_sharp",
  "panorama_wide_angle_two_tone",
  "party_mode",
  "party_mode_outlined",
  "party_mode_rounded",
  "party_mode_sharp",
  "party_mode_two_tone",
  "pause",
  "pause_circle_filled",
  "pause_circle_filled_outlined",
  "pause_circle_filled_rounded",
  "pause_circle_filled_sharp",
  "pause_circle_filled_two_tone",
  "pause_circle_outline",
  "pause_circle_outline_outlined",
  "pause_circle_outline_rounded",
  "pause_circle_outline_sharp",
  "pause_circle_outline_two_tone",
  "pause_outlined",
  "pause_presentation",
  "pause_presentation_outlined",
  "pause_presentation_rounded",
  "pause_presentation_sharp",
  "pause_presentation_two_tone",
  "pause_rounded",
  "pause_sharp",
  "pause_two_tone",
  "payment",
  "payment_outlined",
  "payment_rounded",
  "payment_sharp",
  "payment_two_tone",
  "people",
  "people_alt",
  "people_alt_outlined",
  "people_alt_rounded",
  "people_alt_sharp",
  "people_alt_two_tone",
  "people_outline",
  "people_outline_outlined",
  "people_outline_rounded",
  "people_outline_sharp",
  "people_outline_two_tone",
  "people_outlined",
  "people_rounded",
  "people_sharp",
  "people_two_tone",
  "perm_camera_mic",
  "perm_camera_mic_outlined",
  "perm_camera_mic_rounded",
  "perm_camera_mic_sharp",
  "perm_camera_mic_two_tone",
  "perm_contact_calendar",
  "perm_contact_calendar_outlined",
  "perm_contact_calendar_rounded",
  "perm_contact_calendar_sharp",
  "perm_contact_calendar_two_tone",
  "perm_data_setting",
  "perm_data_setting_outlined",
  "perm_data_setting_rounded",
  "perm_data_setting_sharp",
  "perm_data_setting_two_tone",
  "perm_device_information",
  "perm_device_information_outlined",
  "perm_device_information_rounded",
  "perm_device_information_sharp",
  "perm_device_information_two_tone",
  "perm_identity",
  "perm_identity_outlined",
  "perm_identity_rounded",
  "perm_identity_sharp",
  "perm_identity_two_tone",
  "perm_media",
  "perm_media_outlined",
  "perm_media_rounded",
  "perm_media_sharp",
  "perm_media_two_tone",
  "perm_phone_msg",
  "perm_phone_msg_outlined",
  "perm_phone_msg_rounded",
  "perm_phone_msg_sharp",
  "perm_phone_msg_two_tone",
  "perm_scan_wifi",
  "perm_scan_wifi_outlined",
  "perm_scan_wifi_rounded",
  "perm_scan_wifi_sharp",
  "perm_scan_wifi_two_tone",
  "person",
  "person_add",
  "person_add_disabled",
  "person_add_disabled_outlined",
  "person_add_disabled_rounded",
  "person_add_disabled_sharp",
  "person_add_disabled_two_tone",
  "person_add_outlined",
  "person_add_rounded",
  "person_add_sharp",
  "person_add_two_tone",
  "person_outline",
  "person_outline_outlined",
  "person_outline_rounded",
  "person_outline_sharp",
  "person_outline_two_tone",
  "person_outlined",
  "person_pin",
  "person_pin_circle",
  "person_pin_circle_outlined",
  "person_pin_circle_rounded",
  "person_pin_circle_sharp",
  "person_pin_circle_two_tone",
  "person_pin_outlined",
  "person_pin_rounded",
  "person_pin_sharp",
  "person_pin_two_tone",
  "person_rounded",
  "person_sharp",
  "person_two_tone",
  "personal_video",
  "personal_video_outlined",
  "personal_video_rounded",
  "personal_video_sharp",
  "personal_video_two_tone",
  "pets",
  "pets_outlined",
  "pets_rounded",
  "pets_sharp",
  "pets_two_tone",
  "phone",
  "phone_android",
  "phone_android_outlined",
  "phone_android_rounded",
  "phone_android_sharp",
  "phone_android_two_tone",
  "phone_bluetooth_speaker",
  "phone_bluetooth_speaker_outlined",
  "phone_bluetooth_speaker_rounded",
  "phone_bluetooth_speaker_sharp",
  "phone_bluetooth_speaker_two_tone",
  "phone_callback",
  "phone_callback_outlined",
  "phone_callback_rounded",
  "phone_callback_sharp",
  "phone_callback_two_tone",
  "phone_disabled",
  "phone_disabled_outlined",
  "phone_disabled_rounded",
  "phone_disabled_sharp",
  "phone_disabled_two_tone",
  "phone_enabled",
  "phone_enabled_outlined",
  "phone_enabled_rounded",
  "phone_enabled_sharp",
  "phone_enabled_two_tone",
  "phone_forwarded",
  "phone_forwarded_outlined",
  "phone_forwarded_rounded",
  "phone_forwarded_sharp",
  "phone_forwarded_two_tone",
  "phone_in_talk",
  "phone_in_talk_outlined",
  "phone_in_talk_rounded",
  "phone_in_talk_sharp",
  "phone_in_talk_two_tone",
  "phone_iphone",
  "phone_iphone_outlined",
  "phone_iphone_rounded",
  "phone_iphone_sharp",
  "phone_iphone_two_tone",
  "phone_locked",
  "phone_locked_outlined",
  "phone_locked_rounded",
  "phone_locked_sharp",
  "phone_locked_two_tone",
  "phone_missed",
  "phone_missed_outlined",
  "phone_missed_rounded",
  "phone_missed_sharp",
  "phone_missed_two_tone",
  "phone_outlined",
  "phone_paused",
  "phone_paused_outlined",
  "phone_paused_rounded",
  "phone_paused_sharp",
  "phone_paused_two_tone",
  "phone_rounded",
  "phone_sharp",
  "phone_two_tone",
  "phonelink",
  "phonelink_erase",
  "phonelink_erase_outlined",
  "phonelink_erase_rounded",
  "phonelink_erase_sharp",
  "phonelink_erase_two_tone",
  "phonelink_lock",
  "phonelink_lock_outlined",
  "phonelink_lock_rounded",
  "phonelink_lock_sharp",
  "phonelink_lock_two_tone",
  "phonelink_off",
  "phonelink_off_outlined",
  "phonelink_off_rounded",
  "phonelink_off_sharp",
  "phonelink_off_two_tone",
  "phonelink_outlined",
  "phonelink_ring",
  "phonelink_ring_outlined",
  "phonelink_ring_rounded",
  "phonelink_ring_sharp",
  "phonelink_ring_two_tone",
  "phonelink_rounded",
  "phonelink_setup",
  "phonelink_setup_outlined",
  "phonelink_setup_rounded",
  "phonelink_setup_sharp",
  "phonelink_setup_two_tone",
  "phonelink_sharp",
  "phonelink_two_tone",
  "photo",
  "photo_album",
  "photo_album_outlined",
  "photo_album_rounded",
  "photo_album_sharp",
  "photo_album_two_tone",
  "photo_camera",
  "photo_camera_outlined",
  "photo_camera_rounded",
  "photo_camera_sharp",
  "photo_camera_two_tone",
  "photo_filter",
  "photo_filter_outlined",
  "photo_filter_rounded",
  "photo_filter_sharp",
  "photo_filter_two_tone",
  "photo_library",
  "photo_library_outlined",
  "photo_library_rounded",
  "photo_library_sharp",
  "photo_library_two_tone",
  "photo_outlined",
  "photo_rounded",
  "photo_sharp",
  "photo_size_select_actual",
  "photo_size_select_actual_outlined",
  "photo_size_select_actual_rounded",
  "photo_size_select_actual_sharp",
  "photo_size_select_actual_two_tone",
  "photo_size_select_large",
  "photo_size_select_large_outlined",
  "photo_size_select_large_rounded",
  "photo_size_select_large_sharp",
  "photo_size_select_large_two_tone",
  "photo_size_select_small",
  "photo_size_select_small_outlined",
  "photo_size_select_small_rounded",
  "photo_size_select_small_sharp",
  "photo_size_select_small_two_tone",
  "photo_two_tone",
  "picture_as_pdf",
  "picture_as_pdf_outlined",
  "picture_as_pdf_rounded",
  "picture_as_pdf_sharp",
  "picture_as_pdf_two_tone",
  "picture_in_picture",
  "picture_in_picture_alt",
  "picture_in_picture_alt_outlined",
  "picture_in_picture_alt_rounded",
  "picture_in_picture_alt_sharp",
  "picture_in_picture_alt_two_tone",
  "picture_in_picture_outlined",
  "picture_in_picture_rounded",
  "picture_in_picture_sharp",
  "picture_in_picture_two_tone",
  "pie_chart",
  "pie_chart_outlined",
  "pie_chart_rounded",
  "pie_chart_sharp",
  "pie_chart_two_tone",
  "pin_drop",
  "pin_drop_outlined",
  "pin_drop_rounded",
  "pin_drop_sharp",
  "pin_drop_two_tone",
  "pinterest",
  "place",
  "place_outlined",
  "place_rounded",
  "place_sharp",
  "place_two_tone",
  "play_arrow",
  "play_arrow_outlined",
  "play_arrow_rounded",
  "play_arrow_sharp",
  "play_arrow_two_tone",
  "play_circle_filled",
  "play_circle_filled_outlined",
  "play_circle_filled_rounded",
  "play_circle_filled_sharp",
  "play_circle_filled_two_tone",
  "play_circle_filled_white",
  "play_circle_filled_white_outlined",
  "play_circle_filled_white_rounded",
  "play_circle_filled_white_sharp",
  "play_circle_filled_white_two_tone",
  "play_circle_outline",
  "play_circle_outline_outlined",
  "play_circle_outline_rounded",
  "play_circle_outline_sharp",
  "play_circle_outline_two_tone",
  "play_for_work",
  "play_for_work_outlined",
  "play_for_work_rounded",
  "play_for_work_sharp",
  "play_for_work_two_tone",
  "playlist_add",
  "playlist_add_check",
  "playlist_add_check_outlined",
  "playlist_add_check_rounded",
  "playlist_add_check_sharp",
  "playlist_add_check_two_tone",
  "playlist_add_outlined",
  "playlist_add_rounded",
  "playlist_add_sharp",
  "playlist_add_two_tone",
  "playlist_play",
  "playlist_play_outlined",
  "playlist_play_rounded",
  "playlist_play_sharp",
  "playlist_play_two_tone",
  "plus_one",
  "plus_one_outlined",
  "plus_one_rounded",
  "plus_one_sharp",
  "plus_one_two_tone",
  "policy",
  "policy_outlined",
  "policy_rounded",
  "policy_sharp",
  "policy_two_tone",
  "poll",
  "poll_outlined",
  "poll_rounded",
  "poll_sharp",
  "poll_two_tone",
  "polymer",
  "polymer_outlined",
  "polymer_rounded",
  "polymer_sharp",
  "polymer_two_tone",
  "pool",
  "pool_outlined",
  "pool_rounded",
  "pool_sharp",
  "pool_two_tone",
  "portable_wifi_off",
  "portable_wifi_off_outlined",
  "portable_wifi_off_rounded",
  "portable_wifi_off_sharp",
  "portable_wifi_off_two_tone",
  "portrait",
  "portrait_outlined",
  "portrait_rounded",
  "portrait_sharp",
  "portrait_two_tone",
  "post_add",
  "post_add_outlined",
  "post_add_rounded",
  "post_add_sharp",
  "post_add_two_tone",
  "power",
  "power_input",
  "power_input_outlined",
  "power_input_rounded",
  "power_input_sharp",
  "power_input_two_tone",
  "power_off",
  "power_off_outlined",
  "power_off_rounded",
  "power_off_sharp",
  "power_off_two_tone",
  "power_outlined",
  "power_rounded",
  "power_settings_new",
  "power_settings_new_outlined",
  "power_settings_new_rounded",
  "power_settings_new_sharp",
  "power_settings_new_two_tone",
  "power_sharp",
  "power_two_tone",
  "pregnant_woman",
  "pregnant_woman_outlined",
  "pregnant_woman_rounded",
  "pregnant_woman_sharp",
  "pregnant_woman_two_tone",
  "present_to_all",
  "present_to_all_outlined",
  "present_to_all_rounded",
  "present_to_all_sharp",
  "present_to_all_two_tone",
  "print",
  "print_disabled",
  "print_disabled_outlined",
  "print_disabled_rounded",
  "print_disabled_sharp",
  "print_disabled_two_tone",
  "print_outlined",
  "print_rounded",
  "print_sharp",
  "print_two_tone",
  "priority_high",
  "priority_high_outlined",
  "priority_high_rounded",
  "priority_high_sharp",
  "priority_high_two_tone",
  "public",
  "public_outlined",
  "public_rounded",
  "public_sharp",
  "public_two_tone",
  "publish",
  "publish_outlined",
  "publish_rounded",
  "publish_sharp",
  "publish_two_tone",
  "query_builder",
  "query_builder_outlined",
  "query_builder_rounded",
  "query_builder_sharp",
  "query_builder_two_tone",
  "question_answer",
  "question_answer_outlined",
  "question_answer_rounded",
  "question_answer_sharp",
  "question_answer_two_tone",
  "queue",
  "queue_music",
  "queue_music_outlined",
  "queue_music_rounded",
  "queue_music_sharp",
  "queue_music_two_tone",
  "queue_outlined",
  "queue_play_next",
  "queue_play_next_outlined",
  "queue_play_next_rounded",
  "queue_play_next_sharp",
  "queue_play_next_two_tone",
  "queue_rounded",
  "queue_sharp",
  "queue_two_tone",
  "radio",
  "radio_button_checked",
  "radio_button_checked_outlined",
  "radio_button_checked_rounded",
  "radio_button_checked_sharp",
  "radio_button_checked_two_tone",
  "radio_button_unchecked",
  "radio_button_unchecked_outlined",
  "radio_button_unchecked_rounded",
  "radio_button_unchecked_sharp",
  "radio_button_unchecked_two_tone",
  "radio_outlined",
  "radio_rounded",
  "radio_sharp",
  "radio_two_tone",
  "rate_review",
  "rate_review_outlined",
  "rate_review_rounded",
  "rate_review_sharp",
  "rate_review_two_tone",
  "receipt",
  "receipt_outlined",
  "receipt_rounded",
  "receipt_sharp",
  "receipt_two_tone",
  "recent_actors",
  "recent_actors_outlined",
  "recent_actors_rounded",
  "recent_actors_sharp",
  "recent_actors_two_tone",
  "record_voice_over",
  "record_voice_over_outlined",
  "record_voice_over_rounded",
  "record_voice_over_sharp",
  "record_voice_over_two_tone",
  "reddit",
  "redeem",
  "redeem_outlined",
  "redeem_rounded",
  "redeem_sharp",
  "redeem_two_tone",
  "redo",
  "redo_outlined",
  "redo_rounded",
  "redo_sharp",
  "redo_two_tone",
  "refresh",
  "refresh_outlined",
  "refresh_rounded",
  "refresh_sharp",
  "refresh_two_tone",
  "remove",
  "remove_circle",
  "remove_circle_outline",
  "remove_circle_outline_outlined",
  "remove_circle_outline_rounded",
  "remove_circle_outline_sharp",
  "remove_circle_outline_two_tone",
  "remove_circle_outlined",
  "remove_circle_rounded",
  "remove_circle_sharp",
  "remove_circle_two_tone",
  "remove_from_queue",
  "remove_from_queue_outlined",
  "remove_from_queue_rounded",
  "remove_from_queue_sharp",
  "remove_from_queue_two_tone",
  "remove_outlined",
  "remove_red_eye",
  "remove_red_eye_outlined",
  "remove_red_eye_rounded",
  "remove_red_eye_sharp",
  "remove_red_eye_two_tone",
  "remove_rounded",
  "remove_sharp",
  "remove_shopping_cart",
  "remove_shopping_cart_outlined",
  "remove_shopping_cart_rounded",
  "remove_shopping_cart_sharp",
  "remove_shopping_cart_two_tone",
  "remove_two_tone",
  "reorder",
  "reorder_outlined",
  "reorder_rounded",
  "reorder_sharp",
  "reorder_two_tone",
  "repeat",
  "repeat_one",
  "repeat_one_outlined",
  "repeat_one_rounded",
  "repeat_one_sharp",
  "repeat_one_two_tone",
  "repeat_outlined",
  "repeat_rounded",
  "repeat_sharp",
  "repeat_two_tone",
  "replay",
  "replay10",
  "replay10_outlined",
  "replay10_rounded",
  "replay10_sharp",
  "replay10_two_tone",
  "replay30",
  "replay30_outlined",
  "replay30_rounded",
  "replay30_sharp",
  "replay30_two_tone",
  "replay5",
  "replay5_outlined",
  "replay5_rounded",
  "replay5_sharp",
  "replay5_two_tone",
  "replay_outlined",
  "replay_rounded",
  "replay_sharp",
  "replay_two_tone",
  "reply",
  "reply_all",
  "reply_all_outlined",
  "reply_all_rounded",
  "reply_all_sharp",
  "reply_all_two_tone",
  "reply_outlined",
  "reply_rounded",
  "reply_sharp",
  "reply_two_tone",
  "report",
  "report_off",
  "report_off_outlined",
  "report_off_rounded",
  "report_off_sharp",
  "report_off_two_tone",
  "report_outlined",
  "report_problem",
  "report_problem_outlined",
  "report_problem_rounded",
  "report_problem_sharp",
  "report_problem_two_tone",
  "report_rounded",
  "report_sharp",
  "report_two_tone",
  "restaurant",
  "restaurant_menu",
  "restaurant_menu_outlined",
  "restaurant_menu_rounded",
  "restaurant_menu_sharp",
  "restaurant_menu_two_tone",
  "restaurant_outlined",
  "restaurant_rounded",
  "restaurant_sharp",
  "restaurant_two_tone",
  "restore",
  "restore_from_trash",
  "restore_from_trash_outlined",
  "restore_from_trash_rounded",
  "restore_from_trash_sharp",
  "restore_from_trash_two_tone",
  "restore_outlined",
  "restore_page",
  "restore_page_outlined",
  "restore_page_rounded",
  "restore_page_sharp",
  "restore_page_two_tone",
  "restore_rounded",
  "restore_sharp",
  "restore_two_tone",
  "ring_volume",
  "ring_volume_outlined",
  "ring_volume_rounded",
  "ring_volume_sharp",
  "ring_volume_two_tone",
  "room",
  "room_outlined",
  "room_rounded",
  "room_service",
  "room_service_outlined",
  "room_service_rounded",
  "room_service_sharp",
  "room_service_two_tone",
  "room_sharp",
  "room_two_tone",
  "rotate90_degrees_ccw",
  "rotate90_degrees_ccw_outlined",
  "rotate90_degrees_ccw_rounded",
  "rotate90_degrees_ccw_sharp",
  "rotate90_degrees_ccw_two_tone",
  "rotate_left",
  "rotate_left_outlined",
  "rotate_left_rounded",
  "rotate_left_sharp",
  "rotate_left_two_tone",
  "rotate_right",
  "rotate_right_outlined",
  "rotate_right_rounded",
  "rotate_right_sharp",
  "rotate_right_two_tone",
  "rounded_corner",
  "rounded_corner_outlined",
  "rounded_corner_rounded",
  "rounded_corner_sharp",
  "rounded_corner_two_tone",
  "router",
  "router_outlined",
  "router_rounded",
  "router_sharp",
  "router_two_tone",
  "rowing",
  "rowing_outlined",
  "rowing_rounded",
  "rowing_sharp",
  "rowing_two_tone",
  "rss_feed",
  "rss_feed_outlined",
  "rss_feed_rounded",
  "rss_feed_sharp",
  "rss_feed_two_tone",
  "rv_hookup",
  "rv_hookup_outlined",
  "rv_hookup_rounded",
  "rv_hookup_sharp",
  "rv_hookup_two_tone",
  "satellite",
  "satellite_outlined",
  "satellite_rounded",
  "satellite_sharp",
  "satellite_two_tone",
  "save",
  "save_alt",
  "save_alt_outlined",
  "save_alt_rounded",
  "save_alt_sharp",
  "save_alt_two_tone",
  "save_outlined",
  "save_rounded",
  "save_sharp",
  "save_two_tone",
  "scanner",
  "scanner_outlined",
  "scanner_rounded",
  "scanner_sharp",
  "scanner_two_tone",
  "scatter_plot",
  "scatter_plot_outlined",
  "scatter_plot_rounded",
  "scatter_plot_sharp",
  "scatter_plot_two_tone",
  "schedule",
  "schedule_outlined",
  "schedule_rounded",
  "schedule_sharp",
  "schedule_two_tone",
  "school",
  "school_outlined",
  "school_rounded",
  "school_sharp",
  "school_two_tone",
  "score",
  "score_outlined",
  "score_rounded",
  "score_sharp",
  "score_two_tone",
  "screen_lock_landscape",
  "screen_lock_landscape_outlined",
  "screen_lock_landscape_rounded",
  "screen_lock_landscape_sharp",
  "screen_lock_landscape_two_tone",
  "screen_lock_portrait",
  "screen_lock_portrait_outlined",
  "screen_lock_portrait_rounded",
  "screen_lock_portrait_sharp",
  "screen_lock_portrait_two_tone",
  "screen_lock_rotation",
  "screen_lock_rotation_outlined",
  "screen_lock_rotation_rounded",
  "screen_lock_rotation_sharp",
  "screen_lock_rotation_two_tone",
  "screen_rotation",
  "screen_rotation_outlined",
  "screen_rotation_rounded",
  "screen_rotation_sharp",
  "screen_rotation_two_tone",
  "screen_share",
  "screen_share_outlined",
  "screen_share_rounded",
  "screen_share_sharp",
  "screen_share_two_tone",
  "sd_card",
  "sd_card_outlined",
  "sd_card_rounded",
  "sd_card_sharp",
  "sd_card_two_tone",
  "sd_storage",
  "sd_storage_outlined",
  "sd_storage_rounded",
  "sd_storage_sharp",
  "sd_storage_two_tone",
  "search",
  "search_outlined",
  "search_rounded",
  "search_sharp",
  "search_two_tone",
  "security",
  "security_outlined",
  "security_rounded",
  "security_sharp",
  "security_two_tone",
  "select_all",
  "select_all_outlined",
  "select_all_rounded",
  "select_all_sharp",
  "select_all_two_tone",
  "send",
  "send_outlined",
  "send_rounded",
  "send_sharp",
  "send_two_tone",
  "sentiment_dissatisfied",
  "sentiment_dissatisfied_outlined",
  "sentiment_dissatisfied_rounded",
  "sentiment_dissatisfied_sharp",
  "sentiment_dissatisfied_two_tone",
  "sentiment_satisfied",
  "sentiment_satisfied_alt",
  "sentiment_satisfied_alt_outlined",
  "sentiment_satisfied_alt_rounded",
  "sentiment_satisfied_alt_sharp",
  "sentiment_satisfied_alt_two_tone",
  "sentiment_satisfied_outlined",
  "sentiment_satisfied_rounded",
  "sentiment_satisfied_sharp",
  "sentiment_satisfied_two_tone",
  "sentiment_very_dissatisfied",
  "sentiment_very_dissatisfied_outlined",
  "sentiment_very_dissatisfied_rounded",
  "sentiment_very_dissatisfied_sharp",
  "sentiment_very_dissatisfied_two_tone",
  "sentiment_very_satisfied",
  "sentiment_very_satisfied_outlined",
  "sentiment_very_satisfied_rounded",
  "sentiment_very_satisfied_sharp",
  "sentiment_very_satisfied_two_tone",
  "settings",
  "settings_applications",
  "settings_applications_outlined",
  "settings_applications_rounded",
  "settings_applications_sharp",
  "settings_applications_two_tone",
  "settings_backup_restore",
  "settings_backup_restore_outlined",
  "settings_backup_restore_rounded",
  "settings_backup_restore_sharp",
  "settings_backup_restore_two_tone",
  "settings_bluetooth",
  "settings_bluetooth_outlined",
  "settings_bluetooth_rounded",
  "settings_bluetooth_sharp",
  "settings_bluetooth_two_tone",
  "settings_brightness",
  "settings_brightness_outlined",
  "settings_brightness_rounded",
  "settings_brightness_sharp",
  "settings_brightness_two_tone",
  "settings_cell",
  "settings_cell_outlined",
  "settings_cell_rounded",
  "settings_cell_sharp",
  "settings_cell_two_tone",
  "settings_ethernet",
  "settings_ethernet_outlined",
  "settings_ethernet_rounded",
  "settings_ethernet_sharp",
  "settings_ethernet_two_tone",
  "settings_input_antenna",
  "settings_input_antenna_outlined",
  "settings_input_antenna_rounded",
  "settings_input_antenna_sharp",
  "settings_input_antenna_two_tone",
  "settings_input_component",
  "settings_input_component_outlined",
  "settings_input_component_rounded",
  "settings_input_component_sharp",
  "settings_input_component_two_tone",
  "settings_input_composite",
  "settings_input_composite_outlined",
  "settings_input_composite_rounded",
  "settings_input_composite_sharp",
  "settings_input_composite_two_tone",
  "settings_input_hdmi",
  "settings_input_hdmi_outlined",
  "settings_input_hdmi_rounded",
  "settings_input_hdmi_sharp",
  "settings_input_hdmi_two_tone",
  "settings_input_svideo",
  "settings_input_svideo_outlined",
  "settings_input_svideo_rounded",
  "settings_input_svideo_sharp",
  "settings_input_svideo_two_tone",
  "settings_outlined",
  "settings_overscan",
  "settings_overscan_outlined",
  "settings_overscan_rounded",
  "settings_overscan_sharp",
  "settings_overscan_two_tone",
  "settings_phone",
  "settings_phone_outlined",
  "settings_phone_rounded",
  "settings_phone_sharp",
  "settings_phone_two_tone",
  "settings_power",
  "settings_power_outlined",
  "settings_power_rounded",
  "settings_power_sharp",
  "settings_power_two_tone",
  "settings_remote",
  "settings_remote_outlined",
  "settings_remote_rounded",
  "settings_remote_sharp",
  "settings_remote_two_tone",
  "settings_rounded",
  "settings_sharp",
  "settings_system_daydream",
  "settings_system_daydream_outlined",
  "settings_system_daydream_rounded",
  "settings_system_daydream_sharp",
  "settings_system_daydream_two_tone",
  "settings_two_tone",
  "settings_voice",
  "settings_voice_outlined",
  "settings_voice_rounded",
  "settings_voice_sharp",
  "settings_voice_two_tone",
  "share",
  "share_outlined",
  "share_rounded",
  "share_sharp",
  "share_two_tone",
  "shop",
  "shop_outlined",
  "shop_rounded",
  "shop_sharp",
  "shop_two",
  "shop_two_outlined",
  "shop_two_rounded",
  "shop_two_sharp",
  "shop_two_tone",
  "shop_two_two_tone",
  "shopping_basket",
  "shopping_basket_outlined",
  "shopping_basket_rounded",
  "shopping_basket_sharp",
  "shopping_basket_two_tone",
  "shopping_cart",
  "shopping_cart_outlined",
  "shopping_cart_rounded",
  "shopping_cart_sharp",
  "shopping_cart_two_tone",
  "short_text",
  "short_text_outlined",
  "short_text_rounded",
  "short_text_sharp",
  "short_text_two_tone",
  "show_chart",
  "show_chart_outlined",
  "show_chart_rounded",
  "show_chart_sharp",
  "show_chart_two_tone",
  "shuffle",
  "shuffle_outlined",
  "shuffle_rounded",
  "shuffle_sharp",
  "shuffle_two_tone",
  "shutter_speed",
  "shutter_speed_outlined",
  "shutter_speed_rounded",
  "shutter_speed_sharp",
  "shutter_speed_two_tone",
  "signal_cellular0_bar",
  "signal_cellular0_bar_outlined",
  "signal_cellular0_bar_rounded",
  "signal_cellular0_bar_sharp",
  "signal_cellular0_bar_two_tone",
  "signal_cellular1_bar",
  "signal_cellular1_bar_outlined",
  "signal_cellular1_bar_rounded",
  "signal_cellular1_bar_sharp",
  "signal_cellular1_bar_two_tone",
  "signal_cellular2_bar",
  "signal_cellular2_bar_outlined",
  "signal_cellular2_bar_rounded",
  "signal_cellular2_bar_sharp",
  "signal_cellular2_bar_two_tone",
  "signal_cellular3_bar",
  "signal_cellular3_bar_outlined",
  "signal_cellular3_bar_rounded",
  "signal_cellular3_bar_sharp",
  "signal_cellular3_bar_two_tone",
  "signal_cellular4_bar",
  "signal_cellular4_bar_outlined",
  "signal_cellular4_bar_rounded",
  "signal_cellular4_bar_sharp",
  "signal_cellular4_bar_two_tone",
  "signal_cellular_alt",
  "signal_cellular_alt_outlined",
  "signal_cellular_alt_rounded",
  "signal_cellular_alt_sharp",
  "signal_cellular_alt_two_tone",
  "signal_cellular_connected_no_internet0_bar",
  "signal_cellular_connected_no_internet0_bar_outlined",
  "signal_cellular_connected_no_internet0_bar_rounded",
  "signal_cellular_connected_no_internet0_bar_sharp",
  "signal_cellular_connected_no_internet0_bar_two_tone",
  "signal_cellular_connected_no_internet1_bar",
  "signal_cellular_connected_no_internet1_bar_outlined",
  "signal_cellular_connected_no_internet1_bar_rounded",
  "signal_cellular_connected_no_internet1_bar_sharp",
  "signal_cellular_connected_no_internet1_bar_two_tone",
  "signal_cellular_connected_no_internet2_bar",
  "signal_cellular_connected_no_internet2_bar_outlined",
  "signal_cellular_connected_no_internet2_bar_rounded",
  "signal_cellular_connected_no_internet2_bar_sharp",
  "signal_cellular_connected_no_internet2_bar_two_tone",
  "signal_cellular_connected_no_internet3_bar",
  "signal_cellular_connected_no_internet3_bar_outlined",
  "signal_cellular_connected_no_internet3_bar_rounded",
  "signal_cellular_connected_no_internet3_bar_sharp",
  "signal_cellular_connected_no_internet3_bar_two_tone",
  "signal_cellular_connected_no_internet4_bar",
  "signal_cellular_connected_no_internet4_bar_outlined",
  "signal_cellular_connected_no_internet4_bar_rounded",
  "signal_cellular_connected_no_internet4_bar_sharp",
  "signal_cellular_connected_no_internet4_bar_two_tone",
  "signal_cellular_no_sim",
  "signal_cellular_no_sim_outlined",
  "signal_cellular_no_sim_rounded",
  "signal_cellular_no_sim_sharp",
  "signal_cellular_no_sim_two_tone",
  "signal_cellular_null",
  "signal_cellular_null_outlined",
  "signal_cellular_null_rounded",
  "signal_cellular_null_sharp",
  "signal_cellular_null_two_tone",
  "signal_cellular_off",
  "signal_cellular_off_outlined",
  "signal_cellular_off_rounded",
  "signal_cellular_off_sharp",
  "signal_cellular_off_two_tone",
  "signal_wifi0_bar",
  "signal_wifi0_bar_outlined",
  "signal_wifi0_bar_rounded",
  "signal_wifi0_bar_sharp",
  "signal_wifi0_bar_two_tone",
  "signal_wifi1_bar",
  "signal_wifi1_bar_lock",
  "signal_wifi1_bar_lock_outlined",
  "signal_wifi1_bar_lock_rounded",
  "signal_wifi1_bar_lock_sharp",
  "signal_wifi1_bar_lock_two_tone",
  "signal_wifi1_bar_outlined",
  "signal_wifi1_bar_rounded",
  "signal_wifi1_bar_sharp",
  "signal_wifi1_bar_two_tone",
  "signal_wifi2_bar",
  "signal_wifi2_bar_lock",
  "signal_wifi2_bar_lock_outlined",
  "signal_wifi2_bar_lock_rounded",
  "signal_wifi2_bar_lock_sharp",
  "signal_wifi2_bar_lock_two_tone",
  "signal_wifi2_bar_outlined",
  "signal_wifi2_bar_rounded",
  "signal_wifi2_bar_sharp",
  "signal_wifi2_bar_two_tone",
  "signal_wifi3_bar",
  "signal_wifi3_bar_lock",
  "signal_wifi3_bar_lock_outlined",
  "signal_wifi3_bar_lock_rounded",
  "signal_wifi3_bar_lock_sharp",
  "signal_wifi3_bar_lock_two_tone",
  "signal_wifi3_bar_outlined",
  "signal_wifi3_bar_rounded",
  "signal_wifi3_bar_sharp",
  "signal_wifi3_bar_two_tone",
  "signal_wifi4_bar",
  "signal_wifi4_bar_lock",
  "signal_wifi4_bar_lock_outlined",
  "signal_wifi4_bar_lock_rounded",
  "signal_wifi4_bar_lock_sharp",
  "signal_wifi4_bar_lock_two_tone",
  "signal_wifi4_bar_outlined",
  "signal_wifi4_bar_rounded",
  "signal_wifi4_bar_sharp",
  "signal_wifi4_bar_two_tone",
  "signal_wifi_off",
  "signal_wifi_off_outlined",
  "signal_wifi_off_rounded",
  "signal_wifi_off_sharp",
  "signal_wifi_off_two_tone",
  "sim_card",
  "sim_card_outlined",
  "sim_card_rounded",
  "sim_card_sharp",
  "sim_card_two_tone",
  "single_bed",
  "single_bed_outlined",
  "single_bed_rounded",
  "single_bed_sharp",
  "single_bed_two_tone",
  "skip_next",
  "skip_next_outlined",
  "skip_next_rounded",
  "skip_next_sharp",
  "skip_next_two_tone",
  "skip_previous",
  "skip_previous_outlined",
  "skip_previous_rounded",
  "skip_previous_sharp",
  "skip_previous_two_tone",
  "slideshow",
  "slideshow_outlined",
  "slideshow_rounded",
  "slideshow_sharp",
  "slideshow_two_tone",
  "slow_motion_video",
  "slow_motion_video_outlined",
  "slow_motion_video_rounded",
  "slow_motion_video_sharp",
  "slow_motion_video_two_tone",
  "smartphone",
  "smartphone_outlined",
  "smartphone_rounded",
  "smartphone_sharp",
  "smartphone_two_tone",
  "smoke_free",
  "smoke_free_outlined",
  "smoke_free_rounded",
  "smoke_free_sharp",
  "smoke_free_two_tone",
  "smoking_rooms",
  "smoking_rooms_outlined",
  "smoking_rooms_rounded",
  "smoking_rooms_sharp",
  "smoking_rooms_two_tone",
  "sms",
  "sms_failed",
  "sms_failed_outlined",
  "sms_failed_rounded",
  "sms_failed_sharp",
  "sms_failed_two_tone",
  "sms_outlined",
  "sms_rounded",
  "sms_sharp",
  "sms_two_tone",
  "snooze",
  "snooze_outlined",
  "snooze_rounded",
  "snooze_sharp",
  "snooze_two_tone",
  "sort",
  "sort_by_alpha",
  "sort_by_alpha_outlined",
  "sort_by_alpha_rounded",
  "sort_by_alpha_sharp",
  "sort_by_alpha_two_tone",
  "sort_outlined",
  "sort_rounded",
  "sort_sharp",
  "sort_two_tone",
  "spa",
  "spa_outlined",
  "spa_rounded",
  "spa_sharp",
  "spa_two_tone",
  "space_bar",
  "space_bar_outlined",
  "space_bar_rounded",
  "space_bar_sharp",
  "space_bar_two_tone",
  "speaker",
  "speaker_group",
  "speaker_group_outlined",
  "speaker_group_rounded",
  "speaker_group_sharp",
  "speaker_group_two_tone",
  "speaker_notes",
  "speaker_notes_off",
  "speaker_notes_off_outlined",
  "speaker_notes_off_rounded",
  "speaker_notes_off_sharp",
  "speaker_notes_off_two_tone",
  "speaker_notes_outlined",
  "speaker_notes_rounded",
  "speaker_notes_sharp",
  "speaker_notes_two_tone",
  "speaker_outlined",
  "speaker_phone",
  "speaker_phone_outlined",
  "speaker_phone_rounded",
  "speaker_phone_sharp",
  "speaker_phone_two_tone",
  "speaker_rounded",
  "speaker_sharp",
  "speaker_two_tone",
  "speed",
  "speed_outlined",
  "speed_rounded",
  "speed_sharp",
  "speed_two_tone",
  "spellcheck",
  "spellcheck_outlined",
  "spellcheck_rounded",
  "spellcheck_sharp",
  "spellcheck_two_tone",
  "sports",
  "sports_baseball",
  "sports_baseball_outlined",
  "sports_baseball_rounded",
  "sports_baseball_sharp",
  "sports_baseball_two_tone",
  "sports_basketball",
  "sports_basketball_outlined",
  "sports_basketball_rounded",
  "sports_basketball_sharp",
  "sports_basketball_two_tone",
  "sports_cricket",
  "sports_cricket_outlined",
  "sports_cricket_rounded",
  "sports_cricket_sharp",
  "sports_cricket_two_tone",
  "sports_esports",
  "sports_esports_outlined",
  "sports_esports_rounded",
  "sports_esports_sharp",
  "sports_esports_two_tone",
  "sports_football",
  "sports_football_outlined",
  "sports_football_rounded",
  "sports_football_sharp",
  "sports_football_two_tone",
  "sports_golf",
  "sports_golf_outlined",
  "sports_golf_rounded",
  "sports_golf_sharp",
  "sports_golf_two_tone",
  "sports_handball",
  "sports_handball_outlined",
  "sports_handball_rounded",
  "sports_handball_sharp",
  "sports_handball_two_tone",
  "sports_hockey",
  "sports_hockey_outlined",
  "sports_hockey_rounded",
  "sports_hockey_sharp",
  "sports_hockey_two_tone",
  "sports_kabaddi",
  "sports_kabaddi_outlined",
  "sports_kabaddi_rounded",
  "sports_kabaddi_sharp",
  "sports_kabaddi_two_tone",
  "sports_mma",
  "sports_mma_outlined",
  "sports_mma_rounded",
  "sports_mma_sharp",
  "sports_mma_two_tone",
  "sports_motorsports",
  "sports_motorsports_outlined",
  "sports_motorsports_rounded",
  "sports_motorsports_sharp",
  "sports_motorsports_two_tone",
  "sports_outlined",
  "sports_rounded",
  "sports_rugby",
  "sports_rugby_outlined",
  "sports_rugby_rounded",
  "sports_rugby_sharp",
  "sports_rugby_two_tone",
  "sports_sharp",
  "sports_soccer",
  "sports_soccer_outlined",
  "sports_soccer_rounded",
  "sports_soccer_sharp",
  "sports_soccer_two_tone",
  "sports_tennis",
  "sports_tennis_outlined",
  "sports_tennis_rounded",
  "sports_tennis_sharp",
  "sports_tennis_two_tone",
  "sports_two_tone",
  "sports_volleyball",
  "sports_volleyball_outlined",
  "sports_volleyball_rounded",
  "sports_volleyball_sharp",
  "sports_volleyball_two_tone",
  "square_foot",
  "square_foot_outlined",
  "square_foot_rounded",
  "square_foot_sharp",
  "square_foot_two_tone",
  "star",
  "star_border",
  "star_border_outlined",
  "star_border_rounded",
  "star_border_sharp",
  "star_border_two_tone",
  "star_half",
  "star_half_outlined",
  "star_half_rounded",
  "star_half_sharp",
  "star_half_two_tone",
  "star_outline",
  "star_outline_outlined",
  "star_outline_rounded",
  "star_outline_sharp",
  "star_outline_two_tone",
  "star_outlined",
  "star_rate",
  "star_rate_outlined",
  "star_rate_rounded",
  "star_rate_sharp",
  "star_rate_two_tone",
  "star_rounded",
  "star_sharp",
  "star_two_tone",
  "stars",
  "stars_outlined",
  "stars_rounded",
  "stars_sharp",
  "stars_two_tone",
  "stay_current_landscape",
  "stay_current_landscape_outlined",
  "stay_current_landscape_rounded",
  "stay_current_landscape_sharp",
  "stay_current_landscape_two_tone",
  "stay_current_portrait",
  "stay_current_portrait_outlined",
  "stay_current_portrait_rounded",
  "stay_current_portrait_sharp",
  "stay_current_portrait_two_tone",
  "stay_primary_landscape",
  "stay_primary_landscape_outlined",
  "stay_primary_landscape_rounded",
  "stay_primary_landscape_sharp",
  "stay_primary_landscape_two_tone",
  "stay_primary_portrait",
  "stay_primary_portrait_outlined",
  "stay_primary_portrait_rounded",
  "stay_primary_portrait_sharp",
  "stay_primary_portrait_two_tone",
  "stop",
  "stop_outlined",
  "stop_rounded",
  "stop_screen_share",
  "stop_screen_share_outlined",
  "stop_screen_share_rounded",
  "stop_screen_share_sharp",
  "stop_screen_share_two_tone",
  "stop_sharp",
  "stop_two_tone",
  "storage",
  "storage_outlined",
  "storage_rounded",
  "storage_sharp",
  "storage_two_tone",
  "store",
  "store_mall_directory",
  "store_mall_directory_outlined",
  "store_mall_directory_rounded",
  "store_mall_directory_sharp",
  "store_mall_directory_two_tone",
  "store_outlined",
  "store_rounded",
  "store_sharp",
  "store_two_tone",
  "storefront",
  "storefront_outlined",
  "storefront_rounded",
  "storefront_sharp",
  "storefront_two_tone",
  "straighten",
  "straighten_outlined",
  "straighten_rounded",
  "straighten_sharp",
  "straighten_two_tone",
  "streetview",
  "streetview_outlined",
  "streetview_rounded",
  "streetview_sharp",
  "streetview_two_tone",
  "strikethrough_s",
  "strikethrough_s_outlined",
  "strikethrough_s_rounded",
  "strikethrough_s_sharp",
  "strikethrough_s_two_tone",
  "style",
  "style_outlined",
  "style_rounded",
  "style_sharp",
  "style_two_tone",
  "subdirectory_arrow_left",
  "subdirectory_arrow_left_outlined",
  "subdirectory_arrow_left_rounded",
  "subdirectory_arrow_left_sharp",
  "subdirectory_arrow_left_two_tone",
  "subdirectory_arrow_right",
  "subdirectory_arrow_right_outlined",
  "subdirectory_arrow_right_rounded",
  "subdirectory_arrow_right_sharp",
  "subdirectory_arrow_right_two_tone",
  "subject",
  "subject_outlined",
  "subject_rounded",
  "subject_sharp",
  "subject_two_tone",
  "subscriptions",
  "subscriptions_outlined",
  "subscriptions_rounded",
  "subscriptions_sharp",
  "subscriptions_two_tone",
  "subtitles",
  "subtitles_outlined",
  "subtitles_rounded",
  "subtitles_sharp",
  "subtitles_two_tone",
  "subway",
  "subway_outlined",
  "subway_rounded",
  "subway_sharp",
  "subway_two_tone",
  "supervised_user_circle",
  "supervised_user_circle_outlined",
  "supervised_user_circle_rounded",
  "supervised_user_circle_sharp",
  "supervised_user_circle_two_tone",
  "supervisor_account",
  "supervisor_account_outlined",
  "supervisor_account_rounded",
  "supervisor_account_sharp",
  "supervisor_account_two_tone",
  "surround_sound",
  "surround_sound_outlined",
  "surround_sound_rounded",
  "surround_sound_sharp",
  "surround_sound_two_tone",
  "swap_calls",
  "swap_calls_outlined",
  "swap_calls_rounded",
  "swap_calls_sharp",
  "swap_calls_two_tone",
  "swap_horiz",
  "swap_horiz_outlined",
  "swap_horiz_rounded",
  "swap_horiz_sharp",
  "swap_horiz_two_tone",
  "swap_horizontal_circle",
  "swap_horizontal_circle_outlined",
  "swap_horizontal_circle_rounded",
  "swap_horizontal_circle_sharp",
  "swap_horizontal_circle_two_tone",
  "swap_vert",
  "swap_vert_outlined",
  "swap_vert_rounded",
  "swap_vert_sharp",
  "swap_vert_two_tone",
  "swap_vertical_circle",
  "swap_vertical_circle_outlined",
  "swap_vertical_circle_rounded",
  "swap_vertical_circle_sharp",
  "swap_vertical_circle_two_tone",
  "switch_camera",
  "switch_camera_outlined",
  "switch_camera_rounded",
  "switch_camera_sharp",
  "switch_camera_two_tone",
  "switch_video",
  "switch_video_outlined",
  "switch_video_rounded",
  "switch_video_sharp",
  "switch_video_two_tone",
  "sync",
  "sync_alt",
  "sync_alt_outlined",
  "sync_alt_rounded",
  "sync_alt_sharp",
  "sync_alt_two_tone",
  "sync_disabled",
  "sync_disabled_outlined",
  "sync_disabled_rounded",
  "sync_disabled_sharp",
  "sync_disabled_two_tone",
  "sync_outlined",
  "sync_problem",
  "sync_problem_outlined",
  "sync_problem_rounded",
  "sync_problem_sharp",
  "sync_problem_two_tone",
  "sync_rounded",
  "sync_sharp",
  "sync_two_tone",
  "system_update",
  "system_update_alt",
  "system_update_alt_outlined",
  "system_update_alt_rounded",
  "system_update_alt_sharp",
  "system_update_alt_two_tone",
  "system_update_outlined",
  "system_update_rounded",
  "system_update_sharp",
  "system_update_two_tone",
  "tab",
  "tab_outlined",
  "tab_rounded",
  "tab_sharp",
  "tab_two_tone",
  "tab_unselected",
  "tab_unselected_outlined",
  "tab_unselected_rounded",
  "tab_unselected_sharp",
  "tab_unselected_two_tone",
  "table_chart",
  "table_chart_outlined",
  "table_chart_rounded",
  "table_chart_sharp",
  "table_chart_two_tone",
  "tablet",
  "tablet_android",
  "tablet_android_outlined",
  "tablet_android_rounded",
  "tablet_android_sharp",
  "tablet_android_two_tone",
  "tablet_mac",
  "tablet_mac_outlined",
  "tablet_mac_rounded",
  "tablet_mac_sharp",
  "tablet_mac_two_tone",
  "tablet_outlined",
  "tablet_rounded",
  "tablet_sharp",
  "tablet_two_tone",
  "tag_faces",
  "tag_faces_outlined",
  "tag_faces_rounded",
  "tag_faces_sharp",
  "tag_faces_two_tone",
  "tap_and_play",
  "tap_and_play_outlined",
  "tap_and_play_rounded",
  "tap_and_play_sharp",
  "tap_and_play_two_tone",
  "telegram",
  "terrain",
  "terrain_outlined",
  "terrain_rounded",
  "terrain_sharp",
  "terrain_two_tone",
  "text_fields",
  "text_fields_outlined",
  "text_fields_rounded",
  "text_fields_sharp",
  "text_fields_two_tone",
  "text_format",
  "text_format_outlined",
  "text_format_rounded",
  "text_format_sharp",
  "text_format_two_tone",
  "text_rotate_up",
  "text_rotate_up_outlined",
  "text_rotate_up_rounded",
  "text_rotate_up_sharp",
  "text_rotate_up_two_tone",
  "text_rotate_vertical",
  "text_rotate_vertical_outlined",
  "text_rotate_vertical_rounded",
  "text_rotate_vertical_sharp",
  "text_rotate_vertical_two_tone",
  "text_rotation_angledown",
  "text_rotation_angledown_outlined",
  "text_rotation_angledown_rounded",
  "text_rotation_angledown_sharp",
  "text_rotation_angledown_two_tone",
  "text_rotation_angleup",
  "text_rotation_angleup_outlined",
  "text_rotation_angleup_rounded",
  "text_rotation_angleup_sharp",
  "text_rotation_angleup_two_tone",
  "text_rotation_down",
  "text_rotation_down_outlined",
  "text_rotation_down_rounded",
  "text_rotation_down_sharp",
  "text_rotation_down_two_tone",
  "text_rotation_none",
  "text_rotation_none_outlined",
  "text_rotation_none_rounded",
  "text_rotation_none_sharp",
  "text_rotation_none_two_tone",
  "textsms",
  "textsms_outlined",
  "textsms_rounded",
  "textsms_sharp",
  "textsms_two_tone",
  "texture",
  "texture_outlined",
  "texture_rounded",
  "texture_sharp",
  "texture_two_tone",
  "theaters",
  "theaters_outlined",
  "theaters_rounded",
  "theaters_sharp",
  "theaters_two_tone",
  "three_d_rotation",
  "three_d_rotation_outlined",
  "three_d_rotation_rounded",
  "three_d_rotation_sharp",
  "three_d_rotation_two_tone",
  "three_sixty",
  "three_sixty_outlined",
  "three_sixty_rounded",
  "three_sixty_sharp",
  "three_sixty_two_tone",
  "thumb_down",
  "thumb_down_alt",
  "thumb_down_alt_outlined",
  "thumb_down_alt_rounded",
  "thumb_down_alt_sharp",
  "thumb_down_alt_two_tone",
  "thumb_down_outlined",
  "thumb_down_rounded",
  "thumb_down_sharp",
  "thumb_down_two_tone",
  "thumb_up",
  "thumb_up_alt",
  "thumb_up_alt_outlined",
  "thumb_up_alt_rounded",
  "thumb_up_alt_sharp",
  "thumb_up_alt_two_tone",
  "thumb_up_outlined",
  "thumb_up_rounded",
  "thumb_up_sharp",
  "thumb_up_two_tone",
  "thumbs_up_down",
  "thumbs_up_down_outlined",
  "thumbs_up_down_rounded",
  "thumbs_up_down_sharp",
  "thumbs_up_down_two_tone",
  "time_to_leave",
  "time_to_leave_outlined",
  "time_to_leave_rounded",
  "time_to_leave_sharp",
  "time_to_leave_two_tone",
  "timelapse",
  "timelapse_outlined",
  "timelapse_rounded",
  "timelapse_sharp",
  "timelapse_two_tone",
  "timeline",
  "timeline_outlined",
  "timeline_rounded",
  "timeline_sharp",
  "timeline_two_tone",
  "timer",
  "timer10",
  "timer10_outlined",
  "timer10_rounded",
  "timer10_sharp",
  "timer10_two_tone",
  "timer3",
  "timer3_outlined",
  "timer3_rounded",
  "timer3_sharp",
  "timer3_two_tone",
  "timer_off",
  "timer_off_outlined",
  "timer_off_rounded",
  "timer_off_sharp",
  "timer_off_two_tone",
  "timer_outlined",
  "timer_rounded",
  "timer_sharp",
  "timer_two_tone",
  "title",
  "title_outlined",
  "title_rounded",
  "title_sharp",
  "title_two_tone",
  "toc",
  "toc_outlined",
  "toc_rounded",
  "toc_sharp",
  "toc_two_tone",
  "today",
  "today_outlined",
  "today_rounded",
  "today_sharp",
  "today_two_tone",
  "toggle_off",
  "toggle_off_outlined",
  "toggle_off_rounded",
  "toggle_off_sharp",
  "toggle_off_two_tone",
  "toggle_on",
  "toggle_on_outlined",
  "toggle_on_rounded",
  "toggle_on_sharp",
  "toggle_on_two_tone",
  "toll",
  "toll_outlined",
  "toll_rounded",
  "toll_sharp",
  "toll_two_tone",
  "tonality",
  "tonality_outlined",
  "tonality_rounded",
  "tonality_sharp",
  "tonality_two_tone",
  "touch_app",
  "touch_app_outlined",
  "touch_app_rounded",
  "touch_app_sharp",
  "touch_app_two_tone",
  "toys",
  "toys_outlined",
  "toys_rounded",
  "toys_sharp",
  "toys_two_tone",
  "track_changes",
  "track_changes_outlined",
  "track_changes_rounded",
  "track_changes_sharp",
  "track_changes_two_tone",
  "traffic",
  "traffic_outlined",
  "traffic_rounded",
  "traffic_sharp",
  "traffic_two_tone",
  "train",
  "train_outlined",
  "train_rounded",
  "train_sharp",
  "train_two_tone",
  "tram",
  "tram_outlined",
  "tram_rounded",
  "tram_sharp",
  "tram_two_tone",
  "transfer_within_a_station",
  "transfer_within_a_station_outlined",
  "transfer_within_a_station_rounded",
  "transfer_within_a_station_sharp",
  "transfer_within_a_station_two_tone",
  "transform",
  "transform_outlined",
  "transform_rounded",
  "transform_sharp",
  "transform_two_tone",
  "transit_enterexit",
  "transit_enterexit_outlined",
  "transit_enterexit_rounded",
  "transit_enterexit_sharp",
  "transit_enterexit_two_tone",
  "translate",
  "translate_outlined",
  "translate_rounded",
  "translate_sharp",
  "translate_two_tone",
  "trending_down",
  "trending_down_outlined",
  "trending_down_rounded",
  "trending_down_sharp",
  "trending_down_two_tone",
  "trending_flat",
  "trending_flat_outlined",
  "trending_flat_rounded",
  "trending_flat_sharp",
  "trending_flat_two_tone",
  "trending_up",
  "trending_up_outlined",
  "trending_up_rounded",
  "trending_up_sharp",
  "trending_up_two_tone",
  "trip_origin",
  "trip_origin_outlined",
  "trip_origin_rounded",
  "trip_origin_sharp",
  "trip_origin_two_tone",
  "tune",
  "tune_outlined",
  "tune_rounded",
  "tune_sharp",
  "tune_two_tone",
  "turned_in",
  "turned_in_not",
  "turned_in_not_outlined",
  "turned_in_not_rounded",
  "turned_in_not_sharp",
  "turned_in_not_two_tone",
  "turned_in_outlined",
  "turned_in_rounded",
  "turned_in_sharp",
  "turned_in_two_tone",
  "tv",
  "tv_off",
  "tv_off_outlined",
  "tv_off_rounded",
  "tv_off_sharp",
  "tv_off_two_tone",
  "tv_outlined",
  "tv_rounded",
  "tv_sharp",
  "tv_two_tone",
  "twitter",
  "two_wheeler",
  "two_wheeler_outlined",
  "two_wheeler_rounded",
  "two_wheeler_sharp",
  "two_wheeler_two_tone",
  "unarchive",
  "unarchive_outlined",
  "unarchive_rounded",
  "unarchive_sharp",
  "unarchive_two_tone",
  "undo",
  "undo_outlined",
  "undo_rounded",
  "undo_sharp",
  "undo_two_tone",
  "unfold_less",
  "unfold_less_outlined",
  "unfold_less_rounded",
  "unfold_less_sharp",
  "unfold_less_two_tone",
  "unfold_more",
  "unfold_more_outlined",
  "unfold_more_rounded",
  "unfold_more_sharp",
  "unfold_more_two_tone",
  "unsubscribe",
  "unsubscribe_outlined",
  "unsubscribe_rounded",
  "unsubscribe_sharp",
  "unsubscribe_two_tone",
  "update",
  "update_outlined",
  "update_rounded",
  "update_sharp",
  "update_two_tone",
  "usb",
  "usb_outlined",
  "usb_rounded",
  "usb_sharp",
  "usb_two_tone",
  "verified_user",
  "verified_user_outlined",
  "verified_user_rounded",
  "verified_user_sharp",
  "verified_user_two_tone",
  "vertical_align_bottom",
  "vertical_align_bottom_outlined",
  "vertical_align_bottom_rounded",
  "vertical_align_bottom_sharp",
  "vertical_align_bottom_two_tone",
  "vertical_align_center",
  "vertical_align_center_outlined",
  "vertical_align_center_rounded",
  "vertical_align_center_sharp",
  "vertical_align_center_two_tone",
  "vertical_align_top",
  "vertical_align_top_outlined",
  "vertical_align_top_rounded",
  "vertical_align_top_sharp",
  "vertical_align_top_two_tone",
  "vertical_split",
  "vertical_split_outlined",
  "vertical_split_rounded",
  "vertical_split_sharp",
  "vertical_split_two_tone",
  "vibration",
  "vibration_outlined",
  "vibration_rounded",
  "vibration_sharp",
  "vibration_two_tone",
  "video_call",
  "video_call_outlined",
  "video_call_rounded",
  "video_call_sharp",
  "video_call_two_tone",
  "video_label",
  "video_label_outlined",
  "video_label_rounded",
  "video_label_sharp",
  "video_label_two_tone",
  "video_library",
  "video_library_outlined",
  "video_library_rounded",
  "video_library_sharp",
  "video_library_two_tone",
  "videocam",
  "videocam_off",
  "videocam_off_outlined",
  "videocam_off_rounded",
  "videocam_off_sharp",
  "videocam_off_two_tone",
  "videocam_outlined",
  "videocam_rounded",
  "videocam_sharp",
  "videocam_two_tone",
  "videogame_asset",
  "videogame_asset_outlined",
  "videogame_asset_rounded",
  "videogame_asset_sharp",
  "videogame_asset_two_tone",
  "view_agenda",
  "view_agenda_outlined",
  "view_agenda_rounded",
  "view_agenda_sharp",
  "view_agenda_two_tone",
  "view_array",
  "view_array_outlined",
  "view_array_rounded",
  "view_array_sharp",
  "view_array_two_tone",
  "view_carousel",
  "view_carousel_outlined",
  "view_carousel_rounded",
  "view_carousel_sharp",
  "view_carousel_two_tone",
  "view_column",
  "view_column_outlined",
  "view_column_rounded",
  "view_column_sharp",
  "view_column_two_tone",
  "view_comfy",
  "view_comfy_outlined",
  "view_comfy_rounded",
  "view_comfy_sharp",
  "view_comfy_two_tone",
  "view_compact",
  "view_compact_outlined",
  "view_compact_rounded",
  "view_compact_sharp",
  "view_compact_two_tone",
  "view_day",
  "view_day_outlined",
  "view_day_rounded",
  "view_day_sharp",
  "view_day_two_tone",
  "view_headline",
  "view_headline_outlined",
  "view_headline_rounded",
  "view_headline_sharp",
  "view_headline_two_tone",
  "view_kanban",
  "view_list",
  "view_list_outlined",
  "view_list_rounded",
  "view_list_sharp",
  "view_list_two_tone",
  "view_module",
  "view_module_outlined",
  "view_module_rounded",
  "view_module_sharp",
  "view_module_two_tone",
  "view_quilt",
  "view_quilt_outlined",
  "view_quilt_rounded",
  "view_quilt_sharp",
  "view_quilt_two_tone",
  "view_stream",
  "view_stream_outlined",
  "view_stream_rounded",
  "view_stream_sharp",
  "view_stream_two_tone",
  "view_week",
  "view_week_outlined",
  "view_week_rounded",
  "view_week_sharp",
  "view_week_two_tone",
  "vignette",
  "vignette_outlined",
  "vignette_rounded",
  "vignette_sharp",
  "vignette_two_tone",
  "visibility",
  "visibility_off",
  "visibility_off_outlined",
  "visibility_off_rounded",
  "visibility_off_sharp",
  "visibility_off_two_tone",
  "visibility_outlined",
  "visibility_rounded",
  "visibility_sharp",
  "visibility_two_tone",
  "voice_chat",
  "voice_chat_outlined",
  "voice_chat_rounded",
  "voice_chat_sharp",
  "voice_chat_two_tone",
  "voice_over_off",
  "voice_over_off_outlined",
  "voice_over_off_rounded",
  "voice_over_off_sharp",
  "voice_over_off_two_tone",
  "voicemail",
  "voicemail_outlined",
  "voicemail_rounded",
  "voicemail_sharp",
  "voicemail_two_tone",
  "volume_down",
  "volume_down_outlined",
  "volume_down_rounded",
  "volume_down_sharp",
  "volume_down_two_tone",
  "volume_mute",
  "volume_mute_outlined",
  "volume_mute_rounded",
  "volume_mute_sharp",
  "volume_mute_two_tone",
  "volume_off",
  "volume_off_outlined",
  "volume_off_rounded",
  "volume_off_sharp",
  "volume_off_two_tone",
  "volume_up",
  "volume_up_outlined",
  "volume_up_rounded",
  "volume_up_sharp",
  "volume_up_two_tone",
  "vpn_key",
  "vpn_key_outlined",
  "vpn_key_rounded",
  "vpn_key_sharp",
  "vpn_key_two_tone",
  "vpn_lock",
  "vpn_lock_outlined",
  "vpn_lock_rounded",
  "vpn_lock_sharp",
  "vpn_lock_two_tone",
  "wallpaper",
  "wallpaper_outlined",
  "wallpaper_rounded",
  "wallpaper_sharp",
  "wallpaper_two_tone",
  "warning",
  "warning_outlined",
  "warning_rounded",
  "warning_sharp",
  "warning_two_tone",
  "watch",
  "watch_later",
  "watch_later_outlined",
  "watch_later_rounded",
  "watch_later_sharp",
  "watch_later_two_tone",
  "watch_outlined",
  "watch_rounded",
  "watch_sharp",
  "watch_two_tone",
  "waves",
  "waves_outlined",
  "waves_rounded",
  "waves_sharp",
  "waves_two_tone",
  "wb_auto",
  "wb_auto_outlined",
  "wb_auto_rounded",
  "wb_auto_sharp",
  "wb_auto_two_tone",
  "wb_cloudy",
  "wb_cloudy_outlined",
  "wb_cloudy_rounded",
  "wb_cloudy_sharp",
  "wb_cloudy_two_tone",
  "wb_incandescent",
  "wb_incandescent_outlined",
  "wb_incandescent_rounded",
  "wb_incandescent_sharp",
  "wb_incandescent_two_tone",
  "wb_iridescent",
  "wb_iridescent_outlined",
  "wb_iridescent_rounded",
  "wb_iridescent_sharp",
  "wb_iridescent_two_tone",
  "wb_sunny",
  "wb_sunny_outlined",
  "wb_sunny_rounded",
  "wb_sunny_sharp",
  "wb_sunny_two_tone",
  "wc",
  "wc_outlined",
  "wc_rounded",
  "wc_sharp",
  "wc_two_tone",
  "web",
  "web_asset",
  "web_asset_outlined",
  "web_asset_rounded",
  "web_asset_sharp",
  "web_asset_two_tone",
  "web_outlined",
  "web_rounded",
  "web_sharp",
  "web_two_tone",
  "weekend",
  "weekend_outlined",
  "weekend_rounded",
  "weekend_sharp",
  "weekend_two_tone",
  "whats_app",
  "whatshot",
  "whatshot_outlined",
  "whatshot_rounded",
  "whatshot_sharp",
  "whatshot_two_tone",
  "where_to_vote",
  "where_to_vote_outlined",
  "where_to_vote_rounded",
  "where_to_vote_sharp",
  "where_to_vote_two_tone",
  "widgets",
  "widgets_outlined",
  "widgets_rounded",
  "widgets_sharp",
  "widgets_two_tone",
  "wifi",
  "wifi_lock",
  "wifi_lock_outlined",
  "wifi_lock_rounded",
  "wifi_lock_sharp",
  "wifi_lock_two_tone",
  "wifi_off",
  "wifi_off_outlined",
  "wifi_off_rounded",
  "wifi_off_sharp",
  "wifi_off_two_tone",
  "wifi_outlined",
  "wifi_rounded",
  "wifi_sharp",
  "wifi_tethering",
  "wifi_tethering_outlined",
  "wifi_tethering_rounded",
  "wifi_tethering_sharp",
  "wifi_tethering_two_tone",
  "wifi_two_tone",
  "work",
  "work_off",
  "work_off_outlined",
  "work_off_rounded",
  "work_off_sharp",
  "work_off_two_tone",
  "work_outline",
  "work_outline_outlined",
  "work_outline_rounded",
  "work_outline_sharp",
  "work_outline_two_tone",
  "work_outlined",
  "work_rounded",
  "work_sharp",
  "work_two_tone",
  "wrap_text",
  "wrap_text_outlined",
  "wrap_text_rounded",
  "wrap_text_sharp",
  "wrap_text_two_tone",
  "you_tube",
  "youtube_searched_for",
  "youtube_searched_for_outlined",
  "youtube_searched_for_rounded",
  "youtube_searched_for_sharp",
  "youtube_searched_for_two_tone",
  "zoom_in",
  "zoom_in_outlined",
  "zoom_in_rounded",
  "zoom_in_sharp",
  "zoom_in_two_tone",
  "zoom_out",
  "zoom_out_map",
  "zoom_out_map_outlined",
  "zoom_out_map_rounded",
  "zoom_out_map_sharp",
  "zoom_out_map_two_tone",
  "zoom_out_outlined",
  "zoom_out_rounded",
  "zoom_out_sharp",
  "zoom_out_two_tone",
] as const
