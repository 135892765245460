import { makeStyles, Paper } from "@material-ui/core"
import FileInput, { FileInputHandle } from "components/FileInput/FileInput"
import RichTextInput from "components/RichTextInput/RichTextInput"
import { RoomContext } from "components/Room/contexts/room"
import emptyInput$ from "components/Room/observers/emptyInput$"
import { useI18n } from "contexts/i18nContext/i18nContext"
import _ from "lodash"
import _f from "lodash/fp"
import React, { useCallback, useEffect, useRef, useState } from "react"
import FooterContext from "../../contexts/FooterContext"
import SendButton from "../SendButton/SendButton"
import { isSingleParagraphWithNoStyle } from "./isSingleParagraphWithNoStyle"
import { useControls } from "./useControls"
import { useDraftEditorProps } from "./useDraftEditorProps"
import { useKeyBindingFn } from "./useKeyBindingFn"
import { useModeProps } from "./useModeProps"

function MessageInput() {
  const c = useStyles({})
  const t = useI18n()
  const [showSend, setShowSend] = useState(false)
  const value = useRef({ richText: "", isEmpty: true })

  const richTextInput = React.useRef<HTMLInputElement & { reset: () => void }>(
    null
  )

  const {
    submitMessage,
    selectedMessage,
    onFiles: onFiles,
    onChange: contextOnChange,
    onBlur: contextOnBlur,
    onFocus: contextOnFocus,
  } = React.useContext(FooterContext)

  const { emptyTextObject: defaultValue, submitButtonIcon } = useModeProps()

  const onSubmit = useCallback(() => {
    if (!value.current.isEmpty) {
      const richText = value.current.richText

      const message = isSingleParagraphWithNoStyle(richText)
        ? _.get(JSON.parse(richText), "blocks.0.text")
        : richText

      submitMessage(message)
    }

    richTextInput.current && richTextInput.current.reset()
  }, [submitMessage])

  const onChange = useCallback(
    (v) => {
      value.current = v
      if (!v) return
      v.isEmpty && emptyInput$.next()

      if (!value.current) return
      setShowSend(!value.current.isEmpty)
      contextOnChange(value.current.isEmpty ? "" : value.current.richText)
    },
    [contextOnChange]
  )

  const keyBindingFn = useKeyBindingFn({ inputRef: value, onSubmit })

  const draftEditorProps = useDraftEditorProps({
    keyBindingFn,
  })

  useEffect(() => {
    const hasSelectedMessage = _f.get("id")(selectedMessage)
    if (!hasSelectedMessage) return

    richTextInput?.current?.focus()
  }, [selectedMessage])

  const fileInputRef = React.useRef<FileInputHandle>(null)
  const pdfInputRef = React.useRef<FileInputHandle>(null)

  const roomApi = React.useContext(RoomContext)

  const { controls, customControls } = useControls({
    openDocumentDialog: {
      isAllowed: !!roomApi.room?.allowDocumentUpload,
      callback: () => {
        pdfInputRef.current?.openFileDialog()
      },
    },

    openImageDialog() {
      fileInputRef.current?.openFileDialog()
    },
  })

  return (
    <Paper className={c.richInput}>
      <RichTextInput
        placeholder={t["write-a-message"]}
        className={c.richTextInput}
        onChange={onChange}
        ref={richTextInput}
        defaultValue={defaultValue}
        draftEditorProps={draftEditorProps}
        controls={controls}
        customControls={customControls}
        onBlur={contextOnBlur}
        onFocus={contextOnFocus}
      />
      {showSend && (
        <SendButton
          onClick={onSubmit}
          className={c.sendButton}
          icon={submitButtonIcon}
        />
      )}
      <FileInput accept="image/*" ref={fileInputRef} onFiles={onFiles} />
      <FileInput accept="application/pdf" ref={pdfInputRef} onFiles={onFiles} />
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    richTextInput: {
      minHeight: 135,
    },
    richInput: {
      position: "relative",
    },
    sendButton: {
      position: "absolute",
      bottom: theme.spacing(1),
      right: theme.spacing(3),
    },
  }
})

export default React.memo(MessageInput, () => true)
