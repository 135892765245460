import React from "react"
import { Button } from "@material-ui/core"

export default function ForwardButton({ className = "", onClick }) {
  return (
    <Button
      className={className}
      color="primary"
      variant="contained"
      onClick={onClick}
    >
      weiter
    </Button>
  )
}
