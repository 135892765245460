import moment from "moment"
import React from "react"

export default function formatMessageDate(
  date: string,
  formatsWithin: {
    day?: string
    default?: string
    week?: string
    month?: string
    year?: string
    oldPast?: string
  } = { day: "HH:mm", default: "dd. DD.MM. HH:mm" }
) {
  if (!date) return null

  const isSame = (f: moment.unitOfTime.StartOf) =>
    moment(date).isSame(new Date(), f)

  const format = (function format() {
    if (isSame("day") && formatsWithin.day) return formatsWithin.day
    if (isSame("week") && formatsWithin.week) return formatsWithin.day
    if (isSame("month") && formatsWithin.month) return formatsWithin.month
    if (isSame("year") && formatsWithin.year) return formatsWithin.year

    return formatsWithin.oldPast || formatsWithin.default
  })()

  return moment(date).format(format)
}
