import moment from "moment"
import { MomentInput } from "moment"

export default function formatDate(
  momentInput: MomentInput,
  longWeekName?: boolean
) {
  const diff = Math.abs(moment(new Date()).diff(momentInput, "day"))
  const f = (format: string) => moment(momentInput).format(format)

  if (diff === 0) {
    return f("HH:mm")
  }

  if (0 < diff && diff <= 7) {
    return f(longWeekName ? "dddd" : "ddd")
  }

  if (7 < diff && diff <= 365) {
    return f("DD.MM")
  }

  if (365 < diff) {
    return f("DD.MM.YY")
  }

  return
}
