import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import OpenGraphsTemplate from "components/OpenGraphs/components/OpenGraphsTemplate/OpenGraphsTemplate"
import getter from "util/getter"

export default function OpenGraphs({
  className = "",
  openGraphs = [],
  clipText = false,
}) {
  const c = useStyles({})

  if (!openGraphs?.length) return null

  return (
    <div className={clsx("open-graphs", className)}>
      {openGraphs.map((graph) => {
        const g = getter(graph)
        return (
          <OpenGraphsTemplate
            url={g("url")}
            clipText={clipText}
            thumbURL={g("image.url")}
            description={g("description")}
            className={c.previewTemplate}
            caption={g("site_name")}
            title={g("title")}
          />
        )
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    previewTemplate: {
      marginBottom: theme.spacing(0.5),
    },
  }
})
