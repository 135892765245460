import _ from "lodash"
import { ContentState } from "draft-js"
import _f from "lodash/fp"
import contentStateFromString from "util/draftJS/contentStateFromString"
import firstNonEmptyParagraph from "util/draftJS/firstNonEmptyParagraphString"

export default function getText(message) {
  const content = _f.get("content")(message)
  const contentState = _.isString(content)
    ? contentStateFromString(content) || ContentState.createFromText(content)
    : null

  return contentState
    ? firstNonEmptyParagraph(contentState)
    : getInclusionContent(content) || null
}

function getInclusionContent(message) {
  if (!_.get(message, "userInclusionContent")) return null

  const content = (key) => _.get(message, `userInclusionContent`)

  return `${content("user.name")}'s ${(
    content("inclusionType") || ""
  ).toLowerCase()}`
}
