import { ConferenceActivity } from "components/ConferenceActivity/ConferenceActivityContext"
import Room from "components/Room/Room"
import { Shop } from "components/Shop/Shop"
import UseEffect from "components/UseEffect/UseEffect"
import { RootContainerAPI } from "hooks/useRootContainerAPI"
import { PageBoardItemType } from "lib/graphql/types"
import _ from "lodash"
import { useSubscription } from "observable-hooks"
import React from "react"
import { Subject } from "rxjs"
import { ItemTemplate } from "../../../ItemTemplate/ItemTemplate"
import { RoomPageBoardItemApiProvider } from "../../contexts/RoomPageBoardItemContext"
import { RoomPageBoardItemProps } from "../../types"
import { CartPage } from "../Shopping/CartPage/CartPage"
import { Message } from "./Message"
import { useRenderMessageAttachments } from "./hook/useRenderMessageAttachments"

export default function RoomPageBoardItemProvider(
  props: RoomPageBoardItemProps & { children: React.ReactNode }
) {
  const [__, setRootContainer] = React.useState<RootContainerAPI | null>(null)

  const identifier = React.useMemo(() => {
    if (props.identifier) return props.identifier

    if (!props.entry?.room?.id) return null

    return { roomID: props.entry?.room?.id }
  }, [props.entry?.room?.id, props.identifier])
  //

  // const leavingRoomManagerRef = React.useRef<LeaveRoomManagerApi | null>(null)
  const renderMessageAttachments = useRenderMessageAttachments()

  const conferenceAPI = React.useContext(ConferenceActivity.Context)

  const [cartID, setCartID] = React.useState<string>("")

  const onClose$ = React.useRef(new Subject<void>()).current

  useSubscription(
    onClose$,
    React.useCallback(() => {
      conferenceAPI.handleSessionExit(props.onClose)
    }, [conferenceAPI.handleSessionExit, props.onClose])
  )

  if (!identifier) return null

  const partnerItemID = (() => {
    return (
      props.entry?.significativeRelations?.find(
        (a) => a.type === PageBoardItemType.Partner
      )?.itemID || null
    )
  })()

  const hasFixedPartnerID = !!partnerItemID

  return (
    <React.Fragment>
      <Shop.Provider cartID={cartID || ""}>
        <CartPage.Provider
          partnerIdIsEditable={!hasFixedPartnerID}
          partnerItemID={partnerItemID}
        >
          <ItemTemplate.Provider
            onClose={() => {
              onClose$.next()
            }}
          >
            <React.Fragment>
              <Room.Provider
                identifier={identifier}
                initialTopMostItemIndex={props.initialTopMostItemIndex}
                renderMessageAttachments={renderMessageAttachments}
                Message={Message}
              >
                <Room.SearchMessages.Provider>
                  <Room.Context.Consumer>
                    {(roomAPI) => {
                      return (
                        <>
                          <ItemTemplate.Context.Consumer>
                            {(templateAPI) => {
                              return (
                                <>
                                  {/* <Room.LeavingRoomManger
                                    onLeaveRoom={props.onClose}
                                    ref={leavingRoomManagerRef}
                                  ></Room.LeavingRoomManger> */}
                                  <RoomPageBoardItemApiProvider
                                    {..._.omit(props, ["children"])}
                                    twoPagesAPI={templateAPI.twoPagesAPI}
                                    roomAPI={roomAPI}
                                  >
                                    {props.children}
                                  </RoomPageBoardItemApiProvider>
                                  <UseEffect
                                    deps={[templateAPI.rootContainer]}
                                    effect={() => {
                                      setRootContainer(
                                        templateAPI.rootContainer
                                      )
                                    }}
                                  ></UseEffect>
                                </>
                              )
                            }}
                          </ItemTemplate.Context.Consumer>
                          <UseEffect
                            deps={[roomAPI.room?.cartID]}
                            effect={() => setCartID(roomAPI.room?.cartID || "")}
                          ></UseEffect>
                        </>
                      )
                    }}
                  </Room.Context.Consumer>
                </Room.SearchMessages.Provider>
              </Room.Provider>
            </React.Fragment>
          </ItemTemplate.Provider>
        </CartPage.Provider>
      </Shop.Provider>
    </React.Fragment>
  )
}
