import { KanbanSelectionAPI } from "components/RoomKanban/hooks/useSelectionAPI"
import useMemoAPI from "hooks/useMemoAPI"
import { KanbanBoardFragment, KanbanCardFragment } from "lib/graphql/operations"
import _ from "lodash"
import React from "react"
import { ID, OpenKanbanCardPayload } from "types/type"
import {
  KanbanCardAPI,
  KanbanEditAPI,
  TransformKanbanBoard,
} from "../@types/index"
import { useAddKanbanCard } from "./useAddKanbanCard"
import useContentAPI from "./useContentAPI"

export interface KanbanAPIInput {
  editAPI: KanbanEditAPI
  selectionAPI: KanbanSelectionAPI
  board: KanbanBoardFragment | null
  setBoard: (fn: TransformKanbanBoard) => void
  deleteColumn?: (args: { columnID: string }) => void
  cardAPI: KanbanCardAPI
  roomID: string
  openKanbanCard?: (payload: OpenKanbanCardPayload) => void
  changeColumnTitle?: (args: { title: string; columnID: string }) => void
  addColumn?: () => void
  onCardDragEnd?: (
    _card: KanbanCardFragment,
    source: { fromColumnId: string; fromPosition: number },
    destination: { toColumnId: string; toPosition: number }
  ) => void

  onColumnDragEnd?: (
    column: { id: string },
    source: { fromPosition: number },
    destination: { toPosition: number }
  ) => void
}

export type KanbanAPI = ReturnType<typeof useKanbanAPI>

export default function useKanbanAPI(props: KanbanAPIInput) {
  const contentAPI = useContentAPI(
    _.pick(props.cardAPI, ["getCard", "setCard"])
  )

  const selectionAPI = props.selectionAPI

  const addKanbanCard = useAddKanbanCard({ roomID: props.roomID }).addKanbanCard

  // function onCardDragEnd(
  //   _card: KanbanCardFragment,
  //   source: { fromColumnId: string; fromPosition: number },
  //   destination: { toColumnId: string; toPosition: number }
  // ) {
  //   props.onCardDragEnd?.(_card, source, destination)
  // }

  // const addColumn = React.useCallback(() => {
  //   // const columnID = uniqid()

  //   // props.setBoard((board) => {
  //   //   const column: KanbanColumn = {
  //   //     id: columnID,
  //   //     cards: [],
  //   //     title: "",
  //   //   }

  //   //   const r = reactKanbanAPI.addColumn(board || { columns: [] }, column)

  //   //   return r
  //   // })

  //   // props.editAPI.startEdition(columnID)

  // }, [props.addColumn])

  const updateColumn = React.useCallback(
    (columnID, columnUpdate) => {
      // props.setBoard((board) => {
      //   try {
      //     const column = board?.columns?.find(({ id }) => columnID === id)
      //     if (!column) return board
      //     const v = reactKanbanAPI.changeColumn(board, column, columnUpdate)
      //     return v
      //   } catch (e) {
      //     debugger
      //   }
      // })
    },
    [props.setBoard]
  )

  const updateCard = React.useCallback(
    (cardID, cardUpdate) => {
      // props.setBoard((board) => {
      //   return reactKanbanAPI.changeCard(board, cardID, cardUpdate)
      // })
    },
    [props.setBoard]
  )

  const getCardColumn = React.useCallback(
    (cardID: string) => {
      // const column = props.board?.columns?.find((c) =>
      //   c.cards
      //     ?.filter(Boolean)
      //     .map((c) => c.id)
      //     ?.includes(cardID)
      // )
      // return column
    },
    [props.board]
  )

  const deleteCard = React.useCallback(function deleteCard(cardID: ID) {
    // props.setBoard((board) => {
    //   const card = props.cardAPI.getCard(cardID)
    //   const column = getCardColumn(cardID)
    //   if (!column) return
    //   return reactKanbanAPI.removeCard(board, column, card)
    // })
    // if (props.selectionAPI.selectedCard?.id === cardID) {
    //   props.selectionAPI.setSelectedCardID(null)
    // }
  }, [])

  const deleteColumn = React.useCallback(
    function deleteColumn(column: { id: string }) {
      props.deleteColumn?.({ columnID: column.id })
    },
    [props.deleteColumn]
  )

  const api = {
    board: props.board,
    editAPI: props.editAPI,
    cardAPI: props.cardAPI,
    getCardColumn,
    addCard: addKanbanCard,
    addColumn: props.addColumn,
    deleteCard,
    deleteColumn,
    updateColumn,
    updateCard,
    onCardDragEnd: props.onCardDragEnd,
    onColumnDragEnd: props.onColumnDragEnd,
    contentAPI,
    selectionAPI,
    openKanbanCard: props.openKanbanCard,
    updateColumnTitle: props.changeColumnTitle,
  }

  return useMemoAPI(api)
}
