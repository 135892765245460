import useMemoAPI from "hooks/useMemoAPI"
import { ShopOrderAdditionalInfoInput } from "lib/graphql/types"
import React from "react"
import { useCreateShopOrderMutation } from "../../../lib/graphql/operations"

export function useCreateOrder(props: { cartID: string; workspaceID: string }) {
  const [mutate, { loading }] = useCreateShopOrderMutation()

  const create = React.useCallback(
    (data: ShopOrderAdditionalInfoInput) => {
      if (!props.cartID || !props.workspaceID) return

      return mutate({
        variables: {
          cartID: props.cartID,
          workspaceID: props.workspaceID,
          data,
        },
      })
    },
    [mutate, props.cartID, props.workspaceID]
  )

  return useMemoAPI({ create, loading })
}
