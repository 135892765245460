import { useApolloClient } from "@apollo/client";
import useActiveResources from "hooks/useActiveResources"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import {
  PageBoardItemDocument,
  PageBoardItemQuery,
  PageBoardItemQueryVariables,
} from "lib/graphql/operations"

export function useFetchPageBoardItemFromItemID() {
  const client = useApolloClient()
  const workspaceID = useActiveResources().workspaceID

  return React.useCallback(
    async (type: PageBoardItemType, itemID: string) => {
      return (
        client
          .query<PageBoardItemQuery, PageBoardItemQueryVariables>({
            query: PageBoardItemDocument,
            variables: { itemID, workspaceID, type },
            fetchPolicy: "network-only",
          })
          ?.then((a) => a.data.pageBoardItem) || null
      )
    },
    [workspaceID]
  )
}
