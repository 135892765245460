import React from "react"
import { Icon } from "@material-ui/core"

export const iconsNames = {
  CUSTOMER: "business_center_outlined",
  CONTACT: "face_outlined",
  VENDOR: "store_mall_directory_outlined",
  LEAD: "monetization_on_outlined",
  DOCUMENT: "insert_drive_file_outlined",
  Projekte: "square_foot",
  COMPANY: "business",
  KANBAN_CARD: "view_kanban",
}

const COLLECTIONS_TYPES_ICONS = {
  CUSTOMER: <Icon>{iconsNames["CUSTOMER"]}</Icon>,
  CONTACT: <Icon>{iconsNames["CONTACT"]}</Icon>,
  VENDOR: <Icon>{iconsNames["VENDOR"]}</Icon>,
  LEAD: <Icon>{iconsNames["LEAD"]}</Icon>,
  DOCUMENT: <Icon>{iconsNames["DOCUMENT"]}</Icon>,
  Projekte: <Icon>{iconsNames["Projekte"]}</Icon>,
  COMPANY: <Icon>{iconsNames["COMPANY"]}</Icon>,
}

export default COLLECTIONS_TYPES_ICONS
