import React from "react"
import getWeekGrid from "../lib/getWeekGrid"
import { Month, WeekDay } from "../types"

export default function useWeekGrid(props: {
  firstDayOfWeek: WeekDay
  month: Month
}) {
  return React.useMemo(() => {
    return getWeekGrid({
      firstDayOfWeek: props.firstDayOfWeek,
      month: props.month,
    })
  }, [props.month, props.firstDayOfWeek])
}
