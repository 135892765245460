import { useApolloClient } from "@apollo/client";
import useActiveResources from "../../../../../hooks/useActiveResources"
import { fetchPageBoardItem } from "../lib/fetchPageBoardItem"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"

type FetchLib = typeof fetchPageBoardItem

export type TypesThatCanBeFetched = keyof FetchLib

type FetchingParams<Key extends TypesThatCanBeFetched> = Omit<
  Parameters<FetchLib[Key]>[0],
  "workspaceID"
>

export function useFetchPageBoardItemFromParams() {
  const client = useApolloClient()
  const workspaceID = useActiveResources().workspaceID

  const f: <Type extends TypesThatCanBeFetched>(
    type: Type,
    params: FetchingParams<Type>
  ) => ReturnType<FetchLib[Type]> = (type, params) => {
    const fn = fetchPageBoardItem[type]
    return (fn as any)({ ...params, workspaceID }, client)
  }

  return React.useCallback(f, [client, workspaceID])
}

type PromiseResult<T extends any> = T extends Promise<infer U> ? U : never

// type O = Value<ReturnType<FetchLib[PageBoardItemType.Room]>>

export type FetchedItem<Type extends PageBoardItemType> =
  Type extends TypesThatCanBeFetched
    ? PromiseResult<ReturnType<FetchLib[Type]>>
    : never

export const canTypeBeFetched = (
  type: PageBoardItemType
): type is TypesThatCanBeFetched => {
  return Object.keys(fetchPageBoardItem).includes(type)
}

export type FetchingParamsProps<Type extends PageBoardItemType> =
  Type extends TypesThatCanBeFetched ? FetchingParams<Type> : never
