import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { GooglSearch } from "components/GoogleSearch/GoogleSearch"
import { PageComponentProps } from "components/Page/Page"
import { IndexedItems } from "components/PageBoard/components/IndexedItems/IndexedItems"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import { HeaderIconButton } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/PageBoardItem"
import {
  ShopArticlePageBoardItemFragment,
  usePageBoardItemsSemanticSearchQuery,
} from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import { propsInjectorFactory } from "lib/propsInjectorFactory"
import { ArticleDetailPanelProps } from "../../../../ShopArticlePageBoardItem/ShopArticlePageBoardItem"
import { CartButton } from "../CartButton/CartButton"
import { ShoppingArticlePage } from "../ShoppingArticlePage/ShoppingArticlePage"
import { ShoppingArticlesGrid } from "./components/ShoppingArticlesGrid/ShoppingArticlesGrid"
import {
  HookAPIFn,
  createUsePageBoardItems,
} from "hooks/usePageBoardItems/createUsePageBoardItems"
import useThrottledValue from "hooks/useThrottledValue"
import useDebouncedValue from "hooks/useDebouncedValue"

export type ShoppingPageInitialParams = {
  articleID: string
}

export type ShoppingPageProps = PageComponentProps & {
  getQuantity?: ({ articleID }: { articleID: string }) => number
  initialParams?: ShoppingPageInitialParams
  ArticleDetailPanelProps?: Omit<ArticleDetailPanelProps, "quantity">
  renderArticleButtons?: (p: {
    item: ShopArticlePageBoardItemFragment
    open?: () => void
    cartButtons: React.ReactNode
  }) => React.ReactNode[]
}

export const ShoppingPageApi = propsInjectorFactory<ShoppingPageProps>({
  contextName: "ShoppingPageApiContext",
})

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "100%",
      display: "flex",

      flexDirection: "column",
      "&>.body": {
        flex: 1,
        overflow: "auto",
      },
    },
    templateRoot: {
      // paddingTop: 8,
    },
    header: { position: "relative" },
    backButton: {
      position: "absolute",
      top: "50%",
      left: 10,
      transform: "translateY(-50%)",
    },
    cartButton: {
      position: "absolute",
      top: "50%",
      right: 16,
      transform: "translateY(-50%)",
    },
    search: {
      minWidth: "200px",
      maxWidth: "500px",
      width: "60%",
      margin: "0 auto",
      padding: "16px 0",
    },

    body: { height: "auto", marginTop: -16 },

    articlesGrid: { height: "100%", maxHeight: "100%" },
  }
})

const useSematicPageBoardItemsApi: HookAPIFn =
  function useSematicPageBoardItemsApi(args) {
    const b = useDebouncedValue(args.variables.search, 1000)

    const a = usePageBoardItemsSemanticSearchQuery({
      variables: {
        ...args.variables,
        ...(!!b ? { search: b } : {}),
      },
      fetchPolicy: "cache-and-network",
    })

    return {
      fetchingResponse: a.data?.pageBoardItemsSemanticSearch || null,
      fetchNextPage: () => {},
      refetch: () => {},
    }
  }

const useSematicPageBoardItems = createUsePageBoardItems({
  useHookApi: useSematicPageBoardItemsApi,
})

export function ShoppingPage(props: ShoppingPageProps & ShoppingPageProps) {
  const c = useStyles()

  return (
    <ShoppingPageApi.Provider {...props}>
      <IndexedItems.Provider
        filter={{ types: [PageBoardItemType.ShopArticle] }}
        usePageBoardItems={useSematicPageBoardItems}
      >
        <ItemTemplate.Provider
          onClose={props.onClose}
          {...(() => {
            const articleID = props.initialParams?.articleID

            if (!!articleID) {
              return {
                TwoPagesAPIProps: {
                  openedSecondPage: {
                    id: articleID,
                    Page: function InitiallyOpenedSecondPage(props: {
                      onClose: () => any
                    }) {
                      return (
                        <ShoppingArticlePage
                          onClose={props.onClose}
                          getItemParams={{
                            fetchItemProps: {
                              type: PageBoardItemType.ShopArticle,
                              params: { articleID },
                            },
                          }}
                        ></ShoppingArticlePage>
                      )
                    },
                  },
                },
              }
            }

            return {}
          })()}
        >
          <div className={c.root}>
            <ItemTemplate.Root
              className={clsx(c.templateRoot, "body")}
              EnhancedPageProps={{
                header: (
                  <div className={c.header}>
                    <HeaderIconButton
                      className={c.backButton}
                      name="arrow_back"
                      onClick={() => {
                        props.onClose()
                      }}
                    ></HeaderIconButton>
                    <div className={c.search}>
                      <IndexedItems.Consumer>
                        {(api) => {
                          return (
                            <GooglSearch
                              TextFieldProps={{
                                onChange: (e) => api.search.set(e.target.value),
                                value: api.search.value,
                              }}
                            ></GooglSearch>
                          )
                        }}
                      </IndexedItems.Consumer>
                    </div>
                    <CartButton className={c.cartButton}></CartButton>
                  </div>
                ),

                body: (
                  <ItemTemplate.Context.Consumer>
                    {(templateApi) => {
                      const open = (
                        shopArticleItem: ShopArticlePageBoardItemFragment
                      ) => {
                        templateApi.twoPagesAPI.openFullPage(({ onClose }) => {
                          if (
                            shopArticleItem.__typename !==
                            "ShopArticlePageBoardItem"
                          )
                            return null

                          return (
                            <ShoppingArticlePage
                              getItemParams={{
                                item: shopArticleItem,
                              }}
                              onClose={onClose}
                            ></ShoppingArticlePage>
                          )
                        })
                      }

                      return (
                        <ShoppingArticlesGrid
                          onClickItem={(a) => {
                            open(a)
                          }}
                          renderArticleButtons={(a) => {
                            return (
                              props?.renderArticleButtons?.({
                                item: a.shopArticleItem,
                                cartButtons: <>{a.cartButtons}</>,
                              }) || [<></>]
                            )
                          }}
                          className={clsx(c.articlesGrid)}
                        ></ShoppingArticlesGrid>
                      )
                    }}
                  </ItemTemplate.Context.Consumer>
                ),
                noHeader: true,
              }}
            ></ItemTemplate.Root>
          </div>
        </ItemTemplate.Provider>
      </IndexedItems.Provider>
    </ShoppingPageApi.Provider>
  )
}
