import { makeStyles, useTheme } from "@material-ui/core"
import LightTooltip from "components/LightTooltip/LightTooltip"
import Notifications from "components/Notifications/Notifications"
import { WorkspaceBadgeProps } from "components/Notifications/components/WorkspaceBadge/WorkspaceBadge"
import WorkspaceBall from "components/WorkspaceBall/WorkspaceBall"
import useAppRouter from "hooks/useAppRouter"
import {
  GuestWorkspaceFragment,
  WorkspaceFragment,
} from "lib/graphql/operations"
import React from "react"
import { ballProps } from "./ballProps"
import { ConferenceActivity } from "components/ConferenceActivity/ConferenceActivityContext"

export default function WorkspaceIcon(props: {
  workspace: WorkspaceFragment | null | undefined
  isActiveWorkspace: boolean
}) {
  const { workspace: w, isActiveWorkspace } = props

  const c = useStyles({})
  const theme = useTheme()

  const badgeProps = React.useMemo(() => {
    const a: WorkspaceBadgeProps["badgeProps"] = {
      classes: {
        root: c.badgeRoot,
        badge: c.notificationDot,
      },
    }

    return a
  }, [c.notificationDot])

  const router = useAppRouter()

  const accountID = w?.account?.id

  const isGuest = w?.type === "GUEST"

  const goToWorkspace = () => {
    const path = isGuest
      ? `/account/${accountID}/guest/${w?.user?.id}`
      : `/account/${accountID}`

    router.history.push(path)
  }

  const conferenceAPI = React.useContext(ConferenceActivity.Context)

  if (!w) return null

  const navLink = (
    <a
      title={w?.name || ""}
      onClick={(e) => {
        e.preventDefault()
        conferenceAPI.handleSessionExit(() => {
          goToWorkspace()
        })
      }}
      className="workspace"
    >
      <LightTooltip
        placement="right"
        title={
          <>
            {isGuest
              ? `${
                  (w as GuestWorkspaceFragment).partner?.name || ""
                } zu Gast bei ${w?.name}`
              : w?.name}
          </>
        }
      >
        <div>
          <WorkspaceBall
            className={c.workspaceBall}
            name={w?.name}
            {...(() => {
              const style = ballProps()

              return {
                ...style,

                borderRadius: isGuest ? "50%" : "25%",
                background: isActiveWorkspace
                  ? theme.palette.secondary.main
                  : style.background,
              }
            })()}
          />
        </div>
      </LightTooltip>
    </a>
    // </NavLink>
  )

  return (
    <Notifications.WorkspaceBadge workspaceID={w.id} badgeProps={badgeProps}>
      {navLink}
    </Notifications.WorkspaceBadge>
  )
}

const useStyles = makeStyles((theme) => {
  const selectedStyle = {
    background: `${theme.palette.secondary.main} !important`,
  }

  return {
    badgeRoot: {
      display: "block",
    },
    workspaceBall: {
      "&:hover": selectedStyle,
    },
    notificationDot: {
      position: "absolute",
      top: 1,
      right: 0,
    },
  }
})
