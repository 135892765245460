import { useApolloClient } from "@apollo/client";
import path from "path"
import _ from "lodash"
import useAppRouter from "hooks/useAppRouter"
import getAccountRootPathname from "util/getAccountRootPathname"
import { useContext, useMemo } from "react"
import useCurrentAccountRootPathname from "hooks/useCurrentAccountRootPathname/useCurrentAccountRootPathname"
import SectionsRoutes, { SECTIONS_IDS } from "components/SectionsRoutes"
import getWorkspace from "lib/getWorkspace"

export default function useRoutingSystem() {
  const { history } = useAppRouter()
  // const { getSectionRoute } = useSectionsRoutes()
  const { getSectionRoute } = useContext(SectionsRoutes.Context)

  const baseURL = useCurrentAccountRootPathname()

  const apolloClient = useApolloClient()

  const routing = useMemo(
    () =>
      routingSystem(
        {
          history,
          getSectionRoute,
          baseURL,
        },
        apolloClient
      ),
    [getSectionRoute, apolloClient, baseURL]
  )

  window.routing = routing

  return routing
}

function routingSystem({ history, getSectionRoute, baseURL }, client) {
  const push = (url) => history.push(url.replace(/\/$/, ""))

  return {
    getChatListLink({ accountID = "", guestUserID = "" } = {}) {
      baseURL = accountID
        ? getAccountRootPathname({ accountID, guestUserID })
        : baseURL

      return chatListLink({ baseURL, getSectionRoute })
    },
    getRoomLink({
      roomID = "",
      accountID = "",
      guestUserID = "",
      workspaceID = "",
    } = {}) {
      baseURL = accountID
        ? getAccountRootPathname({ accountID, guestUserID })
        : baseURL

      //IMPROVE!!!
      return roomLink({ roomID, baseURL, getSectionRoute, workspaceID }, client)
    },
    goToChatList({ accountID = "", guestUserID = "" } = {}) {
      baseURL = accountID
        ? getAccountRootPathname({ accountID, guestUserID })
        : baseURL

      push(chatListLink({ baseURL, getSectionRoute }))
    },
    async goToRoom({
      roomID = "",
      accountID = "",
      guestUserID = "",
      workspaceID = "",
    } = {}) {
      if (!accountID && !guestUserID && !!workspaceID) {
        const workspace = getWorkspace({ workspaceID }, client)

        accountID =
          accountID ||
          (function getAccountID() {
            const id = _.get(workspace, "account.id")

            return id
          })()

        guestUserID =
          guestUserID ||
          (function getGuestUserID() {
            const user = _.get(workspace, "user")

            if (_.get(user, "type") !== "GUEST") return ""

            const guest = user

            return guest?.id || null
          })()

        if (!accountID && !guestUserID) return null
      }

      baseURL = accountID
        ? getAccountRootPathname({ accountID, guestUserID })
        : baseURL

      if (!roomID) return
      push(
        await roomLink(
          { roomID, baseURL, getSectionRoute, workspaceID },
          client
        )
      )
    },
    goToPrivateRoom({ userID, accountID = "", guestUserID = "" }) {
      baseURL = accountID
        ? getAccountRootPathname({ accountID, guestUserID })
        : baseURL

      const chatSectionRoute = getSectionRoute({ id: SECTIONS_IDS.chat })
      const privateRoomPath = chatSectionRoute.getLink.privateRoom(
        { userID },
        client
      )

      const link = getLink({
        baseURL,
        sectionPath: chatSectionRoute.path,
        pathname: privateRoomPath,
      })

      push(link)
    },
  }
}

const chatListLink = ({ baseURL, getSectionRoute }) => {
  const sectionRoute = getSectionRoute({ id: SECTIONS_IDS.chat })

  const link = getLink({
    sectionRoute,
    pathname: sectionRoute.getLink.home(),
    baseURL,
  })

  return link
}

async function roomLink(
  { roomID, getSectionRoute, baseURL, workspaceID },
  client
) {
  const sectionRoute = getSectionRoute({ id: SECTIONS_IDS.chat })
  const pathname = await sectionRoute.getLink.room(
    { roomID, workspaceID },
    client
  )

  const link = getLink({
    sectionRoute,
    pathname,
    baseURL,
  })

  return link
}

const getLink = ({
  pathname,
  sectionRoute = undefined,
  sectionPath = undefined,
  baseURL,
}) => {
  const url = path.join(baseURL, sectionPath || sectionRoute.path, pathname)

  return url
}
function accountIDAndGuestUserID({ workspaceID = "" } = {}, client) {
  if (!workspaceID) return null

  const workspace = getWorkspace({ workspaceID }, client)
  if (!workspace) return null

  const accountID = _.get(workspace, "account.id")

  const guestUserID = (function getGuestUserID() {
    const user = _.get(workspace, "user")

    if (_.get(user, "type") !== "GUEST") return ""

    const guest = user

    return guest?.id || null
  })()

  if (!accountID && !guestUserID) return null

  return { accountID, guestUserID }
}
