import { RoomContext } from "components/Room/contexts/room"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  MessageListItemFragment,
  useSearchMessageListItemsQuery,
} from "lib/graphql/operations"
import React from "react"

export default function useSearchMessageListItems(props: { search: string }) {
  const { workspaceID } = useActiveResources()
  const { roomID } = React.useContext(RoomContext)

  const query = useSearchMessageListItemsQuery({
    skip: !workspaceID || !roomID || !props.search,
    fetchPolicy: "no-cache",
    variables: {
      roomID: roomID || "",
      workspaceID: workspaceID || "",
      search: props.search,
    },
  })

  return useMemoAPI({
    list: query.data?.searchMessageListItems,
    // list: sample,
    isLoading: query.loading,
  })
}
