import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import useTrueFalse from "hooks/useTrueFalse"
import useOnChange from "hooks/useOnChange"
import { OpenPageProps } from "components/Page/hooks/useSecondPage"
import { TwoPagesAPI } from "../TwoPages"

export default function useSecondPage(props: {
  isOpen?: boolean
  page: OpenPageProps
  twoPagesAPI: TwoPagesAPI
}) {
  const api = props.twoPagesAPI

  const trueFalse = useTrueFalse(!!props.isOpen)

  const isOpen = trueFalse.isTrue
  const close = trueFalse.setFalse
  const pageID = props.page.id

  const manage = (isOpen: boolean) => {
    if (isOpen) return api.secondPage.openPage(props.page)

    if (api.secondPage.viewID === pageID) {
      api.secondPage.closePage()
    }
  }

  useOnChange({
    value: props.isOpen,
    onChange(propsIsOpen) {
      if (typeof propsIsOpen === "undefined") return
      if (propsIsOpen === isOpen) return

      trueFalse.set(!!propsIsOpen)
      manage(propsIsOpen)
    },
  })

  React.useEffect(() => {
    manage(isOpen)
  }, [isOpen])

  useOnChange({
    value: api.secondPage.viewID,
    onChange: (viewID) => {
      if (viewID !== pageID) {
        close()
      }
    },
  })

  return useMemoAPI({
    isOpen,
    toggle: trueFalse.toggle,
    close,
    open: trueFalse.setTrue,
  })
}
