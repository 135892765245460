import { BadgeProps, Icon, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import Avatar from "components/Avatar/Avatar"
import ClippedText from "components/ClippedText/ClippedText"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import useTypesOptions from "components/PageBoard/hooks/useTypesOptions"
import PaperList from "components/PaperList"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import StopPropagation from "components/StopPropagation/StopPropagation"
import { yellow } from "constants/colors"
import { NewPageBoardItem_RoomPageBoardItem_Fragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import { StyledProps } from "types/type"
import arePropsEqual from "util/arePropsEqual"
import formatDate from "../../../../lib/formatDate"
import OpenDocumentButton from "../../../OpenDocumentButton/OpenDocumentButton"
import { ListEntryProps } from "../../types"
import Chain from "../Chain/Chain"
import ListItem from "../ListItem/ListItem"
import MessageChain from "../MessageChain/MessageChain"
import { RoomItemGridCell } from "./RoomItemGridCell"

export default function RoomListItem(props: ListEntryProps) {
  if (props.entry?.__typename !== "RoomPageBoardItem") return null

  return (
    // <MemoizedItem
    // <MemoizedItem
    <Item entry={props.entry} className={props.className} style={props.style} />
  )
}

const MemoizedItem = React.memo(
  Item,
  arePropsEqual([
    (a) => a.entry?.id,
    (a) => a.entry?.thumbnail?.src,
    (a) => a.entry?.thumbnail?.url,
    (a) => a.entry?.notificationCount,
    (a) => a.entry?.name,
    (a) => a.entry?.room?.lastMessage?.id,
    (a) => a.entry?.room?.name,
    (a) => a.entry?.room?.isReadOnly,
    (a) => a.entry?.room?.isSilenced,
  ])
)

function Item(props: {
  entry: NewPageBoardItem_RoomPageBoardItem_Fragment
  className?: string
  style?: React.CSSProperties
}) {
  const c = useStyles({ isReadOnly: !!props.entry?.room?.isReadOnly || false })

  const pageBoardAPI = React.useContext(ItemsListContext)

  const rootContainer = pageBoardAPI.template.itemsListContainer
  const backgroundColor = yellow[2]
  const options = useTypesOptions()

  const lineMaxWidth = pageBoardAPI.template.lineMaxWidth
  const chainDisplay = pageBoardAPI.template.chainDisplay

  const timestamp = formatDate(
    props.entry.room?.lastMessage?.sentAt || props.entry.lastUpdate,
    rootContainer.width > 400
  )

  const timestampComponent = (
    <Typography variant="body2" color="textSecondary">
      {timestamp}
    </Typography>
  )

  const iconName = options[PageBoardItemType.Room]?.icon || ""

  const BadgeProps: BadgeProps = {
    overlap: "circular",
    anchorOrigin: { vertical: "top", horizontal: "right" },
    badgeContent:
      (!props.entry.room?.isSilenced && props.entry.notificationCount) || 0,
    max: 99,
  }

  const context = props.entry.room?.context
  const roomName = props.entry.room?.name || props.entry.name

  const silenceIcon = props.entry.room?.isSilenced && (
    <Icon
      className={c.notificationsOffIcon}
      style={{ fontSize: 19, marginLeft: 4 }}
    >
      notifications_off
    </Icon>
  )

  const description = (() => {
    const text = props.entry.room?.lastMessage?.text

    if (!text) return null

    const authorName = props.entry.room?.lastMessage?.author?.shortName || ""

    return <MessageChain authorName={authorName} text={text}></MessageChain>
  })()

  const renderIcon = (props?: StyledProps) => <Icon {...props}>{iconName}</Icon>

  const api = React.useContext(ItemsListContext)

  if (!props.entry) return null

  return (
    <div className={clsx(c.root, "indexed-item", props.className)}>
      {(() => {
        if (api.variant === "grid") {
          return (
            <RoomItemGridCell
              entry={props.entry}
              className={props.className}
              style={props.style}
              IconBadgeProps={BadgeProps}
              // renderIcon={(p) => {
              //   return (
              //     <PurpleBadge {...BadgeProps}>
              //       {p.defaultComponent}
              //     </PurpleBadge>
              //   )
              // }}
            />
          )
        }

        return (
          <ListItem
            style={props.style}
            entry={props.entry}
            right={
              <PaperList.ListItemText
                secondary={
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <StopPropagation>
                      <div style={{ display: "flex" }}>
                        {props.entry.room?.document?.id ? (
                          <OpenDocumentButton
                            entry={props.entry.room.document}
                          ></OpenDocumentButton>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </StopPropagation>
                  </div>
                }
                {...(() => {
                  // const sentAt = props.entry.room?.lastMessage?.sentAt

                  if (!!timestamp)
                    return {
                      primary: timestampComponent,
                    }

                  return {}
                })()}
              ></PaperList.ListItemText>
            }
            left={
              <React.Fragment>
                <Avatar
                  backgroundColor={backgroundColor}
                  content={(() => {
                    return renderIcon()
                  })()}
                  BadgeProps={BadgeProps}
                />
                <div style={{ marginLeft: 8 }}></div>
                <PaperList.ListItemText
                  primary={(() => {
                    return (
                      <Chain separator="-" display={chainDisplay}>
                        {(() => {
                          const a = [
                            context && (
                              <Typography
                                color="textSecondary"
                                variant={(() => {
                                  if (chainDisplay === "block") return "caption"
                                  return "body1"
                                })()}
                              >
                                <ClippedText maxWidth={lineMaxWidth}>
                                  {context}
                                </ClippedText>
                              </Typography>
                            ),

                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography variant="body1" color="textPrimary">
                                <ClippedText maxWidth={lineMaxWidth}>
                                  {roomName}
                                </ClippedText>
                              </Typography>

                              {silenceIcon}
                            </div>,
                          ]

                          if (chainDisplay == "block") return a

                          return a.reverse()
                        })()}
                      </Chain>
                    )
                  })()}
                  secondary={(() => {
                    return description
                  })()}
                ></PaperList.ListItemText>
              </React.Fragment>
            }
          ></ListItem>
        )
      })()}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      opacity: (props: { isReadOnly: boolean }) =>
        props.isReadOnly ? 0.3 : "inherit",
    },

    notificationsOffIcon: {
      color: theme.palette.text.secondary,
    },
  }
})
