import React from "react"
import TwoPages from "components/TwoPages/TwoPages"
import TemplatePage from "../../contexts/TemplatePageContext"
import MemoAPI from "components/MemoAPI/MemoAPI"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import { TwoPagesAPIInput } from "components/TwoPages/hooks/useTwoPagesAPI"
import { RootContainerAPI } from "hooks/useRootContainerAPI"

export type TwoPagesAPIInputFn = (props: {
  rootContainer: RootContainerAPI
}) => TwoPagesAPIInput | undefined

export default function TemplatePageProvider(props: {
  onClose: () => any
  children: React.ReactNode
  TwoPagesAPIProps?: TwoPagesAPIInput | TwoPagesAPIInputFn
}) {
  const rootContainer = useRootContainerAPI()

  return (
    <TwoPages.Provider
      key={rootContainer.width ? "ready" : "not-ready"}
      {...(() => {
        return {
          TwoPagesAPIProps: (() => {
            if (!props.TwoPagesAPIProps) return undefined

            if (typeof props.TwoPagesAPIProps === "function") {
              const p = props.TwoPagesAPIProps({ rootContainer })

              return p
            }

            return props.TwoPagesAPIProps
          })(),
        }
      })()}
    >
      <TwoPages.Context.Consumer>
        {(twoPagesAPI) => {
          return (
            <MemoAPI
              api={{ twoPagesAPI, onClose: props.onClose, rootContainer }}
            >
              {(api) => (
                <TemplatePage.Provider value={api}>
                  {props.children}
                </TemplatePage.Provider>
              )}
            </MemoAPI>
          )
        }}
      </TwoPages.Context.Consumer>
    </TwoPages.Provider>
  )
}
