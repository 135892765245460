import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import Tabs from "components/Tabs/Tabs"
import { ShopArticleFragment } from "lib/graphql/operations"
import React from "react"
import { alignmentUnits } from "styles/globalStyles"

export function Details({
  details,
  className,
  classes = {},
}: {
  details: ShopArticleFragment["details"] | null
  className?: string
  classes?: { tabs?: string }
}) {
  const c = useStyles()

  const tabs = (details || [])
    .map((detail) => ({
      id: detail.category,
      label: detail.category,
      html: detail.html,
    }))
    .filter((d) => !!d.html)

  const [tabID, setTabID] = React.useState<string>(tabs?.[0]?.id || "")

  if (!tabs.length) return null

  return (
    <div className={clsx("details", className)}>
      <Tabs
        onChange={setTabID}
        tabs={tabs}
        elevation={0}
        className={classes.tabs}
        currentTabID={tabID}
      ></Tabs>
      <div
        className={c.content}
        dangerouslySetInnerHTML={{
          __html: (tabs.find(({ id }) => id === tabID) || {}).html || "",
        }}
      ></div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    content: {
      padding: theme.spacing(1, alignmentUnits),
      "& dt": {
        fontWeight: "bold",
        marginBottom: theme.spacing(1),
      },
      "& dd": {
        marginBottom: theme.spacing(2),
      },
    },
  }
})
