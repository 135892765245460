import { OneVisibleView } from "components/TwoPages/hooks/useTwoPagesAPI"
import { PageBoardItemParams } from "lib/graphql/types"
import React from "react"
import { PageComponentProps } from "../../../../../../../Page/hooks/useSecondPage"
import { HistoryTracker } from "../../../../../HistoryThread/HistoryThread"
import { IndexedItemsContext } from "../../../../IndexedItemsContext/IndexedItemsContext"
import { GetItemParams } from "../../hooks/usePageBoardItem"
import { PageInitialParams } from "../../types"
import { ItemPage } from "../ItemPage/ItemPage"
import {
  PageBoardItemApi,
  PageBoardItemContext,
  PageBoardItemProvider,
} from "../PageBoardItemContext"

export const oneVisibleView: OneVisibleView = { id: "one-visible-view" }

export function SecondPage<TParams extends PageBoardItemParams["type"]>(
  props: {
    getItemParams?: GetItemParams<TParams>
    pageInitialParams?: PageInitialParams<TParams>
    children?: (props: {
      api: PageBoardItemApi
      defaultComponent: React.ReactNode
    }) => React.ReactNode
  } & PageComponentProps
) {
  const indexedApi = React.useContext(IndexedItemsContext)

  return (
    <PageBoardItemProvider
      {...props.getItemParams}
      initialParams={props.pageInitialParams}
      twoPagesApiMandatoryLayout={oneVisibleView}
    >
      <PageBoardItemContext.Consumer>
        {(api) => {
          const defaultComponent = <ItemPage onClose={props.onClose}></ItemPage>

          if (!props.children) return defaultComponent

          return props.children({ defaultComponent, api })
        }}
      </PageBoardItemContext.Consumer>

      <PageBoardItemContext.Consumer>
        {(api) => {
          if (!api.item) return null

          return (
            <HistoryTracker
              params={{ itemID: api.item.itemID, type: api.item.type }}
              api={indexedApi.historyThreadApi}
              onCloseItem={props.onClose}
            ></HistoryTracker>
          )
        }}
      </PageBoardItemContext.Consumer>
    </PageBoardItemProvider>
  )
}
