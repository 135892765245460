import { Nullable, ObjectWithID } from "types/type"
import EditControlComponent from "components/EditControl"
import { DocumentFragment } from "lib/graphql/operations"
import React from "react"
import DetailsTabsIDs from "../lib/detailsTabsIDs"

export interface DetailsTabsParams {
  entry: Nullable<ObjectWithID>
  statusColor?: Nullable<string>
  goToRoom: (props: { userID: string }) => any
  hideBackButton: boolean
  partner: Nullable<ObjectWithID>
  document: Nullable<ObjectWithID>
  partnerIsLoading?: Nullable<boolean>
}

export interface IDetailsTabsContext extends DetailsTabsParams {
  goToPartnerTab: () => any
  goToDocumentTab: () => any
  tabID: Nullable<DetailsTabsIDs>
  setTabID: (tabID: DetailsTabsIDs) => any
  document: DocumentFragment
}

const DetailsTabsContext = React.createContext<IDetailsTabsContext>({
  statusColor: undefined,
  entry: undefined,
  goToPartnerTab() {},
  hideBackButton: false,
  goToDocumentTab() {},
  goToRoom(props: { userID: string }) {},
  tabID: null,
  setTabID(tabID) {},
  partner: undefined,
  // document: undefined,
  partnerIsLoading: undefined,
  // EditControl: EditControlComponent,
})

DetailsTabsContext.displayName = "DetailsTabsContext"

export default DetailsTabsContext
