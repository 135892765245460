import EditableProperty from "./components/EditableProperty/EditableProperty"
import EditControlContext from "./contexts/EditControlContext"
import useEditControlProviderValue from "./hooks/useEditControlProviderValue"
import EditPanel from "./components/EditPanel/EditPanel"

const EditControl = {
  EditableProperty,
  Context: EditControlContext,
  EditPanel,
  Provider: function EditControlProvider({
    object,
    onSaveEditedObject = (updatedObject) => {},
    children,
    endEditionOnSave = false,
    isEditing = false,
  }) {
    const editControlProviderValue = useEditControlProviderValue({
      object,
      onSaveEditedObject,
      isEditing,
      endEditionOnSave,
    })

    return (
      <EditControlContext.Provider value={editControlProviderValue}>
        {children}
      </EditControlContext.Provider>
    )
  },
}

export default EditControl
