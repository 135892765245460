import { Dialog, Popover, PopoverProps } from "@material-ui/core"
import { default as clsx, default as cslx } from "clsx"
import {
  BottomModal,
  BottomModalProps,
} from "components/BottomModal/BottomModal"
import FullScreenModal from "components/FullScreenModal/FullScreenModal"
import KeyListeners from "components/KeyListeners/KeyListeners"
import StopPropagation from "components/StopPropagation/StopPropagation"
import React from "react"
import popoverPositioning from "util/popoverPositioning/popoverPositioning"
import { UnionOmit } from "../../types/type"

type BasicModalProps = {
  children: React.ReactNode
  onClose?: () => any
  isOpen: boolean
  style?: React.CSSProperties
  className?: string
}

type PopoverModalProps = BasicModalProps & {
  variant: "popover"
  PopoverProps?: Omit<PopoverProps, "open" | "anchorEl" | "children"> & {
    anchorEl?: HTMLElement | null
  }
}

export type DialogModalProps = BasicModalProps & {
  variant: "dialog"
  DialogProps?: Omit<PopoverProps, "open" | "anchorEl" | "children"> & {
    anchorEl?: HTMLElement | null
  }
}

export type BottomModal = BasicModalProps & {
  variant: "bottom-modal"
  BottomModalProps?: Omit<BottomModalProps, "isOpen" | "children" | "onClose">
  children: BottomModalProps["children"]
}

type FullScreenModalProps = BasicModalProps & {
  variant: "full-screen"
}

export type ModalProps =
  | FullScreenModalProps
  | DialogModalProps
  | PopoverModalProps
  | BottomModal

export type VariantProps = UnionOmit<ModalProps, keyof BasicModalProps>

export type BottomModalVariantProps = UnionOmit<
  BottomModal,
  keyof BasicModalProps
>

export default function Modal(props: ModalProps) {
  const variant =
    typeof props.variant === "undefined" ? "popover" : props.variant

  const onEsc = React.useCallback(() => {
    props.onClose?.call(undefined)
  }, [props.onClose])

  KeyListeners.useEscapeListener(onEsc, { enabled: !!props.isOpen })

  let className = cslx("modal", props.className)

  if (variant === "full-screen")
    return (
      <FullScreenModal
        isOpen={props.isOpen}
        onClose={props.onClose}
        className={className}
      >
        {props.children}
      </FullScreenModal>
    )

  if (variant === "popover") {
    const style = {
      overflow: "hidden",
      ...props.style,
    }

    return (
      <Popover
        open={props.isOpen}
        className={className}
        style={style}
        onClick={props.onClose}
        {...(() => {
          if ("PopoverProps" in props) {
            const anchorEl = props.PopoverProps?.anchorEl || null

            return {
              open: props.isOpen && !!anchorEl,
              ...popoverPositioning({ anchorEl }),
              ...(props.PopoverProps || {}),
              className: clsx(className, props.PopoverProps?.className),
              style: {
                ...style,

                ...(props.PopoverProps?.style || {}),
              },
            }
          }

          return {}
        })()}
      >
        <StopPropagation
          style={{ maxHeight: "100%", height: "100%", overflow: "hidden" }}
        >
          {props.children}
        </StopPropagation>
      </Popover>
    )
  }

  if (variant === "dialog") {
    // props.

    return (
      <Dialog
        disableEscapeKeyDown
        open={props.isOpen}
        className={className}
        onClose={props.onClose}
        {...(() => {
          if ("DialogProps" in props) {
            className = clsx(className, props.DialogProps?.className)

            return { ...(props.DialogProps || {}), className }
          }

          return {}
        })()}
      >
        {props.children}
      </Dialog>
    )
  }

  if (variant === "bottom-modal") {
    const compProps = (() => {
      if ("BottomModalProps" in props) return props.BottomModalProps
    })()

    return (
      <BottomModal
        isOpen={props.isOpen}
        onClose={props.onClose}
        children={props.children}
        className={props.className}
        {...compProps}
      ></BottomModal>
    )
  }

  return null
}
