import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => {
  return {
    roomWrapper: {
      padding: 0,
      height: "100%",
      "&>*": {
        height: "100%",
      },
    },
  }
})

export default useStyles
