import { makeStyles, Typography } from "@material-ui/core"
import Ball from "components/Ball/Ball"
import { Button } from "components/Button/Button"
import { Icon } from "components/Icon/Icon"
import { purple } from "constants/colors"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { useIsCurrentUserMessage } from "hooks/useIsCurrentUserMessage"
import { Message_ZoomSessionRegistrationMessage_Fragment } from "lib/graphql/operations"
import { MessageBalloonProps } from "../MessageBalloon/MessageBalloon"
import { MessageWrapper } from "../MessageWrapper"

export function ZoomSessionRegistrationMessage(props: {
  message: Message_ZoomSessionRegistrationMessage_Fragment
  MessageBalloonProps: Omit<MessageBalloonProps, "message" | "children">
  deactivateJoinButton?: boolean
  onClickJoinButton?: () => void
}) {
  const c = useStyles()

  const t = useI18n()

  const session = props.message.zoomSession
  const conferenceHasEnded = !!session?.endTime

  const isCurrentUserMessage = useIsCurrentUserMessage(props.message)

  return (
    <MessageWrapper {...props} key={props.message.id}>
      {({ components: { authorName, bottom } }) => {
        return (
          <>
            {authorName}
            <div className={c.line}>
              <Ball background={purple[0]}>
                <Icon
                  name={(() => {
                    if (!conferenceHasEnded) return "video_call"
                    return "call_end"
                  })()}
                  style={{ color: "white" }}
                ></Icon>
              </Ball>

              {(() => {
                if (!conferenceHasEnded) {
                  if (isCurrentUserMessage)
                    return (
                      <Typography variant="body2">
                        {t.conference.inProgress}
                      </Typography>
                    )

                  return (
                    <Button
                      variant="outlined"
                      disabled={props.deactivateJoinButton}
                      onClick={props.onClickJoinButton}
                    >
                      {t.joinConference}
                    </Button>
                  )
                }

                return (
                  <div>
                    <Typography variant="body2">{t.conferenceEnded}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t.getDuration(
                        new Date(session.startTime),
                        new Date(session.endTime)
                      )}
                    </Typography>
                  </div>
                )
              })()}
            </div>
            {bottom}
          </>
        )
      }}
    </MessageWrapper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    line: {
      display: "flex",
      alignItems: "center",
      "&>*:first-child": {
        marginRight: theme.spacing(1),
      },
    },
    paragraphs: {
      "& > *": {
        marginBottom: 8,
      },
    },
  }
})
