import ImageWithAuth from "components/ImageWithAuth/ImageWithAuth"
import PreviewTemplate from "./components/PreviewTemplate/PreviewTemplate"
import React from "react"

export function PreviewWithImage(props: {
  description: React.ReactNode
  title: React.ReactNode
  src: string
  leftRibbonColor?: string
}) {
  const render = React.useCallback((p: { imageObjectURL?: string | null }) => {
    return (
      <PreviewTemplate
        description={props.description}
        title={props.title}
        thumbSrc={p.imageObjectURL}
        leftRibbonColor={props.leftRibbonColor}
      />
    )
  }, [])

  return <ImageWithAuth src={props.src}>{render}</ImageWithAuth>
}
