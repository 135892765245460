import _f from "lodash/fp"
import useImageWithAuth from "hooks/useImageWithAuth/useImageWithAuth"

type ChildrenProps = {
  imageObjectURL?: string | null
  error?: string | Error | null
  isFetching: boolean
  fetchImage: () => any
}

export default function ImageWithAuth(props: {
  src: string
  lazyFetching?: boolean
  children?: (props: ChildrenProps) => JSX.Element | null
}) {
  const { src, lazyFetching = false, children } = props

  const { objectURL, error, isFetching, fetchImage } = useImageWithAuth({
    src,
    lazyFetching,
  })

  const childrenProps: ChildrenProps = {
    imageObjectURL: objectURL,
    error,
    isFetching,
    fetchImage,
  }

  if (typeof objectURL !== "string" && !children) return null

  if (!children) return <img src={objectURL || ""}></img>

  return children?.(childrenProps) || null
}
