import { ButtonBase, Icon, makeStyles, IconButton } from "@material-ui/core"
import clsx from "clsx"
import { useContext } from "react"
import DetailsTabsContext from "../../contexts/DetailsTabsContext"
import DetailsTabsIDs from "../../lib/detailsTabsIDs"

export default function GoToDocumentRoomButton({ className = "" }) {
  const { goToRoom, document } = useContext(DetailsTabsContext)
  const documentID = document?.id

  if (!documentID) return null

  return (
    <IconButton
      onClick={() => goToRoom({ documentID })}
      size="small"
      className={clsx("go-to-document-room-button", className)}
    >
      <Icon style={{ fontSize: 19 }}>chat</Icon>
    </IconButton>
  )
}
