import {
  Button,
  IconButton,
  makeStyles,
  TextFieldProps,
  Typography,
} from "@material-ui/core"
import clsx from "clsx"
import { Icon } from "components/Icon/Icon"
import LightTooltip from "components/LightTooltip/LightTooltip"
import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import TextField from "components/TextField/TextField"
import TwoPages from "components/TwoPages/TwoPages"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import { ModalContentProps, Modal } from "../../../Modal/Modal"
import BoardAndColumnSelectionAPI from "../../BoardAndColumnSelectionAPI"
import SelectColumn from "./components/SelectColumn/SelectColumn"
import SelectRoom from "./components/SelectRoom/SelectRoom"
// import { ModalProps } from '../../../../../../../Modal/Modal';
import { IconProps } from "components/Icon/Icon"

export function SelectionPanel(props: {
  onClose: () => void
  style?: React.CSSProperties
  className?: string
}) {
  return (
    <TwoPages.Provider>
      <TwoPages.Root
        className={props.className}
        style={props.style}
        mainPage={
          <Modal.Content onClose={props.onClose} topButtonsFontSize={18}>
            <Main></Main>
          </Modal.Content>
        }
      ></TwoPages.Root>
    </TwoPages.Provider>
  )
}

function renderTop(props: {
  secondButton: React.ReactNode
}): ModalContentProps["renderTop"] {
  let render: ModalContentProps["renderTop"] = function renderTop(args) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {args.components.closeButton}
        {props.secondButton}
      </div>
    )
  }

  return render
}

const topButtonsFontSize: ModalContentProps["topButtonsFontSize"] = 18

const pages = {
  selectRoom: <SelectRoom></SelectRoom>,
  selectColumn: {
    component: <SelectColumn></SelectColumn>,
    closeIconButton: "arrow_back" as IconProps["name"],
    secondButton: (
      <CreateNewColumnButton
        topButtonsFontSize={topButtonsFontSize}
      ></CreateNewColumnButton>
    ),
  },
}

function Main(props: { className?: string; style?: React.CSSProperties }) {
  const c = useStyles()
  const [chatRoomSelectionOpened, setChatRoomSelectionOpened] =
    React.useState(false)

  const twoPagesAPI = React.useContext(TwoPages.Context)

  const fn = (value: keyof typeof pages) => {
    if (!value) return

    const page = pages[value]

    twoPagesAPI.openSecondPage({
      id: value,
      Page(props: { onClose: () => void }) {
        return (
          <Modal.Content
            topButtonsFontSize={topButtonsFontSize}
            renderTop={renderTop({
              secondButton: "secondButton" in page ? page.secondButton : null,
            })}
            onClose={() => {
              props.onClose()
            }}
            closeButtonIcon="arrow_back"
          >
            <div style={{ height: "100%" }}>
              {"component" in page ? page.component : page}
            </div>
          </Modal.Content>
        )
      },
      // layoutID: "one-visible-view",
      preferentialLayout: { id: "one-visible-view" },
    })
  }

  const t = useI18n()

  const api = React.useContext(BoardAndColumnSelectionAPI.Context)
  const kanbanBoardAPI = React.useContext(KanbanCardPageBoardItemContext)

  const editPencilProps: TextFieldProps["InputProps"] = {
    // position: "end",
    endAdornment: (
      <Icon
        name="edit"
        style={{ fontSize: 19, opacity: 0.8, lineHeight: 1 }}
      ></Icon>
    ),
  }

  return (
    <div className={clsx("selection-panel", props.className, c.root)}>
      <Typography variant="body2" style={{ marginBottom: 8 }}>
        {t.selectGoal}
      </Typography>

      <TextField
        variant="filled"
        value={(() => {
          if (api.roomSelection.selected)
            return api.roomSelection.selected.room?.name

          return kanbanBoardAPI.card.data?.roomName || ""
        })()}
        label={t["chatRoom"]}
        style={{ marginBottom: 8 }}
        onFocus={() => fn("selectRoom")}
        InputProps={editPencilProps}
      ></TextField>
      <TextField
        // style={{ flex: 2, marginRight: 8 }}
        variant="filled"
        onClick={() => fn("selectColumn")}
        value={(() => {
          if (api.columnSelection.selectedColumn) {
            return api.columnSelection.selectedColumn?.title
          }

          if (!api.roomSelection.selected) {
            return kanbanBoardAPI.card.data?.columnName || ""
          }

          return "-"
        })()}
        label={t["list"]}
        InputProps={editPencilProps}
      ></TextField>
      <Button
        style={{ marginTop: 16 }}
        variant="contained"
        disableElevation
        disabled={!api.readyToSubmit}
        onClick={() => {
          api.submit()
        }}
        color="primary"
      >
        {t["move"]}
      </Button>
      {/* <div style={{ display: "flex" }}>
        <TextField
          // style={{ flex: 2, marginRight: 8 }}
          style={{ flex: 1 }}
          variant="filled"
          // value="todo"

          value={(() => {
            if (api.columnSelection.selectedColumn)
              return api.columnSelection.selectedColumn?.title

            return kanbanBoardAPI.card.data?.columnName || ""
          })()}
          label={t["list"]}
        ></TextField>

        {/* <TextField
          style={{ flex: 1 }}
          variant="filled"
          value="5"
          label={t["position"]}
        ></TextField> 
      </div> */}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))

function CreateNewColumnButton(props: {
  topButtonsFontSize: ModalContentProps["topButtonsFontSize"]
}) {
  const api = React.useContext(BoardAndColumnSelectionAPI.Context)
  const t = useI18n()

  return (
    <LightTooltip title={t["addColumn"]}>
      <IconButton
        size="small"
        onClick={() => api.columnSelection.createColumn()}
      >
        <Icon
          name="add"
          style={{ fontSize: props.topButtonsFontSize || 15 }}
        ></Icon>
      </IconButton>
    </LightTooltip>
  )
}
