import { Typography } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import clsx from "clsx"
import LeftSnackbar from "components/LeftSnackbar/LeftSnackbar"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useDocumentsTypesTranslations from "hooks/useDocumentsTypesTranslations/useDocumentsTypesTranslations"
import usePublicAppConfig from "hooks/usePublicAppConfig"
import React from "react"
import { StyledProps } from "types/type"
import Dropzone from "../Dropzone/Dropzone"
import {
  UploadDocumentsContext,
  UploadDocumentsProvider,
} from "./components/ContextAndProvider"
import SuccessFeedback from "./components/SuccessFeedback/SuccessFeedback"
import UploadSteps from "./components/UploadSteps/UploadSteps"
import { UploadProvider } from "./context/UploadContext"
import useAddFilesToContext from "./hooks/useAddFilesToContext"
import useUploadFiles from "./hooks/useUploadFiles"
import transformOnDropFile from "./util/transformOnDropFile"

type ApiInputProps = {
  onClose?: () => void
  defaultUploadType?: string
  partnerID?: string
  collectionsIDs?: string[]
  onChangeLoadingState?: (isLoading: boolean) => void
}

export type UploadDocumentsProps = ApiInputProps & StyledProps

export function UploadDocuments({
  className: propsClassName = "",
  defaultUploadType = "",
  partnerID = "",
  collectionsIDs = [],
  onClose,
  onChangeLoadingState,
}: UploadDocumentsProps) {
  const {
    uploadFiles,
    uploadDone,
    resetUpload,
    amountUploaded,
    loading: uploading,
  } = useUploadFiles({
    partnerID,
    collectionsIDs,
    onChangeLoadingState,
  })

  const { documentsTypesTranslations: fileTypesAvailable } =
    useDocumentsTypesTranslations()

  const className = clsx("upload", propsClassName)

  const { utils, params } = useStuff({
    uploadFiles,
    fileTypesAvailable,
    defaultUploadType,
    amountUploaded,
    loading: uploading,
    uploadDone,
  })

  const [showFileLimitMessage, setShowFileLimitMessage] = React.useState(false)

  const t = useI18n()

  const { fileMaxSizeInMB } = usePublicAppConfig() || {}

  if (typeof fileMaxSizeInMB === "undefined") return null

  if (uploadDone) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SuccessFeedback onClickComeback={resetUpload} className={className} />
      </div>
    )
  }

  return (
    <UploadProvider transformOnDropFile={transformOnDropFile}>
      <UploadDocumentsProvider onClose={onClose} {...utils} {...params}>
        <>
          <DropzoneProvider
            accept="application/pdf"
            multiple
            maxSize={fileMaxSizeInMB * Math.pow(10, 6)}
            onDropFileOverLimit={() => setShowFileLimitMessage(true)}
          >
            <Dropzone.Root className={className}>
              <UploadSteps />
            </Dropzone.Root>
          </DropzoneProvider>
          <UploadDocumentsContext.Consumer>
            {(api) => {
              return (
                <LeftSnackbar
                  open={uploading}
                  style={{ alignItems: "flex-end" }}
                >
                  {(() => {
                    return (
                      <Alert severity="info">
                        {uploading && typeof amountUploaded === "number" ? (
                          <Typography variant="body1">{`${amountUploaded}/${api.filesApi.uploadedFiles.length} uploaded`}</Typography>
                        ) : (
                          <span />
                        )}
                      </Alert>
                    )
                  })()}
                </LeftSnackbar>
              )
            }}
          </UploadDocumentsContext.Consumer>

          <LeftSnackbar
            open={showFileLimitMessage}
            onClose={() => setShowFileLimitMessage(false)}
            autoHideDuration={15000}
          >
            <Alert
              severity="error"
              variant="filled"
              onClose={() => setShowFileLimitMessage(false)}
            >
              {t.fileLimitMessage(fileMaxSizeInMB)}
            </Alert>
          </LeftSnackbar>
        </>
      </UploadDocumentsProvider>
    </UploadProvider>
  )
}

/**TODO:  IT SHOULD BE MOVED to UploadDocumentContext */
function useStuff({
  uploadFiles,
  fileTypesAvailable,
  defaultUploadType,
  uploadDone,
  amountUploaded,
  loading,
}: {
  uploadFiles: ({ files }: { files: any }) => void
  fileTypesAvailable: any
  defaultUploadType: string
  amountUploaded: number
  uploadDone?: boolean
  loading: boolean
}) {
  const utils = React.useMemo(() => ({ uploadFiles }), [uploadFiles])

  const params = React.useMemo(
    () => ({
      fileTypesAvailable,
      defaultUploadType,
      amountUploaded,
      loading,
      uploadDone,
    }),
    [
      //TODO: move to UploadContext (see: https://trello.com/c/yWpwMUBZ/493-15-hour-replace-the-name-of-uploadcontext-by-filescontext-and-create-an-uploadcontext)
      amountUploaded,
      loading,

      //params
      fileTypesAvailable,
      uploadDone,
      defaultUploadType,
    ]
  )
  return { utils, params }
}

function DropzoneProvider({
  children,
  multiple,
  accept,
  maxSize,
  onDropFileOverLimit,
}) {
  const addFilesToContext = useAddFilesToContext()

  return (
    <Dropzone.Provider
      onDrop={(files) => {
        const passedSizeLimit = () => files.some((file) => file.size > maxSize)

        if (typeof maxSize !== "undefined" && passedSizeLimit()) {
          return onDropFileOverLimit()
        }

        addFilesToContext(files)
      }}
      multiple={multiple}
      accept={accept}
    >
      {children}
    </Dropzone.Provider>
  )
}
