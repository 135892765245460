import { Typography, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import { StyledProps } from "types/type"
import RoomPageBoardItemApiContext from "../../contexts/RoomPageBoardItemContext"
import { red } from "constants/colors"
import I18nContext from "contexts/i18nContext/i18nContext"

export function HeaderWithAssistantStatus(
  props: { children: React.ReactNode } & StyledProps
) {
  const api = React.useContext(RoomPageBoardItemApiContext)
  const c = useStyles()

  return (
    <div
      className={clsx("header-with-assistant-status", props.className)}
      style={{
        ...props.style,
      }}
    >
      <div>{props.children}</div>

      {api.assistantIsWorking && (
        <div className={c.statusRibbon}>
          <I18nContext.Consumer>
            {(t) => {
              return (
                <Typography>
                  <span>{t.aileenIsThinking}</span>
                </Typography>
              )
            }}
          </I18nContext.Consumer>
        </div>
      )}
    </div>
  )
}
const useStyles = makeStyles(() => ({
  statusRibbon: {
    background: red[0],
    color: "white",
    textAlign: "center",
  },
}))
