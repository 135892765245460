import { ID } from "types/type"
import { RoomContext } from "components/Room/contexts/room"
import { ToggleableMenuItem } from "components/ToggleableMenu/ToggleableMenu"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useActiveResources from "hooks/useActiveResources"
import { useIsRoomSilenced } from "hooks/useIsRoomSilenced"
import useMemoAPI from "hooks/useMemoAPI"
import {
  RoomNotificationsCountDocument,
  RoomNotificationsCountQueryVariables,
  useUpdateRoomSilenceMutation,
  WorkspaceNotificationsCountDocument,
  WorkspaceNotificationsCountQueryVariables,
  WorkspaceSettingsDocument,
  WorkspaceSettingsQueryVariables,
} from "lib/graphql/operations"
import React from "react"

export default function useNotificationOption(): ToggleableMenuItem {
  const { roomID } = React.useContext(RoomContext)
  const { isSilenced, toggleSilence } = useRoomNotification({ roomID })
  const t = useI18n()

  return {
    component: isSilenced ? (
      <>{t["turn-on-notifications"]}</>
    ) : (
      <>{t["turn-off-notifications"]}</>
    ),
    onClick: toggleSilence,
  }
}
function useRoomNotification(props: { roomID?: string | null }) {
  const { workspaceID } = useActiveResources()
  const variables = { workspaceID, roomID: props.roomID || "" }

  const { isSilenced: isRoomSilenced, setIsSilenced } =
    useIsRoomSilenced(variables)

  const [mutate] = useUpdateRoomSilenceMutation()

  const toggleSilence = React.useCallback(() => {
    const newState = !isRoomSilenced

    if (Object.values(variables).some((a) => !a)) return

    mutate({
      variables: { ...variables, isSilenced: newState },
      optimisticResponse: {
        __typename: "Mutation",
        updateRoomSilence: { success: true, __typename: "BasicResponse" },
      },
      refetchQueries: [
        {
          query: RoomNotificationsCountDocument,
          variables: {
            roomID: variables.roomID,
            workspaceID: variables.workspaceID,
          } as RoomNotificationsCountQueryVariables,
        },
        {
          query: WorkspaceSettingsDocument,
          variables: {
            workspaceID: variables.workspaceID,
          } as WorkspaceSettingsQueryVariables,
        },
        {
          query: WorkspaceNotificationsCountDocument,
          variables: {
            workspaceID: variables.workspaceID,
          } as WorkspaceNotificationsCountQueryVariables,
        },
      ],
      update(proxy) {
        // proxy.writeQuery<IsRoomSilencedQuery, IsRoomSilencedQueryVariables>({
        //   query: IsRoomSilencedDocument,
        //   data: { isRoomSilenced: newState, __typename: "Query" },
        //   variables,
        // })
        setIsSilenced(newState, proxy)
      },
    })
  }, [...Object.values(variables), mutate, isRoomSilenced, setIsSilenced])

  return useMemoAPI({ toggleSilence, isSilenced: isRoomSilenced })
}
