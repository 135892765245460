import _ from "lodash"
import React from "react"

/**
 * Provided that a list of providers [P1, P2, P3, P4] is passed as props,
 * it renders
 *
 *    <P1>
        <P2>
          <P3>
            <P4>
              {children}
            </P4>
          </P3>
        </P2>
      </P1>


    if P1, ... or Pn is an instantiated Component (element), it will be cloned and 
    receive children as props.
 *
 */

export type ComposeProviderProps = {
  Providers: (
    | React.ReactElement<{ children: React.ReactNode }>
    | React.FC<{ children: React.ReactNode }>
  )[]
  children: React.ReactNode
}

export default function ComposeProviders(props: ComposeProviderProps) {
  const { Providers, children } = props

  if (_.isEmpty(Providers)) return <>{children}</>

  const composed = _.reverse(Providers)
    // .filter(_.isFunction)
    .reduce((acc, Provider) => {
      const isElement = React.isValidElement(Provider)

      if (isElement)
        return React.cloneElement(Provider, { children: <>{acc}</> })

      if (typeof Provider === "function") return <Provider>{acc}</Provider>

      return acc
    }, children)

  return <>{composed}</>
}
