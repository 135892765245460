import { useApolloClient, gql } from "@apollo/client";
import { AddKanbanImagesDocument } from "lib/graphql/operations"
import React from "react"
import { ID } from "types/type"
import bindCardImageToCardOnCache from "../../lib/bindCardImageToCardOnCache"

export default function useAddImages(props: {
  onChangeLoadingState: (state: boolean) => void
  workspaceID: ID
}) {
  const client = useApolloClient()

  return React.useCallback(
    async function addImages(cardID: ID, files: File[]) {
      props.onChangeLoadingState(true)

      await client
        .mutate({
          mutation: AddKanbanImagesDocument,
          variables: { workspaceID: props.workspaceID, cardID, images: files },
          update(proxy, { data }) {
            if (!data?.addKanbanImages) return

            const cardID = data?.addKanbanImages?.cardID

            bindCardImageToCardOnCache({ cardID }, proxy)
          },
        })
        .finally(() => props.onChangeLoadingState(false))
    },
    [props.onChangeLoadingState, props.workspaceID]
  )
}
