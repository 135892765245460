import { useContext } from "react"
import { useTheme } from "@material-ui/core"
import { RobotSVG } from "components/RobotSVG/RobotSVG"
import { RoomContext } from "components/Room/contexts/room"
import { useI18n } from "contexts/i18nContext/i18nContext"
import RoomWallMetaCard from "components/Room/components/RoomWall/components/CreateRoomMetaCard/CreateRoomMetaCard"

export default function CreateRoomMetaCard() {
  const { createRoom } = useContext(RoomContext)
  const theme = useTheme()

  const t = useI18n()

  return (
    <RoomWallMetaCard
      className="create-room-meta-card"
      buttonProps={{
        onClick: createRoom,
        children: <>{t["create-room"]}</>,
      }}
      avatarProps={{
        backgroundColor: theme.palette.secondary.main,
        content: <RobotSVG fill="white"></RobotSVG>,
      }}
    ></RoomWallMetaCard>
  )
}
