import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { StyledProps } from "types/type"
import FieldsetIndentation, {
  FieldsetIndentationProps,
} from "./components/FieldsetIndentation/FieldsetIndentation"
import { FieldsetTitle } from "./FieldsetTitle"

type Grouped = FieldsetIndentationProps | null | undefined | false

export { FieldsetTitle } from "./FieldsetTitle"

export function PageForm(
  props: {
    fieldsIndentationsProps: Array<Grouped | Grouped[]>
    className?: string
    hideFieldBottomLine?: boolean
  } & StyledProps
) {
  const c = useStyles({ hideBottomLine: props.hideFieldBottomLine })

  /**
   * Grouping fieldsIndentationsProps into arrays
   */
  const groups = props.fieldsIndentationsProps
    .map((b) => {
      if (!b) return null
      if (!Array.isArray(b)) return [b]
      return b
    })
    .filter((a): a is Exclude<typeof a, null> => !!a)

  const fields = (
    <>
      {groups.map((group, index) => {
        return (
          <div className={clsx(c.group, "group")} key={index}>
            {group
              // .filter((a): a is FieldsetIndentationProps => !!a)
              .map((a, index) => {
                if (!a) return <div></div>

                if (!a.children) return null

                return (
                  <FieldsetIndentation
                    {...a}
                    key={index}
                    className={clsx(c.box, props.className)}
                  >
                    <div className={c.fields}>
                      {a.title ? (
                        <FieldsetTitle>{a.title}</FieldsetTitle>
                      ) : null}
                      {a.children}
                    </div>
                  </FieldsetIndentation>
                )
              })}
          </div>
        )
      })}
    </>
  )

  return (
    <div className={clsx("form", c.root, props.className)} style={props.style}>
      {fields}
    </div>
  )
}
export const useStyles = makeStyles((theme) => {
  return {
    root: {
      "&>*": {
        // height: "100%",
      },
    },
    group: {
      display: "flex",
      "&>*": {
        flex: 1,
      },

      "&:not(:last-child)": {
        borderBottom: (props: { hideBottomLine?: boolean }) =>
          props.hideBottomLine
            ? "none"
            : ["1.1px solid", "rgba(0,0,0, 0.1)"].join(" "),
      },
    },
    fields: {
      "&>*": {
        marginBottom: theme.spacing(1),
      },
      "&>*:last-child": {
        marginBottom: "0 !important",
      },
    },
    box: {
      padding: theme.spacing(1.5, 0),
      paddingRight: theme.spacing(2),
    },
  }
})
