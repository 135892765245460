import { makeStyles, IconButton, Icon } from "@material-ui/core"
import React from "react"
import useManageMembers from "components/Room/hooks/useManageMembers/useManageMembers"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"

//REMOVE
export default function LisItem({ user, item, added }) {
  const c = useStyles({})
  const { addUser, removeUser } = useManageMembers()

  const props = {
    added: { iconName: "close", action: () => removeUser(user.id) },
    "not-added": { iconName: "add", action: () => addUser(user.id) },
  }

  const { iconName, action } = props[added ? "added" : "not-added"]

  return (
    <div className={c.item}>
      {item}
      <IconButton size="small" color="primary" onClick={action}>
        <Icon>{iconName}</Icon>
      </IconButton>
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    item: {
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "&>*:first-child": {
        maxWidth: "60%",
      },
    },
  }
})
