import _ from "lodash"
import { RoomMessageListFragmentDoc } from "lib/graphql/operations"
import cachedIDGetters from "./getCacheID/getCacheID"

export default function getRoomMessageList({ roomID }, client) {
  const id = cachedIDGetters.RoomMessageList({ roomID })
  let item

  try {
    item = client.readFragment({
      fragmentName: "RoomMessageList",
      id,
      fragment: RoomMessageListFragmentDoc,
    })
  } catch (e) {
    return console.error(e)
  }

  return item
}
