import React from "react"
import RootContainerContext from "../../contexts/RootContainerContext"
import useRootContainerAPI from "../../../../hooks/useRootContainerAPI"

export type RootContainerProviderChildren =
  | React.ReactChild
  | React.ReactNode
  | ((props: ReturnType<typeof useRootContainerAPI>) => React.ReactChild)

export default function RootContainerProvider(props: {
  children: RootContainerProviderChildren
}) {
  const providerValue = useRootContainerAPI()

  return (
    <RootContainerContext.Provider value={providerValue}>
      {typeof props.children === "function"
        ? props.children(providerValue)
        : props.children}
    </RootContainerContext.Provider>
  )
}
