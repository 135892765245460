import _ from "lodash"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { useCallback, useContext, useEffect } from "react"
import TabsWithBody from "components/TabsWithBody/TabsWithBody"
import DetailsTabsContext from "../../contexts/DetailsTabsContext"
import basicTabs from "../../lib/basicTabs"
import DetailsTabsIDs from "../../lib/detailsTabsIDs"
import * as ChatOverlay from "components/ChatOverlay/index"

export default function DetailsTabsRoot({ className = "", tabs = basicTabs }) {
  const {
    setTabID,
    tabID,
    statusColor,
    entry: activity,
    document,
  } = useContext(DetailsTabsContext)

  const hideBalloonIfIsRoomTab = useHideBalloonIfRoomTab()

  const onChangeTabID = useCallback(
    function onChangeTabID(tabID) {
      setTabID(tabID)
      hideBalloonIfIsRoomTab(tabID)
    },
    [hideBalloonIfIsRoomTab]
  )

  const c = useStyles()

  if (!document?.id) return null

  return (
    <TabsWithBody
      lazyRendering
      noScroll
      className={clsx(c.root, "details-tabs", className)}
      tabs={tabs}
      selectedTabColor={statusColor}
      classes={{
        bodyRoot: c.body,
      }}
      onChange={onChangeTabID}
      key={activity?.id}
      tabID={tabID}
      contentZeroPadding
    ></TabsWithBody>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    body: {
      overflowY: "hidden !important",
    },
  }
})
function useHideBalloonIfRoomTab() {
  const { hideChatBalloon, showChatBalloon } = useContext(ChatOverlay.Context)

  useEffect(() => {
    return () => showChatBalloon()
  }, [])

  const hideBalloonIfRoomTab = useCallback(
    (tabID) => {
      if (tabID === DetailsTabsIDs.ROOM) hideChatBalloon()
      else showChatBalloon()
    },
    [hideChatBalloon, showChatBalloon]
  )
  return hideBalloonIfRoomTab
}
