import { Month, WeekProps } from "components/MonthCalendar/MonthCalendar"
import moment from "moment"
import React, { ForwardedRef } from "react"
import { WeekHeader } from "./CalendarViews/WeekHeader"
import Day from "./CalendarViews/components/Day/Day"
import {
  MonthsAPIProps,
  useMonthsAPI,
} from "./CalendarViews/hooks/useMonthsAPI"

import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import {
  SnappedCarousel,
  SnappedCarouselProps,
} from "components/SnappedCarousel/SnappedCarousel"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { MonthEntity } from "./CalendarViews/components/MonthEntity/MonthEntity"

export type RefAPI = {
  clearFields(): any
}

export type {
  MonthsAPI,
  MonthsAPIProps,
  Period,
} from "./CalendarViews/hooks/useMonthsAPI"

export type MonthsProps = {
  apiProps?: MonthsAPIProps
}

const F = contextAndProviderFactory({ hookApi: useMonthsAPI })

//  cosnt F.Context
const MonthsContext = F.Context
const MonthsProvider = F.Provider

const notSameMonth = (monthA: Month, monthB: Month) => {
  return monthA.index !== monthB.index || monthA.year !== monthB.year
}

export const Months = {
  Provider: MonthsProvider,
  Context: MonthsContext,
  Root: React.forwardRef(MonthsRoot),
  WeekHeader,
}

function MonthsRoot(props: {}, ref: ForwardedRef<RefAPI>) {
  const api = React.useContext(MonthsContext)

  const renderDay = React.useCallback(
    (month: Month) =>
      (function renderDay(
        p: {
          day: moment.Moment
          defaultComponent: React.ReactNode
        }
      ) {
        return (
          <MonthsContext.Consumer>
            {(api) => {
              const isNotSameMonth = notSameMonth(
                { index: p.day.month(), year: p.day.year() },
                month
              )

              return (
                <Day
                  period={api.interval}
                  day={p.day}
                  defaultComponent={p.defaultComponent}
                  TypographyProps={
                    // p.day.month() !== api.list.activeMonth.index
                    isNotSameMonth
                      ? { color: "textSecondary", style: { opacity: 0.3 } }
                      : { color: "textPrimary" }
                  }
                ></Day>
              )
            }}
          </MonthsContext.Consumer>
        )
      }),

    []
  )

  const c = useStyles()

  const render = React.useCallback<SnappedCarouselProps["children"]>((p) => {
    return (
      <MonthsContext.Consumer>
        {(api) => {
          const diff = Math.abs(p.index - api.list.activeMonth.index)

          if (diff > 5) return null

          const month = api.list.getMonth({ index: p.index })

          const WeekProps: WeekProps = {
            onClickCell: api.setDay,
            renderDay: renderDay(month),
          }

          return (
            <MonthEntity
              month={month}
              WeekProps={WeekProps}
              showOnlyDaysOfSelectedMonth={false}
              renderMonthHeader={false}
              showWeekHeader
            ></MonthEntity>
          )
        }}
      </MonthsContext.Consumer>
    )
  }, [])

  return (
    <MonthsContext.Provider value={api}>
      <div className={clsx("horizontal-months", c.root)}>
        <WeekHeader style={{ marginBottom: 4 }}></WeekHeader>
        <MonthsContext.Consumer>
          {(api) => {
            return (
              <SnappedCarousel
                activeIndex={api.list.activeMonth.index}
                setActiveIndex={api.list.activeMonth.setIndex}
                count={api.list.totalCount}
                children={render}
              ></SnappedCarousel>
            )
          }}
        </MonthsContext.Consumer>
      </div>
    </MonthsContext.Provider>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      // display: "flex",
      // flexDirection: "column",
      padding: 8,
      // paddingTop: 0,
    },
    datesFields: {
      marginTop: 16,
    },

    padding: {
      padding: 8,
    },

    header: {},
    weeks: {
      // borderBottom:
      //   "1px solid " + Color(theme.palette.text.secondary).alpha(0.12),
    },
  }
})
