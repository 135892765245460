import { Badge, BadgeProps } from "@material-ui/core"
import clsx from "clsx"
import COLORS from "constants/colors"
import { makeStyles } from "@material-ui/styles"

export type PurpleBadgeProps = BadgeProps

export default function PurpleBadge(props: PurpleBadgeProps) {
  const c = useStyles()
  return (
    <Badge
      {...props}
      classes={{
        ...c,
        ...(props.classes || {}),
        badge: clsx(c.badge, props?.classes?.badge),
      }}
    ></Badge>
  )
}

const useStyles = makeStyles(() => {
  return {
    badge: {
      backgroundColor: COLORS.red[1],
      color: "white",
    },
  }
})
