import clsx from "clsx"
import React from "react"
import DocumentFormContext from "components/OldDocumentForm/contexts/DocumentFormContext"
import { Button } from "components/Page/Page"

export default function PDFButton(props: { className?: string }) {
  const api = React.useContext(DocumentFormContext)

  const pdf = api.document?.pdf

  return (
    <Button
      isActive={api.pdfPage.isOpen}
      className={clsx("pdf-button", props.className)}
      invisible={!pdf}
      onClick={api.pdfPage.toggle}
      iconName="picture_as_pdf"
    ></Button>
  )
}
