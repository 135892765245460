import moment from "moment"
import React from "react"
import { Month, MonthCalendarProviderProps } from "../../types/index"
import MonthCalendarContext from "../../contexts/MonthCalendarContext"
import useMonthCalendarAPI from "../../hooks/useMonthCalendarAPI"

export default function MonthCalendarProvider(
  props: MonthCalendarProviderProps & { children: React.ReactNode }
) {
  const { children, ...providerProps } = props

  const api = useMonthCalendarAPI(providerProps)

  return (
    <MonthCalendarContext.Provider value={api}>
      {children}
    </MonthCalendarContext.Provider>
  )
}
