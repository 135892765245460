import { useApolloClient, gql } from "@apollo/client";
import { ID, RoomQueryVariables } from "types/type"
import React from "react"
import { RemoveKanbanCommentReactionDocument } from "lib/graphql/operations"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"

export default function useRemoveReaction(props: RoomQueryVariables) {
  const client = useApolloClient()

  const { user: loggedUser } = useCurrentWorkspace()

  return React.useCallback(
    (cardID: ID, commentID: ID) => {
      const comment = client.readFragment({
        id: `KanbanComment:${commentID}`,
        fragment: gql`
          fragment KanbanComment on KanbanComment {
            id
            reactions {
              emoji
              user {
                id
              }
            }
          }
        `,
      })

      const optimisticResponse = {
        __typename: "Mutation",
        removeKanbanCommentReaction: {
          __typename: "KanbanComment",
          id: comment?.id,
          reactions: (comment?.reactions || []).filter(
            (r: { user: { id: ID } }) => r && r.user?.id !== loggedUser?.id
          ),
        },
      }

      client.mutate<typeof optimisticResponse>({
        mutation: RemoveKanbanCommentReactionDocument,
        variables: {
          roomID: props.roomID,
          workspaceID: props.workspaceID,
          cardID,
          commentID,
        },
        optimisticResponse,
      })
    },
    [props.roomID, props.workspaceID, loggedUser?.id]
  )
}
