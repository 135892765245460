import { green, red, yellow } from "constants/colors"
import buildMap from "./buildMap"

const workStatuses = [
  { value: "abgelehnt", translation: "abgelehnt", color: red[0] },
  { value: "akzeptiert", translation: "akzeptiert", color: green[0] },
  { value: "angekommen", translation: "angekommen", color: yellow[0] },
  { value: "bezahlt", translation: "bezahlt", color: green[4] },
  { value: "eingegangen", translation: "eingegangen", color: yellow[4] },
  { value: "gebucht", translation: "gebucht", color: yellow[3] },
  { value: "gestartet", translation: "gestartet", color: yellow[1] },
  { value: "in Bearbeitung", translation: "in Bearbeitung", color: green[1] },
  { value: "überwiesen", translation: "überwiesen", color: green[3] },
  { value: "versendet", translation: "versendet", color: red[1] },
  {
    value: "Zahlungsvorschlag",
    translation: "Zahlungsvorschlag",
    color: red[2],
  },
]

export default workStatuses

export const workStatusesColors = buildMap("color", workStatuses)
export const workStatusTranslation = buildMap("translation", workStatuses)
