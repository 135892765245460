import { DataProxy } from "apollo-cache/lib/types/DataProxy"
import cachedIDGetters from "lib/getCacheID/getCacheID"
import { Typename } from "types/type"
import { FragmentMap } from "./fragmentMap"
import getFragmentParams from "./getFragmentParams"

export default function readFragment<
  Type extends Extract<keyof FragmentMap, Typename>,
  Return extends ReturnType<FragmentMap[Type]["updateFn"]>
>(
  props: {
    typename: Type
    cacheIDParams: Parameters<(typeof cachedIDGetters)[Type]>[0]
    fragmentParams?: ReturnType<typeof getFragmentParams>
  },
  dataProxy: DataProxy
): Return | null {
  const fragment =
    props.fragmentParams ||
    getFragmentParams({
      typename: props.typename,
      cacheIDParams: props.cacheIDParams,
    })

  try {
    const data = dataProxy.readFragment<Return>(fragment)
    return data
  } catch (e) {
    console.error(e)
    return null
  }
}
