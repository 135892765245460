import React from "react"
import moment from "moment"
import { CalendarEventInput, UpdateType } from "lib/graphql/types"
import { CalendarEventFragment } from "lib/graphql/operations"
import useCreateCalendarEvent from "hooks/useCreateCalendarEvent"
import useUpdateCalendarEvent from "hooks/useUpdateCalendarEvent"
import { ApiProps } from "../types"
import useForm from "../../../hooks/useForm"
import * as Yup from "yup"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function useEventForm(
  props: ApiProps & { event?: CalendarEventFragment | null }
) {
  const day = React.useMemo(() => {
    return moment(props?.date || new Date().toISOString())
  }, [props?.date])

  const initialValues = React.useMemo(() => {
    const evt = props.event

    const initial: CalendarEventInput = {
      title: evt?.title || "",
      startDateTime: evt?.startDateTime || day.startOf("day").toISOString(),
      endDateTime: evt?.endDateTime || day.endOf("day").toISOString(),
      description: evt?.description || "",
      members: (evt?.members || []).map((a) => ({
        updateType: UpdateType.Add,
        userID: a.id,
      })),
    }

    return initial
  }, [day, props.event])
  // console.log(initialValues)

  const { createCalendarEvent } = useCreateCalendarEvent()
  const { updateCalendarEvent } = useUpdateCalendarEvent({
    eventID: props?.eventID || "",
  })

  const submitEvent = React.useCallback(
    (e: CalendarEventInput | null | undefined) => {
      if (!e) return

      const create = () =>
        createCalendarEvent(e)?.then((a) => {
          const createdEvent = a.data?.createCalendarEvent
          if (!createdEvent || !props?.onCreateEvent) return

          props.onCreateEvent?.call(undefined, createdEvent)
        })

      const update = () =>
        Promise.resolve(updateCalendarEvent(e)).then((a) => {
          const updateResult = a?.data?.updateCalendarEvent

          if (!updateResult) return

          props?.onUpdateEvent?.call(undefined, updateResult)
        })

      return (props?.eventID ? update() : create()).catch((e) =>
        console.error(e)
      )
    },
    [
      createCalendarEvent,
      props?.onCreateEvent,
      props?.onUpdateEvent,
      updateCalendarEvent,
    ]
  )

  const onSubmit = React.useCallback(
    (e: { values: CalendarEventInput | null | undefined }) => {
      return submitEvent(e.values)
    },
    [submitEvent]
  )

  const onChange = React.useCallback(
    (e: { values: CalendarEventInput | null | undefined }) => {
      if (!e.values) return
      props.onChange?.call(undefined, e.values)
    },
    [props.onChange]
  )

  const t = useI18n()

  const v = useForm({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    oldOnChange: onChange,
    validationSchema: Yup.object({
      title: Yup.string().required(t.required),
    }),
  })

  return v
}
