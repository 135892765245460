import _ from "lodash"
import { Icon, IconButton, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { useContext } from "react"
import DateMetaCards from "components/DateMetaCards/DateMetaCards"
import RootContainer from "components/RootContainer/RootContainer"
import { useCallback } from "react"
import UserAvatar from "components/UserAvatar/UserAvatar"
import DetailsTabsContext from "../../../../../DocumentsDashboard/components/Details/components/DetailsTabs/contexts/DetailsTabsContext"

export default function TimeLog({
  created = undefined,
  createdBy = undefined,
  updated = undefined,
  updatedBy = undefined,
  className = "",
}) {
  const { goToRoom } = useContext(DetailsTabsContext)

  const c = useStyles()

  const renderAvatar = useCallback((user) => {
    if (!user) return null
    return (
      <UserAvatar name={user?.name} status={user?.status} type={user.type} />
    )
  }, [])

  const renderAuthorName = useCallback(
    (author) => {
      return ({}) => {
        if (!author) return null

        return (
          <span>
            {author?.name}
            <IconButton
              size="small"
              onClick={() => goToRoom({ userID: author?.id })}
            >
              <Icon style={{ fontSize: 14 }}>chat</Icon>
            </IconButton>
          </span>
        )
      }
    },
    [goToRoom]
  )

  return (
    <RootContainer.Default className={clsx("time-log", className)}>
      {({ sizeLabel }) => {
        return (
          <div
            className={clsx(
              (sizeLabel === RootContainer.SizeEnums.SMALL && c.stack) ||
                c.sideBySide
            )}
          >
            {created && (
              <DateMetaCards
                by={createdBy}
                avatar={renderAvatar(createdBy)}
                when={created}
                renderAuthorName={renderAuthorName(createdBy)}
                type="created"
              />
            )}
            {updated && (
              <DateMetaCards
                by={updatedBy}
                when={updated}
                type="updated"
                renderAuthorName={renderAuthorName(updatedBy)}
              />
            )}
          </div>
        )
      }}
    </RootContainer.Default>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    stack: {
      "&>*:not(:first-child)": {
        marginTop: theme.spacing(1),
      },
    },
    sideBySide: {
      display: "flex",
      justifyContent: "space-between",

      "&>*": {
        width: `calc(${100 / 2}% - ${theme.spacing(1 / 2)}px)`,
      },
    },
  }
})
