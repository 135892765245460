import { useMutation, gql } from "@apollo/client";

import useActiveResources from "hooks/useActiveResources"
import { DeleteMessageDocument, RoomFragment } from "lib/graphql/operations"
import _ from "lodash"
import { useCallback } from "react"
import useMemoAPI from "hooks/useMemoAPI"

export default function useDeleteMessage(props: { room: RoomFragment | null }) {
  const { workspaceID } = useActiveResources()
  const roomID = (props.room || {}).id

  const [mutate, { loading }] = useMutation(DeleteMessageDocument)

  const deleteMessage = useCallback(
    ({ messageID }) => {
      return mutate({
        variables: {
          workspaceID,
          roomID,
          messageID,
        },
        optimisticResponse: {
          __typename: "Mutation",
          deleteMessage: {
            __typename: "TextMessage",
            id: messageID,
            deletedAt: new Date().toISOString(),
            content: null,
          },
        },
      })
    },
    [mutate, roomID, workspaceID]
  )

  return useMemoAPI({ deleteMessage, isLoading: loading })
}
