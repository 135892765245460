import { useKeys } from "rooks"

export default function useEscKey(
  handler: (event: KeyboardEvent) => void,
  options?: {
    isDisabled?: boolean
  }
) {
  useKeys(
    ["Escape"],
    (e) => {
      e.preventDefault()
      handler?.call(null, e)
    },
    { when: !options?.isDisabled }
  )
}
