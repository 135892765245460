import useMemoAPI from "hooks/useMemoAPI"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import { Nullable } from "types/type"
import { useGridProps } from "./hooks/useGridProps"
// import { const } from '../../lib/getCacheID/getCacheID';

const getFetchingPoint = (props: any[]) => {
  return Math.max(props.length - 10, 0, props.length - 1)
}

export function useListApi(props: ListApiInput) {
  const gridProps = useGridProps({
    items: props.items || [],
    ...(props.variant === "list" ? { numOfColumns: 1 } : {}),
  })

  const fetchingPoint = getFetchingPoint(gridProps.lines)

  const rootContainer = useRootContainerAPI()

  const estimatedColumnWidth = (() => {
    const w = rootContainer.width

    const constant = 19 //experimental

    return w / gridProps.numOfColumns - constant
  })()

  return useMemoAPI({
    ...props,
    totalCount: props.totalCount || props.items?.length || 0,
    fetchingPoint,
    rootContainer,
    gridProps,
    estimatedColumnWidth,
  })
}

export type VariantProps<Data = any> = { variant?: "list" } | GridProps<Data>

export type ListVariants = Exclude<VariantProps["variant"], undefined>

export type ListApiInput<T extends any = any> = {
  itemKey?: (index: number, item: T) => string
  items?: Exclude<Nullable<T[]>, false>
  fetchMore?: () => any
  totalCount?: number
} & VariantProps<T>

export type GridProps<Data> = {
  variant: "grid"
  getCellKey?: (props: {
    item: Data | null | undefined
    column: number
    row: number
    index: number
  }) => string
}
