import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React, { useEffect } from "react"
import { UploadDocumentsContext } from "../ContextAndProvider"
import { TwoColumns } from "../TwoColumns/TwoColumns"
import UploadedGrid from "../UploadedGrid/UploadedGrid"

export function Body({
  enableFileSelection,
  className,
}: {
  enableFileSelection?: boolean
  className?: string
}) {
  const api = React.useContext(UploadDocumentsContext)

  const amount = (api.uploadedFiles || []).length

  const FirstColumn = api.stepsController.activeStep.FirstColumn

  const { goToStepIndex } = api.stepsController

  useEffect(() => {
    amount === 0 && goToStepIndex(0)
  }, [amount])

  const c = useStyles({})

  return (
    <TwoColumns
      className={clsx(c.body, className)}
      firstColumn={<FirstColumn />}
      secondColumn={
        !!amount && (
          <div className={c.scrollbars}>
            <UploadedGrid
              enableSelection={!!(enableFileSelection && amount > 1)}
              className={c.uploadedGrid}
            />
          </div>
        )
      }
    ></TwoColumns>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    scrollbars: {
      height: "100%",
      overflow: "scroll",
    },
    uploadedGrid: {
      maxWidth: "100%",
      margin: 0,
      // marginTop: theme.spacing(-1),
      padding: 12,
    },
    body: {
      // height:
      "&>*": {
        maxWidth: "100%",
      },
      // height: "100%",
    },
  }
})
