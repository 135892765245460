import _ from "lodash"
import React from "react"
import PaperList from "components/PaperList"
import MessageListItem from "./components/MessageListItem/MessageListItem"
import SearchMessagesContext from "../../contexts/SearchMessagesContext"

export default function List() {
  const { searchResult } = React.useContext(SearchMessagesContext)

  return (
    <PaperList.Provider items={searchResult.list} ListItem={MessageListItem}>
      <PaperList.OldRoot></PaperList.OldRoot>
    </PaperList.Provider>
  )
}
