import { IconButton } from "@material-ui/core"
import clsx from "clsx"
import { IconProps, Icon } from "components/Icon/Icon"
import { StyledProps } from "types/type"

export function HeaderIconButton(
  props: { name: IconProps["name"]; onClick: () => void } & StyledProps
) {
  return (
    <IconButton
      size="small"
      onClick={props.onClick}
      style={props.style}
      className={clsx(props.className, "header-icon-button")}
    >
      <Icon name={props.name}></Icon>
    </IconButton>
  )
}
