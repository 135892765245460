// import { MonthsAPI, Period } from "../CalendarViews/hooks/useMonthsAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import useMemoAPI from "hooks/useMemoAPI"
import { MonthsAPI, MonthsAPIProps } from "./components/Months"
import { Period } from "types/type"
import useOpenClose from "hooks/useOpenClose"

function useDateIntervalApi(
  props: DateIntervalSelectorProps & {
    monthsApi: MonthsAPI
  }
) {
  const modal = useOpenClose()
  return useMemoAPI({ ...props, modal })
}

export type DateIntervalApi = ReturnType<typeof useDateIntervalApi>

export type DateIntervalSelectorProps = {
  MonthsProps?: MonthsAPIProps
}

const F = contextAndProviderFactory({
  hookApi: useDateIntervalApi,
})
export const DateIntervalApiProvider = F.Provider
export const DateIntervalApiContext = F.Context
