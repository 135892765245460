import contextAndProviderFactory from "lib/contextAndProviderFactory"
import useRoomPageBoardItemApi from "../hooks/useRoomPageBoardItemAPI"

const F = contextAndProviderFactory({
  hookApi: useRoomPageBoardItemApi,
  contextName: "RoomPageBoardItemApiContext",
})

export const RoomPageBoardItemApiProvider = F.Provider
export const RoomPageBoardItemApiContext = F.Context

export const RoomPageBoardItemApi = {
  Provider: RoomPageBoardItemApiProvider,
  Context: RoomPageBoardItemApiContext,
}

/** @deprecated*/
export default RoomPageBoardItemApiContext
