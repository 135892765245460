import { ItemParams } from "components/PageBoard/components/IndexedItems/types"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  RelatedPageBoardItemsQueryVariables,
  useRelatedPageBoardItemsQuery,
} from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import { useLiveQuery, LiveQueryInput } from "../../../hooks/useLiveQuery"
import { RelatedPageBoardItemsSubscriptionDocument } from "../../../lib/graphql/operations"

export function useRelatedPageBoardItems(props: {
  itemParams?: ItemParams | null
}) {
  const workspaceID = useActiveResources().workspaceID
  const params = props.itemParams

  const variables: RelatedPageBoardItemsQueryVariables = useMemoAPI({
    workspaceID,
    itemID: params?.itemID || "",
    type: params?.type || PageBoardItemType.Room,
  })

  const input: LiveQueryInput<typeof useRelatedPageBoardItemsQuery> = {
    useQuery: useRelatedPageBoardItemsQuery,
    subscriptionDocument: RelatedPageBoardItemsSubscriptionDocument,
    options: useMemoAPI({
      fetchPolicy: "cache-and-network",
      variables,
      skip: Object.values(variables).some((a) => !a),
    }),
  }

  const related = useLiveQuery(input).data?.relatedPageBoardItems || []

  return useMemoAPI({ related })
}
