import { makeStyles } from "@material-ui/core"
import React from "react"
import {
  EnhancedPageProps,
  EnhancedPage as BasicSecondPage,
} from "components/EnhancedPage/EnhancedPage"

export default function SecondPage(props: EnhancedPageProps) {
  const c = useSecondPageStyles()

  return (
    <BasicSecondPage {...props} classes={{ body: c.body }}></BasicSecondPage>
  )
}
const useSecondPageStyles = makeStyles((theme) => {
  return {
    body: {
      padding: theme.spacing(2.5),
      paddingTop: 32,
      height: "100%",
      justifyContent: "center",
    },
  }
})
