import React, { useState, useImperativeHandle, useContext } from "react"

export const LoadingProvider = React.forwardRef(Provider)

export function useLoadingContext() {
  return useContext(LoadingContext)
}

export const LoadingContext = React.createContext([])

function Provider({ children }, ref) {
  const api = useState(false)

  useImperativeHandle(ref, () => api)

  return (
    <LoadingContext.Provider value={api}>{children}</LoadingContext.Provider>
  )
}
