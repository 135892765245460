import { ArticleDetailPanelBody } from "./ArticleDetailPanelBody"
import {
  ArticleDetailPanelInput,
  ArticleDetailPanelProvider,
} from "../ArticleDetailPanelContext"

export function ArticleDetailPanelDefault(props: ArticleDetailPanelInput) {
  return (
    <ArticleDetailPanelProvider {...props}>
      <ArticleDetailPanelBody></ArticleDetailPanelBody>
    </ArticleDetailPanelProvider>
  )
}
