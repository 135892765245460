import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import { LiveQueryInput, useLiveQuery } from "hooks/useLiveQuery"
import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import {
  useWorkspacesAlertsCountQuery,
  WorkspacesAlertsCountSubscriptionDocument,
} from "lib/graphql/operations"
import React from "react"

export const NotificationsAPI = contextAndProviderFactory({
  hookApi: useNotifications,
})

type Input = LiveQueryInput<typeof useWorkspacesAlertsCountQuery>

const arrayOptions: Input["arrayOptions"] = {
  unicityFn(a) {
    return a.id
  },
}

const options: Input["options"] = {
  variables: {},
  fetchPolicy: "cache-and-network",
}

function useNotifications() {
  const queryInput: Input = React.useMemo(() => {
    const queryInput: Input = {
      useQuery: useWorkspacesAlertsCountQuery,
      refetchQueryOnSubscriptionData: true,
      options: options,
      subscriptionDocument: WorkspacesAlertsCountSubscriptionDocument,
      arrayOptions: arrayOptions,
    }

    return queryInput
  }, [])

  const query = useLiveQuery(queryInput)

  // console.log(
  //   query.data?.["workspacesAlertsCount"]?.find((a) => a.id === "account.457"),
  //   "tango"
  // )
  // console.log(query.data?.workspacesAlertsCount)

  const notifications = React.useMemo(() => {
    return query.data?.workspacesAlertsCount || []
  }, [query.data?.workspacesAlertsCount])

  const getWorkspaceNotifications = React.useCallback(
    (props: { workspaceID: string }) => {
      return notifications.find((w) => w.id === props.workspaceID)
    },
    [notifications]
  )
  const sumCount = React.useMemo(
    () => notifications.reduce((acc, curr) => acc + curr.total, 0),
    [notifications]
  )

  const workspace = useCurrentWorkspace().workspace

  const getWorkspaceCount = React.useCallback(
    (props: { workspaceID: string }) => {
      return (
        getWorkspaceNotifications({ workspaceID: props.workspaceID || "" })
          ?.total || 0
      )
    },
    [getWorkspaceNotifications]
  )

  const sumCountThisWorkspace = React.useMemo(() => {
    return (
      getWorkspaceNotifications({ workspaceID: workspace?.id || "" })?.total ||
      0
    )
  }, [getWorkspaceNotifications, workspace])

  const sumCountOtherThanCurrentWorkspace = React.useMemo(() => {
    return Math.max(sumCount - sumCountThisWorkspace, 0)
  }, [sumCount, sumCountThisWorkspace])

  return useMemoAPI({
    notifications,
    sumCount,
    sumCountOtherThanCurrentWorkspace,
    sumCountThisWorkspace,
    getWorkspaceCount,
  })
}
