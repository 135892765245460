import React from "react"
import Transition from "react-transition-group/Transition"

export type CycleStatus = "exited" | "initial" | "final"

export default function AnimationStatus(props: {
  active: boolean
  children: (status: CycleStatus) => React.ReactChild
  durationInMs?: number
}) {
  const { active, children, durationInMs } = props

  return (
    <Transition
      in={active}
      // timeout={active ? 0 : durationInMs}
      timeout={
        typeof props.durationInMs === "undefined" ? 500 : props.durationInMs
      }
    >
      {(state) => {
        const status = (function getStatus(): CycleStatus {
          if (state === "entering") return "initial"
          if (state === "entered") return "final"
          if (state === "exiting") return "initial"
          return "exited"
        })()

        return <>{children(status)}</>
      }}
    </Transition>
  )
}
