import { DataProxy } from "apollo-cache"
import _ from "lodash"
import {
  NewPageBoardItemFragment,
  PageBoardItemsQuery,
} from "lib/graphql/operations"
import updateQuery from "lib/updateQuery/updateQuery"
import sortItems from "./sortItems"
import { UpdateType } from "./graphql/types"

export default function updatePageBoardItemsQueryCache(
  props: {
    item?: NewPageBoardItemFragment | null
    workspaceID: string
    updateType?: UpdateType
    updateFn?: (a: PageBoardItemsQuery) => PageBoardItemsQuery
  },
  client: DataProxy
) {
  const updateType = props.updateType || UpdateType.Add

  return updateQuery(
    {
      queryName: "pageBoardItems",
      variables: { workspaceID: props.workspaceID },
      updateFn(a) {
        if (props.updateFn) return props.updateFn(a)

        const items = (() => {
          const old = a?.pageBoardItems?.items || []

          if (!props.item) return old

          const i = [props.item, ...old]

          const v = _.uniqBy(i, "id")

          if (updateType === UpdateType.Add) return v

          const filtered = v.filter((a) => a?.id !== props.item?.id)

          return filtered
        })()

        const query: PageBoardItemsQuery = {
          __typename: "Query",
          pageBoardItems: {
            __typename: "PageBoardItemsResponse",
            items,
            pagination: a?.pageBoardItems?.pagination || null,
          },
        }

        return query
      },
    },
    client
  )
}
