import DocumentsDashboardContext from "../../contexts/DocumentsDashboardContext"
import useDocumentsDashboardProviderValue, {
  DocumentsDashboardProps as DocumentsDashboardProviderProps,
} from "../../hooks/useDocumentsDashboardProviderValue"

export default function DocumentsDashboardProvider(
  props: DocumentsDashboardProviderProps & { children: React.ReactNode }
) {
  const value = useDocumentsDashboardProviderValue(props)

  return (
    <DocumentsDashboardContext.Provider value={value}>
      {props.children}
    </DocumentsDashboardContext.Provider>
  )
}
