import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import FrameWithLoadingProgress from "components/FrameWithLoadingProgress/FrameWithLoadingProgress"
import { DocumentFormPage } from "./components/DocumentFormPage/DocumentFormPage"
import {
  DocumentPageBoardItemContext,
  DocumentPageBoardItemProps,
  DocumentPageBoardItemProvider,
} from "./DocumentPageBoardItemContext"
import { PdfPage } from "./components/PdfPage/PdfPage"

export type { DocumentPageBoardItemProps } from "./DocumentPageBoardItemContext"

export function DocumentPageBoardItem(props: DocumentPageBoardItemProps) {
  const c = useStyles({})

  return (
    <DocumentPageBoardItemProvider {...props}>
      <DocumentPageBoardItemContext.Consumer>
        {({ document, query, initialPage }) => {
          return (
            <FrameWithLoadingProgress
              isLoading={!document && query.loading}
              className={clsx(c.root, "document-page-board-item")}
            >
              <div className="inner-content">
                {initialPage === "viewer" ? (
                  <PdfPage onClose={props.onClose} showInfoButton></PdfPage>
                ) : (
                  <DocumentFormPage
                    onClose={props.onClose}
                    pdfIsInTheNextPage
                  ></DocumentFormPage>
                )}
              </div>
            </FrameWithLoadingProgress>
          )
        }}
      </DocumentPageBoardItemContext.Consumer>
    </DocumentPageBoardItemProvider>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "&,& .inner-content": {
        height: "100%",
      },
    },
  }
})
