import React from "react"
import useKeyListenersAPI from "../hooks/useKeyListenersAPI"

const KeyListenersContext = React.createContext(
  {} as ReturnType<typeof useKeyListenersAPI>
)

KeyListenersContext.displayName = "KeyListenersContext"

export default KeyListenersContext
