import { Icon, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import PopoverWrapper from "components/PopoverWrapper/PopoverWrapper"
import StatusColorsExplanation from "./components/StatusColorExplantion/StatusColorsExplanation"

export interface EntryStatusProps {
  showColorExplanation?: boolean
  showColorDot?: boolean
  className?: string
  statusValue?: string
  statusTranslated: any
  statusColor: string
  colorMap: any
  translationMap: any
}

export default function EntryStatus(props: EntryStatusProps) {
  const {
    showColorExplanation = false,
    showColorDot = true,
    className = "",
    statusValue,
    statusTranslated,
    statusColor,
    colorMap,
    translationMap,
  } = props
  const c = useStyles()

  if (statusValue === "FINISHED") return null

  if (!statusTranslated) return null

  const components = [
    <span>{statusTranslated}</span>,
    showColorDot && statusColor && (
      <div className="status-dot" style={{ background: statusColor }} />
    ),
    showColorExplanation && statusColor && (
      <PopoverWrapper
        popover={
          <StatusColorsExplanation
            statusValue={statusValue}
            colorMap={colorMap}
            translationMap={translationMap}
          ></StatusColorsExplanation>
        }
      >
        <Icon style={{ fontSize: 15 }} className="color-explanation-icon">
          help_outline
        </Icon>
      </PopoverWrapper>
    ),
  ]

  return <div className={clsx(c.root, className)}>{components}</div>
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      "&>*": {
        "&:not(:last-child)": {
          marginRight: theme.spacing(0.5),
        },
        display: "block",
      },

      "& .status-dot": {
        position: "relative",
        top: -0.3,
        borderRadius: "50%",
        width: 11,
        height: 11,
      },

      "& .color-explanation-icon": {
        display: "flex",
      },
    },
  }
})
