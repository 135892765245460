import {
  Icon,
  IconButton,
  IconButtonProps,
  makeStyles,
} from "@material-ui/core"
import printJS from "print-js"
import React from "react"
import ToggleableMenu from "../../../ToggleableMenu/ToggleableMenu"
import PdfContext from "../../contexts/PdfContext"

export default function PdfControls(props: {
  IconButtonProps?: IconButtonProps
  renderIcons?: (props: {
    components: {
      zoomIn: React.ReactNode
      zoomOut: React.ReactNode
      rotateLeft: React.ReactNode
      rotateRight: React.ReactNode
      resetTransformation: React.ReactNode
    }
  }) => React.ReactNode
}) {
  const ctx = React.useContext(PdfContext)

  const c = useStyles()

  const render = (b: IconButtonProps<"button", {}>) => {
    return (
      <IconButton {...b} {...props.IconButtonProps}>
        {b.children}
      </IconButton>
    )
  }

  const zoomIn = render({
    onClick: () => ctx.zoomIn(),
    children: <Icon>zoom_in</Icon>,
  })

  const zoomOut = render({
    onClick: () => ctx.zoomOut(),
    children: <Icon>zoom_out</Icon>,
  })

  const rotateLeft = render({
    onClick: () => ctx.rotateLeft(),
    children: <Icon>rotate_left</Icon>,
  })

  const rotateRight = render({
    onClick: () => ctx.rotateRight(),
    children: <Icon>rotate_right</Icon>,
  })
  const resetTransformation = render({
    onClick: () => ctx.resetTransformation(),
    children: <Icon>zoom_out_map</Icon>,
  })

  const iconButtonProps: IconButtonProps[] = [
    {
      onClick: () => ctx.zoomIn(),
      children: <Icon>zoom_in</Icon>,
    },
    {
      onClick: () => ctx.zoomOut(),
      children: <Icon>zoom_out</Icon>,
    },
    {
      onClick: () => ctx.rotateLeft(),
      children: <Icon>rotate_left</Icon>,
    },

    {
      onClick: () => ctx.rotateRight(),
      children: <Icon>rotate_right</Icon>,
    },
    {
      onClick: () => ctx.resetTransformation(),
      children: <Icon>zoom_out_map</Icon>,
    },
  ]

  const formRef = React.useRef<HTMLFormElement | null>(null)

  return (
    <React.Fragment>
      {(() => {
        if (!props.renderIcons)
          return iconButtonProps.map((b, index) => {
            return <React.Fragment key={index}>{render(b)}</React.Fragment>
          })

        return props.renderIcons?.({
          components: {
            zoomIn,
            zoomOut,
            rotateLeft,
            rotateRight,
            resetTransformation,
          },
        })
      })()}

      <form
        className={c.form}
        ref={formRef}
        method="get"
        action={ctx.url}
        target="_blank"
      ></form>

      <ToggleableMenu
        className={c.toggleableMenu}
        IconButtonProps={props.IconButtonProps}
        menuItems={[
          { component: "print", onClick: () => printJS(ctx.url) },
          { component: "download", onClick: () => formRef.current?.submit() },
        ]}
        icon={<Icon>more_vert</Icon>}
      ></ToggleableMenu>
    </React.Fragment>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    form: {
      display: "none",
    },
    toggleableMenu: {
      display: "inline-block",
    },
  }
})
