import React, { useRef, ReactChild } from "react"
import ReactDOM from "react-dom"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import { useEffect } from "react"
import useIsMounted from "hooks/useIsMounted"
import { useImperativeHandle } from "react"
import AnimationStatus from "components/AnimationStatus/AnimationStatus"
import { white } from "constants/colors"

export type FullScreeModalAPI = { getZIndex(): number }

export default React.forwardRef(FullScreenModal)

const transitionInMs = 300

function FullScreenModal(
  props: {
    children:
      | React.ReactNode
      | ((props: { setIsOpen(bool: boolean): any }) => React.ReactNode)
    zIndex?: number
    isOpen?: boolean
    className?: string
    anchor?: Element | null
    onClose?: () => any
  },
  externalRef: React.Ref<FullScreeModalAPI>
) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const isMounted = useIsMounted()

  React.useEffect(() => {
    if (!isMounted) return
    setIsOpen(isOpen)
  }, [isOpen])

  React.useEffect(() => {
    if (!isOpen) return
    setTimeout(() => {
      setIsOpen(true)
    }, 300)
  }, [])

  const container =
    props.anchor || (document.querySelector("body") as HTMLElement)

  const zIndex = props.zIndex || 1

  const c = useStyle({
    containerWidth: container.clientWidth,
    containerHeight: container.clientHeight,
  })

  useEffect(() => {
    const additionalClassNames = [c.container, "full-screen-modal-container"]
    container.classList.add(...additionalClassNames)
  }, [container])

  const timeoutID = useRef<ReturnType<typeof setTimeout>>()

  useImperativeHandle(
    externalRef,
    () => {
      return {
        getZIndex() {
          return zIndex
        },
      }
    },
    [zIndex]
  )

  useEffect(() => {
    if (!isMounted) return
    if (typeof props.onClose !== "function") return
    if (isOpen) return clearTimeout(timeoutID.current as number | undefined)
    timeoutID.current = setTimeout(props.onClose, transitionInMs + 10)
  }, [isOpen])

  return ReactDOM.createPortal(
    <AnimationStatus
      active={props.isOpen || false}
      durationInMs={transitionInMs}
    >
      {(state) => {
        return (
          <div
            className={clsx(props.className, c.root, "full-screen-modal")}
            style={{
              // background: "#f5f5f5",
              // background: "white",
              ...(state === "exited" ? { display: "none" } : {}),
              transition: `${transitionInMs}ms transform`,
              ...(state === "initial" ? { transform: "translateY(100%)" } : {}),
              ...(state === "final" ? { transform: "translateY(0)" } : {}),
            }}
          >
            <div className={c.children}>
              {typeof props.children === "function"
                ? props.children({ setIsOpen })
                : props.children}
            </div>
          </div>
        )
      }}
    </AnimationStatus>,
    container
  )
}

const useStyle = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  root: {
    background: white[1],
    zIndex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: (props: { containerWidth: number; containerHeight: number }) =>
      props.containerWidth,
    height: (props: { containerWidth: number; containerHeight: number }) =>
      props.containerHeight,
  },
  children: {
    position: "relative",
    overflow: "auto",
    height: "100%",
  },
}))
