import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import isProduction from "lib/isProduction"
import useStateController from "hooks/useStateController"

const possible = ["list", "cards"] as const

export type ViewID = (typeof possible)[number]

export type ViewProps = {
  selectedID?: ViewID
  setSelectedID?: (id: ViewID) => void
}

export default function useViews(props?: ViewProps) {
  const [selectedID, setSelectedID] = useStateController({
    onChange(value: ViewID) {
      props?.setSelectedID?.(value)
    },
    value: props?.selectedID ?? (isProduction ? "list" : "cards"),
  })

  return useMemoAPI({ possible, selectedID, setSelectedID })
}
