import useActiveResources from "hooks/useActiveResources"
import { usePageBoardVisitationHistoryQuery } from "lib/graphql/operations"

export function useHistoryRegistry() {
  const r = useActiveResources()

  const historyQuery = usePageBoardVisitationHistoryQuery({
    variables: {
      workspaceID: r.workspaceID,
    },
    fetchPolicy: "cache-and-network",
  })
  return { historyQuery }
}
