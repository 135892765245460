import clsx from "clsx"
import { Button } from "components/Button/Button"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  MessageFragment,
  useTriggerMessageActionMutation,
} from "lib/graphql/operations"
import React from "react"
import { StyledProps } from "types/type"

export function MessageActions(
  props: { message: MessageFragment } & StyledProps
) {
  const { message } = props

  const { triggerAction, loading } = useTriggerMutation({
    messageID: message.id,
  })

  return (
    <div
      className={clsx("message-actions", props.className)}
      style={{ ...props.style }}
    >
      {/** Temporary configuration until we have more actions to show.
       * By now only compare-product actions is available */}
      {message.actions?.length ? (
        <Button
          variant="outlined"
          disabled={!!loading}
          onClick={() =>
            triggerAction({ actionID: message?.actions?.[0].id || "" })
          }
        >
          vergleichen
        </Button>
      ) : null}
    </div>
  )
}

function useTriggerMutation(props: { messageID: string }) {
  const [mutation, { loading }] = useTriggerMessageActionMutation()

  const r = useActiveResources()

  const workspaceID = r.workspaceID

  const triggerAction = React.useCallback(
    (args: { actionID: string }) => {
      if (!workspaceID || !args.actionID) return

      mutation({
        variables: {
          messageID: props.messageID,
          workspaceID,
          actionID: args.actionID,
        },
      })
    },
    [mutation, workspaceID, props.messageID]
  )

  return useMemoAPI({ triggerAction, loading })
}
