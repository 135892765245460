import React, { useState } from "react"
import SuggestionsWrapper from "components/SuggestionsWrapper/SuggestionsWrapper"
import {
  TextField,
  Paper,
  makeStyles,
  useTheme,
  TextFieldProps,
} from "@material-ui/core"
import IconButton from "components/IconButton/IconButton"
import clsx from "clsx"
import { Variant as TypographyVariant } from "@material-ui/core/styles/createTypography"
// import SelectWithAutocomplete from 'components/SelectWithAutocomplete/SelectWithAutocomplete';
import useTypographyClass from "../../hooks/useTypographyClass"
import useIsMounted from "hooks/useIsMounted"
import { Option } from "./types/types"
import useAutoCompleteSuggestions, {
  Config,
} from "./hooks/useAutoCompleteSuggestions"

export { default as useAutoCompleteSuggestions } from "./hooks/useAutoCompleteSuggestions"

export type SelectAPI = { resetTextField: () => void }

export default React.forwardRef(SelectWithAutocomplete)

export type SelectWithAutoCompleteProps<T extends Option = Option> = {
  onlyOptionsMatchingText?: Config["onlyOptionsMatchingText"]
  options: T[]
  onSelect: (item: T) => void
  textField?: string
  label?: string
  TextField?: React.FC<TextFieldProps>
  typographyVariant?: TypographyVariant
  renderInput?: (props: TextFieldProps) => React.ReactNode
  placeholder?: string
  onChangeTextField?: (value: string) => void
  focusOnMount?: boolean
  TextFieldProps?: TextFieldProps
  renderItem?: (props: { item: T }) => React.ReactNode
  className?: string
  classes?: {
    suggestionsPaper?: string
    suggestionsItem?: string
    textField?: string
  }
}

function SelectWithAutocomplete<T extends Option = Option>(
  props: SelectWithAutoCompleteProps<T>,
  ref: React.Ref<SelectAPI>
) {
  const { state, dispatch } = useAutoCompleteSuggestions(
    {
      options: props.options,
      textField: props.textField,
    },
    React.useMemo(
      () => ({
        onlyOptionsMatchingText: props.onlyOptionsMatchingText,
      }),
      [props.onlyOptionsMatchingText]
    )
  )

  const inputRef = React.useRef<HTMLInputElement | null>(null)

  const isMounted = useIsMounted()

  React.useEffect(() => {
    if (!isMounted) return
    return inputRef.current?.focus()
  }, [isMounted])

  React.useEffect(() => {
    dispatch({ type: "newOptions", payload: { options: props.options } })
  }, [props.options])

  // useEffect(
  //   () => dispatch({ type: "newOptions", payload: { options: props.options } }),
  //   [props.options]
  // )

  // useEffect(
  //   () => dispatch({ type: "changeText", payload: { text: props.textField } }),
  //   [props.textField]
  // )

  React.useImperativeHandle(
    ref,
    () => {
      return {
        resetTextField: () =>
          dispatch({ type: "changeText", payload: { text: "" } }),
      }
    },
    [dispatch]
  )

  const { divHeight, setDivRef } = useDivHeight()

  const typography = useTypographyClass()
  const c = useStyles({ inputHeight: divHeight })

  if (!state.options) return null

  return (
    <SuggestionsWrapper
      items={state.options}
      renderItem={props.renderItem || (({ item: { label } }) => label)}
      className={clsx("selection-with-auto-complete", props.className)}
      onSelect={(selected) => {
        dispatch({ type: "select", payload: { selected } })

        if (typeof props.onSelect !== "function") return

        props.onSelect(selected)
      }}
      isOpen={!!state.isVisible}
      {...(props.classes
        ? { classes: { suggestionsItem: props.classes.suggestionsItem || "" } }
        : {})}
      onClose={() => {
        dispatch({ type: "hideSuggestions" })
      }}
    >
      {({ getInputProps, suggestionsComponent }) => {
        return (
          <div className={c.inputWithSuggestions} ref={setDivRef}>
            {(() => {
              const p: TextFieldProps = {
                label: props.label,
                ...(props.TextFieldProps || {}),
                // variant: props.typographyVariant,
                className: props.classes?.textField,

                InputProps: {
                  placeholder: props.placeholder || "",
                  // endAdornment: _.get(state, "options.length") ? (
                  // variant

                  // style: props.typographyVariant
                  //   ? theme.typography[props.typographyVariant]

                  //   : {},

                  // style: { marginTop: 0 },
                  endAdornment: state?.options?.length ? (
                    <IconButton
                      iconName="arrow_drop_down"
                      onClick={() => {
                        dispatch({ type: "toggleVisibilityAllOptions" })
                      }}
                      size="small"
                      className={c.endAdornment}
                      disableRipple
                    />
                  ) : null,
                  ...getInputProps({
                    ...(props.TextFieldProps?.InputProps || {}),
                    value: state.text || "",
                    onChange: (e) => {
                      const value = e.target.value
                      dispatch({ type: "type", payload: { text: value } })

                      if (typeof props.onChangeTextField === "function")
                        props.onChangeTextField(value)
                      if (
                        typeof props.TextFieldProps?.onChange === "function"
                      ) {
                        props.TextFieldProps.onChange(e)
                      }
                    },

                    className: clsx(
                      props.typographyVariant &&
                        typography[props.typographyVariant],
                      c.Input,
                      // props.classes?.textField
                      (props.TextFieldProps?.InputProps || {}).className
                    ),
                    ref: inputRef,
                    onBlur: () => {
                      dispatch({ type: "hideSuggestions" })
                    },
                    onFocus: () => {
                      dispatch({ type: "startTyping" })
                    },
                  }),
                  // {}
                },
              }

              return props.renderInput ? (
                props.renderInput(p)
              ) : props.TextField ? (
                <props.TextField {...p}></props.TextField>
              ) : (
                <TextField {...p}></TextField>
              )
            })()}
            <div className={c.suggestions}>
              <Paper
                className={clsx(
                  c.suggestionsPaper,
                  props.classes?.suggestionsPaper
                )}
              >
                {suggestionsComponent}
              </Paper>
            </div>
          </div>
        )
      }}
    </SuggestionsWrapper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    Input: {
      width: "100%",
    },
    endAdornment: {
      padding: 0,
    },
    suggestionsPaper: {
      overflowY: "auto",
    },
    inputWithSuggestions: {
      position: "relative",
    },
    suggestions: {
      position: "absolute",
      top: (props: { inputHeight: number }) => props.inputHeight,
      zIndex: 1,
      width: "100%",
    },
  }
})

function useDivHeight() {
  const [divRef, setDivRef] = useState<HTMLDivElement | null>(null)
  const divHeight = divRef ? divRef.getBoundingClientRect().height : 0

  return { divHeight: divHeight, setDivRef: setDivRef }
}
