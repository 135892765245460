import { TextFieldProps } from "@material-ui/core"
import SelectWithAutocomplete from "components/SelectWithAutocomplete/SelectWithAutocomplete"
import { FieldInputProps } from "hooks/useForm"
import React, { useCallback } from "react"
import { SelectWithAutoCompleteProps } from "../../../SelectWithAutocomplete/SelectWithAutocomplete"

export type AutoCompleteOption = { value: string; label: string }

export type AutoCompleteProps = {
  options: AutoCompleteOption[]
  renderInput?: (params: TextFieldProps) => React.ReactNode
  renderSuggestionsItem?: (suggestion: React.ReactNode) => React.ReactNode
  TextFieldProps?: TextFieldProps
  onlyOptionsMatchingText?: SelectWithAutoCompleteProps["onlyOptionsMatchingText"]
} & FieldInputProps<string>

export default function AutoComplete(props: AutoCompleteProps) {
  const onChange = useCallback(
    (v?: { value?: string; label: string }) => {
      if (!v) return
      props.onChange?.({
        target: { name: props.name || "", value: v.value || "" },
      })
    },
    [props.onChange, props.name, props.value]
  )

  return (
    <SelectWithAutocomplete
      options={props.options}
      onlyOptionsMatchingText={props.onlyOptionsMatchingText}
      textField={
        props.options.find((a) => a.value === props.value)?.label || ""
      }
      TextFieldProps={props.TextFieldProps}
      onSelect={onChange}
      renderInput={props.renderInput}
      {...(props.renderSuggestionsItem
        ? {
            renderItem: (a) =>
              props.renderSuggestionsItem?.call(undefined, a.item.label) ||
              a.item.label,
          }
        : {})}
    ></SelectWithAutocomplete>
  )
}
