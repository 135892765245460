import { makeStyles, Paper } from "@material-ui/core"
import IconButton from "components/IconButton/IconButton"
// import MessagePreview from "components/Room/components/MessagePreview/TextMessagePreview"
import { useFooterContext } from "../../contexts/useFooterContext"
import { getPreview } from "../../../../lib/getPreview"

export function FooterMessagePreview() {
  const c = useStyles({})

  const { selectedMessage, discardMessageSelection, mode } = useFooterContext()

  const messageID = (selectedMessage || {}).id

  if (!messageID) return null

  if (!selectedMessage) return null

  const preview =
    getPreview({ message: selectedMessage })?.[selectedMessage.__typename] ||
    null

  if (!preview) return null

  return (
    <Paper className={c.footerMessagePreview} square>
      <div className={c.previewWrapper}>{preview}</div>
      {typeof discardMessageSelection === "function" && (
        <IconButton
          iconName="close"
          size="small"
          edge="end"
          onClick={discardMessageSelection}
        />
      )}
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    previewWrapper: {
      maxWidth: "90%",
    },
    footerMessagePreview: {
      padding: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    closeButton: {
      paddingLeft: theme.spacing(1),
    },
  }
})
