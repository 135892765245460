import { IconButton } from "@material-ui/core"
import { Button } from "components/Button/Button"
import { Icon } from "components/Icon/Icon"
import React from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { FormCommonsContext } from "./components/FormCommonsContext"

export function ToggleDeletionButton() {
  const ctx = React.useContext(FormCommonsContext)
  const toggleFn = ctx.deletion?.toggleDeletion

  const t = useI18n()

  const bt = (() => {
    if (ctx.deletion?.isDeleted) {
      return (
        <Button onClick={toggleFn} disableElevation variant="outlined">
          <Icon name="restore_from_trash"></Icon> {t.restore}
        </Button>
      )
    }

    return (
      <IconButton onClick={toggleFn} size="small">
        <Icon name="delete_outline"></Icon>
      </IconButton>
    )
  })()

  return bt
}
