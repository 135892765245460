import { NewPageBoardItemFragment } from "lib/graphql/operations"
import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "../../../../lib/contextAndProviderFactory"
import { FieldInputProps } from "hooks/useForm"

export type PageBoardItemsAutoCompleteProps = {
  label: string
} & Omit<FieldInputProps<NewPageBoardItemFragment | null>, "onBlur">

function usePageBoardItemsAutoComplete(props: PageBoardItemsAutoCompleteProps) {
  return useMemoAPI(props)
}
const Factory = contextAndProviderFactory({
  hookApi: usePageBoardItemsAutoComplete,
})
export const PageBoardItemsAutoCompleteContext = Factory.Context
export const PageBoardItemsAutoCompleteProvider = Factory.Provider
