import _ from "lodash"
import React from "react"

export default function useDebouncedValue<T = any>(
  value: T,
  timeoutInMs = 300
) {
  const [v, set] = React.useState<T>(value)
  const debounced = React.useCallback(_.debounce(set, timeoutInMs), [])

  React.useEffect(() => {
    debounced(value)
  }, [value])

  return v
}
