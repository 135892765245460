import _ from "lodash"
import {
  AssociateCollectionDocument,
  AssociateCollectionMutation,
  AssociateCollectionMutationVariables,
  CollectionItemFragment,
  DocumentDocument,
} from "lib/graphql/operations"
import { DataProxy } from "apollo-cache"
import { ApolloClient, gql } from "@apollo/client";
import readFragment from "lib/updateFragment/readFragment"
import { Typename } from "types/type"
import updateFragment from "lib/updateFragment/updateFragment"

export default async function associateCollection(
  props: {
    collectionID: string
    documentID: string
    workspaceID: string
    onAssociate?: (props: {
      collection: CollectionItemFragment
      apolloCache: DataProxy
    }) => void
  },
  client: ApolloClient<{}>
) {
  const {
    collectionID,
    documentID,
    workspaceID,
    onAssociate = ({ collection, apolloCache }) => undefined,
  } = props

  await client.mutate<
    AssociateCollectionMutation,
    AssociateCollectionMutationVariables
  >({
    mutation: AssociateCollectionDocument,
    variables: { collectionID, documentID, workspaceID },
    optimisticResponse: {
      associateCollection: {
        __typename: "BasicResponse",
        success: true,
      },
    },
    refetchQueries: [
      { query: DocumentDocument, variables: { documentID, workspaceID } },
    ],
    update(cache, { data }) {
      if (!data?.associateCollection?.success) return

      const collection = readFragment(
        {
          typename: Typename.CollectionItem,
          cacheIDParams: { id: collectionID },
        },
        cache
      )

      if (!collection) return

      updateFragment(
        {
          typename: Typename.Document,
          cacheIDParams: { id: documentID },
          updateFn(a) {
            const updated = {
              ...a,
              collections: [...(a.collections || []), collection]?.sort(
                (a, b) => {
                  if (!a?.title || !b.title) return -1
                  if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
                  return 1
                }
              ),
            }

            return updated
          },
        },
        cache
      )
      // console.log(collection)

      // update
    },
  })
}
