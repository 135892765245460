import React from "react"
import { MediaStream } from "../index-types"
interface MediaContext {
  audio: {
    encode: boolean
    decode: boolean
  }
  video: {
    encode: boolean
    decode: boolean
  }
  share: {
    encode: boolean
    decode: boolean
  }
  mediaStream: MediaStream | null
}

const MediaContext = React.createContext<MediaContext>(null as any)

MediaContext.displayName = "MediaContext"

export default MediaContext
