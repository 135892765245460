import _ from "lodash"
import React from "react"

export default function withMemo(Component, reRenderOnChange) {
  if (_.isString(reRenderOnChange)) reRenderOnChange = [reRenderOnChange]

  return React.memo(Component, function equalIf(prevProps, newProps) {
    return (reRenderOnChange || []).every(propKey => {
      if (!_.isString(propKey)) {
        const error = "All props passed to $reRenderOnChange should be a string"
        throw new Error(error)
      }

      const string = props => JSON.stringify(_.get(props, propKey))

      return string(prevProps) === string(newProps)
    })
  })
}
