import { useMutation, gql } from "@apollo/client";
import _ from "lodash"
import { useMemo } from "react"
import useActiveResources from "hooks/useActiveResources"
import {
  // ContactSummaryFragmentDoc
  ContactSummaryFragmentDoc,
  // contactFragmentFields,
} from "lib/graphql/operations"
import fragmentFields from "util/fragmentFields"

export default function useAddContact({ partnerID }) {
  const [mutate, { loading, data }] = useMutation(ADD_DETAILED_CONTACT)
  const { workspaceID } = useActiveResources()

  return useMemo(() => {
    return {
      isLoading: loading,
      addContact({ firstName, lastName, email, remarks }) {
        return mutate({
          variables: _.pickBy(
            {
              firstName,
              lastName,
              remarks,
              email,

              partnerID,
              workspaceID,
            },
            _.isString
          ),

          update(cache, { data: { addDetailedContact } }) {
            const partnerFragment = gql`
              ${ContactSummaryFragmentDoc}
              fragment PartnerWithContacts on Partner {
                contacts {
                  ...ContactSummary
                }
              }
            `
            const args = {
              fragment: partnerFragment,
              id: `Partner:${partnerID}`,
              fragmentName: "PartnerWithContacts",
            }

            const partner = cache.readFragment(args)

            cache.writeFragment({
              ...args,
              data: {
                __typename: "Partner",
                contacts: [...partner.contacts, { ...addDetailedContact }].map(
                  (p) => ({ ...p, __typename: "ContactSummary" })
                ),
              },
            })
          },
        })
      },
    }
  }, [loading, workspaceID, partnerID])
}

export const contactFragmentFields = fragmentFields({
  fragment: ContactSummaryFragmentDoc,
})

const ADD_DETAILED_CONTACT = gql`
  mutation AddDetailedContact(
    $firstName: String
    $lastName: String!
    $email: String
    $workspaceID: ID!
    $partnerID: ID!
    $remarks: String
  ) {
    addDetailedContact(
      firstName: $firstName
      lastName: $lastName
      partnerID: $partnerID
      email: $email
      workspaceID: $workspaceID
      remarks: $remarks
    ) {
      ${contactFragmentFields.join("\n")}
    }
  }
`
