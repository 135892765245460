import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import useTrueFalse from "hooks/useTrueFalse"
import {
  CropFragment,
  NewPageBoardItemFragment,
  useUploadPageBoardItemThumbnailMutation,
} from "lib/graphql/operations"
import { CropInput } from "lib/graphql/types"
import React from "react"

export function useUploadPageBoardItemThumbnail() {
  const workspaceID = useActiveResources().workspaceID
  const [mutation] = useUploadPageBoardItemThumbnailMutation()

  const loading = useTrueFalse(false)

  const uploadThumbnail = React.useCallback(
    function uploadThumbnail(args: {
      file: File
      item: NewPageBoardItemFragment
      crop?: CropInput
    }) {
      const { file, item, crop } = args

      if (!item?.itemID || !item?.type || !file) return

      loading.setTrue()

      return mutation({
        variables: {
          workspaceID,
          item: { itemID: item.itemID, type: item.type },
          file,
          ...(crop ? { crop } : {}),
        },
        optimisticResponse: {
          __typename: "Mutation",
          uploadPageBoardItemThumbnail: {
            ...item,

            thumbnail: {
              __typename: "Image",
              src: URL.createObjectURL(file),
              ...(() => {
                if (!crop) return {}

                const c: CropFragment = {
                  ...crop,
                  __typename: "Crop",
                }

                return {
                  crop: c,
                }
              })(),
            },
            // thumbnailSrc: {
            //   ...
            // }
          },
        },
      }).finally(() => {
        loading.setFalse()
      })
    },
    [workspaceID, loading]
  )

  return useMemoAPI({ upload: uploadThumbnail, loading })
}
