import React from "react"

const DocumentsGridContext = React.createContext({
  collectionID: undefined,
  relatedCollectionsIDs: undefined,
  onChangeRelatedCollectionsIDs: () => undefined,
  paginatedDocuments: {
    isLoading: false,
    fetchMore: () => undefined,
    queryData: undefined,
    total: undefined,
  },
})

DocumentsGridContext.displayName = "DocumentsGridContext"

export default DocumentsGridContext
