import _ from "lodash"
import React, { useMemo, useContext } from "react"
import TopBar, { TopBarSearchProps } from "components/TopBar/TopBar"
import { IconButton, makeStyles } from "@material-ui/core"
import ChatContext from "components/Chat/contexts/ChatContext"
import { SearchVariants } from "components/TopBar/components/Search/Search"

export type ChatListTopBarRenderLeft = (props: {
  components: { title: React.ReactNode }
  isMobileSearchActive: boolean
  searchIsActive: boolean
}) => React.ReactNode

export type ChatListTopBarRender = (props: {
  defaultComponent: React.ReactNode
  search: TopBarSearchProps
  title: string
  showProgressBar: boolean
}) => JSX.Element

export default function ChatListTopBar(props: {
  renderLeft?: ChatListTopBarRenderLeft
  render?: ChatListTopBarRender
}) {
  const c = useStyles({})
  const { rooms, roomsAreLoading, setFilter } = useContext(ChatContext)

  const search = useMemo(
    () => ({ onChange: setFilter, variant: SearchVariants.TOGGLEABLE }),
    [rooms]
  )

  const defaultComponent = (
    <TopBar
      title="Chat"
      search={search}
      classes={{
        right: ({ searchIsActive }) => searchIsActive && c.rightFull,
      }}
      showProgressBar={roomsAreLoading && _.isEmpty(rooms)}
      renderLeft={
        props.renderLeft ||
        (({ searchIsActive, components: { title } }) =>
          !searchIsActive && title)
      }
    ></TopBar>
  )

  if (typeof props.render === "function") {
    return props.render({
      defaultComponent,
      search,
      title: "Chat",
      // title: "Chat",
      showProgressBar: roomsAreLoading && _.isEmpty(rooms),
    })
  }

  return defaultComponent
}

const useStyles = makeStyles((theme) => {
  return {
    rightFull: {
      flex: 1,
    },
  }
})
