import React from "react"
import { KanbanActivityFragment } from "lib/graphql/operations"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function AddCard(props: {
  cardLink: React.ReactNode
  activity: KanbanActivityFragment
}) {
  const t = useI18n()

  if (props.activity.__typename !== "KanbanAddCardActivity") return null

  return (
    <>
      <span>
        {t["iveCreatedATask"]}
        {":  "}
      </span>
      {props.cardLink}
    </>
  )
}
