import { Paper, Typography, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { getGermanyMoneyFormat } from "lib/getGermanyMoneyFormat"
import { Paragraph_PageBoardItemSummaryParagraph_Fragment } from "lib/graphql/operations"
import React from "react"
import { TypographyWithLineCounter } from "components/TypographyWithLineCounter/TypographyWithLineCounter"

export function ShopArticlePageBoardItemSummaryParagraph(props: {
  paragraph: Paragraph_PageBoardItemSummaryParagraph_Fragment

  onClick?: () => void

  isLayoutVertical?: boolean
  summaryLimitOfLines?: number
  renderDetails?: (props: {
    components: {
      summary: React.ReactNode
      sku: React.ReactNode
      title: React.ReactNode
      price: React.ReactNode
      brand: React.ReactNode
    }

    defaultComponent: React.ReactNode
  }) => React.ReactNode
}) {
  const isLayoutVertical = props.isLayoutVertical

  const p = props.paragraph

  const c = useStyles({
    isLayoutVertical,
    // summaryLimitOfLines: props.summaryLimitOfLines,
  })

  const item = p.item

  if (item.__typename !== "ShopArticlePageBoardItem") return null

  return (
    <Paper
      elevation={2}
      className={clsx(
        !isLayoutVertical && c.itemPaperHorizontal,
        "item-paper",
        "shop-article-page-board-item-summary-paragraph"
      )}
      onClick={props.onClick}
    >
      <div className={c.imageFrame}>
        <div
          className={"image-frame-content"}
          style={{
            backgroundImage: `url(${
              item.article?.thumbnail || item.article?.images?.[0] || ""
            })`,
          }}
        ></div>
      </div>

      {(() => {
        const sku = (
          <Typography variant="caption" color="textSecondary">
            {item.article?.sku}
          </Typography>
        )

        const title = (
          <Typography variant="body1" className="title">
            {item.article?.name || p.item.name}
          </Typography>
        )
        // const summary = (
        //   <Typography
        //     variant="body2"
        //     color="textSecondary"
        //     className={clsx(props.summaryLimitOfLines && c.clamp)}
        //   >
        //     {p.summary}
        //   </Typography>
        // )
        const summary = (
          <TypographyWithLineCounter
            enableLimit={!!props.summaryLimitOfLines}
            linesLimit={props.summaryLimitOfLines}
            TypographyProps={{
              variant: "body2",
              color: "textSecondary",
            }}
          >
            {p.summary}
          </TypographyWithLineCounter>
        )

        const brand = (() => {
          if (item.article?.brand?.imageSrc) {
            return (
              <div>
                <img
                  src={item.article.brand.imageSrc}
                  alt={item.article.brand.name}
                  style={{ width: 100 }}
                />
              </div>
            )
          }

          return null
        })()

        const price = (
          <div>
            {(() => {
              if (item.article?.price?.value) {
                return (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    // className={c.price}
                  >
                    {[
                      getGermanyMoneyFormat(item.article.price.value),
                      "€",
                    ].join(" ")}
                  </Typography>
                )
              }

              return null
            })() || <div></div>}
          </div>
        )

        const defaultComponent = (
          <>
            {sku}
            {title}
            {summary}
            {brand}
            {price}
          </>
        )

        return (
          <div
            className={clsx(
              c.textContent,
              !isLayoutVertical && c.textContentHorizontal
            )}
          >
            {(() => {
              if (typeof props.renderDetails === "function") {
                return props.renderDetails({
                  defaultComponent,
                  components: {
                    sku,
                    title,
                    summary,
                    brand,
                    price,
                  },
                })
              }

              return defaultComponent
            })()}
          </div>
        )
      })()}
    </Paper>
  )
}

type Styles = {
  isLayoutVertical?: boolean
}

const useStyles = makeStyles((theme) => {
  return {
    itemPaperHorizontal: {
      display: "flex",
      // alignItems: "stretch",
      alignItems: "flex-start",
    },

    textContentHorizontal: {
      width: 66 + "%",
    },
    textContent: {
      padding: 12,
      "&>.title": {
        marginBottom: 4,
      },
    },
    imageFrame: {
      flex: 1,

      "&>.image-frame-content": {
        background: "rgba(0,0,0,0.1)",
        paddingTop: ({ isLayoutVertical }: Styles) =>
          (isLayoutVertical ? 0.55 : 1) * 100 + "%",
        // paddingTop: "100%",
        height: "100%",

        backgroundSize: "cover",
      },
    },
  }
})
