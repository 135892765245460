import React from "react"
import BasicSelectWithAutocomplete from "components/SelectWithAutocomplete/SelectWithAutocomplete"
import { makeStyles, Typography, TypographyVariant } from "@material-ui/core"
import SelectUsersInputContext from "../../contexts/SelectUsersInputContext"
import SelectWithAutocompleteCopy from "components/SelectWithAutocomplete/SelectWithAutocomplete copy"

export default function SelectUsersInputAutocomplete(props: {
  typographyVariant: TypographyVariant
  label?: string
  placeholder?: string
  focusOnMount?: boolean
  SelectWithAutoCompleteProps?: Omit<
    React.ComponentProps<typeof SelectWithAutocompleteCopy>,
    "options" | "onSelect"
  >
}) {
  const api = React.useContext(SelectUsersInputContext)

  const c = useStyles()

  return (
    <BasicSelectWithAutocomplete
      placeholder={props.placeholder}
      focusOnMount={props.focusOnMount}
      label={props.label}
      textField={api.text}
      ref={api.selectRef}
      onChangeTextField={api.setText}
      typographyVariant={props.typographyVariant}
      renderItem={(a) => (
        <Typography variant={props.typographyVariant}>
          {a.item?.label}
        </Typography>
      )}
      onSelect={api.addUserOption}
      options={api.options}
      classes={{
        suggestionsPaper: c.suggestionsPaper,
        textField: c.input,
      }}
      {...(props.SelectWithAutoCompleteProps || {})}
    ></BasicSelectWithAutocomplete>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    input: {},
    suggestionsPaper: {
      overflow: "auto",
      maxHeight: 200,
    },
  }
})
