import readFragment from "util/readFragment"
import {
  CreatePrivateRoomDocument,
  CreatePrivateRoomMutation,
  PrivateRoomDocument,
  PrivateRoomQuery,
  PrivateRoomQueryVariables,
} from "lib/graphql/operations"
import RoomAPI from "../RoomAPI/RoomAPI"
import { PrivateRoomIdentifier } from "types/type"

export default class PrivateRoomAPI extends RoomAPI<PrivateRoomIdentifier> {
  allowAutomaticCreation = true

  getVariables(identifier: PrivateRoomIdentifier) {
    const variables = {
      workspaceID: this.workspaceID,
      secondMemberID: identifier.userID,
    }
    return variables
  }

  async getRoom(identifier: PrivateRoomIdentifier) {
    const room = await this.client
      .query<PrivateRoomQuery, PrivateRoomQueryVariables>({
        query: PrivateRoomDocument,
        variables: this.getVariables({ userID: identifier.userID }),
        fetchPolicy: "cache-first",
      })
      .then((a) => a.data.privateRoom)

    return room
  }

  async createRoom(identifier: PrivateRoomIdentifier) {
    const mutate = await this.client.mutate<CreatePrivateRoomMutation>({
      mutation: CreatePrivateRoomDocument,
      variables: this.getVariables({ userID: identifier.userID }),
    })

    const room = mutate.data?.createPrivateRoom

    return room
  }
}
