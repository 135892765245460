import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
  ReactChild,
} from "react"
import _ from "lodash"
import Input from "components/Input/Input"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import RestoreButton from "./components/RestoreButton/RestoreButton"
import EditControlContext from "components/EditControl/contexts/EditControlContext"

type EditablePropertyProps = {
  property: string
  children: ReactChild
  Input?: FunctionComponent<{ value: any; onChange: (value: any) => void }>
}

export default function EditableProperty(props: EditablePropertyProps) {
  const { property, children } = props
  const { isEditing, objectValue, newObjectValue, updateObject } =
    React.useContext(EditControlContext)

  const oldPropertyValue = _.get(objectValue, property)
  const newPropertyValue = _.get(newObjectValue, property)

  const [value, setValue] = useState(oldPropertyValue)

  useEffect(() => {
    setValue(oldPropertyValue)
  }, [oldPropertyValue])

  useEffect(() => {
    setValue(newPropertyValue)
  }, [newPropertyValue])

  const onChange = useCallback(
    (e) => {
      const value = e.target.value
      updateObject({ field: property, value })
    },
    [property]
  )

  const c = useStyles({})

  if (!property) return null

  if (isEditing) {
    return (
      <div className={clsx("editable-property", c.root)}>
        <div className="input">
          {React.createElement(props.Input || Input["textarea"], {
            value,
            onChange,
          })}
        </div>

        <RestoreButton property={property} />
      </div>
    )
  }

  return <>{children}</>
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      "&>.input": {
        flex: 1,
        "&>*": {
          width: "100%",
        },
      },
    },
  }
})
