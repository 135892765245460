import React from "react"
import {
  IconButton as MuiIconButton,
  Icon,
  IconButtonProps,
  IconProps,
} from "@material-ui/core"
import clsx from "clsx"

import { makeStyles } from "@material-ui/styles"

export default function IconButton(
  props: Partial<{
    iconName?: string
    disableRipple?: boolean
    onClick?: () => void
    color?: IconProps["color"]
    type?: string
    children: React.ReactChildren
    invisible?: boolean
    classes: { icon: string } & IconButtonProps["classes"]
    disabled?: boolean
    fontSize: IconProps["fontSize"]
    className?: string
  }> &
    Partial<IconButtonProps>
) {
  const {
    iconName = "",
    disableRipple = false,
    onClick = null,
    type = "",
    children = null,
    edge = "start",
    invisible = false,
    classes = { icon: "" },
    disabled = false,
    className = "",
    ...otherProps
  } = props

  const c = useStyles({ invisible })

  return (
    <MuiIconButton
      edge={edge}
      onClick={onClick}
      disabled={invisible || disabled}
      className={className}
      type={type}
      disableRipple={disableRipple}
      {...otherProps}
    >
      <Icon
        className={clsx(c.icon, classes.icon)}
        color={props.color}
        fontSize={props.fontSize}
      >
        {iconName || children}
      </Icon>
    </MuiIconButton>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      visibility: (props: { invisible: boolean }) =>
        props.invisible ? "hidden" : "visible",
    },
  }
})
