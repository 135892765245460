import { ChipProps } from "@material-ui/core"
import { Icon } from "components/Icon/Icon"
import { IndexedItemsContext } from "components/PageBoard/components/IndexedItems/IndexedItems"
import _ from "lodash"
import moment from "moment"
import React from "react"

export function useSelectedPeriodChipProps() {
  const api = React.useContext(IndexedItemsContext)

  const selectorApi = api.dateIntervalSelectorApi.monthsApi.periodSelectorApi

  if (selectorApi.periodType === "all" || !selectorApi) return null

  const period = selectorApi.resolvePeriod()

  const label = (() => {
    if (!!selectorApi.periodType)
      return selectorApi.getPeriodTypeDescription(selectorApi.periodType)

    return _.uniq(
      [period?.start, period?.end]
        .filter((a): a is moment.Moment => !!a)
        .map((a) =>
          a.format(
            (() => {
              const thisYear = moment().year()

              if (a.year() === thisYear) {
                return a.format("DD.MM")
              }

              return "DD.MM.YYYY"
            })()
          )
        )
    ).join(" - ")
  })()

  const chipProps: ChipProps = {
    key: "period",
    label: label,
    icon: (
      <Icon
        name="date_range"
        style={{
          fontSize: 19,
          position: "relative",
          top: -1,
        }}
      ></Icon>
    ),
    size: "small",
    onClick: () => {
      api.dateIntervalSelectorApi.modal.open()
    },
  }

  return chipProps
}
