import { useRoomsQuery } from "lib/graphql/operations"
import useMemoAPI from "hooks/useMemoAPI"
import useWhenIsBackOnline from "../useWhenIsBackOnline"

export default function useRooms(props: { workspaceID: string }) {
  const { workspaceID } = props

  const query = useRoomsQuery({
    variables: { workspaceID: workspaceID || "" },
    fetchPolicy: "cache-and-network",
  })

  useWhenIsBackOnline(query.refetch)

  const rooms = query.data?.rooms
  const result = useMemoAPI({ rooms, isLoading: query.loading })

  return result
}
