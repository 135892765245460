import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { useLoggedUserQuery } from "lib/graphql/operations"

const Factory = contextAndProviderFactory({
  hookApi: function useLoggedUser() {
    const result = useLoggedUserQuery({
      fetchPolicy: "cache-and-network",
    })

    const loggedUser =
      result?.data?.loggedUser || result?.previousData?.loggedUser || null

    return useMemoAPI({ loggedUser: loggedUser })
  },
})

export const LoggedUserContext = Factory.Context
export const LoggedUserProvider = Factory.Provider

export const LoggedUser = {
  Context: LoggedUserContext,
  Provider: LoggedUserProvider,
}
