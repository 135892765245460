import { ChipProps, makeStyles } from "@material-ui/core"
import Emoji from "components/Emoji/Emoji"
import { excludeNullable } from "lib/excludeNullable"
import _ from "lodash"
import React from "react"
import { ActionsChipsContext } from "../components/ActivityEntry/components/AddCommentActivity/ActionsChips/ActionsChipsContext"

export function useReactionsChips(props: { emojiPosition: "right" | "left" }) {
  const c = useStyles()
  const api = React.useContext(ActionsChipsContext)

  const grouped = _.groupBy(api.comment.reactions, (a) => a.emojiUnicode)

  const emojis = _.keys(grouped)

  const ok = [
    (() => {
      if (!emojis?.length) return null

      const a = React.createRef<HTMLElement | null>()

      const chipProps: ChipProps = {
        onClick: () => {
          api.reactionApi.openUserReactions({ popoverAnchorRef: a })
        },

        ...api.getBasicChipProps({ iconName: "info", labelRef: a }),
      }

      return chipProps
    })(),
    ...emojis.map((emojiUnicode) => {
      const reactionsAmount = grouped[emojiUnicode].length

      if (!reactionsAmount) return null

      const chipProps: ChipProps = {
        key: emojiUnicode,
        clickable: true,
        style: { userSelect: "none" },
        icon: (
          <div
            style={{
              fontSize: 13,
              marginRight: -1,
              lineHeight: 1,
              userSelect: "none",
            }}
          >
            <Emoji unicode={emojiUnicode}></Emoji>
          </div>
        ),
        label: reactionsAmount,
        classes: { label: c.iconLabel },
        className: c.chipRoot,
        onClick: () => {
          api.reactionApi.toggle({ emojiUnicode })
        },
        variant: "default",
      }

      return chipProps
    }),

    (() => {
      const a = React.createRef<HTMLElement | null>()

      const props = api.getBasicChipProps({
        iconName: "mood",
        labelRef: a,
      })

      const chipProps: ChipProps = {
        onClick: () => {
          api.reactionApi.openEmojiPicker({ popoverAnchorRef: a })
        },
        ...props,
        // label: <span style={{display: 'flex'}} ref={ref}>{props.label}</span>,
      }

      return chipProps
    })(),
  ].filter(excludeNullable)

  if (props.emojiPosition === "right") {
    return [...ok].reverse()
  }

  return ok
}

const useStyles = makeStyles((theme) => {
  return {
    chip: {
      marginBottom: "0px !important",
      marginRight: theme.spacing(0.5) + " !important",
    },
    root: {
      display: "flex",
      alignItems: "center",

      flexWrap: "wrap",

      "&>*:not:(:first-child)": {
        marginLeft: theme.spacing(1),
      },
    },
    chipIcon: { fontSize: "16px !important" },
    chipRoot: {
      height: 19,

      marginRight: 4,
      // marginTop: 6,

      padding: "11px 2px",
    },
    iconLabel: {
      color: theme.palette.text.secondary,
      userSelect: "none",
      display: "flex",
      padding: theme.spacing(0, 0.5),
    },
  }
})
