import React from "react"
import { useActiveRoomID } from "contexts/activeRoom"

export default function useIsRoomFocused() {
  const { activeRoomID } = useActiveRoomID()

  const isRoomFocused = React.useCallback(
    (props: { roomID: string }) => {
      if (document.visibilityState === "hidden") return false
      if (!activeRoomID) return false
      return activeRoomID === props.roomID
    },
    [activeRoomID]
  )

  return isRoomFocused
}
