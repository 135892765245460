import _ from "lodash"
import React, { useMemo } from "react"
import BasicForm from "components/BasicForm/BasicForm"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import ScrollableContentWithFixedTopAndBottom from "components/ScrollableContentWithFixedTopAndBottom/ScrollableContentWithFixedTopAndBottom"
import ComponentsGrid from "components/ComponentsGrid/ComponentsGrid"
import ActionsPanel from "components/ActionsPanel/ActionsPanel"
import { Button } from "@material-ui/core"
import DateMetaCards from "components/DateMetaCards/DateMetaCards"
import SubForms from "./components/SubForms/SubForms"

export default function FormWithMetaCards({
  onSubmit,
  toggleDelete,
  className,
  fieldset: propsFieldset,
  subForms: subFormsProps,
  spacing: propsSpacing = 1,
  created,
  updated,
  deleted,
  MetaCards,
}) {
  const isDeleted = !!_.get(deleted, "when")

  const fieldset = useMemo(
    () => (propsFieldset || []).map((f) => ({ ...f, disabled: isDeleted })),
    [propsFieldset, isDeleted]
  )

  const c = useStyles({})
  const basicMetaCards = getBasicMetaCards({ deleted, created, updated })

  return (
    <BasicForm
      fieldset={fieldset}
      className={clsx(c.form, className)}
      onSubmit={onSubmit}
      grid={{ xs: 12, md: 6 }}
      spacing={propsSpacing}
    >
      {function render(formProps) {
        const {
          getInput,
          components: { submitButton },
          spacing,
        } = formProps

        const subForms = SubForms({ subForms: subFormsProps, getInput })
        const formGrid = _.map((subForms || []).filter(Boolean), (sForm) => ({
          component: sForm.component || sForm,
          grid: sForm.grid || SUB_FORM_GRID,
        }))

        const metaCards = MetaCards({ getInput, basicMetaCards })

        const metaCardsGrid = _.map(
          (metaCards || []).filter(Boolean),
          (card) => ({
            component: card.component || card,
            grid: card.grid || META_CARD_GRID,
            className: c.metaCard,
          })
        )

        const components = [
          ...(formGrid || []),
          ...(metaCardsGrid || []),
        ].filter((c) => React.isValidElement(c.component))
        //

        return (
          <div className="form-content">
            <div
              className="fields"
              // style={}
            >
              <ComponentsGrid
                spacing={spacing}
                components={components}
              ></ComponentsGrid>
            </div>

            <ActionsPanel
              actionButtons={[
                <Button color="primary" onClick={toggleDelete}>
                  {isDeleted ? "Wiederherstellen" : "Löschen"}
                </Button>,
                submitButton,
              ]}
            ></ActionsPanel>
          </div>
        )
      }}
    </BasicForm>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    metaCard: {
      alignSelf: "flex-start",
      height: theme.spacing(12),
    },
    form: {
      padding: 0,
      height: "100%",

      "&>.form-content": {
        height: "100%",
        display: "flex",
        flexDirection: "column",

        "&>.fields": {
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
          padding: "16px",
        },
      },
    },
  }
})

const META_CARD_GRID = { xs: 12, sm: 6, md: 3, xl: 2 }
const SUB_FORM_GRID = { xs: 12, md: 6 }

function getBasicMetaCards({ updated, created, deleted }) {
  const isValid = (obj) => {
    return !!_.get(obj, "when")
  }

  return {
    updated: isValid(updated) && (
      <DateMetaCards
        type="updated"
        by={updated.by}
        when={updated.when}
      ></DateMetaCards>
    ),
    created: isValid(created) && (
      <DateMetaCards
        type="created"
        by={created.by}
        when={created.when}
      ></DateMetaCards>
    ),
    deleted: isValid(deleted) && (
      <DateMetaCards
        type="deleted"
        by={deleted.by}
        when={deleted.when}
      ></DateMetaCards>
    ),
  }
}
