import usePartnerTypeField from "./usePartnerTypeField"
import { useMemo } from "react"
import useCountriesField from "./useCountriesField"
import { fieldsetWithDefaultValue } from "components/Form/util"
import Partner from "components/Partner/index"
import useBasicFields from "./useBasicFields"
import { useContext } from "react"
// import basicFields from "../lib/basicFields"

export default function usePartnerFieldset() {
  const { partner } = useContext(Partner.Context) || {}
  const typesField = usePartnerTypeField({ name: "type" })
  const countryField = useCountriesField({ name: "country" })
  const basicFields = useBasicFields()

  const fields = [typesField, ...basicFields, countryField]

  const fieldset = useMemo(
    () => fieldsetWithDefaultValue({ data: partner, fieldset: fields }),
    [JSON.stringify(partner), countryField, typesField]
  )

  return fieldset
}
