import clsx from "clsx"
import React from "react"
// import DocumentFormContext from "components/DocumentForm/contexts/DocumentFormContext"
import { makeStyles } from "@material-ui/core"
import TwoViewsWithTransition from "components/ViewsWithTransition/TwoViewsWithTransition"
import { TwoPagesAPI } from "../../TwoPages"
import TwoPagesContext from "../../contexts/TwoPagesContext"

export default function TwoPagesRoot(props: {
  className?: string
  style?: React.CSSProperties
  /** mainPage on mounting */
  mainPage: React.ReactNode
}) {
  const api = React.useContext(TwoPagesContext)

  const views = React.useMemo(
    () => [
      props.mainPage,
      (() => {
        if (!api.secondPage.view) {
          return null
        }

        return (
          <TwoPagesContext.Consumer>
            {(api) => {
              if (api.layout.id === "side-by-side")
                return (
                  <div
                    className={clsx(c.secondPageWrapper)}
                    style={{
                      height: "100%",
                      borderLeft: "1px solid rgba(0,0,0,0.06)",
                    }}
                  >
                    {api.secondPage.view}
                  </div>
                )

              return (
                <div className={c.secondPageWrapper}>{api.secondPage.view}</div>
              )
            }}
          </TwoPagesContext.Consumer>
        )
      })(),
    ],
    [api.secondPage.view, api.secondPage.hidden.isTrue]
  )

  const c = useStyles({
    layout: api.layout,
    secondPageIsHidden: api.secondPage.hidden.isTrue,
  })

  const rootProps = {
    className: clsx("two-page-styles", c.root, props.className),
    style: props.style,
  }

  let component: React.ReactNode = null

  if (api.layout.id === "side-by-side") {
    component = (
      <div className={clsx(c.sideBySide)}>
        {views.map((v, index) => React.cloneElement(<>{v}</>, { key: index }))}
      </div>
    )
  } else if (api.layout.id === "one-visible-view") {
    component = (
      <TwoViewsWithTransition
        views={[views[0], views[1]]}
        transitionInMs={api.viewsTransitionInMs}
        secondViewIsHidden={api.secondPage.hidden}
      ></TwoViewsWithTransition>
    )
  } else {
    component = null
  }

  return (
    <div
      className={clsx(rootProps.className, "two-pages-root")}
      style={rootProps.style}
      ref={api.rootContainer.containerRef}
    >
      {component}
    </div>
  )
}

type Style = { layout: TwoPagesAPI["layout"]; secondPageIsHidden?: boolean }

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "&>*": {
        height: "100%",
      },
    },
    secondPageWrapper: {
      height: "100%",
      "&>*": {
        height: "100%",
      },
    },
    sideBySide: {
      display: "flex",
      overflow: "hidden",
      "&>*": {
        flex: 1,
        height: "100%",
        // "&>*": {
        //   height: "100%",
        // },
        "&:not(:first-child)": {
          flex: ({ secondPageIsHidden }: Style) => {
            return secondPageIsHidden ? 0 : 1
          },

          maxWidth: (props: Style) => {
            if (props.secondPageIsHidden) return 0
            if (props.layout.id === "side-by-side" && props.layout.minimized) {
              return 320
            }

            return "50%"
          },
        },

        "&:not(:first-child):last-child": {
          // height: '100%',

          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
          // borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
          marginLeft: "7px",
        },
      },
    },
  }
})
