import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { StyledProps } from "types/type"

export function ImagePlaceholder(
  props: { src: string; lightOpacity?: boolean } & StyledProps
) {
  const c = useStyles()

  return (
    <div className={clsx(props.lightOpacity && c.lightOpacity, c.imageWrapper)}>
      <img src={props.src}></img>
    </div>
  )
}
const useStyles = makeStyles(() => {
  return {
    imageWrapper: {
      height: "100%",
      width: "100%",
      overflow: "hidden",
      "& img": {
        maxWidth: "100%",
      },
    },
    lightOpacity: {
      opacity: 0.5,
    },
  }
})
