import _ from "lodash"
import React, { useCallback } from "react"
import { FormControlLabel } from "@material-ui/core"
import MuiCheckbox from "@material-ui/core/Checkbox"
import useInputOnChange from "../../util/useInputOnChange"
import { FieldInputProps, FormikConfig } from "formik"

export default function Checkbox({
  value,
  onChange: propsOnChange,
  label,
  onBlur,
  name,
  disabled,
  style,
  className,
}: {
  label: string
  disabled?: boolean
  className?: string
  style?: React.CSSProperties
} & FieldInputProps<boolean>) {
  const onChange = useInputOnChange({ onChange: propsOnChange })

  return (
    <FormControlLabel
      label={label}
      disabled={disabled}
      style={style}
      className={className}
      control={
        <MuiCheckbox
          checked={!!value}
          disabled={disabled}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
        />
      }
    />
  )
}
