import React from "react"
import _ from "lodash"
import { MessagesRendered } from "components/Room/types"
import useMemoAPI from "hooks/useMemoAPI"
import { useObservableState } from "observable-hooks"
import { Observable } from "rxjs"
import useToBeSeen from "./useToBeSeen"
import useSeenMessages from "./useSeenMessages"

export default function useCountAPI(props: {
  messagesRendered: MessagesRendered | undefined
  messagesRendered$: Observable<MessagesRendered>
  loadedFirstUnreadIndex$: Observable<number | null | undefined>
}) {
  const { loadedFirstUnreadIndex$, messagesRendered: rendered } = props

  const loadedFirstUnreadIndex = useObservableState(loadedFirstUnreadIndex$)

  const toBeSeen = useToBeSeen({
    loadedFirstUnreadIndex: loadedFirstUnreadIndex,
    messagesRendered$: props.messagesRendered$,
  })

  const seen = useSeenMessages({
    messagesRendered$: props.messagesRendered$,
    minIndexToBeSeen: toBeSeen[0],
  })

  const unseen = React.useMemo(
    () => _.difference(toBeSeen, seen || []),
    [toBeSeen, seen]
  )

  const above = rendered
    ? unseen.filter((index) => index < rendered?.visibleStartIndex)
    : []

  const bellow = rendered
    ? unseen.filter((index) => index > rendered?.visibleStopIndex)
    : []

  // console.log({ above, bellow, seen, unseen, toBeSeen })

  return useMemoAPI({ bellow: bellow.length, above: above.length })
}
