import React from "react"
import _ from "lodash"
import clsx from "clsx"
import { Fab, Icon } from "@material-ui/core"
import { RoomContext } from "components/Room/contexts/room"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import MessagesContext from "../../contexts/MessagesContext"

export default function JumpToBottom(props: { className?: string }) {
  const { lastMessageIndex } = React.useContext(RoomContext)

  const { listRenderer } = React.useContext(MessagesContext)

  const countBellow = listRenderer.unread.count.bellow

  const jumpToTheBottom = useJump()

  if (typeof lastMessageIndex !== "number") return null
  if (!listRenderer.messagesRendered) return null

  if (listRenderer.messagesRendered?.visibleStopIndex === lastMessageIndex)
    return null

  return (
    <PurpleBadge
      badgeContent={countBellow}
      overlap="circle"
      className={clsx("jump-to-bottom", props.className)}
    >
      <Fab size="small" onClick={jumpToTheBottom}>
        <Icon>arrow_downward</Icon>
      </Fab>
    </PurpleBadge>
  )
}

function useJump() {
  const { lastMessageIndex } = React.useContext(RoomContext)
  const { listRenderer } = React.useContext(MessagesContext)

  const countBellow = listRenderer.unread.count.bellow

  const scrollToItem = listRenderer.scrolling.scrollToItem
  const scrollToBottom = listRenderer.messageListRef.current?.scrollToBottom

  enum Action {
    GOTO_BOTTOM,
    GOTO_FIRST_UNREAD,
  }

  const [action, setAction] = React.useState(Action.GOTO_BOTTOM)

  React.useEffect(() => {
    setAction(countBellow ? Action.GOTO_FIRST_UNREAD : Action.GOTO_BOTTOM)
  }, [!!countBellow])

  const goToBottom = React.useCallback(() => {
    if (typeof lastMessageIndex !== "number") return
    // ?.call(undefined, { index: lastMessageIndex })
    scrollToBottom?.call(undefined)
  }, [lastMessageIndex, scrollToBottom])

  const goToFirstUnread = React.useCallback(() => {
    if (typeof lastMessageIndex !== "number") return

    scrollToItem?.call(undefined, {
      index: lastMessageIndex - (countBellow - 1),
    })

    setAction(Action.GOTO_BOTTOM)
  }, [lastMessageIndex, scrollToItem, setAction, countBellow])

  const actions = {
    [Action.GOTO_BOTTOM]: goToBottom,
    [Action.GOTO_FIRST_UNREAD]: goToFirstUnread,
  }

  return actions[action]
}
