import AppBar from "../AppBar/AppBar"
import { SideSheetProvider } from "components/SideSheet"
import React from "react"
import PageLayout from "components/PageLayout/PageLayout"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import FabsWrapper from "components/FabsWrapper/FabsWrapper"

export default function AppPageLayout({
  className = "",
  appBar,
  main,
  sideSheet,
  showChatBalloon = true,
  floatingButtons = [],
}) {
  const c = useStyles({})

  return (
    <SideSheetProvider>
      <FabsWrapper
        showChatBalloon={showChatBalloon}
        floatingButtons={floatingButtons}
      >
        <PageLayout
          topBar={typeof appBar === "undefined" ? <AppBar /> : appBar}
          body={main}
          className={clsx("app-page-layout", c.root, className)}
        />
      </FabsWrapper>
    </SideSheetProvider>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
    },
  }
})
