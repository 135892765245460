import React from "react"
import _ from "lodash"
import MessagesContext from "./contexts/MessagesContext"
import useMessagesProviderValue from "./hooks/useMessagesProviderValue"
import MessagesRoot from "./components/MessagesRoot/MessagesRoot"

const Messages = {
  Root: MessagesRoot,
  Context: MessagesContext,
  Provider: function MessagesProvider(props: { children: React.ReactChild }) {
    const value = useMessagesProviderValue()

    return (
      <MessagesContext.Provider value={value}>
        {props.children}
      </MessagesContext.Provider>
    )
  },
}

export default Messages
