import React, { useCallback, useContext } from "react"
import _ from "lodash"
import clsx from "clsx"
import { Icon, IconButton } from "@material-ui/core"
import DocumentsFilterContext from "../../contexts/CollectionDocumentsFilterContext"
import IconWithNumber from "components/IconWithNumber/IconWithNumber"

export default function FilterButton({
  children = ({ defaultComponent, components: { button }, onClickButton }) =>
    defaultComponent,
  className = "",
  edge = undefined,
  count = undefined,
}) {
  const { isFilterOpen, toggleFilter } = useContext(DocumentsFilterContext)

  const onClickButton = useCallback(
    (e) => {
      e && e.stopPropagation()
      toggleFilter()
    },
    [toggleFilter]
  )

  const button = (
    <IconWithNumber
      showZeroCounting={false}
      hideNumber={isFilterOpen}
      number={count}
      numberOnLeft
      icon={
        <IconButton
          size="small"
          edge={edge}
          onClick={onClickButton}
          className={clsx("filter-button", className)}
        >
          <Icon color={isFilterOpen ? "primary" : "default"} fontSize="small">
            {isFilterOpen ? "close" : "filter_list"}
          </Icon>
        </IconButton>
      }
    ></IconWithNumber>
  )

  return children({
    defaultComponent: button,
    components: { button },
    onClickButton,
  })
}
