import { Button } from "@material-ui/core"
import Hook from "components/Hook/Hook"
import { Icon } from "components/Icon/Icon"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import openKanbanCard from "components/PageBoard/lib/openKanbanCard"
import RoomKanban from "components/RoomKanban/RoomKanban"
import { OpenedSecondPage } from "components/TwoPages/hooks/useTwoPagesAPI"
import I18nContext from "contexts/i18nContext/i18nContext"
import useCreateKanbanColumn from "hooks/useCreateKanbanColumn"
import RoomPageBoardItemApiContext from "../../../../contexts/RoomPageBoardItemContext"

export const openedKanbanBoardInput: OpenedSecondPage = {
  // layoutID: "one-visible-view",
  preferentialLayout: { id: "one-visible-view" },
  id: "kanban-board",
  Page: function KanbanBoard(props: { onClose: () => any }) {
    return (
      <ItemTemplate.Provider onClose={props.onClose}>
        <ItemTemplate.Context.Consumer>
          {(pageBoardAPI) => {
            return (
              <RoomPageBoardItemApiContext.Consumer>
                {(api) => {
                  const roomID = api.roomAPI?.room?.id || ""
                  return (
                    <RoomKanban.Provider
                      roomID={roomID}
                      openKanbanCard={(payload) =>
                        openKanbanCard(
                          { cardID: payload.cardID },
                          { twoPagesAPI: pageBoardAPI.twoPagesAPI }
                        )
                      }
                    >
                      <ItemTemplate.Root
                        EnhancedPageProps={{
                          title: api.roomAPI?.room?.name || "",
                          onClose: props.onClose,
                          style: { height: "100%" },
                          renderTitleLine(props) {
                            return (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                {props.titleComponent}
                                <RoomKanban.Context.Consumer>
                                  {(kanbanApi) => {
                                    if (kanbanApi.board !== null) return null

                                    return (
                                      <I18nContext.Consumer>
                                        {(t) => {
                                          return (
                                            <Hook
                                              hook={useCreateKanbanColumn}
                                              hookProps={{ roomID }}
                                            >
                                              {(api) => {
                                                return (
                                                  <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="primary"
                                                    disableElevation
                                                    disabled={api.loading}
                                                    onClick={() =>
                                                      api.createColumn()
                                                    }
                                                  >
                                                    <Icon name="view_kanban"></Icon>
                                                    <span
                                                      style={{ marginLeft: 4 }}
                                                    >
                                                      {t.createTasksBoard}
                                                    </span>
                                                  </Button>
                                                )
                                              }}
                                            </Hook>
                                          )
                                        }}
                                      </I18nContext.Consumer>
                                    )
                                  }}
                                </RoomKanban.Context.Consumer>
                              </div>
                            )
                          },
                          body: (
                            <RoomKanban.Root
                              style={{ height: "100%" }}
                            ></RoomKanban.Root>
                          ),
                        }}
                      ></ItemTemplate.Root>
                    </RoomKanban.Provider>
                  )
                }}
              </RoomPageBoardItemApiContext.Consumer>
            )
          }}
        </ItemTemplate.Context.Consumer>
      </ItemTemplate.Provider>
    )
  },
}
