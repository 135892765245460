import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import {
  useDeleteCollectionItemMutation,
  CollectionsOverviewsDocument,
} from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"

export default function useDeleteCollection() {
  const { workspaceID } = useActiveResources()

  const [mutate, { data, loading }] = useDeleteCollectionItemMutation()

  const deleteCollection = React.useCallback(
    (props: { collectionID: string }) => {
      return mutate({
        variables: {
          workspaceID,
          collectionID: props.collectionID,
        },

        refetchQueries: [
          { query: CollectionsOverviewsDocument, variables: { workspaceID } },
        ],
      })
    },
    [workspaceID]
  )

  return useMemoAPI({ deleteCollection, isLoading: loading })
}
