import React from "react"
import { MODES } from "../constants"
import useMembersProviderProps from "../hooks/useMembersProviderProps"

const MembersContext = React.createContext(
  {} as ReturnType<typeof useMembersProviderProps>
)

MembersContext.displayName = "MembersContext"

export default MembersContext
