import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import Image from "components/Image/Image"
import ImageAttachmentContext from "components/ImageAttachment/contexts/ImageAttachmentContext"
import React from "react"
import { StyledProps } from "types/type"
import { ImageWithPlaceholder } from "../ImageWithPlaceholder"

export function ImageAttachmentRoot(
  props: {
    disableViewer?: boolean
    // className?: string
    // style?: React.CSSProperties
    classes?: React.ComponentProps<typeof Image>["classes"]
  } & StyledProps
) {
  const api = React.useContext(ImageAttachmentContext)
  const c = useStyles({ height: api.height })

  const imageClass = clsx(c.image, props?.classes?.image)

  return (
    <ImageWithPlaceholder
      className={clsx(props.className, "image-attachment-root")}
      style={props.style}
    >
      {(() => {
        if (!api.objectURL) return <div className={imageClass}></div>

        return (
          <Image
            imageURL={api.objectURL}
            classes={{ ...(props.classes || {}), image: imageClass }}
            disableViewer={props.disableViewer}
            onLoad={api.onLoad}
          ></Image>
        )
      })()}
    </ImageWithPlaceholder>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    image: {
      height: ({ height }: { height: number }) => height,
    },
  }
})
