import _f from "lodash/fp"
import _ from "lodash"
import { DataProxy } from "apollo-cache"
import { RoomMessageListUpdateFragment } from "./graphql/operations"
import updateQuery from "./updateQuery/updateQuery"

export default function updateRoomMessageList(
  props: RoomMessageListUpdateFragment & { workspaceID: string },
  client: DataProxy
) {
  updateQuery(
    {
      queryName: "roomMessageList",
      variables: { roomID: props.roomID, workspaceID: props.workspaceID },
      updateFn: (a) => {
        return {
          __typename: "Query",
          roomMessageList: {
            __typename: "RoomMessageList",
            totalItems: props.totalItems || 0,
            roomID: props.roomID,
            list: _.uniqBy(
              [
                ...(a?.roomMessageList.list || []),
                ...(props.newListItems || []),
              ],
              (a) => a.index
            ),
          },
        }
      },
    },
    client
  )
}
