import { RoomContext } from "components/Room/contexts/room"
import { Typography, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { Message_TextMessage_Fragment } from "lib/graphql/operations"
import { MessageBalloonProps } from "../MessageBalloon/MessageBalloon"
import MessageRichText from "../MessageRichText/MessageRichText"
import { RepliedMessage } from "../RepliedMessage/RepliedMessage"
import { MessageWrapper } from "../MessageWrapper"

import MessageAttachments from "components/MessageAttachments/MessageAttachments"
import React from "react"
import MessageOpenGraphs from "../MessageOpenGraphs/MessageOpenGraphs"
import ChatFileUploadProgress from "components/Room/components/ChatFileUploadProgress/ChatFileUploadProgress"

export default function TextMessage(props: {
  message: Message_TextMessage_Fragment
  MessageBalloonProps: Omit<MessageBalloonProps, "message" | "children">
}) {
  const c = useStyles({})
  const api = React.useContext(RoomContext)

  const message = props.message

  const repliedMessageListItem = props.message?.repliedMessageListItem || null

  const attachments = (() => {
    if (!message?.attachments) return null

    const p: React.ComponentProps<typeof MessageAttachments> = {
      message,
      onClickAttachment: (a) => api.clickAttachment$.next(a),
    }

    if (api.renderMessageAttachments)
      return api.renderMessageAttachments({ MessageAttachmentProps: p })

    return <MessageAttachments {...p} />
  })()

  const openGraphs = message?.content && (
    <MessageOpenGraphs messageText={message.content}></MessageOpenGraphs>
  )

  if (typeof message.id !== "string" && message.attachments)
    return <ChatFileUploadProgress />

  return (
    <MessageWrapper {...props}>
      {({ components: { authorName, bottom } }) => {
        return (
          <>
            {repliedMessageListItem && (
              <RepliedMessage
                className={c.reply}
                repliedMessageListItem={repliedMessageListItem}
              ></RepliedMessage>
            )}
            {authorName}
            {attachments}
            <Typography variant="body2" className={clsx("content", c.richText)}>
              <MessageRichText
                value={props.message.content || ""}
              ></MessageRichText>
            </Typography>
            {openGraphs}
            {bottom}
          </>
        )
      }}
    </MessageWrapper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    reply: {
      marginBottom: theme.spacing(1),
    },
    richText: {
      display: "table",
    },
  }
})
