import { ID } from "types/type"
import React from "react"
import useWorkspaceRootPathname from "hooks/useWorkspaceRootPathname/useWorkspaceRootPathname"
import shareableLib from "../lib/shareableLib"
import { catchError } from "rxjs/operators"

/** It resolves the internal url of the the a Shareable */
export default function useShareableWorkspaceURL(props: {
  workspaceID: ID | null
  shareableURL?: URL | null
}) {
  const workspaceBaseURL = useWorkspaceRootPathname({
    workspaceID: props.workspaceID,
  })

  return React.useMemo(() => {
    if (!props.shareableURL || !workspaceBaseURL) return null

    try {
      const url = shareableLib.getWorkspaceURL({
        sharedURL: props.shareableURL,
        workspaceRootPathname: workspaceBaseURL + "",
      })

      return url
    } catch (e) {
      console.error(e)
      return null
    }
  }, [props.shareableURL?.href, workspaceBaseURL])
}
