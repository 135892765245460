import React, { useContext } from "react"
import Draggable from "../Draggable/Draggable"
import Control from "../Control/Control"
import { makeStyles, Paper } from "@material-ui/core"
import { useKey, useKeys } from "rooks"
import ChatOverlayContext from "components/ChatOverlay/contexts/ChatOverlayContext"

export default function ChatDraggableOverlay({ children }) {
  const c = useStyles({})

  const { overlayIsOpen, dimensions } = useContext(ChatOverlayContext)

  useKeysShortCuts()

  if (!overlayIsOpen) return null

  return (
    <Draggable>
      <Paper
        elevation={10}
        className={c.chatOverlayPaper}
        style={dimensions}
        square={false}
      >
        <Control></Control>
        {children}
      </Paper>
    </Draggable>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    chatOverlayPaper: {
      background: "#f5f5f5",
      position: "absolute",
      left: 0,
      top: 0,
      display: "flex",
      flexDirection: "column",
    },
  }
})

function useKeysShortCuts() {
  const { overlayIsOpen, closeOverlay, openOverlay } =
    useContext(ChatOverlayContext)

  // useKey(["Escape"], closeOverlay, { when: overlayIsOpen })

  // useKeys(
  //   ["Control", "Shift", "KeyC"],
  //   (e) => {
  //     e.preventDefault()
  //     openOverlay()
  //   },
  //   {
  //     when: !overlayIsOpen,
  //   }
  // )
}
