import clsx from "clsx"
import { ListProps } from "components/List/List"
import useMemoAPI from "hooks/useMemoAPI"
import {
  PageBoardItemsHookResult,
  createUsePageBoardItems,
} from "hooks/usePageBoardItems/createUsePageBoardItems"
import { useFetchNextPage } from "hooks/usePageBoardItems/useFetchNextPage"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import {
  PageBoardItemsInCommonWithUsersQuery,
  usePageBoardItemsInCommonWithUsersQuery,
} from "lib/graphql/operations"
import React from "react"
import { StyledProps } from "types/type"
import { IndexedItems } from "../IndexedItems/IndexedItems"
import { DateAndTypesFilter } from "./components/DateAndTypesFilter/DateAndTypesFilter"
import { PageBoardItemType } from "lib/graphql/types"

const F = contextAndProviderFactory({
  hookApi: function useItemsWithCommonUsersAPI(props: { userID: string }) {
    return useMemoAPI(props)
  },
})

const ItemsInCommonWithUserProviderAPI = F.Provider
const ItemsInCommonWithUserContextAPI = F.Context

export function ItemsInCommonWithUserProvider(props: {
  userID: string
  children: React.ReactNode
}) {
  return (
    <ItemsInCommonWithUserProviderAPI userID={props.userID}>
      <IndexedItems.Provider
        usePageBoardItems={usePageBoardItemsInCommon}
        getFilterTypes={(types) =>
          types.filter((type) => type !== PageBoardItemType.User)
        }
      >
        {props.children}
      </IndexedItems.Provider>
    </ItemsInCommonWithUserProviderAPI>
  )
}

export const ItemsInCommonWithUser = {
  Provider: ItemsInCommonWithUserProvider,
  Context: ItemsInCommonWithUserContextAPI,
  Root: ItemsInCommonWithUserRoot,
  DateAndTypesFilter,
  Search(props: React.ComponentProps<typeof IndexedItems.GoogleSearch>) {
    return <IndexedItems.GoogleSearch {...props}></IndexedItems.GoogleSearch>
  },
}

export function ItemsInCommonWithUserRoot(
  props: {
    openItemPage?: React.ComponentProps<
      typeof IndexedItems.ItemsList.Provider
    >["openItemPage"]
  } & StyledProps
) {
  return (
    <div
      className={clsx("items-in-common-with-user", props.className)}
      style={props.style}
    >
      <IndexedItems.Consumer>
        {(api) => {
          const basicProps: ListProps = {
            items: api.pageBoardItems || [],
            totalCount: api.pagination?.total || 0,
            fetchMore: api.fetchMore || (() => {}),
          }

          return (
            <IndexedItems.ItemsList.Provider
              variant="grid"
              openItemPage={props.openItemPage}
              ListProps={{
                totalCount: basicProps.totalCount,
                items: basicProps.items || [],
                fetchMore: basicProps.fetchMore,
              }}
            >
              <IndexedItems.ItemsList.Root
                style={{ height: "100%" }}
                className="items-list-root"
              ></IndexedItems.ItemsList.Root>
            </IndexedItems.ItemsList.Provider>
          )
        }}
      </IndexedItems.Consumer>
      <IndexedItems.DateIntervalModal></IndexedItems.DateIntervalModal>
    </div>
  )
}

// function DefaultCard(props: {entry: React})

// const ItemCard = {
//   [PageBoardItemType.Room]: RoomItemGridCell,
// }
const usePageBoardItemsInCommon = createUsePageBoardItems({
  useHookApi(args) {
    const api = React.useContext(ItemsInCommonWithUserContextAPI)

    const queryResult = usePageBoardItemsInCommonWithUsersQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        ...args.variables,
        types: (() => {
          const types = args.variables?.types

          const t = (() => {
            if (Array.isArray(types)) {
              return types
            }

            return [types]
          })()

          return t.filter(
            (a): a is PageBoardItemType => a !== PageBoardItemType.User
          )
        })(),
        usersIDs: [api.userID],
      },
      skip: !args.variables.workspaceID || !api.userID,
    })

    const fetchNextPage = useFetchNextPage({
      queryResult,
      getResponse: (a) => a?.pageBoardItemsInCommonWithUsers,
      updateQuery(p) {
        const l: PageBoardItemsInCommonWithUsersQuery = {
          __typename: "Query",
          pageBoardItemsInCommonWithUsers: {
            __typename: "PageBoardItemsResponse",
            pagination: p.pagination,
            items: p.items || [],
          },
        }
        return l
      },
    })

    const b: PageBoardItemsHookResult = {
      fetchingResponse: queryResult.data?.pageBoardItemsInCommonWithUsers,
      fetchNextPage,
      refetch: queryResult.refetch,
    }

    return b
  },
})
