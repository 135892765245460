import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import useCurrentWorkspaceRootPathname from "hooks/useCurrentWorkspaceRootPathname/useCurrentWorkspaceRootPathname"
import React from "react"
import shareableLib from "../lib/shareableLib"
import { ShareableVariables } from "../@types/index"

export default function useGetShareableLink() {
  const { user } = useCurrentWorkspace()
  const workspaceRootPathname = useCurrentWorkspaceRootPathname()

  const getShareableLink = React.useCallback(
    (
      props: Omit<ShareableVariables, "sharerID"> & {
        sharerID?: string
        pathname?: string
      }
    ) => {
      const sharerID = props.sharerID || user?.id || ""
      const urlToShare = new URL(window.location.href)

      if (props.pathname) {
        urlToShare.pathname = props.pathname
      }

      const shareableVariables: ShareableVariables = {
        sharerID,
        shareableID: props.shareableID || "",
        shareableType: props.shareableType || "",
      }

      const url = shareableLib.getShareableURL({
        urlToShare,
        ...shareableVariables,
        workspaceRootPathname: workspaceRootPathname + "",
      })

      return url?.href
    },
    [workspaceRootPathname, user?.id]
  )

  return getShareableLink
}
