import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { usePartnerFormAPI } from "./usePartnerFormAPI"

const { Context, Provider } = contextAndProviderFactory({
  hookApi: usePartnerFormAPI,
  contextName: "PartnerForm",
})

export const PartnerFormContext = Context
export const PartnerFormProvider = Provider
