import { useErrorContext } from "contexts/error"
import _ from "lodash"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useAddPartner from "hooks/useAddPartner/useAddPartner"
import useForm, { UseFormProps } from "hooks/useForm"
import useMemoAPI from "hooks/useMemoAPI"
import apolloErrorMessage from "lib/apolloErrorMessage"
import {
  AddPartnerMutationVariables,
  PartnerFragment,
} from "lib/graphql/operations"
import React from "react"
import * as Yup from "yup"

export function AddNewPartnerProvider(
  props: AddNewPartnerApiInput & {
    children: React.ReactNode
  }
) {
  const api = useAddNewPartnerAPI(props)

  return (
    <AddNewPartnerContext.Provider value={api}>
      {props.children}
    </AddNewPartnerContext.Provider>
  )
}
export const AddNewPartnerContext = React.createContext(
  {} as ReturnType<typeof useAddNewPartnerAPI>
)

AddNewPartnerContext.displayName = "AddNewPartnerContext"

export type InitialValue = Omit<AddPartnerMutationVariables, "workspaceID">

export type AddNewPartnerApiInput = {
  onChangeLoadingState?: (loadingState: boolean) => any
  onSubmit?: (args: { addedPartner: PartnerFragment | undefined | null }) => any
  partnerName?: string
  partnerType?: string
}

export function useAddNewPartnerAPI(props: AddNewPartnerApiInput) {
  const { addPartner } = useAddPartner()
  const { setErrorMessage } = useErrorContext()
  const onChangeLoadingState =
    props.onChangeLoadingState || ((bool: boolean) => undefined)

  const [addedPartners, setAddedPartners] = React.useState<PartnerFragment[]>(
    []
  )

  const t = useI18n()

  const onSubmit = React.useCallback(
    (() => {
      const onSubmit: UseFormProps<typeof initialValues>["onSubmit"] =
        async function onSubmit({ values: data }) {
          onChangeLoadingState(true)

          try {
            let addedPartner = await addPartner(data)

            setAddedPartners((prev) =>
              _.uniqBy([...prev, addedPartner], (a) => a?.id)?.filter(
                (a): a is PartnerFragment => !!a
              )
            )

            if (typeof props.onSubmit === "function")
              props.onSubmit({ addedPartner })
          } catch (e) {
            onChangeLoadingState(false)
            const error = apolloErrorMessage(e)
            if (error?.code === 206) {
              setErrorMessage(t["partner-number-already-in-use"])
            }
            throw e
          }
          onChangeLoadingState(false)
        }

      return onSubmit
    })(),
    [
      props.onChangeLoadingState,
      props.onSubmit,
      addPartner,
      setErrorMessage,
      t,
      setAddedPartners,
    ]
  )

  const initialValues = React.useMemo(() => {
    const v: InitialValue = {
      name1: props.partnerName || "",
      remarks: "",
      no: "",
      taxID: "",
      type: props.partnerType,
    }

    return v
  }, [props.partnerName, props.partnerType])

  const form = useForm({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    // validateOnMount: true,
    validationSchema: Yup.object({
      name1: Yup.string().required(t.required),
      type: Yup.string().required(t.required),
    }),
  })

  const api = useMemoAPI({ ...props, form, addedPartners })

  return api
}
