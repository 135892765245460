import {
  ArticleDetailPanelContext,
  ArticleDetailPanelProvider,
} from "./ArticleDetailPanelContext"
import { ArticleDetailPanelBody } from "./components/ArticleDetailPanelBody"
import { ArticleDetailPanelDefault } from "./components/ArticleDetailPanelDefault"
import { AttributeVariationSelectionModalFrame } from "./components/AttributeVariationSelectionModalFrame"

export type { ArticleDetailPanelInput as ArticleDetailPanelProps } from "./ArticleDetailPanelContext"

export const ArticleDetailPanel = {
  Provider: ArticleDetailPanelProvider,
  Default: ArticleDetailPanelDefault,
  Context: ArticleDetailPanelContext,
  Body: ArticleDetailPanelBody,
  AttributeVariationSelectionModalFrame,
}
