import React from "react"
import isPaymentType from "lib/isPaymentType"
import { I18nAPI } from "contexts/i18nContext/i18nContext"
import { FieldsetIndentationProps } from "../../../../../Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import EuropeanMoneyInput from "../../../EuropeanMoneyInput/EuropeanMoneyInput"
import { DocumentFormAPI } from "components/OldDocumentForm/contexts/DocumentFormContext"
import DateTextField from "components/OldDocumentForm/components/DateTextField/DateTextField"

export default function getMoneyFieldProps(props: {
  translations: I18nAPI
  api: DocumentFormAPI
}) {
  const { translations, api } = props

  const indentationProps: FieldsetIndentationProps | null = (() => {
    const itIsAPaymentType = isPaymentType(api.documentForm.values["type"])

    if (!itIsAPaymentType) return null

    const a: FieldsetIndentationProps = {
      icon: "euro",
      children: (
        <>
          <EuropeanMoneyInput
            label={translations.netValueGoods}
            {...api.documentForm.getFieldProps("netValueGoods")}
          ></EuropeanMoneyInput>

          <EuropeanMoneyInput
            label={translations.totalTax}
            {...api.documentForm.getFieldProps("totalTax")}
          ></EuropeanMoneyInput>

          <EuropeanMoneyInput
            label={translations.totalAmount}
            {...api.documentForm.getFieldProps("totalAmount")}
          ></EuropeanMoneyInput>

          <DateTextField
            {...api.documentForm.getFieldProps("paymentDate")}
            label={translations.paymentDate}
          ></DateTextField>

          <DateTextField
            {...api.documentForm.getFieldProps("postDate")}
            label={translations.postDate}
          ></DateTextField>
        </>
      ),
    }

    return a
  })()
  return indentationProps
}
