import { SubscriptionResult } from "@apollo/client/react/types/types"
import { ApolloClient } from "@apollo/client";
import useMemoAPI from "hooks/useMemoAPI"
import {
  useWorkspacesUpdatesSubscription as useApolloWorkspacesUpdatesSubscription,
  WorkspacesUpdatesSubscription,
} from "lib/graphql/operations"
import updateWorkspace from "lib/updateWorkspace"
import { useCallback, useRef } from "react"
import { Subject } from "rxjs"

export default function useWorkspacesUpdatesSubscription() {
  const workspacesUpdates$ = useRef(
    new Subject<WorkspacesUpdatesSubscription["workspacesUpdates"]>()
  ).current

  const onSubscriptionData = useCallback(
    function onSubscriptionData(props: {
      client: ApolloClient<{}>
      subscriptionData: SubscriptionResult<WorkspacesUpdatesSubscription>
    }) {
      const workspacesUpdates = props.subscriptionData.data?.workspacesUpdates

      if (workspacesUpdates) workspacesUpdates$.next(workspacesUpdates)
      ;(workspacesUpdates || []).forEach((update) => {
        updateWorkspace({ workspaceUpdate: update }, props.client)
      })
    },
    [workspacesUpdates$, updateWorkspace]
  )

  useApolloWorkspacesUpdatesSubscription({ onSubscriptionData })

  const api = useMemoAPI({
    workspacesUpdates$,
  })

  return api
}
