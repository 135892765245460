import { PartnerFragment } from "lib/graphql/operations"
import _ from "lodash"
import React from "react"

export interface IPartnerContext {
  partner?: PartnerFragment | null
  isLoading: boolean
  onClickDocumentsButton?: () => any
}

const PartnerContext = React.createContext({} as IPartnerContext)

PartnerContext.displayName = "PartnerContext"

export default PartnerContext
