import React from "react"
import { Icon, makeStyles } from "@material-ui/core"
import clsx from "clsx"

export default function Indentation(props: {
  children: React.ReactNode
  icon?: React.ReactNode | string | null
  indentation?: number
  left?: React.ReactNode
  className?: string
  leftPosition?: "top" | "center"
  style?: React.CSSProperties
  classes?: { leftWrapper?: string; rightWrapper?: string }
  IconProps?: React.ComponentProps<typeof Icon>
  RightWrapperProps?: {
    className?: string
    style?: React.CSSProperties
  }
}) {
  const indentation =
    typeof props.indentation === "number" ? props.indentation : 45

  const c = useStyles({ indentation })

  return (
    <div
      className={clsx("indented", c.root, props.className)}
      // style={{ position: "relative", ...(props.style || {}) }}
      style={props.style || {}}
    >
      <div
        className={clsx(
          "left",
          c.leftWrapper,
          c[props.leftPosition || "center"],
          props.classes?.leftWrapper
        )}
      >
        {props.left || props.icon ? (
          <React.Fragment>
            {typeof props.icon === "string" ? (
              <Icon {...props.IconProps}>{props.icon}</Icon>
            ) : (
              props.left || props.icon
            )}
          </React.Fragment>
        ) : null}
      </div>
      {/* <div style={{ paddingLeft: indentation }}>{props.children}</div> */}
      <div
        className={clsx(
          "right",
          props.classes?.rightWrapper,
          props.RightWrapperProps?.className
        )}
        style={props.RightWrapperProps?.style}
      >
        {props.children}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",

      "&>.right": {
        flex: 1,
        // overflowX: "hidden",
      },
    },
    leftWrapper: {
      // position: "absolute",
      justifyContent: "center",
      width: (props: { indentation: number }) => props.indentation,
      display: "flex",
    },

    top: {
      // top: 0,
      alignItems: "flex-start",
    },
    center: {
      alignItems: "center",
      // top: "50%",
      // transform: `translateY(-50%)`,
    },
  }
})
