import { makeStyles, Paper, Typography, useTheme } from "@material-ui/core"
import clsx from "clsx"
import Emoji from "components/Emoji/Emoji"
import Indentation from "components/Indentation/Indentation"
import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import Separator from "components/Separator/Separator"
import UserAvatar from "components/UserAvatar/UserAvatar"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { KanbanCommentReactionFragment } from "lib/graphql/operations"
import _ from "lodash"
import React from "react"
import { Modal } from "../Modal/Modal"
import { BottomAndPopoverModal } from "components/BottomAndPopoverModal/BottomAndPopoverModal"

export default function UsersReactionsModal() {
  const c = useStyles()
  const api = React.useContext(KanbanCardPageBoardItemContext)

  const modal = api.comments.reaction.modal
  const usersReactions = modal.userReactions

  const theme = useTheme()

  const t = useI18n()

  return (
    // <Modal.Default
    //   isOpen={usersReactions.isOpen}
    //   onClose={usersReactions.close}
    //   className={clsx(c.root, "users-reactions-modal")}
    // >

    // </Modal.Default>
    <BottomAndPopoverModal
      // isOpen={usersReactions.isOpen}
      // onClose={usersReactions.close}
      // className={clsx(c.root, "users-reactions-modal")}

      popoverAnchorRef={usersReactions.popoverAnchorRef}
      className={clsx("emoji-picker-modal")}
      openClose={{
        isOpen: usersReactions.isOpen,
        open: () => {},
        close: usersReactions.close,
        toggle: () => {},
      }}
    >
      <Paper className="reactions" style={{ height: "100%" }}>
        <div style={{ padding: 16 }}>
          {(() => {
            const comment = usersReactions.comment

            if (!comment?.reactions?.length) return null

            const grouped = _.groupBy(comment.reactions, (r) => r.emojiUnicode)

            const entries: [
              KanbanCommentReactionFragment["emojiUnicode"],
              KanbanCommentReactionFragment[]
            ][] = Object.entries(grouped)

            return entries.map(([emojiUnicode, reactions], index) => {
              const marginBottom = theme.spacing(1)
              return (
                <div
                  key={emojiUnicode}
                  className={clsx("emoji-entry", c.emojiEntry)}
                >
                  <EntryIndentation
                    left={
                      <div
                        style={{
                          lineHeight: 1,
                          ...(index === 0 ? { paddingTop: 5 } : {}),
                        }}
                      >
                        <Emoji unicode={emojiUnicode}></Emoji>
                      </div>
                    }
                    style={{ marginBottom }}
                    text={`${reactions.length} ${
                      reactions.length === 1 ? t["person"] : t["people"]
                    }`}
                  ></EntryIndentation>
                  <Separator style={{ marginBottom }}></Separator>

                  {reactions.map((reaction) => {
                    return (
                      <div className={c.users} key={reaction.user.id}>
                        <EntryIndentation
                          key={reaction.user.id}
                          className="user"
                          text={reaction.user.name}
                          left={
                            <UserAvatar
                              user={reaction.user}
                              AvatarProps={{ style: { width: 29, height: 29 } }}
                              StatusDotProps={{
                                style: { width: 8, height: 8 },
                              }}
                            ></UserAvatar>
                          }
                        ></EntryIndentation>
                      </div>
                    )
                  })}
                </div>
              )
            })
          })()}
        </div>
      </Paper>
    </BottomAndPopoverModal>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    emojiEntry: {
      marginBottom: theme.spacing(5),
    },
    users: {
      "&>*:not(:last-child)": {
        marginBottom: theme.spacing(1.5),
      },
    },
  }
})

function EntryIndentation(
  props: Omit<
    React.ComponentProps<typeof Indentation>,
    "indentation" | "children"
  > & { text: string }
) {
  const theme = useTheme()

  return (
    <Indentation {...props} indentation={theme.spacing(7)}>
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography style={{ lineHeight: 1 }}>{props.text}</Typography>
      </div>
    </Indentation>
  )
}
