import { makeStyles } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import LeftSnackbar from "components/LeftSnackbar/LeftSnackbar"
import React from "react"
import ErrorControllerContext from "../../contexts/ErrorControllerContext"

export default function ErrorSnackbar() {
  const api = React.useContext(ErrorControllerContext)
  const message = api.message

  const c = useStyles()

  return (
    <LeftSnackbar open={!!message}>
      <Alert severity="error" classes={{ root: c.root, icon: c.icon }}>
        {message}
      </Alert>
    </LeftSnackbar>
  )
}

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      alignItems: "center",
    },
    // standardError: {
    //   backgroundColor: red[0],
    //   color: "white",
    // },

    icon: {
      color: "white !important",
    },
  }
})
