import { Icon } from "@material-ui/core"
import React from "react"
import Members from "../Members/Members"
import Chat from "../Chat/Chat"
import RoomTemplateContext from "../RoomTemplate/contexts/RoomTemplateContext"
import { RoomContext } from "components/Room/contexts/room"
import RoomKanban from "components/RoomKanban/RoomKanban"
import IconWithNumber from "components/IconWithNumber/IconWithNumber"

export enum RoomTabIDs {
  CHAT = "CHAT",
  MEMBERS = "MEMBERS",
  KANBAN = "KANBAN",
}

const basicTabs: Record<
  string,
  { label: React.ReactNode; component: React.ReactNode }
> = {
  [RoomTabIDs.CHAT]: {
    label: <ChatIcon></ChatIcon>,
    component: <Chat></Chat>,
  },
  [RoomTabIDs.MEMBERS]: {
    label: <Icon fontSize="small">people</Icon>,
    component: (
      <RoomTemplateContext.Consumer>
        {() => <Members></Members>}
      </RoomTemplateContext.Consumer>
    ),
  },
  [RoomTabIDs.KANBAN]: {
    label: <KanbanIcon></KanbanIcon>,
    component: <RoomKanban.Root></RoomKanban.Root>,
  },
}

export default basicTabs

function KanbanIcon() {
  const { room } = React.useContext(RoomContext)

  return (
    <IconWithNumber
      number={
        ((room?.notificationsCount?.visible &&
          room?.notificationsCount?.kanban) ||
          0) as number
      }
      // number={3}
      showZeroCounting={false}
      iconName="view_kanban"
    ></IconWithNumber>
  )
}

function ChatIcon() {
  const { room } = React.useContext(RoomContext)

  return (
    <IconWithNumber
      number={(room?.unread || 0) as number}
      showZeroCounting={false}
      iconName="chat"
    ></IconWithNumber>
  )
}
