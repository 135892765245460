import React from "react"

const tabSample = {
  label: "",
  id: "",
  AppBar() {
    return null
  },
  Component() {
    return null
  },
}

const PartnerViewContext = React.createContext({
  collectionID: undefined,
  partnerID: undefined,
  type: undefined,
  onChangeLoadingState: () => undefined,
  tabs: [tabSample],
  activeTab: tabSample,
  partner: undefined,
  isPartnerLoading: false,
})

PartnerViewContext.displayName = "PartnerViewContext"

export default PartnerViewContext
