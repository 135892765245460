import React from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import PdfTopBar from "../PdfTopBar/PdfTopBar"
import PdfBody from "../PdfBody/PdfBody"
import PdfContext from "components/Pdf/contexts/PdfContext"

export default function PdfRoot(props: {
  style?: React.CSSProperties
  classes?: { bodyWrapper?: string }
  className?: string
}) {
  const c = useStyles()
  const ctx = React.useContext(PdfContext)

  return (
    <div
      className={clsx(c.root, "pdf-root", props.className)}
      style={props.style || {}}
    >
      <PdfTopBar style={{ zIndex: 1 }}></PdfTopBar>
      <div className={clsx(props.classes?.bodyWrapper, c.bodyWrapper)}>
        <div
          style={{
            transform: `scale(${ctx.scale})`,
            transition: "all 300ms",
          }}
        >
          <PdfBody></PdfBody>
        </div>
      </div>
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      // height: "100vh",
      height: "100%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    bodyWrapper: { flex: 1, overflow: "auto" },
  }
})
