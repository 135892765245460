import _ from "lodash"

import React, { useMemo, useCallback, useContext } from "react"
import Profile from "../Profile/Profile"
import { upload, documents } from "../tabs/tabs"
import { PartnersTabsIDs } from "pages/Partners/types"
import DetailedContactProvider, {
  DetailedContactContext,
  useDetailedContactContext,
} from "contexts/DetailedContactProvider"
import ContactForm from "../ContactForm/ContactForm"

import useUpdateDetailedContact from "hooks/useUpdateDetailedContact/useUpdateDetailedContact"
import useKontaktToggleDelete from "hooks/useKontaktToggleDelete/useKontaktToggleDelete"
import useOnChange from "hooks/useOnChange"
import { useLoadingState } from "components/PageLayoutFromPageID/PageLayoutFromPageID"
import FabsWrapper from "components/FabsWrapper/FabsWrapper"
import PartnersContext from "pages/Partners/contexts/PartnersContext"

export default function ContactProfile() {
  const { collectionID, contactID } = useContext(PartnersContext)
  const [, setLoadingState] = useLoadingState()

  return (
    <DetailedContactProvider
      collectionID={collectionID}
      contactID={contactID}
      fetchPolicy="cache-and-network"
      onChangeLoadingState={setLoadingState}
    >
      <Profile tabs={contactTabs}></Profile>
    </DetailedContactProvider>
  )
}

const contactTabs = [
  {
    id: PartnersTabsIDs.CONTACT,
    label: "Kontakt",
    Component: function KontaktTab({ isActiveView }) {
      const { detailedContact, isLoading } = useDetailedContactContext()
      const [, setLoadingState] = useLoadingState()

      const { updateDetailedContact, isLoading: isUpdating } =
        useUpdateDetailedContact({ contactID: (detailedContact || {}).id })

      const update = useCallback(
        (update) => {
          updateDetailedContact({ dataUpdate: update })
        },
        [updateDetailedContact]
      )

      const { toggleDelete, isLoading: isTogglingDelete } =
        useKontaktToggleDelete({
          kontaktType: "DETAILED_CONTACT",
          id: (detailedContact || {}).id,
        })

      useOnChange({
        value: isLoading || isUpdating || isTogglingDelete,
        onChange: setLoadingState,
      })

      return (
        <FabsWrapper isVisible={isActiveView}>
          <ContactForm
            toggleDelete={toggleDelete}
            updateContact={update}
          ></ContactForm>
        </FabsWrapper>
      )
    },
  },

  documents,

  {
    ...upload,
    Component: function UploadTab({ isActiveView }) {
      const { collectionID } = useContext(PartnersContext)
      const collectionsIDs = [collectionID]

      const { detailedContact } = useContext(DetailedContactContext)

      const partnerID = _.get(detailedContact, "partner.id")

      const p = { partnerID, collectionsIDs }
      const uploadProps = useMemo(() => p, [JSON.stringify(p)])

      return (
        <FabsWrapper isVisible={isActiveView}>
          <upload.Component {...uploadProps} />
        </FabsWrapper>
      )
    },
  },
]
