import { Button, Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import ShopArticleCard from "components/ShopArticleCard/ShopArticleCard"
import React from "react"
import { ArticleDetailPanelContext } from "../ArticleDetailPanelContext"
import Typography from "@material-ui/core/Typography"
import I18nContext from "contexts/i18nContext/i18nContext"

export function ArticlesToSelect() {
  const api = React.useContext(ArticleDetailPanelContext)

  const c = useStyles()

  if (!api.selectedVariation?.attributeName) return null
  if (!api.selectedVariation?.variationName) return null

  return (
    <Paper className={clsx("articles-to-select", c.root)} elevation={10}>
      <div className={c.header}>
        <Typography className={c.headline}>
          <span style={{ fontWeight: 700 }}>
            {api.selectedVariation?.attributeName}:{" "}
          </span>
          {api.selectedVariation?.variationName}
        </Typography>

        <I18nContext.Consumer>
          {(t) => {
            return (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => api.setSelectedVariation(null)}
                size="small"
              >
                {t.close}
              </Button>
            )
          }}
        </I18nContext.Consumer>
      </div>

      <div className={c.articles}>
        {api.selectedVariationArticles?.map((item, i) => {
          const article = item.article || null
          if (!article) return null

          return (
            <ShopArticleCard
              onClick={() => {
                api.selectVariationArticle$.next(item)
              }}
              article={article}
              key={item.id}
            ></ShopArticleCard>
          )
        })}
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflowX: "auto",
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  headline: {
    marginBottom: theme.spacing(1),
  },

  articles: {
    display: "flex",
    "&>*": {
      height: 200,
      width: 150,
      "&:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
  },
}))
