import _ from "lodash"
import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/styles"
import useFormFormik from "./hooks/useFormFormik"
import { Button } from "@material-ui/core"
import clsx from "clsx"
import useOnChange from "hooks/useOnChange"
import Fieldset from "./components/Fieldset/Fieldset"
import useGetInput from "./hooks/useGetInput"
import { white } from "constants/colors"

export default function Form({
  onSubmit = null,
  fieldsets: propsFieldsets,
  fieldset = null,
  className = null,
  grid = null,
  spacing = 2,
  Inputs = {},
  onChange = null,
  SubmitButtonProps = {},
  renderForm = null,
  children = null,
}) {
  const c = useStyles({})

  const fieldsets = useMemo(
    () => propsFieldsets || [{ fieldset }],
    [propsFieldsets, fieldset]
  )

  const formik = useFormFormik({ fieldsets, onSubmit })

  useOnChange({ value: formik.values, onChange })

  const getInput = useGetInput({ formik, fieldsets, Inputs })
  const readyToSubmit = _.isEmpty(formik.errors) && formik.dirty

  const components = {
    fieldsets: fieldsets.map((f, index) => {
      return (
        <Fieldset
          {...f}
          key={`fieldset-${f.name || index}`}
          spacing={f.spacing || spacing}
          grid={f.grid || grid}
          getInput={getInput}
        ></Fieldset>
      )
    }),
    submitButton: (
      <Button
        variant="contained"
        type="submit"
        size="small"
        color="primary"
        disabled={!readyToSubmit}
        {...SubmitButtonProps}
      >
        {_.get(SubmitButtonProps, "children") || "save"}
      </Button>
    ),
  }

  const render = renderForm || children
  const hasRender = _.isFunction(render)

  return (
    <form
      className={clsx(!hasRender && c.root, className)}
      onSubmit={formik.handleSubmit}
    >
      {hasRender ? (
        render({
          components,
          formik,
          grid,
          spacing,
          readyToSubmit,
          getInput,
          className,
        })
      ) : (
        <>
          {components.fieldsets}
          {React.cloneElement(components.submitButton, {
            className: c.submitButton,
          })}
        </>
      )}
    </form>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    submitButton: {
      marginTop: theme.spacing(4),
    },
  }
})
