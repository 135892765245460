import MessageAttachments from "components/MessageAttachments/MessageAttachments"
import { MessageMenuItem } from "components/Room/components/Messages/components/MessageMenu/useMenuItems"
import { RenderMessageProps } from "components/Room/components/Messages/components/MessageRow/components/Message/Message"
import useMemoAPI from "hooks/useMemoAPI"
import useOnChange from "hooks/useOnChange"
import {
  AttachmentFragment,
  MessageFragment,
  useEditedMessageSubscription,
} from "lib/graphql/operations"
import React, { useEffect } from "react"
import { Subject } from "rxjs"
import { ID, OpenKanbanCardPayload, RoomIdentifier } from "types/type"
import useTemplateAPI from "../../components/RoomTemplate/hooks/useTemplateAPI"
import useResolveRoom from "../useResolveRoom/useResolveRoom"

export type { RenderMessageProps }

const openKanbanCard$ = new Subject<OpenKanbanCardPayload>()

// export type RenderMessageProps = {
//   message: MessageFragment
//   MessageBalloonProps: Omit<MessageBalloonProps, "message" | "children">
//   defaultComponent: React.ReactNode
// }

export type RenderMessage = (props: RenderMessageProps) => React.ReactNode

export type RoomAPIInput = {
  identifier: RoomIdentifier
  onChangeRoomID?: (roomID: ID | null) => ID
  onChangeLoadingState?: (isLoading: boolean) => void
  goToPrivateRoom?: (props: { userID: ID }) => Promise<void>
  onChangeIdentifier?: (identifier: RoomIdentifier) => void
  renderMessageMenuItems?: (props: {
    message: MessageFragment
    defaultItems: MessageMenuItem[]
  }) => MessageMenuItem[]
  disableTasksCreationFromTextSelection?: boolean
  initialTopMostItemIndex?: number
  // renderMessage?: (props: {
  //   index: number
  //   defaultComponent: React.ReactNode
  //   isLastMessage: boolean
  // }) => React.ReactNode

  renderMessage?: RenderMessage
  Message?: React.FunctionComponent<RenderMessageProps>
  renderMessageAttachments?: (props: {
    MessageAttachmentProps: React.ComponentProps<typeof MessageAttachments>
  }) => React.ReactNode
}

export function useRoomAPI(props: RoomAPIInput) {
  const resolveRoom = useResolveRoom({ identifier: props.identifier })

  const roomID = resolveRoom?.roomID

  React.useEffect(() => {
    if (typeof roomID === "undefined") return
    if (typeof props.onChangeRoomID !== "function") return
    props.onChangeRoomID(roomID)
  }, [roomID])

  const roomData = resolveRoom.roomData
  const room = roomData.room || null

  const s = useEditedMessageSubscription({
    skip: !room?.id || !room?.workspaceID,
    variables: {
      roomID: room?.id || "",
      workspaceID: room?.workspaceID || "",
    },
  })

  useEffect(() => {
    return () => {
      // s.
    }
  }, [])

  useOnChange({
    value: roomData.isLoading,
    onChange: props.onChangeLoadingState || (() => {}),
  })

  const createRoom = resolveRoom.createRoom

  const template = useTemplateAPI({
    room,
    createRoom,
    roomWasFetched: roomData.roomWasFetched,
  })

  const deleteRoom$ = React.useRef(new Subject<any>()).current

  // const conferenceIsActive = useTrueFalse(false)

  const api = {
    ...props,
    // conferenceIsActive,
    roomAPI: resolveRoom.roomAPI,
    roomID: room?.id || resolveRoom?.roomID,
    template,
    ...roomData,
    room,
    isLoading: (roomData || {}).isLoading || resolveRoom.isResolvingRoom,
    roomIsInexistent: !!(
      roomData.roomIsInexistent || resolveRoom?.roomIsInexistent
    ),
    lastMessageIndex: (() => {
      const totalItems = roomData.messageList.data?.totalItems
      if (typeof totalItems !== "number") return
      return totalItems - 1
    })(),
    createRoom,
    isCreating: resolveRoom.isCreatingRoom,
    openKanbanCard$,
    deleteRoom$,
    clickAttachment$: React.useRef(new Subject<AttachmentFragment>()).current,
  }

  return useMemoAPI(api)
}
