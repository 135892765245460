import React, { useCallback, useContext } from "react"
import ToggleableMenu from "components/ToggleableMenu/ToggleableMenu"
import _ from "lodash"
import clsx from "clsx"
import { Chip, makeStyles, Paper } from "@material-ui/core"
import CollectionDocumentsFilterContext from "../../contexts/CollectionDocumentsFilterContext"

export default function ChipsPaper({}) {
  const c = useStyles({})

  const { DocumentsFilter } = useContext(CollectionDocumentsFilterContext)
  const {
    getLabel,
    selectedCollections,
    unselectCollection,
    getTypeCollections,
    selectCollection,
  } = useContext(DocumentsFilter.Context)

  if (!selectedCollections?.length) return null

  return (
    <Paper className={c.root}>
      {selectedCollections?.map((collection) => {
        return (
          <ToggleableMenu
            key={collection?.id}
            menuItems={
              getTypeCollections
                ?.call(null, collection?.type || "")
                ?.map((c) => ({
                  component: getLabel(c),
                  onClick: selectCollection.bind(null, c),
                  isSelected: c?.id === collection?.id,
                })) || []
            }
            anchor={
              <Chip
                label={getLabel?.call(null, collection)}
                size="small"
                className={c.chip}
                onDelete={unselectCollection?.bind(null, collection?.id)}
              ></Chip>
            }
          ></ToggleableMenu>
        )
      })}
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(0, 2, 0.5),
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  }
})
