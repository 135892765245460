import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { usePaperListAPI } from "../hooks/usePaperListAPI"

const F = contextAndProviderFactory({
  hookApi: usePaperListAPI,
})

export const PaperListApiContext = F.Context
export const PaperListApiProvider = F.Provider
export const PaperListApiConsumer = F.Consumer

export default PaperListApiContext
