import { useCallback } from "react"
import { useAppHttpGet } from "../useAppHttpGet/useAppHttpGet"

export const cache: { [key: string]: string | undefined } = {}

export default function useFetchImageWithAuth() {
  const httpGet = useAppHttpGet()

  const fetchImage = useCallback(
    (src: string): Promise<string | null> => {
      if (cache[src]) return Promise.resolve(cache[src] || "")

      return httpGet(src)
        .then((response) => {
          const blob = response.blob()
          return blob
        })
        .then((blob) => {
          const objectURL = URL.createObjectURL(blob)

          cache[src] = objectURL

          return objectURL
        })
    },
    [httpGet]
  )

  return fetchImage
}
