import { Fab, Tooltip } from "@material-ui/core"
import classNames from "classnames"
import { Icon } from "components/Icon/Icon"
import { ZoomApiInputContext } from "components/Zoom/components/ZoomProvider/ZoomApiInputContext"
import React, { useState } from "react"

interface LeaveButtonProps {
  onLeaveClick: () => void
  onEndClick: () => void
  isHost: boolean
}

const LeaveButton: React.FC<LeaveButtonProps> = ({
  onLeaveClick,
  onEndClick,
  isHost,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isHost) {
      setAnchorEl(event.currentTarget)
    } else {
      onLeaveClick()
    }
  }

  // const a = new Url
  // <Uri></Uri>

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEndClick = () => {
    onEndClick()
    handleClose()
  }

  const leave = isHost ? onEndClick : onLeaveClick

  const manageLeaving = React.useContext(ZoomApiInputContext).manageLeaving

  const handleLeaving = React.useCallback(() => {
    if (manageLeaving) {
      manageLeaving?.(leave)
    } else {
      leave()
    }
  }, [manageLeaving, leave])

  return (
    <>
      <Tooltip title={isHost ? "End session" : "Leave session"}>
        <Fab
          color="secondary"
          size="large"
          // onClick={handleClick}
          // onClick={handleC}
          // onClick={isHost ? onEndClick : onLeaveClick}
          onClick={() => {
            handleLeaving()
          }}
          className={classNames("vc-button")}
        >
          <Icon name="logout" style={{ color: "white" }} />
        </Fab>
      </Tooltip>
    </>
  )
}

export { LeaveButton }
