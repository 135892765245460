import { PopoverProps, PopoverOrigin } from "@material-ui/core"
import bestQuadrantForFloatingElement from "./bestQuadrantForFloatingElement"

export default function popoverPositioning(props: {
  anchorEl?: HTMLElement | null
}) {
  if (!props.anchorEl) return

  const quadrant = bestQuadrantForFloatingElement({
    nodeAnchor: props.anchorEl,
  })

  const positioningByQuadrant: Record<
    typeof quadrant,
    Pick<PopoverProps, "anchorOrigin" | "transformOrigin">
  > = {
    one: {
      anchorOrigin: TOP_LEFT,
      transformOrigin: TOP_RIGHT,
    },
    two: {
      anchorOrigin: TOP_RIGHT,
      transformOrigin: TOP_LEFT,
    },
    three: {
      anchorOrigin: BOTTOM_RIGHT,
      transformOrigin: BOTTOM_LEFT,
    },
    four: {
      anchorOrigin: BOTTOM_LEFT,
      transformOrigin: BOTTOM_RIGHT,
    },
  }

  const positioning = positioningByQuadrant[quadrant]

  return positioning
}

const TOP_LEFT: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
}

const TOP_RIGHT: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
}

const BOTTOM_LEFT: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
}

const BOTTOM_RIGHT: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
}
