import { makeStyles } from "@material-ui/core"
import Color from "color"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { IndexedItems } from "../components/IndexedItems/IndexedItems"
import { useHistoryRegistry } from "./useHistoryRegistry"
import { useMostUsedTypes } from "./useMostUsedTypes"
import usePageBoardNotifications from "./usePageBoardNotifications"
import useTemplate from "./useTemplate"
import useViews, { ViewProps } from "./useViews"

export type PageBoardAPI = ReturnType<typeof usePageBoardAPI>

export type PageBoardAPIProps = {
  // onChangeViewID?: (viewID: string) => void
  // viewID?: ViewID
  view?: ViewProps
}

export default function usePageBoardAPI(props: PageBoardAPIProps) {
  const board = React.useContext(IndexedItems.Context)

  const { selected } = board

  const notifications = usePageBoardNotifications({
    selected: selected.params || null,
  })

  const classes = useStyles()

  const template = useTemplate()

  const views = useViews(props?.view)

  const { historyQuery } = useHistoryRegistry()

  const history = historyQuery?.data?.pageBoardVisitationHistory?.entries || []

  const mostUsedTypes = useMostUsedTypes({
    history,
    types: board.availableTypes,
  })

  const api = useMemoAPI({
    ...board,
    mostUsedTypes,
    // availableTypes: availableTypes,
    // rootKey: [
    //   ...(throttledSearch ? [throttledSearch] : []),
    //   ...(types.value || []),
    // ].join("-"),
    views,
    template,
    notifications,
    history,
    classes,
  })

  return api
}
const useStyles = makeStyles((theme) => {
  return {
    notificationsNumber: {
      marginLeft: theme.spacing(-0.5),
    },

    side: {
      display: "flex",
      "&>*:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },

    filter: {},

    mainWidth: {
      minWidth: "300px",
      maxWidth: "1700px",
      width: "95%",
    },

    border: {
      border: "1px solid " + Color("gray").alpha(0.3).string(),
    },

    selectorBar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(1),
      margin: "0 auto",
    },

    center: {
      margin: "0 auto",
    },
  }
})
