import {
  PdfAttachmentContext,
  PdfAttachmentProvider,
} from "./PdfAttachmentContext"
import { PdfAttachmentRoot } from "./PdfAttachmentRoot"

export const PdfAttachment = {
  Root: PdfAttachmentRoot,
  Context: PdfAttachmentContext,
  Provider: PdfAttachmentProvider,
}
