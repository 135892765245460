import _ from "lodash"
import React, { useMemo } from "react"
import { useDropzone } from "react-dropzone"
import { states } from "../constants"

const { IDLE, ACCEPT, REJECT } = states

export const DropzoneContext = React.createContext({
  dropzoneState: IDLE,
  isDragActive: false,
  getInputProps: () => ({}),
  getRootProps: () => ({}),
  open: () => null,
})

DropzoneContext.displayName = "DropzoneContext"

export function DropzoneProvider({
  multiple,
  accept,
  onDrop,
  children,
  noClick = true,
}) {
  const api = useDropzone({ multiple, accept, onDrop, noClick })

  const { isDragReject, isDragAccept } = api

  const dropzoneState =
    (isDragReject && REJECT) || (isDragAccept && ACCEPT) || IDLE

  const value = useMemo(
    () => ({ ...api, dropzoneState }),
    [dropzoneState, ..._.values(api)]
  )

  return (
    <DropzoneContext.Provider value={value}>
      {children}
    </DropzoneContext.Provider>
  )
}
