export const overlayModes = {
  WINDOW_OVERLAY: "window-overlay",
  DRAGGABLE: "draggable",
}

export const aspectRatioEnums = {
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
}
