import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import FormWithMetaCards from "components/FormWithMetaCards/FormWithMetaCards"
import PartnerContext from "components/Partner/contexts/PartnerContext"
import { FlexSpaceBetween } from "components/Styled"
import { useErrorContext } from "contexts/error"
import { useI18n } from "contexts/i18nContext/i18nContext"
import apolloErrorMessage from "lib/apolloErrorMessage"
import _ from "lodash"
import React, { useCallback } from "react"
import getter from "util/getter"
import DocumentsButton from "./components/DocumentsButton/DocumentsButton"
import InvitationButton from "./components/InvitationButton/InvitationButton"
import usePartnerFieldset from "./hooks/usePartnerFieldset"
import usePartnerModifications from "./hooks/usePartnerModifications"

export default function PartnerForm({
  className = "",
  onChangeLoadingState = undefined,
  hideDocumentsButton = false,
}): JSX.Element | null {
  const fieldset = usePartnerFieldset()
  const { partner } = React.useContext(PartnerContext) || {}
  const t = useI18n()
  const { setErrorMessage } = useErrorContext()

  const partnerID = partner?.id

  const { update: updatePartner, toggleDelete } = usePartnerModifications({
    partnerID: partner?.id,
    onChangeLoadingState,
  })

  const g = getter(partner)
  const c = useStyles({})

  const onSubmit = useCallback(
    (data) => {
      updatePartner(data).catch((e) => {
        const error = apolloErrorMessage(e) || {}
        if (error.message)
          setErrorMessage(
            error.code === 206
              ? t["partner-number-already-in-use"]
              : error.message
          )
      })
    },
    [updatePartner]
  )
  if (_.isEmpty(partner)) return null

  return (
    <FormWithMetaCards
      /**
       * form should be reset when partner changes.
       * It prevents the mixing of data reported at
       * https://trello.com/c/ktpxN6ln/672-bug-if-web-null-old-value-remains
       *
       * by informing the key should change when the partnerID changes,
       * we achieve this effect.
       */
      key={partnerID}
      toggleDelete={toggleDelete}
      className={clsx("partner-form", c.root, className)}
      created={{ by: g("createdBy"), when: g("created") }}
      updated={{ by: g("updatedBy"), when: g("updated") }}
      deleted={{ by: g("deletedBy"), when: g("deleted") }}
      MetaCards={({
        basicMetaCards: { deleted = null, created = null, updated = null } = {},
      } = {}) => [created, updated, deleted]}
      onSubmit={onSubmit}
      subForms={[
        {
          fields: [
            { name: "name1", grid: { md: 6 } },
            {
              render: ({ getInput }) => {
                return (
                  <div className={clsx(c.active, className)}>
                    {getInput("active")}
                  </div>
                )
              },
              grid: { md: 6 },
            },
            "name2",
            "street",
            "zip",
            "city",
            "country",
          ],
        },

        {
          fields: [
            ({ getInput }) => <SecondFormTop typeInput={getInput("type")} />,
            { name: "no", grid: { md: 6 } },
            { name: "taxID", grid: { md: 6 } },
            { name: "url" },
            g("amountOfDocs") &&
              !hideDocumentsButton && {
                render: () => (
                  <DocumentsButton
                    className={c.documentsButton}
                    amountOfDocs={g("amountOfDocs")}
                  />
                ),
                grid: { xs: 6 },
              },
            { name: "ocr", grid: { xs: 6 } },
          ],
        },

        { fields: ["remarks"] },
      ]}
      fieldset={fieldset}
    />
  )
}

function SecondFormTop({ typeInput }) {
  const { isSmallMobile } = React.useContext(DeviceObserver.Context)
  const c = useSecondFormTopStyles({ isSmallMobile })

  return (
    <FlexSpaceBetween className={c.secondFormTop}>
      {React.cloneElement(typeInput, {
        className: clsx(_.get(typeInput, "props.className", c.typeInput)),
      })}
      <InvitationButton />
    </FlexSpaceBetween>
  )
}

const useSecondFormTopStyles = makeStyles((theme) => {
  return {
    secondFormTop: {
      alignItems: "flex-end",
    },

    typeInput: {
      width: (props) => theme.spacing(props.isSmallMobile ? 20 : 30),
    },
  }
})

const useStyles = makeStyles((theme) => {
  return {
    documentsButton: {
      maxWidth: theme.spacing(20),
    },
    active: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      "&>*": {
        marginRight: -12,
      },
    },
  }
})
