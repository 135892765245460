import React from "react"
import useTemplateAPI from "../hooks/useTemplateAPI"

type TemplateAPI = ReturnType<typeof useTemplateAPI>

const RoomTemplateContext = React.createContext<TemplateAPI>({} as TemplateAPI)

RoomTemplateContext.displayName = "RoomTemplateContext"

export default RoomTemplateContext
