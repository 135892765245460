import {
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  LexicalEditor,
  ElementNode,
} from "lexical"
import { $wrapNodes } from "@lexical/selection"
import { $createHeadingNode } from "@lexical/rich-text"
import { BlockType } from "../types"

export default function $wrapSelection(
  blockType: Extract<BlockType, "paragraph" | "h1" | "h2">,
  editor: LexicalEditor
) {
  const createBlockNode: {
    [i in typeof blockType]: () => ElementNode
  } = {
    paragraph: $createParagraphNode,
    h1: $createHeadingNode.bind(null, "h1"),
    h2: $createHeadingNode.bind(null, "h2"),
  }

  editor.update(() => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      $wrapNodes(selection, () => createBlockNode[blockType]())
    }
  })
}
