import { WorkspacesDocument } from "lib/graphql/operations"
import _ from "lodash"

export default function getWorkspace(
  { workspaceID = "", userID = "" },
  client
) {
  if (!workspaceID && !userID) return null

  const queryResult = client.readQuery({ query: WorkspacesDocument }) || {}
  const { workspaces } = queryResult

  const workspace = (workspaces || []).find((w) => {
    return [
      !!workspaceID && _.get(w, "id") === workspaceID,
      !!userID && userID === _.get(w, "user.id"),
    ].some(Boolean)
  })

  return workspace || null
}
