import { useApolloClient, gql } from "@apollo/client";
import { SetKanbanCardCoverImageDocument } from "lib/graphql/operations"
import React from "react"
import { ID } from "types/type"

export default function useSetCoverImage(props: {
  workspaceID: ID
  onChangeLoadingState?: (state: boolean) => void
}) {
  const client = useApolloClient()

  return React.useCallback(
    async function setCoverImage(imageID: ID, cardID: ID) {
      props.onChangeLoadingState?.call(null, true)

      await client
        .mutate({
          mutation: SetKanbanCardCoverImageDocument,
          variables: { workspaceID: props.workspaceID, cardID, imageID },
          optimisticResponse: {
            setKanbanCardCoverImage: {
              __typename: "KanbanCardImages",
              cardID,
              cover: {
                __typename: "KanbanImage",
                id: imageID,
              },
            },
          },
          update(proxy) {
            proxy.writeFragment({
              fragment: gql`
                fragment CardImageCover on KanbanCard {
                  images {
                    cardID
                  }
                }
              `,
              id: `KanbanCard:${cardID}`,
              data: {
                images: {
                  __typename: "KanbanCardImages",
                  cardID,
                },
              },
            })
          },
        })
        .finally(() => props.onChangeLoadingState?.call(null, false))
    },
    [props.onChangeLoadingState, props.workspaceID]
  )
}
