import graphqlErrorCodes from "./graphqlErrorCodes"
import { flow, get, intersection } from "lodash/fp"

export default function isRoomInexistent(graphqlResult) {
  const isInexistent =
    flow(
      graphqlErrorCodes,
      intersection(["invalid-channel", "error-room-not-found"]),
      get("length")
    )(graphqlResult) >= 1

  return isInexistent
}
