import React from "react"
import _ from "lodash"
import { Icon, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"

export default function IconWithNumber(props: {
  iconName?: string
  number?: number
  showZeroCounting?: boolean
  icon?: string
  hideNumber?: boolean
  classes?: { number?: string }
  className?: string
  numberOnLeft?: boolean
}) {
  const {
    icon = "",
    number = 0,
    showZeroCounting = true,
    hideNumber = false,
    classes = { number: "" },
    className = "",
    numberOnLeft = false,
  } = props

  const c = useStyles()

  return (
    <Typography
      variant="caption"
      className={clsx(c.root, "icon-with-number", className)}
    >
      {[
        (() => {
          if (props.iconName) return <Icon>{props.iconName}</Icon>
          if (React.isValidElement(props.icon)) return props.icon

          return null
        })(),
        !hideNumber &&
          (function count() {
            if (typeof number !== "number") return ""
            if (number === 0 && !showZeroCounting) return ""

            return (
              <span className={clsx(c.number, classes.number)}>({number})</span>
            )
          })(),
      ]
        .filter(Boolean)
        .sort(() => (numberOnLeft ? -1 : 1))}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
    },
    number: {
      paddingLeft: theme.spacing(0.5),
    },
  }
})
