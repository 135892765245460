import React from "react"
import SecondPage from "../../SecondPage/SecondPage"
import DocumentCollections from "components/DocumentCollections/DocumentCollections"
import { useI18n } from "contexts/i18nContext/i18nContext"
import TextField from "components/TextField/TextField"
import GroupSelection from "components/GroupSelection/GroupSelection"
import useTypographyClass from "hooks/useTypographyClass"
import {
  SaveButton,
  EnhancedPageProps,
} from "../../../../../../../EnhancedPage/EnhancedPage"
import RichTextField from "components/RichTextField/RichTextField"

export default function AddNewPartner(props: { onClose?: () => any }) {
  const t = useI18n()

  const renderTitleLine = React.useCallback(
    (() => {
      const r: EnhancedPageProps["renderTitleLine"] = function render({
        titleComponent,
      }) {
        return (
          <>
            {titleComponent}
            <DocumentCollections.AddNewPartner.Context.Consumer>
              {({ form }) => {
                return (
                  <SaveButton
                    disabled={!form.readyToSubmit}
                    onClick={() => {
                      form.submit()
                      props.onClose?.call(undefined)
                    }}
                  ></SaveButton>
                )
              }}
            </DocumentCollections.AddNewPartner.Context.Consumer>
          </>
        )
      }

      return r
    })(),
    [props.onClose]
  )

  return (
    <DocumentCollections.AddNewPartner.Provider>
      <SecondPage
        body={<Form></Form>}
        renderTitleLine={renderTitleLine}
        title={t["new-partner"]}
        onClose={props.onClose}
      ></SecondPage>
    </DocumentCollections.AddNewPartner.Provider>
  )
}

function Form() {
  const api = React.useContext(DocumentCollections.AddNewPartner.Context)
  const form = api.form
  const tClass = useTypographyClass()

  const t = useI18n()

  return (
    <div className={"collections-input-page-partner-form"}>
      {(() => {
        const field = form.getFieldProps("type")

        return (
          <GroupSelection
            TextField={TextField}
            classes={{ suggestionsItem: tClass.caption }}
            onChange={(type) => {
              field.onChange({
                target: { value: type, name: "type" },
              })
            }}
            type={field.value}
            filter={(group) => !!group.hasPartnerAssociated}
          />
        )
      })()}
      <TextField {...form.getFieldProps("name1")} label={t.name} />
      <TextField {...form.getFieldProps("taxID")} label={t.taxID} />
      <TextField {...form.getFieldProps("no")} label={t.partnerNumber} />
      <RichTextField
        TextField={TextField}
        textFieldProps={{
          ...form.getFieldProps("remarks"),
          label: t.remarks,
        }}
      />
    </div>
  )
}
