import { ButtonProps } from "@material-ui/core"
import clsx from "clsx"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { Button } from "components/Button/Button"

export default function SaveButton(props: ButtonProps) {
  const translations = useI18n()

  return (
    <Button {...props} className={clsx("save-button", props.className)}>
      {props.children || translations["save"]}
    </Button>
  )
}
