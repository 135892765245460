import classNames from "classnames"
import React from "react"
import { StyledProps } from "types/type"
import { ConferenceContext } from "../../../../ConferenceContext"
import { FabControlButton } from "../FabControlButton/FabControlButton"
import { useI18n } from "contexts/i18nContext/i18nContext"
// import Fab from "components/Fab/Fab"

export function CameraButton(props: StyledProps) {
  const api = React.useContext(ConferenceContext)
  const { isStartedVideo, startVideo } = api.video

  const t = useI18n()

  return (
    <div
      className={classNames("camera-footer", props.className)}
      style={props.style}
    >
      <FabControlButton
        toolTipTitle={`${
          isStartedVideo
            ? t.conference.deactivateCamera
            : t.conference.activateCamera
        }`}
        icon={isStartedVideo ? "videocam" : "videocam_off"}
        onClick={startVideo}
      ></FabControlButton>
    </div>
  )
}
