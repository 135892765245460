import _ from "lodash"
import Downshift, { PropGetters } from "downshift"
import { Paper, MenuItem, Typography } from "@material-ui/core"
import React, { useState, useEffect, useCallback } from "react"
import clsx from "clsx"
import getter from "util/getter"
import useGlobalStyles from "styles/globalStyles"

export default function SuggestionsWrapper<T>(props: {
  items: T[] | null
  className?: string
  renderItem: (props: { item: T }) => React.ReactNode
  onSelect: (item: T) => void
  inputValue?: string
  isOpen: boolean
  classes?: { suggestionsItem?: string }
  onClose: () => void
  children: (props: {
    getInputProps: PropGetters<{ item: T; index: number }>["getInputProps"]
    suggestionsComponent: React.ReactNode
  }) => React.ReactNode
}) {
  const {
    items,
    children,
    className = "",
    renderItem,
    onSelect: propsOnSelect,
    inputValue = "",
    isOpen: propsIsOpen,
    onClose: propsOnClose,
  } = props

  const gs = useGlobalStyles()
  const isControlled = _.isBoolean(propsIsOpen)

  /** TODO: simplify the managing of $isOpen state (maybe using useInputController) */
  const [isOpen, setIsOpen] = useState(isControlled ? propsIsOpen : false)
  const close = useCallback(() => setIsOpen(false), [])

  const hasItems = !_.isEmpty(items)

  useEffect(
    () => {
      const value = isControlled ? propsIsOpen : !!inputValue && hasItems
      setIsOpen(value)
    },
    isControlled ? [propsIsOpen] : [hasItems, !!inputValue]
  )

  useEffect(() => {
    const isClosed = !isOpen
    _.isFunction(propsOnClose) && isClosed && propsOnClose()
  }, [isOpen])

  useEffect(() => {}, [items])

  const onSelect = useCallback(
    function onSelect(item) {
      _.isFunction(propsOnSelect) && propsOnSelect(item)
      close()
    },
    [propsOnSelect]
  )

  return (
    <Downshift
      onOuterClick={close}
      onSelect={onSelect}
      inputValue={inputValue}
      isOpen={isOpen}
      // isOpen
    >
      {(downshift) => {
        const g = getter(downshift)

        // downshift.getMenuProps()

        const suggestionsComponent = isOpen ? (
          // <Paper className="suggestions" {...g("getMenuProps")()}>
          <Paper className="suggestions" {...downshift.getMenuProps()}>
            {(items || []).map((item, index) => (
              <MenuItem
                key={index + ""}
                // selected={g("highlightedIndex") === index}
                selected={downshift.highlightedIndex === index}
                // {...g("getItemProps")({ item, index })}
                {...downshift.getItemProps({ item, index })}
                className={gs.clipText}
              >
                {(function children() {
                  if (_.isString(item))
                    return (
                      <Typography
                        className={clsx(
                          gs.clipText,
                          props.classes?.suggestionsItem
                        )}
                      >
                        {item}
                      </Typography>
                    )

                  const c = (() => {
                    if (React.isValidElement(item)) return item

                    if (_.isFunction(renderItem)) return renderItem({ item })

                    return null
                  })()

                  if (props.classes?.suggestionsItem) {
                    return (
                      <div
                        className={clsx(
                          "suggestions-item",
                          props.classes.suggestionsItem
                        )}
                      >
                        {c}
                      </div>
                    )
                  }

                  return c || null
                })()}
              </MenuItem>
            ))}
          </Paper>
        ) : null

        return (
          <div className={clsx("suggestions-wrapper", className)}>
            {children({
              // getInputProps: g("getInputProps"),
              getInputProps: downshift.getInputProps,
              suggestionsComponent,
            })}
          </div>
        )
      }}
    </Downshift>
  )
}
