import { RefObject, useEffect } from "react"
/**
 * `useClickOutside` is a custom React hook that detects clicks outside of a specified HTML element
 * and triggers a callback function when such a click occurs. This is useful for handling interactions
 * like closing dropdown menus, modals or tooltips when the user clicks outside of the target element.
 *
 * The hook accepts two parameters:
 * - `ref`: A React ref object that points to the target HTML element.
 * - `cb`: A callback function to be executed when a click outside the target element is detected.
 *
 * The hook uses a `useEffect` to set up a "mousedown" event listener on the `document`, which
 * checks if the click event target is outside the specified element using the `contains` method.
 * If the click is outside, it calls the provided callback function. On cleanup, the hook removes
 * the event listener to prevent memory leaks.
 *
 * The hook also accepts an optional third parameter, `options`, which is an object with a `disabled`.
 *
 * @template T - The type of the HTMLElement being monitored for clicks.
 * @param {RefObject<T> | null} ref - A React ref object pointing to the target HTML element.
 * @param {() => void} cb - The callback function to execute when a click outside the target element is detected.
 * @param {{ disabled?: boolean }} [options] - An optional object with a `disabled` property that can be used to disable the hook.
 * @returns {void}
 *
 * @example
 * const MyComponent = () => {
 *   const ref = useRef<HTMLDivElement>(null);
 *   const handleOutsideClick = () => console.log("Clicked outside the div!");
 *
 *   useClickOutside(ref, handleOutsideClick);
 *
 *   return <div ref={ref}>Click outside this div to trigger the callback!</div>;
 * }
 */

export default function useClickOutside<T extends HTMLElement>(
  ref: RefObject<T> | null,
  cb: () => void,
  options?: { disabled?: boolean }
) {
  useEffect(() => {
    if (options?.disabled) return

    const element = ref?.current

    function handleClickOutside(event: Event) {
      if (element && !element.contains(event.target as Node | null)) {
        cb()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, cb, options?.disabled])
}
