import DocumentSelection from "components/DocumentSelection/DocumentSelection"
import React from "react"
import RoomKanban from "../RoomKanban/RoomKanban"
import Chat from "./components/Chat/Chat"
import EditRoomName from "./components/EditRoomName/EditRoomName"
import Members from "./components/Members/Members"
import { Paragraph } from "./components/Messages/components/MessageRow/components/Message/components/ParagraphsMessage/Paragraph"
import { Paragraphs } from "./components/Messages/components/MessageRow/components/Message/components/ParagraphsMessage/Paragraphs"
import { ShopArticlePageBoardItemSummaryParagraph } from "./components/Messages/components/MessageRow/components/Message/components/ParagraphsMessage/ShopArticlePageBoardItemSummaryParagraph"
import Messages from "./components/Messages/Messages"
import RoomName from "./components/RoomName/RoomName"
import RoomTemplate from "./components/RoomTemplate/RoomTemplate"
import SearchMessages from "./components/SearchMessages/SearchMessages"
import SettingsMenu from "./components/SettingsMenu/SettingsMenu"
import basicTabs from "./components/tabs/basicTabs"
// import { RoomLeavingManager } from "./components/VideoConference/LeaveSessionManager/LeaveConferenceDialog"
// import { LeaveConferenceManager } from "../Conference/LeaveConferenceManager/LeaveConferenceManager"
import { VideoConference } from "../ConferenceActivity/VideoConference"
import { RoomContext, useContextRoom } from "./contexts/room"
import { RoomAPIInput, useRoomAPI } from "./hooks/useRoomAPI/useRoomAPI"
import useStyles from "./hooks/useStyles"

export type RoomAPI = ReturnType<typeof useRoomAPI>

const Room = {
  VideoConference,
  Paragraph,
  Paragraphs,
  ShopArticlePageBoardItemSummaryParagraph,
  SettingsMenu,
  RoomName,
  Messages,
  SearchMessages,
  Chat,
  Members: Members,
  EditRoomName,
  Kanban: RoomKanban,
  Template: RoomTemplate,
  DefaultTemplate: RoomTemplate.Default,
  Context: RoomContext,
  Default: function RoomDefaultComponent(props: { roomID: string }) {},
  useContext: useContextRoom,
  useStyles,
  useAPI: useRoomAPI,

  Body: RoomTemplate.Body, //remove
  basicTabs: basicTabs,
  Provider: function RoomProvider(
    props: RoomAPIInput & {
      children: React.ReactChild
    }
  ) {
    const { children, ...roomAPIInput } = props

    const roomAPI = useRoomAPI(roomAPIInput)

    return (
      <RoomContext.Provider value={roomAPI} key={roomAPI.room?.id || ""}>
        <RoomTemplate.Provider
          className="room-template-provider"
          style={{ height: "100%" }}
        >
          <DocumentSelection.Provider>
            <Messages.Provider>{props.children}</Messages.Provider>
          </DocumentSelection.Provider>
        </RoomTemplate.Provider>
      </RoomContext.Provider>
    )
  },
}

export default Room
