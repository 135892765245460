import DocumentForm from "components/DocumentForm/DocumentForm"
import { PageComponentProps } from "components/Page/Page"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import React from "react"
import { HeaderIconButton } from "../../../HeaderIconButton"
import { DocumentPageBoardItemContext } from "../../DocumentPageBoardItemContext"
import { PdfPage } from "../PdfPage/PdfPage"

export function DocumentFormPage(
  args: PageComponentProps & { pdfIsInTheNextPage?: boolean }
) {
  const api = React.useContext(DocumentPageBoardItemContext)
  const document = api.document

  return (
    <ItemTemplate.Provider
      onClose={args.onClose}
      TwoPagesAPIProps={React.useCallback((p) => {
        if (p.rootContainer.width >= 1000 && args.pdfIsInTheNextPage)
          return { openedSecondPage: { id: "PdfPage", Page: PdfPage } }

        return undefined
      }, [])}
    >
      <ItemTemplate.Context.Consumer>
        {(templatePageApi) => {
          const showPdfButton =
            !templatePageApi.twoPagesAPI.secondPage.isOpen &&
            args.pdfIsInTheNextPage

          return (
            <DocumentForm.Provider
              documentID={api.document?.id || ""}
              key={document?.id || ""}
              twoPagesAPI={templatePageApi.twoPagesAPI}
              openPartnerPage={
                api.document?.isProcessing ? undefined : api.openPartnerPage
              }
            >
              <ItemTemplate.Root
                EnhancedPageProps={{
                  titleComponent: (
                    <>
                      <div></div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {showPdfButton && (
                          <HeaderIconButton
                            style={{ marginRight: 8 }}
                            name="picture_as_pdf"
                            onClick={() => {
                              templatePageApi.twoPagesAPI.openHalfPage(PdfPage)
                            }}
                          ></HeaderIconButton>
                        )}
                        <DocumentForm.SaveButton></DocumentForm.SaveButton>
                      </div>
                    </>
                  ),

                  body: (
                    <DocumentForm.Root
                      style={{ height: "100%" }}
                    ></DocumentForm.Root>
                  ),
                }}
              ></ItemTemplate.Root>
            </DocumentForm.Provider>
          )
        }}
      </ItemTemplate.Context.Consumer>
    </ItemTemplate.Provider>
  )
}
