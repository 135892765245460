import ComposeProviders from "components/ComposeProviders/ComposeProviders"
import _ from "lodash"
import React, { useMemo, useEffect, useRef } from "react"

import AppPageLayout from "components/AppPageLayout/AppPageLayout"
import { LoadingProvider, useLoadingContext } from "contexts/loading"

function PageLayoutFromPageID({
  pageID,
  pages,
  className = "",
  mountAllProvidersAtOnce = true,
  Layout = AppPageLayout,
}) {
  const PageLayoutProps = useMemo(() => _.get(pages, pageID), [pageID, pages])

  const loadingProvider = useRef([false, (state) => null])
  const [, setIsLoading] = loadingProvider.current

  useEffect(() => {
    setIsLoading && setIsLoading(false)
  }, [pageID, setIsLoading])

  if (!pageID || !PageLayoutProps) return null

  return (
    <LoadingProvider ref={loadingProvider}>
      {(() => {
        const layout = <Layout {...PageLayoutProps} className={className} />

        const AllProviders = _.values(pages)
          .map((p) => p.Provider || p.provider)
          .filter(Boolean)

        const noProvider = !AllProviders.length

        if (noProvider) return layout

        if (mountAllProvidersAtOnce) {
          return (
            <ComposeProviders Providers={AllProviders}>
              {layout}
            </ComposeProviders>
          )
          // return "bingo"
        }

        const CurrentProvider = PageLayoutProps.Provider

        if (React.isValidElement(CurrentProvider))
          return React.cloneElement(CurrentProvider, { children: layout })

        if (CurrentProvider) return <CurrentProvider>{layout}</CurrentProvider>

        return layout
      })()}
    </LoadingProvider>
  )
}

export default React.memo(PageLayoutFromPageID, function areEqual(prev, next) {
  const props = ["pages", "pageID"]
  const areEqual = props.every((p) => prev[p] === next[p])

  return areEqual
})

export { useLoadingContext as useLoadingState }
