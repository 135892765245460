import React from "react"
import _ from "lodash"
import TwoPages from "components/TwoPages/TwoPages"
import UserPageBoardItemAPI from "./UserPageBoardItemAPI"
import { UserPageBoardItemAPIProps } from "../../types"
import UserPageBoardItemContext from "../../contexts/UserPageBoardItemContext"
import { ItemsInCommonWithUser } from "components/PageBoard/components/ItemsInCommonWithUser/ItemsInCommonWithUser"

export default function UserPageBoardItemProvider(
  props: UserPageBoardItemAPIProps & { children: React.ReactNode }
) {
  return (
    <ItemsInCommonWithUser.Provider userID={props.entry?.user?.id || ""}>
      <TwoPages.Provider>
        <TwoPages.Context.Consumer>
          {(twoPagesAPI) => {
            return (
              <UserPageBoardItemAPI
                twoPagesAPI={twoPagesAPI}
                {..._.omit(props, "children")}
              >
                {(api) => {
                  return (
                    <UserPageBoardItemContext.Provider value={api}>
                      {props.children}
                    </UserPageBoardItemContext.Provider>
                  )
                }}
              </UserPageBoardItemAPI>
            )
          }}
        </TwoPages.Context.Consumer>
      </TwoPages.Provider>
    </ItemsInCommonWithUser.Provider>
  )
}
