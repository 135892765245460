import { IconButton, Typography } from "@material-ui/core"
import { IconProps, Icon } from "components/Icon/Icon"
import PaperList from "components/PaperList"
import StopPropagation from "components/StopPropagation/StopPropagation"
import React from "react"
import formatDate from "../../../../lib/formatDate"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"

type IconButton = { icon?: IconProps["name"]; onClick?: () => void }

export default function EntryRight(props: {
  iconButton?: IconButton | null
  iconsButtons?: IconButton[]
  lastUpdate?: string
  context?: string | null
}) {
  // const { RootContainer } = React.useContext(PaperList.Context)
  const api = React.useContext(ItemsListContext)

  const rootContainer = api.rootContainer

  const iconsButtons = (() => {
    if (props.iconsButtons) return props.iconsButtons
    return props.iconButton ? [props.iconButton] : []
  })()

  if (!iconsButtons && !props.lastUpdate) return null

  return (
    <PaperList.ListItemText
      secondary={
        <>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {iconsButtons.map((b, index) => {
              return (
                <React.Fragment key={index}>
                  {(() => {
                    if (!b.icon) return null

                    return (
                      <StopPropagation>
                        <IconButton size="small" onClick={b?.onClick}>
                          <Icon style={{ fontSize: 19 }} name={b.icon}></Icon>
                        </IconButton>
                      </StopPropagation>
                    )
                  })()}
                </React.Fragment>
              )
            })}
          </div>
        </>
      }
      {...(() => {
        const update = props.lastUpdate

        if (!!update)
          return {
            primary: (
              <>
                <Typography variant="body2" color="textSecondary">
                  {formatDate(update, rootContainer.width > 400)}
                </Typography>
                {/* {(() => {
                  const name = props.context || ""

                  if (!name) return null

                  return (
                    <Typography variant="body2" color="textPrimary">
                      <ClippedText maxWidth={500} style={{ display: "inline" }}>
                        {[name].join(" ")}
                      </ClippedText>
                    </Typography>
                  )
                })()} */}
              </>
            ),
          }

        return {}
      })()}
    ></PaperList.ListItemText>
  )
}
