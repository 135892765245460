import moment from "moment"
import _ from "lodash"
import React from "react"
import { Avatar, Icon } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import BasicDialog from "components/BasicDialog/BasicDialog"
import HeaderDescription from "components/HeaderDescription/HeaderDescription"
import getter from "util/getter"
import { purple } from "constants/colors"
import EditSelectedContactForm from "./components/EditSelectedContactForm/EditSelectedContactForm"

function SelectedContactDialog({ selectedContact: contact, onClose, isOpen }) {
  const { isSmallMobile } = React.useContext(DeviceObserver.Context)
  const c = useStyles({ isSmallMobile })

  const g = getter(contact)

  const [isLoading, setIsLoading] = React.useState(false)

  if (_.isEmpty(contact)) return null

  const name = `${g("firstName") || ""} ${g("lastName") || ""}`

  const created =
    !isSmallMobile && g("created")
      ? moment(g("created")).format("[Erstellt am] DD.MM.YY [um] HH:mm")
      : ""

  return (
    <BasicDialog
      open={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      className={c.selectedDialog}
      classes={{ paper: c.dialog }}
    >
      <div className={c.top}>
        <HeaderDescription
          firstLine={name}
          secondLine={created}
          avatar={
            <Avatar style={{ backgroundColor: purple[0] }}>
              <Icon style={{ color: "white" }}>person</Icon>
            </Avatar>
          }
        ></HeaderDescription>
      </div>

      <EditSelectedContactForm
        selectedContact={contact}
        onUpdate={onClose}
        onChangeLoadingState={setIsLoading}
      ></EditSelectedContactForm>
    </BasicDialog>
  )
}

export default SelectedContactDialog

const useStyles = makeStyles((theme) => {
  return {
    selectedDialog: {},
    top: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(5),
    },
    saveButton: {
      alignSelf: "flex-end",
      marginTop: theme.spacing(6),
    },
    dialog: {
      display: "flex",
      flexDirection: "column",
    },
  }
})
