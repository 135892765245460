import { useQuery } from "@apollo/client";
import _ from "lodash"
import { PublicAppConfigDocument } from "lib/graphql/operations"

export default function usePublicAppConfig({
  fetchPolicy = "cache-first",
} = {}) {
  // @ts-ignore
  const query = useQuery(PublicAppConfigDocument, { fetchPolicy })

  return _.get(query, "data.publicAppConfig", null)
}
