import { makeStyles } from "@material-ui/styles"
import _ from "lodash"
import React from "react"
import { Grid as MuiGrid } from "@material-ui/core"
import clsx from "clsx"

//@TODO: add index.d.ts
function Grid({
  entries = [],
  renderEntry = () => null,
  grid = { xs: 12 },
  spacing = 2,
  onClickItem = () => null,
  className = "",
  classes: { gridItem: gridItemClassName } = {}
}) {
  const c = useStyles()

  return (
    <MuiGrid
      container
      spacing={_.isFinite(spacing) ? spacing : 3}
      className={clsx("grid", className)}
    >
      {(entries || [])
        .map((entry, index) => {
          const component = renderEntry({ entry })

          return (
            component && (
              <MuiGrid
                item
                key={entry.id || index}
                {...grid}
                {...(entry || {}).grid}
                className={clsx(c.item, gridItemClassName, entry.className)}
                onClick={() => _.isFunction(onClickItem) && onClickItem(entry)}
              >
                {component}
              </MuiGrid>
            )
          )
        })
        .filter(Boolean)}
    </MuiGrid>
  )
}

const useStyles = makeStyles(theme => {
  return {
    item: {
      "&>*": {
        height: "100%",
        width: "100%"
      }
    }
  }
})

export default Grid
