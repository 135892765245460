import React from "react"
import { Icon } from "@material-ui/core"
import Page, { PageProps } from "components/Page/Page"
import { useI18n } from "contexts/i18nContext/i18nContext"
import SelectUsers from "./components/SelectUsers/SelectUsers"
import Body from "./components/Body/Body"
import useAddMembersInputPageAPI from "./hooks/useAddMembersInputPageAPI"
import AddMembersInputPageContext from "./contexts/AddMembersInputPageContext"

export default function AddMembersInputPage(props: {
  onClose: () => any
  style?: React.CSSProperties
}) {
  const t = useI18n()
  const HeaderProps = React.useMemo(
    () =>
      (() => {
        const HeaderProps: PageProps["HeaderProps"] = {
          IndentationProps: { left: <Icon>search</Icon> },
          content: <SelectUsers></SelectUsers>,
        }

        return HeaderProps
      })(),
    [t]
  )

  const api = useAddMembersInputPageAPI({ onClose: props.onClose })

  return (
    <AddMembersInputPageContext.Provider value={api}>
      <Page
        className="add-members-input-page"
        style={props.style}
        HeaderProps={HeaderProps}
        body={<Body></Body>}
      ></Page>
    </AddMembersInputPageContext.Provider>
  )
}
