import React from "react"
import { DocumentFormContext } from "components/DocumentForm/contexts/DocumentFormContext"
import TextField from "../../../TextField/TextField"

import CollectionsInputPage from "./components/CollectionsInputPage/CollectionsInputPage"

export default function CollectionsTextField(
  props: React.ComponentProps<typeof TextField>
) {
  const api = React.useContext(DocumentFormContext)

  const collections = api.document?.collections || []

  const open = () => {
    api.twoPagesAPI?.secondPage.openPage({
      Page: CollectionsInputPage.Default,
    })
  }

  return (
    <TextField
      label="Collections"
      value={[collections.length, "Items"].join(" ")}
      InputProps={{
        ...(props?.InputProps || {}),
        readOnly: true,
        disableUnderline: false,
      }}
      onClick={open}
    ></TextField>
  )
}
