import useAppRouter from "hooks/useAppRouter"
import { matchPath } from "react-router-dom"

export default function useParams(path) {
  const { location } = useAppRouter()
  const { pathname } = location

  const pattern = `(.*)?${path}`
  const match = matchPath(pathname, { path: pattern }) || {}

  return match.params || {}
}
