// export const purple = ["#8430c1", "#9457c1", "#4b0082", "#431862", "#310055"]

import {
  chartreuseGlow,
  crimsonHeart,
  dandelionDelight,
  darkRoyalPurple,
  deepPlum,
  forestMoss,
  fuchsiaBloom,
  goldenNugget,
  magentaPunch,
  mustardSeed,
  oliveGrove,
  paleSunshine,
  pearGreen,
  regalPurple,
  royalBurgundy,
  springMeadow,
  wineBerry,
} from "./tokenized-colors"

// export const purple = ["#9586a5", "#776589", "#452650", "#2f1937"]
// export const red = ["#ca5ba3", "#ca3396", "#960091", "#61003f", "#701c53"]
// export const green = ["#b8db62", "#abdb37", "#82b700", "#547700", "#6b8922"]
// export const yellow = ["#e0dd65", "#e0dc38", "#c2bd00", "#7e7b00", "#918f24"]
// export const white = ["#eeeeee", "#f5f5f5"]

// export const purple = [regalPurple, darkRoyalPurple, deepPlum, lavenderCloud]
// export const red = [fuchsiaBloom, magentaPunch, royalBurgundy, crimsonHeart, wineBerry]
// export const green = [springMeadow, chartreuseGlow, pearGreen, forestMoss, oliveGrove]
// export const yellow = [dandelionDelight, goldenNugget, darkRoastCoffee, mustardSeed]

export const purple = [regalPurple, darkRoyalPurple, deepPlum, "#2f1937"] // Solo se reemplazan los 3 primeros
export const red = [
  fuchsiaBloom,
  magentaPunch,
  royalBurgundy,
  crimsonHeart,
  wineBerry,
]
export const green = [
  springMeadow,
  chartreuseGlow,
  pearGreen,
  forestMoss,
  oliveGrove,
]
export const yellow = [
  dandelionDelight,
  // darkRoastCoffee,
  goldenNugget,
  goldenNugget,
  mustardSeed,
]
export const white = [paleSunshine, "#f5f5f5"]
export const logo = "#310055"

const COLORS = {
  purple,
  red,
  green,
  yellow,
}

export default COLORS
