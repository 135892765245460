import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import ControlBar from "components/ControlBar/ControlBar"
import DocumentsGridContext from "components/DocumentsGrid/context/DocumentsGridContext"
import IconButton from "components/IconButton/IconButton"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import SideSheetContext from "components/SideSheet/contexts/sideSheetContext/sideSheetContext"
import COLORS from "constants/colors"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import { useContext } from "react"
import Grid from "../Grid/Grid"
import useGridView, { GRID_VIEWS } from "./hooks/useGridView"
import React from "react"

export default function DocumentsGridMain({
  className = "",
  showInfoButton = false,
  renderDocument = undefined,
  getLink = undefined,
  onClickDocument = (document) => undefined,
  columnCount: propsColumnCount = undefined,
}) {
  const { isMobile } = React.useContext(DeviceObserver.Context)
  const { relatedCollectionsIDs } = useContext(DocumentsGridContext)
  const c = useStyles()
  const { toggleSideSheet } = useContext(SideSheetContext)
  const gridView = useGridView()

  const controlBar = isMobile && (
    <ControlBar>
      <IconButton
        size="small"
        iconName="view_column"
        className={clsx(
          gridView.view === GRID_VIEWS.multiColumn && c.selectedIcon
        )}
        onClick={gridView.toggleView}
      ></IconButton>

      <PurpleBadge
        variant="dot"
        classes={{ badge: c.badge }}
        invisible={!(relatedCollectionsIDs || []).length}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <IconButton
          size="small"
          iconName="filter_list"
          onClick={toggleSideSheet}
        ></IconButton>
      </PurpleBadge>
    </ControlBar>
  )

  return (
    <div className={clsx("main", c.main, className)}>
      {controlBar}
      <Grid
        getLink={getLink}
        onClickDocument={onClickDocument}
        renderDocument={renderDocument}
        showInfoButton={showInfoButton}
        columnCount={
          propsColumnCount ||
          (function columnCount() {
            if (gridView.view === GRID_VIEWS.singleColumn) return 1
            if (gridView.view === GRID_VIEWS.multiColumn) {
              return isMobile ? 2 : 4
            }
          })()
        }
      ></Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    main: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    justifySpaceBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    layoutButtons: {
      display: "flex",
      alignItems: "center",
    },
    selectedIcon: {
      color: COLORS.red[0],
    },
    badge: {
      left: "3px",
      bottom: "21px",
    },
  }
})
