import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import Pdf from "components/Pdf/Pdf"
import { StyledProps } from "types/type"

export function PdfFirstPage(
  props: { height: number; url: string } & StyledProps
) {
  const c = useStyles({ height: props.height })

  return (
    <Pdf.Provider enableCaching url={props.url}>
      <div
        className={clsx("pdf-first-page", c.root, props.className)}
        style={props.style}
      >
        <Pdf.Page index={0}></Pdf.Page>
      </div>
    </Pdf.Provider>
  )
}
export const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: ({ height }: { height: number }) => height,
      overflow: "hidden",
    },
  }
})
