import { makeStyles, Paper, Typography } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import clsx from "clsx"
import Color from "color"
import { Button } from "components/Button/Button"
import { Icon } from "components/Icon/Icon"
import { green } from "constants/colors"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { MessageFragment } from "lib/graphql/operations"
import moment from "moment"
import React from "react"
import { StyledProps } from "types/type"

export type MessageBalloonProps = {
  showAuthorName: boolean
  isCurrentUserMessage: boolean
  message: MessageFragment
  children: (props: {
    components: {
      authorName: React.ReactNode
      bottom: React.ReactNode
    }
  }) => React.ReactNode
} & StyledProps

export default function MessageBalloon(props: MessageBalloonProps) {
  const c = useStyles()
  const t = useI18n()

  const backgroundColor =
    props.isCurrentUserMessage && Color(green[0]).alpha(0.1).string()

  const components = {
    authorName: props.showAuthorName && (
      <Typography
        variant="subtitle2"
        color="textSecondary"
        className="author-name"
      >
        {props.message.author.name}
      </Typography>
    ),
    bottom: (
      <Typography
        // className={c.paperTimestamp}
        variant="caption"
        color="textSecondary"
      >
        {props.message.editedAt ? t["edited"] : ""}{" "}
        {moment(props.message.sentAt).format("HH:mm")}
        {props.message.isFavorite && (
          <Icon
            style={{ fontSize: "1.2em", position: "relative", top: 2 }}
            name="star"
          ></Icon>
        )}
        {/* {message.loading ? (
          <Icon style={{ fontSize: "1.2em" }} color="inherit">
            flight_takeoff
          </Icon>
        ) : (
          moment(message.sentAt).format("HH:mm")
        )} */}
      </Typography>
    ),
  }

  const ref = React.useRef<HTMLDivElement>(null)

  return (
    <Paper
      // style={backgroundColor ? { ...(props.style || {}), backgroundColor } : {}}
      style={{
        ...(props.style || {}),
        ...(backgroundColor ? { backgroundColor } : {}),
      }}
      ref={ref}
      className={clsx("textMessage", props.className || "")}
    >
      <Box p={1} className={c.paperContent} pb={0.5}>
        {props.children?.call(undefined, { components })}
      </Box>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    text: {
      display: "table",
    },
    paperTimestamp: {
      position: "relative",
      float: "right",
      paddingLeft: theme.spacing(2),
      // display: "inline-block"
      display: "flex",
      alignItems: "center",
    },
    paperContent: {
      "&::after": {
        content: "''",
        display: "block",
        clear: "right",
      },
    },
  }
})
