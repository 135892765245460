import _ from "lodash"
import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import useTrueFalse from "hooks/useTrueFalse"
import { KanbanCardImagesFragment } from "lib/graphql/operations"
import useSearchParams from "hooks/useSearchParams/useSearchParams"
import useOnChange from "hooks/useOnChange"
import useUpdateKanbanImage from "./useUpdateKanbanImage"
import Slider from "react-slick"
import useDeleteImage from "./useDeleteImage"

export default function useModalUI(props: {
  images?: KanbanCardImagesFragment | null | undefined
}) {
  const getImage = React.useCallback(
    (imageID?: string | null) => {
      if (!imageID) return null
      const list = props.images?.list

      if (!list?.length) return null

      const r = _.keyBy(list, (a) => a.id)[imageID]

      return r || null
    },
    [props.images?.list]
  )

  const searchParams = useSearchParams({
    keyPrefix: "kanban-modal",
    cleanOnUnmount: true,
  })

  const [paramsImageID] = searchParams.get(["image-id"])

  const [imageID, setImageID] = React.useState<string | null>(
    paramsImageID as string
  )

  useOnChange({
    value: imageID,
    onChange(imageID) {
      if (openness.isFalse) return

      searchParams.set({ "image-id": imageID })
    },
  })

  const openness = useTrueFalse(!!imageID)

  useOnChange({
    value: openness.isTrue,
    onChange(isTrue) {
      if (!isTrue) {
        searchParams.clear()
      }
    },
  })

  const activeIndex = React.useMemo(() => {
    const index = props.images?.list?.findIndex((image) => image.id === imageID)

    if (index === -1 || typeof index === "undefined") return 0

    return index
  }, [props.images, imageID])

  const changeIndex = React.useCallback(
    (imageIndex: number) => {
      const image = props.images?.list?.[imageIndex]

      if (!image) return null

      setImageID(image.id)
    },
    [props.images, setImageID]
  )

  const sliderRef = React.useRef<Slider>(null)

  const updateKanbanImage = useUpdateKanbanImage({ imageID })

  const deleteImage = useDeleteImage({ cardID: props.images?.cardID || "" })

  const deleteSelected = React.useCallback(() => {
    // console.log(imageID)
    if (!imageID) return

    const list = props.images?.list

    const nextImageId =
      list?.[(activeIndex + 1) % list.length].id || imageID || ""

    setImageID(nextImageId)

    setTimeout(() => {
      deleteImage(imageID)
    }, 1000)
  }, [deleteImage, imageID])

  const selected = useMemoAPI({
    index: activeIndex,
    data: props.images?.list?.[activeIndex] || null,
    changeIndex: changeIndex,
    update: updateKanbanImage.update,
    delete: deleteSelected,
  })

  const open = React.useCallback(
    (props: { imageID: string | undefined | null }) => {
      const image = getImage(props.imageID)

      if (!image?.src) return

      setImageID(image.id)

      openness.setTrue()
    },
    [getImage]
  )

  const close = React.useCallback(() => {
    openness.setFalse()
    setImageID(null)
  }, [])

  useOnChange({
    value: props.images?.list?.length || 0,
    onChange(length: number) {
      if (length === 0) close()
    },
  })

  return useMemoAPI({
    open,
    isOpen: openness.isTrue,
    close,
    selected,
    sliderRef,
  })
}
