import { useApolloClient } from "@apollo/client";
import React from "react"
import { SelectAPI } from "components/SelectWithAutocomplete/SelectWithAutocomplete"
import { Option } from "components/SelectWithAutocomplete/types/types"
import { UserFragment } from "lib/graphql/operations"
import { UpdateType, UserListUpdate } from "lib/graphql/types"
import { useRoomsMembersQuery } from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import readFragment from "lib/updateFragment/readFragment"
import { Typename } from "types/type"
import useMemoAPI from "hooks/useMemoAPI"
import { SelectUsersInputProviderProps } from "../types"

export default function useSelectUsersInputAPI(
  props: SelectUsersInputProviderProps
) {
  const [text, setText] = React.useState<string>("")
  const selectRef = React.useRef<SelectAPI>(null)
  const r = useActiveResources()

  const query = useRoomsMembersQuery({
    variables: { workspaceID: r.workspaceID },
    skip: !r.workspaceID,
    fetchPolicy: props.fetchPolicy || "cache-and-network",
  })

  const roomsMembers = React.useMemo(
    (): UserFragment[] => query.data?.roomsMembers || [],
    [query.data]
  )

  const options = React.useMemo((): Option<UserFragment>[] => {
    return roomsMembers.map((u) => ({
      label: [u.name, u.company].filter((a): a is string => !!a).join(" - "),
      value: u.id,
      metadata: u,
    }))
  }, [roomsMembers])

  const [usersListUpdate, setUsersListUpdate] = React.useState<
    UserListUpdate[] | undefined | null
  >(props.value)

  React.useEffect(() => {
    if (!props.onChange) return
    props.onChange({ target: { value: usersListUpdate, name: props.name } })
  }, [usersListUpdate])

  const setListUpdate = React.useCallback(
    (listUpdate: UserListUpdate) => {
      setUsersListUpdate((old) => [
        ...(old || [])?.filter((a) => a.userID !== listUpdate.userID),
        listUpdate,
      ])
    },
    [setUsersListUpdate]
  )

  const addUserOption = React.useCallback((option: (typeof options)[0]) => {
    const user = option?.metadata
    if (!user) return
    setListUpdate({ updateType: UpdateType.Add, userID: user.id })
    selectRef.current?.resetTextField()
  }, [])

  const removeUser = React.useCallback((user: UserFragment) => {
    if (!user) return
    setListUpdate({ updateType: UpdateType.Remove, userID: user.id })
  }, [])

  const client = useApolloClient()

  const selectedUsers = React.useMemo(() => {
    const removedUsersIDs = (usersListUpdate || [])
      ?.filter((u) => u.updateType === UpdateType.Remove)
      .map((a) => a.userID)

    return [
      ...(props.listOfUsers || []).filter(
        (a) => !removedUsersIDs.includes(a.id)
      ),
      ...(usersListUpdate || [])
        ?.filter((u) => u.updateType === UpdateType.Add)
        .map((a) =>
          readFragment(
            { typename: Typename.User, cacheIDParams: { id: a.userID } },
            client
          )
        ),
    ]?.filter((a): a is UserFragment => {
      return !!a
    })
  }, [props.listOfUsers, usersListUpdate])

  return useMemoAPI({
    ...props,
    text,
    setText,
    selectRef,
    addUserOption,
    options,
    removeUser,
    selectedUsers,
  })
}
