import Context from "./context/DocumentsGridContext"
import Provider from "./components/DocumentsGridProvider/DocumentsGridProvider"
import Main from "./components/Main/Main"
import useDocumentsGridProviderValue from "./hooks/useDocumentsGridProviderValue/useDocumentsGridProviderValue"

const DocumentsGrid = {
  Main,
  Context,
  Provider,
  useProviderValue: useDocumentsGridProviderValue,
}

export default DocumentsGrid
