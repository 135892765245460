import { Paper, makeStyles, IconButton, Icon } from "@material-ui/core"
import React from "react"
import clsx from "clsx"
import { ImageViewerAPI } from "components/ImageViewer/@types"
import ZoomSlider from "./components/ZoomSlider/ZoomSlider"
import ImageViewerContext from "components/ImageViewer/contexts/ImageViewerContext"

export default function Zoom(props: { className?: string }): JSX.Element {
  const api = React.useContext(ImageViewerContext)
  const c = useStyles()

  return (
    <Paper
      elevation={2}
      className={clsx("zoom-slider", c.root, props.className)}
    >
      <IconButton size="medium" onClick={() => api.scale.reduce()}>
        <Icon>zoom_out</Icon>
      </IconButton>
      <ZoomSlider></ZoomSlider>
      <IconButton size="medium" onClick={() => api.scale.increase()}>
        <Icon>zoom_in</Icon>
      </IconButton>
    </Paper>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      padding: theme.spacing(1, 0.5),
      alignItems: "center",
      justifyContent: "space-between",
      width: 250,
    },
  }
})
