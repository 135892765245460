import { Typography } from "@material-ui/core"
import React from "react"
import { Variant as TypographyVariant } from "@material-ui/core/styles/createTypography"
import formatPrice from "lib/formatPrice"

export function PriceDescription(props: {
  classes?: { totalPrice?: string }
  price: number
  quantity: number
  typographyVariant?: {
    totalPrice?: TypographyVariant
    unit?: TypographyVariant
  }
}) {
  return (
    <>
      <Typography
        variant={props.typographyVariant?.totalPrice || "h5"}
        className={props.classes?.totalPrice}
      >
        {formatPrice(props.price * (props.quantity || 1), "€")}
      </Typography>
      {props.quantity > 1 && (
        <Typography variant={props.typographyVariant?.unit || "caption"}>
          ({formatPrice(props.price, "€")}
          /unit.)
        </Typography>
      )}
    </>
  )
}
