import { Typography, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import ClippedText from "components/ClippedText/ClippedText"
import RoomPageBoardItem from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/RoomPageBoardItem/RoomPageBoardItem"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import React from "react"
import { Action } from "../../../ControlButtons/ControlButtons"
import UserPageBoardItemContext from "../../contexts/UserPageBoardItemContext"
import { MailButton } from "./MailButton"

export function Title(props: { className?: string }) {
  const api = React.useContext(UserPageBoardItemContext)

  const rootContainer = useRootContainerAPI()
  const maxSize = rootContainer.width < 700 ? 150 : 500

  const user = api.entry?.user || null

  const c = useTitleStyles()

  return (
    <div
      className={clsx("user-page-title-line", c.root, props.className)}
      ref={rootContainer.containerRef}
    >
      <div>
        <Typography variant="h6">
          <ClippedText maxWidth={maxSize}>{user?.name}</ClippedText>
        </Typography>
      </div>

      {/* <ControlButtons actions={actions} collapsed={false}></ControlButtons> */}
    </div>
  )
}
const useTitleStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  }
})
