import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import FabsWrapper from "components/FabsWrapper/FabsWrapper"
import { useLoadingState } from "components/PageLayoutFromPageID/PageLayoutFromPageID"
import Partner from "components/Partner/index"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import useMemoAPI from "hooks/useMemoAPI"
import usePartner from "hooks/usePartner"
import { useParamsContext } from "pages/Partners/contexts/libContext"
import { PartnersTabsIDs } from "pages/Partners/types"
import { useCallback, useContext, useMemo } from "react"
import useGlobalStyles from "styles/globalStyles"
import AddContact from "../components/MainAppBar/components/AddContact/AddContact"
import PartnerAppBarBasic from "../components/PartnerAppBar/PartnerAppBar"
import Contacts from "../components/PartnerProfile/components/Contacts/Contacts"
import { documents, upload } from "../components/tabs/tabs"
import PartnersContext from "../contexts/PartnersContext"

export default function usePartnerViewProvider() {
  const { collectionID, collection } = useContext(PartnersContext)
  const [setLoadingState] = useLoadingState()
  // const { type } = useCollection({ id: collectionID, fields: ["type"] }) || {}

  const type = collection?.type

  const partnerTabs = usePartnerTabs()

  // const activeTab = useMemo(() => {
  //   return partnerTabs.find(({ id }) => id === tabID) || partnerTabs[0]
  // }, [tabID, partnerTabs])

  const { partner, isLoading: isPartnerLoading } = usePartner({
    collectionID,
    type,
    fetchPolicy: "cache-and-network",
  })

  const api = {
    collectionID,
    type,
    onChangeLoadingState: setLoadingState,
    tabs: partnerTabs,
    partner,
    isPartnerLoading,
  }

  return useMemoAPI(api)
}

function usePartnerTabs() {
  const { workspace } = useCurrentWorkspace()
  const shop = (workspace || {}).shop
  const shopIsEnabled = !!shop
  const t = useI18n()

  const tabs = useMemo(
    () => getPartnerTabs({ shopIsEnabled, translations: t }),
    [shopIsEnabled, t]
  )

  return tabs
}

const getPartnerTabs = ({ shopIsEnabled, translations }) =>
  [
    {
      label: "Partner",
      id: PartnersTabsIDs.PARTNER,
      Component: PartnerFormTab,
    },

    {
      label: "Kontakte",
      id: PartnersTabsIDs.CONTACT,

      AppBar: PartnerAppBar,
      Component: PartnerContactsTab,
    },

    documents,
    shopIsEnabled && {
      id: PartnersTabsIDs.ORDER_TEMPLATES,
      label: translations["order-templates"],
      // Component: OrderTemplates,
    },
    {
      ...upload,
      Component: UploadTab,
    },
  ]
    .filter(Boolean)
    .map((a) => {
      return {
        ...a,
        AppBar: a.AppBar || PartnerAppBarBasic,
      }
    })

const useStyles = makeStyles((theme) => {
  return {
    paddingZero: {
      padding: "0 !important",
    },
  }
})

function PartnerContactsTab({ className, isActiveView }) {
  const gs = useGlobalStyles()
  return (
    <FabsWrapper
      showChatBalloon={true}
      isVisible={isActiveView}
      className={clsx(gs.height100Percent, className)}
      // floatingButtons={[<ContactsFab />]}
    >
      <Contacts></Contacts>
    </FabsWrapper>
  )
}
function PartnerAppBar({}) {
  const renderRight = useCallback(
    ({ components: { settings, search }, isMobileSearchActive }) => {
      return (
        <>
          {search}
          {!isMobileSearchActive && <AddContact></AddContact>}
          {!isMobileSearchActive && settings}
        </>
      )
    },
    []
  )

  return <PartnerAppBarBasic renderRight={renderRight}></PartnerAppBarBasic>
}
// function OrderTemplates({ isActiveView }) {
//   const { partner } = useContext(Partner.Context) || {}

//   if (!isActiveView || !partner) return null

//   return <Portfolio partnerID={partner.id}></Portfolio>
// }
function UploadTab({ isActiveView }) {
  const { partner = {} } = useContext(Partner.Context)
  const { collectionID } = useParamsContext()
  const collectionsIDs = [collectionID]

  const p = { partnerID: (partner || {}).id, collectionsIDs }
  const uploadProps = useMemo(() => p, [JSON.stringify(p)])
  const gs = useGlobalStyles()

  const c = useStyles()
  return (
    <FabsWrapper
      showChatBalloon={true}
      isVisible={isActiveView}
      className={clsx(c.paddingZero, gs.height100Percent)}
    >
      <upload.Component {...uploadProps} />
    </FabsWrapper>
  )
}
function PartnerFormTab({ isActiveView }) {
  const [, setLoadingState] = useLoadingState()

  const c = useStyles({})
  const gs = useGlobalStyles()

  return (
    <FabsWrapper
      isVisible={isActiveView}
      showChatBalloon={true}
      className={clsx(c.paddingZero, gs.height100Percent)}
    >
      <Partner.Form onChangeLoadingState={setLoadingState}></Partner.Form>
    </FabsWrapper>
  )
}
