import _ from "lodash"
import {
  PartnerDocument,
  PartnerFragment,
  usePartnerQuery,
} from "lib/graphql/operations"
import useActiveResources from "./useActiveResources"
import useFragment from "./apolloHooks/useFragment"
import { useMemo } from "react"
import { PartnerFragmentDoc } from "lib/graphql/operations"
import { WatchQueryFetchPolicy, useQuery, gql } from "@apollo/client";
import useMemoAPI from "./useMemoAPI"

export default function usePartner(props: {
  id?: string
  collectionID?: string
  type?: string
  fetchPolicy?: WatchQueryFetchPolicy
  partner?: PartnerFragment | null
}) {
  const {
    id = "",
    collectionID = "",
    type = "",
    fetchPolicy = "cache-and-network",
  } = props

  const { workspaceID } = useActiveResources()

  const query = usePartnerQuery({
    fetchPolicy,
    skip: (() => {
      if (!!props.partner) return true
      if (!workspaceID) return true
      if (!id && (!collectionID || !type)) return true

      if (!!collectionID && !type) return true
      if (!collectionID && !!type) return true

      return false
    })(),
    variables: {
      id,
      type,
      collectionID,
      workspaceID,
    },
  })

  // const cachedPartner = useFragment({
  //   fragment: PartnerFragmentDoc,
  //   fragmentName: "Partner",
  //   id: `Partner:${id}`,
  // })

  // const partner = _.get(data, "partner") || cachedPartner
  // const result = { partner, isLoading: loading }

  // return useMemo(() => result, [JSON.stringify(result)])

  return useMemoAPI({
    partner: props.partner || query.data?.partner || null,
    isLoading: query.loading,
  })
}
