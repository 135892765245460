import { gql } from "@apollo/client";
import clsx from "clsx"
import React from "react"
import UserAvatar from "components/UserAvatar/UserAvatar"
import UserPopover from "components/UserPopover/UserPopover"
import useUser from "hooks/useUser/useUser"

export default function UserAvatarWithPopover(props: {
  userID?: string
  className?: string
  classes?: { avatar?: string }
  goToPrivateRoom?: () => void
  avatarBackgroundColor?: string
}) {
  const user = useUser({
    id: props.userID || "",
  })

  if (!user) return null

  return (
    <UserPopover
      className={clsx("user-avatar-with-popover", props.className)}
      userID={props.userID}
      goToPrivateRoom={props.goToPrivateRoom}
    >
      <UserAvatar
        classes={{ avatar: props.classes?.avatar }}
        name={user.name}
        status={user.status}
        backgroundColor={props.avatarBackgroundColor}
        type={user.type}
      />
    </UserPopover>
  )
}
