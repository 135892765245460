import { Button, ButtonBase, Typography, makeStyles } from "@material-ui/core"
import Modal, { DialogModalProps } from "components/Modal/Modal"
import { Month } from "components/MonthCalendar/types"
import { SnappedCarousel } from "components/SnappedCarousel/SnappedCarousel"
import { purple } from "constants/colors"
import I18nContext from "contexts/i18nContext/i18nContext"
import moment from "moment"
import React from "react"
// import { Months } from "../CalendarViews/Months"
import { CalendarView } from "../CalendarView"
import { MonthDaysView } from "../MonthDaysView"
import { Months } from "./Months"

export function IntervalPeriodModal(props: {
  isOpen: boolean
  onClose: () => any
}) {
  const c = useStyles()

  const dialogProps: Omit<DialogModalProps, "children" | "isOpen"> = {
    variant: "dialog",
    DialogProps: {
      classes: {
        paper: c.dialogPaperClass,
      },
    },
  }

  const [activeViewIndex, setActiveViewIndex] = React.useState(0)

  const views = [
    <MonthDaysView
      onClose={props.onClose}
      renderTitle={(defaultComponent) => (
        <ButtonBase onClick={() => setActiveViewIndex(1)}>
          {defaultComponent}
        </ButtonBase>
      )}
    ></MonthDaysView>,
    <CalendarView
      renderTitle={(date) => date.format("YYYY")}
      additionPeriod="year"
      body={
        <Months.Context.Consumer>
          {(api) => {
            const activeMonth = api.list.activeMonth.month

            const isActive = (month: Month) => {
              return (
                month.index === activeMonth.index &&
                month.year === activeMonth.year
              )
            }

            return (
              <I18nContext.Consumer>
                {(t) => {
                  return (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        padding: 8,
                      }}
                    >
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {Array.from({ length: 12 }).map((__, index) => {
                          const month = { index, year: activeMonth.year }
                          const isSelected = isActive(month)

                          return (
                            <ButtonBase
                              key={index}
                              onClick={() => {
                                api.list.activeMonth.setMonth(month)
                                setActiveViewIndex(0)
                              }}
                              style={{
                                width: "25%",
                                flex: 1,
                                padding: 24,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                ...(isSelected
                                  ? {
                                      background: purple[1],
                                      color: "white",
                                    }
                                  : {}),
                              }}
                            >
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                {...(isSelected
                                  ? { style: { color: "white" } }
                                  : {})}
                              >
                                {moment().month(index).format("MMM")}
                              </Typography>
                            </ButtonBase>
                          )
                        })}
                      </div>

                      <Button
                        onClick={() => setActiveViewIndex(0)}
                        size="small"
                        style={{ alignSelf: "flex-end" }}
                        variant="outlined"
                      >
                        {t.done}
                      </Button>
                    </div>
                  )
                }}
              </I18nContext.Consumer>
            )
          }}
        </Months.Context.Consumer>
      }
    ></CalendarView>,
  ]

  return (
    <Modal {...props} {...dialogProps}>
      {/* <Months.Provider period={period} onChangeInterval={onChange}> */}
      <SnappedCarousel
        className={c.carousel}
        count={views.length}
        activeIndex={activeViewIndex}
        setActiveIndex={setActiveViewIndex}
        classes={{
          snapped: c.snapped,
        }}
      >
        {(p) => {
          return views[p.index]
        }}
      </SnappedCarousel>
      {/* <HorizontalMonths.Context.Consumer>
          {(api) => {
            console.log(api.list.activeMonth.index)

            return (
              <UseEffect
                deps={[props.isOpen]}
                effect={() => {
                  setTimeout(() => {
                    if (props.isOpen && props.period?.start) {
                      api.list.activeMonth.setMonth(() => ({
                        index: props.period.start.month(),
                        year: props.period.start.year(),
                      }))
                    }
                  }, 0)
                }}
              ></UseEffect>
            )
          }}
        </HorizontalMonths.Context.Consumer> */}
      {/* </Months.Provider> */}
    </Modal>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    carousel: {
      "&>*": {
        height: "100%",
      },
    },

    snapped: {
      "&>*": {
        height: "100%",
      },
    },
    dialogPaperClass: {
      width: "90%",
      maxWidth: 330,
    },
  }
})
