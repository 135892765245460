import { Icon, IconButton } from "@material-ui/core"
import { NewPageBoardItem_DocumentPageBoardItem_Fragment } from "lib/graphql/operations"
import React from "react"
import { ItemsListContext } from "../../context/ItemsListContext"

export default function OpenDocumentButton(props: {
  entry?: NewPageBoardItem_DocumentPageBoardItem_Fragment
}) {
  const api = React.useContext(ItemsListContext).indexedItemsApi

  const entry = props.entry

  if (!entry) return null

  return (
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation()

        api.selected.set(entry)
      }}
    >
      <Icon color="primary" style={{ fontSize: 19 }}>
        description
      </Icon>
    </IconButton>
  )
}
