import React from "react"
import { useSubscription } from "observable-hooks"
import { filter, map, tap } from "rxjs/operators"
import GraphqlSubscriptions from "components/GraphqlSubscriptions/GraphqlSubscriptions"
import useMemoAPI from "hooks/useMemoAPI"
import { combineLatest, Observable } from "rxjs"
import AppVisibility from "components/AppVisibility/AppVisibility"

//it gives which index marks the start of unread messages
export default function useGuideLine(props: {
  loadedFirstUnreadIndex$: Observable<number | null | undefined>
}) {
  const { appIsVisible$ } = React.useContext(AppVisibility.Context)

  const {
    workspaceUserNewMessageListItem$,
    roomNewMessage$: roomNewMessageUpdate$,
  } = React.useContext(GraphqlSubscriptions.Context)

  const [messageIndex, setMessageIndex] = React.useState<
    number | undefined | null
  >()

  useSubscription(props.loadedFirstUnreadIndex$, setMessageIndex)

  useSubscription(workspaceUserNewMessageListItem$, () => {
    setMessageIndex(null)
  })

  const update$ = React.useMemo(() => {
    return combineLatest([roomNewMessageUpdate$, appIsVisible$]).pipe(
      filter(([__, appIsVisible]) => {
        return !appIsVisible
      }),
      map(([update]) => update),

      map((r) => r?.notifications?.firstUnreadMessageIndex)
    )
  }, [])

  useSubscription(update$, setMessageIndex)

  const hasUnreadLine = React.useCallback(
    (props: { index: number }) => {
      return messageIndex === props.index
    },
    [messageIndex]
  )

  return useMemoAPI({
    hasUnreadLine,
  })
}
