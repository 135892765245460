import MessageAttachments from "components/MessageAttachments/MessageAttachments"
import { RoomAPI } from "components/Room/Room"
import React from "react"
import { hasRenderer, renderAttachmentLib } from "../renderAttachmentLib"

export function useRenderMessageAttachments() {
  return React.useCallback<
    Exclude<RoomAPI["renderMessageAttachments"], undefined>
  >((t) => {
    return (
      <MessageAttachments
        {...t.MessageAttachmentProps}
        renderPageBoardItemAttachment={(props) => {
          const item = props.attachment.item

          if (!hasRenderer(item.__typename)) return null

          const j = renderAttachmentLib[item.__typename]({
            attachment: props.attachment,
            onClick() {},
          })

          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation()
                t.MessageAttachmentProps.onClickAttachment?.(props.attachment)
              }}
            >
              {j}
            </div>
          )
        }}
      ></MessageAttachments>
    )
  }, [])
}
