import { gql } from "@apollo/client";
import readFragment from "./readFragment"

export default function getPrivateRoomFromCache({ userID }, apolloClient) {
  const user = readFragment(
    {
      fragment: gql`
        fragment UserWithPrivateRoom on User {
          privateRoom {
            id
          }
        }
      `,
      id: `User:${userID}`
    },
    apolloClient
  )

  return (user || {}).privateRoom || null
}
