import _f from "lodash/fp"
// import { Attachment  } from "@types"
import AttachmentProvider from "./components/ImageAttachmentProvider/ImageAttachmentProvider"
import ImageAttachmentContext from "./contexts/ImageAttachmentContext"
import { ImageAttachmentRoot } from "./components/ImageAttachmentRoot/ImageAttachmentRoot"

const ImageAttachment = {
  Provider: AttachmentProvider,
  Root: ImageAttachmentRoot,
  Context: ImageAttachmentContext,
}

export default ImageAttachment
