import React from "react"
import ContentPopover from "components/ContentPopover/ContentPopover"
import {
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Icon,
} from "@material-ui/core"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import clsx from "clsx"

type MenuOnClickProps = {
  children: React.ReactNode
  renderItem: (props: { index: number; isOpen: boolean }) => React.ReactNode
  itemsCount: number
  onClickItem: ({ index }: { index: number }) => void
}

export default function MenuOnClick(props: MenuOnClickProps) {
  const ref = React.useRef<{
    openPopover(args: { anchorAbsoluteX: number; anchorAbsoluteY: number }): any
    closePopover(): any
  } | null>(null)

  const { isMobile, isDesktop } = React.useContext(DeviceObserver.Context)
  const showEvent = isMobile ? "long-press" : "contextmenu"

  const menu = (
    <Menu
      itemsCount={props.itemsCount}
      onClickItem={({ index }) => {
        props.onClickItem({ index })
        ref.current?.closePopover()
      }}
    >
      {({ index }) => props.renderItem({ index })}
    </Menu>
  )

  const c = useStyles({ isDesktop })

  return (
    <ContentPopover
      className={clsx("menu-on-click", c.root)}
      showEvent={showEvent}
      content={menu}
      ref={ref}
    >
      <IconButton
        edge="end"
        className="toggler"
        size="small"
        onClick={(e) => {
          e.stopPropagation()
          const { top, left } = (
            e.target as HTMLElement
          ).getBoundingClientRect()

          ref.current?.openPopover({
            anchorAbsoluteX: left,
            anchorAbsoluteY: top,
          })
        }}
      >
        <Icon>more_vert</Icon>
      </IconButton>
      {props.children}
    </ContentPopover>
  )
}

function Menu(props: {
  itemsCount: MenuOnClickProps["itemsCount"]
  onClickItem: MenuOnClickProps["onClickItem"]
  children: (props: { index: number }) => MenuOnClickProps["children"]
}) {
  return (
    <MenuList>
      {Array.from({ length: props.itemsCount }).map((_, index) => (
        <MenuItem
          key={`Item-${index}`}
          onClick={() => props.onClickItem({ index })}
        >
          {React.cloneElement(<>{props.children({ index })}</>)}
        </MenuItem>
      ))}
    </MenuList>
  )
}

type Style = { isDesktop: boolean }

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "relative",
      // paddingRight({ isDesktop }) {
      //   return theme.spacing(isDesktop ? 0 : 3)
      // },
      "& .toggler": {
        position: "absolute",
        zIndex: 1,
        opacity: 0.3,
        right: theme.spacing(-1),
        bottom: theme.spacing(-0.3),
        // transform({ isDesktop }) {
        //   if (isDesktop) return ""
        //   return "translateX(100%)"
        // },
        display({ isDesktop }: Style) {
          return isDesktop ? "none" : "block"
        },
      },
      "&:hover": {
        "& .toggler": {
          display({ isDesktop }: Style) {
            if (!isDesktop) return "initial"
            return isDesktop ? "block" : "none"
          },
        },
      },
    },
  }
})
