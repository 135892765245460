import { makeStyles, useTheme } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import Separator from "components/Separator/Separator"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import ActivityEntry from "../ActivityEntry/ActivityEntry"
import AddCommentInput from "../AddCommentInput/AddCommentInput"
import BoardAndColumnSelection from "../BoardAndColumnSelection/BoardAndColumnSelection"
import CardImage from "../CardImage/CardImage"
import CoverContainer from "../CoverContainer/CoverContainer"
import ImagesCarousel from "../ImagesCarousel/ImagesCarousel"
import KanbanCardFab from "../KanbanCardFab/KanbanCardFab"
import DescriptionField from "./DescriptionField"

export default function Body(props: {
  ActivitiesProps?: { style?: React.CSSProperties; className?: string }
  className?: string
  style?: React.CSSProperties
}) {
  const api = React.useContext(KanbanCardPageBoardItemContext)
  const theme = useTheme()
  const t = useI18n()
  const c = useStyles()

  return (
    <div
      className={clsx("kanban-board-body", props.className, c.root)}
      style={props.style}
    >
      <main className={c.scrollbars} ref={api.containerRef}>
        <CoverContainer></CoverContainer>
        <div className={clsx(c.main, c.verticalSpacing, c.horizontalSpacing)}>
          <BoardAndColumnSelection.Default></BoardAndColumnSelection.Default>
        </div>

        {/* <div className={c.separator} /> */}
        <Separator></Separator>
        <ItemTemplate.Indentation
          icon="subject"
          className={c.verticalSpacing}
          leftPosition="top"
        >
          <DescriptionField></DescriptionField>
        </ItemTemplate.Indentation>
        {(() => {
          if (!api.images.data?.list?.length) return null

          return (
            <React.Fragment>
              <Separator></Separator>
              <div
                className={clsx(c.verticalSpacing)}
                style={{ width: "100%", maxWidth: "100%" }}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                  style={{ marginBottom: theme.spacing(2) }}
                  className={clsx(c.horizontalSpacing)}
                >
                  {t["attachments"]}
                </Typography>

                <div
                  // className={clsx("activities-list", c.activitiesList)}
                  style={{ paddingBottom: 21 }}
                >
                  <ImagesCarousel
                    SliderProps={{
                      dots: true,
                      infinite: true,
                      speed: 500,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      // ref:
                    }}
                    children={(i) => {
                      return (
                        <CardImage
                          image={i}
                          imgProps={{ className: c.image }}
                          render={(p) => {
                            const objectURL = p.objectURL

                            if (!objectURL) return null

                            return (
                              <div
                                key={i.id}
                                className="image-item"
                                onClick={() => p.openImage()}
                              >
                                <div className={c.imageContainer}>
                                  {p.defaultComponent}
                                </div>
                              </div>
                            )
                          }}
                        ></CardImage>
                      )
                    }}
                  ></ImagesCarousel>
                </div>
              </div>
            </React.Fragment>
          )
        })()}

        {/* <div className={c.separator} /> */}
        <Separator></Separator>
        <div
          className={clsx(
            c.verticalSpacing,
            c.activitiesContainer,
            "activities"
          )}
        >
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ marginBottom: theme.spacing(2) }}
            className={clsx(c.horizontalSpacing)}
          >
            {t["activities"]}
          </Typography>

          <div
            className={clsx("activities-list", c.activitiesList)}
            // style={}
          >
            {!api.viewport?.isSmallView && <AddCommentInput></AddCommentInput>}
            {api.activities.data?.map((activity) => {
              return (
                <ActivityEntry
                  className="activity-entry"
                  key={activity.id}
                  activity={activity}
                ></ActivityEntry>
              )
            })}
          </div>
        </div>
      </main>

      <KanbanCardFab className="add-comment-button"></KanbanCardFab>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    // content
    scrollbars: {
      maxHeight: "100%",
      display: "flex ",
      flexDirection: "column",
      overflowY: "auto",

      "&>.activities": {
        flex: 1,
      },

      "&>div:first-child": {
        display: "flex",
        flexDirection: "column",
        "&>.activities": {
          flex: 1,
        },
      },
    },
    root: {
      display: "flex",
      flexDirection: "column",

      position: "relative",

      "& .add-comment-button": {
        position: "absolute",
        right: theme.spacing(2),
        bottom: theme.spacing(2),
        // zIndex: 1,
      },

      "& main": {
        flex: 1,
        background: "white",
      },
    },
    title: { opacity: 1 },
    image: {
      position: "absolute",
      height: "100%",

      left: "50%",
      transform: "translateX(-50%)",
    },
    imageContainer: {
      width: "100%",
      height: 150,
      position: "relative",
      overflow: "hidden",
    },

    // separator: ,
    titleInput: { padding: 0 },
    activitiesList: {
      paddingBottom: theme.spacing(2),
      "&>*": {
        marginBottom: theme.spacing(2),
      },
    },
    verticalSpacing: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },

    activitiesContainer: {
      background: "rgba(0,0,0,0.03)",
    },

    horizontalSpacing: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    main: {
      "& .text": {
        marginTop: theme.spacing(0.5),
        opacity: 0.7,
      },
    },
  }
})
