import _ from "lodash"
import {
  IndexedItems,
  IndexedItemsApiInput,
} from "../IndexedItems/IndexedItems"
import {
  PageBoardItemsAutoCompleteProps,
  PageBoardItemsAutoCompleteProvider,
} from "./PageBoardItemsAutoCompleteContext"
import { PageBoardItemsAutoCompleteRoot } from "./PageBoardItemsAutoCompleteRoot"

export function PageBoardItemsAutoComplete(
  props: PageBoardItemsAutoCompleteProps & {
    filter?: IndexedItemsApiInput["filter"]
  }
) {
  return (
    <IndexedItems.Provider filter={props.filter}>
      <PageBoardItemsAutoCompleteProvider {..._.omit(props, "filter")}>
        <PageBoardItemsAutoCompleteRoot></PageBoardItemsAutoCompleteRoot>
      </PageBoardItemsAutoCompleteProvider>
    </IndexedItems.Provider>
  )
}
