import { Icon, InputAdornment, makeStyles, TextField } from "@material-ui/core"
import _ from "lodash"
import clsx from "clsx"
import React from "react"
import arePropsEqual from "util/arePropsEqual"

function TextInputWithReset({
  onChange = (value) => undefined,
  value,
  className = "",
  placeholder = "",
  variant = undefined,
  classes = undefined,
  inputProps = undefined,
}) {
  const c = useStyles({})

  return (
    <TextField
      onChange={(e) => onChange(e.target.value)}
      variant={variant || "outlined"}
      placeholder={placeholder || ""}
      className={clsx("text-input-with-reset", c.textField, className)}
      classes={classes}
      value={value || ""}
      inputProps={{
        ...(inputProps || {}),
        className: clsx(c.input, _.get(inputProps, "className")),
      }}
      InputProps={{
        endAdornment: value && (
          <InputAdornment
            position="end"
            className={c.cursor}
            onClick={() => onChange("")}
          >
            <Icon fontSize="small">close</Icon>
          </InputAdornment>
        ),
      }}
    ></TextField>
  )
}

export default React.memo(
  TextInputWithReset,
  arePropsEqual([
    "onChange",
    "value",
    "inputProps",
    "placeholder",
    "className",
    "classes",
  ])
)

const useStyles = makeStyles((theme) => {
  return {
    textField: {
      display: "block",
      width: "100%",
      "&>*": {
        width: "100%",
      },
    },
    cursor: {
      cursor: "pointer",
    },
    input: {
      padding: theme.spacing(1, 0, 1, 1.5),
      width: "100%",
    },
  }
})
