import useComponentSize from "@rehooks/component-size"
import { PurpleBadgeProps } from "components/PurpleBadge/PurpleBadge"
import useMemoAPI from "hooks/useMemoAPI"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import React from "react"
import { StyledProps } from "types/type"
import { ItemGridCellContext } from "./ItemGridCellContext"

export type CellThumbnailProps = {
  src: string
  lightOpacity?: boolean
  placeholder?: React.ReactNode
  BadgeProps?: PurpleBadgeProps
}

export function useCellThumbnail(props: CellThumbnailProps) {
  const api = React.useContext(ItemGridCellContext)

  const rootRef = React.useRef<HTMLDivElement>(null)
  const r = useComponentSize(rootRef)

  const rootWidth = r.width

  const [imageAspect, setImageAspect] = React.useState<number | null>(null)

  const [error, setError] = React.useState(false)

  React.useEffect(() => {
    setError(false)

    const image = new Image()

    image.onload = (e) => {
      const target = e.currentTarget as HTMLImageElement
      setImageAspect(target.naturalWidth / target.naturalHeight)
    }

    image.onerror = () => {
      setError(true)
    }

    image.src = props.src
  }, [props.src])

  const imageIsLoaded = imageAspect !== null && !isNaN(imageAspect)

  const imageStyle: StyledProps["style"] = (() => {
    if (!api.entry.thumbnail?.crop) return { maxWidth: "100%" }
    if (imageAspect === null || isNaN(imageAspect)) return { maxWidth: "100%" }

    const cropStyle = api.entry.thumbnail?.crop
    const width = rootWidth / (cropStyle.width / 100)
    const height = width / imageAspect

    return {
      width,
      position: "relative",
      right: width * (cropStyle.x / 100),
      bottom: height * (cropStyle.y / 100),
    }
  })()

  return useMemoAPI({
    ...props,
    rootRef,
    imageStyle,
    error,
    imageIsLoaded,
    setError,
    imageAspect,
    setImageAspect,
    rootWidth,
    size: r,
  })
}

const F = contextAndProviderFactory({
  hookApi: useCellThumbnail,
})

const CellThumbnailProvider = F.Provider
const CellThumbnailContext = F.Context

export function CellThumbnailRoot() {
  const api = React.useContext(CellThumbnailContext)

  const { rootRef, imageStyle } = api

  return (
    <div className="cell-thumbnail" ref={rootRef}>
      {(() => {
        if ((api.error || !api.imageIsLoaded) && !!api.placeholder)
          return api.placeholder

        return <img src={api.src} style={imageStyle}></img>
      })()}
    </div>
  )
}

export const CellThumbnail = {
  Root: CellThumbnailRoot,
  Context: CellThumbnailContext,
  Provider: CellThumbnailProvider,
}
