import React from "react"
import { KanbanCardFragment } from "lib/graphql/operations"
import { ShareableType } from "types/type"
import { useI18n } from "contexts/i18nContext/i18nContext"
import LinkButtonWithIcon from "components/LinkButtonWithIcon/LinkButtonWithIcon"
import Shareable from "components/Shareable/Shareable"
import { RoomContext } from "components/Room/contexts/room"

export default function LinkToTheCardButton(props: {
  card?: {
    id: KanbanCardFragment["id"]
    title?: KanbanCardFragment["title"]
    isDeleted?: KanbanCardFragment["isDeleted"]
  } | null
}) {
  const t = useI18n()

  const api = React.useContext(RoomContext)

  const cardTitle = props.card?.title || ""
  const cardID = props.card?.id || ""

  const noCard = !props.card || !!props.card.isDeleted

  const link = (
    <LinkButtonWithIcon
      disabled={noCard}
      description={(() => {
        if (noCard) return t["deletedTask"]
        return cardTitle || t["card-without-title"]
      })()}
      iconName={Shareable.icons[ShareableType.KanbanCard]}
      onClick={() => {
        if (!cardID) return
        api.openKanbanCard$.next({ cardID })
      }}
    ></LinkButtonWithIcon>
  )

  return link
}
