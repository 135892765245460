import React from "react"
import isPaymentType from "lib/isPaymentType"
import { I18nAPI } from "contexts/i18nContext/i18nContext"
import { FieldsetIndentationProps } from "../../../../../Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import EuropeanMoneyInput from "../../../EuropeanMoneyInput/EuropeanMoneyInput"
import { DocumentFormAPI } from "components/DocumentForm/contexts/DocumentFormContext"
import DateTextField from "components/DocumentForm/components/DateTextField/DateTextField"

export default function getMoneyFieldProps(props: {
  translations: I18nAPI
  api: DocumentFormAPI
  summarized?: boolean
}) {
  const { translations, api } = props

  const indentationProps: FieldsetIndentationProps | null = (() => {
    const itIsAPaymentType = isPaymentType(api.form.values["type"])

    if (!itIsAPaymentType) return null

    const a: FieldsetIndentationProps = {
      icon: "euro",
      children: (
        <>
          {!props.summarized && (
            <EuropeanMoneyInput
              label={translations.netValueGoods}
              {...api.form.getFieldProps("netValueGoods")}
            ></EuropeanMoneyInput>
          )}

          {!props.summarized && (
            <EuropeanMoneyInput
              label={translations.totalTax}
              {...api.form.getFieldProps("totalTax")}
            ></EuropeanMoneyInput>
          )}

          <EuropeanMoneyInput
            label={translations.totalAmount}
            {...api.form.getFieldProps("totalAmount")}
          ></EuropeanMoneyInput>

          {!props.summarized && (
            <DateTextField
              {...api.form.getFieldProps("paymentDate")}
              label={translations.paymentDate}
            ></DateTextField>
          )}

          {!props.summarized && (
            <DateTextField
              {...api.form.getFieldProps("postDate")}
              label={translations.postDate}
            ></DateTextField>
          )}
        </>
      ),
    }

    return a
  })()
  return indentationProps
}
