// Rich Purples
export const regalPurple = "#8430c1"
export const darkRoyalPurple = "#4b0082"
export const deepPlum = "#310055"

// Soft Purples
export const lavenderCloud = "#9457c1"

// Vibrant Pinks
export const magentaPunch = "#ca3395"
export const fuchsiaBloom = "#ca5ba3"

// Deep Reds
export const wineBerry = "#701c53"
export const crimsonHeart = "#61003f"
export const royalBurgundy = "#960061"

// Earthy Greens
export const oliveGrove = "#6b8922"
export const forestMoss = "#547700"
export const pearGreen = "#82b700"
export const chartreuseGlow = "#abdb37"
export const springMeadow = "#b8db62"

// Golden Yellows
export const mustardSeed = "#918f24"
export const goldenNugget = "#c2bd00"
export const dandelionDelight = "#e0ddc38"
export const paleSunshine = "#e0ddd6"

// Warm Browns
export const darkRoastCoffee = "#7e7b00"
