import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { KanbanActivityFragment } from "lib/graphql/operations"
import { useI18n } from "contexts/i18nContext/i18nContext"
import LexicalEditor from "components/LexicalEditor/LexicalEditor"

export default function AddComment(props: {
  cardLink: React.ReactNode
  activity: KanbanActivityFragment
}) {
  const t = useI18n()
  const c = useStyles()

  if (props.activity.__typename !== "KanbanAddCommentActivity") return null

  return (
    <>
      <span>{t["iveAddedACommentToTheTask"]} </span>
      {props.cardLink}
      <blockquote className={c.blockquote}>
        <LexicalEditor.Provider
          namespace={`kanban-comment-${props.activity.id}`}
          editable={false}
          markdownMode={false}
          value={props.activity.comment?.text || ""}
        >
          <LexicalEditor.Root className={c.lexicalRoot}></LexicalEditor.Root>
        </LexicalEditor.Provider>
      </blockquote>
    </>
  )
}
const useStyles = makeStyles((theme) => ({
  lexicalRoot: { paddingLeft: theme.spacing(1.5) },
  blockquote: {
    position: "relative",
    backgroundColor: "rgb(240, 246, 221)",
    fontStyle: "italic",
    color: theme.palette.text.primary,
    margin: 0,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    opacity: 0.7,
    marginTop: theme.spacing(1),
    "&::before": {
      content: '"\\201C"',
      fontSize: "2.5em",
      position: "absolute",
      left: 0,
      top: "-0.1em",
    },
  },
}))
