import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import {
  $getRoot,
  EditorState,
  LexicalEditor,
  SerializedEditorState,
} from "lexical"
import { InitialConfigType } from "@lexical/react/LexicalComposer"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import useStateController from "hooks/useStateController"
import useTrueFalse from "hooks/useTrueFalse"
import useOnChange from "hooks/useOnChange"

export type LexicalEditorAPIInput = {
  editable: boolean
  namespace: InitialConfigType["namespace"]
  markdownMode?: boolean
  autoFocus?: boolean //default: true
  value?: string | SerializedEditorState
  onSave?: (stringifiedEditorState: string) => any
  allowToSaveEmptyValue?: boolean
}

export default function useLexicalEditorAPI(props: LexicalEditorAPIInput) {
  const [editor] = useLexicalComposerContext()
  const [editorState, setEditorState] = React.useState<EditorState | null>(null)

  const markdownMode = useTrueFalse(props.markdownMode, {
    isBidirectional: true,
  })

  useOnChange({
    value: props.editable,
    onChange: (editable) => {
      editor.setEditable(editable)
    },
  })

  const editorStateAPI = useMemoAPI({
    onChange: setEditorState,
    value: editorState,
  })

  const saveAPI = useSaveAPI({
    allowToSaveEmptyValue: props.allowToSaveEmptyValue,
    initialValue: props.value,
    onSave: props.onSave,
    editorState,
  })

  return useMemoAPI({
    ...(props || {}),
    editor,
    editorState: editorStateAPI,
    saveAPI,
    markdownMode,
  })
}

export type LexicalEditorAPI = ReturnType<typeof useLexicalEditorAPI>

function useSaveAPI(props: {
  editorState: EditorState | null
  allowToSaveEmptyValue?: LexicalEditorAPIInput["allowToSaveEmptyValue"]
  initialValue?: LexicalEditorAPIInput["value"]
  onSave?: LexicalEditorAPIInput["onSave"]
}) {
  const [editor] = useLexicalComposerContext()

  const stringifiedState = (() => {
    if (props.editorState) return JSON.stringify(props.editorState.toJSON())

    return ""
  })()

  const textContent = props.editorState?.read(() => $getRoot().getTextContent())

  const isDisabled = [
    ...(() => {
      if (!props.allowToSaveEmptyValue) return [!stringifiedState, !textContent]
      return []
    })(),

    stringifiedState === props?.initialValue,
  ].some(Boolean)

  const clearEditor = React.useCallback(() => {
    return editor.update(() => {
      $getRoot().clear().selectEnd()
    })
  }, [editor])

  const save = React.useCallback(
    async (args: { clearEditorOnSave?: boolean }) => {
      if (isDisabled) return

      props.onSave?.call(undefined, stringifiedState)

      if (args.clearEditorOnSave) clearEditor()

      return
    },
    [clearEditor, stringifiedState, isDisabled, props.onSave]
  )

  return useMemoAPI({ isDisabled, save })
}
