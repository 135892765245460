import useWorkspaces from "hooks/useWorkspaces"
import React from "react"

const WorkspacesContext = React.createContext(
  {} as ReturnType<typeof useWorkspaces>
)

WorkspacesContext.displayName = "WorkspacesContext"

const Workspaces = {
  Provider(props: { children: React.ReactNode }) {
    const api = useWorkspaces({ fetchPolicy: "cache-and-network" })

    return (
      <WorkspacesContext.Provider value={api}>
        {props.children}
      </WorkspacesContext.Provider>
    )
  },
  Context: WorkspacesContext,
}

export default Workspaces
