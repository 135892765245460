import React, { useState } from "react"
import InView from "react-intersection-observer"
import arePropsEqual from "util/arePropsEqual"

/**
 *
 * Element backed by IntersectionObserver, that triggers action
 * once it shows up
 *
 * Useful for the creation of infinite loading
 */

function TodoOnceVisible(props: {
  todoOnceVisible: () => void
  executeOnce: boolean
}) {
  const [executed, setExecuted] = useState(false)

  if (executed) return null

  return (
    <InView
      as="div"
      onChange={(inView, entry) => {
        if (!inView || typeof props.todoOnceVisible !== "function") return
        props.todoOnceVisible()
        props.executeOnce && setExecuted(true)
      }}
    >
      <div />
    </InView>
  )
}

export default React.memo(TodoOnceVisible, arePropsEqual(["todoOnceVisible"]))
