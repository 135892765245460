import Hook from "components/Hook/Hook"
import { PageComponent, PageComponentProps } from "components/Page/Page"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import Pdf from "components/Pdf/Pdf"
import React from "react"
import { DocumentPageBoardItemContext } from "../../DocumentPageBoardItemContext"
import { usePagePdfProps } from "./usePagePdfProps"

export function PdfPage(
  args: PageComponentProps & { showInfoButton?: boolean }
) {
  const api = React.useContext(DocumentPageBoardItemContext)

  return (
    <ItemTemplate.Provider onClose={args.onClose}>
      <Pdf.Provider url={api.document?.pdf || ""}>
        <ItemTemplate.Context.Consumer>
          {(template) => {
            return (
              <Hook
                hook={usePagePdfProps}
                hookProps={{
                  onClose: template.onClose,
                  showInfoButton: !!args.showInfoButton,
                }}
              >
                {(a) => {
                  return (
                    <ItemTemplate.Root
                      EnhancedPageProps={a}
                    ></ItemTemplate.Root>
                  )
                }}
              </Hook>
            )
          }}
        </ItemTemplate.Context.Consumer>
      </Pdf.Provider>
    </ItemTemplate.Provider>
  )
}
