import DocumentsFilterContext from "./contexts/DocumentsFilterContext"
import DocumentsFilterDefault from "./components/DocumentsFilterDefault/DocumentsFilterDefault"
import DocumentsFilterProvider from "./components/DocumentsFilterProvider/DocumentsFilterProvider"
import useDocumentsFilterProviderValue from "./hooks/useDocumentsFilterProviderValue/useDocumentsFilterProviderValue"

const DocumentsFilter = {
  Default: DocumentsFilterDefault,
  Provider: DocumentsFilterProvider,
  Context: DocumentsFilterContext,
  useProviderValue: useDocumentsFilterProviderValue,
}

export default DocumentsFilter
