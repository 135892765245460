import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { getIcon } from "components/PageBoard/hooks/useTypesOptions"
import I18nContext from "contexts/i18nContext/i18nContext"
import { PageBoardItemType } from "lib/graphql/types"
import { PageComponentProps } from "../../../../Page/hooks/useSecondPage"
import { FormCommons } from "../../IndexedItems/components/PageBoardItem/components/FormCommons/FormCommons"
import { ContactsChips } from "../components/ContactsChips"
import { NewContactFormPage } from "../components/NewContactFormPage"
import { AddNewContactFormApiInput } from "../../../../AddNewContactForm/AddNewContactForm"

export function getContactsFieldProps(props?: {
  AddNewContactFormApiInput?: AddNewContactFormApiInput
}) {
  const icon = getIcon(PageBoardItemType.Contact)

  const fieldProps: FieldsetIndentationProps = {
    icon: icon,
    title: <I18nContext.Consumer>{(t) => t.contacts}</I18nContext.Consumer>,
    children: (
      <FormCommons.Context.Consumer>
        {(api) => {
          return (
            <ContactsChips
              onClickNewContactButton={() =>
                api.twoPagesApi?.openMiniPage(function NewContactPage(
                  args: PageComponentProps
                ) {
                  return (
                    <NewContactFormPage
                      {...args}
                      {...props?.AddNewContactFormApiInput}
                    ></NewContactFormPage>
                  )
                })
              }
            ></ContactsChips>
          )
        }}
      </FormCommons.Context.Consumer>
    ),
  }

  return fieldProps
}
