import { makeStyles } from "@material-ui/core"
import React from "react"
import PageBoardContext from "../../contexts/PageBoardContext"
import { IconType } from "./IconType"
import useOnChange from "../../../../hooks/useOnChange"

export function IconsFilters(props: { maxWidthAvailable: number }) {
  const api = React.useContext(PageBoardContext)

  useOnChange({
    value: props.maxWidthAvailable,
    onChange: () => {
      api.mostUsedTypes.setMaxWidthAvailable(props.maxWidthAvailable)
    },
  })

  const c = useStyles()

  return (
    <div className={c.root}>
      {api.mostUsedTypes.visible.map((type) => {
        const isSelected = api.types.value.some((t) => type === t)

        return (
          <IconType
            key={type}
            type={type}
            isSelected={isSelected}
            onClick={() => api.types.toggle(type)}
          ></IconType>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
    },
  }
})
