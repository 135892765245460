import React from "react"
import useOnChange from "hooks/useOnChange"
import ApolloSetup from "components/ApolloSetup/ApolloSetup"
import ConnectionStatus from "components/ConnectionStatus/ConnectionStatus"

export default function useWhenIsBackOnline(callback: () => any) {
  const apolloSetup = React.useContext(ApolloSetup.Context)
  const connection = React.useContext(ConnectionStatus.Context)

  useOnChange({
    value: connection.isOnline,
    onChange() {
      if (!connection.isOnline) return
      callback?.call(undefined)
      // return apolloSetup.wsReconnected$.pipe(take(1)).subscribe(callback)
    },
  })
}
