import {
  ChangeKanbanCardImagesMutationVariables,
  KanbanCardActivitiesQueryVariables,
} from "lib/graphql/operations"
import React from "react"
import { useChangeKanbanCardImagesMutation } from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import updateQuery from "lib/updateQuery/updateQuery"
import { KanbanCardActivitiesDocument } from "lib/graphql/operations"

export default function useChangeKanbanCardImages(props: { cardID: string }) {
  const [mutate] = useChangeKanbanCardImagesMutation({})

  const r = useActiveResources()

  const change = React.useCallback(
    (a: ChangeKanbanCardImagesMutationVariables["update"]) => {
      // if (!a.cover?.imageID && typeof a.cover === "undefined") return null
      // if (!a.cover?.imageID && typeof a.cover === "undefined") return null

      const variables: ChangeKanbanCardImagesMutationVariables = {
        cardID: props.cardID,
        workspaceID: r.workspaceID,
        update: {
          ...(typeof a.list !== "undefined"
            ? {
                list: a.list,
              }
            : {}),
          ...(typeof a.cover !== "undefined"
            ? (() => {
                const cover = (() => {
                  if (a.cover === null) return null
                  if (a.cover.imageID) return { imageID: a.cover.imageID }
                  if (a.cover.upload) return { upload: a.cover.upload }
                })()

                if (!cover) return {}

                return {
                  cover,
                }
              })()
            : {}),
        },
      }
      return mutate({
        refetchQueries: [
          {
            query: KanbanCardActivitiesDocument,
            variables: {
              cardID: props.cardID,
              workspaceID: r.workspaceID,
            } as KanbanCardActivitiesQueryVariables,
          },
        ],
        update(dataProxy, result) {
          const cardImagesFragment = result.data?.changeKanbanCardImages
          if (!cardImagesFragment) return

          // result.context.
          updateQuery(
            {
              queryName: "kanbanCardImages",
              variables: {
                cardID: variables.cardID,
                workspaceID: variables.workspaceID,
              },
              updateFn() {
                return {
                  __typename: "Query",
                  kanbanCardImages: cardImagesFragment,
                }
              },
            },
            dataProxy
          )
        },
        variables: variables,
      })
    },
    [r.workspaceID, props.cardID]
  )

  return change
}
