import { OrdinaryRoomIdentifier, RoomIdentifier } from "types/type"
import ApolloClient from "apollo-client"
import basicTabs from "components/Room/components/tabs/basicTabs"
import { RoomFragment } from "lib/graphql/operations"
// import { RoomIdentifier } from "@types"

export default abstract class RoomAPI<
  Identifier extends RoomIdentifier = RoomIdentifier
> {
  allowAutomaticCreation = false
  allowMembersToEdit = false
  workspaceID: string
  client: ApolloClient<any>

  constructor(params: {
    workspaceID: string
    client: ApolloClient<any>
    identifier?: Identifier
  }) {
    this.workspaceID = params.workspaceID
    this.client = params.client
  }

  async getRoomID(identifier: Identifier) {
    return this.getRoom(identifier).then((a) => a?.id)
  }

  abstract getRoom(
    identifier: Identifier
  ): Promise<RoomFragment | null | undefined>

  abstract createRoom(
    identifier: Identifier
  ): Promise<RoomFragment | null | undefined>

  // public get tabs() {
  //   return this.basicTabs
  // }
}
