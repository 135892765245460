import PrivateRoomAPI from "./PrivateRoomAPI/PrivateRoomAPI"
import DocumentRoomAPI from "./DocumentRoomAPI/DocumentRoomAPI"
import RoomAPI from "./RoomAPI/RoomAPI"
import CollectionRoomAPI from "./CollectionRoomAPI/CollectionRoomAPI"
// import { RoomType } from "lib/constants"
import {
  CollectionRoomIdentifier,
  DocumentRoomIdentifier,
  PrivateRoomIdentifier,
  RoomIdentifier,
} from "types/type"
import ApolloClient from "apollo-client"
import OrdinaryRoomAPI from "./OrdinaryRoomAPI/OrdinaryRoomAPI"

export default function getRoomAPI<T extends RoomIdentifier>(props: {
  workspaceID: string
  identifier: T
  client: ApolloClient<{}>
}) {
  const variables = { workspaceID: props.workspaceID, client: props.client }

  let api: RoomAPI | undefined = undefined

  if ("userID" in props.identifier) {
    api = new PrivateRoomAPI(variables)
  }

  if ("roomID" in props.identifier) {
    api = new OrdinaryRoomAPI(variables)
  }

  if ("collectionID" in props.identifier) {
    api = new CollectionRoomAPI(variables)
  }

  if ("documentID" in props.identifier) {
    api = new DocumentRoomAPI(variables)
  }

  if (!api) return null

  return new RoomAPIContext(props.identifier, api)
}

//Strategy Pattern
class RoomAPIContext<T extends RoomIdentifier> {
  identifier: T
  private roomAPI: RoomAPI<T>

  constructor(identifier: T, api: RoomAPI<T>) {
    this.roomAPI = api
    this.identifier = identifier
  }

  createRoom() {
    return this.roomAPI.createRoom(this.identifier)
  }

  getRoomID() {
    return this.roomAPI.getRoomID(this.identifier)
  }

  getRoom() {
    return this.roomAPI.getRoom(this.identifier)
  }

  get allowMembersToEdit() {
    return this.roomAPI.allowMembersToEdit
  }

  get allowAutomaticCreation() {
    return this.roomAPI.allowAutomaticCreation
  }
}
