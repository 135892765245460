import React from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import useOnChange from "hooks/useOnChange"
import useStateController from "hooks/useStateController"
import { TrueFalseAPI } from "hooks/useTrueFalse"

type TwoViews = [React.ReactNode, React.ReactNode?]

export default function TwoViewsWithTransition(props: {
  views: TwoViews
  className?: string
  style?: React.CSSProperties
  transitionInMs?: number
  secondViewIsHidden?: TrueFalseAPI
}) {
  const [secondViewIsVisible, setSecondViewIsVisible] = React.useState(
    !props.secondViewIsHidden?.isTrue
  )

  useOnChange({
    value: secondViewIsVisible,
    onChange(isVisible) {
      const isHidden = !isVisible
      props.secondViewIsHidden?.setIsTrue(isHidden)
    },
  })

  useOnChange({
    value: props.secondViewIsHidden?.isTrue,
    onChange(isHidden) {
      setSecondViewIsVisible(!isHidden)
    },
  })

  const transitionInMs =
    typeof props.transitionInMs === "undefined" ? 300 : props.transitionInMs

  const c = useStyles({ moved: !!secondViewIsVisible, transitionInMs })

  const validViews = props.views.filter((a) => !!a)

  const [currentViews, setCurrentViews] =
    React.useState<React.ReactNode[]>(validViews)

  React.useEffect(() => {
    if (currentViews.length > 1) {
      setTimeout(() => {
        setSecondViewIsVisible(() => true)
      }, 0)
    }
  }, [])

  // React.useEffect(() => {
  //   const isVisible = props.secondViewIsHidden
  //   if (typeof isVisible === "undefined") return
  //   setSecondViewIsVisible(() => isVisible)
  // }, [props.secondViewIsHidden])

  useOnChange({
    value: validViews.length > currentViews.length,
    onChange(increased) {
      if (!increased) return

      setCurrentViews(() => validViews)

      process.nextTick(() => {
        setSecondViewIsVisible(() => true)
      })
    },
  })

  useOnChange({
    value: validViews.length < currentViews.length,
    onChange(reduced) {
      if (!reduced) return

      setSecondViewIsVisible(() => false)

      setTimeout(() => {
        setCurrentViews(() => validViews)
      }, transitionInMs + 10)
    },
  })

  return (
    <div
      className={clsx("views-with-transition", c.root, props.className)}
      style={props.style}
    >
      {currentViews
        .filter((a) => !!a)
        .map((a, index) => React.cloneElement(<>{a}</>, { key: index }))}
    </div>
  )
}
type Style = { moved: boolean; transitionInMs: number }

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      display: "flex",
      overflow: "hidden",
      "&>*": {
        flexBasis: "100%",
        flexShrink: 0,
        height: "100%",
        width: "100%",
      },

      "&>*:first-child": {
        marginLeft: (props: Style) => {
          return props.moved ? "-100%" : "0%"
        },
        transition: (props: Style) =>
          ["margin-left ", props.transitionInMs, "ms"].join(""),
      },
      // "&>*:first-child": {
      //   marginLeft: (props: Style) => {
      //     return props.moved ? "-100%" : "0%"
      //   },
      //   transition: (props: Style) =>
      //     ["margin-left ", props.transitionInMs, "ms"].join(""),
      // },
    },
  }
})
