import { RoomContext } from "components/Room/contexts/room"
import { MessageFragment } from "lib/graphql/operations"
import React from "react"
import { UserInclusionMessage } from "./UserInclusionMessage"
import KanbanLogMessage from "./components/KanbanLogMessage/KanbanLogMessage"
import { MessageBalloonProps } from "./components/MessageBalloon/MessageBalloon"
import { Paragraphs } from "./components/ParagraphsMessage/Paragraphs"
import TextMessage from "./components/TextMessage/TextMessage"
import { ZoomSessionRegistrationMessage } from "./components/ZoomSessionRegistrationMessage/ZoomSessionRegistrationMessage"

export type RenderMessageProps = {
  message: MessageFragment
  MessageBalloonProps: Omit<MessageBalloonProps, "children" | "message">
  defaultComponent: React.ReactNode
}

export default function Message(props: Omit<MessageBalloonProps, "children">) {
  const { message, ...otherBalloonProps } = props

  const api = React.useContext(RoomContext)

  // const c = useStyles()

  //   let balloonProps: MessageProps['MessageBalloonProps'] = (() => {
  //     if (message?.__typename === "KanbanCardLogMessage")
  // return{
  //             ...otherBalloonProps,
  //             style: {
  //               ...(otherBalloonProps.style || {}),
  //               ...(() => {
  //                 const width = api.template.comfortableMessageWidth

  //                 if (width) return { maxWidth: width }

  //                 return {}
  //               })(),
  //             },
  //           }

  //     if (message?.__typename === "TextMessage") {
  //       return otherBalloonProps
  //     }

  //     if (message?.__typename === "MessageInParagraphs") {
  // return {
  //             ...otherBalloonProps,
  //             style: { maxWidth: 700, ...(otherBalloonProps.style || {}) },
  //           }
  //     }

  //     if (message?.__typename === "UserInclusionMessage") {
  //       return (
  //         <UserInclusionMessage
  //           MessageBalloonProps={otherBalloonProps}
  //           message={message}
  //           classes={{ userCard: c.userCard }}
  //         ></UserInclusionMessage>
  //       )
  //     }
  //   })()

  let args: RenderMessageProps | null = (() => {
    if (message?.__typename === "KanbanCardLogMessage") {
      const MessageBalloonProps = {
        ...otherBalloonProps,
        style: {
          ...(otherBalloonProps.style || {}),
          ...(() => {
            const width = api.template.comfortableMessageWidth
            if (width) return { maxWidth: width }
            return {}
          })(),
        },
      }

      const d = (
        <KanbanLogMessage
          message={message}
          MessageBalloonProps={MessageBalloonProps}
        ></KanbanLogMessage>
      )

      return { message, MessageBalloonProps, defaultComponent: d }
    }

    if (message?.__typename === "TextMessage") {
      const p = { message, MessageBalloonProps: otherBalloonProps }
      const d = <TextMessage {...p}></TextMessage>

      return {
        message,
        MessageBalloonProps: otherBalloonProps,
        defaultComponent: d,
      }
    }

    if (message?.__typename === "MessageInParagraphs") {
      const p = {
        message,
        MessageBalloonProps: {
          ...otherBalloonProps,
          style: { maxWidth: 700, ...(otherBalloonProps.style || {}) },
        },
      }

      const defaultComponent = <Paragraphs {...p}></Paragraphs>

      return { ...p, defaultComponent }
    }

    if (message?.__typename === "ZoomSessionRegistrationMessage") {
      return {
        message,
        MessageBalloonProps: otherBalloonProps,
        defaultComponent: (
          <ZoomSessionRegistrationMessage
            MessageBalloonProps={otherBalloonProps}
            message={message}
          ></ZoomSessionRegistrationMessage>
        ),
      }
    }

    if (message?.__typename === "UserInclusionMessage") {
      const d = (
        <UserInclusionMessage
          MessageBalloonProps={otherBalloonProps}
          message={message}
          // classes={{ userCard: c.userCard }}
        ></UserInclusionMessage>
      )

      return {
        message,
        MessageBalloonProps: otherBalloonProps,
        defaultComponent: d,
      }
    }

    return null
  })()
  // let content = (() => {
  //   if (message?.__typename === "KanbanCardLogMessage")
  //     return (
  //       <KanbanLogMessage
  //         message={message}
  //         MessageBalloonProps={{
  //           ...otherBalloonProps,
  //           style: {
  //             ...(otherBalloonProps.style || {}),
  //             ...(() => {
  //               const width = api.template.comfortableMessageWidth

  //               if (width) return { maxWidth: width }

  //               return {}
  //             })(),
  //           },
  //         }}
  //       ></KanbanLogMessage>
  //     )

  //   if (message?.__typename === "TextMessage") {
  //     return (
  //       <TextMessage
  //         message={message}
  //         MessageBalloonProps={otherBalloonProps}
  //       ></TextMessage>
  //     )
  //   }

  //   if (message?.__typename === "MessageInParagraphs") {
  //     return (
  //       <Paragraphs
  //         message={message}
  //         MessageBalloonProps={{
  //           ...otherBalloonProps,
  //           style: { maxWidth: 700, ...(otherBalloonProps.style || {}) },
  //         }}
  //       ></Paragraphs>
  //     )
  //   }

  //   if (message?.__typename === "UserInclusionMessage") {
  //     return (
  //       <UserInclusionMessage
  //         MessageBalloonProps={otherBalloonProps}
  //         message={message}
  //         // classes={{ userCard: c.userCard }}
  //       ></UserInclusionMessage>
  //     )
  //   }
  // })()

  // if (!content) return null

  // return (
  //   <TextSelectionKanbanCardCreation actionsPosition={props.actionsPosition}>
  //     {content}
  //   </TextSelectionKanbanCardCreation>
  // )

  if (!args) return null

  if (api.Message) return <api.Message {...args}></api.Message>

  return <>{args?.defaultComponent || null}</>
}

// const useStyles = makeStyles((theme) => {
//   return {
//     userCard: {
//       minWidth: 250,
//     },
//   }
// })
