import React from "react"
import {
  ItemNewThumbnailImage,
  ItemsListContext,
} from "../../context/ItemsListContext"
import { Subscriber } from "components/Observer"
import { ImageEditor } from "components/ImageEditor/ImageEditor"
import { excludeNullable } from "lib/excludeNullable"
import useEscapeListener from "components/KeyListeners/hooks/useEscapeListener"
import HighestZIndexLayer from "components/HighestZIndexLayer/HighestZIndexLayer"
import { useUploadPageBoardItemThumbnail } from "../ListItemEntry/components/ItemGridCell/hooks/useUploadPageBoardItemThumbnail"
import { CropFormat, CropUnit } from "lib/graphql/types"

export function UploadedThumbnailEditor() {
  const [thumbEvt, setThumbEvt] = React.useState<ItemNewThumbnailImage | null>(
    null
  )

  const imageUrl = React.useMemo(() => {
    if (!thumbEvt?.file) return null
    return URL.createObjectURL(thumbEvt.file)
  }, [thumbEvt?.file])

  const onClose = React.useCallback(() => {
    setThumbEvt(null)
  }, [])

  useEscapeListener(onClose, { enabled: !!imageUrl })

  const uploadThumbnail = useUploadPageBoardItemThumbnail()

  return (
    <>
      <ItemsListContext.Consumer>
        {(api) => {
          return (
            <Subscriber
              observable={api.newThumbnailImage$}
              subscription={setThumbEvt}
            ></Subscriber>
          )
        }}
      </ItemsListContext.Consumer>

      {(() => {
        if (!thumbEvt) return null

        return (
          <HighestZIndexLayer.Portal>
            <ImageEditor.Provider
              isOpen={!!imageUrl}
              imagesUrls={[imageUrl].filter(excludeNullable)}
              onClose={onClose}
              aspect={thumbEvt.containerAspect}
              submitCrop={(cropState) => {
                if (!cropState?.percentCrop || !thumbEvt.item) return

                if (!thumbEvt?.item?.itemID || !thumbEvt?.item?.type) return

                uploadThumbnail.upload({
                  file: thumbEvt.file,
                  item: thumbEvt.item,
                  crop: {
                    x: cropState.percentCrop.x,
                    y: cropState.percentCrop.y,
                    width: cropState.percentCrop.width,
                    height: cropState.percentCrop.height,
                    unit: CropUnit.Percent,
                    format: CropFormat.Thumbnail,
                  },
                })

                onClose()
              }}
            >
              <ImageEditor.Root></ImageEditor.Root>
            </ImageEditor.Provider>
          </HighestZIndexLayer.Portal>
        )
      })()}
    </>
  )
}
