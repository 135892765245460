import { useApolloClient } from "@apollo/client";
import React from "react"
import { ID } from "types/type"
import { KanbanImagesAPI } from "components/Kanban/@types"
import { getCardImages } from "../../lib/getCardImages"

export default function useAddImagesAndThenCoverWhenUploadingFirstImage(props: {
  workspaceID: ID
  addImages: KanbanImagesAPI["addImages"]
  setCover: KanbanImagesAPI["setCover"]
}): KanbanImagesAPI["addImages"] {
  const client = useApolloClient()

  return React.useCallback(
    async function addImagesAndThenCoverWhenUploadingFirstImage(
      cardID: ID,
      files: File[]
    ) {
      const isTheFirstImage = (() => {
        const kanbanCardImages = getCardImages(cardID, client)
        const { list, cover } = kanbanCardImages || {}
        return !list?.length && !cover
      })()

      await props.addImages(cardID, files)

      if (isTheFirstImage) {
        const { list } = getCardImages(cardID, client)
        if (!list?.length) return
        await props.setCover(list?.[0]?.id, cardID)
      }
    },
    [props.workspaceID]
  )
}

export type CardImages = {
  list: { id: ID }[]
  cover: { id: ID }
}
