import _ from "lodash"
import { useEffect, useState } from "react"

type state<T> = {
  loading: boolean
  result: T | undefined
  error: string | null | Error
}

export default function useAsyncCall<T>(
  asyncFn: () => T | Promise<T>,
  dependencies: Array<any> = []
) {
  const defaultState: state<T> = {
    loading: false,
    result: undefined,
    error: null,
  }

  const [value, setValue] = useState(defaultState)

  useEffect(() => {
    ;(async () => {
      let result: T
      let error: string | Error | null = null

      try {
        setValue((old) => ({ ...old, loading: true }))

        result = await asyncFn()
      } catch (e) {
        error = e as Error | null | string
      } finally {
        setValue(() => ({ result, error, loading: false }))
      }
    })()
  }, dependencies || [])

  return value
}
