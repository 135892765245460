import React from "react"
import useCalendarEventAPI from "../hooks/useCalendarEventAPI"

const CalendarEventContext = React.createContext<CalendarEventAPI>(
  {} as CalendarEventAPI
)

CalendarEventContext.displayName = "CalendarEventContext"

export default CalendarEventContext

type CalendarEventAPI = ReturnType<typeof useCalendarEventAPI>
