import { useContext } from "react"
import { Icon, makeStyles, useTheme } from "@material-ui/core"
import { useI18n } from "contexts/i18nContext/i18nContext"
import RoomWallMetaCard from "components/Room/components/RoomWall/components/CreateRoomMetaCard/CreateRoomMetaCard"
import clsx from "clsx"
import RoomTemplateContext from "components/Room/components/RoomTemplate/contexts/RoomTemplateContext"

export default function InviteRoomMetaCard() {
  const { goToMembersInvitation } = useContext(RoomTemplateContext)
  const theme = useTheme()

  const t = useI18n()
  const c = useStyles()

  return (
    <RoomWallMetaCard
      className={clsx("invite-room-meta-card", c.root)}
      buttonProps={{
        onClick: goToMembersInvitation,
        children: <>{t["invite-member"]}</>,
        size: "small",
        className: c.button,
      }}
      avatarProps={{
        backgroundColor: theme.palette.secondary.main,
        content: <Icon>person_add</Icon>,
      }}
    ></RoomWallMetaCard>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: 290,
    },
    button: {
      marginRight: theme.spacing(1),
    },
  }
})
