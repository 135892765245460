import clsx from "clsx"
import { Typography, TypographyProps } from "@material-ui/core"
import ClippedText, {
  ClippedTextProps,
} from "components/ClippedText/ClippedText"

export default function ClippedTypography(
  props: {
    ClippedTextProps?: Omit<ClippedTextProps, "children">
  } & TypographyProps
) {
  return (
    <Typography
      {...props}
      className={clsx("clipped-typography", props.className)}
    >
      {(() => {
        if (props.ClippedTextProps)
          return (
            <ClippedText {...props.ClippedTextProps}>
              {props.children}
            </ClippedText>
          )

        return props.children
      })()}
    </Typography>
  )
}
