import { useApolloClient } from "@apollo/client";
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  AddKanbanCardMutationVariables,
  DecoupledKanbanBoardQuery,
  KanbanCardFragment,
  useAddKanbanCardMutation,
} from "lib/graphql/operations"
import updateQuery from "lib/updateQuery/updateQuery"
import React from "react"
import { Typename } from "types/type"
import { MutatedColumnsFragment } from "../../../lib/graphql/operations"
import readFragment from "lib/updateFragment/readFragment"

export function useAddKanbanCard(props: { roomID: string }) {
  const [mutate, { loading }] = useAddKanbanCardMutation()

  const r = useActiveResources()
  const client = useApolloClient()

  const addKanbanCard = React.useCallback(
    function addKanbanCard(
      variables: Omit<AddKanbanCardMutationVariables, "workspaceID" | "roomID">
    ) {
      const roomID = props.roomID

      if (!r.workspaceID) return

      return mutate({
        variables: { ...variables, roomID, workspaceID: r.workspaceID },

        update(cache, { data }) {
          updateQuery(
            {
              queryName: "decoupledKanbanBoard",
              variables: { roomID, workspaceID: r.workspaceID },
              updateFn(a) {
                const b: DecoupledKanbanBoardQuery = {
                  __typename: "Query",
                  decoupledKanbanBoard: {
                    __typename: "DecoupledKanbanBoard",
                    id: a.decoupledKanbanBoard?.id || "",
                    roomID: a.decoupledKanbanBoard?.roomID || "",
                    columns: a.decoupledKanbanBoard?.columns || [],
                    columnOrdering:
                      a.decoupledKanbanBoard?.columnOrdering || null,

                    // orderedColumnsIDs: [
                    //   ...(a.decoupledKanbanBoard?.orderedColumnsIDs || []),
                    // ],
                    cards: [
                      ...(a.decoupledKanbanBoard?.cards || []),
                      data?.addKanbanCard?.changedCard || null,
                    ].filter((a): a is Exclude<typeof a, null> => !!a),
                  },
                }

                return b
              },
            },
            cache
          )
        },

        optimisticResponse: {
          __typename: "Mutation",
          addKanbanCard: (() => {
            const column = readFragment(
              {
                typename: Typename.KanbanColumn,
                cacheIDParams: { id: variables.columnID },
              },
              client
            )

            const a: KanbanCardFragment = {
              __typename: "KanbanCard",
              id: Math.random() + "",
              title: "",
              description: "",
              roomName: "",
              columnID: variables.columnID,
              columnName: column?.title || "",
              isDeleted: false,
              roomID: props.roomID,
            }

            if (!column) return null

            const mutatedColumns: MutatedColumnsFragment = {
              __typename: "MutatedColumns",
              upsertedColumns: (() => {
                return [
                  {
                    ...column,
                    orderedCardsIDs: [a.id, ...(column.orderedCardsIDs || [])],
                  },
                ]
              })(),

              changedCard: a,
            }

            return mutatedColumns
          })(),
        },
      })
    },

    [r.workspaceID, mutate, props.roomID, client]
  )

  return useMemoAPI({ addKanbanCard, isLoading: loading })
}
