import _ from "lodash"
import React from "react"
import I18nContext from "contexts/i18nContext/i18nContext"
import Text from "components/Text/Text"
import Input from "components/Input/Input"
import formatPrice from "lib/formatPrice"
import moment from "moment"
import DocumentFormContext, {
  FieldsMap,
  DocumentStatusType,
} from "../contexts/DocumentFormContext"
import DocumentForm from "components/OldDocumentForm/OldDocumentForm"
import TextField from "../../TextField/TextField"
import DocumentCollections from "components/DocumentCollections/DocumentCollections"

export default function getFieldsMap(): FieldsMap {
  return {
    text: {
      getLabel() {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return <>{t.activityDetails.text}</>
            }}
          </I18nContext.Consumer>
        )
      },
      render(value, key) {
        return (
          <DocumentForm.Context.Consumer>
            {(api) => {
              return (
                <TextField
                  {...api.documentForm.getFieldProps("text")}
                ></TextField>
              )
            }}
          </DocumentForm.Context.Consumer>
        )
      },
    },

    collections: {
      getLabel(value) {
        return "Collections"
      },
      render(value, _, object) {
        return (
          <DocumentForm.Context.Consumer>
            {(api) => {
              return (
                <DocumentCollections.Default
                  collections={api.document?.collections || []}
                  documentID={api.document?.id || ""}
                ></DocumentCollections.Default>
              )
            }}
          </DocumentForm.Context.Consumer>
        )
      },
    },

    title: {
      getLabel(value) {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return <>{t.activityDetails.title}</>
            }}
          </I18nContext.Consumer>
        )
      },
      render(value, key) {
        return (
          <DocumentForm.Context.Consumer>
            {(api) => {
              return (
                <TextField
                  {...api.documentForm.getFieldProps("title")}
                ></TextField>
              )
            }}
          </DocumentForm.Context.Consumer>
        )
      },
    },
    date: {
      getLabel(value) {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return <>{t.activityDetails.date}</>
            }}
          </I18nContext.Consumer>
        )
      },
      render(value, key) {
        return (
          <DocumentForm.Context.Consumer>
            {(api) => {
              return (
                <Input.date
                  {...api.documentForm.getFieldProps("date")}
                ></Input.date>
              )
            }}
          </DocumentForm.Context.Consumer>
        )
      },
    },
    status: {
      getLabel(value) {
        return (
          <DocumentForm.Context.Consumer>
            {({ status }) => {
              return (
                <I18nContext.Consumer>
                  {(t) => {
                    const statusLabel = {
                      [DocumentStatusType.jobStatus]: t["job-status"],
                      [DocumentStatusType.workStatus]: t["workstatus"],
                    }

                    if (!status?.type) return "status"

                    return <>{statusLabel[status?.type]}</>
                  }}
                </I18nContext.Consumer>
              )
            }}
          </DocumentForm.Context.Consumer>
        )
      },
      render(value) {
        return (
          <DocumentForm.Context.Consumer>
            {({ status }) => {
              // console.log(status.value)
              return <Text value={status?.value || "-"}></Text>
            }}
          </DocumentForm.Context.Consumer>
        )
      },
    },
    netValueGoods: {
      getLabel(value) {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return t.netValueGoods
            }}
          </I18nContext.Consumer>
        )
      },
      render(value, key) {
        return (
          <DocumentForm.Context.Consumer>
            {(api) => {
              return (
                <Input.euro
                  {...api.documentForm.getFieldProps("netValueGoods")}
                ></Input.euro>
              )
            }}
          </DocumentForm.Context.Consumer>
        )
        // return (

        // <EditableProperty property={key} Input={Input["euro"]}>
        //   <Text value={formatPrice(value, "€")}></Text>
        // </EditableProperty>
        // )
      },
    },
    totalTax: {
      getLabel(value) {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return t.totalTax
            }}
          </I18nContext.Consumer>
        )
      },
      render(value, key) {
        return (
          // <EditableProperty property={key} Input={Input["euro"]}>
          //   <Text value={formatPrice(value, "€")}></Text>
          // </EditableProperty>
          <DocumentForm.Context.Consumer>
            {(api) => {
              return (
                <Input.euro
                  {...api.documentForm.getFieldProps("totalTax")}
                ></Input.euro>
              )
            }}
          </DocumentForm.Context.Consumer>
        )
      },
    },
    totalAmount: {
      getLabel(value) {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return t.totalAmount
            }}
          </I18nContext.Consumer>
        )
      },
      render(value, key) {
        return (
          // <EditableProperty property={key} Input={Input["euro"]}>
          //   <Text value={formatPrice(value, "€")}></Text>
          // </EditableProperty>
          <DocumentForm.Context.Consumer>
            {(api) => {
              return (
                <Input.euro
                  {...api.documentForm.getFieldProps("totalAmount")}
                ></Input.euro>
              )
            }}
          </DocumentForm.Context.Consumer>
        )
      },
    },
    discountFactor: {
      getLabel(value) {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return t.discountFactor
            }}
          </I18nContext.Consumer>
        )
      },
      render(value) {
        return <Text value={formatPrice(value, "€")}></Text>
      },
    },
    paymentDate: {
      getLabel(value) {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return t.paymentDate
            }}
          </I18nContext.Consumer>
        )
      },
      render(value, key) {
        return (
          // <EditableProperty property={key} Input={Input["date"]}>
          //   {value ? (
          //     <Text value={moment.utc(value).format("DD.MM.YYYY")}></Text>
          //   ) : (
          //     "-"
          //   )}
          // </EditableProperty>
          <DocumentForm.Context.Consumer>
            {(api) => {
              return (
                <Input.date
                  {...api.documentForm.getFieldProps("paymentDate")}
                ></Input.date>
              )
            }}
          </DocumentForm.Context.Consumer>
        )
      },
    },
    postDate: {
      getLabel(value) {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return t.postDate
            }}
          </I18nContext.Consumer>
        )
      },
      render(value, key) {
        return (
          // <EditableProperty property={key} Input={Input["date"]}>
          //   <>
          //     {!value && "-"}
          //     {value && (
          //       <Text value={moment.utc(value).format("DD.MM.YYYY")}></Text>
          //     )}
          //   </>
          // </EditableProperty>
          <DocumentForm.Context.Consumer>
            {(api) => {
              return (
                <Input.date
                  {...api.documentForm.getFieldProps("postDate")}
                ></Input.date>
              )
            }}
          </DocumentForm.Context.Consumer>
        )
      },
    },

    type: {
      getLabel(value) {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return <>{t.activityDetails.type}</>
            }}
          </I18nContext.Consumer>
        )
      },
      render(value, key) {
        return (
          // <EditableProperty
          //   property={key}
          //   Input={Input["document-types-autocomplete"]}
          // >
          //   {/* <I18nContext.Consumer>
          //     {(t) => {
          //       return (
          //         <Text
          //           value={t.collectionsTypes?.title_type[value] || value}
          //         ></Text>
          //       )
          //     }}
          //   </I18nContext.Consumer> */}
          // </EditableProperty>

          <DocumentForm.Context.Consumer>
            {(api) => {
              return React.createElement(Input["document-types-autocomplete"], {
                ...api.documentForm.getFieldProps("type"),
              })
              // return (
              //   // <Input.date
              //   //   {...api.documentForm.getFieldProps("postDate")}
              //   // ></Input.date>
              // )
            }}
          </DocumentForm.Context.Consumer>
        )
      },
    },

    partner: {
      getLabel() {
        return (
          <I18nContext.Consumer>
            {(t) => {
              return (
                <DocumentForm.Context.Consumer>
                  {({ partner }) => {
                    if (!partner) return "Partner"
                    return (
                      t.appGroups[(partner?.type || "").toLowerCase()] ||
                      "Partner"
                    )
                  }}
                </DocumentForm.Context.Consumer>
              )
            }}
          </I18nContext.Consumer>
        )
      },
      render(value) {
        return (
          <DocumentForm.Context.Consumer>
            {({ partner }) => {
              return <Text value={partner?.name}></Text>
            }}
          </DocumentForm.Context.Consumer>
        )
      },
    },
  }
}
