import { convertFromRaw } from "draft-js"
import contentStateFromString from "./contentStateFromString"
import firstNonEmptyParagraphString from "./firstNonEmptyParagraphString"

export default function doesHaveNoText(input: string) {
  const contentState = contentStateFromString(input)

  if (contentState) {
    const firstParagraphText = firstNonEmptyParagraphString(contentState)

    return !firstParagraphText
  }

  if (typeof input === "string") {
    return !input
  }

  return true
}
