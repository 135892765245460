import { useApolloClient } from "@apollo/client";
import { KanbanCardAPI, KanbanCardLinkAPI } from "components/Kanban/@types"
import { useDeleteKanbanColumn } from "components/Kanban/hooks/useDeleteKanbanColumn"
import { KanbanAPIInput } from "components/Kanban/hooks/useKanbanAPI"
import useActiveResources from "hooks/useActiveResources"
import useChangeKanbanColumn from "hooks/useChangeKanbanColumn"
import useChangeKanbanColumnTitle from "hooks/useChangeKanbanColumnTitle"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { ID, OpenKanbanCardPayload } from "types/type"
import useCreateKanbanColumn from "../../../hooks/useCreateKanbanColumn"
import {
  ChangeColumnIndexPositionMutation,
  useChangeColumnIndexPositionMutation,
} from "../../../lib/graphql/operations"
import { createColumnOrdering } from "./createColumnOrdering"
import { useBoard } from "./useBoard"
import useCommentAPI from "./useCommentAPI/useCommentAPI"
import useEditAPI from "./useEditAPI"
import useGetCard from "./useGetCard"
import useImagesAPI from "./useImagesAPI/useImagesAPI"
import usePastedImageListener from "./usePastedImageListener"
import useSelectionAPI from "./useSelectionAPI"
import useSetBoard from "./useSetBoard"
import useSetCard from "./useSetCard"
import useTrackActiveKanbanCard from "./useTrackActiveKanbanCard"

type RoomKanbanAPIInput = {
  roomID: ID
  getCardLink?: KanbanCardLinkAPI["getCardShareableLink"]
  selectedCardID?: ID | null
  onChangeSelectedCardID?: (cardID: ID | null) => void
  openKanbanCard?: (payload: OpenKanbanCardPayload) => void
}

export default function useRoomKanban(props: RoomKanbanAPIInput) {
  const { workspaceID } = useActiveResources()

  const roomID = props.roomID

  const changeColumnTitle = useChangeKanbanColumnTitle({
    roomID,
  }).changeColumnTitle

  const onColumnDragEnd = useOnColumnDragEnd({ roomID })

  // const board = useOldBoard({
  const board = useBoard({
    roomID,
    workspaceID,
    fetchPolicy: "cache-and-network",
  })

  const getCard = useGetCard({ board })
  const setBoard = useSetBoard({ workspaceID, roomID })
  const setCard = useSetCard({ workspaceID, roomID })
  const trackActiveCard = useTrackActiveKanbanCard({ roomID, workspaceID })

  const selectionAPI = useSelectionAPI({
    selectedCardID: props.selectedCardID || undefined,
    onChangeSelectedCardID: props.onChangeSelectedCardID,
    trackActiveCard,
    getCard,
  })

  const imagesAPI = useImagesAPI({ workspaceID, getCard })
  const editAPI = useEditAPI()

  usePastedImageListener({ selectionAPI, editAPI, imagesAPI })

  const onCardDragEnd = useOnCardDragEnd()

  const commentAPI = useCommentAPI({ workspaceID, roomID, getCard })

  const linkAPI = useMemoAPI({ getCardShareableLink: props.getCardLink })

  const cardAPI: KanbanCardAPI = useMemoAPI({
    setCard,
    commentAPI,
    getCard,
    linkAPI,
    imagesAPI,
  })

  const value: KanbanAPIInput = {
    board,
    setBoard,
    cardAPI,
    editAPI,
    selectionAPI,
    openKanbanCard: props.openKanbanCard,
    roomID: props.roomID,
    onCardDragEnd,
    changeColumnTitle,
    onColumnDragEnd,
    deleteColumn: useDeleteKanbanColumn(),
    addColumn: useCreateKanbanColumn({ roomID: props.roomID }).createColumn,
  }

  return useMemoAPI(value)
}

function useOnCardDragEnd(): KanbanAPIInput["onCardDragEnd"] {
  const { changeCardColumn } = useChangeKanbanColumn()

  return React.useCallback<Exclude<KanbanAPIInput["onCardDragEnd"], undefined>>(
    function onCardDragEnd(card, __, destination) {
      return changeCardColumn({
        cardID: card.id,
        columnID: destination.toColumnId,
        indexPosition: destination.toPosition,
      })
    },
    [changeCardColumn]
  )
}

function useOnColumnDragEnd(props: {
  roomID: string
}): KanbanAPIInput["onColumnDragEnd"] {
  const [mutate] = useChangeColumnIndexPositionMutation()

  const r = useActiveResources()
  const client = useApolloClient()

  return React.useCallback<
    Exclude<KanbanAPIInput["onColumnDragEnd"], undefined>
  >(
    function onColumnDragEnd(column, __, destination) {
      const indexPosition = destination.toPosition
      const columnID = column.id

      return mutate({
        variables: {
          workspaceID: r.workspaceID,
          columnID,
          indexPosition,
        },

        optimisticResponse: (() => {
          const roomID = props.roomID
          const workspaceID = r.workspaceID

          const updated = createColumnOrdering(
            { roomID, workspaceID, columnID, indexPosition },
            client
          )

          const optimisticResponse: ChangeColumnIndexPositionMutation = {
            __typename: "Mutation",
            changeColumnIndexPosition: updated,
          }

          return optimisticResponse
        })(),
      })
    },
    [mutate, r.workspaceID, client]
  )
}
