import React, { useRef } from "react"
import IconWithNumber from "components/IconWithNumber/IconWithNumber"
import { Icon, IconButton, makeStyles, Paper } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import cslx from "clsx"
import Editable, { EditableAPI } from "components/Editable/Editable"
import KanbanContext from "components/Kanban/contexts/KanbanContext"
// import { KanbanCard } from "components/Kanban/@types"
// import Hyphenated from "components/Hyphenated/Hyphenated"
import { red } from "constants/colors"
import clsx from "clsx"
import ImageWithAuth from "components/ImageWithAuth/ImageWithAuth"
import { KanbanCardFragment } from "lib/graphql/operations"

export default function ListCard(props: {
  card: KanbanCardFragment
  className?: string
}) {
  const c = useStyles({})
  const api = React.useContext(KanbanContext)
  const cardID = props.card?.id

  const columnID = React.useMemo(() => {
    const id = api.getCardColumn(cardID)?.id || ""
    return id
  }, [cardID, api.getCardColumn])

  const onChange = React.useCallback(
    (value) => {
      api.contentAPI.setTitle(props.card.id, value)
    },
    [props.card.id, api.contentAPI.title]
  )
  const editableRef = React.useRef<EditableAPI>(null)

  const onStartEditing = React.useCallback(() => {
    api.editAPI.startEdition(columnID, cardID)
  }, [api.editAPI.startEdition, columnID, cardID])

  const onCancelEditing = React.useCallback(() => {
    api.editAPI.cancelEdition()
  }, [api.editAPI.cancelEdition])

  return (
    <Paper
      className={cslx("kanban-list-card", c.root, props.className)}
      onContextMenu={(e) => {
        e.preventDefault()
        editableRef.current?.startEdition()
      }}
      // onClick={() => api.selectionAPI.setSelectedCardID(props.card?.id)}
      onClick={() => api.openKanbanCard?.({ cardID: props.card?.id || "" })}
      style={{ overflow: "hidden" }}
    >
      {/* <img src={alt="" /> */}

      {(() => {
        const cover = api.cardAPI.imagesAPI.cover(props.card?.id)

        if (!cover) return null

        return (
          <ImageWithAuth src={cover.src}>
            {({ imageObjectURL }) => {
              return <img src={imageObjectURL} style={{ width: "100%" }}></img>
            }}
          </ImageWithAuth>
        )
      })()}

      <div className={clsx("content", c.content)}>
        <Editable
          ref={editableRef}
          allowToStartEditionOnClick={false}
          defaultValue={props.card.title || ""}
          onCancelEditing={onCancelEditing}
          onStartEditing={onStartEditing}
          isEditing={api.editAPI.isEditing(columnID, cardID)}
          placeholder="Description"
          classes={{ input: c.typographyBody2 }}
          onChange={onChange}
          multiline
        >
          <div>
            <Typography variant="body2">
              {props.card.title || "Description"}
              {/* <Hyphenated></Hyphenated> */}
            </Typography>
          </div>
          {/* <IconButton
            className="close-button"
            edge="end"
            onClick={(e) => {
              e.stopPropagation()
              onDelete()
            }}
          >
            <Icon style={{ fontSize: "15px" }}>close</Icon>
          </IconButton> */}
        </Editable>
        {(() => {
          const icons = [
            props.card.description && <Icon>wrap_text</Icon>,
            props.card.commentList?.comments?.length && (
              <IconWithNumber
                iconName="question_answer"
                number={props.card.notificationsCount}
                showZeroCounting={false}
                className={
                  props.card.notificationsCount ? c.withNotification : ""
                }
                classes={{ number: c.numberFontSize }}
              ></IconWithNumber>
            ),
          ].filter(Boolean)

          if (!icons.length) return null

          return (
            <div className={cslx("icons", c.icons)}>
              {icons.map((i, index) => (
                <React.Fragment key={index}>{i}</React.Fragment>
              ))}
            </div>
          )
        })()}
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  const padding = 12
  return {
    typographyBody2: theme.typography.body2,
    withNotification: {
      "& *": {
        color: `${red[0]} !important`,
      },
    },
    icons: {
      marginTop: 3,
      display: "flex",
      alignItems: "center",
      "&>*:not(:first-child)": {
        marginLeft: theme.spacing(1),
      },

      "& *": {
        fontSize: "19px !important",
        color: "rgba(0,0,0,.6)",
      },
    },
    numberFontSize: {
      fontSize: "17px !important",
    },
    content: {
      padding,
    },
    root: {
      width: 250,
      position: "relative",
      marginBottom: 10,
      "&:hover": {
        "& .close-button": {
          display: "block",
        },
      },
      "& .close-button": {
        position: "absolute",
        right: padding,
        top: "50%",
        transform: "translateY(-50%)",
        display: "none",
      },
    },
  }
})
