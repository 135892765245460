import _f from "lodash/fp"
import _ from "lodash"
import PaperList from "components/PaperList/index"
import clsx from "clsx"
import React, { useContext } from "react"
import DocumentsDashboardContext from "../../contexts/DocumentsDashboardContext"

export default function ActivitiesList({ className = "" }) {
  const {
    entries: activities,
    selectedEntryColor: selectedActivityStatusColor,
    fetchMore: fetchMore,
    EntryListItem: PropsListItem,
  } = useContext(DocumentsDashboardContext)

  if (!PropsListItem) return null

  return (
    <PaperList.Provider
      items={activities}
      fetchMore={fetchMore}
      ListItem={PropsListItem}
      selectedEntryColor={selectedActivityStatusColor}
    >
      <PaperList.OldRoot
        className={clsx("activities-list", className)}
      ></PaperList.OldRoot>
    </PaperList.Provider>
  )
}
