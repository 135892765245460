import { useApolloClient } from "@apollo/client";
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import {
  KanbanColumnFragment,
  useChangeKanbanCardColumnMutation,
} from "lib/graphql/operations"
import readFragment from "lib/updateFragment/readFragment"
import React from "react"
import { Typename } from "types/type"

export default function useChangeKanbanColumn() {
  const [mutate] = useChangeKanbanCardColumnMutation()

  const workspaceID = useActiveResources().workspaceID

  const client = useApolloClient()

  const changeCardColumn = React.useCallback(
    function submit(props: {
      cardID: string
      columnID: string
      indexPosition?: number
    }) {
      if (!props.cardID || !props.columnID) return

      const getColumn = (columnID: string) => {
        return readFragment(
          {
            typename: Typename.KanbanColumn,
            cacheIDParams: { id: columnID },
          },
          client
        )
      }

      const card = readFragment(
        {
          typename: Typename.KanbanCard,
          cacheIDParams: { id: props.cardID },
        },
        client
      )

      const oldColumnID = card?.columnID || ""

      return mutate({
        variables: {
          workspaceID,
          cardID: props.cardID,
          columnID: props.columnID,
          ...((props.indexPosition !== undefined && {
            indexPosition: props.indexPosition,
          }) ||
            {}),
        },

        optimisticResponse: {
          __typename: "Mutation",
          changeKanbanCardColumn: {
            __typename: "MutatedColumns",
            changedCard: card,
            upsertedColumns: [
              (function oldColumn() {
                const oldColumn = getColumn(oldColumnID)

                if (oldColumnID === props.columnID) return null

                const a: KanbanColumnFragment = {
                  ...oldColumn,
                  __typename: "KanbanColumn",
                  id: oldColumn?.id || "",
                  orderedCardsIDs:
                    oldColumn?.orderedCardsIDs?.filter(
                      (id) => id !== props.cardID
                    ) || [],
                }

                return a
              })(),

              (function newColumn() {
                const newColumn = getColumn(props.columnID)
                const cardsIDs = (() => {
                  if (oldColumnID === props.columnID)
                    return (newColumn?.orderedCardsIDs || []).filter(
                      (id) => id !== props.cardID
                    )

                  return newColumn?.orderedCardsIDs || []
                })()

                const orderedCardsIDs = [
                  ...(cardsIDs || []).slice(0, props.indexPosition),
                  props.cardID,
                  ...(cardsIDs || []).slice(props.indexPosition),
                ]

                const a: KanbanColumnFragment = {
                  ...newColumn,
                  __typename: "KanbanColumn",
                  id: newColumn?.id || "",
                  orderedCardsIDs,
                }

                return a
              })(),
            ].filter((a): a is KanbanColumnFragment => !!a),
          },
        },
      })
    },
    [workspaceID, mutate, client]
  )

  return useMemoAPI({
    changeCardColumn,
  })
}
