import { makeStyles } from "@material-ui/styles"
import React from "react"
import { Typography } from "@material-ui/core"
import Grid from "../../../Grid/Grid"
import { getName } from "../../util"
import clsx from "clsx"

export default function Fieldset({
  name: fieldsetName = "",
  label = "",
  className = "",
  spacing = 2,
  grid = { xs: 12 },
  getInput,
  fieldset
}) {
  const c = useStyles()

  return (
    <fieldset className={clsx(c.fieldset, className)}>
      {label && <Typography variant="body1">{label}</Typography>}
      <Grid
        spacing={spacing}
        grid={grid}
        renderEntry={({ entry: field }) => {
          return getInput({ fieldName: field.name })
        }}
        entries={fieldset}
      ></Grid>
    </fieldset>
  )
}

const useStyles = makeStyles(theme => {
  return {
    fieldset: {
      width: "100%",
      padding: 0,
      border: 0,
      margin: 0
    }
  }
})
