import PreviewTemplate from "./components/PreviewTemplate/PreviewTemplate"
import React from "react"
import { PreviewWithImage } from "./PreviewWithImage"

export type PreviewProps = {
  description: React.ReactNode
  title: React.ReactNode
  src: string
  leftRibbonColor?: string
}

export function Preview(props: PreviewProps) {
  const commonProps = {
    description: props.description,
    title: props.title,
    leftRibbonColor: props.leftRibbonColor,
  }

  if (!!props?.src) {
    return (
      <PreviewWithImage
        // description={props.description}
        // title={props.title}
        {...commonProps}
        src={props.src}
      />
    )
  }

  return <PreviewTemplate {...commonProps} />
}
