import _ from "lodash"
import { useCallback } from "react"
import uniqid from "uniqid"
import { useUploadContext } from "../context/UploadContext"
import { useParamsContext } from "../context/libContext"

export default function useAddFilesToContext() {
  const { addUploadedFiles } = useUploadContext()
  const { defaultUploadType, fileTypesAvailable } = useParamsContext() || {}

  return useCallback(
    async function addFilesToContext(acceptedFiles) {
      const urls = (await loadUrls(acceptedFiles)) || []

      const newUploadedFiles = acceptedFiles.map((file, index) => ({
        filename: file.name,
        id: uniqid(),
        base64URL: urls[index],
        fileObj: file,
        collectionType:
          defaultUploadType || _.get(fileTypesAvailable, "0.value")
      }))

      addUploadedFiles(newUploadedFiles)
    },
    [addUploadedFiles, JSON.stringify(fileTypesAvailable), defaultUploadType]
  )
}

function loadUrls(acceptedFiles) {
  return Promise.all(
    acceptedFiles.map(file => {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.readAsDataURL(file)
      })
    })
  )
}
