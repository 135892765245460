import getLibContext from "util/getLibContext"

export let { LibProvider, useParamsContext, useUtilsContext } = getLibContext({
  utilsDefault: {
    uploadFiles: () => null,
  },
  paramsDefault: {
    defaultUploadType: "",
  },
})
