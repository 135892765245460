import { makeStyles } from "@material-ui/styles"
import { EnhancedPageProps } from "components/EnhancedPage/EnhancedPage"
import FrameWithLoadingProgress from "components/FrameWithLoadingProgress/FrameWithLoadingProgress"
import { Subscriber } from "components/Observer"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import { propsInjectorFactory } from "lib/propsInjectorFactory"
import { StyledProps } from "types/type"
import { PageBoardItemContext } from "../PageBoardItemContext"
import {
  ArticleDetailPanel,
  ArticleDetailPanelProps as _ArticleDetailPanelProps,
} from "./components/ArticleDetailPanel/ArticleDetailPanel"

export type ArticleDetailPanelProps = Omit<
  _ArticleDetailPanelProps,
  "onClose" | "initialArticle" | "openArticle"
>

type Props = StyledProps & { onClose: () => void } & {
  ArticleDetailPanelProps?: ArticleDetailPanelProps
  renderTitleLine?: EnhancedPageProps["renderTitleLine"]
}

const ShopArticlePageBoardItemApi = propsInjectorFactory<Props>({
  contextName: "ShopArticlePageBoardItemApiContext",
})

export function ShopArticlePageBoardItem(props: Props) {
  const c = useStyles()

  return (
    <ShopArticlePageBoardItemApi.Provider {...props}>
      <PageBoardItemContext.Consumer>
        {(api) => {
          if (api.item?.__typename !== "ShopArticlePageBoardItem") return null

          if (!api.item.article) return null

          const article = api.item.article

          return (
            <ShopArticlePageBoardItemApi.Consumer>
              {(api) => {
                return (
                  <ArticleDetailPanel.Provider
                    initialArticle={article}
                    onClose={props.onClose}
                    {...(api.ArticleDetailPanelProps || {})}
                  >
                    <ArticleDetailPanel.AttributeVariationSelectionModalFrame>
                      <ItemTemplate.Provider onClose={props.onClose}>
                        <ArticleDetailPanel.Context.Consumer>
                          {(articleApi) => {
                            return (
                              <FrameWithLoadingProgress
                                isLoading={articleApi.isLoading}
                                style={{ height: "100%" }}
                              >
                                <ItemTemplate.Root
                                  EnhancedPageProps={{
                                    body: (
                                      <div className={c.body}>
                                        <ArticleDetailPanel.Body></ArticleDetailPanel.Body>
                                        <ItemTemplate.Context.Consumer>
                                          {(templateApi) => {
                                            return (
                                              <ArticleDetailPanel.Context.Consumer>
                                                {(articleApi) => {
                                                  return (
                                                    <Subscriber
                                                      doNotUnsubscribeWhenSubscriptionsChange
                                                      observable={
                                                        articleApi.goToVariationArticle$
                                                      }
                                                      subscription={(item) => {
                                                        articleApi.setArticle(
                                                          item
                                                        )
                                                      }}
                                                    ></Subscriber>
                                                  )
                                                }}
                                              </ArticleDetailPanel.Context.Consumer>
                                            )
                                          }}
                                        </ItemTemplate.Context.Consumer>
                                      </div>
                                    ),
                                    renderTitleLine: props.renderTitleLine,
                                  }}
                                ></ItemTemplate.Root>
                              </FrameWithLoadingProgress>
                            )
                          }}
                        </ArticleDetailPanel.Context.Consumer>
                      </ItemTemplate.Provider>
                    </ArticleDetailPanel.AttributeVariationSelectionModalFrame>
                  </ArticleDetailPanel.Provider>
                )
              }}
            </ShopArticlePageBoardItemApi.Consumer>
          )
        }}
      </PageBoardItemContext.Consumer>
    </ShopArticlePageBoardItemApi.Provider>
  )
}

const useStyles = makeStyles((theme) => ({
  body: {
    padding: 0,
    "&, & > *": {
      height: "100%",
    },
  },
}))
