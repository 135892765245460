import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { ShopArticleFragment } from "lib/graphql/operations"
import { useShop } from "./hooks/useShop"

const Factory = contextAndProviderFactory({
  hookApi: useShop,
})

export const ShopContext = Factory.Context
export const ShopProvider = Factory.Provider
