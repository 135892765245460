import { useApolloClient } from "@apollo/client";
import _ from "lodash"

export default function useFragment({ fragment, id, fragmentName = "" }) {
  const args = _.pickBy({ fragment, id, fragmentName }, v => !!v)

  const client = useApolloClient()

  try {
    return client.readFragment(args)
  } catch (e) {
    console.error(e)
    return
  }
}
