import { Button, makeStyles } from "@material-ui/core"
import ActionsPanel from "components/ActionsPanel/ActionsPanel"
import clsx from "clsx"
import { useContext } from "react"
import EditControlContext from "../../contexts/EditControlContext"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function EditPanel({ className = "", hidden = false }) {
  const { isEditing, editionChanged, saveEdition } =
    useContext(EditControlContext)

  const ableToSave = isEditing && editionChanged
  const t = useI18n()

  if (hidden) return null

  return (
    <ActionsPanel
      className={clsx("edit-panel", className)}
      actionButtons={[
        <Button
          color="primary"
          disabled={!ableToSave}
          onClick={saveEdition}
          variant="contained"
          size="small"
        >
          {t.save}
        </Button>,
      ]}
    ></ActionsPanel>
  )
}
