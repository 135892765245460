import React from "react"
import { KanbanCardFragment } from "lib/graphql/operations"
import useForm from "hooks/useForm"
import { KanbanCardInput } from "lib/graphql/types"
import useChangeKanbanCard from "./useChangeKanbanCard"

export default function useCardForm(props: {
  card: KanbanCardFragment | undefined | null
}) {
  const initialValues = React.useMemo(() => {
    let value: KanbanCardInput = {
      description: props.card?.description || "",
      title: props.card?.title || "",
    }

    return value
  }, [props.card?.description, props.card?.title])

  const changeKanbanCard = useChangeKanbanCard({ cardID: props.card?.id })

  return useForm({
    initialValues,
    onSubmit(e) {
      changeKanbanCard(e.values)
    },
    onBlur(p) {
      return changeKanbanCard({ [p.target.name]: p.target.value })
    },
  })
}
