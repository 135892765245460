import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import { ArticleDetailPanelContext } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ShopArticlePageBoardItem/components/ArticleDetailPanel/ArticleDetailPanelContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { excludeNullable } from "lib/excludeNullable"
import React from "react"
import { StyledProps } from "types/type"
import { Attributes } from "../Attributes/Attributes"
import * as Carousel from "../Carousel/Carousel"
import { Details } from "../Details/Details"
import Price from "../Price/Price"

export default function ArticleDesktopLayout(props: StyledProps) {
  const api = React.useContext(ArticleDetailPanelContext)
  const { article } = api

  const articleID = article?.id

  const images = [
    ...new Set<string>([...(article?.images || []).filter(excludeNullable)]),
  ]

  const t = useI18n()

  const hasThumbnails = images.length > 1

  const c = useStyles({ hasThumbnails })

  if (!articleID) return null

  return (
    <div
      className={clsx(c.root, props.className, ArticleDesktopLayout.name)}
      style={props.style}
    >
      <div className={c.content}>
        <div className={clsx(c.topContent)}>
          <div>
            <Carousel.Provider images={images}>
              <Carousel.Root></Carousel.Root>
              <Carousel.Context.Consumer>
                {(api) => {
                  {
                    hasThumbnails && (
                      <div className={c.thumbnails}>
                        {images.map((img, index) => (
                          <div
                            className={clsx(
                              c.thumbnail,
                              index === api.selectedIndex && c.selectedImage
                            )}
                          >
                            <img
                              src={img}
                              alt={"image-" + index}
                              className={clsx(c.img)}
                              onClick={() => api.setSelectedIndex(index)}
                            />
                          </div>
                        ))}
                      </div>
                    )
                  }
                }}
              </Carousel.Context.Consumer>
            </Carousel.Provider>
          </div>
          <div>
            {article.name && (
              <Typography variant="h5" gutterBottom>
                {article.name.replace(/(,|\.|,|-)/g, "$1 ")}
              </Typography>
            )}
            <Typography
              variant="body2"
              color="textSecondary"
              className={c.articleNumber}
              gutterBottom
            >
              {`${t["article-number"]}: ${article.sku}`}
            </Typography>
            {!article.brand?.imageSrc && article.brand?.name && (
              <Typography
                variant="body2"
                gutterBottom={!article.brand?.imageSrc}
              >
                {article.brand?.name}
              </Typography>
            )}
            {!!article.brand?.imageSrc && (
              <img
                src={article.brand?.imageSrc}
                className={c.brandImage}
                {...(article.brand?.name ? { alt: article.brand?.name } : {})}
                {...(article.brand?.name ? { title: article.brand?.name } : {})}
              />
            )}

            <Price article={article} className={c.price}></Price>

            <div className={c.actionsButtons}>
              {api.CartButtons && (
                <api.CartButtons
                  articleID={articleID}
                  // className={c.buttonMargin}
                ></api.CartButtons>
              )}

              {api.ShareButton && (
                <api.ShareButton
                  articleID={article.id}
                  // className={c.buttonMargin}
                ></api.ShareButton>
              )}
            </div>
            <Attributes></Attributes>
          </div>
        </div>

        <div>
          <Details details={article.details} className={c.details}></Details>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "& > *": {
        height: "100%",
      },
      // position: "relative"
    },
    carrousel: {
      display: "flex",
      alignItems: "center",
    },
    articleNumber: {},
    // brandNameWithImage: {
    //   marginB
    // },
    selectedImage: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    brandImage: {
      // marginBottom: theme.spacing(0),
      width: 110,
    },
    details: {
      // marginTop: theme.spacing(4),
    },
    content: {
      "&>*": {
        padding: 8,
      },
    },
    topContent: {
      // width: "65%",
      // maxWidth: "1024px",
      // margin: "0 auto",
      // padding: theme.spacing(6, 0),
      padding: 16,
      display: "flex",

      "&>*": {
        maxWidth: "50%",
        flex: 1,
      },
    },
    price: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    img: {
      display: "block",
      maxWidth: ({ hasThumbnails }: { hasThumbnails: boolean }) =>
        hasThumbnails ? "75%" : "100%",
      flex: 1,
    },
    thumbnails: {
      marginTop: theme.spacing(2),
      display: "flex",
      // justifyContent: "flex-end",
      "&>*": {
        cursor: "pointer",
        marginLeft: theme.spacing(2),
      },

      "& img": {
        width: "100px",
      },
    },

    thumbnail: {
      cursor: "pointer",
      paddingTop: theme.spacing(5),
      overflow: "hidden",
      width: theme.spacing(12),
      maxHeight: theme.spacing(12),
      "& img": {
        width: "100%",
      },
    },

    actionsButtons: {
      margin: theme.spacing(3, 0),

      "&>*": {
        width: "100%",
      },

      "&>*:not(:last-child)": {
        marginBottom: theme.spacing(1),
      },
    },
  }
})
