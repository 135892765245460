import { makeStyles, Typography, useTheme } from "@material-ui/core"
import PaperList from "components/PaperList/index"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { workStatusesColors } from "hooks/useActivityStatus/workStatuses"
import formatPrice from "lib/formatPrice"
import { DocumentFragment } from "lib/graphql/operations"
import moment from "moment"
import React, { useContext } from "react"
import { DocumentsListContext } from "./ContextAndProvider"
import { DocumentEntryStatus } from "./DocumentsEntryStatus"

export function EntryListItem(props: {
  id?: string
  title?: string
  type?: string
  partnerName?: string
  totalAmount?: number
  entry: DocumentFragment
}) {
  const { activeID, setActiveID } = React.useContext(DocumentsListContext)

  const t = useI18n()
  const isSelected = props.id === activeID

  const theme = useTheme()

  const color = (() => {
    if (isSelected) return getEntryColor(props.entry)
    return theme.palette.primary.dark
  })()

  const c = useStyles({ selectedEntryColor: color })
  const { RootContainer } = useContext(PaperList.Context)

  const left = (
    <PaperList.ListItemText
      primary={
        <RootContainer.ClippedText maxPercentage="50%">
          {props.title || ""}
        </RootContainer.ClippedText>
      }
      primaryTypographyProps={{
        style: {
          transition: "color 500ms",
          color,
        },
      }}
      secondary={
        <>
          {props.type && (
            <Typography variant="body2">
              {t.documentType[props.type as keyof typeof t.documentType] ||
                props.type}
              {(() => {
                if (!props.totalAmount) return null

                const value = formatPrice(props.totalAmount, "€")

                return value ? (
                  <span
                    style={{
                      // display: "block",
                      color: theme.palette.secondary.dark,
                    }}
                  >
                    {" - " + value}
                  </span>
                ) : (
                  ""
                )
              })()}
            </Typography>
          )}
          {props.partnerName && (
            <Typography variant="body2">
              <RootContainer.ClippedText maxPercentage="50%">
                {props.partnerName}
              </RootContainer.ClippedText>
            </Typography>
          )}
        </>
      }
    ></PaperList.ListItemText>
  )

  const right = (
    <PaperList.ListItemText
      primary={
        moment(props.entry.date, "YYYY-MM-DD").isValid() ? (
          <Typography
            style={{ textAlign: "right" }}
            variant="caption"
            color="textSecondary"
          >
            {moment(props.entry.date, "YYYY-MM-DD").format("DD.MM.YYYY")}
          </Typography>
        ) : (
          ""
        )
      }
      secondary={
        <>
          {/* {!!props.totalAmount && (
            <span
              style={{
                display: "block",
                color: theme.palette.secondary.dark,
              }}
            >
              {formatPrice(props.totalAmount, "€")}
            </span>
          )}
          <DocumentEntryStatus
            className={c.activityStatus}
            showColorDot={!isSelected}
            showColorExplanation={isSelected}
          ></DocumentEntryStatus> */}
        </>
      }
      secondaryTypographyProps={{ style: { textAlign: "right" } }}
    ></PaperList.ListItemText>
  )

  return (
    <PaperList.ListItem
      left={left}
      right={right}
      onClick={() => props.id && setActiveID(props?.id)}
      isSelected={isSelected}
      classes={{ listItemRight: c.listItemRight }}
      selectedColor={color}
    ></PaperList.ListItem>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    activityStatus: {
      float: "right",
    },

    listItemRight: {
      // textAlign: "right",
      paddingLeft: 0,
      flex: "initial",
    },
  }
})

const getEntryColor = function getDocumentColor(document?: DocumentFragment) {
  const result = (workStatusesColors[
    (document?.workStatus || "") as keyof typeof workStatusesColors
  ] || "") as string

  return result
}
