import { useApolloClient, useQuery } from "@apollo/client";
// import from
import _ from "lodash"
import cachedIDGetters from "lib/getCacheID/getCacheID"
import {
  CollectionsTypesTranslationsDocument,
  TranslatedWordFragmentDoc,
} from "lib/graphql/operations"
import { useMemo } from "react"

export default function useCollectionsTranslations() {
  const { data } = useQuery(CollectionsTypesTranslationsDocument)
  const translations = _.get(data, "collectionsTypesTranslations")

  const client = useApolloClient()

  const api = useMemo(
    () => ({
      translations,
      translate(type) {
        const data = client.readFragment({
          id: cachedIDGetters.TranslatedWord({ value: type }),
          fragment: TranslatedWordFragmentDoc,
        })

        return _.get(data, "locale")
      },
    }),
    [translations]
  )

  return api
}
