import { Icon, IconButton, Paper, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import Room from "components/Room/Room"
import TextField from "components/TextField/TextField"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import useTrueFalse from "hooks/useTrueFalse"
import React from "react"
import { RoomPageBoardItemApiContext } from "../../../../contexts/RoomPageBoardItemContext"
import RoomControlButtons from "../RoomControlButtons/RoomControlButtons"
import SearchOptions from "../SearchOptions/SearchOptions"

export default function Title() {
  const c = useStyles()

  const container = useRootContainerAPI()
  const api = React.useContext(RoomPageBoardItemApiContext)

  const searchInputRef = React.useRef<HTMLInputElement>(null)

  const buttonIsCollapsed = container.width < 400

  React.useEffect(() => {
    if (!api.searchField.isOpen) return
    searchInputRef.current?.focus()
  }, [!!api.searchField.isOpen])

  const isEditingName = useTrueFalse(false)

  return (
    <div className={clsx(c.root, "room-title")} ref={container.containerRef}>
      <Room.RoomName
        style={{ flex: 1 }}
        EditRoomNameProps={{ isEditing: isEditingName }}
        disabledEdition={!!api.initialParams?.summarizedLayout}
        ClippedTextProps={(p) => {
          return {
            maxWidth: isEditingName.isTrue
              ? "100%"
              : [
                  buttonIsCollapsed && 220,
                  container.width < 400 && 150,
                  container.width < 500 && 170,
                  container.width < 600 && 250,
                  container.width < 700 && 400,
                ].find((a) => !!a) || 500,
          }
        }}
      ></Room.RoomName>

      {(() => {
        if (isEditingName.isTrue) return null
        if (api.initialParams?.summarizedLayout) return null

        return (
          <RoomControlButtons
            collapsed={buttonIsCollapsed}
          ></RoomControlButtons>
        )
      })()}
      {(() => {
        if (!api.searchField.isOpen) return null

        return (
          <div className={c.searchMessages}>
            <Room.SearchMessages.Provider>
              <Room.SearchMessages.Context.Consumer>
                {(searchAPI) => {
                  return (
                    <Paper className={clsx("search-field", c.searchField)}>
                      <div>
                        <div style={{ display: "flex" }}>
                          <div style={{ padding: 8, flex: 1 }}>
                            <TextField
                              typographyVariant="body2"
                              variant="filled"
                              style={{ width: "100%" }}
                              value={searchAPI.search}
                              onChange={(a) =>
                                searchAPI.setSearch(a.target.value)
                              }
                              inputProps={{
                                ref: searchInputRef,
                                style: {
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <Icon
                                    style={{ fontSize: 19, marginRight: 8 }}
                                  >
                                    search
                                  </Icon>
                                ),
                              }}
                            ></TextField>
                          </div>

                          <IconButton onClick={api.searchField.close}>
                            <Icon>close</Icon>
                          </IconButton>
                        </div>

                        <SearchOptions
                          options={searchAPI.searchResult.list || []}
                        ></SearchOptions>
                      </div>
                    </Paper>
                  )
                }}
              </Room.SearchMessages.Context.Consumer>
            </Room.SearchMessages.Provider>
          </div>
        )
      })()}
    </div>
  )
}
const useStyles = makeStyles(() => ({
  searchField: { background: "#f5f5f5", zIndex: 1 },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",

    position: "relative",
  },

  searchMessages: {
    left: 0,
    right: 0,
    // background: "red",
    // height: 80,
    position: "absolute",
    top: "100%",
    zIndex: 1,
  },
}))
