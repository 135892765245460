import React, { useContext, useState, useMemo, useCallback } from "react"
import _ from "lodash"
import CollectionDocumentsFilterContext from "./contexts/CollectionDocumentsFilterContext"
import FilterButton from "./components/FilterButton/FilterButton"
import ChipsPaper from "./components/ChipsPaper/ChipsPaper"

const DocumentsFilter = {
  Context: CollectionDocumentsFilterContext,
  Button: FilterButton,
  ChipsPaper,
  Provider: function CollectionDocumentsFilterProvider({
    children,
    DocumentsFilter,
  }) {
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    const toggleFilter = useCallback(
      setIsFilterOpen.bind(null, (a) => !a),
      []
    )

    const api = {
      isFilterOpen,
      toggleFilter,
      DocumentsFilter,
    }

    const value = useMemo(() => api, Object.values(api))

    return (
      <CollectionDocumentsFilterContext.Provider value={value}>
        {children}
      </CollectionDocumentsFilterContext.Provider>
    )
  },
}

export default DocumentsFilter
