import { Button, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { DocumentFormContext } from "components/DocumentForm/contexts/DocumentFormContext"
import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { PageForm } from "components/Page/Page"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { useGroupInTwoInLargeDevices } from "hooks/useGroupInTwoInLargeDevices"
import React from "react"
import { StyledProps } from "../../../../types/type"
import getPartnerFieldProps from "../../../Page/components/PageForm/lib/getPartnerFieldProps"
import getTimelogFieldProps from "../lib/renderTimeLog/getTimelogFieldProps"
import getCollectionsFieldProps from "./lib/getIndentationProps/getCollectionsFieldProps"
import getDescriptionFieldProps from "./lib/getIndentationProps/getDescriptionFieldProps"
import getMoneyFieldProps from "./lib/getIndentationProps/getMoneyFieldProps"
import getTextFieldProps from "./lib/getIndentationProps/getTextFieldProps"

export function Form(props: StyledProps) {
  const api = React.useContext(DocumentFormContext)
  const c = useStyles()

  const translations = useI18n()

  const indentationProps: Array<FieldsetIndentationProps | null | false> = [
    getDescriptionFieldProps({ api: api, translations }),
    !api.hidePartnerField &&
      getPartnerFieldProps({
        translations,
        PartnerTextFieldProps: {
          layout: api.twoPagesAPI,
          partner: api.partner,
          PartnerFormProps: {
            onClickOpenButton: api.openPartnerPage,
          },
        },
      }),
    !api.summarized && getCollectionsFieldProps({ api: api, translations }),
    !api.summarized && getTextFieldProps({ api: api, translations }),
    getMoneyFieldProps({
      api: api,
      translations,
      summarized: !!api.summarized,
    }),
    !api.summarized &&
      getTimelogFieldProps({
        translations,
        updated: {
          timestamp: api.document?.updated,
          person: api.document?.updatedBy,
        },
        created: {
          timestamp: api.document?.created,
          person: api.document?.createdBy,
        },
      }),
  ].filter((a) => !!a)

  const { grouped, containerRef } = useGroupInTwoInLargeDevices({
    items: indentationProps,
  })

  return (
    <div
      ref={containerRef}
      style={props.style}
      className={clsx("contact-form", props.className)}
    >
      <PageForm fieldsIndentationsProps={grouped}></PageForm>

      {api.summarized && (
        <div className={clsx(c.openItem)}>
          <Button
            size="large"
            onClick={api.onClickOpenButton}
            variant="outlined"
            color="primary"
            disableElevation
          >
            {translations.open}
          </Button>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    openItem: {
      // paddingTop:
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "center",

      "& button": {
        width: "100%",
      },
    },
    root: {
      display: "flex",
      flexDirection: "column",
      "&>*:first-child": {
        flex: 1,
        overflow: "auto",
      },
    },
  }
})
