import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import React from "react"
import { $getSelection, $isRangeSelection } from "lexical"
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link"
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils"
import { $isListNode, ListNode } from "@lexical/list"
import { $isHeadingNode } from "@lexical/rich-text"
import $getSelectedNode from "../lib/$getSelectedNode"
import { BlockType } from "../types"
import useMemoAPI from "hooks/useMemoAPI"

export type ToolbarPluginAPIInput = {
  fontSize?: number
}

export default function useToolbarPluginAPI(props: ToolbarPluginAPIInput) {
  const [editor] = useLexicalComposerContext()
  const toolbarRef = React.useRef(null)
  const [blockType, setBlockType] = React.useState<BlockType>("paragraph")
  const [isLink, setIsLink] = React.useState(false)
  const [isBold, setIsBold] = React.useState(false)
  const [isItalic, setIsItalic] = React.useState(false)
  const [isUnderline, setIsUnderline] = React.useState(false)
  const [isStrikethrough, setIsStrikethrough] = React.useState(false)

  const updateToolbar = React.useCallback(() => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow()
      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getTag() : element.getTag()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()

          setBlockType(type as BlockType)
        }
      }
      // Update text format buttons (bold, italic, etc) based on selection
      setIsBold(selection.hasFormat("bold"))
      setIsItalic(selection.hasFormat("italic"))
      setIsUnderline(selection.hasFormat("underline"))
      setIsStrikethrough(selection.hasFormat("strikethrough"))

      // Update links
      const node = $getSelectedNode(selection)
      const parent = node.getParent()

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
    }
  }, [editor])

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      })
    )
  }, [editor, updateToolbar])

  const insertLink = React.useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://")
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink])

  return useMemoAPI({
    ...props,
    insertLink,
    isLink,
    isBold,
    isItalic,
    isUnderline,
    isStrikethrough,
    blockType,
    toolbarRef,
  })
}
