import React from "react"
import { Typography } from "@material-ui/core"
import { CalendarEventFragment } from "../../../../lib/graphql/operations"
import UserListItem from "../UserListItem/UserListItem"
import { useI18n } from "contexts/i18nContext/i18nContext"

const MembersList = {
  isVisible(props: { event?: CalendarEventFragment }) {
    const users = props.event?.members
    return !!users?.length
  },
  Component: function MembersList(props: {
    event: CalendarEventFragment
    style?: React.CSSProperties
  }) {
    const users = props.event?.members

    const quantity = users?.length

    const t = useI18n()

    return (
      <div className="members-list" style={props.style || {}}>
        <Typography variant="body1">
          {(() => {
            if (!quantity) return null
            return `${quantity} ${quantity > 1 ? t["members"] : t["member"]}`
          })()}
        </Typography>
        <div className="users" style={{ marginLeft: -10 }}>
          {users?.map((user) => {
            return <UserListItem user={user} key={user?.id}></UserListItem>
          })}
        </div>
      </div>
    )
  },
}

export default MembersList
