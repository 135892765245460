import moment from "moment"
import { Icon, IconButton } from "@material-ui/core"
import { DocumentFormAPI } from "../../../../contexts/DocumentFormContext"
import { I18nAPI } from "contexts/i18nContext/i18nContext"
import { DocumentStatusType } from "components/DocumentForm/types"
import DocumentTypesAutocomplete from "components/Input/components/DocumentTypesAutocomplete/DocumentTypesAutocomplete"
import TextField from "../../../../../TextField/TextField"
import { FieldsetIndentationProps } from "../../../../../Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import DateTextField from "components/DocumentForm/components/DateTextField/DateTextField"
import AutoComplete from "../../../../../AutoComplete/AutoComplete"

export default function getDescriptionFieldProps(props: {
  translations: I18nAPI
  api: DocumentFormAPI
}) {
  const { translations, api } = props

  const description: FieldsetIndentationProps = {
    icon: "description",
    children: (
      <>
        <TextField
          label={translations.activityDetails.title}
          {...api.form.getFieldProps("title")}
        ></TextField>
        <DateTextField
          {...api.form.getFieldProps("date")}
          label={translations.activityDetails.date}
        ></DateTextField>

        <DocumentTypesAutocomplete
          {...api.form.getFieldProps("type")}
          TextFieldProps={{ label: translations.activityDetails.type }}
          AutoComplete={AutoComplete}
        ></DocumentTypesAutocomplete>

        {!!api.status && (
          <TextField
            label={(() => {
              const statusLabel = {
                [DocumentStatusType.jobStatus]: translations["job-status"],
                [DocumentStatusType.workStatus]: translations["workstatus"],
              }

              const status = api.status

              return <>{statusLabel[status.type]}</>
            })()}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            value={api.status.value}
          ></TextField>
        )}
      </>
    ),
  }

  return description
}
