import { Icon } from "@material-ui/core"
import LightTooltip from "components/LightTooltip/LightTooltip"
import OptionsToggleMenu from "components/OptionsToggleMenu/OptionsToggleMenu"
import { IndexedItemsContext } from "components/PageBoard/components/IndexedItems/IndexedItems"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import { StyledProps } from "types/type"
import useTypesOptions from "../../../../hooks/useTypesOptions"

export function FilterToggleableMenu(props: StyledProps) {
  const api = React.useContext(IndexedItemsContext)

  const options = useTypesOptions()

  const t = useI18n()

  return (
    <OptionsToggleMenu
      className={props.className}
      style={props.style}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      options={api.availableTypes
        .map((a) => options[a] || null)
        .filter((a): a is Exclude<typeof a, null> => !!a)}
      anchor={
        <LightTooltip title={t["filter"]}>
          <Icon>filter_list</Icon>
        </LightTooltip>
      }
      IconButtonProps={{ size: "small", color: "secondary" }}
      value={api.types.value}
      onChange={api.types.set}
    ></OptionsToggleMenu>
  )
}
