import { useContext, useEffect, useState } from "react"
import DecisionDialog from "components/DecisionDialog/DecisionDialog"
import delete$ from "components/Room/observers/delete$"
import { useI18n } from "contexts/i18nContext/i18nContext"
import RoomTemplateContext from "../RoomTemplate/contexts/RoomTemplateContext"

export function DeleteMessageDialog() {
  const { deleteMessage } = useContext(RoomTemplateContext)
  const [open, setOpen] = useState(false)

  const [id, setID] = useState<string | null>(null)
  const t = useI18n()

  useEffect(() => {
    setOpen(!!id)
  }, [id])

  useEffect(() => {
    const sub = delete$.subscribe(({ messageID }) => {
      setID(messageID)
    })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  const text = t["delete-dialog"]

  return (
    <DecisionDialog
      open={open}
      onClose={() => {
        setOpen(false)
        setID(null)
      }}
      onDisagree={() => {
        delete$.next({ messageID: null })
        setOpen(false)
      }}
      onAgree={() => {
        deleteMessage({ messageID: id })
        setOpen(false)
      }}
      disagreeText={text.disagreeText}
      agreeText={text.agreeText}
      title={text.title}
      content={text.content}
    />
  )
}
