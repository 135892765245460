import useOnChange from "hooks/useOnChange"
import useUploadDocuments from "hooks/useUploadDocuments/useUploadDocuments"
import { useMemo } from "react"

export default function useUploadFiles({
  partnerID,
  collectionsIDs,
  onChangeLoadingState
}) {
  const {
    uploadDocuments: uploadFiles,
    loading,
    uploadDone,
    resetUpload,
    amountUploaded
  } = useUploadDocuments({
    partnerID,
    collectionsIDs: (collectionsIDs || []).filter(Boolean)
  })

  useOnChange({ value: loading, onChange: onChangeLoadingState })

  return useMemo(() => {
    return { uploadFiles, uploadDone, resetUpload, amountUploaded, loading }
  }, [uploadFiles, uploadDone, amountUploaded, loading])
}
