import { PageBoardItemType } from "lib/graphql/types"

export const indexedTypes = [
  PageBoardItemType.Room,
  PageBoardItemType.User,
  PageBoardItemType.CalendarEvent,
  PageBoardItemType.Message,
  PageBoardItemType.KanbanCard,
  PageBoardItemType.KanbanCardComment,
  PageBoardItemType.Document,
  PageBoardItemType.Contact,
  PageBoardItemType.Vendor,
  PageBoardItemType.ShopOrder,
  PageBoardItemType.Customer,
  PageBoardItemType.Lead,
]
