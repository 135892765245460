import PartnerContext from "./contexts/PartnerContext"
import PartnerForm from "./components/PartnerForm/PartnerForm"

//
const Partner = {
  Form: PartnerForm,
  Context: PartnerContext,
}

export default Partner
