import useOnChange from "hooks/useOnChange"
import useDeprecatedPaginatedDocuments from "hooks/useDeprecatedPaginatedDocuments/useDeprecatedPaginatedDocuments"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"

export default function useCollectionDocuments({
  relatedCollectionsIDs: propsRelatedCollectionsIDs,
  collectionID,
  onChangeDocumentsLoadingState = () => undefined,
  onChangeRelatedCollectionsIDs: propsOnChangeRelatedCollectionsIDs = () =>
    undefined,
  activeDocumentID = undefined,
}) {
  const { relatedCollectionsIDs, onChangeRelatedCollectionsIDs } =
    useRelatedCollectionsIDs({
      defaultValue: propsRelatedCollectionsIDs,
      onChange: propsOnChangeRelatedCollectionsIDs,
    })

  const paginatedDocuments = useDeprecatedPaginatedDocuments({
    collectionID,
    relatedCollectionsIDs,
    fetchPolicy: "cache-and-network",
    activeDocumentID,
    memoizeActiveDocumentID: true,
  })

  useOnChange({
    onChange: onChangeDocumentsLoadingState,
    value: (paginatedDocuments || {}).isLoading,
  })

  const api = {
    paginatedDocuments,
    onChangeRelatedCollectionsIDs,
    collectionID,
    relatedCollectionsIDs,
  }

  return useMemo(() => api, [..._.values(api)])
}

const sort = (as) => {
  if (!as) return as

  return [...(as || [])].sort((a, b) => {
    if (b > a) return 1
    return -1
  })
}

function useRelatedCollectionsIDs({ defaultValue, onChange }) {
  const [relatedCollectionsIDs, setRelatedCollectionsIDs] = useState(
    defaultValue ? sort(defaultValue) : undefined
  )

  const getString = (ids) => JSON.stringify(sort(ids))

  useEffect(() => {
    if (getString(defaultValue) === getString(relatedCollectionsIDs)) {
      return
    }

    setRelatedCollectionsIDs(sort(defaultValue))
  }, [getString(defaultValue)])

  useEffect(() => {
    if (typeof onChange !== "function") return
    onChange(relatedCollectionsIDs)
  }, [getString(relatedCollectionsIDs)])

  const api = useMemo(() => {
    return {
      relatedCollectionsIDs,
      onChangeRelatedCollectionsIDs: (ids) =>
        setRelatedCollectionsIDs(sort(ids)),
    }
  }, [getString(relatedCollectionsIDs)])

  return api
}
