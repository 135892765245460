import UserAvatar from "../UserAvatar/UserAvatar"
import {
  MetaCardWithAvatar,
  MetaCardWithAvatarProps,
} from "../MetaCardWithAvatar/MetaCardWithAvatar"

export default function MetaCardWithUserAvatar(
  props: {
    userName: string
  } & MetaCardWithAvatarProps
) {
  return (
    <MetaCardWithAvatar
      {...props}
      avatar={props.avatar || <UserAvatar name={props.userName} />}
    />
  )
}
