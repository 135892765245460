import React, { useCallback } from "react"
import CopyToClipboardInput from "../CopyToClipboardInput/CopyToClipboardInput"
import getAccessKeyLink from "lib/getAccessKeyLink"

export default function CopyAccessKeyToClipboardInput(
  props: Omit<
    React.ComponentProps<typeof CopyToClipboardInput>,
    "getClipboardValue"
  >
) {
  const getLink = useCallback(
    (accessKey: string) => getAccessKeyLink(accessKey),
    []
  )

  return (
    <CopyToClipboardInput
      {...props}
      getClipboardValue={getLink}
    ></CopyToClipboardInput>
  )
}
