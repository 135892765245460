import { Alert } from "@material-ui/lab"
import LeftSnackbar from "components/LeftSnackbar/LeftSnackbar"
import { ApolloErrorContext } from "contexts/apolloErrorContext/ApolloErrorContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import useMemoAPI from "hooks/useMemoAPI"
import { AppError } from "lib/graphql/types"
import { useSubscription } from "observable-hooks"
import React from "react"
import { filter, map } from "rxjs/operators"

export default function useErrorControllerAPI() {
  const [message, setMessage] = React.useState("")

  const t = useI18n()

  const errorMessages = React.useMemo(() => {
    return {
      [AppError.CanNotAddUser]: t["user-cannot-be-added"],
      [AppError.DuplicateCollection]: "Duplicate collection",
    }
  }, [t])

  const id = React.useRef<NodeJS.Timeout>()
  const { graphQLError$ } = React.useContext(ApolloErrorContext)

  const pushError = React.useCallback(
    (message: string) => {
      clearTimeout(id.current)
      setMessage(message)
      id.current = setTimeout(() => setMessage(""), 5000)
    },
    [setMessage]
  )

  const errorMessage$ = React.useMemo(() => {
    return graphQLError$.pipe(
      map((e) => e.extensions?.code),
      filter(
        (a): a is AppError => !!Object.values(AppError).includes(a as AppError)
      ),
      filter((a): a is AppError => a in errorMessages),
      map((a) => errorMessages[a as keyof typeof errorMessages])
    )
  }, [graphQLError$])

  useSubscription(errorMessage$, pushError)

  return useMemoAPI({ message, showErrorOnUI: pushError })
}
