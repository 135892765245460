import { useApolloClient } from "@apollo/client";
import { ID, RoomQueryVariables } from "types/type"
import React from "react"
import { EditKanbanCommentDocument } from "lib/graphql/operations"

export default function useEditComment(props: RoomQueryVariables) {
  const client = useApolloClient()

  return React.useCallback(
    (cardID: ID, commentID: ID, text: string) => {
      const optimisticResponse = {
        __typename: "Mutation",
        editKanbanComment: {
          id: commentID,
          text,
          __typename: "KanbanComment",
        },
      }

      client.mutate<typeof optimisticResponse>({
        mutation: EditKanbanCommentDocument,
        variables: {
          roomID: props.roomID,
          workspaceID: props.workspaceID,
          cardID,
          commentID,
          text,
        },
        optimisticResponse,
      })
    },
    [props.roomID, props.workspaceID]
  )
}
