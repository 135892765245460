import { ListItemText, ListItemTextProps } from "@material-ui/core"
import useMemoAPI from "hooks/useMemoAPI"
import { useContext } from "react"
import OldPaperListRoot from "./components/OldPaperListRoot/OldPaperListRoot"
import PaperListItem from "./components/PaperListItem/PaperListItem"
import PaperListProvider from "./components/PaperListProvider/PaperListProvider"
import PaperListRoot from "./components/PaperListRoot/PaperListRoot"
import PaperListApiContext from "./contexts/PaperListContext"

const PaperList = {
  OldRoot: OldPaperListRoot, //old root with old virtualized list
  Root: PaperListRoot,
  Provider: PaperListProvider,
  ListItem: PaperListItem,
  ListItemText,
  CenteredListItemText: function CenteredListItemText(
    props: ListItemTextProps
  ) {
    return (
      <ListItemText
        {...props}
        secondary={
          props.secondary ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              {props.secondary}
            </div>
          ) : (
            props.secondary
          )
        }
        primary={
          props.primary ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              {props.primary}
            </div>
          ) : (
            props.primary
          )
        }
      ></ListItemText>
    )
  },
  Context: PaperListApiContext,
  useRootContainer: useRootContainer,
}

function useRootContainer() {
  const { RootContainer } = useContext(PaperListApiContext)
  const rootContainerContext = useContext(RootContainer.Context)
  const { sizeLabel } = rootContainerContext

  const isSmall = sizeLabel === RootContainer.SizeEnums.SMALL
  const isLarge = sizeLabel === RootContainer.SizeEnums.LARGE
  const isMedium = sizeLabel === RootContainer.SizeEnums.LARGE

  return useMemoAPI({ ...rootContainerContext, isSmall, isLarge, isMedium })
}

export default PaperList
