import ClippedText from "components/ClippedText/ClippedText"
import {
  EnhancedPageProps,
  EnhancedPage,
} from "components/EnhancedPage/EnhancedPage"
import DocumentFormContext from "components/OldDocumentForm/contexts/DocumentFormContext"
import Pdf from "components/Pdf/Pdf"
import React from "react"
import { PageComponent } from "../../../Page/hooks/useSecondPage"

const PDFPage: PageComponent = function PDFPage(props) {
  const api = React.useContext(DocumentFormContext)

  const renderTitleLine = React.useCallback(
    (() => {
      const r: EnhancedPageProps["renderTitleLine"] = function render({
        titleComponent,
      }) {
        return (
          <>
            <DocumentFormContext.Consumer>
              {(api) => {
                return (
                  <ClippedText
                    maxWidth={api.twoPagesApi.rootContainer.width * 0.2}
                  >
                    {titleComponent}
                  </ClippedText>
                )
              }}
            </DocumentFormContext.Consumer>

            <div>
              <Pdf.Controls IconButtonProps={{ size: "small" }}></Pdf.Controls>
            </div>
          </>
        )
      }

      return r
    })(),
    [props.onClose]
  )

  return (
    <Pdf.Provider url={api.document?.pdf || ""}>
      <EnhancedPage
        onClose={api.pdfPage.close}
        title={api.document?.title || ""}
        className="pdf-page"
        style={{ height: "100%" }}
        body={
          <div style={{ marginBottom: 20 }}>
            <Pdf.Body style={{ padding: 20, marginBottom: 24 }}></Pdf.Body>
          </div>
        }
        renderTitleLine={renderTitleLine}
      ></EnhancedPage>
    </Pdf.Provider>
  )
}

//

export default PDFPage
