import useMemoAPI from "hooks/useMemoAPI"
import React from "react"

export default function Hook<
  HookProps extends { [key: string]: any } | undefined | null,
  HookReturn extends any
>(props: {
  hook: (args: HookProps) => HookReturn
  hookProps: HookProps
  children?: (args: HookReturn) => React.ReactNode
}) {
  const apiProps = useMemoAPI(props.hookProps)

  const api = props.hook(apiProps)

  if (!props.children) return null

  return <>{props.children?.(api)}</>
}
