import React from "react"
import moment from "moment"
import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import useWeekGrid from "../../hooks/useWeekGrid"
import { Month, WeekDay } from "components/MonthCalendar/types"
import WeekRow from "../WeekRow/WeekRow"
import arePropsEqual from "util/arePropsEqual"
import MonthCalendarContext from "components/MonthCalendar/contexts/MonthCalendarContext"

export type WeekProps = Omit<React.ComponentProps<typeof WeekRow>, "week">

export default React.memo(
  MonthCalendarRoot,
  arePropsEqual([
    "className",
    "style",
    "showMonthHeader",
    "renderWeek",
    "WeekProps",
  ])
)

function MonthCalendarRoot(props: {
  className?: string
  showMonthHeader?: boolean
  style?: React.CSSProperties
  WeekProps?: WeekProps
  renderWeek?: (props: {
    week: moment.Moment[]
    showDayOfWeekName: boolean
  }) => React.ReactNode
}) {
  const c = useStyles({})

  const ctx = React.useContext(MonthCalendarContext)

  const grid = useWeekGrid({
    month: ctx.month,
    firstDayOfWeek: WeekDay.Monday,
  })

  const defaultRenderDay = React.useCallback(
    (p: { day: moment.Moment; defaultComponent?: React.ReactNode }) => {
      if (p.day.startOf("day").month() !== ctx.month.index) return null

      return p.defaultComponent
    },
    [ctx.month.index]
  )

  return (
    <div
      className={clsx("month-calendar-root", c.root, props.className)}
      style={props.style || {}}
    >
      {(function renderHeader() {
        if (!props.showMonthHeader) return null

        const d = moment().month(ctx.month.index).year(ctx.month.year)

        return (
          <Typography
            variant="body1"
            color="textSecondary"
            className={clsx(c.monthHeader, "month-header")}
          >{`${d.format("MMMM")}, ${d.format("YYYY")}`}</Typography>
        )
      })()}
      <div className="grid">
        {grid.map((week, weekIndex) => {
          return (
            <div className="row" key={`week-${weekIndex + 1}`}>
              {(() => {
                if (!props.renderWeek)
                  return (
                    <WeekRow
                      week={week}
                      showDayOfWeekName={weekIndex === 0}
                      classes={{ cell: c.cell }}
                      renderDay={defaultRenderDay}
                      {...(props.WeekProps || {})}
                    ></WeekRow>
                  )

                return props.renderWeek({
                  week,
                  showDayOfWeekName: weekIndex === 0,
                })
              })()}
            </div>
          )
        })}
      </div>
    </div>
  )
}
const useStyles = makeStyles(() => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",

      "&>.grid": {
        flex: 1,
        display: "flex",
        flexDirection: "column",

        "&>.row": {
          flex: 1,

          "&>*": {
            height: "100%",
          },
        },
      },
    },
    cell: {
      textAlign: "center",
    },
    monthHeader: {
      textAlign: "center",
      marginBottom: 8,
    },
  }
})
