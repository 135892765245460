import { InlinedChips } from "components/InlinedChips/InlinedChips"
// import useStyles from "../../../../../Page/components/PageForm/PageForm"
import { ChipProps, makeStyles } from "@material-ui/core"
// import { purple } from "constants/colors"
import { purple, red } from "constants/colors"
import isTouchDevice from "lib/isTouchDevice"
import React from "react"
import { DateIntervalApiContext } from "./DateIntervalSelectorContext"
import { periodOptions } from "./components/CalendarViews/hooks/usePeriodSelector"

export function PeriodSelector() {
  const { periodType, setPeriodType } = React.useContext(DateIntervalApiContext)
    .monthsApi.periodSelectorApi

  const c = useStyles()
  return (
    <InlinedChips
      wrap
      style={{ flexWrap: isTouchDevice ? "nowrap" : "wrap" }}
      chips={[
        {
          key: "all",
          label: "immer",
          size: isTouchDevice ? "medium" : "small",
          classes: {
            // root: "-selector-chip",
            clickableColorPrimary: c.selected,
          },
          color: periodType === "all" ? "primary" : "default",
          onClick() {
            setPeriodType("all")
          },
        },
        ...(periodOptions || []).map<ChipProps>((a) => {
          return {
            key: a.period,
            label: a.description,
            size: isTouchDevice ? "medium" : "small",
            classes: {
              clickableColorPrimary: c.selected,
            },
            color: periodType === a.period ? "primary" : "default",
            onClick() {
              setPeriodType(a.period)
            },
          }
        }),
      ]}
    ></InlinedChips>
  )
}

const useStyles = makeStyles((theme) => ({
  selected: {
    // color: theme.palette.primary.main,
    backgroundColor: `${purple[1]} !important`,
    // color:
    // fontWeight: 600
  },
  highlightedSelected: {
    backgroundColor: `${red[0]} !important`,
  },
}))
