import React, { useState, useMemo } from "react"

const SideSheetContext = React.createContext({
  isSideSheetOpen: false,
  openSideSheet: () => null,
  closeSideSheet: () => null,
  toggleSideSheet: () => undefined,
})

SideSheetContext.displayName = "SideSheetContext"

export default SideSheetContext

export function SideSheetProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false)

  const api = useMemo(
    () => ({
      isSideSheetOpen: !!isOpen,
      openSideSheet: () => setIsOpen(true),
      toggleSideSheet: () => {
        setIsOpen((v) => !v)
      },
      closeSideSheet: () => setIsOpen(false),
    }),
    [isOpen]
  )

  return (
    <SideSheetContext.Provider value={api}>
      {children}
    </SideSheetContext.Provider>
  )
}

export function useSideSheetController() {
  return React.useContext(SideSheetContext)
}
