import { Typography, makeStyles } from "@material-ui/core"
import { Button } from "components/Button/Button"
import UseEffect from "components/UseEffect/UseEffect"
import React from "react"
import I18nContext from "contexts/i18nContext/i18nContext"
import { MobileHiddenXScrollbar } from "components/MobileHiddenXScrollbar/MobileHiddenXScrollbar"
import { CalendarView } from "./CalendarView"
import { PeriodSelector } from "./PeriodSelector"
import { Months, Period } from "./components/Months"

export function MonthDaysView(props: {
  onClose: () => any
  onSubmit?: (period: Period) => any
  renderTitle: (defaultComponent: React.ReactNode) => React.ReactNode
}) {
  const c = useStyles()
  const t = React.useContext(I18nContext)

  const periodSelector = (
    <Months.Context.Consumer>
      {(api) => {
        const period = api.period

        return (
          <MobileHiddenXScrollbar.Provider>
            <MobileHiddenXScrollbar.Root className={c.chipsWrapper}>
              <UseEffect
                deps={[[period?.start].map((a) => a?.toISOString()).join("-")]}
                effect={() => {
                  if (!period?.start) return

                  api.list.activeMonth.setMonth({
                    index: period?.start?.month(),
                    year: period?.start?.year(),
                  })
                }}
              ></UseEffect>
              <PeriodSelector></PeriodSelector>
            </MobileHiddenXScrollbar.Root>
          </MobileHiddenXScrollbar.Provider>
        )
      }}
    </Months.Context.Consumer>
  )
  return (
    <CalendarView
      renderTitle={(date) =>
        props.renderTitle(
          <Typography variant="body1">{date.format("MMMM YYYY")}</Typography>
        )
      }
      additionPeriod="month"
      body={
        <React.Fragment>
          {periodSelector}
          <Months.Root></Months.Root>
          <Months.Context.Consumer>
            {(api) => (
              <div className={c.buttons}>
                <div>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => props.onClose()}
                  >
                    {t.close}
                  </Button>
                </div>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={!api.period?.start}
                  onClick={() => api.setPeriod(null)}
                >
                  {t.clear}
                </Button>
              </div>
            )}
          </Months.Context.Consumer>
        </React.Fragment>
      }
    ></CalendarView>
  )
}

export const useStyles = makeStyles((theme) => {
  return {
    buttons: {
      display: "flex",
      marginTop: 16,
      justifyContent: "space-between",
      marginBottom: 8,
      marginRight: 8,
      marginLeft: 8,

      "& button": {
        "& *": {
          fontSize: "inherit",
        },
      },
    },
    chipsWrapper: {
      display: "flex",
      alignItems: "center",
      padding: 8,

      // ...(() => {
      //   if (!isTouchDevice) return { flexWrap: "wrap" }
      //   return {}
      // })(),
      // overflow: "auto",
      // scrollbarWidth: "none",
      // "&::-webkit-scrollbar": {
      //   display: "none",
      // },
    },
    clearFieldButton: { textAlign: "left" },
    titleLine: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "100%",
    },
  }
})
