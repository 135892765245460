import React, { ReactChild } from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"

export default function CenteredOnContainer(props: {
  children: ReactChild
  className?: string
}) {
  const c = useStyles({})
  return (
    <div className={clsx(c.root, "centered-on-container", props.className)}>
      {props.children}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
  }
})
