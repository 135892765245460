import { IdGetterObj, InMemoryCache } from "@apollo/client"
import { Typename } from "../../types/type"
import introspectionQueryResultData from "../fragmentTypes/possibleTypes.json"
import {
  getCacheID,
  getPageBoardItemIDWithoutPrefix,
} from "../getCacheID/getCacheID"
import { PageBoardItemQueryVariables } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import _ from "lodash"

export default new InMemoryCache({
  possibleTypes: introspectionQueryResultData,
  dataIdFromObject(object: IdGetterObj) {
    return getCacheID(object)
  },

  typePolicies: {
    Query: {
      fields: {
        workspace: {
          read(_, { args, toReference }) {
            // TODO: add type to args. WorkspaceID might change and it will break this.
            const id = toReference({
              __typename: args?.workspaceID?.includes("guest")
                ? Typename.GuestWorkspace
                : Typename.Workspace,
              id: args?.workspaceID,
            })

            return id
          },
        },
        pageBoardItem: {
          read(_, { args, toReference }) {
            if (!args) return null

            const a = args as PageBoardItemQueryVariables

            const typename = convertPageBoardItemTypeToTypename(a.type)

            const id = getPageBoardItemIDWithoutPrefix({
              type: a.type,
              itemID: a.itemID,
              __typename: typename as any,
            })

            return toReference({
              __typename: typename,
              id: id,
            })
          },
        },
        collectionItem: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: Typename.CollectionItem,
              id: args?.collectionID,
            })
          },
        },
        calendarEvent: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: Typename.CalendarEvent,
              id: args?.eventID,
            })
          },
        },
        room: {
          read(_, { args, toReference }) {
            return toReference({ __typename: Typename.Room, id: args?.roomID })
          },
        },
      },
    },
  },
  // cacheRedirects: {
  //   Query: {
  //     workspace: (_, args = {}, { getCacheKey }) => {
  //       const id = getCacheKey({
  //         // __typename: "Workspace",
  //         __typename: Typename.Workspace,
  //         id: args.workspaceID,
  //       })

  //       return id
  //     },

  //     pageBoardItem(
  //       _,
  //       args: { itemID: string; type: PageBoardItemType },
  //       { getCacheKey }
  //     ) {
  //       const id = cachedIDGetters[Typename.NewPageBoardItem](args)

  //       const key = getCacheKey({
  //         // __typename: "CollectionItem",
  //         __typename: Typename.NewPageBoardItem,
  //         id: id,
  //       })

  //       // console.log(key)

  //       // return key
  //       return id
  //     },

  //     collectionItem(_, args = { collectionID: "" }, { getCacheKey }) {
  //       return getCacheKey({
  //         // __typename: "CollectionItem",
  //         __typename: Typename.CollectionItem,
  //         id: args.collectionID,
  //       })
  //     },

  //     calendarEvent(_, args: CalendarEventQueryVariables, { getCacheKey }) {
  //       const id = getCacheKey({
  //         __typename: Typename.CalendarEvent,
  //         id: args.eventID,
  //       })

  //       return id
  //     },
  //     room(_, args: RoomQueryVariables, { getCacheKey }) {
  //       const id = getCacheKey({
  //         __typename: Typename.Room,
  //         id: args.roomID,
  //       })

  //       return id
  //     },
  //   },
  // },
})

type CamelCase<S extends string> = S extends `${infer P}_${infer Q}${infer R}`
  ? `${Lowercase<P>}${Uppercase<Q>}${CamelCase<R>}`
  : Lowercase<S>

type PageBoardItemTypeTypenameMap = {
  [K in PageBoardItemType]: `${Capitalize<CamelCase<string & K>>}PageBoardItem`
}

function snakeToCamel(s: string): string {
  return s.toLowerCase().replace(/(_\w)/g, (m) => m[1].toUpperCase())
}

function capitalizeFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const pageBoardItemTypes = Object.fromEntries(
  Object.values(PageBoardItemType).map((value) => [
    value,
    capitalizeFirstLetter(`${snakeToCamel(value)}PageBoardItem`),
  ])
) as PageBoardItemTypeTypenameMap

function convertPageBoardItemTypeToTypename<TType extends PageBoardItemType>(
  type: TType
) {
  return pageBoardItemTypes[type] || null
}
