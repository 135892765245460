import { makeStyles } from "@material-ui/core"
import React from "react"
import clsx from "clsx"

export default function Actions(props: {
  leftActions?: React.ReactNode
  classes?: { stackedActions?: string }
  rightActions?: React.ReactNode
  isStack?: boolean
  className?: string
}) {
  const c = useStyles({})

  return (
    <div
      className={clsx(
        "actions",
        c.root,
        props.isStack && c.stack,
        props.isStack && props.classes?.stackedActions,
        props.className
      )}
    >
      <div className={clsx("side", "leftActions")}>
        {props.leftActions || null}
      </div>
      <div className={clsx(c.rightActions, "side")}>
        {props.rightActions || null}
      </div>
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    stack: {
      display: "block !important",
      "& .side>*": {
        width: "100%",
        textAlign: "center",
        margin: "0 !important",
        marginBottom: `${theme.spacing(0.5)}px !important`,
      },
    },
    root: {
      marginTop: theme.spacing(6),
      display: "flex",
      justifyContent: "space-between",
    },

    rightActions: {
      "&>*:not(:first-child)": {
        marginLeft: theme.spacing(0.5),
      },
    },
  }
})
