import React from "react"
import clsx from "clsx"
import { KanbanActivityFragment } from "lib/graphql/operations"
import { KanbanAction } from "lib/graphql/types"
import RemoveImageActivity from "./components/RemoveImageActivity/RemoveImageActivity"
import AddCardActivity from "./components/AddCardActivity/AddCardActivity"
import AddImageActivity from "./components/AddImageActivity/AddImageActivity"
import AddCommentActivity, {
  MoveCardBetweenColumnsActivity,
} from "./components/AddCommentActivity/AddCommentActivity"

const Component = {
  [KanbanAction.AddCard]: AddCardActivity,
  [KanbanAction.AddImage]: AddImageActivity,
  [KanbanAction.RemoveImage]: RemoveImageActivity,
  [KanbanAction.AddComment]: AddCommentActivity,
  [KanbanAction.MoveCardBetweenColumns]: MoveCardBetweenColumnsActivity,
}

export default function ActivityEntry<T extends KanbanActivityFragment>(props: {
  activity: T
  style?: React.CSSProperties
  className?: string
}) {
  const C = Component[props.activity.action as keyof typeof Component]

  if (!C) return null

  return (
    <div
      style={props.style}
      className={clsx("activity-entry", props.className)}
    >
      <C
        activity={props.activity as React.ComponentProps<typeof C>["activity"]}
      ></C>
    </div>
  )
}
