import { useContext } from "react"
import { makeStyles } from "@material-ui/core"
import { RoomContext } from "components/Room/contexts/room"
import useModeAPI from "./useModeAPI/useModeAPI"
import useMemoAPI from "hooks/useMemoAPI"
import useUpdateRoomMembers from "hooks/useUpdateRoomMembers"

export default function useMembersProviderProps() {
  const { room, roomMembers } = useContext(RoomContext)

  const roomID = (room || {}).id || ""

  const { addMember, removeMember } = useUpdateRoomMembers({ roomID })

  const c = useStyles({})

  const modeAPI = useModeAPI()

  return useMemoAPI({
    modeAPI,
    roomID,
    classes: c,
    members: roomMembers,
    addMember,
    removeMember,
  })
}

const useStyles = makeStyles((theme) => {
  return {
    textField: {
      // margin: theme.spacing(1),
    },

    controlPanel: {
      display: "flex",

      justifyContent: "space-between",
      padding: theme.spacing(1, 2),
    },

    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    presenceList: {
      // padding: theme.spacing(1, 2),
      display: "flex",
      flexDirection: "column",
    },
    editButton: {
      width: "100%",
    },
    presenceListContainer: {
      flex: 1,
      height: "100%",
      overflow: "auto",
      minHeight: 0,
    },
  }
})
