import { IndexedItemsApiInput } from "components/PageBoard/components/IndexedItems/types"
import useMemoAPI from "hooks/useMemoAPI"
import { useWorkspaceCache } from "../../../../../hooks/useWorkspaceCache"

export type Filter = IndexedItemsApiInput["filter"]

export function useCachedFilter() {
  const a = useWorkspaceCache<Filter | null | undefined>({
    key: "pageBoardItemsFilter",
  })

  return useMemoAPI({ filter: a.value, setFilter: a.setValue })
}
