import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import useIndexedMessages from "./useIndexedMessages"

export default function useMessagesDataAPI() {
  const indexedMessages = useIndexedMessages()

  const getMessage = React.useCallback(
    (index: number) => {
      const { messagesByIndex } = indexedMessages
      return messagesByIndex?.[index]
    },
    [indexedMessages]
  )

  const isDeleted = React.useCallback(
    (index: number) => {
      const message = indexedMessages.messagesByIndex?.[index]

      const deleted = !!message?.deletedAt

      return deleted
    },
    [indexedMessages]
  )

  const getIndexOfPreviousNotDeleted = React.useCallback(
    (index: number) => {
      const listItem = indexedMessages.previousItemNotDeleted?.[index]
      const indexOfNotDeleted = listItem?.index || null
      return indexOfNotDeleted
    },
    [indexedMessages]
  )

  const getIndexOfNextNotDeleted = React.useCallback(
    (index: number) => {
      const listItem = indexedMessages.nextItemNotDeleted?.[index]
      const indexOfNotDeleted = listItem?.index || null
      return indexOfNotDeleted
    },
    [indexedMessages]
  )

  const getNextMessage = React.useCallback(
    (index: number) => {
      const { nextItemNotDeleted } = indexedMessages
      return nextItemNotDeleted?.[index]?.message || null
    },
    [indexedMessages]
  )

  const getPreviousMessage = React.useCallback(
    (index: number) => {
      const { previousItemNotDeleted } = indexedMessages
      return previousItemNotDeleted?.[index]?.message || null
    },
    [indexedMessages]
  )

  const value = useMemoAPI({
    getMessage,
    getNextMessage,
    isDeleted,
    getPreviousMessage,
    getIndexOfNextNotDeleted,
    getIndexOfPreviousNotDeleted,
  })

  return value
}
