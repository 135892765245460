import clsx from "clsx"
import { IndexedItems } from "components/PageBoard/components/IndexedItems/IndexedItems"
import { Shop } from "components/Shop/Shop"
import { excludeNullable } from "lib/excludeNullable"
import {
  ShopArticleFragment,
  ShopArticlePageBoardItemFragment,
} from "lib/graphql/operations"
import _ from "lodash"
import React from "react"
import { StyledProps } from "types/type"

export type ShoppingArticlesGridProps = StyledProps & {
  renderArticleButtons?: (props: {
    shopArticleItem: ShopArticlePageBoardItemFragment
    cartButtons: React.ReactNode
  }) => React.ReactNode[]
  onClickItem?: (a: ShopArticlePageBoardItemFragment) => void
}

export function ShoppingArticlesGrid(props: ShoppingArticlesGridProps) {
  const board = React.useContext(IndexedItems.Context)

  const items = board.pageBoardItems
  const fetchMore = board.fetchMore

  const validItems = (items || [])
    .filter(
      (a): a is ShopArticlePageBoardItemFragment =>
        a?.__typename === "ShopArticlePageBoardItem"
    )
    .filter(excludeNullable)

  const articles = validItems.map((a) => a.article)

  const itemsByArticleID: Record<
    ShopArticleFragment["id"],
    ShopArticlePageBoardItemFragment
  > = _.keyBy(validItems, (a) => a.article?.id || "")

  return (
    <Shop.ArticlesGrid
      key={board.throttledSearch}
      fetchMore={fetchMore}
      style={props.style}
      className={clsx("articles-grid", props.className)}
      ArticleCardProps={({ article, index }) => {
        const item = itemsByArticleID[article.id || ""]

        return {
          onClick: () => props?.onClickItem?.(item),
          renderActions: (p) => {
            return (
              props.renderArticleButtons?.({
                shopArticleItem: item,
                cartButtons: p.cartButtons,
              }) || p.cartButtons
            )
          },
        }
      }}
      articles={articles}
    ></Shop.ArticlesGrid>
  )
}
