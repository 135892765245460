import { useMemo } from "react"

export default function useMemoAPI<
  API extends { [key: string]: any } | undefined | null
>(api: API): API {
  return useMemo(
    () => api,
    (() => {
      if (!api) return []
      return Object.values(api)
    })()
  )
}
