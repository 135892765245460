import React, { useContext } from "react"
import AdapterLink from "components/AdapterLink/AdapterLink"
import { Button, Icon } from "@material-ui/core"
import { useUtilsContext } from "pages/Partners/contexts/libContext"
import { useI18n } from "contexts/i18nContext/i18nContext"
import clsx from "clsx"
import PartnerContext from "components/Partner/contexts/PartnerContext"

export default function DocumentsButton({ amountOfDocs, className }) {
  // const { getDocumentsTabLink } = useUtilsContext()
  const { onClickDocumentsButton } = useContext(PartnerContext)
  const t = useI18n()

  const description = (() => {
    const first =
      amountOfDocs === 1 ? "" : amountOfDocs > 99 ? "+99" : amountOfDocs
    const second = amountOfDocs === 1 ? t["one-document"] : t.documents

    return [first, second].filter(Boolean).join(" ")
  })()

  return (
    <Button
      className={clsx("documents-button", className)}
      size="small"
      variant="contained"
      component={AdapterLink}
      color="primary"
      // to={getDocumentsTabLink()}
      onClick={onClickDocumentsButton}
      startIcon={<Icon>insert_drive_file</Icon>}
    >
      {description}
    </Button>
  )
}
