//

// import { InMemoryCache,  } from 'apollo-cache-inmemory';
import { PersistedData, PersistentStorage } from "apollo-cache-persist/types"

import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";

import { persistCache } from "apollo-cache-persist"

import cache from "./cache"
import link, { LinkProps } from "./link"
import sessionID from "./sessionID"

export default function setupApollo(props: LinkProps) {
  persistCache({
    cache,
    storage: window.localStorage as PersistentStorage<
      PersistedData<NormalizedCacheObject>
    >,
  })

  const client = new ApolloClient({
    link: link(props),
    cache,
    resolvers: {
      MessageListItem: {
        sessionID() {
          return sessionID
        },
      },
      KanbanComment: {
        isDeleted() {
          return false
        },
      },
      Room: {
        usersTyping() {
          return []
        },
      },
      TextMessage: {
        loading() {
          return false
        },
        deleted() {
          return false
        },
      },
    },
    typeDefs: gql`
      extend type Message {
        loading: Boolean
        deleted: Boolean
      }

      extend type Room {
        usersTyping: [User!]!
      }
    `,
  })

  // client.onResetStore(() => {
  //   cache.writeData({ data: {} })
  // })

  return client
}
