import { Stream, VideoClient } from "@zoom/videosdk"
import useMemoAPI from "hooks/useMemoAPI"
// import { useOnLeave } from "../ConferenceContext"
import { useAudio } from "./useAudio"
import { useParticipants } from "./useParticipants"
import { useVideo } from "./useVideo"
import { useOnLeave } from "./useOnLeave"

export type ConferenceApiInput = {
  zoomClient: typeof VideoClient
  mediaStream: typeof Stream | null
  manageLeaving?: (callback: () => void) => void
  sessionID: string | null
}

export function useConference(props: ConferenceApiInput) {
  const video = useVideo({
    zoomClient: props.zoomClient,
    mediaStream: props.mediaStream,
  })

  const audio = useAudio({
    zoomClient: props.zoomClient,
    mediaStream: props.mediaStream,
  })

  const onLeave = useOnLeave(props)

  const participantsApi = useParticipants({
    zoomClient: props.zoomClient,
    mediaStream: props.mediaStream,
    sessionID: props.sessionID,
  })

  return useMemoAPI({
    ...props,
    leave: onLeave,
    video,
    audio,
    participants: participantsApi,
  })
}
