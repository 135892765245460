import KeyListeners from "components/KeyListeners/KeyListeners"
import React from "react"
import { UploadContext, useUploadContext } from "../context/UploadContext"
import useStepsController from "./UploadSteps/hooks/useStepsController/useStepsController"
import { steps } from "./UploadSteps/steps"

export function useUploadDocumentsApi(props?: {
  onClose?: () => void

  //bring stuff here from UploadDocuments.tsx
  uploadFiles?: ({ files }: { files: any }) => void
  fileTypesAvailable?: { value: string; locale: string }[]
  defaultUploadType?: string
  amountUploaded?: number
  loading?: boolean
}) {
  const [selectedFileID, setSelectedFileID] = React.useState<string | null>(
    null
  )
  const controller = useStepsController({ steps, onClose: props?.onClose })

  KeyListeners.useEscapeListener(controller.goBackward, {
    enabled: controller.activeStepIndex > 0,
  })

  const filesApi = useUploadContext()

  const api = React.useContext(UploadContext)

  return {
    ...props,
    ...api,
    filesApi,
    stepsController: controller,
    selectedFileID,
    setSelectedFileID,
  }
}
