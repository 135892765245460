import { TwoPagesAPI } from "components/TwoPages/TwoPages"
import { PageBoardItemType } from "lib/graphql/types"
import { OpenKanbanCardPayload } from "types/type"
import { PageBoardItem } from "../components/IndexedItems/components/PageBoardItem/PageBoardItem"

export const getKanbanCardView = (payload: OpenKanbanCardPayload) =>
  ({
    id: "kanban-card",
    Page: function KanbanCard(props: { onClose: () => any }) {
      return (
        <PageBoardItem.SecondPage
          onClose={props.onClose}
          getItemParams={{
            fetchItemProps: {
              type: PageBoardItemType.KanbanCard,
              params: {
                cardID: payload.cardID,
              },
            },
          }}
        />
      )
    },
  } as const)

export default function openKanbanCard(
  payload: OpenKanbanCardPayload,
  options: { twoPagesAPI: TwoPagesAPI }
) {
  return options.twoPagesAPI.secondPage.openPage(getKanbanCardView(payload))
}
