import { makeStyles } from "@material-ui/core"
import { MuiThemeProvider } from "@material-ui/core/styles"
import clsx from "clsx"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import { ContentState, convertToRaw, EditorState } from "draft-js"
import useOnChange from "hooks/useOnChange"
import _ from "lodash"
import MUIRichTextEditor, { TMUIRichTextEditorRef } from "mui-rte"
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react"
import theme from "theme"
import { StyledProps } from "types/type"
import useResizeObserver from "use-resize-observer"
import arePropsEqual from "util/arePropsEqual"
import contentStateFromString from "util/draftJS/contentStateFromString"

function RichTextInput(
  props: {
    placeholder: string
    onChange: (v: { richText: string; isEmpty: boolean }) => void
    onBlur: () => void
    onFocus: () => void
    controls: any
    draftEditorProps: any
    defaultValue: string
    customControls: any
  } & StyledProps,
  ref: React.Ref<any>
) {
  const { isSmallMobile, isDesktop, isMobile } = React.useContext(
    DeviceObserver.Context
  )

  const { rootRef, iconSize } = useIconSize()

  const c = useStyles({ iconSize })

  const [defaultValue, setDefaultValue] = React.useState<string | null>(
    props.defaultValue || randomEmptyValue()
  )

  useEffect(() => {
    const initialValue = props.defaultValue

    if (!initialValue || (initialValue && typeof initialValue !== "string"))
      return setDefaultValue(null)

    const contentState =
      contentStateFromString(initialValue) ||
      ContentState.createFromText(initialValue || "")

    const rawContentState = convertToRaw(contentState)

    setDefaultValue(JSON.stringify(rawContentState))
  }, [props.defaultValue])

  const richTextRef = React.useRef<TMUIRichTextEditorRef>(null)

  const reset = useCallback(() => {
    props.onChange && props.onChange({ richText: "", isEmpty: true })
    // const newState = EditorState.createEmpty()
    // const emptyState = convertToRaw(newState.getCurrentContent())
    setDefaultValue(
      JSON.stringify(convertToRaw(ContentState.createFromText("")))
    )
  }, [props.onChange])

  useOnChange({
    value: defaultValue,
    onChange() {
      if (!defaultValue) return
      // debugger
      richTextRef.current && richTextRef.current.focus()
    },
  })

  useEffect(() => {
    isDesktop && richTextRef.current && richTextRef.current.focus()
  }, [isDesktop])

  useImperativeHandle(
    ref,
    () => ({
      reset,
      focus: () => {
        richTextRef?.current && richTextRef.current.focus()
      },
    }),
    []
  )

  const [editorState, setEditorState] = React.useState<EditorState | null>(null)

  useOnChangeEditorState({ editorState, onChange: props.onChange })
  // const Editor = MUIRichTextEditor as unknown as React.Component<typeof MUIRichTextEditor>

  const editor = (
    //@ts-ignore
    <MUIRichTextEditor
      onChange={setEditorState}
      toolbarButtonSize={iconSize}
      ref={richTextRef}
      //defaultValue should be a controlled value because to change it is necessary to reset.
      defaultValue={defaultValue}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      label={props.placeholder}
      controls={props.controls}
      customControls={props.customControls}
      draftEditorProps={props.draftEditorProps}
    />
  )

  return (
    <div
      className={clsx(c.richTextInput, props.className)}
      style={props.style}
      ref={rootRef as React.LegacyRef<HTMLDivElement>}
    >
      <MuiThemeProvider theme={muiRTETheme}>{editor}</MuiThemeProvider>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    richTextInput: {
      minHeight: theme.spacing(13),
      position: "relative",
      "& #mui-rte-toolbar": {
        paddingLeft: ({ iconSize }: { iconSize: "small" | "medium" }) =>
          theme.spacing(iconSize === "small" ? 1 : 0),
      },
      paddingBottom: theme.spacing(1),
    },
  }
})

export const randomEmptyValue = () =>
  JSON.stringify(convertToRaw(ContentState.createFromText("")))

export default memo(
  forwardRef(RichTextInput),
  arePropsEqual([
    "placeholder",
    "draftEditorProps",
    "defaultValue",
    "draftEditorProps",
    "controls",
    "customControls",
    "onBlur",
    "onChange",
    "onFocus",
  ])
)

const muiRTETheme = Object.assign(theme, {
  /** following instructions on https://github.com/niuware/mui-rte#styling-the-editor*/
  overrides: {
    MUIRichTextEditor: {
      root: {},
      placeHolder: {
        padding: theme.spacing(0, 2),
      },
      editor: {
        padding: theme.spacing(0, 2),
      },
    },
  },
})

function useOnChangeEditorState({
  editorState,
  onChange,
}: {
  editorState: any
  onChange: any
}) {
  const { isMobile } = React.useContext(DeviceObserver.Context)

  useEffect(() => {
    if (!onChange || !editorState) return

    onChange(
      (() => {
        if (isMobile) {
          //draft-js doesn't have full support to mobile, so we need the following workaround
          const node = document.querySelector("#mui-rte-editor") as HTMLElement
          const innerText = node ? node.innerText.trim() : ""

          // console.log({ innerText })

          return {
            isEmpty: !innerText,
            richText: innerText.split("\n").filter(Boolean).join("\n"),
          }
        }

        const newValue = convertToRaw(editorState.getCurrentContent())
        // console.log({ newValue })

        return {
          richText: JSON.stringify(newValue),
          isEmpty: _.every(_.get(newValue, "blocks"), (v) => _.isEmpty(v.text)),
        }
      })()
    )
  }, [editorState])
}

function useIconSize() {
  const { ref: rootRef, width } = useResizeObserver()

  const api = {
    iconSize: ((width || 0) < 900 ? "small" : "medium") as "small" | "medium",
    rootRef,
  }

  return useMemo(() => {
    return api
  }, [..._.values(api)])
}
