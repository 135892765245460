import * as Sentry from "@sentry/react"
import { LoggedUser } from "components/LoggedUser/LoggedUser"
import { enableSentry } from "lib/config"
import isProduction from "lib/isProduction"
import _ from "lodash"
import React, { useEffect } from "react"
import getter from "util/getter"
import App from "./App"

function AuthenticationContainer() {
  const loggedUser = React.useContext(LoggedUser.Context)?.loggedUser

  useEffect(() => {
    if (!loggedUser) {
      return
    }

    const u = getter(loggedUser)

    enableSentry &&
      Sentry.configureScope((scope) => {
        scope.setUser({ id: u("id"), email: u("email"), name: u("name") })
      })
  }, [_.get(loggedUser, "id")])

  if (enableSentry)
    return (
      <Sentry.ErrorBoundary
        showDialog={true}
        beforeCapture={(scope) => {
          scope.setTag("user.id", _.get(loggedUser, "id"))
          scope.setTag("user.name", _.get(loggedUser, "name"))
        }}
      >
        <App></App>
      </Sentry.ErrorBoundary>
    )

  return <App></App>
}

export default isProduction
  ? Sentry.withProfiler(AuthenticationContainer)
  : AuthenticationContainer
