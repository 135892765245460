// import { StyledProps } from "@material-ui/core"
import "react-image-crop/src/ReactCrop.scss"

import { ImageEditorRoot } from "./ImageEditorRoot"
import { ImageEditorProvider } from "./ImageEditorProvider"

export const ImageEditor = {
  Provider: ImageEditorProvider,
  Root: ImageEditorRoot,
}
