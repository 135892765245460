import React from "react"
import { Freezer } from "components/Freezer/Freezer"
import { FormCommonsContext } from "./FormCommonsContext"

export function FormFreezer(props: { children: React.ReactNode }) {
  const ctx = React.useContext(FormCommonsContext)
  const freezed = ctx.deletion?.isDeleted || !!ctx.readOnly

  return <Freezer freezed={!!freezed}>{props.children}</Freezer>
}
