import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { useCommentSelectionUI } from "./useCommentSelectionUI"
import { KanbanCommentInput, KanbanEditCommentInput } from "lib/graphql/types"

export default function useEdition(props: {
  edit: (input: KanbanEditCommentInput) => any
}) {
  const commentModalAPI = useCommentSelectionUI()

  const commentID = commentModalAPI.selectedComment?.id
  const edit = React.useCallback(
    (text: string) => {
      if (!commentID || !text) return

      return props.edit({ commentID, text })
    },
    [props.edit, commentID]
  )

  return useMemoAPI({
    // ui.close: commentModalAPI.close,
    // ui.open: commentModalAPI.open,
    // ui.isOpen: commentModalAPI.isOpen,
    // isOpen: true,
    ui: commentModalAPI,

    edit,
  })
}
