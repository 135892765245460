import useDocumentsGridProviderValue from "components/DocumentsGrid/hooks/useDocumentsGridProviderValue/useDocumentsGridProviderValue"
import { SideSheetDocumentProvider } from "contexts/sideSheetDocument/sideSheetDocument"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import DocumentsGridContext from "../../context/DocumentsGridContext"

export default function DocumentsGridProviderValue({
  relatedCollectionsIDs,
  collectionID,
  onChangeDocumentsLoadingState = () => undefined,
  onChangeRelatedCollectionsIDs: propsOnChangeRelatedCollectionsIDs = () =>
    undefined,
  children,
}) {
  const providerValue = useDocumentsGridProviderValue({
    relatedCollectionsIDs,
    collectionID,
    onChangeDocumentsLoadingState,
    onChangeRelatedCollectionsIDs: propsOnChangeRelatedCollectionsIDs,
  })

  return (
    <DocumentsGridContext.Provider value={providerValue}>
      <SideSheetDocumentProvider>{children}</SideSheetDocumentProvider>
    </DocumentsGridContext.Provider>
  )
}

const sort = (as) => {
  if (!as) return as

  return [...(as || [])].sort((a, b) => {
    if (b > a) return 1
    return -1
  })
}

function useRelatedCollectionsIDs({ defaultValue, onChange }) {
  const [relatedCollectionsIDs, setRelatedCollectionsIDs] = useState(
    defaultValue ? sort(defaultValue) : undefined
  )

  const getString = (ids) => JSON.stringify(sort(ids))

  useEffect(() => {
    if (getString(defaultValue) === getString(relatedCollectionsIDs)) {
      return
    }

    setRelatedCollectionsIDs(sort(defaultValue))
  }, [getString(defaultValue)])

  useEffect(() => {
    if (typeof onChange !== "function") return
    onChange(relatedCollectionsIDs)
  }, [getString(relatedCollectionsIDs)])

  const api = useMemo(() => {
    return {
      relatedCollectionsIDs,
      onChangeRelatedCollectionsIDs: (ids) =>
        setRelatedCollectionsIDs(sort(ids)),
    }
  }, [getString(relatedCollectionsIDs)])

  return api
}
