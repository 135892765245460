import {
  IsLoggedDocument,
  LogoutDocument,
  LogoutMutation,
} from "lib/graphql/operations"
import { ApolloClient } from "@apollo/client";

export default function logout(client: ApolloClient<{}>) {
  return client.mutate<LogoutMutation>({
    mutation: LogoutDocument,
    refetchQueries: [{ query: IsLoggedDocument }],
  })
}
