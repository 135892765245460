import { ChipProps, Typography } from "@material-ui/core"
import clsx from "clsx"
import { Icon } from "components/Icon/Icon"
import { InlinedChips } from "components/InlinedChips/InlinedChips"
import { FieldsetTitle } from "components/Page/components/PageForm/FieldsetTitle"
import PageBoardContext from "components/PageBoard/contexts/PageBoardContext"
import { getIcon } from "components/PageBoard/hooks/useTypesOptions"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import React from "react"
import { StyledProps } from "types/type"

export function RecentlyUpsertedItemsSection(
  props: StyledProps & {
    filter?: (item: NewPageBoardItemFragment | null | undefined) => boolean
  }
) {
  const pageBoardAPI = React.useContext(PageBoardContext)

  const items = pageBoardAPI.newItems

  const chipsProps: ChipProps[] = React.useMemo(
    () =>
      (items || [])
        .filter((a) => {
          if (!props.filter) return true
          return props.filter(a)
        })
        .map((item) => {
          const a: ChipProps = {
            key: item.id,
            label: item.name || "",
            variant: "outlined",
            icon: (
              <Icon name={getIcon(item.type)} style={{ fontSize: 19 }}></Icon>
            ),
            onClick: () => {
              pageBoardAPI.selected.set(item)
            },
          }

          return a
        }),
    [items, pageBoardAPI?.selected.set]
  )

  const t = useI18n()

  if (!chipsProps?.length) return null

  return (
    <div
      className={clsx("recently-upserted-items-section", props.className)}
      style={props.style}
    >
      <FieldsetTitle>{t["recentlyCreated"]}</FieldsetTitle>
      <InlinedChips wrap chips={chipsProps} charactersLimit={15}></InlinedChips>
    </div>
  )
}
