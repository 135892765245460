import React from "react"
import { Typography } from "@material-ui/core"

export default function FourZeroFour() {
  return (
    <div
      className="four-zero-four"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h1">
        😢
        <span style={{ color: "rgba(0,0,0,0.6)" }}> 404</span>
      </Typography>
    </div>
  )
}
