import { PublicAppConfigDocument } from "lib/graphql/operations"
import _ from "lodash"

export default async function getPublicAppConfig(configPath, client) {
  const queryResult = await client.query({
    query: PublicAppConfigDocument,
    fetchPolicy: "cache-first",
  })

  const ok = _.get(queryResult?.data?.publicAppConfig, configPath)

  return ok
}
