import DocumentsFilter from "components/DocumentsFilter/index"
import { workStatusesColors } from "hooks/useActivityStatus/workStatuses"
import usePartner from "hooks/usePartner"
import useUpdateDocument from "hooks/useUpdateDocument/useUpdateDocument"
import DocumentsDashboard from "components/DocumentsDashboard"
import DocumentsGrid from "components/DocumentsGrid/DocumentsGrid"
import useDocument from "hooks/useDocument/useDocument"
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactChild,
} from "react"
import CollectionDashboardRoot from "./components/CollectionDashboardRoot/CollectionDashboardRoot"
import CollectionDashboardContext from "./contexts/CollectionDashboardContext"
import documentsViewsEnums from "./constants/documentsViewsEnums"
import CollectionDashboardDetailsTabsRoot from "./components/CollectionDashboardDetailsTabsRoot/CollectionDashboardDetailsTabsRoot"
import useGetPartner from "hooks/useGetPartner"
import DocumentsEntryListItem from "./components/DocumentsEntryListItem/DocumentsEntryListItem"
import DocumentsEntryStatus from "./components/DocumentsEntryStatus/DocumentsEntryStatus"
import { ObjectWithID } from "types/type"

const CollectionDocumentsDashboard = {
  documentsViewsEnums,
  Context: CollectionDashboardContext,
  Root: CollectionDashboardRoot,
  Provider: function CollectionDashboardProvider(props: {
    relatedCollectionsIDs?: Array<string> | null
    onChangeRelatedCollectionsIDs?(ids: Array<string> | null): any
    collectionID: string
    activeDocumentID?: string | null
    children: React.ReactChild
    onChangeActiveDocumentID?(id: string): any
    renderDetailsOnOverlay?: boolean
    detailsOverlayAnchor?: Element | null
  }) {
    const filterProviderValue = DocumentsFilter.useProviderValue({
      relatedCollectionsIDs: props.relatedCollectionsIDs,
      onChangeRelatedCollectionsIDs: props.onChangeRelatedCollectionsIDs,
      mainCollectionID: props.collectionID,
    })

    const documentsGridProviderValue = DocumentsGrid.useProviderValue({
      relatedCollectionsIDs: filterProviderValue?.selectedRelatedCollectionsIDs,
      onChangeRelatedCollectionsIDs: props.onChangeRelatedCollectionsIDs,
      collectionID: props.collectionID,
      activeDocumentID: props.activeDocumentID,
    })

    // const getEntryDocument = useGetEntryDocument()

    const [activeDocumentID, setActiveDocumentID] = useState<string | null>(
      props.activeDocumentID || null
    )

    // const {document} = useDocument({documentID: activeDocumentID})

    const { document: activeDocument } = useDocument({
      documentID: activeDocumentID || undefined,
      fetchPolicy: "cache-and-network",
    })

    const { partner: activeEntryPartner } = usePartner({
      type: activeDocument?.partnerCollection?.type,
      collectionID: activeDocument?.partnerCollection?.id,
    })

    const getEntryColor = useCallback(function getDocumentColor(document) {
      return workStatusesColors[document?.workStatus]
    }, [])

    const documentsDashboardProviderValue = DocumentsDashboard.useProviderValue(
      {
        selectedEntryID: props.activeDocumentID || null,
        detailsOverlayAnchor: props.detailsOverlayAnchor,
        fetchMore: documentsGridProviderValue?.paginatedDocuments?.fetchMore,
        selectedEntryDocument: activeDocument,
        selectedEntryPartner: activeEntryPartner,
        detailedSelectedEntry: activeDocument,
        onChangeActiveID: setActiveDocumentID,
        getEntryColor,
        EntryListItem: DocumentsEntryListItem,
        EntryStatus: DocumentsEntryStatus,
        renderRightOnOverlay: props.renderDetailsOnOverlay,
        entriesAreLoaded:
          documentsGridProviderValue?.paginatedDocuments?.dataIsLoaded,
        entries: documentsGridProviderValue?.paginatedDocuments?.queryData?.list
          ?.map((a) => a.document || { id: "" })
          .filter((a) => !!a.id),
        detailsTabsRoot: (
          <CollectionDashboardDetailsTabsRoot></CollectionDashboardDetailsTabsRoot>
        ),
      }
    )

    useEffect(() => {
      if (typeof props.onChangeActiveDocumentID !== "function") return
      props.onChangeActiveDocumentID(
        documentsDashboardProviderValue.selectedEntryID
      )
    }, [documentsDashboardProviderValue.selectedEntryID])

    const [view, setView] = useState(documentsViewsEnums.LIST)

    const api = {
      DocumentsDashboard,
      DocumentsGrid,
      DocumentsFilter,
      documentView: view,
      setDocumentView: setView,
    }

    const collectionDashboardProviderValue = useMemo(() => {
      return api
    }, Object.values(api))

    return (
      <DocumentsFilter.Context.Provider value={filterProviderValue}>
        <DocumentsGrid.Context.Provider value={documentsGridProviderValue}>
          <DocumentsDashboard.Context.Provider
            value={documentsDashboardProviderValue}
          >
            <CollectionDashboardContext.Provider
              value={collectionDashboardProviderValue}
            >
              {props.children}
            </CollectionDashboardContext.Provider>
          </DocumentsDashboard.Context.Provider>
        </DocumentsGrid.Context.Provider>
      </DocumentsFilter.Context.Provider>
    )
  },
}

export default CollectionDocumentsDashboard

function useGetEntryPartner() {
  const getPartner = useGetPartner()

  return useCallback(
    ({ detailedEntry: document }) => {
      // return getPartner()
    },
    [getPartner]
  )
}

function useGetEntryDocument() {
  return useCallback(({ detailedEntry: document }) => {
    return document
  }, [])
}
