import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import ClippedText from "components/ClippedText/ClippedText"
import useTypesOptions from "components/PageBoard/hooks/useTypesOptions"
import PaperList from "components/PaperList"
import UserAvatar from "components/UserAvatar/UserAvatar"
import { NewPageBoardItem_UserPageBoardItem_Fragment } from "lib/graphql/operations"
import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import arePropsEqual from "util/arePropsEqual"
import { ListEntryProps } from "../../types"
import EntryRight from "../EntryRight/EntryRight"
import ListItem from "../ListItem/ListItem"
import { useGoToPrivateRoom } from "./useGoToPrivateRoom"
import { ItemsListContext } from "components/PageBoard/components/IndexedItems/components/ItemsList/context/ItemsListContext"
import { ItemGridCell, ItemGridCellBody } from "../ItemGridCell/ItemGridCell"
import { IconProps } from "components/Icon/Icon"
import { userTypesColor } from "lib/constants"
import { titleGettersMap } from "components/PageBoard/components/IndexedItems/components/HistoryNavigation/HistoryNavigation"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function UserListItem(props: ListEntryProps) {
  if (props.entry?.__typename !== "UserPageBoardItem") return null

  return (
    <MemoizedItem
      entry={props.entry}
      className={props.className}
      style={props.style}
    />
  )
}

const MemoizedItem = React.memo(
  Item,
  arePropsEqual([
    (a) => a.entry?.id,
    (a) => a.entry?.user?.name,
    (a) => a.entry?.user?.status,
    (a) => a.entry?.user?.company,
  ])
)

function Item(props: {
  entry: NewPageBoardItem_UserPageBoardItem_Fragment
  className?: string
  style?: React.CSSProperties
}) {
  const c = useStyles()

  // const { RootContainer } = React.useContext(PaperList.Context)
  const api = React.useContext(ItemsListContext)

  const rootContainer = api.rootContainer

  const maxSize = rootContainer.width < 700 ? 150 : 500
  const t = useI18n()

  const { goToRoom } = useGoToPrivateRoom({
    userID: props.entry?.user?.id || "",
  })

  const options = useTypesOptions()
  if (!props.entry.user?.name) return null
  // if (!props.entry) return null

  // const iconButtons = React.useMemo<Exclude<>>(() => {}, [])
  const button = {
    icon: options?.[PageBoardItemType.Room]?.icon as IconProps["name"],
    onClick: () => goToRoom(),
  }

  const title =
    titleGettersMap({ translations: t })[PageBoardItemType.User]?.(
      props.entry
    ) || ""

  if (api.variant === "grid") {
    return (
      <ItemGridCell
        buttons={[button]}
        entry={props.entry}
        title={title}
        timestamp={props.entry.lastUpdate}
        descriptionText={props.entry.user.account?.name || ""}
        // AppCardProps={{
        //   body: (
        //     <ItemGridCellBody>
        //       {/* {(a) => {
        //         return (
        //           <>
        //             <div>
        //               {a.title}
        //               {a.description}
        //             </div>
        //             {a.bottom}
        //           </>
        //         )
        //       }} */}
        //     </ItemGridCellBody>
        //   ),
        // }}
        // typeIconBadgeProps={{
        //   style: {
        //     backgroundColor:
        //       userTypesColor[
        //         (props.entry.user?.type ||
        //           ("" as any)) as keyof typeof userTypesColor
        //       ] || "",
        //   },
        // }}
        typeIconBackgroundColor={
          userTypesColor[
            (props.entry.user?.type ||
              ("" as any)) as keyof typeof userTypesColor
          ] as string
        }
      />
    )
  }

  return (
    <ListItem
      className={clsx(c.root, "indexed-item", props.className)}
      style={props.style}
      entry={props.entry}
      right={
        <EntryRight
          iconButton={button}
          lastUpdate={props.entry.lastUpdate}
        ></EntryRight>
      }
      left={
        <React.Fragment>
          <UserAvatar user={props.entry.user} />
          <div style={{ marginLeft: 8 }}></div>
          <PaperList.ListItemText
            primary={(() => {
              return (
                <Typography variant="body1" color="textPrimary">
                  <ClippedText maxWidth={maxSize}>{title}</ClippedText>
                </Typography>
              )
            })()}
            secondary={(() => {
              if (!props.entry.user?.account?.name) return null

              return (
                <Typography color="textSecondary" variant="body2">
                  <ClippedText maxWidth={maxSize}>
                    {props.entry.user.account?.name}
                  </ClippedText>
                </Typography>
              )
            })()}
          ></PaperList.ListItemText>
        </React.Fragment>
      }
    ></ListItem>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    // iconBadge: {
    //   backgroundColor:
    // }
  }
})
