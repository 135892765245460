import _ from "lodash"
import { useI18n } from "contexts/i18nContext/i18nContext"
import { makeStyles } from "@material-ui/core"

export default function useBasicFields() {
  const t = useI18n()
  const c = useStyles()

  return [
    { name: "name1", label: "Name" },
    { name: "name2", label: t.name2 },
    { name: "no", label: t.partnerNumber },
    { name: "street", label: t.street },
    { name: "zip", label: t.zip },
    { name: "city", label: t.city },

    {
      name: "remarks",
      label: t.remarks,
      type: "rich-editor",
      toolbarButtonSize: "small",
      className: c.remarks,
    },
    // { name: "remarks", label: "Bemerkung", multiline: true },
    { name: "taxID", label: t.taxID },
    { name: "active", label: "", type: "active-switch" },
    { name: "url", label: "Web", type: "web" },
    {
      name: "ocr",
      label: "OCR",
      type: "checkbox",
      getDefaultValue: (data) => !!_.get(data, "ocr"),
    },
  ]
}

const useStyles = makeStyles(() => {
  return {
    remarks: {
      minHeight: 100,
    },
  }
})
