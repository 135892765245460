import React, { useState, useEffect } from "react"
import { Paper, makeStyles, Icon, LinearProgress } from "@material-ui/core"
import chatFilesUpload$ from "../../observers/chatFilesUpload"
import clsx from "clsx"

function ChatFileUploadProgress() {
  const c = useStyles({})
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const sub = chatFilesUpload$.subscribe(({ percentage } = {}) => {
      setProgress(percentage || 0)
    })

    return () => sub.unsubscribe()
  }, [])

  return (
    <Paper className={clsx(c.chatFileUploadProgress)}>
      <div className={c.ball}>
        <Icon>image</Icon>
      </div>
      <div className={c.linearProgress}>
        <LinearProgress
          {...(progress >= 100
            ? { variant: "indeterminate" }
            : { variant: "determinate", value: progress })}
        />
      </div>
    </Paper>
  )
}

const useStyles = makeStyles(theme => {
  return {
    chatFileUploadProgress: {
      padding: theme.spacing(2, 1),
      display: "flex",
      alignItems: "center",
      minWidth: theme.spacing(36)
    },
    hide: {
      display: "none"
    },
    linearProgress: {
      flex: 1
    },
    ball: (() => {
      const $width = theme.spacing(5)

      return {
        background: theme.palette.primary.main,
        marginRight: theme.spacing(1),
        borderRadius: "50%",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: $width,
        height: $width
      }
    })()
  }
})

export default React.memo(ChatFileUploadProgress, () => true)
