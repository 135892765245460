import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import { useAddMoreOne } from "./useAddMoreOne"
import { useCartQuantity } from "./useCartQuantity"
import { useChangeQuantityApi } from "./useChangeQuantityApi"
import { useCreateOrder } from "./useCreateOrder"
import { useLiveCartQuery } from "./useLiveCartQuery"
import { useRemoveApi } from "../useRemoveApi"

export function useCartApi(props: { cartID: string }) {
  const workspaceID = useActiveResources().workspaceID

  const cart = useLiveCartQuery({ workspaceID, cartID: props.cartID || "" })
    .data?.cart

  const { quantity, getQuantity: getItemQuantity } = useCartQuantity({ cart })

  const removeApi = useRemoveApi({ cart, workspaceID, cartID: props.cartID })

  const changeQuantityApi = useChangeQuantityApi({
    cart,
    workspaceID,
    cartID: props.cartID,
  })

  const addMoreOneApi = useAddMoreOne({
    cart,
    workspaceID,
    cartID: props.cartID,
  })

  const createOrder = useCreateOrder({ cartID: props.cartID, workspaceID })

  return useMemoAPI({
    hasItems: (cart?.items?.length || 0) > 0,
    cart,
    createOrder,
    quantity,
    changeQuantity: changeQuantityApi,
    addMoreOne: addMoreOneApi,
    getItemQuantity: getItemQuantity,
    remove: removeApi,
  })
}
