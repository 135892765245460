import KeyListeners from "components/KeyListeners/KeyListeners"
import useMemoAPI from "hooks/useMemoAPI"
import useOnChange from "hooks/useOnChange"
import usePageBoardItems from "hooks/usePageBoardItems/usePageBoardItems"
import { PageBoardItemType } from "lib/graphql/types"
import _ from "lodash"
import React from "react"
import { DateIntervalApi } from "../components/DateIntervalSelector/DateIntervalSelectorContext"
import { Filter, IndexedItemsApiInput } from "../types"
import { indexedTypes } from "./indexedTypes"
import { useAvailableTypes } from "./useAvailableTypes"
import { useOpenPageItem } from "./useOpenItemPage"
import { useSelectedAPI } from "./useSelectedAPI"
import { HistoryThreadApi } from "../../HistoryThread/HistoryThreadContext"

export function useIndexedItems(
  props: IndexedItemsApiInput & {
    dateIntervalSelectorApi: DateIntervalApi
    historyThreadApi: HistoryThreadApi
  }
) {
  const [search, setSearch] = React.useState(props?.filter?.search || "")
  const [types, setTypes] = React.useState<PageBoardItemType[]>(
    props?.filter?.types || []
  )

  const availableTypes = useAvailableTypes({ requestedTypes: indexedTypes })

  const filter = React.useMemo<Filter>(() => {
    return {
      search,
      types,
    }
  }, [search, types])

  useOnChange({
    value: filter,
    onChange: props?.onChangeFilter,
    disabled: !props?.onChangeFilter,
  })

  const selectedAPI = useSelectedAPI({
    params: props?.params,
    onChangeParams: props?.onChangeParams,
  })

  //move this to selected api
  const openItemPage = useOpenPageItem({ selectedAPI })

  const searchAPI = useMemoAPI({ value: search, set: setSearch })

  const typesAPI = useMemoAPI({
    value: types,
    set: setTypes,
    delete(type: PageBoardItemType) {
      return setTypes((types) => types.filter((t) => t !== type))
    },

    toggle(type: PageBoardItemType) {
      return setTypes((types) => {
        if (types.includes(type)) {
          return types.filter((t) => t !== type)
        }

        return _.uniq([...(types || []), type])
      })
    },
  })

  const result = (props.usePageBoardItems || usePageBoardItems)({
    search,
    period:
      // getting fresh value of period, taking into account the periodType
      props.dateIntervalSelectorApi.monthsApi.periodSelectorApi.resolvePeriod(),
    types: React.useCallback(
      (args: { throttledSearch: string }) => {
        const t = (() => {
          if (types.length) return types

          if (args.throttledSearch) return indexedTypes

          return indexedTypes.filter((a) => a !== PageBoardItemType.Message)
        })()

        return props.getFilterTypes?.(t) || t
      },
      [types.length, JSON.stringify(indexedTypes)]
    ),
  })

  KeyListeners.useEscapeListener(selectedAPI.setNull, { enabled: true })

  const throttledSearch = result?.throttledSearch
  const typesApi = typesAPI

  const api = useMemoAPI({
    ...props,
    availableTypes: props.getFilterTypes?.(availableTypes) || availableTypes,
    dateIntervalSelectorApi: props.dateIntervalSelectorApi,
    search: searchAPI,
    selected: selectedAPI,
    types: typesApi,
    listIsVisible: !selectedAPI.params,
    refetch: result?.refetch,
    fetchMore: result?.fetchNextPage,
    openItemPage,
    newItems: result?.newItems || null,
    pageBoardItems: result?.fetchingResponse?.items || null,
    pagination: result?.fetchingResponse?.pagination || null,
    throttledSearch: result?.throttledSearch,
    rootKey: [
      ...(throttledSearch ? [throttledSearch] : []),
      ...(typesApi.value || []),
    ].join("-"),
  })

  return api
}
