// import { StyledProps } from "@material-ui/core";

import {
  ButtonProps,
  IconButton as MaterialIconButton,
  IconButtonProps,
  makeStyles,
  Button,
} from "@material-ui/core"
import clsx from "clsx"
import { Icon, IconProps } from "components/Icon/Icon"
import { ImageEditorContext } from "components/ImageEditor/ImageEditorProvider"
import SelfContainedBall from "components/SelfContainedBall/SelfContainedBall"
import { purple } from "constants/colors"
import I18nContext from "contexts/i18nContext/i18nContext"
import React from "react"
import { StyledProps } from "types/type"

export function EditorControls(props: StyledProps) {
  const c = useStyles()
  const api = React.useContext(ImageEditorContext)

  return (
    <div
      className={clsx("editor-controls", c.root, props.className)}
      style={props.style}
    >
      <IconButton
        icon="arrow_back_rounded"
        onClick={() => api.onClose()}
      ></IconButton>

      {/* <SelfContainedBall
        className={clsx(api.cropApi.isActive.isTrue && c.highlightButton)}
      >
        <Button
          icon="crop"
          className={c.cropPadding}
          disabled
          // onClick={() => api.cropApi.isActive.toggle()}
        ></Button>
      </SelfContainedBall> */}

      {/* <IconButton
        icon="arrow_upward"
        color="secondary"
        onClick={() => api.submitCrop()}
      ></IconButton> */}

      <I18nContext.Consumer>
        {(t) => {
          return (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => api.submitCrop()}
            >
              {t.upload}
              {/* <Icon name="arrow_upward" style={{ marginLeft: 4 }}></Icon> */}
            </Button>
          )
        }}
      </I18nContext.Consumer>
    </div>
  )
}

function IconButton(
  props: { icon: IconProps["name"] } & StyledProps & IconButtonProps
) {
  const { icon, ...buttonProps } = props
  const c = iconUseStyles()

  return (
    <MaterialIconButton
      {...buttonProps}
      size="small"
      className={clsx(c.root, "editor-icon-button", buttonProps.className)}
    >
      <Icon name={props.icon} style={{ color: "white !important" }} />
    </MaterialIconButton>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    cropPadding: { padding: 6 },
    root: {
      background: "transparent",
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(2),
    },
    highlightButton: { background: purple[0] },
  }
})

const iconUseStyles = makeStyles((theme) => {
  return {
    root: {
      color: "white !important",
      // "& button": {
      // }
      // display: "flex",
      // justifyContent: "space-between",
      // padding: theme.spacing(2),
    },
  }
})
