import _ from "lodash"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { useHistory, useLocation } from "react-router"
import { sanitizedEval } from "../../lib/sanitizedEval"

export default function useSearchParams<
  T extends { [key: string]: string | null | number | undefined | boolean }
>(props?: { keyPrefix?: string; cleanOnUnmount?: boolean }) {
  const location = useLocation()
  const history = useHistory()

  const urlSearchParams = React.useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const getKey = React.useCallback(
    (key: keyof T) => {
      if (!props?.keyPrefix) return key as string
      return [props?.keyPrefix, key as string].join("-")
    },
    [props?.keyPrefix]
  )

  const clear = React.useCallback(() => {
    const keysWithPrefix = Array.from(urlSearchParams.keys()).filter((key) =>
      key.match(new RegExp("^" + props?.keyPrefix))
    )

    keysWithPrefix.forEach((key) => urlSearchParams.delete(key))

    history.push({ search: urlSearchParams.toString() })
  }, [])

  React.useEffect(() => {
    //important: removing params generated by this hook

    return () => {
      if (!props?.cleanOnUnmount) return
      if (!props.keyPrefix) return

      clear()
    }
  }, [])

  // type Ok  = {[i in keyof T]: number}

  const getParams = React.useCallback(
    function getParams(keys: (keyof T)[]) {
      const ok = keys.map((k, index) => {
        const key = getKey(k)
        const value = urlSearchParams.get(key) as T[typeof k]

        try {
          // return eval(value + "") as T[typeof k]
          return sanitizedEval(value) as T[typeof k]
        } catch (e) {
          return value
        }
      })

      // console.table(ok.map((v, i) => ({ key: keys[i], v })))

      return ok
    },
    [urlSearchParams]
  )

  const setParams = React.useCallback(function setParams(params: Partial<T>) {
    _.entries(params).forEach(([key, value]) => {
      if (typeof value === "undefined" || value === null)
        return urlSearchParams.delete(getKey(key))
      urlSearchParams.set(getKey(key), value + "")
    })

    history.push({ search: urlSearchParams.toString() })
  }, [])

  return useMemoAPI({
    get: getParams,
    set: setParams,
    getKey,
    urlSearchParams,
    clear,
  })
}
