import React from "react"
import {
  ButtonBase,
  Icon,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core"
import Indentation from "components/Page/components/Indentation/Indentation"
import clsx from "clsx"

export type ListItemWithAvatarProps = {
  overline?: string | null
  firstLine?: string | null
  secondLine?: string | null
  avatar?: React.ReactNode

  onClick?: () => any
  onDelete?: () => any

  IndentationProps?: Pick<
    React.ComponentProps<typeof Indentation>,
    "leftPosition"
  >
}

export default function ListItemWithAvatar(props: ListItemWithAvatarProps) {
  const c = useStyles()

  return (
    <React.Fragment>
      {(() => {
        const rootClass = clsx(c.root, "list-item-with-avatar")

        const component = (
          <Indentation
            leftPosition="top"
            className={clsx("list-item-indentation", c.indentation)}
            left={props.avatar || null}
            {...(props.IndentationProps || {})}
          >
            <div className={c.containerWithCloseButton}>
              <div>
                {props.overline && (
                  <Typography variant="overline" className={c.overline}>
                    {props.overline}
                  </Typography>
                )}
                {props.firstLine && (
                  <Typography
                    variant="body1"
                    className={clsx(c.firstLine, "first-line")}
                  >
                    {props.firstLine}
                  </Typography>
                )}
                {props.secondLine && (
                  <Typography variant="body1" color="textSecondary">
                    {props.secondLine}
                  </Typography>
                )}
              </div>
              {(() => {
                if (!props.onDelete) return null

                return (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      props.onDelete?.call(undefined)
                    }}
                  >
                    <Icon className={c.icon}>close</Icon>
                  </IconButton>
                )
              })()}
            </div>
          </Indentation>
        )

        return React.createElement(props.onClick ? ButtonBase : "div", {
          className: rootClass,
          children: component,
          onClick: props.onClick,
        })
      })()}
    </React.Fragment>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    firstLine: { lineHeight: 1, marginBottom: 4 },
    overline: {
      lineHeight: "1",
      marginBottom: "7px",
      display: "block",
    },
    root: {
      padding: theme.spacing(1, 0),

      textAlign: "left",
      display: "block",
      width: "100%",
    },
    icon: {
      fontSize: "17px !important",
    },
    indentation: {
      "&>.list-item": {
        lineHeight: 1,
      },
    },

    containerWithCloseButton: {
      display: "flex",
      alignItems: "center",
      "&>*:first-child": {
        flex: 1,
      },
    },
  }
})
