import { ID } from "types/type"
import { RoomContext } from "components/Room/contexts/room"
import { ToggleableMenuItem } from "components/ToggleableMenu/ToggleableMenu"
import useActiveResources from "hooks/useActiveResources"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import useMemoAPI from "hooks/useMemoAPI"
// import { Typename } from "lib/getCacheID/getCacheID"
import { Typename } from "types/type"
import { useMakeRoomReadOnlyMutation } from "lib/graphql/operations"
import updateFragment from "lib/updateFragment/updateFragment"
import React from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function useReadOnlyOption(): ToggleableMenuItem | null {
  const { room } = React.useContext(RoomContext)
  const { toggleReadOnly } = useToggleReadOnly()

  const t = useI18n()
  // const {} = React.useContext()
  const { workspace: w } = useCurrentWorkspace()
  const api = useMemoAPI({
    component: !room?.isReadOnly ? (
      <>{t["archive-room"]}</>
    ) : (
      <>{t["active-room"]}</>
    ),
    onClick: toggleReadOnly,
  })

  if (room?.owner?.id !== w?.user?.id) return null

  return api
}
function useToggleReadOnly() {
  const { workspaceID } = useActiveResources()
  const { roomID, room } = React.useContext(RoomContext)

  const [mutation] = useMakeRoomReadOnlyMutation()

  const toggleReadOnly = React.useCallback(() => {
    if (!roomID || !workspaceID) return

    const oldReadOnly = room?.isReadOnly
    const newReadOnly = !oldReadOnly

    return mutation({
      variables: {
        workspaceID,
        roomID,
        isReadOnly: newReadOnly,
      },
      update(dataProxy, result) {
        if (!result?.data?.makeRoomReadOnly?.success) return

        updateFragment(
          {
            typename: Typename.Room,
            cacheIDParams: { id: roomID, workspaceID },
            updateFn: (a) => ({ ...a, isReadOnly: newReadOnly }),
          },
          dataProxy
        )
      },
    })
  }, [mutation, workspaceID, roomID, room?.isReadOnly])

  return useMemoAPI({ toggleReadOnly })
}
