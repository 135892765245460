import _ from "lodash"
import React, { useMemo, useCallback } from "react"
import { getName } from "../util"

export default function useGetInput({ formik, fieldsets, Inputs }) {
  const fieldsByName = useMemo(() => getFieldsByName({ fieldsets }), [
    fieldsets
  ])

  return useCallback(
    function getInput(args) {
      let fieldName = ""
      let fieldsetName = ""

      if (typeof args === "string") fieldName = args
      else {
        args = args || {}
        fieldName = args.fieldName
        fieldsetName = args.fieldsetName
      }

      const name = getName({ fieldsetName, fieldName })

      const field = {
        ...fieldsByName[name],
        ...formik.getFieldProps(name)
      }

      const Input = Inputs[field.type || "text"] || Inputs.text
      const meta = formik.getFieldMeta(name)

      return (
        <Input
          {..._.omit(field, "schema")}
          error={meta.touched && meta.error}
          meta={meta}
        ></Input>
      )
    },

    [fieldsByName, formik, Inputs]
  )
}

function getFieldsByName({ fieldsets }) {
  return fieldsets.reduce((acc, f) => {
    const fieldsetName = _.get(f, "name")

    ;(f.fieldset || []).forEach(field => {
      const name = getName({ fieldsetName, fieldName: field.name })
      acc[name] = field
    })

    return acc
  }, {})
}
