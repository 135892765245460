import { ChipProps } from "@material-ui/core"
import { InlinedChips } from "components/InlinedChips/InlinedChips"
import { excludeNullable } from "lib/excludeNullable"
import {
  RelatedItemsChipApiInput,
  RelatedItemsChipsContext,
  RelatedItemsChipsProvider,
} from "./RelatedItemsChipsContext"

export function RelatedItemsChips(props: RelatedItemsChipApiInput) {
  return (
    <RelatedItemsChipsProvider {...props}>
      <RelatedItemsChipsContext.Consumer>
        {(api) => {
          const chipsProps = [
            ...api.related
              .filter(excludeNullable)
              .map((item) => {
                const chipProps: ChipProps = {
                  key: item.id,
                  onClick: () => {
                    if (!item.id) return
                    api.onClickChip?.(item)
                  },
                  label:
                    (item.name || "").length <= 25
                      ? item.name
                      : item.name?.slice(0, 25) + "...",
                }

                return chipProps
              })
              .filter((a): a is ChipProps => !!a),
            ...(props.additionalChips?.(api) || []),
          ]?.filter((a): a is ChipProps => !!a)

          return (
            <InlinedChips
              wrap
              chips={chipsProps}
              style={{ marginTop: 8 }}
            ></InlinedChips>
          )
        }}
      </RelatedItemsChipsContext.Consumer>
    </RelatedItemsChipsProvider>
  )
}
