import { LexicalEditorContext } from "./contexts/LexicalEditorContext"
import LexicalEditorRoot from "./components/LexicalEditorRoot/LexicalEditorRoot"
import LexicalEditorProvider from "./components/LexicalEditorProvider/LexicalEditorProvider"
import ToolbarPlugin from "./components/ToolbarPlugin/ToolbarPlugin"
import FocusTrackPlugin from "./components/FocusTrackPlugin/FocusTrackPlugin"
import useInitialConfig from "./hooks/useInitialConfig"
import nodes from "./lib/nodes"

export type { LexicalEditorAPIInput } from "./hooks/useLexicalEditorAPI"

const LexicalEditor = {
  Provider: LexicalEditorProvider,
  useInitialConfig,
  Context: LexicalEditorContext,
  Root: LexicalEditorRoot,
  Toolbar: ToolbarPlugin,
  FocusTrack: FocusTrackPlugin,
}

export default LexicalEditor
