import { useApolloClient } from "@apollo/client";
import _ from "lodash"
import React from "react"
import { useEffect } from "react"
import { useActiveRoomID } from "contexts/activeRoom"
import useActiveResources from "hooks/useActiveResources"
import { RoomFragment, TypingOnRoomDocument } from "lib/graphql/operations"
import { RoomTabIDs } from "../../tabs/basicTabs"
import useDeleteMessage from "components/Room/hooks/useDeleteMessage"
import useMemoAPI from "hooks/useMemoAPI"
import useSearchParams from "hooks/useSearchParams/useSearchParams"
import useTabs from "./useTabs"
import setRoomUsersTyping from "./setRoomUsersTyping"
import { Subject } from "rxjs"
import useRootContainerAPI from "hooks/useRootContainerAPI"

export default function useTemplateAPI(props: {
  room: RoomFragment | null
  roomWasFetched: boolean
  createRoom: () => Promise<RoomFragment | undefined> | undefined
}) {
  const { room, roomWasFetched, createRoom } = props

  const messagesListContainer = useRootContainerAPI()

  const [searchIsOpen, setSearchIsOpen] = React.useState<boolean>(false)
  const searchParams = useSearchParams({ keyPrefix: "room" })

  const rootContainer = useRootContainerAPI()
  const tabsAPI = useTabs({ searchParams, room: props.room })

  const [isEditingMembers, setIsEditingMembers] = React.useState(false)

  const { workspaceID } = useActiveResources()
  const roomID = (room || {}).id
  const client = useApolloClient()

  const { setActiveRoomID } = useActiveRoomID()

  useEffect(() => {
    if (!roomWasFetched) return
    /**
     * set $activeRooID before the completion room's data fetching may
     * cause a wrong notification counting.
     *
     * the tracking of $activeRoomID is used to update
     * current room's properties, like the unread count.
     * **/
    setActiveRoomID(roomID || null)
    return () => setActiveRoomID(null)
  }, [roomWasFetched])

  useEffect(() => {
    setActiveRoomID(roomID || null)
  }, [roomID])

  useEffect(() => {
    if (!roomID || !workspaceID) return

    const variables = { roomID, workspaceID }

    let typingOnRoomSub = client
      .subscribe({ query: TypingOnRoomDocument, variables })
      .subscribe((result) => {
        const usersIDs = _.get(result, "data.typingOnRoom.usersIDs") || []
        setRoomUsersTyping({ usersIDs, roomID }, client)
      })

    return () => {
      typingOnRoomSub.unsubscribe()
      setRoomUsersTyping({ usersIDs: [], roomID }, client)
    }
  }, [workspaceID, roomID])

  const { deleteMessage } = useDeleteMessage({ room })

  const goToMembersInvitation$ = React.useRef(new Subject<void>()).current

  const goToMembersInvitation = React.useCallback(() => {
    setIsEditingMembers(() => true)
    goToMembersInvitation$.next()
    tabsAPI.setTabID(RoomTabIDs.MEMBERS)
  }, [setIsEditingMembers, tabsAPI.setTabID])

  const value = useMemoAPI({
    showRoomCreationButton: typeof createRoom === "function",
    deleteMessage,
    goToMembersInvitation,
    goToMembersInvitation$,
    isEditingMembers,
    setIsEditingMembers,
    searchParams,
    searchIsOpen,
    setSearchIsOpen,
    tabsAPI,
    messagesListContainer,
    /** Sets a limit on the messages container size to prevent
     * excessive enlargement by large images.*/
    comfortableMessageWidth: (() => {
      const width = messagesListContainer.width

      const percentage = (() => {
        if (width < 1000) return 0.75
        return 0.41
      })()

      const comfortableBalloonWidth = percentage * width

      return comfortableBalloonWidth
    })(),
    rootContainer,
  })

  return value
}
