import React, { useState, useContext, useMemo } from "react"
import getUtilsContext from "./getUtilsContext"

export default function getLibContext({
  utilsDefault = {},
  paramsDefault = {},
}) {
  const { UtilsProvider, useUtilsContext } = getUtilsContext({
    defaultValue: utilsDefault,
  })

  const ParamsContext = React.createContext(paramsDefault)
  ParamsContext.displayName = "ParamsContext"

  function LibProvider({ params = {}, utils = {}, children }) {
    return (
      <ParamsContext.Provider value={params}>
        <UtilsProvider utils={utils}>{children}</UtilsProvider>
      </ParamsContext.Provider>
    )
  }

  function useParamsContext() {
    return useContext(ParamsContext)
  }

  return { LibProvider, useParamsContext, useUtilsContext }
}
