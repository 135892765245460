import { ChipProps, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { excludeNullable } from "lib/excludeNullable"
import React from "react"
import { StyledProps } from "types/type"
import { IndexedItems } from "../../../../../IndexedItems/IndexedItems"
import { DateAndTypesFilterApiContext } from "../../DateAndTypesFilterApiContext"

export function useChipsProps(
  props: { typesChipsProps: ChipProps[] } & StyledProps
) {
  const indexedApi = React.useContext(IndexedItems.Context)
  const api = React.useContext(DateAndTypesFilterApiContext)

  const c = useStyles()

  const periodChip = IndexedItems.useSelectedPeriodChipProps()

  type ChipPropsEnhanced = ChipProps & { id: string; isSelected: boolean }

  const chips: ChipPropsEnhanced[] = [
    (() => {
      if (!periodChip) return null

      const chipProps: ChipPropsEnhanced = {
        ...periodChip,
        id: "DATE-FILTER",
        variant: "default",
        color: "primary",
        isSelected: true,
        size: api.isSmall ? "small" : "medium",
        className: clsx(
          c.isSelected,
          "date-and-types-filter-chip",
          periodChip.className
        ),
        onDelete() {
          indexedApi.dateIntervalSelectorApi.monthsApi.periodSelectorApi.clear()
        },
      }
      return chipProps
    })(),
    ...props.typesChipsProps.map((a, index) => {
      const type = indexedApi.availableTypes[index]
      const isSelected = indexedApi.types.value.includes(type)

      const b: ChipPropsEnhanced = {
        ...a,
        id: type,
        isSelected,
        variant: isSelected ? "default" : "outlined",
        color: isSelected ? "primary" : "default",
        size: api.isSmall ? "small" : "medium",
        onDelete: undefined,
        className: clsx(
          a.className,
          isSelected && c.isSelected,
          "date-and-types-filter-chip"
        ),
        onClick: () => {
          indexedApi.types.toggle(indexedApi.availableTypes[index])
        },
      }

      return b
    }),
  ].filter(excludeNullable)

  return chips
}

export const useStyles = makeStyles((theme) => ({
  isSelected: {
    backgroundColor: "#776589 !important",
    color: "white !important",
  },
}))
