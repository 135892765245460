import React from "react"
import * as pdfjs from "pdfjs-dist"
import { makeStyles, Paper } from "@material-ui/core"
import clsx from "clsx"
import { PdfContext } from "../../contexts/PdfContext"
import useComponentSize from "@rehooks/component-size"
import useRenderContext from "./hooks/useRenderContext"
import { TextContent } from "pdfjs-dist/types/src/display/api"

export default function PdfPage(props: {
  index: number
  className?: string
  width?: number
}) {
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const [visible, setVisible] = React.useState<boolean>(false)

  const c = useStyles({ visible })

  const [page, setPage] = React.useState<pdfjs.PDFPageProxy | null>(null)
  const [textContent, setTextContent] = React.useState<TextContent | null>(null)

  const ctx = React.useContext(PdfContext)

  const rootRef = React.useRef<HTMLDivElement | null>(null)
  const textLayerRef = React.useRef<HTMLDivElement | null>(null)

  const size = useComponentSize(rootRef)

  const width = typeof props.width === "undefined" ? size.width : props.width

  React.useEffect(() => {
    if (!ctx.getPage) return

    ctx.getPage(props.index).then((page) => {
      if (!page) return
      return setPage(page)
    })
  }, [ctx.getPage, props.index])

  const renderContext = useRenderContext({ page, width, canvasRef })

  React.useEffect(() => {
    if (!page || !renderContext) return
    page
      .render(renderContext)
      .promise.then((a) => page.getTextContent())
      .then((a: TextContent) => {
        setTextContent(a)
      })
    setVisible(true)
  }, [renderContext, page])

  React.useEffect(() => {
    if (!textContent || !textLayerRef.current || !renderContext?.viewport)
      return

    pdfjs.renderTextLayer({
      textContent,
      container: textLayerRef.current,
      viewport: renderContext.viewport,
      enhanceTextSelection: true,
    })
  }, [textContent, renderContext])

  return (
    <Paper
      elevation={3}
      className={clsx("pdf-page", c.root, props.className)}
      ref={rootRef}
    >
      <canvas ref={canvasRef}></canvas>
      <div className={clsx("text-layer", c.textLayer)} ref={textLayerRef}></div>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      visibility: (props: { visible: boolean }) =>
        !props.visible ? "hidden" : "visible",
      // display: "table",
      width: "100%",
      position: "relative",
      "&>.text-layer": {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
    },
    textLayer: {
      overflow: "hidden",
      opacity: 0.2,
      lineHeight: 1.0,

      "&>span": {
        color: "transparent",
        position: "absolute",
        cursor: "text",
        whiteSpace: "pre",
        transformOrigin: "0% 0%",
      },
    },
  }
})
