import {
  Card as MaterialCard,
  CardProps as MaterialCardProps,
  makeStyles,
} from "@material-ui/core"
import clsx from "clsx"
import _ from "lodash"
import React from "react"
import { StyledProps } from "types/type"

export { CardContent } from "./Content"

export type AppCardProps = Omit<MaterialCardProps, "children"> & {
  body?: React.ReactNode
  header?: React.ReactNode
} & StyledProps

export function AppCard(props: AppCardProps) {
  const c = useStyles({ isClickable: !!props.onClick })

  return (
    <MaterialCard
      {..._.omit(props, ["CardMediaProps", "body"])}
      className={clsx(c.root, "app-card", props.className)}
      style={props.style}
    >
      <div className={c.headerWrapper}>{props.header}</div>
      <div className={clsx(c.bodyWrapper, "body-wrapper")}>{props.body}</div>
    </MaterialCard>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    headerWrapper: {
      // height: "100%",
      position: "relative",
      // width: "100%",

      paddingTop: 0.5056 * 100 + "%",

      "&>*": {
        inset: 0,
        position: "absolute",
        height: "100%",
      },

      // "&>*:not(img)": {
      //   height: "100%",
      // },

      // "&>img": {
      //   objectFit: "cover",
      //   backgroundPosition: "center",
      //   width: "100%",
      // },
    },
    bodyWrapper: {
      padding: theme.spacing(1),
    },
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      cursor: (props: { isClickable: boolean }) => {
        return props.isClickable ? "pointer" : "default"
      },
      "&>.body-wrapper": {
        flex: 1,
      },
    },
    // cardSelection: {
    //   "&>*": {
    //     height: "100%",
    //   },
    // },
    // loadingBar: {
    //   position: "absolute",
    //   left: 0,
    //   right: 0,
    //   top: 0,
    // },
    // backgroundColor: {
    //   backgroundColor: "lightgray",
    // },
    header: {
      position: "relative",

      paddingTop: 0.5056 * 100 + "%",
      "&>*": {
        height: "100%",
      },
    },
    // actions: {
    //   display: "flex",
    // },
    // tabs: {
    //   maxWidth: "100%",
    // },
    // content: {
    //   // paddingTop: "16px !important",
    //   // paddingBottom: "16px !important",
    //   padding: 8,
    // },
    // contentTop: {
    //   display: "flex",
    //   justifyContent: "space-between",
    //   alignItems: "center",
    // },
    // image: {
    //   width: "100%",
    // },
  }
})
