import { makeStyles, Paper } from "@material-ui/core"
import clsx from "clsx"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import _ from "lodash"
import React from "react"
import { highlightBorder } from "styles/globalStyles"

export default function ActionsPanel(props: {
  actionButtons?: React.ReactNode[]
  className?: string
  children?: React.ReactNode
  style?: React.CSSProperties
}) {
  const { actionButtons = [], className = "", children, style } = props

  const { isMobile } = React.useContext(DeviceObserver.Context)

  const c = useStyles({ isMobile })

  if (!actionButtons?.length) return null

  const buttons = <div className={c.buttons}>{actionButtons}</div>

  return (
    <Paper
      className={clsx(c.actionsPanel, className)}
      elevation={2}
      square
      style={style}
    >
      {typeof children === "function"
        ? children({ defaultComponent: buttons })
        : buttons}
    </Paper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    actionsPanel: {
      padding: theme.spacing(1),
      // padding: ({ isMobile }) =>
      //   theme.spacing(...(isMobile ? [1] : [3, alignmentUnits])),
      display: "flex",
      borderTop: highlightBorder,
      // justifyContent: "flex-end",
      alignItems: "center",
    },
    buttons: {
      "&>*:not(:first-child)": {
        marginLeft: theme.spacing(1),
      },
    },
  }
})
