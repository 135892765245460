import { ID } from "types/type"
import { ShareableType, ShareableVariables } from "../@types"

class ShareableSearchParams {
  idKey = "shareable_id"
  typeKey = "shareable_type"
  sharerIDKey = "sharer_id"

  removeShareableParams(url: URL) {
    const clonedURL = new URL(url)

    ;[this.idKey, this.typeKey, this.sharerIDKey].forEach((key) => {
      clonedURL.searchParams.delete(key)
    })

    return clonedURL
  }

  parse(url: URL): ShareableVariables {
    const searchParams = url.searchParams

    return {
      shareableType: searchParams.get(this.typeKey) as ShareableType,
      shareableID: searchParams.get(this.idKey) as ID,
      sharerID: searchParams.get(this.sharerIDKey) as ID,
    }
  }
  set(
    props: { shareableType: string; shareableID: ID; sharerID: ID },
    url: URL
  ) {
    url.searchParams.set(this.typeKey, props.shareableType)
    url.searchParams.set(this.idKey, props.shareableID + "")
    url.searchParams.set(this.sharerIDKey, props.sharerID + "")

    return url
  }
}

const shareableSearchParams = new ShareableSearchParams()

export default shareableSearchParams
