import PaperList from "components/PaperList/index"
import ListItemLeft from "./components/ListItemLeft/ListItemLeft"
import { ListItemProps } from "components/PaperList/types"
import { purple, red } from "constants/colors"
import { COLLECTIONS_TYPES_COLOR } from "lib/constants"
import { CollectionItemType } from "types/type"
import arePropsEqual from "util/arePropsEqual"
import React from "react"
import { RoomMemberFragment } from "lib/graphql/operations"
import getRoomMemberColor from "../../lib/getRoomMemberColor"

export default React.memo(
  PresenceListItem,
  arePropsEqual([
    "isSelected",
    (a) => (a.entry?.id || "") + a.entry?.__typename,
  ])
)

function PresenceListItem(props: ListItemProps<RoomMemberFragment>) {
  const { entry: roomMember } = props

  const color = getRoomMemberColor(roomMember)

  return (
    <PaperList.ListItem
      left={
        props.left || (
          <ListItemLeft member={roomMember} color={color}></ListItemLeft>
        )
      }
      disableRipple={props.disableRipple}
      right={props.right}
      isSelected={props.isSelected}
      selectedColor={color}
      onClick={props.onClick}
    ></PaperList.ListItem>
  )
}
