// import { Button } from "components/Button/Button"
import { Button, Paper } from "@material-ui/core"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import { Shop } from "components/Shop/Shop"
import I18nContext from "contexts/i18nContext/i18nContext"
import useOnChange from "hooks/useOnChange"
import React from "react"
import { ShopOrderForm } from "../../../../ShopOrderForm/ShopOrderForm"
import { CartPageApiContext } from "./CartPageContext"

export function CartBody(props: {
  ArticlesGridProps?: Omit<
    React.ComponentProps<typeof Shop.ArticlesGrid>,
    "articles"
  >
}) {
  const shop = React.useContext(Shop.Context)
  const articles = (shop.cartApi.cart?.items ?? [])
    .filter((a) => !!a.quantity)
    .map((a) => a.article)

  const template = React.useContext(ItemTemplate.Context)
  const api = React.useContext(CartPageApiContext)

  useOnChange({
    value: articles?.length,
    onChange() {
      if (!articles.length) template.onClose()
    },
  })

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ShopOrderForm.Body
        style={{ flex: 1, overflow: "auto" }}
        renderArticles={() => {
          return (
            <Shop.ArticlesGrid
              {...(props.ArticlesGridProps || {})}
              articles={articles}
              noVirtualization
              style={{
                marginLeft: -4,
                marginRight: -4,
              }}
            ></Shop.ArticlesGrid>
          )
        }}
      ></ShopOrderForm.Body>

      {api.renderSaveButtonInTheBottom && (
        <Paper
          elevation={10}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 16,
            width: "100%",
          }}
        >
          <I18nContext.Consumer>
            {(t) => {
              return (
                <ShopOrderForm.Context.Consumer>
                  {(api) => {
                    return (
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ width: "100%" }}
                        onClick={api.form.submit}
                        disabled={!api.form.readyToSubmit}
                      >
                        {t.createOrder}
                      </Button>
                    )
                  }}
                </ShopOrderForm.Context.Consumer>
              )
            }}
          </I18nContext.Consumer>
        </Paper>
      )}
    </div>
  )
}
