import { Shop } from "components/Shop/Shop"
import _ from "lodash"
import { UseFormProps } from "hooks/useForm"
import useMemoAPI from "hooks/useMemoAPI"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import { ShopArticleFragment } from "lib/graphql/operations"
import { ShopOrderAdditionalInfoInput } from "lib/graphql/types"
import React from "react"
import { Subject } from "rxjs"
import { FormType } from "../../../../ShopOrderForm/ShopOrderFormContext"
import { CartBodyProps, OnClickArticle } from "./CartPage"

export function useCartPageApi(args: { partnerItemID?: string | null }) {
  const shop = React.useContext(Shop.Context)

  const [partnerItemID, setItemPartnerID] = React.useState(
    args.partnerItemID || ""
  )

  React.useEffect(() => {
    setItemPartnerID(args.partnerItemID || "")
  }, [args.partnerItemID])

  const articles = React.useMemo(
    () => (shop.cartApi.cart?.items ?? []).map((a) => a.article),
    [shop.cartApi.cart?.items]
  )

  const getQuantity = React.useCallback(
    (props: { articleID: string }) => {
      return (
        shop.cartApi.cart?.items?.find((a) => a.article.id === props.articleID)
          ?.quantity ?? 0
      )
    },
    [shop.cartApi.cart?.items]
  )

  const onClickArticle$ = React.useRef(
    new Subject<ShopArticleFragment>()
  ).current

  const onClickArticle: OnClickArticle = React.useCallback((a) => {
    onClickArticle$.next(a.article)
  }, [])

  const CartBodyProps = useCartProps({ onClickArticle })

  const onSubmit = useCreateOrderOnSubmit()

  const rootContainer = useRootContainerAPI()

  return useMemoAPI({
    ...args,
    shop,
    onSubmit,
    getQuantity,
    onClickArticle$,
    onClickArticle,
    articles,
    renderSaveButtonInTheBottom: rootContainer.width < 500,
    rootContainer,
    CartBodyProps,
    partnerItemID: partnerItemID,
    onChangePartnerItemID: setItemPartnerID,
  })
}

export function useCreateOrderOnSubmit() {
  const shopApi = React.useContext(Shop.Context)

  type SubmitFn = Exclude<UseFormProps<FormType>["onSubmit"], undefined>

  const onSubmit = React.useCallback<SubmitFn>(
    async (a) => {
      const partnerParams = a.values.partnerParams

      if (!partnerParams) return

      const b: ShopOrderAdditionalInfoInput = {
        ...a.values,
        partnerParams,
        address: _.omit(a.values.address, "__typename"),
      }

      const result = await shopApi.cartApi.createOrder.create(b)

      return result
    },
    [shopApi.cartApi.createOrder.create]
  )

  return onSubmit
}

function useCartProps(props: {
  onClickArticle?: OnClickArticle
}): CartBodyProps | undefined {
  return React.useMemo<CartBodyProps | undefined>(() => {
    if (!props.onClickArticle) return undefined

    return {
      ArticlesGridProps: {
        ArticleCardProps: (a) => {
          return {
            onClick() {
              props.onClickArticle?.(a)
            },
          }
        },
      },
    }
  }, [props.onClickArticle])
}
