import _ from "lodash"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { useHistoryThread } from "./useHistoryThread"

export type HistoryThreadApi = ReturnType<typeof useHistoryThread>

const Factory = contextAndProviderFactory({ hookApi: useHistoryThread })
export const HistoryThreadContext = Factory.Context

export const HistoryThreadProvider = function HistoryThreadProvider(
  props: React.ComponentProps<typeof Factory.Provider>
) {
  return (
    <HistoryThreadContext.Consumer>
      {(api) => {
        // check if we are inside another provider and reusing it
        if (!_.isEmpty(api)) return <>{props.children}</>
        return <Factory.Provider {...props} />
      }}
    </HistoryThreadContext.Consumer>
  )
}
