import {
  makeStyles,
  Typography,
  TypographyProps,
  useTheme,
} from "@material-ui/core"
import clsx from "clsx"
import Color from "color"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import { StyledProps } from "types/type"

// useAvatarAction

export function UserIdentification(
  props: {
    displayName: string
    isActive?: boolean
    children?: React.ReactNode
    variant?: "centered-display-name" | "left-display-name"
    backgroundColor?: string
  } & StyledProps
) {
  const rootApi = useRootContainerAPI()
  const theme = useTheme()

  const variant = props.variant || "centered-display-name"
  const c = useStyles({ isActive: props.isActive })

  const typographyProps: TypographyProps | null = (() => {
    if (variant === "centered-display-name") {
      return {
        variant: rootApi.width < 600 ? "h6" : "h5",
        className: c.centeredDisplayName,
      }
    }

    if (variant === "left-display-name") {
      return {
        variant: "body1",
        className: c.leftDisplayName,
      }
    }

    return null
  })()

  return (
    <div
      className={clsx(c.root, props.className)}
      style={props.style}
      ref={rootApi.containerRef}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          // backgroundColor: props.backgroundColor,
        }}
      ></div>
      {props.children || null}

      <Typography
        {...(typographyProps || {})}
        className={clsx(typographyProps?.className, c.displayName)}
      >
        <span style={{ padding: 16 }}>{props.displayName}</span>
      </Typography>
    </div>
  )
}

type Style = { isActive?: boolean }

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    border: (props: Style) => {
      return props.isActive
        ? `4px solid ${theme.palette.secondary.main}`
        : "none"
    },
  },
  displayName: {
    position: "absolute",
  },
  centeredDisplayName: {
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  leftDisplayName: {
    left: 8,
    bottom: 8,
    // padding: theme.spacing(0.25),
    // background: "rgba(0,0,0,0.5)",
    background: (props: Style) => {
      return props.isActive
        ? new Color(theme.palette.secondary.main).alpha(0.5).toString()
        : "rgba(0,0,0,0.5)"
    },
    display: "table",
    color: "white",
    // transform: "scale(1.5)",
  },
}))
