import { makeStyles } from "@material-ui/core"
import { RoomContext } from "components/Room/contexts/room"
import {
  Message_MessageInParagraphs_Fragment,
  ParagraphFragment,
} from "lib/graphql/operations"
import { MessageBalloonProps } from "../MessageBalloon/MessageBalloon"
import { MessageWrapper } from "../MessageWrapper"

import React from "react"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import { Paragraph } from "./Paragraph"

export function Paragraphs(props: {
  message: Message_MessageInParagraphs_Fragment
  MessageBalloonProps: Omit<MessageBalloonProps, "message" | "children">
  renderParagraph?: (props: {
    defaultComponent: React.ReactNode
    paragraph: ParagraphFragment
    containerWidth?: number
  }) => React.ReactNode
}) {
  const rootApi = useRootContainerAPI()

  const c = useStyles()

  return (
    <MessageWrapper {...props} key={props.message.id}>
      {({ components: { authorName, bottom } }) => {
        return (
          <>
            {authorName}
            <div className={c.paragraphs} ref={rootApi.containerRef}>
              {props.message.paragraphs?.map((p, i) => {
                const defaultComponent = <Paragraph paragraph={p}></Paragraph>

                return (
                  <React.Fragment key={i}>
                    {(() => {
                      if (props.renderParagraph) {
                        return props.renderParagraph({
                          defaultComponent: defaultComponent,
                          paragraph: p,
                          containerWidth: rootApi.width,
                        })
                      }

                      return defaultComponent
                    })()}
                  </React.Fragment>
                )
              })}
            </div>
            {bottom}
          </>
        )
      }}
    </MessageWrapper>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    paragraphs: {
      "& > *": {
        marginBottom: 8,
      },
    },
  }
})
