import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import { HeaderIconButton } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/PageBoardItem"
import PurpleBadge from "components/PurpleBadge/PurpleBadge"
import { Shop } from "components/Shop/Shop"
import { StyledProps } from "types/type"
import { CartPage } from "../CartPage/CartPage"

export function CartButton(props: StyledProps) {
  return (
    <ItemTemplate.Context.Consumer>
      {(api) => {
        return (
          <Shop.Context.Consumer>
            {(shop) => {
              if (!shop.cartApi.hasItems) return null

              return (
                <PurpleBadge
                  variant="standard"
                  badgeContent={shop.cartApi.cart?.items?.length || 0}
                  className={props.className}
                >
                  <div>
                    <HeaderIconButton
                      name="shopping_basket"
                      onClick={() => {
                        api.twoPagesAPI.openFullPage(CartPage.Body)
                      }}
                    ></HeaderIconButton>
                  </div>
                </PurpleBadge>
              )
            }}
          </Shop.Context.Consumer>
        )
      }}
    </ItemTemplate.Context.Consumer>
  )
}
