import useMemoAPI from "hooks/useMemoAPI"
import useRootContainerAPI from "hooks/useRootContainerAPI"
import { useObservableState } from "observable-hooks"
import React from "react"
import { Subject } from "rxjs"

export function useGridProps<T extends any = any>(props: {
  items: T[]
  numOfColumns?: number
}) {
  const items = props.items

  const rootWidth$ = React.useRef(new Subject<number>()).current
  const rootWidth = useObservableState(rootWidth$, 0)

  const numOfColumns =
    props.numOfColumns ??
    ((rootWidth < 500 && 2) ||
      (rootWidth < 700 && 3) ||
      (rootWidth < 900 && 4) ||
      (rootWidth < 1300 && 5) ||
      (rootWidth < 1600 && 6) ||
      7)

  const lines = React.useMemo(() => {
    if (!items?.length) return []

    const amountOfGroups = Math.ceil(items.length / numOfColumns)

    return Array.from({ length: amountOfGroups }, (_, i) => {
      return [
        ...items.slice(i * numOfColumns, (i + 1) * numOfColumns),
        ...Array.from<null>({ length: numOfColumns }).fill(null),
      ].slice(0, numOfColumns)
    })
  }, [items, numOfColumns])

  return useMemoAPI({
    lines,
    numOfColumns,
    rootWidth,
    rootWidth$,
  })
}
