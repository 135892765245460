import { makeStyles } from "@material-ui/core"
import clsx from "clsx"

export default function RoomWall(props: {
  bottomRight?: React.ReactNode
  className?: string
}) {
  const c = useStyles()

  return (
    <div className={clsx(c.root, "room-all", props.className)}>
      <div className={c.content}>{props.bottomRight || null}</div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
      height: "100%",
    },
    content: {
      height: "100%",
      position: "absolute",
      padding: theme.spacing(2),
      alignItems: "flex-end",
      justifyContent: "flex-end",
      display: "flex",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  }
})
