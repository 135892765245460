import moment from "moment"
import clsx from "clsx"
import React from "react"
import { UserFragment } from "lib/graphql/operations"
import TextField from "../../../TextField/TextField"
import isValidTimestamp from "lib/isValidTimestamp"
import { makeStyles } from "@material-ui/core"

export function DocumentTimelog(props: {
  label?: string
  dateTime: moment.MomentInput
  person?: UserFragment | null
  style?: React.CSSProperties
}) {
  const dateTime = props.dateTime

  const c = useStyles()

  if (!isValidTimestamp(dateTime)) return null

  const value = moment(dateTime).format("DD.MM.YY [um] HH:mm")

  return (
    <div className={clsx("time-log")} style={props.style || {}}>
      <TextField
        label={props.label}
        value={value}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
        }}
        className={c.time}
        inputProps={{ className: c.input }}
        defaultValue="-"
      ></TextField>

      {props.person &&
        (() => {
          const value = ["von", props.person.name].join(" ")
          return (
            <TextField
              value={value}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              // inputProps={{ style: { paddingTop: 4 } }}
              inputProps={{ className: c.personName }}
            ></TextField>
          )
        })()}
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    time: {
      marginBottom: "0 !important",
    },
    input: { paddingBottom: 0 },

    personName: {
      paddingTop: 4,
    },
  }
})
