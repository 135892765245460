import { Fab, Tooltip } from "@material-ui/core"
import { Icon, IconProps } from "components/Icon/Icon"
import React from "react"
import { StyledProps } from "types/type"
// import Fab from "components/Fab/Fab"

export function FabControlButton(
  props: StyledProps & {
    toolTipTitle: string
    icon: IconProps["name"]
    onClick: () => void
    renderIcon?: (props: {
      defaultComponent: React.ReactNode
    }) => React.ReactNode
  }
) {
  const defaultIcon = <Icon name={props.icon} style={{ color: "white" }} />

  return (
    <Tooltip title={props.toolTipTitle}>
      <Fab color="secondary" size="large" onClick={props.onClick}>
        <>
          {props.renderIcon
            ? props.renderIcon({ defaultComponent: defaultIcon })
            : defaultIcon}
        </>
      </Fab>
    </Tooltip>
  )
}
