import { useApolloClient } from "@apollo/client";
import RoomResolver from "./lib/RoomResolver/RoomResolver"
import React from "react"
import useRoom from "hooks/useRoom/useRoom"
import useActiveResources from "hooks/useActiveResources"
import useAsyncCall from "hooks/useAsyncCall/useAsyncCall"
import { useMemo } from "react"
import { RoomIdentifier } from "types/type"
import useMemoAPI from "hooks/useMemoAPI"
import { getUpdateCache } from "lib/getUpdateCache"

export default function useResolveRoom(props: { identifier: RoomIdentifier }) {
  const client = useApolloClient()
  const { workspaceID } = useActiveResources()

  const [identifier, setIdentifier] = React.useState<RoomIdentifier>(
    props.identifier
  )

  React.useEffect(() => {
    setIdentifier(props.identifier)
  }, [JSON.stringify(props.identifier)])

  const roomResolver = useMemo(() => {
    return new RoomResolver({ client, workspaceID })
  }, [workspaceID, client])

  const { result: resolved, loading } = useAsyncCall(() => {
    return roomResolver.resolveRoom(identifier)
  }, [roomResolver, JSON.stringify(identifier)])

  const [isCreating, setIsCreating] = React.useState<boolean>(false)

  const roomAPI = resolved?.api

  const createRoom = React.useCallback(() => {
    setIsCreating(true)
    return roomAPI?.createRoom
      ?.call(roomAPI)
      .then((room) => {
        if (!room) return

        getUpdateCache(client).addRoom({
          __typename: "CreatedRoom",
          room: room,
          roomID: room.id,
        })

        setIdentifier({ roomID: room.id })

        return room
      })
      .finally(() => setIsCreating(false))
  }, [roomAPI?.createRoom, client])

  const roomID = resolved?.roomID

  const roomData = useRoom({
    roomID: roomID || "",
    workspaceID,
  })

  const api = {
    roomID,
    roomData,
    roomIsInexistent: resolved?.roomIsInexistent,
    isResolvingRoom: loading,
    roomAPI,
    createRoom,
    isCreatingRoom: isCreating,
  }

  return useMemoAPI(api)
}
