import { CartFragment } from "lib/graphql/operations"
import {
  CartItemFragment,
  ShopArticleFragment,
  ShopArticleFragmentDoc,
} from "../../../lib/graphql/operations"
import { DataProxy } from "apollo-cache/lib/types/DataProxy"
import { excludeNullable } from "lib/excludeNullable"
import cachedIDGetters from "lib/getCacheID/getCacheID"
import { Typename } from "../../../types/type"

export async function updateCart(
  {
    cart,
    articleID,
    updateItemFn: updateFn,
    workspaceID,
  }: {
    cart?: CartFragment | null
    articleID: string
    workspaceID: string
    updateItemFn: (item: CartItemFragment) => CartItemFragment
  },
  dataProxy: DataProxy
) {
  if (!cart) {
    const emptyCart: CartFragment = {
      id: "",
      __typename: "Cart",
      items: [],
      workspaceID,
    }

    cart = emptyCart
  }

  let index = (cart?.items || [])?.findIndex(
    (item) => item.article.id === articleID
  )

  const cloned: (CartItemFragment | null)[] = [...(cart?.items || [])]

  const item = (() => {
    if (index === -1) {
      const article = (() => {
        try {
          const article = dataProxy.readFragment<ShopArticleFragment>({
            id: cachedIDGetters[Typename.ShopArticle]({ id: articleID }),
            fragment: ShopArticleFragmentDoc,
          })

          return article || null
        } catch (e) {
          console.error(e)
        }
      })()

      if (!article) return null

      const newItem: CartItemFragment = {
        __typename: "CartItem",
        id: articleID,
        article,
        quantity: 0,
      }

      return newItem
    }

    const item = cart.items?.[index]

    return item
  })()

  cloned[index === -1 ? cloned.length : index] = !!item ? updateFn(item) : null

  const cartItems = cloned.filter(excludeNullable)

  const updatedCart: CartFragment = {
    ...cart,
    items: cartItems,
  }

  return updatedCart
}
