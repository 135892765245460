import { useApolloClient } from "@apollo/client";
import { DocumentNode } from "graphql"
import useFragment from "hooks/apolloHooks/useFragment"
import { UserFragmentDoc } from "lib/graphql/operations"
import readFragment from "lib/updateFragment/readFragment"
import React from "react"
import { Typename } from "../../types/type"

export default function useUser(props: {
  id: string
  fragment?: DocumentNode
  fragmentName?: string
}) {
  const client = useApolloClient()

  const user = React.useMemo(() => {
    return readFragment(
      { typename: Typename.User, cacheIDParams: { id: props.id } },
      client
    )
  }, [client, props.id])

  // let { id, fragment = UserFragmentDoc, fragmentName } = props

  // if (fragment == UserFragmentDoc) fragmentName = "User"

  // return useFragment({
  //   fragment: fragment,
  //   id: `User:${id}`,
  //   ...(fragmentName ? { fragmentName } : {}),
  // })

  return user
}
