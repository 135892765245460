import { Icon, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { CycleStatus } from "components/AnimationStatus/AnimationStatus"
import ImageViewerContext from "components/ImageViewer/contexts/ImageViewerContext"
import React from "react"

export default function OpacityAnimation(props: {
  children?: React.ReactChild
  animation: { duration: number; status: CycleStatus }
}) {
  const c = useStyles()

  // const { animation } = React.useContext(ImageViewerContext)
  const animation = props.animation

  const animationClassName: Record<CycleStatus, string> = {
    exited: "",
    final: c.final,
    initial: c.initial,
  }

  return (
    <div
      style={{ transition: `opacity ${animation?.duration}ms` }}
      className={clsx(
        "opacity-animation",
        animationClassName[animation?.status || "exited"]
      )}
    >
      {props.children}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    initial: {
      opacity: 0,
    },
    final: {
      opacity: 1,
    },
  }
})
