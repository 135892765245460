import Partner from "components/Partner/index"
import { useContext, useMemo } from "react"

export default function usePartnerTypeField({ name = "type" }) {
  const { partner } = useContext(Partner.Context) || {}

  const { typesAvailable } = partner || {}

  const typesOptions = (typesAvailable || []).map(({ locale, value }) => ({
    value,
    label: locale,
  }))

  return useMemo(
    () => ({ name, type: "select", options: typesOptions }),
    [JSON.stringify(typesOptions)]
  )
}
