import _ from "lodash"
import {
  ButtonBase,
  Icon,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core"
import clsx from "clsx"
import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import React, { useContext, useState } from "react"
import AspectRatioButton from "./components/AspectRatioButton/AspectRatioButton"
import CloseButton from "./components/CloseButton/CloseButton"
import { useI18n } from "contexts/i18nContext/i18nContext"
import ChatOverlayContext from "components/ChatOverlay/contexts/ChatOverlayContext"
import useLastValue from "hooks/useLastValue/useLastValue"
import { useEffect } from "react"
import isAllValuesEmpty from "lib/isAllValuesEmpty"

export default function Control({ className = "" }) {
  const c = useStyles({})
  const { workspace } = useCurrentWorkspace()
  const { roomIdentifier, setRoomIdentifier } = useContext(ChatOverlayContext)
  const unread = _.get(workspace, "unread")
  const t = useI18n()

  const [showUnreadMessages, setShowUnreadMessages] = useState(false)
  const isRoomOpen = !isAllValuesEmpty(roomIdentifier)

  const lastUnread = useLastValue(unread)

  useEffect(() => {
    setShowUnreadMessages(isRoomOpen && unread)
  }, [lastUnread])

  useEffect(() => {
    if (!isRoomOpen || !unread) return setShowUnreadMessages(false)
  }, [isRoomOpen, unread])

  return (
    <div className={clsx("chat-control", c.root, className)}>
      <div className="chat-control-left">
        {showUnreadMessages && (
          <ButtonBase onClick={() => setRoomIdentifier(null)}>
            <Typography className={c.unreadText} variant="body2">
              <Icon>chevron_left</Icon>
              <span className="text">{t["unread-messages"](unread)}</span>
            </Typography>
          </ButtonBase>
        )}
      </div>
      <div className={clsx("chat-control-right", c.controlRight)}>
        <AspectRatioButton></AspectRatioButton>
        <CloseButton></CloseButton>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    controlRight: {
      display: "flex",
    },
    unreadText: {
      paddingLeft: theme.spacing(1),
      "&, & *": {
        color: "white",
      },
      "& .text": {
        position: "relative",
        top: 0.5,
      },
      display: "flex",
      alignItems: "center",
    },
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // opacity: 0.3,
      background: theme.palette.primary.main,
    },
    icon: {
      color: "white",
    },
  }
})
