import { Icon } from "@material-ui/core"
import {
  CreateDocumentRoomDocument,
  CreateDocumentRoomMutation,
  DocumentRoomDocument,
  DocumentRoomQuery,
  DocumentRoomQueryVariables,
} from "lib/graphql/operations"
import RoomAPI from "../RoomAPI/RoomAPI"
import RoomDocument from "./components/RoomDocument/RoomDocument"
import DeprecatedDocumentForm from "components/DeprecatedDocumentForm"
import UpdateDocument from "components/UpdateDocument/UpdateDocument"
import { DocumentRoomIdentifier, ObjectWithID } from "types/type"
import usePartner from "hooks/usePartner"
import basicTabs from "components/Room/components/tabs/basicTabs"
import Pdf from "components/Pdf/Pdf"

export default class DocumentRoomAPI extends RoomAPI<DocumentRoomIdentifier> {
  allowAutomaticCreation = false
  allowMembersToEdit = true

  async getRoom(params: DocumentRoomIdentifier) {
    const room = await this.client
      .query<DocumentRoomQuery, DocumentRoomQueryVariables>({
        query: DocumentRoomDocument,
        variables: {
          documentID: params.documentID,

          workspaceID: this.workspaceID,
        },
        fetchPolicy: "cache-first",
      })
      .then((a) => a.data.documentRoom)

    return room
  }

  async createRoom(identifier: DocumentRoomIdentifier) {
    const room = await this.client
      .mutate<CreateDocumentRoomMutation>({
        mutation: CreateDocumentRoomDocument,
        variables: {
          documentID: identifier.documentID,
          workspaceID: this.workspaceID,
        },
      })
      .then((a) => a.data?.createDocumentRoom)

    return room
  }

  static tabs = (() => {
    {
      const documentCacheKey = "DOCUMENT_ROOM_API"

      const tabs: Record<
        string,
        { label: React.ReactNode; component: React.ReactNode }
      > = {
        ...basicTabs,
        DOCUMENT: {
          label: <Icon>info</Icon>,
          component: (
            <RoomDocument cacheKey={documentCacheKey}>
              {({ document, loading }) => (
                <UpdateDocument documentID={document?.id}>
                  {(updateDocument) => {
                    return (
                      <Partner
                        collectionID={document?.partnerCollection?.id}
                        type={document?.partnerCollection?.type}
                      >
                        {({ partner, isLoading }) => {
                          return (
                            <DeprecatedDocumentForm.Provider
                              document={document}
                              onSaveEdition={updateDocument}
                              partner={partner}
                            >
                              <DeprecatedDocumentForm.Root></DeprecatedDocumentForm.Root>
                            </DeprecatedDocumentForm.Provider>
                          )
                        }}
                      </Partner>
                    )
                  }}
                </UpdateDocument>
              )}
            </RoomDocument>
          ),
        },
        INFO: {
          label: <Icon>description</Icon>,
          component: (
            <RoomDocument cacheKey={documentCacheKey}>
              {({ document, loading }) =>
                document?.pdf ? (
                  <Pdf.Provider url={document?.pdf}>
                    <Pdf.Root></Pdf.Root>
                  </Pdf.Provider>
                ) : null
              }
            </RoomDocument>
          ),
        },
      }
      return tabs
    }
  })()
}

function Partner(props: {
  collectionID?: string
  type?: string
  partnerID?: string
  children: (props: {
    partner: ObjectWithID
    isLoading?: boolean
  }) => JSX.Element
}) {
  // const {} = usePartner()
  const { partner, isLoading } = usePartner({
    collectionID: props.collectionID,
    type: props.type,
    id: props.partnerID,
  })

  return props.children({
    partner,
    isLoading: isLoading || false,
  })
}
