import React, { useMemo, useState, useContext } from "react"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import { DrawerProps } from "@material-ui/core"
import useTrueFalse from "hooks/useTrueFalse"
import useMemoAPI from "hooks/useMemoAPI"
import useComponentSize from "@rehooks/component-size"

export function DrawerProvider(props: { children: React.ReactNode }) {
  const api = useDrawerAPI()
  return (
    <DrawerContext.Provider value={api}>
      {props.children}
    </DrawerContext.Provider>
  )
}

export function useDrawerContext() {
  return React.useContext(DrawerContext)
}

function useDrawerAPI() {
  const { isMobile } = React.useContext(DeviceObserver.Context)
  const variant: DrawerProps["variant"] = isMobile ? "temporary" : "persistent"

  const isDrawerOpen = useTrueFalse(false)
  const drawerContainerRef = React.useRef<HTMLDivElement>(null)

  const size = useComponentSize(drawerContainerRef)

  const ref = useMemoAPI({
    ref: drawerContainerRef,
    width: size.width,
  })

  return useMemoAPI({
    variant,
    isDrawerOpen: isDrawerOpen.isTrue,
    container: ref,

    toggleDrawer: isDrawerOpen.toggle,
    openDrawer: isDrawerOpen.setTrue,
    closeDrawer: isDrawerOpen.setFalse,
  })
}

export const DrawerContext = React.createContext(
  {} as ReturnType<typeof useDrawerAPI>
)

DrawerContext.displayName = "DrawerContext"
