import PaperList from "components/PaperList"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"

export function ChatListRoot(props: { marginZero: boolean }) {
  const c = useStyles({})

  const isSmall = props.marginZero

  return (
    <PaperList.OldRoot
      className={clsx("chat-list-root", isSmall && c.paddingZero)}
      classes={{ itemPaper: clsx(isSmall && c.marginZero) }}
      marginZero={isSmall}
    ></PaperList.OldRoot>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    marginZero: {
      margin: 0,
    },

    paddingZero: {
      padding: 0,
    },
  }
})
