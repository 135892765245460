import React from "react"
import clsx from "clsx"
import IconButton from "components/IconButton/IconButton"
import { Tooltip as MuiTooltip, Icon } from "@material-ui/core"
import arePropsEqual from "util/arePropsEqual"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"

function SendButton({ onClick, className, icon = "send" }) {
  const { isDesktop } = React.useContext(DeviceObserver.Context)
  icon = icon || "send"

  return (
    <IconButton
      onClick={onClick}
      className={clsx("sendButton", className)}
      edge="end"
      color="secondary"
    >
      {isDesktop && (
        <Tooltip>
          <Icon>{icon}</Icon>
        </Tooltip>
      )}
      {!isDesktop && <Icon>{icon}</Icon>}
    </IconButton>
  )
}

function Tooltip({ children }) {
  const title = (() => {
    // const isMac = window.navigator.platform.toUpperCase().includes("MAC")
    return "Enter"
  })()

  return (
    <MuiTooltip title={title} placement="top">
      {children}
    </MuiTooltip>
  )
}

export default React.memo(SendButton, arePropsEqual(["onClick", "className"]))
