import _ from "lodash"
import React from "react"
import { useEffect, useState } from "react"
import useIsMounted from "./useIsMounted"
import useMemoAPI from "./useMemoAPI"

/**
 * It enables two-way data-binding
 * IMPORTANT: Don't pass objects to $value containing circular references!
 * */
export function useInputController<Value>(props: {
  onChange?: (value: Value) => any
  value: Value
}) {
  const [newValue, setNewValue] = React.useState<Value>(props.value)

  const isMounted = useIsMounted()

  React.useEffect(() => {
    if (!isMounted) return
    if (isEqual(props.value, newValue)) return
    props.onChange?.call(null, newValue)
  }, [JSON.stringify(newValue)])

  React.useEffect(() => {
    if (!isMounted) return
    if (isEqual(props.value, newValue)) return
    setNewValue(props.value)
  }, [JSON.stringify(props.value)])

  return useMemoAPI({ inputValue: newValue, setInputValue: setNewValue })
}

function isEqual<T>(oldValue: T, newValue: T) {
  return JSON.stringify(oldValue) === JSON.stringify(newValue)
}
