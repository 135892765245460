import {
  createStyles,
  makeStyles,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
  useTheme,
} from "@material-ui/core"
import { Variant as TypographyVariant } from "@material-ui/core/styles/createTypography"
import clsx from "clsx"
import useEscKey from "hooks/old.useEscKey"
import { useInputController } from "hooks/useInputController"
import React from "react"
import useIsMounted from "../../hooks/useIsMounted"
import useTypographyClass from "../../hooks/useTypographyClass"

export interface EditableAPI {
  startEdition: () => void
  cancelEdition: () => void
}

export default React.forwardRef(Editable)

function Editable(
  props: {
    children?: string | React.ReactChild | React.ReactChild[] | null
    defaultValue?: string | null
    placeholder?: string
    multiline?: boolean
    allowToStartEditionOnClick?: boolean
    className?: string
    isEditing?: boolean
    onCancelEditing?: () => void
    onStartEditing?: () => void
    onSubmit?: (value: string) => void
    onChange?: (value: string) => void
    renderInput?: () => React.ReactNode
    classes?: { input?: string; nonEditingWrapper?: string }
    textFieldProps?: TextFieldProps
    style?: React.CSSProperties
    variant?: TypographyVariant
    focusOnMounting?: boolean
  },

  externalRef: React.Ref<EditableAPI | null>
) {
  const { allowToStartEditionOnClick = true } = props

  const inputRef = React.useRef<HTMLInputElement>(null)

  const { inputValue: value, setInputValue: setValue } = useInputController({
    value: props.defaultValue,
  })

  const isMounted = useIsMounted()

  React.useEffect(() => {
    if (!isMounted) return
    if (!props.focusOnMounting) return
    setIsEditing(true)
  }, [isMounted])

  React.useImperativeHandle(
    externalRef,
    () => ({
      startEdition: () => setIsEditing(true),
      cancelEdition: () => setIsEditing(false),
    }),
    []
  )

  const { inputValue: isEditing, setInputValue: setIsEditing } =
    useInputController({
      onChange: (v) =>
        v
          ? props.onStartEditing?.call(null)
          : props.onCancelEditing?.call(null),

      value: props.isEditing,
    })

  React.useEffect(() => {
    if (!isEditing) {
      return setValue(props.defaultValue)
    }

    inputRef.current?.focus()
  }, [isEditing])

  const submit = React.useCallback(() => {
    props.onSubmit?.call(null, value || "")
    props.onChange?.call(null, value || "")
    setIsEditing(() => false)
  }, [value, props.onChange, props.onSubmit, setIsEditing])

  useEscKey(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      submit()
    },
    { isDisabled: !isEditing }
  )

  const c = useStyles()

  // const getTypography = useGetTypography()
  const typography = useTypographyClass()

  let content

  if (!isEditing)
    content = (
      <div
        className={clsx(
          "non-editing",
          c.nonEditing,
          props?.classes?.nonEditingWrapper
        )}
        onClick={() => allowToStartEditionOnClick && setIsEditing(true)}
        style={
          props.multiline
            ? {
                /**
                 * a padding is applied when the text is being edited.
                 * So prevent the 'jumping' in the layout, when pre added when $isEditing === false
                 */
                padding: "6px 0 7px",
              }
            : {}
        }
      >
        {typeof props.variant !== "undefined" ? (
          <Typography variant={props.variant}>{props.children}</Typography>
        ) : (
          props.children
        )}
      </div>
    )
  else {
    content = (
      <form
        action=""
        className="editable"
        onSubmit={(e) => {
          e.preventDefault()
          submit()
        }}
      >
        {typeof props.renderInput === "function" ? (
          props.renderInput()
        ) : (
          <TextField
            value={value}
            className={clsx("editable-text-field", c.textField)}
            onChange={(e) => setValue(e.target.value)}
            multiline={props.multiline}
            onClick={(e) => e.stopPropagation()}
            inputProps={{
              className: clsx(
                props.classes?.input,
                props.variant && typography[props.variant]
              ),
              ref: inputRef,
              placeholder: props.placeholder || "Type...",
            }}
            onBlur={() => submit()}
            {...props.textFieldProps}
          ></TextField>
        )}
      </form>
    )
  }

  return (
    <div
      className={clsx("editable", c.root, props.className)}
      style={props.style}
    >
      {content}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      // wordBreak: "break-all",
      "&>*": {
        width: "100%",
      },
    },
    nonEditing: {
      cursor: "pointer",
    },
    textField: {
      display: "block",
      "&>*": {
        width: "100%",
      },
    },
  }
})
