import React from "react"
import { Icon, IconButton, Button } from "@material-ui/core"
import { PageTextField } from "components/Page/Page"
import { useI18n } from "contexts/i18nContext/i18nContext"
import AddMembersInputPageContext from "../../contexts/AddMembersInputPageContext"
import CalendarEventContext from "components/CalendarEvent/contexts/CalendarEventContext"

export default function SelectUsers() {
  const api = React.useContext(AddMembersInputPageContext)
  const ac = api.autoCompleteSuggestions
  const eventAPI = React.useContext(CalendarEventContext)

  const inputRef = api.inputRef

  React.useEffect(() => {
    if (!inputRef.current) return
    inputRef.current.focus()
  }, [])

  const t = useI18n()
  return (
    <PageTextField
      value={ac.text || ""}
      onChange={(e) => ac.changeTextFilteringOptions(e.target.value)}
      typographyVariant="body1"
      inputProps={{ ref: inputRef }}
      style={{ marginBottom: 0 }}
      placeholder={t["search-members"]}
      InputProps={{
        disableUnderline: true,
        endAdornment: (() => {
          if (!ac.isVisible)
            return (
              <Button
                color="primary"
                onClick={() => api.onClose?.call(undefined)}
                variant="text"
                size="small"
              >
                ok
              </Button>
            )

          return (
            <IconButton size="small" onClick={api.hideSuggestions}>
              <Icon className={eventAPI.classes.smallIcon}>close</Icon>
            </IconButton>
          )
        })(),
      }}
    ></PageTextField>
  )
}
