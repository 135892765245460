import { useI18n } from "contexts/i18nContext/i18nContext"
import useActiveResources from "hooks/useActiveResources"
import useForm, { UseFormProps } from "hooks/useForm"
import useMemoAPI from "hooks/useMemoAPI"
import usePartner from "hooks/usePartner"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import {
  PartnerDocument,
  PartnerQueryVariables,
  useAddDetailedContactMutation,
} from "lib/graphql/operations"
import React from "react"
import * as Yup from "yup"

export type AddNewContactFormApiInput = {
  partnerId?: string
}

const emptyState = { firstName: "", lastName: "", email: "", remarks: "" }

type FormInput = typeof emptyState

const A = contextAndProviderFactory({
  hookApi: function useAddNewContactForm(props?: AddNewContactFormApiInput) {
    const t = useI18n()

    const { addContact } = useAddNewContact({ partnerId: props?.partnerId })

    const partner = usePartner({
      id: props?.partnerId || "",
      fetchPolicy: "cache-only",
    }).partner

    const onSubmit = React.useCallback<
      Exclude<UseFormProps<FormInput>["onSubmit"], undefined>
    >(
      (e) => {
        return addContact({ ...e.values })
      },
      [addContact]
    )

    const usedEmails =
      partner?.contacts
        ?.map((c) => c.email || [])
        .filter((a): a is string => !!a) || []

    const form = useForm<FormInput>({
      initialValues: emptyState,
      onSubmit,
      validationSchema: Yup.object().shape({
        lastName: Yup.string().required(t.required),
        email: Yup.string()
          .email(t.wrongEmail)
          .notOneOf(usedEmails, t.emailAlreadyUsed),
      }),
    })

    return useMemoAPI({ ...(props || {}), form })
  },
  contextName: "AddNewContactFormContext",
})

export const AddNewContactFormContext = A.Context
export const AddNewContactFormProvider = A.Provider

function useAddNewContact(props: { partnerId?: string }) {
  const [mutate] = useAddDetailedContactMutation()

  const workspaceID = useActiveResources().workspaceID

  const addContact = React.useCallback(
    (args: { partnerId?: string } & FormInput) => {
      if (!workspaceID) return

      const { partnerId: propsPartnerId, ...formInput } = args

      const partnerId = args.partnerId || props.partnerId

      if (!partnerId) return

      return mutate({
        variables: {
          workspaceID,
          partnerID: partnerId,
          firstName: formInput.firstName || "",
          lastName: formInput.lastName || "",
          email: formInput.email || "",
          remarks: formInput.remarks || "",
        },

        refetchQueries: [
          {
            query: PartnerDocument,
            variables: (() => {
              const a: PartnerQueryVariables = { workspaceID, id: partnerId }
              return a
            })(),
          },
        ],
      })
    },
    [props.partnerId, workspaceID, mutate]
  )

  return useMemoAPI({ addContact })
}

// const { addContact } = useAddContact({ partnerId })

// return { addContact }
