import { makeStyles } from "@material-ui/styles"
import _ from "lodash"
import React from "react"

import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import matches from "util/matches"
import stringOrNumber from "util/stringOrNumber"

export default function Body({
  rows,
  columns,
  defaultValue = "-",
  hover,
  onClickRow,
  searchedTerm = "",
  classes: { row: rowClass }
}) {
  const c = useStyles({})
  return (
    <TableBody>
      {rows.map((row, index) => (
        <TableRow
          key={`row-${row.id || index}`}
          hover={hover}
          className={rowClass}
          onClick={() => _.isFunction(onClickRow) && onClickRow(row)}
        >
          {(function renderRow() {
            return columns.map(columnProps => {
              const {
                name: columnName,
                Cell,
                unsearchable,
                getValueOnSearch
              } = columnProps

              const value =
                _.find(row, (value, key) => key === columnName) || defaultValue

              const isMatchingSearch = (() => {
                const valueOnSearch =
                  typeof getValueOnSearch === "function"
                    ? getValueOnSearch({ entry: row, value })
                    : value

                return (
                  !unsearchable &&
                  matches({ value: valueOnSearch, searchedTerm })
                )
              })()

              const highlightClass = isMatchingSearch ? c.highlighted : ""

              return (
                <TableCell
                  {...columnProps}
                  key={"cell-" + columnName}
                  component="td"
                  scope={index === 0 ? "row" : ""}
                >
                  {typeof Cell === "function" ? (
                    <Cell
                      value={value}
                      row={row}
                      className="cell"
                      matchingSearchClass={highlightClass}
                    />
                  ) : stringOrNumber(value) ? (
                    <span className={highlightClass}>{value}</span>
                  ) : (
                    value
                  )}
                </TableCell>
              )
            })
          })()}
        </TableRow>
      ))}
    </TableBody>
  )
}

const useStyles = makeStyles(theme => {
  return {
    highlighted: {
      fontWeight: 700
    }
  }
})
