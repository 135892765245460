import Case from "case"
import React from "react"
import { useInputController } from "../../hooks/useInputController"
import { COLLECTIONS_TYPES } from "../../lib/constants"
import OptionsToggleMenu from "components/OptionsToggleMenu/OptionsToggleMenu"

export default function CollectionsTypesFilter({
  edge,
  onChange,
  defaultValue,
  typesToOmit = [],
  value,
}) {
  return (
    <OptionsToggleMenu
      edge={edge}
      value={!Array.isArray(value) ? [value].filter(Boolean) : value}
      // value={[COLLECTIONS_TYPES[0]]}
      options={COLLECTIONS_TYPES.map((a) => ({
        label: Case.capital(a),
        value: a,
      }))}
      onChange={onChange}
    ></OptionsToggleMenu>
  )
}
