import { useApolloClient } from "@apollo/client";
import _ from "lodash"
import clsx from "clsx"
import { Button } from "@material-ui/core"
import React from "react"
import { useI18n } from "contexts/i18nContext/i18nContext"
import ButtonBase from "components/ButtonBase/ButtonBase"
import getPrivateRoomFromCache from "util/getPrivateRoomFromCache"
import { useActiveRoomID } from "contexts/activeRoom"
import useRoutingSystem from "hooks/useRouteSystem/useRoutingSystem"

export default function GoToPrivateRoomButton({
  userID,
  children: propsChildren = null,
  variant: propsVariant = "",
  ButtonBaseProps = {},
  MuiButtonProps = {},
  className: propsClassName = "",
  goToPrivateRoom: propsGoToPrivateRoom = undefined,
}) {
  const t = useI18n()
  const className = clsx("go-to-private-room-button", propsClassName)
  const { goToPrivateRoom: goToPrivateRoomPage } = useRoutingSystem()
  const children = propsChildren || t.message
  const variant = propsVariant || MuiButtonProps.variant

  const apolloClient = useApolloClient()
  const { activeRoomID } = useActiveRoomID()

  if (isAlreadyInThePrivateRoom({ userID, activeRoomID }, apolloClient))
    return null

  const goToRoom = () => {
    if (typeof propsGoToPrivateRoom === "function")
      return propsGoToPrivateRoom({ userID })

    return goToPrivateRoomPage({ userID })
  }

  if (!variant)
    return (
      <ButtonBase {...ButtonBaseProps} onClick={goToRoom} className={className}>
        {children}
      </ButtonBase>
    )

  return (
    <Button
      {...MuiButtonProps}
      onClick={goToRoom}
      className={className}
      variant={variant}
    >
      {children}
    </Button>
  )
}

function isAlreadyInThePrivateRoom({ userID, activeRoomID }, apolloClient) {
  const privateRoom = getPrivateRoomFromCache({ userID }, apolloClient)
  return activeRoomID && activeRoomID === _.get(privateRoom, "id")
}
