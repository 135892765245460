import React from "react"
import useUserPageBoardItemAPI from "../hooks/useUserPageBoardItemAPI"

const UserPageBoardItemContext = React.createContext(
  {} as ReturnType<typeof useUserPageBoardItemAPI>
)

UserPageBoardItemContext.displayName = "UserPageBoardItemContext"

export default UserPageBoardItemContext
