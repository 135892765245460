import { makeStyles } from "@material-ui/core"
import ContactForm from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ContactForm/ContactForm"
import { FormCommons } from "../FormCommons/FormCommons"
import { ItemTemplate } from "../ItemTemplate/ItemTemplate"
import { ContactPageBoardItemBody } from "./Body"
import {
  ContactPageBoardItemApiInput,
  ContactPageBoardItemConsumer,
  ContactPageBoardItemProvider,
} from "./ContactPageBoardItemApi/ContactPageBoardItemApi"
import { Controls } from "./Controls"
// import { ItemTemplate } from "../ItemTemplate/ItemTemplate"

export function ContactPageBoardItem(props: ContactPageBoardItemApiInput) {
  const c = useStyles({})

  return (
    <ContactPageBoardItemProvider {...props}>
      <ContactPageBoardItemConsumer>
        {(api) => {
          const contact = api.contact
          const openPartnerPage = api.openPartnerPage

          if (!contact) return null

          return (
            <ItemTemplate.Provider onClose={api.onClose}>
              <ItemTemplate.Context.Consumer>
                {(templateApi) => {
                  return (
                    <ContactForm.Provider
                      contactID={contact.id}
                      twoPagesAPI={templateApi.twoPagesAPI}
                      openPartnerPage={openPartnerPage} /** TODO: remove */
                    >
                      <ContactForm.Context.Consumer>
                        {(contactFormApi) => {
                          return (
                            <FormCommons.Provider
                              twoPagesApi={templateApi.twoPagesAPI}
                              form={contactFormApi.form as any}
                              deletion={{
                                isDeleted: !!contact.deleted,
                                toggleDeletion: contactFormApi.toggleDeletion,
                              }}
                            >
                              <ItemTemplate.Root
                                className={c.root}
                                EnhancedPageProps={{
                                  titleComponent: (
                                    <>
                                      <div></div>
                                      <ContactPageBoardItemConsumer>
                                        {(api) => {
                                          return (
                                            <Controls
                                              contact={api.contact || null}
                                            ></Controls>
                                          )
                                        }}
                                      </ContactPageBoardItemConsumer>
                                    </>
                                  ),
                                  body: (
                                    <ContactPageBoardItemBody></ContactPageBoardItemBody>
                                  ),
                                }}
                              ></ItemTemplate.Root>
                            </FormCommons.Provider>
                          )
                        }}
                      </ContactForm.Context.Consumer>
                    </ContactForm.Provider>
                  )
                }}
              </ItemTemplate.Context.Consumer>
            </ItemTemplate.Provider>
          )
        }}
      </ContactPageBoardItemConsumer>
    </ContactPageBoardItemProvider>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "&>*": {
        height: "100%",
      },
    },
  }
})
