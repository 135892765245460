import { useApolloClient } from "@apollo/client";
import React from "react"
import { useChangeKanbanColumnTitleMutation } from "lib/graphql/operations"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import { Nullable, Typename } from "types/type"
import readFragment from "lib/updateFragment/readFragment"

export default function useChangeKanbanColumnTitle(props: {
  roomID: Nullable<string>
}) {
  const [mutate, { data, loading, error }] =
    useChangeKanbanColumnTitleMutation()

  const client = useApolloClient()
  const r = useActiveResources()

  const changeColumnTitle = React.useCallback(
    async (args: { title: string; columnID: string }) => {
      const workspaceID = r.workspaceID
      const title = args.title
      const roomID = props.roomID

      const columnID = args.columnID

      if (!workspaceID || !roomID) return

      const currentColumn = readFragment(
        { typename: Typename.KanbanColumn, cacheIDParams: { id: columnID } },
        client
      )

      if (!currentColumn) return

      const updatedColumn: typeof currentColumn = { ...currentColumn, title }

      const { data } = await mutate({
        optimisticResponse: {
          __typename: "Mutation",
          changeKanbanColumnTitle: updatedColumn,
        },
        variables: {
          workspaceID,
          columnID: currentColumn.id,
          title,
        },
      })

      return data?.changeKanbanColumnTitle
    },
    [props.roomID, r.workspaceID, mutate, client]
  )

  return useMemoAPI({ changeColumnTitle })
}
