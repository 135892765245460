import React from "react"
import { KanbanActivity_KanbanAddImageActivity_Fragment } from "lib/graphql/operations"
import { useTheme } from "@material-ui/core"
import { useI18n } from "contexts/i18nContext/i18nContext"
import ActivitiesLines from "../ActivitiesLines/ActivitiesLines"
import KanbanCardPageBoardItemContext from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/KanbanCardPageBoardItem/contexts/KanbanCardPageBoardItemContext"
import CardImage from "../../../CardImage/CardImage"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"

export default function AddImageActivity(props: {
  activity: KanbanActivity_KanbanAddImageActivity_Fragment
}) {
  const t = useI18n()

  const api = React.useContext(KanbanCardPageBoardItemContext)
  const templatePageApi = React.useContext(ItemTemplate.Context)
  const theme = useTheme()

  return (
    <div>
      <ActivitiesLines
        action={(() => {
          return (
            <span>
              hat{" "}
              <span
                onClick={() =>
                  api.images.modal.open({ imageID: props.activity.image?.id })
                }
                style={{
                  cursor: "pointer",
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                  ...(!props.activity.image?.src && { opacity: 0.4 }),
                }}
              >
                {(() => {
                  const filename = props.activity.image?.filename

                  if (!filename) return null

                  const max = templatePageApi.rootContainer.isSmall ? 12 : 40

                  if (filename.length > max)
                    return filename.slice(0, max) + "..."

                  return filename
                })()}
              </span>{" "}
              zu dieser Aufgabe hinzugefügt
            </span>
          )
        })()}
        icon="attach_file"
        dateTime={props.activity.when}
        user={props.activity.user}
        render={(p) => {
          return (
            <>
              {p.components?.action}
              {props.activity.image?.src && (
                <div
                  style={{
                    marginTop: 8,
                    height: 150,
                    width: "100%",
                    marginBottom: 8,
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <CardImage
                    image={props.activity.image}
                    style={{ height: "100%", position: "absolute" }}
                  ></CardImage>
                </div>
              )}

              {p.components?.when}
            </>
          )
        }}
      ></ActivitiesLines>
    </div>
  )
}
