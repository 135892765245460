import React, { useContext } from "react"
import IconButton from "components/IconButton/IconButton"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import SideSheetContext from "components/SideSheet/contexts/sideSheetContext/sideSheetContext"

function MobileSideSheetToggle({ iconName = "group", edge = "end" }) {
  const { openSideSheet } = useContext(SideSheetContext)
  const { isMobile } = React.useContext(DeviceObserver.Context)

  if (!isMobile) return null

  return (
    <IconButton
      edge={edge}
      iconName={iconName}
      onClick={() => {
        openSideSheet()
      }}
    />
  )
}

export default MobileSideSheetToggle
