import React from "react"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { mergeRegister } from "@lexical/utils"
import { BLUR_COMMAND, FOCUS_COMMAND } from "lexical"
import useOnChange from "hooks/useOnChange"
import KeyListeners from "components/KeyListeners/KeyListeners"

export default function FocusTrackPlugin(props: {
  children: (props: { hasFocus: boolean }) => React.ReactNode
  containerElement?: HTMLElement | null
  onFocus?: () => any
  onBlur?: () => any
}) {
  const [editor] = useLexicalComposerContext()
  // Commands are subscriptions so the default state is important!
  const isFocused = React.useCallback(() => {
    return editor.getRootElement() === document.activeElement
  }, [editor])

  const [hasFocus, setHasFocus] = React.useState(isFocused)

  const blur = React.useCallback(() => {
    editor.dispatchCommand(
      BLUR_COMMAND,
      new FocusEvent("blur", { relatedTarget: window })
    )

    setHasFocus(false)
  }, [editor])

  KeyListeners.useEscapeListener(blur, { enabled: hasFocus })

  useOnChange({
    value: hasFocus,
    disabled: !props.onBlur && !props.onFocus,
    onChange: (hasFocus) => {
      if (hasFocus) {
        props.onFocus?.()
      } else {
        props.onBlur?.()
      }
    },
  })

  React.useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setHasFocus(isFocused)
          return true
        },
        2
      ),
      (() => {
        // If we have a container element, blur events are only considered when target is outside of the container
        if (props.containerElement) return () => {}

        return editor.registerCommand(
          BLUR_COMMAND,
          () => {
            setHasFocus(isFocused)
            return true
          },
          2
        )
      })()
    )
  }, [isFocused, editor, props.containerElement])

  React.useEffect(() => {
    if (!props.containerElement) return

    const fn = (ev: MouseEvent) => {
      if (props.containerElement?.contains(ev.target as Node)) return
      setHasFocus(false)
    }

    window.addEventListener("click", fn)

    return () => {
      window.removeEventListener("click", fn)
    }
  }, [props.containerElement])

  return <>{props.children({ hasFocus })}</>
}
