import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import {
  EnhancedPageProps,
  EnhancedPage,
} from "components/EnhancedPage/EnhancedPage"
import { PartnerFragment } from "lib/graphql/operations"
import React from "react"
import { ArrayElement } from "../../types/type"
import PartnerForm from "../PageBoard/components/PartnerForm/PartnerForm"
import { FormCommons } from "../PageBoard/components/IndexedItems/components/PageBoardItem/components/FormCommons/FormCommons"

export default function PartnerFormPage(props: {
  onClose: () => void
  title?: string
  summarized?: boolean
  style?: React.CSSProperties
  onClickOpenButton?: () => void
  partnerID?: string
  onClickContactChip?: (
    contact: ArrayElement<PartnerFragment["contacts"]>
  ) => void
}) {
  const renderTitleLine = React.useCallback(
    (() => {
      const r: EnhancedPageProps["renderTitleLine"] = function render({
        titleComponent,
      }) {
        return (
          <>
            {titleComponent}
            <FormCommons.SaveButton></FormCommons.SaveButton>
          </>
        )
      }

      return r
    })(),
    [props.onClose]
  )

  const c = useStyles({})

  return (
    <PartnerForm.Provider
      partnerID={props.partnerID}
      onClickOpenButton={props.onClickOpenButton}
      onClickContactChip={props.onClickContactChip}
      summarized={props.summarized}
    >
      <EnhancedPage
        {...props}
        title="Partner"
        className={clsx(c.root, "partner-form-page")}
        body={
          <div style={{ maxHeight: "100%", overflow: "hidden" }}>
            <PartnerForm.Root style={{ maxHeight: "100%" }}></PartnerForm.Root>
          </div>
        }
        renderTitleLine={renderTitleLine}
      ></EnhancedPage>
    </PartnerForm.Provider>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}))
