// import { RoomType } from "lib/constants"

import { RoomFragment } from "lib/graphql/operations"
import { RoomType } from "lib/graphql/types"

export default function isPrivateRoom(room: RoomFragment) {
  const isPrivate = [RoomType.UserGuest, RoomType.UserUser].some(
    (t) => t === (room || {}).type
  )

  return isPrivate
}
