import { useI18n } from "contexts/i18nContext/i18nContext"
import TextField from "components/TextField/TextField"
import React from "react"
import { StyledProps } from "types/type"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import { AddNewContactFormContext } from "./ContextAndProvider"

export function AddNewContactFormRoot(props: StyledProps) {
  const api = React.useContext(AddNewContactFormContext)

  const t = useI18n()

  const c = useStyles()

  return (
    <div
      className={clsx(c.root, "add-new-contact-form-root", props.className)}
      style={props.style}
    >
      <TextField
        {...api.form.getFieldProps("firstName")}
        label={t["firstName"]}
      ></TextField>
      <TextField
        {...api.form.getFieldProps("lastName")}
        label={t["lastName"]}
        error={!!api.form.formik.errors["lastName"]}
        helperText={api.form.formik.errors["lastName"]}
      ></TextField>
      <TextField
        {...api.form.getFieldProps("email")}
        label={t["email"]}
        error={!!api.form.formik.errors["email"]}
        helperText={api.form.formik.errors["email"]}
      ></TextField>
      <TextField
        {...api.form.getFieldProps("remarks")}
        label={t["remarks"]}
      ></TextField>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    "&>*": {
      paddingBottom: theme.spacing(1),
    },
  },
}))
