import React from "react"
import { makeStyles } from "@material-ui/core"
import { EnhancedPageProps } from "components/EnhancedPage/EnhancedPage"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import Title from "../Title/Title"
import Body from "../Body/Body"
import clsx from "clsx"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import ImagesModal from "../ImagesModal/ImagesModal"
import EmojiPickerModal from "../EmojiPickerModal/EmojiPickerModal"
import UsersReactionsModal from "../UsersReactionsModal/UsersReactionsModal"
import EditCommentModal from "../EditCommentModal/EditCommentModal"
import RemoveCommentModal from "../RemoveCommentModal/RemoveCommentModal"
import AddCommentModal from "../AddCommentModal/AddCommentModal"
// import ReactionModal from "../ActivityEntry/ReactionModal/ReactionModal"
import DescriptionModal from "../DescriptionModal/DescriptionModal"
import DeleteCardModal from "../DeleteCardModal/DeleteCardModal"

export default function KanbanCardPageBoardItemRoot(props: {
  EnhancedPageProps?: EnhancedPageProps
  className?: string
  style?: React.CSSProperties
}) {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  return (
    <div
      className={clsx("kanban-card-page-board-item-root", props.className)}
      style={props.style}
    >
      {/** images input */}
      <input
        hidden
        type="file"
        multiple={api.images.listFileUI.multiple}
        ref={api.images.listFileUI.inputRef}
        accept={api.images.listFileUI.accept}
        onChange={api.images.listFileUI.onChange}
      ></input>

      {/** cover input */}
      <input
        hidden
        type="file"
        ref={api.images.coverFileUI.inputRef}
        accept={api.images.coverFileUI.accept}
        onChange={api.images.coverFileUI.onChange}
      ></input>

      <ImagesModal
        isOpen={api.images.modal.isOpen}
        onClose={api.images.modal.close}
      ></ImagesModal>

      <EmojiPickerModal></EmojiPickerModal>
      <UsersReactionsModal></UsersReactionsModal>

      {!!api.viewport?.isSmallView && <AddCommentModal></AddCommentModal>}

      {!!api.viewport?.isSmallView && <DescriptionModal></DescriptionModal>}

      {!!api.viewport?.isSmallView && <EditCommentModal></EditCommentModal>}

      <RemoveCommentModal></RemoveCommentModal>
      <DeleteCardModal></DeleteCardModal>

      <ItemTemplate.Root
        EnhancedPageProps={{
          titleComponent: <Title style={{ width: "100%" }}></Title>,
          body: (
            <Body
            // style={{ background: "white" }}
            // ActivitiesProps={{ style: { background: "white" } }}
            ></Body>
          ),
          // style: { background: gray },
          // HeaderProps: {
          //   style: { background: "white" },
          // },
        }}
      ></ItemTemplate.Root>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },

  fullHeight: {
    height: "100%",
  },
}))
