import { gql } from "@apollo/client";
import readFragment from "./readFragment"
import { roomTypesColors } from "lib/constants"
import { RoomType } from "lib/graphql/types"

export default function privateRoomColor(
  { userID, userType = undefined },
  client
) {
  const user =
    readFragment(
      {
        fragment: gql`
          fragment UserPrivateRoomColor on User {
            type
          }
        `,
        id: `User:${userID}`,
      },
      client
    ) || {}

  const isGuest = (user.type || userType) === "GUEST"
  const { UserGuest: USER_GUEST, UserUser: USER_USER } = RoomType
  const backgroundColor = roomTypesColors[isGuest ? USER_GUEST : USER_USER]

  return backgroundColor
}
