import _ from "lodash"
import isPaymentType from "lib/isPaymentType"
import useMemoAPI from "hooks/useMemoAPI"
import DocumentForm from "components/OldDocumentForm/OldDocumentForm"
import React from "react"

const basicInfoFields = ["title", "date", "type", "status"]
const collectionsFields = ["partner", "collections"]
const textFields = ["text"]

export default function useBasicFieldsGroups() {
  const { documentForm } = React.useContext(DocumentForm.Context)

  const type = documentForm.values["type"]
  const isAPaymentActivity = isPaymentType(type)

  const paymentFields = React.useMemo(() => {
    if (!isAPaymentActivity) return null

    return [
      "netValueGoods",
      "paymentDate",
      "totalTax",
      "postDate",
      "totalAmount",
    ]
  }, [isAPaymentActivity])

  return useMemoAPI({
    paymentFields,
    collectionsFields,
    textFields,
    basicInfoFields,
  })
}
