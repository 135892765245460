import { makeStyles } from "@material-ui/styles"
import React from "react"
import { Button, ButtonProps, Icon } from "@material-ui/core"
import clsx from "clsx"

function UploadButton(ButtonProps: ButtonProps) {
  const c = useStyles({})

  return (
    <Button
      // disabled={disabled}
      variant="contained"
      // onClick={onClick}
      color="primary"
      {...ButtonProps}
      className={clsx("upload-button", ButtonProps.className)}
    >
      <Icon className={c.icon}>cloud_upload</Icon>
      upload
    </Button>
  )
}

export default UploadButton

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      marginRight: theme.spacing(1),
    },
  }
})
