import React from "react"
import { TwoPagesAPI } from "components/TwoPages/TwoPages"
import useRootContainerAPI from "hooks/useRootContainerAPI"

export type TemplatePageApi = {
  rootContainer: ReturnType<typeof useRootContainerAPI>
  onClose: () => any
  twoPagesAPI: TwoPagesAPI
}

const TemplatePage = React.createContext({} as TemplatePageApi)

TemplatePage.displayName = "TemplatePageContext"

export default TemplatePage
