import React from "react"
import { RangeAPI } from "../../@types"
import useMemoAPI from "hooks/useMemoAPI"

export default function useRange(props: {
  min: number
  max: number
  step: number
  defaultValue?: number
}): RangeAPI {
  const min = props.min
  const max = props.max
  const step = props.step

  const defaultValue = props.defaultValue || props.min

  const [value, setPercentage] = React.useState<number>(defaultValue)

  const limit = (percentage: number = 0) => {
    return Math.max(min, Math.min(percentage, max))
  }

  const change = React.useCallback((percentage: number) => {
    return setPercentage(limit(percentage))
  }, [])

  const increase = React.useCallback(
    (multiplier?: number) => {
      const m = multiplier || 1

      return setPercentage((v) => limit(v + step * m))
    },
    [step]
  )

  const reduce = React.useCallback(
    (multiplier?: number) => {
      const m = multiplier || 1
      return setPercentage((v) => limit(v - step * m))
    },
    [step]
  )

  const reset = React.useCallback(
    () => setPercentage(defaultValue),
    [defaultValue]
  )

  const api: RangeAPI = {
    max,
    min,
    value,
    change,
    reset,
    increase,
    reduce,
    step,
  }

  return useMemoAPI(api)
}
