import ImageAttachmentContext from "components/ImageAttachment/contexts/ImageAttachmentContext"
import {
  ImageAttachmentAPIInput,
  useImageAttachmentAPI,
} from "components/ImageAttachment/hooks/useImageAttachmentAPI"
import React from "react"

export default function AttachmentProvider(
  props: ImageAttachmentAPIInput & { children: React.ReactChild }
) {
  const { children, ...attachmentProviderValue } = props
  const value = useImageAttachmentAPI(attachmentProviderValue)

  return (
    <ImageAttachmentContext.Provider value={value}>
      {props.children}
    </ImageAttachmentContext.Provider>
  )
}
