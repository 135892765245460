import _f from "lodash/fp"
import _ from "lodash"
import { makeStyles, Typography } from "@material-ui/core"
import React from "react"

export default function StatusColorsExplanation({
  statusValue,
  colorMap,
  translationMap,
}) {
  const c = useStyles({ ballSize: 15 })

  if (!colorMap) return null

  return (
    <div className={c.root}>
      {Object.entries(colorMap).map(([colorStatusValue, color]) => {
        const isSelected = colorStatusValue === statusValue

        return (
          <div className={c.line}>
            <div className={c.ball} style={{ backgroundColor: color }}></div>
            <Typography
              variant="body2"
              color={isSelected ? "textPrimary" : "textSecondary"}
              style={{ opacity: isSelected ? 1 : 0.7 }}
            >
              {translationMap?.[colorStatusValue] || colorStatusValue}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "table",
      padding: theme.spacing(1),
    },
    ball: {
      marginRight: theme.spacing(0.3),
      width: ({ ballSize }) => ballSize,
      height: ({ ballSize }) => ballSize,
      borderRadius: "50%",
    },
    line: {
      "&:not(:last-child)": {
        marginBottom: theme.spacing(0.5),
      },
      display: "flex",
      alignItems: "center",
    },
  }
})

//
