import React from "react"
import { RoomContext } from "components/Room/contexts/room"
import MessagesContext from "../../contexts/MessagesContext"
import MessageRow from "../MessageRow/MessageRow"
import TodoOnceVisible from "components/TodoOnceVisible/TodoOnceVisible"
import requestFrame from "lib/requestFrame"

/** deprecated */
export default Message

export function Message(props: { index: number }) {
  const { index } = props
  const { messageList } = React.useContext(RoomContext)
  const { messagesData, listRenderer } = React.useContext(MessagesContext)

  const message = React.useMemo(
    () => messagesData.getMessage(index),
    [messagesData.getMessage, index]
  )

  if (!!message?.deletedAt)
    return (
      <div
        style={{
          height: 1,
        }}
      ></div>
    )

  if (!message) {
    return (
      <React.Fragment>
        {(() => {
          const { visibleStartIndex, visibleStopIndex } =
            listRenderer.messagesRendered || {}

          if (
            typeof visibleStartIndex !== "number" ||
            typeof visibleStopIndex !== "number"
          )
            return null

          return (
            <div style={{ paddingTop: 80 }}>
              <TodoOnceVisible
                executeOnce
                todoOnceVisible={() => {
                  requestFrame(() => {
                    const totalItems = messageList.data?.totalItems
                    if (!totalItems) return

                    if (!messageList?.fetchingInterval) {
                      const { startIndex = 0, stopIndex = 0 } =
                        messageList.fetchingInterval || {}

                      if (index >= startIndex && index <= stopIndex) return
                    }

                    messageList.fetchMore({
                      stopIndex: Math.min(index + 20, totalItems),
                      startIndex: Math.max(index - 20, 0),
                    })
                  })
                }}
              ></TodoOnceVisible>
            </div>
          )
        })()}
      </React.Fragment>
    )
  }

  return (
    <MessageRow
      index={index}
      firstUnread={listRenderer.unread.thisMessageHasGuideLine({ index })}
      isHighlighted={index === listRenderer.scrolling.highlightedIndex}
    ></MessageRow>
  )
}
