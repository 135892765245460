import clsx from "clsx"
import Shareable from "components/Shareable/Shareable"
import ToggleableMenu from "components/ToggleableMenu/ToggleableMenu"
import I18nContext from "contexts/i18nContext/i18nContext"
import React from "react"
import { ShareableType } from "types/type"
import KanbanCardPageBoardItemContext from "../../contexts/KanbanCardPageBoardItemContext"
import TextFieldWithControlledFocus from "../TextFieldWithControlledFocus/TextFieldWithControlledFocus"

export default function Title(props: {
  style?: React.CSSProperties
  className?: string
}) {
  const api = React.useContext(KanbanCardPageBoardItemContext)

  return (
    <div
      className={clsx("title", props.className)}
      style={{
        ...(props.style || {}),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      {/* <Typography variant="h6" style={{ marginRight: 8 }}>
        hello
      </Typography> */}

      <div></div>
      <TextFieldWithControlledFocus
        // className={c.title}
        // InputProps={{ className: c.titleInput }}
        typographyVariant="h6"
        multiline
        placeholder="Title"
        style={{ flex: 1 }}
        inputProps={{ ...(api.card.form.getFieldProps("title") as any) }}
      ></TextFieldWithControlledFocus>
      {/* <IconButton
        size="small"
        // style={{ marginRight: 8 }}
        // onClick={api.images.listFileUI.open}
        onClick={() => {}}
        edge="end"
      >
        <Icon name="more_vert"></Icon>
      </IconButton> */}

      <I18nContext.Consumer>
        {(t) => {
          return (
            <Shareable.CopyLink
              shareableType={ShareableType.KanbanCard}
              shareableID={api.card.data?.id || ""}
            >
              {({ copyShareableLink }) => {
                return (
                  <ToggleableMenu
                    edge="end"
                    menuItems={[
                      {
                        text: t["deleteTask"],
                        onClick: () => api.card.deleteModal.open(),
                      },
                      {
                        text: t["copy-link"],
                        onClick: copyShareableLink,
                      },
                    ]}
                  ></ToggleableMenu>
                )

                return <></>
              }}
            </Shareable.CopyLink>
          )
        }}
      </I18nContext.Consumer>

      {/* <Button
        style={{ fontSize: 12 }}
        variant="contained"
        size="small"
        disableElevation
        onClick={api.images.coverFileUI.open}
        color="primary"
      >
        cover
      </Button> */}
    </div>
  )
}
