import { QueryResult } from "@apollo/client/react/types/types"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import useOnChange from "hooks/useOnChange"
import useStateController from "hooks/useStateController"
import contextAndProviderFactory from "lib/contextAndProviderFactory"
import { excludeNullable } from "lib/excludeNullable"
import {
  ShopArticleFragment,
  ShopArticlePageBoardItemFragment,
  ShopArticleVariationFragment,
  ShopArticleVariationsAttributeFragment,
  ShopArticleVariationsAttributesQuery,
  ShopArticleVariationsAttributesQueryVariables,
  useSelectArticleVariationMutation,
  useShopArticleVariationsAttributesQuery,
} from "lib/graphql/operations"
import { Exact } from "lib/graphql/types"
import _ from "lodash"
import { useObservable, useSubscription } from "observable-hooks"
import React from "react"
import { Subject, merge } from "rxjs"
import { filter, map } from "rxjs/operators"
import { StyledProps } from "types/type"

type ArticleInput = ShopArticleFragment | ShopArticlePageBoardItemFragment

export type ArticleDetailPanelInput = {
  /** article may change internally */
  initialArticle: ArticleInput
  onClose: () => void
  quantity?: number
  getQuantity?: (p: { articleID: string }) => number
  onClickCartButton?: () => void
  // openArticle?: (article: Article) => void
  ShareButton?: React.FunctionComponent<{ articleID: string } & StyledProps>
  CartButtons?: React.FunctionComponent<{ articleID: string } & StyledProps>
  CartItemQuantity?: React.FunctionComponent<
    { articleID: string } & StyledProps
  >
}

const Factory = contextAndProviderFactory({
  hookApi: useArticleDetailPanel,
})

function extractArticle(input: ArticleInput): ShopArticleFragment | null {
  if (input.__typename === "ShopArticlePageBoardItem") {
    return input.article || null
  }

  return input
}
function useArticleDetailPanel(input: ArticleDetailPanelInput) {
  const propsArticle = React.useMemo(
    () => extractArticle(input.initialArticle),
    [input.initialArticle.id]
  )

  const [article, setArticle] = useStateController({ value: propsArticle })

  const quantity = React.useMemo(() => {
    if (!article?.id || !input.getQuantity) return 0

    return input.getQuantity?.({ articleID: article.id }) || 0
  }, [article?.id, input.getQuantity])

  const variables: ShopArticleVariationsAttributesQueryVariables = {
    workspaceID: useActiveResources().workspaceID,
    articleID: article?.id || "",
  }

  const variationsQuery = useShopArticleVariationsAttributesQuery({
    variables,
    skip: Object.values(variables).some((v) => !v),
  })

  const [selectedVariation, setSelectedVariation] =
    React.useState<Variation | null>(null)

  const selectVariationArticle$ = React.useRef(
    new Subject<ArticleInput>()
  ).current

  const { articles: selectedVariationArticles, isLoading } =
    useSelectedVariationsArticles({
      selectedVariation,
      variationsQuery,
      articleID: article?.id || "",
    })

  const selectedVariationArticles$ = useObservable(
    (input$) =>
      input$.pipe(
        map((a) => a[0]),
        filter(excludeNullable)
      ),
    [selectedVariationArticles]
  )

  const selectedVariationHasMultipleArticles$ = React.useRef(
    selectedVariationArticles$.pipe(filter((articles) => articles.length > 1))
  ).current

  const selectedVariationHasSingleArticle$ = React.useRef(
    selectedVariationArticles$.pipe(
      filter((a) => a.length === 1),
      map((a) => a[0])
    )
  ).current

  const goToVariationArticle$ = React.useRef(
    merge(selectVariationArticle$, selectedVariationHasSingleArticle$)
  ).current

  useSubscription(
    goToVariationArticle$,
    React.useCallback((article: ArticleInput) => {
      setArticle(extractArticle(article))
      setSelectedVariation(null)
    }, [])
  )

  return useMemoAPI({
    ..._.omit(input, "article"),
    article,
    quantity,

    isLoading,
    setArticle: React.useCallback(
      (article: ArticleInput | null) => {
        if (!article) return
        setArticle(extractArticle(article))
      },
      [setArticle]
    ),
    // ----- variation api ------
    selectedVariation,
    goToVariationArticle$,
    setSelectedVariation,
    selectVariationArticle$,
    selectedVariationArticles$,
    selectedVariationHasSingleArticle$,
    selectedVariationHasMultipleArticles$,
    selectedVariationArticles,
    variations: React.useMemo(
      () => variationsQuery.data?.shopArticleVariationsAttributes || [],
      [variationsQuery.data?.shopArticleVariationsAttributes]
    ),
  })
}
export const ArticleDetailPanelContext = Factory.Context
export const ArticleDetailPanelProvider = Factory.Provider

function useSelectedVariationsArticles(props: {
  selectedVariation?: Variation | null
  articleID: string
  variationsQuery: QueryResult<
    ShopArticleVariationsAttributesQuery,
    Exact<{
      articleID: string
      workspaceID: string
    }>
  >
}) {
  const [isLoading, setIsLoading] = React.useState(false)

  const getVariationArticles = React.useCallback(
    (variation: Variation | null) => {
      if (!variation) return null

      return (
        props.variationsQuery.data?.shopArticleVariationsAttributes
          ?.find((a) => a.displayName === variation.attributeName)
          ?.variations?.find((v) => v.variationName === variation.variationName)
          ?.articles?.filter((a) => a.article?.id !== props.articleID) || null
      )
    },
    [props.variationsQuery, props.articleID]
  )
  const [articles, setArticles] = React.useState<
    ShopArticlePageBoardItemFragment[]
  >(getVariationArticles(props.selectedVariation || null) || [])

  const [selectMutation] = useSelectArticleVariationMutation()
  const workspaceID = useActiveResources().workspaceID

  useOnChange({
    value: JSON.stringify(props.selectedVariation),
    onChange: () => {
      if (!props.selectedVariation) return

      const articles = getVariationArticles(props.selectedVariation || null)

      if (articles?.length) {
        return setArticles(articles)
      }

      setIsLoading(true)

      selectMutation({
        variables: {
          workspaceID,
          articleID: props.articleID,
          variationName: props.selectedVariation?.variationName || "",
          attributeName: props.selectedVariation?.attributeName || "",
        },
      })
        .then((res) => {
          const fetchedArticles =
            res.data?.selectArticleVariation?.variationArticles

          if (!fetchedArticles?.length) return

          setArticles(fetchedArticles)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
  })

  return { articles, isLoading }
}

type Variation = {
  attributeName: ShopArticleVariationsAttributeFragment["displayName"]
  variationName: ShopArticleVariationFragment["variationName"]
}
