import React from "react"
import Input from "components/Input/Input"
import { Icon, InputAdornment, makeStyles } from "@material-ui/core"
import useIsMounted from "hooks/useIsMounted"
import SearchMessagesContext from "../../contexts/SearchMessagesContext"

export default function SearchInput() {
  const c = useStyles({})

  const { search, setSearch } = React.useContext(SearchMessagesContext)

  const inputRef = React.useRef<HTMLInputElement>()

  const isMounted = useIsMounted()

  React.useEffect(() => {
    if (!isMounted) return
    inputRef.current?.focus()
  }, [isMounted])

  return (
    <Input.text
      variant="outlined"
      onChange={(a) => setSearch(a.target.value)}
      inputProps={{
        className: c.input,
        ref: inputRef,
        value: search,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon fontSize="small" className={c.searchIcon}>
              search
            </Icon>
          </InputAdornment>
        ),
      }}
    ></Input.text>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    input: {
      padding: 10,
    },

    searchIcon: {
      opacity: 0.4,
    },
  }
})
