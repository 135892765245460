import { makeStyles } from "@material-ui/core"
import Hook from "components/Hook/Hook"
import { FormCommons } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/FormCommons/FormCommons"
import PartnerForm from "components/PageBoard/components/PartnerForm/PartnerForm"
import { ItemTemplate } from "components/PageBoard/components/IndexedItems/components/PageBoardItem/components/ItemTemplate/ItemTemplate"
import usePartner from "hooks/usePartner"
import { PartnerPageBoardItemFragment } from "lib/graphql/operations"
import { usePartnerFormMinimizedPage } from "./usePartnerFormMinimizedPage"

export function PartnerPageBoardItem(props: {
  entry: PartnerPageBoardItemFragment | null
  onClose: () => any
}) {
  const partnerID = props.entry?.itemID || ""

  const c = useStyles({})
  const data = usePartner({ id: partnerID })

  const partner = data.partner || undefined

  if (!partner) return null

  return (
    <ItemTemplate.Provider onClose={props.onClose}>
      <Hook hook={usePartnerFormMinimizedPage} hookProps={undefined}>
        {(api) => {
          return (
            <PartnerForm.Provider
              partnerID={partner.id}
              onClickContactChip={api.openContactPage}
            >
              <PartnerForm.Context.Consumer>
                {(partnerFormApi) => {
                  return (
                    <ItemTemplate.Context.Consumer>
                      {(templateApi) => {
                        return (
                          <FormCommons.Provider
                            twoPagesApi={templateApi.twoPagesAPI}
                            form={partnerFormApi.form as any}
                            deletion={{
                              isDeleted: !!partner.deleted,
                              toggleDeletion: partnerFormApi.toggleDeletion,
                            }}
                          >
                            <ItemTemplate.Root
                              className={c.root}
                              EnhancedPageProps={{
                                titleComponent: (
                                  <>
                                    <div></div>
                                    <FormCommons.ControlsButtons
                                      UploadDocumentsProps={{
                                        partnerID,
                                      }}
                                    ></FormCommons.ControlsButtons>
                                  </>
                                ),
                                body: (
                                  <FormCommons.Freezer>
                                    <PartnerForm.Root
                                      lastDocuments={
                                        <FormCommons.RelatedDocumentsChips
                                          openUploadPageArgs={{
                                            UploadDocumentsProps: {
                                              partnerID,
                                            },
                                          }}
                                          openAllDocumentsMiniPageArgs={{
                                            collectionId:
                                              data.partner?.collection?.id ||
                                              "",
                                          }}
                                        ></FormCommons.RelatedDocumentsChips>
                                      }
                                    ></PartnerForm.Root>
                                  </FormCommons.Freezer>
                                ),
                              }}
                            ></ItemTemplate.Root>
                          </FormCommons.Provider>
                        )
                      }}
                    </ItemTemplate.Context.Consumer>
                  )
                }}
              </PartnerForm.Context.Consumer>
            </PartnerForm.Provider>
          )
        }}
      </Hook>
    </ItemTemplate.Provider>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      "&>*": {
        height: "100%",
      },
    },
  }
})
