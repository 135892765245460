import useOnChange from "hooks/useOnChange"
import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import { CollectionItemFragment } from "lib/graphql/operations"

export default function useCollectionIDFormAPI(props: {
  setCollectionID: (id: string | null) => void
  collection?: CollectionItemFragment | null
}) {
  const [type, setType] = React.useState<string>(props.collection?.type || "")
  // const [type, setType] = React.useState<string>("VENDOR")

  useOnChange({
    value: props.collection?.type || null,
    onChange: (type) => {
      if (!type) return
      setType(type)
    },
  })

  const [collectionText, setCollectionText] = React.useState<string>(
    props.collection?.type || ""
  )

  const defaultView = "main"
  // const defaultView = "add-new-partner"

  const [viewID, setViewID] = React.useState<"main" | "add-new-partner">(
    defaultView
  )

  useOnChange({
    value: props.collection?.title || "",
    onChange: (title) => {
      if (!title) return
      setCollectionText(title)
    },
  })

  const resetForm = React.useCallback(() => {
    setCollectionText("")
    setType("")
    setViewID(defaultView)
  }, [])

  return useMemoAPI({
    type,
    setType,
    collectionText,
    setCollectionText,
    viewID,
    setViewID,
    resetForm,
  })
}
