import React from "react"
import ListItem from "./components/ListItem/ListeItem"
import { MODES } from "./constants"
import EditButton from "./components/EditButton/EditButton"
import CancelEditButton from "./components/CancelEditButton/CancelEditButton"
import IdlePresenceList from "./components/IdlePresenceList/IdlePresenceList"
import EditPresenceList from "./components/EditPresenceList/EditPresenceList"
import SearchMemberInput from "./components/SearchMemberInput/SearchMemberInput"

export default function modePros(props: { allowEdit: boolean }) {
  return {
    [MODES.idle]: {
      modeButton: props.allowEdit && <EditButton />,
      presenceList: <IdlePresenceList />,
      search: null,
    },
    [MODES.editing]: {
      modeButton: <CancelEditButton />,
      presenceList: <EditPresenceList />,
      search: <SearchMemberInput />,
    },
  }
}
