import { PageBoardItemType } from "lib/graphql/types"
import React from "react"
import {
  canTypeBeFetched,
  FetchedItem,
  FetchingParamsProps,
  useFetchPageBoardItemFromParams,
} from "../../../../hooks/useFetchPageBoardItemFromParams"
import { useFetchPageBoardItemFromItemID } from "./useFetchPageBoardItemFromItemID"

export type FetchProps<Type extends PageBoardItemType> = {
  type: Type
  params?: FetchingParamsProps<Type>
}

export function FetchPageBoardItem<
  Type extends PageBoardItemType,
  Item extends FetchedItem<Type>
>(props: {
  children: (props: { item: Item | null }) => React.ReactNode
  itemID?: string
  type?: FetchProps<Type>["type"]
  params?: FetchProps<Type>["params"]
}) {
  const { children, ...apiProps } = props

  const item = useFetchPageBoardItem<Type, Item>(apiProps)

  return <>{props.children({ item })}</>
}

export function useFetchPageBoardItem<
  Type extends PageBoardItemType,
  Item extends FetchedItem<Type>
>(props: {
  itemID?: string
  type?: FetchProps<Type>["type"]
  params?: FetchProps<Type>["params"]
  skip?: boolean
}) {
  const fetchItemFromParams = useFetchPageBoardItemFromParams()
  const fetchItemFromItemID = useFetchPageBoardItemFromItemID()

  const [item, setItem] = React.useState<Item | null>(null)

  React.useEffect(() => {
    // console.log(props, "popor")
    if (props.skip) return

    const fn = async () => {
      // debugger
      if (!props.type) return null

      if (props.params) {
        if (!canTypeBeFetched(props.type)) return null
        return fetchItemFromParams(props.type, props.params)
      }

      if (props.itemID) {
        return fetchItemFromItemID(props.type, props.itemID)
      }
    }

    fn().then((item) => setItem(item as any))
  }, [
    fetchItemFromParams,
    fetchItemFromItemID,
    props.type,
    props.itemID,
    Object.values(props.params || {}).join("-"),
    props.skip,
  ])

  return item
}
