import { copyToClipboard as copyToClipBoard } from "util/copyToClipboard"
import { useI18n } from "contexts/i18nContext/i18nContext"
import React from "react"
import { Snackbar } from "@material-ui/core"
import clsx from "clsx"
import HighestZIndexLayer from "components/HighestZIndexLayer/HighestZIndexLayer"
import useMemoAPI from "hooks/useMemoAPI"

export function useCopyToClipBoard() {
  const [open, setOpen] = React.useState<boolean>(false)

  const copyItem = React.useCallback((text: string) => {
    copyToClipBoard(text)

    setOpen(true)

    setTimeout(() => {
      setOpen(false)
    }, 2000)
  }, [])

  const t = useI18n()

  const feedbackComponent = React.useMemo(() => {
    return (
      <HighestZIndexLayer.Portal>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          className={clsx("left-snackbar")}
          open={open}
          message={t.copied}
        ></Snackbar>
      </HighestZIndexLayer.Portal>
    )
  }, [open, t.copied])

  return useMemoAPI({
    feedbackComponent: feedbackComponent,
    copyToClipBoard: copyItem,
  })
}
