import _ from "lodash"

export default function fragmentFields({ fragment, fragmentName = "" }) {
  const definitions = _.get(fragment, "definitions")

  if (!Array.isArray(definitions)) return

  let def = definitions[0]

  if (fragmentName)
    def = definitions.find(v => _.get(v, "name.value") === fragmentName)

  const selections = _.get(def, "selectionSet.selections")

  if (!selections) return

  return _.map(selections, "name.value")
}
