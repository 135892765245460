import React from "react"
import { makeStyles, Icon, IconButton } from "@material-ui/core"
import clsx from "clsx"
import stopPropagation from "util/stopPropagation"

export default function ActionsButtons({ user, goToPrivateRoom }) {
  const c = useStyles({})

  return (
    <div className={clsx("action-buttons", c.root)}>
      {user?.status === "OFFLINE" && (
        <IconButton onClick={stopPropagation(() => alert("open email"))}>
          <Icon>email</Icon>
        </IconButton>
      )}
      <IconButton onClick={stopPropagation(goToPrivateRoom)}>
        <Icon>chat</Icon>
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
    },
  }
})
