import LoadingPage from "components/LoadingPage/LoadingPage"
import useHomeWorkspace from "hooks/useHomeWorkspace"
import useShareable from "components/Shareable/hooks/useShareable"
import useAppRouter from "hooks/useAppRouter"
import React from "react"

export default function ShareableRedirector(props: {
  url: URL
  pathname404: string
}) {
  const { homeWorkspace } = useHomeWorkspace()

  const { workspaceURL, fetchingIsComplete } = useShareable({
    url: props.url,
    requesterWorkspaceID: homeWorkspace?.id,
  })

  const router = useAppRouter()

  React.useEffect(() => {
    if (!fetchingIsComplete) return

    if (!!workspaceURL?.href) {
      const path = workspaceURL.pathname + workspaceURL.search
      return router.history.replace(path)
    }

    return router.history.push(props.pathname404)
  }, [workspaceURL?.href, fetchingIsComplete])

  return <LoadingPage></LoadingPage>

  // return <h1>hey</h1>
}
