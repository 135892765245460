import { ID, RequireAtLeastOne, ShareableType } from "types/type"
import useActiveResources from "hooks/useActiveResources"
import useMemoAPI from "hooks/useMemoAPI"
import { useShareableQuery } from "lib/graphql/operations"
import React from "react"
import shareableSearchParams from "./shareableSearchParams"
import useShareableWorkspaceURL from "./useShareableWorkspaceURL"
import { WatchQueryFetchPolicy } from "@apollo/client"
import isProduction from "lib/isProduction"
import { ShareableVariables } from "../@types/index"
import useGetShareableLink from "./useGetShareableLink"

export type UseShareableProps = RequireAtLeastOne<{
  url: URL
  shareableVariables: ShareableVariables
}> & {
  requesterWorkspaceID?: ID
}

export default function useShareable(props: UseShareableProps) {
  const { workspaceID: activeWorkspaceID } = useActiveResources()
  const workspaceID = props.requesterWorkspaceID || activeWorkspaceID

  React.useEffect(() => {
    return () => {
      console.log("unloaded", props.url)
    }
  }, [])
  const shareableVariables = React.useMemo(() => {
    if (props.shareableVariables) return props.shareableVariables

    if (props.url) return shareableSearchParams.parse(props.url)

    return null
  }, [props.url?.href, props.shareableVariables])
  const shareableID = shareableVariables?.shareableID || ""

  const { data, loading, networkStatus } = useShareableQuery({
    skip: !workspaceID || !shareableVariables,
    variables: {
      shareableID,
      shareableType:
        shareableVariables?.shareableType || ShareableType.KanbanCard,
      sharerID: shareableVariables?.sharerID || "",
      workspaceID,
    },
    fetchPolicy: (() => {
      if (!shareableVariables) return "cache-only"

      const policy = isProduction
        ? shareableTypeFetchPolicy[shareableVariables.shareableType] ||
          "cache-first"
        : "cache-and-network"

      return policy
    })(),
  })

  const getUrl = useGetShareableLink()

  const shareableURL = React.useMemo(() => {
    if (props.url) return props.url

    if (shareableVariables) {
      const url = getUrl(shareableVariables)

      if (!url) return null

      return new URL(url)
    }

    return null
  }, [shareableVariables, props.url])

  const workspaceURL = useShareableWorkspaceURL({
    workspaceID: data?.shareable?.workspaceID || null,
    shareableURL,
  })

  return useMemoAPI({
    workspaceURL: data?.shareable ? workspaceURL : null,
    shareable: { ...data?.shareable, id: shareableID || "" },
    type: shareableVariables?.shareableType,
    fetchingIsComplete: networkStatus === 7,
    isLoading: loading,
  })
}

const shareableTypeFetchPolicy: {
  [key in ShareableType]?: WatchQueryFetchPolicy
} = {
  [ShareableType.KanbanCard]: "cache-and-network",
}
