import {
  HistoryThreadContext,
  HistoryThreadProvider,
} from "./HistoryThreadContext"
import { HistoryThreadTracker, HistoryTracker } from "./HistoryThreadTracker"

export { HistoryTracker }

/**
 ** We use HistoryThread to track the history of the selected item.
 ** It is used to determine whether the item is previously selected or not.
 */

export const HistoryThread = {
  Context: HistoryThreadContext,
  Provider: HistoryThreadProvider,
  Consumer: HistoryThreadContext.Consumer,
  Tracker: HistoryThreadTracker,
}
