import { HistoryThread } from "components/PageBoard/components/HistoryThread/HistoryThread"
import { getIcon } from "components/PageBoard/hooks/useTypesOptions"
import { excludeNullable } from "lib/excludeNullable"
import { NewPageBoardItemFragment } from "lib/graphql/operations"
import { NewPageBoardItem } from "lib/graphql/types"
import React from "react"
import { ArrayElement } from "types/type"
import { HeaderIconButton } from "../HeaderIconButton"

type ItemParams = ArrayElement<NewPageBoardItem["significativeRelations"]>

export function RelatedItemsIconButtons(props: {
  item: NewPageBoardItemFragment
  onClick?: (a: ItemParams) => void

  render?: (props: {
    defaultComponent: React.ReactNode
    itemParams: ItemParams
  }) => React.ReactNode
}) {
  const historyThreadApi = React.useContext(HistoryThread.Context)

  const relatedPageBoardItems = (props.item.significativeRelations || [])
    .filter((item) => !historyThreadApi.alreadyVisited(item))
    .map((itemParams) => {
      const icon = getIcon(itemParams.type)

      if (!icon) return null

      const defaultComponent = (
        <HeaderIconButton
          name={icon}
          onClick={() => props.onClick?.(itemParams)}
        ></HeaderIconButton>
      )

      if (props.render) return props.render({ defaultComponent, itemParams })

      return <>{defaultComponent}</>
    })
    .filter((a): a is React.ReactNode => !!a)
    .map((a, index) => <React.Fragment key={index}>{a}</React.Fragment>)

  return <>{relatedPageBoardItems}</>
}
