import clsx from "clsx"
import React from "react"
import { makeStyles } from "@material-ui/core"
import { fullHeight } from "styles/globalStyles"

export default function PageLayout(props: {
  className?: string
  topBar?: React.ReactNode
  body: React.ReactNode
  style?: React.CSSProperties
}) {
  const c = useStyles()

  return (
    <div
      className={clsx("page-layout", props.className, c.root)}
      style={props.style || {}}
    >
      {props.topBar || null}
      <div className={c.body}>{props.body}</div>
    </div>
  )
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      ...fullHeight,
    },
    body: {
      flexGrow: 1,
      overflow: "hidden",
      maxWidth: "100%",
      "&>*": {
        width: "100%",
      },
    },
  }
})
