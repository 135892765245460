import React, { ReactNode } from "react"
import EditControl from "components/EditControl"
import { DocumentFragment, PartnerFragment } from "lib/graphql/operations"

export enum DocumentStatusType {
  jobStatus = "job-status",
  workStatus = "work-status",
}

export type DocumentStatus = {
  type: DocumentStatusType
  value: string
}

export interface IDocumentFormData {
  document?: DocumentFragment
  fieldsMap?: FieldsMap
  partner?: PartnerFragment
  status: DocumentStatus
}

export type FieldsMap = {
  [key: string]: {
    getLabel: (value: any, key: string, object: Object) => ReactNode
    render: (value: any, key: string, object: Object) => ReactNode
  }
}

export interface IDocumentFormContext extends IDocumentFormData {
  EditControl: typeof EditControl
}

const DocumentFormContext = React.createContext<IDocumentFormContext>({
  EditControl,
  status: { type: DocumentStatusType.workStatus, value: "" },
})

DocumentFormContext.displayName = "DocumentFormContext"

export default DocumentFormContext
