import "moment/locale/de"
import ApolloSubscriptions from "components/GraphqlSubscriptions/GraphqlSubscriptions"
import "./draft.css"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core"
import { ID } from "types/type"
import { useRoomAPI } from "components/Room/hooks/useRoomAPI/useRoomAPI"
import { RoomContext } from "components/Room/contexts/room"
import RoomTemplate from "components/Room/components/RoomTemplate/RoomTemplate"
import RoomAppBar from "components/Room/components/RoomAppBar/RoomAppBar"
import { useWorkspaceQuery } from "lib/graphql/operations"
import WorkspaceIcon from "pages/Home/components/WorkspacesMenu/WorkspaceIcon"
import { WorkOffSharp } from "@material-ui/icons"
import GraphqlSubscriptions from "components/GraphqlSubscriptions/GraphqlSubscriptions"

export default function NotificationsDraft(props: {
  roomID: string
  workspaceID: ID
}) {
  const query = useWorkspaceQuery({
    variables: { workspaceID: props.workspaceID },
    fetchPolicy: "cache-first",
  })

  const workspace = query?.data?.workspace

  return (
    <GraphqlSubscriptions.Provider>
      <>
        <DefaultRoom roomID={props.roomID}></DefaultRoom>
        {!!workspace && (
          <WorkspaceIcon
            workspace={workspace}
            isActiveWorkspace={false}
          ></WorkspaceIcon>
        )}
      </>
    </GraphqlSubscriptions.Provider>
  )
}

function DefaultRoom(props: { className?: string; roomID: ID }) {
  const roomProviderValue = useRoomAPI({
    identifier: { roomID: String(props.roomID) },
  })

  const c = useStyles({})

  return (
    <div className={clsx(c.root, "room")}>
      <RoomContext.Provider value={roomProviderValue}>
        <RoomTemplate.Provider>
          <div className={c.body}>
            {/* <Messages /> */}
            {/* <h1>lero</h1> */}
            <RoomAppBar></RoomAppBar>
          </div>
        </RoomTemplate.Provider>
      </RoomContext.Provider>
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    body: {
      // // flex: 1,
      // height: "100%",
    },
    root: {
      // // height: "100vh",
      // height: "100vh",
      // // maxHeight: "100vh",
      // // overflow: "auto",
      // "&>*": {
      //   height: "100%",
      // },
    },
  }
})
