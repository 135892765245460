import _ from "lodash"
import React, { useState, useRef, useCallback, useEffect } from "react"
import arePropsEqual from "util/arePropsEqual"

const PWAInstallContext = React.createContext({
  promptInstall() {},
  showInstallButton: false,
})

PWAInstallContext.displayName = "PWAInstallContext"

function PWAInstallProvider({ children }: { children: React.ReactNode }) {
  const [isInstalled, setIsInstalled] = React.useState(
    (function isInstalled() {
      const isPWAInstalled = eval(localStorage.getItem("pwaInstalled") || "")

      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches

      return isPWAInstalled || isStandalone
    })()
  )

  const installable = "onbeforeinstallprompt" in window

  const deferredPrompt = React.useRef(null)

  const promptInstall = React.useCallback(() => {
    if (typeof _.get(deferredPrompt, "current.prompt") !== "function") return
    deferredPrompt.current?.prompt()
  }, [])

  useEffect(() => {
    if (!installable) return

    const beforeInstallPrompt = (e) => {
      localStorage.setItem("pwaInstalled", "")
      e.preventDefault()
      deferredPrompt.current = e
      window.deferredPrompt = e
    }

    window.addEventListener("beforeinstallprompt", beforeInstallPrompt)

    return () =>
      window.removeEventListener("beforeinstallprompt", beforeInstallPrompt)
  }, [])

  useEffect(() => {
    if (!installable) return

    const isAppInstalled = () => {
      localStorage.setItem("pwaInstalled", "true")
      setIsInstalled(true)
    }

    window.addEventListener("appinstalled", isAppInstalled)
    return () => {
      window.removeEventListener("appinstalled", isAppInstalled)
    }
  }, [])

  return (
    <PWAInstallContext.Provider
      value={{
        promptInstall,
        showInstallButton: !!installable && !isInstalled,
      }}
    >
      {children}
    </PWAInstallContext.Provider>
  )
}

const PWAInstallation = {
  Context: PWAInstallContext,
  Provider: PWAInstallProvider,
}

export default PWAInstallation
