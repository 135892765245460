import React from "react"
import { ImageViewerAPI, RangeAPI } from "../@types/index"

export default React.createContext<ImageViewerAPI>({
  viewerIsOpen: false,
  closeViewer: () => {},
  active: false,
  scale: {
    change() {},
    increase() {},
    step: 10,
    max: 100,
    min: 0,
    reduce() {},
    reset() {},
    value: 0,
  },
})
