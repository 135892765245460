import { useGesture } from "@use-gesture/react"
import clsx from "clsx"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import useWindowSize from "hooks/useWindowSize"
import React from "react"
import ImageViewerContext from "../../contexts/ImageViewerContext"
interface ImgProps {
  imageURL: string
  className?: string
  isAnimated?: boolean
  imageRef: React.RefObject<HTMLElement>
}

export default function Img(props: ImgProps) {
  if (props.isAnimated) {
    return (
      <AnimationStyles imageRef={props.imageRef}>
        {(style) => {
          return <ImageComponent style={style} {...props}></ImageComponent>
        }}
      </AnimationStyles>
    )
  }

  return (
    <StaticStyles imageURL={props.imageURL}>
      {(style) => {
        return <ImageComponent style={style} {...props}></ImageComponent>
      }}
    </StaticStyles>
  )
}

function StaticStyles(
  props: {
    children: (style: React.CSSProperties) => React.ReactElement
  } & Pick<ImgProps, "imageURL">
) {
  const staticStyles = useStaticOpenStyles(props.imageURL)
  return props.children(staticStyles)
}

function AnimationStyles(
  props: {
    children: (style: React.CSSProperties) => React.ReactElement
  } & Pick<ImgProps, "imageRef">
) {
  const style = useOpeningAnimationStyles(props.imageRef) || {}

  return props.children(style)
}

function ImageComponent(props: ImgProps & { style: React.CSSProperties }) {
  const { bind: bindGestures, styles: gestureStyles } = useGestureStyles()

  return (
    <img
      alt=""
      style={{
        ...gestureStyles,
        ...(props.style || {}),
        cursor: "pointer",
      }}
      className={clsx("brega", props.className)}
      src={props.imageURL}
      {...bindGestures()}
    />
  )
}

function useGestureStyles() {
  const { scale, active } = React.useContext(ImageViewerContext)
  const [delta, setDelta] = React.useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })

  const bind = useGesture({
    onWheel({ event, direction: [_, dy] }) {
      if (dy < 0) return scale.increase(2)
      return scale.reduce(2)
    },
    onDrag({ event, down, tap, offset: [mx, my] }) {
      event.preventDefault()

      setDelta({
        x: mx,
        y: my,
      })
    },
  })

  return {
    bind,
    styles: active
      ? {
          transform: `translate3d(${delta.x}px, ${delta.y}px, 0) scale(${scale.value})`,
        }
      : {},
  }
}

function useOpeningAnimationStyles(imageRef: React.RefObject<HTMLElement>) {
  const { animation } = React.useContext(ImageViewerContext)

  const animationIsInProgress = animation?.status !== "exited"

  const rect = imageRef.current?.getBoundingClientRect()

  const getFinalImgRect = useGetFinalImgRect({
    imageOriginalDimensions: { height: rect?.height, width: rect?.width },
  })

  if (!animationIsInProgress) return null

  const animationStyles = getOpeningAnimationStyles({
    duration: animation?.duration || 0,
    initialRect: rect || null,
    finalRect: getFinalImgRect(),
  })

  if (animation?.status === "final") return animationStyles?.final
  if (animation?.status === "initial") return animationStyles?.initial

  return null
}

function getOpeningAnimationStyles(props: {
  duration: number
  initialRect: DOMRect | null
  finalRect: DOMRect | null
}) {
  if (!props.finalRect || !props.initialRect) return

  const transition = ["left", "top", "width", "height"]
    .map((p) => `${p} ${props.duration}ms`)
    .join(", ")

  const initial = getStyle(props.initialRect)
  const final = getStyle(props.finalRect)

  return {
    initial: { ...initial, transition },
    final: { ...final, transition },
  }
}

function useGetFinalImgRect(props: {
  imageOriginalDimensions: { height?: number; width?: number }
}) {
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  const { isSmallMobile } = React.useContext(DeviceObserver.Context)

  return React.useCallback(() => {
    if (
      !props.imageOriginalDimensions.height ||
      !props.imageOriginalDimensions.width
    )
      return null

    const ratio =
      props.imageOriginalDimensions.height / props.imageOriginalDimensions.width

    const maxWidth = ((isSmallMobile && 0.9) || 0.4) * windowWidth
    const maxHeight = ((isSmallMobile && 0.9) || 0.6) * windowHeight

    const newWidth = Math.min(maxWidth, maxHeight / ratio) //TODO: create explanation for the use of Math.min
    const newHeight = newWidth * ratio

    return DOMRect.fromRect({
      x: (windowWidth - newWidth) / 2 || 0,
      y: (windowHeight - newHeight) / 2 || 0,
      height: newHeight || 0,
      width: newWidth || 0,
    })
  }, [
    props.imageOriginalDimensions.height,
    props.imageOriginalDimensions.width,
    isSmallMobile,
    windowHeight,
    windowWidth,
  ])
}

function useStaticOpenStyles(imageURL: string) {
  const [dimensions, setDimension] = React.useState<{
    width: number
    height: number
  } | null>(null)
  const getFinalImgRect = useGetFinalImgRect({
    imageOriginalDimensions: dimensions || {},
  })

  const style = React.useMemo(() => {
    const finalRect = getFinalImgRect()
    return finalRect ? getStyle(finalRect) : {}
  }, [getFinalImgRect, dimensions?.height, dimensions?.width])

  React.useEffect(() => {
    getImageDimensions(imageURL).then(({ height, width }) => {
      setDimension({ height, width })
    })
  }, [imageURL])

  return style
}

const getStyle = (p: DOMRect) => {
  return {
    position: "fixed",
    left: p.left,
    top: p.top,
    bottom: p.bottom,
    right: p.right,
    height: p.height,
    width: p.width,
  }
}

async function getImageDimensions(
  imageURL: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const image = new Image()

    image.onload = () => {
      resolve({ height: image.height, width: image.width })
    }

    image.onerror = reject

    image.src = imageURL
  })
}
