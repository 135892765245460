import { ApolloError } from "@apollo/client"
import UserPageBoardItemProvider from "./components/UserPageBoardItemProvider/UserPageBoardItemProvider"
import UserPageBoardItemRoot from "./components/UserPageBoardItemRoot/UserPageBoardItemRoot"
import { NewPageBoardItem_UserPageBoardItem_Fragment } from "lib/graphql/operations"

const UserPageBoardItem = {
  Default: UserPageBoardItemDefault,
  Provider: UserPageBoardItemProvider,
  Root: UserPageBoardItemRoot,
}

export default UserPageBoardItem

function UserPageBoardItemDefault(props: {
  entry: NewPageBoardItem_UserPageBoardItem_Fragment
  onClose: () => any
  onError: (error: ApolloError) => any
}) {
  return (
    <UserPageBoardItemProvider entry={props.entry} onClose={props.onClose}>
      <UserPageBoardItemRoot></UserPageBoardItemRoot>
    </UserPageBoardItemProvider>
  )
}
