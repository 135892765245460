import {
  makeStyles,
  IconButton,
  Icon,
  IconButtonProps,
} from "@material-ui/core"
import MembersContext from "components/Room/components/Members/contexts/MembersContext"
import useManageMembers from "components/Room/hooks/useManageMembers/useManageMembers"
import { RoomMemberFragment } from "lib/graphql/operations"
import React from "react"

export default function InclusionButton(props: {
  member: RoomMemberFragment
  added: boolean
  edge?: IconButtonProps["edge"]
}) {
  const { member: member, added = false, edge = "end" } = props

  const { addMember, removeMember } = React.useContext(MembersContext)

  const iconButton = React.useMemo(
    () => ({
      added: { iconName: "close", action: () => removeMember(member) },
      "not-added": { iconName: "add", action: () => addMember(member) },
    }),
    [removeMember, addMember]
  )

  const { iconName, action } = iconButton[added ? "added" : "not-added"]

  return (
    <IconButton size="small" color="primary" onClick={action} edge={edge}>
      <Icon>{iconName}</Icon>
    </IconButton>
  )
}
