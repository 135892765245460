import useCurrentWorkspace from "hooks/useCurrentWorkspace/useCurrentWorkspace"
import React from "react"
import useMemoAPI from "hooks/useMemoAPI"
import {
  useUpdateWorkspaceAudioNotificationsMutation,
  WorkspaceSettingsFragmentDoc,
} from "lib/graphql/operations"
import cachedIDGetters from "lib/getCacheID/getCacheID"
import { WorkspaceSettings } from "lib/graphql/types"
import useIsRoomMuted from "./useIsRoomMuted"

import Workspaces from "components/Workspaces/Workspaces"

export default function useMuteAPI() {
  const { getWorkspace } = React.useContext(Workspaces.Context)

  const isWorkspaceMuted = React.useCallback(
    (props: { workspaceID: string }) => {
      const workspaceSettings = getWorkspace({
        workspaceID: props.workspaceID,
      })?.settings

      return !!workspaceSettings?.audioNotificationsIsOff
    },
    [getWorkspace]
  )

  const [mutate] = useUpdateWorkspaceAudioNotificationsMutation()

  const toggleWorkspaceMute = React.useCallback(
    async (props: { workspaceID: string }) => {
      const workspaceID = props.workspaceID

      if (!workspaceID) return

      const newValueIsOff = !isWorkspaceMuted({ workspaceID })

      await mutate({
        variables: { workspaceID, isOff: newValueIsOff },
        optimisticResponse: {
          __typename: "Mutation",
          updateWorkspaceAudioNotifications: {
            success: true,
            __typename: "BasicResponse",
          },
        },
        update(proxy) {
          proxy.writeFragment<WorkspaceSettings>({
            fragment: WorkspaceSettingsFragmentDoc,
            id: cachedIDGetters.WorkspaceSettings({ workspaceID }),
            data: {
              __typename: "WorkspaceSettings",
              workspaceID,
              audioNotificationsIsOff: newValueIsOff,
            },
          })
        },
      })
      return
    },
    [isWorkspaceMuted, mutate]
  )

  const isRoomMuted = useIsRoomMuted()

  return useMemoAPI({
    isWorkspaceMuted,
    isRoomMuted,
    toggleWorkspaceMute,
  })
}
