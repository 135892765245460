import { Slider, makeStyles } from "@material-ui/core"
import React from "react"
import ImageViewerContext from "../../../../contexts/ImageViewerContext"

export default function ZoomSlider() {
  const api = React.useContext(ImageViewerContext)
  const c = useStyles()

  return (
    <Slider
      max={api.scale.max}
      min={api.scale.min}
      value={api.scale.value}
      onChange={(_, value) => {
        api.scale.change(value as number)
      }}
      step={api.scale.step}
      className={c.root}
      classes={{ track: c.track, rail: c.rail, thumb: c.thumb }}
    ></Slider>
  )
}

const useStyles = makeStyles((theme) => {
  const thick = 6
  return {
    root: {
      height: thick * 2,
    },
    rail: {
      height: thick,
    },
    track: {
      height: thick,
    },
    thumb: {
      marginTop: (-1 * thick) / 2,
    },
  }
})
