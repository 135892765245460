import _ from "lodash"
import React from "react"
import { Avatar as MuiAvatar, makeStyles, Badge } from "@material-ui/core"
import initials from "pages/util/initials"
import clsx from "clsx"
import PurpleBadge, {
  PurpleBadgeProps,
} from "components/PurpleBadge/PurpleBadge"

export default function Avatar(props: {
  backgroundColor?: string | null
  content?: React.ReactNode
  name?: string
  className?: string
  showInitials?: boolean
  src?: string
  style?: React.CSSProperties
  BadgeProps?: PurpleBadgeProps
}) {
  const { showInitials = true, src = "", style = {} } = props

  const description = props.content || props.name
  const c = useStyles({ backgroundColor: props.backgroundColor || "" })

  const content = (() => {
    if (showInitials && description && _.isString(description))
      return initials(description)

    const typeOfDescription = typeof description

    return typeOfDescription === "string" ||
      typeOfDescription === "number" ||
      React.isValidElement(description)
      ? description
      : null
  })()

  const avatar = (
    <MuiAvatar
      alt={props.name}
      className={clsx("avatar", c.avatar, props.className)}
      src={src}
      style={style}
    >
      {content}
    </MuiAvatar>
  )

  if (props.BadgeProps) {
    return <PurpleBadge {...props.BadgeProps}>{avatar}</PurpleBadge>
  }

  return avatar
}

const useStyles = makeStyles((theme) => {
  return {
    avatar: {
      backgroundColor: (props: { backgroundColor?: string }) =>
        props.backgroundColor || theme.palette.primary.main,
    },
  }
})
