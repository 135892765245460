import React from "react"
import useAppIsVisible from "../../hooks/useAppIsVisible"

const AppVisibilityContext = React.createContext(
  {} as ReturnType<typeof useAppIsVisible>
)

AppVisibilityContext.displayName = "AppVisibilityContext"

const AppVisibility = {
  Context: AppVisibilityContext,

  Provider: function AppVisibilityProvider(props: {
    children: React.ReactNode
  }) {
    const api = useAppIsVisible()

    return (
      <AppVisibilityContext.Provider value={api}>
        {props.children}
      </AppVisibilityContext.Provider>
    )
  },
}

export default AppVisibility
