import _ from "lodash"
import React, { useContext, useMemo } from "react"
import useDetailedContact from "../hooks/useDetailedContact/useDetailedContact"
import useOnChange from "../hooks/useOnChange"
import useCopyToWindow from "hooks/useCopyToWindow/useCopyToWindow"

export const DetailedContactContext = React.createContext({
  detailedContact: null,
  isLoading: false,
})

DetailedContactContext.displayName = "DetailedContactContext"

// export DetailedContactContext

export default function DetailedContactProvider({
  collectionID,
  fetchPolicy,
  children,
  contactID,
  onChangeLoadingState,
}) {
  const values = useDetailedContact({
    collectionID,
    id: contactID,
    fetchPolicy: "cache-and-network",
  })

  useOnChange({ value: values.isLoading, onChange: onChangeLoadingState })
  useCopyToWindow("detailedContact", _.get(values, "detailedContact"))

  const value = useMemo(
    () => _.pick(values, ["detailedContact", "isLoading"]),
    [JSON.stringify(values)]
  )

  return (
    <DetailedContactContext.Provider value={value}>
      {children}
    </DetailedContactContext.Provider>
  )
}

//REMOVE
export function useDetailedContactContext() {
  return useContext(DetailedContactContext)
}
