import { makeStyles, Theme } from "@material-ui/core"
import { DefaultTheme, ThemeOfStyles } from "@material-ui/styles"
import React from "react"

export const clipText: Pick<
  React.CSSProperties,
  "overflow" | "whiteSpace" | "textOverflow"
> = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}

export const fullHeight = (() => {
  //checking if it supports custom properties
  if (window.CSS && window.CSS.supports("color", "var(--primary)")) {
    //property '--vh' is defined in index.html
    return { height: "calc(var(--vh, 1vh) * 100)", maxHeight: "100%" }
  }

  return { height: "100vh" }
})()

export const flexColumn = {
  display: "flex",
  flexDirection: "column",
}

export const centerBackgroundImage = {
  backgroundSize: "cover",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat no-repeat",
}

export const displayNone = {
  display: "none",
}

export const displayBlock = {
  display: "block",
}

export const textAlignCenter = {
  textAlign: "center",
}

export const selectionBorder = (theme: Theme) => ({
  border: "2px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "4px",
})

export const height100Percent: Pick<React.CSSProperties, "height"> = {
  height: "100%",
}

export const highlightBorder = "1px solid lightgray"

export const appBarPadding = (theme: Theme) => {
  const p = theme.spacing(alignmentUnits)

  return {
    paddingLeft: p,
    paddingRight: p,
  }
}

export const appBarMarginAlignment = (theme: Theme) => {
  const p = theme.spacing(alignmentUnits)

  const a: Pick<React.CSSProperties, "marginLeft" | "marginRight"> = {
    marginLeft: p,
    marginRight: p,
  }

  return a
}

export const alignmentUnits = 2

const useGlobalStyles = makeStyles((theme) => ({
  centerBackgroundImage,
  clipText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  height100Percent: {
    height: "100%",
  },
  displayNone: {
    display: "none",
  },
  displayBlock: {
    display: "block",
  },
  appBarPadding: appBarPadding(theme),
  bold: {
    fontWeight: 700,
  },
  appBarMarginAlignment: appBarMarginAlignment(theme),
  input: {
    width: "100%",
  },
}))

export default useGlobalStyles
