import clsx from "clsx"
import React from "react"
import { alignmentUnits } from "styles/globalStyles"
import { Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"
import IconButton from "components/IconButton/IconButton"
import FrameWithLoadingProgress from "components/FrameWithLoadingProgress/FrameWithLoadingProgress"

export default function BasicDialog(props: {
  open: boolean
  isLoading: boolean
  onClose?: () => void
  showCloseIcon?: boolean
  classes?: { paper?: string; content?: string }
  className?: string
  children: React.ReactNode
}) {
  const {
    open = true,
    isLoading = false,
    onClose = () => null,
    showCloseIcon = true,
    classes: { paper: paperClassName = "" } = {},
    className = "",
    children,
  } = props

  const { isSmallMobile } = React.useContext(DeviceObserver.Context)
  const c = useStyles({ isSmallMobile })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={clsx(c.basicDialog, className)}
      classes={{ paper: clsx(c.paper, paperClassName) }}
    >
      <FrameWithLoadingProgress isLoading={isLoading}>
        <div className={clsx(c.content, props.classes?.content)}>
          {showCloseIcon && (
            <IconButton
              className={c.iconButton}
              iconName="close"
              onClick={onClose}
              size="small"
              edge="end"
            />
          )}
          {children}
        </div>
      </FrameWithLoadingProgress>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => {
  const padding = theme.spacing(alignmentUnits)

  return {
    basicDialog: {},
    dialog: {},
    content: {
      padding,
    },
    paper: {
      width: (props) => (props.isSmallMobile ? 300 : 420),
      maxHeight: "95vh",
      position: "relative",
    },

    iconButton: {
      position: "absolute",
      top: padding,
      right: padding,
    },
  }
})
