import useMemoAPI from "hooks/useMemoAPI"
import useRootContainerAPI, {
  RootContainerAPI,
} from "hooks/useRootContainerAPI"
import { ChainProps } from "../components/IndexedItems/components/ItemsList/components/ListItemEntry/components/Chain/Chain"

//TODO: gradually, move to this hook all template related variables.
export default function useTemplate() {
  const rootContainer = useRootContainerAPI({
    breakingPoints: { isMedium: 620 },
  })

  return useMemoAPI({
    rootContainer,
  })
}
