import React from "react"
import useAudioNotificationsControl from "./hooks/useAudioNotificationsControl"
import { AudibleNotificationToggleButton } from "./components/AudibleNotificationToggleButton/AudibleNotificationToggleButton"

type AudibleNotificationsAPI = ReturnType<typeof useAudioNotificationsControl>

export const AudibleNotificationsContext = React.createContext(
  {} as AudibleNotificationsAPI
)

AudibleNotificationsContext.displayName = "AudibleNotificationToggleButton"

const AudibleNotifications = {
  ToggleButton: AudibleNotificationToggleButton,
  Context: AudibleNotificationsContext,
  Audio: function NotificationsAudio() {
    const { audioElementRef } = React.useContext(AudibleNotificationsContext)
    return <audio src="/audio/notification.mp3" ref={audioElementRef}></audio>
  },
  Provider(props: { children: React.ReactNode }) {
    const audioAPI = useAudioNotificationsControl()

    return (
      <AudibleNotificationsContext.Provider value={audioAPI}>
        {props.children}
      </AudibleNotificationsContext.Provider>
    )
  },
}

export default AudibleNotifications
