import _ from "lodash"
import React from "react"

export default function useThrottledValue<T = any>(
  value: T,
  timeoutInMs = 300,
  diffFn?: (a: T) => any
) {
  const [v, set] = React.useState<T>(value)
  const throttledSet = React.useCallback(_.throttle(set, timeoutInMs), [])

  React.useEffect(() => {
    throttledSet(value)
  }, [diffFn ? diffFn(value) : value])

  return v
}
