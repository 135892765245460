import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { Subject } from "rxjs"
import { useCommentSelectionUI } from "./useCommentSelectionUI"
import { EmojiReaction } from "./useKanbanComments"

export default function useEmojiPickerModal() {
  const selectEmoji$ = React.useRef(new Subject<EmojiReaction>()).current

  const commentModalAPI = useCommentSelectionUI()

  const comment = commentModalAPI.selectedComment

  const select = React.useCallback(
    (emojiUnicode: string) => {
      if (!comment) return
      selectEmoji$.next({ emojiUnicode: emojiUnicode, comment })
    },
    [comment]
  )

  return useMemoAPI({
    popoverRef: commentModalAPI.popoverAnchorRef,
    close: commentModalAPI.close,
    open: commentModalAPI.open,
    isOpen: commentModalAPI.isOpen,
    select,
    selectEmoji$,
  })
}
