import defaultTranslations from "contexts/i18nContext/defaultTranslations"
import _ from "lodash"
import contentStateFromString from "util/draftJS/contentStateFromString"
import firstNonEmptyParagraphString from "util/draftJS/firstNonEmptyParagraphString"
import getter from "util/getter"
import { RawDraftContentState, ContentState } from "draft-js"

export default function getStringFromMessageContent({
  message,
  translations = defaultTranslations,
  maxAmountOfParagraphs = null,
}) {
  const messageType = (message || {}).__typename
  const getString = getMessageContent[messageType] || (() => "")

  return getString({ message, translations, maxAmountOfParagraphs })
}

const getMessageContent = {
  TextMessage({ message, maxAmountOfParagraphs }) {
    const g = getter(message)

    const string = g("content")

    const contentState = contentStateFromString(string)
    const text = contentState
      ? // ? firstNonEmptyParagraphString(contentState)
        // firstNonEmptyParagraphString(contentState)
        (() => {
          const ps = getParagraphsStrings(contentState)

          return ps
            .slice(
              0,
              maxAmountOfParagraphs ? maxAmountOfParagraphs - 1 : ps.length
            )
            .join("\n")
        })()
      : string

    return text
  },

  UserInclusionMessage({ message, translations }) {
    const g = getter(message)
    const userName = g("userInclusionContent.user.shortName")

    const getString = {
      REMOVAL: () => translations.userWasRemoved({ userName }),
      ADDITION: () => translations.userWasAdded({ userName }),
    }

    const inclusionType = g("userInclusionContent.inclusionType")

    const string = getString[inclusionType] || (() => "")

    return string()
  },
}

export function getParagraphsStrings(contentState: ContentState) {
  const paragraphs = contentState
    .getBlocksAsArray()
    .filter(Boolean)
    .map((a) => a.getText())
  // .find((c) => !!c.getText())
  // if (!blockWithText) return ""
  // return blockWithText.getText()
  // return blockWithText.getText()

  return paragraphs.length ? paragraphs : null
}
