import _ from "lodash"
import { useInputController } from "./useInputController"
import useMemoAPI from "./useMemoAPI"

/**
 * It enables two-way data-binding
 * IMPORTANT: Don't pass objects to $value containing circular references!
 * */
export default function useStateController<Value>(props: {
  onChange?: (value: Value) => any
  value: Value
}) {
  const { inputValue, setInputValue } = useInputController(props)

  const tuple: [Value, typeof setInputValue] = [inputValue, setInputValue]

  return useMemoAPI(tuple)
}
