import { makeStyles } from "@material-ui/core"
import { AudioAnimationIcon } from "components/Zoom/components/audio-animation-icon"
import React from "react"
import { ConferenceContext } from "../../../../ConferenceContext"
import { FabControlButton } from "../FabControlButton/FabControlButton"
import { useI18n } from "contexts/i18nContext/i18nContext"

export function MicButton() {
  const control = React.useContext(ConferenceContext).audio

  const { isAudioStarted, isMuted, startAudio, muteAudio, unmuteAudio } =
    control

  const handleMicClick = async () => {
    if (!isAudioStarted) {
      await startAudio()
    } else if (isMuted) {
      await unmuteAudio()
    } else {
      await muteAudio()
    }
  }

  const audioIsActive = isAudioStarted && !isMuted

  const t = useI18n()

  const tooltipText = isAudioStarted
    ? isMuted
      ? t.conference.unmuteMic
      : t.conference.muteMic
    : t.conference.activateMic

  const icon = isAudioStarted ? (isMuted ? "mic_off" : "mic") : "mic_off"

  const c = useStyles()

  return (
    <FabControlButton
      toolTipTitle={tooltipText}
      icon={icon}
      renderIcon={(props: { defaultComponent: React.ReactNode }) =>
        audioIsActive ? (
          <AudioAnimationIcon
            level={control.audioLevel || 1}
            className={c.audioAnimation}
          />
        ) : (
          props.defaultComponent
        )
      }
      onClick={handleMicClick}
    />
  )
}

const useStyles = makeStyles((them) => {
  const d = "1.7em"
  return {
    audioAnimation: {
      "& svg": {
        height: d,
        width: d,
        position: "relative",
      },
    },
  }
})
