import _ from "lodash"
import React from "react"

import { makeStyles } from "@material-ui/styles"
import { Icon } from "@material-ui/core"
import clsx from "clsx"
import withMemo from "../../hocs/withMemo"

const icons = {
  bilateralID: "link",
  deleted: "delete"
}

function CollectionItemStatus({ collection, className, children }) {
  const c = useStyles()

  const listOfIcons = _.keys(icons).map(collectionKey => {
    const iconName = icons[collectionKey]
    const showIcon = collection[collectionKey] && iconName

    return (
      showIcon && (
        <Icon key={iconName} className={c.icon} fontSize="small">
          {iconName}
        </Icon>
      )
    )
  })

  const status = <div className={c.status}>{listOfIcons}</div>

  return (
    <div className={clsx(c.collectionItemStatusWrapper, className)}>
      {_.isFunction(children) ? children({ components: { status } }) : status}
    </div>
  )
}

export default withMemo(CollectionItemStatus, "collection")

const useStyles = makeStyles(theme => {
  return {
    icon: {
      color: theme.palette.text.secondary
    },
    collectionItemStatusWrapper: {
      display: "flex",
      alignItems: "center",
      "&>*:last-child:not(:first-child)": {
        marginLeft: theme.spacing(1)
      }
    },
    status: {
      display: "flex",
      alignItems: "center",
      "&>*:not(:last-child)": {
        marginRight: theme.spacing(0.5)
      }
    }
  }
})
