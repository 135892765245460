import { FieldsetIndentationProps } from "components/Page/components/PageForm/components/FieldsetIndentation/FieldsetIndentation"
import { getIcon } from "components/PageBoard/hooks/useTypesOptions"
import { PageBoardItemType } from "lib/graphql/types"
import { DocumentsChips } from "../components/OldDocumentsChips"
import I18nContext from "contexts/i18nContext/i18nContext"
import { FormCommonsContext } from "../components/FormCommonsContext"

export function getLastDocumentsFieldProps(
  props:
    | {
        lastDocuments: React.ReactNode
      }
    | {
        collectionId: string
      }
) {
  const fieldProps: FieldsetIndentationProps = {
    icon: getIcon(PageBoardItemType.Document),
    title: (
      <I18nContext.Consumer>{(api) => api.lastDocuments}</I18nContext.Consumer>
    ),
    children: (() => {
      if ("lastDocuments" in props) return props.lastDocuments

      return (
        <FormCommonsContext.Consumer>
          {(api) => {
            return (
              <DocumentsChips
                collectionId={props.collectionId}
                onClickDocumentChip={api.openDocumentWithDocumentID}
                onClickOpenAllDocuments={() =>
                  api.openAllDocumentsMiniPage({
                    collectionId: props.collectionId,
                  })
                }
              ></DocumentsChips>
            )
          }}
        </FormCommonsContext.Consumer>
      )
    })(),
  }

  return fieldProps
}
