import clsx from "clsx"
import { TextMessagePreview } from "components/Room/components/MessagePreview/TextMessagePreview"
import MessagesContext from "components/Room/components/Messages/contexts/MessagesContext"
import { getPreview } from "components/Room/lib/getPreview"
import StopPropagation from "components/StopPropagation/StopPropagation"
import { RepliedMessageListItemFragment } from "lib/graphql/operations"
import requestFrame from "lib/requestFrame"
import React from "react"
// import TextMessagePreview from "components/Room/components/MessagePreview/TextMessagePreview"

export function RepliedMessage(props: {
  className: string
  repliedMessageListItem: RepliedMessageListItemFragment
}) {
  const { listRenderer } = React.useContext(MessagesContext)

  const preview = getPreview({ message: props.repliedMessageListItem.message })[
    props.repliedMessageListItem.message.__typename
  ]

  if (!preview) return null

  return (
    <div
      className={clsx("replied-message", props.className)}
      onClick={(e) => {
        e.stopPropagation()

        requestFrame(() => {
          listRenderer.scrolling.scrollToItem({
            index: props.repliedMessageListItem.index,
          })
        })
      }}
    >
      {preview}
    </div>
  )
}
