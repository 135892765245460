import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import { Scrollbars } from "react-custom-scrollbars"
import Header from "./components/Header/Header"

export { default as PageTextField } from "../TextField/TextField"
export { default as Button } from "./components/Button/Button"
export { PageForm as PageForm } from "./components/PageForm/PageForm"
export { default as PageIndentation } from "./components/Indentation/Indentation"
export { default as SecondPageAPI } from "./components/SecondPageAPI/SecondPageAPI"
export { default as useSecondPage } from "./hooks/useSecondPage"
export type { PageComponent, PageComponentProps } from "./hooks/useSecondPage"

export type PageProps = {
  HeaderProps?: React.ComponentProps<typeof Header> | null
  body: React.ReactNode
  className?: string
  classes?: { body: string }
  style?: React.CSSProperties
  header?: React.ReactNode
  BodyProps?: {
    style?: React.CSSProperties
    className?: string
    classes?: { bodyWrapper?: string }
  }
}

export default function Page(props: PageProps) {
  const c = useStyles()

  const page = (
    <div
      className={clsx("modal-page", c.root, props.className)}
      style={props.style}
    >
      {React.isValidElement(props.header) ? (
        props.header
      ) : (
        <Header
          {...props.HeaderProps}
          className={clsx(props.HeaderProps?.className)}
        ></Header>
      )}
      <Scrollbars className={clsx("body-wrapper", c.bodyWrapper)}>
        <div
          className={clsx(
            props.classes?.body,
            c.body,
            props.BodyProps?.className
          )}
          style={props.BodyProps?.style || {}}
        >
          {props.body}
        </div>
      </Scrollbars>
    </div>
  )

  return page
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",

      "&>.body-wrapper": {
        flex: 1,
        overflow: "hidden",
      },
    },
    bodyWrapper: {
      "&>*": {
        // width: "100%",
      },
    },
    body: {
      height: "100%",
      "&, &>*": {
        // height: "100%",
      },
      "&>*": {
        height: "100%",
      },
    },
  }
})
