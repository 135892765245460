import PageBoard, { PageBoardProps } from "components/PageBoard/PageBoard"
// import { BoardApiInput } from "components/PageBoard/components/IndexedItems/types"
import useAppRouter from "hooks/useAppRouter"
import useMemoAPI from "hooks/useMemoAPI"
import { StyledProps } from "types/type"
import { useBrowserItemParams } from "./hooks/useBrowserItemParams"
import { useCachedFilter } from "./hooks/useCachedFilter"
import { ViewID, ViewProps } from "components/PageBoard/hooks/useViews"
import { useWorkspaceCache } from "../../../../hooks/useWorkspaceCache"
// import { View } from '../../../../components/Views/Views';

export function StartPageBoard(props: StyledProps) {
  const router = useAppRouter()
  const { params, push } = useBrowserItemParams({ router })
  const cachedFilter = useCachedFilter()

  const apiProps = useMemoAPI<PageBoardProps["IndexedItemsApiProps"]>({
    params,
    filter: cachedFilter.filter || undefined,
    onChangeFilter: cachedFilter.setFilter,
    onChangeParams: push,
  })

  const a = useWorkspaceCache<ViewID | null | undefined>({
    key: "pageBoardView",
  })

  const view = useMemoAPI<ViewProps>({
    selectedID: a.value || "list",
    setSelectedID: (id) => {
      a.setValue(id)
    },
  })

  return (
    <PageBoard
      IndexedItemsApiProps={apiProps}
      view={view}
      RootProps={{ style: props.style, className: props.className }}
    ></PageBoard>
  )
}
