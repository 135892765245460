import useActiveResources from "hooks/useActiveResources"
import { LiveQueryInput, useLiveQuery } from "hooks/useLiveQuery"
import useMemoAPI from "hooks/useMemoAPI"
import useOpenClose from "hooks/useOpenClose"
import {
  KanbanCardQueryVariables,
  useDeleteKanbanCardMutation,
  useKanbanCardQuery,
} from "lib/graphql/operations"
import React from "react"
import { Typename } from "types/type"
import { KanbanCardSubscriptionDocument } from "lib/graphql/operations"
import updateFragment from "lib/updateFragment/updateFragment"
import useCardForm from "./useCardForm"
import useChangeKanbanCard from "./useChangeKanbanCard"
import { ItemTemplate } from "../../ItemTemplate/ItemTemplate"

export default function useCard(props: { cardID: string }) {
  const r = useActiveResources()

  const variables: KanbanCardQueryVariables = {
    cardID: props.cardID,
    workspaceID: r.workspaceID,
  }

  type Input = LiveQueryInput<typeof useKanbanCardQuery>

  const options = React.useMemo<Input["options"]>(() => {
    return {
      variables,
      fetchPolicy: "cache-and-network",
    }
  }, [...Object.values(variables)])

  const query = useLiveQuery({
    useQuery: useKanbanCardQuery,
    options,
    subscriptionDocument: KanbanCardSubscriptionDocument,
  })

  const cardData = query.data?.kanbanCard

  const form = useCardForm({ card: cardData })

  const description = useDescription({
    cardForm: form,
    cardID: cardData?.id,
  })

  return useMemoAPI({
    data: cardData,
    form,
    description: description,
    deleteModal: useDeleteModal({ cardID: cardData?.id }),
  })
}

function useDeleteModal(props: { cardID: string | undefined }) {
  const modalAPI = useOpenClose()

  const [mutate] = useDeleteKanbanCardMutation()

  const r = useActiveResources()

  const templatePageApi = React.useContext(ItemTemplate.Context)

  const deleteCard = React.useCallback(() => {
    if (!props.cardID || !r.workspaceID) return

    mutate({
      variables: { cardID: props.cardID, workspaceID: r.workspaceID },
      update(proxy) {
        if (!props.cardID) return

        updateFragment(
          {
            typename: Typename.KanbanCard,
            cacheIDParams: { id: props.cardID },
            updateFn(a) {
              const b: typeof a = { ...(a || {}), isDeleted: true }
              return b
            },
          },
          proxy
        )

        // const a = readFragment(
        //   {
        //     typename: Typename.KanbanCard,
        //     cacheIDParams: { id: props.cardID },
        //   },
        //   proxy
        // )

        // console.log({ a })
        // proxy.writeFragment<>({})
      },
    })

    templatePageApi.onClose()
  }, [props.cardID, r.workspaceID, mutate, templatePageApi.onClose])

  return useMemoAPI({
    open: modalAPI.open,
    close: modalAPI.close,
    isOpen: modalAPI.isOpen,
    delete: deleteCard,
  })
}

function useDescription(props: {
  cardForm: ReturnType<typeof useCardForm>
  cardID: string | undefined | null
}) {
  const modalAPI = useOpenClose()

  const fieldProps = props.cardForm.getFieldProps("description")

  const text = fieldProps.value

  const changeCard = useChangeKanbanCard({ cardID: props.cardID })

  const submitChange = React.useCallback(
    (text: string) => {
      return changeCard({ description: text })
    },
    [changeCard]
  )

  const modal = useMemoAPI({
    close: modalAPI.close,
    isOpen: modalAPI.isOpen,
    open: modalAPI.open,
  })

  return useMemoAPI({
    modal,
    text,
    submitChange,
  })
}
