import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import { Icon, IconProps } from "components/Icon/Icon"
import useTypesOptions from "components/PageBoard/hooks/useTypesOptions"
import { PageBoardItemType } from "lib/graphql/types"
import { StyledProps } from "types/type"

export function TypeBallBadge(
  props: {
    size?: number
    backgroundColor?: string
    type?: PageBoardItemType
    icon?: IconProps["name"]
    children?: (props: { defaultComponent: React.ReactNode }) => React.ReactNode
  } & StyledProps
) {
  const options = useTypesOptions()

  const size = props.size

  const c = useStyles({
    size: size,
    defaultSize: 30,
    backgroundColor:
      props.backgroundColor ||
      (props.type ? options[props.type]?.color || "" : ""),
  })

  return (
    <div
      className={clsx(c.root, "type-ball-badge", props.className)}
      style={props.style}
    >
      {(() => {
        const icon: IconProps["name"] | null = props.type
          ? (options[props.type]?.icon as IconProps["name"])
          : props.icon || null

        if (!icon) return null

        const className = clsx(c.icon)

        const p: IconProps = {
          name: icon,
          className,
        }

        const d = <Icon {...p}></Icon>

        return props.children ? props.children({ defaultComponent: d }) : d
      })()}
    </div>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    root: (props: {
      size?: number
      backgroundColor: string
      defaultSize: number
    }) =>
      (() => {
        const size = props.size ?? props.defaultSize
        const height = size
        const width = size

        const scale = `scale(${size / props.defaultSize})`

        return {
          height,
          transform: scale,
          width,
          borderRadius: "50%",
          background: props.backgroundColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }
      })(),
    icon: {
      color: "white",
      fontSize: "19px !important",
      position: "relative",
    },
  }
})
