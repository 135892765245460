import { Fab, Tooltip } from "@material-ui/core"
import classNames from "classnames"
import { Icon, IconProps } from "components/Icon/Icon"
import React from "react"
import { MediaDevice } from "../video-types"
import { StyledProps } from "types/type"

type MicrophoneButtonProps = StyledProps & {
  isStartedAudio: boolean
  isMuted: boolean
  onMicrophoneClick: () => void
}

const MicrophoneButton: React.FC<MicrophoneButtonProps> = (props) => {
  const { className } = props

  const tooltipText = props.isStartedAudio
    ? props.isMuted
      ? "unmute"
      : "mute"
    : "start audio"

  const icon: IconProps["name"] = props.isStartedAudio
    ? props.isMuted
      ? "mic_off"
      : "mic"
    : "mic"

  return (
    <div className={classNames("microphone-footer", className)}>
      <Tooltip title={tooltipText}>
        <Fab color="secondary" size="large" onClick={props.onMicrophoneClick}>
          <Icon name={icon} style={{ color: "white" }} />
        </Fab>
      </Tooltip>
    </div>
  )
}

export default MicrophoneButton
