import { IconButton } from "@material-ui/core"
import clsx from "clsx"
import { Icon, IconProps } from "components/Icon/Icon"
import StopPropagation from "components/StopPropagation/StopPropagation"
import React from "react"
import { StyledProps } from "types/type"

export function ItemIconButton(
  props: {
    onClick: () => any
    icon: IconProps["name"]
  } & StyledProps
) {
  return (
    <StopPropagation
      className={clsx(props.className, "item-icon-button")}
      style={props.style}
    >
      <IconButton size="small" onClick={props?.onClick}>
        <Icon style={{ fontSize: 19 }} name={props.icon}></Icon>
      </IconButton>
    </StopPropagation>
  )
}
