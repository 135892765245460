import React from "react"
import useMemoAPI from "./useMemoAPI"

export default function useDocumentSelection() {
  const initialSelection = document.getSelection() || null

  const lastNotCollapsedRange = React.useRef<Range | null>(
    initialSelection?.isCollapsed
      ? null
      : initialSelection?.getRangeAt(0) || null
  )
  const [selection, setSelection] = React.useState<Selection | null>()

  const [textSelection, setTextSelection] = React.useState<string | null>("")

  React.useEffect(() => {
    const onSelection = () => {
      const s = document.getSelection()
      setSelection(() => s)
      setTextSelection(s?.toString() || "")

      if (!s?.isCollapsed) {
        lastNotCollapsedRange.current = s?.getRangeAt(0) || null
      }
    }

    document.addEventListener("selectionchange", onSelection)

    return () => {
      document.removeEventListener("selectionchange", onSelection)
    }
  }, [])

  const restoreLastNotCollapsedSelection = React.useCallback(() => {
    const s = document.getSelection()

    s?.removeAllRanges()

    const range = lastNotCollapsedRange.current
    if (!range) return

    s?.addRange(range)
  }, [])

  const containerContainsSelectionAnchor = React.useCallback(
    (containerElement: HTMLElement | null | undefined) => {
      // console.log(containerElement)
      if (!containerElement) return false
      if (initialSelection?.isCollapsed) return false
      return containerElement.contains(initialSelection?.anchorNode as Node)
    },
    [initialSelection, textSelection]
  )

  const selectionRestorerProps = React.useMemo(() => {
    const a = {
      style: { userSelect: "none" } as React.CSSProperties,
      onClick: () => restoreLastNotCollapsedSelection(),
    }

    return a
  }, [restoreLastNotCollapsedSelection])

  return useMemoAPI({
    selection: initialSelection,
    containerContainsAnchor: containerContainsSelectionAnchor,
    anchorNode: initialSelection?.anchorNode || null,
    // DO NOT REMOVE! '$textSelection' it is the reactive element that trigger the computation of this hook. $selection is not reactive.
    textSelection: textSelection,
    selectedLines: textSelection?.split("\n").filter(Boolean) || null,
    restoreLastNotCollapsedSelection,
    selectionRestorerProps,
  })
}
