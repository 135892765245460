import { ActiveSpeaker, Stream, VideoClient } from "@zoom/videosdk"
import MediaContext from "components/Zoom/context/MediaContext"
// import { useAudioLevel } from "components/Zoom/feature/video/hooks/useAudioLevel"
import useMemoAPI from "hooks/useMemoAPI"
import React from "react"
import { useAudioLevel } from "./useAudioLevel"
// import FabControlButton from 'components/FabControlButton'; // Adjust import based on your actual file structure
// import useMicControl from 'hooks/useMicControl'; // Assuming you place useMicControl in hooks folder

export function useAudio(props: {
  zoomClient: typeof VideoClient
  mediaStream: typeof Stream | null
}) {
  const [isAudioStarted, setIsAudioStarted] = React.useState(false)
  const [isMuted, setIsMuted] = React.useState(false)
  const stream = props.mediaStream

  const startAudio = React.useCallback(async () => {
    try {
      await stream?.startAudio()
      setIsAudioStarted(true)
      setIsMuted(false) // Since startAudio also unmutes
    } catch (error) {
      console.error("Error starting audio:", error)
    }
  }, [stream]) // stream added as a dependency

  // Stop Audio
  const stopAudio = React.useCallback(async () => {
    try {
      await stream?.stopAudio()
      setIsAudioStarted(false)
      setIsMuted(false)
    } catch (error) {
      console.error("Error stopping audio:", error)
    }
  }, [stream]) // stream added as a dependency

  // Mute Audio
  const muteAudio = React.useCallback(async () => {
    try {
      await stream?.muteAudio()
      setIsMuted(true)
    } catch (error) {
      console.error("Error muting audio:", error)
    }
  }, [stream]) // stream added as a dependency

  // Unmute Audio
  const unmuteAudio = React.useCallback(async () => {
    try {
      await stream?.unmuteAudio()
      setIsMuted(false)
    } catch (error) {
      console.error("Error unmuting audio:", error)
    }
  }, [stream]) // stream added as a dependency

  const level = useAudioLevel(props.zoomClient)

  const activeUserId = useUserIdOfActiveSpeaker(props.zoomClient)

  return useMemoAPI({
    audioLevel: level,
    isAudioStarted,
    isMuted,
    startAudio,
    stopAudio,
    muteAudio,
    unmuteAudio,
    activeUserId,
  })
}

function useUserIdOfActiveSpeaker(zoomClient: typeof VideoClient) {
  const [activeUserId, setActiveUserId] = React.useState<number | null>(null)

  React.useEffect(() => {
    const onActiveSpeakerChanged = (payload: Array<ActiveSpeaker>) => {
      setActiveUserId(payload[0].userId || null)
    }

    zoomClient.on("active-speaker", onActiveSpeakerChanged)

    return () => {
      zoomClient.off("active-speaker", onActiveSpeakerChanged)
    }
  }, [zoomClient])

  return activeUserId
}
