import React from "react"
import { FormikConfig, FormikErrors, useFormik } from "formik"
import useMemoAPI from "hooks/useMemoAPI"
import { FieldInputProps as FormikFieldInputProps } from "formik"

type Formik<T extends Object> = ReturnType<typeof useFormik<T>>

export type FieldFn<
  Value extends any = any,
  Key extends string = string
> = (props: { target: { name: Key; value: Value } }) => void

export type FieldInputProps<
  Value extends any = any,
  Key extends string = string
> = Omit<FormikFieldInputProps<Value>, "onChange" | "onBlur"> & {
  onChange: FieldFn<Value, Key>
  onBlur: FieldFn<Value, Key>
}

export type UseFormProps<Values extends { [key: string]: any }> = {
  initialValues: Values
  enableReinitialize?: boolean
  onSubmit?: (e: { values: Values }) => any

  onBlur?<Key extends keyof Values>(props: {
    target: { name: Key; value: Values[Key] }
    formik: Formik<Values>
  }): any

  onChange?<Key extends keyof Values>(props: {
    target: { name: Key; value: Values[Key] }
    formikWithOldValue: Formik<Values>
  }): any

  isReadyToSubmit?: (props: {
    values: Values
    errors: FormikErrors<Values>
    dirty: boolean
  }) => boolean
  validationSchema?: FormikConfig<Values>["validationSchema"]
  validateOnMount?: boolean

  //kept for the sake of backward compatibility
  //todo: remove this
  oldOnChange?: (props: { values: Values }) => any
}

export type FormApi<T extends object = object> = ReturnType<typeof useForm<T>>
export type UseFormApi<T extends object = object> = typeof useForm<T>

export default function useForm<Values extends Object>(
  props: UseFormProps<Values>
) {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const initialValues = props.initialValues

  const submit = React.useCallback(
    (e: Values) => {
      setIsSubmitting(true)
      return Promise.resolve(
        props.onSubmit?.call(undefined, { values: e })
      ).finally(() => setIsSubmitting(false))
    },
    [props.onSubmit]
  )

  const formik = useFormik<Values>({
    initialValues,
    enableReinitialize:
      typeof props.enableReinitialize === "undefined"
        ? true
        : props.enableReinitialize,
    onSubmit: submit,
    validationSchema: props.validationSchema,
    validateOnMount: props.validateOnMount,
  })

  // formik.handleBlur()
  React.useEffect(() => {
    props?.oldOnChange?.call(undefined, { values: formik.values })
  }, [formik.values])

  const getFieldProps = React.useCallback(
    <T extends keyof Values>(key: T) => {
      const fns = formik.getFieldProps(key)

      return {
        ...fns,

        onChange(...args: Parameters<(typeof fns)["onChange"]>) {
          fns.onChange(...args)

          const [params] = args as [
            { target: { name: typeof key; value: Values[typeof key] } }
          ]

          props.onChange?.call(undefined, {
            target: { name: params.target.name, value: params.target.value },
            formikWithOldValue: formik,
          })
        },
        onBlur(...args: Parameters<(typeof fns)["onBlur"]>) {
          fns.onBlur(...args)

          const [params] = args as [
            { target: { name: typeof key; value: Values[typeof key] } }
          ]

          props.onBlur?.call(undefined, {
            target: { name: params.target.name, value: params.target.value },
            formik,
          })
        },
      } as FieldInputProps<Values[T]>
    },
    [formik, props.onBlur, props.onChange]
  )
  const setValue = React.useCallback(function setValue<
    Key extends Extract<keyof Values, string>
  >(key: Key, value: Values[Key]) {
    // formik.setFieldValue(key, value)
    getFieldProps(key).onChange({ target: { name: key, value } })
  },
  [])

  const readyToSubmit = React.useMemo(() => {
    if (!!props.isReadyToSubmit)
      return props.isReadyToSubmit({
        values: formik.values,
        errors: formik.errors,
        dirty: formik.dirty,
      })

    const noError = !Object.values(formik.errors).length

    return formik.dirty && noError
  }, [props.isReadyToSubmit, formik.dirty, formik.errors])

  return useMemoAPI({
    formik,
    values: formik.values,
    submit: formik.submitForm?.bind(formik),
    getFieldProps: getFieldProps,
    setValue,
    readyToSubmit,
    isSubmitting,
  })
}
