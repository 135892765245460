import clsx from "clsx"

export default function Separator(props: {
  className?: string
  style?: React.CSSProperties
}) {
  return (
    <div
      className={clsx("separator", props.className)}
      style={{
        ...(props.style || {}),
        borderBottom: ".8px solid rgba(0,0,0,0.08)",
      }}
    />
  )
}
