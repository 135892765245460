import ScreenCentered from "../../components/ScreenCentered/ScreenCentered"
import React from "react"
import { Typography, Icon, useTheme } from "@material-ui/core"
import { Link } from "react-router-dom"
import DeviceObserver from "components/DeviceObserver/DeviceObserver"

export default function Page404() {
  const { isDesktop } = React.useContext(DeviceObserver.Context)

  const children = ({ color }) => (
    <Link
      to="/"
      style={{
        color,
        position: "absolute",
        left: 16,
        top: 16,
        display: "flex",
      }}
    >
      <Icon>arrow_back</Icon>
      <Typography style={{ marginLeft: 8 }}>Home </Typography>
    </Link>
  )

  return (
    <div style={{ position: "relative" }}>
      {isDesktop ? (
        <DesktopPage children={children} />
      ) : (
        <Ordinary404Page children={children} />
      )}
    </div>
  )
}

function Ordinary404Page({ children }) {
  const theme = useTheme()
  return (
    <ScreenCentered
      style={{ position: "relative", background: theme.palette.primary.main }}
    >
      {children({ color: "white" })}
      <NotFound color="white" />
    </ScreenCentered>
  )
}

function DesktopPage({ children }) {
  const theme = useTheme()
  const color = theme.palette.primary.main
  return (
    <ScreenCentered>
      {children({ color })}
      <NotFound color={color} />
      <img
        src="/static-images/404.png"
        style={{ maxWidth: "650px", width: "70vw", marginLeft: 100 }}
      />
    </ScreenCentered>
  )
}

function NotFound({ color, style = {} }) {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <Typography variant="h1" style={{ color }}>
        404
      </Typography>
    </div>
  )
}
