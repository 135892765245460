import { Chip, ChipProps, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import UseEffect from "components/UseEffect/UseEffect"
import React from "react"
import { StyledProps } from "types/type"
import { wrap } from "module"

export type InlineChipsProps = {
  chips: ChipProps[]
  charactersLimit?: number
  classes?: {
    chip?: string
  }
  wrap?: boolean
  renderChip?: (args: {
    defaultComponent: React.ReactNode
    index: number
  }) => React.ReactNode
} & StyledProps

function InlinedChipsComponent(
  props: InlineChipsProps,
  ref: React.Ref<HTMLDivElement>
) {
  const c = useStyles({ wrap: props.wrap })

  const charactersLimit = props.charactersLimit ?? 25

  return (
    <div
      className={clsx(c.root, "chips", props.className)}
      style={props.style}
      ref={ref}
    >
      {props.chips.map((chipProps, index) => {
        const d = (
          <React.Fragment key={index}>
            <Chip
              key={index}
              {...chipProps}
              label={(() => {
                if (
                  typeof chipProps.label === "string" &&
                  chipProps.label.length > charactersLimit
                ) {
                  return chipProps.label.slice(0, charactersLimit) + "..."
                }

                return chipProps?.label || ""
              })()}
              className={clsx(
                c.chip,
                props.classes?.chip,
                chipProps?.className
              )}
              size={chipProps?.size || "medium"}
            ></Chip>
          </React.Fragment>
        )

        if (typeof props.renderChip === "function")
          return (
            <React.Fragment key={index}>
              {props.renderChip({ defaultComponent: d, index })}
            </React.Fragment>
          )

        return d
      })}
    </div>
  )
}

export const InlinedChips = React.forwardRef(InlinedChipsComponent)

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      flexWrap: (props: { wrap?: boolean }) => (props.wrap ? "wrap" : "nowrap"),
    },
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }
})
