import clsx from "clsx"
import { RoomContext } from "components/Room/contexts/room"
import { RoomVideoConference } from "components/RoomVideoConference/RoomVideoConference"
import UseEffect from "components/UseEffect/UseEffect"
// import r Conference } from "contexts/ConferenceContext"
import { StyledProps } from "types/type"
import { ConferenceActivity } from "./ConferenceActivityContext"
// import { StyledProps } from "@material-ui/core"
/**
 * @deprecated This component is being replaced by a newer implementation.
 */

export function VideoConference(props: StyledProps & { onLeave?: () => void }) {
  return (
    <ConferenceActivity.Context.Consumer>
      {(conferenceAPI) => {
        return (
          <RoomContext.Consumer>
            {(roomAPI) => {
              const roomID = roomAPI.room?.id

              if (!roomID) return null

              return (
                <>
                  <RoomVideoConference
                    roomID={roomID || ""}
                    style={props.style}
                    className={clsx(props.className, "video-conference")}
                    onLeave={props.onLeave}
                  ></RoomVideoConference>
                  <UseEffect
                    deps={[]}
                    effect={() => {
                      conferenceAPI.isActive.setTrue()

                      return () => {
                        conferenceAPI.isActive.setFalse()
                      }
                    }}
                  ></UseEffect>
                </>
              )
            }}
          </RoomContext.Consumer>
        )
      }}
    </ConferenceActivity.Context.Consumer>
  )
}
