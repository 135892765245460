import clsx from "clsx"
import _ from "lodash"
import React from "react"
import { makeStyles } from "@material-ui/styles"
import { alignmentUnits } from "styles/globalStyles"
import { Paper } from "@material-ui/core"
import Grid from "components/Grid/Grid"

function SubForms({ subForms = [], getInput }) {
  const c = useStyles({})
  return subForms.map(({ fields, grid }) => {
    return (
      <Paper className={c.paperForm}>
        <Grid
          entries={fields.filter(Boolean)}
          grid={grid || { xs: 12 }}
          spacing={2}
          renderEntry={({ entry: field }) => {
            const components = {
              string: getInput(field),
              function: React.createElement(field, { getInput }),
              object: (function object() {
                const input = field.render ? (
                  <field.render getInput={getInput} />
                ) : field.component ? (
                  field.component
                ) : (
                  getInput(field.name)
                )

                if (!input) return null

                const inputClassName = _.get(input, "props.className")
                const fieldClassName = _.get(field, "props.className")

                const props = {
                  ...(input.props || {}),
                  ...(field.props || {}),
                  className: clsx(fieldClassName, inputClassName)
                }

                return React.cloneElement(input, props)
              })()
            }

            return components[typeof field]
          }}
        ></Grid>
      </Paper>
    )
  })
}

export default SubForms

const useStyles = makeStyles(theme => {
  return {
    paperForm: {
      padding: theme.spacing(alignmentUnits)
    }
  }
})
