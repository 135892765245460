import Color from "color"
import { makeStyles, ButtonBase } from "@material-ui/core"
import moment from "moment"
import clsx from "clsx"
import MonthCalendar, {
  WeekProps,
} from "components/MonthCalendar/MonthCalendar"
import React from "react"
import SelfContainedBall from "components/SelfContainedBall/SelfContainedBall"
import MonthCalendarRoot from "components/MonthCalendar/components/MonthCalendarRoot/MonthCalendarRoot"
import MonthCalendarContext from "components/MonthCalendar/contexts/MonthCalendarContext"

export default function MonthCalendarWithSelection(
  props: React.ComponentProps<typeof MonthCalendarRoot>
) {
  const renderDay = React.useCallback(
    (() => {
      const a: WeekProps["renderDay"] = function renderDay({
        defaultComponent,
        day,
      }) {
        return <DayDecorator day={day}>{defaultComponent}</DayDecorator>
      }
      return a
    })(),
    []
  )

  return (
    <MonthCalendar.Root
      {...props}
      WeekProps={{ ...(props.WeekProps || {}), renderDay }}
    ></MonthCalendar.Root>
  )
}
function DayDecorator(props: {
  children: React.ReactNode
  day: moment.Moment
}) {
  const api = React.useContext(MonthCalendarContext)

  const format = "YYYY-MM-DD"

  const c = useDayDecoratorStyle()

  const isSelected =
    api.selectedDay?.format(format) === props.day.format(format)

  if (props.day.month() !== api.month.index) return null

  return (
    <SelfContainedBall
      className={clsx("day-decorator", isSelected && c.selected, c.root)}
    >
      <ButtonBase
        onClick={() => api.setSelectedDay(props.day)}
        className={c.circularButton}
      >
        {props.children}
      </ButtonBase>
    </SelfContainedBall>
  )
}
const useDayDecoratorStyle = makeStyles((theme) => {
  const selectionBaseColor = theme.palette.secondary.light
  const backgroundColor = Color(selectionBaseColor).alpha(0.3).string()

  return {
    root: {
      margin: "0 auto",
    },
    selected: {
      background: backgroundColor,
      "& *": {
        color: Color(backgroundColor).alpha(0.9).string(),
      },
    },
    circularButton: { height: "100%", borderRadius: "50%", width: "100%" },
  }
})
