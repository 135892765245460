import * as Yup from "yup"
import _ from "lodash"
import { useI18n } from "contexts/i18nContext/i18nContext"

export default function useContactFields() {
  const t = useI18n()

  return [
    {
      name: "salutation",
      label: t.salutation,
      type: "autocomplete",
      options: [t.herr, t.frau, t.team],
    },
    { name: "title", label: t.title },
    { name: "firstName", label: t.firstName },
    { name: "lastName", label: t.lastName },
    { name: "position", label: t.position },
    { name: "landline", label: t.landline },
    { name: "mobile", label: t.mobile },
    { name: "active", label: "", type: "active-switch" },
    // { name: "remarks", label: t.remarks, multiline: true },
    {
      name: "remarks",
      label: t.remarks,
      type: "rich-editor",
      toolbarButtonSize: "small",
    },
    {
      name: "email",
      label: t.email,
      schema: Yup.string().email(t.wrongEmail).nullable(),
    },
    {
      name: "accessKey",
      label: t["access-key"],
      readOnly: true,
      getDefaultValue: (data) => _.get(data, "access.key"),
      type: "copy-access-key-to-clipboard",
    },
    {
      name: "attachFile",
      label: t["attach-file-to-email"],
      type: "radio-group",
      row: true,
      options: [
        { label: t.yes, value: "YES" },
        { label: t.no, value: "NO" },
        { label: t.direct, value: "DIRECT" },
      ],
    },
    {
      name: "notifyAccessKey",
      type: "select",
      label: t["automatic-notification"],
      options: [
        {
          label: t.yes,
          value: "YES",
        },
        { label: t.no, value: "NO" },
        {
          label: "OrderResp",
          value: "ORDERRESP",
        },
        { label: "Order", value: "ORDER" },
        { label: "Invoice", value: "INVOICE" },
      ],
    },

    {
      name: "checkboxes-flags",
      label: "",
      type: "checkboxes",
      getDefaultValue: (data) => _.pick(data, ["createColKey", "remindMe"]),
      checkboxes: [
        { label: t["create-access-on-upload"], name: "createColKey" },
        {
          label: t["weekly-reminder-email-for-new-documents"],
          name: "remindMe",
        },
      ],
    },
  ]
}
