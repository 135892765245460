import React from "react"
import _ from "lodash"
import moment from "moment"
import useMemoAPI from "hooks/useMemoAPI"
import { Interval } from "../types"
import { VirtuosoHandle } from "react-virtuoso"
import useOnChange from "hooks/useOnChange"
import { excludeNullable } from "lib/excludeNullable"
import { Month } from "components/MonthCalendar/types"
import { Period } from "types/type"
import { PeriodSelectorProps, usePeriodSelector } from "./usePeriodSelector"
// import { usePeriodSelector } from "../../DateIntervalSelector/usePeriodSelector"

type Position = "start" | "end"

// export type Period =
export type { Period }

export type MonthsAPIProps = {
  period?: Period | null
  onChangePeriod?(props: { period: Period | null }): any
} & PeriodSelectorProps

export type MonthsAPI = ReturnType<typeof useMonthsAPI>

const format = "DD.MM.YY"

const getInterval = (period: Period | null) => {
  return [period?.start, period?.end].filter(excludeNullable) as Interval
}

const getPeriod = (interval: Interval): Period | null => {
  if (!interval.length) return null

  const start = interval[0]

  if (!start) return null

  return {
    start,
    end: interval[1],
  }
}

export function useMonthsAPI(props: MonthsAPIProps) {
  const periodSelector = usePeriodSelector({
    periodState: props.periodState,
    onChangePeriodState: props.onChangePeriodState,
  })

  const period = periodSelector.period
  const setPeriod = periodSelector.setPeriod

  const interval = React.useMemo(() => {
    return getInterval(period)
  }, [period])

  // console.log(interval, period)

  const setDay = React.useCallback(
    function setDay(props: { day: moment.Moment; position?: Position }) {
      const resolve = (period: Period | null) => {
        const old = getInterval(period)

        if (old.some((d) => d?.isSame(props.day, "day")))
          //toggling
          return getPeriod(
            old.filter((a) => !a?.isSame(props.day, "day")) as Interval
          )

        let array: moment.Moment[] = [
          ...(old || []).filter((a): a is moment.Moment => !!a),
        ]

        if (!!props.position) {
          if (props.position === "start") {
            return getPeriod([props.day])
          }

          if (props.position === "end" && old[0]?.isBefore(props.day)) {
            // return [old[0], props.day]
            return getPeriod([old[0], props.day])
          }

          return getPeriod(old)
        }

        if (array.length % 2 === 0) {
          array = [props.day]
        } else {
          array[1] = props.day
        }

        const b = array.sort((a, b) => {
          if (a.toISOString() < b.toISOString()) return -1
          return 1
        })

        const c = _.uniqBy(b, (a) => a?.format(format))

        const interval = [c[0], c[1]] as Interval

        const newPeriod = getPeriod(interval)

        return newPeriod
      }

      // setInterval((old) => {
      // })

      setPeriod((oldPeriod) => {
        return resolve(oldPeriod)
      })
    },

    []
  )

  // const clearField = React.useCallback(
  //   function clear(props: { position: Position }) {
  //     return setPeriod((oldPeriod) => {
  //       const old = getInterval(oldPeriod)
  //       if (props.position === "start") return getPeriod([])
  //       return getPeriod([old[0]])
  //     })
  //   },
  //   [setPeriod]
  // )

  // const setPeriod = React.useCallback((period: Period | null) => {
  //   const interval: Interval = (() => {
  //     const a = [period?.start, period?.end]
  //       .filter((a): a is moment.Moment => !!a)
  //       .sort((a, b) => {
  //         return a.toISOString() < b.toISOString() ? -1 : 1
  //       })

  //     return [a[0], a[1]]
  //   })()

  //   setInterval(interval)
  // }, [])

  // useOnChange({
  //   value: [period?.start?.format(format), period?.end?.format(format)]
  //     .filter(Boolean)
  //     .join("-"),
  //   disabled: !props?.onChangePeriod,
  //   onChange() {
  //     props?.onChangePeriod?.call(undefined, { period: period || null })
  //   },
  // })

  const listAPI = useListAPI({
    ActiveMonthProps: {
      monthOnMount: getMonthByMoment({ date: period?.start || moment() }),
    },
  })

  return useMemoAPI({
    interval,
    list: listAPI,
    setPeriod,
    period,
    periodSelectorApi: periodSelector,
    setDay,
  })
}

const start = moment("2010-01-01")
const end = moment().add(5, "years")
const totalCount = moment(end).diff(start, "month")

function getMonth(props: { index: number }) {
  const a = start.clone().add(props.index, "month")
  const r: Month = { index: a.month(), year: a.year() }
  return r
}

export function getMonthByMoment(props: { date: moment.Moment }) {
  const a = props.date
  const r: Month = { index: a.month(), year: a.year() }
  return r
}

type ActiveMonthProps = {
  monthOnMount?: Month
}

function useActiveMonth(props: ActiveMonthProps = {}) {
  const [activeMonth, setActiveMonth] = React.useState<Month>(
    props.monthOnMount || getMonthByMoment({ date: moment() })
  )

  const activeIndex = React.useMemo(() => {
    return moment()
      .month(activeMonth.index)
      .year(activeMonth.year)
      .diff(start, "month")
  }, [activeMonth])

  // console.log(activeMonth)

  const setActiveIndex = React.useCallback((index: number) => {
    const month = getMonth({ index })
    setActiveMonth(month)
  }, [])

  const addMonth = React.useCallback((props: { amount: number }) => {
    // const month = getMonth({ index: activeIndex + props.amount })
    setActiveMonth((month) => {
      const a = moment()
        .month(month.index)
        .year(month.year)
        .add(props.amount, "month")

      return { index: a.month(), year: a.year() }
    })
  }, [])

  const add = React.useCallback(
    (props: {
      amount: number
      unit: moment.unitOfTime.DurationConstructor
    }) => {
      setActiveMonth((month) => {
        const a = moment()
          .month(month.index)
          .year(month.year)
          .add(props.amount, props.unit)

        return { index: a.month(), year: a.year() }
      })
    },
    []
  )

  return useMemoAPI({
    month: activeMonth,
    setMonth: setActiveMonth,
    addMonth,
    index: activeIndex,
    add,
    setIndex: setActiveIndex,
  })
}

function useListAPI(props: { ActiveMonthProps?: ActiveMonthProps } = {}) {
  const virtuosoHandle = React.useRef<VirtuosoHandle>(null)

  const initialTopMostIndex = (() => {
    return moment().diff(start, "month")
  })()

  const goToMonth = React.useCallback(
    (props: { date: moment.Moment }) => {
      const index = moment()
        .year(props.date.year())
        .month(props.date.month())
        .diff(start, "month")

      virtuosoHandle.current?.scrollToIndex({ index, align: "start" })
    },
    [virtuosoHandle.current?.scrollToIndex]
  )

  return useMemoAPI({
    activeMonth: useActiveMonth(props.ActiveMonthProps),
    virtuosoHandle,
    start,
    end,
    totalCount,
    initialTopMostIndex,
    goToMonth,
    getMonth,
  })
}
