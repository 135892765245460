import groupBy from "util/groupBy"
import React, { useState, useMemo } from "react"
import { makeStyles } from "@material-ui/core/styles"
import _ from "lodash"
import useFilteredAndSortedEntries from "hooks/useFilteredAndSortedEntries/useFilteredAndSortedEntries"
import { flexColumn, alignmentUnits } from "styles/globalStyles"
import Head from "./components/Head/Head"
import Body from "./components/Body/Body"
import FilterInput from "./components/FilterInput/FilterInput"
import { Scrollbars } from "react-custom-scrollbars"
import { Paper, Table as MuiTable } from "@material-ui/core"
import clsx from "clsx"
// import sortBy from "util/sortBy"

/** TODO:  include documentation */
function Table({
  columns = [],
  rows: propsRows = [],
  hover = true,
  className = "",
  showSearch = true,
  onClickRow = null,
  defaultSortOrder = "asc",
  defaultSortProperty = "",
}) {
  const c = useStyles({ hover })

  const [order, setOrder] = useState(defaultSortOrder)
  const toggleOrder = () =>
    setOrder(["asc", "desc"][Number(!(order === "desc"))])

  const firstStringColumn =
    firstColumnOfStrings({ rows: propsRows, columns: columns }) || {}

  const [orderBy, setOrderBy] = useState(
    defaultSortProperty || firstStringColumn.name
  )

  const [searchedTerm, setSearchedTerm] = useState("")

  const rows = useFilteredAndSortedEntries({
    entries: propsRows,
    searchedTerm,
    order,
    orderBy,
    fields: groupBy({ key: "name", collection: columns }),
  })

  const visibleColumns = useMemo(
    () => columns.filter((c) => !c.invisible),
    [columns]
  )

  return (
    <Paper className={clsx(c.paper, c.table, className)}>
      {showSearch && (
        <FilterInput
          onChange={_.debounce(setSearchedTerm, 300)}
          className={c.searchInput}
        />
      )}
      <Scrollbars>
        <MuiTable stickyHeader className={c.muiTable}>
          <Head
            columns={visibleColumns}
            orderBy={rows.length > 1 ? orderBy : ""}
            order={order}
            onClickLabel={({ columnName }) => {
              setOrderBy(columnName)
              toggleOrder()
            }}
          />
          <Body
            rows={rows}
            columns={visibleColumns}
            defaultValue="-"
            onClickRow={onClickRow}
            searchedTerm={searchedTerm}
            hover={hover}
            classes={{ row: c.bodyRow }}
          />
        </MuiTable>
      </Scrollbars>
    </Paper>
  )
}

export default React.memo(Table, (oldProps, nextProps) => {
  return oldProps.rows === nextProps.rows
})

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      height: "100%",
    },
    searchInput: {
      margin: theme.spacing(alignmentUnits),
    },
    muiTable: {
      "& .MuiTableCell-stickyHeader": {
        backgroundColor: "white",
      },
    },
    bodyRow: {
      cursor: (props) => props.hover && "pointer",
    },
    table: {
      background: "white",
      width: "100%",
      overflowX: "auto",
      height: "100%",
      ...flexColumn,
    },
    padding: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },
    flexGrow: {
      flexGrow: 1,
    },
  }
})

const firstColumnOfStrings = ({ columns, rows }) => {
  return columns.find((c) => {
    const firstRow = rows[0] || {}
    return typeof firstRow[c.name || ""] === "string"
  })
}
