import { useListApi } from "./useListApi"
import contextAndProviderFactory from "lib/contextAndProviderFactory"

const F = contextAndProviderFactory({
  hookApi: useListApi,
  contextName: "ListContext",
})

export const ListContext = F.Context
export const ListProvider = F.Provider
export const ListConsumer = F.Consumer
