import { makeStyles } from "@material-ui/styles"
import React from "react"
import { Button, Icon } from "@material-ui/core"
import clsx from "clsx"

function UploadButton({ onClick, className = "", disabled  = false }) {
  const c = useStyles({})

  return (
    <Button
      disabled={disabled}
      variant="contained"
      onClick={onClick}
      color="primary"
      className={clsx("upload-button", className)}
    >
      <Icon className={c.icon}>cloud_upload</Icon>
      upload
    </Button>
  )
}

export default UploadButton

const useStyles = makeStyles(theme => {
  return {
    icon: {
      marginRight: theme.spacing(1)
    }
  }
})
