import _ from "lodash"

export default function arePropsEqual<T extends Object>(
  keys: (keyof T | ((a: T) => any))[]
) {
  return (prevProps: T, nextProps: T) => {
    const r = (keys || []).every((key) => {
      if (typeof key === "function") {
        const fn = key
        return fn(prevProps) === fn(nextProps)
      }

      return _.get(prevProps, key) === _.get(nextProps, key)
    })

    return r
  }
}
